class MeusDadosVH extends AmaisVH {

	constructor() {
		super(MeusDadosVH.name);
		this.addOperacaoParaHash("mdmm", this.mostrarMeusDados);
	}

	async mostrarMeusDados() {

		UtilHash.registrarHistorico(this.mostrarMeusDados);

		const recuperacaoMeusDadosTO: RecuperacaoMeusDadosTO = await this.call("CadastroUsuarioFCD/recuperarMeusDados");

		this.limpar();

		this.setTitulo(recuperacaoMeusDadosTO.nome);
		this.setSubtitulo(this.getMsg("MSG_VH_CU_01"));

		if (recuperacaoMeusDadosTO.possuiSenha && (!this.isAluno() || this.isCfgHabilitada("HABILITAR_TROCA_SENHA_PELO_AVALIADO"))) {
			this.setIdTarget("tituloSuperiorDireito");

			this.addBotao({
				label: `<i class="fa fa-sm fa-key"></i> ` + this.getMsg("FP_FRONT_MeusDadosVH_001"),
				onClick: async () => {
					await this.exibirTrocaDeSenha(recuperacaoMeusDadosTO.codUsuario)
				}
			});

			this.setIdTarget(null);
		}

		if (this.hasValue(recuperacaoMeusDadosTO.email)) {
			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_CU_02"), 
				valor: recuperacaoMeusDadosTO.email,
				classe: "col-md-12"
			});
		}		

		if (this.hasValue(recuperacaoMeusDadosTO.login)) {
			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_CU_03"), 
				valor: recuperacaoMeusDadosTO.login,
				classe: "col-md-12"
			});
		}

		if (recuperacaoMeusDadosTO.podeAlterarDocIdentificacao && this.isAluno()) {
			this.addCampoExibicaoComUploadImg({
				label: this.getMsg("FP_FRONT_MeusDadosVH_014"),
				urlImg: recuperacaoMeusDadosTO.pathDocIdentificacaoCadastrado,
				onClickUpload: async () => {
					const isDocAnexado = await this.uploadDocIdentidade(recuperacaoMeusDadosTO.codUsuario);
					if (isDocAnexado === true) {
						this.mostrarMeusDados();
					}
				},
				onClickRemocao: async () => {
					await this.excluirDocIdentificacao(recuperacaoMeusDadosTO.codUsuario);
					this.mostrarMeusDados();
				},
			});
		}
		
		if (this.isCfgHabilitada("PERMITIR_TROCA_IDIOMA_USUARIO")) {

			await this.addSelect({
				collection: this.getCollectionLocales(),
				id: "usuario_locale",
				label: this.getMsg("MSG_VH_CU_190"),
				valor: recuperacaoMeusDadosTO.locale,
				obrigatorio: true,
				classe: "col-md-3",
				onChange: async () => {
					const dadosBasicosTO: DadosBasicosTO = {
						codUsuario: recuperacaoMeusDadosTO.codUsuario,
						locale: this.getValor("usuario_locale"),
					}
					await this.call("LoginFCD/alterarDadosBasicos", dadosBasicosTO);
					await this.exibirAlerta({
						msg: `<i class="fa fa-check-circle fa-lg text-success"></i> ${this.getMsg("FP_FRONT_MeusDadosVH_008")}`,
						botoes: [{
							label: this.getMsg("FP_FRONT_MeusDadosVH_007"),
							classe: "btn-primary"
						}]
					});
					document.location.reload();
				}
			});
		}

		this.exibir();
	}
	
	async excluirDocIdentificacao(codUsuario: number) {
		return new Promise((resolve, reject) => {
			this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_MeusDadosVH_013"),
				botoes: [{
					label: "Excluir",
					classe: "btn-danger",
					onClick: async () => {
						try {
							await this.call("DocIdentificacaoFCD/excluirDoc", codUsuario);
							resolve(null);
						} catch (e) {
							this.logger.error("Erro ao tentar remover doc de identificação", e);
							reject(e);
						}
					}
				}],
			});
		});
	}

	exibirTrocaDeSenha(codUsuario: number) {
		
		this.addPopup({
			width: "300px",
			titulo: this.getMsg("FP_FRONT_MeusDadosVH_001"),
			botoes: [{
				label: this.getMsg("FP_FRONT_MeusDadosVH_002"), 
				classe: "btn-primary", 
				onClick: async () => {

					const senha_atual = this.getValor("senha_atual");
					const senha_nova = this.getValor("senha_nova");
					const senha_nova_confirmacao = this.getValor("senha_nova_confirmacao");
					
					if (!this.validarCamposObrigatorios("senha_atual", "senha_nova", "senha_nova_confirmacao")) {
						return false;
					}

					if (!this.isEmpty(senha_nova) && !UtilPasswordValidator.isSenhaForte("senha_nova")) {
						await amaisVH.exibirAlerta({ 
							msg: UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_040') 
						});
						return false;
					}

					const trocaSenhaTO: TrocaSenhaTO = {
						codUsuario: codUsuario,
						senhaAtual: senha_atual,
						novaSenha: senha_nova,
						confirmacaoNovaSenha: senha_nova_confirmacao
					}

					await this.call("LoginFCD/trocarSenha", trocaSenhaTO);
				}
			}]
		});

		this.addCampoTexto({ 
			id: "senha_atual", 
			label: this.getMsg("MSG_VH_L_02"), 
			tipo: "SENHA",
			classe: "col-md-12"
		});
		this.addCampoTexto({
			id: "senha_nova",
			label: this.getMsg("MSG_VH_L_03"),
			tipo: "SENHA",
			html: "autocomplete='new-password'",
			validacaoSenha: true,
			classe: "col-md-12"
		});
		this.addCampoTexto({ 
			id: "senha_nova_confirmacao", 
			label: this.getMsg("MSG_VH_L_04"), 
			tipo: "SENHA" ,
			classe: "col-md-12"
		});

		this.exibirPopups();
	}

	/**
	 * Só dá RESOLVE se o processo (upload e validação) obtiver sucesso. Senão, dá o REJECT.
	 */
	async uploadDocIdentidade(codUsuario: number): Promise<boolean> {

		let uploadTO: UploadTO = null;

		try {
			uploadTO = await this.enviarDocIdentidade(codUsuario);
		} catch (e) {
			this.logger.error("Erro ao enviar o doc de identidade", e);
			await this.exibirAlerta({
				msg: "Ocorreu um erro ao realizar o upload do documento. Verifique seu sinal de internet e tente novamente."
			});
			throw e;
		}

		if (!uploadTO) return false;

		const isDocValido = await this.analisarDocIdentidade(uploadTO, codUsuario);

		if (isDocValido === true) {
			await this.exibirAlerta({ 
				msg: `<i class="fa fa-check-circle fa-lg text-success"></i> ` + this.getMsg("FP_FRONT_TelaInicialVH_026")
			});
		}		

		return isDocValido;
	}

	async analisarDocIdentidade(uploadTO: UploadTO, codUsuario: number): Promise<boolean> {

		let resolve = null;
		let validou = false;

		const promise = new Promise<boolean>((r) => {
			resolve = r;
		});

		this.exibirAlerta({
			msg: `<i class="fas fa-spinner fa-spin" ></i>&nbsp;&nbsp;&nbsp;`+ this.getMsg("FP_FRONT_CadastroUsuarioVH_051"),
			blockUICallback: async ($msgModal) => {

				const analiseDocIdentidade: AnaliseDocIdentidade = await this.call(
					"DocIdentificacaoFCD/inserirDocEValidar", 
					uploadTO,
					codUsuario
				);

				this.logger.info("Analise do documento de identidade retornou: ", JSON.stringify(analiseDocIdentidade));

				if (analiseDocIdentidade.msgErro) {

					$msgModal.html(analiseDocIdentidade.msgErro);

					if (analiseDocIdentidade.permiteModeracaoAdm) {
						this.show("btnDocExcluir", "btnDocManter");
					} else {
						this.show("btnDocFechar");
					}

					// não fecha a popup e espera o onHidden
					// qquer clique nos botões aciona o hide e hidden
					await promise;

				} else if (analiseDocIdentidade.msgAlerta) {
					validou = true;
					$msgModal.html(analiseDocIdentidade.msgAlerta);
					this.show("btnDocFechar");

					await promise;
				} else {
					validou = true;
					// vai fechar a popup e chamar o onHidden
				}				
			},
			onHidden: async () => {
				resolve(validou);
			},
			botoes:  [{
				id: "btnDocManter",
				label: this.getMsg("FP_FRONT_CadastroUsuarioVH_046"),
				css: "display: none",
				onClick: async () => {
					validou = true;
				}
			},{
				id: "btnDocExcluir",
				label: this.getMsg("FP_FRONT_CadastroUsuarioVH_047"),
				css: "display: none",
				classe: "btn-danger",
				onClick: async () => {
					await this.call("DocIdentificacaoFCD/excluirDoc", codUsuario);
				}
			},{
				id: "btnDocFechar",
				label: this.getMsg("MSG_VH_003"),
				css: "display: none",
			}]
		});

		return promise;
	}

	async enviarDocIdentidade(codUsuario): Promise<UploadTO> {
		
		return new Promise<UploadTO>(async (resolve, reject) => {

			let uploadTOParaResolve: UploadTO = null;
			let errorParaReject: Error = null;

			try {
				const recuperacaoUsuarioLogadoTO = await this.call("LoginFCD/recuperarDadosUsuarioLogado", codUsuario);
						
				this.addPopup({
					id: "upload_doc_identificacao",
					titulo: recuperacaoUsuarioLogadoTO.nome,
					subtitulo: this.getMsg("FP_FRONT_MeusDadosVH_006"),
					fullWidth: true,
					onHidden: () => {
						if (errorParaReject) {
							reject(errorParaReject);
						} else {
							resolve(uploadTOParaResolve);
						}
					}
				});

				this.append(`
					<div class='col-md-6 col-md-offset-3' style="font-size: 120%">
				`);

				this.addSubtitulo(this.getMsg("FP_FRONT_MeusDadosVH_003"));

				this.append(this.getMsg("MSG_VH_CU_191"));
				
				this.addSubtitulo(this.getMsg("FP_FRONT_MeusDadosVH_004"));

				this.append(`
						<img src="widgets/img/img_instrucao_doc_v2.jpeg" id="img_instrucao_doc" class="img-certer-div" alt=""/>
				`);

				this.addSubtitulo(this.getMsg("FP_FRONT_MeusDadosVH_005"));

				this.addZonaUpload({
					id: "aluno_uploadDocIdentificacao",
					label: "Selecione o RG ou CNH",
					classe: "col-md-12",
					isBase64: true,
					// maxArquivos: 1,
					arquivosAceitos: ".png,.jpg,.jpeg,.heic,.heif,.pdf",
					css: "text-align: center;",
					privado: true,
					maxWidthOuHeight: 1500, // SE DIMINUIR PARA 700 NÃO DÁ OCR NA MINHA CNH!
					converterPdfParaPng: true,
					onErro: () => {
						errorParaReject = new Error("Erro no upload do documento de identificacao");
						this.fecharPopup("upload_doc_identificacao"); // dispara o reject no onHidden
					},
					onDepoisEnvio: (file, uploadTO) => {
						uploadTOParaResolve = uploadTO;
						this.fecharPopup("upload_doc_identificacao"); // dispara o resolve no onHidden
					}
				});

				this.append(`
					</div>
				`);

				this.exibirPopups();

			} catch (e) {
				errorParaReject = new Error(e);
				this.fecharPopup("upload_doc_identificacao"); // dispara o reject no onHidden
			}
		});
	}
	
	alertarValicadacaoDocumento(body, botoes) {
		this.addPopup({
			id: "validacao_documento_identificacao",
			titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_041"),
			width: "40%",
			botoes: botoes,
		});

		this.append(`
			<div class="col-md-12 container-alerta-doc-identificacao">
				${body}
			</div>
		`);

		this.exibirPopups();
	}
}

const meusDadosVH = new MeusDadosVH();

type RecuperacaoMeusDadosTO = {
	codUsuario: number;
	nome: string;
	email: string;
	login: string;
	pathDocIdentificacaoCadastrado: string;	
	possuiSenha: boolean;
	podeAlterarDocIdentificacao: boolean;
	locale: string;
}
type DadosBasicosTO = {
	codUsuario: number;
	caminhoDocumentoIdentificacao?: UploadTO;
	caminhoImgPerfil?: UploadTO;
	trocaSenhaTO?: TrocaSenhaTO;
	locale?: string;
}
type TrocaSenhaTO = {
	codUsuario: number;
	senhaAtual: string;
	novaSenha: string;
	confirmacaoNovaSenha: string;
}
type AnaliseDocIdentidade = {
	msgErro: string;
	msgAlerta: string;
	permiteModeracaoAdm: boolean;
}