class CadastroSecaoVH extends AmaisVH {
	
	public edicaoQuestoesProvaTO: any;
	listaTiposQuestao: any;
	edicaoSecaoTO: any;
	metodoVHDepoisDeSalvarSecao: any;
	
	constructor() {
		super("CadastroSecaoVH");
	}

	async exibirSecoes(codProva, irParaBuscaQuestoes = false) {

		const edicaoQuestoesProvaTO: EdicaoQuestoesProvaTO = await this.call("CadastroProvaFCD/listarSecoesPorProva", codProva);

		this.limpar(true);

		await this.exibirSecoesProva(edicaoQuestoesProvaTO, irParaBuscaQuestoes)
	}

	async exibirSecoesProva(edicaoQuestoesProvaTO, irParaBuscaQuestoes = false) {
		revisaoProvaVH.exibirDemandaProva(edicaoQuestoesProvaTO.demandaProvaTO, true);

		if (edicaoQuestoesProvaTO.podeAlterar) {
			this.append(`
				<div class="col-md-12">
					<div class="btn-group pull-right">
			`);
			if (!edicaoQuestoesProvaTO.isArquivada) {
				if (edicaoQuestoesProvaTO.secoes?.length > 0) {
					this.addBotao({
						texto: this.getMsg("FP_FRONT_CadastroSecaoVH_001"), 
						classe: "btn-sm", 
						onClick: () => {
							this.addPopup({titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_002"), id: "edicao_secao",
								botoes: [{
									label:this.getMsg("FP_FRONT_CadastroSecaoVH_003"),
									classe: "btn-primary",
									onClick: async () => {

										const idsSecoes = [];

										$("[id^=secaoOpt]:checked").each(function() {
											idsSecoes.push(this.id.substring(8));
										})

										if (idsSecoes.length == 1) {
											this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroSecaoVH_004") });
											return false;
										}

										await this.call("CadastroProvaFCD/salvarSecoesOptativas", edicaoQuestoesProvaTO.codProva, idsSecoes);

										await this.exibirSecoes(edicaoQuestoesProvaTO.codProva);
									}
								}]
							})

							this.addTexto(this.getMsg("FP_FRONT_CadastroSecaoVH_005"));

							for (const exibicaoSecaoTO of edicaoQuestoesProvaTO.secoes) {
								this.addCheckbox({
									id: "secaoOpt" + exibicaoSecaoTO.codSecao,
									label: exibicaoSecaoTO.nome,
									valor: exibicaoSecaoTO.isOptativa
								});
								this.addEspacamentoHorizontal("1px");
							}

							this.exibirPopups();
						}
					});
				}
			}

			this.addBotao({
				label: `<i class='fa fa-lock'></i> ${edicaoQuestoesProvaTO.isQuestoesBloqueadas ? this.getMsg("FP_FRONT_CadastroSecaoVH_006") : this.getMsg("FP_FRONT_CadastroSecaoVH_007")}`,
				classe: "btn-sm fp-small-business-hidden",
				id: "btn-bloquear-questoes",
				onClick: () => {
					this.exibirTelaBloqueioQuestoes(edicaoQuestoesProvaTO.codProva);
				}
			});

			if (!edicaoQuestoesProvaTO.isArquivada) {
				this.addBotao({
					id: "btnSalvarSecao",
					texto:`<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroSecaoVH_008")}`,
					onClick: () => this.criarNovaSecao(),
					classe: "btn-sm btn-success"
				});

				this.append(`
						<button id="btnDropdownOpcoesQuestaoSecao" type='button' class='btn btn-sm btn-primary dropdown-toggle' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
							<i class='fa fa-plus-circle'></i> 
								${this.getMsg("FP_FRONT_CadastroSecaoVH_065")}
							<span class='caret'></span>
						</button>
						<ul class='dropdown-menu dropdown-menu-right'>
							<li>
								<a onclick="cadastroSecaoVH.exibirCriacaoQuestao(${edicaoQuestoesProvaTO.codProva}, ${edicaoQuestoesProvaTO.idProvaResponsavel})">
									<i class='fa fa-plus-circle'></i> 
									${this.getMsg("FP_FRONT_CadastroSecaoVH_064")}
								</a>
							</li>
					
							<li>
								<a onclick="cadastroSecaoVH.exibirBuscaDeQuestoes(${edicaoQuestoesProvaTO.codProva})">
									<i class='fa fa-search'></i> 
									${this.getMsg("FP_FRONT_CadastroSecaoVH_009")}
								</a>
							</li>
							
							<li></li>
						</ul>
				`)
			}
			this.append(`
					</div>
				</div>
			`);
		}


		if (edicaoQuestoesProvaTO.secoes?.length > 0) {

			const deveExibirPontuacaoMinima = edicaoQuestoesProvaTO.secoes.some(s => this.hasValue(s.pontuacaoMinima));
			const totalizar = [];
			const colunas: ColunaAddTabela[] = [];

			colunas.push({
				titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_010"),
				prop: function(secaoTO) {
					if (!secaoTO.codSecao) return;
					const h = ["<a onclick='cadastroSecaoVH.verQuestoesDaSecao(this)' style='font-size: 110%'>" + secaoTO.numSecao + ") " + secaoTO.nome + "</a>"];
					secaoTO.listaEdicaoSecaoBlocoTO?.forEach(edicaoSecaoBlocoTO => {

						let msgBloco = [this.getMsg("FP_FRONT_CadastroSecaoVH_016", edicaoSecaoBlocoTO.numBloco)];

						msgBloco.push(edicaoSecaoBlocoTO.isAssociacaoAutomatica ? this.getMsg("FP_FRONT_CadastroSecaoVH_014") : "");
						msgBloco.push(edicaoSecaoBlocoTO.isAssociacaoAutomatica && edicaoSecaoBlocoTO.isRestricaoConteudo ? " e " : "");
						msgBloco.push(edicaoSecaoBlocoTO.isRestricaoConteudo ? this.getMsg("FP_FRONT_CadastroSecaoVH_015") : "");
						msgBloco.push(edicaoSecaoBlocoTO.tipoQuestaoResumido ? edicaoSecaoBlocoTO.tipoQuestaoResumido + " de " : "");

						edicaoSecaoBlocoTO.listaEdicaoSecaoConteudoTO.forEach(edicaoSecaoConteudoTO => {
							msgBloco.push("<br>&nbsp;&nbsp;&nbsp;&nbsp;" + exibicaoQuestaoVH.exibirNomeClassificacao(edicaoSecaoConteudoTO.exibicaoDisciplinaTO));
						});


						h.push(msgBloco.join(""));
					});

					return h.join("<br>");
				},
				classe: "descricao"
			});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_011"), prop: this.montarPesoSecao, formato: "numero"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_012"), prop: this.montarNumQuestoesSecao});
			colunas.push({titulo: "", prop: this.montarColunaTipos});

			if (edicaoQuestoesProvaTO.labelDificuldades != null) {
				colunas.push({titulo: edicaoQuestoesProvaTO.labelDificuldades, prop: "descricaoDificuldades"});
			}

			if (edicaoQuestoesProvaTO.possuiSecaoOptativa) {
				colunas.push({titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_013"), prop: "isOptativa"});
			}

			if (edicaoQuestoesProvaTO.possuiMsgsPorSecao) {
				colunas.push({titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_063"), prop: "possuiMensagens"});
			}

			if (deveExibirPontuacaoMinima) {
				colunas.push({titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_062"), prop: (exibicaoCadastroSecaoTO) => {
						if (!exibicaoCadastroSecaoTO?.pontuacaoMinima) return;
						return UtilNumero.floatToString(exibicaoCadastroSecaoTO.pontuacaoMinima, 1) + "%";
					}
				});
			}

			if (edicaoQuestoesProvaTO.deveExibirPontuacaoMaxima) {
				let label = this.getMsg("FP_FRONT_CadastroSecaoVH_017");

				if (this.isCfgHabilitada("PONTUACAO_PROPORCIONAL")) {
					label = this.getMsg("FP_FRONT_CadastroSecaoVH_018");
				}

				colunas.push({titulo: label, prop: "pontuacaoMaxima", formato: "numero"});
				totalizar.push("pontuacaoMaxima");
			}

			const numSecoes = edicaoQuestoesProvaTO.secoes.length;

			if (edicaoQuestoesProvaTO.podeAlterar && !edicaoQuestoesProvaTO.isArquivada) {
				colunas.push({
					titulo: "",
					prop: async (secaoTO) => await this.montarBotoesTrSecao(secaoTO, numSecoes)
				});
			}

			if (numSecoes > 5) {
				const secoes = numSecoes + (numSecoes == 1 ? " seção" : " seções");
				this.addTexto(this.getMsg("FP_FRONT_CadastroSecaoVH_021", edicaoQuestoesProvaTO.qtdQuestoesNaProva, secoes))
			}

			await this.addTabela({
				collection: edicaoQuestoesProvaTO.secoes,
				id: "edicao-prova-listagem-secoes",
				propId: "codSecao",
				colunas: colunas,
				ordenar: false,
				totalizar: edicaoQuestoesProvaTO.deveTotalizarPontuacaoMaxima ? totalizar : null,
			});

			if (edicaoQuestoesProvaTO.qtdQuestoesNaProva > 0) {
				this.append(`
					<div class="col-md-12">
				`);
				this.addBotao({
					label: this.getMsg("FP_FRONT_CadastroSecaoVH_022"),
					onClick: async () => await associacaoQuestaoVH.listarQuestoesDaProva(edicaoQuestoesProvaTO.codProva)
				});
				this.append(`
					</div>
				`);
			}

		} else {
			this.addTexto({
				id: 'texto-instrucoes-secoes',
				texto: `
					<br><br>
					${this.getMsg("FP_FRONT_CadastroSecaoVH_023")}
					<a onclick='cadastroSecaoVH.criarNovaSecao()'>${this.getMsg("FP_FRONT_CadastroSecaoVH_024")}</a> ${this.getMsg("FP_FRONT_CadastroSecaoVH_025")}.
				`
			});
		}

		this.addEspacamentoHorizontal("20px");

		this.exibir();
		this.edicaoQuestoesProvaTO = edicaoQuestoesProvaTO;

		if (irParaBuscaQuestoes == true) {
			this.exibirBuscaDeQuestoes(edicaoQuestoesProvaTO.codProva);
		}
		this.exibir();
	}

	async exibirImportacaoArquivoSecoes(codSecao: number){
		let filtroExibicaoRevisaoProvaTO = {
			codProva: this.edicaoQuestoesProvaTO.codProva,
			codQuestao: null,
			idProvaResponsavel: null
		};

		let revisaoProvaTO = await this.call("RevisaoProvaFCD/recuperarRevisaoProvaInteira", filtroExibicaoRevisaoProvaTO);

		if (revisaoProvaTO?.collectionExibicaoProvaTO?.length === 1) {
			let demandaProvaTO = revisaoProvaTO.collectionExibicaoProvaTO[0].demandaProvaTO;
			await importacaoProvaVH.exibirTelaImportacaoQuestoes(demandaProvaTO?.idProvaResponsavel, codSecao, null, null);
		}
	}
	
	montarColunaTipos(exibicaoCadastroSecaoTO) {
		const h = [];

		if (exibicaoCadastroSecaoTO.numQuestoesME > 0) {
			h.push("ME " + exibicaoCadastroSecaoTO.numQuestoesME);
		}
		if (exibicaoCadastroSecaoTO.numQuestoesDiscursivas > 0) {
			h.push("DC " + exibicaoCadastroSecaoTO.numQuestoesDiscursivas);
		}
		if (exibicaoCadastroSecaoTO.numQuestoesCE > 0) {
			h.push("CE " + exibicaoCadastroSecaoTO.numQuestoesCE);
		}
		if (exibicaoCadastroSecaoTO.numQuestoesRespostaExata > 0) {
			h.push("EX " + exibicaoCadastroSecaoTO.numQuestoesRespostaExata);
		}
		
		if (h.length == 0) return;

		return "<small>" + h.join(" | ") + "</small>";
	}

	montarPesoSecao(secaoTO) {
		return UtilNumero.floatToString(secaoTO.peso);
	}

	montarNumQuestoesSecao(exibicaoCadastroSecaoTO) {

		const isSorteioPorBloco = exibicaoCadastroSecaoTO.listaEdicaoSecaoBlocoTO?.some(edicaoSecaoBlocoTO => edicaoSecaoBlocoTO.numQuestoesSorteadas > 0);

		let numItens = 0;
		let detalhes = [];

		if (isSorteioPorBloco) {
			exibicaoCadastroSecaoTO.listaEdicaoSecaoBlocoTO.forEach(edicaoSecaoBlocoTO => {
				if (this.hasValue(edicaoSecaoBlocoTO.numQuestoesSorteadas)) {
					numItens += Math.min(edicaoSecaoBlocoTO.numQuestoesSorteadas, edicaoSecaoBlocoTO.numQuestoesNaSecao);
					detalhes.push(this.getMsg("FP_FRONT_CadastroSecaoVH_026", edicaoSecaoBlocoTO.numBloco, edicaoSecaoBlocoTO.numQuestoesSorteadas, edicaoSecaoBlocoTO.numQuestoesNaSecao));
				} else {
					numItens += edicaoSecaoBlocoTO.numQuestoesNaSecao;
					detalhes.push(this.getMsg("FP_FRONT_CadastroSecaoVH_027", edicaoSecaoBlocoTO.numBloco, edicaoSecaoBlocoTO.numQuestoesNaSecao));
				}
			});
		} else if (this.hasValue(exibicaoCadastroSecaoTO.numQuestoesSorteadas)) {
			numItens = Math.min(exibicaoCadastroSecaoTO.numQuestoesSorteadas, exibicaoCadastroSecaoTO.numQuestoes);
			detalhes.push(this.getMsg("FP_FRONT_CadastroSecaoVH_028", exibicaoCadastroSecaoTO.numQuestoesSorteadas, exibicaoCadastroSecaoTO.numQuestoes));
		} else if (this.hasValue(exibicaoCadastroSecaoTO.numQuestoes)) {
			numItens = exibicaoCadastroSecaoTO.numQuestoes;
		} else {
			return null;
		}
		return numItens + (detalhes.length > 0 ? "<br><small>" + detalhes.join("<br>") + "</small>" : "");
	}

	async excluirSecao(codSecao) {
		await this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_CadastroSecaoVH_029"),
			botoes: [
				{
					label: this.getMsg("MSG_VH_004"),
					classe: "btn-primary",
					onClick: async () => {
						const codProva = await this.call("CadastroProvaFCD/excluirSecao", codSecao);
						this.exibirSecoes(codProva);
					}
				},
				{label: this.getMsg("MSG_VH_005"),}
			],
		});
	}

	exibirBuscaDeQuestoes(codProva) {
		associacaoQuestaoVH.exibirBuscaDeQuestoes(codProva);
	}

	exibirCriacaoQuestao(codProva, idProvaResponsavel) {
		cadastroQuestaoVH.exibirAbasQuestao(1, null, idProvaResponsavel, null, (codQuestao: number) => {
			cadastroProvaVH.exibirAbasProva(2, codProva, codQuestao, idProvaResponsavel).then(() => {}, () => {});
		}).then(() => {}, () => {});
	}

	async verQuestoesDaSecao(link) {
		const codSecao = $(link).closest("tr").attr("identificador");
		await associacaoQuestaoVH.listarQuestoesDaSecao(this.edicaoQuestoesProvaTO.codProva, codSecao);
	}

	criarNovaSecao() {
		this.exibirEdicaoNovaSecao((codProva, codSecaoSalva) => {
			cadastroSecaoVH.exibirSecoes(codProva, codSecaoSalva);
		});
	}

	async reposicionarSecao(to: ExibicaoCadastroSecaoTO, reposicionamento) {
		const codProva = await this.call(
			"CadastroProvaFCD/reposicionarSecao", 
			to.codSecao, 
			to.numSecao, 
			to.numSecao + reposicionamento
		);
		this.exibirSecoes(codProva);
	}

	async exibirEdicaoSecao(codSecao, metodoVHDepoisDeSalvar) {

		const edicaoSecaoTO = await this.call("CadastroProvaFCD/recuperarEdicaoSecao", codSecao);

		this.listaTiposQuestao = edicaoSecaoTO.listaTiposQuestao;

		this.addPopup({
			titulo: edicaoSecaoTO.nome, 
			id: "edicao_secao", 
			width: "1050px", 
			// height: "600px", 
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroSecaoVH_030"), 
				onClick: async () => await this.salvarEdicaoSecao(), 
				classe: "btn-primary"
			}]
		});

		this.setMetodoSubmit(async () => await this.salvarEdicaoSecao());

		this.addFormulario({onEnter: async () => await this.salvarEdicaoSecao()});
		
		this.addCampoTexto({
			id: "secao_edicao_nome",
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_031"),
			valor: edicaoSecaoTO.nome, 
			classe: "col-md-9",
			obrigatorio: true
		});
		
		this.addCampoTexto({
			id: "secao_edicao_peso",
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_032"),
			valor: edicaoSecaoTO.peso, 
			classe: "col-md-3",
			obrigatorio: true,
			tipo: "NUMERO"
		});
		
		this.addCampoTexto({
			id: "secao_edicao_pontuacao_minima",
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_033"),
			valor: UtilNumero.floatToString(edicaoSecaoTO.pontuacaoMinima), 
			classe: "col-md-4", 
			maxLength: "3", 
			sufixo: "%", 
			mascara: "9?9"
		});

		const isSorteioPorBloco = edicaoSecaoTO.listaEdicaoSecaoBlocoTO.some(edicaoSecaoBlocoTO => edicaoSecaoBlocoTO.numQuestoesSorteadas != null);

		this.addCampoTexto({
			id: "secao_edicao_num_sorteio",
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_034"),
			idComponente: "secao_edicao_div_num_sorteio",
			valor: isSorteioPorBloco ? null : edicaoSecaoTO.numQuestoesSorteadas,
			sufixo: this.getMsg("FP_FRONT_CadastroSecaoVH_035", edicaoSecaoTO.numItens), 
			classe: "col-md-3",
			checkboxAtivacao: true,
			onChange: async () => await this.atualizarCamposSorteio()
		});
		
		let isAssociacaoAutomatica = edicaoSecaoTO.listaEdicaoSecaoBlocoTO.some(edicaoSecaoBlocoTO => edicaoSecaoBlocoTO.isAssociacaoAutomatica);
		
		this.append("<div class='row'><div class='col-md-12' div-edicao-blocos>");
			
		this.addSubtitulo({
			texto: this.getMsg("FP_FRONT_CadastroSecaoVH_036"),
			htmlDireita: this.addBotao({
				label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroSecaoVH_039")}`,
				classe: "btn-sm",
				onClick: async () => {
					this.setIdTarget("[edicao-blocos]");
					const idListagem = await this.addSecaoBloco(isAssociacaoAutomatica, {
						numBloco: $("[edicao-bloco]:visible").length + 1,
						listaEdicaoSecaoConteudoTO: [{exibicaoDisciplinaTO: {}}]
					});
					this.exibir();
					$(`#${idListagem} tbody tr`).remove();
					await this.handleNovoConteudoSecao(idListagem);
					await this.atualizarCheckboxesGerais();
				},
				retornarHtml: true
			})
		});

		this.addFormulario();
		this.append("<div class='col-md-12'>");

		this.addCheckbox({
			id: `isRestricaoConteudo`,
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_040"),
			ajuda: this.getMsg("FP_FRONT_CadastroSecaoVH_037"),
			classe: "col-md-12",
			valor: isSorteioPorBloco || edicaoSecaoTO.listaEdicaoSecaoBlocoTO.some(edicaoSecaoBlocoTO => edicaoSecaoBlocoTO.isRestricaoConteudo),
			visivel: edicaoSecaoTO.listaEdicaoSecaoBlocoTO.length > 0,
			habilitado: !isSorteioPorBloco
		});

		this.addCheckbox({
			id: `isAssociacaoAutomatica`,
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_041"),
			ajuda: this.getMsg("FP_FRONT_CadastroSecaoVH_038"),
			classe: "col-md-12",
			valor: isAssociacaoAutomatica,
			visivel: edicaoSecaoTO.listaEdicaoSecaoBlocoTO.length > 0,
			onChange: (event) => {
				isAssociacaoAutomatica = this.getValor("isAssociacaoAutomatica");
				this.atualizarNumQuestoesConteudo(event);
			}
		});
		this.append("</div>");

		this.fecharFormulario();

		this.append("<div class='col-md-12' edicao-blocos>");

		for (const edicaoSecaoBlocoTO of edicaoSecaoTO.listaEdicaoSecaoBlocoTO) {
			await this.addSecaoBloco(isAssociacaoAutomatica, edicaoSecaoBlocoTO);
		}
		
		this.append("</div>");

		this.append("</div></div>");

		this.addSubtitulo(this.getMsg("FP_FRONT_CadastroSecaoVH_042"));

		this.addTexto(this.getMsg("FP_FRONT_CadastroSecaoVH_043"));
		
		this.addCampoTexto({
			id: "inicioFaixaAproveitamento",
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_044"),
			sufixo: "%",
			tipo: "NUMERO",
			valor: edicaoSecaoTO.inicioFaixaAproveitamento,
			classe: "col-md-6"
		});
		
		this.addCampoTexto({
			id: "fimFaixaAproveitamento",
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_045"),
			tipo: "NUMERO",
			sufixo: "%",
			valor: edicaoSecaoTO.fimFaixaAproveitamento,
			classe: "col-md-6"
		});
		
		this.addEditorHTML({id: "mensagemPorFaixa", label: this.getMsg("FP_FRONT_CadastroSecaoVH_046"), valor: edicaoSecaoTO.mensagemPorFaixa, classe: "col-md-12", ajuda: this.getMsg("FP_FRONT_CadastroSecaoVH_047")});

		this.exibirPopups();
		this.edicaoSecaoTO = edicaoSecaoTO;
		this.metodoVHDepoisDeSalvarSecao = metodoVHDepoisDeSalvar;
	}

	async addSecaoBloco(isAssociacaoAutomatica, edicaoSecaoBlocoTO) {
		
		this.append("<div class='col-md-12' edicao-bloco>");
		const idBloco = Date.now();
		const idListagem = `edicao-bloco-${idBloco}`;

		this.addSubtitulo({
			texto: `<span style='font-size: 80%'>${this.getMsg("FP_FRONT_CadastroSecaoVH_048", edicaoSecaoBlocoTO.numBloco)}</span>`,
			htmlDireita: this.addBotao({
				label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroSecaoVH_049")}`,
				classe: "btn-sm",
				onClick: async () => {
					await this.handleNovoConteudoSecao(idListagem);
				},
				retornarHtml: true
			})
		});

		this.append("<div class='row'>");

		this.append("<div class='col-md-3'>");
		this.append("<div class='row'>");

		this.addFormulario();

		await this.addSelect({
			collection: this.listaTiposQuestao,
			valor: edicaoSecaoBlocoTO.tipoQuestao,
			id: `tipoQuestao-${idBloco}`,
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_050"),
			classe: "col-md-12",
			textoOpcaoVazia: "Todos",
			onChange: async (event) => await this.atualizarNumQuestoesConteudo(event)
		});

		this.addCampoTexto({
			id: `secao_edicao_num_sorteio-${idBloco}`,
			label: `<span title='${this.getMsg("FP_FRONT_CadastroSecaoVH_051")}'>${this.getMsg("FP_FRONT_CadastroSecaoVH_052")}</span></small>`,
			isVisivel: null,
			valor: edicaoSecaoBlocoTO.numQuestoesSorteadas,
			sufixo: this.getMsg("FP_FRONT_CadastroSecaoVH_053") + " <span total-de-questoes>" + (isAssociacaoAutomatica ? edicaoSecaoBlocoTO.numQuestoesComCriterios || 0 : edicaoSecaoBlocoTO.numQuestoesNaSecao || 0) + "</span> " + this.getMsg("FP_FRONT_CadastroSecaoVH_054"), 
			classe: "col-md-12",
			checkboxAtivacao: true,
			onChange: async () => await this.atualizarCamposSorteio()
		});

		this.fecharFormulario();

		this.append("</div>");
		this.append("</div>");

		this.append("<div class='col-md-1'>");		
		this.append("</div>");

		this.append("<div class='col-md-8'>");
		this.append("<div class='row'>");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_055"), prop: function(edicaoSecaoConteudoTO) {
				return `<span cod-disciplina='${edicaoSecaoConteudoTO.exibicaoDisciplinaTO.codDisciplina}'>
						${exibicaoQuestaoVH.exibirNomeClassificacao(edicaoSecaoConteudoTO.exibicaoDisciplinaTO)}
					</span>`;
			}
		});
		colunas.push({titulo: "", prop: function(edicaoSecaoConteudoTO) {
				return "<a class='btn btn-default' onclick='cadastroSecaoVH.handleRemocaoSecaoConteudo(event)'><i class='fa fa-trash'></i></a>";
			}
		});
		
		await this.addTabela({
			collection: edicaoSecaoBlocoTO.listaEdicaoSecaoConteudoTO,
			propId: "idSecaoConteudo",
			id: idListagem,
			desabilitarDownload: true,
			colunas: colunas,
		});

		this.append("</div>");
		this.append("</div>");

		this.append("</div>");
		this.append("</div>");

		// this.setIdTarget(null);

		return idListagem;
	}

	handleRemocaoSecaoConteudo(event) {
		const $botao = $(event.target);
		$botao.closest("tr").fadeOut("slow", async () => {
			const numConteudos = $botao.closest("table").find("tbody tr:visible").length;
			if (numConteudos == 0) {
				$botao.closest("[edicao-bloco]").hide();
			} else {
				this.atualizarNumQuestoesConteudo(event);
			}
			await this.atualizarCheckboxesGerais();
		});
	}
	
	async handleNovoConteudoSecao(idListagem) {
		var h = ["<tr><td style='max-width: 400px;'>"];
		const sufixoId = Date.now();
		h.push(await this.addSelect({
			id: "codDisciplina" + sufixoId,
			ignorarTagForm: true,
			classe: "col-md-12",
			retornarHtml: true,
			obrigatorio: true,
			onChange: async (event) => await this.atualizarNumQuestoesConteudo(event),
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarDisciplinasTags",
				numMinimoCaracteres: 3,
			}
		}));
		h.push("</td><td>");
		h.push("<a class='btn btn-default' onclick='cadastroSecaoVH.handleRemocaoSecaoConteudo(event)'><i class='fa fa-trash'></i></a>");
		h.push("</td></tr>");

		$(`#${idListagem} tbody`).append(h.join(""));
		
		this.exibir();
	}

	async atualizarNumQuestoesConteudo(event) {

		if (!event) return;

		const $edicaoBloco = $(event.target).closest("[edicao-bloco]");
		const $table = $edicaoBloco.find("table");
		const listaContagemQuestoesTO = [];

		let tipoQuestao = this.getValor($edicaoBloco.find("[id^=tipoQuestao]"));
		const codsDisciplinas = [];

		if (this.isEmpty(tipoQuestao)) {
			tipoQuestao = null;
		}

		$table.find("tbody tr").each((i, tr) => {
			
			const $tr = $(tr);

			if ($tr.is(":not(:visible)")) return;
			
			let codDisciplina;

			if ($tr.find("[id^=codDisciplina]").length) {
				codDisciplina = this.getValor($tr.find("[id^=codDisciplina]").attr("id"));
			} else {
				codDisciplina = $tr.find("[cod-disciplina]").attr("cod-disciplina");
			}
	
			if (this.hasValue(codDisciplina)) {
				codsDisciplinas.push(codDisciplina);
			}
		});

		const setNumQuestoes = (numQuestoes) => {
			$table.closest("[edicao-bloco]").find("[total-de-questoes]").text(numQuestoes);
		}

		if (codsDisciplinas.length == 0) {
			setNumQuestoes(0);
		} else {
			const numQuestoes = await this.call("CadastroDisciplinaFCD/getNumQuestoes", tipoQuestao, codsDisciplinas);
			setNumQuestoes(numQuestoes);
		}
	}

	atualizarCamposSorteio() {
		let numSorteiosDeBlocoHabilitados = 0;
		$("[id^='secao_edicao_num_sorteio-']").each((i, input) => {
			if (this.isMarcado(input)) {
				numSorteiosDeBlocoHabilitados++;
			}
		});
	
		if (numSorteiosDeBlocoHabilitados > 0) {
			this.setValor("secao_edicao_num_sorteio", "");
			this.setValor("isRestricaoConteudo", true);
			this.disable("secao_edicao_num_sorteio", "isRestricaoConteudo");
		} else {
			this.enable("isRestricaoConteudo");
		}
	}

	async atualizarCheckboxesGerais() {
		if ($("[edicao-bloco]:visible").length > 0) {
			await this.show("isRestricaoConteudo", "isAssociacaoAutomatica");
		} else {
			await this.hide("isRestricaoConteudo", "isAssociacaoAutomatica");
		}
	}

	async salvarEdicaoSecao() {

		this.verificarObrigatorios("#edicao_secao");
		
		var pesoRecebido = this.getValor("secao_edicao_peso")

		if (isNaN(pesoRecebido) && pesoRecebido.indexOf('.') != -1){
			pesoRecebido = pesoRecebido.replace('.', ',')
		}

		pesoRecebido = UtilNumero.stringToFloat(pesoRecebido)

		if (pesoRecebido <= 0){
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroSecaoVH_056") });
			return false;
		}
		
		var numQuestoesSorteadas = this.getValor("secao_edicao_num_sorteio");
		
		if (numQuestoesSorteadas != null && isNaN(numQuestoesSorteadas)) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroSecaoVH_057") });
			return false;
		}
		
		var salvamentoSecaoTO = {
			codProva: this.edicaoSecaoTO.codProva,
			codSecao: this.edicaoSecaoTO.codSecao,
			peso: pesoRecebido,
			pontuacaoMinima: UtilNumero.stringToFloat(this.getValor("secao_edicao_pontuacao_minima")),
			numQuestoesSorteadas: numQuestoesSorteadas,
			isSorteado: this.isMarcado("secao_edicao_num_sorteio"),
			nome: this.getValor("secao_edicao_nome"),
			codDisciplinaConteudo: this.getValor("codDisciplinaConteudo"),
			inicioFaixaAproveitamento: this.getValor("inicioFaixaAproveitamento"),
			fimFaixaAproveitamento: this.getValor("fimFaixaAproveitamento"),
			mensagemPorFaixa: this.getValor("mensagemPorFaixa"),
			idsSecaoConteudoRemovidos: [],
			listaSalvamentoSecaoConteudoTO: []
		}
		
		let contadorBloco = 0;

		const isRestricaoConteudo = this.getValor("isRestricaoConteudo");
		const isAssociacaoAutomatica = this.getValor("isAssociacaoAutomatica");

		$("[edicao-bloco]").each((i, divEdicaoBloco) => {

			const $edicaoBloco = $(divEdicaoBloco);
			const $table = $edicaoBloco.find("table");

			if ($edicaoBloco.is(":visible")) {
				contadorBloco++;
			}

			let tipoQuestao = this.getValor($edicaoBloco.find("[id^='tipoQuestao-']").get(0));
			const numQuestoesSorteadasBloco = this.getValor($edicaoBloco.find("[id^='secao_edicao_num_sorteio-']").get(0));

			if (this.isEmpty(tipoQuestao)) {
				tipoQuestao = null;
			}

			$table.find("tbody tr").each((i, tr) => {
				var $tr = $(tr);
				
				if ($tr.find("td").length <= 1) return;
				
				const idSecaoConteudo = $tr.attr("identificador");
				let codDisciplina = null;

				if (idSecaoConteudo) {
					if ($tr.is(":hidden")) {
						salvamentoSecaoTO.idsSecaoConteudoRemovidos.push(idSecaoConteudo);
					} else {
						codDisciplina = $tr.find("[cod-disciplina]").attr("cod-disciplina");
					}
				} else if ($tr.is(":visible")) {
					codDisciplina = this.getValor($tr.find("select:first"));
				}

				if (codDisciplina != null) {
					var salvamentoSecaoConteudoTO = {
						idSecaoConteudo: idSecaoConteudo,
						codDisciplina: codDisciplina,
						tipoQuestao: tipoQuestao,
						numBloco: contadorBloco,
						isRestricaoConteudo: isRestricaoConteudo,
						isAssociacaoAutomatica: isAssociacaoAutomatica,
						numQuestoesSorteadas: numQuestoesSorteadasBloco
					}
					salvamentoSecaoTO.listaSalvamentoSecaoConteudoTO.push(salvamentoSecaoConteudoTO);
				}
			});
		});
		
		if (salvamentoSecaoTO.inicioFaixaAproveitamento != null || salvamentoSecaoTO.fimFaixaAproveitamento != null || this.hasValue(salvamentoSecaoTO.mensagemPorFaixa)) {
			if (!this.validarCamposObrigatorios("inicioFaixaAproveitamento", "fimFaixaAproveitamento", "mensagemPorFaixa")) {
				return false
			}
			
			if (salvamentoSecaoTO.inicioFaixaAproveitamento >= salvamentoSecaoTO.fimFaixaAproveitamento) {
				await this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_CadastroSecaoVH_058"),
					botoes: [{ label: this.getMsg("MSG_VH_003") }],
				});
				this.focar("fimFaixaAproveitamento");
				return false;
			}
			
		}

		if (salvamentoSecaoTO.isSorteado) {
			if (!this.validarCamposObrigatorios("secao_edicao_num_sorteio")) {
				return false;
			}
		}

		const idSP = await this.call("CadastroProvaFCD/salvarSecao", salvamentoSecaoTO);

		eval(this.metodoVHDepoisDeSalvarSecao + "(" + salvamentoSecaoTO.codProva + ")");

		this.fecharPopup("edicao_secao");
		this.fecharPopup("confirmacao-secao-conteudo");

		if (idSP) {

			// this.exibirAlerta("A associação automática de questões está sendo processada neste momento. Volte em alguns instantes a esta tela para poder verificar o resultado.")
			
			UtilSP.criarToastAguardar({
				idSP,
				toastAguardando: {
					titulo: `Seção <strong>${salvamentoSecaoTO.nome}</strong>`,
					texto: `Associação automática`,
					onClick: null
				},
				toastFinalizada: {
					titulo: null,
					texto: "Ver questões",
					onClick: () => {
						cadastroProvaVH.exibirAbasProva("1", salvamentoSecaoTO.codProva);
					}
				}
			});
		}

		return false;
	}
	
	exibirEdicaoNovaSecao(onSecaoCriada) {

		const salvar = async () => {

			if (!this.validarCamposObrigatorios("painel_novasecao_nome")) {
				return false;
			}

			let salvamentoNovaSecaoTO: any = {
				codProva: this.edicaoQuestoesProvaTO.codProva,
				nomeSecao: this.getValor("painel_novasecao_nome"),
			}

			salvamentoNovaSecaoTO = await this.call("CadastroProvaFCD/salvarNovaSecao", salvamentoNovaSecaoTO);

			onSecaoCriada(salvamentoNovaSecaoTO.codProva, salvamentoNovaSecaoTO.codSecaoSalva, salvamentoNovaSecaoTO.nomeSecao);

			this.fecharPopup("painel_novasecao");
			return false;
		}

		this.addPopup({
			id: "painel_novasecao",
			data: {codProva: this.edicaoQuestoesProvaTO.codProva},
			titulo: this.getMsg("FP_FRONT_CadastroSecaoVH_059"),
			width: "400px",
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroSecaoVH_060"), 
				classe: "btn-primary", 
				onClick: salvar
			}]
		});

		this.setMetodoSubmit(salvar);
		this.addCampoTexto({
			id: "painel_novasecao_nome",
			label: this.getMsg("FP_FRONT_CadastroSecaoVH_061"), classe: "col-md-12"
		});

		this.exibirPopups()
		this.focar("painel_novasecao_nome");
	}
	
	async exibirTelaBloqueioQuestoes(codProva) {

		const edicaoBloqueioQuestoesTO = await this.call("CadastroProvaFCD/editarBloqueioQuestoes", codProva);

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CadastroProvaVH_175"),
			botoes: [{
				label: `<i class='fa fa-lock-open'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_176")}`,
				visivel: edicaoBloqueioQuestoesTO.codConteudoBloqueio != null,
				onClick: async (event) => {
					await this.call("CadastroProvaFCD/desbloquearQuestoes", edicaoBloqueioQuestoesTO.codProva);
					cadastroProvaVH.exibirAbasProva(1, edicaoBloqueioQuestoesTO.codProva);
				}
			}, {
				label: edicaoBloqueioQuestoesTO.codConteudoBloqueio ? this.getMsg("FP_FRONT_CadastroProvaVH_177") : `<i class='fa fa-lock'></i> ${this.getMsg("FP_FRONT_CadastroProvaVH_178")}`,
				classe: "btn-primary",
				onClick: async (event) => {

					this.verificarObrigatorios(event.target);

					var salvamentoBloqueioQuestoesTO = {
						codConteudoBloqueio: edicaoBloqueioQuestoesTO.codConteudoBloqueio,
						codProva: edicaoBloqueioQuestoesTO.codProva,
						dataFim: this.getValor("periodo_fim"),
					}

					await this.call("CadastroProvaFCD/bloquearQuestoes", salvamentoBloqueioQuestoesTO);
					cadastroProvaVH.exibirAbasProva(1, edicaoBloqueioQuestoesTO.codProva);
				}
			}]
		});

		if (edicaoBloqueioQuestoesTO.codConteudoBloqueio) {
			this.addTexto(this.getMsg("FP_FRONT_CadastroProvaVH_179", edicaoBloqueioQuestoesTO.nomeUsuarioCriacao, UtilData.toDDMMYYYYHHMM(edicaoBloqueioQuestoesTO.dataInicio)));
		} else {
			this.addTexto(this.getMsg("FP_FRONT_CadastroProvaVH_180"));
		}
		this.addTexto(this.getMsg("FP_FRONT_CadastroProvaVH_181"));

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroProvaVH_182"),
			id: "periodo_fim",
			valor: edicaoBloqueioQuestoesTO.dataFim,
			classe: "col-md-12",
			tipo: "DATA"
		});

		this.exibirPopups();
	}

	async montarBotoesTrSecao(to: ExibicaoCadastroSecaoTO, numSecoes: number) {

		if (this.isEmpty(to?.numSecao)) return;

		const h = [`
			<div class="col-md-12" style="min-width: 180px">
		`];

		// if (numSecoes > 1) {
		// 	h.push(`
		// 		<a class="btn btn-lg btn-link pull-right" title="Arraste para reposicionar" style="padding: 3px 0px 0px 15px">
		// 			<i class="fa fa-grip-horizontal text-muted" style="opacity: 0.5"></i>
		// 		</a>
		// 	`);
		// }
		h.push(`
				<div class="btn-group pull-right" style="display: inline-flex">
		`);

		if (to.numSecao > 1) {
			h.push(this.addBotao({
				dica: "Subir",
				classe: "btn-sm",
				label: `<i class="fa fa-chevron-up"></i>`,
				onClick: async () => this.reposicionarSecao(to, -1),
				retornarHtml: true
			}));
			// h.push(`
			// 		<a class="btn btn-sm btn-default" onclick="cadastroSecaoVH.reposicionarSecao(this, -1)" title="Subir">
						
			// 		</a>
			// `);
		}

		if (to.numSecao < numSecoes) {
			h.push(this.addBotao({
				dica: "Descer",
				classe: "btn-sm",
				label: `<i class="fa fa-chevron-down"></i>`,
				onClick: async () => this.reposicionarSecao(to, 1),
				retornarHtml: true
			}));
			// h.push(`
			// 		<a class="btn btn-sm btn-default" onclick="cadastroSecaoVH.reposicionarSecao(this, 1)" title="Descer">
			// 			<i class="fa fa-chevron-down"></i>
			// 		</a>
			// `);
		}
		h.push(this.addBotao({
			dica: this.getMsg("FP_FRONT_CadastroSecaoVH_019"),
			classe: "btn-sm btn-alterar-secao",
			label: `<i class="fa fa-pencil"></i>`,
			onClick: async () => {
				cadastroSecaoVH.exibirEdicaoSecao(to.codSecao, 'cadastroSecaoVH.exibirSecoes');
			},
			retornarHtml: true
		}));
		h.push(this.addBotao({
			dica: this.getMsg("FP_FRONT_CadastroSecaoVH_020"),
			classe: "btn-sm",
			label: `<i class="fa fa-trash"></i>`,
			onClick: async () => {
				cadastroSecaoVH.excluirSecao(to.codSecao);
			},
			retornarHtml: true
		}));
		h.push(this.addBotao({
			dica: this.getMsg("FP_FRONT_RevisaoProvaVH_029"),
			classe: "btn-sm importacao-questoes-secao",
			label: `<i class="fa fa-upload"></i>`,
			onClick: async () => {
				cadastroSecaoVH.exibirImportacaoArquivoSecoes($(this).closest('tr').attr('identificador'));
			},
			retornarHtml: true
		}));
		// h.push(`
		// 			<a class="btn btn-sm btn-default btn-alterar-secao" onclick="cadastroSecaoVH.exibirEdicaoSecao($(this).closest('tr').attr('identificador'), 'cadastroSecaoVH.exibirSecoes')">
		// 				<i class="fa fa-pencil" title="${this.getMsg("FP_FRONT_CadastroSecaoVH_019")}"></i>
		// 			</a>
		// 			<a class="btn btn-sm btn-default" onclick="cadastroSecaoVH.excluirSecao($(this).closest('tr').attr('identificador'))">
		// 				<i class="fa fa-trash" title="${this.getMsg("FP_FRONT_CadastroSecaoVH_020")}"></i>
		// 			</a>
		// 			<a class="btn btn-sm btn-default importacao-questoes-secao" title="${this.getMsg("FP_FRONT_RevisaoProvaVH_029")}" onclick="cadastroSecaoVH.exibirImportacaoArquivoSecoes($(this).closest('tr').attr('identificador'))">
		// 				<i class="fa fa-upload"></i>
		// 			</a>
		// `);
		
		h.push(`
				</div>
			</div>
		`);

		return h.join("");
	}
}

const cadastroSecaoVH = new CadastroSecaoVH();

type EdicaoQuestoesProvaTO = {
	codProva: number;
	titulo: string;
	qtdQuestoesNaProva: number;
	labelDificuldades: string;
	possuiSecaoOptativa: boolean;
	possuiMsgsPorSecao: boolean;
	deveExibirPontuacaoMaxima: boolean;
	deveTotalizarPontuacaoMaxima: boolean;
	isArquivada: boolean;
	isQuestoesBloqueadas: boolean;
	podeAlterar: boolean;
	demandaProvaTO: object;
	idProvaResponsavel;
	secoes: ExibicaoCadastroSecaoTO[];
}

type ExibicaoCadastroSecaoTO = {
	codProva: number;
	codSecao: number;
	numSecao: number;
	peso: number;
	numQuestoesSorteadas: number;
	isSorteado: boolean;
	nome: string;
	codProfessorResponsavel: number;
	descricaoDificuldades: string;
	numQuestoes: number;
	pontuacaoMinima: number;
	pontuacaoMaxima: number;
	isOptativa: boolean;
	possuiMensagens: boolean;
	numQuestoesRespostaExata: number;
	numQuestoesCE: number;
	numQuestoesME: number;
	numQuestoesDiscursivas: number;
	listaEdicaoSecaoBlocoTO: EdicaoSecaoBlocoTO[];
}

type EdicaoSecaoBlocoTO = {
	numBloco: number;
	numQuestoesComCriterios: number;
	numQuestoesSorteadas: number;
	numQuestoesNaSecao: number;
	isRestricaoConteudo: boolean;
	isAssociacaoAutomatica: boolean;
	nomeTipoQuestao: string;
	tipoQuestao: QuestaoTipo;
	tipoQuestaoResumido: string;
	listaEdicaoSecaoConteudoTO: object[];
}