class CronoCorrecaoDiscursivaVH {
    worker: Worker;
    $tempoElement: any;
    callback: Function;
    milisegundosRestantes: number;
    isTempoAcabando: boolean = false;

    cronoListener: Function = (msDecorridos) => {
        this.handleCronoTick(msDecorridos);
    }

    iniciarCronometro(milisegundosRestantes, $tempoElement, callback: Function) {
        this.pararCronometro();

        this.milisegundosRestantes = milisegundosRestantes;
        this.$tempoElement = $tempoElement;
        this.callback = callback;
        this.isTempoAcabando = false;

        UtilCrono.adicionarListener(this.cronoListener);
    }

    async handleCronoTick(msDecorridos: number) {
        if (!this.$tempoElement.is(':visible')) {
            this.pararCronometro();
        }

        if (msDecorridos == null || msDecorridos < 1) return;

        const tempoFormatado = UtilTempo.getTempoFormatado(this.milisegundosRestantes);
        this.$tempoElement.text(tempoFormatado);

        if (!this.isTempoAcabando && Math.round(this.milisegundosRestantes/1000) <= 60) {
            this.$tempoElement.removeClass('label-info');
            this.$tempoElement.addClass('label-danger');
            this.isTempoAcabando = true;
        }

        if (this.isTempoEsgotado()) {
            this.pararCronometro();
            this.callback();
        }

        this.milisegundosRestantes -= msDecorridos;
    }

    isTempoEsgotado(): boolean {
        return this.milisegundosRestantes <= 0;
    }

    pararCronometro() {
        UtilCrono.removerListener(this.cronoListener);
    }
}
