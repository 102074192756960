class SafeBrowserVH extends AmaisVH {
	
	constructor() {
		super(SafeBrowserVH.name);
		this.addOperacaoParaHash("seb", this.exibirInstrucoesInstalacao);
	}
	
	async exibirInstrucoesInstalacao() {
		
		UtilHash.registrarHistorico(this.exibirInstrucoesInstalacao);

		const infosInstalacaoSafeBrowserTO = await this.call("SafeBrowserFCD/recuperarInfosInstalacao");

		this.limpar();
		this.setTitulo("Acesso pelo navegador seguro");	

		this.append(`
			<div class="col-md-3"></div>
			<div class="col-md-6" style="font-size: 150%">
				<p>
					Para iniciar esta prova é necessário acessar utilizando o navegador <strong>Safe Exam Browser</strong>. Siga os seguintes passos:
				</p>
				<br>
				<ol>
					<li>
						<p>
							<a href="${infosInstalacaoSafeBrowserTO.pathDownloadSafeBrowserWindows}" target="_blank">Baixe</a>
							e instale o navegador.
						</p>
					</li>
					<li>
						<p>
							<a href="${infosInstalacaoSafeBrowserTO.pathArquivoSEB}" target="_blank">Baixe</a> 
							e abra o arquivo da sua prova. A senha para abrir este arquivo é 
						</p>
						<p>
							<strong>${infosInstalacaoSafeBrowserTO.senhaArquivoSEB}</strong>
						</p>
					</li>
					<li>
						Informe seus dados de login e boa prova!
					</li>
				</ol>
				<br>
				<br>
				<small>
					Caso esteja utilizando o MacOS ou iOS, 
					<a href="${infosInstalacaoSafeBrowserTO.pathDownloadSafeBrowser}">baixe e instale o navegador por aqui</a>
					.
				</small>
			</div>
			<div class="col-md-3"></div>
		`);

		this.exibir();
	}
}

const safeBrowserVH = new SafeBrowserVH();