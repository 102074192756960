class RelatoriosImportacaoVH extends AmaisVH {
	
	listas: any;
	collectionClassificadores: null;
	relatorioClassificacoesPorProvaTO: any;
	filtrosSelecaoQuestoesTO: any;

	constructor() {
		super(RelatoriosImportacaoVH.name);
		this.addOperacaoParaHash("riid", this.exibirRelatorioImportacaoPorDisciplina);
		this.addOperacaoParaHash("rird", this.exibirRevisaoPorDisciplina);
		this.addOperacaoParaHash("rilp", this.listarProvas);	
	}

	async exibirRelatorioImportacaoPorDisciplina() {

		UtilHash.registrarHistorico(this.exibirRelatorioImportacaoPorDisciplina);

		const collectionRelatorioImportacaoPorDisciplinaTO = await this.call("RelatoriosImportacaoFCD/recuperarRelatorioImportacaoPorDisciplina", true, true);
	
		this.limpar()
		this.setTitulo(this.getMsg("MSG_VH_RI_01"))

		this.addLink({
			label: this.getMsg("MSG_VH_RI_02"),
			onClick: () => this.exibirRelatorioImportacaoDisciplinasPublicas(),
			idAlvo: "tituloSuperiorDireito"
		})

		this.addLink({
			label: this.getMsg("MSG_VH_RI_03"),
			onClick: () => this.exibirRelatorioImportacaoDisciplinasNaoPublicas(),
			idAlvo: "tituloSuperiorDireito"
		})


		this.addLink({
			label: this.getMsg("MSG_VH_RI_04"),
			onClick: () => this.exibirRelatorioImportacaoClassificacoesQuestaoAVerificar(),
			idAlvo: "tituloSuperiorDireito"
		})

		this.append("<div id='listagem_relatorio' style='float: left'></div>")

		this.exibir();

		await this.exibirListagemPorDisciplinas(collectionRelatorioImportacaoPorDisciplinaTO, this.getMsg("MSG_VH_RI_05"))
	}

	async exibirRelatorioImportacaoDisciplinasPublicas() {
		const collectionRelatorioImportacaoPorDisciplinaTO = await this.call("RelatoriosImportacaoFCD/recuperarRelatorioImportacaoPorDisciplina", true, true);
		await this.exibirListagemPorDisciplinas(collectionRelatorioImportacaoPorDisciplinaTO, this.getMsg("MSG_VH_RI_06"))
	}

	async exibirListagemPorDisciplinas(collectionRelatorioImportacaoPorDisciplinaTO, titulo) {
		this.setHtml("listagem_relatorio", "")
		this.setIdTarget("listagem_relatorio")
		this.setSubtitulo(titulo)

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: "descricao", classe: "descricao"});
		colunas.push({titulo: this.getMsg("MSG_VH_RI_07"), prop: "numQuestoes"});
		colunas.push({titulo: this.getMsg("MSG_VH_RI_08"), prop: this.montarColunaClassificadas});
		colunas.push({titulo: this.getMsg("MSG_VH_RI_09"), prop: "numQuestoesAClassificar"});

		await this.addTabela({
			collection: collectionRelatorioImportacaoPorDisciplinaTO,
			propId: "codDisciplina",
			colunas: colunas,
			onEdicao: this.exibirRevisaoPorDisciplina,
			totalizar: ["numQuestoes", "numQuestoesAClassificar"]
		})

		this.exibir();
		this.setIdTarget(null)
	}

	exibirRevisaoPorDisciplina(codDisciplina) {

		UtilHash.registrarHistorico(this.exibirRevisaoPorDisciplina, codDisciplina);

		revisaoProvaVH.exibirQuestoesNaoClassificadas(codDisciplina)
	}

	montarColunaClassificadas(relatorioImportacaoPorDisciplinaTO) {
		if (relatorioImportacaoPorDisciplinaTO.numQuestoesAClassificar != null) {
			return UtilProgressBar.basic({
				evolucao: relatorioImportacaoPorDisciplinaTO.numQuestoes - relatorioImportacaoPorDisciplinaTO.numQuestoesAClassificar, 
				total: relatorioImportacaoPorDisciplinaTO.numQuestoes
			})
		} else {
			return ""
		}
	}

	async exibirRelatorioImportacaoDisciplinasNaoPublicas() {
		const collectionRelatorioImportacaoPorDisciplinaTO = await this.call("RelatoriosImportacaoFCD/recuperarRelatorioImportacaoPorDisciplina", true, false);
		await this.exibirListagemPorDisciplinas(collectionRelatorioImportacaoPorDisciplinaTO, this.getMsg("MSG_VH_RI_10"));
	}

	async exibirRelatorioImportacaoClassificacoesQuestaoAVerificar() {
		const collectionRelatorioImportacaoPorDisciplinaTO = await this.call("RelatoriosImportacaoFCD/recuperarRelatorioImportacaoPorDisciplina", false, false);
		await this.exibirListagemPorDisciplinas(collectionRelatorioImportacaoPorDisciplinaTO, this.getMsg("MSG_VH_RI_11"))
	}

	async exibirRelatorioClassificacoes() {

		if (this.collectionClassificadores == null) {
			const collectionClassificadores = await this.call("RelatoriosImportacaoFCD/recuperarClassificadores");
			this.setCollectionClassificadores(collectionClassificadores);
			return;
		}

		const relatorioClassificacoesPorProvaTO = await this.call("RelatoriosImportacaoFCD/recuperarClassificacoesPorProva", {});

		this.limpar()
		this.setTitulo(this.getMsg("MSG_VH_RI_12"))

		if (!this.isAdministrador()) {
			this.setSubtitulo(this.getMsg("MSG_VH_RI_13"))
		} else {
			this.setSubtitulo(this.getMsg("MSG_VH_RI_14") + " " + this.getNomeUsuarioLogado())
		}

		await this.addSelect({
			collection: this.collectionClassificadores,
			id: "filtro_revisor",
			visivel: this.isAdministrador(),
			onChange: async () => await this.atualizarRelatorioClassificacoes(),
			// width: "300px",
			label: this.getMsg("MSG_VH_RI_15")
		})

		this.addCamposPeriodo({
			idInicio: "filtro_data_inicio",
			idFim: "filtro_data_fim",
			width: "300px",
			label: this.getMsg("MSG_VH_RI_16"),
			onChange: async () => await this.atualizarRelatorioClassificacoes()
		})

		this.append("<div id='listagem_classificacoes' style='float: left;'>")

		await this.montarListagemClassificacoes(relatorioClassificacoesPorProvaTO)

		this.append("</div>")

		this.addLink({
			onClick: () => this.exibirQuestoesClassificadasDoRevisor(),
			label: this.getMsg("MSG_VH_RI_17"),
			css: "float: right"
		})

		this.exibir();
	}

	setCollectionClassificadores(collectionClassificadores) {
		this.collectionClassificadores = collectionClassificadores
		this.exibirRelatorioClassificacoes()
	}

	async montarListagemClassificacoes(relatorioClassificacoesPorProvaTO) {
		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_RI_18"), prop: "tituloProva"});
		colunas.push({titulo: this.getMsg("MSG_VH_RI_19"), prop: "numClassificacoes"});

		await this.addTabela({
			collection: relatorioClassificacoesPorProvaTO.collectionClassificacoesPorProvaTO,
			propId: "codProva",
			colunas: colunas,
			totalizar: ["numClassificacoes"],
			ordenar: false,
			onEdicao: [async (codProva) => await this.exibirQuestoesClassificadasDoRevisor(codProva)]
		})
		this.relatorioClassificacoesPorProvaTO = relatorioClassificacoesPorProvaTO
	}

	async atualizarRelatorioClassificacoes() {

		const filtrosClassificacoesPorProvaTO = {
			codUsuario: this.getValor("filtro_revisor"),
			dataInicio: this.getValor("filtro_data_inicio"),
			dataFim: this.getValor("filtro_data_fim")
		}
		
		const relatorioClassificacoesPorProvaTO = await this.call("RelatoriosImportacaoFCD/recuperarClassificacoesPorProva", filtrosClassificacoesPorProvaTO)

		this.setHtml("listagem_classificacoes", "");
		this.setIdTarget("listagem_classificacoes");
		await this.montarListagemClassificacoes(relatorioClassificacoesPorProvaTO);
		this.setIdTarget(null);
		this.exibir();
	}

	exibirQuestoesClassificadasDoRevisor(codProva?: number) {
		const codsProvas = []
		let tituloPagina = this.getMsg("MSG_VH_RI_20")

		if (this.relatorioClassificacoesPorProvaTO.nomeRevisor != null) {
			tituloPagina = this.relatorioClassificacoesPorProvaTO.nomeRevisor
		}

		if (codProva != null) {
			codsProvas.push(codProva)
		}

		for (const classificacoesPorProvaTO of this.relatorioClassificacoesPorProvaTO.collectionClassificacoesPorProvaTO) {
			if (this.isEmpty(codProva)) {
				codsProvas.push(classificacoesPorProvaTO.codProva)
			} else if (classificacoesPorProvaTO.codProva == codProva) {
				tituloPagina += " (" + classificacoesPorProvaTO.tituloProva + ")"
			}
		}

		const codUsuario = this.relatorioClassificacoesPorProvaTO.codUsuario
		const dataInicio = UtilData.toDDMMYYYY(this.relatorioClassificacoesPorProvaTO.dataInicio)
		const dataFim = UtilData.toDDMMYYYY(this.relatorioClassificacoesPorProvaTO.dataFim)

		this.chamarMetodoVHEmNovaPagina("revisaoProvaVH.exibirClassificacoes([" + codsProvas + "]," + codUsuario + ",'" + dataInicio + "','" + dataFim + "');", tituloPagina)
	}

	async listarProvas() {

		UtilHash.registrarHistorico(this.listarProvas);
		
		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_RI_21"));
		
		this.listas = await this.call("CadastroConcursoFCD/recuperarListagens");

		await selecaoQuestoesVH.montarHtmlFiltros({subtitulo: this.getMsg("MSG_VH_RI_22"), onBuscar: async () => await relatoriosImportacaoVH.listarProvasComFiltro()});
		
		this.addBotao({label: this.getMsg("MSG_VH_RI_23") + " <i class='fa fa-search'></i>", onClick: () => this.listarProvasComFiltro(), classe: "btn-primary", css: "float: right"});
		
		this.append("<div style='float: left; width: 100%; margin: 10px 0px 0px 0px' id='resultado_busca_provas_revisao'></div>");

		this.exibir();
	}
	
	async listarProvasComFiltro() {

		this.filtrosSelecaoQuestoesTO = selecaoQuestoesVH.getFiltrosSelecaoQuestoesTO() || {};
		const collectionExibicaoNumQuestoesPorProvaTO = await this.call("RelatoriosImportacaoFCD/listarRevisaoProvas", this.filtrosSelecaoQuestoesTO);

		this.setIdTarget("resultado_busca_provas_revisao");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_RI_24"), prop: "tituloProva"});
		colunas.push({titulo: this.getMsg("MSG_VH_RI_25"), prop: "numQuestoes"});
		colunas.push({titulo: this.getMsg("MSG_VH_RI_26"), prop: "ano"});

		await this.addTabela({
			collection: collectionExibicaoNumQuestoesPorProvaTO,
			propId: "codProva",
			colunas: colunas,
			onEdicao: async (codProva) => await cadastroProvaVH.exibirAbasProva(2, codProva),
			propIsExcluivel: "isExcluivel"
		});
		this.setIdTarget(null);
		this.setHtml("resultado_busca_provas_revisao", "");
		this.exibir();
	}
}

const relatoriosImportacaoVH = new RelatoriosImportacaoVH();