class FiscalizacaoMonitoradaVH extends AmaisVH {

	proctoringService: ProctoringService;
	inicioFiscalizacaoMonitoradaTO: InicioFiscalizacaoMonitoradaTO;
	fiscalizacaoMonitoradaTO: FiscalizacaoMonitoradaTO;

	constructor() {
		super(FiscalizacaoMonitoradaVH.name);
	}
	
	// ----------------------------------------------------------------------------------------------
	// FUNÇÕES PÚBLICAS
	// ----------------------------------------------------------------------------------------------

	async iniciarMonitoramento(inicioFiscalizacaoMonitoradaTO: InicioFiscalizacaoMonitoradaTO) {

		if (this.isEmpty(inicioFiscalizacaoMonitoradaTO.codAgendamentoUsuario) 
			&& this.isEmpty(inicioFiscalizacaoMonitoradaTO.codProvaFeita)) {

			throw new Error("Chamada para iniciar monitoramento sem codProvaFeita e codAgendamentoUsuario: " + JSON.stringify(inicioFiscalizacaoMonitoradaTO));
		}
		
		this.inicioFiscalizacaoMonitoradaTO = inicioFiscalizacaoMonitoradaTO;

		try {

			const fiscalizacaoMonitoradaTO: FiscalizacaoMonitoradaTO = await this.call("FiscalizacaoMonitoradaFCD/iniciar", inicioFiscalizacaoMonitoradaTO.codAgendamentoUsuario, inicioFiscalizacaoMonitoradaTO.codProvaFeita);

			this.fiscalizacaoMonitoradaTO = fiscalizacaoMonitoradaTO;

			if (!fiscalizacaoMonitoradaTO.isHabilitado) return;
		
			this.proctoringService = ProctoringService.getInstance(fiscalizacaoMonitoradaTO);

			this.proctoringService.onMsgChat((e: EventoMsgChat) => {
				this.handleEventoMsgChat(e);
			});
			this.proctoringService.onInterrupcao(() => {
				this.inicioFiscalizacaoMonitoradaTO.onMonitoramentoInterrompido();
			});
			this.proctoringService.onErro((e: EventoErro) => {
				this.handleEventoErro(e);
			});
			this.proctoringService.onFinalizado((e: EventoErro) => {
				this.handleMonitoramentoFinalizado(e);
			});
			this.proctoringService.onMsgLog((e: EventoMsgLog) => {
				this.handleEventoMsgLog(e);
			});

			this.exibirTelaPasso1(fiscalizacaoMonitoradaTO);

		} catch (e) {

			const msg = e?.responseBody?.msgErro || this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_001");
			
			await this.exibirAlerta({ msg: msg });
			UtilHash.carregarTelaInicial();
		}
	}
	
	isIniciada(): boolean {
		return this.proctoringService?.isIniciado();
	}

	async pararMonitoramento() {
		try {
			if (this.proctoringService) {
				await this.proctoringService.parar();
			}
		} catch (e) {
			console.log("Erro ao chamar fiscalizacaoMonitoradaVH.pararMonitoramento()", e);
		}
		
		try {
			this.esconderBotaoChat();
			$("#container-chat-fiscalizacao-monitorada").remove();
		} catch (e) {
			console.log("Erro ao remover chat", e);
		}
	}

	// ----------------------------------------------------------------------------------------------
	// FUNÇÕES INTERNAS
	// ----------------------------------------------------------------------------------------------
	
	async handleMonitoramentoIniciado() {
		await this.inicioFiscalizacaoMonitoradaTO.onMonitoramentoIniciado();
		this.incluirChat();
	}
	
	handleEventoErro(e: EventoErro) {
		this.inicioFiscalizacaoMonitoradaTO.onErroMonitoramento(
			e.msgUsuario, 
			e.msgAuditoria, 
			e.msgIntegracaoAuditoria, 
			this.inicioFiscalizacaoMonitoradaTO.codProvaFeita, 
			this.inicioFiscalizacaoMonitoradaTO.codAgendamentoUsuario
		);
		this.esconderBotaoChat();
	}

	handleMonitoramentoFinalizado(e: EventoErro) {
		this.inicioFiscalizacaoMonitoradaTO.onMonitoramentoFinalizado(
			e.msgUsuario,
			e.msgAuditoria,
			e.msgIntegracaoAuditoria,
			this.inicioFiscalizacaoMonitoradaTO.codProvaFeita,
			this.inicioFiscalizacaoMonitoradaTO.codAgendamentoUsuario
		);
		this.esconderBotaoChat();
	}
	
	handleEventoMsgLog(e: EventoMsgLog) {
		this.inicioFiscalizacaoMonitoradaTO.onEventoParaRegistroEmLog(
			e.msg, 
			e.msgIntegracao, 
			this.inicioFiscalizacaoMonitoradaTO.codProvaFeita, 
			this.inicioFiscalizacaoMonitoradaTO.codAgendamentoUsuario
		);
	}
	
	handleEventoMsgChat(e: EventoMsgChat) {
		this.inserirMensagemChat(this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_002"), e.msg, 'left', 'right');
		this.exibirBotaoChat();
	}
	
	exibirTelaPasso1(fiscalizacaoMonitoradaTO: FiscalizacaoMonitoradaTO) {

		const hasTermoLGPD = fiscalizacaoMonitoradaTO.termoLGPD !== null;

		this.limpar();
		
		this.setTitulo(fiscalizacaoMonitoradaTO.tituloProva);
		this.setSubtitulo(this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_003"));
		
		if (hasTermoLGPD) {
			this.append(`
				<div class="row" id="termo-lgpd">
					<div class="col-md-12">
						${fiscalizacaoMonitoradaTO.termoLGPD}
					</div>
			`);
			this.addBotao({
				id: "botao_aceite_termo_lgpd",
				label: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_017"),
				classe: "btn-md btn-success",
				css: "float: right;",
				onClick: async () => {
					await this.show("container-inicio-proctoring");
					await this.hide("termo-lgpd");
					this.handleEventoMsgLog({
						msg: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_018"),
						msgIntegracao: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_019")
					});
				}
			});
			this.append("</div>");
		}

		this.append(`
			<div class="row" id="container-inicio-proctoring" ${hasTermoLGPD? "style='display:none;'": ""}>
				<div class="col-md-12">
					${this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_004")}
				</div>
		`);
		this.addEspacamentoHorizontal("10px");
		this.append("<div class='btn-group col-md-12'>");

		this.addBotao({
			id: "botao_iniciar_proctoring",
			label: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_005"),
			classe: "btn-lg col-md-6 btn-success",
			onClick: async () => {
				this.disable('botao_iniciar_proctoring');
				try {
					await this.proctoringService.pedirAutorizacoesUsuario(() => {

						if (!document.getElementById("container-inicio-proctoring")) {
							this.logger.info("Callback do pedirAutorizacoesUsuario chamado mas não está na tela de início do proctoring. Abortando callback.");
							return;
						}

						this.exibirTelaPasso2(fiscalizacaoMonitoradaTO);

						this.handleEventoMsgLog({
							msg: "Autorizações para o serviço de fiscalização monitorada foram concedidas.",
							msgIntegracao: null
						});
						this.enable('botao_iniciar_proctoring');
					});
				} catch (e) {
					this.logger.error("Erro ao solicitar autorizações do usuário", e);
					this.enable('botao_iniciar_proctoring');
				}
			}
		});

		this.addBotao({
			id: "botao_iniciar_prova_monitorada",
			label: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_006"),
			classe: "btn-lg col-md-6",
			onClick: () => {},
		});

		this.append(`
				</div>
			</div>
		`);

		this.addEspacamentoHorizontal("20px");

		if (fiscalizacaoMonitoradaTO.msgInicioMonitoramento) {
			this.append("<div class='col-md-12'>" + fiscalizacaoMonitoradaTO.msgInicioMonitoramento + "</div>");
		}
		
		this.exibir();
	}
	
	exibirTelaPasso2(fiscalizacaoMonitoradaTO) {
		
		this.limpar();
		this.setTitulo(fiscalizacaoMonitoradaTO.tituloProva);
		this.setSubtitulo(this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_007"));
		this.append("<div class='row'>");
		this.append("<div class='col-md-12'>");
		this.addTexto(this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_008"));
		this.append("</div>");
		this.addEspacamentoHorizontal("10px");
		this.append("<div class='btn-group col-md-12'>");
		this.addBotao({
			id: "botao_iniciar_proctoring",
			label: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_009"),
			classe: "btn-lg col-md-6",
			onClick: () => {},
		});
		this.addBotao({
			id: "botao_iniciar_prova_monitorada",
			label: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_010"),
			classe: "btn-lg col-md-6 btn-success",
			onClick: async () => {
				this.disable('botao_iniciar_prova_monitorada');
				try {
					this.proctoringService.iniciarGravacao();
					await this.handleMonitoramentoIniciado();
					this.handleEventoMsgLog({
						msg: "Gravação no serviço de fiscalização monitorada foi iniciada.", 
						msgIntegracao: null
					});

					this.enable('botao_iniciar_prova_monitorada');
				} catch (error) {
					this.handleEventoErro({
						msgUsuario: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_011") + (typeof error == "object" ? JSON.stringify(error) : error),
						msgAuditoria: this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_011"),
						msgIntegracaoAuditoria: error
					});

					this.enable('botao_iniciar_prova_monitorada');
				}
			}
		});
		this.append("</div>");
		this.append("</div>");
		this.addEspacamentoHorizontal("20px");
		if(fiscalizacaoMonitoradaTO.msgInicioProva){
			this.append("<div class='col-md-12'>" + fiscalizacaoMonitoradaTO.msgInicioProva + "</div>");
		}
		this.exibir();
	}

	exibirChat() {
		$('#chat-panel').show();
	}

	fecharChat() {
		$('#chat-panel').hide();
	}

	incluirChat() {

		let chat = [];

		chat.push(`
			<div id="chat-panel" class="panel chat-panel panel-warning" style="display:none">
			    <div class="panel-heading">
			        <i class="hidden-xs far fa-comment"></i> ${this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_012")}
			        <div class="btn-group pull-right">
			            <button type="button" class="btn btn-default btn-xs" onclick="fiscalizacaoMonitoradaVH.fecharChat()">
			                <span>x</span>
			            </button>
			        </div>
			    </div>
			    <div class="panel-body">
			        <ul id="chat" class="chat">
			        </ul>
			    </div>
			   <div class="panel-footer">
			        <div class="input-group">
			            <input id="chat-input" type="text" class="form-control input-sm" placeholder="${this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_013")}" onkeyup="fiscalizacaoMonitoradaVH.keyUpChat(event)">
			            <span class="input-group-btn">
			                <button class="btn btn-default btn-sm" id="btn-chat" onclick="fiscalizacaoMonitoradaVH.enviarMensagemChat()">
			                    ${this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_014")}
							</button>
			            </span>
			        </div>
			    </div>
			</div>
		`);

		this.addFerramenta({
			id: "container-chat-fiscalizacao-monitorada",
			html: chat.join("") 
		});
		
		$("body").append(this.addBotao({
			id: "botao_chat",
			label: `<i class='hidden-xs far fa-comment'></i> ${this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_015")}`,
			classe: "pull-right col-md-3 col-xs-3 btn-warning chat-button",
			css: "font-size: 110%; display:" + (this.fiscalizacaoMonitoradaTO.isChatHabilitado ? "block;" : "none;"),
			onClick: () => this.exibirChat(),
			retornarHtml: true
		}));
	}

	exibirBotaoChat() {
		$('#botao_chat').show();
	}

	esconderBotaoChat() {
		$('#botao_chat').hide();
	}

	keyUpChat(event) {
		if (event.code == "Enter") {
			this.enviarMensagemChat();
		}
	} 

	enviarMensagemChat() {
		const mensagem = $('#chat-input').val();
		if (mensagem) {
			this.proctoringService.enviarMsgChat(mensagem);
			this.inserirMensagemChat(this.getMsg("FP_FRONT_FiscalizacaoMonitoradaVH_016"), mensagem, 'right', 'left');
			$('#chat-input').val('')
		}
	} 

	inserirMensagemChat(nome: string, mensagem: string, posicaoNomeMensagem: string, posicaoDataHora: string) {

		const dataHora = UtilData.toDDMMYYYYHHMMSS(Date.now());
		const chat = $('#chat')[0];

		chat.insertAdjacentHTML('beforeend', `
			<li class="left clearfix">
				<div class="chat-body clearfix" style="display:grid;">
					<div class="header">
						<strong class="pull-${posicaoNomeMensagem} primary-font" style="margin-right: 6px;">${nome}</strong>
						<small class="pull-${posicaoDataHora} text-muted" style=" margin-right: 6px;">${dataHora}</small>
					</div>
					<p style="text-align: ${posicaoNomeMensagem};">
						${mensagem}
					</p>
				</div>
			</li>
		`);

		chat.scrollIntoView({behavior: 'smooth', block: 'end'});
		this.exibirChat();
	} 
}

const fiscalizacaoMonitoradaVH = new FiscalizacaoMonitoradaVH();

interface InicioFiscalizacaoMonitoradaTO {
	codAgendamentoUsuario: number;
	codProvaFeita: number;
	onMonitoramentoIniciado: (() => Promise<void>);
	onMonitoramentoInterrompido: (() => void);
	onErroMonitoramento: ((msgUsuario: string, msgAuditoria: string, msgIntegracaoAuditoria: any, codProvaFeita: number, codAgendamentoUsuario: number) => void);
	onMonitoramentoFinalizado: ((msgUsuario: string, msgAuditoria: string, msgIntegracaoAuditoria: any, codProvaFeita: number, codAgendamentoUsuario: number) => void);
	onEventoParaRegistroEmLog: ((msg: string, msgIntegracao: any, codProvaFeita: number, codAgendamentoUsuario: number) => void);
}

interface FiscalizacaoMonitoradaTO {
	isHabilitado: boolean;
	isChatHabilitado: boolean;
	msgInicioMonitoramento: string;
	termoLGPD: string;
	msgInicioProva: string;
	tokenAgendamentoUsuario: string;
	tituloProva: string;
	chavePublica: string;
	dadosSessao: any;
	proctoringId: string;
	urlFrontend: string;
	isWebcamCheck: boolean;
	forcarCompartilhamentoTelaInteira: boolean;
	bloquearMultiplosMonitores: boolean;
	isScreenshotsHabilitado: boolean;
}