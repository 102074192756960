class AgendamentoSalaVH extends AmaisVH {
	buscaAlocacoesTO: any;
	idInterval: any;
	edicaoAlocacaoTO: any;

	constructor() {
		super(AgendamentoSalaVH.name);
		this.addOperacaoParaHash("ased", this.editarAgendamentoSala);
		this.addOperacaoParaHash("asla", this.listarAlocacoes);
	}

	async listarAlocacoes() {

		UtilHash.registrarHistorico(this.listarAlocacoes);

		this.buscaAlocacoesTO = this.buscaAlocacoesTO || {};
		this.buscaAlocacoesTO.inicioPeriodo = this.buscaAlocacoesTO.inicioPeriodo || UtilData.getDataServidorUltimoResponse();

		const filtrosListagemAlocacoesTO = await this.call("AgendamentoSalaFCD/recuperarFiltrosListagem");

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_AgendamentoSalaVH_001"));

		this.append("<div class='btn-group pull-right'>");
		this.addBotao({
			label: this.getMsg("FP_FRONT_AgendamentoSalaVH_002"), classe: "btn-sm", onClick: () => {
				cadastroTurmaVH.listar(1);
			}
		});
		this.addBotao({
			label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_AgendamentoSalaVH_003")}`, classe: "btn-success btn-sm", onClick: () => {
				agendamentoSalaVH.editarAgendamentoSala();
			}
		});
		this.addBotaoBusca("filtrosEnsalamentos", {classe: "btn-sm"});
		this.append("</div>");

		this.addEspacamentoHorizontal("1px");

		const isExibirSegmentos = filtrosListagemAlocacoesTO.collectionSegmentosTO.length > 1 || (filtrosListagemAlocacoesTO.collectionSegmentosTO.length == 1 && filtrosListagemAlocacoesTO.collectionSegmentosTO[0].filhos != null);

		this.append("<div id='filtrosEnsalamentos' style='display: none'>");
		this.abrirAbaAccordion({ titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_004"), aberta: true });

		this.addFormulario();

		if (isExibirSegmentos) {
			await this.addSelect({
				collection: filtrosListagemAlocacoesTO.collectionSegmentosTO,
				id: "codsEmpresasBusca",
				label: this.getMsg("FP_FRONT_AgendamentoSalaVH_005"),
				multiplo: true,
				valor: this.buscaAlocacoesTO.codsEmpresasBusca,
				classe: "col-xs-12 col-md-3",
				onChange: async () => await this.atualizarListagemAlocacoes()
			});
		}

		await this.addSelect({
			id: "codsTurmas",
			label: this.getMsg("FP_FRONT_AgendamentoSalaVH_006"),
			classe: "col-md-3",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarSalas",
				payload: {
					idsOmitidos: null,
					omitirSemCapacidade: false,
					codsSegmentos: () => this.getValor("codsEmpresasBusca")
				},
				numMinimoCaracteres: 0,
			},			
			onChange: async () => await this.atualizarListagemAlocacoes()
		});

		await this.addSelect({
			id: "codsProvas",
			label: this.getMsg("FP_FRONT_AgendamentoSalaVH_007"),
			classe: "col-md-3",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarProvas",
				payload: {
					somenteComAplicacao: true
				},
				numMinimoCaracteres: 3,
			},
			onChange: async () => await this.atualizarListagemAlocacoes()
		});

		this.addCamposPeriodo({
			label: this.getMsg("FP_FRONT_AgendamentoSalaVH_008"),
			idInicio: "periodo_inicio",
			idFim: "periodo_fim",
			valorInicio: this.buscaAlocacoesTO.inicioPeriodo,
			valorFim: this.buscaAlocacoesTO.fimPeriodo,
			classe: "col-xs-12 col-md-3",
			onChange: async () => await this.atualizarListagemAlocacoes()
		});

		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		this.append("</div>");

		this.append("<div class='row'><div class='col-md-12' id='listagem-alocacoes'></div></div>");

		this.exibir();

		this.atualizarListagemAlocacoes();
	}

	async atualizarListagemAlocacoes() {

		this.buscaAlocacoesTO = {
			inicioPeriodo: this.getValor("periodo_inicio"),
			fimPeriodo: this.getValor("periodo_fim"),
			codsEmpresasBusca: this.getValor("codsEmpresasBusca"),
			codsTurmas: this.getValor("codsTurmas"),
			codsProvas: this.getValor("codsProvas")
		}

		const exibicaoListagemAlocacaoSalaTO = await this.call("AgendamentoSalaFCD/listarAlocacoes", this.buscaAlocacoesTO);
	
		$("#listagem-alocacoes").html("");
		this.setIdTarget("listagem-alocacoes");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_009"), prop: "dataInicioAplicacao", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_010"), prop: (to) => {
				if (to.dataInicioAplicacao == to.dataFimAplicacao) {
					return "";
				} else if (to.dataInicioAplicacao && to.dataFimAplicacao) {
					return UtilData.getDuracao({
						millis: UtilData.getDiferencaMillis(to.dataFimAplicacao, to.dataInicioAplicacao)
					});
				}
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_011"), prop: (to) => {
				if (to.numSegmentos > 2) {
					return to.numSegmentos + this.getMsg("FP_FRONT_AgendamentoSalaVH_014");
				} else {
					return to.nomesSegmentos;
				}
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_012"), prop:
				(to) => {
					if (to.numSalas > 2) {
						return to.numSalas + this.getMsg("FP_FRONT_AgendamentoSalaVH_015");
					} else {
						return to.nomesSalas;
					}
				}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_013"), prop: (to) => {
				if (to.numProvas > 2) {
					return to.numProvas + this.getMsg("FP_FRONT_AgendamentoSalaVH_016");
				} else {
					return to.nomesProvas;
				}
			}
		});
		colunas.push({titulo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO"), prop: "numAvaliadosDisponiveis"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_017"), prop: "numAvaliadosAlocados"});
		colunas.push({titulo: "", prop: (to) => {
				const h = [`
						<div class='btn-group-vertical'>
							<a class='btn btn-default' onclick='agendamentoSalaVH.exibirProvasAgendamentoSala(${to.idAgendamentoSala})'>
								${this.getMsg("FP_FRONT_AgendamentoSalaVH_018")} ${this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase()}
							</a>
					`];
				h.push(this.montarHtmlBotaoArquivoZip(to));
				h.push("</div>")
				return h.join("");
			}
		});

		await this.addTabela({
			collection: exibicaoListagemAlocacaoSalaTO.collectionListagemAlocacaoSalaTO,
			propId: "idAgendamentoSala",
			colunas: colunas,
			onEdicao: async (idAgendamentoSala) => await this.editarAgendamentoSala(idAgendamentoSala)
		});

		this.exibir();

		this.atualizarGeracaoArquivos();
	}

	montarHtmlBotaoArquivoZip(to) {
		if (!to.dataGeracaoZip) {
			return `
				<a class='btn btn-default btn-primary' onclick='agendamentoSalaVH.gerarArquivosAgendamentoSala(this, ${to.idAgendamentoSala})'>
					${this.getMsg("FP_FRONT_AgendamentoSalaVH_019")}
				</a>
			`;

		} else if (!to.pathZip) {
			return this.montarBotaoArquivoEmGeracao(to.idAgendamentoSala);

		} else if (to.pathZip.toUpperCase().startsWith("ERRO:")) {
			return `
				<a class='btn btn-default' title='${to.pathZip.replace(/'/g, '"')}'> 
					<i class='fa fa-exclamation-circle'></i> 
					${this.getMsg("FP_FRONT_AgendamentoSalaVH_020")}
				</a>
			`;

		} else {
			return `
				<a class='btn btn-default' target='_blank' href='${to.pathZip}'> 
					<i class='fa fa-download'></i> 
					${this.getMsg("FP_FRONT_AgendamentoSalaVH_021")}
				</a>
			`;
		}
	}

	montarBotaoArquivoEmGeracao(idAgendamentoSala) {
		return `
			<a class='btn btn-default' onclick='agendamentoSalaVH.atualizarGeracaoArquivos()' zip-sala-aguardando='${idAgendamentoSala}'>
				<i class='fa fa-spinner fa-spin fa-fw' title='${this.getMsg("FP_FRONT_AgendamentoSalaVH_022")}'></i>
			</a>
		`;
	}

	gerarArquivosAgendamentoSala(link, idAgendamentoSala) {
		this.addPopup({
			id: "agendamento_sala_geracao_arquivos",
			titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_023"),
			botoes: [{ label: this.getMsg("FP_FRONT_AgendamentoSalaVH_024"), classe: "btn-sm" }, {
				label: this.getMsg("FP_FRONT_AgendamentoSalaVH_025"), 
				classe: "btn-sm btn-primary", 
				onClick: async () => {

					await this.call("AplicacaoImpressaFCD/gerarCadernosAgendamentoSala", idAgendamentoSala, this.getValor("geracao_incluir_gabarito"), this.getValor("geracao_incluir_cadernos"));

					$(link).replaceWith($(this.montarBotaoArquivoEmGeracao(idAgendamentoSala)));
					this.exibir();
					this.atualizarGeracaoArquivos();
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_AgendamentoSalaVH_026"));
		this.addTexto(this.getMsg("FP_FRONT_AgendamentoSalaVH_027"));
		this.addCheckbox({ id: "geracao_incluir_gabarito", label: this.getMsg("FP_FRONT_AgendamentoSalaVH_028"), css: "padding-left: 2px; padding-top: 10px;" });
		this.addCheckbox({ id: "geracao_incluir_cadernos", label: this.getMsg("FP_FRONT_AgendamentoSalaVH_029"), css: "padding-left: 2px; padding-top: 10px;", valor: true });
		this.exibirPopups();
	}

	async atualizarGeracaoArquivos() {

		const idsAgendamentosSalas = [];

		$("[zip-sala-aguardando]").each((i, zip) => {
			idsAgendamentosSalas.push($(zip).attr("zip-sala-aguardando"));
		});

		if (idsAgendamentosSalas.length == 0) {
			if (this.idInterval) {
				clearInterval(this.idInterval);
			}
			return;
		}

		if (this.idInterval == null) {
			this.idInterval = setInterval(() => {
				if (!this.isUsuarioAutenticado()) return;
				this.atualizarGeracaoArquivos();
			}, 5000);
			return;
		}

		const listagemSituacaoGeracaoZipTO = await this.call({
			endpoint: "AgendamentoSalaFCD/recuperarInfoGeracaoZip",
			params: [idsAgendamentosSalas],
			desativarMsgCarregando: true
		});

		for (const situacaoGeracaoZipTO of listagemSituacaoGeracaoZipTO.collectionSituacaoGeracaoZipTO) {
			const $link = $("[zip-sala-aguardando='" + situacaoGeracaoZipTO.idAgendamentoSala + "']");
			const html = this.montarHtmlBotaoArquivoZip(situacaoGeracaoZipTO);

			$link.replaceWith($(html));
		}

		this.exibir();
	}

	async editarAgendamentoSala(idAgendamentoSala?: number) {

		UtilHash.registrarHistorico(this.editarAgendamentoSala, idAgendamentoSala);

		const edicaoAlocacaoTO = await this.call("AgendamentoSalaFCD/recuperar", idAgendamentoSala);

		this.edicaoAlocacaoTO = edicaoAlocacaoTO || {};
		this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO = this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO || [];
		this.edicaoAlocacaoTO.collectionAplicacoes = this.edicaoAlocacaoTO.collectionAplicacoes || [];

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_AgendamentoSalaVH_030") + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase());
		this.setSubtitulo(this.edicaoAlocacaoTO.idAgendamentoSala ? this.getMsg("FP_FRONT_AgendamentoSalaVH_031") : this.getMsg("FP_FRONT_AgendamentoSalaVH_032"));

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_AgendamentoSalaVH_033"),
			id: "dataInicioAplicacao",
			tipo: "DATAHORA",
			obrigatorio: true,
			valor: this.edicaoAlocacaoTO.dataInicioAplicacao,
			onChange: async () => await this.atualizarSituacao(),
			habilitado: this.edicaoAlocacaoTO.podeEditar
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_AgendamentoSalaVH_034"),
			id: "dataFimAplicacao",
			tipo: "DATAHORA",
			obrigatorio: true,
			valor: this.edicaoAlocacaoTO.dataFimAplicacao,
			onChange: async () => await this.atualizarSituacao(),
			habilitado: this.edicaoAlocacaoTO.podeEditar
		});

		this.addEspacamentoHorizontal("1px");

		this.append("<div class='col-md-12' id='listagem_aplicacoes'></div>");
		this.append("<div class='col-md-12' id='listagem_salas'></div>");

		this.append("<div class='col-md-12'>");
		this.addSubtitulo(this.getMsg("FP_FRONT_AgendamentoSalaVH_035"));
		this.append("<div class='row'><div class='col-md-12' id='situacao_alocacao'></div></div>");
		this.append("</div>");

		this.addEspacamentoHorizontal("10px");

		this.addBotao({
			label: "Voltar", onClick: () => {
				this.listarAlocacoes();
			}
		});

		this.append("<div class='btn-group pull-right'>");

		if (this.edicaoAlocacaoTO.pathZip) {
			if (this.edicaoAlocacaoTO.pathZip.toUpperCase().startsWith("ERRO:")) {
				this.append(`
					<a class='btn btn-default' title='${this.edicaoAlocacaoTO.pathZip.replace(/'/g, '"')}'> 
						<i class='fa fa-exclamation-circle'></i> 
						${this.getMsg("FP_FRONT_AgendamentoSalaVH_036")}
					</a>
				`);

			} else {
				this.append(`
					<a class='btn btn-default' target='_blank' href='${this.edicaoAlocacaoTO.pathZip}'> 
						<i class='fa fa-file-archive-o'></i> 
						${this.getMsg("FP_FRONT_AgendamentoSalaVH_037")}
					</a>
				`);
			}
		}

		if (this.edicaoAlocacaoTO.idAgendamentoSala && UtilAuth.possuiAcesso(TipoFuncionalidade.ALOCACAO_EXCLUSAO)) {
			this.addBotao({
				label: this.getMsg("FP_FRONT_AgendamentoSalaVH_038"),
				classe: "btn-danger",
				onClick: async () => {
					await this.exibirAlerta({
						titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_039"),
						msg: this.getMsg("FP_FRONT_AgendamentoSalaVH_040"),
						botoes: [{
							label: this.getMsg("FP_FRONT_AgendamentoSalaVH_038"), 
							classe: "btn-danger", 
							onClick: async () => {
								await this.call("AgendamentoSalaFCD/excluir", this.edicaoAlocacaoTO.idAgendamentoSala);
								this.listarAlocacoes();
							}
						}]
					});
				},
				habilitado: this.edicaoAlocacaoTO.podeExcluir
			});
		}

		this.addBotao({
			label: this.getMsg("FP_FRONT_AgendamentoSalaVH_041"),
			classe: "btn-primary",
			onClick: async () => {
				this.verificarObrigatorios();

				const salvamentoAlocacaoSalaTO = {
					idAgendamentoSala: this.edicaoAlocacaoTO.idAgendamentoSala,
					dataInicioAplicacao: this.getValor("dataInicioAplicacao"),
					dataFimAplicacao: this.getValor("dataFimAplicacao"),
					listaSalvamentoAlocacaoSalaTurmaTO: this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO.map(function (to) { return { codTurma: to.codTurma, codUsuarioFiscal: to.codUsuarioFiscal }; }),
					//						idsAplicacoes: this.edicaoAlocacaoTO.collectionAplicacoes.map(a => a.id),
					idsAplicacoes: this.edicaoAlocacaoTO.collectionAplicacoes.map(function (to) { return to.id; }),
				}

				await this.call("AgendamentoSalaFCD/salvarAlocacao", salvamentoAlocacaoSalaTO);
				this.listarAlocacoes();
			},
			habilitado: this.edicaoAlocacaoTO.podeEditar
		});

		this.append("</div>");

		this.addEspacamentoHorizontal("20px");

		if (this.edicaoAlocacaoTO.idAgendamentoSala) {
			this.addSubtitulo({
				texto: this.getMsg("FP_FRONT_AgendamentoSalaVH_043"),
				htmlDireita: `
					<a class='btn btn-sm btn-default' onclick='loginVH.exibirAcessosDoAgendamentoSala(${this.edicaoAlocacaoTO.idAgendamentoSala})'>
						${this.getMsg("FP_FRONT_AgendamentoSalaVH_042")}
					</a>
				`
			});

			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_AgendamentoSalaVH_044"), valor: UtilData.toDDMMYYYYHHMM(this.edicaoAlocacaoTO.dataCriacao)});

			if (this.edicaoAlocacaoTO.nomeUsuarioCriacao) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_AgendamentoSalaVH_045"), valor: this.edicaoAlocacaoTO.nomeUsuarioCriacao + " (" + this.edicaoAlocacaoTO.perfilUsuarioCriacao + ")"});
			}

			if (this.edicaoAlocacaoTO.dataGeracaoArquivos) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_AgendamentoSalaVH_046"), valor: UtilData.toDDMMYYYYHHMM(this.edicaoAlocacaoTO.dataGeracaoArquivos)});
			}

			if (this.edicaoAlocacaoTO.nomeUsuarioGeracaoArquivos) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_AgendamentoSalaVH_047"), valor: this.edicaoAlocacaoTO.nomeUsuarioGeracaoArquivos});
			}

			if (this.edicaoAlocacaoTO.isCriadoViaIntegracao && this.edicaoAlocacaoTO.nomeUsuarioCriacao) {
				this.append(`
					<div class='col-md-12'>
						<p class='text-danger'><strong>
							${this.getMsg("FP_FRONT_AgendamentoSalaVH_048", this.edicaoAlocacaoTO.nomeUsuarioCriacao)}
						</strong></p>
					</div>
				`);
			}

			this.addEspacamentoHorizontal("20px");
		}

		this.exibir();

		await this.atualizarAplicacoes(null, false);
		await this.atualizarSalas(null, true);
	}

	async atualizarSalas(tosSalasParaAdicionar, deveAtualizarSituacao) {
		$("#listagem_salas").html("");
		this.setIdTarget("listagem_salas");

		if (tosSalasParaAdicionar) {
			this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO.push(tosSalasParaAdicionar);
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_050"), prop: "nome", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_051"), prop: "nomeEmpresa"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_052") + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase() + ")", prop: "capacidade"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_053"), prop: async (to) => {
				if (this.edicaoAlocacaoTO.podeEditar) {
					return await this.addSelect({
						collection: to.listaOpcaoListaTOFiscais,
						id: "codFiscal_sala" + to.codTurma,
						ignorarTagForm: true,
						classe: "col-md-12",
						retornarHtml: true,
						valor: to.codUsuarioFiscal,
						habilitado: this.edicaoAlocacaoTO.podeEditar,
						onChange: () => {
							for (const edicaoAgendamentoSalaTurmaTO of this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO) {
								if (edicaoAgendamentoSalaTurmaTO.codTurma == to.codTurma) {
									edicaoAgendamentoSalaTurmaTO.codUsuarioFiscal = this.getValor("codFiscal_sala" + to.codTurma);
								}
							}
						}
					});
				} else {
					return to.nomeUsuarioFiscal;
				}
			}
		});
		colunas.push({titulo: "", prop: (to) => {
				if (!to.codTurma) return null;

				const h = [];
				if (this.edicaoAlocacaoTO.podeEditar) {
					h.push("<a onclick='agendamentoSalaVH.reposicionarSala(this, 1)'><i class='fa fa-chevron-down'></i></a>");
					h.push("<a onclick='agendamentoSalaVH.reposicionarSala(this, -1)'><i class='fa fa-chevron-up'></i></a>");
					h.push("<a onclick='agendamentoSalaVH.reposicionarSala(this, 0)'><i class='fa fa-remove'></i></a>");

				} else if (to.listaListagemDocTO.length) {
					h.push("");
					for (const listagemDocTO of to.listaListagemDocTO) {
						h.push(this.montarLinkDocumento(listagemDocTO));
					}
					h.push('');
				}
				return h.join("");
			}, classe: "esquerda"
		});

		await this.addTabela({
			collection: this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO,
			titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_049"),
			propId: "codTurma",
			desabilitarDownload: true,
			cssDiv: "overflow: visible",
			colunas: colunas,
			labelNovo: this.getMsg("FP_FRONT_AgendamentoSalaVH_054"),
			ordenacao: false,
			exibirNumeracao: true,
			onNovo: !this.edicaoAlocacaoTO.podeEditar ? null : async () => {

				this.addPopup({
					titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_055"),
					width: "900px",
					botoes: [{
						label: this.getMsg("FP_FRONT_AgendamentoSalaVH_056"), 
						classe: "btn-primary", 
						onClick: async () => { }
					}]
				});

				this.addSubtitulo(this.getMsg("FP_FRONT_AgendamentoSalaVH_057"));

				await this.addSelect({
					collection: this.edicaoAlocacaoTO.collectionSegmentosTO,
					id: "codsSegmentos",
					label: this.getMsg("FP_FRONT_AgendamentoSalaVH_058"),
					classe: "col-md-6",
					multiplo: true,
					onChange: async () => await this.atualizarListagemBuscaSalas(),
				});

				this.addCampoTexto({ label: this.getMsg("FP_FRONT_AgendamentoSalaVH_059"), id: "buscaTextualSala", onEnter: async () => await this.atualizarListagemBuscaSalas() });

				this.addSubtitulo(this.getMsg("FP_FRONT_AgendamentoSalaVH_060"));

				this.append("<div class='row'><div class='col-md-12' id='listagem_busca_salas'></div></div>");

				this.exibirPopups();

				this.atualizarListagemBuscaSalas();
			}
		});

		this.exibir();

		if (deveAtualizarSituacao) {
			this.atualizarSituacao();
		}
	}

	montarLink(path, label) {

		if (!path) return "";

		if (path.startsWith("ERRO:")) {
			return "<i class='fa fa-exclamation-triangle'></i> " + path.substr(5);
		}
		return "<a class='btn btn-link' style='font-size: 13px; padding-top: 3px;' target='_blank' href='" + path + "'>" + label + "</a>";
	}

	montarLinkDocumento(listagemDocTO) {

		if (!listagemDocTO?.path) return "";

		const h = ["<div>"];

		h.push(this.montarLink(listagemDocTO.path, listagemDocTO.nomeDoc));

		if (listagemDocTO.listaListagemDocPaginaTO.length > 0) {

			h.push("<div class='btn-group' style='margin-left: 10px'>");

			const listaDocPaginaTOJaEnviadas = [];

			for (const listagemDocPaginaTO of listagemDocTO.listaListagemDocPaginaTO) {
				h.push(" <a class='btn btn-default btn-sm' ");

				if (listagemDocPaginaTO.pathPaginaPreenchida) {
					h.push("target='_blank' href='" + listagemDocPaginaTO.pathPaginaPreenchida + "' ");
					listaDocPaginaTOJaEnviadas.push(listagemDocPaginaTO);

				} else {
					h.push(` disabled='disabled' title='${this.getMsg("FP_FRONT_AgendamentoSalaVH_061")}' style='background-color: lightgray' `);
				}

				h.push(">" + listagemDocPaginaTO.numPagina + "</a>");
			}

			if (listaDocPaginaTOJaEnviadas.length && UtilAuth.possuiAcesso(TipoFuncionalidade.ALOCACAO_DOC_EXCLUSAO_PAGINA)) {
				h.push('<button type="button" class="btn btn-default dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-container="body">');
				h.push('<span class="caret"></span>');
				h.push('<span class="sr-only">Toggle Dropdown</span>');
				h.push('</button>');
				h.push('<ul class="dropdown-menu">');
				for (const listagemDocPaginaTO of listaDocPaginaTOJaEnviadas) {
					h.push(`
						<li>
							<a onclick="agendamentoSalaVH.removerPaginaDoc(${listagemDocPaginaTO.idDocPagina},${listagemDocPaginaTO.numPagina},'${listagemDocTO.nomeDoc}')">
								${this.getMsg("FP_FRONT_AgendamentoSalaVH_062", listagemDocPaginaTO.numPagina)}
							</a>
						</li>
					`);
				}
				h.push('</ul>');
			}

			h.push("</div>");
		}

		h.push("</div>");

		return h.join("");
	}

	removerPaginaDoc(idDocPagina, numPagina, nomeDoc) {
		this.exibirAlerta({
			titulo: nomeDoc,
			msg: this.getMsg("FP_FRONT_AgendamentoSalaVH_063", numPagina),
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoSalaVH_064"), 
				classe: "btn-danger", 
				onClick: async () => {
					await this.call("AplicacaoImpressaFCD/removerPaginaDeDoc", idDocPagina);
					document.location.reload();
				}
			}]
		});
	}

	async adicionarSala(link) {

		const opcaoListaTOSala = this.getTOListagem(link);
		$(link).closest("tr").remove();

		const edicaoAgendamentoSalaTurmaTO = {
			codTurma: opcaoListaTOSala.id,
			nome: opcaoListaTOSala.nome,
			codEmpresa: opcaoListaTOSala.codEmpresa,
			nomeEmpresa: opcaoListaTOSala.descricao,
			capacidade: opcaoListaTOSala.capacidade,
			listaOpcaoListaTOFiscais: null
		}

		const tos = await this.call("ListagemSelecaoFCD/listarUsuariosComPermissao", {
			codEmpresa: edicaoAgendamentoSalaTurmaTO.codEmpresa, 
			tipoFuncionalidade: "FISCALIZACAO_APLICACAO"
		});

		edicaoAgendamentoSalaTurmaTO.listaOpcaoListaTOFiscais = tos;
		
		await this.atualizarSalas(edicaoAgendamentoSalaTurmaTO, true);
	}

	async reposicionarSala(link, operacao) {

		const codTurma = $(link).closest("tr").attr("identificador");

		for (let i = 0; i < this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO.length; i++) {

			const toAnterior = this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO[i - 1];
			const to = this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO[i];
			const toSeguinte = this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO[i + 1];

			if (to.codTurma == codTurma) {
				if (operacao < 0 && toAnterior) {
					this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO[i - 1] = to;
					this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO[i] = toAnterior;
				} else if (operacao == 0) {
					this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO.splice(i, 1);
				} else if (operacao > 0 && toSeguinte) {
					this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO[i] = toSeguinte;
					this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO[i + 1] = to;
				}
				break;
			}
		}

		await this.atualizarSalas(null, true);
	}

	async atualizarAplicacoes(toAplicacaoParaAdicionar, deveAtualizarSituacao) {
		$("#listagem_aplicacoes").html("");
		this.setIdTarget("listagem_aplicacoes");

		if (toAplicacaoParaAdicionar) {
			this.edicaoAlocacaoTO.collectionAplicacoes.push(toAplicacaoParaAdicionar);
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_066"), prop: (to) => to.nome});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_067"), prop: (to) => `<small>#${to.id}</small>`});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_068"), prop: "dataAplicacao", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO"), prop: "numAvaliados"});
		colunas.push({titulo: "", prop: (to) => {
				if (!to.id || !this.edicaoAlocacaoTO.podeEditar) return null;
				const h = [];
				h.push("<a onclick='agendamentoSalaVH.reposicionarAplicacao(this, 0)'><i class='fa fa-remove'></i></a>");
				return h.join("");
			}
		});

		await this.addTabela({
			collection: this.edicaoAlocacaoTO.collectionAplicacoes,
			titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_065"),
			propId: "id",
			desabilitarDownload: true,
			colunas: colunas,
			labelNovo: this.getMsg("FP_FRONT_AgendamentoSalaVH_069"),
			ordenacao: false,
			onNovo: !this.edicaoAlocacaoTO.podeEditar ? null : async () => {
				this.addPopup({
					titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_070"),
					width: "900px",
					botoes: [{ label: this.getMsg("FP_FRONT_AgendamentoSalaVH_071") }]
				});

				this.addSubtitulo(this.getMsg("FP_FRONT_AgendamentoSalaVH_072"));

				await this.addSelect({
					id: "codProva",
					label: this.getMsg("FP_FRONT_AgendamentoSalaVH_073"),
					classe: "col-md-6",
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarProvas",
						payload: {
							somenteComAplicacao: true
						},
						numMinimoCaracteres: 3,
					},
					onChange: async () => await this.atualizarListagemBuscaAplicacoes(),
				});

				this.addCamposPeriodo({
					label: this.getMsg("FP_FRONT_AgendamentoSalaVH_074"),
					idInicio: "dataInicioPeriodo",
					idFim: "dataFimPeriodo",
					onChange: async () => await this.atualizarListagemBuscaAplicacoes(),
					valorInicio: UtilData.getDataServidorUltimoResponse(),
					valorFim: null,
					classe: "col-md-6",
				});

				this.addSubtitulo(this.getMsg("FP_FRONT_AgendamentoSalaVH_075"));

				this.append("<div class='row'><div class='col-md-12' id='listagem_busca_aplicacoes'></div></div>");

				this.exibirPopups();

				this.atualizarListagemBuscaAplicacoes();
			}
		});

		this.exibir();

		if (deveAtualizarSituacao) {
			this.atualizarSituacao();
		}
	}

	async reposicionarAplicacao(link, operacao) {

		const id = $(link).closest("tr").attr("identificador");
		for (let i = 0; i < this.edicaoAlocacaoTO.collectionAplicacoes.length; i++) {

			const toAnterior = this.edicaoAlocacaoTO.collectionAplicacoes[i - 1];
			const to = this.edicaoAlocacaoTO.collectionAplicacoes[i];
			const toSeguinte = this.edicaoAlocacaoTO.collectionAplicacoes[i + 1];

			if (to.id == id) {
				if (operacao < 0 && toAnterior) {
					this.edicaoAlocacaoTO.collectionAplicacoes[i - 1] = to;
					this.edicaoAlocacaoTO.collectionAplicacoes[i] = toAnterior;
				} else if (operacao == 0) {
					this.edicaoAlocacaoTO.collectionAplicacoes.splice(i, 1);
				} else if (operacao > 0 && toSeguinte) {
					this.edicaoAlocacaoTO.collectionAplicacoes[i] = toSeguinte;
					this.edicaoAlocacaoTO.collectionAplicacoes[i + 1] = to;
				}
				break;
			}
		}
		await this.atualizarAplicacoes(null, true);
	}

	async atualizarListagemBuscaSalas() {

		const idsSalasOmitidos = this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO.map(function (to) { return to.codTurma; });
		const codsSegmentos = this.getValor("codsSegmentos");
		const buscaTextualSala = this.getValor("buscaTextualSala");

		if (!(this.hasValue(codsSegmentos) || this.hasValue(buscaTextualSala))) {
			$("#listagem_busca_salas").html("Informe pelo menos um filtro.");
			return;
		}

		const listarAplicacoesTO = {
			idsOmitidos: idsSalasOmitidos,
			omitirSemCapacidade: true,
			codsSegmentos,
			buscaTextual: buscaTextualSala
		}

		const collectionOpcaoListaTOSala = await this.call("ListagemSelecaoFCD/listarSalas", listarAplicacoesTO);
	
		$("#listagem_busca_salas").html("");
		this.setIdTarget("listagem_busca_salas");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_076"), prop: "nome"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_077"), prop: "capacidade"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_078"), prop: "descricao"});
		colunas.push({titulo: "", prop: `<a onclick='agendamentoSalaVH.adicionarSala(this)'><i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_AgendamentoSalaVH_079")}</a>`});

		await this.addTabela({
			collection: collectionOpcaoListaTOSala,
			id: "codsTurmasInclusao",
			colunas
		});

		this.exibir();
	}

	async atualizarListagemBuscaAplicacoes() {

		const codProva = this.getValor("codProva");
		const dataInicioPeriodo = this.getValor("dataInicioPeriodo");
		const dataFimPeriodo = this.getValor("dataFimPeriodo");

		if (!(codProva || dataInicioPeriodo || dataFimPeriodo)) {
			$("#listagem_busca_aplicacoes").html(this.getMsg("FP_FRONT_AgendamentoSalaVH_080"));
			return;
		}

		const collectionOpcaoListaTOAplicacaoImpressa = await this.call("ListagemSelecaoFCD/listarAplicacoesImpressas", {
			codProva: codProva,
			inicioAplicacao: dataInicioPeriodo,
			fimAplicacao: dataFimPeriodo
		});
		
		
		$("#listagem_busca_aplicacoes").html("");
		this.setIdTarget("listagem_busca_aplicacoes");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_081"), prop: "nome"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_082"), prop: "<small>#${id}</small>"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_083"), prop: "dataAplicacao", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO"), prop: "numAvaliados"});
		colunas.push({titulo: "", prop: `<a onclick='agendamentoSalaVH.adicionarAplicacao(this)'><i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_AgendamentoSalaVH_084")}</a>`});

		await this.addTabela({
			collection: collectionOpcaoListaTOAplicacaoImpressa,
			id: "listagem_resultado_busca_aplicacoes",
			propId: "id",
			desabilitarDownload: true,
			colunas: colunas,
			ordenacao: false,
		});

		this.exibir();
	}

	async adicionarAplicacao(link) {
		const to = this.getTOListagem(link);
		$(link).closest("tr").remove();
		await this.atualizarAplicacoes(to, true);
	}

	async atualizarSituacao() {

		if (!this.edicaoAlocacaoTO.podeEditar) {
			$("#situacao_alocacao").html(`<div class='alert alert-success'>${this.getMsg("FP_FRONT_AgendamentoSalaVH_085")}</div>`);
			return;
		}

		const calculoSituacaoAlocacaoSalaTO = {
			codsTurmas: this.edicaoAlocacaoTO.collectionEdicaoAgendamentoSalaTurmaTO.map(function (to) { return to.codTurma }),
			idsAplicacoes: this.edicaoAlocacaoTO.collectionAplicacoes.map(function (to) { return to.id; }),
			dataInicioAplicacao: this.getValor("dataInicioAplicacao"),
			dataFimAplicacao: this.getValor("dataFimAplicacao"),
			idAgendamentoSala: this.edicaoAlocacaoTO.idAgendamentoSala
		}

		const situacaoAlocacaoSalasTO = await this.call("AgendamentoSalaFCD/calcularSituacaoAlocacao", calculoSituacaoAlocacaoSalaTO);

		$("#situacao_alocacao").html("");
		this.setIdTarget("situacao_alocacao");

		if (situacaoAlocacaoSalasTO.msgErro) {
			this.append("<div class='alert alert-danger'>" + situacaoAlocacaoSalasTO.msgErro + "</div>");

		} else {

			const labelAvaliados = (situacaoAlocacaoSalasTO.numAvaliadosUnicos == 1 ? this.getMsg("FP_FRONT_AgendamentoSalaVH_089") : this.getMsg("FP_FRONT_AgendamentoSalaVH_086"));
			const labelAlocados = (situacaoAlocacaoSalasTO.numAvaliadosUnicos == 1 ? this.getMsg("FP_FRONT_AgendamentoSalaVH_088") : this.getMsg("FP_FRONT_AgendamentoSalaVH_087"));
			const labelSalas = (situacaoAlocacaoSalasTO.numSalas == 1 ? this.getMsg("FP_FRONT_AgendamentoSalaVH_091") : this.getMsg("FP_FRONT_AgendamentoSalaVH_090"));
			const inicioFrase = `${situacaoAlocacaoSalasTO.numAvaliadosUnicos} ${labelAvaliados} ${labelAlocados}`;
			const textoSalas = `${situacaoAlocacaoSalasTO.numSalas} ${labelSalas}`;

			this.append(`
				<div class='alert alert-success'>
					${this.getMsg("FP_FRONT_AgendamentoSalaVH_092", inicioFrase, textoSalas, situacaoAlocacaoSalasTO.capacidadeAlocacao)}
				</div>
			`);
		}
		this.exibir();
	}

	async exibirProvasAgendamentoSala(idAgendamentoSala, idAgendamentoSalaTurma) {
		
		const detalhesAgendamentoSalaTO = await this.call("CadastroTurmaFCD/exibirProvasAgendamentoSala", idAgendamentoSala, idAgendamentoSalaTurma);
		const idTabela = this.gerarId();

		this.addPopup({
			id: "agendamento_sala_provas",
			titulo: UtilData.toDDMMYYYYHHMM(detalhesAgendamentoSalaTO.dataDoAgendamento) + " <small>" + (detalhesAgendamentoSalaTO.nomeSala || "") + "</small>",
			botoes: [{ label: "OK", classe: "btn-sm" }],
			width: "980px",
			// height: "440px",
		});

		this.append(`
			<div class="col-md-12">
				<table id="${idTabela}" style="width: 100%">
		`);

		for (const provaEmAgendamentoSalaTO of detalhesAgendamentoSalaTO.listProvaEmAgendamentoSalaTO) {

			this.append(`
					<tr tipo="web">
						<td style="text-align: left; padding-top: 20px" colspan=4>
							<h4>${provaEmAgendamentoSalaTO.titulo}</h4>
						</td>
					</tr>
					<tr tipo="web">
						<td style="text-align: left" colspan=3>
							<small>
								${this.getMsg("FP_FRONT_AgendamentoSalaVH_093")}: ${provaEmAgendamentoSalaTO.listUsuarioProvaEmAgendamentoSalaTO.length}
							</small>
						</td>
						<td>
						</td>
					</tr>
			`);

			for (const usuarioProvaEmAgendamentoSalaTO of provaEmAgendamentoSalaTO.listUsuarioProvaEmAgendamentoSalaTO) {

				this.append("<tr><td style='text-align: left'>" + usuarioProvaEmAgendamentoSalaTO.nomeUsuario);
				this.append("</td>");
				this.append("<td>" + (usuarioProvaEmAgendamentoSalaTO.cpf || "") + "</td>");
				this.append("<td style='display: block; width: 1px; height: 1px; overflow: hidden;'>" + provaEmAgendamentoSalaTO.titulo + "</span></td>");
				this.append("<td style='width: 210px' tipo='web'>");

				if (usuarioProvaEmAgendamentoSalaTO.urlCadernoDocx) {
					this.append("<div class='btn-group'><a class='btn btn-link' target='_blank' href='" + usuarioProvaEmAgendamentoSalaTO.urlCadernoDocx + "'>DOCX</a>");

					if (usuarioProvaEmAgendamentoSalaTO.urlCadernoPdf) {
						this.append("<a class='btn btn-link' target='_blank' href='" + usuarioProvaEmAgendamentoSalaTO.urlCadernoPdf + "'>PDF</a>");
					}

					if (usuarioProvaEmAgendamentoSalaTO.urlGabaritoPdf) {
						this.append(`
							<a class='btn btn-link' target='_blank' href='${usuarioProvaEmAgendamentoSalaTO.urlGabaritoPdf}'>
								${this.getMsg("FP_FRONT_AgendamentoSalaVH_094")}
							</a>
						`);

					} else if (usuarioProvaEmAgendamentoSalaTO.urlGabaritoXls) {
						this.append(`
							<a class='btn btn-link' target='_blank' href='${usuarioProvaEmAgendamentoSalaTO.urlGabaritoXls}'>
								${this.getMsg("FP_FRONT_AgendamentoSalaVH_094")}
							</a>
						`);
					}

					this.append("</div>");
				}

				this.append("</td></tr>");
			}
		}

		this.append("<tr>");
		const idBtnDownload = this.gerarId();
		this.append(`
			<td colspan=4 style='text-align: right'>
				<button id="${idBtnDownload}" class='btn btn-link' type='button' title='${this.getMsg("FP_FRONT_AgendamentoSalaVH_095")}'>
					<i class='fa fa-download'></i>
				</button>
			</td>
		`);
		this.appendJs(() => this.listenToClick("#" + idBtnDownload, async () => {
			return await UtilXlsx.fazerDownloadTabela({
				ordenarPelaPrimeiraColuna: true,
				idTabela: idTabela
			});
		}));
		this.append("</tr>");

		this.append("</table>");
		this.append("</div>");

		this.exibirPopups();
	}
}

const agendamentoSalaVH = new AgendamentoSalaVH();
