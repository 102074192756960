class PFVH extends AmaisVH {
	
	constructor(vh: string) {
		super(vh);
	}

	get$AplicacaoProva() {

		const $aplicacaoProva = $("#divAplicacaoProva");

		if ($aplicacaoProva.length === 0 || $aplicacaoProva.closest("body").length === 0) {
			return null;
		}

		return $aplicacaoProva;
	}

	isEmAplicacaoDeProva(codProvaFeita?: number) {
		const $ap = this.get$AplicacaoProva();
		if ($ap === null) return false;
		if (this.isEmpty(codProvaFeita)) return true;
		return $ap.attr("cod-prova-feita") === String(codProvaFeita);
	}
}
