class InicioPFVH extends PFVH {

	tokensCodigoAcesso: string[];

	constructor() {
		super(InicioPFVH.name);
		this.addOperacaoParaHash("apre", this.verificarPrecondicoesParaInicioDePF, true);
	}

	/**
	 * Verifica 
	 * - horários, 
	 * - arquivamento, 
	 * - tentativas, 
	 * - liberação de fiscal,
	 * - etc
	 */
	async verificarSePodeIniciarProvaAgendada(codProva: number, codAgendamentoUsuario: number, botaoQueDisparou: EventTarget = null) {

		$(botaoQueDisparou).closest("a, button").attr("disabled", "disabled").text(this.getMsg("MSG_VH_AP_70") + "...").off("click").attr("onclick", "");
		
		try {
			await this.call({
				endpoint: "AgendamentoProvaFCD/verificarSePodeIniciarProvaAgendada",
				params: [codAgendamentoUsuario],
				onRequestError: async (e: BackendRequestError) => {

					let msgErro = e.msgErro || this.getMsg("FP_FRONT_InicioPFVH_001");

					if (loginVH.isAutenticacaoLTI()) {
						this.limpar();
						this.addTextoErro({texto: msgErro});
						this.exibir();
					} else {
						await this.exibirAlerta({ 
							msg: msgErro, 
						});
						UtilHash.carregarTelaInicialDoPerfil();
					}

					return BackendRequestError.ERRO_TRATADO;
				}
			});

			await inicioPFVH.verificarPrecondicoesParaInicioDeProva(codProva, codAgendamentoUsuario, botaoQueDisparou);

		} catch (ignored) {}
	}
	
	async verificarPrecondicoesParaInicioDeProva(codProva: number, codAgendamentoUsuario?: number, botaoQueDisparou: EventTarget = null) {

		$(botaoQueDisparou).closest("a, button").attr("disabled", "disabled").text(this.getMsg("MSG_VH_APR_65") + "...").off("click").attr("onclick", "");

		const precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO = await this.call(
			"AplicacaoProvaFCD/verificarPrecondicoesParaInicioDeProva", 
			codProva, 
			codAgendamentoUsuario, 
			null
		);

		const geracaoProvaFeitaTO: GeracaoProvaFeitaTO = new GeracaoProvaFeitaTO();
		
		geracaoProvaFeitaTO.codProva = codProva;
		geracaoProvaFeitaTO.codAgendamentoUsuario = codAgendamentoUsuario;
		geracaoProvaFeitaTO.codSecaoOptativaEscolhida = await this.perguntarOpcaoSecaoOptativaParaAvaliado(precondicoesParaInicioDeProvaTO);

		const resultadoGeracaoPFTO: ResultadoGeracaoPFTO = await this.call("AplicacaoProvaFCD/gerarProvaFeita", geracaoProvaFeitaTO);

		if (resultadoGeracaoPFTO.codProvaFeita) {
			// this.checkHashPagina(resultadoGeracaoPFTO.codProvaFeita);
			await this.verificarPrecondicoesParaInicioDePF(resultadoGeracaoPFTO.codProvaFeita, botaoQueDisparou)
			// await this.recuperarComposicaoDaProvaFeita(resultadoGeracaoPFTO.codProvaFeita);

		} else if (resultadoGeracaoPFTO.isGeracaoDuplicada === true) {
			await this.exibirAlerta({msg: this.getMsg("FP_FRONT_InicioPFVH_015")});

		} else if (resultadoGeracaoPFTO.isGeracaoEmFila === true) {
			this.popupFilaGeracao(geracaoProvaFeitaTO);

		} else {
			this.logger.warn("Não veio codProvaFeita nem tratou o erro!");
		}
	}

	async verificarPrecondicoesParaInicioDePF(codProvaFeita: number, botaoQueDisparou: EventTarget = null) {

		$(botaoQueDisparou).closest("a, button").attr("disabled", "disabled").text(this.getMsg("MSG_VH_APR_135") + "...").off("click").attr("onclick", "");

		UtilHash.registrarHistorico(this.verificarPrecondicoesParaInicioDePF, codProvaFeita);

		const precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO = await this.call("AplicacaoProvaFCD/verificarPrecondicoesParaInicioDeProva", null, null, codProvaFeita);

		if (precondicoesParaInicioDeProvaTO.isPFEncerrada === true && !precondicoesParaInicioDeProvaTO.isReaberturaVigente) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_InicioPFVH_016") });
			UtilHash.carregarTelaInicial();
			return;
		}
		
		if (precondicoesParaInicioDeProvaTO.possuiQuestoesDiscursivas) {
			if (!await exibicaoQuestaoVH.testarContagemLinhasDiscursiva()) {
				await this.exibirAlerta({msg: this.getMsg("FP_FRONT_InicioPFVH_033")});
				this.logger.info("Prova não iniciada devido a detectação de alteração na contagem de linhas de questões discursivas.");
				UtilHash.carregarTelaInicial();
				return;
			}
		}

		await this.bloquearSmartphones(precondicoesParaInicioDeProvaTO);
		await facematchPFVH.iniciarValidacao(precondicoesParaInicioDeProvaTO.situacaoFacematchValidacaoTO);
		await this.ativarInicioProctoring(precondicoesParaInicioDeProvaTO);
		await this.confirmarDadosAvaliado(precondicoesParaInicioDeProvaTO);
		await this.confirmarCodigoAcesso(precondicoesParaInicioDeProvaTO);
		await this.confirmarCodigoRotativoAplicacao(precondicoesParaInicioDeProvaTO, codProvaFeita);
		await this.exibirComposicaoDaProvaFeita(precondicoesParaInicioDeProvaTO);
		await fullScreenPFVH.exibirTelaFullScreen(precondicoesParaInicioDeProvaTO);
		await this.validarQuestionarios(precondicoesParaInicioDeProvaTO);
		await this.iniciarProvaAposVerificarPrecondicoes({ codProvaFeita: codProvaFeita });
	}

	async validarQuestionarios(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO) {
		if (precondicoesParaInicioDeProvaTO.idAplicacaoQuestPreProva) {
			await questVH.aplicarQuestionario(precondicoesParaInicioDeProvaTO.idAplicacaoQuestPreProva);
		}

		if(precondicoesParaInicioDeProvaTO.idAplicacaoQuestFormularioComplementar) {
			await questVH.aplicarQuestionario(precondicoesParaInicioDeProvaTO.idAplicacaoQuestFormularioComplementar);
		}

		return null;
	}

	async iniciarProvaAposVerificarPrecondicoes({ codProvaFeita, botaoQueDisparou = null }) {

		$(botaoQueDisparou).closest("a, button").attr("disabled", "disabled").text(this.getMsg("MSG_VH_APR_135") + "...").off("click").attr("onclick", "");

		const aplicacaoProvaFeitaTO: AplicacaoProvaFeitaTO = await this.call("AplicacaoProvaFCD/iniciarProvaFeita", codProvaFeita, this.getTokensCodigosAcesso());

		this.adicionarEventoParaProvaOffline();

		if (await encerramentoPFVH.encerrarProvaPorTempoEsgotado(aplicacaoProvaFeitaTO)) {
			UtilHash.carregarTelaInicial();
			return;
		}

		this.limpar();
		await $("#container").fadeOut();

		const tituloProva = aplicacaoProvaFeitaTO.tituloPublico ? aplicacaoProvaFeitaTO.tituloPublico: aplicacaoProvaFeitaTO.titulo;
		this.setTitulo(tituloProva, null, tituloProva);

		$("#identificacaoConteudo").css("padding-top", "30px");

		this.append('<nav id="barra-comandos-da-prova" class="navbar navbar-default comandos-da-prova arrastavel">');

		const key = UtilBoot.getKeymaster();
		
		key.unbind("command+left,ctrl+left");
		key("command+left,ctrl+left", () => $("#botao_anterior").click());

		this.addBotao({
			id: "botao_anterior",
			label: "<span class='hidden-xs'> &laquo; " + this.getMsg("MSG_VH_APR_138")+ "</span>" + "<span class='visible-xs'> &laquo; " + this.getMsg("MSG_VH_APR_181") + "</span>",
			classe: "btn-sm pull-left col-md-3 col-xs-3 btn-success",
			css: "font-size: 110%",
			visivel: aplicacaoProvaFeitaTO.isQuestaoPorQuestao === false && aplicacaoProvaFeitaTO.numQuestoesNaProva > 1,
			onClick: async () => {

				await navegacaoPFVH.forcarBlurRespostaDiscursiva();

				const btnAnterior = document.getElementById('botao_anterior');
				const modalLoading = $('.modal-loading');

				if (modalLoading.length || btnAnterior?.hasAttribute('disabled')) {
					return;
				}

				if (!(await navegacaoPFVH.validarQuestaoEmBranco())) {
					return;
				}

				if (aplicacaoProvaFeitaTO.isQuestaoPorQuestao) {
					await navegacaoPFVH.irParaQuestao("anterior", null, true);
				} else {
					navegacaoPFVH.irParaQuestaoPaginaUnica("anterior");
				}
			},
			ariaLabel: this.getMsg("FP_FRONT_InicioPFVH_028")
		})

		this.append(cronoPFVH.gerarHtmlCronometro(aplicacaoProvaFeitaTO));

		key.unbind("command+right,ctrl+right");
		key("command+right,ctrl+right", () => $("#botao_avancar").click());

		this.addBotao({
			id: "botao_avancar",
			label: "<span class='hidden-xs'>" + this.getMsg("MSG_VH_APR_141") + " &raquo; </span>" + "<span class='visible-xs'>" + this.getMsg("MSG_VH_APR_180") + " &raquo; </span>",
			classe: "btn-sm pull-right col-md-3 col-xs-3 btn-success",
			css: "font-size: 110%",
			visivel: aplicacaoProvaFeitaTO.numQuestoesNaProva > 1,
			onClick: async () => {
				await navegacaoPFVH.handleClickBotaoAvancar(true);
			},
			ariaLabel: this.getMsg("FP_FRONT_InicioPFVH_029")
		})

		let labelConcluirAProva = this.getMsg("MSG_VH_APR_143");
		let labelBtnConcluir = `
			<span class="hidden-xs">${labelConcluirAProva}</span>
			<span class="visible-xs">${this.getMsg("MSG_VH_APR_182")}</span>
		`;

		this.addBotao({
			id: "botao_concluir", 
			label: labelBtnConcluir, 
			onClick: async () => {

				await navegacaoPFVH.forcarBlurRespostaDiscursiva();
				
				const btnConcluir = document.getElementById('botao_concluir');
				const modalLoading = $('.modal-loading');

				if (modalLoading.length || btnConcluir?.hasAttribute('disabled')) {
					return;
				}

				this.stopAudioAndVideos();
				await salvamentoPFVH.verificarSalvamentoEEncerrar(false, labelConcluirAProva + "?");
			},
			visivel: aplicacaoProvaFeitaTO.numQuestoesNaProva === 1,
			classe: "btn-primary btn-sm pull-right col-md-3 col-xs-3",
			css: "font-size: 110%",
			ariaLabel: this.getMsg("FP_FRONT_InicioPFVH_030")
		});

		if (!aplicacaoProvaFeitaTO.isQuestaoPorQuestao && aplicacaoProvaFeitaTO.numQuestoesNaProva > 1) {
			navegacaoPFVH.monitorarScroll(aplicacaoProvaFeitaTO.codProvaFeita);
		}

		this.append("</nav>");

		this.append(`
			<div id="alerta-pf-com-respostas-nao-salvas" class="alert alert-danger arrastavel fixo-centralizado" role="alert" style="display: none;">
				<div class="col-md-9">
					${this.getMsg("MSG_VH_APR_144")}
				</div>
				<div class="col-md-3">
					<a class='btn btn-danger pull-right' onclick="salvamentoPFVH.salvarRespostasPendentes()">
						${this.getMsg("MSG_VH_APR_59")}
					</a>
				</div>
			</div>
		`);

		if (aplicacaoProvaFeitaTO.nomeProfessorResponsavel != null) {
			this.setSubtitulo(this.getMsg("MSG_VH_APR_145") + ": " + aplicacaoProvaFeitaTO.nomeProfessorResponsavel)
		}

		exibicaoQuestaoVH.limparControleTextos();

		if (this.isAdministradorSistema()) {

			this.append("<div class='well'>");

			if (aplicacaoProvaFeitaTO.htmlDaProva) {

				this.addTexto(this.getMsg("MSG_VH_APR_146") + " ")

				this.addBotao({
					label: this.getMsg("MSG_VH_APR_147"),
					onClick: async () => {
						
						await this.call("AplicacaoProvaFCD/limparHtmlDaProva", $("#divAplicacaoProva").attr("cod-prova-feita"));

						await this.call("AgendamentoProvaFCD/removerAgendamentoUsuario", aplicacaoProvaFeitaTO.codAgendamentoUsuario);

						await this.exibirAlerta({
							msg: this.getMsg("MSG_VH_APR_148"),
							botoes: [{ label: this.getMsg("MSG_VH_003") }],
						});
						UtilHash.carregarTelaInicial();
					}
				});
			}

			this.addBotao({
				label: this.getMsg("MSG_VH_APR_149"),
				onClick: () => $("[questao]").show()
			});

			this.addBotao({
				label: this.getMsg("MSG_VH_APR_150"),
				onClick: async () => {
					
					await this.call("AplicacaoProvaFCD/salvarHtmlDaProva", $("#divAplicacaoProva").attr("cod-prova-feita"), $("#divAplicacaoProva").html());

					await this.call("AgendamentoProvaFCD/removerAgendamentoUsuario", aplicacaoProvaFeitaTO.codAgendamentoUsuario);

					await this.exibirAlerta({
						msg: this.getMsg("MSG_VH_APR_151"),
						botoes: [{ label: this.getMsg("MSG_VH_003") }],
					});
					UtilHash.carregarTelaInicial();
				}
			});

			this.append("</div>")
		}

		this.append(`<div id="divAplicacaoProva" aplicacao-prova cod-prova-feita="${aplicacaoProvaFeitaTO.codProvaFeita}" data-fp-segundos-sem-keep-alive="0"`);

		if (this.hasValue(aplicacaoProvaFeitaTO.segundosToleranciaMonitoramento)) {
			this.append(" segundos-tolerancia-monitoramento='" + aplicacaoProvaFeitaTO.segundosToleranciaMonitoramento + "' ");
			this.append(" anular-automaticamente='" + aplicacaoProvaFeitaTO.isAnularAutomaticamenteSaidaNavegador + "' ");
		}

		if (this.hasValue(aplicacaoProvaFeitaTO.codProvaFeitaLogUltimoKA)) {
			this.append(` cod-prova-feita-log-ultimo-ka="${aplicacaoProvaFeitaTO.codProvaFeitaLogUltimoKA}" `);
		}
		
		this.append(">");

		if (aplicacaoProvaFeitaTO.htmlDaProva) {
			this.append(aplicacaoProvaFeitaTO.htmlDaProva);

		} else {
			
			let nomeUltimaSecao = null;

			if (aplicacaoProvaFeitaTO.listaTemposRespostaSecao) {

				// Para cada seção, adiciona uma div para controle do tempo de resposta por seção
				aplicacaoProvaFeitaTO.listaTemposRespostaSecao.forEach(s => {
					this.append(`
						<div secao cod-secao="${s.codSecao}" 
							data-fp-tempo-para-resposta-secao="${s.tempoParaResposta}" 
							data-fp-tempo-utilizado-secao="${s.tempoUtilizado}" 
							style="display: none">
						</div>`
					);
				});
			}
			
			for (const exibicaoQuestaoTO of aplicacaoProvaFeitaTO.collectionExibicaoQuestaoTO) {

				exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO, {
					isExibirNomeSecao: aplicacaoProvaFeitaTO.isQuestaoPorQuestao || nomeUltimaSecao != exibicaoQuestaoTO.nomeSecao,
					isBloquearCorretorOrtografico: aplicacaoProvaFeitaTO.isBloquearCorretorOrtografico,
					isAnularDiscursivasMinLinhas: aplicacaoProvaFeitaTO.isAnularDiscursivasMinLinhas
				});
				
				nomeUltimaSecao = exibicaoQuestaoTO.nomeSecao;

				if (aplicacaoProvaFeitaTO.isQuestaoPorQuestao) {
					exibicaoQuestaoVH.limparControleTextos();
				}
			}
		}

		this.append("</div>");

		this.addEspacamentoHorizontal("10px");

		if (aplicacaoProvaFeitaTO.codAgendamentoUsuario) {
			this.append(`
				<div class='panel panel-default' id='painel_respostas' tabindex="0" aria-label='${this.getMsg("FP_FRONT_InicioPFVH_031")}'></div>
			`);
		}

		this.exibir({ isDeveFazerScrollParaTitulo: true });

		if (aplicacaoProvaFeitaTO.codAgendamentoUsuario) {
			this.setIdTarget("painel_respostas");
			this.append(navegacaoPFVH.montarPainelDeRespostas(aplicacaoProvaFeitaTO));
			this.exibir({ isDeveFazerScrollParaTitulo: true });
		}

		const $aplicacaoProva = this.get$AplicacaoProva();

		if (aplicacaoProvaFeitaTO.isBloquearConclusaoComEmBranco) {
			$aplicacaoProva.data('fp-impedir-respostas-em-branco-antes-finalizacao', true);
		}

		if (aplicacaoProvaFeitaTO.isBloquearQuestoesJaRespondidas) {
			$aplicacaoProva.data('fp-deve-bloquear-ja-respondidas', true);
		}

		if (aplicacaoProvaFeitaTO.isBloquearQuestoesJaVisualizadas) {
			$aplicacaoProva.data('fp-deve-bloquear-ja-visualizadas', true);
		}
		
		if (aplicacaoProvaFeitaTO.isQuestaoPorQuestao) {
			$aplicacaoProva.data('fp-is-questao-por-questao', true);
		}
		
		$aplicacaoProva.data('cod-resposta-questao-visualizada-por-ultimo', aplicacaoProvaFeitaTO.codRespostaQuestaoVisualizadaPorUltimo);

		await salvamentoPFVH.bindEventosSalvamento();
		await recursoPFVH.exibirLinksDeRecurso(aplicacaoProvaFeitaTO);
		await pausaPFVH.inicializar();

		if (aplicacaoProvaFeitaTO.isQuestaoPorQuestao) {
			await navegacaoPFVH.irParaQuestao(null, aplicacaoProvaFeitaTO.codRespostaQuestaoVisualizadaPorUltimo);					
		}

		await this.fadeIn("#container", 150);

		$('button.btn.btn-opcao_resposta').mousedown(function() {
			$(this).on('mouseleave', function() {
				$(this).blur();
			});
		});

		$('button.btn[cod-item]').mousedown(function() {
			$(this).on('mouseleave', function() {
				$(this).blur();
			});
		});

		await $("#link_help").hide();

		await monitoramentoPFVH.monitorarSaidaDoNavegador();
		await monitoramentoPFVH.removerLinksExternos(aplicacaoProvaFeitaTO);

		// respiro para estabilizar UI
		await this.sleep(500);

		await cronoPFVH.iniciarCronometro(aplicacaoProvaFeitaTO);
		await monitoramentoPFVH.iniciarKA($aplicacaoProva);
		await facematchPFVH.iniciarRevalidacao(aplicacaoProvaFeitaTO.situacaoFacematchRevalidacaoTO);
		await exibicaoQuestaoVH.monitorarEstiloTextArea($aplicacaoProva);
	}
	
	async exibirComposicaoDaProvaFeita(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO): Promise<void> {

		if (precondicoesParaInicioDeProvaTO.dataInicioPF) return;
		if (precondicoesParaInicioDeProvaTO.tipoAplicacao === TipoAplicacao.E) return;

		return new Promise<void>(async (resolve, reject) => {

			const exibicaoComposicaoProvaTO = await this.call("AplicacaoProvaFCD/recuperarComposicaoDaProvaFeita", precondicoesParaInicioDeProvaTO.codProvaFeita);

			const tituloProva = exibicaoComposicaoProvaTO.tituloPublico ? exibicaoComposicaoProvaTO.tituloPublico: exibicaoComposicaoProvaTO.titulo;
			const isMostrarInstrucoes = this.isCfgDesabilitada("OCULTAR_INSTRUCOES_PADRAO_DA_PROVA_NA_APLICACAO");
			const ariaLabelTitulo = isMostrarInstrucoes ? `${this.getMsg("MSG_VH_APR_70")} ${tituloProva}`: tituloProva;
			const isProvaSomenteDiscursiva = exibicaoComposicaoProvaTO.possuiQuestoesDiscursivas && !exibicaoComposicaoProvaTO.possuiQuestoesME && !exibicaoComposicaoProvaTO.possuiQuestoesCE && !exibicaoComposicaoProvaTO.possuiQuestoesRespostaExata;

			this.limpar();
			this.setTitulo(tituloProva, null, ariaLabelTitulo);

			if (!exibicaoComposicaoProvaTO.possuiConcurso && this.hasValue(exibicaoComposicaoProvaTO.instrucoesEspecificasDaProva)) {
				this.append("<div class='col-md-12' style='font-size: 120%'>" + exibicaoComposicaoProvaTO.instrucoesEspecificasDaProva + "</div>");
				this.addEspacamentoHorizontal("10px");
			}

			if (isMostrarInstrucoes) {

				this.setSubtitulo(this.getMsg("MSG_VH_APR_70"));
				let listaInstrucoes = [];

				if (exibicaoComposicaoProvaTO.pathPdfFolhaResposta) {

					if (isProvaSomenteDiscursiva) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_71")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_72")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_73")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_74")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_75")});

					} else {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_76")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_77")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_78")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_79")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_80")});
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_81")});
					}

					if (exibicaoComposicaoProvaTO.possuiQuestoesCE || exibicaoComposicaoProvaTO.possuiQuestoesME) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_82")});
					}

				} else {

					if (exibicaoComposicaoProvaTO.dataLimiteInicioDaProva) {
						let data: string;

						if (UtilData.isHoje(exibicaoComposicaoProvaTO.dataLimiteInicioDaProva)) {
							data = UtilData.toHHMMSS(exibicaoComposicaoProvaTO.dataLimiteInicioDaProva);
						} else {
							data = UtilData.toDDMMYYYYHHMMSS(exibicaoComposicaoProvaTO.dataLimiteInicioDaProva);
						}

						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_185", data)});
					}

					if (exibicaoComposicaoProvaTO.tempoParaResposta != null) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_83") + " <b>" + UtilData.tempoEmSegundosParaUserFriendly(exibicaoComposicaoProvaTO.tempoParaResposta) + "</b> " + this.getMsg("MSG_VH_APR_84")})
					}

					if (exibicaoComposicaoProvaTO.isCronometroPorQuestao) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_85")})
					}

					if (exibicaoComposicaoProvaTO.isCronometroPorSecao) {
						listaInstrucoes.push({texto: this.getMsg("FP_FRONT_InicioPFVH_076")})
					}

					const tiposDeQuestao = [];

					if (exibicaoComposicaoProvaTO.possuiQuestoesME) tiposDeQuestao.push("<b>" + this.getMsg("MSG_VH_APR_86") + "</b>");
					if (exibicaoComposicaoProvaTO.possuiQuestoesCE) tiposDeQuestao.push("<b>" + this.getMsg("MSG_VH_APR_87") + "</b>");
					if (exibicaoComposicaoProvaTO.possuiQuestoesRespostaExata) tiposDeQuestao.push("<b>" + this.getMsg("MSG_VH_APR_88") + "</b>");
					if (exibicaoComposicaoProvaTO.possuiQuestoesDiscursivas) tiposDeQuestao.push("<b>" + this.getMsg("MSG_VH_APR_89") + "</b>");

					let textoTipoQuestao = "";

					for (let i = 0; i < tiposDeQuestao.length; i++) {
						if (i == 0) {
							textoTipoQuestao += tiposDeQuestao[i];
						} else if (i == (tiposDeQuestao.length - 1)) {
							textoTipoQuestao += " " + this.getMsg("MSG_VH_APR_90") + " " + tiposDeQuestao[i];
						} else {
							textoTipoQuestao += ", " + tiposDeQuestao[i];
						}
					}

					listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_91") + " " + textoTipoQuestao + "."})

					if (exibicaoComposicaoProvaTO.possuiQuestoesME) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_92")})
					}

					if (exibicaoComposicaoProvaTO.possuiQuestoesCE) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_93")})
					}

					if (exibicaoComposicaoProvaTO.possuiQuestoesCE || exibicaoComposicaoProvaTO.possuiQuestoesME) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_94")})
					}

					if (exibicaoComposicaoProvaTO.possuiQuestoesRespostaExata) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_95")})
					}

					if (exibicaoComposicaoProvaTO.podeInterporRecursos) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_96")})
					}
				}

				if (exibicaoComposicaoProvaTO.possuiQuestoesDiscursivas) {
					if (exibicaoComposicaoProvaTO.diasPrazoEnvioRedacao) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_97") + " " + exibicaoComposicaoProvaTO.diasPrazoEnvioRedacao + " " + this.getMsg("MSG_VH_APR_98")});
					} else {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_99")});
					}
					if (exibicaoComposicaoProvaTO.diasPrazoCorrecaoDiscursiva) {
						listaInstrucoes.push({texto: this.getMsg("MSG_VH_APR_100") + " " + exibicaoComposicaoProvaTO.diasPrazoCorrecaoDiscursiva + " " + this.getMsg("MSG_VH_APR_101")});
					}
				}

				if (exibicaoComposicaoProvaTO.qtdErrosAnulamCerta != null) {
					let msg = this.getMsg("MSG_VH_APR_102") + " ";

					if (exibicaoComposicaoProvaTO.possuiConcurso) {
						msg = this.getMsg("MSG_VH_APR_103") + " ";
					}

					if (exibicaoComposicaoProvaTO.qtdErrosAnulamCerta == 1) {
						msg += this.getMsg("MSG_VH_APR_104") + " ";

					} else {
						msg += exibicaoComposicaoProvaTO.qtdErrosAnulamCerta + " " + this.getMsg("MSG_VH_APR_105") + " ";
					}

					msg += this.getMsg("MSG_VH_APR_106");

					listaInstrucoes.push({texto: msg});
				}
				
				if (exibicaoComposicaoProvaTO.possuiQuestionarioPreProva) {
					listaInstrucoes.push({texto: `Como parte do processo de avaliação, você deverá responder a um <strong>questionário</strong> antes de iniciar a prova.`})
				}
				
				if (exibicaoComposicaoProvaTO.possuiQuestionarioPosProva) {
					listaInstrucoes.push({texto: `Como parte do processo de avaliação, você deverá responder a um <strong>questionário</strong> após concluir sua prova.`})
				}

				this.addListaComMarcadores(listaInstrucoes, this.getMsg("FP_FRONT_InicioPFVH_026"));
			}

			this.addEspacamentoHorizontal("5px");

			this.append(`
				<div aplicacao-prova-inicio-listagem-secoes class='row' cod-prova="${exibicaoComposicaoProvaTO.codProva}" is-resultado-por-mencao="${exibicaoComposicaoProvaTO.isProvaComResultadoPorMencao || false}">
					<div class='col-md-12'>
			`);

			if (exibicaoComposicaoProvaTO.listaExibicaoComposicaoSecaoTO.length > 1) {
				const descricao = `${this.getMsg("MSG_VH_APR_109")} ${exibicaoComposicaoProvaTO.listaExibicaoComposicaoSecaoTO.length} ${this.getMsg("MSG_VH_APR_110")}`;
				this.append(`
					<div class='col-md-12' tabindex="0" aria-label="${descricao}">
						<strong>${descricao}</strong>
					</div>
				`);
			} else {
				this.append(`
					<div class='col-md-12' tabindex="0" aria-label="${this.getMsg("MSG_VH_APR_111")}">
						<strong>${this.getMsg("MSG_VH_APR_111")}</strong>
					</div>
				`);
			}

			this.addEspacamentoHorizontal("5px");

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("FP_FRONT_InicioPFVH_027"), prop: "nomeSecao", classe: "titulo-nome-secao-tela-instrucoes"});
			colunas.push({titulo: this.getMsg("MSG_VH_APR_112"), prop: "numQuestoes"});

			if (exibicaoComposicaoProvaTO.exibirPesoDasSecoes) {
				colunas.push({titulo: this.getMsg("MSG_VH_APR_113"), prop: "peso", formato: "numero"});
				colunas.push({titulo: this.getMsg("MSG_VH_APR_114"), prop: "pontos", formato: "numero"});
			}

			if (exibicaoComposicaoProvaTO.exibirPontuacaoExigidaSecoes === true) {
				colunas.push({titulo: this.getMsg("MSG_VH_APR_115"), prop: "pontuacaoExigida", formato: "numero"});
			}

			await this.addTabela({
				id: "tableSecoesInstrucoesProva",
				collection: exibicaoComposicaoProvaTO.listaExibicaoComposicaoSecaoTO,
				colunas: colunas,
				exibirTotalDeItens: false,
				ordenar: false,
				desabilitarDownload: true,
				ocultarOrdernacao: true
			});


			this.append("</div></div>");

			if (exibicaoComposicaoProvaTO.isMonitorarSaidaNavegador || exibicaoComposicaoProvaTO.isMonitorarSaidaNavegadorManual) {
				if (exibicaoComposicaoProvaTO.isFiscalizacaoMonitoradaHabilitadaComUsoDeAbaSeparada) {
					this.append(`
						<div class="col-md-12">
							<div class='alert alert-danger' tabindex="0" aria-label="${UtilHtml.removeTagsHtml(this.getMsg("MSG_VH_APR_195"))}">
								${this.getMsg("MSG_VH_APR_195")}
							</div>
						</div>
					`);
				} else {
					const descricaoMonitoramento= exibicaoComposicaoProvaTO.isMonitorarSaidaNavegador ? this.getMsg("MSG_VH_APR_116"):this.getMsg("MSG_VH_APR_196");
					this.append(`
						<div class="col-md-12">
							<div class='alert alert-danger' tabindex="0" aria-label="${UtilHtml.removeTagsHtml(descricaoMonitoramento)}">
								${descricaoMonitoramento}
							</div>
						</div>
					`);
				}
			}

			if (exibicaoComposicaoProvaTO.pathImagemTeste != null) {
				this.append(`
					<img id="fp_imagem_teste" 
						alt="${this.getMsg("MSG_VH_APR_117")}" 
						onload="$(this).data('on-load-ok', true).data('fp-ms-on-load', Date.now())" 
						onerror="$(this).data('on-error', true).data('fp-ms-on-error', Date.now())" 
						data-fp-ms-inserido-no-buffer="${Date.now()}"
						src="${exibicaoComposicaoProvaTO.pathImagemTeste}">
				`);
			}

			if (exibicaoComposicaoProvaTO.pathAudioTeste != null) {

				this.addSubtitulo(this.getMsg("FP_FRONT_InicioPFVH_057"));

				this.addTexto(this.getMsg("FP_FRONT_InicioPFVH_058"));

				this.addEspacamentoHorizontal("10px");

				this.exibirAudioOuVideoQuestao({path: exibicaoComposicaoProvaTO.pathAudioTeste}, 'audio');

				this.append(`
					<div class="col-md-12">
						<div class="text-center" style="margin: 50px">
							<div class="btn-group">
				`);

				this.addBotao({
					label: this.getMsg("FP_FRONT_InicioPFVH_059"),
					classe: "btn-lg",
					onClick: () => {
						this.exibirAlerta({
							titulo: `<i class="fa fa-exclamation-circle"></i> ${this.getMsg("FP_FRONT_InicioPFVH_060")}`,
							msg: `
								${this.getMsg("FP_FRONT_InicioPFVH_061")}
								<ol>
									<li>${this.getMsg("FP_FRONT_InicioPFVH_062")}</li>
									<li>${this.getMsg("FP_FRONT_InicioPFVH_063")}</li>
									<li>${this.getMsg("FP_FRONT_InicioPFVH_064")}</li>
									<li>${this.getMsg("FP_FRONT_InicioPFVH_065")}</li>
									<li>${this.getMsg("FP_FRONT_InicioPFVH_066")}</li>
								</ol>
								${this.getMsg("FP_FRONT_InicioPFVH_067")}
							`
						});
					}
				});
				this.addBotao({
					label: `${this.getMsg("FP_FRONT_InicioPFVH_068")} &raquo;`,
					classe: "btn-primary btn-lg",
					onClick: () => {
						$("#botaoIniciarProva").trigger("click");
					}
				});

				this.append(`
							</div>
						</div>
					</div>
				`);
			}

			this.addEspacamentoHorizontal("10px");

			const botoes = [];

			if (exibicaoComposicaoProvaTO.pathPdfFolhaResposta) {

				let labelDownloadFR = this.getMsg("MSG_VH_APR_118");
				let labelDownloadProva = this.getMsg("MSG_VH_APR_119");
				let labelUpload = this.getMsg("MSG_VH_APR_120");

				if (isProvaSomenteDiscursiva) {
					labelDownloadFR = this.getMsg("MSG_VH_APR_121");
					labelDownloadProva = this.getMsg("MSG_VH_APR_122");
					labelUpload = this.getMsg("MSG_VH_APR_123");
				}

				botoes.push({
					label: " <i class='fa fa-download'></i> " + labelDownloadFR,
					css: "float: right",
					href: exibicaoComposicaoProvaTO.pathPdfFolhaResposta,
					classe: "btn-primary fp-link-aguardar-geracao-arquivo",
					ariaLabel: labelDownloadFR
				});
				botoes.push({
					label: " <i class='fa fa-download'></i> " + labelDownloadProva,
					css: "float: right",
					href: exibicaoComposicaoProvaTO.pathPdfProva,
					classe: "btn-primary fp-link-aguardar-geracao-arquivo",
					ariaLabel: labelDownloadProva
				});
				botoes.push({
					label: " <i class='fa fa-upload'></i> " + labelUpload,
					id: "botao_upload",
					css: "float: right",
					classe: "btn-success botao-upload-fr-discursiva",
					html: "agendamento-usuario='" + exibicaoComposicaoProvaTO.codAgendamentoUsuario + "'",
					ariaLabel: labelUpload,
					onClick: (event) => {
						aplicacaoProvaVH.exibirUploadFRIndividual(exibicaoComposicaoProvaTO.codAgendamentoUsuario, event.target);
					}
				});

			} else {
				botoes.push({
					id: "botaoIniciarProva", 
					label: this.getMsg("MSG_VH_APR_124") + " &raquo;", 
					css: "float: right", 
					classe: "btn-primary btn-lg",
					visivel: exibicaoComposicaoProvaTO.pathAudioTeste == null,
					ariaLabel: `${this.getMsg("MSG_VH_APR_124")} ${tituloProva}`,
					onClick: async (event) => {

						this.disable('botaoIniciarProva');

						if (await this.testeDeCarregamentoDeImagemFalhou(exibicaoComposicaoProvaTO.pathImagemTeste)) {
							this.enable('botaoIniciarProva');
							return;
						}

						if (exibicaoComposicaoProvaTO.listaControleCotaTO?.length) {
							const validarCotas = await controleCotaVH.validarCotas(exibicaoComposicaoProvaTO.listaControleCotaTO, exibicaoComposicaoProvaTO.codAgendamentoUsuario);
							if (!validarCotas) {
								this.enable('botaoIniciarProva');
								return;
							}
						}

						resolve();
						this.enable('botaoIniciarProva');
					}
				});
			}

			this.addGrupoBotoes({
				botoes,
				classe: "pull-right"
			});
		
			this.exibir({ isDeveFazerScrollParaTitulo: true });
			$("#fp_imagem_teste").data("fp-ms-inserido-no-dom", Date.now());
			this.focar("identificacaoConteudo");
		});
	}

	async handleErroTesteCarregamentoImagem(e?: Error) {
		this.logger.error(e?.message || "Não foi possível carregar a imagem de teste");
		await this.exibirAlerta({
			msg: this.getMsg("MSG_VH_APR_125"),
			botoes: [{
				label: `<i class='fa fa-refresh'></i>  ${this.getMsg("FP_FRONT_InicioPFVH_069")}`,
				onClick: () => document.location.reload()
			}]
		});
	}

	async testarCarregamentoImagem(url: string, timeout = 10000): Promise<HTMLImageElement> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			let timedOut = false;
			const inicio = Date.now();

			const timer = setTimeout(() => {
				timedOut = true;
				reject(new Error(`Deu timeout em ${Date.now() - inicio}ms`));
			}, timeout);

			img.onload = () => {
				if (!timedOut) {
					clearTimeout(timer);
					this.logger.info(`Carregou imagem em ${Date.now() - inicio}ms`);
					resolve(img);
				}
			};

			img.onerror = () => {
				if (!timedOut) {
					clearTimeout(timer);
					reject(new Error(`Deu onerror em ${Date.now() - inicio}ms`));
				}
			};

			img.src = url;
		});
	}
	
	addTokenCodigoAcesso(tokenCodigoAcesso: string) {
		if (!tokenCodigoAcesso) return;
		this.tokensCodigoAcesso = this.tokensCodigoAcesso || [];
		this.tokensCodigoAcesso.push(tokenCodigoAcesso);
	}

	getTokensCodigosAcesso() {
		return this.tokensCodigoAcesso || [];
	}
	
	async confirmarCodigoAcesso(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO): Promise<void> {

		if (![precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoWhatsAppOuEmail, precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoWhatsApp, precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoEmail].includes(true))  {
			return;
		}

		return new Promise(async (resolve, reject) => {

			let timeout = null;
			let podeProsseguir = false;

			const trocarMsgAlerta = async (msg) => {
				clearTimeout(timeout);
				const $alerta = $("#alerta_codigo_acesso");
				await $alerta.fadeOut();
				$alerta.html(msg)
				$alerta.fadeIn();
				timeout = setTimeout(() => $alerta.fadeOut(), 3000);
			}
	
			this.addPopup({
				width: "600px",
				titulo: this.getMsg("FP_FRONT_InicioPFVH_006"),
				onHidden: () => {
					if (podeProsseguir) {
						resolve();
					} else {
						reject(new Error("Não confirmou o código enviado"));
						UtilHash.carregarTelaInicial();
					}
				},
				botoes: [{
					label: `<i class='fa fa-redo'></i> ${this.getMsg("FP_FRONT_InicioPFVH_007")}`,
					css: `display: ${precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoWhatsAppOuEmail ? "none": ""}`,
					id: "botao-reenviar-codigo-acesso",
					onClick: async () => {
						const isConfirmacaoEmail = $("#confirmacao-email").length;

						const tokenCodigoAcessoEnviado = isConfirmacaoEmail 
							? await this.call("CodigoAcessoFCD/enviarEmailCodigoAcesso", precondicoesParaInicioDeProvaTO.codProvaFeita, true) 
							: await this.call("CodigoAcessoFCD/enviarWhatsAppCodigoAcesso", precondicoesParaInicioDeProvaTO.codProvaFeita, true) ;
						this.addTokenCodigoAcesso(tokenCodigoAcessoEnviado);

						await trocarMsgAlerta(this.getMsg("FP_FRONT_InicioPFVH_008", isConfirmacaoEmail ? "E-mail": "WhatsApp"));
						return false;
					}
				},{
					label: this.getMsg("FP_FRONT_InicioPFVH_073"), 
					id: "botao-confirmar-codigo-acesso", 
					classe: "btn-primary",
					css: `display: ${precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoWhatsAppOuEmail ? "none": ""}`,
					onClick: async () => {
						const codigo = this.getValor("codigo_acesso");
			
						if (this.isEmpty(codigo)) {
							const isEmail = $("#confirmacao-email").length
							await trocarMsgAlerta(this.getMsg("FP_FRONT_InicioPFVH_004", isEmail ? "E-mail": "WhatsApp"));
							return false;
						}
						
						try {
							const tokenCodigoAcessoAutorizado = await this.call("CodigoAcessoFCD/verificarCodigoAcesso", codigo, precondicoesParaInicioDeProvaTO.codProvaFeita, this.getTokensCodigosAcesso());
				
							if (this.isEmpty(tokenCodigoAcessoAutorizado)) {
								await trocarMsgAlerta("Código inválido.");
								return false;
							} else {
								this.addTokenCodigoAcesso(tokenCodigoAcessoAutorizado);			
								await trocarMsgAlerta(this.getMsg("FP_FRONT_InicioPFVH_005"));
								await this.sleep(1000);
								podeProsseguir = true;
								return true;
							}

						} catch (e) {
							this.logger.error("Erro na confirmação do código enviado por e-mail", e);
						}

						return false;
					}
				}]
			});

			this.addTexto("");
			this.addTexto(this.getMsg("FP_FRONT_InicioPFVH_017"));

			let html = "";

			if (precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoWhatsAppOuEmail) {
				const handleBotao = async (isEmail) => {
					this.logger.info("Enviando código de acesso via " + (isEmail ? "E-mail": "WhatsApp"));
					$('#confirmacao_codigo_acesso').html(isEmail ? this.getHtmlEnvioEmail() : this.getHtmlEnvioWhatsApp());
					this.show('codigo_acesso');
					this.show('botao-confirmar-codigo-acesso');
					this.show('botao-reenviar-codigo-acesso');
					const tokenCodigoAcessoEnviado = isEmail 
						? await this.call("CodigoAcessoFCD/enviarEmailCodigoAcesso", precondicoesParaInicioDeProvaTO.codProvaFeita, false) 
						: await this.call("CodigoAcessoFCD/enviarWhatsAppCodigoAcesso", precondicoesParaInicioDeProvaTO.codProvaFeita, false);
					this.addTokenCodigoAcesso(tokenCodigoAcessoEnviado);
					await trocarMsgAlerta(this.getMsg("FP_FRONT_InicioPFVH_070", (isEmail ? "E-mail": "WhatsApp")));
				}

				const btnWpp = this.addBotao({
					label: `<i class='fa fa-whatsapp'></i> this.getMsg("FP_FRONT_InicioPFVH_074")}`,
					onClick: async () => {
						await handleBotao(false);
					},
					retornarHtml: true
				});
				const btnEmail = this.addBotao({
					label: `<i class='fa fa-envelope'></i> ${this.getMsg("FP_FRONT_InicioPFVH_075")}`,
					onClick: async () => {
						await handleBotao(true);
					},
					retornarHtml: true
				})

				html = `
					<p class="col-md-12">
						${this.getMsg("FP_FRONT_InicioPFVH_071")}
					</p>
				
					<div class='col-md-12' style='display: flex; justify-content: center; margin-top: 20px'>
						${btnWpp}
						${btnEmail}
					</div>
				`;
			} else if(precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoWhatsApp) {
				const tokenCodigoAcessoEnviado = await this.call("CodigoAcessoFCD/enviarWhatsAppCodigoAcesso", precondicoesParaInicioDeProvaTO.codProvaFeita, false);
				this.addTokenCodigoAcesso(tokenCodigoAcessoEnviado);
				html = this.getHtmlEnvioWhatsApp();
			} else if(precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoEmail) {
				const tokenCodigoAcessoEnviado = await this.call("CodigoAcessoFCD/enviarEmailCodigoAcesso", precondicoesParaInicioDeProvaTO.codProvaFeita, false);
				this.addTokenCodigoAcesso(tokenCodigoAcessoEnviado);
				html = this.getHtmlEnvioEmail();
			}

			this.append(`
				<div id='confirmacao_codigo_acesso'>
					${html}
				</div>
			`)

			this.addCampoTexto({
				id: "codigo_acesso",
				label: this.getMsg("FP_FRONT_InicioPFVH_009"),
				mascara: "999-999",
				css: `padding: 20px 160px 20px 140px; width: 100%; font-size: 16px; ${precondicoesParaInicioDeProvaTO?.isAcessoRestritoVerificacaoWhatsAppOuEmail ? "display: none": ""}`,
				onEnter: () => $("#botao-confirmar-codigo-acesso").trigger("click")
			});
	
			this.append(`<div class="col-md-12" style="height: 80px">`);
			this.addTextoAlerta({
				id: "alerta_codigo_acesso",
				texto: "",
				css: "width: 100%; text-align: center;",
				visivel: false
			});
			this.append(`</div>`);
	
			this.exibirPopups();
	
			$("#codigo_acesso").css("font-size", "25px").css("text-align", "center").css("height", "50px");
			
			this.focar("codigo_acesso");
		});
	}


	getHtmlEnvioEmail() {
		return `<div class='col-md-12' id="confirmacao-email">
					<p>
						${this.getMsg("FP_FRONT_InicioPFVH_010", UtilEmail.esconderEnderecoCompleto(this.getEmailUsuarioLogado()))}
					</p>
					<br>
					<p>
						${this.getMsg("FP_FRONT_InicioPFVH_018", "E-mail")}
					</p>
				</div>`
	}

	getHtmlEnvioWhatsApp() {
		return `<div class='col-md-12' id="confirmacao-whats-app">
					<p>
						${this.getMsg("FP_FRONT_InicioPFVH_072", UtilTelefone.esconderTelefoneCompleto(this.getTelefoneUsuarioLogado()))}
					</p>
					<br>
					<p>
						${this.getMsg("FP_FRONT_InicioPFVH_018", "WhatsApp")}
					</p>
				</div>`
	}

	adicionarEventoParaProvaOffline() {
		let tempoLimiteOfflineProva = UtilCfg.getCfg('FUNCIONALIDADE_TEMPO_LIMITE_OFFLINE_PROVA');
		
		if (this.isEmpty(tempoLimiteOfflineProva)) return;

		tempoLimiteOfflineProva = Number(tempoLimiteOfflineProva);

		if (tempoLimiteOfflineProva < 2) return;

		setTimeout(async () => {
			const $aplicacaoProva = $('#divAplicacaoProva')
			const isDeveBloquearJaVisualizadas = $aplicacaoProva.data('fp-deve-bloquear-ja-visualizadas');
			if (isDeveBloquearJaVisualizadas) {
				const $questaoAtual = $aplicacaoProva.find("[questao]:visible");
				const isQuestaoVisualizada = $questaoAtual.data("fp-tempo-utilizado");
				if (isQuestaoVisualizada) {
					let $proximaQuestao = navegacaoPFVH.getQuestaoPosterior($questaoAtual);
					if ($proximaQuestao != null) {
						await navegacaoPFVH.handleClickBotaoAvancar();
					} else {
						await navegacaoPFVH.irParaQuestao();
					}
				}
			}
		}, 300);

		window.addEventListener('offline', () => {
			this.exibirAlerta({
				titulo: `<span class='text-danger'><i class='fa fa-exclamation-triangle text-danger'></i> ${this.getMsg('FP_FRONT_InicioPFVH_011')}</span>`,
				msg: this.getMsg("FP_FRONT_InicioPFVH_032", tempoLimiteOfflineProva),
				classe: 'modal-overlay',
				blockUICallback: async ($modalMsg) => {
					return new Promise<void>((resolve) => {
						const crono = new CronoTempoOfflineProvaVH();

						crono.iniciarCronometro(tempoLimiteOfflineProva * 1000, $('#sem-conexao-tempo-restante'), async () => {
							this.limpar();
							window.location.href = "/";
						});

						window.addEventListener('online', () => {
							resolve();
							crono.pararCronometro();
						})
					});
				}
			});
		});

		this.exibir();
	}

	async confirmarCodigoRotativoAplicacao(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO, codProvaFeita: number): Promise<void> {

		if (precondicoesParaInicioDeProvaTO?.isAcessoRestritroPorCodigoAcessoRotativo !== true) return;

		return new Promise<void>((resolve, reject) => {
			
			let timeout = null;
			let podeProsseguir = false;

			const trocarMsgAlerta = async (msg) => {
				clearTimeout(timeout);
				const $alerta = $("#alerta_codigo_acesso_rotativo");
				await $alerta.fadeOut();
				$alerta.html(msg)
				$alerta.fadeIn();
				timeout = setTimeout(() => $alerta.fadeOut(), 3000);
			}

			this.addPopup({
				width: "600px",
				id: "confirmacao_codigo_acesso",
				titulo: this.getMsg("FP_FRONT_InicioPFVH_006"),
				botoes: [{
					label: this.getMsg("FP_FRONT_InicioPFVH_038"),
					id: "botao-confirmar-codigo-acesso",
					classe: "btn-primary",
					onClick: async () => {
						const codigo = this.getValor("codigo_acesso");

						if (this.isEmpty(codigo)) {
							await trocarMsgAlerta(this.getMsg("FP_FRONT_InicioPFVH_034"));
							return false;
						}

						const isAprovado = await this.call("CodigoAcessoFCD/verificarCodigoAcessoRotativo", codProvaFeita, codigo);

						if (!isAprovado) {
							await trocarMsgAlerta(this.getMsg("FP_FRONT_InicioPFVH_037"));
							return false;
						}

						await trocarMsgAlerta(this.getMsg("FP_FRONT_InicioPFVH_005"));
						podeProsseguir = true;
						return true;
					}
				}],
				onHidden: () => {
					if (podeProsseguir) {
						resolve();
					} else {
						reject(new Error("Não passou pela confirmação do código rotativo"));
						UtilHash.carregarTelaInicial();
					}
				}
				// onHide: () => {
				// 	resolve();
				// }
			});

			this.addTexto("");
			this.addTexto(this.getMsg("FP_FRONT_InicioPFVH_035"));
			this.addTexto("");
			this.addTexto(this.getMsg("FP_FRONT_InicioPFVH_036"));

			this.addCampoTexto({
				id: "codigo_acesso",
				label: this.getMsg("FP_FRONT_InicioPFVH_009"),
				css: "padding: 20px 160px 20px 140px; width: 100%; font-size: 16px",
				onEnter: () => $("#botao-confirmar-codigo-acesso").trigger("click")
			});
			
			this.append(`<div class="col-md-12" style="height: 80px">`);
			this.addTextoAlerta({
				id: "alerta_codigo_acesso_rotativo",
				texto: "",
				css: "width: 100%; text-align: center;",
				visivel: false
			});
			this.append(`</div>`);

			this.exibirPopups();

			$("#codigo_acesso").css("font-size", "25px").css("text-align", "center").css("height", "50px");

			this.focar("codigo_acesso");
		});
	}

	async testeDeCarregamentoDeImagemFalhou(pathImagemTeste: string): Promise<boolean> {

		const $fpImagemTeste = $("#fp_imagem_teste");

		if ($fpImagemTeste.length !== 1) return false;

		if (pathImagemTeste) {
			try {
				await this.testarCarregamentoImagem(pathImagemTeste + "?ts=" + Date.now());
			} catch (e) {
				await this.handleErroTesteCarregamentoImagem(e);
				return true;
			}
		}

		const msBuffer = $fpImagemTeste.data("fp-ms-inserido-no-buffer");
		const msDom = $fpImagemTeste.data("fp-ms-inserido-no-dom");
		const msOnLoad = $fpImagemTeste.data("fp-ms-on-load");
		const msOnError = $fpImagemTeste.data("fp-ms-on-error");
		const msgTempos = [];

		try {
			msgTempos.push("Buffer > DOM em " + (msDom - msBuffer) + "ms");
			if (msOnLoad > 0) {
				msgTempos.push("DOM > on-load em " + (msOnLoad - msDom) + "ms");
			}
			if (msOnError > 0) {
				msgTempos.push("DOM > on-error em " + (msOnError - msDom) + "ms");
			}
			this.logger.info(msgTempos.join(", "));
		} catch (ignored) {};

		if ($fpImagemTeste.data("on-error")) {
			await this.handleErroTesteCarregamentoImagem(new Error('Erro no caregamento da imagem de teste pela tag img.'));
			return true;
		}

		
		if (!$fpImagemTeste.data("on-load-ok")) {
			await this.handleErroTesteCarregamentoImagem(new Error("Não encontrou o on-load-ok que indica que o onload foi chamado."));
			return true;
		}

		const img = $fpImagemTeste.get(0);
		const isImagemDeTesteCarregada = ((img.width == 10 && img.height == 11) || (img.naturalWidth == 10 && img.naturalHeight == 11));

		if (!isImagemDeTesteCarregada) {
			await this.handleErroTesteCarregamentoImagem(new Error(
				`Size da imagem de teste deu como ${img.width}x${img.height} (natural: ${img.naturalWidth}x${img.naturalHeight}) mas devia ser 10x11`
			));
			return true;
		}

		return false;
	}

	// checkHashPagina(codProvaFeita: any) {
	// 	try {
	// 		const hash = window.location.hash;
	// 		if(codProvaFeita && (hash === '' || hash === null)){
	// 			window.location.hash = `/apre/${codProvaFeita}`;
	// 		}
	// 	} catch (e){}
	// }

	popupFilaGeracao(geracaoProvaFeitaTO){
		let attempts = 0;
		let timeLeft = 30;

		this.addPopup({
			width: "50%",
			id: "fila_geracao_prova",
			titulo: this.getMsg("FP_FRONT_InicioPFVH_019")
		});

		this.append(`
			<div class="col-md-12 container-fila" id="container-fila">
				<span id="msg-fila-prova">${this.getMsg("FP_FRONT_InicioPFVH_021")}</span>
				<div class="container-timer" id="container-timer">
					<span>${this.getMsg("FP_FRONT_InicioPFVH_024")}</span>
					<span id="timeLeft" class="bolder">${timeLeft}</span>
					<span>${this.getMsg("FP_FRONT_InicioPFVH_025")}</span>
				</div>
				<span><strong>${this.getMsg("FP_FRONT_InicioPFVH_020")}</strong></span>
			</div>
		`);

		this.exibirPopups();

		setTimeout(async () => {
			await this.startCronFilaProva(timeLeft, attempts, geracaoProvaFeitaTO);
		}, 200);
	}

	startCronFilaProva(timeLeft, attempts, geracaoProvaFeitaTO){
		let intervalTimer = setInterval(async () => {
			if(timeLeft <= 0){
				timeLeft = 30;
				attempts += 1;
				this.setTexto("timeLeft", timeLeft);
				clearInterval(intervalTimer);

				const resultadoGeracaoPFTO: ResultadoGeracaoPFTO = await this.call("AplicacaoProvaFCD/gerarProvaFeita", geracaoProvaFeitaTO);

				if (resultadoGeracaoPFTO.codProvaFeita != null) {
					await this.verificarPrecondicoesParaInicioDePF(resultadoGeracaoPFTO.codProvaFeita);
					await this.fecharPopup("fila_geracao_prova");
					return;
				} else if (resultadoGeracaoPFTO.isGeracaoDuplicada === true) {
					await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_InicioPFVH_015") });
					await this.fecharPopup("fila_geracao_prova");
					return;
				} else if (resultadoGeracaoPFTO.isGeracaoEmFila === true) {
					this.startCronFilaProva(timeLeft, attempts, geracaoProvaFeitaTO);
					this.updateMsgFilaProva(attempts);
				}
			}
			else{
				timeLeft -= 1;
				this.setTexto("timeLeft", timeLeft);
			}
		}, 1000);
	}

	updateMsgFilaProva(attempts){
		let msg: string;
		switch (true){
			case attempts === 1:
				msg = this.getMsg("FP_FRONT_InicioPFVH_022");
				break;
			case attempts >= 2:
				msg = this.getMsg("FP_FRONT_InicioPFVH_023");
				break;
			default:
				msg = this.getMsg("FP_FRONT_InicioPFVH_021");
				break;
		}

		this.setTexto("msg-fila-prova", msg);
	}

	async ativarInicioProctoring(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO): Promise<void> {

		if (precondicoesParaInicioDeProvaTO?.isFiscalizacaoMonitoradaHabilitada !== true) return;
		if (fiscalizacaoMonitoradaVH.isIniciada()) return;

		return new Promise<void>((resolve) => {
			fiscalizacaoMonitoradaVH.iniciarMonitoramento({
				codAgendamentoUsuario: precondicoesParaInicioDeProvaTO.codAgendamentoUsuario,
				codProvaFeita: precondicoesParaInicioDeProvaTO.codProvaFeita,
				onMonitoramentoIniciado: async () => {
					resolve();
				},
				onMonitoramentoInterrompido: () => {
					encerramentoPFVH.onFiscalizacaoMonitoradaInterrompida();
				},
				onErroMonitoramento: (msgErroParaUsuario, msgErroParaAuditoria, msgErroIntegracaoParaAuditoria, codProvaFeita, codAgendamentoUsuario) => {
					pausaPFVH.onErroFiscalizacaoMonitorada(msgErroParaUsuario, msgErroParaAuditoria, msgErroIntegracaoParaAuditoria, codProvaFeita, codAgendamentoUsuario);
				},
				onMonitoramentoFinalizado: (msgErroParaUsuario, msgErroParaAuditoria, msgErroIntegracaoParaAuditoria, codProvaFeita, codAgendamentoUsuario) => {
					pausaPFVH.onErroFiscalizacaoMonitorada(msgErroParaUsuario, msgErroParaAuditoria, msgErroIntegracaoParaAuditoria, codProvaFeita, codAgendamentoUsuario);
				},
				onEventoParaRegistroEmLog: (msgErro, msgErroIntegracao, codProvaFeita, codAgendamentoUsuario) => {
					monitoramentoPFVH.onEventoParaRegistroEmLog(msgErro, msgErroIntegracao, codProvaFeita, codAgendamentoUsuario);
				},
			});
		});
	}

	async perguntarOpcaoSecaoOptativaParaAvaliado(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO): Promise<number> {

		if (!precondicoesParaInicioDeProvaTO?.listaSecaoOptativaTO?.length) return null;

		return new Promise((resolve) => {
			this.limpar();
			this.setTitulo(precondicoesParaInicioDeProvaTO.tituloProva);
			this.setSubtitulo(this.getMsg("MSG_VH_APR_66"));
	
			this.addTexto({
				classe: "lead", 
				texto: this.getMsg("MSG_VH_APR_67") + " " + precondicoesParaInicioDeProvaTO.listaSecaoOptativaTO.length + " " + this.getMsg("MSG_VH_APR_68") + ":"
			});
	
			this.append("<div class='well col-md-9'>");
	
			let devePreexibirQuestoes = false;
	
			for (const secaoOptativaTO of precondicoesParaInicioDeProvaTO.listaSecaoOptativaTO) {
	
				this.addBotao({
					label: secaoOptativaTO.nome,
					classe: "btn-primary btn-lg btn-block",
					onClick: () => {
						resolve(secaoOptativaTO.codSecao);
					}
				});
	
				devePreexibirQuestoes = devePreexibirQuestoes || (secaoOptativaTO.listaExibicaoQuestaoTO.length > 0);
			}
	
			this.append("</div>");
	
			if (devePreexibirQuestoes) {
	
				this.addSubtitulo(this.getMsg("MSG_VH_APR_69"));
	
				for (const secaoOptativaTO of precondicoesParaInicioDeProvaTO.listaSecaoOptativaTO) {
					for (let j = 0; j < secaoOptativaTO.listaExibicaoQuestaoTO.length; j++) {
						let exibicaoQuestaoTO = secaoOptativaTO.listaExibicaoQuestaoTO[j];
						exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO, {
							isExibirNomeSecao: j == 0
						});
					}
				}
			}
	
			this.exibir();
		});
	}

	async bloquearSmartphones(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO) {
		
		if (precondicoesParaInicioDeProvaTO?.isBloquearSmartphones !== true) return;

		if (!this.isAndroidDesktopMode()) return;

		await this.exibirAlerta({ msg: this.getMsg("MSG_VH_APR_193") });
		
		throw new Error("Não é possível acessar esta prova com Android em desktop mode");
	}

	async confirmarDadosAvaliado(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO) {

		if (precondicoesParaInicioDeProvaTO?.exibirConfirmacaoDadosCandidato !== true) return;

		const nomeCandidato = this.getNomeUsuarioLogado();
		const emailCandidato = this.getEmailUsuarioLogado();
		const dadosUsuario: string[] = [];

		dadosUsuario.push(`
			${this.getMsg("FP_FRONT_InicioPFVH_055")}:
			<br>
			<strong style="font-size: 120%">${nomeCandidato}</strong>
		`);

		if (emailCandidato) {
			dadosUsuario.push(`
				${this.getMsg("FP_FRONT_InicioPFVH_056")}:
				<br>
				<strong style="font-size: 120%">${emailCandidato}</strong>
			`);
		}

		let isConfirmouConta = false;
		let isDeslogou = false;
		
		await this.exibirAlerta({
			titulo: `<i class='fa fa-exclamation-triangle'></i> ${this.getMsg("FP_FRONT_InicioPFVH_051")}`,
			msg: `
				<p>${this.getMsg("FP_FRONT_InicioPFVH_052")}</p>
				
				<div class="row">
					${dadosUsuario.join("<br><br>")}
				</div>
			`,
			botoes: [{
				label: this.getMsg("FP_FRONT_InicioPFVH_053"),
				onClick: async () => {
					this.logger.info("O candidato não confirmou os dados antes do início da prova.");
					isDeslogou = true;
					await loginVH.onLogout(true);
				}
			},{
				label: this.getMsg("FP_FRONT_InicioPFVH_054"),
				classe: 'btn-primary',
				onClick: async () => {
					this.logger.info("O candidato confirmou seus dados. Seguindo o fluxo.");
					isConfirmouConta = true;
				}
			}]
		});

		if (isDeslogou) {
			// está recarregando a página
			await AmaisVH.DEAD_PROMISE;
		}

		if (!isConfirmouConta) {
			this.logger.warn("Prova não iniciada. O candidato não confirmou os dados.");
			await AmaisVH.DEAD_PROMISE;
		}
	}
}

const inicioPFVH = new InicioPFVH();

class ResultadoGeracaoPFTO {
	codProvaFeita: number;
	isGeracaoDuplicada: boolean;
	isGeracaoEmFila: boolean;
}
class GeracaoProvaFeitaTO {
	codProva: number;
	codAgendamentoUsuario: number;
	codSecaoOptativaEscolhida: number;
	codUsuarioAvaliado: number;
	isFiscalizacaoMonitoradaHabilitada: boolean;
	codsDisciplinasQueDeveResponder: Set<number>;
	numQuestoesSorteioMinimoPorSecao: Map<number, number>;
}
type AplicacaoProvaFeitaTO = {
	codProvaFeita: number;
	codUsuario: number;
	isAplicacaoCorrecaoFR: boolean;
	titulo: string;
	tituloPublico: string;
	nomeProfessorResponsavel: string;
	isQuestaoPorQuestao: boolean;
	nota: number;
	milisegundosRestantesParaProva: number;
	codProvaFeitaLogUltimoKA: number;
	tempoParaResposta: number;
	deveEncerrarProvaPorTempoEsgotado: boolean;
	numQuestoesNaProva: number;
	codAgendamento: number;
	codAgendamentoUsuario: number;
	permitirExportacaoDOCXParaAluno: boolean;
	segundosToleranciaMonitoramento: number;
	isAnularAutomaticamenteSaidaNavegador: boolean;
	isBloquearQuestoesJaRespondidas: boolean;
	isBloquearQuestoesJaVisualizadas: boolean;
	codRespostaQuestaoVisualizadaPorUltimo: number;
	isBloquearConclusaoComEmBranco: boolean;
	htmlDaProva: string;
	numeracaoInicial: number;
	isPermitirMarcarQuestaoParaRecursoAposProva: boolean;
	isPermitirRecursosDuranteProva: boolean;
	tipoCronometro: string;
	mapGabarito: object;
	listaAplicacaoSecaoFeitaTO: AplicacaoSecaoFeitaTO[];
	collectionExibicaoQuestaoTO: any[];
	listaTemposRespostaSecao: any[];
	isBloquearCorretorOrtografico: boolean;
	isAnularDiscursivasMinLinhas: boolean;
	situacaoFacematchRevalidacaoTO: SituacaoFacematchRevalidacaoTO;
}
type AplicacaoSecaoFeitaTO = {
	numSecao: number;
	nomeSecao: string;
	listaRespostaParaPainelTO: RespostaParaPainelTO[];
}
type RespostaParaPainelTO = {
	numerador: string;
	resposta: string;
	possuiUploadResposta: boolean;
	isAnulada: boolean;
	isTempoEsgotado: boolean;
}
type PrecondicoesParaInicioDeProvaTO = {
	codProva: number;
	codAgendamentoUsuario: number;
	codProvaFeita: number;
	tipoAplicacao: TipoAplicacao;
	tituloProva: string;
	tituloProvaPublico: string;
	isFiscalizacaoMonitoradaHabilitada: boolean;
	dataInicioPF: string;
	listaSecaoOptativaTO: any[];
	situacaoFacematchValidacaoTO: SituacaoFacematchValidacaoTO;
	isPFEncerrada: boolean;
	possuiQuestoesDiscursivas: boolean;
	exibirConfirmacaoDadosCandidato: boolean;
	isReaberturaVigente: boolean;
	isAcessoRestritroPorCodigoAcessoRotativo: boolean;
	isAcessoRestritoVerificacaoEmail: boolean;
	isAcessoRestritoVerificacaoWhatsApp: boolean;
	isAcessoRestritoVerificacaoWhatsAppOuEmail: boolean;
	isBloquearSmartphones: boolean;
	isFullScreen: boolean;
	idAplicacaoQuestPreProva: number;
	idAplicacaoQuestFormularioComplementar: number;
}