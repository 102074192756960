class CadastroChecklistVH extends AmaisVH {

    constructor() {
        super(CadastroChecklistVH.name);
        this.addOperacaoParaHash("cchll", this.exibirListagem);
    }

    async exibirListagem() {

        UtilHash.registrarHistorico(this.exibirListagem);

        this.limpar();

        this.setTitulo(this.getMsg("FP_FRONT_CadastroChecklistVH_001"));

        this.setIdTarget("tituloSuperiorDireito");
        this.append("<div class='btn-group'>");
        if (UtilAuth.possuiAcesso(TipoFuncionalidade.CHECKLIST_CRIACAO)) {
            this.addBotao({
                label: "<i class='fa fa-plus-circle'></i> Novo CheckList",
                classe: "btn-success btn-sm",
                onClick: async () => await cadastroChecklistVH.editar()
            });
        }
        this.append("</div>");
        this.setIdTarget(null);

        let listaChecklistTO = await this.call("CadastroChecklistFCD/listar");

        const colunas: ColunaAddTabela[] = [];
        colunas.push({titulo: this.getMsg("FP_FRONT_CadastroChecklistVH_002"), prop: "nome"});
        colunas.push({titulo: this.getMsg("FP_FRONT_CadastroChecklistVH_003"), prop: "numItens"});

        await this.addTabela({
            collection: listaChecklistTO,
            propId: "id",
            colunas: colunas,
            onEdicao: async (codChecklist) => await this.editar(codChecklist),
            ordenacao: [["nome", 0]]
        });

        this.exibir();
    }

    async editar(codChecklist?) {

        const edicaoCheckListTO = await this.call("CadastroChecklistFCD/recuperar", codChecklist);

        let itemsCheckList = [];

        const isNovoItem = !codChecklist;

        let botoes = [];
        let countItens = 0;

        botoes.push({
            label: this.getMsg("FP_FRONT_CadastroChecklistVH_010"),
            classe: "btn-warning",
        });

        if(isNovoItem){
            botoes.push({
                label: this.getMsg("FP_FRONT_CadastroChecklistVH_005"),
                classe: "btn-primary",
                onClick: async (botao) => {
                    this.verificarObrigatorios(botao);
                    
                    if(itemsCheckList.length === 0) {
                        this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroChecklistVH_011") });
                        return false;
                    }
                    else {
                        const salvamentoChecklistTO = {
                            codChecklist: codChecklist,
                            nome: this.getValor("nome_checklist"),
                            listaCheckListItemTO: itemsCheckList.map(to => {
                                return {
                                    codChecklistItem: to.codChecklistItem,
                                    nome: to.nome,
                                    isObrigatorio: to.isObrigatorio
                                }
                            })
                        }

                        await this.call("CadastroChecklistFCD/salvar", salvamentoChecklistTO);

                        await cadastroChecklistVH.exibirListagem();
                    }
                }
            });
        }

        this.addPopup({
            titulo: this.getMsg("FP_FRONT_CadastroChecklistVH_004"),
            width: '50%',
            botoes: botoes
        });

        this.addCampoTexto({
            id: "nome_checklist",
            label: this.getMsg("FP_FRONT_CadastroChecklistVH_006"),
            valor: edicaoCheckListTO ? edicaoCheckListTO.nome: '',
            obrigatorio: true,
            habilitado: isNovoItem,
            classe: "col-md-8"
        });

        this.addSubtitulo({
            texto: this.getMsg("FP_FRONT_CadastroChecklistVH_007"),
            css: "font-size: medium;"
        });

        this.append(`
            <div class='row col-md-12'>
            <div class='col-md-12' id='itens-checklist' style='display:grid;margin-bottom: 10px'></div>
        `);

        if(isNovoItem){

            this.addCampoTexto({
                id: "novo_item_checklist",
                valor: '',
                classe: "col-md-6"
            });

            this.addCheckbox({
                id: "isObrigatorio",
                label: this.getMsg("FP_FRONT_CadastroChecklistVH_012"),
                valor: true,
                classe: "col-md-2",
                css: "display: flex;align-items: center;min-height: 32px;"
            });

            this.addBotao({
                label: this.getMsg("FP_FRONT_CadastroChecklistVH_008"),
                onClick: () => {
                    let name = this.getValor("novo_item_checklist");
                    let isObrigatorio = this.getValor("isObrigatorio");
                    if(!this.isEmpty(name)){
                        addItemLista({
                            codChecklistItem: null,
                            nome: name,
                            isObrigatorio: isObrigatorio
                        });
                        updateEventItens();
                        this.setValor("novo_item_checklist", "");
                        this.setValor("isObrigatorio", true);
                    }
                },
                classe: "btn-primary btn-sm col-md-2"
            });
        }

        setTimeout(() => {
            edicaoCheckListTO.listaEdicaoCheckListItemTO.forEach(item => {
                addItemLista(item);
            });
            updateEventItens();
        }, 500);

        const addItemLista = (item) => {
            itemsCheckList.push({
                codChecklistItem: item.codChecklistItem,
                nome: item.nome,
                isObrigatorio: item.isObrigatorio
            });

            if(isNovoItem){
                $('#itens-checklist').append(
                    `<div item-checklist style="margin: 5px 0;">
                        <a style='${!isNovoItem ? "display:none;":""}' cod-item='${item.codChecklistItem}' name-item='${item.nome}' class='btn btn-default delete-item' data-placement='right' title='${this.getMsg("FP_FRONT_CadastroChecklistVH_009")}'>
                            <i cod-item='${item.codChecklistItem}' name-item='${item.nome}' class='fa fa-trash fa-sm' ></i>
                        </a>
                        <span style="padding: 5px 0 0 5px;">${item.nome} ${!item.isObrigatorio ? "(" + this.getMsg('FP_FRONT_CadastroChecklistVH_013') + ")": ""}</span>
                    </div>`
                );
            }
            else{
                $('#itens-checklist').append(
                    `<div item-checklist style="margin: 10px 0;">
                        <strong><span style="padding: 5px 0 0 5px;">${countItens + 1}) </span></strong>
                        <span style="padding: 5px 0 0 5px;">${item.nome} ${!item.isObrigatorio ? "(" + this.getMsg('FP_FRONT_CadastroChecklistVH_013')+ ")": ""}</span>
                    </div>`
                );
            }

            countItens ++;
        }

        const updateEventItens = () => {
            $('#itens-checklist').find(".delete-item").on("click", ({target}) => removerItemLista(target));
        }

        const removerItemLista = (item) => {
            let codItem = $(item).attr("cod-item");
            let nameItem = $(item).attr("name-item");
            if(codItem !== 'null'){
                itemsCheckList = itemsCheckList.filter((item) => {
                    return item.codChecklistItem !== codItem;
                });
            }
            else if(nameItem !== 'null'){
                itemsCheckList = itemsCheckList.filter((item) => {
                    return item.nome !== nameItem;
                });
            }
            $(item).closest("[item-checklist]").remove();

            countItens --;
        }

        this.append("</div>");
        this.exibirPopups();
    }
}

const cadastroChecklistVH = new CadastroChecklistVH();