class CadastroRoteiroVH extends AmaisVH {

	constructor() {
		super(CadastroRoteiroVH.name);
		this.addOperacaoParaHash("crlr", this.listarRoteiros);
		this.addOperacaoParaHash("crer", this.editarRoteiro);
	}
 
	async listarRoteiros() {

		UtilHash.registrarHistorico(this.listarRoteiros);

		const listaListagemRoteiroTO: ListagemRoteiroTO[] = await this.call("CadastroRoteiroFCD/listarRoteiros");

		this.limpar();

		this.setTitulo(this.getCfg("LABEL_ROTEIRO_PLURAL"));

		await this.addTabela({
			collection: listaListagemRoteiroTO,
			propId: "codRoteiro",
			colunas: [{
				titulo: "Nome", 
				prop: "nomeRoteiro"
			}, {
				titulo: "Segmento", 
				prop: "nomeSegmento",
				regraExibicao: () => this.getIsAmbienteSegmentado()
			}, {
				titulo: "Nota máxima", 
				prop: "notaMaxima",
				formato: "NUMERO"
			}],
			labelNovo: this.getMsg("FP_FRONT_CadastroRoteiroVH_001"),
			onEdicao: async (codRoteiro) => await this.editarRoteiro(codRoteiro)
		})
		
		this.exibir();
	}

	async editarRoteiro(codRoteiro: number) {

		UtilHash.registrarHistorico(this.editarRoteiro, codRoteiro);

		const edicaoRoteiroTO: EdicaoRoteiroTO = await this.call("CadastroRoteiroFCD/recuperar", codRoteiro);

		this.limpar();

		this.setTitulo(edicaoRoteiroTO.nomeRoteiro)
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroRoteiroVH_002"));

		this.addCampoTexto({
			id: "nomeRoteiro", 
			label: "Nome", 
			obrigatorio: true,
			valor: edicaoRoteiroTO.nomeRoteiro,
			classe: "col-md-4",
		});
		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				id: "codEmpresa",
				label: "Segmento",
				obrigatorio: true,
				classe: "col-md-4",
				valor: edicaoRoteiroTO.codEmpresa,
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarSegmentos",
					numMinimoCaracteres: 0,
				},
			});
		}
		this.addCampoTexto({
			id: "notaMaxima", 
			label: "Nota máxima", 
			obrigatorio: true,
			valor: edicaoRoteiroTO.notaMaxima,
			tipo: "NUMERO",
			classe: "col-md-4",
		});
		this.addEditorHTML({
			id: "orientacoesRoteiro", 
			label: "Orientações", 
			obrigatorio: true,
			valor: edicaoRoteiroTO.orientacoesRoteiro,
			classe: "col-md-8",
		});

		this.addEspacamentoHorizontal();

		if (edicaoRoteiroTO.nomeUsuarioCriacao) {
			this.addCampoExibicao({
				label: "Criado por",
				valor: edicaoRoteiroTO.nomeUsuarioCriacao
			});
		}
		if (edicaoRoteiroTO.dataCriacao) {
			this.addCampoExibicao({
				label: "Data de criação",
				valor: edicaoRoteiroTO.dataCriacao,
				formato: "DD/MM/YYYY HH:mm"
			});
		}
		if (edicaoRoteiroTO.dataAtualizacao) {
			this.addCampoExibicao({
				label: "Data de atualização",
				valor: edicaoRoteiroTO.dataAtualizacao,
				formato: "DD/MM/YYYY HH:mm"
			});
		}

		this.addEspacamentoHorizontal();

		this.addBotao({
			label: this.getMsg("MSG_VH_008"), 
			css: "float: right", 
			amarrarCampos: ["nome"], 
			onClick: async () => {

				this.verificarObrigatorios();
				
				const salvamentoRoteiroTO: SalvamentoRoteiroTO = {
					codRoteiro: codRoteiro,
					nomeRoteiro: this.getValor("nomeRoteiro"),
					codEmpresa: this.getValor("codEmpresa"),
					notaMaxima: this.getValor("notaMaxima"),
					orientacoesRoteiro: this.getValor("orientacoesRoteiro"),
				}
				
				await this.call("CadastroRoteiroFCD/salvar", salvamentoRoteiroTO);
				await this.listarRoteiros();
			}
		});
		
		this.addBotao({
			label: this.getMsg("MSG_VH_006"), 
			classe: "btn-danger", 
			css: "float: right", 
			onClick: async () => {
				this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_CadastroRoteiroVH_003"),
					botoes: [{
						label: this.getMsg("MSG_VH_006"), 
						classe: "btn-danger",
						onClick: async () => {
							await this.call("CadastroRoteiroFCD/toggleArquivar", codRoteiro);
							await this.listarRoteiros();
						}
					}],
				});
			}
		});
		
		this.exibir();
	}
}

const cadastroRoteiroVH = new CadastroRoteiroVH();

type ListagemRoteiroTO = {
	codRoteiro: number;
	nomeRoteiro: string;
	nomeSegmento: string;
	notaMaxima: number;
}
type EdicaoRoteiroTO = {
	codRoteiro: number;
	nomeRoteiro: string;
	codEmpresa: number;
	notaMaxima: number;
	orientacoesRoteiro: string;
	nomeUsuarioCriacao: string;
	dataCriacao: string;
	dataAtualizacao: string;
}
type SalvamentoRoteiroTO = {
	codRoteiro: number;
	nomeRoteiro: string;
	codEmpresa: number;
	notaMaxima: number;
	orientacoesRoteiro: string;
}