class RelatorioProvasVH extends DataWarehouseVH {

	listandoProvasMontadas: boolean;
	codProvaEmExibicao: number;
	filtrosRelNotasEPontuacoesTO: any;
	exibicaoListagemProvaFeitaTO: any;
	mapListagemProvaFeitaTO: any[];
	listagemProvaFeitaTO: any;
	isOrdenarQuestoesPelaProva: boolean;
	ordenacaoProvasRespondidas: any;
	ordenacaoDetalhamentoProvasRespondidas: any[][];
	isExibirNotasEPontuacoes = true;
	valorPadraoIsExibirTodasTentativas: boolean; // usado pelo fp-custom

	constructor() {
		super(RelatorioProvasVH.name);
		this.addOperacaoParaHash("rprc", this.exibirRelatorioCustomizados);
		this.addOperacaoParaHash("rppr", this.exibirAbasProvasRespondidas);
		this.addOperacaoParaHash("rpprd", this.exibirDetalhamentoProvasRespondidas);
	}

	filtroRelatorio = null;

	async exibirTentativas(codAgendamentoUsuario) {
		const exibicaoTentativasAUTO = await this.call("RelatorioProvasFCD/recuperarTentativasAgendamentoUsuario", codAgendamentoUsuario);

		let podeAnularProvasRespondidas = UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_APLICACAO_ANULACAO);
		
		this.addPopup({
			id: "popupTentativas",
			titulo: exibicaoTentativasAUTO.nomeAvaliado,
			subtitulo: this.getMsg("FP_FRONT_RelatorioProvasVH_027", exibicaoTentativasAUTO.codAgendamento, exibicaoTentativasAUTO.tituloProva),
			width: "900px",
			// height: "600px"
		});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "ID", prop: (tentativaAUTO) => {
				let oficial = "";
				if (exibicaoTentativasAUTO.codProvaFeitaConsiderada == tentativaAUTO.codProvaFeita) {
					oficial = `<br><strong>${this.getMsg("FP_FRONT_RelatorioProvasVH_028")}</strong>`;
				}
				return `<small>#${tentativaAUTO.codProvaFeita}${oficial}</small>`;
			}
		});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_32"), prop: "dataFim", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_33"), prop: "nota", formato: "numero"});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_34"), prop: "notaMaxima", formato: "numero"});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_37"), prop: "pontuacaoObtida", formato: "numero"});
		colunas.push({titulo: this.getMsg("MSG_VH_RP_35"), prop: "pontuacaoMaxima", formato: "numero"});
		colunas.push({titulo: "", prop: this.montarColunaLinkNovaAbaProvaFeita});
		colunas.push({titulo: "", prop:  (tentativaAUTO) => {
				let h = [];
				if (podeAnularProvasRespondidas) {
					if (tentativaAUTO.isAnulada) {
						h.push(this.addBotao({
							label: this.getMsg("FP_FRONT_RelatorioProvasVH_032"), classe: "btn-sm", dica: this.getMsg("FP_FRONT_RelatorioProvasVH_029"), retornarHtml: true, onClick: () => {
								this.fecharPopup("popupTentativas");
								gestaoPFVH.removerAnulacaoProvaRespondida(tentativaAUTO.codProvaFeita, () => {
									this.exibirTentativas(codAgendamentoUsuario);
								});
							}
						}));
					} else {
						h.push(this.addBotao({
							label: this.getMsg("FP_FRONT_RelatorioProvasVH_033"), classe: "btn-sm", dica: this.getMsg("FP_FRONT_RelatorioProvasVH_030"), retornarHtml: true, onClick: () => {
								this.fecharPopup("popupTentativas");
								gestaoPFVH.anularProvaRespondida(tentativaAUTO.codProvaFeita, () => {
									this.exibirTentativas(codAgendamentoUsuario);
								});
							}
						}));
					}
				}
				if (podeAnularProvasRespondidas && !tentativaAUTO.isArquivada) {
					h.push(this.addBotao({
						label: "<i class='fa fa-trash'></i>", classe: "btn-sm", dica: this.getMsg("FP_FRONT_RelatorioProvasVH_031"), retornarHtml: true, onClick: () => {
							this.fecharPopup("popupTentativas");
							gestaoPFVH.arquivarProvaFeita(tentativaAUTO.codProvaFeita, () => {
								this.exibirTentativas(codAgendamentoUsuario);
							});
						}
					}));
				}
				return "<div class='btn-group-vertical'>" + h.join("") + "</div>";
			}
		});

		await this.addTabela({
			collection: exibicaoTentativasAUTO.listaTentativaAUTO,
			titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_034"),
			colunas: colunas,
		});

		this.exibirPopups();
	}

	montarColunaLinkNovaAbaProvaFeita(tentativaAUTO) {
		let h = [];

		if (tentativaAUTO.nomeFase) {
			h.push(`<span style="text-wrap: nowrap">${tentativaAUTO.nomeFase}</span>`);
		}
		if (tentativaAUTO.isAnulada) {
			h.push(`<span class='label label-danger'>${this.getMsg("FP_FRONT_RelatorioProvasVH_035")}</span>`);
		}
		if (tentativaAUTO.isArquivada) {
			h.push(`<span class='label label-default'>${this.getMsg("FP_FRONT_RelatorioProvasVH_036")}</span>`);
		}
		if (tentativaAUTO.isCadernoPreGerado) {
			h.push(`<span class='label label-warning'>${this.getMsg("FP_FRONT_RelatorioProvasVH_096")}</span>`);
		}
		h.push(this.addLink({
			retornarHtml: true,
			label: this.getMsg("MSG_VH_RP_155"),
			css: "padding: 0px",
			onClick: () => aplicacaoProvaVH.exibirDetalhesProvaRealizada(tentativaAUTO.codProvaFeita)
		}));
		return h.join("<br>");
	}

	async exibirRelatorioMarcacaoAvaliados(codProva: number, possuiTentativasOuReaplicacao: boolean) {

		const filtrosRelMarcacoesTO = await this.call("RelatorioProvasFCD/recuperarFiltrosRelMarcacoes");

		this.limpar(true);

		this.abrirAbaAccordion({ titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_053"), aberta: true });

		this.addFormulario();

		await this.addSelect({
			id: "infosAvaliados",
			collection: filtrosRelMarcacoesTO.listaInfosAvaliados,
			label: this.getMsg("MSG_VH_RP_72"),
			multiplo: true,
			classe: "col-md-3",
			valor: ["CPF"],
		});

		await this.addSelect({
			collection: [{ 
				id: "PROVA", 
				nome: this.getMsg("FP_FRONT_RelatorioProvasVH_119") 
			}, { 
				id: "CADERNO", 
				nome: this.getMsg("FP_FRONT_RelatorioProvasVH_120") 
			}],
			id: "isOrdenarQuestoesPelaProva",
			label: this.getMsg("FP_FRONT_RelatorioProvasVH_054"),
			valor: "PROVA",
			obrigatorio: true
		});

		this.addCheckbox({
			id: "isExibirTodasTentativas",
			label: this.getMsg("FP_FRONT_RelatorioProvasVH_118"),
			valor: possuiTentativasOuReaplicacao
		});

		this.addEspacamentoHorizontal("1px");

		this.append("<div class='col-md-12'>");

		this.addBotao({
			label: "Gerar",
			classe: "btn-primary pull-right",
			onClick: () => {
				this.atualizarTabelaRelMarcacoes(this.getCfgsRelMarcacoesTO(codProva));
			}
		});

		this.append("</div>");

		this.fecharFormulario();
		this.fecharAbaAccordion();

		this.addEspacamentoHorizontal("10px");

		this.append("<div id='divTabelaRelMarcacoes' style='min-width: 680px; max-width: calc(100vw - 45px);'></div>")

		this.exibir();

		this.atualizarTabelaRelMarcacoes(this.getCfgsRelMarcacoesTO(codProva));
	}

	getCfgsRelMarcacoesTO(codProva: number, paginacaoTO?: any) {

		const cfgsRelMarcacoesTO: any = {
			isOrdenarQuestoesPelaProva: this.getValor("isOrdenarQuestoesPelaProva") === "PROVA",
			codProva: codProva,
			infosAvaliados: this.getValor("infosAvaliados"),
			paginacaoTO: paginacaoTO,
			isExibirSomentePFDaAU: !this.getValor("isExibirTodasTentativas")
		};

		let codsTurmasFiltrosBoot = UtilBoot.getFiltrosTurmas();
		let codsAplicacoesFiltrosBoot = UtilBoot.getFiltrosAplicacoes();

		if (codsTurmasFiltrosBoot) {
			cfgsRelMarcacoesTO.codsTurmas = codsTurmasFiltrosBoot;
		}

		if (codsAplicacoesFiltrosBoot) {
			cfgsRelMarcacoesTO.idsAplicacoes = codsAplicacoesFiltrosBoot;
		}

		return cfgsRelMarcacoesTO;
	}

	async atualizarTabelaRelMarcacoes(cfgsRelMarcacoesTO: any) {
		
		const relatorioMarcacoesProvaTO = await this.call("RelatorioProvasFCD/gerarRelatorioMarcacaoAvaliado", cfgsRelMarcacoesTO);

		$("#divTabelaRelMarcacoes").html("");
		this.setIdTarget("divTabelaRelMarcacoes");

		if (this.isEmpty(relatorioMarcacoesProvaTO.listaDetalhesProvaFeita)) {
			this.append(`<p>${this.getMsg("FP_FRONT_RelatorioProvasVH_037")}</p>`);
			this.exibir();
			return;
		}

		const paginacaoTO = relatorioMarcacoesProvaTO.paginacaoTO;

		relatorioMarcacoesProvaTO.numQuestoesProva = relatorioMarcacoesProvaTO.numQuestoesProva.sort((a: number, b: number) => a - b);

		let hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);
		let hashCorrecao = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada);
		const isAnonimo = !this.isAdministrador() && this.isCfgHabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL")
		// let colunas = [
		// 	[this.getMsg("FP_FRONT_RelatorioProvasVH_038"), function (exibicaoRelatorioMarcacaoProvaTO) {
		// 		return relatorioMarcacoesProvaTO.tiposAplicacao[exibicaoRelatorioMarcacaoProvaTO.tipoAplicacao];
		// 	}],
		// 	[this.getCfg("LABEL_ALUNO"), (exibicaoRelatorioMarcacaoProvaTO) => {
		// 		const linkEdicaoUsuario = !isAnonimo ? `
		// 			<a href='${hashEdicaoUsuario}/${exibicaoRelatorioMarcacaoProvaTO.codUsuario}'>
		// 				<i class='fa fa-user'></i>
		// 			</a>
		// 		` : '';
		// 		return `
		// 			${linkEdicaoUsuario}
		// 			<a href='${hashCorrecao}/${exibicaoRelatorioMarcacaoProvaTO.codProvaFeita}'>
		// 				${exibicaoRelatorioMarcacaoProvaTO.nomeUsuario}
		// 			</a>
		// 		`;
		// 	}, "", "descricao"],
		// ];

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_038"), prop: function (exibicaoRelatorioMarcacaoProvaTO) {
				return relatorioMarcacoesProvaTO.tiposAplicacao[exibicaoRelatorioMarcacaoProvaTO.tipoAplicacao];
			}
		})
		colunas.push({titulo: this.getCfg("LABEL_ALUNO"), classe: "descricao", prop: (exibicaoRelatorioMarcacaoProvaTO) => {
				const linkEdicaoUsuario = !isAnonimo ? `
					<a href='${hashEdicaoUsuario}/${exibicaoRelatorioMarcacaoProvaTO.codUsuario}'>
						<i class='fa fa-user'></i>
					</a>
				` : '';
				return `
					${linkEdicaoUsuario}
					<a href='${hashCorrecao}/${exibicaoRelatorioMarcacaoProvaTO.codProvaFeita}'>
						${exibicaoRelatorioMarcacaoProvaTO.nomeUsuario}
					</a>
				`;
			}
		})

		if (cfgsRelMarcacoesTO.infosAvaliados.includes("SEGMENTO")) {
			// colunas.push([this.getMsg("FP_FRONT_RelatorioProvasVH_039"), "nomeSegmento"]);
			colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_039"), prop: "nomeSegmento"})
		}

		if (cfgsRelMarcacoesTO.infosAvaliados.includes("TURMA")) {
			// colunas.push([this.getCfg("LABEL_TURMA"), "nomeTurma"]);
			colunas.push({titulo: this.getCfg("LABEL_TURMA"), prop: "nomeTurma"})
		}

		if (cfgsRelMarcacoesTO.infosAvaliados.includes("CPF")) {
			//colunas.push([this.getMsg("FP_FRONT_RelatorioProvasVH_041"), "cpf"]);
			colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_041"), prop: "cpf"})
		}

		if (cfgsRelMarcacoesTO.infosAvaliados.includes("EMAIL")) {
			//colunas.push([this.getMsg("FP_FRONT_RelatorioProvasVH_042"), "email"]);
			colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_042"), prop: "email"});
		}

		if (cfgsRelMarcacoesTO.infosAvaliados.includes("LOGIN")) {
			//colunas.push([this.getMsg("FP_FRONT_RelatorioProvasVH_040"), "login"]);
			colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_040"), prop: "login"});
		}

		if (cfgsRelMarcacoesTO.infosAvaliados.includes("MATRICULA")) {
			//colunas.push([this.getCfg("LABEL_MATRICULA"), "matricula"]);
			colunas.push({titulo: this.getCfg("LABEL_MATRICULA"), prop: "matricula"});
		}

		if (cfgsRelMarcacoesTO.infosAvaliados.includes("ID_SISTEMA_INTEGRADO")) {
			//colunas.push([this.getMsg("FP_FRONT_RelatorioProvasVH_044"), "codigoSistemaOrigem"]);
			colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_044"), prop: "codigoSistemaOrigem"});
		}

		for(let numQuestao of relatorioMarcacoesProvaTO.numQuestoesProva){

			colunas.push({titulo: numQuestao, prop: (function (numQuestao) {
					return (to) => {
						let resposta = "";
						let codQuestaoRecurso = null;
						for(let marcacaoResposta of to.listaMarcacaoResposta){
							if(marcacaoResposta.numQuestaoNaProva === numQuestao){
								if(marcacaoResposta.emBranco === false){
									if(marcacaoResposta.marcacao) {
										resposta = marcacaoResposta.marcacao;
									} else {
										resposta = "(OK)";
									}
								}

								if(marcacaoResposta.codRecurso){
									codQuestaoRecurso = marcacaoResposta.codQuestao;
								}

								break;
							}
						}

						if (codQuestaoRecurso === null) return resposta;

						return `
						<a onclick="respostaRecursoVH.listarRecursosDaQuestao(0, ${codQuestaoRecurso}, ${relatorioMarcacoesProvaTO.codProva})">
							<i class='fa fa-legal text-warning' title='Abriu recurso'></i> ${resposta}
						</a>
					`;
					}
				})(numQuestao) });
		}

		await this.addTabela({
			id: "relMarcacoesProva",
			collection: relatorioMarcacoesProvaTO.listaDetalhesProvaFeita,
			colunas: colunas,
			paginacao: {
				paginacaoTO: paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					cfgsRelMarcacoesTO.paginacaoTO = paginacaoTO;
					await this.atualizarTabelaRelMarcacoes(cfgsRelMarcacoesTO);
				}
			},
		});

		this.exibir();
	}

	async exibirRelatorioDeDesempenhoDetalhado(codProva: number, possuiTentativasOuReaplicacao: boolean, codProva2?: number) {

		const filtrosRelatorioDetalhadoTO = await this.call("RelatorioProvasFCD/recuperarFiltrosRelatorioDesempenhoDetalhado", codProva);

		if ($("#resultado-relatorio-detalhado").length == 0) {

			let codsAplicacoesFiltrosBoot = UtilBoot.getFiltrosAplicacoes();

			this.limpar(true);

			this.abrirAbaAccordion({
				titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_066"),
				aberta: true
			});

			this.addFormulario();

			await this.addSelect({
				id: "infosAvaliados",
				collection: filtrosRelatorioDetalhadoTO.listaInfoAvaliado,
				label: this.getMsg("MSG_VH_RP_72"),
				multiplo: true,
				classe: "col-md-3",
				valor: ["CPF", "TURMA"],
			});

			await this.addSelect({
				collection: filtrosRelatorioDetalhadoTO.listaListagemAplicacoesTO,
				id: "idsAplicacoes",
				label: this.getMsg("MSG_VH_RP_77"),
				multiplo: true,
				classe: "col-md-3",
				visivel: codsAplicacoesFiltrosBoot == null
			});

			await this.addSelect({
				collection: filtrosRelatorioDetalhadoTO.listaListagemSegmentos,
				id: "idsSegmentos",
				label: this.getMsg("MSG_VH_RP_78"),
				multiplo: true,
				classe: "col-md-3",
			});

			await this.addSelect({
				collection: JSON.parse(JSON.stringify(filtrosRelatorioDetalhadoTO.listaListagemSegmentos)),
				id: "idsSegmentosAvaliados",
				label: this.getMsg("FP_FRONT_RelatorioProvasVH_098"),
				multiplo: true,
				classe: "col-md-3",
			});

			await this.addSelect({
				collection: filtrosRelatorioDetalhadoTO.listaOpcaoListaTOTiposAplicacao,
				id: "tiposAplicacao",
				label: this.getMsg("MSG_VH_RP_149"),
				multiplo: true,
				classe: "col-md-3",
			});

			await this.addSelect({
				id: "codsUsuariosAvaliados",
				label: this.getCfg("LABEL_MENU_CADASTRO_ALUNO"),
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarAlunos",
					numMinimoCaracteres: 3,
				},
			});

			this.addCamposPeriodo({
				label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_017"),
				idInicio: "dataRespostaInicio",
				idFim: "dataRespostaFrim"
			});

			await this.addSelect({
				collection: filtrosRelatorioDetalhadoTO.listaTipoSinalizacaoQuestaoEmBranco,
				id: "tipoSinalizacaoQuestaoEmBranco",
				label: this.getMsg("FP_FRONT_RelatorioProvasVH_067"),
				multiplo: false,
				classe: "col-md-3",
				habilitado: false
			});

			this.addEspacamentoHorizontal("1px");

			this.addCheckbox({
				id: "exibirQuestoes", 
				label: this.getMsg("MSG_VH_RP_79"), 
				classe: "col-md-3",
				onChange: async () => {
					if (this.getValor("exibirQuestoes")) {
						this.enable("tipoSinalizacaoQuestaoEmBranco");
						await this.show("exibirPontuacaoQuestao01");
					} else {
						this.disable("tipoSinalizacaoQuestaoEmBranco");
						await this.hide("exibirPontuacaoQuestao01");
					}
				}
			});
			this.addCheckbox({
				id: "exibirPontuacaoQuestao01",
				label: this.getMsg("FP_FRONT_RelatorioProvasVH_068"),
				classe: "col-md-3",
				visivel: false
			});

			this.addCheckbox({
				id: "isDetalharSecoes",
				label: this.getMsg("MSG_VH_RP_80"),
				classe: "col-md-3",
			});
			this.addCheckbox({
				id: "exibirDadosProva",
				label: this.getMsg("MSG_VH_RP_81"),
				valor: true,
				classe: "col-md-3",
			});
			this.addCheckbox({
				id: "exibirAusentes",
				label: this.getMsg("MSG_VH_RP_82"),
				classe: "col-md-3",
			});
			this.addCheckbox({
				id: "exibirAnuladas",
				label: this.getMsg("FP_FRONT_RelatorioProvasVH_116"),
				classe: "col-md-3",
			});
			this.addCheckbox({
				id: "exibirModaEMediana",
				label: this.getMsg("FP_FRONT_RelatorioProvasVH_069"),
				classe: "col-md-3",
			});
			this.addCheckbox({
				id: "isExibirTodasTentativas",
				label: this.getMsg("FP_FRONT_RelatorioProvasVH_117"),
				classe: "col-md-3",
				valor: this.hasValue(this.valorPadraoIsExibirTodasTentativas) ? this.valorPadraoIsExibirTodasTentativas : possuiTentativasOuReaplicacao
			});

			this.addEspacamentoHorizontal("1px");

			this.addBotao({
				label: this.getMsg("MSG_VH_RP_84"),
				css: "float: right",
				classe: "btn-primary",
				onClick: () => this.gerarRelatorioDesempenhoDetalhadoPorUsuario(codProva, codProva2)
			})

			this.fecharFormulario();
			this.fecharAbaAccordion();

			this.addEspacamentoHorizontal("20px");

			this.append(`
				<div id='listagemRelatorioDetalhado_titulo' class='col-md-12'></div>
				<div id='resultado-relatorio-detalhado' style="min-width: 680px; max-width: calc(100vw - 45px);" class='table-responsive col-md-12' fp-is-prova-com-mencoes='${filtrosRelatorioDetalhadoTO.isProvaPorMencao}'>
				</div>
			`);

			this.exibir();
		}
	}

	async gerarRelatorioDesempenhoDetalhadoPorUsuario(codProva, codProva2, paginacaoTO: PaginacaoTO = null) {

		$("#listagemRelatorioDetalhado_titulo").html("");
		$("#resultado-relatorio-detalhado").html("");

		this.setIdTarget("resultado-relatorio-detalhado");

		const geracaoRelatorioDetalhadoTO: any = {
			codsProvas: [codProva],
			idsAplicacoes: this.getValor("idsAplicacoes"),
			tiposAplicacao: this.getValor("tiposAplicacao"),
			idsSegmentos: this.getValor("idsSegmentos"),
			idsSegmentosAvaliados: this.getValor("idsSegmentosAvaliados"),
			codsUsuariosAvaliados: this.getValor("codsUsuariosAvaliados"),
			periodoResposta: [this.getValor("dataRespostaInicio"), this.getValor("dataRespostaFrim")],
			exibirQuestoes: this.getValor("exibirQuestoes"),
			isDetalharSecoes: this.getValor("isDetalharSecoes"),
			exibirDadosProva: this.getValor("exibirDadosProva"),
			exibirAusentes: this.getValor("exibirAusentes"),
			exibirAnuladas: this.getValor("exibirAnuladas"),
			infosAvaliados: this.getValor("infosAvaliados"),
			tipoSinalizacaoQuestaoEmBranco: this.getValor("tipoSinalizacaoQuestaoEmBranco"),
			exibirPontuacaoQuestao01: this.getValor("exibirPontuacaoQuestao01"),
			isExibirTodasTentativas: this.getValor("isExibirTodasTentativas"),
			paginacaoTO: paginacaoTO
		};

		let codsTurmasFiltrosBoot = UtilBoot.getFiltrosTurmas();
		let codsAplicacoesFiltrosBoot = UtilBoot.getFiltrosAplicacoes();

		if (codsTurmasFiltrosBoot) {
			geracaoRelatorioDetalhadoTO.codsTurmas = codsTurmasFiltrosBoot;
		}

		if (codsAplicacoesFiltrosBoot) {
			geracaoRelatorioDetalhadoTO.idsAplicacoes = codsAplicacoesFiltrosBoot;
		}

		let exibirModaEMediana = this.getValor("exibirModaEMediana");

		if (codProva2) geracaoRelatorioDetalhadoTO.codsProvas.push(codProva2);

		const relatorioDesempenhoDetalhadoTO = await this.call("RelatorioProvasFCD/gerarRelatorioDesempenhoDetalhadoPorUsuario", geracaoRelatorioDetalhadoTO);
		paginacaoTO = relatorioDesempenhoDetalhadoTO.paginacaoTO;

		if (!relatorioDesempenhoDetalhadoTO?.linhas || !relatorioDesempenhoDetalhadoTO.linhas[0]) {
			this.addTexto(this.getMsg("FP_FRONT_RelatorioProvasVH_070"));
			this.exibir({ isDeveFazerScrollParaTitulo: true });
			return;
		}

		let colunasTablesorter = [];
		let ordenacaoTablesorter: any[] = [["nomeAvaliado"]];
		let listaColunasMedias = [];
		let listaColunasModas = [];
		let listaColunasMedianas = [];
		let listaColunasTotais = [];
		let linhaReferencia = relatorioDesempenhoDetalhadoTO.linhas[0];

		this.append(`
			<div class='table-responsive'>
				<table id='listagemRelatorioDetalhado' class='tablesorter table table-hover'>
					<thead>
						<tr>
							<th class='descricao'>${this.getCfg("LABEL_ALUNO")}</th>
		`);

		colunasTablesorter.push(["", "nomeAvaliado"]);
		listaColunasMedias.push(this.getMsg("FP_FRONT_RelatorioProvasVH_121"));
		listaColunasModas.push(this.getMsg("FP_FRONT_RelatorioProvasVH_122"));
		listaColunasMedianas.push(this.getMsg("FP_FRONT_RelatorioProvasVH_123"));
		listaColunasTotais.push(this.getMsg("FP_FRONT_RelatorioProvasVH_124"));
		
		if (linhaReferencia.hasOwnProperty("nomeFantasia")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_071")}</th>`);
			colunasTablesorter.push(["", "nomeFantasia"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("tipoFase")) {
			this.append("<th>" + this.getMsg("FP_FRONT_RelatorioProvasVH_103") + "</th>");
			colunasTablesorter.push(["", "tipoFase"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("nomeTurma")) {
			this.append("<th>" + this.getCfg("LABEL_TURMA") + "</th>");
			colunasTablesorter.push(["", "nomeTurma"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("u.codigoSistemaOrigem")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_073")}</th>`);
			colunasTablesorter.push(["", "u.codigoSistemaOrigem"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("login")) {
			this.append("<th>" + this.getMsg("MSG_VH_RP_87") + "</th>");
			colunasTablesorter.push(["", "login"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("cpf")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_072")}</th>`);
			colunasTablesorter.push(["", "cpf"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("email")) {
			this.append("<th>" + this.getMsg("MSG_VH_RP_88") + "</th>");
			colunasTablesorter.push(["", "email"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("matricula")) {
			this.append("<th>" + this.getMsg("MSG_VH_RP_89") + "</th>");
			colunasTablesorter.push(["", "matricula"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("codigoSistemaOrigem")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_104")}</th>`);
			colunasTablesorter.push(["", "codigoSistemaOrigem"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("dataNascimento")) {
			this.append("<th>" + this.getMsg("MSG_VH_RP_90") + "</th>");
			colunasTablesorter.push(["", "dataNascimento", "DD/MM/YY"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("endereco")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_074")}</th>`);
			colunasTablesorter.push(["", "endereco"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("cidade")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_075")}</th>`);
			colunasTablesorter.push(["", "cidade"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("uf")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_076")}</th>`);
			colunasTablesorter.push(["", "uf"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("cep")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_077")}</th>`);
			colunasTablesorter.push(["", "cep"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("nomeMae")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_078")}</th>`);
			colunasTablesorter.push(["", "nomeMae"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("apelido")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_079")}</th>`);
			colunasTablesorter.push(["", "apelido"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("telefone")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_080")}</th>`);
			colunasTablesorter.push(["", "telefone"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("rg")) {
			this.append(`<th>${this.getMsg("FP_FRONT_RelatorioProvasVH_081")}</th>`);
			colunasTablesorter.push(["", "rg"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		let numQuestao = relatorioDesempenhoDetalhadoTO.numPrimeiraQuestao;
		let propQuestao = "questao" + numQuestao;

		while (linhaReferencia.hasOwnProperty(propQuestao)) {
			this.append("<th>" + numQuestao + "</th>");
			propQuestao = "questao" + (++numQuestao);
			colunasTablesorter.push(["", propQuestao, "numero"]);
			listaColunasMedias.push("AVG");
			listaColunasModas.push("MODA");
			listaColunasMedianas.push("MEDIANA");
			listaColunasTotais.push("SUM");
		}

		let numSecao = 1;
		let propSecao = "secao" + numSecao;

		while (linhaReferencia.hasOwnProperty(propSecao + "Obtida")) {
			let nomeSecao = relatorioDesempenhoDetalhadoTO.titulosColunas[propSecao];

			if (geracaoRelatorioDetalhadoTO.isDetalharSecoes) {
				this.append("<th title='" + this.getMsg("MSG_VH_RP_91") + " " + nomeSecao + "'>" + nomeSecao + "<br><small>" + this.getMsg("MSG_VH_RP_92") + "</small></th>");
				this.append("<th title='" + this.getMsg("MSG_VH_RP_93") + " " + nomeSecao + "'>" + nomeSecao + "<br><small>" + this.getMsg("MSG_VH_RP_94") + "</small></th>");
				this.append("<th title='" + this.getMsg("MSG_VH_RP_95") + " " + nomeSecao + "'>" + nomeSecao + "<br><small>" + this.getMsg("MSG_VH_RP_96") + "</small></th>");
				this.append("<th title='" + this.getMsg("MSG_VH_RP_97") + " " + nomeSecao + "'>" + nomeSecao + "<br><small>" + this.getMsg("MSG_VH_RP_98") + "</small></th>");

				if (relatorioDesempenhoDetalhadoTO.isExibirColunaMencao) {
					this.append("<th title='" + this.getMsg("MSG_VH_RP_156") + " " + nomeSecao + "'>" + nomeSecao + "<br><small>" + this.getMsg("MSG_VH_RP_156") + "</small></th>");
				}

				colunasTablesorter.push(
					["", propSecao + "Acertos", "numero"], 
					["", propSecao + "Erros", "numero"], 
					["", propSecao + "EmBranco", "numero"],
					["", propSecao + "Obtida", "numero"],
					["", propSecao + "Mencao"]
				);

				listaColunasMedias.push("AVG", "AVG", "AVG", "AVG");
				listaColunasModas.push("MODA", "MODA", "MODA", "MODA");
				listaColunasMedianas.push("MEDIANA", "MEDIANA", "MEDIANA", "MEDIANA");
				listaColunasTotais.push("SUM", "SUM", "SUM", "SUM");

			} else {
				this.append("<th title='" + this.getMsg("MSG_VH_RP_99") + " " + nomeSecao + "'>" + nomeSecao + "</th>");
				colunasTablesorter.push(["", propSecao + "Obtida", "numero"]);
				listaColunasMedias.push("AVG");
				listaColunasModas.push("MODA");
				listaColunasMedianas.push("MEDIANA");
				listaColunasTotais.push("SUM");
			}

			numSecao++;
			propSecao = "secao" + numSecao;
		}

		this.append("<th title='" + this.getMsg("MSG_VH_RP_100") + "'>" + this.getMsg("MSG_VH_RP_101") + "</th>");
		colunasTablesorter.push(["", "dataInicio", "DD/MM/YY"]);
		listaColunasMedias.push(null);
		listaColunasModas.push(null);
		listaColunasMedianas.push(null);
		listaColunasTotais.push(null);

		if (relatorioDesempenhoDetalhadoTO.isExibirColunaMencao === true) {
			this.append("<th>" + this.getMsg("MSG_VH_RP_156") + "</th>");
			colunasTablesorter.push(["", "codMencao"]);
			listaColunasMedias.push(null);
			listaColunasModas.push(null);
			listaColunasMedianas.push(null);
			listaColunasTotais.push(null);
		}

		if (linhaReferencia.hasOwnProperty("provaAproveitamento")) {

			this.append(`
				<th title='${this.getMsg("MSG_VH_RP_105")}'>%AP</th>
				<th title='${this.getMsg("MSG_VH_RP_106")}'>TA</th>
				<th title='${this.getMsg("MSG_VH_RP_107")}'>PD</th>
				<th title='"${this.getMsg("MSG_VH_RP_108")}'>NF</th>
			`);

			colunasTablesorter.push(
				["", "provaAproveitamento", "numero"], ["", "provaTotalAcertos", "numero"],
				["", "provaDescontada", "numero"], ["", "provaNota", "numero"]
			);
			if(relatorioDesempenhoDetalhadoTO.isExibirColunaMencao === false){
				ordenacaoTablesorter = [["provaAproveitamento", true]];
			}
			listaColunasMedias.push("AVG", "AVG", "AVG", "AVG");
			listaColunasModas.push("MODA", "MODA", "MODA", "MODA");
			listaColunasMedianas.push("MEDIANA", "MEDIANA", "MEDIANA", "MEDIANA");
			listaColunasTotais.push("AVG", "SUM", "SUM", "SUM");
		}

		this.append("</tr></thead><tbody>");

		let hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);
		let hashCorrecao = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada);
		let numAvaliados = 0;
		const isAnonimo = !this.isAdministrador() && this.isCfgHabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL")

		for (const linha of relatorioDesempenhoDetalhadoTO.linhas) {

			numAvaliados++;

			const linkEdicaoUsuario = !isAnonimo ? `
					<a href='${hashEdicaoUsuario + "/" + linha.codUsuarioAvaliado}'>
						<i class='fa fa-user'></i>
					</a>
				` : '';
			this.append(`
				<tr id="listagem_linha_${linha.codProvaFeita}">
				<td style='text-align: left'>
					${linkEdicaoUsuario}
			`);

			if (linha.codProvaFeita) {
				this.append("<a href='" + hashCorrecao + "/" + linha.codProvaFeita + "'>");
			}

			if (linha.nomeAvaliado) {
				this.append(linha.nomeAvaliado);
			} else {
				this.append(this.getMsg('FP_FRONT_RelatorioProvasVH_106', linha.codProvaFeita));
			}

			if (linha.codProvaFeita) {
				this.append("</a>");
			}

			this.append("</td>");

			if (linha.hasOwnProperty("nomeFantasia")) {
				this.append("<td>" + (linha["nomeFantasia"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("tipoFase")){
				this.append(`<td>${relatorioDesempenhoDetalhadoTO.mapaTiposAplicacao[linha["tipoFase"]] || ""}</td>`);
			}
			if (linha.hasOwnProperty("nomeTurma")) {
				this.append("<td>" + (linha["nomeTurma"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("u.codigoSistemaOrigem")) {
				this.append("<td>" + (linha["u.codigoSistemaOrigem"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("login")) {
				this.append("<td>" + (linha["login"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("cpf")) {
				this.append("<td>" + (linha["cpf"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("email")) {
				this.append("<td>" + (linha["email"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("matricula")) {
				this.append("<td>" + (linha["matricula"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("codigoSistemaOrigem")) {
				this.append("<td>" + (linha["codigoSistemaOrigem"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("dataNascimento")) {
				this.append("<td>" + (UtilData.toDDMMYYYY(linha["dataNascimento"]) || "") + "</td>");
			}
			if (linha.hasOwnProperty("endereco")) {
				this.append("<td>" + (linha["endereco"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("cidade")) {
				this.append("<td>" + (linha["cidade"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("uf")) {
				this.append("<td>" + (linha["uf"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("cep")) {
				this.append("<td>" + (linha["cep"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("nomeMae")) {
				this.append("<td>" + (linha["nomeMae"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("apelido")) {
				this.append("<td>" + (linha["apelido"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("telefone")) {
				this.append("<td>" + (linha["telefone"] || "") + "</td>");
			}
			if (linha.hasOwnProperty("rg")) {
				this.append("<td>" + (linha["rg"] || "") + "</td>");
			}

			numQuestao = relatorioDesempenhoDetalhadoTO.numPrimeiraQuestao;
			propQuestao = "questao" + numQuestao;

			while (linha.hasOwnProperty(propQuestao)) {
				let aproveitamentoQuestao = linha[propQuestao];
				let aproveitamentoQuestaoExibicao;
				if (aproveitamentoQuestao == null) {
					aproveitamentoQuestao = "";
					if (geracaoRelatorioDetalhadoTO.tipoSinalizacaoQuestaoEmBranco == "SIGLA_EM_BRANCO") {
						aproveitamentoQuestaoExibicao = "EB";
					} else {
						aproveitamentoQuestaoExibicao = "";
					}
				} else {
					aproveitamentoQuestaoExibicao = UtilNumero.floatToString(aproveitamentoQuestao);
				}

				this.append(`
					<td tipo='n' title='"${this.getMsg("MSG_VH_RP_110") + " " + numQuestao + "' valor='" + aproveitamentoQuestao}'>
						${aproveitamentoQuestaoExibicao}
					</td>
				`);

				propQuestao = "questao" + (++numQuestao);
			}

			let numSecao = 1;
			let propSecao = "secao" + numSecao;

			while (linha.hasOwnProperty(propSecao + "Obtida")) {
				let nomeSecao = relatorioDesempenhoDetalhadoTO.titulosColunas[propSecao];
				let obtida = linha[propSecao + "Obtida"];
				let acertos = linha[propSecao + "Acertos"];
				let erros = linha[propSecao + "Erros"];
				let emBranco = linha[propSecao + "EmBranco"];

				if (geracaoRelatorioDetalhadoTO.isDetalharSecoes) {

					this.append("<td tipo='n' title='" + this.getMsg("MSG_VH_RP_111") + " " + nomeSecao + "' valor='" + (acertos != null ? acertos : "") + "'>");
					this.append(acertos != null ? UtilNumero.floatToString(acertos) : "");
					this.append("</td>");

					this.append("<td tipo='n' title='" + this.getMsg("MSG_VH_RP_112") + " " + nomeSecao + "' valor='" + (erros != null ? erros : "") + "'>");
					this.append(erros != null ? UtilNumero.floatToString(erros) : "");
					this.append("</td>");

					this.append("<td tipo='n' title='" + this.getMsg("MSG_VH_RP_113") + " " + nomeSecao + "' valor='" + (emBranco != null ? emBranco : "") + "'>");
					this.append(emBranco != null ? UtilNumero.floatToString(emBranco) : "");
					this.append("</td>");
				}

				this.append("<td tipo='n' title='" + this.getMsg("MSG_VH_RP_114") + " " + nomeSecao + "' valor='" + (obtida != null ? obtida : "") + "'>");
				this.append(obtida != null ? UtilNumero.floatToString(obtida) : "");
				this.append("</td>");

				
				if (geracaoRelatorioDetalhadoTO.isDetalharSecoes && relatorioDesempenhoDetalhadoTO.isExibirColunaMencao) {
					let mencao = relatorioDesempenhoDetalhadoTO.mapaMencoes[linha[propSecao + "Mencao"]];

					this.append("<td title='" + this.getMsg("MSG_VH_RP_156") + " " + nomeSecao + "' valor='" + (mencao != null ? mencao : "") + "'>");
					this.append(mencao ?? "");
					this.append("</td>");
				}

				numSecao++;
				propSecao = "secao" + numSecao;
			}

			if (linha.dataInicio) {
				const labelAnuladas = linha.isAnulada === true
					? `<span class='label label-danger'>${this.getMsg("FP_FRONT_RelatorioProvasVH_065")}</span>`
					: "";
				this.append(`
					<td>
						${UtilData.toDDMMYYYY(linha.dataInicio)}
						${labelAnuladas}
					</td>
				`);
			} else if (linha.observacaoAusencia) {
				this.append("<td>" + linha.observacaoAusencia + "</td>");
			} else if (this.isEmpty(linha.codProvaFeita) || this.isEmpty(linha.dataInicio)) {
				this.append("<td>" + (linha.tipoAplicacao == TipoAplicacao.F ? this.getMsg("FP_FRONT_RelatorioProvasVH_082") : this.getMsg("FP_FRONT_RelatorioProvasVH_083")) + "</td>");
			} else {
				this.append("<td></td>");
			}

			if(relatorioDesempenhoDetalhadoTO.isExibirColunaMencao === true && linha.codMencao){
				let descricaoMencao;
				if(this.hasValue(linha.descricaoMencao)){
					descricaoMencao = linha.descricaoMencao;
				} else {
					descricaoMencao = relatorioDesempenhoDetalhadoTO.mapaMencoes[linha.codMencao];
				}
				this.append(`<td>${descricaoMencao}</td>`);
			}

			if (linha.hasOwnProperty("provaAproveitamento")) {
				let provaAproveitamento = linha["provaAproveitamento"];
				let provaTotalAcertos = linha["provaTotalAcertos"];
				let provaDescontada = linha["provaDescontada"];
				let provaNota = linha["provaNota"];

				this.append("<td tipo='n' title='" + this.getMsg("MSG_VH_RP_116") + "' valor='" + (provaAproveitamento ?? "") + "'>");
				this.append(provaAproveitamento != null ? UtilNumero.floatToString(provaAproveitamento) : "");
				this.append("</td>");
				this.append("<td tipo='n' title='" + this.getMsg("MSG_VH_RP_117") + "' valor='" + (provaTotalAcertos ?? "") + "'>");
				this.append(provaTotalAcertos != null ? UtilNumero.floatToString(provaTotalAcertos) : "");
				this.append("</td>");
				this.append("<td tipo='n' title='" + this.getMsg("MSG_VH_RP_118") + "' valor='" + (provaDescontada ?? "") + "'>");
				this.append(provaDescontada != null ? UtilNumero.floatToString(provaDescontada) : "");
				this.append("</td>");
				this.append("<td tipo='n' title='" + this.getMsg("MSG_VH_RP_119") + "' valor='" + (provaNota ?? "") + "'>");
				this.append(provaNota != null ? UtilNumero.floatToString(provaNota) : "");
				this.append("</td>");
			}

			this.append("</tr>");
		}
		
		this.append(`
					</tbody>
					<tfoot>
						<tr>
							<td colspan="${colunasTablesorter.length}" style="text-align: right">
								<button class="btn btn-link" id="download_listagemRelatorioDetalhado" type="button">
									<i class="fa fa-download"></i> ${this.getMsg("MSG_VH_AP_48")}
								</button>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		`);

		const idPaginador = "paginador_rel_detalhado";
		const cfgsDownload: CfgsDownloadTabela = {
			idPaginador: idPaginador,
			idTabela: "listagemRelatorioDetalhado",
			numItensPorPagina: 50,
			paginacao: {
				paginacaoTO: paginacaoTO
			}
		};

		const handleDownloadTabela = async () => {
			return await UtilXlsx.fazerDownloadTabela(cfgsDownload);
		}

		this.appendJs(() => this.listenToClick("#download_listagemRelatorioDetalhado", handleDownloadTabela));

		this.exibir();

		this.ativarTablesorterTabela({
			collection: [],
			id: "listagemRelatorioDetalhado",
			colunas: colunasTablesorter,
			ordenacao: ordenacaoTablesorter,
		});

		this.setIdTarget("listagemRelatorioDetalhado_titulo");

		const cfgsPaginador: CfgsPaginador = {
			onCarregarPagina: async (callbackPaginacaoTO) => {
				paginacaoTO.numPaginaAtual = callbackPaginacaoTO.numPaginaAtual;
				paginacaoTO.numItensPorPagina = callbackPaginacaoTO.numItensPorPagina;
				await this.gerarRelatorioDesempenhoDetalhadoPorUsuario(codProva, codProva2, paginacaoTO);
			},
			paginacaoTO: paginacaoTO,
			botoesAdicionais: [{
				label: `<i class="fa fa-sm fa-download"></i>`,
				onClick: handleDownloadTabela,
				dica: this.getMsg("MSG_VH_A_11")
			}]
			// itensPorPagina: paginacaoTO.numItensPorPagina,
			// totalItens: paginacaoTO.numTotalItens,
			// paginaAtiva: paginacaoTO.numPaginaAtual
		};

		this.append(UtilPaginacao.criarPaginador(cfgsPaginador, idPaginador));

		this.setIdTarget(null);

		this.exibir();

		this.incluirLinhaCalculada("listagemRelatorioDetalhado", listaColunasTotais);

		if (exibirModaEMediana) {
			this.incluirLinhaCalculada("listagemRelatorioDetalhado", listaColunasMedianas);
			this.incluirLinhaCalculada("listagemRelatorioDetalhado", listaColunasModas);
		}

		this.incluirLinhaCalculada("listagemRelatorioDetalhado", listaColunasMedias);
	}

	incluirLinhaCalculada(idTable, cfgsColunas) {
		let $table = $("table[id='" + idTable + "']");
		let numColunas = $table.find("thead th").length;
		let $tfoot = $table.find("tfoot");

		if ($tfoot.length == 0) {
			$table.append("<tfoot></tfoot>");
			$tfoot = $table.find("tfoot");
		}

		$tfoot.prepend("<tr></tr>");
		let tr$ = $tfoot.find("tr:first-child");

		for (let i = 0; i < numColunas; i++) {
			let tipoTotalizacao = cfgsColunas[i];

			if (tipoTotalizacao == null) {
				tr$.append("<td></td>");

			} else if (tipoTotalizacao == "AVG") {
				let total = 0;
				let ocorrencias = 0;

				$("table[id='" + idTable + "'] tbody tr td:nth-child(" + (i + 1) + ")").each(function () {
					let valor = $(this).attr("valor");
					if (valor == null || valor == "" || isNaN(valor)) return;
					total = total + Number(valor);
					ocorrencias++;
				});

				let media = "";

				if (ocorrencias > 0) {
					media = UtilNumero.floatToString(total / ocorrencias);
				}

				tr$.append("<td>" + media + "</td>");

			} else if (tipoTotalizacao == "MODA") {
				let mapaFrequenciaNotas = {};
				let freqModa = null;

				$("table[id='" + idTable + "'] tbody tr td:nth-child(" + (i + 1) + ")").each(function () {
					let valor = $(this).attr("valor");
					if (valor == null || valor == "" || isNaN(valor)) return;
					valor = Math.round((isNaN(valor) ? 0 : Number(valor)) * 100) / 100;
					let freq = mapaFrequenciaNotas[valor];

					if (freq == null) {
						freq = {
							ocorrencias: 0,
							label: $(this).text()
						}

						mapaFrequenciaNotas[valor] = freq;
					}

					freq.ocorrencias++;

					if (freqModa == null || freq.ocorrencias > freqModa.ocorrencias) {
						freqModa = freq;
					}
				});

				tr$.append("<td>" + freqModa.label + "</td>");

			} else if (tipoTotalizacao == "MEDIANA") {
				let valores = [];

				$("table[id='" + idTable + "'] tbody tr td:nth-child(" + (i + 1) + ")").each(function () {
					let valor = $(this).attr("valor");
					if (valor == null || valor == "" || isNaN(valor)) return;
					valores.push(Number(valor));
				});

				let mediana;

				valores.sort(function (a, b) {
					return a - b
				});

				if (valores.length % 2 == 0) {
					// a media entre os dois valores do meio
					let indice1 = valores.length / 2;
					let indice2 = indice1 - 1;
					let valor1 = valores[indice1];
					let valor2 = valores[indice2];
					mediana = (valor1 + valor2) / 2;
				} else {
					// o valor que está na posição do meio
					let indice = Math.floor(valores.length / 2);
					mediana = valores[indice];
				}

				tr$.append("<td>" + UtilNumero.floatToString(mediana) + "</td>");

			} else if (tipoTotalizacao == "COUNT") {
				let ocorrencias = 0;

				$("table[id='" + idTable + "'] tbody tr td:nth-child(" + (i + 1) + ")").each(function () {
					ocorrencias++;
				});

				tr$.append("<td>" + ocorrencias + "</td>");

			} else if (tipoTotalizacao == "SUM") {
				let total = 0;

				$("table[id='" + idTable + "'] tbody tr td:nth-child(" + (i + 1) + ")").each(function () {
					let valor = $(this).attr("valor");
					if (valor == null || valor == "" || isNaN(valor)) return;
					total = total + Number(valor || 0);
				});

				tr$.append("<td>" + UtilNumero.floatToString(total) + "</td>");

			} else {
				tr$.append("<td class='descricao'>" + tipoTotalizacao + "</td>");
			}
		}
	}













	async exibirRelatorioCustomizados(codEmpresa) {

		UtilHash.registrarHistorico(this.exibirRelatorioCustomizados, codEmpresa);

		const collectionListagemRelatoriosPersonalizadosTO = await this.call("MicrosservicosDesacopladosFCD/listarRelatorios");

		this.limpar()

		this.setTitulo(this.getMsg("FP_FRONT_RelatorioProvasVH_084"));

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_085"), prop: "nomeRelatorio", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_086"), prop: "tipoArquivo"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_087"), prop: (listagemRelatoriosPersonalizadosTO) => {
				let h = [];
				for (const p of listagemRelatoriosPersonalizadosTO.parametros) {
					h.push(this.getNomeParametro(p));
				}
				return h.join("<br>");
			}
		});

		await this.addTabela({
			collection: collectionListagemRelatoriosPersonalizadosTO,
			propId: "nomeRelatorio",
			colunas: colunas,
			onEdicao: async (nomeRelatorio) => {
				for (const listagemRelatoriosPersonalizadosTO of collectionListagemRelatoriosPersonalizadosTO) {
					if (listagemRelatoriosPersonalizadosTO.nomeRelatorio == nomeRelatorio) {
						await relatorioProvasVH.exibirModalGeracaoRelatorio(listagemRelatoriosPersonalizadosTO);
					}
				}
			}
		});

		this.exibir();
	}

	getNomeParametro(parametro) {
		if (parametro.tipoParametro == "TURMA") {
			return this.getCfg("LABEL_TURMA");

		} else if (parametro.tipoParametro == "AVALIACAO") {
			return this.getCfg("LABEL_AVALIACAO");

		} else {
			return parametro.nome;
		}
	}

	async exibirModalGeracaoRelatorio(listagemRelatoriosPersonalizadosTO) {
		let tipoConfigMicrosservicoTO = listagemRelatoriosPersonalizadosTO.tipoConfigMicrosservicoTO;

		this.addPopup({
			id: "gerar_relatorio",
			titulo: listagemRelatoriosPersonalizadosTO.nomeRelatorio,
			width: "700px",
			// height: "450px",
			botoes: [{
				label: this.getMsg("FP_FRONT_RelatorioProvasVH_088"),
				id: "botao-gerar-relatorio",
				classe: "btn-primary",
				onClick: async (event) => {
					this.verificarObrigatorios(event.target);

					let requisicaoMSTO: RequisicaoMSTO = {
						nomeServico: tipoConfigMicrosservicoTO.nome,
						servico: listagemRelatoriosPersonalizadosTO.servico,
						codsEmpresas: this.getValor("codsEmpresas"),
						codsAvaliacoes: cadastroProvaVH.getValorAvaliacoes(),
						codsProvas: this.getValor("codsProvas"),
						codsAplicacoesOnline: this.getValor("codsAplicacoesOnline"),
						codsTurmas: this.getValor("codsTurmas"),
						codsUsuarios: this.getValor("codsUsuarios"),
						codsCategoriasClassificacoes: this.getValor("codsCategoriasClassificacoes"),
						dataInicio: this.getValor("dataInicio"),
						dataFim: this.getValor("dataFim"),
						periodo: [this.getValor("periodo_inicio"), this.getValor("periodo_fim")],
						nomeArquivoASerGerado: listagemRelatoriosPersonalizadosTO.nomeArquivoASerGerado,
						requisicao: {},
					};

					if (requisicaoMSTO.codsEmpresas && !Array.isArray(requisicaoMSTO.codsEmpresas)) {
						requisicaoMSTO.codsEmpresas = [requisicaoMSTO.codsEmpresas];
					}

					if (requisicaoMSTO.codsTurmas && !Array.isArray(requisicaoMSTO.codsTurmas)) {
						requisicaoMSTO.codsTurmas = [requisicaoMSTO.codsTurmas];
					}

					if (requisicaoMSTO.codsUsuarios && !Array.isArray(requisicaoMSTO.codsUsuarios)) {
						requisicaoMSTO.codsUsuarios = [requisicaoMSTO.codsUsuarios];
					}

					if (requisicaoMSTO.codsProvas && !Array.isArray(requisicaoMSTO.codsProvas)) {
						requisicaoMSTO.codsProvas = [requisicaoMSTO.codsProvas];
					}

					if (requisicaoMSTO.codsAplicacoesOnline && !Array.isArray(requisicaoMSTO.codsAplicacoesOnline)) {
						requisicaoMSTO.codsAplicacoesOnline = [requisicaoMSTO.codsAplicacoesOnline];
					}

					if (requisicaoMSTO.codsCategoriasClassificacoes && !Array.isArray(requisicaoMSTO.codsCategoriasClassificacoes)) {
						requisicaoMSTO.codsCategoriasClassificacoes = [requisicaoMSTO.codsCategoriasClassificacoes];
					}

					for (const parametro of listagemRelatoriosPersonalizadosTO.parametros) {
						if (parametro.tipoParametro == "LISTA") {
							requisicaoMSTO.requisicao[parametro.id] = this.getValor(parametro.id);
						}

						if (parametro.nomeAtributo) {
							requisicaoMSTO.requisicao[parametro.nomeAtributo] = this.getValor(parametro.nomeAtributo);
						}
					}

					await this.show("subtitulo_arquivo_para_download");
					const $alertaAguardandoProcessamento = $('#alerta-aguardar-processamento');
					$alertaAguardandoProcessamento.html(`
						<i class="fas fa-clock"></i>	
				 		${this.getMsg('FP_FRONT_RelatorioProvasVH_105')}
					`);
					await this.show("alerta-aguardar-processamento");
					this.setIdTarget("arquivos-para-download");
					let idLink = Date.now();
					this.addLink({
						label: `<i class='fa fa-spinner fa-pulse'></i> ${this.getMsg("FP_FRONT_RelatorioProvasVH_089")}`,
						id: idLink
					});
					this.addEspacamentoHorizontal();
					this.exibir();
					await this.hide("botao-gerar-relatorio");
					let $link = $("#" + idLink);

					try {
						const {path}: any = await UtilMS.enviar(listagemRelatoriosPersonalizadosTO.url, requisicaoMSTO);
						$link.text(UtilArquivo.getNomeArquivo(listagemRelatoriosPersonalizadosTO.urlParaDownload))
							.attr("href", path);
						$alertaAguardandoProcessamento.html(`
							<i class="fa fa-check-circle"></i>	
							${this.getMsg("FP_FRONT_RelatorioProvasVH_110")}
						`);
					} catch (e) {
						const fpMsRequestError: FpMsRequestError = e;

						// this.exibirAlerta({msg: fpMsRequestError.msgErro || this.getMsg("MSG_VH_A_19")});

						this.logger.error(fpMsRequestError);

						$link.remove();
						
						$alertaAguardandoProcessamento.html(`
							<i class="fa fa-warning"></i>	
							${fpMsRequestError.msgErro || this.getMsg("FP_FRONT_RelatorioProvasVH_111")}
						`);
					}

					await this.show("botao-gerar-relatorio");

					return false;
				}
			}]
		});

		if (listagemRelatoriosPersonalizadosTO.parametros.length > 0) {
			this.addTexto(this.getMsg("FP_FRONT_RelatorioProvasVH_091"));
		}

		let tipoConfigMicrosservicoParametroTipoTOAvaliacao = null;

		for (const parametro of listagemRelatoriosPersonalizadosTO.parametros) {

			let label = this.getNomeParametro(parametro);

			if (parametro.tipoParametro == "PROVA") {
				await this.addSelect({
					id: parametro.nomeAtributo ? parametro.nomeAtributo : "codsProvas",
					label: label,
					multiplo: parametro.isMultiplo,
					obrigatorio: parametro.isObrigatorio,
					classe: "col-md-12",
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarProvas",
						numMinimoCaracteres: 3,
					},
				});

			} else if (parametro.tipoParametro == "APLICACAO_ONLINE" || parametro.tipoParametro == "APLICACAO") {
				await this.addSelect({
					id: parametro.nomeAtributo ? parametro.nomeAtributo : "codsAplicacoesOnline",
					label: label,
					multiplo: parametro.isMultiplo,
					obrigatorio: parametro.isObrigatorio,
					classe: "col-md-12",
					propLabelCod: 'codAgendamento',
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarAplicacoes",
						numMinimoCaracteres: 3,
					}
				});

			} else if (parametro.tipoParametro == "TURMA") {
				await this.addSelect({
					id: parametro.nomeAtributo ? parametro.nomeAtributo :  "codsTurmas",
					label: label,
					multiplo: parametro.isMultiplo,
					obrigatorio: parametro.isObrigatorio,
					classe: "col-md-12",
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarTurmas",
						numMinimoCaracteres: 3,
					}
				});

			} else if (parametro.tipoParametro == "AVALIACAO") {

				tipoConfigMicrosservicoParametroTipoTOAvaliacao = parametro.tipoParametro;
				this.append("<div class='col-md-12'><div class='row' id='divSelectsAvaliacoes'></div></div>");

			} else if (parametro.tipoParametro == "SEGMENTO") {
				await this.addSelect({
					id: parametro.nomeAtributo ? parametro.nomeAtributo : "codsEmpresas",
					label: label,
					multiplo: parametro.isMultiplo,
					obrigatorio: parametro.isObrigatorio,
					classe: "col-md-12",
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarSegmentos",
						numMinimoCaracteres: 0,
					},
				});

			} else if (parametro.tipoParametro == "USUARIO") {

				await this.addSelect({
					id: parametro.nomeAtributo ? parametro.nomeAtributo : "codsUsuarios",
					label: label,
					multiplo: parametro.isMultiplo,
					obrigatorio: parametro.isObrigatorio,
					classe: "col-md-12",
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarUsuarios",
						numMinimoCaracteres: 3,
					},
				});

			} else if (parametro.tipoParametro == "DATA_INICIO") {
				this.addCampoTexto({
					id: "dataInicio",
					label: label,
					obrigatorio: parametro.isObrigatorio,
					tipo: "DATA",
					classe: "col-md-12",
				});

			} else if (parametro.tipoParametro == "DATA_FIM") {
				this.addCampoTexto({
					id: "dataFim",
					label: label,
					obrigatorio: parametro.isObrigatorio,
					classe: "col-md-12",
					tipo: "DATA",
				});

			} else if (parametro.tipoParametro == "PERIODO") {
				this.addCamposPeriodo({
					label: label,
					idInicio: "periodo_inicio",
					idFim: "periodo_fim",
					obrigatorio: parametro.isObrigatorio,
					classe: "col-md-12"
				});

			} else if (parametro.tipoParametro == "LISTA") {
				await this.addSelect({
					collection: this.getColletionDeValoresDeLista(parametro.valoresLista),
					id: parametro.id,
					label: label,
					multiplo: parametro.isMultiplo,
					obrigatorio: parametro.isObrigatorio,
					classe: "col-md-12",
				});
			} else if (parametro.tipoParametro == "CATEGORIA_CLASSIFICACAO") {
				await this.addSelect({
					id: parametro.nomeAtributo ? parametro.nomeAtributo : "codsCategoriasClassificacoes",
					propId: "id",
					propLabel: "nome",
					label: label,
					classe: "col-md-12",
					obrigatorio: parametro.isObrigatorio,
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarDisciplinasUsadas",
						numMinimoCaracteres: 0
					}
				});
			}
		}

		this.append("<div class='col-md-12' id='arquivos-para-download'>");
		this.addSubtitulo({
			texto: this.getMsg("FP_FRONT_RelatorioProvasVH_092"),
			id: "subtitulo_arquivo_para_download",
			visivel: false
		})

		this.append(`
			<div style="padding: 6px 12px; display: none" class="text-info" id="alerta-aguardar-processamento"> 
			 	<i class="fas fa-clock"></i>	
				 ${this.getMsg('FP_FRONT_RelatorioProvasVH_105')}
			</div>`)
		this.append("</div>");
		this.exibirPopups();

		if (tipoConfigMicrosservicoParametroTipoTOAvaliacao) {
			await cadastroProvaVH.carregarSelectAvaliacoes(
				null,
				false,
				tipoConfigMicrosservicoParametroTipoTOAvaliacao.isMultiplo,
				tipoConfigMicrosservicoParametroTipoTOAvaliacao.isObrigatorio,
				null
			);
		}
	}

	getColletionDeValoresDeLista(valoresLista) {
		const c = [];
		for (let i = 0; i < valoresLista.length; i = i + 2) {
			c.push({ id: valoresLista[i], text: valoresLista[i + 1] });
		}
		return c;
	}

	checkEmptyPeriodDate(start, end){
		return this.isEmpty(start) && this.isEmpty(end);
	}
	
	exibirAbasProvasRespondidas(numAba: number, cfgs: any = {}) {
		
		const acessaPorProva = UtilAuth.possuiAcesso(TipoFuncionalidade.RELATORIO_PROVAS_REALIZADAS_PROVA);
		const acessaPorTurma = UtilAuth.possuiAcesso(TipoFuncionalidade.RELATORIO_PROVAS_REALIZADAS_TURMA);
		const acessaPorEstudo = this.getIsBancoQuestoesHabilitado() && UtilAuth.possuiAcesso(TipoFuncionalidade.RELATORIO_PROVAS_REALIZADAS_ESTUDO);

		if (numAba == null) {
			if (acessaPorProva) {
				numAba = 0;
			} else if (acessaPorTurma) {
				numAba = 1;
			} else if (acessaPorEstudo) {
				numAba = 2;
			} else {
				return;
			}

			this.exibirAbasProvasRespondidas(numAba);
			return;
		}

		UtilHash.registrarHistorico(this.exibirAbasProvasRespondidas, numAba, cfgs);

		this.limpar();

		this.setTitulo(this.getMsg("MSG_VH_RP_16"))
		let abas = [];

		abas.push({
			label: this.getMsg("MSG_VH_R_19"),
			onClick: () => this.exibirProvasRespondidasPorProva(),
			habilitada: acessaPorProva
		});
		abas.push({
			label: this.getMsg("FP_FRONT_RelatorioProvasVH_094"),
			onClick: () => this.exibirProvasRespondidasPorTurma(),
			habilitada: acessaPorTurma
		});
		abas.push({
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_003"),
			onClick: () => this.exibirProvasRespondidasPorAvaliado(),
			habilitada: acessaPorTurma
		});

		this.addAbas({ abas, numAbaAtiva: numAba });

		this.exibir();
	}

	async exibirProvasRespondidasPorProva() {
		return this.exibirProvasRespondidas(<CfgsConsultaIndicePFsTO> {
			campoAgrupamento: CampoIndicePFs.NOME_PROVA
		});
	}

	async exibirProvasRespondidasPorTurma() {
		return this.exibirProvasRespondidas(<CfgsConsultaIndicePFsTO> {
			campoAgrupamento: CampoIndicePFs.NOME_TURMA_NA_APLICACAO
		});
	}

	async exibirProvasRespondidasPorAvaliado() {
		return this.exibirProvasRespondidas(<CfgsConsultaIndicePFsTO> {
			campoAgrupamento: CampoIndicePFs.NOME_USUARIO
		});
	}

	async exibirProvasRespondidas(cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO) {

		this.ordenacaoProvasRespondidas = null;

		let resultadoProvasRespondidasTO = await this.call("RelatorioProvasFCD/gerarProvasRespondidas", cfgsConsultaIndicePFsTO);

		const colunas: ColunaAddTabela[] = [
			{titulo: cfgsConsultaIndicePFsTO.campoAgrupamento.getNome(), prop: "nome", classe: "descricao"},
			{titulo: this.getMsg("MSG_VH_RP_16"), prop: "numProvasRespondidas", formato: "numero"},
			{titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_101"), prop: "pontuacaoMedia", formato: "numero"},
			{titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_102"), prop: "notaMedia", formato: "numero"},
			{titulo: this.getMsg("MSG_VH_RP_06"), prop: "aproveitamentoMedio", formato: "porcentagem"},
			{titulo: this.getMsg("MSG_VH_RP_21"), prop: "dataUltimoFimPF", formato: "DD/MM/YY HH:mm"},
		];

		if (cfgsConsultaIndicePFsTO.campoAgrupamento === CampoIndicePFs.NOME_PROVA) {
			colunas.push({titulo: "", prop: (grupoProvaRespondidaTO) => {
					return `
					<div class='btn-group'>
						<a class='btn btn-sm btn-default' 
							href='${UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 5, grupoProvaRespondidaTO.cod)}' 
							title='${this.getMsg("FP_FRONT_CadastroProvaVH_022")}'>
							
							<i class='fa fa-users fa-lg'></i>
						</a>
						<a class='btn btn-sm btn-default' href='${UtilHash.getHash(cadastroProvaVH.exibirAbasProva, "5C", grupoProvaRespondidaTO.cod)}' 
							title='${this.getMsg("FP_FRONT_CadastroProvaVH_023")}'>
							
							<i class='fa fa-table fa-lg'></i>
						</a>
					</div>
				`;
				}
			});
		}

		this.limpar(true);

		this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
		this.addBotaoBusca("filtrosPFs");
		this.append("</div>");

		await this.addFiltrosPFs({
			onAplicarFiltros: async () => {
				cfgsConsultaIndicePFsTO = this.getCfgsConsultaIndicePFsTO();

				resultadoProvasRespondidasTO = await this.call("RelatorioProvasFCD/gerarProvasRespondidas", cfgsConsultaIndicePFsTO);

				await this.exibirTabelaProvasRespondidas(cfgsConsultaIndicePFsTO, colunas, resultadoProvasRespondidasTO);
			}, 
			cfgsConsultaIndicePFsTO: cfgsConsultaIndicePFsTO,
			valorIsExibirNotasEPontuacoes: this.isExibirNotasEPontuacoes,
			suprimirCamposOpcionais: true
		});

		this.append(`
			<div class="row">
				<div id="tabela_rel_provas_respondidas" class="tabela-estatisticas col-md-12">
				</div>
			</div>
		`);

		this.exibir();

		this.exibirTabelaProvasRespondidas(cfgsConsultaIndicePFsTO, colunas, resultadoProvasRespondidasTO);
	}

	async exibirTabelaProvasRespondidas(cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO, colunas: ColunaAddTabela[], resultadoProvasRespondidasTO: any) {

		$("#tabela_rel_provas_respondidas").html("");
		this.setIdTarget("tabela_rel_provas_respondidas");
		this.isExibirNotasEPontuacoes = this.getValor("isExibirNotasEPontuacoes");

		if(this.isExibirNotasEPontuacoes === false){
			colunas = colunas.filter(col => {
				return col.prop !== "pontuacaoMedia" && col.prop !== "notaMedia";

			});
		}

		await this.addTabela({
			id: cfgsConsultaIndicePFsTO.campoAgrupamento.id,
			collection: resultadoProvasRespondidasTO.listaGrupoProvaRespondidaTO,
			propId: "cod",
			colunas: colunas,
			paginacao: {
				paginacaoTO: resultadoProvasRespondidasTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {

					cfgsConsultaIndicePFsTO.paginacaoTO = paginacaoTO;

					resultadoProvasRespondidasTO = await this.call("RelatorioProvasFCD/gerarProvasRespondidas", cfgsConsultaIndicePFsTO);

					return resultadoProvasRespondidasTO.listaGrupoProvaRespondidaTO;
				}
			},
			onEdicao: this.exibirDetalhamentoProvasRespondidas,
			onEdicaoParam1: (grupoProvaRespondidaTO) => {
				if (grupoProvaRespondidaTO?.nome) {
					grupoProvaRespondidaTO.nome = grupoProvaRespondidaTO.nome.replace(/\//g, '-');
				}

				cfgsConsultaIndicePFsTO.paginacaoTO = null;
				cfgsConsultaIndicePFsTO.grupoDetalhamentoTO = grupoProvaRespondidaTO;
				cfgsConsultaIndicePFsTO.campoOrdenacao = null;
				cfgsConsultaIndicePFsTO.isOrdenacaoDecrescente = null;
				return cfgsConsultaIndicePFsTO;
			},
			ordenacao: this.ordenacaoProvasRespondidas,
			onOrdenacaoColuna: async (coluna: ColunaAddTabela, isOrdenacaoDecrescente: boolean) => {

				if (coluna.prop === "nome") {
					cfgsConsultaIndicePFsTO.campoOrdenacao = cfgsConsultaIndicePFsTO.campoAgrupamento.toNomeProp();
				} else {
					cfgsConsultaIndicePFsTO.campoOrdenacao = coluna.prop;
				}

				cfgsConsultaIndicePFsTO.isOrdenacaoDecrescente = isOrdenacaoDecrescente;
				cfgsConsultaIndicePFsTO.paginacaoTO = null;

				resultadoProvasRespondidasTO = await this.call("RelatorioProvasFCD/gerarProvasRespondidas", cfgsConsultaIndicePFsTO);

				this.ordenacaoProvasRespondidas = [[coluna.prop, isOrdenacaoDecrescente ? 1 : 0]];

				await this.exibirTabelaProvasRespondidas(cfgsConsultaIndicePFsTO, colunas, resultadoProvasRespondidasTO);
			}
		});

		this.exibir();
	}

	async exibirDetalhamentoProvasRespondidas(cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO) {

		this.ordenacaoDetalhamentoProvasRespondidas = null;

		let resultadoDetalhamentoProvasRespondidasTO = await this.call("RelatorioProvasFCD/gerarDetalhamentoProvasRespondidas", cfgsConsultaIndicePFsTO);

		const colunas: ColunaAddTabela[] = [{
			titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_012"), 
			prop: "nomeUsuario",
			classe: "descricao",
			regraExibicao: () => cfgsConsultaIndicePFsTO.campoAgrupamento !== CampoIndicePFs.NOME_USUARIO
		}, {
			titulo: this.getMsg("MSG_VH_RAE_03"), 
			prop: "nomeProva",
			regraExibicao: () => cfgsConsultaIndicePFsTO.campoAgrupamento !== CampoIndicePFs.NOME_PROVA
		}, {
			titulo: this.getCfg("LABEL_TURMA"), 
			prop: "nomeTurmaNaAplicacao",
			regraExibicao: () => cfgsConsultaIndicePFsTO.campoAgrupamento !== CampoIndicePFs.NOME_TURMA_NA_APLICACAO
		}, {
			titulo: this.getMsg("MSG_VH_RP_37"), 
			prop: "pontuacaoObtida", 
			formato: "numero"
		}, {
			titulo: this.getMsg("MSG_VH_RP_35"), 
			prop: "pontuacaoMaxima", 
			formato: "numero"
		}, {
			titulo: this.getMsg("MSG_VH_RP_33"),
			prop: "nota", 
			formato: "numero"
		}, {
			titulo: this.getMsg("MSG_VH_RP_35"),
			prop: "notaMaxima", 
			formato: "numero"
		}, {
			titulo: this.getMsg("MSG_VH_RP_06"),
			prop: "aproveitamento", 
			formato: "porcentagem"
		}, {
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_054"),
			prop: "dataInicioPF", 
			formato: "DD/MM/YY HH:mm"
		}, {
			titulo: this.getMsg("MSG_VH_CU_122"),
			prop: "dataFimPF", 
			formato: "DD/MM/YY HH:mm"
		}];

		this.limpar();

		this.setTitulo(cfgsConsultaIndicePFsTO.grupoDetalhamentoTO.nome);
		this.setSubtitulo(this.getMsg("MSG_VH_RP_16"))

		this.setIdTarget("tituloSuperiorDireito");
		this.addBotaoBusca("filtrosPFs", {classe: "btn-sm"});
		this.setIdTarget(null);

		await this.addFiltrosPFs({
			onAplicarFiltros: async () => {
				let cfgsConsultaIndicePFsTOCopy = this.getCfgsConsultaIndicePFsTO();
				if(!cfgsConsultaIndicePFsTOCopy.grupoDetalhamentoTO && cfgsConsultaIndicePFsTO.grupoDetalhamentoTO){
					cfgsConsultaIndicePFsTOCopy['grupoDetalhamentoTO'] = cfgsConsultaIndicePFsTO.grupoDetalhamentoTO;
				}

				resultadoDetalhamentoProvasRespondidasTO = await this.call("RelatorioProvasFCD/gerarDetalhamentoProvasRespondidas", cfgsConsultaIndicePFsTOCopy);

				await this.exibirTabelaDetalhamentoProvasRespondidas(cfgsConsultaIndicePFsTO, colunas, resultadoDetalhamentoProvasRespondidasTO);
			}, 
			cfgsConsultaIndicePFsTO: cfgsConsultaIndicePFsTO, 
			valorIsExibirNotasEPontuacoes: this.isExibirNotasEPontuacoes,
			suprimirCamposOpcionais: true
		});

		this.append(`
			<div class="row">
				<div id="tabela_rel_detalhamento_provas_respondidas" class="tabela-estatisticas col-md-12">
				</div>
			</div>
		`);

		this.exibir();

		await this.exibirTabelaDetalhamentoProvasRespondidas(cfgsConsultaIndicePFsTO, colunas, resultadoDetalhamentoProvasRespondidasTO);
	}
	
	async exibirTabelaDetalhamentoProvasRespondidas(cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO, colunas: ColunaAddTabela[], resultadoDetalhamentoProvasRespondidasTO: any) {

		$("#tabela_rel_detalhamento_provas_respondidas").html("");
		this.setIdTarget("tabela_rel_detalhamento_provas_respondidas");
		this.isExibirNotasEPontuacoes = this.getValor("isExibirNotasEPontuacoes");

		if(this.isExibirNotasEPontuacoes === false){
			colunas = colunas.filter(col => {
				return col.prop !== "pontuacaoObtida" && col.prop !== "pontuacaoMaxima" && col.prop !== "nota" && col.prop !== "notaMaxima";
			});
		}

		await this.addTabela({
			id: cfgsConsultaIndicePFsTO.campoAgrupamento.id,
			collection: resultadoDetalhamentoProvasRespondidasTO.listaDetalhamentoProvaRespondidaTO,
			propId: "codProvaFeita",
			colunas: colunas,
			paginacao: {
				paginacaoTO: resultadoDetalhamentoProvasRespondidasTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {

					cfgsConsultaIndicePFsTO.paginacaoTO = paginacaoTO;

					resultadoDetalhamentoProvasRespondidasTO = await this.call("RelatorioProvasFCD/gerarDetalhamentoProvasRespondidas", cfgsConsultaIndicePFsTO);

					return resultadoDetalhamentoProvasRespondidasTO.listaDetalhamentoProvaRespondidaTO;
				}
			},
			onEdicao: aplicacaoProvaVH.exibirDetalhesProvaRealizada,
			ordenacao: this.ordenacaoDetalhamentoProvasRespondidas,
			onOrdenacaoColuna: async (coluna: ColunaAddTabela, isOrdenacaoDecrescente: boolean) => {

				cfgsConsultaIndicePFsTO.campoOrdenacao = coluna.prop;
				cfgsConsultaIndicePFsTO.isOrdenacaoDecrescente = isOrdenacaoDecrescente;
				cfgsConsultaIndicePFsTO.paginacaoTO = null;

				resultadoDetalhamentoProvasRespondidasTO = await this.call("RelatorioProvasFCD/gerarDetalhamentoProvasRespondidas", cfgsConsultaIndicePFsTO);

				this.ordenacaoDetalhamentoProvasRespondidas = [[coluna.prop, isOrdenacaoDecrescente ? 1 : 0]];

				await this.exibirTabelaDetalhamentoProvasRespondidas(cfgsConsultaIndicePFsTO, colunas, resultadoDetalhamentoProvasRespondidasTO);
			}
		});

		this.exibir();
	}
}

const relatorioProvasVH = new RelatorioProvasVH();
