class RelatorioAplicacoesVH extends AmaisVH {

	filtrosParaGeracaoZipEmLoteTO: any;
	codsEmpresas: any;
	tipoAplicacao: any;
	codsTurmas: any;
	codsSalas: any;
	dataAplicacaoInicio: any;
	dataAplicacaoFim: any;
	codsProvas: any;
	codAluno: any;
	idsFasesFiltroCache: number[];

	constructor() {
		super(RelatorioAplicacoesVH.name);
		this.addOperacaoParaHash("raea", this.exibirAbasRelatorios);
	}

	async exibirAbasRelatorios(numAba) {

		if (numAba == null) {
			this.exibirAbasRelatorios(0);
			return;
		}

		if (this.filtrosParaGeracaoZipEmLoteTO == null) {
			this.filtrosParaGeracaoZipEmLoteTO = await this.call("RelatorioAplicacoesFCD/recuperarFiltros");
			this.exibirAbasRelatorios(numAba);
			return;
		}

		UtilHash.registrarHistorico(this.exibirAbasRelatorios, numAba);

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_RelatorioAplicacoesVH_001"));

		const abas = [];

		abas.push({
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_002"),
			// onClick: async () => await this.exibirPorAplicacao(),
		});
		abas.push({
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_003"),
			// onClick: async () => await this.exibirPorAvaliado(),
		});

		this.addAbas({ abas, numAbaAtiva: numAba });

		let funcaoAtualizarRelatorio = async (...args) => {
			try {
				if (numAba == 0) {
					await this.atualizarRelatorioPorAplicacao();
				} else {
					await this.atualizarRelatorioPorAvaliado();
				}
			} catch (e) {
				this.logger.warn("Erro na atualização do relatório", e);
			}
		}

		this.abrirAbaAccordion({ titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_004"), aberta: true });

		await this.addSelect({
			collection: this.filtrosParaGeracaoZipEmLoteTO.collectionSegmentosTO,
			id: "codsEmpresas",
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_005"),
			multiplo: true,
			valor: this.codsEmpresas,
			classe: "col-xs-12 col-md-6 col-lg-4",
		});

		await this.addSelect({
			collection: this.filtrosParaGeracaoZipEmLoteTO.collectionItemListaTOTiposAplicacao,
			id: "tipoAplicacao",
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_006"),
			valor: this.tipoAplicacao, // || "P",
			classe: "col-xs-12 col-md-6 col-lg-4",
		});

		await this.addSelect({ 
			collection: this.filtrosParaGeracaoZipEmLoteTO.listaTurmasItemListaTO, 
			id: "codsTurmas", 
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_007"), 
			multiplo: true, 
			valor: this.codsTurmas, 
			classe: "col-xs-12 col-md-6 col-lg-4", 
		});

		await this.addSelect({ 
			collection: this.filtrosParaGeracaoZipEmLoteTO.listaSalasItemListaTO, 
			id: "codsSalas", 
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_008"), 
			multiplo: true, 
			valor: this.codsSalas, 
			classe: "col-xs-12 col-md-6 col-lg-4", 
		});

		await this.addSelect({
			collection: this.filtrosParaGeracaoZipEmLoteTO.listaOpcaoListaTOProvas,
			classe: "col-xs-12 col-md-6 col-lg-4",
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_009"),
			multiplo: true,
			id: "codsProvas",
		});

		await this.addSelect({
			id: "codAluno",
			classe: "col-xs-12 col-md-6 col-lg-4",
			label: this.getCfg("LABEL_ALUNO"),
			multiplo: false,
			obrigatorio: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarAlunos",
				numMinimoCaracteres: 3,
			},
		});

		this.addCamposPeriodo({
			label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_010"),
			idInicio: "dataAplicacaoInicio",
			idFim: "dataAplicacaoFim",
			valorInicio: this.dataAplicacaoInicio || UtilData.getDataServidorUltimoResponse(),
			valorFim: this.dataAplicacaoFim
		});

		this.addEspacamentoHorizontal("5px");

		this.addBotao({
			label: "Filtrar",
			classe: "btn-primary pull-right",
			onClick: funcaoAtualizarRelatorio
		});

		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();

		this.exibir();
	}

	async exibirPorAvaliado(relPorAvaliadoTO?: RelPorAvaliadoTO) {

		if ($("#div-exibicao-relatorio").length == 0) {
			this.append("<div class='col-md-12' id='div-exibicao-relatorio'></div>");
			this.exibir();
		}

		$("#div-exibicao-relatorio").html("");
		this.setIdTarget("div-exibicao-relatorio");

		if (this.getFiltrosRelAplicacoesTO() === null) {
			return null;
		}

		if (!relPorAvaliadoTO?.paginacaoTO?.numTotalItens) {
			this.addTexto(this.getMsg("FP_FRONT_RelatorioAplicacoesVH_011"));
			this.exibir();
			return;
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_012"), prop: (to: LinhaRelPorAvaliadoTO) => this.montarColunaAvaliado(to), classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_013"), prop: "cpf"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_014"), prop: (to: LinhaRelPorAvaliadoTO) => this.montarColunaProva(to)});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_015"), prop: (to: LinhaRelPorAvaliadoTO) => this.montarColunaTipo(to)});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_016"), prop: (to: LinhaRelPorAvaliadoTO) => this.montarColunaPeriodoAplicacao(to)});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_017"), prop: (to: LinhaRelPorAvaliadoTO) => this.montarColunaRespondidaEm(to)});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_018"), prop: (to: LinhaRelPorAvaliadoTO) => this.montarColunaSituacao(to)});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_019"), prop: (to: LinhaRelPorAvaliadoTO) => this.montarColunaNota(to)});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_054"), prop: "notaMaxima", formato: "numero"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_020"), prop: (to: LinhaRelPorAvaliadoTO) => this.montarColunaAuditoria(to)});

		await this.addTabela({
			collection: relPorAvaliadoTO.listaLinhaRelPorAvaliadoTO,
			propId: "codAgendamentoUsuario",
			id: "tabela_aplicacoes_por_avaliado",
			colunas: colunas,
			ordenar: false,
			paginacao: {
				paginacaoTO: relPorAvaliadoTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO: PaginacaoTO) => {
					this.atualizarRelatorioPorAvaliado(paginacaoTO);
				},
			},
			download: {
				numItensPorPagina: relPorAvaliadoTO.paginacaoTO.numItensPorPagina,
			}
		});

		this.exibir();
	}

	ordenar() {
		//		alert(1)
	}

	montarColunaProva(linhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO) {
		const numAbaProva = linhaRelPorAvaliadoTO.tipoAplicacao == TipoAplicacao.F ? 4 : 3;
		return "<a href='" + UtilHash.getHash(cadastroProvaVH.exibirAbasProva, numAbaProva, linhaRelPorAvaliadoTO.codProva) + "'>" + linhaRelPorAvaliadoTO.tituloProva + "</a>";
	}

	montarColunaAuditoria(linhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO) {
		const h = [];

		if (linhaRelPorAvaliadoTO.codUsuario && linhaRelPorAvaliadoTO.dataInicioResposta && linhaRelPorAvaliadoTO.tipoAplicacao != TipoAplicacao.F) {
			h.push(this.addBotao({
				label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_021"),
				onClick: async () => await loginVH.exibirSessoesWebDoUsuario(linhaRelPorAvaliadoTO.codUsuario, linhaRelPorAvaliadoTO.dataInicioResposta, linhaRelPorAvaliadoTO.dataInicioResposta),
				retornarHtml: true
			}));
		}
		if (linhaRelPorAvaliadoTO.codProvaFeita) {
			h.push(this.addBotao({
				label: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_022"),
				onClick: async () => await loginVH.exibirAcessosProvaFeita(linhaRelPorAvaliadoTO.codProvaFeita),
				retornarHtml: true
			}));
		}
		return h.join(" ");
	}

	montarColunaTipo(linhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO) {
		if (linhaRelPorAvaliadoTO.tipoAplicacao == TipoAplicacao.F) {
			return `<i class='fa fa-print' title='${this.getMsg("FP_FRONT_RelatorioAplicacoesVH_023")}'></i> `;
		} else {
			return `<i class='fa fa-laptop' title='${this.getMsg("FP_FRONT_RelatorioAplicacoesVH_024")}'></i> `;
		}
	}

	montarColunaAvaliado(linhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO) {
		const hashEdicaoAluno = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, linhaRelPorAvaliadoTO.codUsuario);
		return "<a href='" + hashEdicaoAluno + "'><i class='fa fa-user'></i></a> " + linhaRelPorAvaliadoTO.nome;
	}

	montarColunaPeriodoAplicacao(linhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO) {
		if (linhaRelPorAvaliadoTO.tipoAplicacao == TipoAplicacao.F) {
			if (linhaRelPorAvaliadoTO.dataInicioAplicacao) {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_025", UtilData.toDDMMYYYYHHMM(linhaRelPorAvaliadoTO.dataInicioAplicacao));
			}

		} else {
			if (linhaRelPorAvaliadoTO.dataInicioAplicacao) {
				if (linhaRelPorAvaliadoTO.dataFimAplicacao) {
					return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_026", UtilData.toDDMMYYYYHHMM(linhaRelPorAvaliadoTO.dataInicioAplicacao), UtilData.toDDMMYYYYHHMM(linhaRelPorAvaliadoTO.dataFimAplicacao));
				} else {
					return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_027", UtilData.toDDMMYYYYHHMM(linhaRelPorAvaliadoTO.dataInicioAplicacao));
				}
			} else if (linhaRelPorAvaliadoTO.dataFimAplicacao) {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_028", UtilData.toDDMMYYYYHHMM(linhaRelPorAvaliadoTO.dataFimAplicacao));
			}
		}
	}

	montarColunaRespondidaEm(linhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO) {
		if (linhaRelPorAvaliadoTO.tipoAplicacao == TipoAplicacao.F) {
			return null;
		} else if (linhaRelPorAvaliadoTO.dataFimResposta) {
			return UtilData.toDDMMYYYYHHMM(linhaRelPorAvaliadoTO.dataFimResposta);
		} else if (linhaRelPorAvaliadoTO.dataInicioResposta) {
			return UtilData.toDDMMYYYYHHMM(linhaRelPorAvaliadoTO.dataInicioResposta);
		}
	}

	montarColunaSituacao(linhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO) {
		if (linhaRelPorAvaliadoTO.tipoAplicacao == TipoAplicacao.F) {
			if (linhaRelPorAvaliadoTO.isAguardandoEnvioFRs) {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_031");

			} else if (linhaRelPorAvaliadoTO.isAguardandoCorrecaoDiscursivas) {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_030");

			} else {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_032");
			}

		} else if (!linhaRelPorAvaliadoTO.codProvaFeita) {
			if (linhaRelPorAvaliadoTO.isEmFaseDeAplicacao) {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_033");
			} else {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_034");
			}

		} else if (linhaRelPorAvaliadoTO.isAnulada) {
			return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_035");

		} else if (!linhaRelPorAvaliadoTO.dataInicioResposta) {
			if (linhaRelPorAvaliadoTO.isEmFaseDeAplicacao) {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_036");
			} else {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_037");
			}

		} else if (!linhaRelPorAvaliadoTO.dataFimResposta) {
			if (linhaRelPorAvaliadoTO.isEmFaseDeAplicacao) {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_038");
			} else {
				return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_039");
			}

		} else if (linhaRelPorAvaliadoTO.isAguardandoCorrecaoDiscursivas) {
			return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_040");

		} else {
			return this.getMsg("FP_FRONT_RelatorioAplicacoesVH_041");
		}
	}

	montarColunaNota(linhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO) {
		if (this.hasValue(linhaRelPorAvaliadoTO.nota)) {
			const hash = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, linhaRelPorAvaliadoTO.codProvaFeita);
			return `<a href="${hash}">${UtilNumero.floatToString(linhaRelPorAvaliadoTO.nota)}</a>`;
		}
	}

	async exibirPorAplicacao(listaLinhaRelPorAplicacaoTO?) {

		if ($("#div-exibicao-relatorio").length == 0) {
			this.append("<div class='col-md-12' id='div-exibicao-relatorio'></div>");
			this.exibir();
		}

		$("#div-exibicao-relatorio").html("");
		this.setIdTarget("div-exibicao-relatorio");

		if (this.getFiltrosRelAplicacoesTO() === null) {
			return null;
		}

		if (!listaLinhaRelPorAplicacaoTO?.length) {
			this.addTexto(this.getMsg("FP_FRONT_RelatorioAplicacoesVH_042"));
			this.exibir();
			return;
		}

		this.addEspacamentoHorizontal("20px");

		if (listaLinhaRelPorAplicacaoTO && listaLinhaRelPorAplicacaoTO.length > 0) {

			let numAvaliados = 0;
			let numEncerradas = 0;

			for (const linhaRelPorAplicacaoTO of listaLinhaRelPorAplicacaoTO) {
				numAvaliados += linhaRelPorAplicacaoTO.qtdInscritos;
				numEncerradas += linhaRelPorAplicacaoTO.qtdEncerrados;
			}

			this.append(`<span class='badge pull-right'> ${this.getMsg("FP_FRONT_RelatorioAplicacoesVH_043", numAvaliados, listaLinhaRelPorAplicacaoTO.length)}</span>`);
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_044"), prop: "segmento", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_045"), prop:
				function (linhaRelPorAplicacaoTO) {
					const h = [UtilData.toDDMMYYYYHHMM(linhaRelPorAplicacaoTO.dataAplicacao)];

					if (linhaRelPorAplicacaoTO.tipoAplicacao == TipoAplicacao.P) {
						h.push(`<br><a class='btn btn-default' title='${this.getMsg("FP_FRONT_RelatorioAplicacoesVH_048")}' href='${UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 3, linhaRelPorAplicacaoTO.codProva, "", "", "", linhaRelPorAplicacaoTO.codAgendamento)}'><i class='fa fa-desktop'></i></a>`);
					} else {
						h.push(`<br><a class='btn btn-default' title='${this.getMsg("FP_FRONT_RelatorioAplicacoesVH_049")}' href='${UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 4, linhaRelPorAplicacaoTO.codProva)}'><i class='fa fa fa-print'></i></a>`);
					}

					return h.join("<br>");

				}, formato: "DD/MM/YY HH:mm"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_046"), prop: "prova"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RelatorioAplicacoesVH_047"), prop: this.montarColunaDetalhes, classe: "descricao"});

		await this.addTabela({
			collection: listaLinhaRelPorAplicacaoTO,
			id: "tabela_aplicacoes_para_exportacao",
			propId: "codAgendamento",
			colunas: colunas,
			ordenar: false,
			exibirTotalDeItens: false,
		});

		this.exibir();

		let numAplicacoesComTodosArquivos = 0;

		for (const linhaRelPorAplicacaoTO of listaLinhaRelPorAplicacaoTO) {
			if (!linhaRelPorAplicacaoTO.possuiPaths) {
				$("#tabela_aplicacoes_para_exportacao tr td input[codAgendamento='" + linhaRelPorAplicacaoTO.codAgendamento + "']").attr("disabled", "disabled");
			} else {
				numAplicacoesComTodosArquivos++;
			}
		}

		if (numAplicacoesComTodosArquivos > 0) {
			$("#btnGerarZipLote").removeClass("disabled");
		}
	}

	montarColunaDetalhes(linhaRelPorAplicacaoTO) {
		const h = [];

		if (linhaRelPorAplicacaoTO.sala) {
			h.push(`<strong>${this.getMsg("FP_FRONT_RelatorioAplicacoesVH_050")}</strong>: <br>${linhaRelPorAplicacaoTO.sala}<br>`);
		}

		if (linhaRelPorAplicacaoTO.turmas) {
			h.push(`<strong>${this.getMsg("FP_FRONT_RelatorioAplicacoesVH_051")}</strong>: <br>${linhaRelPorAplicacaoTO.turmas}<br>`);
		}

		if (linhaRelPorAplicacaoTO.qtdInscritos) {
			h.push(`<strong>${this.getMsg("FP_FRONT_RelatorioAplicacoesVH_052", linhaRelPorAplicacaoTO.qtdInscritos)}</strong>:`);

			if (linhaRelPorAplicacaoTO.inscritos) {
				h.push(linhaRelPorAplicacaoTO.inscritos + "<br>");
			}
		}

		return h.join("<br>");
	}

	async atualizarRelatorioPorAplicacao() {
		const filtrosRelAplicacoesTO = this.getFiltrosRelAplicacoesTO();
		if (filtrosRelAplicacoesTO === null) return null;
		const listaLinhaRelPorAplicacaoTO = await this.call("RelatorioAplicacoesFCD/buscar", filtrosRelAplicacoesTO);
		await this.exibirPorAplicacao(listaLinhaRelPorAplicacaoTO);
	}

	async atualizarRelatorioPorAvaliado(paginacaoTO?: PaginacaoTO) {
		const filtrosRelAplicacoesTO = this.getFiltrosRelAplicacoesTO();
		if (filtrosRelAplicacoesTO === null) return null;
		filtrosRelAplicacoesTO.paginacaoTO = paginacaoTO;
		const relPorAvaliadoTO = await this.call("RelatorioAplicacoesFCD/buscarPorAvaliado", filtrosRelAplicacoesTO);
		this.idsFasesFiltroCache = relPorAvaliadoTO.idsFasesFiltroCache;
		await this.exibirPorAvaliado(relPorAvaliadoTO);
	}

	getFiltrosRelAplicacoesTO(): FiltrosRelAplicacoesTO {

		this.codsEmpresas = this.getValor("codsEmpresas");
		this.codsTurmas = this.getValor("codsTurmas");
		this.codsProvas = this.getValor("codsProvas");
		this.codsSalas = this.getValor("codsSalas");
		this.dataAplicacaoInicio = this.getValor("dataAplicacaoInicio");
		this.dataAplicacaoFim = this.getValor("dataAplicacaoFim");
		this.tipoAplicacao = this.getValor("tipoAplicacao");
		this.codAluno = this.getValor("codAluno");

		const filtrosRelAplicacoesTO: FiltrosRelAplicacoesTO = {
			codsEmpresas: this.codsEmpresas,
			codsTurmas: this.codsTurmas,
			codsProvas: this.codsProvas,
			codsSalas: this.codsSalas,
			dataAplicacaoInicio: this.dataAplicacaoInicio,
			dataAplicacaoFim: this.dataAplicacaoFim,
			tipoAplicacao: this.tipoAplicacao,
			codAluno: this.codAluno,
			paginacaoTO: null,
			idsFasesFiltroCache: this.idsFasesFiltroCache
		}

		if (Object.keys(filtrosRelAplicacoesTO).every(key => this.isEmpty(filtrosRelAplicacoesTO[key]))) {

			$("#div-exibicao-relatorio").html("");
			this.setIdTarget("div-exibicao-relatorio");
			this.addTexto(this.getMsg("FP_FRONT_RelatorioAplicacoesVH_053"));
			this.exibir();
			return null;
		}

		return filtrosRelAplicacoesTO;
	}
}

const relatorioAplicacoesVH = new RelatorioAplicacoesVH();



type FiltrosRelAplicacoesTO = {
	codsEmpresas: number[];
	codsTurmas: number[];
	codsProvas: number[];
	codsSalas: number[];
	dataAplicacaoInicio: string;
	dataAplicacaoFim: string;
	tipoAplicacao: TipoAplicacao;
	codAluno: number;
	paginacaoTO: PaginacaoTO;
	idsFasesFiltroCache: number[];
}
type RelPorAvaliadoTO = {
	paginacaoTO: PaginacaoTO;
	idsFasesFiltroCache: number[];
	listaLinhaRelPorAvaliadoTO: LinhaRelPorAvaliadoTO[];
}
type LinhaRelPorAvaliadoTO = {
	codUsuario: number;
	codProvaFeita: number;
	codAgendamentoUsuario: number;
	nome: string;
	cpf: string;
	matricula: string;
	dataNascimento: string;
	login: string;
	email: string;
	telefone: string;
	nota: number;
	notaMaxima: number;
	codProva: number;
	tituloProva: string;
	codAgendamento: number;
	tipoAplicacao: TipoAplicacao;
	dataInicioAplicacao: string;
	dataFimAplicacao: string;
	isEmFaseDeAplicacao: boolean;
	dataInicioResposta: string;
	dataFimResposta: string;
	isAguardandoEnvioFRs: boolean;
	isAguardandoCorrecaoDiscursivas: boolean;
	isAnulada: boolean;
}