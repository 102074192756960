class MonitoramentoVH extends AmaisVH {

	constructor() {
		super(MonitoramentoVH.name);
		this.addOperacaoParaHash("mmig", this.exibirInfosGerais);	
	}

	async exibirInfosGerais() {

		UtilHash.registrarHistorico(this.exibirInfosGerais);
		
		const exibicaoMonitoramentoGeralTO = await this.call("MonitoramentoFCD/recuperarInfosGerais");

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_MonitoramentoVH_001"));
		
		this.addSubtitulo(this.getMsg("FP_FRONT_MonitoramentoVH_002"));
		
		exibicaoMonitoramentoGeralTO.volumetriaProximasAOsTO.forEach((collectionRelTO, i) => {
			this.append(`<div id='volumetria_ao_${i}' class='col-md-12' style='min-height: 200px; margin-bottom: 20px'></div>`);
		})
		
		this.exibir();
		
		exibicaoMonitoramentoGeralTO.volumetriaProximasAOsTO.forEach((collectionRelTO, i) => {
			var colunas = [
				["", "titulo"], 
			];
			
			const consolidado = {};
			const dia = collectionRelTO[0].minuto.split(" ")[0]; 
			
			collectionRelTO.forEach(relTO => {
				var tempo = relTO.minuto.split(" ")[1];
				colunas.push([tempo, tempo, "numero"]);
				consolidado[tempo] = relTO.numAvaliados;
			});
			
			if (colunas.length > 50) {
				colunas.forEach((array) => {
					array[0] = array[0].endsWith("00") || array[0].endsWith("30") ? array[0] : "";
				});
			}

			UtilGrafico.criarEChart("#volumetria_ao_" + i, {
				tos: [consolidado],
				props: colunas,
				tipo: "LINHAS",
				titulo: this.getMsg("FP_FRONT_MonitoramentoVH_003") + " - " + dia
			});
		});
		
		this.exibir();
	}
}

const monitoramentoVH = new MonitoramentoVH();