class RevisaoProvaVH extends AmaisVH {

	exibicaoRevisaoDeProvasTO: any;
	substituicaoQuestoesRepetidasTO: any;
	questoesConteudoBloqueadoGabarito: any = [];

	constructor() {
		super(RevisaoProvaVH.name);
	}

	async editar(codProva, codQuestao, idProvaResponsavel?) {
		let filtroExibicaoRevisaoProvaTO = {
			codProva: codProva,
			codQuestao: codQuestao || null,
			idProvaResponsavel: idProvaResponsavel || null
		};

		let exibicaoRevisaoDeProvasTO = await this.call("RevisaoProvaFCD/recuperarRevisaoProvaInteira", filtroExibicaoRevisaoProvaTO);

		await this.exibirRevisaoDeProvas(exibicaoRevisaoDeProvasTO, true);
	}

	async exibirClassificacoes(codsProvas, codUsuario, dataInicio, dataFim) {

		let filtrosExibicaoClassificacoesPorRevisor = {
			codUsuario: codUsuario,
			codsProvas: codsProvas,
			dataInicio: UtilData.usuarioDataToDate(dataInicio),
			dataFim: UtilData.usuarioDataToDate(dataFim)
		}

		const exibicaoRevisaoDeProvasTO = await this.call("RevisaoProvaFCD/recuperarRevisoesPorRevisor", filtrosExibicaoClassificacoesPorRevisor);
		await this.exibirRevisaoDeProvas(exibicaoRevisaoDeProvasTO);
	}

	async exibirQuestoesNaoClassificadas(codDisciplina) {
		const exibicaoRevisaoDeProvasTO = await this.call("RevisaoProvaFCD/recuperarRevisaoPorDisciplina", codDisciplina, false);
		await this.exibirRevisaoDeProvas(exibicaoRevisaoDeProvasTO);
	}

	async revisarTodasQuestoesDaDisciplina(codDisciplina) {
		const exibicaoRevisaoDeProvasTO = await this.call("RevisaoProvaFCD/recuperarRevisaoPorDisciplina", codDisciplina, null);
		await this.exibirRevisaoDeProvas(exibicaoRevisaoDeProvasTO);
	}

	async inicioPorDisciplina(codDisciplina) {
		const exibicaoRevisaoDeProvasTO = await this.call("RevisaoProvaFCD/recuperarRevisaoPorDisciplina", codDisciplina, null);
		await this.exibirRevisaoDeProvas(exibicaoRevisaoDeProvasTO);
	}

	async atualizarRevisao() {
		if (this.exibicaoRevisaoDeProvasTO.codProva != null) {
			let filtroExibicaoRevisaoProvaTO = { codProva: this.exibicaoRevisaoDeProvasTO.codProva, codQuestao: null };
			const exibicaoRevisaoDeProvasTO = await this.call("RevisaoProvaFCD/recuperarRevisaoProvaInteira", filtroExibicaoRevisaoProvaTO);
			await this.exibirRevisaoDeProvas(exibicaoRevisaoDeProvasTO);
		} else {
			const exibicaoRevisaoDeProvasTO = await this.call("RevisaoProvaFCD/recuperarRevisaoPorDisciplina", this.exibicaoRevisaoDeProvasTO.codDisciplina, false);
			await this.exibirRevisaoDeProvas(exibicaoRevisaoDeProvasTO);
		}
	}

	async exibirRevisaoDeProvas(exibicaoRevisaoDeProvasTO, exibicaoCompacta = false) {

		this.exibicaoRevisaoDeProvasTO = exibicaoRevisaoDeProvasTO;

		this.limpar(true);

		exibicaoQuestaoVH.limparControleTextos();

		this.append(`
			<div class='row'>
				<div barra-revisao id='barraRevisao' style='position: absolute; top: 20%; left: 2px;'>
					<div class='btn-group-vertical'>
		`);

		if (exibicaoRevisaoDeProvasTO.codProva) {
			this.addBotao({
				label: "<i class='fa fa-eye'></i>",
				onClick: async () => await loginVH.exibirAcessosDaProva(exibicaoRevisaoDeProvasTO.codProva, true),
				classe: "btn-sm",
				dica: "Auditoria",
				html: "data-placement='bottom'"
			});
		}

		if (this.isRevisor()) {
			this.addBotao({
				id: "revisao_editar_texto",
				texto: "<i class='fa fa-align-justify'></i>",
				onClick: async () => await revisaoProvaVH.editarTexto(),
				classe: "btn-sm",
				dica: "Criar novo texto",
				html: "data-placement='bottom'",
				visivel: exibicaoRevisaoDeProvasTO.numQuestoes > 0
			});
		}

		if (this.isClassificador()) {
			this.addBotao({
				id: "revisao_esconder_clasificacao",
				texto: "<i class='fa fa-road'></i>",
				onClick: async () => await revisaoProvaVH.exibirOuEsconderNumQuestoesClassificadasDoRevisorLogado(),
				classe: "btn-sm",
				dica: this.getMsg("FP_FRONT_RevisaoProvaVH_002"),
				html: "data-placement='bottom'",
				visivel: exibicaoRevisaoDeProvasTO.numQuestoes > 0
			});
		}

		this.addBotao({
			id: "revisao_limpar_selecionadas",
			texto: "<i class='fa fa-ban'></i>",
			onClick: async () => await revisaoProvaVH.limparQuestoesSelecionadas(),
			classe: "btn-sm",
			dica: this.getMsg("FP_FRONT_RevisaoProvaVH_003"),
			html: "data-placement='bottom'",
			visivel: exibicaoRevisaoDeProvasTO.numQuestoes > 0
		});
		this.addBotao({
			id: "revisao_atualizar_revisao",
			texto: "<i class='fa fa-refresh'></i>",
			onClick: async () => await revisaoProvaVH.atualizarRevisao(),
			classe: "btn-sm",
			dica: this.getMsg("FP_FRONT_RevisaoProvaVH_005"),
			html: "data-placement='bottom'",
			visivel: exibicaoRevisaoDeProvasTO.numQuestoes > 0
		});
		this.addBotao({
			id: "revisao_exportar",
			texto: "<i class='fa fa-file-word-o'></i>",
			onClick: async () => await revisaoProvaVH.exportar(),
			classe: "btn-sm",
			dica: this.getMsg("FP_FRONT_RevisaoProvaVH_004"),
			html: "data-placement='bottom'",
			visivel: exibicaoRevisaoDeProvasTO.numQuestoes > 0
		});

		if (exibicaoRevisaoDeProvasTO.codProva) {

			for (const exibicaoProvaTO of exibicaoRevisaoDeProvasTO.collectionExibicaoProvaTO) {

				if (exibicaoProvaTO.pathArquivoEdital || exibicaoProvaTO.pathArquivoProva || exibicaoProvaTO.pathArquivoGabaritoPreliminar || exibicaoProvaTO.pathArquivoGabaritoDefinitivo || exibicaoProvaTO.pathArquivoAtualizacaoGabarito) {
					this.append("</div><br><br><div class='btn-group-vertical'>");
				}
				if (exibicaoProvaTO.pathArquivoEdital) {
					this.addBotao({
						texto: "EDITAL",
						dica: this.getMsg("FP_FRONT_RevisaoProvaVH_006"),
						classe: "btn-sm",
						href: exibicaoProvaTO.pathArquivoEdital,
					})
					this.addBotao({
						texto: "<i class='fa fa-file-o'></i>",
						dica: this.getMsg("FP_FRONT_RevisaoProvaVH_007"),
						classe: "btn-sm",
						onClick: () => this.abrirPDFAoLado(exibicaoProvaTO.pathArquivoEdital),
					})
				}

				if (exibicaoProvaTO.pathArquivoProva) {
					this.addBotao({
						texto: "PROVA",
						dica: this.getMsg("FP_FRONT_RevisaoProvaVH_008"),
						classe: "btn-sm",
						href: exibicaoProvaTO.pathArquivoProva,
					})
					this.addBotao({
						texto: "<i class='fa fa-file-o'></i>",
						dica: this.getMsg("FP_FRONT_RevisaoProvaVH_009"),
						classe: "btn-sm",
						onClick: () => this.abrirPDFAoLado(exibicaoProvaTO.pathArquivoProva),
					})
				}

				if (exibicaoProvaTO.pathArquivoGabaritoDefinitivo) {
					this.addBotao({ texto: "GAB DEF", dica: this.getMsg("FP_FRONT_RevisaoProvaVH_010"), classe: "btn-sm", href: exibicaoProvaTO.pathArquivoGabaritoDefinitivo })
					this.addBotao({ texto: "<i class='fa fa-file-o'></i>", dica: this.getMsg("FP_FRONT_RevisaoProvaVH_011"), classe: "btn-sm", onClick: () => this.abrirPDFAoLado(exibicaoProvaTO.pathArquivoGabaritoDefinitivo) })

				} else if (exibicaoProvaTO.pathArquivoGabaritoPreliminar) {
					this.addBotao({ texto: "GAB PRE", dica: this.getMsg("FP_FRONT_RevisaoProvaVH_012"), classe: "btn-sm", href: exibicaoProvaTO.pathArquivoGabaritoPreliminar })
					this.addBotao({ texto: "<i class='fa fa-file-o'></i>", dica: this.getMsg("FP_FRONT_RevisaoProvaVH_013"), classe: "btn-sm", onClick: () => this.abrirPDFAoLado(exibicaoProvaTO.pathArquivoGabaritoPreliminar) })
				}

				if (exibicaoProvaTO.pathArquivoAtualizacaoGabarito) {
					this.addBotao({ texto: "ATUALIZ", dica: this.getMsg("FP_FRONT_RevisaoProvaVH_014"), classe: "btn-sm", href: exibicaoProvaTO.pathArquivoAtualizacaoGabarito })
					this.addBotao({ texto: "<i class='fa fa-file-o'></i>", dica: this.getMsg("FP_FRONT_RevisaoProvaVH_015"), classe: "btn-sm", onClick: () => this.abrirPDFAoLado(exibicaoProvaTO.pathArquivoAtualizacaoGabarito) })
				}

				if (this.isConteudista()) {
					this.addBotao({ 
						label: this.getMsg("FP_FRONT_RevisaoProvaVH_017"), 
						dica: this.getMsg("FP_FRONT_RevisaoProvaVH_016"), 
						classe: "btn-sm", 
						onClick: async () => await importacaoProvaVH.editarImportacao(null, exibicaoProvaTO.codProva) 
					});
				}

				if(exibicaoCompacta){
					this.append(`
						<a class='btn btn-default btn-sm' onclick='revisaoProvaVH.abrirGabaritoProvaPopup(${exibicaoRevisaoDeProvasTO.codProva})'>
							${this.getMsg("FP_FRONT_RevisaoProvaVH_018")}
						</a>
						<a class='btn btn-default btn-sm' onclick='revisaoProvaVH.abrirProvasUtilizadasPopup(${exibicaoRevisaoDeProvasTO.codProva})'>
							${this.getMsg("FP_FRONT_RevisaoProvaVH_143")}
						</a>
					`);
				}
			}
		}

		this.append(`
						</div>
						<br>
						<br>
						<div class='btn-group-vertical'>
		`);

		for (let exibicaoProvaTO of exibicaoRevisaoDeProvasTO.collectionExibicaoProvaTO) {

			if (exibicaoProvaTO.secoes.length > 1) {
				for (let exibicaoSecaoNaRevisaoTO of exibicaoProvaTO.secoes) {
					if(exibicaoCompacta){
						this.append(`
							<a class='btn btn-default btn-sm' 
								onclick='revisaoProvaVH.carregarQuestoesSecao(${exibicaoSecaoNaRevisaoTO.codSecao})'
								style='text-align: left;'
							>
								${exibicaoSecaoNaRevisaoTO.nomeSecao}
							</a>`
						);
					} else{
						this.append(`
							<a class='btn btn-default btn-sm' 
								onclick='UtilWindow.scrollTo("[secao][cod-secao=${exibicaoSecaoNaRevisaoTO.codSecao}]")' 
								style='text-align: left;'
							>
								${exibicaoSecaoNaRevisaoTO.nomeSecao}
							</a>`
						);
					}
				}
			}

			if (exibicaoRevisaoDeProvasTO.codProva != null) {
				if(exibicaoRevisaoDeProvasTO.numQuestoes){
					this.append(`
						<a class='btn btn-default btn-sm' onclick='UtilWindow.scrollTo("[gabaritos]")'>
							${this.getMsg("FP_FRONT_RevisaoProvaVH_018")}
						</a>
					`);
				}
			}
		}

		this.append(`
					</div>
					<br>
					<br>
					<div questoes-selecionadas class='btn-group-vertical'>
					</div>
					</div>
		`);

		let jaTrabalhouNaDemanda = false;
		let idsSecaoQuestaoParaCarregamento = [];

		for (let exibicaoProvaTO of exibicaoRevisaoDeProvasTO.collectionExibicaoProvaTO) {

			this.append("<div revisao-prova prova class='col-md-12' cod-prova='" + exibicaoProvaTO.codProva + "'");

			if (exibicaoProvaTO.demandaProvaTO) {
				jaTrabalhouNaDemanda = exibicaoProvaTO.demandaProvaTO.numQuestoesElaboradas || exibicaoProvaTO.demandaProvaTO.numQuestoesSelecionadas || exibicaoProvaTO.demandaProvaTO.numQuestoesRevisadas;
				this.append(" id-prova-responsavel='" + exibicaoProvaTO.demandaProvaTO.idProvaResponsavel + "'");
			}

			this.append(">");

			if (!exibicaoRevisaoDeProvasTO.codProva) {
				this.addTitulo({ texto: exibicaoProvaTO.nomeProva });
			}

			this.exibirDemandaProva(exibicaoProvaTO.demandaProvaTO);

			this.addEspacamentoHorizontal();

			if (exibicaoProvaTO.secoes != null) {

				for (let exibicaoSecaoNaRevisaoTO of exibicaoProvaTO.secoes) {

					this.append(`
						<div class='row'>
							<div secao ${exibicaoCompacta? 'secao-compacta opened="false"': null} class='col-md-12' cod-secao='${exibicaoSecaoNaRevisaoTO.codSecao}'>
								<h2 class='titulo-nome-secao col-md-12'>
								<input seletor-secao type='checkbox' onchange='revisaoProvaVH.selecionarQuestoesDaSecao(this)' style="display: ${exibicaoCompacta? 'none': null}"/>
								<a nome-secao onclick='cadastroSecaoVH.exibirEdicaoSecao(${exibicaoSecaoNaRevisaoTO.codSecao}, "revisaoProvaVH.atualizarRevisao")'> 
									${exibicaoSecaoNaRevisaoTO.nomeSecao}
								</a>
					`);

					if (exibicaoCompacta) {
						this.append(`
								<div class="btn-group pull-right btn-group-revisao-secoes">
						`);
						this.addBotao({
							id: `btn-ver-questao-secao-${exibicaoSecaoNaRevisaoTO.codSecao}`,
							label: `<i class='fa fa-arrows-v'></i> ${this.getMsg("FP_FRONT_RevisaoProvaVH_138")}`,
							classe: "btn-primary btn-sm btn-ver-questoes-secoes",
							onClick: async () => {
								await this.carregarQuestoesSecao(exibicaoSecaoNaRevisaoTO.codSecao);
							}
						});
						if (exibicaoProvaTO.demandaProvaTO) {
							this.addBotao({
								id: `btn-adicionar-questao-secao-${exibicaoSecaoNaRevisaoTO.codSecao}`,
								label: `<i class='fa fa-plus-circle'></i>`,
								classe: "btn-success btn-sm",
								dica: this.getMsg("FP_FRONT_RevisaoProvaVH_030"),
								onClick: () => {
									cadastroQuestaoVH.exibirAbasQuestao(1, null, exibicaoProvaTO.demandaProvaTO.idProvaResponsavel, null, (codQuestao: number) => {
										cadastroProvaVH.exibirAbasProva(2, exibicaoProvaTO.demandaProvaTO.codProva, codQuestao, exibicaoProvaTO.demandaProvaTO.idProvaResponsavel).then(() => {}, () => {});
									}, null, exibicaoSecaoNaRevisaoTO.codSecao).then(() => {}, () => {});
								}
							});
						}
						this.append(`
								</div>
						`);
					}

					this.append(`<small detalhes-secao>${exibicaoSecaoNaRevisaoTO.detalhes}</small>`);
					this.append("</h2>");

					if (exibicaoSecaoNaRevisaoTO.questoes != null) {

						let questoes = exibicaoSecaoNaRevisaoTO.questoes;

						for (const exibicaoQuestaoNaRevisaoTO of questoes) {
							this.append(`
								<div exibicao-questao-na-revisao="${exibicaoQuestaoNaRevisaoTO.codQuestao}" class="row">
									<div class='col-md-12'>
										<i class='fas fa-spinner fa-lg fa-spin'></i>
									</div>
								</div>
							`);

							idsSecaoQuestaoParaCarregamento.push(exibicaoQuestaoNaRevisaoTO.idSecaoQuestao);
						}
					}

					this.append("</div></div>");
				}
			}

			this.addEspacamentoHorizontal("30px");

			if (exibicaoRevisaoDeProvasTO.numQuestoes && exibicaoRevisaoDeProvasTO.codProva != null) {

				if (this.hasValue(exibicaoProvaTO.collectionExibicaoProvaUtilizadaTO)) {
					this.addSubtitulo(this.getMsg("FP_FRONT_RevisaoProvaVH_025"));
					await this.gerarTabelaProvasUtilizadas( exibicaoProvaTO.collectionExibicaoProvaUtilizadaTO);
				}

				this.addSubtitulo("Gabarito da prova");

				this.append("<table gabaritos class='gabarito'><tbody><tr></tr></tbody></table>");

				if (this.isRevisor()) {
					this.append("<div id='container-alteracao-gabarito'>");
					this.addBotao({
						id: "btnInserirGabarito",
						label: this.getMsg("FP_FRONT_RevisaoProvaVH_026"),
						classe: "btn-sm",
						onClick: async (event) => await this.exibirEditorGabarito(event.target, exibicaoProvaTO.secoes)
					});
					this.append("</div>");
				}
			}
		}

		if (!jaTrabalhouNaDemanda && !exibicaoCompacta && exibicaoRevisaoDeProvasTO.numQuestoes == 0) {
			this.addTexto(this.getMsg("FP_FRONT_RevisaoProvaVH_027"));
		}

		this.append("</div>");

		this.exibir();

		if (exibicaoRevisaoDeProvasTO.limiteExcedido) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RevisaoProvaVH_028", exibicaoRevisaoDeProvasTO.numQuestoesSemClassificacaoPermitida) });
		}

		let paddingBarra = $("[barra-revisao]").width() - 30 + "px";
		$("[corpo], [identificacao-conteudo]").css("padding-left", paddingBarra);

		this.carregarQuestoes({
			idsSecaoQuestaoParaCarregamento: idsSecaoQuestaoParaCarregamento,
			codQuestaoFoco: exibicaoRevisaoDeProvasTO.codQuestaoFoco
		});

		this.addBotaoScrollParaTopo();
	}

	async gerarTabelaProvasUtilizadas(collectionExibicaoProvaUtilizadaTO){

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_019"), prop: "tituloProva"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_020"), prop: "siglaBanca"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_021"), prop: "ano"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_022"), prop: "${numQuestoesUtilizadas} / ${numQuestoes}"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_023"), prop: "isCadastroRevisado"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_024"), prop: "isGabaritoRevisado"});

		await this.addTabela({
			collection: collectionExibicaoProvaUtilizadaTO,
			propId: "codProva",
			colunas: colunas,
			onEdicao: cadastroProvaVH.exibirAbasProva,
			onEdicaoParam1: 2
		});
	}

	async abrirProvasUtilizadasPopup(codProva){
		const collectionExibicaoProvaUtilizadaTO = await this.call("RevisaoProvaFCD/recuperarExibicaoProvaUtilizada", codProva);

		this.addPopup({
			id: "popup_provas_utilizadas",
			titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_025"),
			width: "80%",
			botoes: [{
				label: this.getMsg("FP_FRONT_RevisaoProvaVH_141"),
				onClick: async () => {}
			}]
		});

		await this.gerarTabelaProvasUtilizadas(collectionExibicaoProvaUtilizadaTO);

		this.exibirPopups();
	}

	async abrirGabaritoProvaPopup(codProva){
		const listaQuestoesGabarito = await this.call("RevisaoProvaFCD/recuperarGabaritoProva", codProva);

		this.addPopup({
			id: "popup_gabarito_prova",
			titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_140"),
			width: "80%",
			botoes: [{
				label: this.getMsg("FP_FRONT_RevisaoProvaVH_141"),
				onClick: async () => {}
			}]
		});

		this.append(`
			<table gabarito-prova="${codProva}" class='gabarito' style="width: 100%">
				<tbody></tbody>
			</table>
		`);

		this.exibirPopups();

		let contadorQuestoes = 0;

		for(let questaoGabarito of listaQuestoesGabarito){
			if (contadorQuestoes % 10 == 0) {
				$(`[gabarito-prova="${codProva}"] tbody`).append("<tr></tr>");
			}
			$(`[gabarito-prova="${codProva}"] tr:last`).append(`
				<td>${questaoGabarito.numQuestao}</td>
				<td>&nbsp;${questaoGabarito.gabarito ? questaoGabarito.gabarito : "-"}</td>	
			`);
			contadorQuestoes ++;
		}
	}

	async carregarQuestoesSecao(codSecao){
		this.disable(".btn-ver-questoes-secoes");
		let secaoHtml = $(`[cod-secao="${codSecao}"]`);
		let isOpened = secaoHtml.attr("opened") === "true";
		let secaoCheckbox = secaoHtml.find(".titulo-nome-secao > [seletor-secao]").get(0);
		let botaoSecao= $(`#btn-ver-questao-secao-${codSecao}`);
		if(isOpened){
			secaoHtml.find("[exibicao-questao-na-revisao]").fadeOut(function() {
				this.remove();
			});
			secaoHtml.find("[container-gabarito-secao]").fadeOut(function() {
				this.remove();
			});
			secaoHtml.find("#resultadoAlteracao").fadeOut(function() {
				this.remove();
			});
			secaoHtml.find("#container-alteracao-gabarito").fadeOut(function() {
				this.remove();
			});
			secaoHtml.find("#btnInserirGabarito").fadeOut(function() {
				this.remove();
			});
			secaoHtml.attr("opened", "false");
			$(secaoCheckbox).css("display", "none");
			await this.hide("revisao_editar_texto", "revisao_esconder_clasificacao", "revisao_limpar_selecionadas", "revisao_atualizar_revisao", "revisao_exportar");
			botaoSecao.html(`<i class='fa fa-arrows-v' style="color: white;"></i> ${this.getMsg("FP_FRONT_RevisaoProvaVH_138")}`);
		}
		else{
			let listaExibicaoQuestao = await this.call("RevisaoProvaFCD/recuperarQuestoesSecao", codSecao);

			if(!this.isEmpty(listaExibicaoQuestao)){
				this.criarExibicaoQuestaoSecao(listaExibicaoQuestao, codSecao);
				$(secaoCheckbox).css("display", "");
				secaoHtml.attr("opened", "true");
				await this.show("revisao_editar_texto", "revisao_esconder_clasificacao", "revisao_limpar_selecionadas", "revisao_atualizar_revisao", "revisao_exportar");
				botaoSecao.html(`<i class='fa fa-arrows-v' style="color: white;"></i> ${this.getMsg("FP_FRONT_RevisaoProvaVH_139")}`);
			}
		}
		this.enable(".btn-ver-questoes-secoes");
		UtilWindow.scrollTo(`[secao][cod-secao=${codSecao}]`);
	}

	static async handleAtualizarExecutarQuestao(event) {
		event.preventDefault();
		event.stopPropagation();
		await revisaoProvaVH.onAtualizarQuestao(event.target);
	}

	async carregarQuestoes(cfgs) {

		if (!cfgs.idsSecaoQuestaoParaCarregamento || cfgs.idsSecaoQuestaoParaCarregamento.length == 0) {

			$("[questao]")
				.off("atualizar-executar", RevisaoProvaVH.handleAtualizarExecutarQuestao)
				.on("atualizar-executar", RevisaoProvaVH.handleAtualizarExecutarQuestao)
				;

			this.atualizarGabarito();

			if (cfgs.codQuestaoFoco) {
				UtilWindow.scrollTo(`[questao][cod-questao='${cfgs.codQuestaoFoco}']`);
			}
			return;
		}

		let loteIdsSecaoQuestao = cfgs.idsSecaoQuestaoParaCarregamento.splice(0, 10);

		const listaExibicaoQuestaoNaRevisaoTO = await this.call("RevisaoProvaFCD/criarExibicaoQuestaoNaRevisaoTO", null, true, loteIdsSecaoQuestao);
		this.exibirQuestoesSecao(listaExibicaoQuestaoNaRevisaoTO, cfgs);
	}

	exibirQuestoesSecao(listaExibicaoQuestaoNaRevisaoTO, cfgs){
		let seletoresQuestoes = [];
		for (let exibicaoQuestaoNaRevisaoTO of listaExibicaoQuestaoNaRevisaoTO) {
			let exibicaoQuestaoTO = exibicaoQuestaoNaRevisaoTO.exibicaoQuestaoTO;
			let cfgsQuestao = this.getCfgsExibicao(exibicaoQuestaoNaRevisaoTO);

			let idDivRevisaoQuestao = "[exibicao-questao-na-revisao='" + exibicaoQuestaoNaRevisaoTO.codQuestao + "']";
			$(idDivRevisaoQuestao).html("");
			this.setIdTarget(idDivRevisaoQuestao);
			exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO, cfgsQuestao);
			this.setIdTarget(null);

			seletoresQuestoes.push(`[questao][cod-questao="${exibicaoQuestaoNaRevisaoTO.codQuestao}"]`);
		}

		this.exibir();
		this.tornarExibicoesQuestoesEditaveis(seletoresQuestoes.join(","));
		if(cfgs){
			this.carregarQuestoes(cfgs);
		}
	}

	criarExibicaoQuestaoSecao(listaExibicaoQuestaoNaRevisaoTO, codSecao){

		let seletoresQuestoes = [];
		for (let exibicaoQuestaoNaRevisaoTO of listaExibicaoQuestaoNaRevisaoTO) {
			let exibicaoQuestaoTO = exibicaoQuestaoNaRevisaoTO.exibicaoQuestaoTO;
			let cfgsQuestao = this.getCfgsExibicao(exibicaoQuestaoNaRevisaoTO);

			this.setIdTarget(`[cod-secao="${codSecao}"]`);

			this.append(`
				<div exibicao-questao-na-revisao='${exibicaoQuestaoNaRevisaoTO.codQuestao}' class="row">
					<div class='col-md-12'></div>
				</div>
			`);

			this.setIdTarget(`[exibicao-questao-na-revisao='${exibicaoQuestaoNaRevisaoTO.codQuestao}']`);
			exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO, cfgsQuestao);
			this.setIdTarget(null);

			seletoresQuestoes.push("[questao][cod-questao='" + exibicaoQuestaoNaRevisaoTO.codQuestao + "']");
		}

		this.setIdTarget(`[cod-secao="${codSecao}"]`);
		this.append("<div container-gabarito-secao>");
		this.addEspacamentoHorizontal("20");
		this.addSubtitulo(this.getMsg("FP_FRONT_RevisaoProvaVH_142"));
		this.append(`
			<table gabarito-secao="${codSecao}" class='gabarito'>
				<tbody><tr></tr></tbody>
			</table>
		`);
		this.append("</div>");
		if (this.isRevisor()) {
			this.append("<div id='container-alteracao-gabarito'>");
			this.addBotao({
				id: "btnInserirGabarito",
				label: this.getMsg("FP_FRONT_RevisaoProvaVH_026"),
				classe: "btn-sm",
				onClick: async (event) => await this.exibirEditorGabarito(event.target, null, codSecao)
			});
			this.append("</div>");
		}
		this.setIdTarget(null);

		this.exibir();

		$("[questao]")
			.off("atualizar-executar", RevisaoProvaVH.handleAtualizarExecutarQuestao)
			.on("atualizar-executar", RevisaoProvaVH.handleAtualizarExecutarQuestao)
			;
		this.atualizarGabaritoSecao(codSecao);

		setTimeout(() => {
			this.tornarExibicoesQuestoesEditaveis(seletoresQuestoes.join(","));
		}, 1000);
	}

	exibirDemandaProva(demandaProvaTO, isNaAbaDeQuestoes: boolean = false) {

		if (demandaProvaTO) {
			let idBox = "box_demanda_" + demandaProvaTO.codProva;
			let elementoBox = null;
			let idDivTemp = null;

			if ($("#" + idBox).length > 0) {
				elementoBox = $("#" + idBox).closest(".well").get(0);
				idDivTemp = this.gerarId();
				this.append("<div style='display:none' id='" + idDivTemp + "'></div>");
				this.setIdTarget(idDivTemp);
			}

			let botoes = [];
			let isDemandaAtendida = demandaProvaTO.numQuestoesSolicitadas != null && !demandaProvaTO.pendenciasInsercao && !demandaProvaTO.pendenciasRevisao;

			if (!demandaProvaTO.dataLiberacao) {
				let botaoImportacao = this.addBotao({
					id: 'botao_importar_questoes_doc',
					label: `<i class='fa fa-upload'></i> ${this.getMsg("FP_FRONT_RevisaoProvaVH_029")}`,
					classe: "btn-sm",
					retornarHtml: true,
					onClick: async () => await importacaoProvaVH.exibirTelaImportacaoQuestoes(demandaProvaTO.idProvaResponsavel, null, demandaProvaTO.codProva)
				});
				let botaoCriarQuestao = this.addBotao({
					id: "bntCriarNovaQuestaoNaRevisao",
					label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_RevisaoProvaVH_030")}`, 
					classe: "btn-success btn-sm", 
					retornarHtml: true, 
					onClick: () => {
						cadastroQuestaoVH.exibirAbasQuestao(1, null, demandaProvaTO.idProvaResponsavel, null, (codQuestao: number) => {
							cadastroProvaVH.exibirAbasProva(2, demandaProvaTO.codProva, codQuestao, demandaProvaTO.idProvaResponsavel).then(() => {}, () => {});
						}).then(() => {}, () => {});
					}
				});
				let botaoBuscarQuestoes = this.addBotao({
					label: `<i class='fa fa-search'></i> ${this.getMsg("FP_FRONT_RevisaoProvaVH_031")}`,
					classe: "btn-sm",
					retornarHtml: true,
					onClick: () => {
						cadastroProvaVH.exibirAbasProva(
							1, demandaProvaTO.codProva, null, demandaProvaTO.idProvaResponsavel, true).then(() => {}, () => {});
					}
				});

				if (!UtilAuth.possuiAcesso(TipoFuncionalidade.PROVA_IMPORTACAO)) {
					botaoImportacao = "";
				}

				botoes.push("<div class='btn-group pull-right'>");

				if (demandaProvaTO.tipoTrabalho == "GESTAO" || demandaProvaTO.tipoTrabalho == "IMPORTACAO_PROVA" || demandaProvaTO.tipoTrabalho == "CRIACAO_PROVA") {
					botoes.push(botaoImportacao);
					botoes.push(botaoCriarQuestao);
					if (!isNaAbaDeQuestoes) botoes.push(botaoBuscarQuestoes);
				} else if (!isDemandaAtendida) {
					if (demandaProvaTO.tipoTrabalho == "ELABORACAO") {
						botoes.push(botaoImportacao);
						botoes.push(botaoCriarQuestao);
					} else if (demandaProvaTO.tipoTrabalho == "SELECAO") {
						if (!isNaAbaDeQuestoes) botoes.push(botaoBuscarQuestoes);
					}
				}

				botoes.push(this.addBotao({
					label: "<i class='fa fa-stop-circle'></i> ENCERRAR " + demandaProvaTO.nomeTipoTrabalho.toUpperCase(),
					classe: "btn-sm",
					retornarHtml: true,
					ativo: demandaProvaTO.pendenciasInsercao == null && demandaProvaTO.numQuestoes > 0,
					onClick: () => {
						revisaoProvaVH.encerrarRevisao(demandaProvaTO.idProvaResponsavel, demandaProvaTO.codProva, demandaProvaTO.nomeTipoTrabalho);
					}
				}));

				botoes.push("</div>");
			}

			this.addBox({ titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_032", demandaProvaTO.nomeTipoTrabalho.toUpperCase()), id: idBox, css: "margin-top: -10px", deixarAberto: true });

			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_RevisaoProvaVH_033"), 
				valor: demandaProvaTO.fimTrabalho ? this.getMsg("FP_FRONT_RevisaoProvaVH_034", UtilData.toDDMMYYYYHHMM(demandaProvaTO.inicioTrabalho), UtilData.toDDMMYYYYHHMM(demandaProvaTO.fimTrabalho)) :  this.getMsg("FP_FRONT_RevisaoProvaVH_035", UtilData.toDDMMYYYYHHMM(demandaProvaTO.inicioTrabalho))
			});

			if (demandaProvaTO.isDelagadoPorOutraPessoa) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_RevisaoProvaVH_036"), valor: demandaProvaTO.nomeUsuarioDelegou });
			}

			if (demandaProvaTO.numQuestoesSolicitadas) {
				let numQuestoesAtendidas = 0;

				if (demandaProvaTO.tipoTrabalho == "ELABORACAO") {
					numQuestoesAtendidas = demandaProvaTO.numQuestoesElaboradas;
				} else if (demandaProvaTO.tipoTrabalho == "SELECAO") {
					numQuestoesAtendidas = demandaProvaTO.numQuestoesSelecionadas;
				} else {
					numQuestoesAtendidas = demandaProvaTO.numQuestoesRevisadas;
				}

				this.addCampoExibicao({
					label: this.getMsg("FP_FRONT_RevisaoProvaVH_037", numQuestoesAtendidas, demandaProvaTO.numQuestoesSolicitadas), 
					valor: UtilProgressBar.basic({ 
						evolucao: numQuestoesAtendidas, 
						total: demandaProvaTO.numQuestoesSolicitadas 
					})
				});
			} else {

				let h = [];

				if (demandaProvaTO.numQuestoesElaboradas) {
					h.push(this.getMsg("FP_FRONT_RevisaoProvaVH_038", demandaProvaTO.numQuestoesElaboradas));
				}

				if (demandaProvaTO.numQuestoesSelecionadas) {
					h.push(this.getMsg("FP_FRONT_RevisaoProvaVH_039", demandaProvaTO.numQuestoesSelecionadas));
				}

				if (demandaProvaTO.numQuestoesRevisadas) {
					h.push(this.getMsg("FP_FRONT_RevisaoProvaVH_040", demandaProvaTO.numQuestoesRevisadas));
				}

				if (h.length > 0) {
					this.addCampoExibicao({ label: this.getMsg("FP_FRONT_RevisaoProvaVH_041"), valor: h.join("<br>")});
				}
			}

			if (demandaProvaTO.dataLiberacao) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_RevisaoProvaVH_042"), valor: UtilData.toDDMMYYYYHHMM(demandaProvaTO.dataLiberacao)});

			} else if (demandaProvaTO.numQuestoesSolicitadas != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_RevisaoProvaVH_043"), valor: demandaProvaTO.pendenciasInsercao || demandaProvaTO.pendenciasRevisao || this.getMsg("FP_FRONT_RevisaoProvaVH_045")});
			}

			this.addEspacamentoHorizontal();

			if (this.hasValue(demandaProvaTO.orientacoes)) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_RevisaoProvaVH_044"), valor: demandaProvaTO.orientacoes, classe: "col-md-9" });
			}

			this.fecharFormulario();
			this.append(botoes.join(""));
			this.fecharBox();

			if (idDivTemp) {
				this.exibir();
				$(elementoBox).replaceWith($("#" + idDivTemp).find("> div"));
				this.setIdTarget(null);
			}
		}
	}

	atualizarGabarito() {
		let contadorQuestoesImpressas = 0;
		$("[gabaritos] tbody").empty();
		$("[questao] [numerador][gabarito]").each(function() {
			if (contadorQuestoesImpressas % 10 == 0) {
				$("[gabaritos] tbody").append("<tr></tr>");
			}
			$("[gabaritos] tr:last").append("<td>" + $(this).attr("numerador") + "</td><td>" + $(this).attr("gabarito") + "</td>");
			contadorQuestoesImpressas++;
		});
	}

	atualizarGabaritoSecao(codSecao) {
		let contadorQuestoesImpressas = 0;
		$(`[gabarito-secao="${codSecao}"] tbody`).empty();
		$(`[cod-secao="${codSecao}"] [questao] [numerador][gabarito]`).each(function() {
			if (contadorQuestoesImpressas % 10 == 0) {
				$(`[gabarito-secao="${codSecao}"] tbody`).append("<tr></tr>");
			}
			let numerador = $(this).attr("numerador");
			let gabarito = $(this).attr("gabarito");
			$(`[gabarito-secao="${codSecao}"] tr:last`).append(`
				<td num-questao="${numerador}">${numerador}</td>
				<td resposta-questao="${numerador}">${gabarito}</td>
			`);
			contadorQuestoesImpressas++;
		});
	}

	async onAtualizarQuestao(questao) {
		let idSecaoQuestao = Number($(questao).attr("id-secao-questao"));
		const listaExibicaoQuestaoNaRevisaoTO = await this.call("RevisaoProvaFCD/criarExibicaoQuestaoNaRevisaoTO", null, true, [idSecaoQuestao]);

		let exibicaoQuestaoNaRevisaoTO = listaExibicaoQuestaoNaRevisaoTO[0];
		if (!exibicaoQuestaoNaRevisaoTO) return;

		exibicaoQuestaoVH.atualizarExibicaoQuestao(questao, exibicaoQuestaoNaRevisaoTO.exibicaoQuestaoTO, this.getCfgsExibicao(exibicaoQuestaoNaRevisaoTO));

		this.onPosAtualizarQuestao(questao, exibicaoQuestaoNaRevisaoTO);
		this.selecionarQuestao(questao, false);
		this.atualizarGabarito();
	}

	onPosAtualizarQuestao(questao, exibicaoQuestaoNaRevisaoTO) {
		$(questao).css("overflow", "visible");
		this.tornarExibicoesQuestoesEditaveis(questao);
	}

	dropdownDificuldades = null;

	getCfgsExibicao(exibicaoQuestaoNaRevisaoTO) {

		if (this.exibicaoRevisaoDeProvasTO.collectionExibicaoProvaTO.length == 1 && this.dropdownDificuldades == null) {
			this.dropdownDificuldades = this.addDropdownSelecao({
				collection: this.exibicaoRevisaoDeProvasTO.collectionExibicaoProvaTO[0].listaDificuldadeTO,
				propValor: "grau",
				propNome: "nome",
				propDescricao: "descricaoPontuacaoPadrao",
				html: "seletor-dificuldades",
				dica: "Grau de dificuldade",
				retornarHtml: true
			});			
		}

		let cfgs = {
			ferramentas: [],
			props: {}
		};

		if (this.dropdownDificuldades && exibicaoQuestaoNaRevisaoTO.exibicaoQuestaoTO.podeAlterarQuestao) cfgs.ferramentas.push(this.dropdownDificuldades);

		cfgs.ferramentas.push(cadastroQuestaoVH.getBotaoClassificacao());

		if (this.getIsAmbienteImportacaoBancoQuestoes()) {
			cfgs.ferramentas.push(`<a classificacao-automatica class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_046")}'><i class='fa fa-magic'></i></a>`);
		}

		if (!exibicaoQuestaoNaRevisaoTO.exibicaoQuestaoTO.podeAlterarQuestao || exibicaoQuestaoNaRevisaoTO.exibicaoQuestaoTO.isConteudoBloqueado) {
			cfgs.ferramentas.push(`<a copiar class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_047")}'><i class='fa fa-clone' ></i></a>`);
		}

		cfgs.ferramentas.push(`<a subir class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_048")}'><i class='fa fa-chevron-up' ></i></a>`);
		cfgs.ferramentas.push(`<a descer class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_049")}'><i class='fa fa-chevron-down' ></i></a>`);
		cfgs.ferramentas.push(`<a auditoria class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_050")}'><i class='fa fa-eye' ></i></a>`);
		cfgs.ferramentas.push(`<a desassociar class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_051")}'><i class='fa fa-times' ></i></a>`);
		cfgs.ferramentas.push(`<a versoes class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_052")}'><i class='fa fa-history'></i></a>`);

		if (this.isAdministrador() || UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_ANULACAO)) {
			cfgs.ferramentas.push(`<a anular class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_053")}'><i class='fa fa-ban'></i></a>`);
		}

		cfgs.ferramentas.push(this.addCampoTexto({ 
			id: "tempoParaResposta", 
			prefixo: "<i class='fa fa-clock-o fa-lg'></i>", 
			classe: "col-md-12", 
			onChange: async (event) => await revisaoProvaVH.editarTempoParaResposta(event.target), 
			css: "margin-top: 15px", 
			dica: this.getMsg("FP_FRONT_RevisaoProvaVH_054"), 
			dicaComoValorInicial: this.getMsg("FP_FRONT_RevisaoProvaVH_055"), 
			valor: exibicaoQuestaoNaRevisaoTO.exibicaoQuestaoTO.tempoParaResposta, 
			tipo: "TEMPO", 
			retornarHtml: true, 
			ignorarFormulario: true 
		}));


		if (exibicaoQuestaoNaRevisaoTO.grauDificuldade) {
			cfgs.props["grau-dificuldade"] = exibicaoQuestaoNaRevisaoTO.grauDificuldade;
		}
		cfgs.props["id-secao-questao"] = exibicaoQuestaoNaRevisaoTO.idSecaoQuestao;

		// revisões

		let h = ["<ul revisoes class='list-group' style='margin-top: 5px; float: left; width: 100%'>"];

		h.push(`<li class='list-group-item' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_056")}'><strong><small>#${exibicaoQuestaoNaRevisaoTO.codQuestao}</small></strong>`);
		if (exibicaoQuestaoNaRevisaoTO.codQuestaoOriginal) {
			h.push(`<br>${this.getMsg("FP_FRONT_RevisaoProvaVH_057")} <strong><small>#${exibicaoQuestaoNaRevisaoTO.codQuestaoOriginal}</small></strong>`);
		}
		h.push("</li>");

		if (exibicaoQuestaoNaRevisaoTO.autoria) h.push(`<li class='list-group-item'><strong>${this.getMsg("FP_FRONT_RevisaoProvaVH_058")}</strong><br>${exibicaoQuestaoNaRevisaoTO.autoria}</li>`);
		if (exibicaoQuestaoNaRevisaoTO.nomeSelecionador) {
			h.push(`<li class='list-group-item'><strong>${this.getMsg("FP_FRONT_RevisaoProvaVH_059")}</strong><br>${exibicaoQuestaoNaRevisaoTO.nomeSelecionador}<br><small>`);
			h.push(UtilData.toDDMMYYYYHHMM(exibicaoQuestaoNaRevisaoTO.dataSelecao) + "</small></li>");
		}

		if (exibicaoQuestaoNaRevisaoTO.listExibicaoRevisaoSecaoQuestaoTO && exibicaoQuestaoNaRevisaoTO.listExibicaoRevisaoSecaoQuestaoTO.length) {
			h.push(`<li class='list-group-item'><strong>${this.getMsg("FP_FRONT_RevisaoProvaVH_060")}</strong>`);

			for (const exibicaoRevisaoSecaoQuestaoTO of exibicaoQuestaoNaRevisaoTO.listExibicaoRevisaoSecaoQuestaoTO) {
				h.push("<p ");
				h.push(exibicaoRevisaoSecaoQuestaoTO.id ? " id-secao-questao-revisao='" + exibicaoRevisaoSecaoQuestaoTO.id + "' " : "");
				h.push(">" + exibicaoRevisaoSecaoQuestaoTO.nomeRevisor + "<br>");
				h.push(exibicaoRevisaoSecaoQuestaoTO.isAprovacao == true ? `<span class='text-success' style='text-transform: uppercase'>${exibicaoRevisaoSecaoQuestaoTO.tipoTrabalho || ""} ${this.getMsg("FP_FRONT_RevisaoProvaVH_061")}</span><br>` : "");
				h.push(exibicaoRevisaoSecaoQuestaoTO.isAprovacao == false ? `<span class='text-danger' style='text-transform: uppercase'>${exibicaoRevisaoSecaoQuestaoTO.tipoTrabalho || ""} ${this.getMsg("FP_FRONT_RevisaoProvaVH_062")}</span><br>` : "");
				h.push(exibicaoRevisaoSecaoQuestaoTO.comentarios ? "<em>" + exibicaoRevisaoSecaoQuestaoTO.comentarios + "</em><br>" : "");
				h.push("<small>" + UtilData.toDDMMYYYYHHMM(exibicaoRevisaoSecaoQuestaoTO.data) + "</small><br>");
				h.push(exibicaoRevisaoSecaoQuestaoTO.id ? " <a class='btn btn-link'><i class='fa fa-trash'></i></a>" : "");
				h.push("</p>");
			}

			h.push("</li>");
		}

		if (exibicaoQuestaoNaRevisaoTO.permitirRevisao) {
			if (exibicaoQuestaoNaRevisaoTO.idProvaResponsavelPendente != null) {
				h.push(`<li suas-revisoes class='list-group-item' style='background-color: white'><strong>${this.getMsg("FP_FRONT_RevisaoProvaVH_063")}</strong><br><br>`);
				h.push(`<textarea comentarios class='form-control textarea-resize-vertical' rows='2' placeholder='${this.getMsg("FP_FRONT_RevisaoProvaVH_064")}'></textarea><br>`);
				h.push("<div class='btn-group-vertical' role='group' style='width: 100%'>");
				h.push(`<a opcao-revisao class='btn btn-default' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_067")}' value='true'><i class='fa fa-check text-success'></i> ${this.getMsg("FP_FRONT_RevisaoProvaVH_065")}</a>`);
				h.push(`<a opcao-revisao class='btn btn-default' title='${this.getMsg("FP_FRONT_RevisaoProvaVH_068")}' value='false'><i class='fa fa-ban text-danger'></i> ${this.getMsg("FP_FRONT_RevisaoProvaVH_066")}</a> `);
				h.push("</div><br>");
				h.push("</li>");
			} else {
				h.push(`<li class='list-group-item' style='background-color: white'>${this.getMsg("FP_FRONT_RevisaoProvaVH_069")}<br><br>`);
				h.push("<div class='btn-group btn-group-justified' role='group'>");
				h.push(`<a botao-incluir-na-equipe class='btn btn-default'>${this.getMsg("FP_FRONT_RevisaoProvaVH_070")}</a>`);
				h.push("</div><br>");
				h.push("</li>");
			}
		}

		h.push("</ul>");

		cfgs.ferramentas.push(h.join(""));

		return cfgs;
	}

	tornarExibicoesQuestoesEditaveis(questao) {
		let seletor = questao || "[questao]";

		/* ALTERAÇÕES E EVENTOS */

		$(seletor).find("[ferramentas]").removeClass("btn-group-vertical");
		$(seletor).find("[exibicao]").removeClass("col-md-11").addClass("col-md-10");
		$(seletor).find("[div-ferramentas]").removeClass("col-md-1").addClass("col-md-2").show();

		// adicionar o checkbox na questão

		$(seletor).find("[enunciado]").prepend("<input type='checkbox' selecao>").find("[selecao]").off("change").on("change", ({ target }) => {
			this.selecionarQuestao(target);
		});

		// mostrar disciplinas e permitir exclusão

		$(seletor).find("[disciplinas]").show().find("a").off("click").on("click", async ({target}) => {
			const $btn = $(target).closest("a");
			await this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_RevisaoProvaVH_151", $btn.text()),
				botoes: [
					{
						label: this.getMsg("FP_FRONT_RevisaoProvaVH_146")
					},
					{
					label: this.getMsg("FP_FRONT_RevisaoProvaVH_152"),
					classe: "btn-danger",
					onClick: async () => {
						await this.removerClassificacao($btn);
					}
				}]
			})
		}).tooltip({ container: "body", title: "Clique para removê-la" }).append(" <i class='fa fa-trash'></i>");

		// corrigindo o tabindex

		$(seletor).filter("[pode-alterar]").find("[conteudo], [texto]").css("cursor", "pointer")
			.attr("id", function() { return revisaoProvaVH.contadorTabIndex++; })
			.attr("tabindex", function() { return revisaoProvaVH.contadorTabIndex++; });

		// editar enunciado
		this.permitirEditarEnunciadoQuestao(seletor);

		// editar item
		this.permitirEditarItemQuestao(seletor);

		// editar texto
		this.permitirEditarTextoQuestao(seletor);

		// tira link Encontrou erros? se a questão é da empresa

		$(seletor).filter("[pode-alterar]").find("[links] .encontrou-erros").hide();

		// botão de classificação

		$(seletor).find("a[classificar]").on("click", ({ target }) => {
			let $a = $(target);

			if (!$a.is("a")) $a = $a.closest("a");

			const $questao = $a.closest("[questao]");
			const codQuestao = $questao.attr("cod-questao");
			const categoria = $a.find("[id-categoria]").attr("id-categoria");
			let codQuestoes = [codQuestao];
			let codsQuestoesSelecionadas = [];
			let considerarTodasSelecionadas = false;

			$("[questoes-selecionadas] a").each(function() {
				let codQuestaoSelecionada = $(this).attr("cod-questao");
				considerarTodasSelecionadas = considerarTodasSelecionadas || (codQuestaoSelecionada == codQuestao);
				codsQuestoesSelecionadas.push(new Number(codQuestaoSelecionada));
			});

			if (considerarTodasSelecionadas) {
				codQuestoes = codsQuestoesSelecionadas
			}

			let textoBusca = $("[seletor-secao]:checked").parent().find("[nome-secao]").text();

			cadastroQuestaoVH.mostrarOpcoesDeDisciplinas(codQuestoes, textoBusca, categoria, (codDisciplina, codsQuestoes, exibicaoDisciplinaTO) => {
				revisaoProvaVH.gravarDisciplina(codDisciplina, codsQuestoes);
			}).then(() => {}, () => {});
		});

		// botão de cópia

		$(seletor).find("a[copiar]").on("click", ({ target }) => {
			let codQuestao = $(target).closest("[questao]").attr("cod-questao");
			let idSecaoQuestao = $(target).closest("[questao]").attr("id-secao-questao");
			associacaoQuestaoVH.copiarQuestao(idSecaoQuestao, codQuestao, () => {
				this.atualizarRevisao();
			});
		});

		// botão de grau de dificuldade

		$(seletor).filter("[pode-alterar]").find("[ferramentas] [seletor-dificuldades] ul li a").on("click", async ({ target }) => {
			let codQuestao = $(target).closest("[questao]").attr("cod-questao");
			let grauDificuldade = $(target).attr("botao-selecao-valor");
			let salvamentoDificuldadeRevisaoTO = {
				codQuestao: codQuestao,
				grauDificuldade: grauDificuldade
			}
			await this.call("RevisaoProvaFCD/salvarDificuldade", salvamentoDificuldadeRevisaoTO);
		});

		// botões de reposicionamento ou remoção

		$(seletor).find("[ferramentas]").find("a[subir]").on("click", ({ target }) => {
			this.reposicionarQuestao(target, true);
		});
		$(seletor).find("[ferramentas]").find("a[descer]").on("click", ({ target }) => {
			this.reposicionarQuestao(target, false);
		});
		$(seletor).find("[ferramentas]").find("a[auditoria]").on("click", ({ target }) => {
			let codQuestao = $(target).closest("[questao]").attr("cod-questao");
			loginVH.exibirAcessosDaQuestao(codQuestao, true);
		});
		$(seletor).find("[ferramentas]").find("a[versoes]").on("click", ({ target }) => {
			let codQuestao = $(target).closest("[questao]").attr("cod-questao");
			exibicaoQuestaoVH.exibirVersoesQuestao(codQuestao);
		});
		$(seletor).find("[ferramentas]").find("a[anular]").on("click", ({ target }) => {
			let codQuestao = $(target).closest("[questao]").attr("cod-questao");
			cadastroQuestaoVH.anular(codQuestao);
		});
		$(seletor).find("[ferramentas]").find("a[desassociar]").on("click", async ({ target }) => {

			const msgDesassociarQuestao = this.getMsg("FP_FRONT_RevisaoProvaVH_075") +
				(this.isAdministrador() ? " " + this.getMsg("FP_FRONT_RevisaoProvaVH_149") : "");

			this.exibirAlerta({
				msg: msgDesassociarQuestao,
				botoes: [
					{
						label: this.getMsg("MSG_VH_004"),
						classe: "btn-primary",
						onClick: async () => {
							let codQuestao = $(target).closest("[questao]").attr("cod-questao");
							let codProva = $(target).closest("[cod-prova]").attr("cod-prova");

							let desassociacaoQuestaoTO = {
								codProva: codProva,
								codQuestao: codQuestao
							}

							await this.call("AssociacaoQuestaoFCD/desassociarQuestao", desassociacaoQuestaoTO);
							$(target).closest("[questao]").css("opacity", "0.3");
							this.mostrarMsgAjax(this.getMsg("FP_FRONT_RevisaoProvaVH_076"), 2)
						}
					},
					{label: this.getMsg("MSG_VH_005"),}
				],
			});
		});

		// alterar gabarito

		$(seletor).filter("[pode-alterar][conteudo-bloqueado='true']").find("[opcao]").off("click").on("click", ({ target }) => {
			let $questao = $(target).closest("[questao]");
			let codQuestao = $questao.attr("cod-questao");
			this.abrirNovaJanela(UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 1, codQuestao));
			$questao.attr("abriu-edicao-nova-pagina", true);
		}).removeAttr("disabled").tooltip({ container: "body", title: this.getMsg("FP_FRONT_RevisaoProvaVH_077") });

		$(seletor).filter("[pode-alterar][conteudo-bloqueado='false']").find("[opcao]").off("click").on("click", async ({ target }) => {
			let codQuestao = $(target).closest("[questao]").attr("cod-questao");
			let idItem = $(target).attr("cod-item");
			let idProva = $(target).closest("[cod-prova]").attr("cod-prova");
			let valor = $(target).val();
			let isRespostaCerta = (valor == "v" ? true : (valor == "f" ? false : null));

			await this.call("RevisaoProvaFCD/alterarRespostaCorretaDaQuestaoOuItem", idItem, isRespostaCerta, idProva);
			this.atualizarQuestao(codQuestao);
		}).removeAttr("disabled");

		// adicionar o link no numerador da questão

		$(seletor).filter("[pode-alterar]").find("[numerador]").wrap("<a class='numerador btn btn-link' editar></a>").closest("[editar]").off("click").on("click", ({ target }) => {
			let $questao = $(target).closest("[questao]");
			let codQuestao = $questao.attr("cod-questao");
			this.abrirNovaJanela(UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 1, codQuestao));
			$questao.attr("abriu-edicao-nova-pagina", true);
		});

		/* ALTERAÇÕES PARA CADA QUESTÃO */

		$(seletor).filter("[pode-alterar]").each((i, questao) => {

			// mostra a dificuldade da questão

			let seletorDificuldades = $(questao).find("[seletor-dificuldades]");

			if (seletorDificuldades.length) {
				let grauDificuldade = $(questao).attr("grau-dificuldade");
				let label = "<i class='fa fa-dashboard'></i>";

				if (this.hasValue(grauDificuldade)) {
					label = $(questao).find(`[seletor-dificuldades] ul li a[botao-selecao-valor='${grauDificuldade}']`).attr("botao-selecao-nome");
				}

				$(seletorDificuldades).find("> a").prepend(label);
			}
		});

		$(seletor).find("[div-ferramentas] [id-secao-questao-revisao] a").on("click", async ({ target }) => {
			let idSecaoQuestaoRevisao = $(target).closest("[id-secao-questao-revisao]").attr("id-secao-questao-revisao");
			let codQuestao = $(target).closest("[questao]").attr("cod-questao");
			let $revisaoProva = $(target).closest("[revisao-prova]");
			let idProvaResponsavel = $revisaoProva.attr("id-prova-responsavel");

			this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_RevisaoProvaVH_078"),
				botoes: [
					{
						label: this.getMsg("MSG_VH_004"),
						classe: "btn-primary",
						onClick: async () => {
							const demandaProvaTO = await this.call("RevisaoProvaFCD/removerSecaoQuestaoRevisao", idSecaoQuestaoRevisao, idProvaResponsavel);
							this.atualizarQuestao(codQuestao);
							this.exibirDemandaProva(demandaProvaTO);
						}
					},
					{label: this.getMsg("MSG_VH_005"),}
				],
			});
		});

		$(seletor).find("[div-ferramentas] [suas-revisoes] [opcao-revisao]").on("click", async ({ target }) => {
			
			let suasRevisoes = $(target).closest("[suas-revisoes]");
			let codQuestao = (new Number($(suasRevisoes).closest("[questao]").attr("cod-questao"))).valueOf();
			let idsQuestoesSelecionadas = this.getIdsQuestoesSelecionadas();
			let $revisaoProva = $(target).closest("[revisao-prova]");
			let idProvaResponsavel = $revisaoProva.attr("id-prova-responsavel");

			let salvamentoSecaoQuestaoRevisaoTO = {
				comentarios: $(suasRevisoes).find("[comentarios]").val(),
				codProva: $(suasRevisoes).closest("[prova]").attr("cod-prova"),
				isAprovacao: $(target).attr("value"),
				codsQuestoes: null,
				idProvaResponsavel: idProvaResponsavel
			}

			let salvarRevisao = true;

			if (idsQuestoesSelecionadas.length > 1 && idsQuestoesSelecionadas.includes(codQuestao)) {
				const labelAprovacao = salvamentoSecaoQuestaoRevisaoTO.isAprovacao != 'false' ? this.getMsg("FP_FRONT_RevisaoProvaVH_079") : this.getMsg("FP_FRONT_RevisaoProvaVH_080");

				salvarRevisao = false;
				await this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_RevisaoProvaVH_081", idsQuestoesSelecionadas.length, labelAprovacao),
					botoes: [
						{
							label: this.getMsg("MSG_VH_004"),
							classe: "btn-primary",
							onClick: () => {
								salvamentoSecaoQuestaoRevisaoTO.codsQuestoes = idsQuestoesSelecionadas;
								salvarRevisao = true;
							}
						},
						{ label: this.getMsg("MSG_VH_005") }
					],
				});
			} else {
				salvamentoSecaoQuestaoRevisaoTO.codsQuestoes = [codQuestao];
			}

			if (salvarRevisao) {
				const secaoQuestaoRevisaoSalvaTO = await this.call("RevisaoProvaFCD/salvarSecaoQuestaoRevisao", salvamentoSecaoQuestaoRevisaoTO);
				this.atualizarQuestao(salvamentoSecaoQuestaoRevisaoTO.codsQuestoes);

				if (secaoQuestaoRevisaoSalvaTO.codsQuestoesParaRemoverDaRevisao) {
					for (let i = 0; i < secaoQuestaoRevisaoSalvaTO.codsQuestoesParaRemoverDaRevisao.length; i++) {
						$revisaoProva.find("[questao][cod-questao='" + secaoQuestaoRevisaoSalvaTO.codsQuestoesParaRemoverDaRevisao[i] + "']").slideUp(function() {
							$(this).remove();
						});
					}
				}

				this.exibirDemandaProva(secaoQuestaoRevisaoSalvaTO.demandaProvaTO);
			}
		});

		$(seletor).find("[div-ferramentas] [botao-incluir-na-equipe]").on("click", async ({ target }) => {
			let $revisaoProva = $(target).closest("[revisao-prova]");
			let codProva = $revisaoProva.attr("cod-prova");

			let salvamentoControleAcessoTO = {
				idProvaControleAcesso: null,
				codUsuario: this.getCodUsuarioLogado(),
				inicioVigencia: null,
				fimVigencia: null,
				codProva: codProva,
				tipoTrabalho: null,
				numQuestoes: null,
				obs: null
			}

			await this.call("CadastroProvaResponsavelFCD/salvarControleAcessoProva", salvamentoControleAcessoTO);

			document.location.reload();
		});

		// PARA PERMITIR EDIÇÃO DE CONTEÚDO VAZIO
		$(seletor).find("[enunciado] [conteudo]:empty").html("<p>(sem conteúdo)</p>");
		$(seletor).find("[item] [conteudo]:empty").html("<p>(sem conteúdo)</p>");

		this.exibir();
	}

	permitirEditarEnunciadoQuestao(seletor) {
		$(seletor).filter("[pode-alterar][conteudo-bloqueado='true']").find("[enunciado] [conteudo]").tooltip({ container: "body", title: this.getMsg("FP_FRONT_RevisaoProvaVH_071") })

		$(seletor).filter("[pode-alterar][conteudo-bloqueado='false']").find("[enunciado] [conteudo]").off("focus").on("focus", ({ target }) => {
			if ($(target).attr("contenteditable") == "true") {
				return;
			}

			$(target).attr("contenteditable", "true");

			this.ativarEditorHtml(target, "importacao");

			$(target).off("blur").on("blur", ({ target }) => {
				setTimeout(async () => {
					const CKEDITOR = UtilBoot.getCKEditor();
					let editor = CKEDITOR.instances[target.id];

					if (!editor?.checkDirty()) {
						return;
					}
					// espera meio segundo para sallet (para possibilitar Ctrl-C Ctrl-V, que tira o foco momentaneamente)
					if ($(target).is(":focus")) {
						return;
					}

					let salvamentoRevisaoQuestaoTO = {
						codQuestao: $(target).closest("[questao]").attr("cod-questao"),
						enunciado: editor.getData()
					}

					await this.call("RevisaoProvaFCD/salvarRevisaoQuestao", salvamentoRevisaoQuestaoTO);

					editor.destroy();
					$(target).attr("contenteditable", "false");
					this.atualizarQuestao(target);
				}, 500);

			});
		});
	}

	permitirEditarItemQuestao(seletor) {
		$(seletor).filter("[pode-alterar][conteudo-bloqueado='true']").find("[item] [conteudo]").tooltip({ container: "body", title: this.getMsg("FP_FRONT_RevisaoProvaVH_072") });

		$(seletor).filter("[pode-alterar][conteudo-bloqueado='false']").find("[item] [conteudo]").off("focus").on("focus", ({ target }) => {
			if ($(target).attr("contenteditable") == "true") return;

			$(target).attr("contenteditable", "true");

			this.ativarEditorHtml(target, "importacao");

			$(target).off("blur").on("blur", ({ target }) => {
				setTimeout(async () => {
					const CKEDITOR = UtilBoot.getCKEditor();
					let editor = CKEDITOR.instances[target.id];
					if (!editor?.checkDirty()) {
						return;
					}
					// espera meio segundo para sallet (para possibilitar Ctrl-C Ctrl-V, que tira o foco momentaneamente)
					let salvamentoRevisaoItemTO = {
						codItem: $(target).closest("[item]").attr("cod-item"),
						texto: editor.getData()
					}

					await this.call("RevisaoProvaFCD/salvarItem", salvamentoRevisaoItemTO);

					editor.destroy();
					$(target).attr("contenteditable", "false");
					revisaoProvaVH.atualizarQuestao(target);
				}, 500);
			});
		});

		$(seletor).find("[texto]").each(({ target }) => {
			if ($(target).is("[ja-tem-refs]")) return;

			let codTexto = $(target).attr("cod-texto");
			let numeradores = $(`[texto][cod-texto=${codTexto}]`).closest("[questao]").find("[numerador]").map((i, n) => $(n).attr("numerador")).get().join(", ");
			$("[texto][cod-texto=" + codTexto + "]").attr("ja-tem-refs", "").find("[refs]").addClass("questao_texto_num_questoes_associadas").html(this.getMsg("FP_FRONT_RevisaoProvaVH_073", numeradores));
		})
	}

	permitirEditarTextoQuestao(seletor, deveAtualizarPaginaAposSalvar?: boolean) {
		$(seletor).filter("[pode-alterar][conteudo-bloqueado='true']").find("[texto] [conteudo]").closest("[texto]").find("[conteudo], [titulo], [rodape], [refs]").tooltip({ container: "body", title: this.getMsg("FP_FRONT_RevisaoProvaVH_074") });

		$(seletor).filter("[pode-alterar][conteudo-bloqueado='false']").find("[texto] [conteudo]").closest("[texto]").find("[conteudo], [titulo], [rodape], [refs]").css("cursor", "pointer").off("click").on("click", async ({ target }) => {
			await this.editarTexto(target, deveAtualizarPaginaAposSalvar);
		});
	}

	selecionarQuestoesDaSecao(checkboxSecao) {

		let novoEstadoCheckbox = $(checkboxSecao).is(":checked");
		$(checkboxSecao).closest("[cod-secao]").find("[enunciado] [selecao]").each(function() {
			revisaoProvaVH.selecionarQuestao(this, novoEstadoCheckbox);
		});
	}

	limparQuestoesSelecionadas() {
		this.desselecionarTodas();
		$("[secao] [seletor-secao]").prop("checked", false);
	}

	async encerrarRevisao(idProvaControleAcessoPendente, codProva, tipoTrabalho) {
		tipoTrabalho = tipoTrabalho.toLowerCase();

		const resultadoEncerramentoRevisaoTO = await this.call("RevisaoProvaFCD/encerrarRevisao", idProvaControleAcessoPendente, codProva);
	
		revisaoProvaVH.desselecionarTodas();

		for (const codQuestao of resultadoEncerramentoRevisaoTO.codsQuestoesRevisaoPendente) {
			revisaoProvaVH.selecionarQuestao($("[questao][cod-questao='" + codQuestao + "'] [selecao]"), true);
		}

		if (resultadoEncerramentoRevisaoTO.codsQuestoesRevisaoPendente.length) {
			await this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_RevisaoProvaVH_082"),
				botoes: [{ label: this.getMsg("MSG_VH_003") }],
			});
			UtilWindow.scrollTo($("[questao][cod-questao='" + resultadoEncerramentoRevisaoTO.codsQuestoesRevisaoPendente[0] + "']"));
		} else if (resultadoEncerramentoRevisaoTO.revisaoFoiEncerrada) {
			UtilHash.carregarTelaInicial();
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RevisaoProvaVH_083", tipoTrabalho) });

		} else if (resultadoEncerramentoRevisaoTO.nomeUsuariosRevisaoPendente.length) {
			await this.exibirAlerta({ msg: `${this.getMsg("FP_FRONT_RevisaoProvaVH_084")}:<br><br><strong>${resultadoEncerramentoRevisaoTO.nomeUsuariosRevisaoPendente.join("<br>")}</strong>` });

		} else if (resultadoEncerramentoRevisaoTO.iraPublicar) {
			this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_RevisaoProvaVH_085", tipoTrabalho),
				botoes: [
					{
						label: this.getMsg("MSG_VH_004"),
						classe: "btn-primary",
						onClick: async () => {
							await this.call("RevisaoProvaFCD/publicar", idProvaControleAcessoPendente, codProva);
							UtilHash.carregarTelaInicial();
						}
					},
					{label: this.getMsg("MSG_VH_005"),}
				],
			});
		}
	}

	async atualizarNumQuestoesClassificadasDoRevisorLogado() {
		const numQuestoesClassificadas = await this.call("RevisaoProvaFCD/recuperarNumClassificacoesRevisorLogado");
		this.setHtml("numClassificacoesRevisor", numQuestoesClassificadas)
	}

	async exibirOuEsconderNumQuestoesClassificadasDoRevisorLogado() {

		if (this.getElementoHtml("numClassificacoesRevisor") == null) {
			this.append("menuFlutuante", `<div id='numClassificacoesRevisor_titulo' class='painel_titulo'>${this.getMsg("FP_FRONT_RevisaoProvaVH_087")}</div>`)
			this.append("menuFlutuante", "<div id='numClassificacoesRevisor' class='painel_rodape'>...</div>")
			this.exibir();

		} else if (this.isShown("numClassificacoesRevisor")) {
			await this.hide("numClassificacoesRevisor", "numClassificacoesRevisor_titulo")

		} else {
			await this.show("numClassificacoesRevisor", "numClassificacoesRevisor_titulo")
		}

		this.atualizarNumQuestoesClassificadasDoRevisorLogado()
	}

	async exibirEditorGabarito(botao, listaExibicaoSecaoNaRevisaoTO, codSecao = null) {
		$("#resultadoAlteracao").remove();
		$(botao).before("<textarea id='novoGabarito' class='gabarito' style='display: block'></textarea>");
		$(botao).after(`
			<button class='btn btn-default btn-sm processar'>${this.getMsg("FP_FRONT_RevisaoProvaVH_100")}</button>
			<button class='btn btn-default btn-sm btn-danger atualizar' style='display:none; margin-left: 20px'>
				${codSecao === null ? this.getMsg("FP_FRONT_RevisaoProvaVH_099"): this.getMsg("FP_FRONT_RevisaoProvaVH_144")}
			</button>
		`);

		$(botao).hide();

		$("#novoGabarito").each(function() {
			let textarea = this;
			(textarea.oninput = function() {
				textarea.style.height = 0;
				textarea.style.height = textarea.scrollHeight + 'px';
			})();
		})

		let secoes = [];
		let btnProcessar = $("button.processar");

		if(listaExibicaoSecaoNaRevisaoTO !== null){
			listaExibicaoSecaoNaRevisaoTO.forEach((exibicaoSecaoNaRevisaoTO, i) => {
				secoes.push({ id: exibicaoSecaoNaRevisaoTO.codSecao, text: exibicaoSecaoNaRevisaoTO.nomeSecao })
			});

			btnProcessar.after(
				await this.addSelect({
					collection: secoes,
					id: "codSecaoAtualizacaoGabarito",
					label: this.getMsg("FP_FRONT_RevisaoProvaVH_101"),
					dica: this.getMsg("FP_FRONT_RevisaoProvaVH_102"),
					classe: "col-md-4",
					retornarHtml: true, css: "display: block"
				})
			);
		}

		btnProcessar.click(() => {
			if($("#gabaritoProcessado").length == 0) {
				$("#novoGabarito").after("<div id='gabaritoProcessado' class='gabarito' contenteditable='true'></div>");
			}

			$("#gabaritoProcessado").html(revisaoProvaVH.processarGabaritoEGerarTabelaHtml($("#novoGabarito").val()));

			$("button.atualizar").show();
		});

		$("button.atualizar").on("click", async ({target}) => {

			if(this.questoesConteudoBloqueadoGabarito.length > 0){

				let numQuestoes = this.questoesConteudoBloqueadoGabarito.map(questao => {
					return questao.numQuestao;
				});

				this.exibirAlerta({
					id: "modal_alerta_questoes_bloqueadas_gabarito",
					msg: this.getMsg("FP_FRONT_RevisaoProvaVH_147", numQuestoes.join(", ")),
					botoes: [{
						label: this.getMsg("FP_FRONT_RevisaoProvaVH_146"),
						classe: "btn-info",
						onClick: async () => {
							this.fecharPopup("modal_alerta_questoes_bloqueadas_gabarito");
						}
					}, {
						label: this.getMsg("FP_FRONT_RevisaoProvaVH_145"),
						classe: "btn-warning",
						onClick: async () => {
							let codsQuestoesAtualizacao = this.questoesConteudoBloqueadoGabarito.map(questao => {
								return questao.codQuestao;
							});
							await this.processarGabaritosAlterados(target, botao, codSecao, codsQuestoesAtualizacao);
						}
					}]
				});
			} else {
				await this.processarGabaritosAlterados(target, botao, codSecao);
			}
		});

		this.exibir();
	}

	async processarGabaritosAlterados(target, botao, codSecao, codsQuestoesAtualizacao = null){
		let e = $(target);

		while (e.attr("cod-prova") == null && e.length > 0) e = e.parent();

		let atualizacaoGabaritoTO = {
			codProva: e.attr("cod-prova"),
			codSecao: codSecao || this.getValor("codSecaoAtualizacaoGabarito"),
			codsQuestoesAtualizacao: codsQuestoesAtualizacao,
			gabaritos: {}
		}

		$("#gabaritoProcessado table.gabarito td.num-questao").each((i, value) => {
			atualizacaoGabaritoTO.gabaritos[$(value).text()] = $(value).next().text();
		});

		const gabaritosAtualizadosTO = await this.call("RevisaoProvaFCD/atualizarGabarito", atualizacaoGabaritoTO);

		let html = ["<table class='gabarito'><tbody><tr>"];
		let gabaritoSecaoTable = null;
		if(codSecao != null){
			gabaritoSecaoTable = $(`[gabarito-secao="${codSecao}"] tbody`);
		}

		let questoesAtualizadas = [];

		for (let i = 0; i < gabaritosAtualizadosTO.numQuestoesAtualizadas.length; i++) {
			let numQuestao = gabaritosAtualizadosTO.numQuestoesAtualizadas[i];
			let resposta = gabaritosAtualizadosTO.novosGabaritos[i];
			if (i % 10 == 0) {
				html.push("</tr><tr>");
			}

			html.push(`
					<td class='num-questao'>${numQuestao}</td>
					<td class='resposta'>${resposta}</td>
				`);

			if(gabaritoSecaoTable != null){
				let respostaQuestaoGabarito = gabaritoSecaoTable.find(`[resposta-questao=${numQuestao}]`);
				if(respostaQuestaoGabarito){
					respostaQuestaoGabarito.html(resposta);
				}
			}

			const questao = this.buscarQuestaoPorNumQuestao(numQuestao);

			if(questao !== null){
				questoesAtualizadas.push(Number(questao.attr("cod-questao")));
			}
		}

		html.push("</tr></tbody></table>");

		$("button.atualizar").after(`
			<div class='jumbotron' id='resultadoAlteracao' style="margin-top: 20px;">
				<label>${this.getMsg("FP_FRONT_RevisaoProvaVH_103")}</label>
				${gabaritosAtualizadosTO.numGabaritosProcessados} 
				<label>${this.getMsg("FP_FRONT_RevisaoProvaVH_104")}</label>
				${gabaritosAtualizadosTO.numQuestoesAtualizadas.length}
				<label>${this.getMsg("FP_FRONT_RevisaoProvaVH_105")}</label>
				${html.join("")}
			</div>
		`);

		$("#novoGabarito").remove();
		$("#gabaritoProcessado").remove();
		$("button.processar").remove();
		$("button.atualizar").remove();
		$(botao).show();

		if(!this.isEmpty(questoesAtualizadas)) this.atualizarQuestao(questoesAtualizadas);
	}

	processarGabaritoEGerarTabelaHtml(textoDoGabarito) {
		let numeros = [];
		let respostas = [];
		this.questoesConteudoBloqueadoGabarito = [];

		let tratarTexto = function(txt) {
			txt = String(txt);
			return " " + txt.replace(/&nbsp;/g, "");
		}

		let txt = tratarTexto(textoDoGabarito);
		let pattern = /\D(\d+)\D/g;
		let nums = pattern.exec(txt);

		while (nums != null) {
			let numero = Number(nums[1]);

			if (numero != 0) {
				numeros.push(numero);
			}

			pattern.lastIndex--;
			nums = pattern.exec(txt);
		}

		pattern = /([a-zA-Z\*])/g;
		nums = pattern.exec(txt);

		while (nums != null) {
			respostas.push(nums[1]);
			nums = pattern.exec(txt);
		}

		let html = ["<table class='gabarito'><tbody><tr>"];
		let length = numeros.length;

		if (respostas.length > length) {
			length = respostas.length;
		}

		for (let i = 0; i < length; i++) {
			const numQuestao = numeros[i];
			if (i % 10 == 0) {
				html.push("</tr><tr>");
			}
			html.push("<td class='num-questao'>" + numQuestao + "</td><td class='resposta'>" + respostas[i] + "</td>");

			const questao = this.buscarQuestaoPorNumQuestao(numQuestao);

			if(questao !== null){
				const isConteudoBloqueado = questao.attr("conteudo-bloqueado");

				if(isConteudoBloqueado && isConteudoBloqueado === "true"){
					const codQuestao = Number(questao.attr("cod-questao"));
					this.questoesConteudoBloqueadoGabarito.push({
						codQuestao: codQuestao,
						numQuestao: numQuestao
					});
				}
			}
		}

		html.push("</tr></tbody></table>");

		return html.join("");
	}

	buscarQuestaoPorNumQuestao(numerador){
		const questao = $(`[numerador=${numerador}]`).closest("[questao]");

		if(questao && questao.length && questao.get(0)){
			return questao;
		} else {
			return null;
		}
	}

	abrirPDFAoLado(href) {
		$("#container").css("float", "left");
		$("#divBody").css("max-width", "800px").css("margin", "0px");
		$("#containerHeader").css("z-index", "0").removeClass("navbar-fixed-top");
		$("#containerRodape").remove();

		$("#container").animate({ "min-width": "100%" }, 400, function() {
			if ($("#container > object").length > 0) {
				$("#container > object").remove();
			}

			$("#corpo").after("<object data='" + href + "'></object>");
			let width = $(window).width() - 845;
			let height = $(window).height();
			$('#barraRevisao').css('top', "0px");
			$("#container > object").css("width", width + "px").css("height", height + "px");
			$("#container > object").css("position", "fixed").css("right", "10px").css("top", "10px");
			try {
				$("#container > object").resizable();
			} catch (ignored) { }
		});
	}

	contadorTabIndex = 1;

	async salvarRespostaCertaValorExato(codQuestao) {

		let salvamentoGabaritoValorExatoTO = {
			codQuestao: codQuestao,
			respostaCerta: this.getValor("respostaValorExato" + codQuestao)
		}

		await this.call("RevisaoProvaFCD/salvarRespostaCertaValorExato", salvamentoGabaritoValorExatoTO);

		this.mostrarMsgAjax(this.getMsg("FP_FRONT_RevisaoProvaVH_106"), 4)
	}

	async reposicionarQuestao(botao, isSubirQuestao) {

		let q1 = $(botao).closest("[questao]");
		let codQuestao = $(q1).attr("cod-questao");

		let mudancaDePosicaoDeQuestaoTO = {
			codProva: $(botao).closest("[cod-prova]").attr("cod-prova"),
			codQuestao: codQuestao,
			isSubirQuestao: isSubirQuestao
		}

		const codQuestaoOutra = await this.call("AssociacaoQuestaoFCD/mudarPosicaoDaQuestao", mudancaDePosicaoDeQuestaoTO);
	
		if (codQuestaoOutra) {
			let q2 = $("[questao][cod-questao='" + codQuestaoOutra + "']");

			setTimeout(() => {
				$(q1).trigger("atualizar");
				$(q2).trigger("atualizar");
			}, 500);

			$(q1).after("<div q1></div>");
			$(q2).after("<div q2></div>");
			$("div[q2]").replaceWith(q1);
			$("div[q1]").replaceWith(q2);

			let n1 = $(q1).find("[numerador]");
			let n2 = $(q2).find("[numerador]");
			let n1Text = $(n1).attr("numerador");
			let n2Text = $(n2).attr("numerador");
			n1.attr("numerador", n2Text).find("a").text(n2Text);
			n2.attr("numerador", n1Text).find("a").text(n1Text);
			UtilWindow.scrollTo(q1);
		}
	}

	atualizarQuestao(param) {
		if (param.push) {
			for (const p of param) {
				$("[questao][cod-questao='" + p + "']").trigger("atualizar");
			}
		} else if (!isNaN(param)) {
			$("[questao][cod-questao='" + param + "']").trigger("atualizar");
		} else if ($(param).length > 0) {
			$(param).trigger("atualizar");
		}

		revisaoProvaVH.atualizarNumQuestoesClassificadasDoRevisorLogado();
	}

	async verificarImpedimentoQuestaoAntesMarcarOK(botao) {
		let codQuestao = $(botao).closest("[questao]").attr("cod-questao");

		const collectionPendencias = await this.call("RevisaoProvaFCD/recuperarPendenciasQuestao", codQuestao);

		if (collectionPendencias.length > 0) {
			this.exibirAlerta({
				msg: `
					${this.getMsg("FP_FRONT_RevisaoProvaVH_107")}: 
					\n\n
					- ${collectionPendencias.join("\n\n - ")}
					\n\n 
					${this.getMsg("FP_FRONT_RevisaoProvaVH_108")}
				`,
				botoes: [
					{
						label: this.getMsg("MSG_VH_004"),
						classe: "btn-primary",
						onClick: async () => {
							await this.call("RevisaoProvaFCD/marcarRevisaoQuestaoOK", codQuestao);
							this.atualizarQuestao(codQuestao);
						}
					},
					{label: this.getMsg("MSG_VH_005"),}
				],
			});
			return;
		}

		await this.call("RevisaoProvaFCD/marcarRevisaoQuestaoOK", codQuestao);
		this.atualizarQuestao(codQuestao);
	}

	getIdsQuestoesSelecionadas() {
		let ids = [];
		$("[questao] [selecao]:checked").each((i, checkbox) => {
			ids.push(Number($(checkbox).closest("[questao]").attr("cod-questao")));
		})
		return ids;
	}

	async gravarDisciplina(codDisciplina, codsQuestoes) {

		let codDisciplinaAlteracao = codDisciplina;

		if (codDisciplinaAlteracao == null) {
			codDisciplinaAlteracao = this.getValor("codDisciplinaEscolhida");
		}

		if (codsQuestoes.push == null) {
			let codigos = [];
			codigos.push(codsQuestoes);
			codsQuestoes = codigos;
		}

		let gravacaoDisciplinaTO = {
			codsQuestoes: codsQuestoes,
			codDisciplina: codDisciplinaAlteracao
		}

		await this.call("RevisaoProvaFCD/gravarDisciplina", gravacaoDisciplinaTO);
		this.atualizarQuestao(codsQuestoes);
		revisaoProvaVH.limparQuestoesSelecionadas();
	}

	async removerClassificacao(botao) {

		const $botao = $(botao);
		let idDisciplina = Number($botao.attr("id-disciplina"));
		let idQuestao = Number($botao.closest("[questao]").attr("cod-questao"));
		let idsQuestoesSelecionadas = this.getIdsQuestoesSelecionadas();

		idsQuestoesSelecionadas.push(idQuestao);

		if (idsQuestoesSelecionadas.length > 1 && !await this.confirmar(this.getMsg("FP_FRONT_RevisaoProvaVH_109"))) {
			idsQuestoesSelecionadas = [idQuestao];
		}

		await this.call("RevisaoProvaFCD/removerClassificacao", idDisciplina, idsQuestoesSelecionadas);

		this.atualizarQuestao(idsQuestoesSelecionadas);
	}

	substituirTexto() {

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_RevisaoProvaVH_110"));

		this.setMetodoSubmit(async () => await this.buscarTextosParaSubstituicao());

		this.addCampoTexto({ id: "expRegBusca", label: this.getMsg("FP_FRONT_RevisaoProvaVH_111"), valor: "julgue os item" });

		this.addCampoTexto({ id: "expRegSubstituicao", label: this.getMsg("FP_FRONT_RevisaoProvaVH_112"), valor: "julgue o item" });

		this.append("<div id='resultadoBuscaParaSubst'></div>");

		this.addEspacamentoHorizontal("20px");

		this.addBotao({
			id: "botaoAplicarSubst", label: this.getMsg("FP_FRONT_RevisaoProvaVH_113"), onClick: () => this.aplicarSubstituicoes(),
			classe: "btn-danger", visivel: false
		});

		this.addEspacamentoHorizontal("20px");
		this.exibir();
		this.focar("expRegBusca");
	}

	async buscarTextosParaSubstituicao(pagina?) {

		if (this.hasValue(pagina)) {
			this.setHtml("resultadoBuscaParaSubst", "");
			pagina = 0;
		}

		let buscaParaSubstituicaoTO = {
			expRegBusca: this.getValor("expRegBusca"),
			expRegSubstituicao: this.getValor("expRegSubstituicao"),
			pagina: pagina
		}

		$("div.alert").remove();
		$("#botaoAplicarSubst").hide();

		const encontradosBuscaSubstituicaoTO = await this.call({
			endpoint: "RevisaoProvaFCD/buscarTextosParaSubstituicao",
			params: [buscaParaSubstituicaoTO],
			msgCarregando: this.getMsg("FP_FRONT_RevisaoProvaVH_114"),
		});

		this.setIdTarget("resultadoBuscaParaSubst");

		if (pagina == 0) {
			this.append("<span class='badge'>" + encontradosBuscaSubstituicaoTO.numPrimeiro + "-" + encontradosBuscaSubstituicaoTO.numUltimo
				+ " de " + encontradosBuscaSubstituicaoTO.numEncontrados + "</span>")
		} else {
			this.append("<span class='badge'>" + encontradosBuscaSubstituicaoTO.numPrimeiro + "-" + encontradosBuscaSubstituicaoTO.numUltimo + "</span>")
		}

		for (const textoParaSubstituicaoTO of encontradosBuscaSubstituicaoTO.collectionTextoParaSubstituicaoTO) {

			this.append("<div class='well' ");

			if (textoParaSubstituicaoTO.codTexto != null)
				this.append(" codTexto='" + textoParaSubstituicaoTO.codTexto + "' ");

			if (textoParaSubstituicaoTO.codItem != null)
				this.append(" codItem='" + textoParaSubstituicaoTO.codItem + "' ");

			if (textoParaSubstituicaoTO.codQuestao != null)
				this.append(" codQuestao='" + textoParaSubstituicaoTO.codQuestao + "' ");

			this.append(">");
			this.append("<div class='operacoes'>");
			this.append(`<button class='btn btn-default btn-sm' onclick='revisaoProvaVH.salvarTexto(this)'>${this.getMsg("FP_FRONT_RevisaoProvaVH_115")}</button>`);
			this.append(`<button class='btn btn-default btn-sm' onclick='revisaoProvaVH.recuperarProvaOriginal(this)'>${this.getMsg("FP_FRONT_RevisaoProvaVH_116")}</button>`);
			this.append("</div>");
			this.append("<div contentEditable='true'>" + textoParaSubstituicaoTO.conteudo + "</div>");

			if (textoParaSubstituicaoTO.conteudoSubstituido != null) {
				this.append("<div class='substituido'>" + textoParaSubstituicaoTO.conteudoSubstituido + "</div>");
			}

			this.append("</div>");
		}

		if (encontradosBuscaSubstituicaoTO.numUltimo < encontradosBuscaSubstituicaoTO.numEncontrados) {
			$(".maisOcorrencias").remove();
			this.addEspacamentoHorizontal("10px");
			this.addBotao({ label: "+50", onClick: () => this.buscarTextosParaSubstituicao(pagina + 1), classe: "maisOcorrencias" })
		}

		this.exibir();

		if (encontradosBuscaSubstituicaoTO.numEncontrados > 0) {
			await this.show("botaoAplicarSubst");
		}
	}

	async salvarTexto(elementoBotao) {
		let button = $(elementoBotao);
		let div = button.parent().parent();
		let divConteudo = div.find("div[contentEditable='true']");

		divConteudo.find("span.highlight").each(function() {
			let span = $(this);
			span.replaceWith(span.html());
		})

		let salvamentoTextoNaSubstituicaoTO = {
			codTexto: div.attr("codTexto"),
			codItem: div.attr("codItem"),
			codQuestao: div.attr("codQuestao"),
			novoTexto: divConteudo.html()
		}

		await this.call("RevisaoProvaFCD/salvarNovoConteudoNaSubstituicaoTexto", salvamentoTextoNaSubstituicaoTO);
		this.mostrarMsgAjax(this.getMsg("FP_FRONT_RevisaoProvaVH_117"), 1);
	}

	async recuperarProvaOriginal(elementoBotao) {
		let button = $(elementoBotao);
		let div = button.parent().parent();

		let buscaProvaOriginalTO = {
			codTexto: div.attr("codTexto"),
			codQuestao: div.attr("codQuestao"),
			codItem: div.attr("codItem"),
			codProva: null
		}

		buscaProvaOriginalTO = await this.call("RevisaoProvaFCD/recuperarProvaOriginal", buscaProvaOriginalTO);

		if (buscaProvaOriginalTO.codProva == null) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RevisaoProvaVH_118") });
			return;
		}

		this.chamarMetodoVHEmNovaPagina("revisaoProvaVH.editar(" + buscaProvaOriginalTO.codProva + "," + buscaProvaOriginalTO.codQuestao + ")");
	}

	async aplicarSubstituicoes() {

		let buscaParaSubstituicaoTO = {
			expRegBusca: this.getValor("expRegBusca"),
			expRegSubstituicao: this.getValor("expRegSubstituicao"),
		}

		const substituidos = await this.call({
			endpoint: "RevisaoProvaFCD/aplicarSubstituicoes",
			params: [buscaParaSubstituicaoTO],
			msgCarregando: this.getMsg("FP_FRONT_RevisaoProvaVH_119")
		});

		this.addMsgSucesso({ texto: this.getMsg("FP_FRONT_RevisaoProvaVH_120", substituidos) });
		this.exibir();
	}

	async substituirQuestoesRepetidas(codQuestao) {
		this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_RevisaoProvaVH_123"),
			botoes: [
				{
					label: this.getMsg("MSG_VH_004"),
					classe: "btn-primary",
					onClick: async () => {
						await this.call("CadastroQuestaoFCD/substituirQuestoesRepetidas", codQuestao);
						this.mostrarMsgAjax(this.getMsg("FP_FRONT_RevisaoProvaVH_124"), 2);
					}
				},
				{label: this.getMsg("MSG_VH_005"),}
			],
		});
	}

	async editarTempoParaResposta(input) {
		let div = $(input).closest('[id-secao-questao]');
		let idSecaoQuestao = $(div).attr('id-secao-questao');
		await this.call("RevisaoProvaFCD/editarTempoParaResposta", this.getValor(input), idSecaoQuestao);
	}

	async editarTexto(div?, deveAtualizarPaginaAposSalvar?: boolean) {

		let codTexto = $(div).closest("[texto]").attr("cod-texto");

		this.addPopup({
			id: "revisao_texto",
			titulo: this.getMsg("FP_FRONT_RevisaoProvaVH_125"),
			width: "710px",
			// height: "560px",
			botoes: [{
				label: this.getMsg("FP_FRONT_RevisaoProvaVH_126"), 
				classe: "btn-primary", 
				onClick: async () => {

					if (!this.validarCamposObrigatorios("revisao_texto_conteudo")) {
						return false;
					}

					let salvamentoRevisaoTextoTO = {
						codTexto: codTexto,
						titulo: this.getValor("revisao_texto_titulo"),
						conteudo: this.getValor("revisao_texto_conteudo"),
						rodape: this.getValor("revisao_texto_rodape"),
						isLinhasNumeradas: this.getValor("revisao_texto_isLinhasNumeradas"),
						codsQuestoesAssociadas: this.getValor("revisao_texto_questoes_associadas")
					}

					if (this.isEmpty(salvamentoRevisaoTextoTO.codsQuestoesAssociadas) && !await this.confirmar(this.getMsg("FP_FRONT_RevisaoProvaVH_127"))) {
						return false;
					}

					const codsQuestoesImpactadas = await this.call("RevisaoProvaFCD/salvarRevisaoTexto", salvamentoRevisaoTextoTO);
					if (deveAtualizarPaginaAposSalvar) {
						document.location.reload();
						return false;
					}

					this.atualizarQuestao(codsQuestoesImpactadas);
					this.fecharPopup("revisao_texto");

					return false;
				}
			}, {
				label: this.getMsg("FP_FRONT_RevisaoProvaVH_128"), 
				classe: "btn-danger", 
				onClick: async () => {
					await this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_RevisaoProvaVH_129"),
						botoes: [
							{
								label: this.getMsg("MSG_VH_004"),
								classe: "btn-primary",
								onClick: async () => {
									this.fecharPopup("revisao_texto");
									await this.call("RevisaoProvaFCD/removerTexto", codTexto);
									$("[cod-texto=" + codTexto + "]").fadeOut(1000).remove();
								}
							},
							{label: this.getMsg("MSG_VH_005"),}
						],
					});
				}
			}]
		});

		let codsQuestoes = [];
		let collectionQuestoes = []

		$("[questao]").each((i, questao) => {
			collectionQuestoes.push({ id: $(questao).attr("cod-questao"), descricao: $(questao).find("[numerador]").attr("numerador") });
		})

		$("[cod-texto=" + codTexto + "]").each((i, texto) => {
			codsQuestoes.push($(texto).closest("[questao]").attr("cod-questao"));
		})

		await this.addSelect({
			collection: collectionQuestoes,
			id: "revisao_texto_questoes_associadas",
			label: this.getMsg("FP_FRONT_RevisaoProvaVH_130"),
			multiplo: true,
			classe: "col-md-6",
			valor: codsQuestoes
		})

		this.addCheckbox({
			id: "revisao_texto_isLinhasNumeradas",
			label: this.getMsg("FP_FRONT_RevisaoProvaVH_131"),
			valor: $(div).closest("[texto]").find("[numeracao]").length > 0,
			classe: "col-md-6"
		})

		let titulo = $(div).closest("[texto]").find("[titulo]").html();
		let conteudo = $(div).closest("[texto]").find("[conteudo]").html();
		let rodape = $(div).closest("[texto]").find("[rodape]").html();
		if (titulo) titulo = titulo.trim();
		if (conteudo) conteudo = conteudo.trim();
		if (rodape) rodape = rodape.trim();

		this.addEspacamentoHorizontal("1px");
		this.addEditorHTML({ label: this.getMsg("FP_FRONT_RevisaoProvaVH_132"), id: "revisao_texto_titulo", valor: titulo, classe: "col-md-12", toolbar: "importacao" });
		this.addEditorHTML({ label: this.getMsg("FP_FRONT_RevisaoProvaVH_133"), id: "revisao_texto_conteudo", valor: conteudo, classe: "col-md-12", toolbar: "importacao" })
		this.addEditorHTML({ label: this.getMsg("FP_FRONT_RevisaoProvaVH_134"), id: "revisao_texto_rodape", valor: rodape, classe: "col-md-12", toolbar: "importacao" })

		this.exibirPopups();
	}

	desselecionarTodas() {
		$("[questao] [selecao]").each(function() {
			revisaoProvaVH.selecionarQuestao(this, false);
		})
	}

	selecionarQuestao(checkbox, novoEstadoCheckbox: boolean = null) {

		if (novoEstadoCheckbox != null) {
			$(checkbox).prop("checked", novoEstadoCheckbox);
		}

		let questao = $(checkbox).closest("[questao]");
		let codQuestao = $(questao).attr("cod-questao");

		if ($(checkbox).is(":checked")) {
			$(questao).addClass("questaoSelecionada");
			let numerador = $(questao).find("[numerador]").attr("numerador");
			let style = "";

			$("[questoes-selecionadas]").append("<a class='btn btn-default questaoSelecionada' cod-questao='" + codQuestao + "' " + style + ">" + numerador + "</a>").find("[cod-questao='" + codQuestao + "']").on("click", ({ target }) => {
				let codQuestao = $(target).attr("cod-questao");
				UtilWindow.scrollTo(`[questao][cod-questao='${codQuestao}']`);
			});

		} else {
			$(checkbox).closest("[questao]").removeClass("questaoSelecionada");
			$("[questoes-selecionadas] [cod-questao='" + codQuestao + "']").remove();
		}
	}

	exportar() {
		if (this.exibicaoRevisaoDeProvasTO.codProva == null) return;

		this.addPopup({
			id: "substituicao_questoes_repetidas",
			titulo: "Exportação de DOCX para revisão",
			width: "500px",
			// height: "400px",
			botoes: [{ label: "<i class='fa fa-file-word-o'></i> Exportar", classe: "btn-primary", onClick: async () => {
				
				const exportacaoDocxRevisaoTO = await this.call("RevisaoProvaFCD/exportar", this.exibicaoRevisaoDeProvasTO.codProva, this.getValor("isComComentariorComAutor"));
		
				await this.exibirAlerta({
					msg: `
						<p>
							${this.getMsg("FP_FRONT_RevisaoProvaVH_137")}:
						</p>
						<p>
							<a href='${exportacaoDocxRevisaoTO.pathDocx}' class='fp-link-aguardar-geracao-arquivo' target='_blank'>${this.getMsg("FP_FRONT_RevisaoProvaVH_136")}</a>
						</p>`,
				});

				await this.call("RevisaoProvaFCD/removerPFExportacao", exportacaoDocxRevisaoTO.codProvaFeita);
		
				this.exibir();
			} }]
		});

		this.addCheckbox({
			label: "Comentários devem possuir autor e data",
			id: "isComComentariorComAutor",
			classe: "col-md-12",
			valor: true
		});

		this.exibirPopups();
	}
}

const revisaoProvaVH = new RevisaoProvaVH();
