class PausaPFVH extends PFVH {
	
	constructor() {
		super(PausaPFVH.name);
	}

	async inicializar() {
		const $aplicacaoProva = this.get$AplicacaoProva();

		if (!$aplicacaoProva) return;

		$aplicacaoProva.removeData("fp-is-prova-pausada");
	}

	onErroFiscalizacaoMonitorada(msgUsuario: string, msgAuditoria: string, msgIntegracaoAuditoria: any, codProvaFeita: number, codAgendamentoUsuario: number) {
		
		monitoramentoPFVH.enviarKeepAlive().then(() => {}, () => {}); // vai enviar só se estiver em prova
		monitoramentoPFVH.registrarAuditoriaAU(msgAuditoria, msgIntegracaoAuditoria, codProvaFeita, codAgendamentoUsuario).then(() => {}, () => {});

		$("#corpo").html("");

		fiscalizacaoMonitoradaVH.pararMonitoramento();
		
		this.exibirAlerta({
			titulo: this.getMsg("MSG_VH_APR_190"),
			msg: msgUsuario || this.getMsg("MSG_VH_APR_194"),
			botoes: [{label: "OK"}],
		}).then(() => {
			UtilHash.carregarTelaInicial();
		});
	}

	async handleResponsePausa(codProvaFeita: number, situacaoMonitoramentoPFTO: any) {

		const $aplicacaoProva = this.get$AplicacaoProva();

		if (!$aplicacaoProva || $aplicacaoProva.data("fp-is-prova-pausada")) return;

		if (!situacaoMonitoramentoPFTO || situacaoMonitoramentoPFTO.isPFEmSolicitacaoDePausa !== true) return;

		const milisegundosRestantesParaProva = cronoPFVH.getMilisegundosRestantesParaProva();

		const pausaAvaliadoPFTO = {
			codProvaFeita,
			segundosRestantesNaPausa: milisegundosRestantesParaProva != null ? Math.ceil(milisegundosRestantesParaProva / 1000) : null
		}
	
		const dadosPausaPFTO = await this.call("AplicacaoProvaFCD/registrarMomentoPausa", pausaAvaliadoPFTO);

		if (!dadosPausaPFTO) {
			throw new Error("Registro do momento da pausa para avaliado no backend veio sem informações.");
		}

		$aplicacaoProva.data("fp-is-prova-pausada", true);

		cronoPFVH.pararCronometro();
		monitoramentoPFVH.desativarMonitoramentoSaidaDoNavegador();
		monitoramentoPFVH.pararKA();
		salvamentoPFVH.salvarProvaFeita(this.getMsg("FP_FRONT_PausaPFVH_001"), true);

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_PausaPFVH_002"));
		
		this.addCampoExibicao({
			label: this.getMsg("FP_FRONT_PausaPFVH_003"), 
			valor: dadosPausaPFTO.motivoPausa,
			classe: "col-md-12"
		});
		
		this.addCampoExibicao({
			label: this.getMsg("FP_FRONT_PausaPFVH_004"), 
			valor: UtilData.toDDMMYYYYHHMMSS(dadosPausaPFTO.instantFimPausa),
			classe: "col-md-12"
		});

		if (dadosPausaPFTO.segundosRestantesCronometroDeProvaNaPausa != null) {
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_PausaPFVH_005"), 
				valor: UtilData.getDuracao({segundos: dadosPausaPFTO.segundosRestantesCronometroDeProvaNaPausa}),
				classe: "col-md-12"
			});
		}

		this.addTextoAlerta({ texto: `
			${this.getMsg("FP_FRONT_PausaPFVH_007")}
			<br><br>
			${this.getMsg("FP_FRONT_PausaPFVH_008")}
		`});

		this.addBotao({
			label: this.getMsg("FP_FRONT_PausaPFVH_006"),
			classe: "btn-primary pull-right",
			onClick: () => document.location.reload()
		})

		this.exibir();
	}
}

const pausaPFVH = new PausaPFVH();
