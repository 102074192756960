class CadastroAvaliacaoVH extends AmaisVH {
	selecaoFiltrosAvaliacoesTO: any;

	constructor() {
		super(CadastroAvaliacaoVH.name);
		this.addOperacaoParaHash("cael", this.exibirListaAvaliacoes);
		this.addOperacaoParaHash("caea", this.editarAvaliacao);	
	}

	async exibirListaAvaliacoes(codAvaliacao?) {

		UtilHash.registrarHistorico(this.exibirListaAvaliacoes, codAvaliacao);

		this.selecaoFiltrosAvaliacoesTO = {};

		const exibicaoFiltrosAvaliacoesTO = await this.call("CadastroAvaliacaoFCD/listarFiltros");

		this.limpar();

		this.setTitulo(this.getCfg("LABEL_AVALIACAO_PLURAL"));
		this.setIdTarget("tituloSuperiorDireito");

		this.append("<div class='btn-group'>");

		this.addBotao({
			label: "<i class='fa fa-plus-circle'></i> " + this.getCfg("LABEL_AVALIACAO"), classe: "btn-success", onClick: () => {
				this.editarAvaliacao();
			}
		});

		this.addBotaoBusca("filtrosAvaliacoes")

		this.append("</div>");
		this.setIdTarget(null);

		const isExibirSegmentos = exibicaoFiltrosAvaliacoesTO.collectionSegmentosTO.length > 1 || (exibicaoFiltrosAvaliacoesTO.collectionSegmentosTO.length == 1 && exibicaoFiltrosAvaliacoesTO.collectionSegmentosTO[0].filhos != null);

		this.append("<div id='filtrosAvaliacoes' style='display: none'>");
		this.abrirAbaAccordion({ titulo: this.getMsg("MSG_VH_001"), aberta: true });
		this.addFormulario();

		if (isExibirSegmentos) {
			await this.addSelect({
				collection: exibicaoFiltrosAvaliacoesTO.collectionSegmentosTO,
				id: "codsEmpresasBusca",
				label: this.getMsg("MSG_VH_CU_18"),
				multiplo: true,
				valor: this.selecaoFiltrosAvaliacoesTO.codsSegmentos,
				classe: "col-xs-12 col-md-6 col-lg-4",
				onChange: () => {
					this.buscarAvaliacoes();
				}
			});
		}

		this.addCampoTexto({
			label: this.getMsg("MSG_VH_002"),
			id: "busca",
			valor: this.selecaoFiltrosAvaliacoesTO.buscaTextual,
			classe: "col-xs-12 col-md-6 col-lg-4",
			onEnter: async () => await this.buscarAvaliacoes()
		});

		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		this.append("</div>");

		this.append("<div class='row'><div class='col-md-12' id='resultado_busca_avaliacoes'></div></div>");

		this.exibir();

		this.buscarAvaliacoes(codAvaliacao);
	}

	async buscarAvaliacoes(codAvaliacao?) {
		let selecaoFiltrosAvaliacoesTO = {
			codsSegmentos: this.getValor("codsEmpresasBusca"),
			buscaTextual: this.getValor("busca"),
			paginacaoTO: null
		}

		const exibicaoListagemAvaliacoesTO = await this.call("CadastroAvaliacaoFCD/listarAvaliacoes", selecaoFiltrosAvaliacoesTO);
		this.atualizarListagemAvaliacoes(exibicaoListagemAvaliacoesTO, codAvaliacao, selecaoFiltrosAvaliacoesTO);
	}

	atualizarPaginadorAvaliacoes(exibicaoListagemAvaliacoesTO, selecaoFiltrosAvaliacoesTO, codAvaliacao) {
		this.setIdTarget("resultado_busca_avaliacoes");
		this.append("<div id='paginacao_resultado_busca_avaliacoes'></div>");

		this.setIdTarget("paginacao_resultado_busca_avaliacoes");

		this.append(
			UtilPaginacao.criarPaginador({
				paginacaoTO: exibicaoListagemAvaliacoesTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					selecaoFiltrosAvaliacoesTO.paginacaoTO = paginacaoTO;
					exibicaoListagemAvaliacoesTO = await this.call("CadastroAvaliacaoFCD/listarAvaliacoes", selecaoFiltrosAvaliacoesTO);

					this.atualizarListagemAvaliacoes(exibicaoListagemAvaliacoesTO, codAvaliacao, selecaoFiltrosAvaliacoesTO);
				},
				classe: "paginador-resultado-avaliacoes",
				// itensPorPagina: exibicaoListagemAvaliacoesTO.paginacaoTO.numItensPorPagina,
				displayOpcao: null,
				displayOpcaoInicioFim: null,
				// paginaAtiva: exibicaoListagemAvaliacoesTO.paginacaoTO.numPaginaAtual,
				// totalItens: exibicaoListagemAvaliacoesTO.paginacaoTO.numTotalItens
			})
		);

		this.setIdTarget(null);
	}

	atualizarListagemAvaliacoes(exibicaoListagemAvaliacoesTO, codAvaliacao, selecaoFiltrosAvaliacoesTO) {
		this.setHtml("resultado_busca_avaliacoes", "");
		this.setIdTarget("resultado_busca_avaliacoes");

		if (!exibicaoListagemAvaliacoesTO?.listaListagemAvaliacaoTO) return;

		this.addListagemHierarquica(exibicaoListagemAvaliacoesTO.listaListagemAvaliacaoTO, {
			propId: "codAvaliacao",
			nomeNovoItem: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroAvaliacaoVH_001")}`,
			metodoMontarLabel: (listagemAvaliacaoTO) => `<span class='label-avaliacao'>${listagemAvaliacaoTO.nomeTipoAvaliacao}&nbsp;</span>${listagemAvaliacaoTO.nomeAvaliacao}`,
			hashSelecao: UtilHash.getHash(this.editarAvaliacao),
			idSelecionado: codAvaliacao,
			propDetalhe: (listagemAvaliacaoTO) => {
				const h = [];
				if (listagemAvaliacaoTO.numProvasAssociadas && listagemAvaliacaoTO.numProvasAssociadas == 1) {
					h.push(this.getMsg("FP_FRONT_CadastroAvaliacaoVH_002", listagemAvaliacaoTO.numProvasAssociadas));
				} else if (listagemAvaliacaoTO.numProvasAssociadas && listagemAvaliacaoTO.numProvasAssociadas > 1) {
					h.push(this.getMsg("FP_FRONT_CadastroAvaliacaoVH_003", listagemAvaliacaoTO.numProvasAssociadas));
				}
				if (listagemAvaliacaoTO.numTurmasAssociadas && listagemAvaliacaoTO.numTurmasAssociadas == 1) {
					h.push(listagemAvaliacaoTO.numTurmasAssociadas + " " + this.getCfg("LABEL_TURMA").toLowerCase());
				} else if (listagemAvaliacaoTO.numTurmasAssociadas && listagemAvaliacaoTO.numTurmasAssociadas > 1) {
					h.push(listagemAvaliacaoTO.numTurmasAssociadas + " " + this.getCfg("LABEL_LISTAGEM_TURMAS").toLowerCase());
				}
				return h.join(", ");
			},
			onNovo: (codAvaliacao) => {
				this.editarAvaliacao(null, codAvaliacao);
			},
			metodoMostrarFilhos: async (link, to, cfgs) => {
				return (await this.call("CadastroAvaliacaoFCD/listarAvaliacoes", { 
					codAvaliacaoPai: to.codAvaliacao,
					paginacaoTO: {
						numItensPorPagina: 1000 // senão não mostra todos os filhos na hierarquia (PRECISA MELHORAR ISSO)
					}
				})).listaListagemAvaliacaoTO;
			}
		});

		this.atualizarPaginadorAvaliacoes(exibicaoListagemAvaliacoesTO, selecaoFiltrosAvaliacoesTO, codAvaliacao);

		this.exibir();
	}

	async editarAvaliacao(codAvaliacao?, codAvaliacaoPai?, isVoltar?) {

		UtilHash.registrarHistorico(this.editarAvaliacao, codAvaliacao, codAvaliacaoPai, isVoltar);

		let filtroRecuperacaoEdicaoTO = {
			paginacaoTO: null,
			codAvaliacao: codAvaliacao
		}

		let edicaoAvaliacaoTO = await this.call("CadastroAvaliacaoFCD/recuperarEdicao", filtroRecuperacaoEdicaoTO);

		this.limpar();

		const isExibirSegmentos = edicaoAvaliacaoTO.collectionSegmentosTO.length > 1 || (edicaoAvaliacaoTO.collectionSegmentosTO.length == 1 && edicaoAvaliacaoTO.collectionSegmentosTO[0].filhos != null);
		const isEdicaoHabilitada = this.isEmpty(edicaoAvaliacaoTO.codigoSistemaOrigem);

		this.setTitulo(edicaoAvaliacaoTO.nomeCompleto || this.getMsg("FP_FRONT_CadastroAvaliacaoVH_004", this.getCfg("LABEL_AVALIACAO").toLowerCase()));
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroAvaliacaoVH_005"));

		if (edicaoAvaliacaoTO.isArquivada) {
			this.append(" <span class='label label-default'>CADASTRO EXCLUÍDO</span> ");
			this.addEspacamentoHorizontal("20px");
		}

		if (isExibirSegmentos) {
			await this.addSelect({
				label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_006"),
				id: "codEmpresa",
				collection: edicaoAvaliacaoTO.collectionSegmentosTO,
				valor: edicaoAvaliacaoTO.codEmpresa,
				habilitado: isEdicaoHabilitada
			});
		}

		await this.addSelect({
			label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_008", this.getCfg("LABEL_AVALIACAO")),
			id: "codAvaliacaoPai",
			collection: edicaoAvaliacaoTO.listaOpcaoListaTOAvaliacoesPai,
			valor: (edicaoAvaliacaoTO.codAvaliacao == null && codAvaliacaoPai != null ? codAvaliacaoPai : null),
			habilitado: isEdicaoHabilitada
		});

		await this.addSelect({
			label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_007"),
			id: "tipoAvaliacao",
			collection: edicaoAvaliacaoTO.listaOpcaoListaTOTipoAvaliacao,
			obrigatorio: true,
			habilitado: isEdicaoHabilitada
		});

		await this.addSelect({
			label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_009"),
			id: "tipoFormula",
			collection: edicaoAvaliacaoTO.listaOpcaoListaTOTipoFormula.concat([{ id: "SEM_CALCULO", descricao: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_010") }]),
			onChange: async () => await this.atualizarFormularioEdicao(),
			habilitado: isEdicaoHabilitada,
			valor: edicaoAvaliacaoTO.tipoFormula || "SEM_CALCULO",
			obrigatorio: true
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_011"),
			id: "nome",
			valor: edicaoAvaliacaoTO.nome,
			obrigatorio: true,
			classe: "col-md-9",
			habilitado: isEdicaoHabilitada
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_012"),
			id: "sigla",
			valor: edicaoAvaliacaoTO.sigla,
			habilitado: isEdicaoHabilitada
		});

		this.addEspacamentoHorizontal("5px");

		this.append(`
			<div id="formulaPersonalizada">
		`);

		this.addCampoTexto({
			label: "Fórmula para CÁLCULO DE NOTA",
			id: "formulaNota",
			valor: edicaoAvaliacaoTO.formulaPersonalizadaTO?.formulaNota,
			habilitado: isEdicaoHabilitada
		});

		this.addCampoTexto({
			label: "Fórmula para definir APROVAÇÃO",
			id: "formulaAprovacao",
			valor: edicaoAvaliacaoTO.formulaPersonalizadaTO?.formulaAprovacao,
			habilitado: isEdicaoHabilitada
		});

		this.addCampoTexto({
			label: "Fórmula para definir se avaliado esteve PRESENTE",
			id: "formulaPresenca",
			valor: edicaoAvaliacaoTO.formulaPersonalizadaTO?.formulaPresenca,
			habilitado: isEdicaoHabilitada
		});

		this.addCampoTexto({
			label: "Fórmula para definir se DISCURSIVAS devem ser corrigidas",
			id: "formulaDiscursivasCorrigiveis",
			valor: edicaoAvaliacaoTO.formulaPersonalizadaTO?.formulaDiscursivasCorrigiveis,
			habilitado: isEdicaoHabilitada
		});

		this.addCheckbox({
			id: "isAprovacaoDependeAvaliacaoPai",
			label: "Aprovação nesta avaliação depende da avaliação superior",
			valor: edicaoAvaliacaoTO.formulaPersonalizadaTO?.isAprovacaoDependeAvaliacaoPai,
			habilitado: isEdicaoHabilitada
		});

		this.append(`
			</div>
		`);

		this.addEspacamentoHorizontal("5px");

		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_015"),
			classe: "pull-left",
			onClick: () => {
				UtilHash.voltar();
			}
		});

		this.append("<div class='btn-group pull-right'>");

		this.addBotao({
			label: (edicaoAvaliacaoTO.isArquivada ? this.getMsg("FP_FRONT_CadastroAvaliacaoVH_013") : this.getMsg("FP_FRONT_CadastroAvaliacaoVH_014")),
			classe: (edicaoAvaliacaoTO.isArquivada ? "" : "btn-danger"),
			visivel: isEdicaoHabilitada,
			onClick: async () => {
				
				await this.call("CadastroAvaliacaoFCD/arquivarAvaliacao", edicaoAvaliacaoTO.codAvaliacao);

				if (isVoltar) {
					UtilHash.voltar();
				} else {
					this.exibirListaAvaliacoes();
				}
			}
		});

		if (isEdicaoHabilitada && !edicaoAvaliacaoTO.isArquivada) {
			this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_016"),
				onClick: async () => {
					this.verificarObrigatorios();

					const salvamentoAvaliacaoTO = {
						codAvaliacao: edicaoAvaliacaoTO.codAvaliacao,
						codEmpresa: this.getValor("codEmpresa"),
						codAvaliacaoPai: this.getValor("codAvaliacaoPai"),
						nome: this.getValor("nome"),
						sigla: this.getValor("sigla"),
						codigoSistemaOrigem: edicaoAvaliacaoTO.codigoSistemaOrigem,
						tipoAvaliacao: this.getValor("tipoAvaliacao"),
						tipoFormula: this.getValor("tipoFormula"),
						formulaPersonalizadaTO: null
					};

					if (salvamentoAvaliacaoTO.tipoFormula === "SEM_CALCULO") {

						salvamentoAvaliacaoTO.tipoFormula = null;

					} else if (salvamentoAvaliacaoTO.tipoFormula === "PERSONALIZADA") {
						
						salvamentoAvaliacaoTO.formulaPersonalizadaTO = {
							formulaNota: this.getValor("formulaNota"),
							formulaAprovacao: this.getValor("formulaAprovacao"),
							formulaPresenca: this.getValor("formulaPresenca"),
							formulaDiscursivasCorrigiveis: this.getValor("formulaDiscursivasCorrigiveis"),
							isAprovacaoDependeAvaliacaoPai: this.getValor("isAprovacaoDependeAvaliacaoPai"),
						};
					}

					const codAvaliacao = await this.call("CadastroAvaliacaoFCD/salvarAvaliacao", salvamentoAvaliacaoTO);

					if (isVoltar) {
						UtilHash.voltar();
					} else {
						this.exibirListaAvaliacoes(codAvaliacao);
					}
				}
			});
		}

		this.append("</div>");

		if (codAvaliacao && !edicaoAvaliacaoTO.isArquivada) {
			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_018"), prop: (to) => `<a href='${UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 0, to.codProva)}'>${to.tituloProva}</a>`});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_019"), prop: (to) => {
					if (to.numInscritos > 0) {
						return `<a class='btn btn-default' href='${UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 3, to.codProva)}' title='${this.getMsg("FP_FRONT_CadastroAvaliacaoVH_022")}'>${to.numInscritos}</a>`;
					} else {
						return null;
					}
				}
			});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_020"), prop: (to) => {
					if (to.numRespostas > 0) {
						return `<a class='btn btn-default' href='${UtilHash.getHash(cadastroProvaVH.exibirAbasProva, "5A", to.codProva)}' title='${this.getMsg("FP_FRONT_CadastroAvaliacaoVH_023")}'>${to.numRespostas}</a>`;
					} else {
						return null;
					}
				}
			});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_021"), prop: (to) => {
					if (to.aproveitamentoMedio != null) {
						return `<a class='btn btn-default' href='${UtilHash.getHash(cadastroProvaVH.exibirAbasProva, "5C", to.codProva)}' title='${this.getMsg("FP_FRONT_CadastroAvaliacaoVH_024")}'>${UtilNumero.floatToString(to.aproveitamentoMedio)}%</a>`;
					} else {
						return null;
					}
				}
			});
			await this.addTabela({
				id: "pastas_provas_vinculadas",
				titulo: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_017"),
				colunas: colunas,
				collection: edicaoAvaliacaoTO.listaOpcaoListaTOProvasVinculadas,
				propId: "codProva",
				onExclusao: async (botao, codProva) => {
					await this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_025"),
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_026"), 
							classe: "btn-danger", 
							onClick: async () => {
								await this.call("CadastroAvaliacaoFCD/desvincularProva", codAvaliacao, codProva);
								await this.editarAvaliacao(codAvaliacao);
							}
						}]
					})
				},
				labelNovo: "Prova",
				onNovo: async () => {
					this.addPopup({
						titulo: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_027"),
						subtitulo: this.getValor("nome"),
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_028"), 
							classe: "btn-primary", 
							onClick: async () => {
								const codsProvasParaVinculacao = this.getValor("codsProvasParaVinculacao");

								if (this.hasValue(codsProvasParaVinculacao)) {
									await this.call("CadastroAvaliacaoFCD/vincularProvas", codAvaliacao, codsProvasParaVinculacao);
									this.editarAvaliacao(codAvaliacao);
								}
							}
						}]
					});

					await this.addSelect({
						label: this.getMsg("FP_FRONT_CadastroAvaliacaoVH_029"),
						classe: "col-md-12",
						id: "codsProvasParaVinculacao",
						loadOpcoesBack: {
							endpoint: "ListagemSelecaoFCD/listarProvas",
							numMinimoCaracteres: 3,
						},
						multiplo: true,
						obrigatorio: true
					});

					this.exibirPopups();
				},
				paginacao: {
					paginacaoTO: edicaoAvaliacaoTO.paginacaoTO,
					onCarregarPagina: async (paginacaoTO) => {
						filtroRecuperacaoEdicaoTO.paginacaoTO = paginacaoTO;
						edicaoAvaliacaoTO = await this.call("CadastroAvaliacaoFCD/recuperarEdicao", filtroRecuperacaoEdicaoTO);
						return edicaoAvaliacaoTO.listaOpcaoListaTOProvasVinculadas;
					}
				}
			});
		}

		this.exibir();

		await this.atualizarFormularioEdicao();
	}

	async atualizarFormularioEdicao() {
		const tipoFormula = this.getValor("tipoFormula");
		if (tipoFormula == "PERSONALIZADA") {
			await this.show("formulaPersonalizada");
		} else {
			await this.hide("formulaPersonalizada");
		}
	}
}

const cadastroAvaliacaoVH = new CadastroAvaliacaoVH();
