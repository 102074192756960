class SelecaoQuestoesVH extends AmaisVH {
	cfgs: any;
	filtroAtivo: any;

	constructor() {
		super(SelecaoQuestoesVH.name);
	}
	
	cacheFiltros = {};

	async montarHtmlFiltros(cfgs) {
		
		this.addEspacamentoHorizontal("5px");
		
		this.append(`
			<div>
		`);
				
		if (cfgs.subtitulo != null) {
			this.append("<div class='col-md-12'>");
			this.addSubtitulo(cfgs.subtitulo);
			this.append("</div>");
		}
		
		if (cfgs.textoAntes != null) {
			this.append("<div class='col-md-12'>" + cfgs.textoAntes + "</div>");
			this.addEspacamentoHorizontal("5px");
		}
		
		this.appendJs(() => {
			$('#tiposDeFiltro a').click(function() {
				$(this).filter(':not(.active)').each(function() {
					selecaoQuestoesVH.recuperarListagem(this);
				});
			});
		});
		
		this.append(`
				<div class="col-md-12">
					<div busca-questoes class="well container-fluid">
						<div class="row">
		`);

		this.addFormulario();
		
		const exibirFiltrosAdicionais = !this.isAluno();
		const dicaBusca = this.getMsg("MSG_VH_SQ_01");
		const classeFormGroup = exibirFiltrosAdicionais ? "col-sm-8 col-lg-7" : "col-sm-12";
		
		this.append(`
							<div questao-busca-textual class="form-group ${classeFormGroup}">
								<div class="input-group input-group-lg">
									<input vh="SelecaoQuestoesVH" type="text" id="textoBusca" class="form-control" placeholder="${dicaBusca}">
									<div class="input-group-btn">
										<button id="btn-busca" class="btn btn-default"><i class="fa fa-search"></i></button>
									</div>
								</div>
							</div>
		`);
		
		if (exibirFiltrosAdicionais) {
			this.addCampoTexto({
				dica: this.getMsg("MSG_VH_SQ_02"), 
				id: "codigoQuestao", 
				onEnter: cfgs.onFiltroAlterado, 
				classe: "col-sm-4 col-lg-2"
			});
			this.append(`
							<div id="resultado_busca_por_codigo" style="float: left; margin-top: 5px">
							</div>
			`);
		}

		this.appendJs(() => {
			this.listenToClick("#btn-busca", cfgs.onFiltroAlterado);
			this.listenToEnter("#textoBusca", cfgs.onFiltroAlterado);
		});
		
		this.fecharFormulario();
		this.append(`
						</div>
						<div class="row">
		`);
		this.addFormulario();
		this.append(`
							<div id="tiposDeFiltro" class="btn-toolbar col-md-12">
								<div class="btn-group">
		`);

		if (this.getIsBancoQuestoesHabilitado() || this.getIsAmbienteImportacaoBancoQuestoes() || cfgs.possuiAcessoQuestoesComConcurso) {
			this.append(`
									<a class="btn btn-default" id="ANO">${this.getMsg("MSG_VH_SQ_08")}</a>
									<a class="btn btn-default" id="BANCA">${this.getMsg("MSG_VH_SQ_09")}</a>
									<a class="btn btn-default" id="ORGAO">${this.getCfg("LABEL_ORGAO", this.getMsg("MSG_VH_SQ_10"))}</a>
									<a class="btn btn-default" id="CARGO">${this.getMsg("MSG_VH_SQ_11")}</a>
									<a class="btn btn-default" id="NIVEL_CARGO">${this.getMsg("MSG_VH_SQ_12")}</a>
			`);
		}
		
		this.append(`				<a class="btn btn-default" id="PROVA">${this.getMsg("MSG_VH_SQ_13")}</a>
								</div>
								<div class="btn-group">
		`);
		
		const categoriasClassificacao = this.getCategoriasUtilizadas();

		if (categoriasClassificacao["DISCIPLINA"]) {
			this.append(`			<a class="btn btn-default" id="DISCIPLINA">${categoriasClassificacao["DISCIPLINA"].nome}</a>`);
		}
		
		if (categoriasClassificacao["TAG"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="TAG">${categoriasClassificacao["TAG"].nome}</a>`);
		}

		if (categoriasClassificacao["GRANDE_AREA"]) {
			this.append(`			<a class="btn btn-default" id="GRANDE_AREA">${categoriasClassificacao["GRANDE_AREA"].nome}</a>`);
		}

		if (categoriasClassificacao["AREA"]) {
			this.append(`			<a class="btn btn-default" id="AREA">${categoriasClassificacao["AREA"].nome}</a>`);
		}
		
		if (categoriasClassificacao["CURSO"]) {
			this.append(`			<a class="btn btn-default" id="CURSO">${categoriasClassificacao["CURSO"].nome}</a>`);
		}

		if (categoriasClassificacao["COMPETENCIA"]) {
			this.append(`			<a class="btn btn-default" id="COMPETENCIA">${categoriasClassificacao["COMPETENCIA"].nome}</a>`);
		}
		
		if (categoriasClassificacao["HABILIDADE"]) {
			this.append(`			<a class="btn btn-default" id="HABILIDADE">${categoriasClassificacao["HABILIDADE"].nome}</a>`);
		}
		
		if (categoriasClassificacao["CONTEUDO"]) {
			this.append(`			<a class="btn btn-default" id="CONTEUDO">${categoriasClassificacao["CONTEUDO"].nome}</a>`);
		}
		
		if (categoriasClassificacao["GRUPO_SUBSTITUICAO"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="GRUPO_SUBSTITUICAO">${categoriasClassificacao["GRUPO_SUBSTITUICAO"].nome}</a>`);
		}

		if (categoriasClassificacao["ASPECTO_AVALIADO"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="ASPECTO_AVALIADO">${categoriasClassificacao["ASPECTO_AVALIADO"].nome}</a>`);
		}
		if (categoriasClassificacao["CANAL"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="CANAL">${categoriasClassificacao["CANAL"].nome}</a>`);
		}
		if (categoriasClassificacao["EQUIPE"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="EQUIPE">${categoriasClassificacao["EQUIPE"].nome}</a>`);
		}
		if (categoriasClassificacao["MODELO_DE_PERGUNTA"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="MODELO_DE_PERGUNTA">${categoriasClassificacao["MODELO_DE_PERGUNTA"].nome}</a>`);
		}
		if (categoriasClassificacao["NOME_DO_PROCESSO"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="NOME_DO_PROCESSO">${categoriasClassificacao["NOME_DO_PROCESSO"].nome}</a>`);
		}
		if (categoriasClassificacao["TIPO_DE_AVALIACAO"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="TIPO_DE_AVALIACAO">${categoriasClassificacao["TIPO_DE_AVALIACAO"].nome}</a>`);
		}

		if (categoriasClassificacao["OUTROS"] && !this.isAluno()) {
			this.append(`			<a class="btn btn-default" id="OUTROS">${categoriasClassificacao["OUTROS"].nome}</a>`);
		}
		
		this.append(`				<a class="btn btn-default" id="TIPO_QUESTAO">${this.getMsg("MSG_VH_SQ_15")}</a>`);
		
		if (!this.isAluno()) {
			this.append(`
									<a class="btn btn-default" id="DIFICULDADE">${this.getMsg("MSG_VH_SQ_17")}</a>
								</div>
								<div class="btn-group">
			`);
			if (this.isAnalista() || (this.isConteudista(false) && this.isCfgDesabilitada("PROFESSOR_VISUALIZA_SOMENTE_SUAS_QUESTOES"))) {
				this.append(`
									<a class="btn btn-default" id="USUARIO_CADASTRO">${this.getMsg("MSG_VH_SQ_19")}</a>
									<a class="btn btn-default" id="EMPRESA_AUTOR">${this.getMsg("MSG_VH_SQ_20")}</a>
				`);
			}
			
			this.append(`
									<a class="btn btn-default" id="USUARIO_AUTOR">${this.getMsg("MSG_VH_SQ_21")}</a>
									<a class="btn btn-default" id="QUESTAO_SITUACAO">${this.getMsg("MSG_VH_SQ_35")}</a>
									<a class="btn btn-default" id="DEMANDA">${this.getMsg("MSG_VH_SQ_40")}</a>
									<a class="btn btn-default" id="GRADE_CORRECAO">${this.getMsg("MSG_VH_SQ_41")}</a>
			`);

			this.cacheFiltros["QUESTAO_SITUACAO"] = [
				{id: "ANULADA", descricao: this.getMsg("FP_FRONT_SelecaoQuestoesVH_002")}, 
				{id: "ARQUIVADA", descricao: this.getMsg("FP_FRONT_SelecaoQuestoesVH_003")}, 
				{id: "INEDITA", descricao: this.getMsg("FP_FRONT_SelecaoQuestoesVH_004")}, 
				{id: "IMPORTACAO_LIBERADA", descricao: this.getMsg("FP_FRONT_SelecaoQuestoesVH_005")}, 
				{id: "COM_TEXTO", descricao: this.getMsg("FP_FRONT_SelecaoQuestoesVH_009")}, 
				{id: "SEM_TEXTO", descricao: this.getMsg("FP_FRONT_SelecaoQuestoesVH_006")}, 
				{id: "TEM_IMAGEM_SIM", descricao: this.getMsg("FP_FRONT_SelecaoQuestoesVH_007")},  
				{id: "TEM_IMAGEM_NAO", descricao: this.getMsg("FP_FRONT_SelecaoQuestoesVH_008")}
			];
		}
		
		this.append(`
								</div>
							</div>
							<div id="selecaoQuestao_select" class="col-md-12">
								<div class="row">
		`);
		await this.addSelect({
			id: "codigosDoFiltro", 
			obrigatorio: true, 
			classe: this.classeInputPrincipal,
			dica: "111 " + this.getMsg("MSG_VH_SQ_22"),
			habilitado: false,
			visivel: false
		});
		this.append(`
								</div>
							</div>
		`);
		this.fecharFormulario();
		this.append(`
						</div>
						<div>
		`);
		this.addFormulario();

		let novosFiltros = [];

		if (cfgs.filtrosSelecionados && !this.isEmpty(cfgs.filtrosSelecionados)) {

			try {
				for (const filtro of cfgs.filtrosSelecionados) {
					if (filtro?.tipo && filtro.codigo && filtro.nome) {
						let tipo;
						switch (filtro.tipo) {
							case 'DP':
								tipo = "DISCIPLINA";
								break;
							case 'CC':
								tipo = "GRADE_CORRECAO";
								break;
							default:
								tipo = null;
								break;
						}
						if(tipo){
							novosFiltros.push({
								codigos: [filtro.codigo],
								descricaoCodigos: [filtro.nome],
								tipoFiltro: tipo
							})
						}
					}
				}
			} catch (e) {
				console.error(e);
				this.logger.error(e);
			}
		}
		
		this.append(`
							<div class='form-group col-md-12' style='margin-bottom: 0px;'>
								${this.getMsg("MSG_VH_SQ_23")}:&nbsp;&nbsp;&nbsp;
								<span id="selecaoQuestao_filtrosAtivos">
									${this.isEmpty(novosFiltros)?" <i>-</i>": ""}
								</span>
							</div>
		`);

		this.fecharFormulario();
		this.append(`
						</div>
					</div>
				</div>
			</div>
		`);

		this.cfgs = cfgs;

		if (this.hasValue(novosFiltros)) {
			await this.exibirNovoFiltro(novosFiltros);
		}
	}
	
	tiposDeFiltrosComMinimoCaracteres = ["DISCIPLINA", "TAG", "HABILIDADE", "GRANDE_AREA", "AREA", "CURSO", "COMPETENCIA", "CONTEUDO", "GRUPO_SUBSTITUICAO", "OUTROS", "PROVA", "CARGO", "ORGAO"];
	
	async recuperarListagem(botao) {
		
		this.filtroAtivo = botao.id;
		let collection = this.cacheFiltros[this.filtroAtivo];
		
		if (!this.tiposDeFiltrosComMinimoCaracteres.includes(this.filtroAtivo) && !collection) {
			
			const collection = await this.call("SelecaoQuestoesFCD/recuperarListagem", {
				filtroListado: this.filtroAtivo,
				codsDisciplinasRestricao: UtilBoot.getCodsDisciplinasRestricao(),
			});

			this.cacheFiltros[this.filtroAtivo] = collection;
			this.recuperarListagem(botao);

			return;
		}

		let descricaoDoUltimoFiltroSelecionado = $(botao).html();
		
		$(botao).closest(".btn-group").find("[titulo-dropdown]").html("<b>" + descricaoDoUltimoFiltroSelecionado + "&nbsp;&nbsp;&nbsp;&nbsp;</b>");
		
		$("#selecaoQuestao_select").html("");
		this.setIdTarget("selecaoQuestao_select");
		this.append('<div class="row">');
		this.addFormulario();
		
		if (collection) {
			await this.addSelect({
				collection: collection,
				id: "codigosDoFiltro",
				dicaComoValorInicial: this.getMsg("MSG_VH_SQ_25"),
				classe: this.classeInputPrincipal,
				multiplo: true
			});
			
		} else {
			await this.addSelect({
				id: "codigosDoFiltro",
				dicaComoValorInicial: this.getMsg("MSG_VH_SQ_25"),
				classe: this.classeInputPrincipal,
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "SelecaoQuestoesFCD/recuperarListagem",
					payload: {
						filtroListado: this.filtroAtivo,
						codsDisciplinasRestricao: UtilBoot.getCodsDisciplinasRestricao(),
					},
					numMinimoCaracteres: (this.tiposDeFiltrosComMinimoCaracteres.includes(this.filtroAtivo) ? 3 : 0)
				}
			});
		}
		
		this.append('<div class="col-md-2">');
		this.addBotao({label: "<i class='fa fa-filter'></i> " + this.getMsg("MSG_VH_SQ_27"), onClick: () => this.adicionarFiltro(), css: "width: 100%"});
		this.append("</div>");
		this.setIdTarget(null);
		this.append('</div>');

		this.fecharFormulario();
		
		this.append('</div>');
		
		this.exibir();
		this.setIdTarget(null);

	}
	
	classeInputPrincipal = "col-sm-12 col-md-10";
	
	onFiltroSelecionado() {
	}
	
	getFiltroAtivoTO() {
		let codigos = this.getValor('codigosDoFiltro');
		
		if (codigos == null) {
			return null;
		}

		let descricaoCodigos = this.getTextoSelect("codigosDoFiltro");

		let filtroAtivoTO = {
			tipoFiltro: this.filtroAtivo,
			codigos: codigos,
			descricaoCodigos: descricaoCodigos
		}
		
		return filtroAtivoTO;
	}
	
	async adicionarFiltro() {

		let filtroAtivoTO = this.getFiltroAtivoTO();
		
		if (filtroAtivoTO == null) {
			await this.exibirAlerta({ msg: this.getMsg("MSG_VH_SQ_31") });
			return;
		}
		
		await this.exibirNovoFiltro([filtroAtivoTO]);
	}
	
	async exibirNovoFiltro(collectionFiltroAtivoTO) {
		
		if ($("button.filtro").length == 0) {
			this.setHtml("selecaoQuestao_filtrosAtivos", "");
		}
		
		for (const filtroAtivoTO of collectionFiltroAtivoTO) {
			
			if (this.isEmpty(filtroAtivoTO.codigos)) continue;
			
			for (let j = 0; j < filtroAtivoTO.codigos.length; j++) {

				const codigo = filtroAtivoTO.codigos[j];
				const descricaoDoFiltro = filtroAtivoTO.descricaoCodigos[j];

				if ($(`button.filtro[tipoFiltro="${filtroAtivoTO.tipoFiltro}"][codigo="${codigo}"]`).length == 0) {
					this.append("selecaoQuestao_filtrosAtivos", `
						<button 
							type="button" 
							tipoFiltro="${filtroAtivoTO.tipoFiltro}" 
							codigo="${codigo}" 
							descricaoDoFiltro="${descricaoDoFiltro}" 
							onclick="selecaoQuestoesVH.removerFiltro(this)"
							class="btn btn-default filtro"
							style="display: none; max-width: 100%; padding-left 5px; padding-right: 5px; direction: rtl"
							title="${this.getMsg("MSG_VH_SQ_32")}">
							
							<i class="fa fa-times" style="opacity:0.4"></i> ${descricaoDoFiltro}
						</button>
					`);
				}
			}
		}
		
		this.exibir();
		
		$("#selecaoQuestao_filtrosAtivos button").fadeIn(600);
		
		if (this.cfgs.onFiltroAlterado) {
			try {
				await this.cfgs.onFiltroAlterado();
			} catch (e) {
				this.logger.error(e);
			}
		}
	}
	
	async removerFiltro(button) {
		
		$(button).remove();
		
		if ($("button.filtro").length == 0) {
			this.setHtml("selecaoQuestao_filtrosAtivos", "<i>" + this.getMsg("MSG_VH_SQ_33") + ".</i>");
		}
		
		if (this.cfgs.onFiltroAlterado) {
			try {
				await this.cfgs.onFiltroAlterado();
			} catch (e) {
				this.logger.error(e);
			}
		}
	}

	getFiltrosSelecaoQuestoesTO() {
		
		if (!this.cfgs) return null;
		
		const filtrosSelecaoQuestoesTO = {
			filtrosAtivos: [],
			textoBusca: this.getValor("textoBusca"),
			tituloExibicaoFiltro: this.getMsg("MSG_VH_SQ_34") + " ",
			codsDisciplinasRestricao: UtilBoot.getCodsDisciplinasRestricao()
		}
		
		$("button.filtro").each(function() {
			filtrosSelecaoQuestoesTO.filtrosAtivos.push({
				tipoFiltro: $(this).attr("tipoFiltro"), 
				codigo: $(this).attr("codigo"), 
				descricaoDoFiltro: $(this).attr("descricaoDoFiltro")
			})
		})
		
		const codQuestaoBuscada = this.getCodQuestaoBuscado();
		
		if (codQuestaoBuscada != null) {
			filtrosSelecaoQuestoesTO.filtrosAtivos.push({
				tipoFiltro: "COD_QUESTAO", 
				codigo: codQuestaoBuscada, 
				descricaoDoFiltro: $("#codigoQuestao").attr("placeholder")
			})
		}
		
		let numFiltros = 0;
		
		for (const filtroAtivoTO of filtrosSelecaoQuestoesTO.filtrosAtivos) {
			if (numFiltros++ > 0) {
				filtrosSelecaoQuestoesTO.tituloExibicaoFiltro += ", ";
			}
			filtrosSelecaoQuestoesTO.tituloExibicaoFiltro += filtroAtivoTO.descricaoDoFiltro;
		}
		
		if (this.hasValue(filtrosSelecaoQuestoesTO.textoBusca)) {
			if (numFiltros++ > 0) {
				filtrosSelecaoQuestoesTO.tituloExibicaoFiltro += ", ";
			}
			filtrosSelecaoQuestoesTO.tituloExibicaoFiltro += "\"" + filtrosSelecaoQuestoesTO.textoBusca + "\"";
		}

		return filtrosSelecaoQuestoesTO;
	}
	
	getCodQuestaoBuscado() {
		const codQuestao = this.getValor("codigoQuestao");
		
		if (this.isEmpty(codQuestao) || isNaN(codQuestao)) {
			return null;
		}
		
		return codQuestao.trim();
	}
	
	async adicionarFiltrosSemUtilizarListagem(collectionFiltroAtivoTO) {
		collectionFiltroAtivoTO = await this.call("SelecaoQuestoesFCD/recuperarDescricaoDeFiltros", collectionFiltroAtivoTO);
		await this.exibirNovoFiltro(collectionFiltroAtivoTO);
	}
	
	limparCache() {
		this.cacheFiltros = {};
	}
}

const selecaoQuestoesVH = new SelecaoQuestoesVH();