class CadastroUsuarioVH extends AmaisVH {

	isExibirArquivados: boolean;
	codsEmpresasBusca: number[];
	idsPerfisBusca: number[];
	buscaTextual: string;
	numeroDaPagina: number;
	onAposExibicaoEdicaoCadastro: () => void;
	inicioPeriodoAtualizacaoBusca: string;
	fimPeriodoAtualizacaoBusca: string;
	inicioPeriodoCadastroBusca: string;
	fimPeriodoCadastroBusca: string;
	ordenacaoAvaliacoes: any;
	codUsuario: any;

	constructor() {
		super(CadastroUsuarioVH.name);
		this.addOperacaoParaHash("cals", this.listarUsuarios);
		this.addOperacaoParaHash("caed", this.editarUsuario);
		this.addOperacaoParaHash("caip", this.exibirTelaImportacaoPlanilha);
	}

	montagemConviteTO = null;
	htmlUltimaEdicaoConvite = null;
	envioConviteTO = null;
	recuperacaoAlunoTO = null;
	exibicaoListagemAlunosTO: any;

	exibirListagemComBusca() {
		this.isExibirArquivados = this.getValor("isExibirArquivados");
		this.codsEmpresasBusca = this.getValor("codsEmpresasBusca");
		this.idsPerfisBusca = this.getValor("idsPerfis");
		this.inicioPeriodoAtualizacaoBusca = this.getValor("inicioPeriodoAtualizacaoBusca");
		this.fimPeriodoAtualizacaoBusca = this.getValor("fimPeriodoAtualizacaoBusca");
		this.inicioPeriodoCadastroBusca = this.getValor("inicioPeriodoCadastroBusca");
		this.fimPeriodoCadastroBusca = this.getValor("fimPeriodoCadastroBusca");
		this.listarUsuarios(this.getValor("busca"));
	}

	async listarUsuarios(buscaTextual = null) {

		const filtrosListagemUsuariosTO = {
			buscaTextual: buscaTextual,
			idsPerfis: this.idsPerfisBusca,
			codsEmpresasBusca: this.codsEmpresasBusca,
			isExibirArquivados: this.isExibirArquivados,
			periodoCadastro: [this.inicioPeriodoCadastroBusca, this.fimPeriodoCadastroBusca],
			periodoAtualizacao: [this.inicioPeriodoAtualizacaoBusca, this.fimPeriodoAtualizacaoBusca]
		}

		UtilHash.registrarHistorico(this.listarUsuarios, buscaTextual);

		this.exibicaoListagemAlunosTO = await this.call("CadastroUsuarioFCD/listarUsuarios", filtrosListagemUsuariosTO);

		let isExibirSegmentos = this.exibicaoListagemAlunosTO.collectionSegmentosTO.length > 1 || (this.exibicaoListagemAlunosTO.collectionSegmentosTO.length == 1 && this.exibicaoListagemAlunosTO.collectionSegmentosTO[0].filhos != null);

		if ($("#resultado_busca_usuarios").length == 0) {

			this.limpar();

			this.setTitulo(this.getMsg("MSG_VH_CU_14"));

			this.setIdTarget("tituloSuperiorDireito");

			this.append("<div class='btn-group'>");

			if (UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_ALTERACAO)) {
				this.addBotao({ 
					id: "btnNovoUsuario",
					label: "<i class='fa fa-plus-circle'></i> " + this.getMsg("MSG_VH_CU_15"), 
					onClick: () => this.editarUsuario(), 
					classe: "btn-success btn-sm" 
				});
			}

			if (UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_IMPORTACAO)) {
				this.addBotao({ texto: "<i class='fa fa-table fa-lg'></i> " + this.getMsg("MSG_VH_CU_16"), onClick: () => this.exibirTelaImportacaoPlanilha(), classe: "btn-sm" });
			}

			this.addBotaoBusca("filtrosUsuarios", {classe: "btn-sm"});

			this.append("</div>");

			this.setIdTarget(null);

			const style = this.hasValue(buscaTextual) ? "" : "display: none;";		

			this.append(`<div id='filtrosUsuarios' style='${style}'>`);

			this.abrirAbaAccordion({ 
				titulo: this.getMsg("MSG_VH_001"), 
				aberta: true
			});

			this.addFormulario();

			const classe = "col-xs-12 col-md-6 col-lg-4";

			if (isExibirSegmentos) {
				await this.addSelect({
					collection: this.exibicaoListagemAlunosTO.collectionSegmentosTO,
					id: "codsEmpresasBusca", 
					dica: this.getMsg("MSG_VH_CU_18"), 
					multiplo: true, 
					valor: this.codsEmpresasBusca, 
					classe,
					onChange: async () => await this.exibirListagemComBusca()
				});
			}

			if (this.exibicaoListagemAlunosTO.collectionPerfis.length > 1) {
				await this.addSelect({ 
					collection: this.exibicaoListagemAlunosTO.collectionPerfis,
					id: "idsPerfis", 
					dica: this.getMsg("MSG_VH_CU_19"), 
					multiplo: true, 
					valor: this.idsPerfisBusca, 
					classe, 
					onChange: async () => await this.exibirListagemComBusca()
				});
			}

			this.addCampoTexto({ 
				dica: this.getMsg("MSG_VH_002"), 
				id: "busca", 
				valor: buscaTextual, 
				classe, 
				prefixo: "<i class='fa fa-search'></i> ", 
				onEnter: async () => await this.exibirListagemComBusca ()
			});

			this.addCamposPeriodo({
				idInicio: "inicioPeriodoCadastroBusca",
				idFim: "fimPeriodoCadastroBusca",
				classe, 
				valorInicio: this.inicioPeriodoCadastroBusca,
				valorFim: this.fimPeriodoCadastroBusca,
				dica: this.getMsg("FP_FRONT_CadastroUsuarioVH_049"),
				onChange: async () => await this.exibirListagemComBusca()
			});

			this.addCamposPeriodo({
				idInicio: "inicioPeriodoAtualizacaoBusca",
				idFim: "fimPeriodoAtualizacaoBusca",
				classe, 
				valorInicio: this.inicioPeriodoAtualizacaoBusca,
				valorFim: this.fimPeriodoAtualizacaoBusca,
				dica: this.getMsg("FP_FRONT_CadastroUsuarioVH_050"),
				onChange: async () => await this.exibirListagemComBusca()
			});

			this.addCheckbox({ 
				label: this.getMsg("MSG_VH_CU_21"), 
				id: "isExibirArquivados", 
				valor: this.isExibirArquivados, 
				classe, 
				onChange: async () => await this.exibirListagemComBusca()
			});

			this.fecharFormulario();
			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();
			this.append("</div>");

			this.append("<div class='row'><div class='col-md-12' id='resultado_busca_usuarios'></div></div>");

			this.exibir();
		}

		$("#resultado_busca_usuarios").html("");
		this.setIdTarget("resultado_busca_usuarios");

		const colunasTabela: ColunaAddTabela[] = [];
		colunasTabela.push({ 
			titulo: this.getMsg("MSG_VH_CU_22"), 
			prop: (to) => {
				return to.nome + (to.isArquivado ? `<br><span class='label label-default'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_002")}</span>` : "");
			}, 
			classe: 'descricao', 
			nomeProp: 'nome' 
		});
		if (isExibirSegmentos) {
			colunasTabela.push({ titulo: this.getMsg("MSG_VH_CU_18"), prop: "nomeSegmento"});
		}
		colunasTabela.push({ titulo: this.getMsg("MSG_VH_CU_23"), prop: "perfil"});
		colunasTabela.push({ titulo: this.getMsg("MSG_VH_CU_24"), prop: "login" });
		colunasTabela.push({ titulo: this.getMsg("MSG_VH_CU_25"), prop: "email" });
		colunasTabela.push({ titulo: this.getMsg("MSG_VH_CU_26"), prop: "cpf" });
		colunasTabela.push({ titulo: this.getCfg("LABEL_MATRICULA"), prop: "matricula" });
		colunasTabela.push({ titulo: this.getMsg("MSG_VH_CU_28"), prop: "dataCadastro", formato: "DD/MM/YY HH:mm" });
		colunasTabela.push({ titulo: this.getMsg("MSG_VH_CU_29"), prop: "planoVigente" });

		const cfgs: CfgsAddTabela<any> = {
			collection: this.exibicaoListagemAlunosTO.collectionListagemAlunoTO,
			colunas: colunasTabela,
			id: "listagemAlunos",
			propId: "codUsuario",
			selecao: (usuario) => {
				if (usuario.isArquivado) return false;

				if(!this.exibicaoListagemAlunosTO.codsEmpresasUsuario || this.isEmpty(this.exibicaoListagemAlunosTO.codsEmpresasUsuario)) return true;

				return this.exibicaoListagemAlunosTO.codsEmpresasUsuario.indexOf(usuario.codEmpresa) !== -1;
			},
			onEdicao: this.editarUsuario,
			paginacao: {
				paginacaoTO: this.exibicaoListagemAlunosTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {

					const filtrosListagemUsuariosTO = {
						paginacaoTO: paginacaoTO,
						buscaTextual: this.buscaTextual,
						idsPerfis: this.idsPerfisBusca,
						codsEmpresasBusca: this.codsEmpresasBusca,
						isExibirArquivados: this.isExibirArquivados,
						periodoCadastro: [this.inicioPeriodoCadastroBusca, this.fimPeriodoCadastroBusca],
						periodoAtualizacao: [this.inicioPeriodoAtualizacaoBusca, this.fimPeriodoAtualizacaoBusca]
					}

					this.exibicaoListagemAlunosTO = await this.call("CadastroUsuarioFCD/listarUsuarios", filtrosListagemUsuariosTO);

					return this.exibicaoListagemAlunosTO.collectionListagemAlunoTO;
				}
			},
			paginaAtiva: this.numeroDaPagina,
			ordenar: false,
		}

		await this.addTabela(cfgs);

		this.addBotao({
			id: "btn_anular_provas",
			label: this.getMsg("MSG_VH_CU_185"),
			classe: "btn-primary",
			css: "float:right;",
			onClick: () => {
				let codsUsuarios = [];
				const $elementoHtml = $(this.getElementoHtml("listagemAlunos"));
				if($elementoHtml.find("td:first-child :checkbox").length > 0) {
					$elementoHtml.find("td:first-child :checkbox:checked").each(function () {
						codsUsuarios.push(+$(this).attr("codUsuario"));
					});
				}
				else {
					this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_186") });
					return;
				}
				if (this.isEmpty(codsUsuarios)) {
					this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_187") });
					return;
				}
				this.exibirAlerta({
					msg: this.getMsg("MSG_VH_CU_188"),
					botoes: [{
						label: this.getMsg("MSG_VH_CU_189"),
						classe: "btn-danger",
						onClick: async () => {
							await this.call("CadastroUsuarioFCD/excluir", codsUsuarios);
							await this.listarUsuarios();
						}
					}]
				});
			}
		});

		this.exibir();

		this.setIdTarget(null);

		this.buscaTextual = buscaTextual;
	}

	exibirDetalhesEnvioEmail(){
		const el = $("#detalhes-envio-email");
		const btn = $("#btn-detalhes-envio");

		if (el.is(":visible")) {
			btn.html(this.getMsg("FP_FRONT_CadastroUsuarioVH_070"));
			this.fadeOut("#detalhes-envio-email", 200);
			return;
		}
		btn.html(`${this.getMsg("FP_FRONT_CadastroUsuarioVH_071")} <i class='fa fa-chevron-up'></i>`);
		this.fadeIn("#detalhes-envio-email", 200);
	}

	async editarUsuario(codUsuario: number = null) {

		UtilHash.registrarHistorico(this.editarUsuario, codUsuario);

		const recuperacaoAlunoTO = await this.call("CadastroUsuarioFCD/recuperar", codUsuario);
	
		let podeCriar = this.hasValue(recuperacaoAlunoTO.collectionPerfisVisiveis);
		let podeEditar = !recuperacaoAlunoTO.isCriadoViaIntegracao && UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_ALTERACAO) && podeCriar;

		if(podeEditar === true && recuperacaoAlunoTO.podeEditar === false) podeEditar = false;

		this.limpar();

		this.setTitulo("<i class='fa fa-user'></i> " + (recuperacaoAlunoTO.nome || this.getMsg("MSG_VH_CU_31")))

		if (recuperacaoAlunoTO.isExcluido) {
			this.append(" <span class='label label-default'>" + this.getMsg("MSG_VH_CU_32") + "</span> ");
		}

		this.setMetodoSubmit(() => this.salvar());
		this.addInputHidden("aluno_codUsuario", recuperacaoAlunoTO.codUsuario);

		this.addSubtitulo(this.getMsg("MSG_VH_CU_33"));

		this.addFormulario();

		this.addCampoTexto({
			id: "aluno_nome",
			label: this.getMsg("MSG_VH_CU_34"),
			valor: recuperacaoAlunoTO.nome,
			habilitado: podeEditar,
			obrigatorio: true
		})

		await this.addSelect({
			id: "aluno_idPerfil",
			collection: recuperacaoAlunoTO.collectionPerfisVisiveis,
			label: this.getMsg("MSG_VH_CU_35"),
			habilitado: podeEditar && podeCriar,
			visivel: podeCriar,
			obrigatorio: true,
			onChange: async () => {
				if (this.isConteudista(false, this.getValor("aluno_idPerfil"))) {
					await this.show("aluno_curriculo", "aluno_graduacao");
				} else {
					await this.hide("aluno_curriculo", "aluno_graduacao");
				}
			}
		})

		this.addCampoTexto({
			id: "aluno_email",
			label: this.getMsg("MSG_VH_CU_36"),
			valor: recuperacaoAlunoTO.email,
			habilitado: podeEditar
		})

		await this.addSelect({
			collection: recuperacaoAlunoTO.collectionSegmentosTO,
			id: "codEmpresa",
			label: this.getMsg("MSG_VH_CU_37"),
			valor: recuperacaoAlunoTO.codEmpresa,
			obrigatorio: true,
			habilitado: podeEditar,
			visivel: (recuperacaoAlunoTO.collectionSegmentosTO.length > 1 || (recuperacaoAlunoTO.collectionSegmentosTO.length == 1 && recuperacaoAlunoTO.collectionSegmentosTO[0].filhos != null)),
		});

		if (!recuperacaoAlunoTO.codUsuario) {
			this.addCheckbox({ id: "aluno_enviarBoasVindas", label: this.getMsg("MSG_VH_CU_38"), valor: true })
		}

		this.addSubtitulo(this.getMsg("MSG_VH_CU_39"));

		this.addCampoTexto({
			id: "aluno_login",
			label: this.getMsg("MSG_VH_CU_40"),
			valor: recuperacaoAlunoTO.login,
			habilitado: podeEditar && podeCriar,
			obrigatorio: true
		})

		if (podeEditar && this.isSecretaria()) {

			if (recuperacaoAlunoTO.codUsuario == null) {
				this.addRadioGroup({
					id: "aluno_definicaoSenha",
					label: this.getMsg("MSG_VH_CU_41"),
					valor: 2,
					collection: [{ 
						id: 1, descricao: this.getMsg("MSG_VH_CU_42") 
					}, { 
						id: 2, descricao: this.getMsg("MSG_VH_CU_43"), dica: this.getMsg("MSG_VH_CU_44") 
					}],
					onChange: async () => await this.exibirCampoSenha(),
				})
			}

			this.addCampoTexto({
				id: "aluno_senha",
				idComponente: "aluno_componenteSenha",
				label: recuperacaoAlunoTO.codUsuario == null ? this.getMsg("MSG_VH_CU_45") : this.getMsg("MSG_VH_CU_46"),
				tipo: "SENHA",
				isVisivel: recuperacaoAlunoTO.codUsuario != null,
				classe: "col-md-3",
				html: "autocomplete='new-password'",
				validacaoSenha: true
			})

			this.addCampoTexto({
				id: "aluno_senha_confirmacao",
				idComponente: "aluno_componenteSenhaConfirmacao",
				label: this.getMsg("MSG_VH_CU_47"),
				tipo: "SENHA",
				isVisivel: recuperacaoAlunoTO.codUsuario != null,
				classe: "col-md-3"
			})
		}

		this.addEspacamentoHorizontal("1px");

		this.addCampoTexto({
			id: "aluno_inicioVigencia",
			label: this.getMsg("MSG_VH_CU_48"),
			valor: recuperacaoAlunoTO.inicioVigencia,
			habilitado: podeEditar,
			tipo: "DATA",
		})

		this.addCampoTexto({
			id: "aluno_fimVigencia",
			label: this.getMsg("MSG_VH_CU_49"),
			valor: recuperacaoAlunoTO.fimVigencia,
			habilitado: podeEditar,
			tipo: "DATA",
		})

		this.addSubtitulo(this.getMsg("MSG_VH_CU_184"));
		
		this.addCampoExibicaoComUploadImg({
			label: this.getMsg("FP_FRONT_MeusDadosVH_014"),
			urlImg: recuperacaoAlunoTO.docIdTO?.urlDoc, 
			classe: "col-md-6",
			onClickUpload: async () => {
				const isDocAnexado = await meusDadosVH.uploadDocIdentidade(recuperacaoAlunoTO.codUsuario);
				if (isDocAnexado === true) {
					document.location.reload();
				}
			},
			onClickRemocao: async () => {
				await meusDadosVH.excluirDocIdentificacao(recuperacaoAlunoTO.codUsuario);
				this.editarUsuario(recuperacaoAlunoTO.codUsuario);
			}, 
		});
		
		this.addCampoExibicaoComUploadImg({
			label: this.getMsg("FP_FRONT_CadastroUsuarioVH_003"),
			urlImg: recuperacaoAlunoTO.caminhoArquivoImgPerfil,
			classe: "col-md-6",
			onClickUpload: async () => {
				await this.uploadImgPerfil(recuperacaoAlunoTO.codUsuario);
				this.editarUsuario(recuperacaoAlunoTO.codUsuario);
			},
			onClickRemocao: async () => {
				await this.excluirImgPerfil(recuperacaoAlunoTO.codUsuario);
				this.editarUsuario(recuperacaoAlunoTO.codUsuario);
			},
		});

		this.addEspacamentoHorizontal("1px");

		this.addCampoTexto({
			id: "aluno_cpf",
			label: this.getLabelCpf(recuperacaoAlunoTO.docIdTO),
			valor: recuperacaoAlunoTO.cpf,
			tipo: "CPF",
			habilitado: podeEditar && recuperacaoAlunoTO.docIdTO?.isCPFIgualOcr !== true,
		});

		this.addCampoTexto({
			id: "aluno_rg",
			label: this.getMsg("MSG_VH_CU_51"),
			valor: recuperacaoAlunoTO.rg,
			habilitado: podeEditar
		});

		this.addCampoTexto({
			id: "aluno_matricula",
			label: this.getCfg("LABEL_MATRICULA"),
			valor: recuperacaoAlunoTO.matricula,
			habilitado: podeEditar,
		})

		this.addCampoTexto({
			id: "aluno_nomeMae",
			label: this.getMsg("MSG_VH_CU_53"),
			valor: recuperacaoAlunoTO.nomeMae,
			habilitado: podeEditar,
		})

		this.addCampoTexto({
			id: "aluno_apelido",
			label: this.getMsg("MSG_VH_CU_54"),
			valor: recuperacaoAlunoTO.apelido,
			habilitado: podeEditar,
			ajuda: this.getMsg("MSG_VH_CU_55"),
			visivel: false
		})

		await this.addSelect({
			collection: [{ id: 0, descricao: this.getMsg("FP_FRONT_CadastroUsuarioVH_004") }, { id: 1, descricao: this.getMsg("FP_FRONT_CadastroUsuarioVH_005") }],
			id: "aluno_sexo",
			label: this.getMsg("MSG_VH_CU_56"),
			valor: (recuperacaoAlunoTO.sexo ? (recuperacaoAlunoTO.sexo ? 1 : 0) : null),
			habilitado: podeEditar,
			visivel: false
		});

		this.addCampoTexto({
			id: "aluno_cep",
			label: this.getMsg("MSG_VH_CU_57"),
			valor: recuperacaoAlunoTO.cep,
			tipo: "CEP",
			habilitado: podeEditar
		});

		this.addCampoTexto({
			id: "aluno_telefone",
			label: this.getMsg("MSG_VH_CU_58"),
			valor: recuperacaoAlunoTO.telefone,
			tipo: "TELEFONE",
			habilitado: podeEditar
		});

		this.addCampoTexto({
			id: "aluno_dataNascimento",
			label: this.getMsg("MSG_VH_CU_59"),
			tipo: "DATA",
			valor: recuperacaoAlunoTO.dataNascimento,
			habilitado: podeEditar
		});

		this.addCampoTexto({
			id: "aluno_endereco",
			label: this.getMsg("MSG_VH_CU_60"),
			valor: recuperacaoAlunoTO.endereco,
			habilitado: podeEditar
		});

		this.addCampoTexto({
			id: "aluno_cidade",
			label: this.getMsg("MSG_VH_CU_61"),
			valor: recuperacaoAlunoTO.cidade,
			habilitado: podeEditar
		});

		await this.addSelect({
			collection: this.getCollectionUFs(),
			id: "aluno_uf",
			label: this.getMsg("MSG_VH_CU_62"),
			valor: recuperacaoAlunoTO.uf,
			habilitado: podeEditar
		});

		if (this.isCfgHabilitada("PERMITIR_TROCA_IDIOMA_USUARIO")) {
			await this.addSelect({
				collection: this.getCollectionLocales(),
				id: "aluno_locale",
				label: this.getMsg("MSG_VH_CU_190"),
				valor: recuperacaoAlunoTO.locale,
				habilitado: podeEditar
			});
		}

		this.addCampoTexto({
			id: "aluno_graduacao",
			label: this.getMsg("MSG_VH_CU_63"),
			valor: recuperacaoAlunoTO.graduacao,
			habilitado: podeEditar,
			visivel: this.isConteudista(false, recuperacaoAlunoTO.idPerfil)
		});

		this.addEditorHTML({
			id: "aluno_curriculo",
			label: this.getMsg("MSG_VH_CU_64"),
			valor: recuperacaoAlunoTO.curriculo,
			habilitado: podeEditar,
			visivel: this.isConteudista(false, recuperacaoAlunoTO.idPerfil)
		});

		this.addCampoTexto({
			id: "aluno_codigoSistemaOrigem",
			label: this.getMsg("MSG_VH_CU_65"),
			valor: recuperacaoAlunoTO.codigoSistemaOrigem,
			habilitado: (podeEditar && podeCriar && this.isSecretaria()),
			classe: "fp-small-business-hidden"
		});

		this.addRadioGroup({
			collection: recuperacaoAlunoTO.listaTiposAcessibilidade,
			id: "tipoAcessibilidade",
			label: this.getMsg("FP_FRONT_CadastroUsuarioVH_074"),
			valor: recuperacaoAlunoTO.tipoAcessibilidade,
			classe: "col-md-6",
		});

		this.fecharFormulario();

		this.append("<div class='row col-md-12'>");

		this.addBotao({ label: "&laquo; " + this.getMsg("MSG_VH_CU_66"), onClick: () => this.listarUsuarios(), css: "float: left", classe: "btn-sm" });

		const botoes = [];

		if (recuperacaoAlunoTO.codUsuario && UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_ACESSOS)) {
			botoes.push(this.addBotao({
				label: this.getMsg("MSG_VH_CU_67"),
				onClick: async () => await loginVH.exibirSessoesWebDoUsuario(recuperacaoAlunoTO.codUsuario),
				classe: "btn-sm",
				retornarHtml: true
			}));
		}

		if (recuperacaoAlunoTO.codUsuario != null && UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_ARQUIVAMENTO) && recuperacaoAlunoTO.podeEditar === true) {
			botoes.push(
				this.addBotao({
					label: (recuperacaoAlunoTO.isExcluido ? this.getMsg("MSG_VH_CU_68") : this.getMsg("MSG_VH_CU_69")),
					onClick: async () => {
						if (recuperacaoAlunoTO.isExcluido) {
							await this.exibirAlerta({
								msg: this.getMsg("MSG_VH_CU_70"),
								botoes: [{
									label: this.getMsg("FP_FRONT_CadastroUsuarioVH_063"),
									classe: "btn-primary",
									onClick: async () => {
										await this.call("CadastroUsuarioFCD/restaurar", recuperacaoAlunoTO.codUsuario);
										await cadastroUsuarioVH.editarUsuario(recuperacaoAlunoTO.codUsuario);
									}
								}],
							});
						} else {
							await this.exibirAlerta({
								msg: this.getMsg("MSG_VH_CU_71"),
								botoes: [{
									label: this.getMsg("MSG_VH_007"),
									classe: "btn-danger",
									onClick: async () => {
										await this.call("CadastroUsuarioFCD/excluir", [recuperacaoAlunoTO.codUsuario]);
										await cadastroUsuarioVH.editarUsuario(recuperacaoAlunoTO.codUsuario);
									}
								}],
							});
						}
					},
					retornarHtml: true,
					classe: "btn btn-default btn-sm " + (recuperacaoAlunoTO.isExcluido ? "" : "btn-danger")
				})
			);
		}

		if (podeEditar) {
			botoes.push(this.addBotao({ 
				id: "btnSalvarUsuario", 
				label: this.getMsg("MSG_VH_CU_72") + " &raquo;", 
				onClick: () => {
					this.salvar();
				}, 
				retornarHtml: true, 
				classe: "btn-primary btn-sm" 
			}));
		}

		// this.append("<div class='btn-group-vertical col-xs-12 visible-xs' style='padding-top: 10px; padding-right: 0px; padding-left: 0px;'>");
		// this.append(botoes.join(""));
		// this.append("</div>");

		this.append("<div class='btn-group pull-right'>");
		this.append(botoes.join(""));
		this.append("</div>");

		this.append("</div>");

		this.exibirSubsecaoInfosComplementares(recuperacaoAlunoTO);

		if (recuperacaoAlunoTO.codUsuario) {
			this.addSubtitulo({
				texto: this.getMsg("FP_FRONT_CadastroUsuarioVH_006"),
				htmlDireita: UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_AUDITORIA) ? `<a class='btn btn-sm btn-default' onclick='loginVH.exibirAcessosDoUsuario(${recuperacaoAlunoTO.codUsuario})'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_010")}</a>` : null
			});

			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroUsuarioVH_007"), valor: UtilData.toDDMMYYYYHHMM(recuperacaoAlunoTO.dataCadastro)});

			if (recuperacaoAlunoTO.usuarioCriadorOpcaoListaTO) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroUsuarioVH_008"), valor: recuperacaoAlunoTO.usuarioCriadorOpcaoListaTO.nome + " (" + recuperacaoAlunoTO.usuarioCriadorOpcaoListaTO.descricao + ")"});
			}

			if (recuperacaoAlunoTO.dataAtualizacao != recuperacaoAlunoTO.dataCadastro) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroUsuarioVH_009"), valor: UtilData.toDDMMYYYYHHMM(recuperacaoAlunoTO.dataAtualizacao)});
			}

			if (recuperacaoAlunoTO.isCriadoViaIntegracao && recuperacaoAlunoTO.usuarioCriadorOpcaoListaTO) {
				this.append("<div class='col-md-12'><p class='text-danger'><strong>Somente " + recuperacaoAlunoTO.usuarioCriadorOpcaoListaTO.nome + " pode alterar este cadastro.</strong></p></div>");
			}
		}

		if (recuperacaoAlunoTO.codUsuario != null && UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_TURMA_VISUALIZACAO)) {

			const podeAlterarTurmas = UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_TURMA_ALTERACAO);
			const hashEdicaoTurma = UtilHash.getHash(cadastroTurmaVH.exibirAbasTurma, 0)
			const exibirNomeTurma = (listagemTurmasDoAlunoTO) => {
				let h = [];
				if (podeAlterarTurmas) {
					h.push(`
						<a href="${hashEdicaoTurma + "/" + listagemTurmasDoAlunoTO.codTurma}">
							${listagemTurmasDoAlunoTO.nome}
						</a>
					`)
				} else {
					h.push(listagemTurmasDoAlunoTO.nome);
				}
				if (this.getIsAmbienteSegmentado()) {
					h.push(`
						<br>
						<small>${listagemTurmasDoAlunoTO.nomeSegmento}</small>
					`);
				}
				return h.join("");
			}

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: "", prop: exibirNomeTurma, classe: "descricao"});
			colunas.push({titulo: this.getMsg("MSG_VH_CU_81"), prop: "inicioVigencia", formato: "DD/MM/YY"});
			colunas.push({titulo: this.getMsg("MSG_VH_CU_82"), prop: "fimVigencia", formato: "DD/MM/YY"});
			colunas.push({titulo: this.getMsg("MSG_VH_CU_83") + " " + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase(), prop: "numAlunos"});

			await this.addTabela({
				collection: recuperacaoAlunoTO.collectionListagemTurmasDoAlunoTO,
				titulo: this.getCfg("LABEL_LISTAGEM_TURMAS"),
				propId: "codTurma",
				colunas: colunas,
				onNovo: podeAlterarTurmas ? () => {
					this.abrirPopUpTurmaAluno();
				} : null,
				onExclusao: podeAlterarTurmas ? async (botao, codTurma) => {
					await cadastroTurmaVH.retirarAluno(codUsuario, codTurma, async () => {
						await this.editarUsuario(codUsuario);
					});
				} : null,
				labelNovo: this.getCfg("LABEL_TURMA")
			})
		}

		if (recuperacaoAlunoTO.codUsuario != null && UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_APLICACAO_VISUALIZACAO)) {

			const podeAlterarAplicacoes = UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_APLICACAO_ALTERACAO);

			this.append(`
				<div class="row">
					<div id="tabela_aplicacoes_prova" class="col-md-12">
					</div>
				</div>
			`);

			this.exibir();

			this.ordenacaoAvaliacoes = null;
			this.codUsuario = codUsuario;

			await this.exibirAplicacoesProva(recuperacaoAlunoTO, podeAlterarAplicacoes, codUsuario);
		}

		if (recuperacaoAlunoTO.codUsuario != null && UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_EMAIL_VISUALIZACAO)) {

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_066"), prop: (eTO) => `#${eTO.codMensagemTrocada}` });
			colunas.push({titulo: this.getMsg("MSG_VH_CU_105"), prop: "assunto"});
			colunas.push({titulo: this.getMsg("MSG_VH_CU_106"), prop: "dataEnvio", formato: "DD/MM/YY HH:mm" });

			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_072"), prop: (eTO) => {
					if (eTO.situacao) {
						return eTO.situacao.map(s => {
							if (s == 'ENTREGUE') {
								return `<span class='label label-success'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_067")}</span>`;
							}
							if (s == "DEVOLUCAO") {
								return `<span class='label label-warning'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_068")}</span>`;
							}
							if (s == "RECLAMACAO") {
								return `<span class='label label-danger'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_069")}</span>`;
							}
						}).join("<br>");
					}
				}
			});

			await this.addTabela({
				collection: recuperacaoAlunoTO.collectionMensagensOpcaoListaTO,
				titulo: this.getMsg("MSG_VH_CU_103"),
				propId: "codMensagemTrocada",
				msgListaVazia: this.getMsg("MSG_VH_CU_104"),
				colunas: colunas,
				onEdicao: async (codMensagemTrocada) => {

					const exibicaoMensagemTrocadaTO = await this.call("EmailAgendamentoFCD/recuperarExibicao", codMensagemTrocada);

					this.addPopup({
						width: "800px",
						// height: "500px",
						id: "email_enviado",
						titulo: this.getMsg("MSG_VH_CU_107"),
						botoes: [
							{
								label: this.getMsg("FP_FRONT_CadastroUsuarioVH_064"),
								onClick: async () => {
									this.fecharPopup("email_enviado");
								}
							},
							{
								label: this.getMsg("FP_FRONT_CadastroUsuarioVH_065"),
								classe: "btn-primary",
								onClick: async () => {
									await this.call("EmailAgendamentoFCD/reenviarEmailUsuario", codMensagemTrocada);
									document.location.reload();
								}
							}
						]
					});

					this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroUsuarioVH_066"), valor: exibicaoMensagemTrocadaTO.codMensagemTrocada });
					this.addCampoExibicao({ label: this.getMsg("MSG_VH_CU_108"), valor: UtilData.toDDMMYYYYHHMM(exibicaoMensagemTrocadaTO.dataEnvio) });
					this.addCampoExibicao({ label: this.getMsg("MSG_VH_CU_109"), valor: exibicaoMensagemTrocadaTO.nomeRemetente });
					this.addCampoExibicao({ label: this.getMsg("MSG_VH_CU_110"), valor: exibicaoMensagemTrocadaTO.nomeDestinatario ? exibicaoMensagemTrocadaTO.nomeDestinatario + " <" + exibicaoMensagemTrocadaTO.emailRemetente + ">" : exibicaoMensagemTrocadaTO.emailRemetente });
					this.addCampoExibicao({ label: this.getMsg("MSG_VH_CU_111"), valor: exibicaoMensagemTrocadaTO.pathAnexos ? "SIM" : "NÃO" });
					this.addCampoExibicao({ label: this.getMsg("MSG_VH_CU_112"), classe: "col-md-12", valor: exibicaoMensagemTrocadaTO.assunto });
					this.addCampoExibicao({ label: this.getMsg("MSG_VH_CU_113"), classe: "col-md-12", valor: exibicaoMensagemTrocadaTO.conteudo });

					if (exibicaoMensagemTrocadaTO.eventosExternos) {
						this.append("<div class='col-md-12'>")
						this.append(`<a id="btn-detalhes-envio" onclick='cadastroUsuarioVH.exibirDetalhesEnvioEmail()'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_070")} <i class='fa fa-chevron-down'></i></a>`);
						this.append("<div id='detalhes-envio-email' style='display: none; margin-top: 10px'>")
						const eventosExternos = JSON.parse(exibicaoMensagemTrocadaTO.eventosExternos);
						this.append(`<pre style="max-width: 100%">${JSON.stringify(eventosExternos, null, 4)}</pre>`);
						this.append("</div>")
						this.append("</div>")
					}

					this.exibirPopups();
				}
			});

			if (recuperacaoAlunoTO.detalhesCancelamentoEnvioEmail) {
				this.addTextoAlerta({ texto: `
					${this.getMsg("FP_FRONT_CadastroUsuarioVH_025", recuperacaoAlunoTO.email)}
					<strong>${recuperacaoAlunoTO.detalhesCancelamentoEnvioEmail}</strong>. 
				`});
			}
		}

		if (recuperacaoAlunoTO.codUsuario != null && UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_RESPONS_CORRECAO_VISUALIZACAO) && !recuperacaoAlunoTO.isAluno) {

			const podeAlterarResponsabilidades = UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_RESPONS_CORRECAO_ALTERACAO);

			const onAposAlteracaoClassificacoes = () => {
				this.onAposExibicaoEdicaoCadastro = () => {
					UtilWindow.scrollTo("#listagemClassificacoesResponsabilidadesCorrecao");
				}
				this.editarUsuario(recuperacaoAlunoTO.codUsuario);
			}

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("MSG_VH_CU_115"), prop: "nome", classe: "descricao"});

			await this.addTabela({
				collection: recuperacaoAlunoTO.collectionOpcaoListaTODisciplinasResponsaveis,
				id: "listagemClassificacoesResponsabilidadesCorrecao",
				titulo: this.getMsg("MSG_VH_CU_114"),
				propId: "id",
				colunas: colunas,
				labelNovo: "Classificação",
				onNovo: podeAlterarResponsabilidades ? async () => {

					this.addPopup({
						titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_026"),
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroUsuarioVH_027"), classe: "btn-primary", onClick: async () => {

								const salvamentoCorretoresDeDisciplinaTO = {
									codDisciplina: this.getValor("codClassificacao"),
									codUsuarioParaAdicionar: recuperacaoAlunoTO.codUsuario
								}

								await this.call("CorrecaoDiscursivasCfgFCD/salvarCorretoresDeDisciplina", salvamentoCorretoresDeDisciplinaTO);

								onAposAlteracaoClassificacoes();
							}
						}]
					});

					await this.addSelect({
						label: this.getMsg("FP_FRONT_CadastroUsuarioVH_028"),
						classe: "col-md-12",
						id: "codClassificacao",
						obrigatorio: true,
						loadOpcoesBack: {
							endpoint: "ListagemSelecaoFCD/listarDisciplinasTags",
							numMinimoCaracteres: 3,
						}
					});

					this.exibirPopups();

				} : null,
				onExclusao: podeAlterarResponsabilidades ? async (botao, idDisciplinaResponsavel) => {
					this.exibirAlerta({
						msg: this.getMsg("MSG_VH_CU_116"),
						botoes: [
							{
								label: this.getMsg("MSG_VH_004"),
								classe: "btn-primary",
								onClick: async () => {
									const codUsuario = await this.call("CadastroUsuarioFCD/excluirDisciplinaResponsavel", idDisciplinaResponsavel);
									onAposAlteracaoClassificacoes();
								}
							},
							{label: this.getMsg("MSG_VH_005"),}
						],
					});
				} : null
			});

			if (recuperacaoAlunoTO.collectionOpcaoListaTOTurmasResponsaveis) {
				const colunas: ColunaAddTabela[] = [];
				colunas.push({titulo: this.getCfg("LABEL_TURMA"), prop: "nome", classe: "descricao" });

				await this.addTabela({
					collection: recuperacaoAlunoTO.collectionOpcaoListaTOTurmasResponsaveis,
					titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_029", this.getCfg("LABEL_LISTAGEM_TURMAS")),
					propId: "id",
					colunas: colunas
				});
			}
		}

		this.exibir({ isAffixIdentificacaoConteudo: true });

		if (this.onAposExibicaoEdicaoCadastro) this.onAposExibicaoEdicaoCadastro();
		this.onAposExibicaoEdicaoCadastro = null;
	}

	private async exibirAplicacoesProva(recuperacaoAlunoTO, podeAlterarAplicacoes, codUsuario) {

		$("#tabela_aplicacoes_prova").html("");
		this.setIdTarget("tabela_aplicacoes_prova");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: cadastroUsuarioVH.montarTituloProva, classe: "descricao"});
		colunas.push({titulo: this.getMsg("MSG_VH_CU_90"), prop: cadastroUsuarioVH.montarColunaPodeComecar});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_014"), prop: cadastroUsuarioVH.montarColunaRespondidaEm});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_015"), prop: cadastroUsuarioVH.montarColunaNota});
		colunas.push({titulo: "", prop: cadastroUsuarioVH.montarColunaBotoes});

		await this.addTabela({
			ordenar: false,
			collection: recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO,
			titulo: this.getMsg("MSG_VH_CU_88"),
			colunas: colunas,
			propId: "codProvaFeita",
			msgListaVazia: this.getMsg("MSG_VH_CU_95"),
			labelNovo: this.getMsg("MSG_VH_CU_96"),
			onNovo: podeAlterarAplicacoes ? async () => {

				const realizarBusca = async () => {

					const listListagemAplicacoesDisponiveisAlunoTO = await this.call("CadastroUsuarioFCD/listarAplicacoesDisponiveis", recuperacaoAlunoTO.codUsuario, this.getValor("textoBuscaAplicacoes"));

					this.setHtml("listagemAplicacoes", "");

					this.setIdTarget("listagemAplicacoes");

					const colunas: ColunaAddTabela[] = [];
					colunas.push({titulo: "", prop: "tituloProva"});
					colunas.push({titulo: this.getMsg("MSG_VH_CU_99"), prop: cadastroUsuarioVH.montarColunaPodeComecar});
					colunas.push({titulo: "", prop: "<a class='link link-default' cod-agendamento='${codAgendamento}'><i class='fa fa-plus'></i></a>"});

					await this.addTabela({
						collection: listListagemAplicacoesDisponiveisAlunoTO,
						id: "listagem_aplicacoes_disponiveis",
						colunas: colunas,
						msgListaVazia: this.getMsg("MSG_VH_CU_102"),
					});

					this.exibir();

					$("#listagem_aplicacoes_disponiveis a[cod-agendamento]").click(async ({target}) => {

						const $botao = $(target).closest("a");

						const inclusaoAgendamentoUsuarioTO = {
							codAgendamento: $botao.attr("cod-agendamento"),
							listaInclusaoAgendamentoUsuarioTO: [{codUsuario: recuperacaoAlunoTO.codUsuario}]
						}

						await this.call("AgendamentoProvaFCD/incluirAgendamentoUsuario", inclusaoAgendamentoUsuarioTO);

						$botao.closest("tr").fadeOut();

						this.editarUsuario(recuperacaoAlunoTO.codUsuario);
					});
				}

				this.addPopup({
					width: "90%",
					id: "selecao_aplicacao",
					titulo: this.getMsg("MSG_VH_CU_97") + " " + this.getValor("aluno_nome"),
					botoes: [{label: "OK", css: "float: right"}]
				});

				this.addCampoTexto({
					dica: this.getMsg("MSG_VH_CU_120"),
					css: "width: 100%; float: left; margin-top: 18px;",
					id: "textoBuscaAplicacoes",
					onEnter: realizarBusca
				});

				this.append("<div id='listagemAplicacoes'> </div>");

				this.exibirPopups();

				realizarBusca();

			} : null,
			ordenacao: this.ordenacaoAvaliacoes,
			onOrdenacaoColuna: async (coluna, isOrdenacaoDecrescente) => {

				const propColuna = coluna[1];

				function compararPorAtributoData(lista, atributo, isOrdenacaoDecrescente) {

					const nulos = [];
					const naoNulos = [];

					for (const item of lista) {
						if (item[atributo] != null) {
							naoNulos.push(item);
						} else {
							nulos.push(item);
						}
					}

					naoNulos.sort((a, b) => {
						const valueA = Date.parse(a[atributo]);
						const valueB = Date.parse(b[atributo]);

						if (isOrdenacaoDecrescente) return valueB - valueA;
						return valueA - valueB;
					});

					for (const item of nulos) {
						naoNulos.push(item);
					}

					return naoNulos;
				}

				function compararPorAtributoNumero(lista, atributo, isOrdenacaoDecrescente) {

					const nulos = [];
					const naoNulos = [];

					for (const item of lista) {
						if (item[atributo] != null) {
							naoNulos.push(item);
						} else {
							nulos.push(item);
						}
					}

					naoNulos.sort((a, b) => {
						const valueA = a[atributo];
						const valueB = b[atributo];

						if (isOrdenacaoDecrescente) return valueB - valueA;
						return valueA - valueB;
					});

					for (const item of nulos) {
						naoNulos.push(item);
					}

					return naoNulos;
				}

				if (coluna[0] == this.getMsg("FP_FRONT_CadastroUsuarioVH_014")) {
					recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO = compararPorAtributoData(
						recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO,
						"inicioProvaFeita",
						isOrdenacaoDecrescente
					);
				}
				else if (coluna[0] == this.getMsg("MSG_VH_CU_90")) {
					recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO = compararPorAtributoData(
						recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO,
						"inicioAplicacao",
						isOrdenacaoDecrescente
					);
				}
				else if (coluna[0] == this.getMsg("FP_FRONT_CadastroUsuarioVH_015")) {
					recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO = compararPorAtributoNumero(
						recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO,
						"nota",
						isOrdenacaoDecrescente
					);
				}
				else {

					recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO =
						recuperacaoAlunoTO.collectionListagemAplicacoesDoAlunoTO.sort((a, b) =>
						isOrdenacaoDecrescente
							? b.tituloProva.localeCompare(a.tituloProva)
							: a.tituloProva.localeCompare(b.tituloProva)
					);
				}

				if (propColuna.name !== "montarColunaBotoes"){

					this.ordenacaoAvaliacoes = [[propColuna, isOrdenacaoDecrescente ? 1 : 0]];

					await this.exibirAplicacoesProva(recuperacaoAlunoTO, podeAlterarAplicacoes, codUsuario);

				}
			}
		});

		this.exibir();
	}

	montarTituloProva(listagemAplicacoesDoAlunoTO) {

		const h = [];

		if (listagemAplicacoesDoAlunoTO.nomeAvaliacao) {
			if (UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_AVALIACAO)) {
				h.push("<a href='" + UtilHash.getHash(cadastroAvaliacaoVH.editarAvaliacao, listagemAplicacoesDoAlunoTO.codAvaliacao) + "'>" + listagemAplicacoesDoAlunoTO.nomeAvaliacao + "</a>");
			} else {
				h.push(listagemAplicacoesDoAlunoTO.nomeAvaliacao);
			}
		}

		if (listagemAplicacoesDoAlunoTO.tituloProva) {
			if (UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_PROVA_CORRECAO) && listagemAplicacoesDoAlunoTO.codProvaFeita) {
				h.push("<a href='" + UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, listagemAplicacoesDoAlunoTO.codProvaFeita) + "'>" + listagemAplicacoesDoAlunoTO.tituloProva + "</a>");
			} else {
				h.push(listagemAplicacoesDoAlunoTO.tituloProva);
			}
		}

		if (listagemAplicacoesDoAlunoTO.nomeTipoAplicacao) {
			h.push(listagemAplicacoesDoAlunoTO.nomeTipoAplicacao);
		}

		if (listagemAplicacoesDoAlunoTO.nomeTurmas) {
			h.push(listagemAplicacoesDoAlunoTO.nomeTurmas.join("<br>"));
		}

		return h.join("<br>");
	}

	montarColunaPodeComecar(listagemAplicacoesDoAlunoTO) {
		if (listagemAplicacoesDoAlunoTO.inicioAplicacao != null) {
			if (listagemAplicacoesDoAlunoTO.fimAplicacao == null) {
				return this.getMsg("MSG_VH_CU_85") + " " + UtilData.toDDMMYYYYHHMM(listagemAplicacoesDoAlunoTO.inicioAplicacao);
			} else {
				return this.getMsg("MSG_VH_CU_86") + " " + UtilData.toDDMMYYYYHHMM(listagemAplicacoesDoAlunoTO.inicioAplicacao) + " " + this.getMsg("MSG_VH_CU_87") + " "
					+ UtilData.toDDMMYYYYHHMM(listagemAplicacoesDoAlunoTO.fimAplicacao);
			}
		}
	}

	montarColunaRespondidaEm(listagemAplicacoesDoAlunoTO) {
		if (listagemAplicacoesDoAlunoTO.inicioProvaFeita != null) {
			if (listagemAplicacoesDoAlunoTO.fimProvaFeita == null || listagemAplicacoesDoAlunoTO.inicioProvaFeita == listagemAplicacoesDoAlunoTO.fimProvaFeita) {
				return UtilData.toDDMMYYYYHHMM(listagemAplicacoesDoAlunoTO.inicioProvaFeita);
			} else {
				return this.getMsg("FP_FRONT_CadastroUsuarioVH_011", UtilData.toDDMMYYYYHHMM(listagemAplicacoesDoAlunoTO.inicioProvaFeita), UtilData.toDDMMYYYYHHMM(listagemAplicacoesDoAlunoTO.fimProvaFeita));
			}
		}
	}

	montarColunaNota(listagemAplicacoesDoAlunoTO) {
		let h = "";
		if (listagemAplicacoesDoAlunoTO.nota != null) {
			if (listagemAplicacoesDoAlunoTO.notaMaxima == null) {
				h = UtilNumero.floatToString(listagemAplicacoesDoAlunoTO.nota);
			} else {
				h = UtilNumero.floatToString(listagemAplicacoesDoAlunoTO.nota) + " em " + UtilNumero.floatToString(listagemAplicacoesDoAlunoTO.notaMaxima);
			}
		} else if (listagemAplicacoesDoAlunoTO.observacoesAusencia) {
			h = listagemAplicacoesDoAlunoTO.observacoesAusencia;
		}
		if (listagemAplicacoesDoAlunoTO.isAnulada) {
			h += `<br/><span class='label label-danger'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_012")}</span>`;

		} else if (listagemAplicacoesDoAlunoTO.isPausada) {
			h += `<br/><span class='label label-default'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_013")}</span>`;
		}
		return h;
	}

	montarColunaBotoes(listagemAplicacoesDoAlunoTO) {

		const codUsuario = this.codUsuario;

		const podeVisualizarCorrecao = UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_PROVA_CORRECAO);
		const podeAlterarAplicacoes = UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_APLICACAO_ALTERACAO);
		const podeAnularProvasRespondidas = UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_APLICACAO_ANULACAO);

		let {codAgendamentoUsuario, numTentativas, isAnulada, isPausada, codProvaFeita, tipoAplicacao, nota, inicioProvaFeita, fimProvaFeita} = listagemAplicacoesDoAlunoTO;
		const h = [];

		if (!codAgendamentoUsuario) return "";

		if (podeVisualizarCorrecao && (numTentativas > 1 || (!codProvaFeita && numTentativas == 1))) {
			h.push(`<a class='btn btn-default btn-sm' onclick='relatorioProvasVH.exibirTentativas(${codAgendamentoUsuario})'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_016", numTentativas)}</a>`);
		}

		if (this.isAdministrador() && podeAlterarAplicacoes && !isAnulada && codProvaFeita != null && (tipoAplicacao == TipoAplicacao.P || tipoAplicacao == TipoAplicacao.E)) {
			h.push(this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroUsuarioVH_017"),
				dica: this.getMsg("FP_FRONT_CadastroUsuarioVH_018"),
				classe: "btn-sm",
				onClick: () => {
					gestaoPFVH.anularProvaRespondida(codProvaFeita, () => this.editarUsuario(codUsuario));
				},
				retornarHtml: true
			}));
		}

		if (this.isAdministrador() && podeAlterarAplicacoes && nota != null && (tipoAplicacao == TipoAplicacao.P || tipoAplicacao == TipoAplicacao.E)) {
			h.push(this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroUsuarioVH_019"),
				dica: this.getMsg("FP_FRONT_CadastroUsuarioVH_020"),
				classe: "btn-sm",
				onClick: () => {
					gestaoPFVH.reabrirProvaRespondida(codProvaFeita, () => this.editarUsuario(codUsuario));
				},
				retornarHtml: true
			}));
		}

		if (this.isAdministrador() && podeAlterarAplicacoes && !isAnulada && nota == null && !isPausada && inicioProvaFeita != null && !fimProvaFeita && (tipoAplicacao == TipoAplicacao.P || tipoAplicacao == TipoAplicacao.E)) {
			h.push(this.addBotao({
				label: "<i class='fa fa-pause'></i>",
				dica: this.getMsg("FP_FRONT_CadastroUsuarioVH_021"),
				classe: "btn-sm",
				onClick: () => {
					gestaoPFVH.pausar(codProvaFeita, () => this.editarUsuario(codUsuario));
				},
				retornarHtml: true
			}));
		}

		if (podeAnularProvasRespondidas && isAnulada) {
			h.push(this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroUsuarioVH_022"),
				classe: "btn-sm",
				onClick: () => {
					gestaoPFVH.removerAnulacaoProvaRespondida(codProvaFeita, () => this.editarUsuario(codUsuario));
				},
				retornarHtml: true
			}));
		}

		if (podeAnularProvasRespondidas && codProvaFeita != null) {
			h.push(this.addBotao({
				label: "<i class='fa fa-trash'></i>",
				dica: this.getMsg("FP_FRONT_CadastroUsuarioVH_023"),
				classe: "btn-sm",
				onClick: () => {
					gestaoPFVH.arquivarProvaFeita(codProvaFeita, () => this.editarUsuario(codUsuario));
				},
				retornarHtml: true
			}));
		}

		if (podeAlterarAplicacoes) {
			h.push(`<a class='btn btn-default btn-sm' title='${this.getMsg("FP_FRONT_CadastroUsuarioVH_024")}' onclick='cadastroUsuarioVH.removerDaAplicacao(${codAgendamentoUsuario})'> <i class='fa fa-ban'></i></a>`);
		}

		return "<div class='btn-group pull-right'>" + h.join("") + "</div>";
	}

	removerDaAplicacao(codAgendamentoUsuario, onRemocao) {
		this.exibirAlerta({
			msg: this.getMsg("MSG_VH_CU_118"),
			botoes: [{
				label: `<i class='fa fa-ban'></i> ${this.getMsg("FP_FRONT_CadastroUsuarioVH_030")}`,
				classe: "btn-danger",
				onClick: async () => {

					await this.call("AgendamentoProvaFCD/removerAgendamentoUsuario", codAgendamentoUsuario);

					onRemocao ? onRemocao() : document.location.reload();
				}
			}]
		});
	}

	abrirPopUpTurmaAluno(valorBuscaTextual?) {

		const buscarTurmas = async () => {

			const exibicaoListagemTurmaTO = await this.call("CadastroTurmaFCD/listar", { buscaTextual: this.getValor("buscarTurmas"), codUsuarioQueNaoEstaNaTurma: this.getValor("aluno_codUsuario") });
	
			this.setHtml("listagemTurma", "");
	
			this.setIdTarget("listagemTurma");

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getCfg("LABEL_LISTAGEM_TURMAS"), prop: "nome"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_031"), prop: "nomeEmpresa"});
			colunas.push({titulo: this.getMsg("MSG_VH_CU_121"), prop: "inicioVigencia", formato: "DD/MM/YY"});
			colunas.push({titulo: this.getMsg("MSG_VH_CU_122"), prop: "fimVigencia", formato: "DD/MM/YY"});
			colunas.push({titulo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO"), prop: "numAlunos", formato: "numero"});

			await this.addTabela({
				collection: exibicaoListagemTurmaTO.collectionListagemTurmaTO,
				propId: "codTurma",
				colunas: colunas,
				onEdicao: async (codTurma) => {
					const nomeUsuario = this.getValor("aluno_nome");
					const propagar = await cadastroTurmaVH.validarPropagacaoDeUsuariosTurma(nomeUsuario, codTurma);
					if (propagar) {
						const valorBuscaTextual = this.getValor("buscarTurmas");
						this.fecharPopup("turma_selecao");
						setTimeout(async () => {
							await cadastroTurmaVH.abrirPopupSelecaoAplicacoesTurma(codTurma, this.getValor("aluno_codUsuario"), () => {
								this.editarUsuario(this.getValor("aluno_codUsuario"));
								setTimeout(() => this.abrirPopUpTurmaAluno(valorBuscaTextual), 500);
							});
						}, 500);
						return;
					}

					const incluirTurmaUsuarioTO = {
						codUsuario: this.getValor("aluno_codUsuario"),
						codTurma: codTurma,
						propagarInclusaoParaAplicacoes: false
					}

					await this.call("CadastroUsuarioFCD/incluirTurma", incluirTurmaUsuarioTO);

					this.mostrarMsgAjax(this.getMsg("MSG_VH_CU_124"), 2);

					$('#listagemTurma #listagem_linha_' + codTurma).html(`
						<span style="background-color:#0088CC; color:#fff; margin-left:6px; border-radius:4px 4px 4px 4px; padding:5px; line-height: 28px;">
							${this.getMsg("MSG_VH_CU_124")}
						</span>
					`);
					$('#listagemTurma #listagem_linha_' + codTurma).fadeOut(500);
					this.editarUsuario(this.getValor("aluno_codUsuario"));
				},
				msgListaVazia: this.getMsg("MSG_VH_CU_123"),
			});
	
			this.exibir();
		}

		this.addPopup({
			width: "90%",
			// height: "500px",
			id: "turma_selecao",
			titulo: this.getMsg("MSG_VH_CU_119"),
			botoes: [{ label: "OK", css: "float: right", classe: "btn-sm" }]
		});

		this.addCampoTexto({
			dica: this.getMsg("MSG_VH_CU_120"),
			css: "width: 100%; float: left; margin-top: 18px;",
			id: "buscarTurmas",
			valor: valorBuscaTextual,
			onEnter: buscarTurmas
		});

		this.append("<div id='listagemTurma'> </div>");

		this.exibirPopups();
		buscarTurmas();
	}

	async exibirCampoSenha() {
		if (this.getValor("aluno_definicaoSenha") == 2) {
			await this.hide("aluno_componenteSenha", "aluno_componenteSenhaConfirmacao");
		} else {
			await this.show("aluno_componenteSenha", "aluno_componenteSenhaConfirmacao");
		}
	}

	async salvar() {

		let salvamentoAlunoTO: any = {
			codUsuario: this.getValor("aluno_codUsuario")
		}

		if (this.isAluno()) {
			if (!this.validarCamposObrigatorios("aluno_nome", "aluno_email", "aluno_cidade", "aluno_uf", "aluno_sexo")) {
				return
			}
		} else {

			if (!this.validarCamposObrigatorios("aluno_nome", "aluno_login", "aluno_idPerfil")) {
				return;
			}

			salvamentoAlunoTO.login = this.getValor("aluno_login");
			salvamentoAlunoTO.idPerfil = this.getValor("aluno_idPerfil");
			salvamentoAlunoTO.senha = this.getValor("aluno_senha");
			salvamentoAlunoTO.inicioVigencia = this.getValor("aluno_inicioVigencia");
			salvamentoAlunoTO.fimVigencia = this.getValor("aluno_fimVigencia");
		}

		if (UtilNumero.isCPFValido(this.getValor("aluno_cpf")) === false) {
			await this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_CadastroUsuarioVH_073"),
				botoes: [{
					label: 'Ok',
					classe: 'btn-primary',
					css: "background-color: #92278F; border-color: #6b1e69",
				}]
			});

			this.focar("aluno_cpf");
			return;
		}

		salvamentoAlunoTO.nome = this.getValor("aluno_nome");
		salvamentoAlunoTO.codEmpresa = this.getValor("codEmpresa");
		salvamentoAlunoTO.matricula = this.getValor("aluno_matricula");
		salvamentoAlunoTO.nomeMae = this.getValor("aluno_nomeMae");
		salvamentoAlunoTO.email = this.getValor("aluno_email");
		salvamentoAlunoTO.cpf = this.getValor("aluno_cpf");
		salvamentoAlunoTO.rg = this.getValor("aluno_rg");
		salvamentoAlunoTO.apelido = this.getValor("aluno_apelido");
		salvamentoAlunoTO.cidade = this.getValor("aluno_cidade");
		salvamentoAlunoTO.uf = this.getValor("aluno_uf");
		salvamentoAlunoTO.locale = this.getValor("aluno_locale");
		salvamentoAlunoTO.sexo = (this.getValor("aluno_sexo") ? (this.getValor("aluno_sexo") == 1 ? true : false) : null);
		salvamentoAlunoTO.endereco = this.getValor("aluno_endereco");
		salvamentoAlunoTO.curriculo = this.getValor("aluno_curriculo");
		salvamentoAlunoTO.graduacao = this.getValor("aluno_graduacao");
		salvamentoAlunoTO.dataNascimento = this.getValor("aluno_dataNascimento");
		salvamentoAlunoTO.telefone = this.getValor("aluno_telefone");
		salvamentoAlunoTO.cep = this.getValor("aluno_cep");
		salvamentoAlunoTO.codigoSistemaOrigem = this.getValor("aluno_codigoSistemaOrigem");
		salvamentoAlunoTO.isEditar = this.hasValue(this.getValor("aluno_codUsuario"));
		salvamentoAlunoTO.cpf = UtilString.getSomenteNumeros(salvamentoAlunoTO.cpf);
		salvamentoAlunoTO.isDeveIgnorarEmailBoasVindas = !this.getValor("aluno_enviarBoasVindas");
		salvamentoAlunoTO.tipoAcessibilidade = this.getValor("tipoAcessibilidade");

		const definicaoSenha = this.getValor("aluno_definicaoSenha");
		const confirmacaoDeSenha = this.getValor("aluno_senha_confirmacao");

		if (definicaoSenha == 1) {

			if (this.isEmpty(salvamentoAlunoTO.senha)) {
				await this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_125") });
				this.focar("aluno_senha");
				return;

			} else if (this.isEmpty(confirmacaoDeSenha)) {
				await this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_126") });
				this.focar("aluno_senha_confirmacao")
				return;

			}

		} else if (definicaoSenha == 2) {
			salvamentoAlunoTO.senha = null

			if (salvamentoAlunoTO.email == null){
				await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroUsuarioVH_052") });
				this.focar("aluno_email");
				return false;
			}
		}

		if (salvamentoAlunoTO.senha != null && salvamentoAlunoTO.senha != confirmacaoDeSenha) {
			await this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_127") });
			this.focar("aluno_senha");
			return false;
		}

		if (salvamentoAlunoTO.senha != null && !UtilPasswordValidator.isSenhaForte("aluno_senha")) {
			await this.exibirAlerta({ msg: UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_040') });
			return false;
		}

		if (salvamentoAlunoTO.inicioVigencia?.getTime() >= salvamentoAlunoTO.fimVigencia?.getTime()) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroUsuarioVH_053") });
			this.focar("aluno_fimVigencia");
			return false;
		}

		salvamentoAlunoTO = await this.call("CadastroUsuarioFCD/salvar", salvamentoAlunoTO);
		
		if (salvamentoAlunoTO.isSelecionarTurmas) {
			this.editarUsuario(salvamentoAlunoTO.codUsuario);
			this.abrirPopUpTurmaAluno();

		} else {
			this.listarUsuarios();
		}
	}

	async exibirTelaImportacaoPlanilha() {

		UtilHash.registrarHistorico(this.exibirTelaImportacaoPlanilha);

		const listagensParaImportacaoDeAlunosTO = await this.call("CadastroUsuarioFCD/recuperarListagensParaImportacao", null);

		this.limpar();

		this.setTitulo(this.getMsg("MSG_VH_CU_144") + " " + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase())

		this.addZonaUpload({
			id: "importacaoAlunos",
			arquivosAceitos: ".xlsx",
			label: this.getMsg("MSG_VH_CU_145"),
			classe: "col-md-12",
			onAntesAceitacao: (file) => {
				this.handlePlanilhaImportacaoSelecionada(file, listagensParaImportacaoDeAlunosTO);
				return false;
			}
		});

		this.append("<div id='tabelaComPlanilha'></div>");

		this.addSubtitulo(this.getMsg("FP_FRONT_CadastroUsuarioVH_032"));

		await this.addSelect({
			id: "codEmpresa",
			label: this.getMsg("MSG_VH_CU_154"),
			classe: "col-md-6",
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarSegmentos",
				numMinimoCaracteres: 0,
			},
		});

		await this.addSelect({
			id: "codsAgendamentos",
			label: this.getMsg("MSG_VH_CU_155"),
			multiplo: true,
			classe: "col-md-6",
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarAplicacoes",
				numMinimoCaracteres: 3,
			},
		});

		await this.addSelect({
			id: "codsTurmas",
			label: this.getCfg("LABEL_TURMA") + " " + this.getMsg("MSG_VH_CU_156"),
			multiplo: true,
			classe: "col-md-6",
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarTurmas",
				numMinimoCaracteres: 3,
			},
		});

		this.addCampoTexto({
			id: "nomeNovaTurma",
			label: this.getMsg("MSG_VH_CU_157") + " " + this.getCfg("LABEL_TURMA").toLowerCase() + " " + this.getMsg("MSG_VH_CU_158"),
			classe: "col-md-6",
		});

		this.addRadioGroup({
			id: "cfgSenha",
			label: this.getMsg("MSG_VH_CU_41"),
			valor: 2,
			collection: [{ id: "enviarEmailDefinicaoSenha", descricao: this.getMsg("MSG_VH_CU_159") },
			{ id: "isDeveAlterarSenhaProximoLogin", descricao: this.getMsg("MSG_VH_CU_161") }],
			classe: "col-md-6",
		})

		this.addRadioGroup({
			id: "cfgEmailBoasVindas",
			label: this.getMsg("FP_FRONT_CadastroUsuarioVH_033"),
			valor: "enviarEmailBoasVindasSoParaNovosCadastros",
			collection: [
				{ id: null, descricao: this.getMsg("FP_FRONT_CadastroUsuarioVH_061") },
				{ id: "enviarEmailBoasVindasSoParaNovosCadastros", descricao: this.getMsg("MSG_VH_CU_160") },
				{ id: "enviarEmailBoasVindasParaTodos", descricao: this.getMsg("FP_FRONT_CadastroUsuarioVH_060") },
				{ id: "enviarEmailCustomizado", descricao: this.getMsg("FP_FRONT_CadastroUsuarioVH_059") }
			],
			classe: "col-md-6",
			onChange: () => {
				const cfgEmailBoasVindas = this.getValor("cfgEmailBoasVindas");
				if (cfgEmailBoasVindas === "enviarEmailCustomizado"){
					this.show("conteudoEmail", "dataEnvioEmail", "tituloEmailPersonalizado", "componenteEmailPrevia");

					this.atualizarTextoPrevia(listagensParaImportacaoDeAlunosTO.templateEmail);
				} else {
					this.hide("conteudoEmail", "dataEnvioEmail", "tituloEmailPersonalizado", "componenteEmailPrevia");
				}
			}
		});

		this.append("<div class='row'><div class='col-md-12'>")

		this.addCampoTexto({
			id: "tituloEmailPersonalizado",
			label: this.getMsg("FP_FRONT_CadastroUsuarioVH_062"),
			classe: "col-md-9",
			visivel: false
		});

		this.addCampoTexto({
			id: "dataEnvioEmail",
			tipo: "DATAHORA",
			label: this.getMsg("FP_FRONT_CadastroUsuarioVH_057"),
			classe: "col-sm-3",
			visivel: false
		});

		this.addEditorHTML({
			id: "conteudoEmail",
			label: this.getMsg("FP_FRONT_CadastroUsuarioVH_054"),
			ajuda: this.getMsg("FP_FRONT_CadastroUsuarioVH_055"),
			classe: "col-md-12",
			valor: this.getMsg("FP_FRONT_CadastroUsuarioVH_056"),
			visivel: false,
			onChange: () => this.atualizarTextoPrevia(listagensParaImportacaoDeAlunosTO.templateEmail)
		});

		this.append("</div></div>");

		this.append(UtilEmailPrevia.criar({
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_084"),
			info: this.getMsg("FP_FRONT_AgendamentoProvaVH_085")
		}));

		this.addEspacamentoHorizontal();

		this.addBotao({ label: "Voltar", onClick: () => UtilHash.voltar() });

		this.addBotao({ label: this.getMsg("MSG_VH_CU_162"), css: "float: right", classe: "btn-primary", onClick: async () => {

			const cfgSenha = this.getValor("cfgSenha");
			const cfgEmailBoasVindas = this.getValor("cfgEmailBoasVindas");

			const importacaoPlanilhaDeAlunosTO = {
				enviarEmailDefinicaoSenha: cfgSenha === "enviarEmailDefinicaoSenha",
				isDeveAlterarSenhaProximoLogin: cfgSenha === "isDeveAlterarSenhaProximoLogin",
				enviarEmailBoasVindasSoParaNovosCadastros: cfgEmailBoasVindas === "enviarEmailBoasVindasSoParaNovosCadastros",
				enviarEmailBoasVindasParaTodos: cfgEmailBoasVindas === "enviarEmailBoasVindasParaTodos",
				tituloEmailPersonalizado: cfgEmailBoasVindas === "enviarEmailCustomizado" ? this.getValor("tituloEmailPersonalizado") : null,
				conteudoEmailPersonalizado: cfgEmailBoasVindas === "enviarEmailCustomizado" ? this.getValor("conteudoEmail") : null,
				dataEnvioEmailPersonalizado: cfgEmailBoasVindas === "enviarEmailCustomizado" ? this.getValor("dataEnvioEmail") : null,
				codsAgendamentos: this.getValor("codsAgendamentos"),
				codsTurmas: this.getValor("codsTurmas"),
				nomeNovaTurma: this.getValor("nomeNovaTurma"),
				codEmpresa: this.getValor("codEmpresa"),
				campos: [],
				planilha: []
			}

			let possuiCampoNaoInformado = false;

			$("table thead select[id^=tipoColuna]").each(function() {
				$(this).closest("div").removeClass("has-error");
				const campo = cadastroUsuarioVH.getValor(this.id);

				if (campo == null || campo == "") {
					$(this).closest("div").addClass("has-error");
					$(this).focus();
					possuiCampoNaoInformado = true;
				} else {
					importacaoPlanilhaDeAlunosTO.campos.push(campo);
				}
			})

			if (possuiCampoNaoInformado) {
				await this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_163") });
				return;
			}

			let todaPlanilha = [];

			$("#tabelaComPlanilha table tbody tr").each(function() {
				const linha = [];

				$(this).find("td").each(function() {
					if (importacaoPlanilhaDeAlunosTO.campos.length !== linha.length) {
						linha.push($(this).text());
					}
				});

				todaPlanilha.push(linha);
			})

			todaPlanilha = this.removerLinhasComNomesNumericos(todaPlanilha, importacaoPlanilhaDeAlunosTO.campos);

			$("table tbody tr").attr("class", "").tooltip('destroy');

			let numLinhasPorLote = 10;
			let primeiraLinhaDoLote = 0;
			let conteudoDoLote = todaPlanilha.slice(primeiraLinhaDoLote, primeiraLinhaDoLote + numLinhasPorLote);
			let ultimaLinhaDoLote = primeiraLinhaDoLote + conteudoDoLote.length;

			let importarLote = async (importacaoPlanilhaDeAlunosTO, numeroTR) => {

				const file: any = {type: "application/json", name: "importacao-alunos.json"};
				const uploadURLTO = await UtilArmazenamento.gerarUrlParaUpload(file, true);
				uploadURLTO.uploadTO.metadata = {};
				await UtilArmazenamento.enviar(file, uploadURLTO.urlUploadViaPUT);
				await UtilArmazenamento.finalizar(uploadURLTO.uploadTO);

				const path = await this.call({
					endpoint: "CadastroUsuarioFCD/importarAlunos",
					params: [importacaoPlanilhaDeAlunosTO, uploadURLTO.uploadTO],
				});

				this.exibirLoading({
					msg: this.getMsg("MSG_VH_CU_167") + " " + (primeiraLinhaDoLote + 1) + " " + this.getMsg("MSG_VH_CU_168") + " " + (ultimaLinhaDoLote) + this.getMsg("MSG_VH_CU_169") + "..."
				})

				const resultadoImportacaoPlanilhaAlunosTO = await UtilArmazenamento.aguardarGeracaoJson(path);

				if (resultadoImportacaoPlanilhaAlunosTO.mensagenErro) {
					this.removerLoading();
					await this.exibirAlerta({msg: resultadoImportacaoPlanilhaAlunosTO.mensagenErro});
					return;
				}

				this.removerLoading();

				for (const resultadoImportacaoLinhaTO of resultadoImportacaoPlanilhaAlunosTO.collectionResultadoImportacaoLinhaTO) {
					let classe = "";
					let title = "";

					if (resultadoImportacaoLinhaTO.msgsErro != null) {
						classe = "danger";
						title = resultadoImportacaoLinhaTO.msgsErro;
					} else if (resultadoImportacaoLinhaTO.msgsAlerta != null) {
						classe = "warning";
						title = resultadoImportacaoLinhaTO.msgsAlerta;
					} else if (resultadoImportacaoLinhaTO.isNovo) {
						classe = "success";
						title = this.getMsg("MSG_VH_CU_164");
					} else {
						classe = "info";
						title = this.getMsg("MSG_VH_CU_165");
					}

					$("table tbody tr:nth-child(" + (numeroTR + 1) + ")").attr("class", "").addClass(classe).tooltip('destroy').attr("title", title).tooltip();
					numeroTR++;
				}

				let idDiv = this.gerarId();

				this.append("<div id='" + idDiv + "'></div>");

				this.exibir();

				this.setIdTarget(idDiv);

				this.addEspacamentoHorizontal("1px");

				this.addTitulo({ texto: this.getMsg("MSG_VH_CU_166") + ": " + (primeiraLinhaDoLote + 1) + " " + this.getMsg("MSG_VH_CU_168") + " " + ultimaLinhaDoLote });

				this.append("<pre style='float: left'>" + resultadoImportacaoPlanilhaAlunosTO.mensagens + "</pre>");

				this.exibir();

				UtilWindow.scrollTo("#" + idDiv);

				primeiraLinhaDoLote = ultimaLinhaDoLote;
				ultimaLinhaDoLote = primeiraLinhaDoLote + numLinhasPorLote;
				conteudoDoLote = todaPlanilha.slice(primeiraLinhaDoLote, ultimaLinhaDoLote);
				ultimaLinhaDoLote = primeiraLinhaDoLote + conteudoDoLote.length;

				if (conteudoDoLote.length > 0) {
					importacaoPlanilhaDeAlunosTO.planilha = conteudoDoLote;
					importarLote(importacaoPlanilhaDeAlunosTO, numeroTR);
				} else {
					await this.show("botaoEncerrado");
				}
			}

			importacaoPlanilhaDeAlunosTO.planilha = conteudoDoLote;
			importarLote(importacaoPlanilhaDeAlunosTO, 0);
		}});

		this.addBotao({
			label: "OK", idAlvo: "botoesDireita", css: "float: right", visivel: false, id: "botaoEncerrado", onClick: () => {
				this.listarUsuarios();
			}
		});

		this.exibir();
	}

	atualizarTextoPrevia(templateEmail: string) {

		const variaveis: UtilEmailPreviaVariaveisType[] = [{
			variavel: UtilEmailPreviaVariaveisEnum.usuario_email_login,
			valor: 'EMAIL_DESTINATARIO_LOGIN'
		}, {
			variavel: UtilEmailPreviaVariaveisEnum.usuario_primeiro_nome,
			valor: 'EMAIL_DESTINATARIO_PRIMEIRO_NOME'
		}, {
			variavel: UtilEmailPreviaVariaveisEnum.nome_sistema,
			valor: 'SISTEMA_NOME'
		}, {
			variavel: UtilEmailPreviaVariaveisEnum.usuario_senha,
			valor: 'USUARIO_SENHA'
		}]

		UtilEmailPrevia.atualizarTextoPrevia(
			templateEmail, 'conteudoEmail', variaveis
		);
	}

	async handlePlanilhaImportacaoSelecionada(file, listagensParaImportacaoDeAlunosTO) {
				
		const linhas = await UtilXlsx.getArrayComDadosDaPlanilha(file);

		this.setIdTarget("tabelaComPlanilha");

		this.addSubtitulo(file.name)

		this.addTexto(this.getMsg("MSG_VH_CU_146") + " <strong>" + this.getMsg("MSG_VH_CU_147") + "</strong> " + this.getMsg("MSG_VH_CU_148") + " " + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase() + " " + this.getMsg("MSG_VH_CU_149") + " <strong>" + this.getMsg("MSG_VH_CU_150") + "</strong> " + this.getMsg("MSG_VH_CU_151"));

		let numColunas = 0;
		let numLinhas = 0;

		if (linhas?.length > 0) {
			numColunas = linhas[0].length;
			numLinhas = linhas.length;
		} else {
			this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_152") });
			return;
		}

		this.append("<table class='table table-hover table-condensed importacao'>");

		this.append("<thead>");
		this.append("<tr>");

		for (let j = 0; j < numColunas; j++) {
			this.append("<td><div class='row'>");
			await this.addSelect({
				id: "tipoColuna" + j,
				propId: "tipoCampoImportacaoPlanilha",
				classe: "col-md-12",
				dica: this.getMsg("MSG_VH_CU_153"),
				collection: listagensParaImportacaoDeAlunosTO.listaListagemTipoCampoImportacaoUsuarioTO,
			})
			this.append("</div></td>");
		}

		this.append("<td></td></tr>");
		this.append("<tr>");

		for (let j = 0; j < numColunas; j++) {
			this.append("<td><a onclick='amaisVH.removerColunaDeTabela($(this).closest(\"table\"), $(this).parent().index());'><i class='fa fa-remove'></i></a></td>");
		}

		this.append("<td></td></tr>");
		this.append("</thead>");
		this.append("<tbody>");

		for (let i = 0; i < numLinhas; i++) {
			this.append("<tr>");

			for (let j = 0; j < numColunas; j++) {
				this.append("<td contenteditable='true'>");
				if (linhas[i][j] != null) this.append(linhas[i][j]);
				this.append("</td>");
			}
			this.append("<td><a onclick='$(this).closest(\"tr\").remove()'><i class='fa fa-remove'></i></a></td>");

			this.append("</tr>");
		}

		this.append("</tbody>");
		this.append("</table>");

		await this.hide("importacaoAlunos");
		this.exibir();

		if (linhas[0]?.length) {
			let tiposPreDefinidos = 0;
			for (let numColuna = 0; numColuna < linhas[0].length; numColuna++) {
				let valorPrimeiraLinha = linhas[0][numColuna];
				for (const listagemTipoCampoImportacaoUsuarioTO of listagensParaImportacaoDeAlunosTO.listaListagemTipoCampoImportacaoUsuarioTO) {
					if (UtilString.substituirAcentuacao(listagemTipoCampoImportacaoUsuarioTO.nome).trim().toLowerCase() == UtilString.substituirAcentuacao(valorPrimeiraLinha).trim().toLowerCase()) {
						this.setValor("tipoColuna" + numColuna, listagemTipoCampoImportacaoUsuarioTO.tipoCampoImportacaoPlanilha);
						if (this.getValor("tipoColuna" + numColuna) != null) {
							tiposPreDefinidos++;
						}
						break;
					}
				}
			}
			if (tiposPreDefinidos == linhas[0].length) {
				try {
					const a = <HTMLAnchorElement> document.querySelector("#tabelaComPlanilha tbody tr:nth-child(1) td:last-child a");
					a.click();
				} catch (ignored) { }
			}
		}
	}

	removerLinhasComNomesNumericos(linhas, campos) {
		let indiceCampoNome = null;

		for (let i = 0; i < campos.length; i++) {
			if (campos[i] == "nome") indiceCampoNome = i;
		}

		if (indiceCampoNome == null) return linhas;

		let linhas2 = linhas;
		linhas = [];

		for (const linha of linhas2) {
			const valorNome = linha[indiceCampoNome];
			if (isNaN(valorNome)) linhas.push(linha);
		}

		return linhas;
	}
			
	uploadImgPerfil(codUsuario: number) {
		return new Promise((resolve) => {
			this.addPopup({
				id: "upload-img-perfil",
				titulo: this.getMsg("FP_FRONT_CadastroUsuarioVH_003"),
			});
	
			this.addZonaUpload({
				label: this.getMsg("FP_FRONT_CadastroUsuarioVH_003"),
				classe: "col-md-12",
				// maxArquivos: 1,
				arquivosAceitos: ".png,.jpg,.jpeg,.heic,.heif",
				css: "text-align: center;",
				onDepoisEnvio: async (file, uploadTO) => {

					this.fecharPopup("upload-img-perfil");

					const dadosBasicosTO: DadosBasicosTO = {
						codUsuario: codUsuario,
						caminhoImgPerfil: uploadTO
					}
			
					await this.call("LoginFCD/alterarDadosBasicos", dadosBasicosTO);

					resolve(null);
				}
			});
	
			this.exibirPopups();
		});
	}

	async excluirImgPerfil(codUsuario) {
		return new Promise((resolve) => {
			this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_CadastroUsuarioVH_034"),
				botoes: [{
					label: this.getMsg("MSG_VH_007"),
					classe: "btn-danger",
					onClick: async () => {
						await this.call("CadastroUsuarioFCD/excluirImgPerfil", codUsuario);
						resolve(null);
					}
				}],
			});
		});
	}
	
	exibirSubsecaoInfosComplementares(recuperacaoAlunoTO) {
		if (!recuperacaoAlunoTO?.infosComplementares) return;
		try {
			const infos = JSON.parse(recuperacaoAlunoTO.infosComplementares);

			if (Object.keys(infos).length === 0) return;

			this.addSubtitulo("Informações complementares");
			
			this.exibirInfosComplementares(Object.entries(infos));

		} catch (ignored) {}
	}

	exibirInfosComplementares(entries, tab?) {
		tab = tab || "";
		entries.forEach(([key, value]) => {
			key = UtilString.camelToSnakeCase(key).toUpperCase();
			if (typeof value === "object") {
				this.append(tab + "<strong>" + key + "</strong>: <br>");
				this.exibirInfosComplementares(Object.entries(value), "&nbsp;&nbsp;&nbsp;&nbsp;")
			} else {
				this.append(tab + "<strong>" + key + "</strong>: " + value + "<br>");
			}
		});	
	}

	getLabelCpf(docIdTO) {
		let labelCpf = this.getMsg("MSG_VH_CU_50");

		if (docIdTO?.isCPFIgualOcr) {
			labelCpf = `
				<span class="text-success" title="${this.getMsg("FP_FRONT_AgendamentoProvaVH_166")}">
					${labelCpf}
					<i class="fa fa-shield"></i>
				</span>
			`;
		} else if (docIdTO?.isCPFDiferenteOcr) {
			labelCpf = `
				<span class="text-danger" title="${this.getMsg("FP_FRONT_AgendamentoProvaVH_167")}">
					${labelCpf}
					<i class="fa fa-shield"></i>
				</span>
			`;
		}

		return labelCpf;
	}
}

const cadastroUsuarioVH = new CadastroUsuarioVH();

