class LimiteDadosVH extends AmaisVH {

	constructor() {
		super("LimiteDadosVH");
	}

	async verificarLimites() {
		
		try {
			const alertasLimitesExcedidosTO = await this.call({
				endpoint: "LimiteDadosFCD/recuperarAlertasLimitesExcedidos",
				desativarMsgCarregando: true,
				onRequestError: async (e: BackendRequestError) => {
					this.logger.warn("Erro ao verificar limites de dados.", e);
					return BackendRequestError.ERRO_TRATADO
				}
			});

			if (!alertasLimitesExcedidosTO) {
				return;
			}
			
			if (this.isEmpty(alertasLimitesExcedidosTO.msgsAlerta)) {
				return;
			}
			
			let $divAlertas = $("#container [alertas-limites]");
			
			if ($divAlertas.length == 0) {
				$divAlertas = $("<div class='alert alert-danger alert-dismissible col-md-12' role='alert' alertas-limites></div>");
				$("#container").prepend($("<div class='row'></div>").append($divAlertas));
				$divAlertas.hide();
			}
			
			$divAlertas.html(
			  `<button type='button' class='close' data-dismiss='alert' aria-label='${this.getMsg("FP_FRONT_LimiteDadosVH_002")}'><span aria-hidden='true'>&times;</span></button>`
			+ `<i class='fa fa-exclamation-circle fa-lg'></i> ${this.getMsg("FP_FRONT_LimiteDadosVH_001")}, <strong>`
			+ this.getPrimeiroNomeUsuarioLogado() 
			+ "</strong>. "
			+ alertasLimitesExcedidosTO.msgsAlerta.join(" ")
			);

			$divAlertas.fadeIn("slow");

		} catch (ignored) {}
	}
}

const limiteDadosVH = new LimiteDadosVH();

$(document).ready(() => {
	setTimeout(async () => {
		if (UtilAuth.isAutenticado() && !limiteDadosVH.isAluno()) {
			await limiteDadosVH.verificarLimites();
		}
	}, 5000);
});