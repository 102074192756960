class ExploradorQuestoesVH extends AmaisVH {
	
	cfgs: any;

	constructor() {
		super(ExploradorQuestoesVH.name);
	}
	
	ordemAgrupamentos = ["NIVEL_CARGO", "ANO", "ORGAO", "BANCA", "TIPO_QUESTAO", "PROVA", "SECAO", "DISCIPLINA"];

	async exibirExplorador(cfgs) {
		
		this.cfgs = cfgs;
		
		if (cfgs.idDiv != null) {
			this.setIdTarget(cfgs.idDiv);

		} else {
			this.limpar();
			this.setTitulo(this.getMsg("MSG_VH_EXQ_01"));
		}

		const collectionAgrupamentoQuestoesTO = await this.call("ExploradorQuestoesFCD/listarAgrupamento", {nivel:0, tipoAgrupamento: this.ordemAgrupamentos[0], agrupamentosPais: null});
	
		this.addListagemHierarquica(collectionAgrupamentoQuestoesTO, {
			id: "bancoDeQuestoesPorAgrupamento",
			metodoMontarLabel: function(agrupamentoQuestoesTO) {
				var isProva = agrupamentoQuestoesTO.tipo == "PROVA";
				
				return " " + (isProva ? "<b>" : "") 
						+ agrupamentoQuestoesTO.nome + (isProva ? "</b> " : "") + " <i tipo='" + agrupamentoQuestoesTO.tipo + "' codigo='" 
							+ agrupamentoQuestoesTO.codigo + "' nome-agrupamento='" + agrupamentoQuestoesTO.nome + "'></i>";
			},
			onSelecao: async (agrupamentoQuestoesTO, linkItem) => {
				this.cfgs.metodoOnMostrar.call(this, this.getFiltrosSelecaoQuestoesTO(linkItem));
			},
			metodoMostrarFilhos: async (linkClicado, agrupamentoQuestoesTO, cfgs) => {

				var filtrosAtivos = this.getFiltrosParaAgrupamento(linkClicado);

				var agrupamentosPais = {};
				
				for (var i = 0; i < filtrosAtivos.length; i++) {
					var filtroAtivoTO = filtrosAtivos[i];
					agrupamentosPais[filtroAtivoTO.tipoFiltro] = filtroAtivoTO.codigo;
				}
			
				var nivel = agrupamentoQuestoesTO.nivel + 1;

				return await this.call("ExploradorQuestoesFCD/listarAgrupamento", {nivel: nivel, tipoAgrupamento: this.ordemAgrupamentos[nivel], agrupamentosPais: agrupamentosPais});
				
			},
			metodoParaChecarSeDeveHabilitarFilhos: this.seDeveMostrarFilhos,
			propId: "codigo",
			linksCfgs: [{label: "<i class='fa fa-wrench'></i>", onClick: async () => await this.exibirReconfiguracao()}]
		});

		this.addEspacamentoHorizontal("1px")
		
		this.exibir();
		this.setIdTarget(null);
	}
	
	seDeveMostrarFilhos(agrupamentoQuestoesTO) {
		return (agrupamentoQuestoesTO.tipo != "SECAO" && agrupamentoQuestoesTO.numFilhos && agrupamentoQuestoesTO.numFilhos > 0);
	}
	
	getFiltrosSelecaoQuestoesTO(linkClicado) {
		
		var filtrosAtivos = this.getFiltrosParaAgrupamento(linkClicado);
		var nomesFiltros = [];
		var tituloFiltro = "";
		
		for (var i = 0; i < filtrosAtivos.length; i++) {
			var filtroAtivoTO = filtrosAtivos[i];
			nomesFiltros.unshift(filtroAtivoTO.descricaoDoFiltro);
		}
		
		var filtrosSelecaoQuestoesTO = {
			filtrosAtivos: filtrosAtivos,
			tituloExibicaoFiltro: this.getMsg("MSG_VH_EXQ_02") + " " + nomesFiltros.join(" > ")
		}
		
		return filtrosSelecaoQuestoesTO;
	}
	
	getFiltrosParaAgrupamento(linkClicado) {
		var elementoI = $(linkClicado).closest("td").next().find("> a > i[codigo]");
		var filtrosAtivos = [];
		
		do {
			var filtroAtivoTO = {
				tipoFiltro: elementoI.attr("tipo"),
				codigo: elementoI.attr("codigo"),
				descricaoDoFiltro: elementoI.attr("nome-agrupamento"),
			};
			
			filtrosAtivos.push(filtroAtivoTO);
			
			elementoI = $(elementoI).closest(".list-group").closest(".list-group-item").find("> a > i[codigo]");
			
			if (elementoI.length == 0) {
				break;
			}
			
		} while (elementoI.length > 0);
		
		return filtrosAtivos;
	}
	
	async exibirReconfiguracao() {
		
		var niveisAtuais = this.ordemAgrupamentos.slice(0, this.ordemAgrupamentos.length - 2);
		var niveisDisponiveis = [];
		var mapaTiposFiltros = this.getMapaTiposFiltros();
		for (var tipoFiltro in mapaTiposFiltros) {
			if (tipoFiltro == "PROVA" || tipoFiltro ==  "SECAO" || tipoFiltro == "GRUPO_SUBSTITUICAO" || tipoFiltro == "DIFICULDADE") {
				continue;
			}
			niveisDisponiveis.push({
				id: tipoFiltro,
				text: mapaTiposFiltros[tipoFiltro]
			});
		}
		
		this.addPopup({
			width: "300px",
            // height: "300px",
            titulo: this.getMsg("MSG_VH_EXQ_03"),
            botoes: [{label: this.getMsg("MSG_VH_EXQ_04"), onClick: async () => this.reconfigurar()}]
        })
		
		await this.addSelect({
			collection: JSON.parse(JSON.stringify(niveisDisponiveis)),
			id: "agrupamento1",
			label: "1º Nìvel " + this.getMsg("MSG_VH_EXQ_05"),
			classe: "col-md-12",
			valor: niveisAtuais[0],
		})
		
		await this.addSelect({
			collection: JSON.parse(JSON.stringify(niveisDisponiveis)),
			id: "agrupamento2",
			label: "2º " + this.getMsg("MSG_VH_EXQ_05"),
			classe: "col-md-12",
			valor: niveisAtuais[1],
		})
		
		await this.addSelect({
			collection: JSON.parse(JSON.stringify(niveisDisponiveis)),
			id: "agrupamento3",
			label: "3º " + this.getMsg("MSG_VH_EXQ_05"),
			classe: "col-md-12",
			valor: niveisAtuais[2],
		})
		
		this.exibirPopups();
	}
	
	reconfigurar() {
		var agrupamento1 = this.getValor("agrupamento1");
		var agrupamento2 = this.getValor("agrupamento2");
		var agrupamento3 = this.getValor("agrupamento3");
		var novosAgrupamentos = [];
		
		if (agrupamento1 != null) {
			novosAgrupamentos.push(agrupamento1);
		}
		
		if (agrupamento2 != null) {
			novosAgrupamentos.push(agrupamento2);
		}
		
		if (agrupamento3 != null) {
			novosAgrupamentos.push(agrupamento3);
		}
		
		if (novosAgrupamentos.length == 0) {
			this.exibirAlerta({ msg: this.getMsg("MSG_VH_EXQ_06") });
			return;
		}
		
		novosAgrupamentos.push("PROVA");
		novosAgrupamentos.push("SECAO");

		this.ordemAgrupamentos = novosAgrupamentos;
		
		$("#bancoDeQuestoesPorAgrupamento").remove();
		this.exibirExplorador(this.cfgs);
	}
}

const exploradorQuestoesVH = new ExploradorQuestoesVH();