class RespostaRecursoVH extends AmaisVH {
	
	codQuestao: number;
	codProva: number;
	codAgendamento: number;
	numAbaAtiva: number;
	recursosDaQuestaoTO: any
	filtroRecursoTO: any = {};

	constructor() {
		super(RespostaRecursoVH.name);
		this.addOperacaoParaHash("rrrq", this.listarRecursosDaQuestao);
	}

	async listarRecursosDaQuestao(numAbaAtiva, codQuestao: number, codProva: number, codAgendamento?: number) {

		numAbaAtiva = numAbaAtiva || 0;
		this.codQuestao = codQuestao;
		this.codProva = codProva;
		this.codAgendamento = codAgendamento;
		this.numAbaAtiva = numAbaAtiva;

		UtilHash.registrarHistorico(this.listarRecursosDaQuestao, numAbaAtiva, codQuestao, codProva, codAgendamento);

		const recursosDaQuestaoTO = await this.call("RecursoFCD/listarRecursosDaQuestao", codQuestao, codProva, codAgendamento, this.filtroRecursoTO);

		this.recursosDaQuestaoTO = recursosDaQuestaoTO;

		codQuestao = recursosDaQuestaoTO.exibicaoQuestaoTO.codQuestao;

		this.limpar();

		this.setTitulo(this.getMsg("MSG_VH_RC_30") + " " + (recursosDaQuestaoTO.exibicaoQuestaoTO.numQuestaoNaProva || ("#" + codQuestao)));
		this.setSubtitulo(recursosDaQuestaoTO.tituloProva);
		this.addSubtitulo({
			texto: recursosDaQuestaoTO.nomeSecao,
			cssContainer: "padding-top: 0;"
		});

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_EDICAO)) {
			this.addBotao({
				texto: "<i class='fa fa-pencil'></i>", css: "float: right", dica: this.getMsg("MSG_VH_RC_31"), onClick: () => {
					$("[questao][cod-questao='" + codQuestao + "']").attr("abriu-edicao-nova-pagina", true);
					this.abrirNovaJanela(UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 1, codQuestao));
				}
			});
		}

		exibicaoQuestaoVH.limparControleTextos();
		exibicaoQuestaoVH.exibirQuestao(recursosDaQuestaoTO.exibicaoQuestaoTO);
		this.addEspacamentoHorizontal("20px");

		const hasFiltro = Object.keys(this.filtroRecursoTO).some((campo) => this.filtroRecursoTO[campo])

		this.abrirAbaAccordion({ titulo: this.getMsg('FP_FRONT_RespostaRecursoVH_033'), aberta: hasFiltro});

		this.addFormulario({ classe: "col-md-12" });

		this.addCampoTexto({
			id: "filtroCodRecurso",
			tipo: "NUMERO",
			prefixo: '#',
			label: 'Codigo do Recurso',
		});

		this.addCampoTexto({
			label: this.getMsg('FP_FRONT_RespostaRecursoVH_034'),
			id: "filtroBuscaTextual",
			valor: this.filtroRecursoTO.buscaTextual,
			sufixo: "<i class='fa fa-sm fa-search'></i> ",
		});

		if (!recursosDaQuestaoTO.isAnonimatoHabilitado) {
			this.addCampoTexto({
				id: "filtroNomeAluno",
				valor: this.filtroRecursoTO.nomeAluno,
				label: this.getCfg("LABEL_ALUNO")
			});
		}

		this.addCamposPeriodo({
			idInicio: "inicioPeriodoRecursoBusca",
			idFim: "fimPeriodoRecursoBusca",
			label: this.getMsg("FP_FRONT_RespostaRecursoVH_035"),
		});
		this.append("</div>");

		this.append("<div class='row'>");

		this.addBotao({
			label: "Filtrar",
			classe: "btn-primary pull-right mr-15",
			onClick: async () => {
				this.aplicarFiltros();
				const abaAtual = $('#recurso_abas li.active');
				const recursosDaQuestaoTO = await this.call("RecursoFCD/listarRecursosDaQuestao", codQuestao, codProva, codAgendamento, this.filtroRecursoTO);
				if (abaAtual.length) {
					if (abaAtual.attr('aba') == '0') {
						await this.exibirRecursosPendentes(recursosDaQuestaoTO);
					} else {
						await this.exibirRecursosRespondidos(recursosDaQuestaoTO);
					}
				}
			}
		});

		this.append("</div>");

		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();

		this.append(`
			<div id="recurso_abas" class="col-md-12"></div>
		`);

		this.addAbas({
			abas: [{ 
				label: this.getMsg("MSG_VH_RC_65"), 
				onClick: async () => await this.exibirRecursosPendentes(recursosDaQuestaoTO)
			},{ 
				label: this.getMsg("MSG_VH_RC_38"), 
				onClick: async () => await this.exibirRecursosRespondidos(recursosDaQuestaoTO) 
			}], 
			numAbaAtiva, 
			id: this.gerarId(), 
			idAlvo: "recurso_abas", 
			// classes: 'nav-justified'
		});

		this.addEspacamentoHorizontal("20px");

		this.exibir();

		$("[questao]").on("atualizar-executar", async (event) => {
			event.preventDefault();
			event.stopPropagation();
			await this.onAtualizarQuestao(event.target);
		});

		$("[checkbox-recurso]").on("click", (event) => {
			event.stopPropagation();
			try{
				const isChecked = $(event.currentTarget).is(":checked");
				this.setBackgroundColorSelected(event.currentTarget, isChecked);
			}
			catch (e) {
				console.log(e);
			}
			this.verificarRecursosSelecionados();
		});
	}

	aplicarFiltros() {
		this.filtroRecursoTO = {
			codDuvida: this.getValor('filtroCodRecurso'),
			buscaTextual: this.getValor('filtroBuscaTextual'),
			nomeAluno: this.getValor('filtroNomeAluno'),
			inicioPeriodoRecursoBusca: this.getValor('inicioPeriodoRecursoBusca'),
			fimPeriodoRecursoBusca: this.getValor('fimPeriodoRecursoBusca'),
		}
	}

	async exibirRecursosRespondidos(recursosDaQuestaoTO: any){

		const wrapper = $('#conainer-recursos-respondidos');
		if (wrapper.length) {
			wrapper.remove();
		}

		this.append("<div id='conainer-recursos-respondidos'>")
		this.addFormulario();

		this.recursosDaQuestaoTO = recursosDaQuestaoTO;

		if (recursosDaQuestaoTO.collectionExibicaoRespostaRecursoTO.length > 0) {
			const montarBotoesAplicacao = function (resposta) {
				const html = [`<div class='btn-group' id='btn-group-${resposta.codComentario}'>`];
				html.push(`
					<a id="view_${resposta.codComentario}" class='btn btn-white btn-table'
					 onclick='respostaRecursoVH.validarVisualizacaoResposta(${resposta.codComentario}, ${true})'
					 title='${this.getMsg("FP_FRONT_RespostaRecursoVH_024")}'>
						<i class='fa fa-chevron-down'></i>
					</a>
				`);
				html.push("</div>");
				return html.join("")
			}

			const montarColunaTipoResposta = function (resposta) {
				return this.getMsg(resposta.tipoRespostaRecurso.idMsgSubstantivo)
			}

			const montarColunaRecursos = function (resposta) {
				const recursos = resposta.collectionExibicaoRecursoTO;
				if (recursos.length > 3) {
					return `
						<small title="${recursos.map(recurso => `${this.getMsg("FP_FRONT_RespostaRecursoVH_030")} #${recurso.codDuvida} - ${recurso.nomeAluno}`).join('&lt;br&gt;')}">
							${recursos.length} ${this.getMsg("FP_FRONT_RespostaRecursoVH_027")}
						</small>
					`;
				}
				return recursos.map((recurso: any) => {
					let fase = "";
					if(recurso.tipoAplicacaoFase) {
						fase = ` - ${this.getMsg("FP_FRONT_RespostaRecursoVH_036")}: ${this.getMsg(recurso.tipoAplicacaoFase.idMsg)}`;
					}
					return `<small>${this.getMsg("FP_FRONT_RespostaRecursoVH_030")} #${recurso.codDuvida} ${fase}</small> - ${recurso.nomeAluno}`
				}).join('<br>')
			}

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: "", prop: "<small>#${codComentario}</small>"});
			colunas.push({titulo: this.getMsg("FP_FRONT_RespostaRecursoVH_028"), prop: "dataResposta", formato: "DD/MM/YYYY HH:mm"});
			colunas.push({titulo: this.getMsg("FP_FRONT_RespostaRecursoVH_029"), prop: "nomeAutorResposta"});
			colunas.push({titulo: this.getMsg("FP_FRONT_RespostaRecursoVH_026"), prop: montarColunaTipoResposta});
			colunas.push({titulo: this.getMsg("FP_FRONT_RespostaRecursoVH_027"), prop: montarColunaRecursos});
			colunas.push({titulo: "", prop: montarBotoesAplicacao});

			await this.addTabela({
				id: "listagem_respostas",
				propId: "codComentario",
				collection: recursosDaQuestaoTO.collectionExibicaoRespostaRecursoTO,
				colunas: colunas,
				ordenacao: [["dataResposta", false]]
			})
		}
		else{
			this.addTextoAlerta({
				texto: this.getMsg("MSG_VH_RC_66"),
				classes: "alerta-sem-recursos-abertos col-md-12"
			});
		}

		this.fecharFormulario();
		this.append("</div>");

		this.exibir();
	}

	validarVisualizacaoRecurso(codRecurso, isExibirRecurso) {
		const recurso = this.recursosDaQuestaoTO.collectionExibicaoRecursoTO.filter(it => it.codDuvida == codRecurso);

		if (recurso?.length) {
			const exibicaoRecursoTO = recurso[0];
			this.alterarAcaoRecurso(exibicaoRecursoTO, isExibirRecurso);

			if (isExibirRecurso) {
				this.adicionarLinhaVisualizacaoRecurso(exibicaoRecursoTO);
				return;
			}
			const el = $(`#visualizacao_recurso_${exibicaoRecursoTO.codDuvida}`);
			if (el.length) {
				el.fadeOut(function() {
					$(this).remove();
				});
			}
		}

	}

	validarVisualizacaoResposta(codResposta, isExibirResposta) {
		const resposta = this.recursosDaQuestaoTO.collectionExibicaoRespostaRecursoTO.filter(it => it.codComentario == codResposta);

		if (resposta?.length) {
			const exibicaoRespostaRecursoTO = resposta[0];
			this.alterarAcaoResposta(exibicaoRespostaRecursoTO, isExibirResposta);

			if (isExibirResposta) {
				this.adicionarLinhaVisualizacaoResposta(exibicaoRespostaRecursoTO);
				return;
			}
			const el = $(`#visualizacao_resposta_${exibicaoRespostaRecursoTO.codComentario}`);
			if (el.length) {
				el.fadeOut(function() {
					$(this).remove();
				});
			}
		}
	}

	alterarAcaoRecurso(exibicaoRecursoTO, isExibirRecurso: boolean) {
		const group = $(`#btn-group-${exibicaoRecursoTO.codDuvida}`);
		const title = isExibirRecurso ? this.getMsg("FP_FRONT_RespostaRecursoVH_023") : this.getMsg("FP_FRONT_RespostaRecursoVH_022");
		const icon = isExibirRecurso ? 'fa-chevron-up' : 'fa-chevron-down'

		const a = ` 
			<a id="view_${exibicaoRecursoTO.codDuvida}" class='btn btn-white btn-table'
					 onclick='respostaRecursoVH.validarVisualizacaoRecurso(${exibicaoRecursoTO.codDuvida}, ${!isExibirRecurso})'
					 title='${title}'>
						<i class='fa ${icon}'></i>
					</a>
			`

		group.html(a);
		AmaisVH.dispararAjusteAcessibilidade();
		this.exibir()
	}

	alterarAcaoResposta(exibicaoRespostaRecursoTO, isExibirResposta: boolean) {
		const group = $(`#btn-group-${exibicaoRespostaRecursoTO.codComentario}`);
		const title = isExibirResposta ? this.getMsg("FP_FRONT_RespostaRecursoVH_025") : this.getMsg("FP_FRONT_RespostaRecursoVH_024");
		const icon = isExibirResposta ? 'fa-chevron-up' : 'fa-chevron-down'

		const a = ` 
			<a id="view_${exibicaoRespostaRecursoTO.codComentario}" class='btn btn-white btn-table'
					 onclick='respostaRecursoVH.validarVisualizacaoResposta(${exibicaoRespostaRecursoTO.codComentario}, ${!isExibirResposta})'
					 title='${title}'>
						<i class='fa ${icon}'></i>
					</a>
			`

		group.html(a);
		this.exibir()
	}

	adicionarLinhaVisualizacaoRecurso(exibicaoRecursoTO) {
		const el = $(`#listagem_linha_${exibicaoRecursoTO.codDuvida}`);
		const novaLinha = $(`
						<tr id="visualizacao_recurso_${exibicaoRecursoTO.codDuvida}">
							<td colspan='999'>
								<div id="exibicaoRecurso_${exibicaoRecursoTO.codDuvida}" class="container-recursos-pendentes" style="text-align: initial"></div>
							</td>
						</tr>
		`);
		novaLinha.hide();
		el.after(novaLinha);
		this.visualizarRecurso(exibicaoRecursoTO, `#exibicaoRecurso_${exibicaoRecursoTO.codDuvida}`)
		setTimeout(() => novaLinha.fadeIn(), 100);
	}

	adicionarLinhaVisualizacaoResposta(exibicaoRespostaRecursoTO) {
		const el = $(`#listagem_linha_${exibicaoRespostaRecursoTO.codComentario}`);
		const novaLinha = $(`
						<tr id="visualizacao_resposta_${exibicaoRespostaRecursoTO.codComentario}">
							<td colspan='999'>
								<div id="exibicaoResposta_${exibicaoRespostaRecursoTO.codComentario}" class="container-resposta-recurso" style="text-align: initial"></div>
							</td>
						</tr>
		`);
		novaLinha.hide();
		el.after(novaLinha);
		this.exibirRespostaRecurso(exibicaoRespostaRecursoTO)
		setTimeout(() => novaLinha.fadeIn(), 100);
	}


	editarRespostaRecurso({target}) {
		const div = $(target).closest("[div-resposta-recurso]").get(0);
		const textoResposta = div.querySelector("p[texto-resposta]").innerHTML;
		div.id = Date.now();
		this.setHtml(div.id, "");
		this.setIdTarget(div.id);
		let tipoRespostaRecurso = div.getAttribute("tipo-resposta-recurso");
		let tipoRecalculoRecurso = div.getAttribute("tipo-recalculo-recurso");
		const codComentario = div.getAttribute("cod-comentario");

		tipoRespostaRecurso = tipoRespostaRecurso ? TipoRespostaRecurso.from(tipoRespostaRecurso): TipoRespostaRecurso.INDEFERIMENTO;
		tipoRecalculoRecurso = tipoRecalculoRecurso ? TipoRecalculoRecurso.from(tipoRecalculoRecurso): TipoRecalculoRecurso.NENHUM;

		const containerId = this.exibirEdicaoResposta(this.recursosDaQuestaoTO,codComentario, textoResposta,
			tipoRespostaRecurso, tipoRecalculoRecurso);
		this.exibir();

		setTimeout(async () => {
			await this.atualizarOpcoesResposta(containerId);
		}, 200);
	}

	excluirRespostaRecurso({target}) {
		this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_RespostaRecursoVH_031"),
			botoes: [{
				label: this.getMsg("FP_FRONT_RespostaRecursoVH_032"),
				classe: "btn-danger",
				onClick: async () => {
					const div = $(target).closest("[div-resposta-recurso]").get(0);
					const codComentarioResposta = div.getAttribute("cod-comentario");
					await this.call("RecursoFCD/excluirRespostaRecurso", codComentarioResposta);
					await this.listarRecursosDaQuestao(this.numAbaAtiva, this.codQuestao, this.codProva, this.codAgendamento);
				}
			}]
		});
	}

	exibirRespostaRecurso(exibicaoRespostaRecursoTO) {
		const el = $(`#exibicaoResposta_${exibicaoRespostaRecursoTO.codComentario}`);

		exibicaoRespostaRecursoTO.collectionExibicaoRecursoTO.forEach(it => {
			this.visualizarRecurso(it, `#exibicaoResposta_${exibicaoRespostaRecursoTO.codComentario}`)
		})

		this.visualizarResposta(exibicaoRespostaRecursoTO, el);
	}

	visualizarResposta(exibicaoRespostaRecursoTO, el) {
		let tipoResposta = this.getMsg(exibicaoRespostaRecursoTO.tipoRespostaRecurso.idMsgSubstantivo);

		if (exibicaoRespostaRecursoTO.tipoRecalculoRecurso) {
			tipoResposta += " (" + this.getMsg(exibicaoRespostaRecursoTO.tipoRecalculoRecurso.idMsgSubstantivo).toUpperCase() + ")";
		}

		let htmlBotaoEditar = "";
		let htmlBotaoApagar = "";

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_EDICAO)) {
			htmlBotaoEditar = `
				<a class="btn btn-link btn-sm" 
				onclick='respostaRecursoVH.editarRespostaRecurso(event)'
				title='Editar esta resposta'>
					<i class="fa fa-pencil"></i>
				</a>
			`;
			htmlBotaoApagar = `
				<a class="btn btn-link btn-sm excluir-resposta-recurso" 
				onclick='respostaRecursoVH.excluirRespostaRecurso(event)'
				title='Excluir esta resposta'>
					<i class="fa fa-trash"></i>
				</a>
			`;
		}

		el.append(`
					<div
						div-resposta-recurso
						cod-comentario="${exibicaoRespostaRecursoTO.codComentario}"
						tipo-resposta-recurso="${exibicaoRespostaRecursoTO.tipoRespostaRecurso}"
						tipo-recalculo-recurso="${exibicaoRespostaRecursoTO.tipoRecalculoRecurso}"
					>
						<blockquote resposta-recurso class='blockquote'>
							<p>
								<strong>${tipoResposta}</strong>
								${htmlBotaoEditar}
								${htmlBotaoApagar}
							</p>
							<p texto-resposta>
								${exibicaoRespostaRecursoTO.textoResposta}
							</p>
							<small>${exibicaoRespostaRecursoTO.nomeAutorResposta} ${this.getMsg("MSG_VH_RC_40")} ${UtilData.toDDMMYYYY(exibicaoRespostaRecursoTO.dataResposta)}</small>
						</blockquote>
					</div>
				`);

		this.addEspacamentoHorizontal();
	}

	visualizarRecurso(exibicaoRecursoTO, seletorEl) {
		const possuiSoUmAberto = this.recursosDaQuestaoTO.collectionExibicaoRecursoTO.length == 1;

		const hashUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, exibicaoRecursoTO.codUsuarioAluno);
		const hashCorrecao = UtilHash.getHash(aplicacaoProvaVH.exibirCorrecaoRespostaQuestao, exibicaoRecursoTO.codRespostaQuestao);
		const linkUsuario = exibicaoRecursoTO.codUsuarioAluno && UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_EDICAO)
			? `<a href="${hashUsuario}" target="_blank"><i class='fa fa-user'></i></a>`
			: "";
		const linkCorrecao = this.isCfgDesabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL") && UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_EDICAO)
			? `<a href="${hashCorrecao}" target="_blank">${exibicaoRecursoTO.nomeAluno}</a>`
			: exibicaoRecursoTO.nomeAluno;
		const labelRepostaQuestao = this.isCfgDesabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL")
			? ` Resposta #${exibicaoRecursoTO.codRespostaQuestao} `
			: "";
		const linkRespostaQuestao = this.isCfgDesabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL")
			? `<a onclick="recursoVH.modalRespostaQuestao('${exibicaoRecursoTO.codRespostaQuestao}', '${exibicaoRecursoTO.codDuvida}')" >${labelRepostaQuestao}</a>`
			: labelRepostaQuestao;
		// const checked = possuiSoUmAberto ? "checked" : "";
		// const style = possuiSoUmAberto ? "display: none" : "";
		const onclick = exibicaoRecursoTO.isRespondida ? "" : `onclick="$(this).find('input').click()"`;
		const idCheckbox = this.gerarId();

		const el = $(seletorEl);
		el.append(`
			<blockquote recurso ${onclick} id="blockquote_${exibicaoRecursoTO.codDuvida} ">
				<p>
					<label for="${idCheckbox}">
						${exibicaoRecursoTO.descricao}
					</label>
				</p>
				<small recurso-detalhes>
					<strong>
						${linkUsuario}
						${linkCorrecao}									
					</strong> 
					${this.getMsg("MSG_VH_RC_39")} ${UtilData.toDDMMYYYY(exibicaoRecursoTO.dataCadastro)}
					- Recurso #${exibicaoRecursoTO.codDuvida} 
					- ${linkRespostaQuestao}
				</small>
				${exibicaoRecursoTO.pathMidia ? `
					<small>
						<a href="${exibicaoRecursoTO.pathMidia}" style="font-size: 1rem;">
							<i class="fa fa-files-o" aria-hidden="true"></i>
							${UtilArquivo.getNomeArquivo(exibicaoRecursoTO.pathMidia)}
						</a>
					</small>` : ""
		}
				<a class="btn btn-default btn-small pull-right" 
					id="btn_view_questao_recurso_${exibicaoRecursoTO.codDuvida}"
					onclick="event.stopPropagation(); respostaRecursoVH.ocultarExibirRespostaAluno(${exibicaoRecursoTO.codDuvida});"
				>
					${this.getMsg("FP_FRONT_RecursoVH_017")}
				</a>
				
				<a class="btn btn-default btn-small pull-right" 
					onclick="event.stopPropagation(); exibicaoQuestaoVH.exibirQuestaoEmPopupCB('${encodeURIComponent(JSON.stringify(this.recursosDaQuestaoTO.exibicaoQuestaoTO))}');"
				>
					${this.getMsg("FP_FRONT_RecursoVH_018")}
				</a>
				<br>
			</blockquote>
			<div recurso-exibicao-resposta-prova="true" style="display: none;" id="exibicao-resposta-tabela-${exibicaoRecursoTO.codDuvida}">
			</div>
		`);

		const a = exibicaoQuestaoVH.exibirQuestao(exibicaoRecursoTO.exibicaoQuestaoTOResposta, {retornarHtml: true});
		const respostaQuestao = $(`#exibicao-resposta-tabela-${exibicaoRecursoTO.codDuvida}`);
		if (respostaQuestao.length) {
			respostaQuestao.append(a);
		}
	}

	async ocultarExibirRespostaAluno(codDuvida) {
		const btn = $(`#btn_view_questao_recurso_${codDuvida}`);
		const resposta = $(`#exibicao-resposta-tabela-${codDuvida}`);

		if (resposta.length && btn.length) {
			if (resposta.css('display') === 'none') {
				btn.text(this.getMsg("FP_FRONT_RecursoVH_019"));
			} else {
				btn.text(this.getMsg("FP_FRONT_RecursoVH_017"));
			}
		}
		await recursoVH.showHide(`exibicao-resposta-tabela-${codDuvida}`)
	}

	async exibirRecursosPendentes(recursosDaQuestaoTO){

		let containerId = null;

		const wrapper = $('#conainer-recursos-pendentes');
		if (wrapper.length) {
			wrapper.remove();
		}

		this.addFormulario();
		this.append("<div id='conainer-recursos-pendentes'>")
		this.append("<div class='container-recursos'>");

		if (recursosDaQuestaoTO.collectionExibicaoRecursoTO.length > 0) {
			if (UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_EDICAO)) {
				containerId = this.exibirEdicaoResposta(recursosDaQuestaoTO);
			}
			await this.exibirTabelaRecursos(recursosDaQuestaoTO);
		}
		else {
			this.addTextoAlerta({
				texto: this.getMsg("MSG_VH_RC_37"),
				classes: "alerta-sem-recursos-abertos col-md-12"
			});
		}

		this.append("</div>");
		this.append("</div>");
		this.fecharFormulario();
		this.exibir();

		if (containerId) {
			await this.atualizarOpcoesResposta(containerId);
		}
	}
	
	exibirRecurso(recursosDaQuestaoTO, exibicaoRecursoTO, isExibirCheckbox = true) {
		const possuiSoUmAberto = recursosDaQuestaoTO.collectionExibicaoRecursoTO.length == 1;

		const hashUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, exibicaoRecursoTO.codUsuarioAluno);
		const hashCorrecao = UtilHash.getHash(aplicacaoProvaVH.exibirCorrecaoRespostaQuestao, exibicaoRecursoTO.codRespostaQuestao);
		const linkUsuario = exibicaoRecursoTO.codUsuarioAluno && UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_EDICAO)
			? `<a href="${hashUsuario}" target="_blank"><i class='fa fa-user'></i></a>`
			: "";
		const linkCorrecao = this.isCfgDesabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL") && UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_EDICAO)
			? `<a href="${hashCorrecao}" target="_blank">${exibicaoRecursoTO.nomeAluno}</a>`
			: exibicaoRecursoTO.nomeAluno;
		const labelRepostaQuestao = this.isCfgDesabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL")
			? ` Resposta #${exibicaoRecursoTO.codRespostaQuestao} `
			: "";
		const linkRespostaQuestao = this.isCfgDesabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL")
			? `<a onclick="recursoVH.modalRespostaQuestao('${exibicaoRecursoTO.codRespostaQuestao}', '${exibicaoRecursoTO.codDuvida}')" >${labelRepostaQuestao}</a>`
			: labelRepostaQuestao;
		const checked = possuiSoUmAberto ? "checked" : "";
		const style = possuiSoUmAberto ? "display: none" : "";
		const onclick = exibicaoRecursoTO.isRespondida ? "" : `onclick="$(this).find('input').click()"`;
		const idCheckbox = this.gerarId();
		const checkbox = isExibirCheckbox && !exibicaoRecursoTO.isRespondida && UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_EDICAO)
			? `<input type="checkbox" checkbox-recurso cod-duvida="${exibicaoRecursoTO.codDuvida}" id="${idCheckbox}" name="${idCheckbox}" ${checked} style="${style}">`
			: ""
			;

		this.append(`
			<blockquote recurso ${onclick} id="blockquote_${exibicaoRecursoTO.codDuvida} ">
				<p>
					${checkbox}
					<label for="${idCheckbox}">
						${exibicaoRecursoTO.descricao}
					</label>
				</p>
				<small recurso-detalhes>
					<strong>
						${linkUsuario}
						${linkCorrecao}
					</strong>
					${this.getMsg("MSG_VH_RC_39")} ${UtilData.toDDMMYYYY(exibicaoRecursoTO.dataCadastro)}
					- ${this.getMsg("FP_FRONT_RespostaRecursoVH_030")} #${exibicaoRecursoTO.codDuvida}
					- ${linkRespostaQuestao}
				</small>
				${exibicaoRecursoTO.pathMidia ? `
					<small>
						<a href="${exibicaoRecursoTO.pathMidia}" style="font-size: 1rem;">
							<i class="fa fa-files-o" aria-hidden="true"></i>
							${UtilArquivo.getNomeArquivo(exibicaoRecursoTO.pathMidia)}
						</a>
					</small>`: ""
				}
				<a class="btn btn-default btn-small pull-right"
					onclick="event.stopPropagation(); recursoVH.showHide('exibicao-resposta-${exibicaoRecursoTO.codDuvida}');"
				>
					${this.getMsg("FP_FRONT_RecursoVH_017")}
				</a>

				<a class="btn btn-default btn-small pull-right"
					onclick="event.stopPropagation(); exibicaoQuestaoVH.exibirQuestaoEmPopupCB('${encodeURIComponent(JSON.stringify(recursosDaQuestaoTO.exibicaoQuestaoTO))}');"
				>
					${this.getMsg("FP_FRONT_RecursoVH_018")}
				</a>
				<br>
			</blockquote>
			<div recurso-exibicao-resposta-prova="true" style="display: none;" id="exibicao-resposta-${exibicaoRecursoTO.codDuvida}">
		`);

		exibicaoQuestaoVH.exibirQuestao(exibicaoRecursoTO.exibicaoQuestaoTOResposta);
		this.append(`</div>`);
	}

	async exibirTabelaRecursos(recursosDaQuestaoTO: any) {
		const montarBotoesAplicacao = function (recurso: any) {
			const html = [`<div class='btn-group' id='btn-group-${recurso.codDuvida}'>`];
			html.push(`
				<a id="view_${recurso.codDuvida}" class='btn btn-white btn-table'
				 onclick='respostaRecursoVH.validarVisualizacaoRecurso(${recurso.codDuvida}, ${true})'
				 title='${this.getMsg("FP_FRONT_RespostaRecursoVH_022")}'>
					<i class='fa fa-chevron-down'></i>
				</a>
			`);
			html.push("</div>");
			return html.join("")
		}

		const montarFase = function (recurso: any){
			if(recurso.tipoAplicacaoFase){
				return this.getMsg(recurso.tipoAplicacaoFase.idMsg);
			} else {
				return "-";
			}
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: "<small>#${codDuvida}</small>"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RespostaRecursoVH_021"), prop: "dataCadastro", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RespostaRecursoVH_036"), prop: montarFase});
		colunas.push({titulo: this.getCfg("LABEL_ALUNO"), prop: "nomeAluno"});
		colunas.push({titulo: "", prop: montarBotoesAplicacao});

		this.recursosDaQuestaoTO = recursosDaQuestaoTO;
		await this.addTabela({
			id: "listagem_recursos",
			propId: "codDuvida",
			collection: recursosDaQuestaoTO.collectionExibicaoRecursoTO,
			colunas: colunas,
			selecao: true,
			ordenacao: [["dataCadastro", false]]
		})
	}

	selecionarRecurso(checkbox, isChecked) {
		$(checkbox).prop("checked", isChecked);
		this.setBackgroundColorSelected(checkbox, isChecked);
	}

	setBackgroundColorSelected(checkbox, isChecked){
		const blockquote = $(checkbox).closest("[recurso]");
		if(isChecked){
			blockquote.css("background-color", "#f5f5dc78");
		}
		else{
			blockquote.css("background-color", "");
		}
	}

	selecionarOuDesselecionarTodosRecursos() {
		let isChecked = this.getValor("checkboxCheckAll");
		$("#listagem_recursos input[type='checkbox']").each((i, checkbox) => {
			this.selecionarRecurso(checkbox, isChecked);
		});
	}

	verificarRecursosSelecionados() {
		let allChecked = true;
		$("[recurso] [checkbox-recurso]").each((i, checkbox) => {
			if (!$(checkbox).is(":checked")) {
				allChecked = false;
				return false;
			}
		});

		$("#checkboxCheckAll").prop("checked", allChecked);
	}

	async atualizarOpcoesResposta(containerId?: number) {
		const valorTipoRecalculoRecurso = this.getValor("tipoRecalculoRecurso_" + containerId);
		const tipoRespostaRecurso = TipoRespostaRecurso.from(this.getValor("tipoRespostaRecurso_" + containerId));
		const tipoRecalculoRecurso = TipoRecalculoRecurso.from(valorTipoRecalculoRecurso) || TipoRecalculoRecurso.NENHUM;
		const $botaoAcaoResposta = $("#botao-acao-resposta_" + containerId);

		if (tipoRespostaRecurso == null) {
			await this.hide($botaoAcaoResposta);
			return;
		}
		
		await this.show($botaoAcaoResposta);

		await this.hide(
			"tipoRecalculoRecurso_" + containerId,
			tipoRespostaRecurso === TipoRespostaRecurso.INDEFERIMENTO, 
			500
		);
		await this.show(
			"codItemNovoGabaritoME_" + containerId,
			"codItemNovoGabaritoCE_" + containerId,
			"novoGabaritoValorExato_" + containerId,
			tipoRespostaRecurso !== TipoRespostaRecurso.INDEFERIMENTO && tipoRecalculoRecurso === TipoRecalculoRecurso.ALTERACAO_GABARITO,
			500
		);

		let labelTipoRecalculo = this.getMsg(tipoRecalculoRecurso.idMsg);
		let labelTipoResposta = this.getMsg(tipoRespostaRecurso.idMsg);
		const labelPara = this.getMsg("FP_FRONT_RespostaRecursoVH_016");
		
		if (tipoRecalculoRecurso == null || tipoRespostaRecurso === TipoRespostaRecurso.INDEFERIMENTO || tipoRecalculoRecurso === TipoRecalculoRecurso.NENHUM) {
			$botaoAcaoResposta.text(labelTipoResposta);

		} else {
			$botaoAcaoResposta.text(`${labelTipoRecalculo} ${labelPara} ${labelTipoResposta.toLowerCase()}`);
		}
	}

	getSalvamentoRespostaRecursoTO(idEditorResposta, codComentarioResposta, containerId): SalvamentoRespostaRecursoTO {

		const codsDuvidas = Array.from(document.querySelectorAll("input[codduvida]"))
			.filter((checkbox: HTMLInputElement) => checkbox.checked)
			.map((checkbox: HTMLInputElement) => Number(checkbox.getAttribute("codduvida")))
		;

		console.log(codsDuvidas);

		const salvamentoRespostaRecursoTO: SalvamentoRespostaRecursoTO = {
			codComentarioResposta: codComentarioResposta,
			codsDuvidas: codsDuvidas,
			tipoRespostaRecurso: TipoRespostaRecurso.from(this.getValor("tipoRespostaRecurso_" + containerId)),
			tipoRecalculoRecurso: TipoRecalculoRecurso.from(this.getValor("tipoRecalculoRecurso_" + containerId)),
			textoResposta: this.getValor(idEditorResposta),
			anulacaoQuestaoTO: null,
			alteracaoGabaritoComRecalculoTO: null,
			listaSalvamentoCorrecaoDiscursivaTO: null
		}

		if (salvamentoRespostaRecursoTO.tipoRespostaRecurso === TipoRespostaRecurso.INDEFERIMENTO) {
			salvamentoRespostaRecursoTO.tipoRecalculoRecurso = null;
		}

		if (!salvamentoRespostaRecursoTO.codComentarioResposta && this.isEmpty(salvamentoRespostaRecursoTO.codsDuvidas)) {
			this.exibirAlerta({ msg: this.getMsg("MSG_VH_RC_48") });
			return null;
		}

		if (this.isEmpty(salvamentoRespostaRecursoTO.textoResposta)) {
			this.exibirAlerta({ msg: this.getMsg("MSG_VH_RC_49") });
			return null;
		}

		return salvamentoRespostaRecursoTO;
	}

	async salvarRespostaRecurso(salvamentoRespostaRecursoTO: SalvamentoRespostaRecursoTO) {
		await this.call("RecursoFCD/salvarResposta", salvamentoRespostaRecursoTO);
		await this.listarRecursosDaQuestao(this.numAbaAtiva, this.codQuestao, this.codProva, this.codAgendamento);
	}

	exibirEdicaoResposta(recursosDaQuestaoTO, codComentarioResposta?: number, textoResposta?: string, valorTipoRespostaRecurso?: TipoRespostaRecurso,  valorTipoRecalculoRecurso?: TipoRecalculoRecurso) {

		this.append(`<div id='container-resposta' class='${!valorTipoRespostaRecurso ? "fix-container-resposta-recurso": ""}'>`);
		const possuiSoUmAberto = recursosDaQuestaoTO.collectionExibicaoRecursoTO.length <= 1;
		let containerId = Date.now();

		this.addEspacamentoHorizontal();

		this.addCheckbox({
			id: "checkboxCheckAll",
			css: "margin-bottom: 0px;min-height: 20px;",
			label: this.getMsg("MSG_VH_RC_61"),
			visivel: !possuiSoUmAberto && !valorTipoRespostaRecurso,
			onChange: () => this.selecionarOuDesselecionarTodosRecursos()
		});

		this.addEspacamentoHorizontal();

		const idEditorResposta = "textoRespostaRecurso_" + containerId;

		this.addEditorHTML({
			id: idEditorResposta,
			classe: "col-md-12",
			css: "margin-bottom: 5px;",
			label: this.getMsg("MSG_VH_RC_41") + ":",
			toolbar: "basica",
			valor: textoResposta
		});

		this.addEspacamentoHorizontal();
		
		this.addRadioGroup({
			collection: [
				TipoRespostaRecurso.INDEFERIMENTO,
				TipoRespostaRecurso.DEFERIMENTO_PARCIAL,
				TipoRespostaRecurso.DEFERIMENTO,
			],
			label: "Resultado da análise",
			css: "margin-bottom: 5px;",
			id: "tipoRespostaRecurso_" + containerId,
			valor: valorTipoRespostaRecurso || TipoRespostaRecurso.INDEFERIMENTO,
			onChange: async () => await this.atualizarOpcoesResposta(containerId),
		});

		const isDiscursiva = recursosDaQuestaoTO.exibicaoQuestaoTO.tipoQuestao == "DC";
		const isAnulada = recursosDaQuestaoTO.exibicaoQuestaoTO.isAnulada;
		const exibicaoQuestaoTO = recursosDaQuestaoTO.exibicaoQuestaoTO;

		this.addRadioGroup({
			collection: [
				TipoRecalculoRecurso.NENHUM,
				isDiscursiva ? TipoRecalculoRecurso.RECORRECAO_DISCURSIVA : null,
				isDiscursiva || isAnulada ? null : TipoRecalculoRecurso.ALTERACAO_GABARITO,
				isAnulada ? null : TipoRecalculoRecurso.ANULACAO,
			].filter(o => o != null),
			css: "margin-bottom: 5px;",
			id: "tipoRecalculoRecurso_" + containerId,
			label: "Recálculo de pontuação/nota",
			valor: valorTipoRecalculoRecurso || TipoRecalculoRecurso.NENHUM,
			onChange: async () => await this.atualizarOpcoesResposta(containerId),
		});

		if (exibicaoQuestaoTO.tipoQuestao == "ME") {
			this.addRadioGroup({
				collection: exibicaoQuestaoTO.collectionExibicaoItemTO.filter(i => i.gabaritoDoItem == false).map((exibicaoItemTO: any) => { 
					return {
						id: exibicaoItemTO.codItem,
						descricao: exibicaoItemTO.labelNumeracao
					};
				}),
				id: "codItemNovoGabaritoME_" + containerId,
				label: "Novo gabarito",
				css: "margin-bottom: 5px;"
			});
		} else if (exibicaoQuestaoTO.tipoQuestao == "CE") {
			this.addRadioGroup({
				collection: exibicaoQuestaoTO.collectionExibicaoItemTO.map((exibicaoItemTO: any) => { 
					return {
						id: exibicaoItemTO.codItem,
						descricao: exibicaoItemTO.labelNumeracao + (exibicaoItemTO.gabaritoDoItem ? " E" : " C")
					};
				}),
				id: "codItemNovoGabaritoCE_" + containerId,
				label: "Novo gabarito",
				css: "margin-bottom: 5px;"
			});
		} else if (exibicaoQuestaoTO.tipoQuestao == "EX") {
			this.addCampoTexto({
				id: "novoGabaritoValorExato_" + containerId,
				label: "Novo gabarito",
				valor: exibicaoQuestaoTO.respostaValorExatoCorreta,
				css: "margin-bottom: 5px;"
			});
		}

		this.addEspacamentoHorizontal();

		this.addBotao({
			label: "",
			id: "botao-acao-resposta_" + containerId,
			css: 'margin: 0 0 10px 10px;',
			classe: "btn-primary",
			onClick: async () => {
				const salvamentoRespostaRecursoTO = this.getSalvamentoRespostaRecursoTO(idEditorResposta, codComentarioResposta, containerId);

				if (salvamentoRespostaRecursoTO == null) return;

				const cfgsFluxoTela = new CfgsFluxoTela();

				cfgsFluxoTela.titulo = this.getMsg("FP_FRONT_RespostaRecursoVH_017");
				cfgsFluxoTela.labelBotaoFinalizacao = this.getMsg("FP_FRONT_RespostaRecursoVH_018");
				cfgsFluxoTela.classeBotaoFinalizacao = "btn btn-primary";
				cfgsFluxoTela.isFluxoRecursos = true;

				if (salvamentoRespostaRecursoTO.tipoRecalculoRecurso == null || salvamentoRespostaRecursoTO.tipoRecalculoRecurso === TipoRecalculoRecurso.NENHUM) {
					try {
						await this.salvarRespostaRecurso(salvamentoRespostaRecursoTO);
					}
					catch (e) {
						console.log(e);
					}
	
				} else if (salvamentoRespostaRecursoTO.tipoRecalculoRecurso === TipoRecalculoRecurso.ALTERACAO_GABARITO) {
					let codItemNovoGabarito = null;
					let novoGabaritoValorExato = null;

					if (exibicaoQuestaoTO.tipoQuestao == "ME") {
						codItemNovoGabarito = this.getValor("codItemNovoGabaritoME_" + containerId);
						if (!codItemNovoGabarito) {
							await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RespostaRecursoVH_019") });
							return;
						}
					} else if (exibicaoQuestaoTO.tipoQuestao == "CE") {
						codItemNovoGabarito = this.getValor("codItemNovoGabaritoCE_" + containerId);
						if (!codItemNovoGabarito) {
							await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RespostaRecursoVH_019") });
							return;
						}
					} else if (exibicaoQuestaoTO.tipoQuestao == "EX") {
						novoGabaritoValorExato = this.getValor("novoGabaritoValorExato_" + containerId);
						if (this.isEmpty(novoGabaritoValorExato)) {
							await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RespostaRecursoVH_019") });
							return;
						}
					}

					cfgsFluxoTela.subtitulo = `Alteração de gabarito da questão #${exibicaoQuestaoTO.codQuestao}`;
					cfgsFluxoTela.onClickBotaoFinalizacao = (alteracaoGabaritoComRecalculoTO: AlteracaoGabaritoComRecalculoTO) => {
						salvamentoRespostaRecursoTO.alteracaoGabaritoComRecalculoTO = alteracaoGabaritoComRecalculoTO;
						this.exibirConferenciaRespostaComRecalculo(recursosDaQuestaoTO, salvamentoRespostaRecursoTO);
					}
					try {
						await cadastroQuestaoVH.exibirImpactoAlteracaoGabarito(exibicaoQuestaoTO.codQuestao, codItemNovoGabarito, novoGabaritoValorExato, cfgsFluxoTela);
					}
					catch (e) {
						console.log(e);
					}

				} else if (salvamentoRespostaRecursoTO.tipoRecalculoRecurso === TipoRecalculoRecurso.ANULACAO) {
					
					cfgsFluxoTela.subtitulo = `Anulação da questão #${exibicaoQuestaoTO.codQuestao}`;
					cfgsFluxoTela.onClickBotaoFinalizacao = (anulacaoQuestaoTO: AnulacaoQuestaoTO) => {
						salvamentoRespostaRecursoTO.anulacaoQuestaoTO = anulacaoQuestaoTO;
						this.exibirConferenciaRespostaComRecalculo(recursosDaQuestaoTO, salvamentoRespostaRecursoTO);
					}
					
					cadastroQuestaoVH.anular(recursosDaQuestaoTO.exibicaoQuestaoTO.codQuestao, cfgsFluxoTela);
	
				} else if (salvamentoRespostaRecursoTO.tipoRecalculoRecurso === TipoRecalculoRecurso.RECORRECAO_DISCURSIVA) {
					
					salvamentoRespostaRecursoTO.listaSalvamentoCorrecaoDiscursivaTO = [];

					let listaExibicaoRecursoTOParaRecorrecao = null;

					if (salvamentoRespostaRecursoTO.codComentarioResposta) {
						listaExibicaoRecursoTOParaRecorrecao = this.getListaExibicaoRecursoTORespondidos(recursosDaQuestaoTO, salvamentoRespostaRecursoTO);
					} else {
						listaExibicaoRecursoTOParaRecorrecao = this.getListaExibicaoRecursoTOQueSeraoRespondidos(recursosDaQuestaoTO, salvamentoRespostaRecursoTO);
					}

					const codRespostaQuestaoInicial = listaExibicaoRecursoTOParaRecorrecao[0].codRespostaQuestao;

					cfgsFluxoTela.subtitulo = cfgsFluxoTela.titulo;
					cfgsFluxoTela.subtitulo = null;
					cfgsFluxoTela.labelBotaoFinalizacao = this.getMsg("FP_FRONT_RespostaRecursoVH_020");

					cfgsFluxoTela.onClickBotaoFinalizacao = (salvamentoCorrecaoDiscursivaTO: SalvamentoCorrecaoDiscursivaTO) => {

						salvamentoRespostaRecursoTO.listaSalvamentoCorrecaoDiscursivaTO.push(salvamentoCorrecaoDiscursivaTO);

						listaExibicaoRecursoTOParaRecorrecao = listaExibicaoRecursoTOParaRecorrecao
							.filter(exibicaoRecursoTO => exibicaoRecursoTO.codRespostaQuestao != salvamentoCorrecaoDiscursivaTO.codRespostaQuestao);

						if (listaExibicaoRecursoTOParaRecorrecao.length == 1) {
							cfgsFluxoTela.labelBotaoFinalizacao = this.getMsg("FP_FRONT_RespostaRecursoVH_018");
						}

						if (listaExibicaoRecursoTOParaRecorrecao.length > 0) {
							correcaoDiscursivasVH.exibirTelaCorrecao(listaExibicaoRecursoTOParaRecorrecao[0].codRespostaQuestao, null, cfgsFluxoTela);

						} else {
							this.exibirConferenciaRespostaComRecalculo(recursosDaQuestaoTO, salvamentoRespostaRecursoTO);
						}
					}
					
					await correcaoDiscursivasVH.exibirTelaCorrecao(codRespostaQuestaoInicial, null, cfgsFluxoTela);
				}
			}
		});

		this.append("</div>");

		this.addEspacamentoHorizontal("20px");

		return containerId;
	}

	async onAtualizarQuestao(questao) {
		const codQuestao = $(questao).attr("cod-questao");
		const exibicaoQuestaoTO = await this.call("AssociacaoQuestaoFCD/buscarQuestaoPorCodigo", this.codProva, codQuestao);
		exibicaoQuestaoVH.atualizarExibicaoQuestao(questao, exibicaoQuestaoTO);
	}

	getListaExibicaoRecursoTOQueSeraoRespondidos(recursosDaQuestaoTO, salvamentoRespostaRecursoTO) {
		return recursosDaQuestaoTO.collectionExibicaoRecursoTO.filter(
			exibicaoRecursoTO => salvamentoRespostaRecursoTO.codsDuvidas.includes(exibicaoRecursoTO.codDuvida));
	}

	getListaExibicaoRecursoTORespondidos(recursosDaQuestaoTO, salvamentoRespostaRecursoTO) {

		const collectionExibicaoRespostaRecursoTO = recursosDaQuestaoTO ? recursosDaQuestaoTO.collectionExibicaoRespostaRecursoTO: null;
		const codComentarioResposta = salvamentoRespostaRecursoTO ? +salvamentoRespostaRecursoTO.codComentarioResposta: null;

		if(this.hasValue(collectionExibicaoRespostaRecursoTO) && codComentarioResposta){
			let exibicaoRespostaRecursoTO = collectionExibicaoRespostaRecursoTO.find(x => x.codComentario === codComentarioResposta);

			if(exibicaoRespostaRecursoTO && exibicaoRespostaRecursoTO.collectionExibicaoRecursoTO){
				return exibicaoRespostaRecursoTO.collectionExibicaoRecursoTO
			}
		}
	}

	exibirConferenciaRespostaComRecalculo(recursosDaQuestaoTO, salvamentoRespostaRecursoTO: SalvamentoRespostaRecursoTO) {

		const listaExibicaoRecursoTOQueSeraoRespondidos = this.getListaExibicaoRecursoTOQueSeraoRespondidos(recursosDaQuestaoTO, salvamentoRespostaRecursoTO);
		let labelSubstantivoVerbal = this.getMsg(salvamentoRespostaRecursoTO.tipoRecalculoRecurso.idMsgSubstantivo);

		if (salvamentoRespostaRecursoTO.anulacaoQuestaoTO) {
			for (const exibicaoRecursoTOQueSeraRespondido of listaExibicaoRecursoTOQueSeraoRespondidos) {
				if (!salvamentoRespostaRecursoTO.anulacaoQuestaoTO.idsProvasFeitas.includes(exibicaoRecursoTOQueSeraRespondido.codProvaFeita)) {
					this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RespostaRecursoVH_008", labelSubstantivoVerbal.toLowerCase()) });
					return;
				}
			}

		} else if (salvamentoRespostaRecursoTO.alteracaoGabaritoComRecalculoTO) {
			for (const exibicaoRecursoTOQueSeraRespondido of listaExibicaoRecursoTOQueSeraoRespondidos) {
				if (!salvamentoRespostaRecursoTO.alteracaoGabaritoComRecalculoTO.idsProvasFeitas.includes(exibicaoRecursoTOQueSeraRespondido.codProvaFeita)) {
					this.exibirAlerta({ msg: this.getMsg("FP_FRONT_RespostaRecursoVH_008", labelSubstantivoVerbal.toLowerCase()) });
					return;
				}
			}
		}
	
		this.addPopup({
			titulo: this.getMsg("FP_FRONT_RespostaRecursoVH_009", labelSubstantivoVerbal.toLowerCase()),
			width: "80%",
			botoes: [{
				label: `${this.getMsg(salvamentoRespostaRecursoTO.tipoRespostaRecurso.idMsg)} com ${labelSubstantivoVerbal.toLowerCase()}`,
				classe: "btn-danger",
				onClick: async () => {
					try{
						await this.salvarRespostaRecurso(salvamentoRespostaRecursoTO);
					}
					catch (e) {
						console.log(e);
					}
				}
			}]
		});
		
		this.addCampoExibicao({
			label: "Prova", 
			valor: recursosDaQuestaoTO.tituloProva,
			classe: "col-md-6",
		});

		this.addCampoExibicao({
			label: "Seção", 
			valor: recursosDaQuestaoTO.nomeSecao,
			classe: "col-md-6",
		});

		this.addSubtitulo(this.getMsg("FP_FRONT_RespostaRecursoVH_014"));

		this.append("<div class='col-md-12'>");

		listaExibicaoRecursoTOQueSeraoRespondidos.forEach(exibicaoRecursoTO => {
			this.exibirRecurso(recursosDaQuestaoTO, exibicaoRecursoTO, false);
		});

		this.append("</div>");

		this.addSubtitulo("Resposta");

		this.addCampoExibicao({
			label: this.getMsg("FP_FRONT_RespostaRecursoVH_015"), 
			valor: salvamentoRespostaRecursoTO.textoResposta ,
			classe: "col-md-6",
		});

		this.exibirPopups();
	}
}

const respostaRecursoVH = new RespostaRecursoVH();

class SalvamentoRespostaRecursoTO {
	codComentarioResposta: number;
	codsDuvidas: number[];
	tipoRespostaRecurso: TipoRespostaRecurso;
	tipoRecalculoRecurso: TipoRecalculoRecurso;
	textoResposta: string;
	anulacaoQuestaoTO: AnulacaoQuestaoTO;
	alteracaoGabaritoComRecalculoTO: AlteracaoGabaritoComRecalculoTO;
	listaSalvamentoCorrecaoDiscursivaTO: SalvamentoCorrecaoDiscursivaTO[];
}

class TipoRespostaRecurso extends EnumFP { 

	static INDEFERIMENTO = new TipoRespostaRecurso("INDEFERIMENTO", "FP_FRONT_RespostaRecursoVH_001", "FP_FRONT_RecursoVH_002");
	static DEFERIMENTO_PARCIAL = new TipoRespostaRecurso("DEFERIMENTO_PARCIAL", "FP_FRONT_RespostaRecursoVH_003", "FP_FRONT_RecursoVH_011");
	static DEFERIMENTO = new TipoRespostaRecurso("DEFERIMENTO", "FP_FRONT_RespostaRecursoVH_002", "FP_FRONT_RecursoVH_001");

	readonly idMsgSubstantivo: string;

	constructor(id: string, idMsg: string, idMsgSubstantivo: string) {
		super(TipoRespostaRecurso.name, id, idMsg);
		this.idMsgSubstantivo = idMsgSubstantivo;
	}

	static from(id: string): TipoRecalculoRecurso {
		return <TipoRecalculoRecurso> EnumFP.from(TipoRespostaRecurso.name, id);
	}
}

class TipoRecalculoRecurso extends EnumFP { 

	static NENHUM = new TipoRecalculoRecurso("NENHUM", "FP_FRONT_RespostaRecursoVH_004", "FP_FRONT_RespostaRecursoVH_010");
	static ANULACAO = new TipoRecalculoRecurso("ANULACAO", "FP_FRONT_RespostaRecursoVH_007", "FP_FRONT_RespostaRecursoVH_011");
	static RECORRECAO_DISCURSIVA = new TipoRecalculoRecurso("RECORRECAO_DISCURSIVA", "FP_FRONT_RespostaRecursoVH_005", "FP_FRONT_RespostaRecursoVH_012");
	static ALTERACAO_GABARITO = new TipoRecalculoRecurso("ALTERACAO_GABARITO", "FP_FRONT_RespostaRecursoVH_006", "FP_FRONT_RespostaRecursoVH_013");
	
	readonly idMsgSubstantivo: string;

	constructor(id: string, idMsg: string, idMsgSubstantivo: string) {
		super(TipoRecalculoRecurso.name, id, idMsg);
		this.idMsgSubstantivo = idMsgSubstantivo;
	}

	static from(id: string): TipoRecalculoRecurso {
		return <TipoRecalculoRecurso> EnumFP.from(TipoRecalculoRecurso.name, id);
	}
}