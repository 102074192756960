class RelHistogramaVH extends DataWarehouseVH {
	
	ordenacao: any;
	colunas: ColunaAddTabela[];

	constructor() {
		super(RelHistogramaVH.name);
	}

	async exibirRelHistogramaPorSegmento() {
		return this.exibirRelHistograma(<CfgsConsultaIndicePFsTO> {
			campoAgrupamento: CampoIndicePFs.NOME_EMPRESA_USUARIO
		});
	}

	async exibirRelHistogramaPorProva() {
		return this.exibirRelHistograma(<CfgsConsultaIndicePFsTO> {
			campoAgrupamento: CampoIndicePFs.NOME_PROVA
		});
	}

	async exibirRelHistogramaPorTurma() {
		return this.exibirRelHistograma(<CfgsConsultaIndicePFsTO> {
			campoAgrupamento: CampoIndicePFs.NOME_TURMA_NA_APLICACAO
		});
	}

	async exibirRelHistogramaPorUsuario() {
		return this.exibirRelHistograma(<CfgsConsultaIndicePFsTO> {
			campoAgrupamento: CampoIndicePFs.NOME_USUARIO
		});
	}

	async exibirRelHistograma(cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO) {

		this.ordenacao = null;

		cfgsConsultaIndicePFsTO.paginacaoTO  = {
			numItensPorPagina: 10,
			numPaginaAtual: 0,
			numTotalItens: null
		};

		let resultadoHistogramaTO = await this.call({
			endpoint: "RelatorioHistogramaFCD/gerar",
			params: [cfgsConsultaIndicePFsTO],
			blockUiOnCarregando: true,
		});

		this.colunas = [
			{titulo: cfgsConsultaIndicePFsTO.campoAgrupamento.getNome(), prop: "nome", classe: "descricao" },
			{titulo: "0% a 9%", prop: "freq0", formato: "numero"},
			{titulo: "10% a 19%", prop: "freq1", formato: "numero"},
			{titulo: "20% a 29%", prop: "freq2", formato: "numero"},
			{titulo: "30% a 39%", prop: "freq3", formato: "numero"},
			{titulo: "40% a 49%", prop: "freq4", formato: "numero"},
			{titulo: "50% a 59%", prop: "freq5", formato: "numero"},
			{titulo: "60% a 69%", prop: "freq6", formato: "numero"},
			{titulo: "70% a 79%", prop: "freq7", formato: "numero"},
			{titulo: "80% a 89%", prop: "freq8", formato: "numero"},
			{titulo: "90% a 100%", prop: "freq9", formato: "numero"}
		];

		// this.limpar(true);

		// this.addFiltrosPFs({
		// 	onAplicarFiltros: async () => {
		// 		cfgsConsultaIndicePFsTO = this.getCfgsConsultaIndicePFsTO();
		//
		// 		resultadoHistogramaTO = await this.call("RelatorioHistogramaFCD/gerar", cfgsConsultaIndicePFsTO);
		//
		// 		await this.exibirTabelaEGrafico(cfgsConsultaIndicePFsTO, resultadoHistogramaTO);
		// 	},
		// 	cfgsConsultaIndicePFsTO: cfgsConsultaIndicePFsTO,
		// 	suprimirCamposOpcionais: true
		// });

		// this.abrirAbaAccordion({
		// 	id: 'accordion_grafico_rel_histograma',
		// 	titulo: this.getMsg("FP_FRONT_RelAcertosErrosVH_001"),
		// 	aberta: true
		// });

		this.append(`
				<div id='grafico_rel_histograma_wrapper' style='width: 100%; overflow: auto; margin: 10px 0'>
				</div>
		`);

		// this.fecharAbaAccordion();
		// this.fecharGrupoAccordion();

		if(this.isAluno()){
			this.abrirAbaAccordion({
				id: 'accordion_tabela_rel_histograma',
				titulo: this.getMsg("FP_FRONT_RelAcertosErrosVH_002"),
				aberta: false
			});

			this.append(`
				<div id="tabela_rel_histograma" class="tabela-estatisticas"></div>
			`);

			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();
		} else {
			this.append(`
				<div class="row">
					<div id="tabela_rel_histograma" class="tabela-estatisticas col-md-12"></div>
				</div>
			`);
		}

		this.exibir();

		await this.exibirTabelaEGrafico(cfgsConsultaIndicePFsTO, resultadoHistogramaTO);

		if(!this.isAluno()) this.collapseAccordion("accordion_grafico_rel_histograma");
	}

	async exibirGrafico(listaGrupoHistogramaTO: any, colunas: ColunaAddTabela[]) {

		const el = $("#grafico_rel_histograma_wrapper");
		const tamanho = listaGrupoHistogramaTO && listaGrupoHistogramaTO.length || 0;

		const tamGrafico = (320 + tamanho * 5) + 10;

		el.css('height', tamanho > 0 ? `${tamGrafico > 600 ? 600 :tamGrafico}px;` : '')

		if (!listaGrupoHistogramaTO || !listaGrupoHistogramaTO.length) {
			el.html(`
				<div>${this.getMsg('MSG_VH_RAE_46')}</div>
			`);
			return;

		}

		el.html(`
			<div id='grafico_rel_histograma' class='ct-chart' style='width: 100%; height: ${tamGrafico > 600 ? 600 :tamGrafico}px; margin: 0 auto;'>
			</div>
		`);

		const categorias = colunas.slice(1).map(item => item.titulo);

		const series = listaGrupoHistogramaTO.map(item => {
			const nome = item.nome;
			const dados = [];

			for (let i = 0; i <= 9; i++) {
				const freqValue = item[`freq${i}`];
				dados.push(freqValue);
			}

			return { nome, dados };
		});

		await UtilGrafico.criarEChart("#grafico_rel_histograma", {
			categorias: categorias,
			series: series,
			tipo: "LINHAS_MULTIPLAS",
			titulo: this.getMsg("FP_FRONT_MonitoramentoVH_003")
		});
	}

	async exibirTabelaEGrafico(cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO, resultadoHistogramaTO: any) {

		$("#tabela_rel_histograma").html("");
		this.setIdTarget("tabela_rel_histograma");

		const exportTodosResultados = async() => {
			this.disable("botao_export_all");
			const divMsgAjax = AmaisVH.criarDivMsgAjax(this.getMsg("MSG_VH_RAE_41"));

			let cfgsConsultaIndicePFsCopyTO: any = {...cfgsConsultaIndicePFsTO};
			let colunasXlsx = this.colunas.map(item => {
				let identificador = item.prop;
				return {
					nome: item.titulo, identificador: identificador, tipo: item.formato
				}
			});

			cfgsConsultaIndicePFsCopyTO['paginacaoTO'] = {
				numPaginaAtual: 0, numItensPorPagina: 9999
			}

			let resultados = await this.call("RelatorioHistogramaFCD/gerar", cfgsConsultaIndicePFsCopyTO);

			await UtilXlsx.criarXlsx(
				colunasXlsx,
				resultados.listaGrupoHistogramaTO,
				"export_histograma_" + cfgsConsultaIndicePFsTO.campoAgrupamento.getNome().toString().toLowerCase()
			);

			divMsgAjax.remove();
			this.enable("botao_export_all");
		}
		this.append('<div id="tabela_histograma_container" style="display: none">')
		await this.addTabela({
			id: 'rel-histograma',
			collection: resultadoHistogramaTO.listaGrupoHistogramaTO,
			propId: "cod",
			colunas: this.colunas,
			paginacao: {
				paginacaoTO: resultadoHistogramaTO.paginacaoTO,
				exibirPaginacaoRodape: true,
				onCarregarPagina: async (paginacaoTO) => {

					cfgsConsultaIndicePFsTO.paginacaoTO = paginacaoTO;

					resultadoHistogramaTO = await this.call("RelatorioHistogramaFCD/gerar", cfgsConsultaIndicePFsTO);

					await this.exibirGrafico(resultadoHistogramaTO.listaGrupoHistogramaTO, this.colunas);

					return resultadoHistogramaTO.listaGrupoHistogramaTO;
				}
			},
			// hashEdicao: !this.isAdministrador() ? null : cfgsConsultaIndicePFsTO.campoAgrupamento.getHashEdicao(),
			//ordenacao: this.ordenacao,
			// onOrdenacaoColuna: async (coluna: any[], isOrdenacaoDecrescente: boolean) => {
			//
			// 	const propColuna = coluna[1];
			//
			// 	if (propColuna === "nome") {
			// 		cfgsConsultaIndicePFsTO.campoOrdenacao = cfgsConsultaIndicePFsTO.campoAgrupamento;
			// 	} else {
			// 		cfgsConsultaIndicePFsTO.campoOrdenacao = CampoIndicePFs.fromNomeProp(propColuna);
			// 	}
			//
			// 	if (cfgsConsultaIndicePFsTO.campoOrdenacao == null) {
			// 		this.logger.warn("Coluna " + propColuna + " sem correspondência.");
			// 		return;
			// 	}
			//
			// 	cfgsConsultaIndicePFsTO.isOrdenacaoDecrescente = isOrdenacaoDecrescente;
			// 	cfgsConsultaIndicePFsTO.paginacaoTO = null;
			//
			// 	resultadoHistogramaTO = await this.call("RelatorioHistogramaFCD/gerar", cfgsConsultaIndicePFsTO);
			//
			// 	this.ordenacao = [[propColuna, isOrdenacaoDecrescente ? 1 : 0]];
			//
			// 	await this.exibirTabelaEGrafico(cfgsConsultaIndicePFsTO, resultadoHistogramaTO);
			// },
		});
		this.append('</div>');

		this.exibir();

		await this.exibirGrafico(resultadoHistogramaTO.listaGrupoHistogramaTO, this.colunas);

		$("#botao_export_all").click(exportTodosResultados);
	}
}

const relHistogramaVH = new RelHistogramaVH();