class CadastroAOVH extends AmaisVH {

	numFase: number;
	todasQuestoesTemTempo: any;
	listagensAuxiliaresAgendamentoProvaTO: ListagensAuxiliaresAgendamentoProvaTO;
	edicaoAgendamentoProvaTO: any;
	possuiFiscal: boolean;
	dataEncerramentoAgendamento: any;
	dataFinalizacaoAgendamento: any;
	codsTurmasManterUsuarios: any = [];
	codsUsuariosArquivarPF: any = [];
	edicaoFasesAOTO: any;

	constructor() {
		super("CadastroAOVH");
		this.addOperacaoParaHash("caoe", this.exibirAbasEdicaoAO);
		this.addOperacaoParaHash("cavp", this.verificarSeHaPendenciasParaNovaFase);
	}

	async exibirAbasEdicaoAO(numAbaESubaba: number | string, codAgendamento: number, codProva?: number) {

		UtilHash.registrarHistorico(this.exibirAbasEdicaoAO, numAbaESubaba, codAgendamento, codProva);

		const dadosAbasEdicaoAOTO = await this.call("CadastroAOFCD/recuperarDadosAbasEdicaoAO", codAgendamento, codProva);
	
		this.limpar();

		if (dadosAbasEdicaoAOTO.codAgendamento != null) {
			this.setTitulo(`<i class='fa fa-laptop' style='font-size: 71%;'></i> ${this.getMsg('MSG_VH_AP_29')} <small>#${dadosAbasEdicaoAOTO.codAgendamento}</small>`);
		} else {
			this.setTitulo(`<i class='fa fa-laptop' style='font-size: 71%;'></i> ${this.getMsg('MSG_VH_AP_30')}`);
		}

		let hashEdicaoProva = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 3, dadosAbasEdicaoAOTO.codProva);
		this.setSubtitulo(`<a href='${hashEdicaoProva}'>${dadosAbasEdicaoAOTO.tituloProva}</a>`);

		let abas = [{
			id: "edicaoAOConfiguracoes",
			label: this.getMsg("FP_FRONT_CadastroAOVH_001"),
			onClick: () => this.editarAplicacaoOnline(dadosAbasEdicaoAOTO.codAgendamento, dadosAbasEdicaoAOTO.codProva),
			habilitada: true
		}, {
			id: "edicaoAOFases",
			label: this.getMsg("FP_FRONT_CadastroAOVH_002"),
			onClick: () => this.editarFases(dadosAbasEdicaoAOTO.codAgendamento),
			habilitada: dadosAbasEdicaoAOTO.codAgendamento != null
		}, {
			id: "edicaoAOInscricoes",
			label: this.getMsg("FP_FRONT_CadastroAOVH_003"),
			onClick: () => this.editarInscricoes(dadosAbasEdicaoAOTO.codAgendamento, dadosAbasEdicaoAOTO.codProva),
			habilitada: dadosAbasEdicaoAOTO.codAgendamento != null
		}];

		this.addAbas({ abas, numAbaAtiva: numAbaESubaba });

		this.exibir();
	}

	async editarAplicacaoOnline(codAgendamento, codProva) {

		this.todasQuestoesTemTempo = null;

		const edicaoAgendamentoProvaTO = await this.call("CadastroAOFCD/recuperarAO", codAgendamento, codProva);
		
		const listagensAuxiliaresAgendamentoProvaTO: ListagensAuxiliaresAgendamentoProvaTO = edicaoAgendamentoProvaTO.listagensAuxiliaresAgendamentoProvaTO

		const podeEditar = this.isAdministrador() || !edicaoAgendamentoProvaTO.isAplicacaoTemplate;

		this.listagensAuxiliaresAgendamentoProvaTO = listagensAuxiliaresAgendamentoProvaTO;
		this.edicaoAgendamentoProvaTO = edicaoAgendamentoProvaTO;

		this.limpar(true);

		this.append(`
			<div class='col-md-12' edicao-ao-configuracoes cod-agendamento='${edicaoAgendamentoProvaTO.codAgendamento || ""}'>
				<div class="row">
		`);

		let numTentativasAtual = "";
		let valorNumTentativas = "UMA";

		if (edicaoAgendamentoProvaTO.numTentativas != null) {
			if (edicaoAgendamentoProvaTO.numTentativas == 1) {
				valorNumTentativas = "UMA";
			} else if (edicaoAgendamentoProvaTO.numTentativas == -1) {
				valorNumTentativas = "INDETERMINADO";
			} else {
				numTentativasAtual = edicaoAgendamentoProvaTO.numTentativas;
				valorNumTentativas = "ESPECIFICO";
			}
		}

		let htmlNumTentativas = this.addRadioGroup({
			collection: [
				{ id: "UMA", descricao: this.getMsg("FP_FRONT_CadastroAOVH_004") },
				{ id: "INDETERMINADO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_005") },
				{
					id: "ESPECIFICO", descricao: `
					<div class='input-group col-md-9'>
						<input type='number' class='form-control num-tentativas' min='1' max='100' value='${numTentativasAtual}' style='text-align: center' onclick='cadastroAOVH.setValor("numTentativas", "ESPECIFICO")'>
						<span class='input-group-addon'>${this.getMsg("FP_FRONT_CadastroAOVH_008")}</span>
					</div>
				`}
			],
			label: this.getMsg("FP_FRONT_CadastroAOVH_007"),
			dica: this.getMsg("FP_FRONT_CadastroAOVH_006"),
			id: "numTentativas",
			valor: valorNumTentativas,
			classe: "col-md-3",
			obrigatorio: true,
			retornarHtml: true,
			onChange: () => {
				let numTentativas = this.getValor("numTentativas");

				if (numTentativas == "ESPECIFICO") {
					$(".num-tentativas").focus();
				} else {
					$(".num-tentativas").val("");
				}
			}
		});

		let numColunas = 0;

		if (listagensAuxiliaresAgendamentoProvaTO.collectionSegmentosTO.length > 1 || (listagensAuxiliaresAgendamentoProvaTO.collectionSegmentosTO.length == 1 && listagensAuxiliaresAgendamentoProvaTO.collectionSegmentosTO[0].filhos != null)) {
			await this.addSelect({
				collection: listagensAuxiliaresAgendamentoProvaTO.collectionSegmentosTO,
				id: "codEmpresa",
				label: this.getMsg("FP_FRONT_CadastroAOVH_009"),
				obrigatorio: true,
				valor: edicaoAgendamentoProvaTO.codEmpresa,
				classe: "col-md-3",
			});
			numColunas += 3;
		}

		if (!this.isCfgHabilitada("EMITE_CERTIFICADOS")) {
			await this.addSelect({
				id: "tipoAplicacao",
				label: this.getMsg("FP_FRONT_CadastroAOVH_010"),
				valor: edicaoAgendamentoProvaTO.tipoAplicacao,
				collection: edicaoAgendamentoProvaTO.collectionOpcaoListaTOTiposAplicacao,
				classe: "col-md-3 fp-small-business-hidden",
				habilitado: edicaoAgendamentoProvaTO.tipoAplicacao != TipoAplicacao.F,
				onChange: async (event) => {
					await this.atualizarCamposEdicaoAplicacao(event);
				}
			})
			numColunas += 3;
		}

		await this.addSelect({
			id: "tipoExibicaoProva",
			label: this.getMsg("FP_FRONT_CadastroAOVH_011"),
			valor: edicaoAgendamentoProvaTO.tipoExibicaoProva,
			collection: this.listagensAuxiliaresAgendamentoProvaTO.collectionOpcaoListaTOTiposExibicao,
			classe: "col-md-3",
			onChange: async (event) => await this.atualizarCamposEdicaoAplicacao(event)
		})
		numColunas += 3;

		if (numColunas >= 12) {
			this.append(htmlNumTentativas);
			htmlNumTentativas = "";
		}

		if (this.listagensAuxiliaresAgendamentoProvaTO.exibirListagemFiscais) {
			await this.addSelect({
				id: "codUsuarioFiscal",
				label: this.getMsg("FP_FRONT_CadastroAOVH_012"),
				valor: edicaoAgendamentoProvaTO.codUsuarioFiscal,
				classe: "col-md-3 fp-small-business-hidden",
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarFiscais",
					numMinimoCaracteres: 3,
				}
			})

			numColunas += 3;

			if (numColunas >= 12) {
				this.append(htmlNumTentativas);
				htmlNumTentativas = "";
			}

			this.addCampoTexto({
				label: this.getMsg("FP_FRONT_CadastroAOVH_013"),
				id: "endereco",
				valor: edicaoAgendamentoProvaTO.endereco,
				classe: "col-md-3 fp-small-business-hidden",
			});
			numColunas += 3;

			if (numColunas >= 12) {
				this.append(htmlNumTentativas);
				htmlNumTentativas = "";
			}

			this.addCampoTexto({
				label: this.getMsg("FP_FRONT_CadastroAOVH_014"),
				id: "local",
				valor: edicaoAgendamentoProvaTO.local,
				classe: "col-md-3 fp-small-business-hidden",
			});
			numColunas += 3;

			if (numColunas >= 12) {
				this.append(htmlNumTentativas);
				htmlNumTentativas = "";
			}
		}

		this.append(htmlNumTentativas);


		if (this.listagensAuxiliaresAgendamentoProvaTO.exibirListagemCorretoresDiscursiva) {

			await this.addSelect({
				id: "codUsuarioProfessorCertificado",
				label: this.getMsg("FP_FRONT_CadastroAOVH_015"),
				valor: edicaoAgendamentoProvaTO.codUsuarioProfessorCertificado,
				classe: "col-md-3 fp-small-business-hidden",
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarCorretoresDiscursivas",
					numMinimoCaracteres: 3,
				}
			})
		}

		await this.addSelect({
			id: "CONTROLE_COTA",
			label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["CONTROLE_COTA"],
			ajuda: this.getMsg("FP_FRONT_CadastroAOVH_184"),
			valor: edicaoAgendamentoProvaTO.cfgsGerais["CONTROLE_COTA"] ? JSON.parse(edicaoAgendamentoProvaTO.cfgsGerais["CONTROLE_COTA"]) : [],
			collection: edicaoAgendamentoProvaTO.collectionOpcaoListaTOTiposCota,
			multiplo: true,
			classe: "col-md-3 fp-small-business-hidden",
			habilitado: true
		})

		this.fecharFormulario();

		if (edicaoAgendamentoProvaTO.codAgendamento != null) {

			this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_016"), id: "titulo_tempo_prova" });

			this.addRadioGroup({
				id: "tipoCronometro",
				label: this.getMsg("FP_FRONT_CadastroAOVH_017"),
				collection: [{ 
					id: "N", 
					descricao: this.getMsg("FP_FRONT_CadastroAOVH_018") 
				}, { 
					id: "P", 
					descricao: this.getMsg("FP_FRONT_CadastroAOVH_019") 
				}, { 
					id: "S", 
					descricao: this.getMsg("FP_FRONT_CadastroAOVH_182") 
				}, { 
					id: "Q", 
					descricao: this.getMsg("FP_FRONT_CadastroAOVH_020") 
				}],
				classe: "col-md-2",
				valor: edicaoAgendamentoProvaTO.tempoParaResposta != null ? "P" : (edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_QUESTAO"] != null ? "Q" : edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_SECAO"] != null ? "S" : "N"),
				onChange: async (event) => await this.atualizarCamposEdicaoAplicacao(event)
			});

			this.addCampoTexto({
				id: "tempoParaResposta",
				label: this.getMsg("FP_FRONT_CadastroAOVH_021"),
				valor: edicaoAgendamentoProvaTO.tempoParaResposta != null ? Math.round(edicaoAgendamentoProvaTO.tempoParaResposta / 60) : null,
				sufixo: this.getMsg("FP_FRONT_CadastroAOVH_022"),
				classe: "col-md-4",
				mascara: "9?99",
				cssInput: "width: 200px",
			});

			const tempoCronometroPorSecao = edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_SECAO"];
		    const tempoPorSecao = tempoCronometroPorSecao ? JSON.parse(tempoCronometroPorSecao) : [];
			const secoesHtml = [];

			for (const secao of listagensAuxiliaresAgendamentoProvaTO.listaOpcaoListaTOSecoes) {
				let valor = null;
				if (tempoPorSecao) {
					const s =  tempoPorSecao.find(it => it.codSecao == secao.id);
					valor = s ? s.tempoParaResposta : null;
				}
				const campoTempoSecaoId = `TEMPO_CRONOMETRO_SECAO_${secao.id}`;

				secoesHtml.push(this.addCampoTexto({
					id: campoTempoSecaoId,
					valor: valor,
					label: this.getMsg("FP_FRONT_CadastroAOVH_181", secao.nome),
					sufixo: this.getMsg("FP_FRONT_CadastroAOVH_023"),
					classe: "col-md-12",
					cssInput: "width: 200px",
					tipo: "TEMPO",
					retornarHtml: true
				}));
			}

			this.append(`<div class='col-md-4' id='configuracao-tempo-secao'>${secoesHtml.join("")}</div>`);

			this.addCampoTexto({
				id: "TEMPO_CRONOMETRO_POR_QUESTAO",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_QUESTAO"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["TEMPO_CRONOMETRO_POR_QUESTAO"],
				sufixo: this.getMsg("FP_FRONT_CadastroAOVH_023"),
				classe: "col-md-4",
				cssInput: "width: 200px",
				tipo: "TEMPO",
				textoAposCampo: this.getMsg("FP_FRONT_CadastroAOVH_024")
			});

			this.addCampoExibicao({
				id: "infoTodasQuestoesTemTempo",
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["TEMPO_CRONOMETRO_POR_QUESTAO"],
				valor: this.getMsg("FP_FRONT_CadastroAOVH_025"),
				classe: "col-md-4",
			});

			this.addCheckbox({
				id: "INTERRUPCOES_PARAM_CRONOMETRO",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["INTERRUPCOES_PARAM_CRONOMETRO"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["INTERRUPCOES_PARAM_CRONOMETRO"],
				classe: "col-md-4",
				onChange: async () => {
					const isInterrupcaoCronometro = this.getValor("INTERRUPCOES_PARAM_CRONOMETRO");
					const isAutoAnulacaoMonitoramento = this.getValor("tipoAnulacaoSaidaAbaNavegador") === "MONITORAR_SAIDA_DO_NAVEGADOR";

					if (isInterrupcaoCronometro === true && isAutoAnulacaoMonitoramento === true) {
						await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroAOVH_144") });
						return false; // reverte o checkbox
					}
				}
			});

			this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_174") });

			let isEmbaralharSecoes = edicaoAgendamentoProvaTO.cfgsGerais["EMBARALHAR_SECOES"];
			let isEmbaralharQuestoes = edicaoAgendamentoProvaTO.cfgsGerais["EMBARALHAR_QUESTOES"];
			let isEmbaralharItens = edicaoAgendamentoProvaTO.cfgsGerais["EMBARALHAR_ITENS"];
			let valorIsEmbaralhamento = isEmbaralharSecoes || isEmbaralharQuestoes || isEmbaralharItens;

			this.addRadioGroup({
				id: "isEmbaralhamento",
				label: this.getMsg("FP_FRONT_CadastroAOVH_026"),
				collection: [{ id: "SEM_EMBARALHAMENTO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_027") }, { id: "COM_EMBARALHAMENTO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_028") }],
				classe: "col-md-4",
				valor: (valorIsEmbaralhamento ? "COM_EMBARALHAMENTO" : "SEM_EMBARALHAMENTO"),
				onChange: async (event) => {
					await this.atualizarEmbaralhamento(true);
				}
			});

			this.append(`
				<div class="col-md-4">
					<div class="row">
			`);

			this.addCheckbox({
				id: "EMBARALHAR_SECOES",
				valor: isEmbaralharSecoes,
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["EMBARALHAR_SECOES"],
				classe: "col-md-12",
				css: "min-height: auto",
				onChange: async (event) => {
					await this.atualizarEmbaralhamento();
				}
			});

			this.addCheckbox({
				id: "EMBARALHAR_QUESTOES",
				valor: isEmbaralharQuestoes,
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["EMBARALHAR_QUESTOES"],
				classe: "col-md-12",
				css: "min-height: auto",
				onChange: async (event) => {
					await this.atualizarEmbaralhamento();
				}
			});

			this.addCheckbox({
				id: "EMBARALHAR_ITENS",
				valor: isEmbaralharItens,
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["EMBARALHAR_ITENS"],
				classe: "col-md-12",
				css: "min-height: auto",
				onChange: async (event) => {
					await this.atualizarEmbaralhamento();
				}
			});

			this.append(`
					</div>
				</div>
			`);

			this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_030") });

			let valorBloquear = null;

			if (edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_QUESTOES_JA_VISUALIZADAS"] != null) {
				valorBloquear = "BLOQUEAR_QUESTOES_JA_VISUALIZADAS";
			} else if (edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_QUESTOES_JA_RESPONDIDAS"] != null) {
				valorBloquear = "BLOQUEAR_QUESTOES_JA_RESPONDIDAS";
			}

			this.append(`
				<div class='row'>
					<div class='col-md-4'>
			`);
			this.addRadioGroup({
				id: "tipoBloqueioParaVoltarEmJaRespondidas",
				label: this.getMsg("FP_FRONT_CadastroAOVH_031"),
				collection: [{ id: "BLOQUEAR_QUESTOES_JA_RESPONDIDAS", descricao: this.getMsg("FP_FRONT_CadastroAOVH_032") }, { id: "BLOQUEAR_QUESTOES_JA_VISUALIZADAS", descricao: this.getMsg("FP_FRONT_CadastroAOVH_034") }, { id: "N", descricao: this.getMsg("FP_FRONT_CadastroAOVH_033") }],
				classe: "col-md-12",
				valor: valorBloquear || "N"
			});
			this.append(`
					</div>
					<div class='col-md-4'>
			`);
			this.addCheckbox({
				id: "BLOQUEAR_CONCLUSAO_COM_RESPOSTA_EM_BRANCO",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_CONCLUSAO_COM_RESPOSTA_EM_BRANCO"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["BLOQUEAR_CONCLUSAO_COM_RESPOSTA_EM_BRANCO"],
				classe: "col-md-12 row",
				onChange: async (event) => await this.atualizarCamposEdicaoAplicacao(event)
			});
			this.addCheckbox({
				id: "IS_FULL_SCREEN",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_FULL_SCREEN"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_FULL_SCREEN"],
				classe: "col-md-12 row",
			});
			this.append(`
					</div>
					<div class='col-md-4'>
			`);
			this.addCheckbox({
				id: "BLOQUEAR_CORRETOR_ORTOGRAFICO_DISCURSIVAS",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_CORRETOR_ORTOGRAFICO_DISCURSIVAS"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["BLOQUEAR_CORRETOR_ORTOGRAFICO_DISCURSIVAS"],
				classe: "col-md-12 row",
			});
			this.addCheckbox({
				id: "ANULAR_DISCURSIVA_SEM_MINIMO_LINHAS",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["ANULAR_DISCURSIVA_SEM_MINIMO_LINHAS"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["ANULAR_DISCURSIVA_SEM_MINIMO_LINHAS"],
				classe: "col-md-12 row",
			});
			this.append(`
					</div>
				</div>
			`);

			this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_173") });

			let configSaidaNavegador = null;
			if (edicaoAgendamentoProvaTO.cfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR"] != null) {
				configSaidaNavegador = "MONITORAR_SAIDA_DO_NAVEGADOR";
			}
			else if (edicaoAgendamentoProvaTO.cfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR_SEM_ANULACAO_AUTOMATICA"] != null) {
				configSaidaNavegador = "MONITORAR_SAIDA_DO_NAVEGADOR_SEM_ANULACAO_AUTOMATICA";
			}

			this.append(`
				<div class="row">
					<div class="col-md-4">
			`);
			this.addRadioGroup({
				id: "tipoAnulacaoSaidaAbaNavegador",
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR"],
				collection: [
					{ id: "MONITORAR_SAIDA_DO_NAVEGADOR", descricao: this.getMsg("FP_FRONT_CadastroAOVH_035") },
					{ id: "MONITORAR_SAIDA_DO_NAVEGADOR_SEM_ANULACAO_AUTOMATICA", descricao: this.getMsg("FP_FRONT_CadastroAOVH_036") },
					{ id: "N", descricao: this.getMsg("FP_FRONT_CadastroAOVH_037") }
				],
				classe: "col-md-12",
				valor: configSaidaNavegador || "N",
				onChange: async () => {
					const isInterrupcaoCronometro = this.getValor("INTERRUPCOES_PARAM_CRONOMETRO");
					const isAutoAnulacaoMonitoramento = this.getValor("tipoAnulacaoSaidaAbaNavegador") === "MONITORAR_SAIDA_DO_NAVEGADOR";

					if (isInterrupcaoCronometro === true && isAutoAnulacaoMonitoramento === true) {
						await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroAOVH_143") });
						this.setValor("tipoAnulacaoSaidaAbaNavegador", "MONITORAR_SAIDA_DO_NAVEGADOR_SEM_ANULACAO_AUTOMATICA");
					}
				}
			})
			this.append(`
					</div>
			`);

			let configcodigoAcessoProva = null;
			if (edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO"] != null) {
				configcodigoAcessoProva = "CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO";
			} else if (edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO"] != null) {
				configcodigoAcessoProva = "CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO";
			} else if (edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO"] != null) {
				configcodigoAcessoProva = "CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO";
			}

			this.append(`
					<div class="col-md-4">
			`);
			this.addRadioGroup({
				id: "codigoAcessoProva",
				label: this.getMsg("FP_FRONT_CadastroAOVH_180"),
				collection: [
					{ id: "CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO", descricao: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO"] },
					{ id: "CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO", descricao: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO"] },
					{ id: "CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO", descricao: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO"] },
					{ id: "N", descricao: "Desativado" }
				],
				classe: "col-md-12",
				valor: configcodigoAcessoProva || "N",
			});
			this.append(`
					</div>
					<div class="col-md-4">
			`);
			this.addCheckbox({
				id: "HABILITAR_CODIGO_ROTATIVO_ACESSO_PROVA",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["HABILITAR_CODIGO_ROTATIVO_ACESSO_PROVA"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["HABILITAR_CODIGO_ROTATIVO_ACESSO_PROVA"],
				classe: "col-md-12 row fp-small-business-hidden",
			});
			this.addCheckbox({
				id: "SAFE_BROWSER_HABILITADO",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["SAFE_BROWSER_HABILITADO"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["SAFE_BROWSER_HABILITADO"],
				classe: "col-md-12 row",
				visivel: this.listagensAuxiliaresAgendamentoProvaTO.isSafeBrowserHabilitadoAmbiente
			});
			this.append(`
					</div>
				</div>
			`);

			if (this.listagensAuxiliaresAgendamentoProvaTO.faceMatchHabilitadoAmbiente) {

				const isFacematchAntesHabilitado = this.hasValue(edicaoAgendamentoProvaTO.cfgsGerais["HABILITAR_VALIDACAO_FACEMATCH"]);
				const isFacematchDuranteHabilitado = this.hasValue(edicaoAgendamentoProvaTO.cfgsGerais["HABILITAR_FACEMATCH_DURANTE_APLICACAO"]);

				this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_038"), id: "facematch-titulo" });

				this.append(`
					<div class="col-md-4 facematch-container">
						<div class="row">
				`);

				this.addRadioGroup({
					id: "tipoFacematchHabilitado",
					label: this.getMsg("FP_FRONT_CadastroAOVH_185"),
					collection: [
						{ id: "FACEMATCH_ANTES", descricao: this.getMsg("FP_FRONT_CadastroAOVH_186") },
						{ id: "FACEMATCH_ANTES_E_DURANTE", descricao: this.getMsg("FP_FRONT_CadastroAOVH_187") },
						{ id: "N", descricao: this.getMsg("FP_FRONT_CadastroAOVH_188") }
					],
					classe: "col-md-12",
					valor: (isFacematchAntesHabilitado 
						? (isFacematchDuranteHabilitado ? "FACEMATCH_ANTES_E_DURANTE" : "FACEMATCH_ANTES") 
						: "N"
					),
					onChange: async () => await this.atualizarCamposFacematch(false)
				});

				this.append(`
						</div>
					</div>
					<div class="col-md-4">
						<div class="row">
				`);

				this.addCampoTexto({
					id: "HABILITAR_VALIDACAO_FACEMATCH",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["HABILITAR_VALIDACAO_FACEMATCH"],
					label: this.getMsg("FP_FRONT_CadastroAOVH_042"),
					classe: "col-md-12",
					sufixo: "fotos",
					cssInput: "text-align: center",
					mascara: '9?9',
					visivel: isFacematchAntesHabilitado || isFacematchDuranteHabilitado
				});

				this.addCampoTexto({
					id: "HABILITAR_FACEMATCH_DURANTE_APLICACAO",
					label: this.getMsg('FP_FRONT_CadastroAOVH_139'),
					valor: edicaoAgendamentoProvaTO.cfgsGerais["HABILITAR_FACEMATCH_DURANTE_APLICACAO"],
					prefixo: "A cada",
					sufixo: this.getMsg("FP_FRONT_CadastroAOVH_022"),
					classe: "col-md-12",
					cssInput: "text-align: center",
					mascara: '9?99',
					visivel: isFacematchDuranteHabilitado
				});

				this.append(`
						</div>
					</div>
					<div class="col-md-4">
						<div class="row">
				`);

				this.addRadioGroup({
					id: "tipoTratamentoReprovacaoFacematch",
					label: this.getMsg("FP_FRONT_CadastroAOVH_039"),
					classe: "col-md-12",
					collection: [
						{ id: "PERMITIR_INICIO_PROVA", descricao: this.getMsg("FP_FRONT_CadastroAOVH_040") },
						{ id: "AGUARDAR_FISCAL", descricao: this.getMsg("FP_FRONT_CadastroAOVH_041") },
					],
					valor: edicaoAgendamentoProvaTO.cfgsGerais["PERMITIR_INICIO_PROVA_SEM_FACEMATCH"] ? "PERMITIR_INICIO_PROVA" : "AGUARDAR_FISCAL",
					visivel: isFacematchAntesHabilitado || isFacematchDuranteHabilitado
				});

				this.append(`
						</div>
					</div>
				`);
			}

			if (this.listagensAuxiliaresAgendamentoProvaTO.isExibirCfgAlertaDiscursivaDisponivelParaCorrecao || this.listagensAuxiliaresAgendamentoProvaTO.isExibirCfgAlertaDiscursivasCorrigidas) {

				this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_043") });

				this.addCheckbox({
					id: "IS_INFORMAR_CORRETORES_SOBRE_DISCURSIVAS_DISPONIVEIS",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_INFORMAR_CORRETORES_SOBRE_DISCURSIVAS_DISPONIVEIS"] != null,
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_INFORMAR_CORRETORES_SOBRE_DISCURSIVAS_DISPONIVEIS"],
					classe: "col-md-9",
					visivel: this.listagensAuxiliaresAgendamentoProvaTO.isExibirCfgAlertaDiscursivaDisponivelParaCorrecao
				});

				this.addCheckbox({
					id: "IS_INFORMAR_CRIADOR_DE_PROVA_SOBRE_DISCURSIVAS_CORRIGIDAS",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_INFORMAR_CRIADOR_DE_PROVA_SOBRE_DISCURSIVAS_CORRIGIDAS"],
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_INFORMAR_CRIADOR_DE_PROVA_SOBRE_DISCURSIVAS_CORRIGIDAS"],
					classe: "col-md-9",
					visivel: this.listagensAuxiliaresAgendamentoProvaTO.isExibirCfgAlertaDiscursivasCorrigidas
				});
			}

			if (this.listagensAuxiliaresAgendamentoProvaTO.proctoringHabilitadoAmbiente) {

				const isProctoringHabilitado = this.hasValue(edicaoAgendamentoProvaTO.cfgsGerais["IS_PROCTORING_HABILITADO"]);
				let isProctoringFP = this.listagensAuxiliaresAgendamentoProvaTO.provedorProctoring === 'fp';
				let labelIsProctoringHabilitado = this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_PROCTORING_HABILITADO"];

				if (this.listagensAuxiliaresAgendamentoProvaTO.proctoringAlertasRestricao) {
					labelIsProctoringHabilitado = labelIsProctoringHabilitado + `. <small><strong>${this.listagensAuxiliaresAgendamentoProvaTO.proctoringAlertasRestricao}</strong></small>`;
				}

				this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_044"), id: "proctoring-titulo" });

				this.append(`
					<div class="proctoring-container">
						<div class="col-md-4">
				`);

				this.addCheckbox({
					id: "IS_PROCTORING_HABILITADO",
					valor: isProctoringHabilitado,
					label: labelIsProctoringHabilitado,
					classe: "row col-md-12",
					onChange: () => this.checkConfigsProctoring(isProctoringFP)
				});
				
				if (this.listagensAuxiliaresAgendamentoProvaTO.proctoringCapturaTelaHabilitadoAmbiente) {
					this.addCheckbox({
						id: "IS_CAPTURA_TELA_PROCTORING_HABILITADA",
						valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_CAPTURA_TELA_PROCTORING_HABILITADA"],
						label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_CAPTURA_TELA_PROCTORING_HABILITADA"],
						classe: "row col-md-12",
						habilitado: isProctoringHabilitado,
						onChange: () => {
							if(isProctoringFP) {
								this.checkConfigsProctoringFP(true);
							}
						}
					});
				}
				if (this.listagensAuxiliaresAgendamentoProvaTO.proctoringGravacaoVideoHabilitadoAmbiente) {
					this.addCheckbox({
						id: "IS_GRAVACAO_VIDEO_PROCTORING_HABILITADO",
						valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_GRAVACAO_VIDEO_PROCTORING_HABILITADO"],
						label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_GRAVACAO_VIDEO_PROCTORING_HABILITADO"],
						classe: "row col-md-12",
						habilitado: isProctoringHabilitado,
						onChange: () => {
							if(isProctoringFP) {
								this.checkConfigsProctoringFP(true);
							}
						}
					});
				}
				if (this.listagensAuxiliaresAgendamentoProvaTO.proctoringAutorizacaoCandidatoHabilitadoAmbiente) {
					this.addCheckbox({
						id: "IS_AUTORIZACAO_CANDIDATO_PROCTORING_HABILITADO",
						valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_AUTORIZACAO_CANDIDATO_PROCTORING_HABILITADO"],
						label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_AUTORIZACAO_CANDIDATO_PROCTORING_HABILITADO"],
						classe: "row col-md-12",
						habilitado: isProctoringHabilitado,
					});
				}

				this.append(`
						</div>
						<div class="col-md-4">
				`);

				if (this.listagensAuxiliaresAgendamentoProvaTO.proctoringHabilitacaoChatAmbiente) {
					this.addCheckbox({
						id: "IS_CHAT_HABILITADO",
						valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_CHAT_HABILITADO"],
						label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_CHAT_HABILITADO"],
						classe: "row col-md-12",
						habilitado: isProctoringHabilitado,
					});
				}

				this.addCheckbox({
					id: "IS_PREVIEW_GRAVACAO_PROCTORING_HABILITADO",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_PREVIEW_GRAVACAO_PROCTORING_HABILITADO"],
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_PREVIEW_GRAVACAO_PROCTORING_HABILITADO"],
					classe: "row col-md-12",
					habilitado: isProctoringHabilitado,
				});

				this.addCheckbox({
					id: "IS_SYSCHECK_PROCTORING_HABILITADO",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_SYSCHECK_PROCTORING_HABILITADO"],
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_SYSCHECK_PROCTORING_HABILITADO"],
					classe: "row col-md-12",
					habilitado: isProctoringHabilitado,
				});

				this.addCheckbox({
					id: "IS_WEBCAM_CHECK_PROCTORING_HABILITADO",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_WEBCAM_CHECK_PROCTORING_HABILITADO"],
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_WEBCAM_CHECK_PROCTORING_HABILITADO"],
					classe: "row col-md-12",
					visivel: isProctoringFP,
					habilitado: isProctoringHabilitado,
				});

				this.append(`
						</div>
						<div class="col-md-4">
				`);

				this.addCheckbox({
					id: "FORCAR_COMPARTILHAMENTO_TELA_INTEIRA",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["FORCAR_COMPARTILHAMENTO_TELA_INTEIRA"],
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["FORCAR_COMPARTILHAMENTO_TELA_INTEIRA"],
					classe: "row col-md-12",
					visivel: isProctoringFP,
					habilitado: isProctoringHabilitado,
				});
				
				this.addCheckbox({
					id: "BLOQUEAR_USO_MULTIPLO_MONITORES",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_USO_MULTIPLO_MONITORES"],
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["BLOQUEAR_USO_MULTIPLO_MONITORES"],
					classe: "row col-md-12",
					visivel: isProctoringFP,
					habilitado: isProctoringHabilitado,
				});

				this.addCheckbox({
					id: "IS_SCREENSHOT_HABILITADO",
					valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_SCREENSHOT_HABILITADO"],
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_SCREENSHOT_HABILITADO"],
					classe: "row col-md-12",
					visivel: isProctoringFP,
					habilitado: isProctoringHabilitado,
				});

				if(this.listagensAuxiliaresAgendamentoProvaTO.proctoringAnaliseIaAmbiente) {
					this.addCheckbox({
						id: "IS_ANALISE_IA_HABILITADA",
						valor: edicaoAgendamentoProvaTO.cfgsGerais["IS_ANALISE_IA_HABILITADA"],
						label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["IS_ANALISE_IA_HABILITADA"],
						classe: "row col-md-12",
						visivel: isProctoringFP,
						habilitado: isProctoringHabilitado,
					});
				}

				this.append(`
						</div>
					</div>
				`);
			}

			this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_045"), id: "aplicacao_mensagens" });

			this.addEditorHTML({
				id: "msgApresentacao",
				label: this.getMsg("FP_FRONT_CadastroAOVH_046"),
				valor: edicaoAgendamentoProvaTO.msgApresentacao,
				classe: "col-sm-12 col-md-6"
			});

			this.addEditorHTML({
				id: "INSTRUCOES_PARA_AVALIADO",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["INSTRUCOES_PARA_AVALIADO"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["INSTRUCOES_PARA_AVALIADO"],
				classe: "col-sm-12 col-md-6"
			});

			if (edicaoAgendamentoProvaTO.porcentagemPontuacaoMinima != null) {
				this.addEditorHTML({
					id: "msgAprovacao",
					label: this.getMsg("FP_FRONT_CadastroAOVH_047"),
					valor: edicaoAgendamentoProvaTO.msgAprovacao,
					classe: "col-sm-12 col-md-6"
				})
				this.addEditorHTML({
					id: "msgReprovacao",
					label: this.getMsg("FP_FRONT_CadastroAOVH_048"),
					valor: edicaoAgendamentoProvaTO.msgReprovacao,
					classe: "col-sm-12 col-md-6"
				})
			} else {
				this.addEditorHTML({
					id: "msgProvaEncerrada",
					label: this.getMsg("FP_FRONT_CadastroAOVH_049"),
					valor: edicaoAgendamentoProvaTO.msgProvaEncerrada,
					classe: "col-sm-12 col-md-6"
				})
			}
			if (edicaoAgendamentoProvaTO.possuiDiscursiva) {
				this.addEditorHTML({
					id: "msgCorrecaoPendente",
					label: this.getMsg("FP_FRONT_CadastroAOVH_050"),
					valor: edicaoAgendamentoProvaTO.msgCorrecaoPendente,
					classe: "col-sm-12 col-md-6"
				})
			}

			this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroAOVH_051"), id: "aplicacao_pagina_externa" });

			this.append("<div class='col-md-6'><div class='row'>");

			this.addEditorHTML({
				id: "htmlPaginaExterna",
				label: this.getMsg("FP_FRONT_CadastroAOVH_052"),
				valor: edicaoAgendamentoProvaTO.htmlPaginaExterna,
				classe: "col-sm-12 col-md-12"
			});
			
			this.addEditorHTML({
				id: "MENSAGEM_ENCERRAMENTO_SECAO_AVALIADO_LINK_EXTERNO",
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["MENSAGEM_ENCERRAMENTO_SECAO_AVALIADO_LINK_EXTERNO"],
				valor: edicaoAgendamentoProvaTO.cfgsGerais["MENSAGEM_ENCERRAMENTO_SECAO_AVALIADO_LINK_EXTERNO"],
				classe: "col-sm-12 col-md-12",
				visivel: edicaoAgendamentoProvaTO.cfgsGerais["ENCERRAR_SECAO_AVALIADO_LINK_EXTERNO"] == 'true'
			});

			this.append("</div></div>");

			this.append("<div class='col-md-6'><div class='row'>");

			this.addCampoExibicao({
				id: "urlAcessoExterno",
				label: this.getMsg("FP_FRONT_CadastroAOVH_053"),
				valor: edicaoAgendamentoProvaTO.urlAcessoExterno,
				classe: "col-sm-12 col-md-6",
				isExibirLinkCopiaValor: true,
			});

			if (edicaoAgendamentoProvaTO.urlLinkTemplateLTI) {
				this.addCampoExibicao({
					id: "urlLinkTemplate",
					label: this.getMsg("FP_FRONT_CadastroAOVH_054"),
					valor: edicaoAgendamentoProvaTO.urlLinkTemplateLTI,
					isExibirLinkCopiaValor: true,
					classe: "col-sm-12 col-md-6"
				});
			}

			this.addCheckbox({
				id: "LIBERADO_PARA_VISITANTES",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["LIBERADO_PARA_VISITANTES"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["LIBERADO_PARA_VISITANTES"],
				classe: "col-sm-12 col-md-12",
				onChange: async (event) => await this.atualizarCamposEdicaoAplicacao(event)
			});

			if (this.hasValue(edicaoAgendamentoProvaTO.listaQuestionarioDisponivelTO)) {
				const valor = edicaoAgendamentoProvaTO.cfgsGerais["QUESTIONARIO_COMPLEMENTAR_CADASTRO"];

				await this.addSelect({
					collection: edicaoAgendamentoProvaTO.listaQuestionarioDisponivelTO,
					propId: "idQuestionario",
					propLabel: "nomeQuestionario",
					id: "QUESTIONARIO_COMPLEMENTAR_CADASTRO",
					valor: questVH.getQuestionarioSelecionado(edicaoAgendamentoProvaTO.listaQuestionarioDisponivelTO, valor),
					label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["QUESTIONARIO_COMPLEMENTAR_CADASTRO"],
					classe: "col-sm-12 col-md-12"
				});
			}

			this.addCheckbox({
				id: "ENCERRAR_SECAO_AVALIADO_LINK_EXTERNO",
				valor: edicaoAgendamentoProvaTO.cfgsGerais["ENCERRAR_SECAO_AVALIADO_LINK_EXTERNO"],
				label: this.listagensAuxiliaresAgendamentoProvaTO.mapaCfgsGerais["ENCERRAR_SECAO_AVALIADO_LINK_EXTERNO"],
				classe: "col-sm-12 col-md-12",
				onChange: async () => {
					let encerrarAtivo = this.getValor("ENCERRAR_SECAO_AVALIADO_LINK_EXTERNO");
					encerrarAtivo === true
						? await this.show("MENSAGEM_ENCERRAMENTO_SECAO_AVALIADO_LINK_EXTERNO")
						: await this.hide("MENSAGEM_ENCERRAMENTO_SECAO_AVALIADO_LINK_EXTERNO");
				}
			});

			this.append("</div></div>");

		}

		this.addEspacamentoHorizontal("1px");

		this.addBotao({ label: this.getMsg("MSG_VH_AP_31"), onClick: () => UtilHash.voltar(), css: "float: left" });

		this.append("<div class='btn-group' style='float: right'>");

		if (edicaoAgendamentoProvaTO.codAgendamento != null && podeEditar) {
			this.addBotao({
				label: edicaoAgendamentoProvaTO.isArquivada ? this.getMsg("FP_FRONT_CadastroAOVH_055") : this.getMsg("FP_FRONT_CadastroAOVH_056"),
				classe: edicaoAgendamentoProvaTO.isArquivada ? "" : "btn-danger",
				onClick: () => this.exibirPopupExclusao(edicaoAgendamentoProvaTO.codAgendamento, edicaoAgendamentoProvaTO.codProva, edicaoAgendamentoProvaTO.isArquivada)
			});
			if (this.isAdministrador()) {
				this.addBotao({
					label: `<i class='fa fa-eye'></i> ${this.getMsg("FP_FRONT_CadastroAOVH_059")}`,
					onClick: async () => await loginVH.exibirAcessosDoAgendamento(edicaoAgendamentoProvaTO.codAgendamento)
				});
			}
			this.addBotao({
				label: `<i class='fa fa-copy'></i> ${this.getMsg("FP_FRONT_CadastroAOVH_060")}`,
				dica: this.getMsg("FP_FRONT_CadastroAOVH_057"),
				onClick: async () => await cadastroAOVH.criarCopiaAplicacao(edicaoAgendamentoProvaTO.codAgendamento),
			});

			this.addBotao({
				id: "btnSalvarCfgsEAvancar",
				label: this.getMsg("FP_FRONT_CadastroAOVH_058"),
				onClick: () => this.salvarConfiguracoes(true)
			});
		}

		this.addBotao({
			id: "btnSalvarCfgsAO",
			label: this.getMsg("MSG_VH_AP_32"),
			classe: "btn-primary",
			onClick: () => this.salvarConfiguracoes(false)
		});

		this.append(`
					</div>
				</div>
			</div>
		`);

		this.exibir();
		await this.atualizarCamposEdicaoAplicacao(null, edicaoAgendamentoProvaTO.codAgendamento);
		await this.atualizarEmbaralhamento();
		await this.atualizarCamposFacematch(true);

		if (!podeEditar) {
			setTimeout(() => this.desabilitarCamposEdicaoAO(), 300);
		}
	}

	checkConfigsProctoringFP(isProctoringFP: boolean) {
		let $isProctoringHabilitado = $("#IS_PROCTORING_HABILITADO");
		let $isCapturaTelaProctoringHabilitada = $("#IS_CAPTURA_TELA_PROCTORING_HABILITADA");
		let $isGravacaoVideoProctoringHabilitada = $("#IS_GRAVACAO_VIDEO_PROCTORING_HABILITADO");

		let isProctoringHabilitado = this.getValor($isProctoringHabilitado);
		let isCapturaTelaProctoringHabilitada = this.getValor($isCapturaTelaProctoringHabilitada);
		let isGravacaoVideoProctoringHabilitada = this.getValor($isGravacaoVideoProctoringHabilitada);

		if(isProctoringHabilitado && !isGravacaoVideoProctoringHabilitada && !isCapturaTelaProctoringHabilitada) {
			this.setValor($isProctoringHabilitado, false);
			this.checkConfigsProctoring(isProctoringFP);
		}
	}

	checkConfigsProctoring(isProctoringFP: boolean){

		let isProctoringHabilitado = this.getValor("IS_PROCTORING_HABILITADO");
		let $isCapturaTelaProctoringHabilitada = $("#IS_CAPTURA_TELA_PROCTORING_HABILITADA");
		let $isGravacaoVideoProctoringHabilitada = $("#IS_GRAVACAO_VIDEO_PROCTORING_HABILITADO");
		let $isAutorizacaoCandidatoProctoringHabilitado = $("#IS_AUTORIZACAO_CANDIDATO_PROCTORING_HABILITADO");
		let $isChatHabilitado = $("#IS_CHAT_HABILITADO");
		let $isPreviewHabilitado = $("#IS_PREVIEW_GRAVACAO_PROCTORING_HABILITADO");
		let $isSyscheck = $("#IS_SYSCHECK_PROCTORING_HABILITADO");
		let $isWebcamCheck = $("#IS_WEBCAM_CHECK_PROCTORING_HABILITADO");
		let $isForcarTelaInteira = $("#FORCAR_COMPARTILHAMENTO_TELA_INTEIRA");
		let $isBloquearMultiplosMonitores = $("#BLOQUEAR_USO_MULTIPLO_MONITORES");
		let $isScreenshotsHabilitado = $("#IS_SCREENSHOT_HABILITADO");
		let $isAnaliseIAHabilitado = $("#IS_ANALISE_IA_HABILITADA");

		if (isProctoringHabilitado) {
			this.enable($isCapturaTelaProctoringHabilitada);
			this.enable($isGravacaoVideoProctoringHabilitada);
			this.enable($isAutorizacaoCandidatoProctoringHabilitado);
			this.enable($isChatHabilitado);
			this.enable($isPreviewHabilitado);

			if (isProctoringFP) {
				this.setValor($isCapturaTelaProctoringHabilitada, true);
				this.setValor($isGravacaoVideoProctoringHabilitada, true);
				this.enable($isSyscheck);
				this.enable($isWebcamCheck);
				this.enable($isForcarTelaInteira);
				this.enable($isBloquearMultiplosMonitores);
				this.enable($isScreenshotsHabilitado);
				this.enable($isAnaliseIAHabilitado);
			}

		} else {
			this.setValor($isCapturaTelaProctoringHabilitada, false);
			this.disable($isCapturaTelaProctoringHabilitada);
			this.setValor($isGravacaoVideoProctoringHabilitada, false);
			this.disable($isGravacaoVideoProctoringHabilitada);
			this.setValor($isAutorizacaoCandidatoProctoringHabilitado, false);
			this.disable($isAutorizacaoCandidatoProctoringHabilitado);
			this.setValor($isChatHabilitado, false);
			this.disable($isChatHabilitado);
			this.setValor($isPreviewHabilitado, false);
			this.disable($isPreviewHabilitado);
			this.setValor($isSyscheck, false);
			this.disable($isSyscheck);
			this.setValor($isWebcamCheck, false);
			this.disable($isWebcamCheck);
			
			if (isProctoringFP) {
				this.setValor($isForcarTelaInteira, false);
				this.disable($isForcarTelaInteira);
				this.setValor($isBloquearMultiplosMonitores, false);
				this.disable($isBloquearMultiplosMonitores);
				this.setValor($isScreenshotsHabilitado, false);
				this.disable($isScreenshotsHabilitado);
				this.setValor($isAnaliseIAHabilitado, false);
				this.disable($isAnaliseIAHabilitado);
			}
		}
	}

	async atualizarCamposEdicaoAplicacao(event?: Event, codAgendamento?: number) {

		const tipoAplicacao = this.getValor("tipoAplicacao");
		let tipoCronometro = this.getValor("tipoCronometro");
		const tipoExibicaoProva = this.getValor("tipoExibicaoProva");
		const isLiberadoParaVisitantes = this.getValor("LIBERADO_PARA_VISITANTES");

		let idsShow = [];
		let idsHide = [];

		if (isLiberadoParaVisitantes) {
			idsShow.push("QUESTIONARIO_COMPLEMENTAR_CADASTRO");
		} else {
			idsHide.push("QUESTIONARIO_COMPLEMENTAR_CADASTRO");
		}

		if (tipoExibicaoProva == "P") {
			this.desabilitarOpcaoRadio("tipoCronometro", "Q");
			this.desabilitarOpcaoRadio("tipoCronometro", "S");
			if (tipoCronometro == "Q" || tipoCronometro == "S") {
				this.setValor("tipoCronometro", "N");
				tipoCronometro = "N";
			}
			idsHide.push("tipoBloqueioParaVoltarEmJaRespondidas");
		} else {
			this.habilitarOpcaoRadio("tipoCronometro", "Q");
			this.habilitarOpcaoRadio("tipoCronometro", "S");
			idsShow.push("tipoBloqueioParaVoltarEmJaRespondidas");
		}

		if (tipoAplicacao == "E") {
			idsHide.push("INSTRUCOES_PARA_AVALIADO");
		} else {
			idsShow.push("INSTRUCOES_PARA_AVALIADO");
		}

		if (tipoAplicacao != "F" && tipoAplicacao != "I") {
			idsShow.push("tipoExibicaoProva", "numTentativas", "titulo_tempo_prova", "tipoCronometro");

			if (tipoCronometro == "P") {
				idsHide.push("TEMPO_CRONOMETRO_POR_QUESTAO", "infoTodasQuestoesTemTempo", "configuracao-tempo-secao");
				idsShow.push("tempoParaResposta", "INTERRUPCOES_PARAM_CRONOMETRO");

			} else if (tipoCronometro == "Q") {

				idsHide.push("tempoParaResposta", "configuracao-tempo-secao");
				const isAtualizacaoInicial = this.isEmpty(event);

				if (this.todasQuestoesTemTempo === null && isAtualizacaoInicial && codAgendamento) {

					const response = await this.call("CadastroAOFCD/todasQuestoesTemTempo", codAgendamento);
				
					if (response === true) {
						this.setValor("TEMPO_CRONOMETRO_POR_QUESTAO", "1800");
						idsHide.push("TEMPO_CRONOMETRO_POR_QUESTAO");
						idsShow.push("infoTodasQuestoesTemTempo", "INTERRUPCOES_PARAM_CRONOMETRO");
					} else {
						idsHide.push("infoTodasQuestoesTemTempo");
						idsShow.push("TEMPO_CRONOMETRO_POR_QUESTAO", "INTERRUPCOES_PARAM_CRONOMETRO");
					}

					await this.hide(idsHide);
					await this.show(idsShow);

					this.todasQuestoesTemTempo = response;

				} else if (this.todasQuestoesTemTempo === true) {

					this.setValor("TEMPO_CRONOMETRO_POR_QUESTAO", "1800");
					idsHide.push("TEMPO_CRONOMETRO_POR_QUESTAO");
					idsShow.push("infoTodasQuestoesTemTempo", "INTERRUPCOES_PARAM_CRONOMETRO");
					
				} else {
					idsHide.push("infoTodasQuestoesTemTempo");
					idsShow.push("TEMPO_CRONOMETRO_POR_QUESTAO", "INTERRUPCOES_PARAM_CRONOMETRO");
				}
			} else if(tipoCronometro == "S") {
				idsHide.push("tempoParaResposta", "TEMPO_CRONOMETRO_POR_QUESTAO", "INTERRUPCOES_PARAM_CRONOMETRO", "infoTodasQuestoesTemTempo");
				idsShow.push("configuracao-tempo-secao", "INTERRUPCOES_PARAM_CRONOMETRO");
			} else {
				idsHide.push("tempoParaResposta", "TEMPO_CRONOMETRO_POR_QUESTAO", "INTERRUPCOES_PARAM_CRONOMETRO", "infoTodasQuestoesTemTempo", "configuracao-tempo-secao");
			}

		} else {
			idsHide.push("tipoExibicaoProva", "numTentativas", "titulo_tempo_prova", "tipoCronometro", "tempoParaResposta", "TEMPO_CRONOMETRO_POR_QUESTAO", "INTERRUPCOES_PARAM_CRONOMETRO", "infoTodasQuestoesTemTempo");
		}

		await this.hide(idsHide);
		await this.show(idsShow);
		$(document).trigger("fp-ao-configuracoes-alterado");
	}

	async atualizarEmbaralhamento(isUsuarioAlterandoEmbaralhamento = false) {

		const ativo = this.getValor("isEmbaralhamento") === "COM_EMBARALHAMENTO";
		const ids = ["EMBARALHAR_SECOES", "EMBARALHAR_QUESTOES", "EMBARALHAR_ITENS"];

		await this.show(ids, ativo);

		if (isUsuarioAlterandoEmbaralhamento && ativo) {
			ids.forEach(id => this.setValor(id, true));
		}

		const nenhumMarcado = !ids.map(id => this.getValor(id)).some(valor => valor === true);

		if (!ativo || nenhumMarcado) {
			this.setValor("isEmbaralhamento", "SEM_EMBARALHAMENTO");
			ids.forEach(id => this.setValor(id, null));
			await this.show(ids, false);
		}		
	}

	async atualizarCamposFacematch(isCarregamentoPagina: boolean) {
		const tipoFacematchHabilitado = this.getValor("tipoFacematchHabilitado");
		const ids = ["HABILITAR_VALIDACAO_FACEMATCH", "HABILITAR_FACEMATCH_DURANTE_APLICACAO", "tipoTratamentoReprovacaoFacematch"];

		if (tipoFacematchHabilitado === "FACEMATCH_ANTES") {
			await this.show("HABILITAR_VALIDACAO_FACEMATCH", "tipoTratamentoReprovacaoFacematch");
			await this.hide("HABILITAR_FACEMATCH_DURANTE_APLICACAO");
			if (!isCarregamentoPagina) {
				this.setValor("HABILITAR_FACEMATCH_DURANTE_APLICACAO", null);
			}
			await this.atualizarCamposProctoring(isCarregamentoPagina, false);

		} else if (tipoFacematchHabilitado === "FACEMATCH_ANTES_E_DURANTE") {
			await this.show(ids);
			await this.atualizarCamposProctoring(isCarregamentoPagina, true);

		} else {
			await this.hide(ids);
			this.setValor(["HABILITAR_VALIDACAO_FACEMATCH", "HABILITAR_FACEMATCH_DURANTE_APLICACAO"], null);
			this.setValor("tipoTratamentoReprovacaoFacematch", "PERMITIR_INICIO_PROVA")
			await this.atualizarCamposProctoring(isCarregamentoPagina, false);
		}
	}

	async salvarConfiguracoes(irParaAbaFases = false, isSalvarComo = false) {

		this.verificarObrigatorios();
		const tipoAplicacao = this.getValor("tipoAplicacao");

		let possuiTipoAplicacao = $("#tipoAplicacao").length > 0;

		let camposObrigatorios = [];

		if (possuiTipoAplicacao) {
			if (tipoAplicacao != "F" && tipoAplicacao != "I") {
				camposObrigatorios = ["tipoAplicacao", "tipoExibicaoProva", "numTentativas"];
			} else {
				camposObrigatorios = ["tipoAplicacao"];
			}

			if (!this.validarCamposObrigatorios(camposObrigatorios)) {
				return null;
			}
		}

		const edicaoAgendamentoProvaTO: any = {
			codAgendamento: this.edicaoAgendamentoProvaTO.codAgendamento,
			isSalvarComo: isSalvarComo,
			codProva: this.edicaoAgendamentoProvaTO.codProva,
			codUsuarioProfessorCertificado: this.getValor("codUsuarioProfessorCertificado"),
			endereco: this.getValor("endereco"),
			local: this.getValor("local"),
			tempoParaResposta: this.getValor("tempoParaResposta"),
			codEmpresa: this.getValor("codEmpresa"),
			codUsuarioFiscal: this.getValor("codUsuarioFiscal"),
			tipoAplicacao: tipoAplicacao,
			tipoExibicaoProva: this.getValor("tipoExibicaoProva"),
			htmlPaginaExterna: this.getValor("htmlPaginaExterna"),
			msgApresentacao: this.getValor("msgApresentacao"),
			msgAprovacao: this.edicaoAgendamentoProvaTO.porcentagemPontuacaoMinima != null ? this.getValor("msgAprovacao") : null,
			msgReprovacao: this.edicaoAgendamentoProvaTO.porcentagemPontuacaoMinima != null ? this.getValor("msgReprovacao") : null,
			msgProvaEncerrada: this.edicaoAgendamentoProvaTO.porcentagemPontuacaoMinima != null ? null : this.getValor("msgProvaEncerrada"),
			msgCorrecaoPendente: this.edicaoAgendamentoProvaTO.possuiDiscursiva ? this.getValor("msgCorrecaoPendente") : null,
			cfgsGerais: {},
		}

		let numTentativas = this.getValor("numTentativas");

		if (numTentativas == "UMA") {
			edicaoAgendamentoProvaTO.numTentativas = 1;

		} else if (numTentativas == "INDETERMINADO") {
			edicaoAgendamentoProvaTO.numTentativas = -1;

		} else {
			edicaoAgendamentoProvaTO.numTentativas = $(".num-tentativas").val();
		}

		edicaoAgendamentoProvaTO.cfgsGerais["CONTROLE_COTA"] = JSON.stringify(amaisVH.getValor("CONTROLE_COTA"));
		edicaoAgendamentoProvaTO.cfgsGerais["LIBERADO_PARA_VISITANTES"] = this.getValor("LIBERADO_PARA_VISITANTES");
		edicaoAgendamentoProvaTO.cfgsGerais["INTERRUPCOES_PARAM_CRONOMETRO"] = this.getValor("INTERRUPCOES_PARAM_CRONOMETRO");
		edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_QUESTAO"] = this.getValor("TEMPO_CRONOMETRO_POR_QUESTAO");

		const tempoPorSecao = [];
		$("[id^='TEMPO_CRONOMETRO_SECAO']").each(function() {
			const id = $(this).attr('id');
			const codSecao = id.split("TEMPO_CRONOMETRO_SECAO_")[1];
			const valor = amaisVH.getValor(id);
			if (valor) tempoPorSecao.push({codSecao: codSecao, tempoParaResposta: valor});
		});
		edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_SECAO"] = JSON.stringify(tempoPorSecao);

		edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_CONCLUSAO_COM_RESPOSTA_EM_BRANCO"] = this.getValor("BLOQUEAR_CONCLUSAO_COM_RESPOSTA_EM_BRANCO");
		edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO"] = this.getValor("CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO");
		edicaoAgendamentoProvaTO.cfgsGerais["HABILITAR_CODIGO_ROTATIVO_ACESSO_PROVA"] = this.getValor("HABILITAR_CODIGO_ROTATIVO_ACESSO_PROVA");
		edicaoAgendamentoProvaTO.cfgsGerais["SAFE_BROWSER_HABILITADO"] = this.getValor("SAFE_BROWSER_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_FULL_SCREEN"] = this.getValor("IS_FULL_SCREEN");
		edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_CORRETOR_ORTOGRAFICO_DISCURSIVAS"] = this.getValor("BLOQUEAR_CORRETOR_ORTOGRAFICO_DISCURSIVAS");
		edicaoAgendamentoProvaTO.cfgsGerais["ANULAR_DISCURSIVA_SEM_MINIMO_LINHAS"] = this.getValor("ANULAR_DISCURSIVA_SEM_MINIMO_LINHAS");
		edicaoAgendamentoProvaTO.cfgsGerais["INSTRUCOES_PARA_AVALIADO"] = this.getValor("INSTRUCOES_PARA_AVALIADO");

		edicaoAgendamentoProvaTO.cfgsGerais["IS_PROCTORING_HABILITADO"] = this.getValor("IS_PROCTORING_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_CAPTURA_TELA_PROCTORING_HABILITADA"] = this.getValor("IS_CAPTURA_TELA_PROCTORING_HABILITADA");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_GRAVACAO_VIDEO_PROCTORING_HABILITADO"] = this.getValor("IS_GRAVACAO_VIDEO_PROCTORING_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_AUTORIZACAO_CANDIDATO_PROCTORING_HABILITADO"] = this.getValor("IS_AUTORIZACAO_CANDIDATO_PROCTORING_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_CHAT_HABILITADO"] = this.getValor("IS_CHAT_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_PREVIEW_GRAVACAO_PROCTORING_HABILITADO"] = this.getValor("IS_PREVIEW_GRAVACAO_PROCTORING_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_SYSCHECK_PROCTORING_HABILITADO"] = this.getValor("IS_SYSCHECK_PROCTORING_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_WEBCAM_CHECK_PROCTORING_HABILITADO"] = this.getValor("IS_WEBCAM_CHECK_PROCTORING_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["FORCAR_COMPARTILHAMENTO_TELA_INTEIRA"] = this.getValor("FORCAR_COMPARTILHAMENTO_TELA_INTEIRA");
		edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_USO_MULTIPLO_MONITORES"] = this.getValor("BLOQUEAR_USO_MULTIPLO_MONITORES");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_SCREENSHOT_HABILITADO"] = this.getValor("IS_SCREENSHOT_HABILITADO");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_ANALISE_IA_HABILITADA"] = this.getValor("IS_ANALISE_IA_HABILITADA");

		edicaoAgendamentoProvaTO.cfgsGerais["IS_INFORMAR_CORRETORES_SOBRE_DISCURSIVAS_DISPONIVEIS"] = this.getValor("IS_INFORMAR_CORRETORES_SOBRE_DISCURSIVAS_DISPONIVEIS");
		edicaoAgendamentoProvaTO.cfgsGerais["IS_INFORMAR_CRIADOR_DE_PROVA_SOBRE_DISCURSIVAS_CORRIGIDAS"] = this.getValor("IS_INFORMAR_CRIADOR_DE_PROVA_SOBRE_DISCURSIVAS_CORRIGIDAS");

		edicaoAgendamentoProvaTO.cfgsGerais["ENCERRAR_SECAO_AVALIADO_LINK_EXTERNO"] = this.getValor("ENCERRAR_SECAO_AVALIADO_LINK_EXTERNO");
		edicaoAgendamentoProvaTO.cfgsGerais["MENSAGEM_ENCERRAMENTO_SECAO_AVALIADO_LINK_EXTERNO"] = this.getValor("MENSAGEM_ENCERRAMENTO_SECAO_AVALIADO_LINK_EXTERNO");
		edicaoAgendamentoProvaTO.cfgsGerais["QUESTIONARIO_COMPLEMENTAR_CADASTRO"] = this.getValor("QUESTIONARIO_COMPLEMENTAR_CADASTRO");

		const tipoFacematchHabilitado = this.getValor("tipoFacematchHabilitado");
		let numTentativasFacematch = null;
		let permitirInicioSemFacematch = null;
		
		if (["FACEMATCH_ANTES", "FACEMATCH_ANTES_E_DURANTE"].includes(tipoFacematchHabilitado)) {
			
			numTentativasFacematch = Number(this.getValor("HABILITAR_VALIDACAO_FACEMATCH"));

			if (this.isEmpty(numTentativasFacematch) || numTentativasFacematch < 1) {
				await this.exibirAlerta({
					titulo: `<i class="fa fa-exclamation-circle"></i> ${this.getMsg("FP_FRONT_CadastroAOVH_062")}`,
					msg: this.getMsg("FP_FRONT_CadastroAOVH_061")
				});
				this.focar("HABILITAR_VALIDACAO_FACEMATCH");
				return null;
			}

			const maxTentativasPermitidas = this.listagensAuxiliaresAgendamentoProvaTO.faceMatchMaxTentativas;
			
			if (this.hasValue(maxTentativasPermitidas) && numTentativasFacematch > maxTentativasPermitidas) {
				await this.exibirAlerta({
					titulo: `<i class='fa fa-exclamation-circle'></i> ${this.getMsg("FP_FRONT_CadastroAOVH_062")}`,
					msg: this.getMsg("FP_FRONT_CadastroAOVH_063", maxTentativasPermitidas)
				});
				this.focar("HABILITAR_VALIDACAO_FACEMATCH");
				return null;
			}

			const tipoTratamentoReprovacaoFacematch = this.getValor("tipoTratamentoReprovacaoFacematch");

			if (this.isEmpty(tipoTratamentoReprovacaoFacematch)) {
				await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroAOVH_064") });
				this.focar("tipoTratamentoReprovacaoFacematch");
				return null;
			}

			permitirInicioSemFacematch = (tipoTratamentoReprovacaoFacematch === "PERMITIR_INICIO_PROVA");
		}
		
		edicaoAgendamentoProvaTO.cfgsGerais["HABILITAR_VALIDACAO_FACEMATCH"] = numTentativasFacematch;
		edicaoAgendamentoProvaTO.cfgsGerais["PERMITIR_INICIO_PROVA_SEM_FACEMATCH"] = permitirInicioSemFacematch;

		let intervaloChecagemFacematch = null;

		if (tipoFacematchHabilitado === "FACEMATCH_ANTES_E_DURANTE") {

			intervaloChecagemFacematch = Number(this.getValor("HABILITAR_FACEMATCH_DURANTE_APLICACAO"));

			if (this.isEmpty(intervaloChecagemFacematch)) {
				await this.exibirAlertaFacematchDuranteAplicacao(this.getMsg('FP_FRONT_CadastroAOVH_141'));
				this.focar("HABILITAR_FACEMATCH_DURANTE_APLICACAO");
				return;
			}

			if (intervaloChecagemFacematch < 15) {
				await this.exibirAlertaFacematchDuranteAplicacao(this.getMsg('FP_FRONT_CadastroAOVH_140'));
				this.focar("HABILITAR_FACEMATCH_DURANTE_APLICACAO");
				return;
			}
		}

		edicaoAgendamentoProvaTO.cfgsGerais["HABILITAR_FACEMATCH_DURANTE_APLICACAO"] = intervaloChecagemFacematch;

		let tipoBloqueioParaVoltarEmQuestoes = this.getValor("tipoBloqueioParaVoltarEmJaRespondidas");

		if (tipoBloqueioParaVoltarEmQuestoes == null || tipoBloqueioParaVoltarEmQuestoes == "N") {
			edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_QUESTOES_JA_RESPONDIDAS"] = false;
			edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_QUESTOES_JA_VISUALIZADAS"] = false;
		} else if (tipoBloqueioParaVoltarEmQuestoes == "BLOQUEAR_QUESTOES_JA_RESPONDIDAS") {
			edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_QUESTOES_JA_RESPONDIDAS"] = true;
			edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_QUESTOES_JA_VISUALIZADAS"] = false;
		} else if (tipoBloqueioParaVoltarEmQuestoes == "BLOQUEAR_QUESTOES_JA_VISUALIZADAS") {
			edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_QUESTOES_JA_RESPONDIDAS"] = false;
			edicaoAgendamentoProvaTO.cfgsGerais["BLOQUEAR_QUESTOES_JA_VISUALIZADAS"] = true;
		}

		let monitoramentoSaidaNavegador = this.getValor("tipoAnulacaoSaidaAbaNavegador");

		if (monitoramentoSaidaNavegador == null || monitoramentoSaidaNavegador == "N") {
			edicaoAgendamentoProvaTO.cfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR_SEM_ANULACAO_AUTOMATICA"] = null;
		} else if (monitoramentoSaidaNavegador == "MONITORAR_SAIDA_DO_NAVEGADOR") {
			edicaoAgendamentoProvaTO.cfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR"] = this.edicaoAgendamentoProvaTO.segundosLimiteSaidaMonitoramento;
			edicaoAgendamentoProvaTO.cfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR_SEM_ANULACAO_AUTOMATICA"] = null;
		} else if (monitoramentoSaidaNavegador == "MONITORAR_SAIDA_DO_NAVEGADOR_SEM_ANULACAO_AUTOMATICA") {
			edicaoAgendamentoProvaTO.cfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["MONITORAR_SAIDA_DO_NAVEGADOR_SEM_ANULACAO_AUTOMATICA"] = this.edicaoAgendamentoProvaTO.segundosLimiteSaidaMonitoramento;
		}

		let enviarCodigoAcessoProva = this.getValor("codigoAcessoProva");

		if (enviarCodigoAcessoProva == null || enviarCodigoAcessoProva == "N") {
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO"] = null;
		} else if (enviarCodigoAcessoProva == "CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO") {
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO"] = true;
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO"] = null;
		} else if (enviarCodigoAcessoProva == "CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO") {
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO"] = true;
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO"] = null;
		}  else if (enviarCodigoAcessoProva == "CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO") {
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_EMAIL_POR_CODIGO_DE_ACESSO"] = true;
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_OU_EMAIL_POR_CODIGO_DE_ACESSO"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["CONFIRMACAO_WHATSAPP_POR_CODIGO_DE_ACESSO"] = null;
		}

		let tipoCronometro = this.getValor("tipoCronometro");

		if (tipoCronometro == "P") {

			edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_QUESTAO"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_SECAO"] = null;

			if (edicaoAgendamentoProvaTO.tempoParaResposta != null) {
				edicaoAgendamentoProvaTO.tempoParaResposta = edicaoAgendamentoProvaTO.tempoParaResposta * 60;
			}

		} else if (tipoCronometro == "Q") {
			edicaoAgendamentoProvaTO.tempoParaResposta = null;
			edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_SECAO"] = null;

		} else if(tipoCronometro == "S") {
			edicaoAgendamentoProvaTO.tempoParaResposta = null;
			edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_QUESTAO"] = null;
		} else {
			edicaoAgendamentoProvaTO.tempoParaResposta = null;
			edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_QUESTAO"] = null;
			edicaoAgendamentoProvaTO.cfgsGerais["TEMPO_CRONOMETRO_POR_SECAO"] = null;
		}

		if (edicaoAgendamentoProvaTO.numTentativas == null || edicaoAgendamentoProvaTO.numTentativas == "" || edicaoAgendamentoProvaTO.numTentativas == 0) {
			await this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_CadastroAOVH_065"),
				botoes: [{ label: this.getMsg("MSG_VH_003") }]
			});
			this.focar("numTentativas");
			return null;
		}

		if (edicaoAgendamentoProvaTO.numTentativas != -1 && (edicaoAgendamentoProvaTO.numTentativas > 127 || edicaoAgendamentoProvaTO.numTentativas < 1)) {
			await this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_CadastroAOVH_066"),
				botoes: [{ label: this.getMsg("MSG_VH_003") }],
			});
			this.focar("numTentativas");
			return null;
		}

		let isEmbaralhamento = this.getValor("isEmbaralhamento");
		let isEmbaralharSecoes = null;
		let isEmbaralharQuestoes = null;
		let isEmbaralharItens = null;

		if (isEmbaralhamento == "COM_EMBARALHAMENTO") {
			isEmbaralharSecoes = this.getValor("EMBARALHAR_SECOES");
			isEmbaralharQuestoes = this.getValor("EMBARALHAR_QUESTOES");
			isEmbaralharItens = this.getValor("EMBARALHAR_ITENS");

			if (!isEmbaralharSecoes && !isEmbaralharQuestoes && !isEmbaralharItens) {
				await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroAOVH_067") });
				return null;
			}
		}

		edicaoAgendamentoProvaTO.cfgsGerais["EMBARALHAR_SECOES"] = isEmbaralharSecoes;
		edicaoAgendamentoProvaTO.cfgsGerais["EMBARALHAR_QUESTOES"] = isEmbaralharQuestoes;
		edicaoAgendamentoProvaTO.cfgsGerais["EMBARALHAR_ITENS"] = isEmbaralharItens;

		if (edicaoAgendamentoProvaTO == null) {
			return;
		}

		const codAgendamento = await this.call("CadastroAOFCD/salvarAO", edicaoAgendamentoProvaTO);

		this.mostrarMsgAjax(this.getMsg("FP_FRONT_CadastroAOVH_068"), 3);

		if (codAgendamento && irParaAbaFases) {
			cadastroAOVH.exibirAbasEdicaoAO(1, codAgendamento);

		} else {
			try {
				await this.editarAplicacaoOnline(codAgendamento, edicaoAgendamentoProvaTO.codProva);
			} catch (ignored) {}
		}
	}

	async exibirPopupExclusao(codAgendamento, codProva, isArquivada) {

		const prosseguir = async (enviarEmails?: boolean) => {

			await this.call("AgendamentoProvaFCD/cancelar", codAgendamento, enviarEmails);

			try {
				await cadastroProvaVH.exibirAbasProva(3, codProva);
			} catch (ignored) {}
		}

		if (isArquivada === true) {
			await prosseguir();
			return;
		}

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CadastroAOVH_069"),
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroAOVH_070"),
				classe: "btn-danger",
				onClick: async () => {
					await prosseguir(this.getValor("isEnviarEmailAvaliados"));
				}
			}]
		})

		this.addTexto(this.getMsg("FP_FRONT_CadastroAOVH_072"));
		this.addTexto(this.getMsg("FP_FRONT_CadastroAOVH_073"));

		this.addCheckbox({
			label: this.getMsg("FP_FRONT_CadastroAOVH_071"),
			id: "isEnviarEmailAvaliados",
			classe: "col-md-12"
		})

		this.exibirPopups();
	}

	async editarInscricoes(codAgendamento, codProva) {

		const edicaoInscricaoAOTO: EdicaoInscricaoAOTO = await this.call("CadastroAOFCD/recuperarInscritos", codAgendamento);

		this.limpar(true);

		this.append(`
			<div class="row" id="edicao-ao-inscritos" 
				cod-agendamento="${edicaoInscricaoAOTO.codAgendamento}"
				cod-usuario-criador="${edicaoInscricaoAOTO.codUsuarioCriador}" 
				link-template-lti="${edicaoInscricaoAOTO.linkTemplateLTI}" 
				possui-fase-aplicacao="${edicaoInscricaoAOTO.possuiFaseAplicacao}">

				<div id="area-turmas" class="col-md-12">
				</div>

				<div id="area-inscritos" class="col-md-12">
				</div>

				<div class="btn btn-group pull-right">
		`);

		this.addBotao({
			id: "btnVoltarAgendamento",
			label: "Voltar",
			classe: "btn-primary",
			onClick: () => {
				cadastroProvaVH.exibirAbasProva(3, codProva).then(() => {},() => {});
			}
		});

		this.append(`
				</div>
			</div>
		`);

		this.exibir();

		await this.atualizarInscricaoTurmas(edicaoInscricaoAOTO);
		await this.atualizarInscricaoAvaliados(edicaoInscricaoAOTO.codAgendamento, edicaoInscricaoAOTO.collectionEdicaoInscricaoAvaliadoAOTO, edicaoInscricaoAOTO.isAplicacaoTemplate);
	}

	async atualizarInscricaoTurmas(edicaoInscricaoAOTO: EdicaoInscricaoAOTO) {
		this.setIdTarget("area-turmas");
		this.setHtml("area-turmas", "");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: (edicaoInscricaoTurmaAOTO) => {
				let nomeTurma = edicaoInscricaoTurmaAOTO.nome;
				if (edicaoInscricaoTurmaAOTO.identificador) {
					nomeTurma += " <small>(" + edicaoInscricaoTurmaAOTO.identificador + ")</small>";
				}
				if (this.isAnalista()) {
					let hashEdicaoTurma = UtilHash.getHash(cadastroTurmaVH.exibirAbasTurma, 1, edicaoInscricaoTurmaAOTO.codTurma);
					return `<a href='${hashEdicaoTurma}' target='_blank'>${nomeTurma}</a>`;
				} else {
					return nomeTurma;
				}
			}, classe: "descricao"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAOVH_078"), prop: "nomeSegmento"});
		colunas.push({titulo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO"), prop: "numAlunosNaTurma"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAOVH_079"), prop: "numInscritos"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAOVH_080"), prop: "numInscritosIniciaram"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAOVH_081"), prop: "numInscritosFinalizaram"});

		const podeEditar = this.isAdministrador() || !edicaoInscricaoAOTO.isAplicacaoTemplate;

		await this.addTabela({
			collection: edicaoInscricaoAOTO.collectionEdicaoInscricaoTurmaAOTO,
			id: "turmas_inscritas",
			propId: "codTurma",
			titulo: this.getCfg("LABEL_LISTAGEM_TURMAS"),
			colunas: colunas,
			onExclusao: !podeEditar ? null : async (botao, codTurmaRemocao) => {

				const edicaoInscricaoTurmaAOTOParaRemocao = edicaoInscricaoAOTO.collectionEdicaoInscricaoTurmaAOTO.find(to => to.codTurma == codTurmaRemocao);

				if (edicaoInscricaoTurmaAOTOParaRemocao) {

					let msg = [this.getMsg("FP_FRONT_CadastroAOVH_082", edicaoInscricaoTurmaAOTOParaRemocao.nome)];

					await this.exibirAlerta({
						msg: msg.join(""),
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroAOVH_083"),
							classe: "btn-warning",
							onClick: async () => {
								edicaoInscricaoAOTO.collectionEdicaoInscricaoTurmaAOTO = edicaoInscricaoAOTO.collectionEdicaoInscricaoTurmaAOTO
									.filter(to => to !== edicaoInscricaoTurmaAOTOParaRemocao);
								await this.atualizarInscricaoTurmas(edicaoInscricaoAOTO);
								await this.salvarInscricoes(null, edicaoInscricaoAOTO.codAgendamento);
							}
						}, {
							label: this.getMsg("FP_FRONT_CadastroAOVH_130"),
							classe: "btn-primary",
							onClick: async () => {
								edicaoInscricaoAOTO.collectionEdicaoInscricaoTurmaAOTO = edicaoInscricaoAOTO.collectionEdicaoInscricaoTurmaAOTO
									.filter(to => to !== edicaoInscricaoTurmaAOTOParaRemocao);
								await this.atualizarInscricaoTurmas(edicaoInscricaoAOTO);
								this.codsTurmasManterUsuarios.push(edicaoInscricaoTurmaAOTOParaRemocao.codTurma);
								await this.salvarInscricoes(null, edicaoInscricaoAOTO.codAgendamento);
							}
						}]
					});
				}
			},
			labelNovo: this.getMsg("FP_FRONT_CadastroAOVH_085", this.getCfg("LABEL_TURMA").toLowerCase()),
			onNovo: !podeEditar ? null : async () => {

				let codsTurmasInscritos = edicaoInscricaoAOTO.collectionEdicaoInscricaoTurmaAOTO.map(to => to.codTurma);

				this.addPopup({
					width: "600px",
					titulo: this.getMsg("FP_FRONT_CadastroAOVH_086", this.getCfg("LABEL_TURMA").toLowerCase()),
					botoes: [{
						label: this.getMsg("FP_FRONT_CadastroAOVH_077"),
						classe: "btn-primary btn-salvar-inscricoes",
						onClick: async () => {
							const codsTurmasParaIncluir = [];

							for (const to of this.getTOsSelecionadosSelect("codsTurmasSelecionadas")) {
								codsTurmasParaIncluir.push(to.id);
							}

							const codsTurmas: any[] = this.getValor("turmas_inscritas");
							if (codsTurmas) {
								codsTurmas.push(...codsTurmasParaIncluir);
							}

							const salvamentoIncricoesAOTO = {
								codAgendamento: edicaoInscricaoAOTO.codAgendamento,
								codsUsuarios: this.getValor("avaliados_inscritos", undefined, true),
								codsTurmas: codsTurmas,
								enviarEmailsParaParticipantes: this.getValor("enviarEmailsParaParticipantes"),
								enviarEmailsParaParticipantesComNovaSenha: this.getValor("enviarEmailsParaParticipantesComNovaSenha"),
								codsTurmasManterUsuarios: this.codsTurmasManterUsuarios,
								codsUsuariosArquivarPF: this.codsUsuariosArquivarPF
							}

							await this.salvarInscricoes(salvamentoIncricoesAOTO)
						}
					}]
				});

				await this.addSelect({
					collection: [],
					id: "codsTurmasSelecionadas",
					label: this.getMsg("FP_FRONT_CadastroAOVH_087", this.getCfg("LABEL_LISTAGEM_TURMAS")),
					multiplo: true,
					classe: "col-md-12",
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarTurmasPorSegmentosEAvaliacoes",
						payload: {
							codEmpresa: edicaoInscricaoAOTO.codEmpresa,
							codsAvaliacoesFolhas: edicaoInscricaoAOTO.codsAvaliacoes,
							codsTurmasSelecionadas: codsTurmasInscritos,
						},
						numMinimoCaracteres: 0
					},
				});

				this.adicionarSelecaoDeEnvioEmail();

				this.exibirPopups();
			}
		});

		this.exibir();
	}

	async atualizarInscricaoAvaliados(codAgendamento, collectionEdicaoInscricaoAvaliadoAOTO, isAplicacaoTermplate?) {

		this.setIdTarget("area-inscritos");
		this.setHtml("area-inscritos", "");

		const podeEditar = this.isAdministrador() || !isAplicacaoTermplate;

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: (edicaoInscricaoAvaliadoAOTO) => {
				let html = [];

				if (this.isAnalista()) {
					let hashEdicaoAluno = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, edicaoInscricaoAvaliadoAOTO.codUsuario);
					html.push("<a href='" + hashEdicaoAluno + "' cod-usuario='" + edicaoInscricaoAvaliadoAOTO.codUsuario + "' target='_blank'><i class='fa fa-user'></i></a> ");
				}

				let abriu = false;

				if (this.isAnalista() || this.isFiscal()) {
					if (edicaoInscricaoAvaliadoAOTO.codProvaFeita != null) {
						html.push(" <a href='");
						html.push(UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, edicaoInscricaoAvaliadoAOTO.codProvaFeita));
						html.push("' target='_blank'>");
						abriu = true;
					}
				}

				html.push(edicaoInscricaoAvaliadoAOTO.nomeUsuario);

				if (abriu) html.push("</a>");

				return html.join("");
			}, classe: "descricao"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAOVH_089", this.getCfg("LABEL_TURMA")), prop: (edicaoInscricaoAvaliadoAOTO) => {
				if (edicaoInscricaoAvaliadoAOTO.codTurmaNaAplicacao != null) {
					return edicaoInscricaoAvaliadoAOTO.nomeTurmaNaAplicacao;
				} else {
					return `<small>${this.getMsg("FP_FRONT_CadastroAOVH_090")}</small>`;
				}
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAOVH_091"), prop: (edicaoInscricaoAvaliadoAOTO) => {
				if (edicaoInscricaoAvaliadoAOTO.observacaoAusencia != null) {
					return edicaoInscricaoAvaliadoAOTO.observacaoAusencia;
				} else if (edicaoInscricaoAvaliadoAOTO.nota != null) {
					return this.getMsg("FP_FRONT_CadastroAOVH_092");
				} else if (edicaoInscricaoAvaliadoAOTO.dataInicio) {
					return this.getMsg("FP_FRONT_CadastroAOVH_093");
				} else if (edicaoInscricaoAvaliadoAOTO.codProvaFeita) {
					return this.getMsg("FP_FRONT_CadastroAOVH_094");
				}
			}
		});

		await this.addTabela({
			collection: collectionEdicaoInscricaoAvaliadoAOTO,
			id: "avaliados_inscritos",
			propId: "codUsuario",
			titulo: this.getMsg("FP_FRONT_CadastroAOVH_088", this.getCfg("LABEL_MENU_CADASTRO_ALUNO")),
			colunas: colunas,
			selecao: this.isAdministrador(),
			onExclusao: !podeEditar ? null : async (botao, codUsuarioRemocao) => {

				const edicaoInscricaoAvaliadoAOTOParaRemocao = collectionEdicaoInscricaoAvaliadoAOTO.find(to => to.codUsuario == codUsuarioRemocao);

				if (edicaoInscricaoAvaliadoAOTOParaRemocao) {

					let msg = [this.getMsg("FP_FRONT_CadastroAOVH_095", edicaoInscricaoAvaliadoAOTOParaRemocao.nomeUsuario)];
					let labelBotao = this.getMsg("FP_FRONT_CadastroAOVH_096");
					let classeBotao = null;

					if (edicaoInscricaoAvaliadoAOTOParaRemocao.nota != null || edicaoInscricaoAvaliadoAOTOParaRemocao.codProvaFeita) {
						let acao = (edicaoInscricaoAvaliadoAOTOParaRemocao.nota != null ? this.getMsg("FP_FRONT_CadastroAOVH_098") : this.getMsg("FP_FRONT_CadastroAOVH_099"));
						msg.push(this.getMsg("FP_FRONT_CadastroAOVH_100", acao));
						labelBotao = this.getMsg("FP_FRONT_CadastroAOVH_097");
						classeBotao = "btn-danger";
					}

					await this.exibirAlerta({
						msg: msg.join(""),
						botoes: [{
							label: labelBotao,
							classe: classeBotao,
							onClick: async () => {
								collectionEdicaoInscricaoAvaliadoAOTO = collectionEdicaoInscricaoAvaliadoAOTO
									.filter(to => to !== edicaoInscricaoAvaliadoAOTOParaRemocao);
								await this.atualizarInscricaoAvaliados(codAgendamento, collectionEdicaoInscricaoAvaliadoAOTO, isAplicacaoTermplate);
								await this.salvarInscricoes(null, codAgendamento);

							}
						}]
					});
				}
			},
			labelNovo: this.getMsg("FP_FRONT_CadastroAOVH_101", this.getCfg("LABEL_ALUNO").toLowerCase()),
			onNovo: !podeEditar ? null :  async () => {

				this.addPopup({
					width: "600px",
					id: "agendamento_inclusao_aluno",
					titulo: this.getMsg("FP_FRONT_CadastroAOVH_102"),
					botoes: [{
						id: "btnIncluirInscritoAO",
						label: this.getMsg("FP_FRONT_CadastroAOVH_077"),
						classe: "btn-primary btn-salvar-inscricoes",
						onClick: async () => {

							const codsUsuariosParaIncluir = [];

							for (const to of this.getTOsSelecionadosSelect("codsUsuariosAgendados")) {
								codsUsuariosParaIncluir.push(to.id);
							}

							const codsUsuarios: any[] = this.getValor("avaliados_inscritos", undefined, true);

							if (codsUsuarios) {
								codsUsuarios.push(...codsUsuariosParaIncluir);
							}

							const salvamentoIncricoesAOTO = {
								codAgendamento: codAgendamento,
								codsUsuarios: codsUsuarios,
								codsTurmas: this.getValor("turmas_inscritas"),
								enviarEmailsParaParticipantes: this.getValor("enviarEmailsParaParticipantes"),
								enviarEmailsParaParticipantesComNovaSenha: this.getValor("enviarEmailsParaParticipantesComNovaSenha"),
								codsTurmasManterUsuarios: this.codsTurmasManterUsuarios,
								codsUsuariosArquivarPF: this.codsUsuariosArquivarPF
							}

							await this.salvarInscricoes(salvamentoIncricoesAOTO);
						}
					}]
				});

				await this.addSelect({
					id: "codsUsuariosAgendados",
					label: this.getMsg("FP_FRONT_CadastroAOVH_104"),
					multiplo: true,
					classe: "col-md-12",
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarUsuariosParaInscricao",
						payload: {
							codAgendamento: codAgendamento
						},
						numMinimoCaracteres: 5,
					},
				});

				this.adicionarSelecaoDeEnvioEmail();

				this.exibirPopups();
			}
		});

		if(this.isAdministrador()) {
			this.setIdTarget('avaliados_inscritos_actions_buttons');

			this.addBotao({
				id: "btn_action_deletar_inscritos_ao",
				label: `<i class='fa fa-trash-o'></i> ${this.getMsg('FP_FRONT_CadastroAOVH_175')}`,
				classe: "btn btn-sm pull-left btn-default",
				onClick: async () => {
					const codsUsuarios = this.getValor("avaliados_inscritos");
					const listaAOS = [];

					for(let codUsuario of codsUsuarios){
						const agendamento = collectionEdicaoInscricaoAvaliadoAOTO.find(
							(to: any) => to.codUsuario == codUsuario);

						if(agendamento) {
							listaAOS.push(agendamento);
						}
					}

					if (this.isEmpty(codsUsuarios)) {
						await this.exibirAlerta({
							msg: this.getMsg("FP_FRONT_CadastroAOVH_176")
						});
						return;
					}

					if(!this.isEmpty(listaAOS)) {
						await this.exibirAlerta({
							msg: this.getMsg("FP_FRONT_CadastroAOVH_177"),
							botoes: [{
								label: this.getMsg("FP_FRONT_CadastroAOVH_178"),
								classe: "btn-warning",
								onClick: async () => {
									for(const ao of listaAOS) {
										collectionEdicaoInscricaoAvaliadoAOTO = collectionEdicaoInscricaoAvaliadoAOTO
											.filter((to: any) => to !== ao);
									}
									await this.atualizarInscricaoAvaliados(
										codAgendamento, collectionEdicaoInscricaoAvaliadoAOTO, isAplicacaoTermplate
									);

									const salvamentoIncricoesAOTO = {
										codAgendamento: codAgendamento,
										codsUsuarios: this.getValor("avaliados_inscritos", undefined, true),
										codsTurmas: this.getValor("turmas_inscritas"),
										enviarEmailsParaParticipantes: this.getValor("enviarEmailsParaParticipantes"),
										enviarEmailsParaParticipantesComNovaSenha: this.getValor("enviarEmailsParaParticipantesComNovaSenha"),
										codsTurmasManterUsuarios: this.codsTurmasManterUsuarios,
										codsUsuariosArquivarPF: this.codsUsuariosArquivarPF
									}

									await this.salvarInscricoes(salvamentoIncricoesAOTO);
								}
							}, {
								label: this.getMsg("FP_FRONT_CadastroAOVH_179"),
								classe: "btn-primary",
								onClick: async () => {
									for(const ao of listaAOS) {
										collectionEdicaoInscricaoAvaliadoAOTO = collectionEdicaoInscricaoAvaliadoAOTO.map((to: any) => {
											if(to === ao) {
												to.codProvaFeita = null;
												to.nota = null;
												to.dataInicio = null;
											}

											return to;
										});
									}
									await this.atualizarInscricaoAvaliados(
										codAgendamento, collectionEdicaoInscricaoAvaliadoAOTO, isAplicacaoTermplate
									);
									this.codsUsuariosArquivarPF = codsUsuarios;

									await this.salvarInscricoes(null, codAgendamento);
								}
							}]
						});
					}
				}
			});

			this.setIdTarget(null);
		}

		this.exibir();
	}

	adicionarSelecaoDeEnvioEmail() {
		this.addCheckbox({
			classe: "col-md-12",
			id: "enviarEmailsParaParticipantes",
			label: this.getMsg("FP_FRONT_CadastroAOVH_183"),
			onChange: () => {
				const $btnSalvarInscricoes = $(".btn-salvar-inscricoes");
				if (this.getValor("enviarEmailsParaParticipantes")) {
					this.show("confirmacaoEnvioEmail");
					$btnSalvarInscricoes.text(this.getMsg("FP_FRONT_CadastroAOVH_128"));
					return;
				}
				this.setValor("enviarEmailsParaParticipantesComNovaSenha", false)
				$btnSalvarInscricoes.text(this.getMsg("FP_FRONT_CadastroAOVH_077"));
				this.hide("confirmacaoEnvioEmail")
			},
			valor: false
		});

		this.append("<div id='confirmacaoEnvioEmail' class='col-md-12' style='display: none'>")

		this.addTexto({
			texto: this.getMsg("FP_FRONT_CadastroAOVH_074"),
			css: "padding: 0"
		});

		this.addCheckbox({
			css: "padding: 0",
			id: "enviarEmailsParaParticipantesComNovaSenha",
			valor: false,
			label: this.getMsg("FP_FRONT_CadastroAOVH_075"),
			classe: "col-md-12",
		});

		this.append("</div>")
	}

	async salvarInscricoes(salvamentoIncricoesAOTO?, codAgendamento?) {
		if (!salvamentoIncricoesAOTO) {
			salvamentoIncricoesAOTO = {
				codAgendamento: codAgendamento,
				codsUsuarios: this.getValor("avaliados_inscritos", undefined, true),
				codsTurmas: this.getValor("turmas_inscritas"),
				enviarEmailsParaParticipantes: this.getValor("enviarEmailsParaParticipantes"),
				enviarEmailsParaParticipantesComNovaSenha: this.getValor("enviarEmailsParaParticipantesComNovaSenha"),
				codsTurmasManterUsuarios: this.codsTurmasManterUsuarios,
				codsUsuariosArquivarPF: this.codsUsuariosArquivarPF
			}
		}
		const idSP = await this.call("AgendamentoProvaFCD/salvarInscricoes", salvamentoIncricoesAOTO);

		this.codsTurmasManterUsuarios = [];
		this.codsUsuariosArquivarPF = [];

		const prosseguir = () => {
			this.mostrarMsgAjax(this.getMsg("FP_FRONT_CadastroAOVH_105"), 3);

			cadastroAOVH.exibirAbasEdicaoAO(2, salvamentoIncricoesAOTO.codAgendamento);
		}

		if (!idSP) {
			prosseguir();
			return;
		}

		this.exibirAlerta({
			msg: `<i class='fa fa-spinner fa-pulse'></i> ${this.getMsg("FP_FRONT_CadastroAOVH_106")}`,
			id: "popup_aguardando_alteracoes",
			blockUICallback: async ($modalMsg) => {
				return new Promise<void>((resolve) => {
					const aguardarGeracao = () => {
						setTimeout(async () => {
							const encerrou = await this.call("CadastroAOFCD/aguardarProcessamento", idSP);
	
							if (encerrou == true) {
								resolve();
								prosseguir();
							} else {
								aguardarGeracao();
							}
						}, 3000);
					}
	
					aguardarGeracao();	
				});
			}
		});
	}

	async editarFases(codAgendamento) {
		this.possuiFiscal = false;

		const edicaoFasesAOTO = await this.call("CadastroAOFCD/recuperarFases", codAgendamento);
		this.edicaoFasesAOTO = edicaoFasesAOTO;
		this.limpar(true);
		this.possuiFiscal = edicaoFasesAOTO.possuiFiscal;
		this.dataEncerramentoAgendamento = edicaoFasesAOTO.dataEncerramentoAgendamento;
		this.dataFinalizacaoAgendamento = edicaoFasesAOTO.dataFinalizacaoAgendamento;

		const podeEditar = this.isAdministrador() || !edicaoFasesAOTO.isAplicacaoTemplate;

		if (podeEditar) {
			this.addGrupoBotoes({
				botoes: [{
					id: "btnCriarNovaFase",
					label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroAOVH_107")}`,
					onClick: () => this.onNovaFase(edicaoFasesAOTO)
				}],
				classe: "pull-right fp-small-business-hidden"
			});
		}


		this.addEspacamentoHorizontal("5px");

		this.append(`
			<div id='edicao_fases' class="col-md-12"
				link-template-lti='${edicaoFasesAOTO.linkTemplateLTI}' 
				cod-agendamento='${edicaoFasesAOTO.codAgendamento}'
				cod-usuario-criador='${edicaoFasesAOTO.codUsuarioCriador}'>
			</div>
		`);

		this.addGrupoBotoes({
			botoes: [{
				id: "btnVoltarAO",
				label: this.getMsg("MSG_VH_AP_31"),
				onClick: () => UtilHash.voltar(),
				css: "float: left"
			}]
		});

		if (podeEditar) {
			this.addGrupoBotoes({
				botoes: [{
					id: "btnSalvarFases",
					label: this.getMsg("FP_FRONT_CadastroAOVH_108"),
					classe: "btn-primary",
					onClick: () => this.salvarFases(edicaoFasesAOTO, false),
				}, {
					id: "btnSalvarFasesEAvancar",
					label: this.getMsg("FP_FRONT_CadastroAOVH_109"),
					onClick: () => this.salvarFases(edicaoFasesAOTO, true),
				}],
				classe: "pull-right"
			});
		}

		this.exibir();

		for (const edicaoFaseTO of edicaoFasesAOTO.listaEdicaoFaseTO) {
			await this.exibirFase(edicaoFaseTO, !podeEditar);
		}

		await this.exibirPainelRecursos(edicaoFasesAOTO);

		this.atualizarCfgsFaseDependentes();

		if (!podeEditar) {
			setTimeout(() => this.desabilitarCamposEdicaoAO(), 300);
		}
	}

	async exibirPainelRecursos(edicaoFasesAOTO) {

		this.setIdTarget("edicao_fases");

		this.numFase = this.numFase || 0;
		this.numFase++;
		let edicaoFaseRecursoTO = edicaoFasesAOTO.edicaoFaseRecursoTO;

		let idFase = edicaoFaseRecursoTO ? edicaoFaseRecursoTO.idFase : null;

		this.append(`
            <div class="panel panel-default" fase-recursos tipo-fase="RECURSOS" num-fase='${this.numFase}' id-fase='${idFase}'>
                <div class="panel-heading">
                    <h3 class="panel-title">${this.getMsg("FP_FRONT_CadastroAOVH_110")}</h3>
                </div>
                <div class="panel-body">
        `);

		this.addRadioGroup({
			id: "options_recursos",
			label: this.getMsg("FP_FRONT_CadastroAOVH_111"),
			collection: [
				{ id: "RECURSOS_NAO_HABILITADO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_112") },
				{ id: "RECURSOS_HABILITADO_APLICACAO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_114") },
				{ id: "HABILITAR_RECURSOS_DURANTE_APLICACAO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_129") },
				{ id: "RECURSOS_HABILITADO_PERIODO", descricao: this.getMsg("FP_FRONT_CadastroAOVH_113") }
			],
			classe: "col-md-6 col-sm-10",
			valor: getValorRecursos(),
			onChange: async () => await this.atualizarOpcoesRecursos()
		});

		this.addEspacamentoHorizontal("1px");

		this.append("<div id='CONTAINER_FASE_RECURSOS_APLICACAO'>");

		this.addCheckbox({
			id: "APLICACAO_IS_APRESENTAR_SOMENTE_QUESTOES_ERRADAS_EM_RECURSO",
			label: edicaoFasesAOTO.cfgsGeraisDescricao["IS_APRESENTAR_SOMENTE_QUESTOES_ERRADAS_EM_RECURSO"],
			valor: edicaoFasesAOTO.cfgsGerais["IS_APRESENTAR_SOMENTE_QUESTOES_ERRADAS_EM_RECURSO"],
			classe: "col-sm-6 col-md-4"
		});
		this.addCheckbox({
			id: "APLICACAO_IS_APRESENTAR_QUESTAO_POR_QUESTAO_EM_RECURSO",
			label: edicaoFasesAOTO.cfgsGeraisDescricao["IS_APRESENTAR_QUESTAO_POR_QUESTAO_EM_RECURSO"],
			valor: edicaoFasesAOTO.cfgsGerais["IS_APRESENTAR_QUESTAO_POR_QUESTAO_EM_RECURSO"],
			classe: "col-sm-6 col-md-4"
		});
		this.addCheckbox({
			id: "AVALIADO_DEVE_VER_GABARITO_EM_RECURSO",
			label: edicaoFasesAOTO.cfgsGeraisDescricao["AVALIADO_DEVE_VER_GABARITO_EM_RECURSO"],
			valor: edicaoFasesAOTO.cfgsGerais["AVALIADO_DEVE_VER_GABARITO_EM_RECURSO"],
			classe: "col-sm-6 col-md-4"
		});
		this.addCheckbox({
			id: "APLICACAO_IS_UPLOAD_ARQUIVO_RECURSOS",
			label: edicaoFasesAOTO.cfgsGeraisDescricao["IS_UPLOAD_ARQUIVO_RECURSOS"],
			valor: edicaoFasesAOTO.cfgsGerais["IS_UPLOAD_ARQUIVO_RECURSOS"],
			classe: "col-sm-6 col-md-4"
		});
		this.addCampoTexto({
			id: "APLICACAO_MINUTOS_LIMITE_CRONOMETRO_INTERPOSICAO_RECURSO",
			label: edicaoFasesAOTO.cfgsGeraisDescricao["MINUTOS_LIMITE_CRONOMETRO_INTERPOSICAO_RECURSO"],
			valor: edicaoFasesAOTO.cfgsGerais["MINUTOS_LIMITE_CRONOMETRO_INTERPOSICAO_RECURSO"],
			classe: "col-sm-6 col-md-4",
			mascara: "9?99",
		});

		this.append("</div>");

		this.append("<div id='CONTAINER_FASE_RECURSOS_DURANTE_APLICACAO'>");

		this.addCheckbox({
			id: "DURANTE_APLICACAO_IS_UPLOAD_ARQUIVO_RECURSOS",
			label: edicaoFasesAOTO.cfgsGeraisDescricao["IS_UPLOAD_ARQUIVO_RECURSOS"],
			valor: edicaoFasesAOTO.cfgsGerais["IS_UPLOAD_ARQUIVO_RECURSOS"],
			classe: "col-sm-6 col-md-4"
		});

		this.append("</div>");

		this.append("<div id='CONTAINER_FASE_RECURSOS'>");

		this.addCampoTexto({
			idComponente: "fase_dataInicio",
			id: this.numFase + "fase_dataInicio",
			valor: edicaoFasesAOTO.edicaoFaseRecursoTO.dataInicio,
			tipo: "DATAHORA",
			classe: "col-sm-6 col-md-4",
			label: this.getMsg("FP_FRONT_CadastroAOVH_115"),
			obrigatorio: true,
			cssInput: "width: 40%"
		});

		this.addCampoTexto({
			idComponente: "fase_dataFim",
			id: this.numFase + "fase_dataFim",
			valor: edicaoFasesAOTO.edicaoFaseRecursoTO.dataFim,
			tipo: "DATAHORA",
			label: this.getMsg("FP_FRONT_CadastroAOVH_116"),
			classe: "col-sm-6 col-md-4",
			cssInput: "width: 40%"
		});

		this.addEspacamentoHorizontal("1px");
		this.append(await this.buildCamposFase(edicaoFasesAOTO.edicaoFaseRecursoTO));
		this.append("</div>");

		this.append("</div></div>");

		function getValorRecursos() {
			if (edicaoFasesAOTO.cfgsGerais["HABILITAR_RECURSOS"]) {
				return "RECURSOS_HABILITADO_APLICACAO";
			}
			else if (edicaoFasesAOTO.cfgsGerais["HABILITAR_RECURSOS_DURANTE_APLICACAO"]) {
				return "HABILITAR_RECURSOS_DURANTE_APLICACAO";
			}
			else if (edicaoFasesAOTO.edicaoFaseRecursoTO != null && edicaoFasesAOTO.edicaoFaseRecursoTO.idFase != null) {
				return "RECURSOS_HABILITADO_PERIODO";
			}
			return "RECURSOS_NAO_HABILITADO";
		}

		this.exibir();
		await this.atualizarOpcoesRecursos();
		this.atualizarCfgsFaseDependentes();
		$(`[num-fase=${this.numFase}]`).data("edicaoFaseTO", edicaoFasesAOTO.edicaoFaseRecursoTO);
	}

	async atualizarOpcoesRecursos() {
		const valor = this.getValor("options_recursos");
		if (valor === "RECURSOS_HABILITADO_APLICACAO") {
			await this.show("CONTAINER_FASE_RECURSOS_APLICACAO");
			await this.hide("CONTAINER_FASE_RECURSOS");
			await this.hide("CONTAINER_FASE_RECURSOS_DURANTE_APLICACAO");
		} else if (valor === "RECURSOS_HABILITADO_PERIODO") {
			await this.show("CONTAINER_FASE_RECURSOS");
			await this.hide("CONTAINER_FASE_RECURSOS_APLICACAO");
			await this.hide("CONTAINER_FASE_RECURSOS_DURANTE_APLICACAO");
		} else if (valor === "HABILITAR_RECURSOS_DURANTE_APLICACAO") {
			await this.show("CONTAINER_FASE_RECURSOS_DURANTE_APLICACAO");
			await this.hide("CONTAINER_FASE_RECURSOS_APLICACAO");
			await this.hide("CONTAINER_FASE_RECURSOS");
		} else {
			await this.hide("CONTAINER_FASE_RECURSOS_APLICACAO");
			await this.hide("CONTAINER_FASE_RECURSOS");
			await this.hide("CONTAINER_FASE_RECURSOS_DURANTE_APLICACAO");
		}
	}

	async exibirFase(edicaoFaseTO, naoPodeExcluirFase?: boolean) {

		this.numFase = this.numFase || 0;

		this.numFase++;
		const possuiRespostas = this.hasValue(edicaoFaseTO.numAvaliados) && edicaoFaseTO.numAvaliados > 0;
		const numReaplicacao = edicaoFaseTO.listaEdicaoCfgFaseTO?.filter(to => to.tipoCfgFase === "NUM_REAPLICACAO").map(to => to.valor);

		this.setIdTarget("edicao_fases");

		this.append(`<div class="panel panel-default" tipo-fase='${edicaoFaseTO.tipoFase}' num-fase='${this.numFase}'`);
		this.append(edicaoFaseTO.idFase ? `id-fase='${edicaoFaseTO.idFase}'` : "");
		this.append(`>`);
		this.append('<div class="panel-heading">');
		this.append('<h3 class="panel-title">' + edicaoFaseTO.nome + " " + numReaplicacao);

		if (!this.isFiscal(false) && !naoPodeExcluirFase) {
			this.addLink({ 
				label: `<i class='fa fa-trash'></i></a>`,
				dica: this.getMsg("FP_FRONT_CadastroAOVH_121"), 
				classe: "pull-right fp-small-business-hidden btn-excluir-fase",
				css: "padding: 0px", 
				onClick: async (event) => await cadastroAOVH.removerFase(event.target) 
			});
		}

		if (possuiRespostas) {
			this.append(`
				<span class="pull-right label label-default" style="margin-right: 10px; margin-top: 2px">
					${edicaoFaseTO.numAvaliados} ${edicaoFaseTO.numAvaliados == 1 ? "avaliado" : "avaliados"}
				</span>
			`);
		}

		this.append('</h3>');
		this.append('</div>');
		this.append('<div class="panel-body">');
		this.append("<div class='row'>");

		this.addFormulario();

		this.append("<div class='col-md-12' style='margin-bottom: 10px;'><em>" + edicaoFaseTO.descricao + "</em></div>");

		this.addCampoTexto({
			idComponente: "fase_dataInicio",
			id: this.numFase + "fase_dataInicio",
			valor: edicaoFaseTO.dataInicio,
			tipo: "DATAHORA",
			classe: "col-sm-6 col-md-4",
			label: ["APLICACAO", "REAPLICACAO"].includes(edicaoFaseTO.tipoFase) ? this.getMsg("FP_FRONT_CadastroAOVH_117") : this.getMsg("FP_FRONT_CadastroAOVH_118"),
			obrigatorio: true,
			cssInput: "width: 40%",
			habilitado: !possuiRespostas
		});

		this.addCampoTexto({
			idComponente: "fase_dataFim",
			id: this.numFase + "fase_dataFim",
			valor: edicaoFaseTO.dataFim,
			tipo: "DATAHORA",
			label: ["APLICACAO", "REAPLICACAO"].includes(edicaoFaseTO.tipoFase) ? this.getMsg("FP_FRONT_CadastroAOVH_119") : this.getMsg("FP_FRONT_CadastroAOVH_120"),
			classe: "col-sm-6 col-md-4",
			cssInput: "width: 40%"
		});

		this.addEspacamentoHorizontal("1px");
		this.append(await this.buildCamposFase(edicaoFaseTO));
		this.append("</div></div></div>");

		this.exibir();
		$(`[num-fase=${this.numFase}]`).data("edicaoFaseTO", edicaoFaseTO);
	}

	async buildCamposFase(edicaoFaseTO) {
		let campos = [];

		for (const edicaoCfgFaseTO of edicaoFaseTO.listaEdicaoCfgFaseTO) {

			const possuiRespostas = this.hasValue(edicaoFaseTO.numAvaliados) && edicaoFaseTO.numAvaliados > 0;

			let permitida = edicaoFaseTO.mapaTipoFasePorTipoCfg[edicaoCfgFaseTO.tipoCfgFase].includes(edicaoFaseTO.tipoFase)
				&& edicaoFaseTO.mapaTipoAplicacaoPorTipoCfg[edicaoCfgFaseTO.tipoCfgFase].includes(edicaoFaseTO.tipoAplicacao.toString());

			if (permitida) {
				if (edicaoCfgFaseTO.tipoCfgFase == "QUESTIONARIO_PRE_PROVA" || edicaoCfgFaseTO.tipoCfgFase == "QUESTIONARIO_POS_PROVA") {
					if (this.hasValue(edicaoFaseTO.listaQuestionarioDisponivelTO)) {
						campos.push(await this.addSelect({
							collection: UtilJson.clonar(edicaoFaseTO.listaQuestionarioDisponivelTO),
							propId: "idQuestionario",
							propLabel: "nomeQuestionario",
							id: this.numFase + edicaoCfgFaseTO.tipoCfgFase,
							valor: questVH.getQuestionarioSelecionado(edicaoFaseTO.listaQuestionarioDisponivelTO, edicaoCfgFaseTO.valor),
							label: edicaoCfgFaseTO.descricaoCfg,
							classe: "col-sm-6 col-md-4",
							retornarHtml: true
						}));
					}

				} else if (edicaoCfgFaseTO.tipoCfgFase == "ROTEIRO_APLICACAO_DIAGNOSTICO") {
					if (this.hasValue(edicaoFaseTO.listaListaTORoteiros)) {
						campos.push(await this.addSelect({
							collection: UtilJson.clonar(edicaoFaseTO.listaListaTORoteiros),
							propId: "id",
							propLabel: "text",
							id: this.numFase + edicaoCfgFaseTO.tipoCfgFase,
							valor: edicaoCfgFaseTO.valor,
							label: edicaoCfgFaseTO.descricaoCfg,
							classe: "col-sm-6 col-md-4",
							retornarHtml: true
						}));
					}

				} else if (edicaoCfgFaseTO.tipoCfgFase == "SECOES_DISPONIBILIZADAS_PARA_AVALIADOS") {
					if (this.hasValue(edicaoFaseTO.listaOpcaoListaTOSecoes)) {
						campos.push(await this.addSelect({
							collection: edicaoFaseTO.listaOpcaoListaTOSecoes,
							id: this.numFase + edicaoCfgFaseTO.tipoCfgFase,
							valor: JSON.parse(edicaoCfgFaseTO.valor),
							label: edicaoCfgFaseTO.descricaoCfg,
							classe: "col-sm-6 col-md-4",
							retornarHtml: true,
							multiplo: true
						}));
					}

				} else if (edicaoCfgFaseTO.tipoCfgFase == "NUM_REAPLICACAO") {
					
					if (this.hasValue(edicaoFaseTO.listaNumsReaplicacao)) {

						// clona pq pode alterar a lista retornada pelo back
						const listaNumsReaplicacao = JSON.parse(JSON.stringify(edicaoFaseTO.listaNumsReaplicacao));
						let valor = null;
						
						if (this.hasValue(edicaoCfgFaseTO.valor)) {
							valor = Number(edicaoCfgFaseTO.valor);
						}

						if (this.hasValue(valor)) {
							if (!listaNumsReaplicacao.includes(valor)) {
								listaNumsReaplicacao.push(valor);
							}
						}

						campos.push(await this.addSelect({
							collection: listaNumsReaplicacao,
							id: this.numFase + edicaoCfgFaseTO.tipoCfgFase,
							valor,
							label: edicaoCfgFaseTO.descricaoCfg,
							classe: "col-sm-6 col-md-4",
							retornarHtml: true,
							obrigatorio: true,
							habilitado: !possuiRespostas,
							onChange: async (event) => {
								const numReaplicacao = this.getValor(event.target);
								await this.verificarSeHaPendenciasParaNovaFase(edicaoFaseTO.codProva, edicaoFaseTO.tipoFase, numReaplicacao, true);
							}
						}));
					}

				} else if (edicaoCfgFaseTO.isBooleano) {
					let isSelecionado = edicaoCfgFaseTO.valor && (edicaoCfgFaseTO.valor == "true" || edicaoCfgFaseTO.valor == true || edicaoCfgFaseTO.valor == "1");
					let habilitado = true;

					if (edicaoCfgFaseTO.tipoCfgFase === "FISCAL_VISUALIZA_CORRECAO" && !this.possuiFiscal) {
						habilitado = false;
						isSelecionado = false;
					}

					campos.push(
						this.addCheckbox({
							idComponente: edicaoCfgFaseTO.tipoCfgFase,
							id: this.numFase + edicaoCfgFaseTO.tipoCfgFase,
							label: edicaoCfgFaseTO.descricaoCfg,
							valor: isSelecionado,
							habilitado: habilitado,
							classe: "col-sm-6 col-md-4",
							onChange: () => this.atualizarCfgsFaseDependentes(),
							retornarHtml: true
						})
					);
				} else if (edicaoCfgFaseTO.isInteiro) {
					campos.push(this.addCampoTexto({
						idComponente: edicaoCfgFaseTO.tipoCfgFase,
						id: this.numFase + edicaoCfgFaseTO.tipoCfgFase,
						label: edicaoCfgFaseTO.descricaoCfg,
						valor: edicaoCfgFaseTO.valor,
						classe: "col-sm-6 col-md-4",
						mascara: "9?99",
						retornarHtml: true
					}));
				}
			}
		}

		return campos.join("");
	}

	async criarNovaFase(edicaoFasesAOTO, usarTipoFase?) {
		const tipoFase = usarTipoFase ? usarTipoFase : this.getValor("tipoFase");
		const novaFaseAplicacao = edicaoFasesAOTO.collectionOpcaoListaTOTipoAplicacaoFase.find(f => f.id === tipoFase);

		await this.verificarSeHaPendenciasParaNovaFase(edicaoFasesAOTO.codProva, tipoFase, null, true);

		const edicaoFaseTOUltimo = edicaoFasesAOTO.listaEdicaoFaseTO[edicaoFasesAOTO.listaEdicaoFaseTO.length - 1];
		const edicaoFaseTONovo = JSON.parse(UtilJson.toString(edicaoFaseTOUltimo));
		UtilJson.resolverEnuns(edicaoFaseTONovo);

		edicaoFaseTONovo.idFase = null;
		edicaoFaseTONovo.nome = novaFaseAplicacao.nome;
		edicaoFaseTONovo.tipoFase = tipoFase;
		edicaoFaseTONovo.dataInicio = edicaoFaseTOUltimo.dataFim;
		edicaoFaseTONovo.dataFim = null;
		edicaoFaseTONovo.numAvaliados = 0;
		edicaoFaseTONovo.descricao = novaFaseAplicacao.descricao;

		if (edicaoFaseTONovo.listaEdicaoCfgFaseTO) {
			edicaoFaseTONovo.listaEdicaoCfgFaseTO.forEach(to => {
				to.idCfgFase = null;
				if (to.tipoCfgFase === "NUM_REAPLICACAO") {
					to.valor = null;
				}
			});
		}

		await this.exibirFase(edicaoFaseTONovo);
		UtilWindow.scrollTo($(`[tipo-fase]:last`));
	}

	onNovaFase(edicaoFasesAOTO) {

		this.addPopup({
			id: "nova_fase",
			titulo: this.getMsg("FP_FRONT_CadastroAOVH_122"),
			width: "400px",
			botoes: [{
				label: "OK", 
				classe: "btn-primary", 
				onClick: async () => {
					await this.criarNovaFase(edicaoFasesAOTO)
				}
			}]
		});

		this.addRadioGroup({
			collection: edicaoFasesAOTO.collectionOpcaoListaTOTipoAplicacaoFase.map(opcaoListaTOTipoAplicacaoFase => {
				return {
					id: opcaoListaTOTipoAplicacaoFase.id,
					descricao: `
						<strong>${opcaoListaTOTipoAplicacaoFase.nome}</strong>
						<br>
						<small>${opcaoListaTOTipoAplicacaoFase.descricao}</small>
					`,
					visivel: opcaoListaTOTipoAplicacaoFase.disabled !== true,
					dica: false
				}
			}),
			id: "tipoFase",
			classe: "col-md-12",
		});

		this.exibirPopups();
	}

	atualizarCfgsFaseDependentes() {
		$("[tipo-fase]").each((i, fase) => {
			const $fase = $(fase);
			const $avaliadoVisualizaResultado = $fase.find("[id='AVALIADO_VISUALIZA_RESULTADO'] input");
			const $avaliadoVisualizaCorrecao = $fase.find("[id='AVALIADO_VISUALIZA_CORRECAO'] input");
			const $avaliadoVisualizaGabarito = $fase.find("[id='AVALIADO_VISUALIZA_GABARITO'] input");
			const $avaliadoFazDownload = $fase.find("[id='AVALIADO_FAZ_DOWNLOAD'] input");

			this.toggleTravaDeCheckbox($avaliadoVisualizaResultado, [$avaliadoVisualizaCorrecao, $avaliadoVisualizaGabarito, $avaliadoFazDownload]);
			this.toggleTravaDeCheckbox($avaliadoVisualizaCorrecao, [$avaliadoVisualizaGabarito]);
		});
	}

	toggleTravaDeCheckbox(elemento, elementosCondicionantes) {
		const isTravar = elementosCondicionantes.some(e => this.getValor(e) == true);
		if (isTravar) {
			this.setValor(elemento, true);
			this.disable(elemento);
		} else {
			this.enable(elemento);
		}
	}

	removerFase(linkRemocao) {
		$(linkRemocao).closest("[tipo-fase]").slideUp(function () {
			$(this).remove();
		});
	}

	async salvarFases(edicaoFasesAOTO, irParaInscricoes = false) {

		this.verificarObrigatorios();
		const tipoRecursoSelecionado = this.getValor("options_recursos");
		const fases = $("#edicao_fases [tipo-fase]").map((i, fase) => fase);

		const salvamentoFasesAOTO = {
			codAgendamento: edicaoFasesAOTO.codAgendamento,
			listaSalvamentoFaseTO: [],
			cfgsGerais: {}
		}

		for (const fase of fases) {

			const $fase = $(fase);
			const numFase = $fase.attr("num-fase");
			const edicaoFaseTO = $fase.data("edicaoFaseTO");

			if ($fase.attr("tipo-fase") == "RECURSOS" && tipoRecursoSelecionado !== "RECURSOS_HABILITADO_PERIODO") continue;

			let salvamentoFaseTO = this.criarObjetoSalvamentoFase($fase, numFase, edicaoFaseTO, edicaoFasesAOTO.codAgendamento);

			if (salvamentoFaseTO.dataFim && salvamentoFaseTO.dataInicio.getTime() >= salvamentoFaseTO.dataFim.getTime()) {
				await this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_CadastroAOVH_123"),
					botoes: [{ label: this.getMsg("MSG_VH_003") }],
				});
				this.focar(numFase + "fase_dataInicio");
				return;
			}

			salvamentoFasesAOTO.listaSalvamentoFaseTO.push(salvamentoFaseTO);
		}

		if (tipoRecursoSelecionado === "RECURSOS_HABILITADO_APLICACAO") {
			salvamentoFasesAOTO.cfgsGerais["HABILITAR_RECURSOS"] = true;
			salvamentoFasesAOTO.cfgsGerais["HABILITAR_RECURSOS_DURANTE_APLICACAO"] = null;
			salvamentoFasesAOTO.cfgsGerais["IS_APRESENTAR_SOMENTE_QUESTOES_ERRADAS_EM_RECURSO"] = this.getValor("APLICACAO_IS_APRESENTAR_SOMENTE_QUESTOES_ERRADAS_EM_RECURSO");
			salvamentoFasesAOTO.cfgsGerais["IS_APRESENTAR_QUESTAO_POR_QUESTAO_EM_RECURSO"] = this.getValor("APLICACAO_IS_APRESENTAR_QUESTAO_POR_QUESTAO_EM_RECURSO");
			salvamentoFasesAOTO.cfgsGerais["AVALIADO_DEVE_VER_GABARITO_EM_RECURSO"] = this.getValor("AVALIADO_DEVE_VER_GABARITO_EM_RECURSO");
			salvamentoFasesAOTO.cfgsGerais["MINUTOS_LIMITE_CRONOMETRO_INTERPOSICAO_RECURSO"] = this.getValor("APLICACAO_MINUTOS_LIMITE_CRONOMETRO_INTERPOSICAO_RECURSO");
			salvamentoFasesAOTO.cfgsGerais["IS_UPLOAD_ARQUIVO_RECURSOS"] = this.getValor("APLICACAO_IS_UPLOAD_ARQUIVO_RECURSOS");
		} else {
			if(tipoRecursoSelecionado === "HABILITAR_RECURSOS_DURANTE_APLICACAO"){
				salvamentoFasesAOTO.cfgsGerais["HABILITAR_RECURSOS_DURANTE_APLICACAO"] = true;
				salvamentoFasesAOTO.cfgsGerais["IS_UPLOAD_ARQUIVO_RECURSOS"] = this.getValor("DURANTE_APLICACAO_IS_UPLOAD_ARQUIVO_RECURSOS");
			}
			else{
				salvamentoFasesAOTO.cfgsGerais["HABILITAR_RECURSOS_DURANTE_APLICACAO"] = null;
			}
			salvamentoFasesAOTO.cfgsGerais["HABILITAR_RECURSOS"] = null;
			salvamentoFasesAOTO.cfgsGerais["IS_APRESENTAR_SOMENTE_QUESTOES_ERRADAS_EM_RECURSO"] = null;
			salvamentoFasesAOTO.cfgsGerais["IS_APRESENTAR_QUESTAO_POR_QUESTAO_EM_RECURSO"] = null;
			salvamentoFasesAOTO.cfgsGerais["AVALIADO_DEVE_VER_GABARITO_EM_RECURSO"] = null;
			salvamentoFasesAOTO.cfgsGerais["MINUTOS_LIMITE_CRONOMETRO_INTERPOSICAO_RECURSO"] = null;
		}

		await this.call("CadastroAOFCD/salvarFases", salvamentoFasesAOTO);

		this.mostrarMsgAjax(this.getMsg("FP_FRONT_CadastroAOVH_124"), 3);

		if (this.dataEncerramentoAgendamento || this.dataFinalizacaoAgendamento) {
			await this.validarAplicacaoEncerrada(salvamentoFasesAOTO.codAgendamento);
		}

		if (irParaInscricoes) {
			cadastroAOVH.exibirAbasEdicaoAO(2, salvamentoFasesAOTO.codAgendamento);
		} else {
			this.editarFases(salvamentoFasesAOTO.codAgendamento);
		}
	}

	async validarAplicacaoEncerrada(codAgendamento) {
		const label = this.dataFinalizacaoAgendamento ? this.getMsg('FP_FRONT_CadastroAOVH_134') : this.getMsg('FP_FRONT_CadastroAOVH_133') ;
		const data = this.dataFinalizacaoAgendamento ? UtilData.toDDMMYYYYHHMM(this.dataFinalizacaoAgendamento) : UtilData.toDDMMYYYYHHMM(this.dataEncerramentoAgendamento);
		const botaoSim = {
			label: this.getMsg('FP_FRONT_CadastroAOVH_137'), classe: 'btn-primary', onClick: async () => {
				await this.call("AgendamentoProvaFCD/reabrirAplicacao", codAgendamento);
				this.mostrarMsgAjax(this.getMsg("FP_FRONT_CadastroAOVH_136"), 3);
			}
		};
		const botaoNao = {
			label: this.getMsg('FP_FRONT_CadastroAOVH_138'), classe: 'btn-secondary'
		};
		this.exibirAlerta({
			titulo: `<i class="fas fa-exclamation-triangle"></i> ${this.getMsg('FP_FRONT_CadastroAOVH_132')}`,
			msg: this.getMsg('FP_FRONT_CadastroAOVH_135', label, data),
			botoes: [botaoSim, botaoNao]
		});
	}

	criarObjetoSalvamentoFase(fase, numFase, edicaoFaseTO, codAgendamento) {

		let salvamentoFaseTO = {
			idAplicacao: codAgendamento,
			idFase: fase.attr("id-fase"),
			tipoAplicacaoFase: fase.attr("tipo-fase"),
			dataInicio: this.getValor(numFase + "fase_dataInicio"),
			dataFim: UtilData.setSegundos(this.getValor(numFase + "fase_dataFim"), 59),
			listaSalvamentoCfgFaseTO: []
		}

		edicaoFaseTO.listaEdicaoCfgFaseTO.forEach((edicaoCfgFaseTO) => {

			let valor = this.getValor(numFase + edicaoCfgFaseTO.tipoCfgFase);

			if (this.isEmpty(valor)) return;

			if (edicaoCfgFaseTO.isBooleano && valor !== true) return;

			if (Array.isArray(valor)) {
				valor = valor.map(v => isNaN(v) ? v : Number(v));
			}

			if (edicaoCfgFaseTO.isString && typeof valor !== "string") {
				valor = JSON.stringify(valor);
			}
			
			const salvamentoCfgFaseTO = {
				idCfgFase: edicaoCfgFaseTO.idCfgFase,
				tipoCfgFase: edicaoCfgFaseTO.tipoCfgFase,
				valor
			}

			salvamentoFaseTO.listaSalvamentoCfgFaseTO.push(salvamentoCfgFaseTO);
		});

		return salvamentoFaseTO;
	}

	criarCopiaAplicacao(codAgendamento) {
		this.addPopup({
			width: "600px",
			// height: "320px",
			titulo: this.getMsg("FP_FRONT_CadastroAOVH_125"),
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroAOVH_126"), 
				classe: "btn-primary", 
				onClick: async () => {
					const irParaAbaFases = false;
					const isSalvarComo = true;
					this.salvarConfiguracoes(irParaAbaFases, isSalvarComo);
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_CadastroAOVH_127"));

		this.exibirPopups();
	}

	async atualizarCamposProctoring(isCarregamentoPagina: boolean, deveDesabilitarProctoring: boolean) {

		const ids = [
			"IS_PROCTORING_HABILITADO",
			"IS_CAPTURA_TELA_PROCTORING_HABILITADA",
			"IS_GRAVACAO_VIDEO_PROCTORING_HABILITADO",
			"IS_AUTORIZACAO_CANDIDATO_PROCTORING_HABILITADO",
			"IS_CHAT_HABILITADO","IS_PREVIEW_GRAVACAO_PROCTORING_HABILITADO",
			"IS_SYSCHECK_PROCTORING_HABILITADO",
			"IS_WEBCAM_CHECK_PROCTORING_HABILITADO",
			"FORCAR_COMPARTILHAMENTO_TELA_INTEIRA",
			"BLOQUEAR_USO_MULTIPLO_MONITORES",
			"IS_SCREENSHOT_HABILITADO",
			"IS_ANALISE_IA_HABILITADA"
		];
		
		if (deveDesabilitarProctoring === true) {
			this.disable(ids);
			if (isCarregamentoPagina === false) {
				this.setValor(ids, false);
			}
		} else if (deveDesabilitarProctoring === false) {
			this.enable(ids);
		}
	}

	async exibirAlertaFacematchDuranteAplicacao(msg) {
		return this.exibirAlerta({
			titulo: `<i class='fa fa-warning'></i> ${this.getMsg('FP_FRONT_CadastroAOVH_132')}`,
			msg: msg
		});
	}	
	
	async verificarSeHaPendenciasParaNovaFase(codProva: number, tipoFase: string, numReaplicacao: number = null, retornarCasoTudoOK = false): Promise<boolean> {

		const pendenciasCriacaoNovaFaseTO = <PendenciasCriacaoNovaFaseTO> await this.call("CadastroAOFCD/verificarRegrasNovaFase", codProva, tipoFase, numReaplicacao);
		
		if (retornarCasoTudoOK && this.isTudoOK(pendenciasCriacaoNovaFaseTO)) {
			return true;
		}

		UtilHash.registrarHistorico(this.verificarSeHaPendenciasParaNovaFase, codProva, tipoFase, numReaplicacao);
		
		this.limpar();
		this.setTitulo("Pendências");
		this.setSubtitulo(pendenciasCriacaoNovaFaseTO.nomeNovaFase + " " + (numReaplicacao || ""));

		if (this.hasValue(pendenciasCriacaoNovaFaseTO.listaPendenciaGeral)) {
			for (let pendenciaGeral of pendenciasCriacaoNovaFaseTO.listaPendenciaGeral) {
				this.addTextoAlerta({ texto: pendenciaGeral });
			}
			this.addEspacamentoHorizontal("20px");
			this.addBotaoVoltar();
			this.exibir();
			return false;
		}

		if (this.isTudoOK(pendenciasCriacaoNovaFaseTO)) {
			this.addTexto(`
				Não há pendências para a criação desta fase.
			`);
			this.exibir();
			return true;
		}

		this.addTexto(`
			Antes de criar esta fase é preciso resolver as pendências abaixo.
		`);

		pendenciasCriacaoNovaFaseTO.listaPendenciasPorNumReaplicacaoTO.sort((a, b) => a.numReaplicacao - b.numReaplicacao);
		let possuiPendenciasSecoes = false;

		for (let pendenciasPorNumReaplicacaoTO of pendenciasCriacaoNovaFaseTO.listaPendenciasPorNumReaplicacaoTO) {

			if (this.isTudoOKReaplicacao(pendenciasPorNumReaplicacaoTO)) continue;

			let nomeFase = "aplicação";
			let nomeFaseAnterior = "aplicação";

			if (pendenciasPorNumReaplicacaoTO.numReaplicacao > 0) {
				nomeFase = "reaplicação " + pendenciasPorNumReaplicacaoTO.numReaplicacao;
			}

			if ((pendenciasPorNumReaplicacaoTO.numReaplicacao - 1) > 0) {
				nomeFaseAnterior = "reaplicação " + (pendenciasPorNumReaplicacaoTO.numReaplicacao - 1);
			}

			this.addSubsubtitulo("Fase de " + nomeFase);

			this.append(`
				<div class="col-md-12">
					<ul class="pendencias">
			`);

			let hashCorrecaoPF = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada);

			pendenciasPorNumReaplicacaoTO.listaPendenciaFaseTO?.forEach((pendenciaFaseAplicacaoTO: PendenciaFaseTO) => {
				if (pendenciaFaseAplicacaoTO.codProvaFeita) {
					this.append(`
						<li>
							${pendenciaFaseAplicacaoTO.descricaoPendencia}: 
							<a href="${hashCorrecaoPF + "/" + pendenciaFaseAplicacaoTO.codProvaFeita}">
								${pendenciaFaseAplicacaoTO.nomeAvaliado}
							</a>
						</li>
					`);
				} else {
					this.append(`
						<li>
							${pendenciaFaseAplicacaoTO.descricaoPendencia}
						</li>
					`);
				}
			});
				
			this.append(`
					</ul>
				</div>
			`);
			
			if (pendenciasPorNumReaplicacaoTO.listaPendenciaSecaoTO?.some(pendenciaSecaoTO => this.hasValue(pendenciaSecaoTO.pendencias))) {

				possuiPendenciasSecoes = true;
				pendenciasPorNumReaplicacaoTO.listaPendenciaSecaoTO.sort((a, b) => a.numSecao - b.numSecao);
				
				this.append(`<div><div class="col-md-12">`);

				const colunas: ColunaAddTabela[] = [];
				colunas.push({titulo: "", prop: "numSecao"});
				colunas.push({titulo: "Seção", prop: "nomeSecao", classe: "descricao"});
				colunas.push({titulo: "Alunos ausentes", prop: "numAlunosQueFaraoTodasSecoes"});
				colunas.push({titulo: "Alunos não aprovados", prop: "numAlunosSemAprovacao"});
				colunas.push({titulo: "Questões inéditas necessárias", prop: "numQuestoesNecessarias"});
				colunas.push({titulo: "Questões inéditas disponíveis", prop: "numQuestoesReaplicacao"});
				colunas.push({titulo: "Pendências", prop: (pendenciaSecaoTO) => {
						if (this.isEmpty(pendenciaSecaoTO.pendencias)) {
							return "Não há. O conteúdo está OK.";
						} else {
							const html = [pendenciaSecaoTO.pendencias.join("<br>")];
							if (pendenciaSecaoTO.numQuestoesReaplicacao > pendenciaSecaoTO.numQuestoesNecessarias) {
								html.push(`
										<br>
										<small>
											Questões inéditas: ${pendenciaSecaoTO.codsQuestoesReaplicacao.map(codQuestao => "#" + codQuestao).join(", ")}
										</small>
									`);
							}
							return html.join("");
						}
					}
				});

				await this.addTabela({
					id: "pendenciasNovaFase",
					collection: pendenciasPorNumReaplicacaoTO.listaPendenciaSecaoTO,
					colunas: colunas,
					ordenacao: false
				});
				this.append(`</div>`);
			}
		}

		this.addEspacamentoHorizontal("20px");

		this.addBotaoVoltar();

		if (possuiPendenciasSecoes) {
			this.addBotao({
				label: `Ir para aba "Questões" da prova`,
				css: "float: right",
				classe: "btn-primary",
				onClick: () => {
					this.fecharTodasPopups();
					cadastroProvaVH.exibirAbasProva(1, pendenciasCriacaoNovaFaseTO.codProva);
				}
			});
		}

		this.exibir();

		return false;
	}

	isTudoOK(to: PendenciasCriacaoNovaFaseTO) {
		if (this.hasValue(to.listaPendenciaGeral)) return false;
		if (!to.listaPendenciasPorNumReaplicacaoTO) return true;
		for (let pendenciasPorNumReaplicacaoTO of to.listaPendenciasPorNumReaplicacaoTO) {
			if (!this.isTudoOKReaplicacao(pendenciasPorNumReaplicacaoTO)) return false;
		}
		return true;
	}

	
	isTudoOKReaplicacao(pendenciasPorNumReaplicacaoTO: PendenciasPorNumReaplicacaoTO) {
		if (!pendenciasPorNumReaplicacaoTO) return true;
		if (this.hasValue(pendenciasPorNumReaplicacaoTO.listaPendenciaFaseTO)) return false;
		if (pendenciasPorNumReaplicacaoTO.listaPendenciaSecaoTO.some(pendenciaSecaoTO => this.hasValue(pendenciaSecaoTO.pendencias))) return false;
		return true;
	}

	desabilitarCamposEdicaoAO() {
		$('input, select, textarea').prop('disabled', true);
		$('div[tipo="EDITOR_HTML"]').attr('disabled', 'disabled');
	}
}

const cadastroAOVH = new CadastroAOVH();

type PendenciasCriacaoNovaFaseTO = {
	codProva: number;
	nomeNovaFase: string;
	listaPendenciaGeral: string[];
	listaPendenciasPorNumReaplicacaoTO: PendenciasPorNumReaplicacaoTO[];
}
type PendenciasPorNumReaplicacaoTO = {
	numReaplicacao: number;
	listaPendenciaFaseTO: PendenciaFaseTO[];
	listaPendenciaSecaoTO: PendenciaSecaoTO[];
}
type PendenciaFaseTO = {
	descricaoPendencia: string;
	codProvaFeita: number;
	nomeAvaliado: string;
}
type PendenciaSecaoTO = {
	codSecao: number;
	numSecao: number;
	nomeSecao: string;
	numQuestoesNecessarias: number;
	numQuestoesReaplicacao: number;
	pendencias: string[];
}
type ListagensAuxiliaresAgendamentoProvaTO = {
	urlAcessoExterno: string;
	tokenDeIdentificacao: string;
	collectionItemListaTOTiposAplicacao: ItemListaTO[];
	collectionSegmentosTO: ItemListaTO[];
	collectionOpcaoListaTOTiposExibicao: OpcaoListaTO[];
	listaOpcaoListaTOSecoes: OpcaoListaTO[];
	mapaCfgsGerais: any;
	proctoringHabilitadoAmbiente: boolean;
	proctoringCapturaTelaHabilitadoAmbiente: boolean;
	proctoringGravacaoVideoHabilitadoAmbiente: boolean;
	proctoringAutorizacaoCandidatoHabilitadoAmbiente: boolean;
	proctoringHabilitacaoChatAmbiente: boolean;
	proctoringAnaliseIaAmbiente: boolean;
	provedorProctoring: string;
	isExibirCfgAlertaDiscursivaDisponivelParaCorrecao: boolean;
	isExibirCfgAlertaDiscursivasCorrigidas: boolean;
	faceMatchHabilitadoAmbiente: boolean;
	faceMatchMaxTentativas: number;
	proctoringAlertasRestricao: string;
	isSafeBrowserHabilitadoAmbiente: boolean;
	exibirListagemFiscais: boolean;
	exibirListagemCorretoresDiscursiva: boolean;
}
type EdicaoInscricaoAOTO = {
	codAgendamento: number;
	codEmpresa: number;
	codUsuarioCriador: number;
	linkTemplateLTI: string;
	possuiFaseAplicacao: boolean;
	codsAvaliacoes: number[];
	collectionEdicaoInscricaoTurmaAOTO: any[]; // EdicaoInscricaoTurmaAOTO[];
	collectionEdicaoInscricaoAvaliadoAOTO: any[]; // EdicaoInscricaoAvaliadoAOTO[];
	isAplicacaoTemplate: boolean;
}