class CadastroTurmaVH extends AmaisVH {
	buscaListagemSalasTO: any;
	filtroInicio: any;
	recuperacaoSalaTO: any;
	filtroFim: any;
	recuperacaoTurmaTO: any;

	constructor() {
		super(CadastroTurmaVH.name);
		this.addOperacaoParaHash("ctms", this.listar);
		this.addOperacaoParaHash("cteat", this.exibirAbasTurma);
		this.addOperacaoParaHash("ctes", this.editarSala);
	}

	listar(numAba) {

		UtilHash.registrarHistorico(this.listar, numAba);

		this.limpar();

		this.addAbas({
			abas: [{ 
				label: this.getCfg("LABEL_LISTAGEM_TURMAS"), 
				onClick: () => this.exibirListagemTurmas(), 
				habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.TURMA_CADASTRO) 
			},{ 
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_001"), 
				onClick: () => this.exibirListagemSalas(), 
				habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.SALA_CADASTRO) 
			},{ 
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_002"), 
				onClick: () => publicacaoMaterialVH.listarTodosMateriaisPublicados(), 
				habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.TURMA_CADASTRO) 
			}], 
			numAbaAtiva: numAba
		});

		this.exibir();
	}

	async exibirListagemTurmas() {

		if (!document.getElementById("div-listagem-turmas")) {
			const aplicarFiltros = async () => {
				await this.exibirListagemTurmas();
			}
			
			this.limpar(true);

			this.setTitulo(this.getCfg("LABEL_LISTAGEM_TURMAS"));

			this.append("<div class='btn-group' style='display: flex; justify-content: end'>");
			this.addBotao({
				label: "<i class='fa fa-plus-circle'></i> " + this.getCfg("LABEL_TURMA"),
				onClick: () => this.editar(null),
				classe: "btn-success btn-sm"
			});
			this.addBotaoBusca("filtrosTurmas", {classe: "btn-sm"});
			this.append("</div>")

			this.append("<div id='filtrosTurmas' style='display: none; margin-top: 15px'>");
			this.abrirAbaAccordion({ 
				titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_003"),
				aberta: true
			});

			this.addFormulario();

			if (this.getIsAmbienteSegmentado()) {
				await this.addSelect({
					id: "codsEmpresasBusca", 
					dica: this.getMsg("FP_FRONT_CadastroTurmaVH_004"), 
					multiplo: true, 
					classe: "col-xs-12 col-md-6 col-lg-4", 
					onChange: aplicarFiltros,
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarSegmentos",
						numMinimoCaracteres: 0,
					},
				});
			}

			this.addCampoTexto({ 
				dica: this.getMsg("FP_FRONT_CadastroTurmaVH_005"), 
				id: "busca", 
				onEnter: aplicarFiltros, 
				prefixo: "<i class='fa fa-sm fa-search'></i> ", 
			});

			this.addCamposPeriodo({
				idInicio: "inicioPeriodoCadastroBusca",
				idFim: "fimPeriodoCadastroBusca",
				dica: this.getMsg("FP_FRONT_CadastroTurmaVH_119"),
				onChange: aplicarFiltros 
			});

			this.addCamposPeriodo({
				idInicio: "inicioPeriodoAtualizacaoBusca",
				idFim: "fimPeriodoAtualizacaoBusca",
				dica: this.getMsg("FP_FRONT_CadastroTurmaVH_118"),
				onChange: aplicarFiltros 
			});

			if (this.isAdministrador() || this.isSecretaria()) {
				this.addCheckbox({ 
					label: this.getMsg("FP_FRONT_CadastroTurmaVH_006"), 
					id: "isExibirArquivados", 
					onChange: aplicarFiltros 
				});
			}

			this.fecharFormulario();
			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();
			this.append("</div>");

			this.addEspacamentoHorizontal("5px");

			this.append(`
				<div class="row">
					<div id="div-listagem-turmas" class="col-md-12">
					</div>
				</div>
			`)

			this.exibir();
		}

		const filtrosListagemTurmaTO: any = {
			buscaTextual: this.getValor("busca"), 
			codsEmpresasBusca: this.getValor("codsEmpresasBusca"), 
			isExibirArquivados: this.getValor("isExibirArquivados"),
			periodoCadastro: [this.getValor("inicioPeriodoCadastroBusca"), this.getValor("fimPeriodoCadastroBusca")],
			periodoAtualizacao: [this.getValor("inicioPeriodoAtualizacaoBusca"), this.getValor("fimPeriodoAtualizacaoBusca")],
			paginacaoTO: null
		}

		const exibicaoListagemTurmaTO = await this.call("CadastroTurmaFCD/listar", filtrosListagemTurmaTO);

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_117"), prop: "nome", classe: "descricao"});

		if (this.getIsAmbienteSegmentado()) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_007"), prop: "nomeSegmento"});
		}

		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_008"), prop: "identificador"});
		colunas.push({titulo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO"), prop: "numAlunos"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_009"), prop: "inicioVigencia", formato: "DD/MM/YYYY"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_010"), prop: "fimVigencia", formato: "DD/MM/YYYY"});
		colunas.push({titulo: this.getCfg("LABEL_AVALIACAO"), prop: "nomesAvaliacoes"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_011"), prop: "isAmbienteDeEstudosAtivo"});

		this.setIdTarget("div-listagem-turmas");
		$("#div-listagem-turmas").html("");

		await this.addTabela({
			collection: exibicaoListagemTurmaTO.collectionListagemTurmaTO,
			id: 'listagemTurmas',
			propId: "codTurma",
			colunas: colunas,
			onEdicao: this.exibirAbasTurma,
			onEdicaoParam1: 0,
			paginacao: {
				paginacaoTO: exibicaoListagemTurmaTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					filtrosListagemTurmaTO.paginacaoTO = paginacaoTO;
			
					return (await this.call("CadastroTurmaFCD/listar", filtrosListagemTurmaTO)).collectionListagemTurmaTO;
				}
			},
			ordenar: false,
			selecao: true,
			onSelecionarCheckbox: () => {
				const possuiSelecao = this.getCodsTurmasSelecionadas().length;
				if (possuiSelecao) {
					this.show("btn_excluir_turmas");
					this.show("btn_remover_alunos");
					return;
				}
				this.hide("btn_excluir_turmas");
				this.hide("btn_remover_alunos");
			}
		});

		this.addBotao({
			id: "btn_excluir_turmas",
			label: `<i class='fa fa-trash-o'></i> ${this.getMsg("FP_FRONT_CadastroTurmaVH_127")}`,
			classe: "btn btn-sm pull-right btn-danger",
			css: "display: none",
			onClick: async () => {
				const codsTurmas = this.getCodsTurmasSelecionadas();
				if (this.isEmpty(codsTurmas)) return;

				await this.exibirAlerta({
					titulo: `<i class='fa fa-exclamation-triangle'></i> ${this.getMsg("FP_FRONT_CadastroTurmaVH_129")}`,
					msg: this.getMsg("FP_FRONT_CadastroTurmaVH_133"),
					botoes: [
						{
							label: this.getMsg("FP_FRONT_CadastroTurmaVH_134"),
							classe: 'btn-danger',
							onClick: async () => {
								await this.call("CadastroTurmaFCD/excluir", codsTurmas);

								await this.exibirListagemTurmas();
							}
						},
						{
							label: this.getMsg("FP_FRONT_CadastroTurmaVH_132")
						}
					]
				});


			}
		});

		if (this.isAdministrador()) {
			this.addBotao({
				id: "btn_remover_alunos",
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_128"),
				classe: "btn btn-sm pull-right btn-danger",
				css: "display: none",
				onClick: async () => {
					const codsTurmas = this.getCodsTurmasSelecionadas();
					if (this.isEmpty(codsTurmas)) return;

					await this.exibirAlerta({
						titulo: `<i class='fa fa-exclamation-triangle'></i> ${this.getMsg("FP_FRONT_CadastroTurmaVH_129")}`,
						msg: this.getMsg("FP_FRONT_CadastroTurmaVH_130"),
						botoes: [
							{
								label: this.getMsg("FP_FRONT_CadastroTurmaVH_131"),
								classe: 'btn-danger',
								onClick: async () => {
									await this.call("CadastroTurmaFCD/retirarTodosAlunosPorTurmas", codsTurmas);

									await this.exibirListagemTurmas();
								}
							},
							{
								label: this.getMsg("FP_FRONT_CadastroTurmaVH_132")
							}
						]
					});
				}
			});
		}

		this.exibir();
	}

	getCodsTurmasSelecionadas() {
		let codsTurmas = [];
		const $elementoHtml = $(this.getElementoHtml("listagemTurmas"));
		if($elementoHtml.find("td:first-child :checkbox").length > 0) {
			$elementoHtml.find("td:first-child :checkbox:checked").each(function () {
				codsTurmas.push(+$(this).attr("codTurma"));
			});
		}
		return codsTurmas;
	}

	getCodsUsuariosSelecionadosTurma() {
		let codsUsuarios = [];
		const $elementoHtml = $(this.getElementoHtml("listagemUsuariosTurma"));
		if($elementoHtml.find("td:first-child :checkbox").length > 0) {
			$elementoHtml.find("td:first-child :checkbox:checked").each(function () {
				codsUsuarios.push(+$(this).attr("codUsuario"));
			});
		}
		return codsUsuarios;
	}

	async exibirListagemSalas() {

		this.buscaListagemSalasTO = this.buscaListagemSalasTO || {};

		const exibicaoListagemSalasTO = await this.call("CadastroTurmaFCD/listarSalas", this.buscaListagemSalasTO);

		this.limpar(true);

		this.setTitulo(this.getMsg("FP_FRONT_CadastroTurmaVH_012"));

		this.append("<div class='btn-group d-flex justify-end'>");
		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroTurmaVH_018") + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase(), classe: "btn-sm", onClick: () => {
				agendamentoSalaVH.listarAlocacoes();
			}
		});
		this.addBotao({ label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroTurmaVH_019")}`, onClick: () => this.editarSala(), classe: "btn-success btn-sm" });
		this.addBotaoBusca("filtrosSalas", {classe: "btn-sm"});
		this.append("</div>");

		this.append("<div id='filtrosSalas' style='display: none; margin-top: 15px'>")
		this.abrirAbaAccordion({ titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_013"), aberta: true });

		const isExibirSegmentos = exibicaoListagemSalasTO.collectionSegmentosTO.length > 1 || (exibicaoListagemSalasTO.collectionSegmentosTO.length == 1 && exibicaoListagemSalasTO.collectionSegmentosTO[0].filhos != null);

		if (isExibirSegmentos) {
			await this.addSelect({
				collection: exibicaoListagemSalasTO.collectionSegmentosTO,
				id: "codsEmpresasBusca",
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_014"),
				multiplo: true,
				valor: this.buscaListagemSalasTO.codsEmpresasBusca,
				classe: "col-xs-12 col-md-3",
				onChange: async () => await this.filtrarSalas()
			});
		}

		this.addCampoTexto({ label: this.getMsg("FP_FRONT_CadastroTurmaVH_015"), id: "busca", onEnter: this.filtrarSalas, valor: this.buscaListagemSalasTO.buscaTextual, classe: "col-xs-12 col-md-3" });
		this.addCamposPeriodo({
			label: this.getMsg("FP_FRONT_CadastroTurmaVH_016"),
			idInicio: "periodo_inicio",
			idFim: "periodo_fim",
			onChange: async () => await this.filtrarSalas(),
			valorInicio: UtilData.toDDMMYYYY(this.buscaListagemSalasTO.inicioPeriodo),
			valorFim: UtilData.toDDMMYYYY(this.buscaListagemSalasTO.fimPeriodo),
			classe: "col-xs-12 col-md-3",
		});
		this.addCheckbox({ label: this.getMsg("FP_FRONT_CadastroTurmaVH_017"), id: "isExibirArquivadas", onChange: async () => await this.filtrarSalas(), valor: this.buscaListagemSalasTO.isExibirArquivadas, classe: "col-xs-12 col-md-3" });
		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		this.append("</div>")

		this.addEspacamentoHorizontal("5px");

		const colunas: ColunaAddTabela[] = []
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_020"), prop: "nome", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_021"), prop: "nomeSegmento"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_022"), prop: "capacidade"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_023"), prop: "${endereco} ${cidade} ${uf}"});

		await this.addTabela({
			id: "listaListagemSala",
			collection: exibicaoListagemSalasTO.listaListagemSalaTO,
			propId: "codTurma",
			colunas: colunas,
			paginacao: {
				paginacaoTO: exibicaoListagemSalasTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					const filtroTO = {
						paginacaoTO: paginacaoTO,
						...this.buscaListagemSalasTO
					}
					const exibicaoListagemSalasTO = await this.call("CadastroTurmaFCD/listarSalas", filtroTO);
					return exibicaoListagemSalasTO.listaListagemSalaTO;
				}
			},
			onEdicao: async (codTurma) => await this.editarSala(codTurma)
		})

		this.exibir();
	}

	filtrarSalas() {
		this.buscaListagemSalasTO = {
			buscaTextual: this.getValor("busca"),
			inicioPeriodo: this.getValor("periodo_inicio"),
			fimPeriodo: this.getValor("periodo_fim"),
			codsEmpresasBusca: this.getValor("codsEmpresasBusca"),
			isExibirArquivadas: this.getValor("isExibirArquivadas")
		}
		this.exibirListagemSalas();
	}

	async editar(codTurma) {

		const recuperacaoTurmaTO = await this.call("CadastroTurmaFCD/recuperar", codTurma);

		if (recuperacaoTurmaTO.codTurma == null) {
			this.limpar();
			this.setTitulo(this.getCfg("LABEL_LISTAGEM_TURMAS"));
			this.setSubtitulo("Nova");

		} else {
			this.limpar(true);
		}

		if (recuperacaoTurmaTO.isArquivada) {
			this.append(" <span class='label label-default'>" + this.getMsg("MSG_VH_CT_01") + "</span> ");
			this.addEspacamentoHorizontal("20px");
		}

		this.addCampoTexto({ id: "turma_nome", label: "Nome", valor: recuperacaoTurmaTO.nome, classe: "col-md-6", habilitado: !recuperacaoTurmaTO.isCriadoViaIntegracao });

		await this.addSelect({
			collection: recuperacaoTurmaTO.collectionSegmentosTO,
			id: "codEmpresa",
			label: this.getMsg("FP_FRONT_CadastroTurmaVH_024"),
			valor: recuperacaoTurmaTO.codEmpresa,
			obrigatorio: true,
			habilitado: !recuperacaoTurmaTO.isCriadoViaIntegracao,
			visivel: (recuperacaoTurmaTO.collectionSegmentosTO && (recuperacaoTurmaTO.collectionSegmentosTO.length > 1 || (recuperacaoTurmaTO.collectionSegmentosTO.length == 1 && recuperacaoTurmaTO.collectionSegmentosTO[0].filhos != null))),
		});

		this.addCampoTexto({ id: "turma_identificador", label: "Identificador", valor: recuperacaoTurmaTO.identificador, classe: "col-md-3 fp-small-business-hidden", habilitado: !recuperacaoTurmaTO.isCriadoViaIntegracao });

		if (this.hasValue(recuperacaoTurmaTO.collectionListagemResponsaveis)) {
			await this.addSelect({
				id: "codResponsaveis",
				classe: "fp-small-business-hidden",
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_025"),
				multiplo: true,
				collection: recuperacaoTurmaTO.collectionListagemResponsaveis,
				valor: recuperacaoTurmaTO.collectionIdsResponsaveisPelaTurma,
				habilitado: !recuperacaoTurmaTO.isCriadoViaIntegracao
			});
		}

		if (recuperacaoTurmaTO.possuiConteudoDeConcursosVestCompartilhado) {
			this.addCampoTexto({
				id: "cod_sistema_origem",
				visivel: this.isAdministrador(),
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_026"),
				valor: recuperacaoTurmaTO.codigoSistemaOrigem,
				classe: "col-md-3 fp-small-business-hidden",
				habilitado: !recuperacaoTurmaTO.isCriadoViaIntegracao
			});
		}

		this.addEspacamentoHorizontal("20px");

		this.addBotao({
			label: "&laquo; Voltar",
			onClick: () => this.listar(0),
			css: "float: left",
		});

		this.append("<div class='btn-group pull-right'>");

		if (this.isSecretaria() || this.isAnalista()) {
			if (recuperacaoTurmaTO.codTurma != null) {
				this.addBotao({
					label: this.getMsg("FP_FRONT_CadastroTurmaVH_027"),
					onClick: () => this.abrirPublicacaoMaterial(recuperacaoTurmaTO.codTurma),
				});
			}
		}

		if (this.isSecretaria() || this.isAdministrador()) {
			if (recuperacaoTurmaTO.codTurma != null) {
				this.addBotao({
					label: recuperacaoTurmaTO.isArquivada ? this.getMsg("FP_FRONT_CadastroTurmaVH_028") : this.getMsg("FP_FRONT_CadastroTurmaVH_029"),
					onClick: async () => {

						if (recuperacaoTurmaTO.isArquivada || await this.confirmar(this.getMsg("FP_FRONT_CadastroTurmaVH_030"))) {

							await this.call("CadastroTurmaFCD/excluir", [recuperacaoTurmaTO.codTurma]);
							this.listar(0);
						}
					},
					habilitado: !recuperacaoTurmaTO.isCriadoViaIntegracao,
					classe: recuperacaoTurmaTO.isArquivada ? null : "btn btn-danger"
				})
			}
		}

		this.addBotao({
			label: `${this.getMsg("FP_FRONT_CadastroTurmaVH_031")} &raquo;`,
			onClick: () => this.salvar(),
			habilitado: !recuperacaoTurmaTO.isCriadoViaIntegracao,
			classe: "btn-primary"
		});

		this.append("</div>");

		this.addEspacamentoHorizontal("20px");

		if (recuperacaoTurmaTO.codTurma) {
			this.addSubtitulo({
				texto: this.getMsg("FP_FRONT_CadastroTurmaVH_032"),
				htmlDireita: UtilAuth.possuiAcesso(TipoFuncionalidade.TURMA_AUDITORIA) ? `<a class='btn btn-sm btn-default' onclick='loginVH.exibirAcessosDaTurma(${recuperacaoTurmaTO.codTurma})'>${this.getMsg("FP_FRONT_CadastroTurmaVH_037")}</a>` : null
			});

			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroTurmaVH_033"), valor: UtilData.toDDMMYYYYHHMM(recuperacaoTurmaTO.dataCadastro)});

			if (recuperacaoTurmaTO.nomeUsuarioCadastro) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroTurmaVH_034"), valor: recuperacaoTurmaTO.nomeUsuarioCadastro + " (" + recuperacaoTurmaTO.perfilUsuarioCadastro + ")"});
			}

			if (recuperacaoTurmaTO.isCriadoViaIntegracao && recuperacaoTurmaTO.nomeUsuarioCadastro) {
				this.append(`<div class='col-md-12'><p class='text-danger'><strong>${this.getMsg("FP_FRONT_CadastroTurmaVH_038", recuperacaoTurmaTO.nomeUsuarioCadastro)}</strong></p></div>`);
			}

			this.addEspacamentoHorizontal("20px");

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getCfg("LABEL_AVALIACAO"), prop: (avaliacaoTurmaTO) => {
					if (avaliacaoTurmaTO.nomeAvaliacao == avaliacaoTurmaTO.tipoAvaliacao) {
						return avaliacaoTurmaTO.nomeAvaliacao;
					} else {
						return "<span class='label-avaliacao'>" + avaliacaoTurmaTO.tipoAvaliacao + "</span> " + avaliacaoTurmaTO.nomeAvaliacao;
					}
				}, classe: "descricao"
			});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_035"), prop: "inicioPeriodo", formato: "DD/MM/YYYY HH:mm"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_036"), prop: "fimPeriodo", formato: "DD/MM/YYYY HH:mm"});

			await this.addTabela({
				titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_039") + this.getCfg("LABEL_AVALIACAO_PLURAL").toLowerCase(),
				propId: "codAvaliacaoTurma",
				id: "periodoVigenciaAvaliacaoTurma",
				colunas: colunas,
				collection: recuperacaoTurmaTO.collectionAvaliacaoTurmaTO,
				labelNovo: this.getCfg("LABEL_AVALIACAO"),
				onEdicao: async (codAvaliacaoTurma) => {
					await this.onEdicaoAvaliacaoTurma(
						codAvaliacaoTurma,
						recuperacaoTurmaTO.collectionAvaliacaoTurmaTO
					);
				},
				onNovo: async () => {
					await this.onEdicaoAvaliacaoTurma(null, null, recuperacaoTurmaTO.codTurma);
				},
				onExclusao: async (botao, codAvaliacaoTurma) => {
					await this.call("CadastroTurmaFCD/excluirAvaliacaoTurma", codAvaliacaoTurma);
					this.editar(recuperacaoTurmaTO.codTurma);
				},
				isExcluivel: (avaliacaoTurmaTO) => {
					return !avaliacaoTurmaTO.isCriadoViaIntegracao;
				},
			});
		}

		if (this.hasValue(recuperacaoTurmaTO.collectionDisciplinaTurmaTO)) {
			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_045"), prop: "nomeDisciplina", classe: "descricao"});

			await this.addTabela({
				titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_040"),
				propId: "codDisciplina",
				colunas: colunas,
				collection: recuperacaoTurmaTO.collectionDisciplinaTurmaTO,
			});
		}

		if (this.hasValue(recuperacaoTurmaTO.collectionAgendamentoTurmaTO)) {
			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: "", prop: "nomeProva", classe: "descricao"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_042"), prop: "inicioAgendamento", formato: "DD/MM/YYYY"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_043"), prop: "fimAgendamento", formato: "DD/MM/YYYY"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_044"), prop: "numAlunosAgendados"});

			await this.addTabela({
				collection: recuperacaoTurmaTO.collectionAgendamentoTurmaTO,
				titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_041"),
				propId: "codAgendamento",
				colunas: colunas,
				onEdicao: async (codAgendamento, agendamentoTurmaTO) => {
					await cadastroProvaVH.exibirAbasProva(3, agendamentoTurmaTO.codProva, null, null, null, agendamentoTurmaTO.codAgendamento);
				}
			});
		}

		this.exibir();

		this.recuperacaoTurmaTO = recuperacaoTurmaTO;
	}

	async onEdicaoAvaliacaoTurma(codAvaliacaoTurma, collectionAvaliacaoTurmaTO, codTurma?) {

		var avaliacaoTurmaTO: any = {
			codTurma: codTurma
		};

		if (codAvaliacaoTurma) {
			avaliacaoTurmaTO = collectionAvaliacaoTurmaTO.filter(function(avaliacaoTurmaTO) {
				return avaliacaoTurmaTO.codAvaliacaoTurma == codAvaliacaoTurma;
			}).pop();
		}

		var botoes = null;

		if (!avaliacaoTurmaTO.isCriadoViaIntegracao) {
			botoes = [{ label: this.getMsg("FP_FRONT_CadastroTurmaVH_046"), classe: "btn-primary btn-sm", onClick: async (event) => {

				this.verificarObrigatorios(event.target);

				var salvamentoAvaliacaoTurmaTO = {
					codAvaliacaoTurma: avaliacaoTurmaTO.codAvaliacaoTurma,
					codTurma: avaliacaoTurmaTO.codTurma,
					codAvaliacao: this.getValor("av_codAvaliacao"),
					inicioPeriodo: this.getValor("av_inicioPeriodo"),
					fimPeriodo: this.getValor("av_fimPeriodo"),
				}

				if (salvamentoAvaliacaoTurmaTO.inicioPeriodo == null && salvamentoAvaliacaoTurmaTO.fimPeriodo != null) {
					await this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroTurmaVH_047"),
						botoes: [{ label: this.getMsg("MSG_VH_003") }],
					});
					this.focar("av_inicioPeriodo");
					return false;
				}

				if (salvamentoAvaliacaoTurmaTO.inicioPeriodo && salvamentoAvaliacaoTurmaTO.fimPeriodo && salvamentoAvaliacaoTurmaTO.inicioPeriodo >= salvamentoAvaliacaoTurmaTO.fimPeriodo) {
					await this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroTurmaVH_048"),
						botoes: [{ label: this.getMsg("MSG_VH_003") }],
					});
					this.focar("av_fimPeriodo");

					return false;
				}

				await this.call("CadastroTurmaFCD/salvarAvaliacaoTurma", salvamentoAvaliacaoTurmaTO);

				this.editar(salvamentoAvaliacaoTurmaTO.codTurma);
			}}];
		}

		this.addPopup({
			id: "vincular_avaliacao_turma",
			titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_049"),
			botoes: botoes,
		});

		await this.addSelect({
			collection: [{ id: avaliacaoTurmaTO.codAvaliacao, nome: avaliacaoTurmaTO.nomeAvaliacao }],
			id: "av_codAvaliacao",
			label: this.getCfg("LABEL_AVALIACAO"),
			valor: avaliacaoTurmaTO.codAvaliacao,
			obrigatorio: true,
			classe: "col-md-12",
			habilitado: !avaliacaoTurmaTO.isCriadoViaIntegracao,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/buscarAvaliacoes",
				numMinimoCaracteres: 3,
			},
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroTurmaVH_050"),
			id: "av_inicioPeriodo",
			valor: avaliacaoTurmaTO.inicioPeriodo,
			tipo: "DATAHORA",
			classe: "col-sm-6 col-md-4",
			habilitado: !avaliacaoTurmaTO.isCriadoViaIntegracao,
			obrigatorio: true,
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_CadastroTurmaVH_051"),
			id: "av_fimPeriodo",
			valor: avaliacaoTurmaTO.fimPeriodo,
			tipo: "DATAHORA",
			habilitado: !avaliacaoTurmaTO.isCriadoViaIntegracao,
			classe: "col-sm-6 col-md-4",
		});

		if (avaliacaoTurmaTO.isCriadoViaIntegracao) {
			this.append(`<div class='col-md-12'><p class='text-danger'><strong>${this.getMsg("FP_FRONT_CadastroTurmaVH_052", avaliacaoTurmaTO.nomeUsuarioAssociacao)}</strong></p></div>`);
		}

		this.exibirPopups();
	}

	async editarSala(codTurma?: number) {

		if ($("#listagem_agendamentos").length == 0) {
			this.filtroInicio = this.filtroInicio || UtilData.getDate();
		}

		UtilHash.registrarHistorico(this.editarSala, codTurma);

		const recuperacaoSalaTO = await this.call("CadastroTurmaFCD/recuperarSala", codTurma, this.filtroInicio, this.filtroFim);

		this.recuperacaoSalaTO = recuperacaoSalaTO;

		if ($("#listagem_agendamentos").length == 0) {

			if (recuperacaoSalaTO.codTurma == null) {
				this.limpar();
				this.setTitulo(this.getMsg("FP_FRONT_CadastroTurmaVH_053"));
				this.setSubtitulo(this.getMsg("FP_FRONT_CadastroTurmaVH_054"));
			} else {
				this.limpar();
				this.setTitulo(recuperacaoSalaTO.nome);
			}

			this.addCampoTexto({
				id: "turma_nome",
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_055"),
				valor: recuperacaoSalaTO.nome,
				obrigatorio: true,
				classe: "col-md-6",
				habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao
			});

			await this.addSelect({
				collection: recuperacaoSalaTO.collectionSegmentosTO,
				id: "codEmpresa",
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_056"),
				valor: recuperacaoSalaTO.codEmpresa,
				obrigatorio: true,
				classe: "col-md-3",
				habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao,
				visivel: (recuperacaoSalaTO.collectionSegmentosTO && (recuperacaoSalaTO.collectionSegmentosTO.length > 1 || (recuperacaoSalaTO.collectionSegmentosTO.length == 1 && recuperacaoSalaTO.collectionSegmentosTO[0].filhos != null))),
			});

			this.addCampoTexto({
				id: "turma_capacidade",
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_057"),
				obrigatorio: true,
				sufixo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase(),
				valor: recuperacaoSalaTO.capacidade,
				classe: "col-md-3",
				habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao,
				mascara: "9?999"
			});

			this.addCampoTexto({ id: "turma_endereco", label: this.getMsg("FP_FRONT_CadastroTurmaVH_058"), valor: recuperacaoSalaTO.endereco, classe: "col-md-6", habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao });
			this.addCampoTexto({ id: "turma_cidade", label: this.getMsg("FP_FRONT_CadastroTurmaVH_059"), valor: recuperacaoSalaTO.cidade, classe: "col-md-5", habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao });
			await this.addSelect({ collection: this.getCollectionUFs(), id: "turma_uf", label: "UF", valor: recuperacaoSalaTO.uf, classe: "col-md-1", habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao });

			if (recuperacaoSalaTO.isCriadoViaIntegracao) {
				this.addCampoTexto({ id: "turma_codigoSistemaIntegrado", label: this.getMsg("FP_FRONT_CadastroTurmaVH_060"), valor: recuperacaoSalaTO.codigoSistemaOrigem, classe: "col-md-6", habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao });
			}

			this.addEspacamentoHorizontal("20px");

			this.addBotao({
				label: `&laquo; ${this.getMsg("FP_FRONT_CadastroTurmaVH_064")}`,
				onClick: () => this.listar(1),
				css: "float: left",
			});

			this.append("<div class='btn-group pull-right'>");

			if (this.isSecretaria() || this.isAdministrador()) {
				if (recuperacaoSalaTO.codTurma != null) {
					this.addBotao({
						label: recuperacaoSalaTO.isArquivada ? this.getMsg("FP_FRONT_CadastroTurmaVH_061") : this.getMsg("FP_FRONT_CadastroTurmaVH_062"),
						onClick: async () => {

							if (recuperacaoSalaTO.isArquivada || await this.confirmar(this.getMsg("FP_FRONT_CadastroTurmaVH_063"))) {

								await this.call("CadastroTurmaFCD/excluir", [recuperacaoSalaTO.codTurma]);

								this.listar(1);
							}
						},
						classe: recuperacaoSalaTO.isArquivada ? null : "btn btn-danger",
						habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao
					});
				}
			}

			this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_065"),
				onClick: () => {
					this.salvarSala(true);
				},
				habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao
			});

			this.addBotao({
				label: `${this.getMsg("FP_FRONT_CadastroTurmaVH_069")} &raquo;`,
				onClick: () => {
					this.salvarSala();
				},
				classe: "btn-primary",
				habilitado: !recuperacaoSalaTO.isCriadoViaIntegracao
			});

			this.append("</div>");

			this.addEspacamentoHorizontal("20px");

			if (recuperacaoSalaTO.codTurma) {
				this.addSubtitulo({
					texto: this.getMsg("FP_FRONT_CadastroTurmaVH_066"),
					htmlDireita: UtilAuth.possuiAcesso(TipoFuncionalidade.TURMA_AUDITORIA) ? `<a class='btn btn-sm btn-default' onclick='loginVH.exibirAcessosDaTurma(${recuperacaoSalaTO.codTurma})'>${this.getMsg("FP_FRONT_CadastroTurmaVH_070")}</a>` : null
				});

				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroTurmaVH_067"), valor: UtilData.toDDMMYYYYHHMM(recuperacaoSalaTO.dataCadastro)});

				if (recuperacaoSalaTO.nomeUsuarioCadastro) {
					this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroTurmaVH_068"), valor: recuperacaoSalaTO.nomeUsuarioCadastro + " (" + recuperacaoSalaTO.perfilUsuarioCadastro + ")"});
				}

				if (recuperacaoSalaTO.isCriadoViaIntegracao && recuperacaoSalaTO.nomeUsuarioCadastro) {
					this.append(`<div class='col-md-12'><p class='text-danger'><strong>${this.getMsg("FP_FRONT_CadastroTurmaVH_071", recuperacaoSalaTO.nomeUsuarioCadastro)}</strong></p></div>`);
				}

				this.addEspacamentoHorizontal("20px");
			}

			if (recuperacaoSalaTO.codTurma) {

				var htmlFiltros = this.addCamposPeriodo({
					idInicio: "filtroInicio",
					labelInicio: `${this.getMsg("FP_FRONT_CadastroTurmaVH_072")}&nbsp;&nbsp;&nbsp;`,
					idFim: "filtroFim",
					valorInicio: this.filtroInicio,
					valorFim: this.filtroFim,
					retornarHtml: true,
					classe: "col-md-12",
					css: "margin: 0px",
					onChange: () => {
						this.filtroInicio = this.getValor("filtroInicio");
						this.filtroFim = this.getValor("filtroFim");
						this.editarSala(codTurma);
					}
				});

				this.addSubtitulo({ texto: this.getMsg("FP_FRONT_CadastroTurmaVH_073"), htmlDireita: htmlFiltros });

				this.append("<div id='listagem_agendamentos'></div>");
			}

			this.exibir();
		}

		await this.exibirListagemAgendamentos(recuperacaoSalaTO.collectionAgendamentoSalaTurmaTO);
	}

	async salvarSala(isCriarNova = false) {

		if (this.validarCamposObrigatorios("turma_nome", "turma_capacidade")) {

			const salvamentoTurmaTO = {
				codTurma: this.recuperacaoSalaTO.codTurma,
				codEmpresa: this.getValor("codEmpresa"),
				capacidade: this.getValor("turma_capacidade"),
				codigoSistemaOrigem: this.getValor("turma_codigoSistemaIntegrado"),
				endereco: this.getValor("turma_endereco"),
				cidade: this.getValor("turma_cidade"),
				uf: this.getValor("turma_uf"),
				nome: this.getValor("turma_nome"),
				isTurma: false
			}

			await this.call("CadastroTurmaFCD/salvar", salvamentoTurmaTO);

			if (isCriarNova == true) {
				this.editarSala();
			} else {
				this.listar(1);
			}
		}
	}

	async exibirListagemAgendamentos(collectionAgendamentoSalaTurmaTO) {
		$("#listagem_agendamentos").html("");
		this.setIdTarget("listagem_agendamentos");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_074"), prop: "dataInicioAplicacao", formato: "DD/MM/YYYY HH:mm", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_075"), prop: "numAlunos"});
		colunas.push({titulo: "", prop: this.montarAcoesAgendamentoSala});

		await this.addTabela({
			colunas: colunas,
			collection: collectionAgendamentoSalaTurmaTO
		});
		this.exibir();
	}

	montarAcoesAgendamentoSala(agendamentoSalaTurmaTO) {
		if (agendamentoSalaTurmaTO.numAlunos < 1) return null;

		var h = [`<div class='btn-group'><a class='btn btn-default' onclick='agendamentoSalaVH.exibirProvasAgendamentoSala(null, ${agendamentoSalaTurmaTO.idAgendamentoSalaTurma})'>${this.getMsg("FP_FRONT_CadastroTurmaVH_076")} ${this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase()}</a>`];

		h.push(`<div class='btn-group'><a class='btn btn-default' onclick='agendamentoSalaVH.editarAgendamentoSala(${agendamentoSalaTurmaTO.idAgendamentoSala})'>${this.getMsg("FP_FRONT_CadastroTurmaVH_077")}</a>`);

		if (agendamentoSalaTurmaTO.pathZip) {
			if (agendamentoSalaTurmaTO.pathZip.toUpperCase().startsWith("ERRO:")) {
				h.push(`<a class='btn btn-default' title='${agendamentoSalaTurmaTO.pathZip.replace(/'/g, '"')}'> <i class='fa fa-exclamation-circle'></i> ${this.getMsg("FP_FRONT_CadastroTurmaVH_078")}</a>`);
			} else {
				h.push(`<a class='btn btn-default' target='_blank' href='${agendamentoSalaTurmaTO.pathZip}'> <i class='fa fa-download'></i> ${this.getMsg("FP_FRONT_CadastroTurmaVH_079")}</a>`);
			}
		}

		h.push("</div>");

		return h.join("");
	}

	montarLinkCaderno(usuarioProvaEmAgendamentoSalaTO) {

		if (!usuarioProvaEmAgendamentoSalaTO.urlCadernoDocx) return null;

		var h = [`<div class='btn-group'><a class='btn btn-link' target='_blank' href='${usuarioProvaEmAgendamentoSalaTO.urlCadernoDocx}'>DOCX</a>`];

		if (usuarioProvaEmAgendamentoSalaTO.urlCadernoPdf) {
			h.push(`<a class='btn btn-link' target='_blank' href='${usuarioProvaEmAgendamentoSalaTO.urlCadernoPdf}'>PDF</a>`);
		}
		if (usuarioProvaEmAgendamentoSalaTO.urlGabaritoPdf) {
			h.push(`<a class='btn btn-link' target='_blank' href='${usuarioProvaEmAgendamentoSalaTO.urlGabaritoPdf}'>${this.getMsg("FP_FRONT_CadastroTurmaVH_080")}</a>`);
		} else if (usuarioProvaEmAgendamentoSalaTO.urlGabaritoXls) {
			h.push(`<a class='btn btn-link' target='_blank' href='${usuarioProvaEmAgendamentoSalaTO.urlGabaritoXls}'>${this.getMsg("FP_FRONT_CadastroTurmaVH_081")}</a>`);
		}

		h.push("</div>");

		return h.join("");
	}

	removerArquivosGerados(idAgendamentoSala) {
		this.addPopup({
			id: "agendamento_sala_remocao_arquivos",
			titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_082"),
			botoes: [{ label: this.getMsg("FP_FRONT_CadastroTurmaVH_083"), classe: "btn-sm" }, {
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_084"), classe: "btn-sm btn-danger", onClick: async () => {

					await this.call("AplicacaoImpressaFCD/removerArquivosAgendamentoSala", idAgendamentoSala);

					document.location.reload();
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_CadastroTurmaVH_085"));
		this.addTexto(this.getMsg("FP_FRONT_CadastroTurmaVH_086"));
		this.addTexto(this.getMsg("FP_FRONT_CadastroTurmaVH_087"));
		this.exibirPopups();
	}

	abrirPublicacaoMaterial(codTurma) {
		return publicacaoMaterialVH.listarTodosMateriaisPublicados(codTurma);
	}

	async selecionarAlunoDaTurma(collectionListagemAlunoTO, codTurma) {
		this.setHtml("listagemAlunoDaTurma", "");

		this.setIdTarget("listagemAlunoDaTurma");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_088"), prop: "nome"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_089"), prop: "email"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_090"), prop: "login"});

		await this.addTabela({
			collection: collectionListagemAlunoTO,
			id: "listagemUsuariosTurma",
			propId: "codUsuario",
			colunas: colunas,
			onExclusao: async (botao, codUsuario) => {
				await this.retirarAluno(codUsuario, codTurma, async () => {
					await this.addTabela_hideLinha(codUsuario)
					for (let listagemAlunoTO of collectionListagemAlunoTO) {
						if (codUsuario == listagemAlunoTO.codUsuario) {
							collectionListagemAlunoTO = collectionListagemAlunoTO.filter(to => to !== listagemAlunoTO);
							return
						}
					}
				});
			},
			onEdicao: cadastroUsuarioVH.editarUsuario,
			selecao: true,
			onSelecionarCheckbox: () => {
				const possuiSelecao = this.getCodsUsuariosSelecionadosTurma().length;
				if (possuiSelecao) {
					this.show("btn_remover_alunos_turma");
					return;
				}
				this.hide("btn_remover_alunos_turma");
			},
			msgListaVazia: this.getMsg("FP_FRONT_CadastroTurmaVH_091", this.getCfg("LABEL_ALUNO").toLowerCase())
		});

		if (this.isAdministrador()) {
			this.addBotao({
				id: "btn_remover_alunos_turma",
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_128"),
				classe: "btn btn-sm pull-right btn-danger",
				css: "display: none",
				onClick: async () => {
					const codsUsuarios = this.getCodsUsuariosSelecionadosTurma();
					if (this.isEmpty(codsUsuarios)) return;

					await this.exibirAlerta({
						titulo: `<i class='fa fa-exclamation-triangle'></i> ${this.getMsg("FP_FRONT_CadastroTurmaVH_129")}`,
						msg: this.getMsg("FP_FRONT_CadastroTurmaVH_130"),
						botoes: [
							{
								label: this.getMsg("FP_FRONT_CadastroTurmaVH_131"),
								classe: 'btn-danger',
								onClick: async () => {
									await this.call("CadastroTurmaFCD/retirarAluno", codTurma, codsUsuarios);

									const collectionListagemAlunoTO = await this.call("CadastroTurmaFCD/listarAlunosPorTurma", codTurma, this.getValor("buscaAlunos"));

									await this.selecionarAlunoDaTurma(collectionListagemAlunoTO, codTurma);
								}
							},
							{
								label: this.getMsg("FP_FRONT_CadastroTurmaVH_132")
							}
						]
					});
				}
			});
		}

		this.exibir();
	}

	async salvar() {

		if (this.validarCamposObrigatorios("turma_nome")) {

			let salvamentoTurmaTO = {
				codTurma: this.recuperacaoTurmaTO.codTurma,
				codEmpresa: this.getValor("codEmpresa"),
				codigoSistemaOrigem: this.getValor("cod_sistema_origem"),
				identificador: this.getValor("turma_identificador"),
				codResponsaveis: this.getValor("codResponsaveis"),
				nome: this.getValor("turma_nome"),
			}

			salvamentoTurmaTO = await this.call("CadastroTurmaFCD/salvar", salvamentoTurmaTO);

			this.exibirAbasTurma(0, salvamentoTurmaTO.codTurma);
		}
	}

	async retirarAluno(codUsuario, codTurma, callback) {

		const verificacaoPreRetiradaAlunoTO = await this.call("CadastroTurmaFCD/verificarPreRetiraradaAluno", codTurma, codUsuario);

		this.addPopup({
			id: "turma_retirada_aluno",
			titulo: verificacaoPreRetiradaAlunoTO.nomeTurma,
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroTurmaVH_092"), 
				classe: "btn-sm btn-danger", 
				onClick: async () => {

					codUsuario = await this.call("CadastroTurmaFCD/retirarAluno", codTurma, [codUsuario], this.getValor("codsAUsParaManter"));

					await callback();
				}
			}]
		});

		if (verificacaoPreRetiradaAlunoTO.listExibicaoAplicacaoVerificacaoPreRetiradaAlunoTO) {
			this.addTexto(this.getMsg("FP_FRONT_CadastroTurmaVH_098", verificacaoPreRetiradaAlunoTO.nomeUsuario));
			this.addTexto(this.getMsg("FP_FRONT_CadastroTurmaVH_099", this.getCfg("LABEL_ALUNO").toLowerCase()));

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_093"), prop: "nomeProva"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_094"), prop: "tipoAplicacao"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_095"), prop: function(to) {
					if (to.dataInicioAplicacao != null && to.dataFimAplicacao != null) {
						return this.getMsg("FP_FRONT_CadastroTurmaVH_100", UtilData.toDDMMYYYYHHMM(to.dataInicioAplicacao), UtilData.toDDMMYYYYHHMM(to.dataFimAplicacao));
					} else if (to.dataInicioAplicacao != null) {
						return this.getMsg("FP_FRONT_CadastroTurmaVH_101", UtilData.toDDMMYYYYHHMM(to.dataInicioAplicacao));
					} else if (to.dataFimAplicacao != null) {
						return this.getMsg("FP_FRONT_CadastroTurmaVH_102", UtilData.toDDMMYYYYHHMM(to.dataFimAplicacao));
					} else {
						return this.getMsg("FP_FRONT_CadastroTurmaVH_096");
					}
				}
			});

			await this.addTabela({
				id: "codsAUsParaManter",
				propId: "codAgendamentoUsuario",
				collection: verificacaoPreRetiradaAlunoTO.listExibicaoAplicacaoVerificacaoPreRetiradaAlunoTO,
				selecao: true,
				colunas: colunas
			})
		} else {
			this.addTexto(this.getMsg("FP_FRONT_CadastroTurmaVH_097", verificacaoPreRetiradaAlunoTO.nomeUsuario));
		}

		this.exibirPopups();
	}

	async exibirAbasTurma(...args) {

		const numAba = args[0];
		const codTurma = args[1];

		if (args.length == 1 || codTurma == null) {
			this.exibirAbasTurma(0, args[0]);
			return;
		}

		UtilHash.registrarHistorico(this.exibirAbasTurma, numAba, codTurma);

		this.limpar();

		const abasHabilitadasEdicaoTurmaTO = await this.call("CadastroTurmaFCD/recuperarAbasHabilitadas", codTurma);

		this.setTitulo(abasHabilitadasEdicaoTurmaTO.nomeTurma);
		var abas = [];

		abas.push({
			label: this.getMsg("FP_FRONT_CadastroTurmaVH_103"), onClick: () => {
				this.editar(codTurma);
			}
		});

		abas.push({
			label: this.getCfg("LABEL_MENU_CADASTRO_ALUNO"), 
			onClick: async () => {

				const collectionListagemAlunoTO = await this.call("CadastroTurmaFCD/listarAlunosPorTurma", codTurma, null);

				this.limpar(true);

				this.addCampoTexto({
					dica: this.getMsg("FP_FRONT_CadastroTurmaVH_104"),
					id: "buscaAlunos",
					onEnter: async () => {

						const collectionListagemAlunoTO = await this.call("CadastroTurmaFCD/listarAlunosPorTurma", codTurma, this.getValor("buscaAlunos"));

						await this.selecionarAlunoDaTurma(collectionListagemAlunoTO, codTurma);
					},
				});

				this.addBotao({
					label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroTurmaVH_106", this.getCfg("LABEL_ALUNO").toLowerCase())}`,
					onClick: async () => {
						await this.abrirPopupInclusaoAlunoTurma(codTurma);
					},
					css: "float: right",
					classe: "btn-sm"
				});

				this.append("<div id='listagemAlunoDaTurma' style='float:left; width: 100%;'> </div>");
				this.exibir();
				await this.selecionarAlunoDaTurma(collectionListagemAlunoTO, codTurma);
			}
		});

		this.addAbas({ abas, numAbaAtiva: numAba });

		this.exibir();
	}

	async abrirPopupInclusaoAlunoTurma(codTurma, buscaInicial?){
		this.addPopup({
			id: "turma_inclusao_aluno",
			titulo: `${this.getMsg("FP_FRONT_CadastroTurmaVH_107", this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase())}`,
			botoes: [{ label: "OK", classe: "btn-sm" }]
		})

		this.addCampoTexto({
			dica: this.getMsg("FP_FRONT_CadastroTurmaVH_105"),
			valor: buscaInicial,
			id: "buscarAlunoTurma",
			onEnter: async () => {
				await this.buscarAlunosPorTurma(codTurma);
			},
		});
		this.append(`<div id="listagemAlunoSelecao" class="col-md-12"> </div>`);

		this.exibirPopups();

		if (buscaInicial) {
			await this.buscarAlunosPorTurma(codTurma, buscaInicial);
		}
	}

	async buscarAlunosPorTurma(codTurma, buscaInicial?) {

		const buscaDeAlunos = this.getValor("buscarAlunoTurma") || buscaInicial;

		if (!buscaDeAlunos) {
			await this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_CadastroTurmaVH_112"),
				botoes: [{ label: this.getMsg("MSG_VH_003") }],
			});
			this.focar("buscarAlunoTurma");
			return
		}

		const collection = await this.call("CadastroTurmaFCD/listarAlunoPorBusca", buscaDeAlunos, codTurma);

		this.setHtml("listagemAlunoSelecao", "");
		this.setIdTarget("listagemAlunoSelecao");

		await this.addTabela({
			collection: collection,
			propId: "codUsuario",
			colunas: [{
				titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_108"),
				prop: "nome",
			}, {
				titulo: "Segmento",
				prop: "nomeSegmento",
				regraExibicao: () => this.getIsAmbienteSegmentado()
			}, {
				titulo: "",
				prop: async (to) => {
					console.log(to);
					return this.addBotao({
						label: `<i class="fa fa-plus-circle"></i>`,
						dica: "Adicionar",
						classe: "btn-sm",
						onClick: async () => {
							this.mostrarMsgAjax(this.getMsg("FP_FRONT_CadastroTurmaVH_110", this.getCfg("LABEL_ALUNO")), 4);

							const propagar = await this.validarPropagacaoDeUsuariosTurma(to.nome, codTurma);

							if (propagar) {
								const valorBuscaTextual = this.getValor("buscarAlunoTurma");
								this.fecharPopup("turma_inclusao_aluno");
								setTimeout(async () => {
									await this.abrirPopupSelecaoAplicacoesTurma(codTurma, to.codUsuario, () => {
										this.exibirAbasTurma(1, codTurma);
										setTimeout(() => this.abrirPopupInclusaoAlunoTurma(codTurma, valorBuscaTextual), 500);
									});
								}, 500);
								return;
							}

							const inclusaoAlunoTurmaTO = {
								codUsuario: to.codUsuario, 
								codTurma, 
								propagarInclusaoParaAplicacoes: false
							};

							await this.call("CadastroTurmaFCD/incluirAlunos", inclusaoAlunoTurmaTO);

							$('#listagemAlunoSelecao #listagem_linha_' + to.codUsuario).fadeOut(1000);

							this.exibirAbasTurma(1, codTurma)
						},
						retornarHtml: true
					});
				}
			}],
			msgListaVazia: this.getMsg("FP_FRONT_CadastroTurmaVH_111", this.getCfg("LABEL_ALUNO").toLowerCase())
		});

		this.exibir();
	}

	async validarPropagacaoDeUsuariosTurma(nomeUsuario, codTurma) {
		const filtrosListagemTurmaTO = {
			codTurma: codTurma,
			verificarSeTurmaPossuiAplicacao: true,
			paginacaoTO: {
				numItensPorPagina: 1,
			}
		}

		const agendamentosPorTurmaTO = await this.call("CadastroTurmaFCD/buscarAgendamentosVigentesOuFuturosPorTurma", filtrosListagemTurmaTO);

		let exibirPopup = false;

		if (agendamentosPorTurmaTO.paginacaoTO.numTotalItens > 0) {
			await this.exibirAlerta({
				msg: this.getMsg('FP_FRONT_CadastroTurmaVH_120', this.getCfg('LABEL_ALUNO').toLowerCase(), nomeUsuario),
				botoes: [{
					label: this.getMsg("MSG_VH_005"),
				}, {
					label: this.getMsg("MSG_VH_004"),
					classe: "btn-primary",
					onClick: async () => {
						exibirPopup = true;
					},
				}]
			});
		}

		return exibirPopup;
	}

	async abrirPopupSelecaoAplicacoesTurma(codTurma, codUsuario, callback){
		localStorage.removeItem("codsAgendamentosNaoPropagar");

		this.addPopup({
			width: "90%",
			// height: "500px",
			id: "turma_selecao",
			titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_121"),
			botoes: [
				{label: this.getMsg("FP_FRONT_CadastroTurmaVH_132"), onClick: async () => {callback(false)}},
				{label: this.getMsg("FP_FRONT_CadastroTurmaVH_122", this.getCfg("LABEL_ALUNO").toLowerCase()),  onClick: async () => {
					await this.salvarInclusaoAlunoAgendamento(codTurma, codUsuario, "APENAS_TURMA");
					callback(false);
				}},
				{id: "btnInclusaoAplicacoesSelecionadas", label: this.getMsg("FP_FRONT_CadastroTurmaVH_123", this.getCfg("LABEL_ALUNO").toLowerCase()), onClick: async () => {
					await this.salvarInclusaoAlunoAgendamento(codTurma, codUsuario, "APLICACOES_SELECIONADAS");
					callback(true);
				}},
				{label: this.getMsg("FP_FRONT_CadastroTurmaVH_124", this.getCfg("LABEL_ALUNO").toLowerCase()), classe: "btn-primary", onClick: async () => {
					await this.salvarInclusaoAlunoAgendamento(codTurma, codUsuario, "TODAS_APLICACOES")
					callback(true);
				}},
			]
		});

		this.addCampoTexto({
			dica: this.getMsg("MSG_VH_CU_120"),
			css: "width: 100%; float: left; margin-top: 18px;",
			id: "buscarAgendamentos",
			onEnter: async () => {
				const filtrosListagemTurmaTO = {
					codTurma: codTurma,
					buscaTextual: this.getValor("buscarAgendamentos"),
				}
				const agendamentosPorTurmaTO = await this.call("CadastroTurmaFCD/buscarAgendamentosVigentesOuFuturosPorTurma", filtrosListagemTurmaTO);
				this.setHtml("listagemAplicacoesPorTurma", "");
				this.setIdTarget("listagemAplicacoesPorTurma");
				await this.adicionarTabelaAgendamentosPorTurma(agendamentosPorTurmaTO, codTurma);
				this.exibir();
				this.setIdTarget(null)
			}
		});

		const filtrosListagemTurmaTO = {
			codTurma: codTurma,
		}
		const agendamentosPorTurmaTO = await this.call("CadastroTurmaFCD/buscarAgendamentosVigentesOuFuturosPorTurma", filtrosListagemTurmaTO);

		const numTotalItens = agendamentosPorTurmaTO.paginacaoTO.numTotalItens;
		this.append(`
			<div id='contadorAplicacoesSelecionadas' style='margin-left: 15px' num-total-itens="${numTotalItens}"> 
				<strong>${this.getMsg("FP_FRONT_CadastroTurmaVH_125")}</strong><span>${numTotalItens}</span>
			</div>
		`);

		this.append("<div id='listagemAplicacoesPorTurma'>");
		await this.adicionarTabelaAgendamentosPorTurma(agendamentosPorTurmaTO, codTurma);
		this.append("</div>");

		this.addEspacamentoHorizontal("10px");

		this.exibirPopups();
	}

	async adicionarTabelaAgendamentosPorTurma(agendamentosPorTurmaTO, codTurma) {
		await this.addTabela({
			id: "listagemAgendamentosPorTurma",
			collection: agendamentosPorTurmaTO.collectionAgendamentosTO,
			colunas: [
				{titulo: "", prop: "<small>#${codAgendamento}</small>"},
				{titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_093"), prop: "nomeProva"},
				{titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_094"), prop:  (to) =>  TipoAplicacao[to.tipoAplicacao] ? TipoAplicacao[to.tipoAplicacao].idMsg : ''},
				{titulo: this.getMsg("FP_FRONT_CadastroTurmaVH_095"), prop: (to) => {
						if (to.dataInicioAplicacao != null && to.dataFimAplicacao != null) {
							return this.getMsg("FP_FRONT_CadastroTurmaVH_100", UtilData.toDDMMYYYYHHMM(to.dataInicioAplicacao), UtilData.toDDMMYYYYHHMM(to.dataFimAplicacao));
						} else if (to.dataInicioAplicacao != null) {
							return this.getMsg("FP_FRONT_CadastroTurmaVH_101", UtilData.toDDMMYYYYHHMM(to.dataInicioAplicacao));
						} else if (to.dataFimAplicacao != null) {
							return this.getMsg("FP_FRONT_CadastroTurmaVH_102", UtilData.toDDMMYYYYHHMM(to.dataFimAplicacao));
						} else {
							return this.getMsg("FP_FRONT_CadastroTurmaVH_096");
						}
					}
				}
			],
			paginacao: {
				paginacaoTO: agendamentosPorTurmaTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					const filtrosListagemTurmaTO = {
						codTurma: codTurma,
						buscaTextual: this.getValor("buscarAgendamentos"),
						paginacaoTO
					}
					const agendamentosPorTurmaTO = await this.call("CadastroTurmaFCD/buscarAgendamentosVigentesOuFuturosPorTurma", filtrosListagemTurmaTO);

					return agendamentosPorTurmaTO.collectionAgendamentosTO;
				},
			},
			selecao: true,
			desabilitarDownload: true,
			propId: "codAgendamento",
			selecionarCheckbox: (to) => {
				const codsAgendamentosNaoPropagar = JSON.parse(localStorage.getItem('codsAgendamentosNaoPropagar')) || [];
				return !codsAgendamentosNaoPropagar.includes(to.codAgendamento);
			},
			onSelecionarCheckbox: ($checkbox, selecionado) => {
				let codsAgendamentosNaoPropagar = JSON.parse(localStorage.getItem('codsAgendamentosNaoPropagar')) || [];
				const codAgendamento = $checkbox.closest('tr').attr('identificador');

				if (selecionado) {
					codsAgendamentosNaoPropagar = codsAgendamentosNaoPropagar.filter((codAgendamentoSelecionado) => codAgendamentoSelecionado != codAgendamento);
				} else {
					if (!codsAgendamentosNaoPropagar.includes(codAgendamento)) {
						codsAgendamentosNaoPropagar.push(codAgendamento);
					}
				}

				const $contatoAplicacoesSelecionadas = $('#contadorAplicacoesSelecionadas');

				const numTotalItens = $contatoAplicacoesSelecionadas.attr('num-total-itens');

				const numItensSelecionados = numTotalItens - codsAgendamentosNaoPropagar.length;

				$contatoAplicacoesSelecionadas.find('span').html(numItensSelecionados);

				if (numItensSelecionados > 0) {
					$('#btnInclusaoAplicacoesSelecionadas').attr('disabled', false);
				} else {
					$('#btnInclusaoAplicacoesSelecionadas').attr('disabled', true);
				}

				localStorage.setItem('codsAgendamentosNaoPropagar', JSON.stringify(codsAgendamentosNaoPropagar));
			}
		})
	}

	async salvarInclusaoAlunoAgendamento(codTurma, codUsuario, tipo: string) {
		const inclusaoAlunoTurmaAgendamento: any = {
			codTurma, codUsuario, propagarInclusaoParaAplicacoes: true,
		};
		if (tipo == 'APLICACOES_SELECIONADAS') {
			inclusaoAlunoTurmaAgendamento.codsAgendamentosNaoPropagar = JSON.parse(localStorage.getItem('codsAgendamentosNaoPropagar'));
		} else if (tipo == 'APENAS_TURMA') {
			inclusaoAlunoTurmaAgendamento.propagarInclusaoParaAplicacoes = false;
		}

		await this.call("CadastroTurmaFCD/incluirAlunos", inclusaoAlunoTurmaAgendamento);
	}
}

const cadastroTurmaVH = new CadastroTurmaVH();

