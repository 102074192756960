class NavegacaoPFVH extends PFVH {

	timerScroll: any;
	
	constructor() {
		super(NavegacaoPFVH.name);
		this.timerScroll = null;
	}

	monitorarScroll(codProvaFeita: number) {
		setTimeout(() => {
			let seletor: any = window;
			const idContainerFullScreen = "#" + UtilWindow.getIdContainerFullScreen(); 

			if ($(idContainerFullScreen).length > 0) {
				seletor = idContainerFullScreen;
			}

			$(seletor).on("scroll", () => this.verificarSePrecisaMostrarBotaoDeConclusaoDeProva(codProvaFeita));
		}, 3000);
	}

	verificarSePrecisaMostrarBotaoDeConclusaoDeProva(codProvaFeita: number) {

		if (!this.isEmAplicacaoDeProva(codProvaFeita)) return;

		if (this.timerScroll) {
			window.clearTimeout(this.timerScroll);
		}

		this.timerScroll = window.setTimeout(() => {

			const scrollPercentage = UtilWindow.getScrollPercentage();
			
			if (scrollPercentage >= 95) {
				$("#botao_avancar").hide();
				$("#botao_concluir").show();

			} else {
				let questaoAtual = this.buscarQuestaoAtualPaginaUnica();
				questaoAtual = questaoAtual ? $(questaoAtual): null;
				if (questaoAtual) {
					this.atualizarInfosNavegacao(questaoAtual);
				}
			}
		}, 200);
	}

	buscarQuestaoAtualPaginaUnica() {

		const arrayQuestoes: HTMLElement[] = Array.from(document.querySelectorAll("[questao]"));
		const questaoAtual = arrayQuestoes.find(
			questao => UtilWindow.isInViewport(questao)
		);

		return questaoAtual;
	}

	irParaQuestaoPaginaUnica(irPara) {
		let questaoAtual = this.buscarQuestaoAtualPaginaUnica();

		if (questaoAtual) {

			questaoAtual = $(questaoAtual);

			let irParaQuestao = null;

			if (irPara === 'anterior') {
				irParaQuestao = this.getQuestaoAnterior(questaoAtual);
				
			} else if (irPara === 'proxima') {
				irParaQuestao = this.getQuestaoPosterior(questaoAtual);
			}

			UtilWindow.scrollTo(irParaQuestao);

			this.atualizarInfosNavegacao(irParaQuestao);
		}
	}

	async navegarParaQuestao(refQuestao?) {
		if (!(await this.validarQuestaoEmBranco())) {
			return;
		}
		await this.irParaQuestao(refQuestao);
	}

	async validarQuestaoEmBranco(): Promise<boolean> {
		
		let $questaoAtual = $("[questao]:visible");
		const isDeveBloquearJaVisualizadas = $('#divAplicacaoProva').data('fp-deve-bloquear-ja-visualizadas');
		const isImpedirFinalizarComEmBranco = $('#divAplicacaoProva').data('fp-impedir-respostas-em-branco-antes-finalizacao');

		if (isImpedirFinalizarComEmBranco || !isDeveBloquearJaVisualizadas || !$questaoAtual?.length) {
			return true;
		}

		let questaoEmBrancoValida = false;

		const isTempoQuestaoEsgotado = cronoPFVH.isTempoEsgotado($questaoAtual);

		await new Promise<void>((resolve) => {
			$questaoAtual.find("[numerador]").each((i, num) => {
				const numerador = $(num).attr("numerador");
				if ($("[painel-respostas] [numerador='" + numerador + "']").is(".item-em-branco") && !isTempoQuestaoEsgotado) {
					this.exibirAlerta({
						titulo: `<i class='fa fa-exclamation-circle'></i> ${this.getMsg('MSG_VH_AP_44')}`,
						msg: this.getMsg("MSG_VH_APR_211"),
						botoes: [{
							label:  this.getMsg("MSG_VH_APR_213"),
							onClick: async () => {
								questaoEmBrancoValida = false;
								resolve();
							}
						},{
							label: this.getMsg("MSG_VH_APR_212"),
							classe: "btn-primary",
							onClick: async () => {
								questaoEmBrancoValida = true;
								resolve();
							}
						}]
					});
				} else {
					questaoEmBrancoValida = true;
					resolve();
				}
			});
		});

		return questaoEmBrancoValida;
	}


	async irParaQuestao(refQuestao?, codRespostaQuestaoParaInicializacao?, limparQuestaoOffline?): Promise<any> {

		this.desabilitarBotoes();

		try {

			const isDeveBloquearJaVisualizadas = $('#divAplicacaoProva').data('fp-deve-bloquear-ja-visualizadas');
			const isImpedirFinalizarComEmBranco = $('#divAplicacaoProva').data('fp-impedir-respostas-em-branco-antes-finalizacao');
			let $questaoAtual = $("[questao]:visible");
			
			if ($questaoAtual.length === 0) {
				$questaoAtual = null;
			}
			
			if (this.hasValue(refQuestao) && isDeveBloquearJaVisualizadas && isImpedirFinalizarComEmBranco && $questaoAtual) {
				
				const isTempoQuestaoEsgotado = cronoPFVH.isTempoEsgotado($questaoAtual);
				let isIdaCancelada = false;

				$questaoAtual.find("[numerador]").each((i, num) => {
					
					const numerador = $(num).attr("numerador");
					
					if ($("[painel-respostas] [numerador='" + numerador + "']").is(".item-em-branco") && !isTempoQuestaoEsgotado) {
						this.exibirAlerta({ msg: this.getMsg("MSG_VH_APR_192") });
						isIdaCancelada = true;
					}
				});

				if (isIdaCancelada) {
					return null;
				}
			}

			let $questaoDestino = null;
			
			if (this.isEmpty(refQuestao)) {

				$questaoAtual = null;
				
				$("[questao]").hide(0).trigger("fp-questao-hidden");
				
				if (codRespostaQuestaoParaInicializacao) {
					$questaoDestino = $("[questao][cod-resposta-questao='" + codRespostaQuestaoParaInicializacao + "']");
					if ($questaoDestino.length == 0 || this.isQuestaoComAcessoBloqueado($questaoDestino)) {
						$questaoDestino = null;
					}
				}
				
				if ($questaoDestino == null) {
					$questaoDestino = this.getQuestaoPosterior();
				}

				if ($questaoDestino == null) {
					let codMsg = "MSG_VH_APR_152";
					
					if (isDeveBloquearJaVisualizadas) {
						codMsg = "MSG_VH_APR_184";
					}
					
					$("#divAplicacaoProva").append(this.addTextoErro({texto: this.getMsg(codMsg), retornarHtml: true}));
					
					this.atualizarInfosNavegacao();
					return null;
				}

			} else if (refQuestao == "posterior") {
				$questaoDestino = this.getQuestaoPosterior($questaoAtual);
	
			} else if (refQuestao == "anterior") {
				$questaoDestino = this.getQuestaoAnterior($questaoAtual);
				
			} else if (!isNaN(refQuestao)) {
				$questaoDestino = $("[numerador='" + refQuestao + "']").closest("[questao]");
				
				if ($questaoDestino.length == 0) {
					$questaoDestino = null;
				}
				
				if (!$questaoDestino || this.isQuestaoComAcessoBloqueado($questaoDestino)) {

					let idMsg = "MSG_VH_APR_164";
					
					if ($questaoDestino && cronoPFVH.isTempoEsgotado($questaoDestino)) {
						idMsg = "MSG_VH_APR_163";
					} else if (isDeveBloquearJaVisualizadas) {
						idMsg = "MSG_VH_APR_183";
					} else {
						const codSecao = $questaoDestino.attr("cod-secao");
						const $secao = $(`div[secao][cod-secao="${codSecao}"]`);
						if ($secao.length && cronoPFVH.isTempoEsgotadoSecao($secao)) {
							idMsg = "O tempo disponível para responder as questões desta seção se esgotou.";
						}
					}

					this.exibirAlerta({ msg: this.getMsg(idMsg) });
					return null;
				}
			}
			
			if ($questaoDestino == null) {
				return null;
			}

			await this.fadeOut($questaoAtual, 150);
			await this.fadeIn($questaoDestino, 150);
			
			if ($questaoAtual) {
				$questaoAtual.trigger("fp-questao-hidden");
			}

			UtilWindow.scrollTo("#divAplicacaoProva");
			this.atualizarInfosNavegacao();
			$('#divAplicacaoProva').removeData("cod-resposta-questao-visualizada-por-ultimo");
			cronoPFVH.contabilizarConsumoQuestao($questaoDestino, 0);
			await salvamentoPFVH.salvarProvaFeita();

			$questaoDestino.trigger("fp-questao-shown", $questaoDestino);

			this.stopAudioAndVideos();

			this.setarLoggerNavegacaoQuestao($questaoAtual, $questaoDestino);

			if ($questaoDestino.attr('tipo') == 'DC') {
				setTimeout(async () => {
					await exibicaoQuestaoVH.testarContagemLinhasDiscursiva();
				}, 500);
			}

			return $questaoDestino;

		} finally {
			this.habilitarBotoes();
		}
	}
	
	montarPainelDeRespostas(aplicacaoProvaFeitaTO) {

		var html = [];

		html.push(`
			<div class="panel-heading">
				${this.getMsg("MSG_VH_APR_161")}
			</div>
			<div class="panel-body">
				<p msg-painel-respostas tabindex="0" aria-label="${this.getMsg("MSG_VH_APR_162")}">
					${this.getMsg("MSG_VH_APR_162")}
				</p>
				<div painel-respostas id="painel_respostas_conteudo">
		`);

		for (const aplicacaoSecaoFeitaTO of aplicacaoProvaFeitaTO.listaAplicacaoSecaoFeitaTO) {

			if (this.isEmpty(aplicacaoSecaoFeitaTO.listaRespostaParaPainelTO)) continue;

			const secao = $(`div[secao][cod-secao="${aplicacaoSecaoFeitaTO.codSecao}"]`);
			const isTempoSecaoEsgotado = cronoPFVH.isTempoEsgotadoSecao(secao);
			
			html.push(`
					<div class="row">
						<div class="titulo-nome-secao-mapa-respostas col-md-12 col-xs-12" tabindex="0" aria-label="${aplicacaoSecaoFeitaTO.nomeSecao}" id="titulo_painel_respostas_secao_${aplicacaoSecaoFeitaTO.codSecao}">
							${isTempoSecaoEsgotado ? `<i class='fa fa-clock-o' title='${this.getMsg("FP_FRONT_CronoPFVH_001")}'></i>` : ''} ${aplicacaoSecaoFeitaTO.nomeSecao}
						</div>
					</div>
					<div class='row'>
						<div class='col-md-12 col-xs-12'>
			`);
			
			let contadorQuestoesNaSecao = 0;

			for (const respostaParaPainelTO of aplicacaoSecaoFeitaTO.listaRespostaParaPainelTO) {
				
				contadorQuestoesNaSecao++;

				const numerador = respostaParaPainelTO.numerador;
				const resposta = respostaParaPainelTO.resposta;

				html.push(this.montarItemDoPainelDeRespostas(
					numerador, 
					resposta, 
					respostaParaPainelTO.possuiUploadResposta, 
					respostaParaPainelTO.isAnulada, 
					respostaParaPainelTO.isTempoEsgotado || isTempoSecaoEsgotado
				));

				if (this.hasValue(resposta)) {
					// marcando resposta

					const divAplicacao = $("[questao][numerador='" + numerador + "']");

					if (divAplicacao.length == 1) {
						// ME ou EX
						const letra = divAplicacao.find("input[letra='" + resposta + "']");

						if (letra.length == 1) {
							letra.attr('checked', true);
						} else {
							$("[questao][numerador='" + numerador + "'] input[numerador='" + numerador + "']").val(resposta);
						}
					} else {
						var val = (resposta == "C" ? "C" : "E");
						$("input[numerador='" + numerador + "'][value='" + val + "']").attr('checked', true);
					}

				}
			}

			html.push(`
						</div>
					</div>
			`);
		}

		html.push(`
					<p><br><br></p>
				</div>
			</div>
		`);

		return html.join("");
	}

	montarItemDoPainelDeRespostas(numerador: number, resposta: string, possuiUploadResposta: boolean, isAnulada: boolean, isTempoEsgotado: boolean) {

		const html = [];

		html.push("<button tabindex='0' numerador='" + numerador + "' type='button' class='btn btn-default btn-numerador-questao");

		if (isAnulada === true) {
			html.push(` item-anulado' aria-label="${this.getMsg("FP_FRONT_NavegacaoPFVH_005", numerador)} ${this.getMsg("FP_FRONT_NavegacaoPFVH_009")}"`);
		} else if (isTempoEsgotado === true) {
			html.push(` item-tempo-esgotado' aria-label="${this.getMsg("FP_FRONT_NavegacaoPFVH_006", numerador)} ${this.getMsg("FP_FRONT_NavegacaoPFVH_009")}"`);
		} else if (this.isEmpty(resposta) && !possuiUploadResposta) {
			html.push(` item-em-branco' aria-label="${this.getMsg("FP_FRONT_NavegacaoPFVH_007", numerador)} ${this.getMsg("FP_FRONT_NavegacaoPFVH_009")}"`);
		} else if(this.hasValue(resposta) || possuiUploadResposta) {
			html.push(`' aria-label="${this.getMsg("FP_FRONT_NavegacaoPFVH_008", numerador)} ${this.getMsg("FP_FRONT_NavegacaoPFVH_009")}"`);
		} else {
			html.push("'");
		}

		html.push(` onclick='navegacaoPFVH.navegarParaQuestao(${numerador})'`);
		html.push(">");
		
		if (isTempoEsgotado === true) {
			html.push(`<i class='fa fa-clock-o' title='${this.getMsg("FP_FRONT_NavegacaoPFVH_001")}'></i> `);
		}
		
		html.push("<strong>" + numerador + "</strong> ");

		if (isAnulada === true) {
			html.push(this.getMsg("FP_FRONT_NavegacaoPFVH_002"));

		} else if (this.hasValue(resposta)) {
			html.push(resposta.length > 5 ? this.getMsg("FP_FRONT_NavegacaoPFVH_003") : resposta);

		} else if (possuiUploadResposta) {
			html.push(this.getMsg("FP_FRONT_NavegacaoPFVH_003"));
		}

		html.push("</button>");

		return html.join("");
	}

	atualizarItemPainelDeRespostas(numeradorResposta, respostaDoItem, salvamentoRespostaTO, possuiUploadResposta: boolean, isAnulada: boolean, isTempoEsgotado: boolean) {

		const htmlPainelResposta = this.montarItemDoPainelDeRespostas(numeradorResposta, respostaDoItem, possuiUploadResposta, isAnulada, isTempoEsgotado);

		$("[painel-respostas] [numerador='" + numeradorResposta + "']").replaceWith(htmlPainelResposta);

		if (salvamentoRespostaTO) {
			$("[painel-respostas] [numerador='" + numeradorResposta + "']").data("salvamentoRespostaTO", salvamentoRespostaTO);
		}

		AmaisVH.dispararAjusteAcessibilidade();
	}

	isQuestaoComAcessoBloqueado(questao) {
		
		let $aplicacaoProva = $("#divAplicacaoProva");
		let $questao = $(questao);
		
		let deveBloquearJaVisualizadas = $aplicacaoProva.data("fp-deve-bloquear-ja-visualizadas");
		let deveBloquearJaRespondidas = $aplicacaoProva.data("fp-deve-bloquear-ja-respondidas");
		let codRespostaQuestaoVisualizadaPorUltimo = $aplicacaoProva.data("cod-resposta-questao-visualizada-por-ultimo");
		let codRespostaQuestao = $questao.attr("cod-resposta-questao");
		
		if (deveBloquearJaVisualizadas && $questao.data("fp-tempo-utilizado") > 0 && codRespostaQuestao != codRespostaQuestaoVisualizadaPorUltimo) {
			return true;
		}

		if (deveBloquearJaRespondidas) {
			let isRespondida = false;

			if ($questao.is("[tipo='CE']")) {

				isRespondida = true;

				$questao.find("[item]").each(function() {
					if (!$(this).data("fp-is-respondido")) {
						isRespondida = false;
					}
				})

			} else {
				isRespondida = $questao.data("fp-is-respondida");
			}

			if (isRespondida) {
				return true;
			}
		}

		if (cronoPFVH.isTempoEsgotado($questao)) {
			return true;
		}

		const codSecao = $questao.attr("cod-secao");
		const $secao = $(`div[secao][cod-secao="${codSecao}"]`);
		if ($secao.length) {
			if (cronoPFVH.isTempoEsgotadoSecao($secao)) {
				return true;
			}
		}

		return false;
	}
	
	getQuestaoPosterior($questao?) {

		let $proxima = (this.isEmpty($questao) ? $("[questao]:first") : $questao.next());

		while ($proxima.is("[questao]") && this.isQuestaoComAcessoBloqueado($proxima)) {
			$proxima = $proxima.next();
		}

		if ($proxima.is("[questao]")) {
			return $proxima;
		} else {
			return null;
		}
	}

	getQuestaoAnterior(questao) {

		var anterior = questao.prev();

		while (anterior.is("[questao]") && this.isQuestaoComAcessoBloqueado(anterior)) {
			anterior = anterior.prev();
		}

		if (anterior.is("[questao]")) {
			return anterior;
		} else {
			return null;
		}
	}

	atualizarInfosNavegacao(questaoAtual?) {
		questaoAtual = questaoAtual || $("[questao]:visible");

		if (this.getQuestaoAnterior(questaoAtual) == null) {
			$("#botao_anterior").hide();
		} else {
			$("#botao_anterior").show();
		}

		if (this.getQuestaoPosterior(questaoAtual) == null) {
			$("#botao_avancar").hide();
			$("#botao_concluir").show();
		} else {
			$("#botao_avancar").show();
			$("#botao_concluir").hide();
		}
	}

	async handleClickBotaoAvancar(isOnClick?) {

		await this.forcarBlurRespostaDiscursiva();

		if (isOnClick) {

			const btnAvancar = document.getElementById('botao_avancar');
			const modalLoading = $('.modal-loading');
			
			if (modalLoading.length || btnAvancar?.hasAttribute('disabled')) {
				return;
			}

			if (!(await this.validarQuestaoEmBranco())) {
				return;
			}
		}

		const $aplicacaoProva = this.get$AplicacaoProva();

		if (!$aplicacaoProva) return;

		const isQuestaoPorQuestao = $aplicacaoProva.data('fp-is-questao-por-questao');

		this.stopAudioAndVideos();
	
		if (isQuestaoPorQuestao) {
			await navegacaoPFVH.irParaQuestao("posterior", null, true);
		} else {
			navegacaoPFVH.irParaQuestaoPaginaUnica("proxima");
		}
	}

	get$BotoesNavegacao() {
		return $(`
			#botao_avancar, 
			#botao_anterior, 
			#botao_concluir, 
			.btn-numerador-questao
		`);
	}

	desabilitarBotoes() {
		this.get$BotoesNavegacao().attr("disabled", true);
	}

	habilitarBotoes() {
		const $botoesDesabilitados = $(`
			#botao_avancar[disabled], 
			#botao_anterior[disabled], 
			#botao_concluir[disabled], 
			.btn-numerador-questao[disabled]
		`);

		if ($botoesDesabilitados.length) {
			setTimeout(() => $botoesDesabilitados.removeAttr('disabled'), 1500);
		}
	}

	setarLoggerNavegacaoQuestao($questaoAtual, $questaoDestino) {
		const numQuestaoAtual = $questaoAtual ? $questaoAtual.find('span.numerador').text().trim() : '';
		const numQuestaoDestino = $questaoDestino.find('span.numerador').text().trim()

		if (numQuestaoDestino != numQuestaoAtual) {
			if (numQuestaoAtual) {
				navegacaoPFVH.logger.info(`Usuário navegou da questão ${numQuestaoAtual} para a questão ${numQuestaoDestino}`);
			} else {
				navegacaoPFVH.logger.info(`Usuário visualizou a questão ${numQuestaoDestino}`);
			}
		}
	}

	async forcarBlurRespostaDiscursiva() {
		const $elementoComFoco = $(":focus");

		if ($elementoComFoco.length == 0) {
			return;
		}

		if ($elementoComFoco.is("textarea[tipo='discursiva']")) {
			await new Promise((resolve) => {
				$elementoComFoco.trigger("blur", resolve);
			});
		} else {
			$elementoComFoco.trigger("blur");
		}
	}
	
	handleEntrarModoTravamento() {
		try {
			const $botoesNavegacao = this.get$BotoesNavegacao();

			if ($botoesNavegacao.length) {
				$botoesNavegacao.attr("disabled", true);
			}
		} catch (e) {
			this.logger.error("Erro ao tentar desabilitar botões de navegação", e);
		}
	}
}

const navegacaoPFVH = new NavegacaoPFVH();
