class AssociacaoQuestaoVH extends AmaisVH {

	codProva: number;
	codSecao: number;
	filtrosSelecaoQuestoesTO: any;
	numeroPagina: number;
	listagensTO: any;
	scrollParaResultado: boolean;
	listaSecaoQuestaoTO: any[];
	secaoQuestaoTOPorCodQuestao: any[];
	codQuestaoNovaSecao: number;
	isAssociarNovaSecaoParaTodas: boolean;
	filtroAssociacaoQuestaoTO: FiltroAssociacaoQuestaoTO = {exibirAtivas: true, exibirAnuladas: false, exibirExcluidas: false}

	constructor() {
		super(AssociacaoQuestaoVH.name)
	}

	async listarQuestoesDaProva(codProva: number) {

		this.codProva = codProva;
		this.codSecao = null;
		this.filtrosSelecaoQuestoesTO = null;
		this.numeroPagina = 0;

		const listagensTO = await this.call("AssociacaoQuestaoFCD/recuperarListagens", codProva, null);

		this.listagensTO = listagensTO;
		this.listarQuestoes();
	}

	async listarQuestoesDaSecao(codProva, codSecao) {

		this.codProva = codProva;
		this.codSecao = codSecao;
		this.filtrosSelecaoQuestoesTO = null;
		this.numeroPagina = 0;

		const listagensTO = await this.call("AssociacaoQuestaoFCD/recuperarListagens", codProva, codSecao);

		this.listagensTO = listagensTO;
		await this.listarQuestoes();
	}

	async listarQuestoes() {

		if (this.numeroPagina == null) {
			this.numeroPagina = 0;
		}

		let listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO;

		if (this.codSecao != null) {
			listagemPaginadaQuestoesTO = await this.call("AssociacaoQuestaoFCD/recuperarQuestoesDaSecao", this.codSecao, this.numeroPagina, this.filtroAssociacaoQuestaoTO);
			
		} else {
			listagemPaginadaQuestoesTO = await this.call("AssociacaoQuestaoFCD/recuperarQuestoesDaProva", this.codProva, this.numeroPagina, this.filtroAssociacaoQuestaoTO, false);
		}

		this.montarEstruturaExibicao(listagemPaginadaQuestoesTO);
	}

	montarEstruturaExibicao(listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO) {

		if ($("#barraAssociacao").length == 0) {

			this.append(`
				<div id="barraAssociacao" class="row">
					<div id="barraInternaAssociacao" class="col-md-12">
					</div>
				</div>
				<div class="row">
					<div associacao-prova id="questoesAssociacao" class="col-xs-12">
					</div>
				</div>`
			);

			if (this.listagensTO.podeAlterarProva && !this.listagensTO.isArquivada) {
				this.append("<div id='operacoes_sobre_pagina' class='btn-group affix-bottom' data-spy='affix'>");

				const opcoesTodas: any[] = [{ label: `<i class='fa fa-times-circle'></i> ${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_001")}`, onClick: () => this.desmarcarTodasQuestoes() }];

				opcoesTodas.push({ label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_002")}`, onClick: () => this.criarNovaSecao(null, true) });
				opcoesTodas.push({ divisor: true });

				this.listagensTO.secoes.forEach((listagemSecaoTO) => {
					opcoesTodas.push({ 
						label: listagemSecaoTO.nome, 
						onClick: () => this.marcarTodasQuestoes(listagemSecaoTO.codSecao) 
					});
				});

				this.addBotaoDropdown({ id: "dropdownTodas", label: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_003"), dropup: true, esq: true, opcoes: opcoesTodas });
				this.addBotao({ label: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_004"), onClick: () => this.salvar() });
				this.addBotao({ label: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_005"), onClick: () => this.irParaSecoes() });

				this.append("</div>");
			}

			this.appendJs(() => {
				$("#barraInternaAssociacao").css('top', $('body').css('padding-top')).affix({ offset: { top: 420 } });
				$('#operacoes_sobre_pagina').affix({ offset: { top: null } });
			});

			this.exibir();
		}

		this.exibirListagemDeQuestoes(listagemPaginadaQuestoesTO)
	}

	exibirListagemDeQuestoes(listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO) {

		if (listagemPaginadaQuestoesTO == null) {
			return;
		}

		this.setHtml("barraInternaAssociacao", "");
		this.setIdTarget("barraInternaAssociacao");
		let subtitulo;

		if (this.filtrosSelecaoQuestoesTO != null) {
			if (this.filtrosSelecaoQuestoesTO.tituloExibicaoFiltro != null) {
				subtitulo = this.filtrosSelecaoQuestoesTO.tituloExibicaoFiltro;
			} else {
				subtitulo = this.getMsg("FP_FRONT_AssociacaoQuestaoVH_006");
			}
		} else if (this.listagensTO.codSecao != null) {
			subtitulo = this.getMsg("FP_FRONT_AssociacaoQuestaoVH_008", `<b>${this.listagensTO.nomeSecao}</b>`);
		} else {
			subtitulo = this.getMsg("FP_FRONT_AssociacaoQuestaoVH_007");
		}

		this.addSubtitulo({ 
			texto: subtitulo, 
			css: "width: auto; float: left",
			cssContainer: "width: 100%",
			htmlDireita: `
				${this.criarCheckBoxFiltroQuestao('checkbox_exibir_questoes_ativas', this.filtroAssociacaoQuestaoTO.exibirAtivas, this.getMsg('FP_FRONT_AssociacaoQuestaoVH_044'))} 
				${this.criarCheckBoxFiltroQuestao('checkbox_exibir_questoes_anuladas', this.filtroAssociacaoQuestaoTO.exibirAnuladas, this.getMsg('FP_FRONT_AssociacaoQuestaoVH_045'))} 
				${this.criarCheckBoxFiltroQuestao('checkbox_exibir_questoes_excluidas', this.filtroAssociacaoQuestaoTO.exibirExcluidas, this.getMsg('FP_FRONT_AssociacaoQuestaoVH_046'))} 
			`
		});

		this.append(UtilPaginacao.criarPaginador({
			onCarregarPagina: async (paginacaoTO) => {
				this.numeroPagina = paginacaoTO.numPaginaAtual;
				//index de numero de pagina inicia em zero
		
				if (this.filtrosSelecaoQuestoesTO != null) {

					const listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO = await this.call({
						endpoint: "AssociacaoQuestaoFCD/buscarQuestoes",
						params: [this.listagensTO.codProva, this.filtrosSelecaoQuestoesTO, this.numeroPagina],
						msgCarregando: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_036")
					});

					this.exibirListagemDeQuestoesDaBusca(listagemPaginadaQuestoesTO);
		
				} else {
					let listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO;
					if (this.codSecao != null) {
						listagemPaginadaQuestoesTO = await this.call("AssociacaoQuestaoFCD/recuperarQuestoesDaSecao", this.codSecao, this.numeroPagina, this.filtroAssociacaoQuestaoTO);
		
					} else {
						listagemPaginadaQuestoesTO = await this.call("AssociacaoQuestaoFCD/recuperarQuestoesDaProva", this.codProva, this.numeroPagina, this.filtroAssociacaoQuestaoTO);
					}
					this.exibirListagemDeQuestoes(listagemPaginadaQuestoesTO);
				}
			},
			paginacaoTO: listagemPaginadaQuestoesTO.paginacaoTO,
			displayOpcao: null,
			displayOpcaoInicioFim: null,
			css: "width: auto; float: right"
		}));

		this.setHtml("questoesAssociacao", "");
		this.setIdTarget("questoesAssociacao");

		exibicaoQuestaoVH.limparControleTextos();

		listagemPaginadaQuestoesTO.listaListagemQuestaoTO.forEach((listagemQuestaoTO, i) => {
			const exibicaoQuestaoTO = listagemQuestaoTO.exibicaoQuestaoTO;

			if (i % 2 == 0) {
				this.addEspacamentoHorizontal("1px")
			}

			this.append("<div barra-edicao-questao class='questaoNaAssociacao col-xs-12 col-sm-12 col-md-6' cod-questao='" + exibicaoQuestaoTO.codQuestao + "' ");
			this.append(listagemQuestaoTO.idSecaoQuestao ? " id-secao-questao='" + listagemQuestaoTO.idSecaoQuestao + "'" : "");
			this.append("><div class='cmdsQuestaoAssociacao'>");

			exibicaoQuestaoVH.exibirLinkUsoQuestao(listagemQuestaoTO.usoResumidoQuestaoTO);

			this.append("<div class='btn-group'>");

			this.append("<a class='btn btn-default btn-sm' disabled='disabled'>#" + exibicaoQuestaoTO.codQuestao + "</a>");

			if (exibicaoQuestaoTO.podeAlterarQuestao === true) {
				this.addBotao({
					onClick: () => {
						this.abrirNovaJanela(UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 1, exibicaoQuestaoTO.codQuestao));
						$("[questao][cod-questao='" + exibicaoQuestaoTO.codQuestao + "']").attr("abriu-edicao-nova-pagina", true);
					},
					texto: "<i class='fa fa-pencil'></i>",
					dica: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_013"),
					classe: "btn-sm",
					html: "target='_blank'"
				});
			}

			if ((!exibicaoQuestaoTO.podeAlterarQuestao || exibicaoQuestaoTO.isConteudoBloqueado) && this.listagensTO.podeAlterarProva && !this.listagensTO.isArquivada) {
				this.addBotao({
					onClick: ({target}) => {
						const codQuestao = $(target).closest("[barra-edicao-questao]").attr("cod-questao");
						const idSecaoQuestao = $(target).closest("[barra-edicao-questao]").attr("id-secao-questao");
						this.copiarQuestao(idSecaoQuestao, codQuestao, () => {
							this.listarQuestoes();
						});
					},
					texto: "<i class='fa fa-clone'></i>",
					dica: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_014"),
					classe: "btn-sm",
				});
			}

			if (listagemQuestaoTO.usoResumidoQuestaoTO && (listagemQuestaoTO.usoResumidoQuestaoTO.numRespostas || listagemQuestaoTO.usoResumidoQuestaoTO.numProvasUsando)) {
				this.addBotao({
					onClick: async () => {
						await cadastroQuestaoVH.mostrarUso(exibicaoQuestaoTO.codQuestao, true);
					},
					texto: "<i class='fa fa-list-ul'></i>",
					dica: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_043"),
					classe: "btn-sm",
				});
			}

			this.addBotao({
				onClick: () => {
					exibicaoQuestaoVH.exibirVersoesQuestao(exibicaoQuestaoTO.codQuestao);
				},
				texto: "<i class='fa fa-history'></i>",
				dica: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_015"),
				classe: "btn-sm",
			});

			if (this.listagensTO.podeAlterarProva && !this.listagensTO.isArquivada) {
				if (this.filtrosSelecaoQuestoesTO == null) {
					this.addBotao({
						onClick: () => this.reposicionarQuestao(exibicaoQuestaoTO.codQuestao, false),
						classe: "btn-sm",
						label: "<i class='fa fa-chevron-down' ></i>"
					})
					this.addBotao({
						onClick: () => this.reposicionarQuestao(exibicaoQuestaoTO.codQuestao, true),
						classe: "btn-sm",
						label: "<i class='fa fa-chevron-up' ></i>"
					})
				}
			}

			this.append(this.exibirComboSecao(listagemQuestaoTO));

			this.append("</div></div>");

			exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO);
			this.append("</div>")
		});

		this.appendJs(() => $('._popover').popover({html: true}));

		this.exibir();

		setTimeout( () => {
			const possuiQuestaoComAssociacaoNaoPermitida = $('.msg-associao-nao-permitida').length;

			if (possuiQuestaoComAssociacaoNaoPermitida) {
				$('#dropdownTodas > a').attr('disabled', true);
			}
		}, 300);

		if (this.isEmpty(this.scrollParaResultado) || this.scrollParaResultado === true) {
			UtilWindow.scrollTo("#conteudoTabBusca");
		}

		this.listaSecaoQuestaoTO = [];
		this.secaoQuestaoTOPorCodQuestao = [];

		$("[questao]").each((i, questao) => {
			this.onPosAtualizarQuestao(questao);
		}).on("atualizar-executar", async (event) => {
			event.preventDefault();
			event.stopPropagation();
			await this.onAtualizarQuestao(event.target);
		});
	}

	private criarCheckBoxFiltroQuestao(id, valor, label) {
		return this.addCheckbox({
			id: id,
			valor: valor,
			retornarHtml: true,
			classe: "col-md-3",
			label: label,
			ignorarFormulario: true,
			onChange: async (e) => {
				this.filtroAssociacaoQuestaoTO.exibirAtivas = this.getValor('checkbox_exibir_questoes_ativas');
				this.filtroAssociacaoQuestaoTO.exibirAnuladas = this.getValor('checkbox_exibir_questoes_anuladas');
				this.filtroAssociacaoQuestaoTO.exibirExcluidas = this.getValor('checkbox_exibir_questoes_excluidas');

				await this.buscarQuestoesAssociacao();
			},
		});
	}

	async buscarQuestoesAssociacao() {
		let listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO;
		if (this.codSecao != null) {
			listagemPaginadaQuestoesTO = await this.call("AssociacaoQuestaoFCD/recuperarQuestoesDaSecao", this.codSecao, null, this.filtroAssociacaoQuestaoTO);
		} else {
			listagemPaginadaQuestoesTO = await this.call("AssociacaoQuestaoFCD/recuperarQuestoesDaProva", this.codProva, null, this.filtroAssociacaoQuestaoTO);
		}
		this.exibirListagemDeQuestoes(listagemPaginadaQuestoesTO);
	}

	async copiarQuestao(idSecaoQuestao, codQuestao, callback) {
		
		const historicoUsoQuestaoTO = await this.call("CadastroQuestaoFCD/recuperarHistoricoUsoQuestao", codQuestao);

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_016"),
			width: "1000px",
			botoes: [{
				label: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_017"), 
				classe: "btn-primary", 
				onClick: async () => {
					const substituicaoQuestaoTO = {
						codQuestao: codQuestao,
						listaSubstituicaoQuestaoNaProvaTO: [],
						isArquivarOriginal: this.getValor("isArquivarOriginal")
					}

					$("[id^='substituirSecaoQuestao']:checked").each((i, checkbox) => {
						const id = $(checkbox).attr("id");
						const idSecaoQuestao = id.substr("substituirSecaoQuestao".length);

						const substituicaoQuestaoNaProvaTO = {
							idSecaoQuestao: idSecaoQuestao,
							isTransferirNosCadernosERespostas: this.getValor("substituirNosCadernosERespostasSecaoQuestao" + idSecaoQuestao)
						};

						substituicaoQuestaoTO.listaSubstituicaoQuestaoNaProvaTO.push(substituicaoQuestaoNaProvaTO);
					});

					await this.call("AssociacaoQuestaoFCD/substituirQuestaoPorCopia", substituicaoQuestaoTO);
					callback();
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_AssociacaoQuestaoVH_018"));

		const tosSemRemocoes = [];

		for (const historicoUsoQuestaoProvaTO of historicoUsoQuestaoTO.listaHistoricoUsoQuestaoProvaTO) {
			if (historicoUsoQuestaoProvaTO.dataRemocao) continue;

			tosSemRemocoes.push(historicoUsoQuestaoProvaTO);
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_019"), prop: (historicoUsoQuestaoProvaTO) => {
				const h = [];
				const hashProva = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 2, historicoUsoQuestaoProvaTO.codProva, codQuestao);
				h.push("<a href='" + hashProva + "'>" + historicoUsoQuestaoProvaTO.titulo + "</a>");

				if (historicoUsoQuestaoProvaTO.dataAssociacao) {
					h.push(this.getMsg("FP_FRONT_AssociacaoQuestaoVH_027", UtilData.toDDMMYYYY(historicoUsoQuestaoProvaTO.dataAssociacao), historicoUsoQuestaoProvaTO.nomeUsuarioAssociacao));
				}

				if (historicoUsoQuestaoProvaTO.dataRemocao) {
					h.push(this.getMsg("FP_FRONT_AssociacaoQuestaoVH_028", UtilData.toDDMMYYYY(historicoUsoQuestaoProvaTO.dataRemocao), historicoUsoQuestaoProvaTO.nomeUsuarioRemocao));
				}
				return h.join("<br>");
			}, classe: "descricao"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_020"), prop: (historicoUsoQuestaoProvaTO) => {
				if (historicoUsoQuestaoProvaTO.isArquivada) {
					return `<span class='label label-default'>${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_029")}</span>`;
				} else if (historicoUsoQuestaoProvaTO.numRespostasAlunos || historicoUsoQuestaoProvaTO.numCadernos) {
					return this.getMsg("FP_FRONT_AssociacaoQuestaoVH_030");
				} else {
					return historicoUsoQuestaoTO.tiposStatusProva[historicoUsoQuestaoProvaTO.status];
				}
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_021"), prop: "numQuestao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_022"), prop: "numCadernos"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_023"), prop: "numRespostasAlunos"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_024"), prop:  (historicoUsoQuestaoProvaTO) => {
				const html = [];
				html.push(this.addCheckbox({
					label: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_025"),
					id: "substituirSecaoQuestao" + historicoUsoQuestaoProvaTO.idSecaoQuestao,
					retornarHtml: true,
					valor: true,
					classe: "col-md-12",
					onChange: (event) => {
						const idCheckbox = event.target.id;
						const idSecaoQuestao = idCheckbox.substr("substituirSecaoQuestao".length);
						if (!this.getValor(idCheckbox)) {
							this.setValor("substituirNosCadernosERespostasSecaoQuestao" + idSecaoQuestao, false);
							this.disable("substituirNosCadernosERespostasSecaoQuestao" + idSecaoQuestao);
						} else {
							this.enable("substituirNosCadernosERespostasSecaoQuestao" + idSecaoQuestao);
						}
					}
				}));
				if (historicoUsoQuestaoProvaTO.numRespostasAlunos || historicoUsoQuestaoProvaTO.numCadernos) {
					html.push(this.addCheckbox({
						label: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_026"),
						id: "substituirNosCadernosERespostasSecaoQuestao" + historicoUsoQuestaoProvaTO.idSecaoQuestao,
						retornarHtml: true,
						valor: false,
						classe: "col-md-12"
					}));
				}
				return "<div class='col-md-12' style='text-align: left'>" + html.join("<br>") + "</div>";
			}
		});

		await this.addTabela({
			collection: tosSemRemocoes,
			titulo: "",
			propId: "codProva",
			ordenar: false,
			id: "codsProvasOcorrencias",
			colunas: colunas
		});

		if (!historicoUsoQuestaoTO.isBancoQuestoesPublico) {
			this.addCheckbox({
				id: "isArquivarOriginal",
				label: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_031"),
				valor: true,
				onChange: (event) => {
					const arquivarCheckbox: HTMLInputElement = <HTMLInputElement> document.getElementById(event.target.id);
					document.querySelectorAll("[id^='substituirSecaoQuestao']").forEach((checkbox: HTMLInputElement) => {
						if (arquivarCheckbox.checked) {
							checkbox.checked = true;
							checkbox.disabled = true;
						} else {
							checkbox.disabled = false;
						}
						if (checkbox.onchange) {
							checkbox.onchange(null);
						}
					});
				}
			});
		}

		this.exibirPopups();

		if (!historicoUsoQuestaoTO.isBancoQuestoesPublico) {
			const checkbox = <HTMLInputElement> document.getElementById("isArquivarOriginal");
			checkbox.onchange(null);
		}
	}

	async onAtualizarQuestao(questao) {
		const codQuestao = $(questao).attr("cod-questao");
		const exibicaoQuestaoTO = await this.call("AssociacaoQuestaoFCD/buscarQuestaoPorCodigo", this.codProva, codQuestao);
		exibicaoQuestaoVH.atualizarExibicaoQuestao(questao, exibicaoQuestaoTO);
		this.onPosAtualizarQuestao(questao);
	}

	onPosAtualizarQuestao(questao) {
		$(questao).find("[disciplinas]").show();
		$(questao).filter("[pode-alterar]").find("[disciplinas]").find("a")
			.attr("title", this.getMsg("FP_FRONT_AssociacaoQuestaoVH_032"))
			.append(" <i class='fa fa-trash'></i>")
			.off("click")
			.on("click", ({ target }) => {
				cadastroQuestaoVH.removerClassificacao(target);
			});
	}

	async exibirBuscaDeQuestoes(codProva) {

		this.codProva = codProva;
		this.codSecao = null;

		const listagensTO = await this.call("AssociacaoQuestaoFCD/recuperarListagens", this.codProva, null);
	
		this.listagensTO = listagensTO;

		this.limpar(true);

		// this.append(`
		// 	<ul id='divBuscasQuestoes' class='nav nav-pills'>
		// 		<li role='presentation'>
		// 			<a id='tabBusca' href='#conteudoTabBusca' data-toggle='tab' role='presentation'>
		// 				${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_033")}
		// 			</a>
		// 		</li>
		// 		<li role='presentation'>
		// 			<a id='tabExplorador' href='#conteudoTabExplorador' data-toggle='tab'>
		// 				${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_034")}
		// 			</a>
		// 		</li>
		// 	</ul>
		// 	<div class='tab-content'>
		// 		<div id='conteudoTabBusca' class='tab-pane'></div>
		// 		<div id='conteudoTabExplorador' class='tab-pane'></div>
		// 	</div>
		// `);

		// this.exibir();

		// $("#tabBusca").click(async () => {
		// 	if ($("#conteudoTabBusca").attr("isCarregada") == null) {
		// 		await this.exibirBuscaQuestoes("conteudoTabBusca");
		// 		$("#conteudoTabBusca").attr("isCarregada", true);
		// 	}
		// });

		// $("#tabExplorador").click(() => {
		// 	if ($("#conteudoTabExplorador").attr("isCarregada") == null) {
		// 		exploradorQuestoesVH.exibirExplorador({
		// 			idDiv: "conteudoTabExplorador",
		// 			metodoOnMostrar: async (filtrosSelecaoQuestoesTO) => {

		// 				this.numeroPagina = 0;
		// 				this.filtrosSelecaoQuestoesTO = filtrosSelecaoQuestoesTO;

		// 				const listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO = await this.call("AssociacaoQuestaoFCD/buscarQuestoes", this.listagensTO.codProva, this.filtrosSelecaoQuestoesTO, this.numeroPagina);
						
		// 				this.exibirListagemDeQuestoesDaBusca(listagemPaginadaQuestoesTO);
		// 			}
		// 		});
		// 		$("#conteudoTabExplorador").attr("isCarregada", true);
		// 	}
		// });

		// $("#tabBusca").click();

		this.append(`
			<div id="conteudoTabBusca" class="tab-pane"></div>
		`);
		await this.exibirBuscaQuestoes("conteudoTabBusca");

		this.exibir();
	}

	async exibirBuscaQuestoes(idDiv) {
		this.setIdTarget(idDiv);
		this.setMetodoSubmit(async () => await this.buscarQuestoes(true));

		await selecaoQuestoesVH.montarHtmlFiltros({
			textoAntes: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_035"),
			possuiAcessoQuestoesComConcurso: this.listagensTO.possuiAcessoQuestoesComConcurso,
			onFiltroAlterado: async () => await this.buscarQuestoes(true),
		});

		this.exibir();
		this.buscarQuestoes(false);
	}

	async reposicionarQuestao(codQuestao, isSubirQuestao) {

		const mudancaDePosicaoDeQuestaoTO = {
			codProva: this.codProva,
			codSecao: this.codSecao,
			codQuestao: codQuestao,
			isSubirQuestao: isSubirQuestao
		}

		await this.call("AssociacaoQuestaoFCD/mudarPosicaoDaQuestao", mudancaDePosicaoDeQuestaoTO);

		await this.call("CadastroProvaFCD/atualizarNumQuestoes", mudancaDePosicaoDeQuestaoTO.codProva);

		this.listarQuestoes();
	}

	exibirComboSecao(listagemQuestaoTO) {

		const exibicaoQuestaoTO = listagemQuestaoTO.exibicaoQuestaoTO;
		const h = [];
		let nomeSecaoAtiva = null;
		let possuiSecaoDisponivel = !this.listagensTO.secoes || this.listagensTO.secoes.length == 0;

		for (let listagemSecaoTO of this.listagensTO.secoes) {

			if (!listagemQuestaoTO.codsSecoesNaoPermitidas?.length || !listagemQuestaoTO.codsSecoesNaoPermitidas.includes(listagemSecaoTO.codSecao)) {
				possuiSecaoDisponivel = true;
				h.push("<li><a codSecao='")
				h.push(listagemSecaoTO.codSecao);
				h.push("' onclick='associacaoQuestaoVH.associarQuestao(this)' style='white-space: normal;'>");
				h.push(listagemSecaoTO.nome);
				h.push("</a></li>");

				if (listagemSecaoTO.codSecao == listagemQuestaoTO.codSecao) {
					nomeSecaoAtiva = listagemSecaoTO.nome;
				}
			}
		}

		if (!possuiSecaoDisponivel) {
			h.push(`
				<small class="msg-associao-nao-permitida" style="display: block; padding-left: 10px; padding-right: 5px"> 
					${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_047")}
				</small>
			`);
		}

		const html = ["<div id='associacao_secao_", exibicaoQuestaoTO.codQuestao, "' codQuestao='", exibicaoQuestaoTO.codQuestao, "'"];
		html.push(exibicaoQuestaoTO.isQuestaoBloqueada ? " bloqueada='true' " : "");
		html.push(" class='btn-group'>");

		if (this.listagensTO.podeAlterarProva) {
			if (nomeSecaoAtiva == null && this.listagensTO.podeAlterarProva) {
				html.push("<button type='button' class='btn btn-default btn-sm dropdown-toggle' data-toggle='dropdown'");
				html.push(exibicaoQuestaoTO.isQuestaoBloqueada ? " disabled='disabled' " : "")
				html.push(`>${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_037")}`);
			} else {
				html.push("<button type='button' class='btn btn-default btn-sm btn-warning dropdown-toggle' data-toggle='dropdown'>" + nomeSecaoAtiva);
			}

			html.push(" <span class='caret'></span></button>");
			html.push("<ul class='dropdown-menu pull-right dropdownSecoes' role='menu' aria-labelledby='dLabel'" +
				"style='max-width: 450px;'>");
			html.push(`<li><a onclick='associacaoQuestaoVH.associarQuestao(this)'><i class='fa fa-times-circle'></i> ${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_038")}</a></li>`);
			html.push(`<li><a onclick='associacaoQuestaoVH.criarNovaSecao(${exibicaoQuestaoTO.codQuestao})'><i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_039")}</a></li>`);
			html.push("<li class='divider'></li>");

			html.push(h.join(""));

			html.push("</ul>");
		} else {
			html.push(`<button type='button' class='btn btn-default btn-sm'>${nomeSecaoAtiva}</button>`);
		}

		html.push("</div>");

		return html.join("");
	}

	criarNovaSecao(codQuestao: number, isAssociarNovaSecaoParaTodas: boolean) {
		cadastroSecaoVH.exibirEdicaoNovaSecao((codProva, codSecaoSalva, nomeSecao) => {
			this.exibirNovaSecao(codProva, codSecaoSalva, nomeSecao);
		});
		this.codQuestaoNovaSecao = codQuestao;
		this.isAssociarNovaSecaoParaTodas = isAssociarNovaSecaoParaTodas;
	}

	async exibirNovaSecao(codProva, codSecao, nomeSecao) {
		let h = ["<li><a onclick='associacaoQuestaoVH.associarQuestao(this)' codSecao='"];

		h.push(codSecao);
		h.push("'>");
		h.push(nomeSecao);
		h.push("</a></li>");

		$(".dropdownSecoes").append(h.join(""));

		h = ["<li><a onclick='associacaoQuestaoVH.marcarTodasQuestoes("];
		h.push(codSecao);
		h.push(")'>");
		h.push(nomeSecao);
		h.push("</a></li>");

		$("#dropdownTodas > ul").append(h.join(""));

		if (this.codQuestaoNovaSecao != null) {
			const link = $("#associacao_secao_" + this.codQuestaoNovaSecao + " ul li a:last");
			this.associarQuestao(link);
		}

		if (this.isAssociarNovaSecaoParaTodas === true) {
			this.marcarTodasQuestoes(codSecao);
		}

		this.listagensTO = await this.call("AssociacaoQuestaoFCD/recuperarListagens", codProva, null);
	}

	associarQuestao(linkDaSecao) {

		const $linkDaSecao = $(linkDaSecao);
		const codQuestao = $linkDaSecao.closest("[codQuestao]").attr("codQuestao");
		const codSecao = $linkDaSecao.attr("codSecao");
		let novoLabel = this.getMsg("FP_FRONT_AssociacaoQuestaoVH_040");

		if (codSecao != null) {
			novoLabel = $linkDaSecao.text();
			$linkDaSecao.parent().parent().parent().find("button").addClass("btn-warning");
		} else {
			$linkDaSecao.parent().parent().parent().find("button").removeClass("btn-warning");
		}

		$linkDaSecao.parent().parent().parent().find("button:first").html(novoLabel + " <span class='caret'></span>");

		let secaoQuestaoTO = this.secaoQuestaoTOPorCodQuestao[codQuestao];

		if (secaoQuestaoTO) {
			// remove da lista a adiciona novamente no final
			const i = this.listaSecaoQuestaoTO.indexOf(secaoQuestaoTO);
			this.listaSecaoQuestaoTO.splice(i, 1);
			this.listaSecaoQuestaoTO.push(secaoQuestaoTO);
		} else {
			secaoQuestaoTO = { codQuestao: codQuestao }
			this.secaoQuestaoTOPorCodQuestao[codQuestao] = secaoQuestaoTO;
			this.listaSecaoQuestaoTO.push(secaoQuestaoTO);
		}

		secaoQuestaoTO.codSecao = codSecao;
	}

	async buscarQuestoes(scrollParaResultado) {

		this.scrollParaResultado = scrollParaResultado;
		this.numeroPagina = 0;
		this.filtrosSelecaoQuestoesTO = selecaoQuestoesVH.getFiltrosSelecaoQuestoesTO();

		if (this.filtrosSelecaoQuestoesTO == null) {
			this.filtrosSelecaoQuestoesTO = {
				tituloExibicaoFiltro: this.getMsg("FP_FRONT_AssociacaoQuestaoVH_041")
			}
		}

		const listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO = await this.call("AssociacaoQuestaoFCD/buscarQuestoes", this.listagensTO.codProva, this.filtrosSelecaoQuestoesTO, this.numeroPagina);

		this.exibirListagemDeQuestoesDaBusca(listagemPaginadaQuestoesTO);
	}

	async salvar() {

		const associacaoQuestaoTO = {
			codProva: this.listagensTO.codProva,
			listaSecaoQuestaoTO: this.listaSecaoQuestaoTO
		}

		await this.call("AssociacaoQuestaoFCD/salvarAssociacaoQuestao", associacaoQuestaoTO);

		this.mostrarMsgAjax(this.getMsg("FP_FRONT_AssociacaoQuestaoVH_042"), 2);
	}

	irParaSecoes() {
		cadastroSecaoVH.exibirSecoes(this.codProva)
	}

	desmarcarTodasQuestoes() {
		$(`div[id^='associacao_secao_'] a:contains('${this.getMsg("FP_FRONT_AssociacaoQuestaoVH_038")}') `).click();
	}

	marcarTodasQuestoes(codSecao) {
		$("div[id^='associacao_secao_']:not([bloqueada]) a[codSecao='" + codSecao + "']").click();
	}

	exibirListagemDeQuestoesDaBusca(listagemPaginadaQuestoesTO: ListagemPaginadaQuestoesTO) {
		this.montarEstruturaExibicao(listagemPaginadaQuestoesTO);
		if (this.scrollParaResultado == null || this.scrollParaResultado === true) {
			UtilWindow.scrollTo("#conteudoTabBusca");
		}
	}
}

const associacaoQuestaoVH = new AssociacaoQuestaoVH();

class ListagemPaginadaQuestoesTO {
	listaListagemQuestaoTO: ListagemQuestaoTO[];
	paginacaoTO: PaginacaoTO;
	filtroAssociacaoQuestaoTO: FiltroAssociacaoQuestaoTO;
}
class FiltroAssociacaoQuestaoTO {
	exibirAtivas: boolean;
	exibirAnuladas: boolean;
	exibirExcluidas: boolean;
}
class ListagemQuestaoTO {
	codSecao: number;
	idSecaoQuestao: number;
	exibicaoQuestaoTO: any; //ExibicaoQuestaoTO;
	usoResumidoQuestaoTO: UsoResumidoQuestaoTO;
}
class UsoResumidoQuestaoTO {
	codQuestao: number;
	numRespostas: number;
	numProvasUsando: number;
}