class CronoFaceMatchVH {

	$tempoElement: any;
    callback: Function;
    milisegundosRestantes: number;

    cronoListener: Function = (msDecorridos) => {
        this.handleCronoTick(msDecorridos);
    }

    iniciarCronometro(milisegundosRestantes, $tempoElement, callback: Function) {
        this.pararCronometro();

        this.milisegundosRestantes = milisegundosRestantes;
        this.$tempoElement = $tempoElement;
        this.callback = callback;

        UtilCrono.adicionarListener(this.cronoListener);
    }

    async handleCronoTick(msDecorridos: number) {

		if (msDecorridos == null || msDecorridos < 1) {
			return;
		}

        if (this.$tempoElement.closest('body').length === 0) {
            this.pararCronometro();
            this.callback();
			return;
        }

        const tempoFormatado = UtilTempo.getTempoFormatado(this.milisegundosRestantes);
        this.$tempoElement.text(tempoFormatado);

        if (this.isTempoEsgotado()) {
            this.pararCronometro();
            this.callback();
        }

        this.milisegundosRestantes -= msDecorridos;
    }

    isTempoEsgotado(): boolean {
        return this.milisegundosRestantes <= 0;
    }

    pararCronometro() {
        UtilCrono.removerListener(this.cronoListener);
    }
}
