class FiscalizacaoVH extends AmaisVH {

	constructor() {
		super(FiscalizacaoVH.name);
	}

	async exibirDadosAlunoParaLiberacao(codAgendamentoUsuario, onLiberacaoAlterada?: Function) {

		if (!onLiberacaoAlterada) {
			onLiberacaoAlterada = (codAgendamento: number) => {
				agendamentoProvaVH.exibirSalaDeAvaliacao(codAgendamento);
			}
		}

		const exibicaoDadosUsuarioLiberacaoTO = await this.call("AgendamentoProvaFCD/recuperarDadosAlunoParaLiberacao", codAgendamentoUsuario);

		var botoes = []

		if (!exibicaoDadosUsuarioLiberacaoTO.isCadernoPreGerado && exibicaoDadosUsuarioLiberacaoTO.codProvaFeita) {
			// sem ações para fiscal pois avaliado já gerou caderno

		} else if (exibicaoDadosUsuarioLiberacaoTO.isLiberado) {
			botoes.push({ 
				label: this.getMsg("FP_FRONT_FiscalizacaoVH_001"), 
				onClick: async () => {
					const codAgendamento = await this.call("AgendamentoProvaFCD/cancelarLiberacaoAgendamentoUsuario", exibicaoDadosUsuarioLiberacaoTO.codAgendamentoUsuario);
					onLiberacaoAlterada(codAgendamento);
				}
			});
		} else {
			botoes.push({ 
				label: "<i class='fa fa-calendar-check-o'></i> " + this.getMsg("FP_FRONT_FiscalizacaoVH_002"), 
				classe: "btn-primary", 
				onClick: async () => {

					var deveLiberar = false
			
					if (this.hasValue(this.getValor("agendamento_obs_ausencia"))) {
						if (await this.confirmar(this.getMsg("FP_FRONT_FiscalizacaoVH_021"))) {
							deveLiberar = true
						}
					} else {
						deveLiberar = true
					}
			
					if (deveLiberar) {
						const codAgendamento = await this.call("AgendamentoProvaFCD/liberarAgendamentoUsuario", exibicaoDadosUsuarioLiberacaoTO.codAgendamentoUsuario);
						onLiberacaoAlterada(codAgendamento);
					} else {
						return false
					}
				}
			})
			botoes.push({ 
				label: "<i class='fa fa-calendar-times-o'></i> " + this.getMsg("FP_FRONT_FiscalizacaoVH_003"), 
				classe: "btn-warning", 
				onClick: async () => {
					if (this.validarCamposObrigatorios("agendamento_obs_ausencia")) {
						var registroAusenciaAgendamentoUsuarioTO = {
							codAgendamentoUsuario: exibicaoDadosUsuarioLiberacaoTO.codAgendamentoUsuario,
							observacaoAusencia: this.getValor("agendamento_obs_ausencia")
						}
						const codAgendamento = await this.call("AgendamentoProvaFCD/registrarAusenciaAgendamentoUsuario", registroAusenciaAgendamentoUsuarioTO);
						onLiberacaoAlterada(codAgendamento);
					} else {
						return false
					}
				}
			});
		}

		this.addPopup({
			width: "600px",
			// height: "600px",
			id: "liberacao_agendamento_usuario",
			titulo: this.getMsg("FP_FRONT_FiscalizacaoVH_004"),
			botoes: botoes
		})

		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_005"), valor: exibicaoDadosUsuarioLiberacaoTO.nomeUsuario})
		if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.isMasculino))
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_006"), valor: exibicaoDadosUsuarioLiberacaoTO.isMasculino ? this.getMsg("FP_FRONT_FiscalizacaoVH_007") : this.getMsg("FP_FRONT_FiscalizacaoVH_008")});
		if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.telefone))
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_009"), valor: exibicaoDadosUsuarioLiberacaoTO.telefone});
		if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.dataNascimento))
			try {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_010"), valor: UtilData.toDDMMYYYY(exibicaoDadosUsuarioLiberacaoTO.dataNascimento)})
			} catch (err) {
				console.log(this.getMsg("FP_FRONT_FiscalizacaoVH_011"), err);
				this.logger.error(this.getMsg("FP_FRONT_FiscalizacaoVH_011"), err);
			}
		if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.cpf))
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_012"), valor: exibicaoDadosUsuarioLiberacaoTO.cpf})
		if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.rg))
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_013"), valor: exibicaoDadosUsuarioLiberacaoTO.rg})
		if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.endereco))
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_014"), valor: exibicaoDadosUsuarioLiberacaoTO.endereco})
		if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.cidade))
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_015"), valor: exibicaoDadosUsuarioLiberacaoTO.cidade})
		if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.cep))
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_FiscalizacaoVH_016"), valor: exibicaoDadosUsuarioLiberacaoTO.cep})

		if (!exibicaoDadosUsuarioLiberacaoTO.isLiberado || this.hasValue(exibicaoDadosUsuarioLiberacaoTO.observacaoAusencia)) {
			this.addTextArea({ 
				id: "agendamento_obs_ausencia", 
				label: this.getMsg("FP_FRONT_FiscalizacaoVH_017"), 
				valor: exibicaoDadosUsuarioLiberacaoTO.observacaoAusencia, 
				classe: "col-md-12",
				cssTextarea: "min-height: 100px",
				cssTextareaWrapper: "width: 100%",
			});
		}

		if (!exibicaoDadosUsuarioLiberacaoTO.isCadernoPreGerado && exibicaoDadosUsuarioLiberacaoTO.codProvaFeita) {
			this.addTexto(`<i>${this.getMsg("FP_FRONT_FiscalizacaoVH_022")}</i>`)

		} else if (this.hasValue(exibicaoDadosUsuarioLiberacaoTO.observacaoAusencia)) {
			this.addTexto(`<i>${this.getMsg("FP_FRONT_FiscalizacaoVH_018")}</i>`)

		} else if (exibicaoDadosUsuarioLiberacaoTO.isLiberado) {
			this.addTexto(`<i>${this.getMsg("FP_FRONT_FiscalizacaoVH_019")}</i>`)

		} else {
			this.addTexto(`<i>${this.getMsg("FP_FRONT_FiscalizacaoVH_020")}</i>`)
		}

		this.exibirPopups()
	}
}

const fiscalizacaoVH = new FiscalizacaoVH();

