class CadastroCriteriosCorrecaoVH extends AmaisVH {

	constructor() {
		super(CadastroCriteriosCorrecaoVH.name);
		this.addOperacaoParaHash("ccclc", this.listarCriterios);
		this.addOperacaoParaHash("cccec", this.editarCriterio);	
	}

	idsNovosCriterios = -10;

	async listarCriterios() {

		UtilHash.registrarHistorico(this.listarCriterios);

		const listListagemCriteriosCorrecaoTO = await this.call("CadastroCriteriosCorrecaoFCD/listarCriterios");

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_001"));

		this.addBotao({ label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_011")}`, css: "float: right", onClick: () => this.editarCriterio(), classe: "btn-success btn-sm" });

		const colunas: ColunaAddTabela[] = [{
			titulo: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_002"), 
			prop: "nome"
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_003"), 
			prop: "pontuacaoMaxima", 
			formato: "numero"
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_022"),
			formato: "numero",
			prop: (cc) => {
				if (!cc.quantidadeQuestoes) return 0;

				let filtroDisciplina = {
					codigo: cc.id,
					tipo: "CC",
					nome: cc.nome
				};
				let hashQuestoes = UtilHash.getHash(cadastroQuestaoVH.exibirBuscaQuestoes, encodeURIComponent(JSON.stringify([filtroDisciplina])));

				return this.addBotao({
					label: cc.quantidadeQuestoes,
					href: hashQuestoes,
					abrirNovaPagina: true,
					retornarHtml: true
				});
			},			
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_024"),
			prop: "possuiRespostas"
		}];

		await this.addTabela({
			collection: listListagemCriteriosCorrecaoTO,
			colunas: colunas,
			propId: "id",
			onEdicao: async (id) => await cadastroCriteriosCorrecaoVH.editarCriterio(id),
		});

		this.exibir();
	}

	async editarCriterio(idCriterio?) {
	
		UtilHash.registrarHistorico(this.editarCriterio, idCriterio);

		const exibicaoEdicaoCriterioCorrecaoTO = await this.call("CadastroCriteriosCorrecaoFCD/recuperar", idCriterio);

		let edicaoCriterioCorrecaoTO = exibicaoEdicaoCriterioCorrecaoTO.edicaoCriterioCorrecaoTO;
		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_006"));
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_007"));

		if (edicaoCriterioCorrecaoTO.possuiRespostas) {
			this.addTextoAlerta({ 
				texto: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_025"),
				css: "width: 100%; text-align: center",
			});
		}

		this.addCampoTexto({ 
			id: "nome", 
			label: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_008"), 
			valor: edicaoCriterioCorrecaoTO.nome,
			habilitado: !edicaoCriterioCorrecaoTO.possuiRespostas
		});
		this.addCampoTexto({ 
			id: "formula-" + edicaoCriterioCorrecaoTO.id, 
			label: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_009"), 
			valor: edicaoCriterioCorrecaoTO.formula ,
			habilitado: !edicaoCriterioCorrecaoTO.possuiRespostas
		});
		this.addCampoTexto({ 
			id: "pontuacaoMaxima", 
			tipo: "NUMERO", 
			label: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_010"), 
			valor: edicaoCriterioCorrecaoTO.pontuacaoMaxima, 
			habilitado: false 
		});

		this.append(`
			<table id="table_edicao_grade" class="table table-hover">
				<thead>
					<tr>
						<th width="2%"></th>
						<th width="40%">${this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_012")}</th>
						<th width="15%">${this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_013")}</th>
						<th width="10%">${this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_020")}</th>
						<th width="30%">${this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_014")}</th>
						<th width="10%">${this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_015")}</th>
						<th width="3%">
					</th>
				</thead>
				<tbody>
		`);

		for (let edicaoCriterioCorrecaoTOFilho of edicaoCriterioCorrecaoTO.filhos) {
			this.append(await this.criarTrEdicaoCriterioCorrecao(exibicaoEdicaoCriterioCorrecaoTO, edicaoCriterioCorrecaoTOFilho, edicaoCriterioCorrecaoTO.id));
		}

		this.append(`
				</tbody>
			</table>
			<div class="btn-group">
		`);

		this.addBotao({
			label: "<i class='fa fa-plus-circle'></i> " + this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_026"), 
			visivel: !edicaoCriterioCorrecaoTO.possuiRespostas,
			onClick: async () => {
				let novoEdicaoCriterioCorrecaoTOGrupo = {
					id: this.idsNovosCriterios--,
					nome: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_016"),
					numerador: "0",
					tipo: "FORMULA",
					pontuacaoMaxima: 0,
					filhos: []
				}
				let h = await this.criarTrEdicaoCriterioCorrecao(exibicaoEdicaoCriterioCorrecaoTO, novoEdicaoCriterioCorrecaoTOGrupo, edicaoCriterioCorrecaoTO.id);
				$("table tbody").append(h);
				$(document).trigger("atualizar-grade");
				this.inserirNumeradorDoCriterioNaFormulaDoPai(novoEdicaoCriterioCorrecaoTOGrupo.id, edicaoCriterioCorrecaoTO.id);
			}
		})

		this.addBotao({
			label: "<i class='fa fa-plus-circle'></i> Item", 
			visivel: !edicaoCriterioCorrecaoTO.possuiRespostas,
			onClick: async () => {
				let idUltimoGrupo = $("tr[id-criterio-correcao-pai='" + edicaoCriterioCorrecaoTO.id + "']:last").attr("id-criterio-correcao");
				let novoEdicaoCriterioCorrecaoTOFolha = {
					id: this.idsNovosCriterios--,
					nome: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_017"),
					numerador: "z",
					tipo: "PONTUACAO_3",
					pontuacaoMaxima: 1,
					filhos: []
				}
				let h = await this.criarTrEdicaoCriterioCorrecao(exibicaoEdicaoCriterioCorrecaoTO, novoEdicaoCriterioCorrecaoTOFolha, idUltimoGrupo);
				$("table tbody").append(h);
				$(document).trigger("atualizar-grade");
				this.inserirNumeradorDoCriterioNaFormulaDoPai(novoEdicaoCriterioCorrecaoTOFolha.id, idUltimoGrupo);
			}
		});

		this.append(`
			</div>
			<div class="btn-group pull-right">
		`);

		this.addBotao({
			id: "botao_excluir_criterio",
			label: `<span class='text-danger'><i class='fas fa-trash'></i> ${this.getMsg("MSG_VH_006")} </span>`,
			onClick: async () => {
				await this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_023"), 
					botoes: [{
						label: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_005"), 
						classe: "btn-danger", 
						onClick: async () => {
							await this.call("CadastroCriteriosCorrecaoFCD/excluir", idCriterio);
							this.listarCriterios();
						}
					}]
				});
			}
		});

		this.addBotao({
			id: "botao_salvar_criterio",
			label: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_018"),
			classe: "btn-primary",
			visivel: !edicaoCriterioCorrecaoTO.possuiRespostas,
			onClick: async () => {
				let pontuacaoMaxima = Number(this.getValor("pontuacaoMaxima"));

				let salvamentoCriterioTO = {
					id: edicaoCriterioCorrecaoTO.id,
					nome: this.getValor("nome"),
					formula: this.getValor("formula-" + edicaoCriterioCorrecaoTO.id),
					pontuacaoMaxima: pontuacaoMaxima,
					filhos: []
				};

				$("tr[id-criterio-correcao-pai='" + edicaoCriterioCorrecaoTO.id + "']").each((i, tr) => {

					let idGrupo = $(tr).attr("id-criterio-correcao");
					let pontuacaoMaximaGrupo = this.getValor("pontuacao-maxima-" + idGrupo);

					let salvamentoCriterioTOGrupo = {
						id: idGrupo,
						nome: $(tr).find("td[nome] input").val(),
						formula: this.getValor("formula-" + idGrupo),
						numerador: $(tr).find("td[numerador]").text(),
						pontuacaoMaxima: pontuacaoMaximaGrupo,
						filhos: []
					}

					salvamentoCriterioTO.filhos.push(salvamentoCriterioTOGrupo);

					$("tr[id-criterio-correcao-pai='" + idGrupo + "']").each((i, tr) => {
						let idItem = $(tr).attr("id-criterio-correcao");
						let pontuacaoMaximaItem = this.getValor("pontuacao-maxima-" + idItem);
						let tipo = this.getValor("tipo-criterio-" + idItem);
						let numOpcoes = this.getValor("numero-opcoes-" + idItem);

						salvamentoCriterioTOGrupo.filhos.push({
							id: idItem,
							nome: $(tr).find("td[nome] input").val(),
							tipo: tipo,
							pontuacaoMaxima: pontuacaoMaximaItem,
							numOpcoes: numOpcoes,
							numerador: $(tr).find("td[numerador]").text()
						})
					});
				});

				let formulaGrupo = salvamentoCriterioTO.formula;

				let hasFormulaError = false;

				for(const criterio of salvamentoCriterioTO.filhos){
					let formula = criterio.formula;
					formulaGrupo = this.substituirVariavelFormula(formulaGrupo, criterio.numerador, criterio.pontuacaoMaxima);

					for(const criterioFilho of criterio.filhos){
						formula = this.substituirVariavelFormula(formula, criterioFilho.numerador, criterioFilho.pontuacaoMaxima);
					}

					const resultado = this.calcularFormula(formula);
					if(!resultado){
						hasFormulaError = true;
						break;
					}
				}

				const resultadoGrupo = this.calcularFormula(formulaGrupo);

				if(!resultadoGrupo){
					hasFormulaError = true;
				}

				if(hasFormulaError){
					this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_021") });
					return false;
				}

				await this.call("CadastroCriteriosCorrecaoFCD/salvar", salvamentoCriterioTO, null);

				this.listarCriterios();
			}
		});

		this.append(`
			</div>
		`);

		$(document).off("atualizar-grade").on("atualizar-grade", () => {

			this.exibir();

			if (edicaoCriterioCorrecaoTO.possuiRespostas) {
				$("#table_edicao_grade").find("input, select, button, a").each((_, e) => {
					this.disable(e);
				});
			}

			$("td[pontuacao-maxima] input, td[numero-opcoes] input, input[id^='formula-'], td[tipo] select").each((i, input) => {
				if ($(input).data("possui-handler-change")) {
					return;
				}

				$(input).on("change", ({ target }) => {
					this.atualizarOpcoesPontuacao($(target).closest("tr"), exibicaoEdicaoCriterioCorrecaoTO);
					this.atualizarPontuacaoMaxima(edicaoCriterioCorrecaoTO.id);
				});

				$(input).data("possui-handler-change", true);
			});

			$("table i.fa-remove").closest("a").off().on("click", ({ target }) => {
				this.removerCriterio(target);
				setTimeout(() => $(document).trigger("atualizar-grade"), 800);
			});

			$("table td div.form-group").attr("class", "form-group");
			$("table td[pontuacao-maxima] input[type='text']").css("text-align", "center");
			this.atualizarNumeradores(edicaoCriterioCorrecaoTO.id);
			this.atualizarPontuacaoMaxima(edicaoCriterioCorrecaoTO.id);
			$("td[tipo] select").trigger("change");
		});

		$(document).trigger("atualizar-grade");
	}

	async criarTrEdicaoCriterioCorrecao(exibicaoEdicaoCriterioCorrecaoTO, edicaoCriterioCorrecaoTO, idCriterioPai) {
		let isGrupo = (edicaoCriterioCorrecaoTO.tipo === "FORMULA");
		let isPontuacaoN = (edicaoCriterioCorrecaoTO.tipo === "PONTUACAO_N");
		let h = ["<tr id-criterio-correcao='" + edicaoCriterioCorrecaoTO.id + "' " + (isGrupo ? " class='active' " : "")];

		h.push(" id-criterio-correcao-pai='" + idCriterioPai + "'")
		h.push(">");

		h.push("<td numerador style='text-align: right; font-size: 105%; font-weight: bold'>" + edicaoCriterioCorrecaoTO.numerador + "</td>");

		h.push("<td nome style='text-align: left;'><input type='text' style='width: 100%; ");
		if (isGrupo) {
			h.push("font-size: 114%; font-weight: bold");
		}
		h.push("' value='" + edicaoCriterioCorrecaoTO.nome + "'>");
		h.push("</td>")

		h.push("<td tipo>");

		if (isGrupo) {
			h.push("SOMATÓRIO");
		} else {
			h.push(await this.addSelect({
				id: "tipo-criterio-" + edicaoCriterioCorrecaoTO.id,
				collection: JSON.parse(JSON.stringify(exibicaoEdicaoCriterioCorrecaoTO.listaListagemTipoCriterioTOFolhas)),
				valor: edicaoCriterioCorrecaoTO.tipo,
				retornarHtml: true,
				obrigatorio: true,
				css: "padding: 0px; margin: 0px;"
			}));
		}

		h.push("</td>");

		h.push("<td numero-opcoes>");
		if(!isGrupo){
			h.push(this.addCampoTexto({
				id: "numero-opcoes-" + edicaoCriterioCorrecaoTO.id,
				tipo: "NUMERO",
				valor: edicaoCriterioCorrecaoTO.numOpcoes,
				retornarHtml: true,
				css: "margin-bottom: 1px;",
				cssInput: !isPontuacaoN ? "display:none;text-align:center;" : "text-align:center;",
				obrigatorio: isPontuacaoN
			}));
		}
		h.push("</td>");

		h.push("<td opcoes-pontuacao>");
		if (isGrupo) {
			h.push(this.addCampoTexto({
				id: "formula-" + edicaoCriterioCorrecaoTO.id,
				valor: edicaoCriterioCorrecaoTO.formula,
				css: "margin-bottom: 1px;",
				retornarHtml: true
			}));
		}
		h.push("</td>");

		h.push("<td pontuacao-maxima>");
		h.push(this.addCampoTexto({
			id: "pontuacao-maxima-" + edicaoCriterioCorrecaoTO.id,
			tipo: "NUMERO",
			valor: edicaoCriterioCorrecaoTO.pontuacaoMaxima,
			retornarHtml: true,
			css: "margin-bottom: 1px;",
			habilitado: !isGrupo
		}));
		h.push("</td>");

		h.push("<td>");
		h.push(this.addBotao({ label: "<i class='fa fa-remove'></i>", retornarHtml: true, onClick: () => { } }))
		h.push("</td>");

		h.push("</tr>");
		for (let i = 0; i < edicaoCriterioCorrecaoTO.filhos.length; i++) {
			let edicaoCriterioCorrecaoTOFilho = edicaoCriterioCorrecaoTO.filhos[i];
			edicaoCriterioCorrecaoTOFilho.numero = edicaoCriterioCorrecaoTOFilho.numero || i;
			h.push(await this.criarTrEdicaoCriterioCorrecao(exibicaoEdicaoCriterioCorrecaoTO, edicaoCriterioCorrecaoTOFilho, edicaoCriterioCorrecaoTO.id));
		}

		return h.join("");
	}

	buildLabelCriterios(valor, opcao){
		return `
			<label class="btn btn-default btn-label-criterios">
				<input type="radio" autocomplete="off" name="${valor}" id="${valor}" value="${valor}"/>
				${opcao}
			</label>
		`;
	}

	atualizarOpcoesPontuacao(tr, exibicaoEdicaoCriterioCorrecaoTO) {
		let idCriterio = $(tr).attr("id-criterio-correcao");
		let tipoCriterio = $("#tipo-criterio-" + idCriterio).val() || "FORMULA";
		let pontuacaoMaxima = this.getValor("pontuacao-maxima-" + idCriterio) || 1;
		let numOpcoes = this.getValor("numero-opcoes-" + idCriterio) || 3;
		let h = [];

		if (tipoCriterio === "FORMULA") {
			$(tr).find("td[numero-opcoes] input").val("").hide();
			return;
		}
		else if (tipoCriterio === "VALOR") {
			h.push(this.getMsg("FP_FRONT_CadastroCriteriosCorrecaoVH_019"));
			$(tr).find("td[pontuacao-maxima] input").val("").hide();
			$(tr).find("td[numero-opcoes] input").val("").hide();
		}
		else if (tipoCriterio === "PONTUACAO_N") {
			$(tr).find("td[numero-opcoes] input").val(numOpcoes).show();
			$(tr).find("td[pontuacao-maxima] input").val(UtilNumero.floatToString(pontuacaoMaxima)).show();

			const divisao = pontuacaoMaxima / (+numOpcoes-1);
			let array_opcoes = [0];

			for(let i = 1; i < numOpcoes; i++){
				let opcao = array_opcoes[i - 1] + divisao;
				array_opcoes.push(opcao);
			}

			h.push('<div class="btn-group btn-opcoes-criterios" data-toggle="buttons">');

			for (const opcao of array_opcoes) {
				h.push(this.buildLabelCriterios(opcao, UtilNumero.floatToString(opcao)));
			}

			h.push('</div>');
		}
		else {
			$(tr).find("td[numero-opcoes] input").val("").hide();
			$(tr).find("td[pontuacao-maxima] input").val(UtilNumero.floatToString(pontuacaoMaxima)).show();

			for (const listagemTipoCriterioTO of exibicaoEdicaoCriterioCorrecaoTO.listaListagemTipoCriterioTOFolhas) {

				if (listagemTipoCriterioTO.id !== tipoCriterio) continue;

				h.push('<div class="btn-group btn-opcoes-criterios" data-toggle="buttons">');

				for (const opcaoListaTOPontuacoes of listagemTipoCriterioTO.listaOpcaoListaTOPontuacoes) {
					let valorLabel;

					if (pontuacaoMaxima > 0) {
						valorLabel = UtilNumero.floatToString(pontuacaoMaxima * opcaoListaTOPontuacoes.id);
					}
					else {
						valorLabel = UtilNumero.floatToString(opcaoListaTOPontuacoes.id * 100) + "%";
					}
					h.push(this.buildLabelCriterios(opcaoListaTOPontuacoes.id, valorLabel));
				}

				h.push('</div>');
			}
		}

		$(tr).find("[opcoes-pontuacao]").html(h.join(""));
	}

	atualizarPontuacaoMaxima(idCriterio) {
		let contador = 0;
		let numFilhos = $("tr[id-criterio-correcao-pai='" + idCriterio + "']").length;
		let formula = $("#formula-" + idCriterio + "").val();

		$("tr[id-criterio-correcao-pai='" + idCriterio + "'] td[pontuacao-maxima] input").each((i, input) => {
			let tr = $(input).closest("tr");
			let idCriterioFilho = tr.attr("id-criterio-correcao");
			this.atualizarPontuacaoMaxima(idCriterioFilho);
			let pontuacaoMaximaFilho = UtilNumero.stringToFloat($("#" + input.id).val());
			contador++;
			let numerador = $(tr).find("td[numerador]").text();
			formula = this.substituirVariavelFormula(formula, numerador, pontuacaoMaximaFilho);
		});

		if (numFilhos) {

			let pontuacaoMaxima: number | string = this.calcularFormula(formula);

			if (pontuacaoMaxima === null) {
				this.marcarInputComErro($("#formula-" + idCriterio + ""));
			}
			else {
				this.removerMarcacaoDeErroDeInput($("#formula-" + idCriterio + ""));
			}

			if (pontuacaoMaxima !== null) {
				if ($("tr[id-criterio-correcao='" + idCriterio + "'").length) {
					pontuacaoMaxima = (contador ? UtilNumero.floatToString(pontuacaoMaxima).replace(".", "") : "");
					$("tr[id-criterio-correcao='" + idCriterio + "'] td[pontuacao-maxima] input").val(pontuacaoMaxima);
				} else {
					this.setValor("pontuacaoMaxima", UtilNumero.floatToString(pontuacaoMaxima).replace(".", ""));
				}
			}
		}
	}

	removerCriterio(link) {
		let tr = $(link).closest("tr");
		let idCriterio = $(tr).attr("id-criterio-correcao");

		$("[id-criterio-correcao='" + idCriterio + "'], [id-criterio-correcao-pai='" + idCriterio + "']").remove();
	}

	atualizarNumeradores(idCriterioRaiz) {
		let numeradorGrupo = 1;
		$("tr[id-criterio-correcao-pai='" + idCriterioRaiz + "']").each(function () {
			$(this).find("td[numerador]").html(numeradorGrupo++);
			let idCriterioGrupo = $(this).attr("id-criterio-correcao");
			let numeradorItem = "a";

			$("tr[id-criterio-correcao-pai='" + idCriterioGrupo + "']").each(function () {
				$(this).find("td[numerador]").html(numeradorItem);
				numeradorItem = String.fromCharCode(numeradorItem.charCodeAt(0) + 1);
			});
		});
	}

	substituirVariavelFormula(formula, param, valor) {
		return formula.replace("[" + param + "]", valor);
	}

	calcularFormula(formula): number {
		try {
			let valorFormula = eval(formula);
			if (!(typeof valorFormula === "number")) {
				throw "Fórmula inválida";
			}
			if (Number.isFinite(valorFormula)) {
				return valorFormula;
			} else {
				return 0;
			}
		} catch (e) {
			return null;
		}
	}

	inserirNumeradorDoCriterioNaFormulaDoPai(idCriterio, idCriterioPai) {
		let numeradorAtualizado = $("tr[id-criterio-correcao='" + idCriterio + "'] td[numerador]").text();
		let formula = $("#formula-" + idCriterioPai).val();
		if (this.hasValue(formula)) {
			$("#formula-" + idCriterioPai).val(formula + " + [" + numeradorAtualizado + "]");
		} else {
			$("#formula-" + idCriterioPai).val("[" + numeradorAtualizado + "]");
		}
		$(document).trigger("atualizar-grade");
	}
}

const cadastroCriteriosCorrecaoVH = new CadastroCriteriosCorrecaoVH();