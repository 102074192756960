class PublicacaoMaterialVH extends AmaisVH {
	
	collectionExibicaoMaterialPublicadoTOUltimosPosts: any;
	buscaTextual: any;
	numeroDaPagina: any;
	codTurma: number;
	materialPublicadoTO: any;

	constructor() {
		super(PublicacaoMaterialVH.name);
		this.addOperacaoParaHash("ctem", this.editarMaterial);
	}

	exibirUltimosMateriais(collectionExibicaoMaterialPublicadoTOUltimosPosts, collectionListagemPastaMaterialTO) {
		
		if (collectionExibicaoMaterialPublicadoTOUltimosPosts.length == 0 && collectionListagemPastaMaterialTO.length == 0) {
			return;
		}
		
		this.append("<div class='col-md-12'>");
		this.addSubtitulo(this.getMsg("MSG_VH_PM_01"));
		
		this.append("<div class='input-group' style='width: 50%; float: right'>"); 
		this.append("<input id='buscaMateriais' type='text' class='form-control'>"); 
		this.append("<span class='input-group-btn'><button class='btn btn-default'><i class='fa fa-search'></i></button></span>");
		this.append("</div>");

		this.appendJs(() => this.listenToEnter("#buscaMateriais", async () => {
			await publicacaoMaterialVH.buscarPorTexto();
		}));
		
		this.addEspacamentoHorizontal("1px");


		this.append("<div class='col-md-6'>");
		this.addSubsubtitulo(this.getMsg("MSG_VH_PM_02"));
		
		this.append("<div class='list-group'>")
		
		for (const listagemPastaMaterialTO of collectionListagemPastaMaterialTO) {
			this.append("<a class='list-group-item' onclick='publicacaoMaterialVH.exibirMateriaisPastas("+listagemPastaMaterialTO.idPasta+")'><i class='fa fa-folder'></i> ")
			this.append(listagemPastaMaterialTO.nomePasta)
			this.append("<span class='badge pull-right'>")
			this.append(listagemPastaMaterialTO.quantidade) 
			this.append("</span></a>")
		}	

		this.append("</div>")
		this.append("</div>");
		
		this.append("<div class='col-md-6'>");
		
		this.append("<div id='tabela_materiais'>");
		this.addSubsubtitulo(this.getMsg("MSG_VH_PM_03"));
		this.append("<ul class='list-group'>");
		this.exibirTabela(collectionExibicaoMaterialPublicadoTOUltimosPosts);
		this.append("</ul>");
		this.append("</div>")
		this.append("</div>")
		
		this.append("</div>");
		
		this.exibir();
		this.collectionExibicaoMaterialPublicadoTOUltimosPosts = collectionExibicaoMaterialPublicadoTOUltimosPosts
	}
	
	async buscarPorTexto() {
		this.buscaTextual = this.getValor("buscaMateriais");
		
		if (this.isEmpty(this.buscaTextual)) {
			this.setHtml("tabela_materiais", "");
			this.setIdTarget("tabela_materiais");
			await this.show("subtitulo_ultimos_posts");
			this.exibirTabela(this.collectionExibicaoMaterialPublicadoTOUltimosPosts);
			this.exibir();
			return;
		}
		await this.hide("subtitulo_ultimos_posts");

		const collectionExibicaoMaterialPublicadoTOBusca = await this.call("PublicacaoMaterialFCD/buscarMaterial", this.buscaTextual);

		this.setHtml("tabela_materiais", "");
		this.setIdTarget("tabela_materiais");
		this.exibirTabela(collectionExibicaoMaterialPublicadoTOBusca);
		this.exibir();
	}
	
	exibirTabela(collectionExibicaoMaterialPublicadoTO){
		
		for (var i = 0; i < collectionExibicaoMaterialPublicadoTO.length; i++) {
			
			var exibicaoMaterialPublicadoTO = collectionExibicaoMaterialPublicadoTO[i]
			var idade = UtilData.getTempoUserFriendly(exibicaoMaterialPublicadoTO.dataPublicacao)

			this.append("<li class='list-group-item'><p><strong>")
			this.append(exibicaoMaterialPublicadoTO.titulo);
			this.append("</strong><small class='pull-right'>(" + this.getMsg("MSG_VH_PM_04") + " " + idade + ")</small></p><p><a href='")
			this.append(exibicaoMaterialPublicadoTO.nomeArquivo)
			this.append("' target='_blank'>")
			this.append(UtilArquivo.getNomeArquivoSemHifens(exibicaoMaterialPublicadoTO.nomeArquivo))
			this.append("</a></p><p><a onclick='publicacaoMaterialVH.exibirMateriaisPastas(" + exibicaoMaterialPublicadoTO.idPasta + ")'><i class='fa fa-folder'></i> ")
			this.append(exibicaoMaterialPublicadoTO.nomePasta);
			this.append("</a></p><p><a onclick='publicacaoMaterialVH.exibirMateriaisProfessor(" + exibicaoMaterialPublicadoTO.codProfessor + ")'><i class='fa fa-user'></i> ")
			this.append(exibicaoMaterialPublicadoTO.nomeProfessor);
			this.append("</a></p>")
			this.append("</li>")
		}
	} 
	
	async exibirMateriaisPastas(idPasta) {

		const exibicaoMaterialDaPastaTO = await this.call("PublicacaoMaterialFCD/recuperarMateriaisDaPasta", idPasta);
	
		this.addPopup({
			id: "exibicaoMateriaisPasta", 
	        titulo: exibicaoMaterialDaPastaTO.nomePasta,
	        width: "850px",
	        // height: "500px"
		});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_PM_05"), prop: "titulo"});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_06"), prop: this.nomeAlterado});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_07"), prop:"nomeProfessor"});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_08"), prop: this.montarTempoMaisAntigo});

		await this.addTabela({
			collection: exibicaoMaterialDaPastaTO.collectionExibicaoMaterialPublicadoTO,
			propId: "idPasta",
			colunas: colunas,
			ordenar: false
		});
		
		this.exibirPopups();

    }
	
	montarTempoMaisAntigo(exibicaoMaterialDaPastaTO) {
		return UtilData.getTempoUserFriendly(exibicaoMaterialDaPastaTO.dataPublicacao)
	}
	
	nomeAlterado(exibicaoMaterialDaPastaTO) {
		return " <a href='"
			 + exibicaoMaterialDaPastaTO.nomeArquivo
			 + "' target='_blank'>"
			 + UtilArquivo.getNomeArquivoSemHifens(exibicaoMaterialDaPastaTO.nomeArquivo)
			 + "</a>";
	}
	
	async exibirMateriaisProfessor(codProfessor){
		
		const exibicaoMaterialDoProfessorTO = await this.call("PublicacaoMaterialFCD/recuperarMateriaisDoProfessor", codProfessor);
		
		this.addPopup({
			id: "exibicaoMateriaisProfessor", 
	        titulo: exibicaoMaterialDoProfessorTO.nomeProfessor,
	        width: "850px",
	        // height: "500px"
		})

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_PM_05"), prop: "titulo"});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_06"), prop: this.nomeAlterado});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_07"), prop:"nomePasta"});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_08"), prop: this.montarTempoMaisAntigo});
		
		await this.addTabela({
			collection: exibicaoMaterialDoProfessorTO.collectionExibicaoMaterialPublicadoTO,
			propId: "codProfessor",
			colunas: colunas,
			ordenar: false
		});

		this.exibirPopups();
	}
	
	async listarTodosMateriaisPublicados(codTurma?: number) {
		
		var filtrosListagemMaterialPublicadoTO = {
			numeroDaPagina: this.numeroDaPagina,
			buscarMateriais: this.getValor("buscar_materiais"),
			codTurma: codTurma,
			inicioPublicacao: this.getValor("periodo_inicio"),
			fimPublicacao: this.getValor("periodo_fim"),
		};
		
		const exibicaoMaterialPublicadoPorTurmaTO = await this.call("PublicacaoMaterialFCD/listarMaterial", filtrosListagemMaterialPublicadoTO);
	
		this.limpar(true);
		
		this.setTitulo(this.getMsg("MSG_VH_PM_09"));
		
		this.addCampoTexto({
			label: this.getMsg("MSG_VH_PM_10"), 
			id: "buscar_materiais", 
			onEnter: async () => await this.buscarMateriaisPublicados(),
			valor: filtrosListagemMaterialPublicadoTO.buscarMateriais,
			classe: "col-md-3"
		});
		
		this.addBotao({label: "<i class='fa fa-plus-circle'></i> " + this.getMsg("MSG_VH_PM_11"), onClick: () => this.editarMaterial(), classe: "btn-mini btn-success", css: "float: right"});
		
		this.addCamposPeriodo({
			label: this.getMsg("MSG_VH_PM_12"),
			idInicio: "periodo_inicio",
			idFim: "periodo_fim",
			onChange: async () => await this.listarTodosMateriaisPublicados(),
			valorInicio: UtilData.toDDMMYYYY(filtrosListagemMaterialPublicadoTO.inicioPublicacao),
			valorFim: UtilData.toDDMMYYYY(filtrosListagemMaterialPublicadoTO.fimPublicacao),
		});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_PM_13"), prop: async (materialPublicadoTO) => {
				return UtilArquivo.getNomeArquivoSemHifens(materialPublicadoTO.nomeArquivo);
			}
		});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_14"), prop: "nomePasta"});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_15"), prop: "nomeProfessor"});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_16"), prop: "dataPublicacao", formato: "DD/MM/YY HH:mm"});
		
		await this.addTabela({
			collection: exibicaoMaterialPublicadoPorTurmaTO.collectionMaterialPublicadoTO,
			propId: "idMaterial",
			colunas: colunas,
			onEdicao: this.editarMaterial,
			msgListaVazia: this.getMsg("MSG_VH_PM_17"),
			numTotalItensPaginacao: exibicaoMaterialPublicadoPorTurmaTO.numTotalMateriaisPublicados,	
			onCarregarPagina: async (paginacaoTO) => {
	
				this.numeroDaPagina = paginacaoTO.numPaginaAtual;
				this.buscaTextual = this.getValor("buscar_materiais");
				
				var collectionDeMateriaisPublicados = await this.call("PublicacaoMaterialFCD/listarPaginaMaterial", this.numeroDaPagina, this.buscaTextual); 
				
				return collectionDeMateriaisPublicados;
			},
			paginaAtiva: this.numeroDaPagina,
			ordenar: false
		});
		
		this.exibir();
		this.codTurma = codTurma;
	};
	
	async editarMaterial(idMaterial?: number) {

		UtilHash.registrarHistorico(this.editarMaterial, idMaterial);
		
		const materialPublicadoTO = await this.call("PublicacaoMaterialFCD/recuperarMaterialPublicado", idMaterial);
		
		this.limpar();
		
		this.setTitulo(materialPublicadoTO.tituloMaterial);
		this.addSubtitulo(this.getMsg("MSG_VH_PM_18"));
		
		await this.addSelect({
			collection: materialPublicadoTO.collectionItemListaTOPastas,
			id: "pasta_material",
			label: this.getMsg("MSG_VH_PM_19"),
			valor: materialPublicadoTO.codPasta
		});
		
		await this.addSelect({
			collection: materialPublicadoTO.collectionItemListaTOProfessores,
			id: "nomeProfessor_material",
			label: this.getMsg("MSG_VH_PM_20"),
			valor: materialPublicadoTO.codProfessor
		});
		
		if(materialPublicadoTO.collectionItemListaTOTurmas.length > 0) {
			await this.addSelect({
				collection: materialPublicadoTO.collectionItemListaTOTurmas,
				id: "turma_material",
				label: this.getCfg("LABEL_TURMA"),
				valor: materialPublicadoTO.codTurma
			});
		}
		
		if(materialPublicadoTO.nomeArquivo != null) {
			this.append(this.getMsg("MSG_VH_PM_21") + ": "); 
			this.append("<a href='" + materialPublicadoTO.nomeArquivo);
			this.append("' target='_blank' style='color: #2244BB;'>");
			this.append(UtilArquivo.getNomeArquivoSemHifens(materialPublicadoTO.nomeArquivo));
			this.append("</a></span><br> ");
			
		} else {
			this.addZonaUpload({id: "nomeArquivoUpload", label: this.getMsg("MSG_VH_PM_22"), /*maxArquivos: 1*/});
		}
		
		var tituloMaterialPublicado;
		if(materialPublicadoTO.codMaterial == null) {
			tituloMaterialPublicado = "";
		} else {
			tituloMaterialPublicado = materialPublicadoTO.tituloMaterial;
		}
		
		this.addCampoTexto({id: "titulo_material", label: this.getMsg("MSG_VH_PM_23"), valor: tituloMaterialPublicado, css: "margin-top: 14px"});
		
		this.addEspacamentoHorizontal("1px");
		
		this.addBotao({
			label: "&laquo; " + this.getMsg("MSG_VH_PM_24"), 
			onClick: async () => await cadastroTurmaVH.listar(1),
			css: "float: left;",
			classe: "btn-mini" 	
		})
		
		this.addBotao({
			label: this.getMsg("MSG_VH_PM_25") + " &raquo;", 
			onClick: () => this.salvarMaterialPublicado(),
			css: "float: right;",
			classe: "btn-mini btn-primary" 
		});
		
		this.addBotao({
			label: this.getMsg("MSG_VH_PM_26"), 
			onClick: () => this.exibirListagemPastas(),
			css: "float: right;",
			classe: "btn-mini" 
		});
		
		if(materialPublicadoTO.codMaterial != null) {
			this.addBotao({
				label: this.getMsg("MSG_VH_PM_27"), 
				onClick: () => this.excluirMaterialPublicado(materialPublicadoTO.codMaterial),
				css: "float: right;",
				classe: "btn-mini btn btn-danger" 
			});
		}
		
		this.append("<div id='cadastroPasta' class='col-sm-12'></div>");
		
		this.exibir();
		this.materialPublicadoTO = materialPublicadoTO;
	}
	
	async salvarMaterialPublicado() {
		
		var validacoes;
		
		if(this.materialPublicadoTO.codMaterial == null) {
			validacoes = ["pasta_material", "nomeProfessor_material", "titulo_material", "nomeArquivoUpload"];
		} else {
			validacoes = ["pasta_material", "nomeProfessor_material", "titulo_material"]; 
		}
		
		if (this.validarCamposObrigatorios(validacoes)) {
			
			var salvamentoMaterialPublicadoTO = {
				codMaterial: this.materialPublicadoTO.codMaterial,	
				codTurma: this.getValor("turma_material"),
				codPasta: this.getValor("pasta_material"),
				codProfessor: this.getValor("nomeProfessor_material"),
				tituloMaterial: this.getValor("titulo_material"),
				isEditar: this.materialPublicadoTO.codMaterial == null ? false : true,
				pathArquivoUpload: this.materialPublicadoTO.codMaterial != null ? null : this.getValor("nomeArquivoUpload"),		
			};
			
			salvamentoMaterialPublicadoTO = await this.call("PublicacaoMaterialFCD/salvarMaterialPublicado", salvamentoMaterialPublicadoTO)
			
			if(salvamentoMaterialPublicadoTO.isEditar == false) {
				this.editarMaterial(salvamentoMaterialPublicadoTO.codMaterial);
			} else {
				cadastroTurmaVH.listar(1);
			}
		}
	}
	
	async excluirMaterialPublicado(codMaterial) {
		this.exibirAlerta({
			msg: this.getMsg("MSG_VH_PM_28"),
			botoes: [
				{
					label: this.getMsg("MSG_VH_004"),
					classe: "btn-primary",
					onClick: async () => {
						await this.call("PublicacaoMaterialFCD/excluirMaterialPublicado", codMaterial);
						this.listarTodosMateriaisPublicados();
					}
				},
				{label: this.getMsg("MSG_VH_005"),}
			],
		});
	};
	
	buscarMateriaisPublicados() {
		this.numeroDaPagina = null;
		this.listarTodosMateriaisPublicados(this.codTurma);
	}
		
	async exibirListagemPastas() {

		const listagemPastasTO = await this.call("PublicacaoMaterialFCD/listarPastas");
	
		this.setHtml("cadastroPasta", "");
		this.setIdTarget("cadastroPasta");
		
		this.addSubtitulo(this.getMsg("MSG_VH_PM_29"));
		
		this.addBotao({label: "<i class='fa fa-plus-circle'></i> " + this.getMsg("MSG_VH_PM_30"), onClick: () => this.exibirEdicaoPasta(), classe: "btn-mini btn-success", css: "float: right"});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: "nome"});
		colunas.push({titulo: this.getMsg("MSG_VH_PM_11"), prop: "numMateriais"});

		await this.addTabela({
			collection: listagemPastasTO,
			propId: "idPasta",
			colunas: colunas,
			onEdicao: async (idPasta) => await this.exibirEdicaoPasta(idPasta)
		})
		
		this.exibir();
		this.setIdTarget(null);
		UtilWindow.scrollTo("#cadastroPasta")
	}

	async exibirEdicaoPasta(idPasta?: number) {
		
		const prosseguir = (edicaoPastaTO) => {
			this.addPopup({
				id: "edicaoPasta", 
				titulo: edicaoPastaTO.nome,
				width: "450px",
				// height: "300px",
				botoes:[{
					label: this.getMsg("MSG_VH_PM_32"), 
					onClick: async () => {

						await this.call("PublicacaoMaterialFCD/salvarPasta", edicaoPastaTO.idPasta, this.getValor("nomePasta"));

						this.exibirListagemPastas();

						this.updateSelect({
							id: "pasta_material",
							collection: await this.call("PublicacaoMaterialFCD/getListaPastas")
						})

						return true;
					}
				}]
			});
	
			this.addCampoTexto({id: "nomePasta", label: this.getMsg("MSG_VH_PM_33"), valor: edicaoPastaTO.nome});
			
			this.exibirPopups();
		}
		
		if (!idPasta) {
			prosseguir({
				nome: this.getMsg("MSG_VH_PM_31") 
			})
		} else {
			const edicaoPastaTO = await this.call("PublicacaoMaterialFCD/recuperarPasta", idPasta);
			prosseguir(edicaoPastaTO);
		}
	}
}

const publicacaoMaterialVH = new PublicacaoMaterialVH();