class CadastroDisciplinaVH extends AmaisVH {

	onDisciplinaSalva: Function;
	isEdicaoEmPopup: boolean;

	constructor() {
		super(CadastroDisciplinaVH.name);
		this.addOperacaoParaHash("cdlc", this.listarClassificacoes);
		this.addOperacaoParaHash("cdec", this.editarClassificacao);
	}

	async listarClassificacoes(codDisciplinaPai?: number) {

		if (!document.getElementById("div-listagem-classificacoes")) {

			this.limpar();

			this.setTitulo(this.getMsg("FP_FRONT_CadastroDisciplinaVH_001"));
	
			this.setIdTarget("tituloSuperiorDireito");
			this.append("<div class='btn-group' style='float: right'>");
			
			if (this.getIsAmbienteImportacaoBancoQuestoes() && UtilAuth.possuiAcesso(TipoFuncionalidade.DISCIPLINA_ASSOCIACAO_EQUIVALENCIAS)) {
				this.addBotao({ 
					label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_003"), 
					onClick: () => this.exibirAssociacao()
				});
			}
	
			if (this.getIsAmbienteImportacaoBancoQuestoes()) {
				this.addBotao({ 
					label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_002"), 
					onClick: () => relatoriosImportacaoVH.exibirRelatorioImportacaoPorDisciplina() 
				});
			}
	
			if (this.isAdministrador()) {
				this.addBotao({
					label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_044"),
					onClick: () => {
						this.editarTiposObrigatorios();
					}
				});
			}
	
			this.addBotao({
				id: "btnNovaClassificacao",
				label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroDisciplinaVH_004")}`, 
				classe: "btn-success", 
				onClick: () => {
					this.editarClassificacao(null);
				}
			});

			this.addBotaoBusca("filtrosClassificacoes");
	
			this.append("</div>");
			this.setIdTarget(null);

			this.append("<div id='filtrosClassificacoes' style='display: none'>");
			this.abrirAbaAccordion({
				titulo: this.getMsg("MSG_VH_001"), aberta: true
			});
			this.addFormulario();

			const listagemTiposClassificacoes = await this.call("CadastroDisciplinaFCD/recuperarTiposClassificacoes");
			
			listagemTiposClassificacoes.forEach(to => to.descricao = to.nomeSegmento);

			await this.addSelect({
				collection: listagemTiposClassificacoes,
				id: "codTipoClassificacao",
				propId: "codTipoClassificacao",
				dica: this.getMsg("FP_FRONT_CadastroDisciplinaVH_011"),
				classe: "col-xs-12 col-md-6 col-lg-4",
				onChange: () => this.listarClassificacoes(codDisciplinaPai)
			});
			
			this.addCampoTexto({
				id: "busca_disciplinas",
				sufixo: "<i class='fa fa-sm fa-search'></i> ",
				dica: this.getMsg("MSG_VH_002"),
				valor: "",
				classe: "col-xs-12 col-md-6 col-lg-4",
				onEnter: () => this.listarClassificacoes(codDisciplinaPai)
			});
	
			this.addCamposPeriodo({
				idInicio: "inicioPeriodoCadastroBusca",
				idFim: "fimPeriodoCadastroBusca",
				dica: this.getMsg("FP_FRONT_CadastroTurmaVH_119"),
				onChange: () => this.listarClassificacoes(codDisciplinaPai)
			});

			this.addCamposPeriodo({
				idInicio: "inicioPeriodoAtualizacaoBusca",
				idFim: "fimPeriodoAtualizacaoBusca",
				dica: this.getMsg("FP_FRONT_CadastroTurmaVH_118"),
				onChange: () => this.listarClassificacoes(codDisciplinaPai)
			});

			this.addCheckbox({ 
				label: this.getMsg("MSG_VH_CU_21"), 
				id: "isExibirArquivados", 
				onChange: () => this.listarClassificacoes(codDisciplinaPai)
			});

			this.fecharFormulario();
			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();
			this.append("</div>");

			this.append("<div id='div-listagem-classificacoes'></div>");
	
			this.exibir();
		}

		const filtrosListagemClassificacaoTO: FiltrosListagemClassificacaoTO = {
			paginacaoTO: null,
			buscaTextual: this.getValor("busca_disciplinas"),
			codTipoClassificacao: this.getValor("codTipoClassificacao"),
			codDisciplinaPai: codDisciplinaPai,
			periodoCadastro: [this.getValor("inicioPeriodoCadastroBusca"), this.getValor("fimPeriodoCadastroBusca")],
			periodoAtualizacao: [this.getValor("inicioPeriodoAtualizacaoBusca"), this.getValor("fimPeriodoAtualizacaoBusca")],
			isExibirArquivados: this.getValor("isExibirArquivados"),
		}

		const resultadoListagemClassificacaoTO = await this.call("CadastroDisciplinaFCD/listarClassificacoes", filtrosListagemClassificacaoTO);

		UtilHash.registrarHistorico(this.listarClassificacoes, codDisciplinaPai);
		
		$("#div-listagem-classificacoes").html("");
		this.setIdTarget("div-listagem-classificacoes");
		
		const sumprimirInferiores = !resultadoListagemClassificacaoTO.listaListagemClassificacaoTO?.some(to => to.numFilhas);

		const colunas: ColunaAddTabela[] = [{
			titulo: "", 
			prop:(listagemClassificacaoTO) => {
				const label = listagemClassificacaoTO.exibicaoNomeClassificacaoTO
					? this.exibirNomeClassificacao(listagemClassificacaoTO.exibicaoNomeClassificacaoTO)
					: listagemClassificacaoTO.nome;
				const spanArquivado = listagemClassificacaoTO.isExcluido ? `<br><span class='label label-default'>${this.getMsg("FP_FRONT_CadastroUsuarioVH_002")}</span>` : "";

				if (!listagemClassificacaoTO.isEditavel) return label + spanArquivado;

				return `
					<a href="${UtilHash.getHash(this.editarClassificacao, listagemClassificacaoTO.codDisciplina)}">
						${label}
					</a>
					${spanArquivado}
				`;
			}, 
			classe: "descricao"
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroDisciplinaVH_055"), 
			prop: "nomeSegmento"
		}, {
			titulo: this.getMsg("FP_FRONT_CadastroDisciplinaVH_054"), 
			prop: (listagemClassificacaoTO) => {

				if (!listagemClassificacaoTO.numQuestoes) return "";

				let filtroDisciplina = {
					codigo: listagemClassificacaoTO.codDisciplina,
					tipo: "DP",
					nome: listagemClassificacaoTO.nome
				};
				let hashQuestoes = UtilHash.getHash(cadastroQuestaoVH.exibirBuscaQuestoes, encodeURIComponent(JSON.stringify([filtroDisciplina])));

				return `
					<a href='${hashQuestoes}' target='_blank'>
						${listagemClassificacaoTO.numQuestoes}
					</a>
				`;
			}
		}];
		if (!sumprimirInferiores) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroDisciplinaVH_052"), prop: (listagemClassificacaoTO) => {

					if (!listagemClassificacaoTO.numFilhas) return "";
					return `
						<a class="badge" onclick="cadastroDisciplinaVH.listarClassificacoes(${listagemClassificacaoTO.codDisciplina})">
							${listagemClassificacaoTO.numFilhas} 
							<i class="fa fa-angle-right"></i>
						</a>
					`;
				}
			});
		}
		await this.addTabela({
			collection: resultadoListagemClassificacaoTO.listaListagemClassificacaoTO,
			colunas: colunas,
			paginacao: {
				paginacaoTO: resultadoListagemClassificacaoTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					
					const filtrosListagemClassificacaoTO: FiltrosListagemClassificacaoTO = {
						paginacaoTO: paginacaoTO,
						buscaTextual: this.getValor("busca_disciplinas"),
						codDisciplinaPai: codDisciplinaPai,
						codTipoClassificacao: this.getValor("codTipoClassificacao"),
						periodoCadastro: [this.getValor("inicioPeriodoCadastroBusca"), this.getValor("fimPeriodoCadastroBusca")],
						periodoAtualizacao: [this.getValor("inicioPeriodoAtualizacaoBusca"), this.getValor("fimPeriodoAtualizacaoBusca")],
						isExibirArquivados: this.getValor("isExibirArquivados"),
					};

					const resultadoListagemClassificacaoTO = await this.call("CadastroDisciplinaFCD/listarClassificacoes", filtrosListagemClassificacaoTO);

					return resultadoListagemClassificacaoTO.listaListagemClassificacaoTO;
				}
			},
		});

		this.exibir();
	}

	async cadastrarNovaDisciplina(codDisciplinaSuperior, categoria, onDisciplinaSalva: Function) {
		this.isEdicaoEmPopup = true;
		this.onDisciplinaSalva = onDisciplinaSalva;
		await this.editarClassificacao(null, {
			codDisciplinaPai: codDisciplinaSuperior,
			categoriaClassificacao: categoria
		});
	}

	async editarTiposObrigatorios(){

		const listagemTiposClassificacoes = await this.call("CadastroDisciplinaFCD/recuperarTiposClassificacoes");

		const botoes = [{
			label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_046"),
			classe: 'btn-warning',
			onClick: async () => {
				this.fecharPopup("popup_classificacoes_obrigatorias");
			}
		}, {
			label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_045"),
			classe: 'btn-success',
			onClick: async () => {
				let classificacoes = [];
				for(let tipoClassificacao of listagemTiposClassificacoes){
					classificacoes.push({
						codTipoClassificacao: tipoClassificacao.codTipoClassificacao,
						isClassificacaoObrigatoria: this.getValor("check-classificacao-"+tipoClassificacao.codTipoClassificacao)
					});
				}
				await this.call("CadastroDisciplinaFCD/salvarTiposClassificacoesObrigatorias", classificacoes);
				this.fecharPopup("popup_classificacoes_obrigatorias");
			}
		}]

		this.addPopup({
			id: "popup_classificacoes_obrigatorias",
			titulo: this.getMsg("FP_FRONT_CadastroDisciplinaVH_044"),
			width: "700px",
			// height: "500px",
			botoes: botoes
		});

		this.append(`
			<div class='col-md-12'>
				<div class='col-md-9' style='padding-bottom: 15px;font-size: 15px;'>
					<strong>${this.getMsg("FP_FRONT_CadastroDisciplinaVH_047")}</strong>
				</div>
				<div class='col-md-3' style='text-align: center;padding-bottom: 15px;font-size: 15px;'>
					<strong>${this.getMsg("FP_FRONT_CadastroDisciplinaVH_048")}</strong>
				</div>
		`);

		this.addFormulario();

		for(let tipoClassificacao of listagemTiposClassificacoes){
			this.append(`
				<div class='col-md-9' cod-classificacao='${tipoClassificacao.codTipoClassificacao}'>
					${tipoClassificacao.nome}
					<br>
					<small>
						${tipoClassificacao.nomeSegmento}
					</small>
				</div>
			`);
			this.addCheckbox({
				id: "check-classificacao-"+tipoClassificacao.codTipoClassificacao,
				classe: "col-md-3",
				css: "min-height: 10px;text-align: center;",
				valor : tipoClassificacao.isClassificacaoObrigatoria,
				label: ""
			});
		}

		this.append("</div>");
		this.exibirPopups();
	}

	async editarClassificacao(codDisciplina: number, salvamentoDisciplinaTOTemplate: any = null) {

		let recuperacaoDisciplinaTO: RecuperacaoDisciplinaTO = await this.call("CadastroDisciplinaFCD/recuperar", codDisciplina);

		if (salvamentoDisciplinaTOTemplate) {
			recuperacaoDisciplinaTO = {
				codDisciplina: null,
				codEmpresa: salvamentoDisciplinaTOTemplate.codEmpresa,
				descricao: null,
				isDisciplinaRaiz: false,
				isExcluida: false,
				codigoSistemaIntegrado: null,
				codTipoClassificacao: null,
				listaListaTOResponsaveis: [],
				nomeUsuarioCadastro: null,
				codDisciplinaPai: salvamentoDisciplinaTOTemplate.codDisciplinaPai,
				isPublica: salvamentoDisciplinaTOTemplate.isPublica,
				isClassificacaoPermitida: salvamentoDisciplinaTOTemplate.isClassificacaoPermitida,
				categoria: salvamentoDisciplinaTOTemplate.categoriaClassificacao,
				listaListagemCategoriasTO: recuperacaoDisciplinaTO.listaListagemCategoriasTO
			}
		}
		
		const botoes = [];

		if (recuperacaoDisciplinaTO.codDisciplina) {
			if (recuperacaoDisciplinaTO.isExcluida) {
				botoes.push({ 
					id: "btnRestaurarClassificacao",
					label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_053"), 
					onClick: () => this.restaurarCadastro(recuperacaoDisciplinaTO.codDisciplina)
				});
			} else {
				botoes.push({ 
					id: "btnExcluirClassificacao",
					label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_006"), 
					classe: "btn-danger", 
					onClick: () => this.excluir(false)
				});
				botoes.push({ 
					id: "btnAssociarEquivalenciasClassificacao",
					label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_007"), 
					onClick: () => this.excluir(true), 
					habilitado: UtilAuth.possuiAcesso(TipoFuncionalidade.DISCIPLINA_ASSOCIACAO_EQUIVALENCIAS) 
				});
			}
			botoes.push({ 
				label: `<i class='fa fa-eye'></i> ${this.getMsg("FP_FRONT_CadastroDisciplinaVH_008")}`, 
				onClick: () => {
					loginVH.exibirAcessosDaDisciplina(recuperacaoDisciplinaTO.codDisciplina);
					return false;
				},
				habilitado: this.isAdministrador()
			});
		}

		if (!this.isEdicaoEmPopup && !recuperacaoDisciplinaTO.isExcluida) {
			botoes.push({
				id: "bntSalvarClassificarECriarNova",
				label: "Salvar e criar nova", 
				onClick: () => this.salvarClassificacao(true), 
			});
		}
		if (!recuperacaoDisciplinaTO.isExcluida) {
			botoes.push({
				id: "btnSalvarClassificacao",
				label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_009"), 
				onClick: () => this.salvarClassificacao(), 
				classe: "btn-primary" 
			});
		}

		if (this.isEdicaoEmPopup) {
			this.addPopup({
				id: "cadastro_disciplina",
				titulo: this.getMsg("FP_FRONT_CadastroDisciplinaVH_010"),
				width: "700px",
				// height: "450px",
				botoes: botoes
			});
		} else {
			this.limpar();
			this.setTitulo(recuperacaoDisciplinaTO.descricao || this.getMsg("FP_FRONT_CadastroDisciplinaVH_051"), "Edição de classificação");
		}

		this.setMetodoSubmit(async () => await this.salvarClassificacao());

		this.addInputHidden("disciplina_cod", recuperacaoDisciplinaTO.codDisciplina);

		if (recuperacaoDisciplinaTO.isExcluida) {
			this.append(" <span class='label label-default'>" + this.getMsg("MSG_VH_CU_32") + "</span> ");
			this.addEspacamentoHorizontal("20px");
		}

		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				id: "cod_empresa",
				label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_012"),
				obrigatorio: true,
				classe: "col-md-6",
				valor: recuperacaoDisciplinaTO.codEmpresa,
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarSegmentos",
					numMinimoCaracteres: 0,
				},
			});
		}

		await this.addSelect({
			id: "disciplina_superior",
			label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_013"),
			valor: recuperacaoDisciplinaTO.codDisciplinaPai,
			classe: "col-md-6",
			textoOpcaoVazia: "(Raiz)",
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarDisciplinasTags",
				numMinimoCaracteres: 3,
			}
		});

		await this.addSelect({
			collection: UtilJson.clonar(recuperacaoDisciplinaTO.listaListagemCategoriasTO),
			id: "categoria",
			propId: "categoria",
			propLabel: "nome",
			classe: "col-md-6",
			label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_011"),
			valor: recuperacaoDisciplinaTO.categoria,
		});

		this.addCampoTexto({
			id: "disciplina_descricao",
			label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_014"),
			valor: recuperacaoDisciplinaTO.descricao,
			classe: "col-md-6",
		});

		if (this.isAdministrador()) {
			this.addCheckbox({
				id: "disciplina_isPublica",
				label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_015"),
				valor: recuperacaoDisciplinaTO.isPublica,
				classe: "col-md-6",
			});
			this.addCheckbox({
				id: "disciplina_isClassificacaoPermitida",
				label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_016"),
				valor: recuperacaoDisciplinaTO.isClassificacaoPermitida,
				classe: "col-md-6",
			});
		}

		this.addEspacamentoHorizontal();

		if (recuperacaoDisciplinaTO.codDisciplina) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_017"), valor: recuperacaoDisciplinaTO.codDisciplina})
		}

		if (recuperacaoDisciplinaTO.nomeUsuarioCadastro) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_018"), valor: recuperacaoDisciplinaTO.nomeUsuarioCadastro})
		}

		if (recuperacaoDisciplinaTO.codigoSistemaIntegrado) {
			this.addCampoExibicao({ label: "ID no sistema integrado", valor: recuperacaoDisciplinaTO.codigoSistemaIntegrado})
		}

		if (recuperacaoDisciplinaTO.codDisciplina) {
			let h = this.getMsg("FP_FRONT_CadastroDisciplinaVH_019");
			if (recuperacaoDisciplinaTO.listaListaTOResponsaveis.length > 0) {
				h = recuperacaoDisciplinaTO.listaListaTOResponsaveis.map(to => {
					return `
						<a href="${UtilHash.getHash(cadastroUsuarioVH.editarUsuario, to.id)}">
							<i class='fa fa-user'></i>
						</a>
						${to.text}
					`;
				}).join("<br>");
			}
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_020"), valor: h});
		}

		this.addEspacamentoHorizontal();

		if (this.isEdicaoEmPopup) {
			this.exibirPopups();
		} else {
			this.append(`
				<div class="btn-group pull-right">
			`);
			for (const botao of botoes) {
				this.addBotao(botao);
			}
			this.append(`
				</div>
			`);
			this.exibir();
		}

		this.focar("disciplina_descricao");
		$("#disciplina_superior").trigger("change");
		this.isEdicaoEmPopup = null;
	}

	async salvarClassificacao(isSalvarECriarNova?: boolean) {

		if (!this.validarCamposObrigatorios("disciplina_descricao")) return false;

		const salvamentoDisciplinaTO = {
			codDisciplina: this.getValor("disciplina_cod"),
			descricao: this.getValor("disciplina_descricao"),
			codDisciplinaPai: this.getValor("disciplina_superior"),
			isPublica: this.isMarcado("disciplina_isPublica"),
			isClassificacaoPermitida: this.isMarcado("disciplina_isClassificacaoPermitida"),
			codEmpresa: this.getValor("cod_empresa"),
			categoriaClassificacao: this.getValor("categoria")
		}

		if (salvamentoDisciplinaTO.codDisciplina != null && salvamentoDisciplinaTO.codDisciplina == salvamentoDisciplinaTO.codDisciplinaPai) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroDisciplinaVH_021") });
			return false;
		}

		const exibicaoDisciplinaTO = await this.call("CadastroDisciplinaFCD/salvar", salvamentoDisciplinaTO);

		this.fecharPopup("cadastro_disciplina");

		if (isSalvarECriarNova) {
			setTimeout(() => {
				this.editarClassificacao(null, salvamentoDisciplinaTO);
			}, 500);

		} else if (this.onDisciplinaSalva) {
			this.onDisciplinaSalva(exibicaoDisciplinaTO.codDisciplina, exibicaoDisciplinaTO);
			this.onDisciplinaSalva = null;
		} else {
			this.listarClassificacoes();
		}

		cadastroQuestaoVH.limparCacheClassificacoes();
		selecaoQuestoesVH.limparCache();
	}

	async excluir(verificarAntesDeExcluir) {

		const verificacaoExclusaoDisciplinaTO = await this.call("CadastroDisciplinaFCD/verificarExclusao", this.getValor("disciplina_cod"));
		const numQuestoesComEstaDisciplina = verificacaoExclusaoDisciplinaTO.numQuestoesComEstaDisciplina ?? 0;

		if (numQuestoesComEstaDisciplina === 0) {
			this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_CadastroDisciplinaVH_022", verificacaoExclusaoDisciplinaTO.nomeDisciplina),
				botoes: [{
					label: this.getMsg("MSG_VH_004"),
					classe: "btn-primary",
					onClick: async () => {
						await this.call("CadastroDisciplinaFCD/excluir", verificacaoExclusaoDisciplinaTO.codDisciplina);
						await this.listarClassificacoes();
					}
				}, {
					label: this.getMsg("MSG_VH_005")
				}],
			});
			return
		}

		if (!verificarAntesDeExcluir) {
			this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_CadastroDisciplinaVH_023", verificacaoExclusaoDisciplinaTO.nomeDisciplina, numQuestoesComEstaDisciplina),
				botoes: [
					{
						label: this.getMsg("MSG_VH_004"),
						classe: "btn-primary",
						onClick: async () => {
							await this.call("CadastroDisciplinaFCD/excluir", verificacaoExclusaoDisciplinaTO.codDisciplina);
							this.listarClassificacoes();
						}
					},
					{label: this.getMsg("MSG_VH_005"),}
				],
			});
			return
		}

		this.limpar()

		this.setTitulo(this.getMsg("FP_FRONT_CadastroDisciplinaVH_024"))
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroDisciplinaVH_025"))

		this.addTexto(this.getMsg("FP_FRONT_CadastroDisciplinaVH_029", verificacaoExclusaoDisciplinaTO.nomeDisciplina, numQuestoesComEstaDisciplina))

		await this.addSelect({
			id: "nova_disciplina_para_substituir_excluida",
			propId: "codDisciplina",
			label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_026"),
			propFilhas: "collectionListagemDisciplinaTOFilhos",
			propLabel: "descricao",
			propLabelDescricao: "nomeEmpresa",
			multiplo: false,
			classe: "col-md-5",
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarDisciplinasTags",
				numMinimoCaracteres: 3,
			}
		})

		this.addBotaoDireito(`${this.getMsg("FP_FRONT_CadastroDisciplinaVH_027")} &raquo;`, () => this.excluirComSubstituta(verificacaoExclusaoDisciplinaTO.codDisciplina))
		this.addBotaoEsquerdo(`&laquo; ${this.getMsg("FP_FRONT_CadastroDisciplinaVH_028")}`, () => UtilHash.voltar())

		this.exibir();
	}

	async restaurarCadastro(codDisciplina: number) {
		await this.call("CadastroDisciplinaFCD/excluir", codDisciplina);
		await this.listarClassificacoes();
	}

	async excluirComSubstituta(codDisciplinaASerSubstituidaEExcluida) {
		const codDisciplinaSubstituta = this.getValor("nova_disciplina_para_substituir_excluida")

		if (codDisciplinaSubstituta == codDisciplinaASerSubstituidaEExcluida) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroDisciplinaVH_030") });
			return
		}

		const associacaoDisciplinasEquivalentesTO = {
			codsEquivalentes: [codDisciplinaASerSubstituidaEExcluida],
			codDisciplinaRepresentante: new Number(codDisciplinaSubstituta)
		}

		await this.call("CadastroDisciplinaFCD/associarEquivalentes", associacaoDisciplinasEquivalentesTO);

		this.exibirResultadoAssociacao();
	}

	async exibirAssociacao() {

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroDisciplinaVH_031"));
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroDisciplinaVH_032"));

		await this.addSelect({
			id: "disciplinas_equivalentes",
			propId: "codDisciplina",
			label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_033"),
			propFilhas: "collectionListagemDisciplinaTOFilhos",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarDisciplinasTags",
				numMinimoCaracteres: 3,
			}
		})

		this.addBotaoEsquerdo(`&laquo; ${this.getMsg("FP_FRONT_CadastroDisciplinaVH_034")}`, () => this.listarClassificacoes())
		this.addBotaoDireito(`${this.getMsg("FP_FRONT_CadastroDisciplinaVH_035")} &raquo;`, async () => {

			const collectionDisciplinasEquivalentes = this.getValor("disciplinas_equivalentes");
			const nomes = this.getTOsSelecionadosSelect("disciplinas_equivalentes").map(to => to.nome).join("<br>");

			if (collectionDisciplinasEquivalentes == null || collectionDisciplinasEquivalentes.length < 2) {
				this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroDisciplinaVH_036") });
				return
			}

			this.limpar();

			this.setTitulo(this.getMsg("FP_FRONT_CadastroDisciplinaVH_037"))
			this.setSubtitulo(this.getMsg("FP_FRONT_CadastroDisciplinaVH_038"))

			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_039"), valor: nomes});
			await this.addSelect({
				id: "disciplina_representante",
				propId: "codDisciplina",
				label: this.getMsg("FP_FRONT_CadastroDisciplinaVH_040"),
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarDisciplinasTags",
					numMinimoCaracteres: 3,
				}
			})

			this.addBotaoDireito(`${this.getMsg("FP_FRONT_CadastroDisciplinaVH_041")} &raquo;`, async () => {

				if (!this.validarCamposObrigatorios("disciplina_representante")) {
					return
				}
		
				const associacaoDisciplinasEquivalentesTO = {
					codsEquivalentes: collectionDisciplinasEquivalentes,
					codDisciplinaRepresentante: this.getValor("disciplina_representante")
				}
		
				await this.call("CadastroDisciplinaFCD/associarEquivalentes", associacaoDisciplinasEquivalentesTO);
		
				this.exibirResultadoAssociacao();
			});

			this.addBotaoEsquerdo(`&laquo; ${this.getMsg("FP_FRONT_CadastroDisciplinaVH_042")}`, () => this.exibirAssociacao())

			this.exibir();
		})

		this.exibir();
	}

	exibirResultadoAssociacao() {
		this.mostrarMsgAjax(this.getMsg("FP_FRONT_CadastroDisciplinaVH_043"), 5)
		this.listarClassificacoes();
	}
		
	exibirNomeClassificacao(exibicaoNomeClassificacaoTO) {
		let nome;

		if (exibicaoNomeClassificacaoTO.exibicaoNomeClassificacaoTOPai?.nomeTipoClassificacao == exibicaoNomeClassificacaoTO.nomeTipoClassificacao) {
			nome = ` <span class="label-tipo-classificacao"> > </span> ${exibicaoNomeClassificacaoTO.nome} `;
		} else if (exibicaoNomeClassificacaoTO.exibicaoNomeClassificacaoTOPai) {
			nome = ` <span class="label-tipo-classificacao"> > ${exibicaoNomeClassificacaoTO.nomeTipoClassificacao} </span> ${exibicaoNomeClassificacaoTO.nome}`;
		} else {
			nome = ` <span class="label-tipo-classificacao"> ${exibicaoNomeClassificacaoTO.nomeTipoClassificacao} </span> ${exibicaoNomeClassificacaoTO.nome} `;
		}

		if (exibicaoNomeClassificacaoTO.exibicaoNomeClassificacaoTOPai) {
			return this.exibirNomeClassificacao(exibicaoNomeClassificacaoTO.exibicaoNomeClassificacaoTOPai) + " " + nome;
		} else {
			return nome;
		}
	}

}

const cadastroDisciplinaVH = new CadastroDisciplinaVH();

type FiltrosListagemClassificacaoTO = {
	paginacaoTO: PaginacaoTO;
	buscaTextual: string;
	codTipoClassificacao: number;
	codDisciplinaPai: number;
	periodoCadastro: string[];
	periodoAtualizacao: string[];
	isExibirArquivados: boolean;
}
type RecuperacaoDisciplinaTO = {
	codDisciplina: number;
	codEmpresa: number;
	descricao: string;
	codDisciplinaPai: number;
	isDisciplinaRaiz: boolean;
	isPublica: boolean;
	isExcluida: boolean;
	isClassificacaoPermitida: boolean;
	codTipoClassificacao: number;
	categoria: string;
	nomeUsuarioCadastro: string;
	codigoSistemaIntegrado: string;
	listaListaTOResponsaveis: ListaTO[];
	listaListagemCategoriasTO: ListagemCategoriasTO[];
}
type ListagemCategoriasTO = {
	nome: string;
	nomePlural: string;
	categoria: string;
	isObrigatoria: boolean;
}