class FullScreenPFVH extends PFVH {
	
	constructor() {
		super(FullScreenPFVH.name);
	}
	
	exibirTelaFullScreen(precondicoesParaInicioDeProvaTO: PrecondicoesParaInicioDeProvaTO): Promise<void> {

		if (precondicoesParaInicioDeProvaTO?.isFullScreen !== true) return;
		if (UtilWindow.isFullScreen()) return;

		return new Promise((resolve) => {
			this.limpar();
			this.setTitulo("Ativação de tela cheia");
			this.addTexto(`<span style='font-size: 120%'>${this.getMsg("FP_FRONT_FullScreenPFVH_001")}</span`);
			this.addBotao({
				id: "botaoFullScreenDeAcordo",
				label: this.getMsg("FP_FRONT_FullScreenPFVH_002"), 
				classe: "btn-primary pull-right", 
				onClick: async () => {
					await this.ativarFullScreen();
					resolve(null);
				}
			});
			this.exibir();
		});
	}

	async ativarFullScreen() {
		
		await UtilWindow.ativarFullScreen(); 

		setTimeout(async () => {
			await this.monitorarFullScreen();
		}, 3000);
	}

	async monitorarFullScreen() {

		let e = document.querySelector("#alertaFullScreen");

		UtilWindow.updateRolagemFullScreen();

		if (!document.getElementById("divAplicacaoProva")) {
			this.logger.info(this.getMsg("FP_FRONT_FullScreenPFVH_003"));
			if (e) document.body.removeChild(e);
			await UtilWindow.cancelarFullScreen();
			return;
		}

		if (e == null) {
			$(document.body).append(`
				<div id='alertaFullScreen' class='alert alert-success' style='position: absolute; top: 0px; left: 50%; transform: translate(-50%,0%); font-size: 120%'>
					<strong>Modo tela cheia</strong>
				</div>
			`);
			e = document.querySelector("#alertaFullScreen");
		}

		if (UtilWindow.isFullScreen()) {
			setTimeout(async () => {
				await this.monitorarFullScreen();
			}, 2000);
		} else {
			await this.exibirAlerta({
				msg: this.getMsg("FP_FRONT_FullScreenPFVH_004"),
				botoes: [{label: this.getMsg("FP_FRONT_FullScreenPFVH_005"), classe: "btn-primary"}],
			});
			await this.ativarFullScreen();
		}
	}
}

const fullScreenPFVH = new FullScreenPFVH();
