class CronoPFVH extends PFVH {
	
	worker: Worker;
	$aplicacaoProva: any;
	$cronProva: any;
	$cronSecao: any;
	$cronQuestao: any;
	milisegundosRestantesParaProva: number;
	cronoListener: Function = (msDecorridos) => {
		cronoPFVH.handleCronoTick(msDecorridos);
	}

	constructor() {
		super(CronoPFVH.name);
	}

	gerarHtmlCronometro(aplicacaoProvaFeitaTO: AplicacaoProvaFeitaTO) {

		if (!aplicacaoProvaFeitaTO?.tipoCronometro) return "";

		let htmlCronoLeft = "";

		if (aplicacaoProvaFeitaTO.tipoCronometro === "QUESTAO") {

			htmlCronoLeft = `
				<span crono-questao data-placement="left" title="${this.getMsg("MSG_VH_APR_139")}">
					...
				</span>
			`;

		} else if (aplicacaoProvaFeitaTO.tipoCronometro === "SECAO") {

			htmlCronoLeft = `
				<span crono-secao data-placement="left" title="${this.getMsg("FP_FRONT_CronoPFVH_003")}">
					...
				</span>
			`;
		}
		
		return `
			<div painel-cronometro class="col-md-6 col-xs-6">
				${htmlCronoLeft}
				<span crono-prova data-placement="left" title="${this.getMsg("MSG_VH_APR_140")}" class="label label-default">
					...
				</span>
			</div>
		`;
	}

	pararCronometro() {
		UtilCrono.removerListener(this.cronoListener);
	}
	
	async iniciarCronometro(aplicacaoProvaFeitaTO: AplicacaoProvaFeitaTO) {

		try {
			this.pararCronometro();

			this.milisegundosRestantesParaProva = aplicacaoProvaFeitaTO.milisegundosRestantesParaProva;
			this.$aplicacaoProva = this.get$AplicacaoProva();
			this.$cronQuestao = $("[painel-cronometro] span[crono-questao]");
			this.$cronSecao = $("[painel-cronometro] span[crono-secao]");
			this.$cronProva = $("[painel-cronometro] span[crono-prova]");

			if (this.$cronQuestao.length === 0) this.$cronQuestao = null;
			if (this.$cronSecao.length === 0) this.$cronSecao = null;

			if (aplicacaoProvaFeitaTO.tipoCronometro) {
				this.logger.info(`Iniciando cronômetro por ${aplicacaoProvaFeitaTO.tipoCronometro} com ${this.milisegundosRestantesParaProva}ms restantes para a prova.`);
			} else {
				this.logger.info(`Iniciando cronômetro somente para contabilização de tempo.`);
			}

			UtilCrono.adicionarListener(this.cronoListener);
		} catch (e) {
			this.logger.error("Erro ao iniciar cronômetro", e);
		}
	}

	async handleCronoTick(msDecorridos: number) {

		if (this.isEmpty(msDecorridos) || msDecorridos < 1) return; // por segurança

		if (encerramentoPFVH.isEncerrada(this.$aplicacaoProva)) {
			this.logger.info("A prova foi fechada. Parando cronômetro.");
			this.pararCronometro();
			return;
		}

		if (encerramentoPFVH.isEncerrando(this.$aplicacaoProva)) return;

		await this.handleCronoTickQuestao(msDecorridos);
		await this.handleCronoTickSecao(msDecorridos);
		await this.handleCronoTickProva(msDecorridos);
	}

	async handleCronoTickQuestao(msDecorridos: number) {
		try {
			const $questaoAtual = await this.atualizarNavegacaoPorCronoQuestao();
			await this.atualizarCronoQuestao($questaoAtual);
			await this.contabilizarConsumoQuestao($questaoAtual, msDecorridos);
		} catch (e) {
			this.logger.error("Erro ao tratar tick para cronômetro de questão", e);
		}
	}

	async atualizarNavegacaoPorCronoQuestao(): Promise<any> {
		
		let $questaoAtual = null;

		try {
			const isQuestaoPorQuestao = this.$aplicacaoProva.data('fp-is-questao-por-questao');

			if (!isQuestaoPorQuestao) return null;

			$questaoAtual = this.$aplicacaoProva.find("[questao]:visible");

			if (await this.esperarHandleTempoQuestaoEsgotado($questaoAtual)) {
				$questaoAtual = this.$aplicacaoProva.find("[questao]:visible");
			}

			if (!$questaoAtual.length) return null;

			let segundosRestantes = this.getSegundosRestantes($questaoAtual);

			if (this.hasValue(segundosRestantes)) {

				if (segundosRestantes < 1) {
					
					// se o tempo da questão esgotou, navega para a próxima

					const $proximaQuestao = await this.handleTempoQuestaoEsgotado($questaoAtual);
					
					if (!$proximaQuestao) {
						// pode acontecer de não ter mais questão mas ainda tempo de prova
						this.$cronQuestao?.hide();
						return null;
					}

					$questaoAtual = $proximaQuestao;

				} else if (segundosRestantes <= 3) {

					await navegacaoPFVH.handleEntrarModoTravamento();
				}
			}
			
		} catch (e) {
			this.logger.error("Erro ao atualizar cronômetro de questão", e);
		}

		return $questaoAtual;
	}

	async atualizarCronoQuestao($questaoAtual: any): Promise<void> {

		if (!$questaoAtual || !this.$cronQuestao) return;

		try {
			let segundosRestantes = this.getSegundosRestantes($questaoAtual);

			if (this.isEmpty(segundosRestantes)) return;
			
			const textoDuracao = UtilData.getDuracao({segundos: segundosRestantes});
			const resta30sOuMenos = segundosRestantes <= 30;
			
			this.$cronQuestao.text(textoDuracao);
			
			if (resta30sOuMenos) {
				this.$cronQuestao.addClass("label label-danger");
			} else {
				this.$cronQuestao.removeClass("label label-danger");
			}
			
			recursoPFVH.handleCronoQuestaoAlterado(textoDuracao, resta30sOuMenos);

		} catch (e) {
			this.logger.error("Erro ao atualizar cronômetro de questão", e);
		}
	}

	async handleTempoQuestaoEsgotado($questao: any): Promise<any> {

		$questao.data("fp-is-tratando-tempo-esgotado", true);

		let $proximaQuestao = null;
		
		try {			
			this.logger.info("Tempo esgotado para questão " + $questao.find("[numerador]").attr("numerador"));
		
			await recursoPFVH.handleTempoEsgotado();

			$questao.find("[numerador]").each((i, numerador) => {
				$("[painel-respostas] [numerador='" + $(numerador).attr("numerador") + "']")
					.removeClass("item-em-branco")
					.addClass("item-tempo-esgotado")
					.prepend(`<i class='fa fa-clock-o' title='${this.getMsg("FP_FRONT_CronoPFVH_001")}'></i> `);
			});
			
			await navegacaoPFVH.forcarBlurRespostaDiscursiva();
			$proximaQuestao = navegacaoPFVH.getQuestaoPosterior($questao);

			if ($proximaQuestao) {
				await navegacaoPFVH.handleClickBotaoAvancar();
			} else {
				$proximaQuestao = await navegacaoPFVH.irParaQuestao();
			}
				
			this.mostrarMsgAjax(this.getMsg("MSG_VH_APR_132"));

		} catch (e) {
			this.logger.error("Erro no tratamento do tempo esgotado", e);
		}

		$questao.data("fp-is-tratando-tempo-esgotado", false);

		return $proximaQuestao;
	}

	/**
	 * - contabiliza o tempo consumido para a seção
	 * - se houver crono por seção
	 * 		- trata tempo esgotado
	 * 		- atualiza o crono da seção
	 */
	async handleCronoTickSecao(msDecorridos: number) {
		try {
			const $secaoAtual = await this.atualizarNavegacaoPorCronoSecao();
			await this.atualizarCronoSecao($secaoAtual);
			await this.contabilizarConsumoSecao($secaoAtual, msDecorridos);
		} catch (e) {
			this.logger.error("Erro ao tratar tick para cronômetro de seção", e);
		}
	}

	async handleCronoTickProva(msDecorridos: number) {

		if (this.isEmpty(this.milisegundosRestantesParaProva)) return;

		try {
			
			if (this.milisegundosRestantesParaProva < 1) {
				
				this.pararCronometro();
				this.$cronProva.text(this.getMsg("MSG_VH_APR_133") + "...");
				await recursoPFVH.handleTempoEsgotado();
				await encerramentoPFVH.encerrarProvaPeloCronometro(1);

			} else {

				const resta60sOuMenos = this.milisegundosRestantesParaProva <= 60000;
				const textoDuracao = UtilData.getDuracao({
					segundos: Math.round(this.milisegundosRestantesParaProva / 1000)
				});

				this.$cronProva.text(textoDuracao);

				if (resta60sOuMenos) {
					this.$cronProva.removeClass("label-default").addClass("label-danger");
				}

				if (this.milisegundosRestantesParaProva <= 10000) {
					$('#botao_concluir').attr("disabled", "disabled").text(this.getMsg("FP_FRONT_CronoPFVH_002"));
				}

				this.milisegundosRestantesParaProva = this.milisegundosRestantesParaProva - msDecorridos;

				await recursoPFVH.handleCronoProvaAlterado(textoDuracao, resta60sOuMenos);
			}
								
		} catch (e) {
			this.logger.error("Erro ao tratar tick para cronômetro da prova", e);
		}
	}

	/**
	 * @returns se precisou esperar o tratamento de tempo esgotado
	 */
	async esperarHandleTempoQuestaoEsgotado($questao: any): Promise<boolean> {

		if (!$questao.length) return false;

		if (!$questao.data("fp-is-tratando-tempo-esgotado")) return false;

		const inicio = Date.now();

		while ($questao.data("fp-is-tratando-tempo-esgotado")) {

			this.logger.info("Esperando 1s para terminar tratamento de tempo esgotado");

			await this.sleep(300);

			if ((Date.now() - inicio) >= 15_000) {
				this.logger.warn("Após 15s desistiu de esperar tratamento de tempo esgotado");
				return false;
			}
		}

		return true;
	}

	getMilisegundosRestantesParaProva() {
		return this.milisegundosRestantesParaProva;
	}

	getSegundosRestantes($questao): number {

		if (!$questao?.length) return null;

		const tempoUtilizadoQuestao = $questao.data("fp-tempo-utilizado");
		const tempoParaRespostaQuestao = $questao.data("fp-tempo-para-resposta");

		if (this.isEmpty(tempoUtilizadoQuestao) || this.isEmpty(tempoParaRespostaQuestao)) return null;

		return tempoParaRespostaQuestao - tempoUtilizadoQuestao;
	}
	
	isTempoEsgotado($questao) {
		const segundosRestantes = this.getSegundosRestantes($questao);
		return this.hasValue(segundosRestantes) && segundosRestantes < 1;
	}
	
	contabilizarConsumoQuestao($questao: any, msDecorridos: number) {

		if (!$questao) return;

		try {
			const segundosDecorridos = Math.round(msDecorridos / 1000);			
			const segundosConsumidos = $questao.data("fp-tempo-utilizado") ?? 0;

			$questao.data("fp-tempo-utilizado", segundosConsumidos + segundosDecorridos);
			$questao.attr("fp-teve-tempo-consumido", "true");
		} catch (e) {
			this.logger.error("Erro ao contabilizar tempo para questão", e);
		}
	}

	async atualizarNavegacaoPorCronoSecao(): Promise<any> {

		let $questaoAtual = null;
		let $secaoAtual = null;

		try {
			const isQuestaoPorQuestao = this.$aplicacaoProva.data('fp-is-questao-por-questao');

			if (!isQuestaoPorQuestao) return null;

			$questaoAtual = this.$aplicacaoProva.find("[questao]:visible");
			$secaoAtual = $(`div[secao][cod-secao="${$questaoAtual.attr("cod-secao")}"]`);

			if (await this.esperarHandleTempoSecaoEsgotado($secaoAtual)) {
				$questaoAtual = this.$aplicacaoProva.find("[questao]:visible");
				$secaoAtual = $(`div[secao][cod-secao="${$questaoAtual.attr("cod-secao")}"]`);
			}

			if (!$questaoAtual.length) return null;

			let segundosRestantes = this.getSegundosRestantesSecao($secaoAtual);

			if (this.hasValue(segundosRestantes)) {

				if (segundosRestantes < 1) {

					// se o tempo da seção esgotou, navega para a questão da próxima seção

					const $questaoProximaQuestao = await this.handleTempoSecaoEsgotado($questaoAtual);

					if (!$questaoProximaQuestao) {
						this.$cronQuestao?.hide();
						return null;
					}

					$questaoAtual = $questaoProximaQuestao;

				} else if (segundosRestantes <= 3) {

					await navegacaoPFVH.handleEntrarModoTravamento();
				}
			}

		} catch (e) {
			this.logger.error("Erro ao atualizar cronômetro de seção", e);
		}

		const codSecao = $questaoAtual.attr("cod-secao");
		return $(`div[secao][cod-secao="${codSecao}"]`);
	}

	async atualizarCronoSecao($secaoAtual: any): Promise<void> {

		if (!$secaoAtual || !this.$cronSecao) return;

		try {
			let segundosRestantes = this.getSegundosRestantesSecao($secaoAtual);

			if (this.isEmpty(segundosRestantes)) return;

			const textoDuracao = UtilData.getDuracao({segundos: segundosRestantes});
			const resta30sOuMenos = segundosRestantes <= 30;

			this.$cronSecao.text(textoDuracao);

			if (resta30sOuMenos) {
				this.$cronSecao.addClass("label label-danger");
			} else {
				this.$cronSecao.removeClass("label label-danger");
			}

			await recursoPFVH.handleCronoSecaoAlterado(textoDuracao, resta30sOuMenos);

		} catch (e) {
			this.logger.error("Erro ao atualizar cronômetro de seção", e);
		}
	}

	contabilizarConsumoSecao($secao: any, msDecorridos: number) {
		if (!$secao) return;

		try {
			const segundosDecorridos = Math.round(msDecorridos / 1000);

			$secao.data("fp-tempo-utilizado-secao", $secao.data("fp-tempo-utilizado-secao") + segundosDecorridos);
			$secao.attr("fp-teve-tempo-consumido-secao", "true");
		} catch (e) {
			this.logger.error("Erro ao contabilizar tempo para seção", e);
		}
	}

	/**
	 * @returns se precisou esperar o tratamento de tempo esgotado da seção
	 */
	async esperarHandleTempoSecaoEsgotado($secao: any): Promise<boolean> {

		if (!$secao.length) return false;

		if (!$secao.data("fp-is-tratando-tempo-esgotado-secao")) return false;

		const inicio = Date.now();

		while ($secao.data("fp-is-tratando-tempo-esgotado-secao")) {

			this.logger.info("Esperando 1s para terminar tratamento de tempo esgotado da seção");

			await this.sleep(300);

			if ((Date.now() - inicio) >= 15_000) {
				this.logger.warn("Após 15s desistiu de esperar tratamento de tempo esgotado da seção");
				return false;
			}
		}

		return true;
	}

	isTempoEsgotadoSecao($secao) {
		const segundosRestantes = this.getSegundosRestantesSecao($secao);
		return this.hasValue(segundosRestantes) && segundosRestantes < 1;
	}

	getSegundosRestantesSecao($secao): number {

		if (!$secao?.length) return null;

		const tempoUtilizadoSecao = $secao.data("fp-tempo-utilizado-secao");
		const tempoParaRespostaSecao = $secao.data("fp-tempo-para-resposta-secao");

		if (this.isEmpty(tempoUtilizadoSecao) || this.isEmpty(tempoParaRespostaSecao)) return null;

		return tempoParaRespostaSecao - tempoUtilizadoSecao;
	}

	async handleTempoSecaoEsgotado($questao: any): Promise<any> {

		const $secao = $(`div[secao][cod-secao="${$questao.attr("cod-secao")}"]`);
		$secao.data("fp-is-tratando-tempo-esgotado-secao", true);

		let $proximaQuestao = null;

		try {
			this.logger.info("Tempo esgotado para seção " + $secao.attr("cod-secao"));

			await recursoPFVH.handleTempoEsgotado();
			const codSecao = $secao.attr("cod-secao");

			$(`div[questao][cod-secao="${codSecao}"] [numerador]`).each((i, numerador) => {
				$("[painel-respostas] [numerador='" + $(numerador).attr("numerador") + "']")
					.removeClass("item-em-branco")
					.addClass("item-tempo-esgotado")
					.prepend(`<i class='fa fa-clock-o' title='${this.getMsg("FP_FRONT_CronoPFVH_001")}'></i> `);
			});

			$(`#titulo_painel_respostas_secao_${codSecao}`).prepend(`<i class='fa fa-clock-o' title='${this.getMsg("FP_FRONT_CronoPFVH_001")}'></i> `);

			await navegacaoPFVH.forcarBlurRespostaDiscursiva();
			$proximaQuestao = navegacaoPFVH.getQuestaoPosterior($questao);

			if ($proximaQuestao) {
				await navegacaoPFVH.handleClickBotaoAvancar();
			} else {
				$proximaQuestao = await navegacaoPFVH.irParaQuestao();
			}

			this.mostrarMsgAjax(this.getMsg("FP_FRONT_CronoPFVH_004"));

		} catch (e) {
			this.logger.error("Erro no tratamento do tempo esgotado", e);
		}

		$secao.data("fp-is-tratando-tempo-esgotado-secao", false);

		return $proximaQuestao;
	}

	retomarCronometros() {
		try {
			this.logger.info(`Retomando cronômetros com ${UtilTempo.getTempoFormatado(this.milisegundosRestantesParaProva)} restantes para a prova.`);

			this.pararCronometro();
			UtilCrono.adicionarListener(this.cronoListener);
		} catch (e) {
			this.logger.error("Erro ao retomar cronômetros", e);
		}
	}

	pausarCronometros() {
		try {
			this.logger.info(`Pausando cronômetros com ${UtilTempo.getTempoFormatado(this.milisegundosRestantesParaProva)} restantes para a prova.`);

			this.pararCronometro();
		} catch (e) {
			this.logger.error("Erro ao pausar cronômetros", e);
		}
	}
}

const cronoPFVH = new CronoPFVH();

type CronoUpdateHandler = (textoDuracao: string, isTempoAcabando: boolean) => void;
