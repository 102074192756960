class AplicacaoProvaVH extends AmaisVH {

	constructor() {
		super(AplicacaoProvaVH.name);
		this.addOperacaoParaHash("apdr", this.exibirDetalhesProvaRealizada);
		this.addOperacaoParaHash("aplp", this.listarProvasDeConcurso);
		this.addOperacaoParaHash("aplr", this.listarRealizadas);
		this.addOperacaoParaHash("aprq", this.exibirCorrecaoRespostaQuestao);
		this.addOperacaoParaHash("apla", this.listarProvasDisponiveis);
	}

	async listarProvasDeConcurso() {

		UtilHash.registrarHistorico(this.listarProvasDeConcurso);

		this.limpar();

		this.setTitulo(this.getMsg("MSG_VH_APR_01"));

		this.addTexto(this.getMsg("MSG_VH_APR_02"));

		await this.addSelect({
			id: "busca_orgao",
			label: this.getMsg("FP_FRONT_AplicacaoProvaVH_001"),
			classe: "col-md-4",
			onChange: async () => await this.onFiltrosAlterados(),
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/buscarOrgaos",
				numMinimoCaracteres: 3
			}
		});

		await this.addSelect({
			id: "busca_banca",
			label: this.getMsg("MSG_VH_APR_03"),
			classe: "col-md-4",
			onChange: async () => await this.onFiltrosAlterados(),
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/buscarBancas",
				numMinimoCaracteres: 3
			}
		});

		await this.addSelect({
			id: "busca_ano",
			label: this.getMsg("MSG_VH_APR_04"),
			classe: "col-md-4",
			onChange: async () => await this.onFiltrosAlterados(),
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/buscarAnos",
				numMinimoCaracteres: 0
			}
		});

		this.append("<div id='resultado_busca_provas_concurso' class='col-md-12'>");
		this.append("</div>");

		this.exibir();

		this.onFiltrosAlterados();
	}

	async onFiltrosAlterados() {
		
		const filtrosBuscaProvasConcursoTO: any = {
			codOrgao: this.getValor("busca_orgao"),
			codBanca: this.getValor("busca_banca"),
			ano: this.getValor("busca_ano"),
			paginacaoTO: null
		}

		let resultadoBuscaProvasConcursoTO = await this.call("AplicacaoProvaFCD/listarProvasDeConcurso", filtrosBuscaProvasConcursoTO);

		this.setHtml("resultado_busca_provas_concurso", "");

		this.setIdTarget("resultado_busca_provas_concurso");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_APR_05"), prop: "tituloProva", classe: "descricao"});
		colunas.push({titulo: this.getMsg("MSG_VH_APR_04"), prop: "anoConcurso"});
		colunas.push({titulo: this.getMsg("MSG_VH_APR_03"), prop: "nomeBanca"});

		await this.addTabela({
			collection: resultadoBuscaProvasConcursoTO.listaListagemProvasDeConcursoTO,
			propId: "codProva",
			colunas: colunas,
			onEdicao: async (codProva) => await inicioPFVH.verificarPrecondicoesParaInicioDeProva(codProva),
			msgListaVazia: this.getMsg("MSG_VH_APR_06"),
			paginacao: {
				paginacaoTO: resultadoBuscaProvasConcursoTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					filtrosBuscaProvasConcursoTO.paginacaoTO = paginacaoTO;
					resultadoBuscaProvasConcursoTO = await this.call("AplicacaoProvaFCD/listarProvasDeConcurso", filtrosBuscaProvasConcursoTO);
					return resultadoBuscaProvasConcursoTO.listaListagemProvasDeConcursoTO;
				}
			}
		});

		this.exibir();
	}

	async listarRealizadas() {

		UtilHash.registrarHistorico(this.listarRealizadas);

		const collectionListagemProvasRealizadasTO = await this.call("AplicacaoProvaFCD/listarProvasRealizadas");

		this.limpar()

		this.setTitulo(this.getMsg("MSG_VH_APR_07"))

		if (collectionListagemProvasRealizadasTO.length == 0) {
			this.addTexto(this.getMsg("MSG_VH_APR_08"));

		} else {
			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("MSG_VH_APR_09"), prop: "titulo", classe: "descricao"});
			colunas.push({titulo: this.getMsg("MSG_VH_APR_10"), prop: "tipo", classe: "descricao"});
			colunas.push({titulo: this.getMsg("MSG_VH_APR_11"), prop: "dataRealizacao", formato: "DD/MM/YY HH:mm"});
			colunas.push({titulo: this.getMsg("MSG_VH_APR_12"), prop: "nota", formato: "numero"});
			colunas.push({titulo: this.getMsg("MSG_VH_APR_13"), prop: "numeroQuestoes"});
			colunas.push({titulo: "", prop: this.montarBotao});

			await this.addTabela({
				collection: collectionListagemProvasRealizadasTO,
				propId: "codProvaFeita",
				colunas: colunas,
				onEdicao: this.exibirDetalhesProvaRealizada,
				ordenacao: [["dataRealizacao", 1]]
			});
		}

		this.exibir();
	}

	montarBotao(listagemProvasRealizadasTO) {
		const codProvaFeita = listagemProvasRealizadasTO.codProvaFeita
		return this.addBotao({
			label: this.getMsg("MSG_VH_APR_14"),
			onClick: async () => {
				const codProvaFeitaNova = await this.call("GeracaoProvaFeitaFCD/gerarNovaProvaBaseadaEmProvaFeita", codProvaFeita);
				await inicioPFVH.verificarPrecondicoesParaInicioDePF(codProvaFeitaNova);
			},
			retornarHtml: true,
		});
	};

	async listarProvasDisponiveis() {

		UtilHash.registrarHistorico(this.listarProvasDisponiveis);

		const listagemAplicacoesAvaliadoTO = await this.call("AplicacaoProvaFCD/listarProvasDisponiveis");

		this.limpar()
		
		this.setTitulo(this.getMsg("FP_FRONT_AplicacaoProvaVH_037"));
		
		if (listagemAplicacoesAvaliadoTO.listaProvaDisponivelTO.length === 0) {
			this.addTexto(this.getMsg("MSG_VH_APR_199"));
			this.exibir();
			return;
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_APR_201"), prop: "tituloProva", classe: "descricao"});
		colunas.push({titulo: this.getMsg("MSG_VH_APR_202"), prop: (provaDisponivelTO: ProvaDisponivelTO) => {
				let nomeFase = "Aplicação";

				if (provaDisponivelTO.tipoAplicacaoFase === TipoAplicacaoFase.REAPLICACAO) {
					nomeFase = "Reaplicação " + provaDisponivelTO.numReaplicacao;
				} else if (provaDisponivelTO.tipoAplicacaoFase === TipoAplicacaoFase.RECURSOS) {
					nomeFase = "Recurso";
				}

				if (provaDisponivelTO.tipoAplicacaoFase === TipoAplicacaoFase.RECURSOS) {
					return `<i class="fa fa-gavel" title="${provaDisponivelTO.tipoAplicacao.getNome()}" style="opacity: 0.5"></i> <span>${nomeFase}</span>`;
				} else if(provaDisponivelTO.tipoAplicacao === TipoAplicacao.F) {
					return `<i class="fa fa-print" title="${provaDisponivelTO.tipoAplicacao.getNome()}" style="opacity: 0.5"></i> <span>${nomeFase}</span>`;
				} else {
					return `<i class="fa fa-laptop" title="${provaDisponivelTO.tipoAplicacao.getNome()}" style="opacity: 0.5"></i> <span>${nomeFase}</span>`;
				}
			}
		});
		colunas.push({
			titulo: this.getMsg("MSG_VH_APR_208"), prop: (provaDisponivelTO: ProvaDisponivelTO) => {
				return provaDisponivelTO.dataEncerramento
					? UtilData.toDDMMYYYYHHMM(provaDisponivelTO.dataEncerramento)
					: " - ";
			}
		});
		colunas.push({titulo: "Conceito", prop: (provaDisponivelTO: ProvaDisponivelTO) => {
				if(provaDisponivelTO.tipoAplicacaoFase === TipoAplicacaoFase.RECURSOS) {
					return "-";
				} else if (provaDisponivelTO.isCorrecaoPendente && provaDisponivelTO.isProvaFeitaFinalizada && !provaDisponivelTO.isAnulada) {
					return "<small><em>Aguardando correção de discursivas</em></small>";
				} else if (provaDisponivelTO.isProvaFeitaIniciada && !provaDisponivelTO.isProvaFeitaFinalizada){
					return "<small><em>Prova em andamento</em></small>";
				} else if (provaDisponivelTO.isProvaFeitaIniciada && provaDisponivelTO.isProvaFeitaFinalizada) {
					if (provaDisponivelTO.isResultadoPorMencao) {
						if (this.hasValue(provaDisponivelTO.descricaoMencao)) {
							return provaDisponivelTO.descricaoMencao;
						} else{
							return listagemAplicacoesAvaliadoTO.mapaMencoes[provaDisponivelTO.codMencao];
						}
					} else if (this.hasValue(provaDisponivelTO.nota)) {
						return "<small>Nota " + UtilNumero.floatToString(provaDisponivelTO.nota) + "</small>";
					}
				}
			}
		});

		colunas.push({titulo: "", prop: this.montarBotaoProvaDisponivel});

		await this.addTabela({
			id: "listagem-aplicacoes-disponiveis",
			collection: listagemAplicacoesAvaliadoTO.listaProvaDisponivelTO,
			propId: "codProva",
			colunas: colunas,
			ocultarOrdernacao: true
		});

		this.exibir();
		this.ajustarRowSpanColunaProva("listagem-aplicacoes-disponiveis");
	}

	montarBotaoProvaDisponivel(to: ProvaDisponivelTO) {

		const codProvaFeita = to.codProvaFeita;
		const codAgendamentoUsuario = to.codAgendamentoUsuario;
		const dataFimFase = to.dataFimFase;
		const dataInicioFase = to.dataInicioFase;
		const isAplicacaoEncerrada = to.dataEncerramento;
		const isAI = to.tipoAplicacao === TipoAplicacao.F;
		const isRecurso = to.tipoAplicacaoFase === TipoAplicacaoFase.RECURSOS;

		if (!to.codProvaFeita && !isRecurso) {

			if (isAI) {
				if (isAplicacaoEncerrada) {
					return `<small>Aplicação encerrada</small>`;
				} else {
					return `
						<small><em>Aguardando envio das folhas de respostas</em></small>
					`;
				}

			} else if (to.jaPassou) {
				return `
					<small>Prazo para resposta finalizou em ${UtilData.toDDMMYYYYHHMM(dataFimFase)}</small>
				`;

			} else if (to.isAtingiuPontuacaoMinima === true) {
				return `<small>Atingiu o conceito necessário</small>`;

			} else if (isAplicacaoEncerrada) {
				return `<small>Aplicação encerrada</small>`;

			} else if (to.isFutura) {
				return `
					<small>Inicia em ${UtilData.getTempoUserFriendly(dataInicioFase)}</small>
				`;

			} else if (to.isDentroPeriodo) {

				return this.addBotao({
					label: this.getMsg("MSG_VH_APR_205"),
					classe: "btn-primary",
					onClick: async ({ target }) => {

						encerramentoPFVH.setPosProvaCallback(async () => {
							await this.listarProvasDisponiveis();
						});

						await inicioPFVH.verificarPrecondicoesParaInicioDeProva(to.codProva, codAgendamentoUsuario, target)
					},
					retornarHtml: true
				});
			}

		} else if(isRecurso) {

			if (to.jaPassou) {
				return `
					<small>Prazo para recursos finalizou em ${UtilData.toDDMMYYYYHHMM(dataFimFase)}</small>
				`;
			} else if(to.isFutura){
				return `
					<small>Inicia em ${UtilData.getTempoUserFriendly(dataInicioFase)}</small>
				`;
			} else if(to.isCorrecaoPendente){
				return `
					<small>Aguardando correção de discursivas</small>
				`;
			} else if (!to.codProvaFeita) {
				return `
					<small>Aguardando a conclusão da prova</small>
				`;
			} else if (to.isDentroPeriodo) {
				return this.addBotao({
					id: "btn_recursos",
					label: "<i class='fa fa-legal'></i> " + this.getMsg("MSG_VH_AP_36"),
					onClick: async () => await recursoVH.interporRecurso(to.codProvaFeita),
					classe: "btn-warning",
					retornarHtml: true
				});
			}

		} else if (!to.isProvaFeitaFinalizada || to.isReaberturaVigente) {

			return this.addBotao({
				label: this.getMsg("MSG_VH_APR_205"),
				habilitado: to.isDentroPeriodo || to.isReaberturaVigente,
				classe: "btn-primary",
				onClick: async (event: Event) => {
					await inicioPFVH.verificarPrecondicoesParaInicioDePF(codProvaFeita, event.target)
				},
				retornarHtml: true,
			});

		} else if (to.isVisualizacaoResultadoAutorizada === true) {

			return this.addBotao({
				label: "<i class='fa fa-bar-chart-o'></i> " + this.getMsg("MSG_VH_AP_33"),
				onClick: async () => {
					await this.exibirDetalhesProvaRealizada(codProvaFeita);
				},
				retornarHtml: true,
			});
			
		} else if (to.isAnulada) {
			return `
				<small>Prova anulada</small>
			`;
		}
	}

	ajustarRowSpanColunaProva(idTabela: string) {
		const $tBody = $(`#${idTabela} tbody`);
		const rowSpans = {}

		$tBody.find("tr[identificador]").each((i: number, tr: HTMLTableRowElement) => {
			const codProva = tr.getAttribute("identificador");
			rowSpans[codProva] = (rowSpans[codProva] || 0) + 1;
		});

		let ultimoCodProva = null;

		$tBody.find("tr td:nth-child(1)").each((i: number, tdNomeProva: HTMLTableCellElement) => {
			const codProva = tdNomeProva.parentElement.getAttribute("identificador");

			if (ultimoCodProva != codProva) {
				tdNomeProva.rowSpan = rowSpans[codProva];
				ultimoCodProva = codProva;
			} else {
				$(tdNomeProva).hide();
			}
		});
	}

	montarBarraQuestoesRestantes(listagemProvasFeitasAConcluirTO) {
		if (listagemProvasFeitasAConcluirTO.numQuestoesRespondidas == listagemProvasFeitasAConcluirTO.numQuestoes) {
			return UtilProgressBar.basic({
				evolucao: listagemProvasFeitasAConcluirTO.numQuestoesRespondidas,
				total: listagemProvasFeitasAConcluirTO.numQuestoes,
				sufixo: "*"
			})

		} else {
			return UtilProgressBar.basic({
				evolucao: listagemProvasFeitasAConcluirTO.numQuestoesRespondidas,
				total: listagemProvasFeitasAConcluirTO.numQuestoes
			})
		}
	}

	calcularPorcentagem = (parte, todo, arredondar = true) => {
		if (parte == null || parte == 0) return 0;
		return arredondar ? Math.round(100 * parte / todo): 100 * parte / todo;
	}

	fixPorcentagemAcertosErros = (porcAcertos, porcBranco, porcAnuladas, porcErros, porcCorrecoesPendentes = 0, acertarTotal = false) => {
		while (porcAcertos + porcBranco + porcAnuladas + porcErros + porcCorrecoesPendentes > 100) {
			if (porcErros > 0) {
				porcErros--;
				break;
			}
			if (porcAcertos > 0) {
				porcAcertos--;
				break;
			}
			if (porcBranco > 0) {
				porcBranco--;
				break;
			}
			if (porcAnuladas > 0) {
				porcAnuladas--;
				break;
			}
			if (porcCorrecoesPendentes > 0) {
				porcCorrecoesPendentes--;
				break;
			}
		}

		if(acertarTotal){
			let somaTotal = porcAcertos + porcBranco + porcAnuladas + porcErros + porcCorrecoesPendentes;

			if(somaTotal < 100){
				let diff = 100 - somaTotal;
				let quantidadeDivisao = 0;

				if(porcAcertos > 0) quantidadeDivisao ++;
				if(porcBranco> 0) quantidadeDivisao ++;
				if(porcAnuladas > 0) quantidadeDivisao ++;
				if(porcErros> 0) quantidadeDivisao ++;
				if(porcCorrecoesPendentes > 0) quantidadeDivisao ++;

				let valorDistribuir = diff / quantidadeDivisao;

				if(porcAcertos > 0) porcAcertos += valorDistribuir;
				if(porcBranco> 0) porcBranco += valorDistribuir;
				if(porcAnuladas > 0) porcAnuladas += valorDistribuir;
				if(porcErros> 0) porcErros += valorDistribuir;
				if(porcCorrecoesPendentes > 0) porcCorrecoesPendentes += valorDistribuir;
			}
		}

		return {
			porcErros: porcErros, porcAcertos: porcAcertos,
			porcBranco: porcBranco, porcAnuladas: porcAnuladas, porcDiscursivas: porcCorrecoesPendentes
		}
	}

	async exibirDetalhesProvaRealizada(codProvaFeita: number, codQuestaoScrollTo: number = null) {

		UtilHash.registrarHistorico(this.exibirDetalhesProvaRealizada, codProvaFeita, codQuestaoScrollTo);

		const resultadoProvaRealizadaTO = await this.call("AplicacaoProvaFCD/recuperarPontuacaoProvaRealizada", codProvaFeita);

		if (!resultadoProvaRealizadaTO || (this.isAluno() && resultadoProvaRealizadaTO.isVisualizacaoResultadoAutorizada === false)) {
			// a visualização não é permitida
			if (this.isAluno()) {
				if (loginVH.isAutenticacaoLTI()) {
					this.limpar();
					if (resultadoProvaRealizadaTO && resultadoProvaRealizadaTO.isAnulada === true) {
						this.addTexto({
							id: 'msg-lti-prova-anulada',
							texto: this.getMsg("FP_FRONT_AplicacaoProvaVH_041")
						});
					} else if(resultadoProvaRealizadaTO && resultadoProvaRealizadaTO.numTentativasRestantes === 0 && resultadoProvaRealizadaTO.isVisualizacaoResultadoAutorizada === false) {
						this.addTexto({
							id: 'msg-lti-prova-concluida',
							texto: this.getMsg("FP_FRONT_AplicacaoProvaVH_042")
						});
					} else {
						this.addTexto({
							id: 'msg-lti-prova-finalizada',
							texto: this.getMsg("FP_FRONT_AplicacaoProvaVH_038")
						});
					}
					this.exibir();
				} else {
					await telaInicialVH.iniciar();
				}
			} else {
				UtilHash.carregarTelaInicial();
			}

			return;
		}

		const pontuacaoDaProvaFeitaTO = resultadoProvaRealizadaTO.pontuacaoDaProvaFeitaTO;
		const aplicacaoProvaFeitaTO = resultadoProvaRealizadaTO.aplicacaoProvaFeitaTO;
		const isExibirCorrecao = !this.isAluno() || this.hasValue(pontuacaoDaProvaFeitaTO?.notaObtida);

		this.limpar();

		if (this.isAluno()) {
			this.setTitulo(pontuacaoDaProvaFeitaTO.tituloPublico || pontuacaoDaProvaFeitaTO.titulo);
			this.setSubtitulo(this.getMsg("MSG_VH_APR_24"));
		} else {
			const hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, aplicacaoProvaFeitaTO.codUsuario);
			this.setTitulo("<a href='" + hashEdicaoUsuario + "'><i class='fa fa-user'></i></a> " + resultadoProvaRealizadaTO.nomeAluno);
			const hashEdicaoProva = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, (resultadoProvaRealizadaTO.tipoAplicacao == TipoAplicacao.P ? 3 : 4), resultadoProvaRealizadaTO.codProva);
			this.setSubtitulo(this.getMsg("MSG_VH_APR_16") + " " + "<a href='" + hashEdicaoProva + "'>" + pontuacaoDaProvaFeitaTO.titulo + "</a> ");
		}

		const campos = [];

		if (this.hasValue(resultadoProvaRealizadaTO.cpfAluno)) {
			campos.push({ label: this.getMsg("MSG_VH_APR_17"), valor: resultadoProvaRealizadaTO.cpfAluno, css: "margin-bottom: 0px" });

		} else if (this.hasValue(resultadoProvaRealizadaTO.matriculaAluno)) {
			campos.push({ label: this.getMsg("MSG_VH_APR_18"), valor: resultadoProvaRealizadaTO.matriculaAluno, css: "margin-bottom: 0px" });
		}

		if (resultadoProvaRealizadaTO.dataInicioProva != null) {
			if (resultadoProvaRealizadaTO.tipoAplicacao == TipoAplicacao.F) {
				campos.push({ label: this.getMsg("MSG_VH_APR_186"), valor: UtilData.toDDMMYYYYHHMM(resultadoProvaRealizadaTO.dataInicioProva), css: "margin-bottom: 0px" });
			} else {
				campos.push({ label: this.getMsg("MSG_VH_APR_19"), valor: UtilData.toDDMMYYYYHHMM(resultadoProvaRealizadaTO.dataInicioProva), css: "margin-bottom: 0px" });
			}
		}

		if (resultadoProvaRealizadaTO.codAgendamento && this.hasValue(resultadoProvaRealizadaTO.dataInicioFaseAplicacao)) {
			let data = (resultadoProvaRealizadaTO.dataFimFaseAplicacao ?
				this.getMsg("FP_FRONT_AplicacaoProvaVH_002", UtilData.toDDMMYYYYHHMM(resultadoProvaRealizadaTO.dataInicioFaseAplicacao), UtilData.toDDMMYYYYHHMM(resultadoProvaRealizadaTO.dataFimFaseAplicacao)) :
				this.getMsg("FP_FRONT_AplicacaoProvaVH_003", UtilData.toDDMMYYYYHHMM(resultadoProvaRealizadaTO.dataInicioFaseAplicacao)));

			const numAba = resultadoProvaRealizadaTO.tipoAplicacao == TipoAplicacao.F ? 4 : 3;
			const tipoAplicacao = (resultadoProvaRealizadaTO.tipoAplicacao == TipoAplicacao.F ? this.getMsg("FP_FRONT_AplicacaoProvaVH_005") : this.getMsg("FP_FRONT_AplicacaoProvaVH_006"));

			const label = `${this.getMsg("FP_FRONT_AplicacaoProvaVH_004")} ${tipoAplicacao}`;

			if (!this.isAluno()) {
				const hash = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, numAba, resultadoProvaRealizadaTO.codProva, null, null, null, resultadoProvaRealizadaTO.codAgendamento, null, resultadoProvaRealizadaTO.isAplicacaoArquivada);
				data = "<a href='/" + hash + "'>" + data + "</a>";
			}

			if (resultadoProvaRealizadaTO.isAplicacaoArquivada) {
				data = `${data} <br><span class='label label-default'>${this.getMsg("FP_FRONT_AplicacaoProvaVH_007")}</span>`;
			}

			campos.push({ label: label, valor: data, css: "margin-bottom: 0px" });
		}

		if (resultadoProvaRealizadaTO.possuiCorrecaoPendente) {

		} else if (resultadoProvaRealizadaTO.tipoAplicacao != TipoAplicacao.F && pontuacaoDaProvaFeitaTO.notaObtida != null && this.hasValue(resultadoProvaRealizadaTO.tempoDuracaoEmSegundos)) {
			let duracao = resultadoProvaRealizadaTO.tempoDuracaoEmSegundos;

			if (resultadoProvaRealizadaTO.tempoParaResposta != null && resultadoProvaRealizadaTO.tempoParaResposta < duracao) {
				duracao = resultadoProvaRealizadaTO.tempoParaResposta;
			}

			campos.push({ label: this.getMsg("MSG_VH_APR_15"), valor: UtilData.getDuracao({ segundos: duracao }), css: "margin-bottom: 0px" });

		} else if (resultadoProvaRealizadaTO.tipoAplicacao != TipoAplicacao.F && this.isEmpty(pontuacaoDaProvaFeitaTO.notaObtida) && this.hasValue(resultadoProvaRealizadaTO.dataInicioProva)) {
			campos.push({ label: "", valor: "<i>" + this.getMsg("MSG_VH_APR_20") + "</i>", css: "margin-bottom: 0px" });

		} else if (resultadoProvaRealizadaTO.tipoAplicacao != TipoAplicacao.F && this.isEmpty(pontuacaoDaProvaFeitaTO.notaObtida) && this.isEmpty(resultadoProvaRealizadaTO.dataInicioProva)) {
			campos.push({ label: "", valor: "<i>" + this.getMsg("MSG_VH_APR_21") + "</i>", css: "margin-bottom: 0px" });
		}

		if (resultadoProvaRealizadaTO.segundosRestantesParaProva || resultadoProvaRealizadaTO.segundosRestantesParaProva == 0) {
			campos.push({ label: this.getMsg("FP_FRONT_AplicacaoProvaVH_047"), valor: UtilData.getDuracao({ segundos: resultadoProvaRealizadaTO.segundosRestantesParaProva }), css: "margin-bottom: 0px" });
		}

		if (resultadoProvaRealizadaTO.isAnulada) {
			campos.push({ label: "", valor: "<span class='label label-danger'>" + this.getMsg("MSG_VH_APR_22") + "</span>", css: "margin-bottom: 0px" });
		}

		if (resultadoProvaRealizadaTO.isArquivada) {
			campos.push({ label: "", valor: `<span class='label label-default'>${this.getMsg("FP_FRONT_AplicacaoProvaVH_008")}</span>`, css: "margin-bottom: 0px" });
		}

		if (isExibirCorrecao) {

			if (!this.isCfgHabilitada("PONTUACAO_PROPORCIONAL")) {
				if (this.hasValue(pontuacaoDaProvaFeitaTO.pontuacaoObtida) && !resultadoProvaRealizadaTO.possuiCorrecaoPendente && !resultadoProvaRealizadaTO.isResultadoPorMencao) {
					campos.push({ label: this.getMsg("MSG_VH_APR_23"), valor: `<strong style='font-size: 120%'>${UtilNumero.floatToString(pontuacaoDaProvaFeitaTO.pontuacaoObtida)}</strong>`, css: "margin-bottom: 0px" })
				}

				if (this.hasValue(pontuacaoDaProvaFeitaTO.pontuacaoMaxima) && !resultadoProvaRealizadaTO.isResultadoPorMencao) {
					campos.push({ label: this.getMsg("MSG_VH_APR_25"), valor: UtilNumero.floatToString(pontuacaoDaProvaFeitaTO.pontuacaoMaxima), css: "margin-bottom: 0px" });
				}
			}

			if (this.hasValue(pontuacaoDaProvaFeitaTO.pontuacaoMinima) && !resultadoProvaRealizadaTO.isResultadoPorMencao) {
				campos.push({ label: this.getMsg("MSG_VH_APR_26"), valor: UtilNumero.floatToString(pontuacaoDaProvaFeitaTO.pontuacaoMinima), css: "margin-bottom: 0px" });
			}

			if (this.hasValue(pontuacaoDaProvaFeitaTO.pontuacaoDescontada) && pontuacaoDaProvaFeitaTO.pontuacaoDescontada > 0 && !resultadoProvaRealizadaTO.isResultadoPorMencao) {
				campos.push({ label: this.getMsg("MSG_VH_APR_27"), valor: UtilNumero.floatToString(pontuacaoDaProvaFeitaTO.pontuacaoDescontada), css: "margin-bottom: 0px" })
			}
			
			if (pontuacaoDaProvaFeitaTO.codMencao && resultadoProvaRealizadaTO.isResultadoPorMencao) {
				campos.push({ 
					label: this.getMsg("FP_FRONT_AplicacaoProvaVH_036"), 
					valor: this.getNomeConceito(pontuacaoDaProvaFeitaTO.listaExibicaoMencaoTO, pontuacaoDaProvaFeitaTO.codMencao), 
					css: "margin-bottom: 0px" 
				});
			}
		}

		if (isExibirCorrecao && this.hasValue(pontuacaoDaProvaFeitaTO.notaObtida) && !resultadoProvaRealizadaTO.isResultadoPorMencao) {
			const notaObtida = `<span class='labelNotaBaixa_false' style='font-size: 120%'>${UtilNumero.floatToString(pontuacaoDaProvaFeitaTO.notaObtida)}</span>`;
			const notaMaxima = UtilNumero.floatToString(pontuacaoDaProvaFeitaTO.notaMaxima);
			campos.push({
				label: this.getMsg("MSG_VH_APR_28"),
				valor: this.getMsg("FP_FRONT_AplicacaoProvaVH_009", notaObtida, notaMaxima)
			})
		}

		if (resultadoProvaRealizadaTO.posicaoRanking) {
			campos.push({ label: this.getMsg("FP_FRONT_AplicacaoProvaVH_046"), valor: resultadoProvaRealizadaTO.posicaoRanking, css: "margin-bottom: 0px" });
		}

		if (this.hasValue(pontuacaoDaProvaFeitaTO.notaObtida) && resultadoProvaRealizadaTO.possuiCorrecaoPendente) {
			campos.push({ label: "", valor: resultadoProvaRealizadaTO.msgCorrecaoPendente, css: "margin-bottom: 0px" });
		}

		const tiposAvaliacoes = (resultadoProvaRealizadaTO.listaTiposAvaliacoes ?? []).join(",");
		this.append(`
			<div class="correcao-prova row" correcao-prova-aluno 
				cod-usuario-avaliado="${resultadoProvaRealizadaTO.codUsuarioAvaliado}" 
				cod-prova="${resultadoProvaRealizadaTO.codProva}"
				tipos-avaliacoes="${tiposAvaliacoes}">

				<div class="col-md-8">
		`);

		const colunasResultadoProva: ColunaAddTabela[] = [];
		colunasResultadoProva.push({titulo: "", prop: "label"});
		colunasResultadoProva.push({titulo: "", prop: "valor"});

		await this.addTabela({
			collection: campos,
			id: "tabelaResultadoProva",
			colunas: colunasResultadoProva,
			classe: "table-hover",
			exibirTotalDeItens: false,
			ordenar: false,
			desabilitarDownload: true
		});

		this.appendJs(() => {

			if (this.isEmpty(resultadoProvaRealizadaTO.msgAprovacaoReprovacao)) return;
			if (this.isEmpty(resultadoProvaRealizadaTO.isAprovadoPorAgendamento)) return;

			let msg = "<span class='text-danger'>" + resultadoProvaRealizadaTO.msgAprovacaoReprovacao + "</span>";

			if (resultadoProvaRealizadaTO.isAprovadoPorAgendamento) {
				msg = "<span class='text-success'>" + resultadoProvaRealizadaTO.msgAprovacaoReprovacao + "</span>";
			}

			$("#tabelaResultadoProva tbody").append(`
				<tr msg-aprovacao-reprovacao>
					<td colspan='2' style='font-size: 120%'>
						${msg}
					</td>
				</tr>
			`);
		});

		this.append("</div><div id='grafico_geral' class='col-md-4'></div>")
		this.append("</div>")

		this.addEspacamentoHorizontal("10px");

		if (isExibirCorrecao && pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO?.length > 1) {

			const montarColunaAcertosErros = function (pontuacaoDaSecaoFeitaTO) {
				if (pontuacaoDaSecaoFeitaTO.pontuacaoAcertos == null) return null;

				let porcAcertos = aplicacaoProvaVH.calcularPorcentagem(pontuacaoDaSecaoFeitaTO.pontuacaoAcertos, pontuacaoDaSecaoFeitaTO.pontuacaoMaxima);
				let porcBranco = aplicacaoProvaVH.calcularPorcentagem(pontuacaoDaSecaoFeitaTO.pontuacaoEmBranco, pontuacaoDaSecaoFeitaTO.pontuacaoMaxima);
				let porcAnuladas = aplicacaoProvaVH.calcularPorcentagem(pontuacaoDaSecaoFeitaTO.pontuacaoAnuladas, pontuacaoDaSecaoFeitaTO.pontuacaoMaxima);
				let porcErros = aplicacaoProvaVH.calcularPorcentagem(pontuacaoDaSecaoFeitaTO.pontuacaoErros, pontuacaoDaSecaoFeitaTO.pontuacaoMaxima);

				let fixedPorcentagens = aplicacaoProvaVH.fixPorcentagemAcertosErros(porcAcertos, porcBranco, porcAnuladas, porcErros);
				porcAcertos = fixedPorcentagens.porcAcertos;
				porcBranco = fixedPorcentagens.porcBranco;
				porcAnuladas = fixedPorcentagens.porcAnuladas;
				porcErros = fixedPorcentagens.porcErros;

				return '<div class="progress"' + (pontuacaoDaSecaoFeitaTO.codDisciplina ? ' style="height: 8px" ' : "") + ">"
					+ '<div class="progress-bar progress-bar-success" title="' + this.getMsg("MSG_VH_APR_33") + ': ' + UtilNumero.floatToString(pontuacaoDaSecaoFeitaTO.pontuacaoAcertos) + '" style="width:' + porcAcertos + '%"></div>'
					+ '<div class="progress-bar progress-bar-warning ttip" title="' + this.getMsg("MSG_VH_APR_34") + ': ' + UtilNumero.floatToString(pontuacaoDaSecaoFeitaTO.pontuacaoAnuladas) + '" style="width:' + porcAnuladas + '%"></div>'
					+ '<div class="progress-bar progress-bar-default ttip" title="' + this.getMsg("MSG_VH_APR_35") + ': ' + UtilNumero.floatToString(pontuacaoDaSecaoFeitaTO.pontuacaoEmBranco) + '" style="width:' + porcBranco + '%"></div>'
					+ '<div class="progress-bar progress-bar-danger" title="' + this.getMsg("MSG_VH_APR_36") + ': ' + UtilNumero.floatToString(pontuacaoDaSecaoFeitaTO.pontuacaoErros) + '" style="width:' + porcErros + '%"></div>'
					+ '</div>';
			}

			const montarColunaPontuacao = function (pontuacaoDaSecaoFeitaTO) {
				if (pontuacaoDaSecaoFeitaTO.pontuacaoObtida == null) return null;
				if (pontuacaoDaSecaoFeitaTO.pontuacaoMaxima == null) return UtilNumero.floatToString(pontuacaoDaSecaoFeitaTO.pontuacaoObtida);
				return UtilNumero.floatToString(pontuacaoDaSecaoFeitaTO.pontuacaoObtida) + " " + this.getMsg("MSG_VH_APR_37") + " " + UtilNumero.floatToString(pontuacaoDaSecaoFeitaTO.pontuacaoMaxima);
			}

			const colunasResultadoSecoes: ColunaAddTabela[] = [];
			colunasResultadoSecoes.push({titulo: "", prop: "nome"});
			colunasResultadoSecoes.push({titulo: this.getMsg("MSG_VH_APR_38"), prop: "numQuestoes"});

			if (pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO.some(to => to.peso != null && to.peso != 1.0)) {
				colunasResultadoSecoes.push({titulo: this.getMsg("MSG_VH_APR_40"), prop: "peso", formato: "numero"});
			}

			if (resultadoProvaRealizadaTO.isResultadoPorMencao) {
				
				if (this.hasValue(pontuacaoDaProvaFeitaTO.listaExibicaoMencaoTO)) {
					colunasResultadoSecoes.push({titulo: this.getMsg("FP_FRONT_AplicacaoProvaVH_036"), prop:  (pontuacaoDaSecaoFeitaTO) => this.getNomeConceito(pontuacaoDaProvaFeitaTO.listaExibicaoMencaoTO, pontuacaoDaSecaoFeitaTO.codMencao)});
				}

			} else {

				colunasResultadoSecoes.push({titulo: this.getMsg("MSG_VH_APR_41"), prop: montarColunaPontuacao});

				if (pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO.some(to => this.hasValue(to.pontuacaoMinima))) {
					colunasResultadoSecoes.push({titulo: this.getMsg("MSG_VH_APR_42"), prop: "pontuacaoMinima", formato: "numero"});
				}
	
				if (pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO.some(to => to.pontuacaoDescontada > 0)) {
					colunasResultadoSecoes.push({titulo: this.getMsg("MSG_VH_APR_43"), prop: "pontuacaoDescontada", formato: "numero"});
				}
	
				if (pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO.some(to => this.hasValue(to.isAprovado))) {
					colunasResultadoSecoes.push({titulo: this.getMsg("MSG_VH_APR_44"), prop: "isAprovado"});;
				}
			}

			colunasResultadoSecoes.push({titulo: this.getMsg("MSG_VH_APR_39"), prop: montarColunaAcertosErros});

			let collection = [];
			
			pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO.forEach(pontuacaoDaSecaoFeitaTO => {
				pontuacaoDaSecaoFeitaTO.cod = pontuacaoDaSecaoFeitaTO.codSecaoFeita;
				collection.push(pontuacaoDaSecaoFeitaTO);
				pontuacaoDaSecaoFeitaTO.listPontuacaoPorDisciplinaTO.forEach(pontuacaoPorDisciplinaTO => {
					pontuacaoPorDisciplinaTO.cod = pontuacaoPorDisciplinaTO.codDisciplina;
					collection.push(pontuacaoPorDisciplinaTO);
				});
			});

			await this.addTabela({
				colunas: colunasResultadoSecoes,
				collection,
				id: "tabelaResultadoSecoes",
				propId: "cod",
				ordenar: false,
				classe: "table-hover",
				exibirTotalDeItens: false
			});

			this.addEspacamentoHorizontal("20px");
		}


		this.append("<div class='col-md-12 btn-group pull-right'>");

		if (resultadoProvaRealizadaTO.codAgendamentoUsuario != null && (resultadoProvaRealizadaTO.numTentativasRestantes != null && resultadoProvaRealizadaTO.numTentativasRestantes != 0) && resultadoProvaRealizadaTO.codUsuarioAvaliado == this.getCodUsuarioLogado()) {
			this.addBotao({
				label: this.getMsg("MSG_VH_APR_45"), css: "float: right", classe: "btn-primary",
				onClick: async (event) => {
					await inicioPFVH.verificarSePodeIniciarProvaAgendada(null, resultadoProvaRealizadaTO.codAgendamentoUsuario, event.target);
				}
			});
		}

		if (resultadoProvaRealizadaTO.isPermitirAbrirRecursos) {
			this.addBotao({
				id: "registrar_recursos_pf",
				label: "<i class='fa fa-legal'></i> " + this.getMsg("MSG_VH_AP_36"),
				css: "float: right",
				onClick: async () => await recursoVH.interporRecurso(pontuacaoDaProvaFeitaTO.codProvaFeita),
				classe: "btn-warning",
			});
		}

		if (resultadoProvaRealizadaTO.isAprovadoPorAgendamento === true && this.isCfgHabilitada("GERA_CERTIFICADOS")) {
			this.addBotao({
				label: "<i class='fa fa-certificate'></i>  " + this.getMsg("MSG_VH_APR_46"),
				css: "float: right",
				onClick: async () => await aplicacaoProvaVH.abrirCertificado(resultadoProvaRealizadaTO.codAgendamentoUsuario)
			});
		}

		if (resultadoProvaRealizadaTO.possuiPlanoMontagemProvasVigente && this.isAluno() && resultadoProvaRealizadaTO.codAgendamentoUsuario == null) {
			this.addBotao({ label: this.getMsg("MSG_VH_APR_47"), onClick: async () => await montagemProvaVH.fazerNovaProva(), classe: "btn-primary" });
		}
		this.append("</div>");

		if (aplicacaoProvaFeitaTO != null) {

			this.append("<div class='col-md-12'>");
			this.addTitulo({ texto: this.getMsg("MSG_VH_APR_188") });
			this.append(this.exibirAcertosEErrosDaQuestao(resultadoProvaRealizadaTO));
			this.append("</div>");

			if (resultadoProvaRealizadaTO.isVisualizacaoGabaritoAutorizada) {
				this.exibirGabarito(resultadoProvaRealizadaTO);
			}

			this.append(`
				<div aplicacao-correcao cod-prova-feita='" + aplicacaoProvaFeitaTO.codProvaFeita + "' class='col-md-12'>
			`);

			this.setIdTarget("tituloSuperiorDireito");
			this.append("<div class='btn-group' style='float: right'>");

			const urlsProctoring = resultadoProvaRealizadaTO.urlsProctoring;

			if (this.isAdministrador() && (urlsProctoring !== null && (urlsProctoring.urlTentativa || urlsProctoring.urlTodasTentativas)) && this.hasValue(resultadoProvaRealizadaTO.isEncerradaSistema)) {
				this.addBotaoDropdown({
					id: "dropdownAuditoriaProctoring",
					label: "<i class='fa fa-video-camera'></i> " + this.getMsg("FP_FRONT_AplicacaoProvaVH_010"),
					opcoes: [
						{
							label: this.getMsg("FP_FRONT_AplicacaoProvaVH_034"),
							onClick: () =>  {
								this.abrirNovaJanela(urlsProctoring.urlTentativa);
							}
						},
						{
							label: this.getMsg("FP_FRONT_AplicacaoProvaVH_035"),
							onClick: () => {
								this.abrirNovaJanela(urlsProctoring.urlTodasTentativas);
							}
						}
					]
				});
			}

			if (this.isAdministrador()) {
				this.addBotao({
					label: this.getMsg("FP_FRONT_AplicacaoProvaVH_011"),
					onClick: async () => await loginVH.exibirAcessosProvaFeita(aplicacaoProvaFeitaTO.codProvaFeita),
				});
			}
			
			const hasAcessoAnulacaoProva = UtilAuth.possuiAcesso(TipoFuncionalidade.TELA_CORRECAO_ANULACAO) || this.isAdministrador();
			const hasAcessoRemocaoAnulacaoProva = UtilAuth.possuiAcesso(TipoFuncionalidade.TELA_CORRECAO_REMOCAO_ANULACAO) || this.isAdministrador();

			const hasAcessoReaberturaProva = UtilAuth.possuiAcesso(TipoFuncionalidade.TELA_CORRECAO_REABERTURA_PROVA);
			const podeAlterarAplicacoes = UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_USUARIO_APLICACAO_ALTERACAO);

			if (!resultadoProvaRealizadaTO.dataEncerramentoAplicacao && !resultadoProvaRealizadaTO.codPFLogReabertura && (hasAcessoReaberturaProva || (podeAlterarAplicacoes && this.isAdministrador())) && pontuacaoDaProvaFeitaTO.notaObtida != null && [TipoAplicacao.P,TipoAplicacao.E].includes(resultadoProvaRealizadaTO.tipoAplicacao)) {
				this.addBotao({
					label: "Reabrir",
					onClick: () => {
						gestaoPFVH.reabrirProvaRespondida(aplicacaoProvaFeitaTO.codProvaFeita, () => {
							document.location.reload();
						});
					},
				});
			}

			if ((hasAcessoReaberturaProva || (podeAlterarAplicacoes && this.isAdministrador())) && resultadoProvaRealizadaTO.codPFLogReabertura && [TipoAplicacao.P,TipoAplicacao.E].includes(resultadoProvaRealizadaTO.tipoAplicacao)) {
				this.addBotao({
					label: this.getMsg("FP_FRONT_AplicacaoProvaVH_043"),
					classe: "btn btn-warning",
					onClick: () => {
						gestaoPFVH.editarReaberturaProvaRespondida(aplicacaoProvaFeitaTO.codProvaFeita, resultadoProvaRealizadaTO.codPFLogReabertura)
					},
				});
			}

			if (hasAcessoAnulacaoProva || hasAcessoRemocaoAnulacaoProva) {
				if (!resultadoProvaRealizadaTO.isAnulada && hasAcessoAnulacaoProva) {
					this.addBotao({
						label: this.getMsg("FP_FRONT_AplicacaoProvaVH_039"),
						onClick: () => {
							gestaoPFVH.anularProvaRespondida(aplicacaoProvaFeitaTO.codProvaFeita);
						},
					});
				} else if (resultadoProvaRealizadaTO.isAnulada && hasAcessoRemocaoAnulacaoProva) {
					this.addBotao({
						label: this.getMsg("FP_FRONT_AplicacaoProvaVH_040"),
						onClick: () => {
							gestaoPFVH.removerAnulacaoProvaRespondida(aplicacaoProvaFeitaTO.codProvaFeita);
						}
					});
				}
			}

			if (this.hasValue(aplicacaoProvaFeitaTO.nota)) {

				if (this.isAnalista() || this.isSecretaria() || (this.isAluno() && aplicacaoProvaFeitaTO.permitirExportacaoDOCXParaAluno)) {
					this.addBotaoDropdown({
						id: "dropdownExportacaoCorrecao", label: "<i class='fa fa-file'></i> " + this.getMsg("MSG_VH_APR_48"), opcoes: [
							{ label: "PDF", onClick: (event) => aplicacaoProvaVH.exportarParaDOCX(event, aplicacaoProvaFeitaTO.codProvaFeita, true) },
							{ label: "Word (DOCX)", onClick: (event) => aplicacaoProvaVH.exportarParaDOCX(event, aplicacaoProvaFeitaTO.codProvaFeita, false) }
						]
					});
				}

				if ((this.isAnalista() || this.isSecretaria()) && !resultadoProvaRealizadaTO.possuiCorrecaoPendente && resultadoProvaRealizadaTO.possuiSistemaIntegradoEnvioNotas) {
					this.addBotao({
						label: `<i class='fa fa-share-square'></i> ${this.getMsg("FP_FRONT_AplicacaoProvaVH_012")}`,
						dica: this.getMsg("FP_FRONT_AplicacaoProvaVH_013"),
						onClick: () => {
							this.exibirAlerta({
								titulo: `<i class='fa fa-share-square'></i> ${this.getMsg("FP_FRONT_AplicacaoProvaVH_014")}`,
								msg: this.getMsg("FP_FRONT_AplicacaoProvaVH_015"),
								botoes: [{
									label: this.getMsg("FP_FRONT_AplicacaoProvaVH_016"), 
									classe: "btn-primary", 
									onClick: async () => {
										
										const isNotaSeraEnviada = await this.call("AplicacaoProvaFCD/enviarNotaParaSistemaIntegrado", aplicacaoProvaFeitaTO.codProvaFeita);

										setTimeout(() => {
											if (isNotaSeraEnviada) {
												this.exibirAlerta({
													titulo: `<i class='fa fa-share-square'></i> ${this.getMsg("FP_FRONT_AplicacaoProvaVH_017")}`,
													msg: `
														${this.getMsg("FP_FRONT_AplicacaoProvaVH_018")}
														<br>
														<br>
														${this.getMsg("FP_FRONT_AplicacaoProvaVH_019")}
													`,
													botoes: [{ label: "OK" }]
												});
											} else {
												this.exibirAlerta({
													titulo: `<i class='fa fa-share-square'></i> ${this.getMsg("FP_FRONT_AplicacaoProvaVH_020")}`,
													msg: `
														<strong>
															${this.getMsg("FP_FRONT_AplicacaoProvaVH_021")}
														</strong>
														${this.getMsg("FP_FRONT_AplicacaoProvaVH_022", this.getCfg("LABEL_AVALIACAO"))}.
													`,
													botoes: [{ label: "OK" }]
												});
											}
										}, 500);
									}
								}]
							});
						},
					});
				}
			}

			this.append("</div>");

			this.setIdTarget(null);

			this.addEspacamentoHorizontal("10px");

			if (this.hasValue(resultadoProvaRealizadaTO.urlsImagensFRs) || resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas) {

				this.addTitulo({ texto: this.getMsg("MSG_VH_APR_49") });

				if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_FR_AJUSTE) && (this.hasValue(resultadoProvaRealizadaTO.urlsImagensFRs) || resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas)) {

					if (resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas && this.isAdministrador()) {
						this.addBotao({ 
							label: this.getMsg("FP_FRONT_AplicacaoProvaVH_023"), 
							id: "botao_ajustar_marcacoes", 
							classe: "btn-primary pull-right", 
							onClick: async () => {
								const numsQuestoes = [];
								const alternativasPorNumQuestao = {};

								for (const exibicaoQuestaoTO of aplicacaoProvaFeitaTO.collectionExibicaoQuestaoTO) {
									if (exibicaoQuestaoTO.tipoQuestao == "CE") {
										for (const exibicaoItemTO of exibicaoQuestaoTO.collectionExibicaoItemTO) {
											numsQuestoes.push(exibicaoItemTO.labelNumeracao);
											alternativasPorNumQuestao[exibicaoItemTO.labelNumeracao] = ["C", "E", "Z", "W"];
										}
									} else if (exibicaoQuestaoTO.tipoQuestao == "ME") {
										numsQuestoes.push(exibicaoQuestaoTO.numQuestaoNaProva);
										alternativasPorNumQuestao[exibicaoQuestaoTO.numQuestaoNaProva] = [];
										for (const exibicaoItemTO of exibicaoQuestaoTO.collectionExibicaoItemTO) {
											alternativasPorNumQuestao[exibicaoQuestaoTO.numQuestaoNaProva].push(exibicaoItemTO.labelNumeracao);
										}
										alternativasPorNumQuestao[exibicaoQuestaoTO.numQuestaoNaProva].push("Z", "W");
									}
								}

								$("[ajustes-marcacoes-respostas]").html("");
								await this.hide("#botao_ajustar_marcacoes");
								this.setIdTarget("[ajustes-marcacoes-respostas]");
								this.addSubsubtitulo(`<strong>${this.getMsg("FP_FRONT_AplicacaoProvaVH_024")}</strong>`);
								this.addTexto(this.getMsg("FP_FRONT_AplicacaoProvaVH_025"));
								this.append("<table style='width: 100%'><tr>");

								for (var i = 0; i < resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas.length; i++) {
									if (i > 0 && i % 10 == 0) {
										this.append("</tr><tr>");
									}
									let resposta = resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas[i];

									if (resposta.length > 1) {
										resposta = "W";
									}

									const numQuestao = numsQuestoes[i];
									if (numQuestao == null) continue;
									const alternativas = alternativasPorNumQuestao[numQuestao];
									if (alternativas == null) continue;
									this.append("<td><strong>" + numQuestao + "</strong> ");
									this.append("<select id='resposta_fr_" + i + "'>");
									for (const alternativa of alternativas) {
										this.append("<option value='" + alternativa + "' " + (resposta == alternativa ? "selected=selected" : "") + ">" + alternativa + "</option>");
									}
									this.append("</select></td>");
								}

								this.append("</tr></table>");
								this.addTexto(this.getMsg("FP_FRONT_AplicacaoProvaVH_026", "<strong>Z</strong>", "<strong>W</strong>"));
								this.append("<div class='btn-group' style='float: right'>");
								this.addBotao({
									label: this.getMsg("FP_FRONT_AplicacaoProvaVH_027"), 
									onClick: async () => {
										$("[ajustes-marcacoes-respostas]").html("");
										await this.show("#botao_ajustar_marcacoes");
									}
								});

								this.addBotao({
									label: this.getMsg("FP_FRONT_AplicacaoProvaVH_028"), 
									onClick: async () => {
										const salvamentoAjusteMarcacoesRespostasFRTO = {
											codProvaFeita: aplicacaoProvaFeitaTO.codProvaFeita,
											novasRespostasObjetivas: "",
											mapRespostasObjetivasAlteradas: {}
										}

										const novasRespostas = {};
										let indiceSelectAtual = 0;
										let numAlteracoes = 0;

										$("[id^='resposta_fr_']").each((i, resposta) => {
											const respostaAnterior = resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas[indiceSelectAtual];
											const valorSelect = $(resposta).val();

											salvamentoAjusteMarcacoesRespostasFRTO.novasRespostasObjetivas += valorSelect;

											if (valorSelect && valorSelect != respostaAnterior) {
												const numQuestao = numsQuestoes[indiceSelectAtual];
												salvamentoAjusteMarcacoesRespostasFRTO.mapRespostasObjetivasAlteradas[numQuestao] = valorSelect;
												numAlteracoes++;
											}

											indiceSelectAtual++;
										});

										if (resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas.length != salvamentoAjusteMarcacoesRespostasFRTO.novasRespostasObjetivas.length) {
											this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AplicacaoProvaVH_029") });
											return;
										}

										if (numAlteracoes == 0) {
											this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AplicacaoProvaVH_030") });
											return;
										}

										await this.call("ImportacaoCartaoRespostaFCD/ajustarLeituraMarcacoesRespostas", salvamentoAjusteMarcacoesRespostasFRTO);
										await this.call("ImportacaoCartaoRespostaFCD/recalcularPontuacaoProvaFeita", salvamentoAjusteMarcacoesRespostasFRTO.codProvaFeita);
										document.location.reload();
									}
								});

								this.append("</div>");

								this.setIdTarget(null);

								this.exibir();

								$("[id^='resposta_fr_']").select2();
							}
						});
					}
				}

				if (resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas) {
					this.append(`<p>${this.getMsg("MSG_VH_APR_50")}: ${resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas.join(" ")} <small>(${resultadoProvaRealizadaTO.respostasMarcadasNoCartaoDeRespostas.length})</small></p>`);
				}

				this.append("<div class='row'>");

				if (resultadoProvaRealizadaTO.urlsImagensFRs && resultadoProvaRealizadaTO.urlsImagensFRs.length > 0) {

					this.append(`
						<div class='col-md-8'>
							<div class='row'>
					`);

					for (let i = 0; i < resultadoProvaRealizadaTO.urlsImagensFRs.length; i++) {
						
						const url = resultadoProvaRealizadaTO.urlsImagensFRs[i];

						this.append(`
							<div class="imgThumb col-xs-12 col-sm-12 col-md-3 col-lg-3">
								<img class='img-thumbnail img-responsive img-thumbnail-resizable' src='${url}'>
							</div>
						`);

						if ((i + 1) % 4 == 0) {
							this.append("</div><div class='row'>");
						}
					}
					this.append("</div>");
					this.append("</div>");
					this.append("<div ajustes-marcacoes-respostas class='col-md-4'></div>");
				} else {
					this.append("<div ajustes-marcacoes-respostas class='col-md-12'></div>");
				}

				this.append("</div>");

				this.addEspacamentoHorizontal("10px");
			}

			this.addTitulo({ texto: this.getMsg("MSG_VH_APR_51") });

			if (aplicacaoProvaFeitaTO?.nomeProfessorResponsavel) {
				this.setSubtitulo(this.getMsg("MSG_VH_APR_52") + ": " + aplicacaoProvaFeitaTO.nomeProfessorResponsavel)
			}

			if (aplicacaoProvaFeitaTO?.htmlDaProva) {
				this.append(aplicacaoProvaFeitaTO.htmlDaProva);

			} else {
				exibicaoQuestaoVH.limparControleTextos();

				if (aplicacaoProvaFeitaTO != null) {

					let nomeUltimaSecao = null;

					const hashEdicao = UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 1) + "/";
					const hashAnulacao = UtilHash.getHash(cadastroQuestaoVH.anular) + "/";
					const hashAbaAnulacoes = UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 4) + "/";
					const hashCorrecaoDiscursiva = UtilHash.getHash(correcaoDiscursivasVH.exibirTelaCorrecao) + "/";

					this.append(`
						<div class="row">
					`);

					for (const exibicaoQuestaoTO of aplicacaoProvaFeitaTO.collectionExibicaoQuestaoTO) {

						const pontuacaoDaRespostaQuestaoTO = this.getPontuacaoDaRespostaQuestaoTO(pontuacaoDaProvaFeitaTO, exibicaoQuestaoTO);

						let ferramentas = [];

						if (exibicaoQuestaoTO.podeAlterarQuestao) {
							ferramentas.push(`
								<a class='btn btn-default btn-sm' data-placement='right' title='${this.getMsg("MSG_VH_APR_53")}' href='${hashEdicao}${exibicaoQuestaoTO.codQuestao}'>
									<i class='fa fa-pencil'></i>
								</a>
							`);
						}

						if (exibicaoQuestaoTO.podeAlterarQuestao || (this.isAdministrador() || UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_ANULACAO))) {
							if (exibicaoQuestaoTO.isAnulada) {
								ferramentas.push(`
									<a class='btn btn-default btn-sm' data-placement='right' 
											title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_253")}' 
											href='${hashAbaAnulacoes}${exibicaoQuestaoTO.codQuestao}'>
										<i class='fa fa-undo'></i>
									</a>
								`);
							} else {
								ferramentas.push(`
									<a class='btn btn-default btn-sm' data-placement='right' 
											title='${this.getMsg("FP_FRONT_AplicacaoProvaVH_031")}' 
											href='${hashAnulacao}${exibicaoQuestaoTO.codQuestao}'>
										<i class='fa fa-ban'></i>
									</a>
								`);
							}
						}

						if (exibicaoQuestaoTO.tipoQuestao == "DC" && this.isAdministrador()) {
							const exibicaoRQTO = exibicaoQuestaoTO.exibicaoRQTO;
							const exibicaoCorrecaoRQTO = exibicaoQuestaoTO.exibicaoCorrecaoRQTO;
							if (exibicaoRQTO && this.hasValue(exibicaoQuestaoTO.pontuacaoMaxima) && !this.hasValue(exibicaoCorrecaoRQTO?.pontuacaoObtida)) {
								ferramentas.push(`
									<a class='btn btn-default btn-sm' data-placement='right' 
											title='${this.getMsg("FP_FRONT_AplicacaoProvaVH_044")}' 
											href='${hashCorrecaoDiscursiva}${exibicaoRQTO.codRespostaQuestao}' target="_blank">
											<i class="fa fa-pencil-square"></i>
									</a>
								`);
							}
						}

						exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO, {
							isExibirNomeSecao: nomeUltimaSecao != exibicaoQuestaoTO.nomeSecao,
							conceito: pontuacaoDaRespostaQuestaoTO ? this.getNomeConceito(pontuacaoDaProvaFeitaTO.listaExibicaoMencaoTO, pontuacaoDaRespostaQuestaoTO.codMencao) : null,
							ferramentas: ferramentas,
							exibirConceito: resultadoProvaRealizadaTO.isResultadoPorMencao,
							exibirLabelRespostaQuestao: true
						});

						nomeUltimaSecao = exibicaoQuestaoTO.nomeSecao;

						this.append(`
							</div>
						`);
					}	
				}
			}

			this.append(`
				</div>
			`);
		}

		this.exibir();

		if (resultadoProvaRealizadaTO.possuiPlanoMontagemProvasVigente && this.isAluno() && resultadoProvaRealizadaTO.codAgendamentoUsuario == null) {
			this.append("<div class='col-md-12'>");
			this.addBotao({ label: this.getMsg("MSG_VH_APR_54"), onClick: async () => await montagemProvaVH.fazerNovaProva(), classe: "btn-primary" });
			this.append("</div>");
		}

		this.addEspacamentoHorizontal("10px");

		this.addGrupoBotoes({
			botoes: [{ 
				label: "&laquo; " + this.getMsg("MSG_VH_APR_55"), 
				onClick: () => UtilHash.voltar() 
			}]
		});

		this.addBotaoScrollParaTopo();

		this.addEspacamentoHorizontal("20px");

		this.exibir({ isAffixIdentificacaoConteudo: true, isDeveFazerScrollParaTitulo: true });

		const colunasTabelaInfoSecoes = $("#tabelaResultadoSecoes tbody tr:first td").length;

		for (const pontuacaoDaSecaoFeitaTO of pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO) {

			const $tr = $("tr[identificador='" + pontuacaoDaSecaoFeitaTO.codSecaoFeita + "']");
			$tr.addClass("active");
			$tr.find("td").css("line-height", "50px");
			$tr.find("td:first").css("font-weight", "bold");

			if (pontuacaoDaSecaoFeitaTO.mensagemDaSecao != null) {
				$("tr[identificador='" + pontuacaoDaSecaoFeitaTO.codSecaoFeita + "']").after("<tr><td colspan='" + colunasTabelaInfoSecoes + "'>" + pontuacaoDaSecaoFeitaTO.mensagemDaSecao + "</td></tr>");
			}
		}

		if (codQuestaoScrollTo != null) {
			UtilWindow.scrollTo("#div_questao_" + codQuestaoScrollTo);
		}

		if ((!resultadoProvaRealizadaTO.possuiCorrecaoPendente) || this.isAdministrador()) {
			const exibicaoGraficoTO = resultadoProvaRealizadaTO.exibicaoGraficoTOGeral
			const to = {};
			const cores = {};
			const labels = {};
			for (let i = 0; i < exibicaoGraficoTO.labels.length; i++) {
				const nomeAtributo = exibicaoGraficoTO.labels[i].toLowerCase().replace(/ /g, '_'); // Substitui espaços por underscores
				to[nomeAtributo] = exibicaoGraficoTO.valores[i];
				cores[nomeAtributo] = `#${exibicaoGraficoTO.cores[i]}`;
				labels[nomeAtributo] = exibicaoGraficoTO.labels[i];
			}

			$('#grafico_geral').append(`
				<div id="fp_grafico_pizza" class="col-md-12" style="height: 340px"></div>
			`)

			await UtilGrafico.criarEChart(`#fp_grafico_pizza`, {
				tos: [to],
				labels: labels,
				cores: cores,
				tipo: "PIZZA",
				showLabel: true,
				labelPosition: 'inside',
				nome: exibicaoGraficoTO.tituloGrafico
			});
		}

		if (this.isAdministradorSistema()) {
			gestaoPFVH.permitirAlteracaoRespostasDiscursivasDoAvaliado();
		}
	}

	getPontuacaoDaRespostaQuestaoTO(pontuacaoDaProvaFeitaTO: any, exibicaoQuestaoTO: any): any {
		if (!pontuacaoDaProvaFeitaTO._mapa) {

			pontuacaoDaProvaFeitaTO._mapa = {};
						
			pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO.forEach((pontuacaoDaSecaoFeitaTO: any) => {
				pontuacaoDaSecaoFeitaTO.listPontuacaoDaRespostaQuestaoTO.reduce((m: any, pontuacaoDaRespostaQuestaoTO: any) => {
					m[pontuacaoDaRespostaQuestaoTO.codRespostaQuestao] = pontuacaoDaRespostaQuestaoTO;
					return m;
				}, pontuacaoDaProvaFeitaTO._mapa);
			});
		}

		return pontuacaoDaProvaFeitaTO._mapa[exibicaoQuestaoTO.exibicaoRQTO?.codRespostaQuestao];
	}

	getNomeConceito(listaExibicaoMencaoTO: any, codMencao: number, isSigla: boolean = false): string {

		if (!listaExibicaoMencaoTO || !codMencao) return null;

		if (!listaExibicaoMencaoTO._mapa) {
			listaExibicaoMencaoTO._mapa = listaExibicaoMencaoTO.reduce((m, exibicaoMencaoTO) => {
				m[exibicaoMencaoTO.codMencao] = exibicaoMencaoTO.nomeMencao;
				return m;
			}, {});
		}

		const nome = listaExibicaoMencaoTO._mapa[codMencao];

		if (!isSigla) {
			return nome;
		}

		listaExibicaoMencaoTO._mapaSiglas = listaExibicaoMencaoTO._mapaSiglas || {};

		if (!listaExibicaoMencaoTO._mapaSiglas[codMencao]) {
			const palavras = nome.split(" ");
			let sigla = palavras.reduce((str, p) => {
				str = str + p.charAt(0);
				return str.toUpperCase();
			}, "");

			if (sigla.length == 1) {
				sigla = sigla + nome.charAt(1);
			}

			listaExibicaoMencaoTO._mapaSiglas[codMencao] = sigla.toUpperCase();
		}
		
		return listaExibicaoMencaoTO._mapaSiglas[codMencao];
	}

	getMsgAprovacaoReprovacao(pontuacaoDaProvaFeitaTO, resultadoProvaRealizadaTO) {

		if (pontuacaoDaProvaFeitaTO.notaObtida == null) {
			return null;
		}

		let foiDesclassificadoNasSecoes = false;
		let nomesSecoes = "";

		for (const pontuacaoDaSecaoFeitaTO of pontuacaoDaProvaFeitaTO.collectionPontuacaoDaSecaoFeitaTO) {

			if (pontuacaoDaSecaoFeitaTO.pontuacaoMinima != null && pontuacaoDaSecaoFeitaTO.pontuacaoObtida < pontuacaoDaSecaoFeitaTO.pontuacaoMinima) {

				foiDesclassificadoNasSecoes = true;

				if (this.hasValue(nomesSecoes)) {
					nomesSecoes += ", "
				}

				nomesSecoes += pontuacaoDaSecaoFeitaTO.nome;
			}
		}

		if (foiDesclassificadoNasSecoes) {
			return "<span class='text-danger'>" + this.getMsg("MSG_VH_APR_29") + " <strong>" + nomesSecoes + "</strong> " + this.getMsg("MSG_VH_APR_30") + "</span>";

		} else if (this.hasValue(pontuacaoDaProvaFeitaTO.pontuacaoMinima) && this.hasValue(resultadoProvaRealizadaTO.isAprovadoPorAgendamento)) {
			if (resultadoProvaRealizadaTO.isAprovadoPorAgendamento) {
				return "<span class='text-success'>" + this.getMsg("MSG_VH_APR_31") + "</span>";
			} else {
				return "<span class='text-danger'>" + this.getMsg("MSG_VH_APR_32") + "</span>";
			}
		}

		return null;
	}

	async exportarParaDOCX(event, codProvaFeita, isPDF) {
		const $opcao = $(event.target);
		const $btnGroup = $opcao.closest(".btn-group");
		$btnGroup.find("ul.dropdown-menu").remove();
		const $botaoDropdown = $btnGroup.find(".dropdown-toggle");
		$botaoDropdown.removeClass("dropdown-toggle").attr("download", "").removeAttr("aria-expanded").removeAttr("data-toggle");
		$botaoDropdown.find("span.caret").remove();
		$botaoDropdown.find("i").removeClass("fa-file fa-file-o").addClass("fa-spinner fa-pulse");

		const pathDocx = await this.call({
			endpoint: "AplicacaoProvaFCD/gerarDocxCorrecao",
			params: [codProvaFeita, isPDF],
			desativarMsgCarregando: true
		});

		UtilArmazenamento.aguardarArquivoDisponivel($botaoDropdown.get(0), pathDocx, "<i class='fa fa-file-o'></i> " + UtilArquivo.getNomeArquivo(pathDocx));
	}

	exibirAcertosEErrosDaQuestao(resultadoProvaRealizadaTO: any) {

		const aplicacaoProvaFeitaTO = resultadoProvaRealizadaTO.aplicacaoProvaFeitaTO;
		const pontuacaoDaProvaFeitaTO = resultadoProvaRealizadaTO.pontuacaoDaProvaFeitaTO;

		const progressbarAnulada = '<div class="progress-bar-warning"></div>';
		const progressbarEmBranco = '<div class="progress-bar-default"></div>';
		const progressbarAcerto = '<div class="progress-bar-success"></div>';
		const progressbarErro = '<div class="progress-bar-danger"></div>';

		const h = [];

		h.push(`
			<div id="mapa_questoes" class="row">
				<div class="col-md-8">
		`);

		let nomeUltimaSecaoMapa = null;
		let acertosEErrosDisciplinas = {};
		let isEmProva = this.isEmpty(resultadoProvaRealizadaTO.pontuacaoDaProvaFeitaTO?.notaObtida);
		let isExibirCorrecao = !this.isAluno() || !isEmProva;

		for (const exibicaoQuestaoTO of  aplicacaoProvaFeitaTO.collectionExibicaoQuestaoTO) {

			const exibicaoRQTO = exibicaoQuestaoTO.exibicaoRQTO;
			const exibicaoCorrecaoRQTO = exibicaoQuestaoTO.exibicaoCorrecaoRQTO;
			const pontuacaoDaRespostaQuestaoTO = this.getPontuacaoDaRespostaQuestaoTO(pontuacaoDaProvaFeitaTO, exibicaoQuestaoTO);

			let conceito = resultadoProvaRealizadaTO.isResultadoPorMencao && exibicaoCorrecaoRQTO && pontuacaoDaRespostaQuestaoTO 
				? this.getNomeConceito(pontuacaoDaProvaFeitaTO.listaExibicaoMencaoTO, pontuacaoDaRespostaQuestaoTO.codMencao, true) 
				: null;
			let numAcertos = null;
			let numErros = null;
			let numAnuladas = null;
			let numEmBranco = null;
			let numCorrecaoPendente = null;
			const nomeSecao = exibicaoQuestaoTO.nomeSecao || "";

			if (conceito) {
				conceito = `<small>${conceito}</small>`;
			} else {
				conceito = "";
			}

			if (!nomeUltimaSecaoMapa || nomeUltimaSecaoMapa !== nomeSecao) {

				if (nomeUltimaSecaoMapa) {
					h.push("</div></div>");
				}

				h.push(`
					<a class='btn btn-default col-md-12 nome-secao' onclick='aplicacaoProvaVH.scrollQuestao(${exibicaoQuestaoTO.codQuestao});'>
						${nomeSecao}
					</a>
					<div class='col-md-12 questoes-da-secao'>
						<div class="row">
				`);

				nomeUltimaSecaoMapa = nomeSecao;
			}

			if (exibicaoQuestaoTO.tipoQuestao == "CE") {
				
				for (let exibicaoItemTO of exibicaoQuestaoTO.collectionExibicaoItemTO) {

					const exibicaoRespostaItemTO = exibicaoItemTO.exibicaoRespostaItemTO;
					let progressbar = "";
					let classeBotao = this.hasValue(exibicaoRespostaItemTO?.resposta) ? "" : "em-branco";
					let texto = exibicaoItemTO.labelNumeracao;

					if (this.hasValue(exibicaoRespostaItemTO?.resposta)) {
						texto += " " + (exibicaoRespostaItemTO?.resposta ? "C" : "E");
					}

					if (isExibirCorrecao) {
						if (exibicaoItemTO.isAnulado || exibicaoQuestaoTO.isAnulada) {
							progressbar = progressbarAnulada;
						} else if (isEmProva && this.isEmpty(exibicaoRespostaItemTO?.resposta)) {
							progressbar = progressbarEmBranco;
						} else if (typeof exibicaoItemTO.correcaoItemCE === "boolean") {
							if (exibicaoItemTO.correcaoItemCE) {
								progressbar = progressbarAcerto;
							} else {
								progressbar = progressbarErro;
							}
						}
					}

					h.push(`
						<a class="btn btn-default numero-questao ${classeBotao}" onclick="aplicacaoProvaVH.scrollQuestao(${exibicaoQuestaoTO.codQuestao});">
							${texto}
							${progressbar}
							${conceito}
						</a>
					`);
				}

			} else {
				let progressbar = "";
				let texto = exibicaoQuestaoTO.numQuestaoNaProva || "-";
				let classeBotao = this.hasValue(exibicaoRQTO?.resposta) || this.hasValue(exibicaoRQTO?.pathImagemRespostaDiscursiva) || this.hasValue(exibicaoRQTO?.collectionExibicaoMidiaTO) ? "" : "em-branco";

				if (exibicaoRQTO?.resposta?.length === 1) {
					texto = texto + " " + exibicaoRQTO.resposta;
				}

				if (isExibirCorrecao) {
					if (exibicaoQuestaoTO.isAnulada) {
						progressbar = progressbarAnulada;
					} else if (isEmProva && this.isEmpty(exibicaoRQTO?.resposta)) {
						progressbar = progressbarEmBranco;
					} else if (typeof exibicaoCorrecaoRQTO.acertouQuestao === "boolean") {
						if (exibicaoCorrecaoRQTO.acertouQuestao) {
							progressbar = progressbarAcerto;
						} else {
							progressbar = progressbarErro;
						}
					} else { // acho que não chega aqui, mas fica pelo refactoring
						progressbar = progressbarEmBranco;
					}
				}

				h.push(`
					<a class="btn btn-default numero-questao ${classeBotao}" onclick="aplicacaoProvaVH.scrollQuestao(${exibicaoQuestaoTO.codQuestao});">
						${texto}
						${progressbar}
						${conceito}
					</a>
				`);
			}
			
			if (pontuacaoDaRespostaQuestaoTO) {
				if (this.isEmpty(pontuacaoDaRespostaQuestaoTO.pontuacaoObtida)) {
					numCorrecaoPendente += pontuacaoDaRespostaQuestaoTO.pontuacaoMaxima;

				} else if (exibicaoQuestaoTO.isAnulada) {
					numAnuladas += pontuacaoDaRespostaQuestaoTO.pontuacaoMaxima;

				} else if (!exibicaoQuestaoTO.exibicaoRQTO?.isRespondida) {
					numEmBranco += pontuacaoDaRespostaQuestaoTO.pontuacaoMaxima;

				} else {
					numAcertos += pontuacaoDaRespostaQuestaoTO.pontuacaoObtida;
					numErros += (pontuacaoDaRespostaQuestaoTO.pontuacaoMaxima - pontuacaoDaRespostaQuestaoTO.pontuacaoObtida);
				}
			}

			const getDisciplinaRaiz = (to) => {
				if (to.exibicaoDisciplinaTOPai) {
					return getDisciplinaRaiz(to.exibicaoDisciplinaTOPai);
				} else {
					return to;
				}
			}

			if (!exibicaoQuestaoTO.collectionExibicaoDisciplinaTO) continue;

			for (let to of exibicaoQuestaoTO.collectionExibicaoDisciplinaTO) {

				to = getDisciplinaRaiz(to);

				let tipoClassificacao = acertosEErrosDisciplinas[to.nomeTipoClassificacao];
				let disciplina = null;

				if (!tipoClassificacao) {
					acertosEErrosDisciplinas[to.nomeTipoClassificacao] = {};
					acertosEErrosDisciplinas[to.nomeTipoClassificacao][to.descricao] = {
						acertos: 0, erros: 0, embranco: 0, anuladas: 0, correcoesPendentes: 0, total: 0
					};

				} else {

					disciplina = acertosEErrosDisciplinas[to.nomeTipoClassificacao][to.descricao];

					if (!disciplina) {
						acertosEErrosDisciplinas[to.nomeTipoClassificacao][to.descricao] = {
							acertos: 0, erros: 0, embranco: 0, anuladas: 0, correcoesPendentes: 0, total: 0
						}
					}
				}

				if (!disciplina) {
					disciplina = acertosEErrosDisciplinas[to.nomeTipoClassificacao][to.descricao];
				}

				disciplina.acertos += numAcertos;
				disciplina.erros += numErros;
				disciplina.anuladas += numAnuladas;
				disciplina.embranco += numEmBranco;
				disciplina.correcoesPendentes += numCorrecaoPendente;

				disciplina.total = disciplina.acertos + disciplina.erros + disciplina.anuladas + disciplina.embranco + disciplina.correcoesPendentes;

				acertosEErrosDisciplinas[to.nomeTipoClassificacao][to.descricao] = disciplina;
			}
		}

		h.push(`
						</div>
					</div>
				</div>
		`);

		if (isExibirCorrecao) {
			h.push(`
				<div class="col-md-4 grafico-acerto-e-erros">
			`);

			Object.entries(acertosEErrosDisciplinas).forEach(([key, value]) => {
				h.push(`
					<div class="col-md-12 container-progress-acertos-erros">
						<div class="col-md-12 title-progress-acertos-erros">
							<span>${key}</span>
						</div>
				`);

				Object.entries(value).forEach(([key, value]) => {

					let porcAcertos = this.calcularPorcentagem(value.acertos, value.total, false);
					let porcBranco = this.calcularPorcentagem(value.embranco, value.total, false);
					let porcAnuladas = this.calcularPorcentagem(value.anuladas, value.total, false);
					let porcErros = this.calcularPorcentagem(value.erros, value.total, false);
					let porcCorrecoesPendentes = this.calcularPorcentagem(value.correcoesPendentes, value.total, false);

					let fixedPorcentagens = this.fixPorcentagemAcertosErros(porcAcertos, porcBranco, porcAnuladas, porcErros, porcCorrecoesPendentes, true);
					porcAcertos = fixedPorcentagens.porcAcertos;
					porcBranco = fixedPorcentagens.porcBranco;
					porcAnuladas = fixedPorcentagens.porcAnuladas;
					porcErros = fixedPorcentagens.porcErros;

					h.push(`
						<div class="col-md-5 subtitle-progress-acertos-erros">
							<span title="${key}">${key}</span>
						</div>
						<div class="col-md-7 progress-acertos-erros">
							<div class="progress">
								<div class="progress-bar progress-bar-success" title="${this.getMsg("MSG_VH_APR_33")}: ${UtilNumero.floatToString(value.acertos)}" style="width: ${porcAcertos}%"></div>
								<div class="progress-bar progress-bar-warning ttip" title="${this.getMsg("MSG_VH_APR_34")}: ${UtilNumero.floatToString(value.anuladas)}" style="width: ${porcAnuladas}%"></div>
								<div class="progress-bar progress-bar-default ttip" title="${this.getMsg("MSG_VH_APR_35")}: ${UtilNumero.floatToString(value.embranco)}" style="width: ${porcBranco}%"></div>
								<div class="progress-bar progress-bar-danger ttip" title="${this.getMsg("MSG_VH_APR_36")}: ${UtilNumero.floatToString(value.erros)}" style="width: ${porcErros}%"></div>
							</div>
						</div>
					`);
				});

				h.push("</div>");
			});

			h.push(`
				</div>
			`);
		}

		h.push(`
			</div>
		`);

		return h.join("");
	}

	async abrirCertificado(codAgendamentoUsuario) {

		const dadosGeracaoCertificaoTO = await this.call("EmissaoCertificadoFCD/recuperarInfoCertificado", codAgendamentoUsuario);
	
		this.addPopup({
			titulo: this.getMsg("MSG_VH_APR_62"),
			botoes: [{ label: "OK" }]
		});

		this.addLink({
			label: UtilArquivo.getNomeArquivo(dadosGeracaoCertificaoTO.caminhoCompletoPDF),
			href: dadosGeracaoCertificaoTO.caminhoCompletoPDF,
			css: "font-size: 130%",
			classe: "col-sm-12 fp-link-aguardar-geracao-arquivo"
		});

		this.addTexto("<br><br><br><small>" + this.getMsg("MSG_VH_APR_63") + " \"" + this.getMsg("MSG_VH_APR_64") + "\"</small>");

		this.exibirPopups();
	}

	async exibirCorrecaoRespostaQuestao(codRespostaQuestao) {

		UtilHash.registrarHistorico(this.exibirCorrecaoRespostaQuestao, codRespostaQuestao);

		const exibicaoCorrecaoRespostaQuestaoTO = await this.call("AplicacaoProvaFCD/recuperarCorrecaoRespostaQuestao", codRespostaQuestao);

		this.exibirDetalhesProvaRealizada(exibicaoCorrecaoRespostaQuestaoTO.codProvaFeita, exibicaoCorrecaoRespostaQuestaoTO.codQuestao)
	}

	exibirUploadFRIndividual(codAgendamentoUsuario, botao) {

		const id = "envio_fr_" + this.gerarId();
		let numArquivosEmEnvio = 0;
		const arquivosEnviados = [];
		let deuErro = false;

		this.addZonaUpload({
			id: id,
			label: this.getMsg("MSG_VH_APR_165"),
			css: "display: none",
			classe: "col-md-12",
			arquivosAceitos: ".png,.jpg,.gif,.jpeg,.pdf,.tif,.zip,.heic,.heif",
			maxWidthOuHeight: 1200,
			onAntesEnvio: (file) => {
				var $b = $(botao);
				$b.attr("disabled", "disabled").off("click").attr("onclick", "")
					.css("width", $b.outerWidth() + "px")
					.css("height", $b.outerHeight() + "px")
					.css("background-color", "white")
					.css("opacity", "1")
					.css("color", "#444")
					.css("padding", "0px")
					.addClass("progress");
				$b.html('<span class="progress-bar progress-bar-success progress-bar-striped active" style="padding: 5px 0px; text-align: left; " role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%">&emsp;' + file.name + ' </span>');
				numArquivosEmEnvio++;
			},
			onProgressoEnvio: (file, progresss0a100, bytesSent) => {
				const $b = $(botao);
				$b.find(".progress-bar").attr("aria-valuenow", progresss0a100).css("width", progresss0a100 + "%");
			},
			onErro: (file) => {
				const $b = $(botao);
				$b.removeClass("btn-success").addClass("btn-danger").css("padding", "6px 10px").html(this.getMsg("MSG_VH_APR_166"));
				deuErro = true;
			},
			onDepoisEnvio: async (file, uploadTO) => {

				if (deuErro) return;

				arquivosEnviados.push(uploadTO.pathArmazenamento);

				if (arquivosEnviados.length < numArquivosEmEnvio) return; // aguarda todos completarem

				const $b = $(botao);

				$b.css("padding", "6px 10px").css("background-color", "").css("color", "").addClass("btn-success").html(this.getMsg("MSG_VH_APR_167"));

				const resultadoEnvioFRPeloAvaliadoTO = await this.call({
					endpoint: "ImportacaoCartaoRespostaFCD/corrigirFRIndividual",
					params: [arquivosEnviados, codAgendamentoUsuario],
					msgCarregando: this.getMsg("MSG_VH_APR_168") + "...",
				});

				this.exibirAlerta({
					id: "alerta_processamento_frs_individuais",
					msg: `
						${this.getMsg("FP_FRONT_AplicacaoProvaVH_032")}
						<br><br>
						<p id="msgEvolucaoProcessamento"></p>
					`
				});

				const $msgEvolucaoProcessamento = $("#msgEvolucaoProcessamento");

				const verificar = async () => {

					const exibicaoCorrecoesTO = await this.call("ImportacaoCartaoRespostaFCD/recuperarProcessamentos", { idsPendentes: resultadoEnvioFRPeloAvaliadoTO.idsProcessamentos });

					let isTodasProcessadas = true;
					let semErros = true;

					if (!exibicaoCorrecoesTO || !exibicaoCorrecoesTO.listaExibicaoProcessamentoFRTO || exibicaoCorrecoesTO.listaExibicaoProcessamentoFRTO.length < 1) {
						$msgEvolucaoProcessamento.html(`<i class="fa fa-spinner fa-pulse"></i> ${this.getMsg("FP_FRONT_AplicacaoProvaVH_033")}`);
						isTodasProcessadas = false;

					} else {
						$msgEvolucaoProcessamento.html(exibicaoCorrecoesTO.listaExibicaoProcessamentoFRTO.map(exibicaoProcessamentoFRTO => {
							const h = [UtilArquivo.getNomeArquivoSemHifens(exibicaoProcessamentoFRTO.pathArquivoOriginal)];

							if (exibicaoProcessamentoFRTO.msgErro) {
								h.push(`: <strong>${exibicaoProcessamentoFRTO.msgErro}</strong>`);
								semErros = false;

							} else if (exibicaoProcessamentoFRTO.isExtracao && exibicaoProcessamentoFRTO.isImagemTratada) {
								h.push(`<i class="fa fa-check-square"></i>`)

							} else if (exibicaoProcessamentoFRTO.isImagemTratada && exibicaoProcessamentoFRTO.isCorrigida) {
								h.push(`<i class="fa fa-check-square"></i>`)

							} else {
								h.push(`<i class="fa fa-spinner fa-pulse"></i>`)
								isTodasProcessadas = false;
							}

							return h.join(" ");
						}).join("<br>"));
					}

					if (isTodasProcessadas && semErros) {
						this.fecharPopup("alerta_processamento_frs_individuais");
						telaInicialVH.iniciar(resultadoEnvioFRPeloAvaliadoTO.codAgendamento);
					} else if (!isTodasProcessadas) {
						setTimeout(verificar, 5000);
					}
				}

				verificar();
			}
		});

		this.exibir();

		$("#" + id).click();
	}

	scrollQuestao(codQuestao) {
		document.querySelector('#div_questao_' + codQuestao).scrollIntoView({ behavior: 'smooth' })
	}

	async realizarChecagemSistemaProctoring(codAgendamentoUsuario){
		const to = await this.call("AplicacaoProvaFCD/realizarSyscheckProctoring", codAgendamentoUsuario);

		if(to.url != null && !to.realizado){
			this.abrirNovaJanela(to.url);
		}
		else{
			await this.exibirAlerta({ msg: "O teste do seu sistema já foi concluído!" });
			UtilHash.carregarTelaInicial();
		}
	}

	exibirGabarito(resultadoProvaRealizadaTO) {

		this.append(`
			<div class="col-md-12">
		`);

		this.addTitulo({ texto: this.getMsg('MSG_VH_APR_209') });
		
		this.append(`
				<div class="row">
					<div class="col-md-8" style="padding-left: 17px">
		`);

		let nomeUltimaSecaoMapa = null;

		for (const exibicaoQuestaoTO of resultadoProvaRealizadaTO.aplicacaoProvaFeitaTO.collectionExibicaoQuestaoTO) {
			const nomeSecao = exibicaoQuestaoTO.nomeSecao || '';
			if (!nomeUltimaSecaoMapa || nomeUltimaSecaoMapa != nomeSecao) {
				if (nomeUltimaSecaoMapa) {
					this.append(`
								</div>
							</div>
					`);
				}
				this.append(`
							<a class='btn btn-default btn-secao col-md-12'
								onclick='aplicacaoProvaVH.scrollQuestao(${exibicaoQuestaoTO.codQuestao});'>
								${nomeSecao}
							</a>
							<div class='col-md-12' style="margin-left: -10px">
								<div class="row">
				`);
				nomeUltimaSecaoMapa = nomeSecao;
			}

			if (exibicaoQuestaoTO.tipoQuestao == "CE") {
				for (let exibicaoItemTO of exibicaoQuestaoTO.collectionExibicaoItemTO) {
					const gabaritoDoItem = exibicaoItemTO.gabaritoDoItem ? "C" : "E";
					this.append(`
									<div class="col-md-2">
										<a class="btn btn-default" style="border: none" onclick="aplicacaoProvaVH.scrollQuestao(${exibicaoQuestaoTO.codQuestao});">
											<strong>${exibicaoItemTO.labelNumeracao}</strong> ${gabaritoDoItem}
										</a>
									</div>
					`)
				}
			} else {
				const gabarito = exibicaoQuestaoTO.tipoQuestao == 'DC'? `<small class="label label-default">${this.getMsg('MSG_VH_APR_210')}</small>` : exibicaoQuestaoTO.gabaritoDaQuestao
				this.append(`
									<div class="col-md-2">
										<a class="btn btn-default" style="border: none" onclick="aplicacaoProvaVH.scrollQuestao(${exibicaoQuestaoTO.codQuestao});">
											<strong>${exibicaoQuestaoTO.numQuestaoNaProva}</strong> ${gabarito}
										</a>
									</div>
				`);
			}
		}

		this.append(`
							</div>
						</div>
					</div>
				</div>
			</div>
		`);
	}
}

const aplicacaoProvaVH = new AplicacaoProvaVH();

type ProvaDisponivelTO  = {
	// PROVA
	codProva: number;
	isResultadoPorMencao: boolean;
	tituloProva: string;
	// APLICAÇÃO
	codAgendamento: number;
	dataEncerramento: string;
	tipoAplicacao: TipoAplicacao;
	// FASE
	tipoAplicacaoFase: TipoAplicacaoFase;
	dataInicioFase: string;
	dataFimFase: string;
	numReaplicacao: number;
	isVisualizacaoResultadoAutorizada: boolean;
	jaPassou: boolean;
	isDentroPeriodo: boolean;
	isFutura: boolean;
	// AU
	codAgendamentoUsuario: number;
	isAtingiuPontuacaoMinima: boolean;
	// PF
	codProvaFeita: number;
	nota: number;
	isAnulada: boolean;
	isProvaFeitaIniciada: boolean;
	isProvaFeitaFinalizada: boolean;
	codMencao: number;
	descricaoMencao: string;
	isCorrecaoPendente: boolean;
	isReaberturaVigente: boolean;
}