class FacematchPFVH extends PFVH {
	
	alertasSeguirSemFacematchJaExibidosPorCodProvaFeita: any;
    streams: MediaStream[] = [];

	constructor() {
		super(FacematchPFVH.name);
	}
	
	async iniciarValidacao(situacaoFacematchValidacaoTO: SituacaoFacematchValidacaoTO) {

		if (!situacaoFacematchValidacaoTO) return;

		const bloquear = (motivo) => {
			UtilHash.carregarTelaInicial();
			throw new Error(motivo);
		}

		if (situacaoFacematchValidacaoTO.isPrecisaAguardarValidacaoManual) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_FacematchVH_018") });
			bloquear("Precisa aguardar reconhecimento facial manual.");
		}

		if (situacaoFacematchValidacaoTO.isPodeProsseguirSemValidacao) {
			return;
		}

		if (situacaoFacematchValidacaoTO.isPrecisaFazerUploadDoc) {
			const isUploadOK = await meusDadosVH.uploadDocIdentidade(this.getCodUsuarioLogado());
			if (!isUploadOK) {
				bloquear("Não teve sucesso com o upload do documento.");
			}
		}
	
		const cfgs: ReconhecimentoFacial = {
			codProvaFeita: situacaoFacematchValidacaoTO.codProvaFeita,
			numTentativasRestantes: situacaoFacematchValidacaoTO.numTentativasRestantes,
			isPermiteSeguirSemReconhecimento: situacaoFacematchValidacaoTO.isPermiteSeguirSemReconhecimento,
			isRevalidacao: false
		}

		const podeProsseguir = await this.exibirConfirmacaoFacial(cfgs);

		if (!podeProsseguir) {
			bloquear("Acesso bloqueado pelo facematch: sem sucesso no reconhecimento facial");
		}
	}

	async iniciarRevalidacao(situacaoFacematchRevalidacaoTO: SituacaoFacematchRevalidacaoTO) {

		if (this.isEmpty(situacaoFacematchRevalidacaoTO?.numTentativasPermitido)
			|| this.isEmpty(situacaoFacematchRevalidacaoTO?.minutosIntervaloRevalidacao)) return;

		try {
			const crono = new CronoRevalidacaoFaceMatchVH();
			const $aplicacaoProva = this.get$AplicacaoProva();

			if ($aplicacaoProva === null) return;

			crono.iniciarCronometro(situacaoFacematchRevalidacaoTO.msParaProximaRevalidacao, async () => {

				if ($aplicacaoProva.closest("body").length === 0) {
					this.logger.info("Abortando revalidação pois prova foi fechada.");
					return;
				}

				const dados: ReconhecimentoFacial = {
					codProvaFeita: situacaoFacematchRevalidacaoTO.codProvaFeita,
					numTentativasRestantes: situacaoFacematchRevalidacaoTO.numTentativasRestantes,
					isPermiteSeguirSemReconhecimento: situacaoFacematchRevalidacaoTO.isPermiteSeguirSemReconhecimento,
					isRevalidacao: true
				}
		
				const podeProsseguir = await this.exibirConfirmacaoFacial(dados);
		
				if (!podeProsseguir) {
					this.logger.error("Acesso bloqueado pelo facematch: sem sucesso na revalidação facial");
					UtilHash.carregarTelaInicial();
				}

				if (dados.passou || situacaoFacematchRevalidacaoTO.isPermiteSeguirSemReconhecimento) {
					situacaoFacematchRevalidacaoTO.numTentativasRestantes = situacaoFacematchRevalidacaoTO.numTentativasPermitido;
					situacaoFacematchRevalidacaoTO.msParaProximaRevalidacao = situacaoFacematchRevalidacaoTO.minutosIntervaloRevalidacao * 60_000;
					await this.iniciarRevalidacao(situacaoFacematchRevalidacaoTO);
				}

			});
		} catch (e) {
			this.logger.erro("Erro ao iniciar revalidação", e);
		}
	}

    private async exibirMsgAguardarValidacaoManual() {
        await this.exibirAlerta({
            titulo: `<i class='fa fa-exclamation-circle'></i> ${this.getMsg("FP_FRONT_FacematchVH_014")}`,
            msg: `
                ${this.getMsg("FP_FRONT_FacematchVH_015")}
                <br>
                ${this.getMsg("FP_FRONT_FacematchVH_016")}
                <br>
				${this.getMsg("FP_FRONT_FacematchVH_018")}
            `,
            botoes: [{ label: "OK" }]
        });
    }

    private async exibirMsgSeguirSemReconhecimentoFacial(codProvaFeita: number) {
        this.alertasSeguirSemFacematchJaExibidosPorCodProvaFeita = this.alertasSeguirSemFacematchJaExibidosPorCodProvaFeita || {};
        const alertaJaExibido = this.alertasSeguirSemFacematchJaExibidosPorCodProvaFeita[codProvaFeita];

        if (alertaJaExibido !== true) {
				
			this.alertasSeguirSemFacematchJaExibidosPorCodProvaFeita[codProvaFeita] = true;
			
			await this.exibirAlerta({
				titulo: `<i class='fa fa-exclamation-circle'></i> ${this.getMsg("FP_FRONT_FacematchVH_025")}`,
				msg: `
					${this.getMsg("FP_FRONT_FacematchVH_023")}
					<br>
					<br>
					${this.getMsg("FP_FRONT_FacematchVH_024")}
				`,
				botoes: [{
					label: "Continuar", 
					classe: "btn-primary"
				}]
			});
		}
    }

    private async exibirAlertaFalsaIdentidade(codProvaFeita: any): Promise<void> {

		const idStorage = "fp_facematch_alerta_falsa_identidade_" + this.getCodUsuarioLogado() + "_" + codProvaFeita;

		if (localStorage.getItem(idStorage)) return;

		return new Promise((resolve, reject) => {
			this.addPopup({
				css: "width: 80%; height: 90%;",
				id: "popup_alerta_falsa_identidade",
				titulo: this.getMsg("FP_FRONT_FacematchVH_028"),
				onHide: async () => {
	
					const deuOK = localStorage.getItem(idStorage);
	
					if (deuOK) {
						resolve();

					} else {
						await this.exibirAlerta({
							titulo: `<i class='fa fa-exclamation-circle'></i> ${this.getMsg("FP_FRONT_FacematchVH_028")}`,
							msg: this.getMsg("FP_FRONT_FacematchVH_031"),
							botoes: [{ label: this.getMsg("FP_FRONT_FacematchVH_032") }]
						});
						reject(new Error("Não aceitou alerta de falsa identidade"));
					}
				},
				botoes: [{
					id: "botaoAceiteTermosFalsaIdentidade",
					label: this.getMsg("FP_FRONT_FacematchVH_029"),
					classe: "btn-primary",
					onClick: async () => {
						localStorage.setItem(idStorage, "true");
					}
				}]
			});
	
			this.append(`
				<div class="col-md-12">
					${this.getMsg("FP_FRONT_FacematchVH_030")}
				</div>
			`);
	
			this.exibirPopups();
		});
    }

	private async exibirConfirmacaoFacial(cfgs: ReconhecimentoFacial): Promise<boolean> {

        await this.exibirAlertaFalsaIdentidade(cfgs.codProvaFeita);

		let resolve = null;

		const promise = new Promise<boolean>((res) => {
			resolve = res;
		});

		const tempoEmMs = 90_000;
		let ultimoClick = Date.now();
		let podeProsseguir = false;
		const crono = new CronoFaceMatchVH();

	    this.addPopup({
			fullWidth: true,
            id: "modal_confirmacao_facial",
            titulo: this.getMsg("FP_FRONT_FacematchVH_001"),
			onShown: async () => {

				await this.iniciarVideoWebcam();
				await this.entrarEmModoDeCaptura();

				if (cfgs.isRevalidacao) {
					cronoPFVH.pausarCronometros();
					const $tempoElement = $(`#cron-facematch b`);
					crono.iniciarCronometro(tempoEmMs, $tempoElement, async () => {
						await this.call("FaceMatchFCD/erroRevalidacaoFaceMatch", cfgs.codProvaFeita);
						cfgs.numTentativasRestantes = 0;
						podeProsseguir = await this.analisarResultado(cfgs, null);
						this.fecharPopup("modal_confirmacao_facial");
					})
				}
			},
            onHide: async () => {
				crono.pararCronometro();
				this.pararVideoWebcam();
				this.pararStreams();
				await this.sleep(500);
				$('.container-facematch').remove();
            },
			onHidden: () => {
				if (cfgs.isRevalidacao) {
					const milisegundosGastoRevalidacao = tempoEmMs - crono.milisegundosRestantes;
					try {
						this.call("FaceMatchFCD/registrarTempoGastoRevalidacao", cfgs.codProvaFeita, milisegundosGastoRevalidacao);
					} catch (e) {
						this.logger.error("Erro ao registrar tempo gasto na revalidação", e);
					}
					cronoPFVH.retomarCronometros();
				}
				resolve(podeProsseguir);
			},
            botoes: [{
                id: "botaoCapturarSelfie",
                label: this.getMsg("FP_FRONT_FacematchVH_002"),
                classe: "btn-primary",
                onClick: async () => {
                    await this.capturarSelfie();
                    return false;
                }
            }, {
                id: "botaoLimparCaptura",
                label: this.getMsg("FP_FRONT_FacematchVH_003"),
                classe: "btn-secondary",
                css: "display: none",
                onClick: async () => {
					await this.entrarEmModoDeCaptura();
                    return false;
                }
            }, {
                label: this.getMsg("FP_FRONT_FacematchVH_004"),
                id: "botaoEnviarFacematch",
                classe: "btn-success",
                onClick: async () => {

					ultimoClick = await this.abortarSeDuploClique(ultimoClick);

					const resultadoComparacaoTO = await this.validarFotoSelfie(cfgs);
					podeProsseguir = await this.analisarResultado(cfgs, resultadoComparacaoTO);

					if (podeProsseguir || cfgs.numTentativasRestantes <= 0) {
						// não precisa ou não pode mais tentar
						// fecha popup
						return true;
					}

					await this.entrarEmModoDeCaptura();
					return false;
                }
            }]
        });

		const cron = cfgs.isRevalidacao ?
            ` <div id="cron-facematch">   
                <span>${this.getMsg('FP_FRONT_FacematchVH_026')}</span>
                <i class="fas fa-clock"></i>			
                <b>${UtilTempo.getTempoFormatado(tempoEmMs)}</b>
                <span>${this.getMsg('FP_FRONT_FacematchVH_027')}</span>
             </div>`
            : '';

        
			this.append(`
				<div class="container-facematch">
				${cron}
				<div class="main-facematch">
					<video id="video-facematch" playsinline></video>
					<img id="face-mask-webcam" src="widgets/img/face_mask_webcam_cropped.png" alt="Máscara Rosto"/>
				</div>
				<div class="container-facematch-alerta">
		`);
		
        this.addTextoAlerta({
            id: "alerta_validacao_facial",
			texto: "",
            visivel: false,
            css: 'width: 100%'
        });

        this.append(`
				</div>
			</div>
		`);

        this.exibirPopups();

		return promise;
    }

	private async abortarSeDuploClique(ultimoClick: number): Promise<number> {
		const agora = Date.now();

		if (this.isEmpty(ultimoClick)) return agora;
		
		if (agora - ultimoClick < 3000) {
			await this.sleep(2000); // segura o botão desabilitado
			throw new Error("Facematch prevenindo duplo clique");
		}

		return agora;
	}

	private async iniciarVideoWebcam() {
		const stream: MediaStream = await this.solicitarPermissoesWebcam();

		if (!stream) return;

		const $video = $("#video-facematch");

		try {
			const video: HTMLVideoElement = $video.get(0);
			video.srcObject = stream;
			video.onplay = () => this.ajustarLayoutPopup();

		} catch (e) {
			this.logger.error("Erro no tocarVideo", e);
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AmaisVH_046") });
		}
	}

	private async entrarEmModoDeCaptura() {
		
		await this.disable('botaoEnviarFacematch');
		await this.trocarMsgAlerta(this.getMsg("FP_FRONT_FacematchVH_005"));
		const video: HTMLVideoElement = document.querySelector("#video-facematch");
		await video.play();
		await this.show("face-mask-webcam");
		await this.show("botaoCapturarSelfie");
		await this.hide("botaoLimparCaptura");
	}

	private async trocarMsgAlerta(msg: string) {
		await $("#alerta_validacao_facial").fadeOut();
		setTimeout(() => $("#alerta_validacao_facial").html(msg).fadeIn(), 500);
	}

	private async capturarSelfie() {

		await this.hide("botaoCapturarSelfie");
		await this.show("botaoLimparCaptura");
		
		const video = <HTMLVideoElement> document.getElementById("video-facematch");
		
		video.pause();
		
		await this.hide("face-mask-webcam");
		await this.enable('botaoEnviarFacematch');
		await this.trocarMsgAlerta("Envie a foto para reconhecimento facial");
	}

	private async solicitarPermissoesWebcam(): Promise<MediaStream> {

		let stream: MediaStream = null;

		try {
			stream = await navigator.mediaDevices.getUserMedia({ 
				video: { facingMode: "user" }, 
				audio: false 
			});
			
		} catch (e) {
			this.logger.error("Erro no getUserMedia", e);
		}
		
		if (stream) {
			this.streams.push(stream);
			return stream;

		} else {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AmaisVH_046") });
		}
	}

	private pararVideoWebcam() {
		const video: HTMLVideoElement = document.querySelector("#video-facematch");
		if (!video) return;
		try {
			const stream: MediaStream = <MediaStream> video.srcObject;
			stream.getTracks().forEach(track => track.stop());
		} catch (ignored) {}
	}

	private pararStreams() {
		for (const stream of this.streams) {
			try {
				stream.getTracks().forEach(track => track.stop());
			} catch (ignored) {}
		}
		this.streams = [];
	}

	private async validarFotoSelfie(cfgs: ReconhecimentoFacial): Promise<any> {

		const div = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_FacematchVH_006"));

		try {

			await this.trocarMsgAlerta(this.getMsg("FP_FRONT_FacematchVH_045"));

			const video = <HTMLVideoElement> document.getElementById("video-facematch");

			try {
				if (!video.paused) video.pause();
			} catch (ignored) {}

			const blobSelfie = await UtilImg.capturarFrame(video);
			const uploadTO = await UtilArmazenamento.upload(blobSelfie, true);

			// uploadTO.base64 = await UtilImg.blobToBase64(blobSelfie); // isBase64!!

			div.remove();

			const validarFotoSelfieTO = {
				uploadTOSelfie: uploadTO,
				codProvaFeita: cfgs.codProvaFeita,
				isRevalidacao: cfgs.isRevalidacao,
			}

			const resultadoComparacaoTO = await this.call("FaceMatchFCD/validarFotoSelfie", validarFotoSelfieTO);

			this.logger.info(`validarFotoSelfie retornou: ${JSON.stringify(resultadoComparacaoTO)}`);

			return resultadoComparacaoTO;

		} finally {
			div.remove();
		}
    }

	private async analisarResultado(cfgs: ReconhecimentoFacial, resultadoComparacaoTO: any): Promise<boolean> {

		try {
			const isValid = resultadoComparacaoTO?.similaridade > 60;

			cfgs.numTentativasRestantes--;

			// FACEMATCH OK

			if (resultadoComparacaoTO?.code === "success" && isValid) {
				cfgs.passou = true;
				return true;
			}

			// TENTATIVAS ESGOTADAS

			if (cfgs.numTentativasRestantes <= 0) {

				await this.call("FaceMatchFCD/enviarParaAnaliseManual", cfgs.codProvaFeita, cfgs.isRevalidacao);
				
				if (cfgs.isPermiteSeguirSemReconhecimento === true) {
					await this.exibirMsgSeguirSemReconhecimentoFacial(cfgs.codProvaFeita);
					return true;

				} else {
					await this.exibirMsgAguardarValidacaoManual();
					return false;
				}
			}

			// TRATANDO ERROS

			let msgErro = this.getMsg("FP_FRONT_FacematchVH_008");

			if (resultadoComparacaoTO?.code === "error" && resultadoComparacaoTO?.msg) {
				msgErro = resultadoComparacaoTO.msg;
			}

			const detalhesHtml = this.gerarHtmlDetalhes(resultadoComparacaoTO);

			await this.exibirAlerta({
				titulo: `<i class='fa fa-exclamation-circle'></i> ${this.getMsg("FP_FRONT_FacematchVH_042")}`,
				msg: `
					${this.getMsg("FP_FRONT_FacematchVH_011")}
					<br/>
					${msgErro}
					<br/>
					${this.getMsg("FP_FRONT_FacematchVH_013", cfgs.numTentativasRestantes)}
					${detalhesHtml}
					<br/><br/>
					${this.getMsg("FP_FRONT_FacematchVH_012")}
				`,
				botoes: [{ label: "OK" }]
			});

		} catch (e) {
			this.logger.error("Erro ao realizar nova tentativa", e);
		}

		return false;
    }

    private gerarHtmlDetalhes(resultadoComparacaoTO?: any) {

        let detalhesHtml = "";

        if (resultadoComparacaoTO?.detalhes) {
            const details = resultadoComparacaoTO.detalhes;
            const atendido = `
                <div class="check-atendido-facematch">
                    <i class="fa fa-check"></i> ${this.getMsg("FP_FRONT_FacematchVH_033")}
                </div>
            `;
            const naoAtendido = `
                <div class="check-naoatendido-facematch">
                    <i class="fa fa-exclamation-triangle"></i> ${this.getMsg("FP_FRONT_FacematchVH_034")}
                </div>
            `;

            detalhesHtml = `
                <br/>
                <h4>${this.getMsg("FP_FRONT_FacematchVH_035")}: </h4>
                <ul>
					<li>${this.getMsg("FP_FRONT_FacematchVH_044")}</li>
                    ${naoAtendido}
                    <li>${this.getMsg("FP_FRONT_FacematchVH_036")}</li>
                    ${details.faceIsClear === true ? atendido : naoAtendido}
                    <li>${this.getMsg("FP_FRONT_FacematchVH_037")}</li>
                    ${details.hasFace === true ? atendido : naoAtendido}
                    <li>${this.getMsg("FP_FRONT_FacematchVH_038")}</li>
                    ${details.faceIsCentered === true ? atendido : naoAtendido}
                    <li>${this.getMsg("FP_FRONT_FacematchVH_039")}</li>
                    ${details.eyesOpen === true ? atendido : naoAtendido}
                    <li>${this.getMsg("FP_FRONT_FacematchVH_040")}</li>
                    ${details.faceIsNotOccluded === true ? atendido : naoAtendido}
                    <li>${this.getMsg("FP_FRONT_FacematchVH_041")}</li>
                    ${details.hasOneFace === true ? atendido : naoAtendido}
                </ul>
            `;
        }

        return detalhesHtml;
    }

	async ajustarLayoutPopup() {

		const c = $("#modal_confirmacao_facial").closest(".modal").find(".modal-content").get(0);
		const heightSaindoDoVieport = c.scrollHeight - c.clientHeight;

		if (heightSaindoDoVieport <= 0) return;

		const v = document.getElementById("video-facematch");
		const maxHeightVideo = v.clientHeight - heightSaindoDoVieport;

		$(v).css("max-height", maxHeightVideo + "px");
	}
}

const facematchPFVH = new FacematchPFVH();

type ReconhecimentoFacial = {
	// request
	codProvaFeita: number;
	numTentativasRestantes: number;
	isPermiteSeguirSemReconhecimento: boolean;
	isRevalidacao: boolean;
	// response
	passou?: boolean;
}
type SituacaoFacematchValidacaoTO = {
	codProvaFeita: number;
	isPrecisaAguardarValidacaoManual: boolean;
	isPodeProsseguirSemValidacao: boolean;
	isPrecisaFazerUploadDoc: boolean;
	numTentativasRestantes: number;
	isPermiteSeguirSemReconhecimento: boolean;
}
type SituacaoFacematchRevalidacaoTO = {
	codProvaFeita: number;
	numTentativasPermitido: number;	
	minutosIntervaloRevalidacao: number;
	numTentativasRestantes: number;
	isPermiteSeguirSemReconhecimento: boolean;
	msParaProximaRevalidacao: number;
}