class FPProctoringService extends ProctoringService {

	static BACKEND_URL = "https://proctoring-service.imaginie.com.br";

	msgs: any;
	proctoring: any;
	subject: any;
	intervalCheckWebcam: any;
	intervalCheckSession: any;
	
	constructor() {
		
		super(FPProctoringService.name);

		this.msgs = {
			"ERR_SCREENSHARE_NOT_SUPPORTED": this.getMsg("FP_FRONT_FPProctoringService_001"),
			"ERR_SHARING_WEBCAM": this.getMsg("FP_FRONT_FPProctoringService_002"),
			"ERR_SHARING_SCREEN": this.getMsg("FP_FRONT_FPProctoringService_003"),
			"ERR_STOP_SCREEN_STREAM": this.getMsg("FP_FRONT_FPProctoringService_004"),
			"ERR_STOP_WEBCAM_STREAM": this.getMsg("FP_FRONT_FPProctoringService_005"),
			"ERR_FAILED_TO_CONNECT": this.getMsg("FP_FRONT_FPProctoringService_006"),
			"ERR_UNKNOWN_ERROR": this.getMsg("FP_FRONT_FPProctoringService_007"),
			"ERR_URL_NOT_ALLOWED": this.getMsg("FP_FRONT_FPProctoringService_032"),
			"COMPLETED": this.getMsg("FP_FRONT_FPProctoringService_008"),
			"WEBCAM_INITIALIZED": this.getMsg("FP_FRONT_FPProctoringService_009"),
			"SCREENSHARE_INITIALIZED": this.getMsg("FP_FRONT_FPProctoringService_010"),
			"ERR_WEBCAM_STREAMING": this.getMsg("FP_FRONT_FPProctoringService_021"),
			"ERR_SEND_VIDEO_PARTS": this.getMsg("FP_FRONT_FPProctoringService_023"),
			"ERR_SHARING_SCREEN_STREAM": this.getMsg("FP_FRONT_FPProctoringService_027"),
			"ERR_SHARING_ENTIRE_SCREEN": this.getMsg("FP_FRONT_FPProctoringService_028"),
			"ERR_MULTIPLE_SCREENS_DETECTED": this.getMsg("FP_FRONT_FPProctoringService_029")
		}
	}
	
	async pedirAutorizacoesUsuario(onAutorizacoesConcedidas: Function) {

		const { Proctoring } = await UtilBoot.carregarProctoringFP();

		try {
			const isScreenshotsHabilitado = this.fiscalizacaoMonitoradaTO.isScreenshotsHabilitado && this.fiscalizacaoMonitoradaTO.isScreenshotsHabilitado === true;
			const tokenJwt = this.fiscalizacaoMonitoradaTO.dadosSessao.token_jwt;
			const awsCredentials = this.fiscalizacaoMonitoradaTO.dadosSessao.aws_credentials;

			if(!tokenJwt) {
				this.triggerErro({
					msgUsuario: UtilMsg.getMsg("FP_FRONT_FPProctoringService_033"),
					msgAuditoria: this.getMsg("FP_FRONT_FPProctoringService_012"),
					msgIntegracaoAuditoria: UtilMsg.getMsg("FP_FRONT_FPProctoringService_033")
				});
			}

			if(!awsCredentials) {
				this.triggerErro({
					msgUsuario: UtilMsg.getMsg("FP_FRONT_FPProctoringService_034"),
					msgAuditoria: this.getMsg("FP_FRONT_FPProctoringService_012"),
					msgIntegracaoAuditoria: UtilMsg.getMsg("FP_FRONT_FPProctoringService_034"),
				});
			}

			this.proctoring = new Proctoring(
				this.fiscalizacaoMonitoradaTO.dadosSessao.ext_session_id, 20, false,
				isScreenshotsHabilitado, tokenJwt, awsCredentials
			);

			const clockCheckResult = await this.proctoring.checkClockTime();

			if (clockCheckResult && clockCheckResult.clock_right === false) {

				let localTime = moment();
				let serverTime = moment(clockCheckResult.server_datetime_utc).add(1, 'seconds');
				let timezoneGuess = moment.tz.guess(true);
				let timezone = localTime.format("Z");

				let html = `
					${this.getMsg("FP_FRONT_FPProctoringService_019")}
					</br>
					<div id="container-clockresult">
						<div id="server-time">
							<span id="label"><strong>Hora certa: </strong></span> 
							<span id="value" data-servertime="${serverTime.format()}">
								${serverTime.format("L LTS")}
							</span>
							<span id="timezone"> ${timezone} (${timezoneGuess}) </span>
						</div>
						<div id="local-time">
							<span id="label"><strong>Sua hora: </strong></span> 
							<span id="value" data-localtime="${localTime.format()}">
								${localTime.format("L LTS")}
							</span>
							<span id="timezone"> ${timezone} (${timezoneGuess}) </span>
						</div>
						<div id="dst">
							<span id="label"><strong>Horário de verão: </strong></span> 
							<span id="value">
								${moment().isDST() ? "Sim": "Não"}
							</span>
						</div>
					</div>
				`;

				this.triggerErro({
					msgUsuario: html,
					msgAuditoria: this.getMsg("FP_FRONT_FPProctoringService_020"),
					msgIntegracaoAuditoria: clockCheckResult
				});

				let intervalClockResult = setInterval(() => {
					let selectorHoraLocal = $("#container-clockresult #local-time #value");
					let horaLocal = moment(selectorHoraLocal.data("localtime")).add(1, 'seconds');

					let selectorHoraServer = $("#container-clockresult #server-time #value");
					let horaServer = moment(selectorHoraServer.data("servertime")).add(1, 'seconds');

					selectorHoraLocal.html(horaLocal.format('L LTS'));
					selectorHoraServer.html(horaServer.format('L LTS'));
					selectorHoraLocal.data("localtime", horaLocal.format());
					selectorHoraServer.data("servertime", horaServer.format());
				}, 1000);

				let intervalCheck = setInterval(() => {
					if (!$('#container-clockresult').length) {
						clearInterval(intervalClockResult);
						clearInterval(intervalCheck);
					}
				}, 3000);

				return;
			}

			let forcarCompartilhamentoTelaInteira = false;
			let bloquearMultiplosMonitores = false;

			if (this.fiscalizacaoMonitoradaTO) {
				forcarCompartilhamentoTelaInteira = this.fiscalizacaoMonitoradaTO.forcarCompartilhamentoTelaInteira && this.fiscalizacaoMonitoradaTO.forcarCompartilhamentoTelaInteira === true;
				bloquearMultiplosMonitores = this.fiscalizacaoMonitoradaTO.bloquearMultiplosMonitores && this.fiscalizacaoMonitoradaTO.bloquearMultiplosMonitores === true;
			}

			const urlBasePermitida = window.location.origin;

			if (this.fiscalizacaoMonitoradaTO?.dadosSessao) {
				const dadosSessao = this.fiscalizacaoMonitoradaTO.dadosSessao;
				const isPreviewGravacao = dadosSessao.isPreviewGravacao && dadosSessao.isPreviewGravacao === true;

				if (dadosSessao.isCapturaDeTela && dadosSessao.isGravacaoCamera) {
					this.subject = await this.proctoring.start(isPreviewGravacao, urlBasePermitida, forcarCompartilhamentoTelaInteira, bloquearMultiplosMonitores);
					if (this.fiscalizacaoMonitoradaTO.isWebcamCheck === true) {
						this.monitorarWebcam();
					}

				} else if (dadosSessao.isCapturaDeTela) {
					this.subject = await this.proctoring.startScreenRecord(isPreviewGravacao, urlBasePermitida, forcarCompartilhamentoTelaInteira, bloquearMultiplosMonitores);
				
				} else if (dadosSessao.isGravacaoCamera) {
					this.subject = await this.proctoring.startCamRecord(isPreviewGravacao, urlBasePermitida, forcarCompartilhamentoTelaInteira, bloquearMultiplosMonitores);
					if (this.fiscalizacaoMonitoradaTO.isWebcamCheck === true) {
						this.monitorarWebcam();
					}
				}

			} else {
				this.subject = await this.proctoring.start(false, urlBasePermitida, forcarCompartilhamentoTelaInteira, bloquearMultiplosMonitores);
				if (this.fiscalizacaoMonitoradaTO.isWebcamCheck === true) {
					this.monitorarWebcam();
				}
			}

			this.subject.subscribe(
				() => {},
				(idErro: any) => {
					let msgUsuario = null;
					try {
						$("#video_preview_container").remove();
					} catch (e) {
						this.logger.warn("Erro remoção video_preview_container", e);
					}
					clearInterval(this.intervalCheckWebcam);
					clearInterval(this.intervalCheckSession);

					if (idErro === "ERR_WEBCAM_STREAMING") {
						msgUsuario = this.getMsg("FP_FRONT_FPProctoringService_022");
					} else if (idErro === "ERR_SEND_VIDEO_PARTS") {
						msgUsuario = this.getMsg("FP_FRONT_FPProctoringService_024");
					} else if (idErro === "ERR_SHARING_ENTIRE_SCREEN") {
						msgUsuario = this.getMsg("FP_FRONT_FPProctoringService_030");
					} else if (idErro === "ERR_MULTIPLE_SCREENS_DETECTED") {
						msgUsuario = this.getMsg("FP_FRONT_FPProctoringService_031");
					}

					if (msgUsuario === null && this.isMonitoramentoSomenteTela()) {
						msgUsuario = this.getMsg("FP_FRONT_FPProctoringService_025");
					}

					this.triggerErro({
						msgUsuario: msgUsuario,
						msgAuditoria: this.msgs[idErro] || this.getMsg("FP_FRONT_FPProctoringService_012"),
						msgIntegracaoAuditoria: {id: idErro, msg: this.msgs[idErro]}
					});
				},
				() => {
					try {
						$("#video_preview_container").remove();
					} catch (e) {
						this.logger.warn("Erro remoção video_preview_container", e);
					}

					clearInterval(this.intervalCheckWebcam);
					clearInterval(this.intervalCheckSession);

					if(this.isIniciado()) {
						this.triggerFinalizado({
							msgUsuario: this.msgs["COMPLETED"],
							msgAuditoria: this.msgs["COMPLETED"],
							msgIntegracaoAuditoria: this.msgs["COMPLETED"]
						});
					}

					this.logger.info('Proctoring finalizado.');
				}
			);

			setTimeout(() => {
				this.monitorarGravacaoVideos();
			}, 5000);

			onAutorizacoesConcedidas();

		} catch (e) {

			let msgUsuario = '';
			let msgAuditoria = '';

			if(e.message && e.message === 'ERR_MULTIPLE_SCREENS_DETECTED') {
				this.logger.error("Uso de múltiplos monitores detectado", e);

				msgUsuario = this.getMsg("FP_FRONT_FPProctoringService_031");
				msgAuditoria = this.msgs['ERR_MULTIPLE_SCREENS_DETECTED'] || this.getMsg("FP_FRONT_FPProctoringService_012");

			} else {
				this.logger.error("Erro no pedirAutorizacoesUsuario", e);

				msgUsuario = this.getMsg("FP_FRONT_FPProctoringService_026");
				msgAuditoria = this.getMsg('FP_FRONT_FPProctoringService_018');

				if (this.isMonitoramentoSomenteTela()) {
					msgAuditoria = this.getMsg('FP_FRONT_FPProctoringService_003');
					msgUsuario = this.getMsg("FP_FRONT_FPProctoringService_025");
				}
			}

			this.triggerErro({
				msgUsuario: msgUsuario,
				msgAuditoria: msgAuditoria,
				msgIntegracaoAuditoria: {
					errorName: e.name,
					message: e.message,
					stack: e.stack
				}
			});

			clearInterval(this.intervalCheckWebcam);
			clearInterval(this.intervalCheckSession);

			throw new Error(e);
		}
	}
	
	iniciarGravacao() {
	}
	
	isIniciado() {
		return this.proctoring != null;
	}
	
	async parar() {
		if (this.proctoring) {
			try {
				await this.proctoring.finish();
			} catch (e) {
				this.logger.error("Erro ao parar proctoring", e);
			} finally {
				this.proctoring = null;
			}
		}
		clearInterval(this.intervalCheckWebcam);
		clearInterval(this.intervalCheckSession);
	}

	isMonitoramentoSomenteTela() {
		if (this.fiscalizacaoMonitoradaTO?.dadosSessao) {
			const dadosSessao = this.fiscalizacaoMonitoradaTO.dadosSessao;
			if (dadosSessao.isCapturaDeTela === true && dadosSessao.isGravacaoCamera === false) {
				return true;
			}
		}

		return false;
	}

	monitorarGravacaoVideos() {
		
		if (this.proctoring == null) return;

		let millisPrimeiroRequest = null;
		const millisEntreVerificacoes = 20 * 1000;
		const millisMaximoSemOK = 60 * 1000;

		const verificar = () => {
			const millisSemOK = Date.now() - millisPrimeiroRequest;

			if (millisSemOK >= millisMaximoSemOK) {
				this.triggerErro({
					msgUsuario: this.getMsg("FP_FRONT_FPProctoringService_017"),
					msgAuditoria: this.getMsg("FP_FRONT_FPProctoringService_013"),
					msgIntegracaoAuditoria: null
				});
			} else {
				setTimeout(send, millisEntreVerificacoes);
			}
		}

		const url = `${FPProctoringService.BACKEND_URL}/sessions/check_record/?session_id=${this.fiscalizacaoMonitoradaTO.dadosSessao.ext_session_id}`;

		const send = async () => {

			try {
				
				millisPrimeiroRequest = millisPrimeiroRequest || Date.now();

				const response = await fetch(url, {
					method: "GET",
					headers: {
						"Authorization": "Token " + this.fiscalizacaoMonitoradaTO.dadosSessao._ts
					}
				});

				this.triggerMsgLog({
					msg: this.getMsg("FP_FRONT_FPProctoringService_016"),
					msgIntegracao: { url: url, httpStatus: response.status }
				});

				if (response.status !== 200) {
					verificar();
				} else {
					this.monitorarGravacaoVideosACada10Minutos();
				}

			} catch (e) {
				this.triggerMsgLog({
					msg: this.getMsg("FP_FRONT_FPProctoringService_014"),
					msgIntegracao: {
						url: url, 
						errorName: e.name,
						message: e.message,
						stack: e.stack
					}
				});
				verificar();
			}
		}

		setTimeout(send, millisEntreVerificacoes);
	}

	monitorarGravacaoVideosACada10Minutos() {
		
		setTimeout(() => {
			const url = `${FPProctoringService.BACKEND_URL}/sessions/details/?session_id=${this.fiscalizacaoMonitoradaTO.dadosSessao.ext_session_id}`;

			this.intervalCheckSession = setInterval(async () => {

				try {
					const response = await fetch(url, {
						method: "GET",
						headers: {
							"Authorization": "Token " + this.fiscalizacaoMonitoradaTO.dadosSessao._ts
						}
					});

					const json = await response.json();

					if (!response.ok) {
						throw new Error("Response body: " + JSON.stringify(json));
					}

					if (json?.transmission_started === true && json?.transmission_ended === true) {
						this.triggerErro({
							msgUsuario: this.getMsg("FP_FRONT_FPProctoringService_017"),
							msgAuditoria: this.getMsg("FP_FRONT_FPProctoringService_013"),
							msgIntegracaoAuditoria: json
						});
					}

				} catch (e) {
					this.logger.error("Proctoring - check session error", e);
					this.triggerMsgLog({
						msg: this.getMsg("FP_FRONT_FPProctoringService_014"),
						msgIntegracao: {
							url: url, 
							errorName: e.name,
							message: e.message,
							stack: e.stack
						}
					});
				}

			}, 60000);
		}, 3000);
	}

	monitorarWebcam() {
		setTimeout(() => {
			this.logger.info("Monitorando webcam a cada 10 minutos");
			this.intervalCheckWebcam = setInterval(() => {
				this.logger.info("Disparando verificação da webcam");
				this.proctoring.checkCamera(1000, 0, 3, true);
			}, 600000);
		}, 5000);
	}
}
