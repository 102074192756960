class RecursoVH extends DataWarehouseVH {

	exibicaoTelaInterposicaoRecursoTO: any;
	ordenacaoAgrupamento: any;

	constructor() {
		super(RecursoVH.name);
		this.addOperacaoParaHash("repr", this.listarProvasComRecursos);
		this.addOperacaoParaHash("rerp", this.listarRecursosDaProva);
		this.addOperacaoParaHash("reir", this.interporRecurso);
	}

	async listarProvasComRecursos(numAba: number) {
		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_RC_18"));

		if (numAba == null) {
			numAba = 0;
		}

		UtilHash.registrarHistorico(this.listarProvasComRecursos, numAba);

		this.addAbas({
			abas: [{
				label: this.getMsg("FP_FRONT_RecursoVH_033"),
				onClick: async () => await this.exibirRecursosPorProva()
			}, {
				label: this.getMsg("FP_FRONT_RecursoVH_034"),
				onClick: async () => await this.exibirPorRecursos(),
			}],
			numAbaAtiva: numAba
		});

		this.exibir();
	}

	async exibirRecursosPorProva() {

		const cfgsRelIndiceRespostasTO = this.verificar(
			null,
			CampoRelRespostas.NOME_PROVA,
			"nomeProva"
		);

		this.limpar(true);

		this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
		this.addBotaoBusca("filtrosIndiceRespostas");
		this.append("</div>");

		await this.addFiltrosIndiceRespostas({
			campoAgrupamento: cfgsRelIndiceRespostasTO.campoAgrupamento,
			filtrosTO: cfgsRelIndiceRespostasTO.filtrosTO,
			isExibirCodRecurso: true,
			onAplicarFiltros: async () =>  {
				cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();
				await this.listarRecursosPorProva(cfgsRelIndiceRespostasTO);
			}
		});

		await this.listarRecursosPorProva(cfgsRelIndiceRespostasTO);

		this.exibir();
	}

	async listarRecursosPorProva(cfgsRelIndiceRespostasTO) {
		const exibicaoProvasComRecursoTO = await this.call("RecursoFCD/recuperarProvasComRecurso", cfgsRelIndiceRespostasTO);

		const listagemProvasComRecurso = $('#listagemProvasComRecurso');

		if (listagemProvasComRecurso.length == 0) {
			this.append("<div id='listagemProvasComRecurso'></div>");
			this.exibir();
		}

		listagemProvasComRecurso.html("");

		this.setIdTarget("listagemProvasComRecurso");

		const colunas: ColunaAddTabela[] = [
			{titulo: this.getMsg("MSG_VH_RC_19"), prop: "nomeProva", classe: "descricao"},
			{titulo: this.getMsg("MSG_VH_RC_20"), prop: "dataUltimoRecurso", formato: "DD/MM/YY HH:mm"},
			{titulo: this.getCfg("LABEL_RECURSOS"), prop: "numRecursos"},
			{titulo: this.getMsg("MSG_VH_RC_21"), prop: "numRecursosSemResposta"},
			{titulo: this.getMsg("MSG_VH_RC_22"), prop: "numRecursosRespondidos"},
		];

		await this.addTabela({
			id: "listaProvasComRecurso",
			collection: exibicaoProvasComRecursoTO.listaProvasComRecursoTO,
			colunas: colunas,
			propId: "codProva",
			msgListaVazia: this.getMsg("MSG_VH_RC_23"),
			paginacao: {
				paginacaoTO: exibicaoProvasComRecursoTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					cfgsRelIndiceRespostasTO.paginacaoTO = paginacaoTO;
					const exibicaoProvasComRecursoTO = await this.call("RecursoFCD/recuperarProvasComRecurso", cfgsRelIndiceRespostasTO);
					return exibicaoProvasComRecursoTO.listaProvasComRecursoTO;
				}
			},
			onEdicao: async (codProva) => {
				await this.listarRecursosDaProva(cfgsRelIndiceRespostasTO, codProva);
			},
			ordenacao: this.ordenacaoAgrupamento,
			onOrdenacaoColuna: async (coluna: ColunaAddTabela, isOrdenacaoDecrescente: boolean) => {

				cfgsRelIndiceRespostasTO.campoOrdenacao = coluna.prop;
				cfgsRelIndiceRespostasTO.isOrdenacaoDecrescente = isOrdenacaoDecrescente;
				cfgsRelIndiceRespostasTO.paginacaoTO = null;

				this.ordenacaoAgrupamento = [[coluna.prop, isOrdenacaoDecrescente ? 1 : 0]];

				await this.listarRecursosPorProva(cfgsRelIndiceRespostasTO);
			}
		});

		this.setIdTarget(null);

		this.exibir();
	}

	// fp-custom
	listaColunasPersonalizadasListagemPorRecurso: ColunaAddTabela[] = null;

	async listarRecursosPorRecurso(cfgsRelIndiceRespostasTO) {
		
		const exibicaoRecursosTO = await this.call("RecursoFCD/recuperarRecursos", cfgsRelIndiceRespostasTO);

		const listagemRecursos = $('#listagemRecursos');

		if (listagemRecursos.length == 0) {
			this.append("<div id='listagemRecursos'></div>");
			this.exibir();
		}

		listagemRecursos.html("");

		this.setIdTarget("listagemRecursos");

		let colunas: ColunaAddTabela[] = [
			{titulo: this.getCfg("LABEL_ALUNO"), prop: "nomeUsuario", classe: "descricao"},
			{titulo: this.getMsg("FP_FRONT_RecursoVH_028"), prop: "codQuestao"},
			{titulo: this.getMsg("FP_FRONT_RecursoVH_029"), prop: "numQuestaoNaProva"},
			{titulo: this.getMsg("FP_FRONT_RecursoVH_030"), prop: "numQuestaoCaderno"},
			{titulo: this.getMsg("FP_FRONT_RecursoVH_031"), prop: (recursoTO) => {
				const argumento = recursoTO.argumento;
				if (!argumento) {
					return "";
				}
				return `<div class="td-texto">${argumento}</div>`;
			}},
			{titulo: this.getMsg("FP_FRONT_RecursoVH_032"), prop: "tipoRespostaRecurso"},
		];

		if (this.hasValue(this.listaColunasPersonalizadasListagemPorRecurso)) {
			colunas = colunas.concat(this.listaColunasPersonalizadasListagemPorRecurso);
		}

		await this.addTabela({
			id: "listaRecursos",
			collection: exibicaoRecursosTO.listaRecursosTO,
			colunas: colunas,
			propId: "codRecurso",
			msgListaVazia: this.getMsg("MSG_VH_RC_23"),
			onEdicao: [
				null, 
				async (_, recursosTO) => {
					await respostaRecursoVH.listarRecursosDaQuestao(0, recursosTO.codQuestao, null, recursosTO.codAgendamento);
				}
			],
			paginacao: {
				paginacaoTO: exibicaoRecursosTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					cfgsRelIndiceRespostasTO.paginacaoTO = paginacaoTO;
					const exibicaoProvasComRecursoTO = await this.call("RecursoFCD/recuperarRecursos", cfgsRelIndiceRespostasTO);
					return exibicaoProvasComRecursoTO.listaRecursosTO;
				}
			},
			ordenacao: this.ordenacaoAgrupamento,
			onOrdenacaoColuna: async (coluna: ColunaAddTabela, isOrdenacaoDecrescente: boolean) => {

				cfgsRelIndiceRespostasTO.campoOrdenacao = coluna.prop;
				cfgsRelIndiceRespostasTO.isOrdenacaoDecrescente = isOrdenacaoDecrescente;
				cfgsRelIndiceRespostasTO.paginacaoTO = null;

				this.ordenacaoAgrupamento = [[coluna.prop, isOrdenacaoDecrescente ? 1 : 0]];

				await this.listarRecursosPorRecurso(cfgsRelIndiceRespostasTO);
			}
		});

		this.setIdTarget(null);

		this.exibir();
	}

	async exibirPorRecursos() {
		UtilHash.registrarHistorico(this.listarProvasComRecursos, 1);

		const cfgsRelIndiceRespostasTO = this.verificar(
			null,
			CampoRelRespostas.COD_RESPOSTA_QUESTAO,
			"nomeUsuario"
		);

		this.limpar(true);

		this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
		this.addBotaoBusca("filtrosIndiceRespostas");
		this.append("</div>");

		await this.addFiltrosIndiceRespostas({
			campoAgrupamento: cfgsRelIndiceRespostasTO.campoAgrupamento,
			filtrosTO: cfgsRelIndiceRespostasTO.filtrosTO,
			isExibirCodRecurso: true,
			onAplicarFiltros: async () =>  {
				await this.listarRecursosPorRecurso(this.getFiltrosRecursosPorRecursos());
			}
		});

		await this.listarRecursosPorRecurso(cfgsRelIndiceRespostasTO);

		this.exibir();
	}

	getFiltrosRecursosPorRecursos() {
		const cfgsRelIndiceRespostasTO = this.verificar(
			null,
			CampoRelRespostas.COD_RESPOSTA_QUESTAO,
			"nomeUsuario"
		);
		cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();
		return cfgsRelIndiceRespostasTO;
	}

	async listarProvasComRecursosParaAlunos(selecaoFiltrosRecursoTO) {

		const exibicaoProvasComRecursoTO = await this.call("RecursoFCD/recuperarProvasComRecursoParaAlunos");

		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_RC_18"));

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_RC_19"), prop: "tituloProva", classe: "descricao"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_20"), prop: "dataUltimoRecurso", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_21"), prop: "numRecursosEmAberto"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_22"), prop: "numRecursosRespondidos"});

		await this.addTabela({
			collection: exibicaoProvasComRecursoTO.listaProvasComRecursoTO,
			colunas: colunas,
			propId: "codProva",
			ordenacao: [["dataInicializacao", 1]],
			msgListaVazia: this.getMsg("MSG_VH_RC_23"),
			onEdicao: async (codProva, provasComRecursoTO) => {
				await this.listarRecursosDaProvaParaAlunos(selecaoFiltrosRecursoTO, codProva, provasComRecursoTO.codProvaFeita)
			}
		});

		this.exibir();
	}

	async listarRecursosDaProva(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, codProva, codAgendamento?) {

		UtilHash.registrarHistorico(this.listarRecursosDaProva, cfgsRelIndiceRespostasTO, codProva, codAgendamento);

		cfgsRelIndiceRespostasTO = this.verificar(
			cfgsRelIndiceRespostasTO,
			CampoRelRespostas.NOME_PROVA,
			"nomeProva"
		);

		const listagemRecursosDaProvaTO = await this.call("RecursoFCD/listarQuestoesDaProvaComRecursos", cfgsRelIndiceRespostasTO, codProva, codAgendamento);

		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_RC_24"));
		this.setSubtitulo(listagemRecursosDaProvaTO.tituloProva);

		this.setIdTarget("tituloSuperiorDireito");
		this.addBotaoBusca("filtrosIndiceRespostas", {classe: "btn-sm"});
		this.setIdTarget(null);

		await this.addFiltrosIndiceRespostas({
			campoAgrupamento: cfgsRelIndiceRespostasTO.campoAgrupamento,
			filtrosTO: cfgsRelIndiceRespostasTO.filtrosTO,
			isExibirCodRecurso: true,
			omitidos: [CampoRelRespostas.COD_PROVA.id],
			onAplicarFiltros: async () =>  {
				cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();
				this.listarRecursosDaProva(cfgsRelIndiceRespostasTO, codProva, codAgendamento);
			}
		});

		const montarColunaStatus = function (questao) {
			let status = "";
			if(questao.isAnulada || questao.isExcluida){
				if(questao.isAnulada){
					status += `<span class="label label-danger label-anulada-recursos">${this.getMsg("FP_FRONT_RecursoVH_014")}</span>`;
				}
				if(questao.isExcluida){
					status += `<span class="label label-warning label-excluida-recursos">${this.getMsg("FP_FRONT_RecursoVH_015")}</span>`;
				}
			}
			else{
				status = `<span class="label label-success label-emuso-recursos">${this.getMsg("FP_FRONT_RecursoVH_016")}</span>`;
			}

			return status;
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: "<small>#${codQuestao}</small"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_25"), prop: "numQuestaoNaProva"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_26"), prop: "numAvaliadosQueAbriramRecurso"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_27"), prop: "<strong style='font-size: 110%'>${numRecursosAbertos}</strong>"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_29"), prop: "numRecursosIndeferidos"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_62"), prop: "numRecursosDeferidosPartialmente"});
		colunas.push({titulo: this.getMsg("MSG_VH_RC_28"), prop: "numRecursosDeferidos"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RecursoVH_012"), prop: "numRecalculos"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RecursoVH_013"), prop: montarColunaStatus, classe: "status-questao-recursos"});

		await this.addTabela({
			id: "listagemQuestaoComRecurso",
			collection: listagemRecursosDaProvaTO.collectionListagemQuestaoComRecursoTO,
			colunas: colunas,
			propId: "codQuestao",
			onEdicao: [
				null, 
				async (codQuestao) => {
					await respostaRecursoVH.listarRecursosDaQuestao(0, codQuestao, codProva, codAgendamento);
				}
			],
			ordenacao: [["numQuestaoNaProva", false]],
			paginacao: {
				paginacaoTO: listagemRecursosDaProvaTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					cfgsRelIndiceRespostasTO.paginacaoTO = paginacaoTO;
					const listagemRecursosDaProvaTO = await this.call("RecursoFCD/listarQuestoesDaProvaComRecursos", cfgsRelIndiceRespostasTO, codProva, codAgendamento);
					return listagemRecursosDaProvaTO.collectionListagemQuestaoComRecursoTO;
				}
			},
		})

		this.exibir();
	}

	async listarRecursosDaProvaParaAlunos(selecaoFiltrosRecursoTO, codProva, codProvaFeita) {

		selecaoFiltrosRecursoTO = selecaoFiltrosRecursoTO || {};

		const listagemRecursosDaProvaTO = await this.call(
			"RecursoFCD/listarQuestoesDaProvaComRecursosDoAluno",
			selecaoFiltrosRecursoTO,
			codProva);

		this.exibicaoTelaInterposicaoRecursoTO = await this.call(
			"RecursoFCD/recuperarDadosInterposicaoRecurso",
			codProvaFeita,
			this.isCfgHabilitada("FUNCIONALIDADE_VISUALIZACAO_QUESTAO_NA_LISTAGEM_RECURSOS"))

		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_RC_24"));
		this.setSubtitulo(listagemRecursosDaProvaTO.tituloProva);

		const montarColunaStatusRecurso = function (questao) {
			let status = "";

			if (questao.tipoRespostaRecurso == 2){
				status = this.getMsg("FP_FRONT_RecursoVH_007");
			}
			else if (questao.tipoRespostaRecurso == 1){
				status = this.getMsg("FP_FRONT_RecursoVH_011");
			}
			else if (questao.tipoRespostaRecurso == 0){
				status = this.getMsg("FP_FRONT_RecursoVH_006");
			}
			else {
				status = this.getMsg("FP_FRONT_RecursoVH_021");
			}

			return status;
		}

		const montarBotoesAplicacao = function (questao) {
			const html = [`<div class='btn-group' id='btn-group-${questao.codQuestao}'>`];
			html.push(`
				<a id="view_${questao.codQuestao}" class='btn btn-white btn-default'
						onclick='recursoVH.exibirDetalhesRecurso(${questao.codQuestao}, ${true})'
						title='${this.getMsg("FP_FRONT_RespostaRecursoVH_024")}'>

					${this.getMsg("FP_FRONT_RecursoVH_022")} 
					
					<i class="fa fa-chevron-down" style="margin-top: 3px; padding-left: 3px"></i>
				</a>
			`);
			html.push("</div>");
			return html.join("");
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_RC_25"), prop: "numQuestaoNaProva"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RecursoVH_023"), prop: "dataCadastro", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RecursoVH_024"), prop: montarColunaStatusRecurso, classe: "tipoRespostaRecurso"});
		colunas.push({titulo: this.getMsg("FP_FRONT_RecursoVH_025"), prop: "dataCadastroRecurso", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: "", prop: montarBotoesAplicacao});

		await this.addTabela({
			collection: listagemRecursosDaProvaTO.collectionListagemQuestaoComRecursoTO,
			colunas: colunas,
			propId: "codQuestao",
			ordenacao: [["numQuestaoNaProva", false]]
		})

		this.exibir();
	}

	async exibirDetalhesRecurso(codQuestao, isExibirResposta){

		const recursoAtual = this.exibicaoTelaInterposicaoRecursoTO.listRecursosInterpostosDoUsuarioTO.find(
			recurso => recurso.exibicaoQuestaoTO?.codQuestao === codQuestao
		);

		if (recursoAtual){

			this.alterarAcaoBotaoExibirRecurso(recursoAtual, codQuestao, isExibirResposta);

			if (isExibirResposta){
				this.adicionarLinhaVisualizacaoRecurso(recursoAtual, codQuestao);
				return;
			}
			const el = $(`#visualizacao_recurso_${recursoAtual.codDuvida}`);
			if (el.length) {
				el.fadeOut(function() {
					$(this).remove();
				});
			}

		}
	}

	adicionarLinhaVisualizacaoRecurso(recursoAtual: any, codQuestao: number) {

		const el = $(`#listagem_linha_${codQuestao}`);

		const novaLinha = $(`
			<tr id="visualizacao_recurso_${recursoAtual.codDuvida}">
				<td colspan='999'>
					<div id="exibicaoResposta_${recursoAtual.codDuvida}" class="container-resposta-recurso" style="text-align: initial"></div>
				</td>
			</tr>
		`);

		novaLinha.hide();
		el.after(novaLinha);

		const elementoExibicaoRespostaRecurso = $(`#exibicaoResposta_${recursoAtual.codDuvida}`);

		let htmlRespostaRecurso = "";

		if (recursoAtual.tipoRespostaRecurso != null){

			let tipoResposta = this.getMsg(recursoAtual.tipoRespostaRecurso.idMsgSubstantivo);

			if (recursoAtual.tipoRecalculoRecurso) {
				tipoResposta += " (" + this.getMsg(recursoAtual.tipoRecalculoRecurso.idMsgSubstantivo).toUpperCase() + ")";
			}

			htmlRespostaRecurso = `
				<blockquote resposta-recurso class='blockquote'>
					<p><strong>${tipoResposta}</strong></p>
					<p texto-resposta>${recursoAtual.textoResposta}</p>
				</blockquote>
			`;
		}

		const $button = $(`<button class="btn btn-default pull-right">Ver questão</button>`);

		$button.on('click', function() {
			recursoVH.exibirPopupQuestao(recursoAtual);
		});

		elementoExibicaoRespostaRecurso.append(`
			<div div-resposta-recurso>
				<blockquote recurso class='blockquote'>
					<p>${recursoAtual.textoRecurso}</p>
				</blockquote>
		`);

		elementoExibicaoRespostaRecurso.append(htmlRespostaRecurso);
		elementoExibicaoRespostaRecurso.append("</div>");

		if (this.isCfgHabilitada("FUNCIONALIDADE_VISUALIZACAO_QUESTAO_NA_LISTAGEM_RECURSOS")){
			elementoExibicaoRespostaRecurso.append($button);
		}

		this.addEspacamentoHorizontal();

		setTimeout(() => novaLinha.fadeIn(), 100);
	}

	exibirPopupQuestao(recursoAtual){

		this.addPopup({
			id: "popupExibicaoQuestao",
			titulo: this.getMsg("FP_FRONT_RecursoVH_026")
		});

		exibicaoQuestaoVH.exibirQuestao(recursoAtual.exibicaoQuestaoTO, {
			isExibirEstatisticas: false,
			isExibirComentarios: false,
			isMostrarOperacoes: false,
			isExibirNomeSecao: true
		});

		this.exibirPopups();
	}

	alterarAcaoBotaoExibirRecurso(recursoAtual, codQuestao, isExibirResposta: boolean) {

		const group = $(`#btn-group-${codQuestao}`);

		const title = isExibirResposta ? this.getMsg("FP_FRONT_RespostaRecursoVH_025") : this.getMsg("FP_FRONT_RespostaRecursoVH_024");
		const icon = isExibirResposta ? 'fa-chevron-up' : 'fa-chevron-down'
		const text = isExibirResposta ? "" : this.getMsg("FP_FRONT_RecursoVH_022");
		const styleIcone = isExibirResposta ? "" : "margin-top: 3px; padding-left: 3px";

		const a = ` 
			<a id="view_${recursoAtual.codDuvida}" class='btn btn-white btn-default'
					onclick='recursoVH.exibirDetalhesRecurso(${codQuestao}, ${!isExibirResposta})'
					title='${title}'>

				${text}

				<i class='fa ${icon}' style="${styleIcone}"></i>
			</a>
		`

		group.html(a);
		this.exibir()
	}


	recursoAtual = 0;

	async interporRecurso(codProvaFeita: number, numRecurso?: number) {

		UtilHash.registrarHistorico(this.interporRecurso, codProvaFeita, numRecurso);

		this.pararContagemRegressiva();
		this.recursoAtual = numRecurso || 0;

		const exibicaoTelaInterposicaoRecursoTO = await this.call("RecursoFCD/recuperarDadosInterposicaoRecurso", codProvaFeita, true);

		const isApresentarQuestaoPorQuestaoEmRecurso = exibicaoTelaInterposicaoRecursoTO.isApresentarQuestaoPorQuestaoEmRecurso;
		
		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_RC_50") + " <small>" + exibicaoTelaInterposicaoRecursoTO.tituloProva + "</small>");

		this.append("<div id='interpor-recurso'>")

		let recursosInterpostosDoUsuarioTOParaIniciarCronometro = null;

		if (exibicaoTelaInterposicaoRecursoTO.listRecursosInterpostosDoUsuarioTO.length == 0) {
			this.addTexto(this.getMsg("FP_FRONT_RecursoVH_003"));
			this.addEspacamentoHorizontal("10px");
			this.addBotao({
				label: "Voltar",
				onClick: () => UtilHash.carregarTelaInicial()
			})

		} else {

			this.addTexto(this.getMsg("MSG_VH_RC_51"));
			let possuiRecursosParaEnviar = false;

			// exibicaoTelaInterposicaoRecursoTO.listRecursosInterpostosDoUsuarioTO.forEach((recursosInterpostosDoUsuarioTO, i) => {
			for (let i = 0; i < exibicaoTelaInterposicaoRecursoTO.listRecursosInterpostosDoUsuarioTO.length; i++) {

				const recursosInterpostosDoUsuarioTO = exibicaoTelaInterposicaoRecursoTO.listRecursosInterpostosDoUsuarioTO[i];

				if (isApresentarQuestaoPorQuestaoEmRecurso && (this.recursoAtual != i)) {
					continue;
				}

				if (recursosInterpostosDoUsuarioTO.millisRestantesParaInterposicao != null) {
					recursosInterpostosDoUsuarioTOParaIniciarCronometro = recursosInterpostosDoUsuarioTO;
				}

				let pontuacaoDaRespostaQuestaoTO = null;
				const pontuacaoDaProvaFeitaTO = exibicaoTelaInterposicaoRecursoTO.pontuacaoDaProvaFeitaTO;

				if(pontuacaoDaProvaFeitaTO) {
					pontuacaoDaRespostaQuestaoTO = aplicacaoProvaVH.getPontuacaoDaRespostaQuestaoTO(
						pontuacaoDaProvaFeitaTO, recursosInterpostosDoUsuarioTO.exibicaoQuestaoTO);
				}

				exibicaoQuestaoVH.exibirQuestao(recursosInterpostosDoUsuarioTO.exibicaoQuestaoTO, {
					isExibirEstatisticas: false,
					isExibirComentarios: false,
					isMostrarOperacoes: false,
					isExibirNomeSecao: true,
					conceito: pontuacaoDaRespostaQuestaoTO ? aplicacaoProvaVH.getNomeConceito(
						pontuacaoDaProvaFeitaTO.listaExibicaoMencaoTO, pontuacaoDaRespostaQuestaoTO.codMencao) : null,
					exibirConceito: exibicaoTelaInterposicaoRecursoTO.isResultadoPorMencao,
				});

				this.addEspacamentoHorizontal("10px");
				let isTempoEsgotado = recursosInterpostosDoUsuarioTO.millisRestantesParaInterposicao != null && recursosInterpostosDoUsuarioTO.millisRestantesParaInterposicao <= 0;

				if (recursosInterpostosDoUsuarioTO.textoRecurso == null) {
					if (isTempoEsgotado) {
						this.addTexto(`<strong>${this.getMsg("FP_FRONT_RecursoVH_004")}</strong>`);
					} else {
						const codRespostaQuestao = recursosInterpostosDoUsuarioTO.exibicaoQuestaoTO.exibicaoRQTO.codRespostaQuestao;
						this.addTextArea({
							id: "textoRecurso_" + codRespostaQuestao,
							label: this.getMsg("MSG_VH_RC_52"),
							classe: "col-md-12",
							cssTextarea: "min-height: 120px; width: 600px",
							isCopyPasteCutDesabilitado: true
						});
						if(exibicaoTelaInterposicaoRecursoTO.isUploadArquivo){
							this.append("<div class='col-md-12 flex-row-center'>");
							this.addZonaUpload({
								id: "importacaoArquivoRecurso_" + codRespostaQuestao,
								label: this.getMsg("MSG_VH_RC_64"),
								classe: "col-md-4"
							});
							this.append("</div>");
						}
						possuiRecursosParaEnviar = true;
					}
				} else {

					this.append("<blockquote style='float: left'>")
					this.append(`<small>${this.getMsg("FP_FRONT_RecursoVH_005", UtilData.toDDMMYYYY(recursosInterpostosDoUsuarioTO.dataCadastro))}</small>`)
					if(recursosInterpostosDoUsuarioTO.pathMidia){
						this.append(`
							<small>
								<a href="${recursosInterpostosDoUsuarioTO.pathMidia}" style="font-size: 1rem;">
									<i class="fa fa-files-o" aria-hidden="true"></i>
									${UtilArquivo.getNomeArquivo(recursosInterpostosDoUsuarioTO.pathMidia)}
								</a>
							</small>
						`);
					}
					this.append("<p>" + recursosInterpostosDoUsuarioTO.textoRecurso + "</p>")

					if (recursosInterpostosDoUsuarioTO.isRecursoComResposta !== true) {
						this.addLink({
							label: "<i class='fa  fa-trash-o'></i> Excluir Recurso",
							css: "padding-left: 0px;",
							onClick: async () => {
								if (await this.confirmar(this.getMsg("MSG_VH_RC_53"))) {
									await this.call("RecursoFCD/removerRecurso", recursosInterpostosDoUsuarioTO.codDuvida);
									this.interporRecurso(codProvaFeita, this.recursoAtual);
									return true;
								}
								return false;
							},
							visivel: !isTempoEsgotado
						});
					}

					this.append("</blockquote>");
				}

				if (recursosInterpostosDoUsuarioTO.dataResposta != null) {
					this.append("<blockquote class='pull-right'>")
					if (recursosInterpostosDoUsuarioTO.tipoRespostaRecurso === TipoRespostaRecurso.INDEFERIMENTO) {
						this.append(`<p><strong>${this.getMsg("FP_FRONT_RecursoVH_006")}</strong></p>`);
					} else if (recursosInterpostosDoUsuarioTO.tipoRespostaRecurso === TipoRespostaRecurso.DEFERIMENTO_PARCIAL) {
						this.append(`<p><strong>${this.getMsg("FP_FRONT_RecursoVH_011")}</strong></p>`);
					} else if (recursosInterpostosDoUsuarioTO.tipoRespostaRecurso === TipoRespostaRecurso.DEFERIMENTO) {
						this.append(`<p><strong>${this.getMsg("FP_FRONT_RecursoVH_007")}</strong></p>`);
					}
					this.append(`<small>${this.getMsg("MSG_VH_RC_54")} ${UtilData.toDDMMYYYY(recursosInterpostosDoUsuarioTO.dataResposta)}</small>`)
					this.append(`<p>${recursosInterpostosDoUsuarioTO.textoResposta}</p>`)
					this.append("</blockquote>")
				}

				this.addEspacamentoHorizontal();
			}

			this.addTexto("<i>" + this.getMsg("MSG_VH_RC_55") + "</i>");

			if (isApresentarQuestaoPorQuestaoEmRecurso || possuiRecursosParaEnviar) {
				this.append('<nav barra-navegacao-recursos class="navbar navbar-default">');

				if (recursosInterpostosDoUsuarioTOParaIniciarCronometro != null) {
					const millisRestantes = recursosInterpostosDoUsuarioTOParaIniciarCronometro.millisRestantesParaInterposicao;
					this.append("<div contagem-regressiva class='col-md-6 col-xs-6'>");
					this.append(`<span class='label label-default' title='${this.getMsg("FP_FRONT_RecursoVH_008")}' `);
					this.append(" data-minutos-restantes='" + millisRestantes + "'>");
					this.append(this.getTempoRestante(millisRestantes));
					this.append("</span></div>");
				}

				this.append("<div class='col-12'>");
				if (isApresentarQuestaoPorQuestaoEmRecurso && this.recursoAtual > 0) {
					const key = UtilBoot.getKeymaster();
					key.unbind("command+left,ctrl+left");
					key("command+left,ctrl+left", function () {
						$("#botao_anterior").click();
					});

					this.addBotao({
						id: "botao_anterior",
						label: `
							<span class='hidden-xs'> 
								<i class='fa fa-arrow-left'></i> ${this.getMsg("MSG_VH_RC_58")}
							</span>
							<span class='visible-xs'>
								<i class='fa fa-arrow-left'></i> ${this.getMsg("MSG_VH_APR_181")}
							</span>
						`,
						classe: "btn-sm pull-left col-md-3 col-xs-3 btn-primary",
						css: "font-size: 110%",
						onClick: async () => {
							await this.salvarInterposicoes();
							this.recursoAtual--;
							this.interporRecurso(codProvaFeita, this.recursoAtual);
						},
					});

				} else {
					this.addBotao({
						label: this.getMsg("MSG_VH_RC_56"),
						classe: "btn-sm pull-left col-md-3 col-xs-3 btn-default",
						css: "font-size: 110%",
						onClick: () => UtilHash.carregarTelaInicial()
					});
				}

				this.append("<div class='col-md-6 col-xs-6' style='display: flex; align-items: center; justify-content: center'>");
				this.addBotao({
					label: "<span>" + (isApresentarQuestaoPorQuestaoEmRecurso ? this.getMsg("FP_FRONT_RecursoVH_009") : this.getMsg("MSG_VH_RC_57")) + "</span>",
					classe: "btn-sm pull-right col-md-6 col-xs-6" + (isApresentarQuestaoPorQuestaoEmRecurso ? "" : " btn-primary"),
					css: "font-size: 110%",
					onClick: async () => {
						await this.salvarInterposicoes();
						UtilHash.carregarTelaInicial();
					}
				});
				this.append("</div>");

				if (isApresentarQuestaoPorQuestaoEmRecurso && (this.recursoAtual + 1) < exibicaoTelaInterposicaoRecursoTO.listRecursosInterpostosDoUsuarioTO.length) {

					const key = UtilBoot.getKeymaster();
					key.unbind("command+right,ctrl+right");
					key("command+right,ctrl+right", function () {
						$("#botao_avancar").click();
					});

					this.addBotao({
						id: "botao_avancar",
						label: `
							<span class="hidden-xs">
								${this.getMsg("MSG_VH_RC_59")} <i class="fa fa-arrow-right"></i>
							</span>
							<span class="visible-xs">
								${this.getMsg("MSG_VH_APR_180")} <i class="fa fa-arrow-right"></i>
							</span>
						`,
						classe: "btn-sm pull-right col-md-3 col-xs-3 btn-primary",
						css: "font-size: 110%",
						onClick: async () => {
							await this.salvarInterposicoes();
							this.recursoAtual++;
							this.interporRecurso(codProvaFeita, this.recursoAtual);
						}
					});
				}

				this.append("</div>");


				this.append("</nav>");
			}
		}

		this.exibir();

		if (recursosInterpostosDoUsuarioTOParaIniciarCronometro != null && exibicaoTelaInterposicaoRecursoTO.isApresentarQuestaoPorQuestaoEmRecurso) {
			this.iniciarContagemRegressiva(codProvaFeita, recursosInterpostosDoUsuarioTOParaIniciarCronometro);
			await this.call("RecursoFCD/iniciarCronometroInterposicaoRecurso", recursosInterpostosDoUsuarioTOParaIniciarCronometro.codRespostaQuestao);
		}

		this.append("</div>")
	}

	pararContagemRegressiva() {
		for (const idInterval of this.idsIntervalsCriados) {
			clearInterval(idInterval);
		}
		this.idsIntervalsCriados.length = 0;
	}

	idsIntervalsCriados = [];

	iniciarContagemRegressiva(codProvaFeita, recursosInterpostosDoUsuarioTO) {

		const idIntervalCriado = setInterval((() => {

			if (recursosInterpostosDoUsuarioTO.millisRestantesParaInterposicao <= 0) return;

			const $barraNavegacao = $('[barra-navegacao-recursos]');

			if ($barraNavegacao.length == 0) {
				clearInterval(idIntervalCriado);
				return;
			}

			const $contagem = $barraNavegacao.find("[contagem-regressiva] span");
			let millisRestantes = $contagem.data("millis-restantes");

			if (millisRestantes == null) {
				millisRestantes = recursosInterpostosDoUsuarioTO.millisRestantesParaInterposicao;
			}

			millisRestantes = millisRestantes - 1000;

			if (millisRestantes <= 0) {
				this.salvarInterposicoes().then(() => {
					this.interporRecurso(codProvaFeita, 0);
				});
				clearInterval(idIntervalCriado);
			} else {
				$contagem.data("millis-restantes", millisRestantes);
			}

			$contagem.text(this.getTempoRestante(millisRestantes));

		}).bind(this), 1000);

		this.idsIntervalsCriados.push(idIntervalCriado);
	}

	getTempoRestante(millis) {
		if (millis <= 0) {
			return this.getMsg("FP_FRONT_RecursoVH_010")
		} else {
			return UtilData.getDuracao({ millis: millis });
		}
	}

	async salvarInterposicoes() {
		let listaInterposicaoRecursoTO = [];

		$("[id^='textoRecurso']").each((i, node) => {
			let id = node.id;
			let codRespostaQuestao = id.substring(id.indexOf("_") + 1);
			let interposicaoRecursoTO = {
				textoRecurso: this.getValor(node),
				codRespostaQuestao: codRespostaQuestao,
				uploadMidia: this.getValor("importacaoArquivoRecurso_" + codRespostaQuestao)
			}

			listaInterposicaoRecursoTO.push(interposicaoRecursoTO);
		});

		await this.call("RecursoFCD/interporRecurso", listaInterposicaoRecursoTO);
	}

	async modalRespostaQuestao(codRespostaQuestao, codDuvida) {
		const exibicaoQuestaoTO = await this.call("ExibicaoRQFCD/recuperarExibicaoRespostaQuestao", codRespostaQuestao);

		this.addPopup({
			id: "exibicao_resposta_questao_recurso",
			titulo: this.getMsg("MSG_VH_EQ_02"),
			width: "70%",
			// height: "350px"
		});

		exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO);

		this.exibirPopups();

		$(`[recurso] input[cod-duvida='${codDuvida}']`).prop("checked", false);
	}
}

const recursoVH = new RecursoVH();