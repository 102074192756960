class ListagemProvasPorPastasVH extends AmaisVH {
	
	codsEmpresasBusca: any;
	buscaTextual: any;
	isExibirArquivados: any;
	numeroDaPagina: any;
	itensPorPagina: any;

	constructor() {
		super(ListagemProvasPorPastasVH.name);
		this.addOperacaoParaHash("ppel", this.exibirListagem);
	}

	async exibirListagem(codAvaliacao) {

		UtilHash.registrarHistorico(this.exibirListagem, codAvaliacao);

		var filtrosProvasPorPastaTO = {
			codsSegmentos: this.codsEmpresasBusca,
			buscaTextual: this.buscaTextual,
			codAvaliacao: codAvaliacao,
			isExibirArquivados: this.isExibirArquivados,
			numeroDaPagina: this.numeroDaPagina,
			itensPorPagina: this.itensPorPagina
		}

		const listagemPastaTO = await this.call("ListagemProvasPorPastasFCD/listar", filtrosProvasPorPastaTO);

		var hashPasta = UtilHash.getHash(this.exibirListagem);
		var hashProva = UtilHash.getHash(cadastroProvaVH.exibirAbasProva);
		var hashEditarPasta = UtilHash.getHash(cadastroAvaliacaoVH.editarAvaliacao);

		if ($("[provas-por-pastas]").length == 0) {

			this.limpar();

			this.setTitulo("Pastas");

			{	// FILTROS 

				this.append("<div id='filtrosPastas' style='display: none'>");
				this.abrirAbaAccordion({ titulo: this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_001"), aberta: true });

				this.addFormulario({ classe: "col-md-12" });
				this.append("<div class='row'>");

				if (listagemPastaTO.collectionSegmentosTO.length > 1 || (listagemPastaTO.collectionSegmentosTO.length == 1 && listagemPastaTO.collectionSegmentosTO[0].filhos != null)) {
					await this.addSelect({
						collection: listagemPastaTO.collectionSegmentosTO,
						id: "codsEmpresasBusca",
						dica: this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_002"),
						multiplo: true,
						valor: this.codsEmpresasBusca,
						classe: "col-xs-12 col-md-3 col-lg-3",
						onChange: () => {
							this.codsEmpresasBusca = this.getValor("codsEmpresasBusca");
							this.exibirListagem(codAvaliacao);
						}
					});
				}

				this.addCampoTexto({
					dica: this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_003"), classe: "col-xs-12 col-md-3 col-lg-3", id: "busca", valor: this.buscaTextual, prefixo: "<i class='fa fa-search'></i> ", onEnter: () => {
						this.buscaTextual = this.getValor("busca");
						this.exibirListagem(codAvaliacao);
					}
				});

				if (this.isAdministrador()) {
					this.addCheckbox({
						label: this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_004"), id: "isExibirArquivados", valor: this.isExibirArquivados, classe: "col-xs-12 col-md-3 col-lg-3", onChange: () => {
							this.isExibirArquivados = this.getValor("isExibirArquivados");
							this.exibirListagem(codAvaliacao);
						}
					});
				}

				this.append("</div>");
				this.fecharFormulario();
				this.fecharAbaAccordion();
				this.fecharGrupoAccordion();
				this.append("</div>");
			}

			this.append("<div class='row'><div class='col-md-12' provas-por-pastas>");
			this.append("</div></div>");
			this.exibir();
		}

		$("[provas-por-pastas]").html("");

		this.setIdTarget("[provas-por-pastas]");

		var botoesSubtitulo = [];

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.CADASTRO_AVALIACAO)) {
			botoesSubtitulo.push("<div class='btn-group'>");
			if (listagemPastaTO.codAvaliacao) {
				botoesSubtitulo.push("<a class='btn btn-sm btn-default' onclick='cadastroAvaliacaoVH.editarAvaliacao(" + listagemPastaTO.codAvaliacao + ", null, true)'><i class='fa fa-pencil'></i></a>");
			}
			botoesSubtitulo.push(`<a class='btn btn-sm btn-success' onclick='cadastroAvaliacaoVH.editarAvaliacao(null,${listagemPastaTO.codAvaliacao || "null"}, true)'><i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_005")}</a>`);
			botoesSubtitulo.push(this.addBotaoBusca("filtrosPastas", {classe: "btn-sm", retornarHtml: true}));
			botoesSubtitulo.push("</div>");
		}

		this.addSubtitulo({
			texto: this.getLinksHierarquiaAvaliacao(listagemPastaTO.listaItemListaTOHierarquiaAvaliacao),
			htmlDireita: botoesSubtitulo.join("")
		});

		this.append(UtilPaginacao.criarPaginador({
			onCarregarPagina: async (paginacaoTO) => {
				this.numeroDaPagina = paginacaoTO.numPaginaAtual;
				this.itensPorPagina = paginacaoTO.numItensPorPagina;
				this.exibirListagem(codAvaliacao);
			},
			paginacaoTO: {
				numPaginaAtual: this.numeroDaPagina,
				numTotalItens: listagemPastaTO.numTotalItens,
				numItensPorPagina: listagemPastaTO.itensPorPagina,
			},
			// itensPorPagina: listagemPastaTO.itensPorPagina,
			// totalItens: listagemPastaTO.numTotalItens,
			// paginaAtiva: this.numeroDaPagina
		}));

		for (const listagemPastaFilhaTO of listagemPastaTO.listaListagemPastaFilhaTO) {

			const hashEdicao = hashEditarPasta + "/" + listagemPastaFilhaTO.codAvaliacao + "//1";
			
			this.append("<div class='btn-group'>");
			this.append("<a class='btn btn-default btn-lg' pasta='" + listagemPastaFilhaTO.codAvaliacao + "' href='" + hashPasta + "/" + listagemPastaFilhaTO.codAvaliacao + "'>");
			this.append("<i class='fa fa-folder fa-lg'></i> ");
			if (listagemPastaFilhaTO.nomeTipoAvaliacao) {
				this.append("<span class='label-avaliacao' ondragover='return false'>" + listagemPastaFilhaTO.nomeTipoAvaliacao + "</span> ");
			}
			this.append(listagemPastaFilhaTO.nomeAvaliacao);
			this.append("</a>");
			if (listagemPastaFilhaTO.isArquivada) {
				this.append(`<span ondragover='return false'><span class='label label-default'>${this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_006")}</span></span>`);
			} else {
				this.append("<span ondragover='return false'>" + (listagemPastaFilhaTO.numFilhos > 0 ? "<i class='fa fa-folder-o'></i> " + listagemPastaFilhaTO.numFilhos : "") + "</span>");
				this.append("<span ondragover='return false'>" + (listagemPastaFilhaTO.numProvasAssociadas > 0 ? "<i class='fa fa-file-o'></i> " + listagemPastaFilhaTO.numProvasAssociadas : "") + "</span>");
			}
			this.append("<a href='" + hashEdicao + "' class='btn btn-default'><i class='fa fa-pencil'></i></a>");
			this.append("</div>");
		}

		for (var i = 0; i < listagemPastaTO.listaListagemProvaNaPastaTO.length; i++) {

			var listagemProvaNaPastaTO = listagemPastaTO.listaListagemProvaNaPastaTO[i];

			var hash = hashProva + "/" + (listagemProvaNaPastaTO.numAvaliados > 0 ? "5" : "0") + "/" + listagemProvaNaPastaTO.codProva + "/////1";
			this.append("<div class='btn-group'>");
			this.append("<a class='btn btn-default btn-lg' prova='" + listagemProvaNaPastaTO.codProva + "' href='" + hash + "'>");
			this.append("<i class='fa fa-file-o fa-lg'></i> " + listagemProvaNaPastaTO.tituloProva);
			this.append("</a>");
			if (listagemProvaNaPastaTO.isArquivada) {
				this.append(`<span ondragover='return false'><span class='label label-default'>${this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_007")}</span></span>`);
			} else {
				this.append("<span>" + (listagemProvaNaPastaTO.numAvaliados > 0 ? "<i class='fa fa-user-o'></i> " + listagemProvaNaPastaTO.numAvaliados : "") + "</span>");
				this.append("<span>" + (listagemProvaNaPastaTO.dataUltimaResposta ? "<i class='fa fa-clock-o'></i> " + UtilData.toDDMMYYYYHHMM(listagemProvaNaPastaTO.dataUltimaResposta) : "") + "</span>");
			}
			this.append("</div>");
		}

		if (listagemPastaTO.codAvaliacao) {
			this.append("<div class='btn-group'>");
			this.append("<a class='btn btn-default btn-lg' pasta href='" + hashPasta + "/" + (listagemPastaTO.codAvaliacaoPai || "") + "'>");
			this.append("<span class='col-md-9'><small><i class='fa fa-angle-left'></i></small></span>");
			this.append("<span class='col-md-1'></span>");
			this.append("<span class='col-md-1'></span>");
			this.append("<span class='col-md-1'></span>");
			this.append("</a>");
			this.append("</div>");
		}

		this.exibir();

		this.setIdTarget(null);

		var codAvaliacaoDragged = null;
		var codProvaDragged = null;

		$("[pasta], [prova]").attr("draggable", "true").on("dragstart", ({ target }) => {

			target.style.opacity = '0.4';
			codAvaliacaoDragged = null;
			codProvaDragged = null;

			var $target = $(target);

			if ($target.is("[prova]")) {
				codProvaDragged = $target.attr("prova");

			} else if ($target.is("[pasta]")) {
				codAvaliacaoDragged = $target.attr("pasta");
			}

		}).on("dragend", ({ target }) => {
			target.style.opacity = '1';
			codAvaliacaoDragged = null;
			codProvaDragged = null;
		});

		$("[pasta], [pasta-superior]").on("drop", async (event) => {

			event.preventDefault();
			var $this = $(event.target);

			$this.removeClass("onDropOver");

			const mocaoPastaProvaTO: any = {};

			if ($this.is("[pasta-superior]")) {

				mocaoPastaProvaTO.codAvaliacaoRecebendo = $this.attr("pasta-superior");

				if (!mocaoPastaProvaTO.codAvaliacaoRecebendo) {
					mocaoPastaProvaTO.isMocaoParaRoot = true;
				}

			} else {
				mocaoPastaProvaTO.codAvaliacaoRecebendo = $this.closest("[pasta]").attr("pasta");
			}

			if (this.hasValue(codAvaliacaoDragged)) {
				mocaoPastaProvaTO.codAvaliacaoMovendo = codAvaliacaoDragged;
			} else if (this.hasValue(codProvaDragged)) {
				mocaoPastaProvaTO.codProvaMovendo = codProvaDragged;
			} else {
				return;
			}

			if (mocaoPastaProvaTO.codAvaliacaoMovendo
				&& mocaoPastaProvaTO.codAvaliacaoRecebendo
				&& mocaoPastaProvaTO.codAvaliacaoMovendo == mocaoPastaProvaTO.codAvaliacaoRecebendo) {

				return;
			}

			await this.call("ListagemProvasPorPastasFCD/mover", mocaoPastaProvaTO);

			if (mocaoPastaProvaTO.codAvaliacaoMovendo) {
				$("[pasta='" + mocaoPastaProvaTO.codAvaliacaoMovendo + "']").parent().slideUp();
				this.mostrarMsgAjax(this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_008"));
			} else if (mocaoPastaProvaTO.codProvaMovendo) {
				$("[prova='" + mocaoPastaProvaTO.codProvaMovendo + "']").parent().slideUp();
				this.mostrarMsgAjax(this.getMsg("FP_FRONT_ListagemProvasPorPastasVH_009"));
			}

		}).on("dragover", (event) => {
			event.preventDefault();

			var $this = $(event.target);

			if ($this.is("[pasta-superior]")) {
				$this.addClass("onDropOver");
			} else {
				$this.parent().addClass("onDropOver");
			}

		}).on("dragleave", (event) => {

			var $this = $(event.target);

			if ($this.is("[pasta-superior]")) {
				$this.removeClass("onDropOver");
			} else {
				$this.parent().removeClass("onDropOver");
			}
		});
	}

	getLinksHierarquiaAvaliacaoMultipla(listaItemListaTOHierarquiaAvaliacao) {
		let hashPasta = UtilHash.getHash(this.exibirListagem);
		let isRoot = this.isEmpty(listaItemListaTOHierarquiaAvaliacao);

		let hierarquia = ["<a href='" + hashPasta + "'" + (isRoot ? "" : " pasta-superior") + " title='Pasta principal'><i class='fa fa-folder-o fa-lg'></i></a>"];
		let hierarquiaAvaliacao = [];
		if (this.hasValue(listaItemListaTOHierarquiaAvaliacao)) {
			hierarquia.push(" <small> > </small> ");
			for(let listaHierarquia of listaItemListaTOHierarquiaAvaliacao){
				if(this.hasValue(hierarquiaAvaliacao)){
					hierarquiaAvaliacao.push(" | ");
				}
				for(let i = 0; i < listaHierarquia.length; i++) {
					let listaTO = listaHierarquia[i];
					let h = [];
					const hasMoreItens = listaHierarquia[i + 1];
					h.push("<a class='hierarquia-pastas' href='" + hashPasta + "/" + listaTO.id + "' ");
					if (hasMoreItens) {
						h.push(" pasta-superior='" + listaTO.id + "' ");
					}
					h.push(` cod-avaliacao="${listaTO.id}" tipo-avaliacao="${listaTO.descricao}" nome-avaliacao="${listaTO.text}" `);
					h.push(" title='"+listaTO.text+"'><i class='fa fa-folder-o fa-lg'></i></a>");
					if (hasMoreItens) {
						h.push(" <small> > </small> ");
					}
					hierarquiaAvaliacao.push(h.join(""));
				}
			}
			hierarquia.push(hierarquiaAvaliacao.join(""));
		}

		return hierarquia.join("");
	}

	getLinksHierarquiaAvaliacao(listaItemListaTOHierarquiaAvaliacao) {
		var hashPasta = UtilHash.getHash(this.exibirListagem);
		var isRoot = this.isEmpty(listaItemListaTOHierarquiaAvaliacao);

		var hierarquia = ["<a class='hierarquia-pastas' href='" + hashPasta + "'" + (isRoot ? "" : " pasta-superior") + " title='Pasta principal'><i class='fa fa-folder-o fa-lg'></i></a>"];

		if (this.hasValue(listaItemListaTOHierarquiaAvaliacao)) {
			for (var i = 0; i < listaItemListaTOHierarquiaAvaliacao.length; i++) {
				var listaTO = listaItemListaTOHierarquiaAvaliacao[i];
				var h = [];
				h.push("<a class='hierarquia-pastas' href='" + hashPasta + "/" + listaTO.id + "' ");
				if (listaItemListaTOHierarquiaAvaliacao[i + 1]) {
					h.push(" pasta-superior='" + listaTO.id + "' ");
				}
				h.push(` cod-avaliacao="${listaTO.id}" tipo-avaliacao="${listaTO.descricao}" nome-avaliacao="${listaTO.text}" `);
				h.push("> " + listaTO.text);
				h.push("</a>");
				hierarquia.push(h.join(""));
			}
		}

		return hierarquia.join(" <small> > </small> ");
	}
}

const listagemProvasPorPastasVH = new ListagemProvasPorPastasVH();