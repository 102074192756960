class RecursoPFVH extends PFVH {

	$cronoProva: any;
	$cronoSecao: any;
	$cronoQuestao: any;
	
	constructor() {
		super(RecursoPFVH.name);
	}

	async exibirLinksDeRecurso(aplicacaoProvaFeitaTO: AplicacaoProvaFeitaTO) {

		if (aplicacaoProvaFeitaTO.isPermitirMarcarQuestaoParaRecursoAposProva || aplicacaoProvaFeitaTO.isPermitirRecursosDuranteProva) {

			let htmlBotaoRecursoRespondido = `<i class='fa fa-legal'></i> ${this.getMsg("FP_FRONT_RecursoPFVH_001")}`;
			let htmlBotaoRecursoAberto = `<i class='fa fa-legal'></i> ${this.getMsg("FP_FRONT_RecursoPFVH_002")}`;
			let htmlBotaoQuestaoMarcadaParaRecurso = this.getMsg("MSG_VH_APR_57");
			let htmlBotaoParaMarcarRecurso = this.getMsg("MSG_VH_APR_56");

			const listExibicaoRecursosProvaFeitaTO = await this.call("RecursoFCD/recuperarQuestoesComRecurso", aplicacaoProvaFeitaTO.codProvaFeita);

			const m = {};

			for (const exibicaoRecursosProvaFeitaTO of listExibicaoRecursosProvaFeitaTO) {
				m[exibicaoRecursosProvaFeitaTO.codQuestao] = exibicaoRecursosProvaFeitaTO;
			}

			$("div.operacoes_sobre_questao").append("<a class='btn btn-link cinzaMenor recursos' num-recursos='0'>" + htmlBotaoParaMarcarRecurso + "</a>").find("a.recursos").each((i, link) => {

				const $link = $(link);
				const codQuestao = $link.closest("[questao]").attr("cod-questao") * 1;
				let exibicaoRecursosProvaFeitaTO = m[codQuestao];

				if (!exibicaoRecursosProvaFeitaTO) return;

				if (exibicaoRecursosProvaFeitaTO.possuiRecursoDoAluno || exibicaoRecursosProvaFeitaTO.codDuvidaRecurso) {

					$link.attr("num-recursos", 1);

					if (exibicaoRecursosProvaFeitaTO.possuiResposta) {
						$link.removeClass("btn-link cinzaMenor").addClass("btn btn-warning").html(htmlBotaoRecursoRespondido);
					} else if (exibicaoRecursosProvaFeitaTO.possuiRecursoDoAluno) {
						$link.html(htmlBotaoRecursoAberto);
					} else if (exibicaoRecursosProvaFeitaTO.isPodeInterporRecursosDuranteAvaliacao) {
						$link.html(htmlBotaoParaMarcarRecurso);
					} else if (exibicaoRecursosProvaFeitaTO.codDuvidaRecurso) {
						$link.html(htmlBotaoQuestaoMarcadaParaRecurso);
					}

				} else if (!exibicaoRecursosProvaFeitaTO.isPodeCriarNovoRecurso && !exibicaoRecursosProvaFeitaTO.isPodeMarcarQuestoesParaRecursos && !exibicaoRecursosProvaFeitaTO.isPodeInterporRecursosDuranteAvaliacao) {
					$link.hide();
				}

			}).click(({target}) => {
				const $link = $(target);
				const numRecursos = $link.attr("num-recursos");
				const $questao = $link.closest("[questao]");
				const codQuestao = $questao.attr("cod-questao");
				const numerador = $questao.find("[numerador]").attr("numerador");

				this.exibirRecursoNaQuestaoParaAvaliado(aplicacaoProvaFeitaTO.codProvaFeita, codQuestao, numerador, aplicacaoProvaFeitaTO, (exibicaoRecursoAlunoTO) => {
					if (exibicaoRecursoAlunoTO.textoResposta) {
						$link.attr("num-recursos", numRecursos).html(htmlBotaoRecursoRespondido);
					} else if (exibicaoRecursoAlunoTO.textoRecurso) {
						$link.attr("num-recursos", numRecursos).html(htmlBotaoRecursoAberto);
					} else if (exibicaoRecursoAlunoTO.isAvaliadoPodeInterporRecursosDuranteAvaliacao) {
						$link.attr("num-recursos", numRecursos).html(htmlBotaoParaMarcarRecurso);
					} else if (exibicaoRecursoAlunoTO.codDuvidaRecurso) {
						$link.attr("num-recursos", numRecursos).html(htmlBotaoQuestaoMarcadaParaRecurso);
					} else {
						$link.attr("num-recursos", numRecursos).html(htmlBotaoParaMarcarRecurso);
					}
				});
			});
		}
	}

	async exibirRecursoNaQuestaoParaAvaliado(codProvaFeita, codQuestao, numerador, aplicacaoProvaFeitaTO: AplicacaoProvaFeitaTO, metodoCallback) {
		
		let exibicaoRecursoAlunoTO = await this.call("RecursoFCD/exibirRecursos", codProvaFeita, codQuestao);

		let botoes: CfgsAddPopupBotao[] = [];

		if (exibicaoRecursoAlunoTO.textoResposta) {
			// não pode fazer nada, só visualizar
			
		} else if (exibicaoRecursoAlunoTO.isAvaliadoPodeCriarRecursos || exibicaoRecursoAlunoTO.isAvaliadoPodeInterporRecursosDuranteAvaliacao) {
			if (exibicaoRecursoAlunoTO.textoRecurso) {
				botoes.push({
					label: this.getMsg("MSG_VH_RC_14"), 
					classe: "btn-danger", 
					onClick: async () => {
						if (await this.confirmar(this.getMsg("MSG_VH_RC_15"))) {
							exibicaoRecursoAlunoTO = await this.call("RecursoFCD/excluirRecurso", codProvaFeita, codQuestao);
							metodoCallback(exibicaoRecursoAlunoTO);
							return true;
						}
						return false;
					}
				});
			} else {
				botoes.push({
					id: "btn_salvar_recurso",
					label: `<i class='fa fa-legal'></i> ${this.getMsg("MSG_VH_RC_06")}`,
					classe: "btn-warning",
					onClick: async () => {
						let textoRecurso = this.getValor("textoRecurso");
						let uploadMidia = this.getValor("importacaoArquivoRecurso");

						if (this.isEmpty(textoRecurso)) {
							await this.exibirAlerta({ msg: this.getMsg("MSG_VH_RC_07") });
							return false;
						}

						const registroRecurso = {
							codProvaFeita: codProvaFeita,
							codQuestao: codQuestao,
							argumentos: textoRecurso,
							uploadMidia: uploadMidia
						}

						exibicaoRecursoAlunoTO = await this.call("RecursoFCD/registrarRecurso", registroRecurso);

						metodoCallback(exibicaoRecursoAlunoTO);
					}
				});
			}
			
		} else if (exibicaoRecursoAlunoTO.isAvaliadoPodeMarcarQuestoesParaRecursos) {
			if (exibicaoRecursoAlunoTO.codDuvidaRecurso) {
				botoes.push({
					label: this.getMsg("MSG_VH_RC_11"),
					classe: "btn-danger",
					onClick: async () => {
						exibicaoRecursoAlunoTO = await this.call("RecursoFCD/desmarcarQuestaoComoPassivelDeRecurso", exibicaoRecursoAlunoTO.codDuvidaRecurso);
						metodoCallback(exibicaoRecursoAlunoTO);
					}
				});					
			} else {
				botoes.push({
					label:this.getMsg("MSG_VH_RC_02"),
					onClick: async () => {
						exibicaoRecursoAlunoTO = await this.call("RecursoFCD/marcarQuestaoParaRecurso", codProvaFeita, codQuestao);
						metodoCallback(exibicaoRecursoAlunoTO);
					}
				});
			}
		}
		
		botoes.push({ label: this.getMsg("MSG_VH_RC_16") });
		
		this.addPopup({
			id: "recursos_aplicacao",
			width: "50%",
			titulo: this.getMsg("MSG_VH_RC_10") + ` <small>${this.getMsg("FP_FRONT_RecursoPFVH_003", numerador)}</small>`,
			botoes: botoes,
			onHidden: async () => {
				this.$cronoProva = null;
				this.$cronoSecao = null;
				this.$cronoQuestao = null;
			}
		});
		
		if (exibicaoRecursoAlunoTO.textoRecurso) {
			this.append(`
				<label style='width: 100%'>
					${this.getMsg("FP_FRONT_RecursoPFVH_004")}
				</label>
				<blockquote>
					<p>${exibicaoRecursoAlunoTO.textoRecurso}</p>
					<small>${UtilData.toDDMMYYYY(exibicaoRecursoAlunoTO.dataRecurso)}</small>
					${exibicaoRecursoAlunoTO.pathMidia ? `
						<small>
							<a href="${exibicaoRecursoAlunoTO.pathMidia}" style="font-size: 1rem;">
								<i class="fa fa-files-o" aria-hidden="true"></i>
								${UtilArquivo.getNomeArquivo(exibicaoRecursoAlunoTO.pathMidia)}
							</a>
						</small>
					`: ""}
				</blockquote>
			`);
			
			if (exibicaoRecursoAlunoTO.textoResposta) {
				this.append("<blockquote class='pull-right'>");
				if (exibicaoRecursoAlunoTO.tipoRespostaRecurso === TipoRespostaRecurso.INDEFERIMENTO) {
					this.append(`<p><strong>${this.getMsg("FP_FRONT_RecursoPFVH_006")}</strong></p>`);
				} else if (exibicaoRecursoAlunoTO.tipoRespostaRecurso === TipoRespostaRecurso.DEFERIMENTO) {
					this.append(`<p><strong>${this.getMsg("FP_FRONT_RecursoPFVH_007")}</strong></p>`);
				}
				this.append("<p>" + exibicaoRecursoAlunoTO.textoResposta + "</p>");
				this.append("<p><small>em " + UtilData.toDDMMYYYY(exibicaoRecursoAlunoTO.dataResposta) + "</small></p>");
				this.append("</blockquote>");
			} else {
				this.append("<blockquote class='pull-right'>");
				this.append(`<p><small><em>${this.getMsg("FP_FRONT_RecursoPFVH_005")}</em></small></p>`);
				this.append("</blockquote>");
			}
			
		} else {
			if (exibicaoRecursoAlunoTO.codDuvidaRecurso) {
				this.addTexto(this.getMsg("MSG_VH_RC_12"));
			} 
			
			if (exibicaoRecursoAlunoTO.isAvaliadoPodeCriarRecursos || exibicaoRecursoAlunoTO.isAvaliadoPodeInterporRecursosDuranteAvaliacao) {
				this.addEditorHTML({
					id : "textoRecurso",
					label : this.getMsg("MSG_VH_RC_08"),
					classe: "col-md-12", 
					toolbar: "basicaSemColarCopiar"
				});

				if (exibicaoRecursoAlunoTO.isUploadArquivo) {
					this.append("<div class='col-md-12 flex-row-center'>");
					this.addZonaUpload({
						id: "importacaoArquivoRecurso",
						label: this.getMsg("MSG_VH_RC_64"),
						classe: "col-md-6"
					});
					this.append("</div>");
				}
				
				this.addTexto("<i>" + this.getMsg("MSG_VH_RC_09") + "</i>");
			}
			
			if (exibicaoRecursoAlunoTO.isAvaliadoPodeMarcarQuestoesParaRecursos) {
				this.addTexto(this.getMsg("MSG_VH_RC_03"));
				this.addTexto(this.getMsg("MSG_VH_RC_04"));
			}
		}

		if (this.isEmpty(aplicacaoProvaFeitaTO.nota) && aplicacaoProvaFeitaTO.tipoCronometro && exibicaoRecursoAlunoTO.isAvaliadoPodeInterporRecursosDuranteAvaliacao) {
			let cronoLeft = "";

			if (aplicacaoProvaFeitaTO.tipoCronometro === "SECAO") {
				cronoLeft = `
					<span id="crono-secao" title="${this.getMsg("FP_FRONT_RecursoPFVH_008")}">...</span>
				`;
			} else if (aplicacaoProvaFeitaTO.tipoCronometro === "QUESTAO") {
				cronoLeft = `
					<span id="crono-questao" title="${this.getMsg("MSG_VH_APR_139")}">...</span>
				`;
			}

			this.append(`
				<div id="divCronometroRecurso" painel-cronometro-recurso class="col-md-12">
					<strong>${this.getMsg("MSG_VH_RC_60")}:&nbsp;&nbsp;</strong>
					${cronoLeft}
					<span id="crono-prova" class="label label-default" title="${this.getMsg("MSG_VH_APR_140")}">...</span>
				</div>
			`);
		}
		
		this.exibirPopups();

		const $div = $("#divCronometroRecurso");

		if ($div.length) {
			this.$cronoProva = $div.find("#crono-prova");
			
			if (aplicacaoProvaFeitaTO.tipoCronometro === "SECAO") {
				this.$cronoSecao = $div.find("#crono-secao");
				
			} else if (aplicacaoProvaFeitaTO.tipoCronometro === "QUESTAO") {
				this.$cronoQuestao = $div.find("#crono-questao");
			}
		}
	}

	handleCronoProvaAlterado(textoDuracao: string, isTempoAcabando: boolean) {
		try {
			if (!this.$cronoProva) return;

			this.$cronoProva.text(textoDuracao);

			if (isTempoAcabando) {
				this.$cronoProva.removeClass("label-default").addClass("label-danger");
			}
		} catch (e) {
			this.logger.error("Erro ao tentar atualizar cronômetro de prova", e);
		}
	}

	handleCronoSecaoAlterado(textoDuracao: string, isTempoAcabando: boolean) {
		try {
			if (!this.$cronoSecao) return;

			this.$cronoSecao.text(textoDuracao);

			if (isTempoAcabando) {
				this.$cronoSecao.addClass("label label-danger");
			} else {
				this.$cronoSecao.removeClass("label label-danger");
			}
		} catch (e) {
			this.logger.error("Erro ao tentar atualizar cronômetro de seção", e);
		}
	}

	handleCronoQuestaoAlterado(textoDuracao: string, isTempoAcabando: boolean) {
		try {
			if (!this.$cronoQuestao) return;

			this.$cronoQuestao.text(textoDuracao);

			if (isTempoAcabando) {
				this.$cronoQuestao.addClass("label label-danger");
			} else {
				this.$cronoQuestao.removeClass("label label-danger");
			}
		} catch (e) {
			this.logger.error("Erro ao tentar atualizar cronômetro de questão", e);
		}
	}
	
	async handleTempoEsgotado() {
		try {
			const isRespondendoRecursoDuranteProva = $("#divCronometroRecurso").length > 0;

			if (!isRespondendoRecursoDuranteProva) return;

			const modalRecursos = $("#popup_recursos_aplicacao");

			if (modalRecursos.length) {
				const botaoSalvarRecursos = modalRecursos.find("#btn_salvar_recurso");
				if (botaoSalvarRecursos.length) {
					await botaoSalvarRecursos.click();
				}
			}
		} catch (e) {
			this.logger.error("Erro ao tentar salvar recurso por tempo esgotado", e);
		}
	}
}

const recursoPFVH = new RecursoPFVH();
