class MonitoramentoPFVH extends PFVH {
	
	static IS_DENTRO_DE_IFRAME: boolean;
	
	idIntervalContagemSaidaNavegador: any;
	isTratandoSaidaNavegador: boolean;
	isExibindoMsgSaidaNavegador: boolean;
	isExecutandoOnVolta: boolean;
	isJaExibiuAlertaAnulacao: boolean;
	intervaloEnvioKA: number = 60;
	cronoListener: Function = (msDecorridos) => {
		monitoramentoPFVH.verificarSePrecisaEnviarKA(msDecorridos);
	}

	constructor() {
		super(MonitoramentoPFVH.name);
	}
	
	async enviarKeepAlive(msg?: string) {

		const $aplicacaoProva = this.get$AplicacaoProva();

		if (encerramentoPFVH.isEncerradaOuEncerrando($aplicacaoProva) || $aplicacaoProva.data("fp-is-prova-pausada")) return;

		const codProvaFeita = $aplicacaoProva.attr("cod-prova-feita");
		const codProvaFeitaLogUltimoKA = $aplicacaoProva.attr("cod-prova-feita-log-ultimo-ka");

		if (codProvaFeita == null) return;

		if (msg == null && $aplicacaoProva.data("fp-is-enviando-keep-alive")) return;

		$aplicacaoProva.data("fp-is-enviando-keep-alive", true);

		try {
			const keepAliveTO = {
				codProvaFeita: codProvaFeita,
				codProvaFeitaLogUltimoKA: codProvaFeitaLogUltimoKA,
				msg: msg
			};

			const situacaoMonitoramentoPFTO = await this.call({
				endpoint: "MonitoramentoPFFCD/registrarKeepAlive", 
				params: [keepAliveTO],
				desativarMsgCarregando: true,
				onRequestError: async (e: BackendRequestError) => {
					if (e.isSessaoExpirada()) {
						this.pararKA();
					}
					return BackendRequestError.ERRO_TRATADO;
				}
			});
			
			try {
				$aplicacaoProva.data("fp-segundos-sem-keep-alive", 0);
			} catch (ignored) {}

			this.handleMonitoramentoResponse(codProvaFeita, situacaoMonitoramentoPFTO);

		} catch (ignored) {

		} finally {
			$aplicacaoProva.data("fp-is-enviando-keep-alive", false);
		}
	}

	handleMonitoramentoResponse(codProvaFeita: number, situacaoMonitoramentoPFTO: any) {

		try {
			const $aplicacaoProva = this.get$AplicacaoProva();
			let codProvaFeitaLogUltimoKA = $aplicacaoProva.attr("cod-prova-feita-log-ultimo-ka");

			if (!codProvaFeitaLogUltimoKA && situacaoMonitoramentoPFTO.codProvaFeitaLogUltimoKA) {
				$aplicacaoProva.attr("cod-prova-feita-log-ultimo-ka", situacaoMonitoramentoPFTO.codProvaFeitaLogUltimoKA);
			}
		} catch (ignored) {}

		pausaPFVH.handleResponsePausa(codProvaFeita, situacaoMonitoramentoPFTO);
	}
	
	async registrarAuditoriaAU(msg: string, msgIntegracao: any, codProvaFeita: number, codAgendamentoUsuario: number) {

		if (msgIntegracao && !msgIntegracao.substring) {
			msgIntegracao = JSON.stringify(msgIntegracao, null, 4);
		}

		const registroAuditoriaAUTO = {
			msg: msg,
			msgIntegracao: msgIntegracao,
			codProvaFeita: codProvaFeita,
			codAgendamentoUsuario: codAgendamentoUsuario,
		}

		try {
			await this.call({
				endpoint: "AplicacaoProvaFCD/registrarAuditoriaAU",
				params: [registroAuditoriaAUTO],
				desativarMsgCarregando: true,
				onRequestError: async (e: BackendRequestError) => {
					this.logger.error("Erro no registro de auditoria na aplicação online", e);
					return BackendRequestError.ERRO_TRATADO;
				}
			});
		} catch (ignored) {}
	}
	
	onEventoParaRegistroEmLog(msg: string, msgIntegracao: any, codProvaFeita: number, codAgendamentoUsuario: number) {
		this.enviarKeepAlive(null); // vai enviar só se estiver em prova
		this.registrarAuditoriaAU(msg, msgIntegracao, codProvaFeita, codAgendamentoUsuario);
	}

	static handleBeforeUnload() {
		const $aplicacaoProva = monitoramentoPFVH.get$AplicacaoProva();
	
		if (encerramentoPFVH.isEncerradaOuEncerrando($aplicacaoProva)) return;
	
		salvamentoPFVH.salvarProvaFeita();
		monitoramentoPFVH.enviarKeepAlive();
		
		return monitoramentoPFVH.getMsg("MSG_VH_APR_134");
	}

	static async handleUnload() {
		// NAO TEM COMO ACESSAR REDE NESSE EVENTO
		await fiscalizacaoMonitoradaVH.pararMonitoramento();
	}

	async monitorarSaidaDoNavegador() {

		const codProvaFeita = $("#divAplicacaoProva").attr("cod-prova-feita");

		if (!codProvaFeita) return;

	    $("iframe").off("mouseover").on("mouseover", (e) => {
	    	MonitoramentoPFVH.IS_DENTRO_DE_IFRAME = true;
	    });

	    $("iframe").off("mouseout").on("mouseout", (e) => {
	    	MonitoramentoPFVH.IS_DENTRO_DE_IFRAME = false;
	    });

	    $(window).off("blur").on("blur", async ({target}) => {
			if (aplicacaoProvaVH.isJanelaUploadAberta()) return;
	    	if (MonitoramentoPFVH.IS_DENTRO_DE_IFRAME) return;
			await this.onSaidaDoNavegador("Saiu da aba no navegador.");
	    });

		window.removeEventListener("beforeunload", MonitoramentoPFVH.handleBeforeUnload);
		window.addEventListener("beforeunload", MonitoramentoPFVH.handleBeforeUnload);

		window.removeEventListener("unload", MonitoramentoPFVH.handleUnload);
		window.addEventListener("unload", MonitoramentoPFVH.handleUnload);
		
		$(document).off("container-pre-alteracao", MonitoramentoPFVH.onAntesAlteracaoContainer)
			.on("container-pre-alteracao", MonitoramentoPFVH.onAntesAlteracaoContainer)
			.off("fp-pre-logoff", MonitoramentoPFVH.onPreLogoff)
			.on("fp-pre-logoff", MonitoramentoPFVH.onPreLogoff)
			;
	}

	async iniciarKA($aplicacaoProva: any) {
		try {
			this.pararKA();

			const anularAutomaticamente = $aplicacaoProva.attr("anular-automaticamente") === 'true';
	
			if (anularAutomaticamente) {
				const segundosToleranciaMonitoramento = $aplicacaoProva.attr("segundos-tolerancia-monitoramento");
				this.intervaloEnvioKA = segundosToleranciaMonitoramento || 60;
			}
	
			UtilCrono.adicionarListener(this.cronoListener);	
		} catch (e) {
			this.logger.error("Erro ao iniciar keep alive", e);
		}
	}

	pararKA() {
		UtilCrono.removerListener(this.cronoListener);
	}

	verificarSePrecisaEnviarKA(msDecorridos: number) {

		try {
			const $aplicacaoProva = this.get$AplicacaoProva();

			if (!$aplicacaoProva || !UtilAuth.isAutenticado()) {
				this.pararKA();
				return;
			}

			const segundosSemKA = $aplicacaoProva.data("fp-segundos-sem-keep-alive");

			if (this.isEmpty(segundosSemKA)) {
				this.pararKA();
				return;
			}

			if (segundosSemKA >= this.intervaloEnvioKA && !$aplicacaoProva.data("fp-is-enviando-keep-alive") && segundosSemKA % 10 == 0) {
				// caso esteja sem conexão de internet, tentar mandar a cada 10s
				this.enviarKeepAlive(null);
			}

			const segundosDecorridos = Math.round(msDecorridos / 1000);
			$aplicacaoProva.data("fp-segundos-sem-keep-alive", $aplicacaoProva.data("fp-segundos-sem-keep-alive") + segundosDecorridos);
			
		} catch (eKA) {
			console.error("Erro ao verificar se precisar enviar keep alive", eKA);
		}
	}
	
	static onAntesAlteracaoContainer() {

		const $aplicacaoProva = monitoramentoPFVH.get$AplicacaoProva();

		if (encerramentoPFVH.isEncerradaOuEncerrando($aplicacaoProva)) return;
		
		salvamentoPFVH.salvarProvaFeita(); 
		monitoramentoPFVH.onSaidaDoNavegador(UtilMsg.getMsg("FP_FRONT_MonitoramentoPFVH_001"));
		fiscalizacaoMonitoradaVH.pararMonitoramento();

		$(document).off("container-pre-alteracao", MonitoramentoPFVH.onAntesAlteracaoContainer);
	}

	static async onPreLogoff() {
		
		const $aplicacaoProva = monitoramentoPFVH.get$AplicacaoProva();

		if (encerramentoPFVH.isEncerradaOuEncerrando($aplicacaoProva)) return;
	
		salvamentoPFVH.salvarProvaFeita(); 
		monitoramentoPFVH.onSaidaDoNavegador(monitoramentoPFVH.getMsg("FP_FRONT_MonitoramentoPFVH_011"));
		await fiscalizacaoMonitoradaVH.pararMonitoramento();

		$(document).off("fp-pre-logoff", MonitoramentoPFVH.onPreLogoff);
	}

	desativarMonitoramentoSaidaDoNavegador() {
	    $(window).off("blur").off("focus");
		window.onbeforeunload = null;
		$(document).off("container-pre-alteracao", MonitoramentoPFVH.onAntesAlteracaoContainer)
			.off("fp-pre-logoff", MonitoramentoPFVH.onPreLogoff);
	}
	
	async onSaidaDoNavegador(detalhes) {
		
		if (this.isTratandoSaidaNavegador) return;
		
		try {
			this.isTratandoSaidaNavegador = true;
			
			if (this.isExibindoMsgSaidaNavegador) return;
	
			const $aplicacaoProva = this.get$AplicacaoProva();
	
			if (encerramentoPFVH.isEncerradaOuEncerrando($aplicacaoProva) || $aplicacaoProva.data("fp-is-prova-pausada")) {
				return;
			}
	
			const codProvaFeita = $aplicacaoProva.attr("cod-prova-feita");
			const codProvaFeitaLogUltimoKA = $aplicacaoProva.attr("cod-prova-feita-log-ultimo-ka");
	
			if (codProvaFeita == null) return;

			const saidaNavegadorTO = {
				codProvaFeita: codProvaFeita,
				codProvaFeitaLogUltimoKA: codProvaFeitaLogUltimoKA,
				detalhes: detalhes
			};

			try {
				const situacaoMonitoramentoPFTO = await this.call({
					endpoint: "MonitoramentoPFFCD/registrarSaidaNavegador", 
					params: [saidaNavegadorTO],
					desativarMsgCarregando: true,
					onRequestError: async () => BackendRequestError.ERRO_TRATADO
				});
				try {
					$aplicacaoProva.data("fp-segundos-sem-keep-alive", 0);
				} catch (ignored) {}
				try {
					this.handleMonitoramentoResponse(codProvaFeita, situacaoMonitoramentoPFTO);
				} catch (ignored) {}
			} catch (ignored) {}
	
			let numSaidas = $aplicacaoProva.data("fp-num-saidas-navegador") || 0;
	
			numSaidas++;
	
			const segundosToleranciaMonitoramento = $aplicacaoProva.attr("segundos-tolerancia-monitoramento");
			const anularAutomaticamente = $aplicacaoProva.attr("anular-automaticamente") === 'true';
			
			if (segundosToleranciaMonitoramento == null || segundosToleranciaMonitoramento == 0) return;
			
			this.isExibindoMsgSaidaNavegador = true;
			
			if (numSaidas >= 3) {
				await this.onRegrasMonitoramentoVioladas(anularAutomaticamente, this.getMsg("FP_FRONT_MonitoramentoPFVH_002"));
				if(anularAutomaticamente) return;
			}
	
			$aplicacaoProva.data("fp-num-saidas-navegador", numSaidas);

			if (anularAutomaticamente) {

				this.exibirAlerta({
					id: "saida_navegador_alerta",
					titulo: "<span class='text-danger'><i class='fa fa-ban'></i> " + this.getMsg("MSG_VH_APR_169") + "</span>",
					msg: "<p>" + this.getMsg("MSG_VH_APR_170") + " <strong><span id='saida-navegador-tempo-restante' class='label label-danger' style='font-size: 110%'>" + segundosToleranciaMonitoramento + "</span> " + this.getMsg("MSG_VH_APR_171") + "</p>"
						+ (numSaidas == 2 ? "<p>" + this.getMsg("MSG_VH_APR_172") + "</strong>.</p>" : ""),
					botoes: [{label: this.getMsg("MSG_VH_APR_173")}]
				}).then(async () => {
					await this.onVoltaAoNavegador();
				});

			} else {
				let msg = `<p>${this.getMsg("FP_FRONT_MonitoramentoPFVH_006")}</p>`;
				if (numSaidas == 2) {
					msg += `
						<p>
							${this.getMsg("FP_FRONT_MonitoramentoPFVH_003")}
						</p>
					`
				}
				msg += `
					<p>
						${this.getMsg("FP_FRONT_MonitoramentoPFVH_004")} 
						<strong>
							<span id='saida-navegador-tempo-restante' class='label label-danger' style='font-size: 110%'>${segundosToleranciaMonitoramento}</span> 
							${this.getMsg("FP_FRONT_MonitoramentoPFVH_005")}
						</strong>
					</p>
				`;
				this.exibirAlerta({
					id: "saida_navegador_alerta",
					titulo: `<span class='text-danger'><i class='fa fa-ban'></i>${this.getMsg("MSG_VH_APR_169")}</span>`,
					msg: msg,
					botoes: [{label: this.getMsg("MSG_VH_APR_173")}]
				}).then(async () => {
					await this.onVoltaAoNavegador();
				});
			}
	
	    	clearInterval(this.idIntervalContagemSaidaNavegador);
	    	
	    	this.idIntervalContagemSaidaNavegador = setInterval(async () => {
				let tempoRestante = Number($("#saida-navegador-tempo-restante").text());
				tempoRestante = tempoRestante - 1;
	
				if (tempoRestante <= -1) {
					await this.onRegrasMonitoramentoVioladas(anularAutomaticamente, this.getMsg("FP_FRONT_MonitoramentoPFVH_007"));
				} else {
					$("#saida-navegador-tempo-restante").text(tempoRestante);
				}
			}, 1000);
			
		} finally {
			this.isTratandoSaidaNavegador = false;
		}
	}

	async onVoltaAoNavegador(isAnulacao: boolean = false) {
		
		if (this.isExecutandoOnVolta) return;
		
		this.isExecutandoOnVolta = true;
		
		if (this.isExibindoMsgSaidaNavegador) {
			this.isExibindoMsgSaidaNavegador = false;
			$("#saida_navegador_alerta").modal("hide");
			clearInterval(this.idIntervalContagemSaidaNavegador);
		}

		var $aplicacaoProva = $('#divAplicacaoProva');
		
		if ($aplicacaoProva.length == 0) {
			this.isExecutandoOnVolta = false;
			return;
		}

		var codProvaFeita = $aplicacaoProva.attr("cod-prova-feita");
		const codProvaFeitaLogUltimoKA = $aplicacaoProva.attr("cod-prova-feita-log-ultimo-ka");

		if (codProvaFeita == null) return;

		if (!isAnulacao) {
			
			try {
				const voltaNavegadorTO = {
					codProvaFeita: codProvaFeita,
					codProvaFeitaLogUltimoKA: codProvaFeitaLogUltimoKA
				};
				const situacaoMonitoramentoPFTO = await this.call({
					endpoint: "MonitoramentoPFFCD/registrarVoltaNavegador", 
					params: [voltaNavegadorTO],
					desativarMsgCarregando: true,
					onRequestError: async () => BackendRequestError.ERRO_TRATADO
				});
				try {
					$aplicacaoProva.data("fp-segundos-sem-keep-alive", 0);
				} catch (ignored) {}
				try {
					this.handleMonitoramentoResponse(codProvaFeita, situacaoMonitoramentoPFTO);
				} catch (ignored) {}
			} catch (ignored) {

			} finally {
				this.isExecutandoOnVolta = false;
			}

			// $.ajax({
			// 	url: UtilBoot.getURLBaseBackend() + "c/aplicacao/mon/volta",
			// 	contentType: "application/json",
			// 	headers: {"Authorization": UtilAuth.getJwtToken()},
			// 	type: "POST",
			// 	data: JSON.stringify({
			// 		codProvaFeita: codProvaFeita,
			// 		codProvaFeitaLogUltimoKA: codProvaFeitaLogUltimoKA
			// 	}),
			// 	cache: false
			// }).done((monitoramentoResponse, textStatus, jqXHR) => {
			// 	try {
			// 		$aplicacaoProva.data("fp-segundos-sem-keep-alive", 0);
			// 	} catch (ignored) {}
			// 	try {
			// 		this.handleMonitoramentoResponse(codProvaFeita, monitoramentoResponse);
			// 	} catch (ignored) {}

			// }).always(() => {
			// 	this.isExecutandoOnVolta = false;
			// });
		} else {
			this.isExecutandoOnVolta = false;
		}
	}

	async onRegrasMonitoramentoVioladas(anularAutomaticamente, regraMonitoramentoViolada) {

		const codProvaFeita = $("#divAplicacaoProva").attr("cod-prova-feita");

		if (codProvaFeita == null) return;

		if (anularAutomaticamente) {
			this.desativarMonitoramentoSaidaDoNavegador();
			await this.onVoltaAoNavegador(anularAutomaticamente);
		}

		if (anularAutomaticamente) {

			this.logger.info("onRegrasMonitoramentoVioladas chamando encerrarProva");

			encerramentoPFVH.encerrarProva(codProvaFeita, false, regraMonitoramentoViolada);

			await this.sleep(1500);
			
			await this.exibirAlerta({
				titulo: "<span class='text-danger'><i class='fa fa-ban'></i> " + this.getMsg("MSG_VH_APR_174") + "</span>",
				msg: "<p>" + this.getMsg("MSG_VH_APR_175") + "</p>",
				botoes: [{label: "OK"}]
			});
			
		} else if(!this.isJaExibiuAlertaAnulacao) {

			await this.sleep(1500);

			await this.exibirAlerta({
				titulo: `<span class='text-danger'><i class='fa fa-ban'></i> ${this.getMsg("FP_FRONT_MonitoramentoPFVH_009")}</span>`,
				msg: `<p>${this.getMsg("FP_FRONT_MonitoramentoPFVH_008")}</p>`,
				botoes: [{label: this.getMsg("FP_FRONT_MonitoramentoPFVH_010")}]
			});
		}

		this.isJaExibiuAlertaAnulacao = true;

		this.fecharTodasPopups();
	}

	async removerLinksExternos(aplicacaoProvaFeitaTO: AplicacaoProvaFeitaTO) {
		if (aplicacaoProvaFeitaTO.segundosToleranciaMonitoramento) {
			$('[questao] a').removeAttr('href');
		}
	}
}

const monitoramentoPFVH = new MonitoramentoPFVH();