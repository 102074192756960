class DashboardUsuarioVH extends AmaisVH {

	tsInicioAtualizacao: number;

	constructor() {
		super(DashboardUsuarioVH.name);
		this.addOperacaoParaHash("duuo", this.exibirUsuariosOnline);
	}

	async exibirUsuariosOnline() {

		this.limpar();

		if (document.location.host === "root.fabricadeprovas.com.br") {
			this.append(`
				<div class="row">
					<div class="col-md-12">
						<iframe style="border:0px" width="100%" height="600px" src="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=DashboardRoot&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTUyNjU0NDczNTEyNSIsIlUiOiJ1cy1lYXN0LTFfQWI0czRaQXc1IiwiQyI6IjJtcWVwbmc5ZGxubDY4NnA5bWdtNmV0NzF2IiwiSSI6InVzLWVhc3QtMToxZTAzMjU4Ni01MmU5LTQyN2QtOTBmYi0zNTA1NzhmNGViYjQiLCJPIjoiYXJuOmF3czppYW06OjUyNjU0NDczNTEyNTpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1QdWJsaWNSZWFkT25seUFjY2Vzcy1KWE01TzBQOSIsIk0iOiJQdWJsaWMifQ==&start=PT12H&end=null">
						</iframe>
					</div>
				</div>
			`);
		}

		this.setTitulo(this.getMsg("FP_FRONT_DashboardUsuarioVH_001"));
		this.setSubtitulo(this.getMsg("FP_FRONT_DashboardProvaVH_007"));

		this.setIdTarget("tituloSuperiorDireito");

		this.addBotao({
			id: "botaoAtualizarDashboardUsuario", 
			label: `<i class='fa fa-refresh'></i> ${this.getMsg("FP_FRONT_DashboardProvaVH_008")}`, 
			dica: this.getMsg("FP_FRONT_DashboardProvaVH_009"), 
			onClick: async () => {
				await this.atualizarUsuariosOnline();
				$("#botaoAtualizarDashboardUsuario").data("ultima-atualizacao", Date.now());
			}
		});

		this.setIdTarget(null);

		this.append(`
			<div class='row'>
				<div class="col-md-4">
					<div id="metrica-usuarios-online" class="dash-metrica" style="height: 250px">
						<div><i class='fa fa-spinner fa-spin fa-sm'></i></div>
						<label>${this.getMsg("FP_FRONT_DashboardUsuarioVH_002")}</label>
					</div>
				</div>
				<div class="col-md-8">
					<div id='donut-perfis' class="dash-donut" style="height: 250px">
					</div>
				</div>
			</div>

			<div class='row'>
				<div class="col-md-4">
					<div id="metrica-alunos-online" class="dash-metrica" style="height: 250px">
						<div><i class='fa fa-spinner fa-spin fa-sm'></i></div>
						<label>${this.getMsg("FP_FRONT_DashboardUsuarioVH_003")}</label>
					</div>
				</div>
				<div class="col-md-8">
					<div id='donut-situacao-alunos' class="dash-donut" style="height: 250px">
					</div>
				</div>
			</div>

			<div class='row' ${this.getIsAmbienteSegmentado() || this.isRoot() ? "" : " style='display: none' "}>
				<div class="col-md-12">
					<div id='bars-por-empresa-perfil' class="dash-stacked-horizontal-bars">
					</div>
				</div>
			</div>
			
			<div class='row'>
				<div class="col-md-12" id="tabelaDetalhamentoDashUsuarios">
				</div>
			</div>
		`);

		this.exibir();

		UtilHash.registrarHistorico(this.exibirUsuariosOnline);

		await this.atualizarUsuariosOnline();

		this.tsInicioAtualizacao = Date.now();
		this.atualizarAutomaticamente(this.tsInicioAtualizacao);
	}

	async atualizarUsuariosOnline() {

		const dashUsuariosOnlineTO = await this.call("DashboardUsuarioFCD/recuperarUsuariosOnline", {});

		$("#tabelaDetalhamentoDashUsuarios").html("");
		$("#metrica-usuarios-online div").text(dashUsuariosOnlineTO.numUsuariosOnline);
		$("#metrica-alunos-online div").text(dashUsuariosOnlineTO.numAlunosOnline);

		const onSeriesClick = (dataPointTO) => {
			this.detalharGrupo(dashUsuariosOnlineTO, dataPointTO);
			return null;
		}

		UtilGrafico.criarDonut({
			id: "donut-perfis",
			nomeSerie: "Usuários online",
			data: dashUsuariosOnlineTO.porPerfil,
			onSeriesClick
		});

		UtilGrafico.criarDonut({
			id: "donut-situacao-alunos",
			nomeSerie: "Alunos",
			data: dashUsuariosOnlineTO.porSituacaoAluno,
			tema: UtilGrafico.TEMA_VERDES,
			onSeriesClick
		});

		if (this.getIsAmbienteSegmentado() || this.isRoot()) {
			UtilGrafico.criarStackedHorizontalBars({
				id: "bars-por-empresa-perfil",
				nomeSerie: "Por segmento",
				data: dashUsuariosOnlineTO.porEmpresaEPerfil,
				onSeriesClick
			});
		}
		
		return;
	}

	atualizarAutomaticamente(tsInicioAtualizacao: number) {

		if (tsInicioAtualizacao !== this.tsInicioAtualizacao) return;
		
		const $botao = $("#botaoAtualizarDashboardUsuario");

		if ($botao.length == 0) return;

		const agora = Date.now();
		let tsUltima = $botao.data("ultima-atualizacao");

		if (tsUltima == null) {
			$botao.data("ultima-atualizacao", agora);
			tsUltima = agora;
		}

		const porcentagemPercorrida = Math.round(100 * ((agora - tsUltima) / 1000) / 120);

		$botao.css("background", `linear-gradient(90deg, #ddd ${porcentagemPercorrida}%, white 0%)`);

		if (porcentagemPercorrida >= 100) {
			$botao.click();
		}

		setTimeout(() => this.atualizarAutomaticamente(tsInicioAtualizacao), 1000);
	}

	async detalharGrupo(dashUsuariosOnlineTO: any, dataPointTO: any) {
		const filtroDetalhamentoDashUsuariosOnlineTO = {
			codUsuarioAcessoInicio: dashUsuariosOnlineTO.codUsuarioAcessoInicio,
			codUsuarioAcessoFim: dashUsuariosOnlineTO.codUsuarioAcessoFim,
			dataPointTO
		}

		const detalhamentoDashUsuariosOnlineTO = await this.call("DashboardUsuarioFCD/detalharGrupo", filtroDetalhamentoDashUsuariosOnlineTO);

		const listaUsuarioDetalhamentoDashUsuariosOnlineTO = detalhamentoDashUsuariosOnlineTO.listaUsuarioDetalhamentoDashUsuariosOnlineTO;

		$("#tabelaDetalhamentoDashUsuarios").html("");

		this.setIdTarget("tabelaDetalhamentoDashUsuarios");
		
		let hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);
		const colunas: ColunaAddTabela[] = []

		if (this.isAdministradorSistema()) {
			colunas.push({titulo: "Usuário", prop: "<small>#${codUsuario}</small><br>${nomeUsuario}"});
		} else {
			colunas.push({titulo: "Usuário", prop: (usuarioDetalhamentoDashUsuariosOnlineTO) => `
					<a href='${hashEdicaoUsuario}/${usuarioDetalhamentoDashUsuariosOnlineTO.codUsuario}'>
						<i class='fa fa-user'></i>
					</a>
					${usuarioDetalhamentoDashUsuariosOnlineTO.nomeUsuario}
				`
			});
		}

		colunas.push({titulo: "Login", prop: "dataLogin", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: "Último acesso", prop: "dataUltimoAcesso", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: "IP", prop: "ip"});
		colunas.push({titulo: "Navegador", prop: async (usuarioDetalhamentoDashUsuariosOnlineTO) => await loginVH.montarUserAgent(usuarioDetalhamentoDashUsuariosOnlineTO.agente)});

		const hashCorrecao = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada);

		// public Integer codProvaFeitaEmProva;
		// public String tituloPFEmProva;
		// public Integer codProvaFeitaEncerrada;
		// public String tituloPFEncerrada;
		
		if (listaUsuarioDetalhamentoDashUsuariosOnlineTO.some(usuarioDetalhamentoDashUsuariosOnlineTO => usuarioDetalhamentoDashUsuariosOnlineTO.codProvaFeitaEmProva)) {

			colunas.push({titulo: "Em prova", prop:  (usuarioDetalhamentoDashUsuariosOnlineTO) => {
					if (!usuarioDetalhamentoDashUsuariosOnlineTO.codProvaFeitaEmProva) return;
					if (this.isAdministradorSistema()) {
						return `
						<small>#${usuarioDetalhamentoDashUsuariosOnlineTO.codProvaFeitaEmProva}</small>
						<br>
						${usuarioDetalhamentoDashUsuariosOnlineTO.tituloPFEmProva}
					`;
					} else {
						return `
						<a href='${hashCorrecao}/${usuarioDetalhamentoDashUsuariosOnlineTO.codProvaFeitaEmProva}'>
							${usuarioDetalhamentoDashUsuariosOnlineTO.tituloPFEmProva}
						</a>
					`;
					}
				}
			});
		}

		if (listaUsuarioDetalhamentoDashUsuariosOnlineTO.some(usuarioDetalhamentoDashUsuariosOnlineTO => usuarioDetalhamentoDashUsuariosOnlineTO.codProvaFeitaEncerrada)) {
			colunas.push({titulo: "Prova encerrada", prop: (usuarioDetalhamentoDashUsuariosOnlineTO) => {
					if (!usuarioDetalhamentoDashUsuariosOnlineTO.codProvaFeitaEncerrada) return;
					if (this.isAdministradorSistema()) {
						return `
						<small>#${usuarioDetalhamentoDashUsuariosOnlineTO.codProvaFeitaEncerrada}</small>
						<br>
						${usuarioDetalhamentoDashUsuariosOnlineTO.tituloPFEncerrada}
					`;
					} else {
						return `
						<a href='${hashCorrecao}/${usuarioDetalhamentoDashUsuariosOnlineTO.codProvaFeitaEncerrada}'>
							${usuarioDetalhamentoDashUsuariosOnlineTO.tituloPFEncerrada}
						</a>
					`;
					}
				}
			});
		}

		let titulo = dataPointTO.name;

		if (detalhamentoDashUsuariosOnlineTO.nomeEmpresaFiltro) {
			titulo += ` <small> > ${detalhamentoDashUsuariosOnlineTO.nomeEmpresaFiltro} </small>`;
		}

		await this.addTabela({
			titulo,
			collection: listaUsuarioDetalhamentoDashUsuariosOnlineTO,
			id: "tableTabelaDetalhamentoDashUsuarios",
			colunas,
			ordenacao: false,
		});

		this.exibir();

		UtilWindow.scrollTo("#tableTabelaDetalhamentoDashUsuarios");
	}
}

const dashboardUsuarioVH = new DashboardUsuarioVH();