class CadastroQuestaoVH extends AmaisVH {

	exibicaoQuestaoTO: any;
	edicaoQuestaoTO: any;
	salvamentoQuestaoTO: any;
	filtrosSelecaoQuestoesTO: any;
	htmlBotaoClassificacao: string;
	order: string;
	selecaoClassificacaoTO: any;
	codsQuestoes: number[];
	callbackDisciplinaEscolhida: Function;
	filtroSelecaoTO: any;
	listaExibicaoDisciplinaTOUltimasEscolhidas: any;
	codQuestaoASerSubstituida: number;
	codsProvasOcorrencias: number[];
	alertarImagensSemAlt: boolean = false
	alternativasImagensSemAlt: number[] = [];
	configuracaoReversaoAnulacao: any = {};

	constructor() {
		super(CadastroQuestaoVH.name);
		this.addOperacaoParaHash("buscq", this.exibirBuscaQuestoes);
		this.addOperacaoParaHash("cqea", this.exibirAbasQuestao);
		this.addOperacaoParaHash("cqsq", this.exibirSubstituicaoQuestao);
		this.addOperacaoParaHash("cqan", this.anular);
		this.addOperacaoParaHash("cqran", this.removerAnulacao);
	}

	async exibirAbasQuestao(numAba: number, codQuestao: number, idProvaResponsavel?: number, codDemanda?: number, onFinalizacaoEdicaoQuestao?: Function, copiaConfiguracaoQuestaoTO?: CopiaConfiguracoesQuestaoTO, codSecao?: number) {

		UtilHash.registrarHistorico(this.exibirAbasQuestao, numAba, codQuestao, idProvaResponsavel, codDemanda);

		const exibicaoAbasQuestaoTO = await this.call("CadastroQuestaoFCD/recuperarAbasAtivas", codQuestao);

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_144"));

		let abas = [];

		abas.push({
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_145"),
			onClick: () => this.exibirVisualizacao(codQuestao)
		});
		abas.push({ 
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_146"), 
			onClick: async () => await this.editarQuestao(codQuestao, idProvaResponsavel, codDemanda, onFinalizacaoEdicaoQuestao, copiaConfiguracaoQuestaoTO, codSecao),
			habilitada: exibicaoAbasQuestaoTO.podeAlterarQuestao 
		});
		abas.push({
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_147"),
			onClick: () => cadastroQuestaoVH.mostrarUso(codQuestao),
			habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_CADASTRO)
		});
		abas.push({
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_148"),
			onClick: () => loginVH.exibirAcessosDaQuestao(codQuestao),
			habilitada: exibicaoAbasQuestaoTO.podeAlterarQuestao && UtilAuth.possuiAcesso(TipoFuncionalidade.AUDITORIA_QUESTAO)
		});
		abas.push({
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_295"),
			onClick: () => cadastroQuestaoVH.exibirAnulacaoQuestao(codQuestao),
			habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_CADASTRO)
		});

		this.addAbas({ abas, numAbaAtiva: numAba });

		this.exibir();

		if (numAba != 0) {
			const htmlSituacao = exibicaoQuestaoVH.getHtmlSituacaoQuestao(exibicaoAbasQuestaoTO.isQuestaoBloqueada, exibicaoAbasQuestaoTO.isAnulada, exibicaoAbasQuestaoTO.isArquivada);
			if (htmlSituacao) {
				setTimeout(() => $("#corpo").prepend(htmlSituacao), 500);
			}
		}
	}

	async exibirBuscaQuestoes(filtros) {

		UtilHash.registrarHistorico(this.exibirBuscaQuestoes);

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_001"));
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_002"));

		this.setIdTarget("tituloSuperiorDireito");

		this.append("<div class='btn-group'>");
		this.addBotao({
			label: `<i class='fa fa-area-chart'></i> TRI`,
			classe: "btn-sm",
			onClick: () => calculoTRIVH.listarDatasets(),
			visivel: false
		});
		if (this.getIsAmbienteImportacaoBancoQuestoes()) {
			this.addBotao({ 
				texto: this.getMsg("FP_FRONT_CadastroQuestaoVH_003"), 
				onClick: () => revisaoProvaVH.substituirTexto(), 
				classe: "btn-sm" 
			});
			this.addBotao({ 
				texto: this.getMsg("FP_FRONT_CadastroQuestaoVH_004"), 
				onClick: () => feedbacksVH.iniciarFeedbackConteudo(), 
				classe: "btn-sm" 
			});
		}
		this.addBotao({
			label: `<i class='fa fa-table'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_005")}`,
			classe: "btn-sm",
			onClick: () => cadastroCriteriosCorrecaoVH.listarCriterios()
		});
		this.addBotao({ 
			label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_006")}`, 
			onClick: () => this.exibirAbasQuestao(1, null), 
			classe: "btn-success btn-sm" 
		});
		this.append("</div>");

		this.setIdTarget(null);

		this.setMetodoSubmit(async () => await this.buscarQuestoes());

		await selecaoQuestoesVH.montarHtmlFiltros({
			onFiltroAlterado: async () => await cadastroQuestaoVH.buscarQuestoes(cadastroQuestaoVH.onPosAtualizarQuestao),
			possuiAcessoQuestoesComConcurso: this.getIsBancoQuestoesHabilitado(),
			filtrosSelecionados: filtros || null
		});

		this.append("<div class='col-md-12' id='resultado_busca'></div>");

		this.exibir();

		await this.buscarQuestoes(cadastroQuestaoVH.onPosAtualizarQuestao);
	}

	async mudarPagina(numeroPagina, funcaoOnPosQuestao) {
		this.setHtml("resultado_busca_questao", this.getMsg("FP_FRONT_CadastroQuestaoVH_007"))
		this.exibir();
		if (this.filtrosSelecaoQuestoesTO != null) {
			const listagemPaginadaTO = await this.call("CadastroQuestaoFCD/buscarQuestoes", this.filtrosSelecaoQuestoesTO, numeroPagina);
			this.buscarQuestoesCB(listagemPaginadaTO, funcaoOnPosQuestao)
		}
	}

	mudarOrder(funcaoOnPosQuestao, order) {
		this.setHtml("resultado_busca_questao", this.getMsg("FP_FRONT_CadastroQuestaoVH_007"))
		this.exibir();
		if (this.filtrosSelecaoQuestoesTO != null) {
			this.filtrosSelecaoQuestoesTO['order'] = order ?? "ASC";
			this.mudarPagina(0, funcaoOnPosQuestao);
		}
	}

	onPosAtualizarQuestao(questao) {

		let seletor = questao || "[questao]";

		exibicaoQuestaoVH.mostrarBarraDeFerramentas(seletor);

		$(seletor).filter("[pode-alterar]").find("[ferramentas]").prepend(`<a editar class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_008")}'><i class='fa fa-pencil'></i></a>`).find("[editar]").off("click").on("click", ({ target }) => {
			const codQuestao = $(target).closest("[questao]").attr("cod-questao");
			this.abrirNovaJanela(UtilHash.getHash(this.exibirAbasQuestao, 1, codQuestao));
			$("[questao][cod-questao='" + codQuestao + "']").attr("abriu-edicao-nova-pagina", true);
		});

		$(seletor).filter(":not([pode-alterar])").find("[ferramentas]").prepend(`<a copiar class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_230")}'><i class='fa fa-clone'></i></a>`).find("[copiar]").off("click").on("click", ({ target }) => {
			this.exibirAlerta({
				msg: "Deseja criar uma cópia desta questão?",
				botoes: [{
					label: "Criar cópia",
					onClick: async () => {
						const codQuestao = $(target).closest("[questao]").attr("cod-questao");
						const codQuestaoCopia = await this.call("CadastroQuestaoFCD/copiarQuestao", { codQuestao });
						this.abrirNovaJanela(UtilHash.getHash(this.exibirAbasQuestao, 1, codQuestaoCopia));
					}
				}]
			})
		});

		$(seletor)
			.filter("[pode-alterar],[pode-anular-respostas]")
			.filter(":not([anulada='true'])")
			.find("[ferramentas]")
			.prepend(`<a anular class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_009")}'><i class='fa fa-ban'></i></a>`)
			.find("[anular]")
			.off("click")
			.on("click", ({ target }) => {

			let codQuestao = $(target).closest("[questao]").attr("cod-questao");
			this.anular(codQuestao);
		});

		// $(seletor)
		// 	.filter("[pode-alterar],[pode-anular-respostas]")
		// 	.filter("[anulada='true']")
		// 	.find("[ferramentas]")
		// 	.prepend(`<a anular class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_253")}'><i class='fa fa-undo'></i></a>`)
		// 	.find("[anular]")
		// 	.off("click")
		// 	.on("click", ({ target }) => {
		//
		// 	let codQuestao = $(target).closest("[questao]").attr("cod-questao");
		// 	this.removerAnulacao(codQuestao);
		// });

		$(seletor).filter("[pode-alterar]").find("[ferramentas]").prepend(this.getBotaoClassificacao()).find("[classificar]").off("click").on("click", async ({ target }) => {

			let $a = $(target);

			if (!$a.is("a")) $a = $a.closest("a");

			const $questao = $a.closest("[questao]");
			const codQuestao = $questao.attr("cod-questao");
			const categoria = $a.find("[id-categoria]").attr("id-categoria");

			try {
				await this.mostrarOpcoesDeDisciplinas(codQuestao, null, categoria, async (codDisciplina, codsQuestoes, exibicaoDisciplinaTO) => {
					const gravacaoDisciplinaTO = {
						codDisciplina: codDisciplina,
						codsQuestoes: [codQuestao]
					}
					await this.call("CadastroQuestaoFCD/gravarClassificacaoQuestao", gravacaoDisciplinaTO);
					$questao.trigger("atualizar");
				});
			} catch (ignored) {}
		});

		$(seletor).find("[disciplinas]").show();
		$(seletor).filter("[pode-alterar]").find("[disciplinas]").find("a").attr("title", this.getMsg("FP_FRONT_CadastroQuestaoVH_010")).append(" <i class='fa fa-trash'></i>").off("click").on("click", ({ target }) => {
			this.removerClassificacao($(target).closest("a"));
		});
	}

	getBotaoClassificacao() {
		if (this.htmlBotaoClassificacao == null) {
			const categoriasClassificacao = this.getCategoriasUtilizadas() || {};
			let html = [];
			for (const id in categoriasClassificacao) {
				const categoria = categoriasClassificacao[id];
				const nome = exibicaoQuestaoVH.exibirNomeClassificacao({ nomeTipoClassificacao: categoria.nome, descricao: "" });
				html.push(`<li><a classificar><i class='fa fa-plus-circle'></i> <span id-categoria='${id}'>${nome}</span></a></li>`);
			}

			this.htmlBotaoClassificacao =
				`<div class="btn-group" role="group">`
				+ `<button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">`
				+ `<i class='fa fa-tag'></i> <span class="caret"></span>`
				+ `</button>`
				+ `<ul class="dropdown-menu">`
				+ html.join('')
				+ `<li><a classificar><i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_011")}</a></li>`
				+ `</ul>`
				+ `</div>`
				;
		}
		return this.htmlBotaoClassificacao;
	}

	async buscarQuestoes(funcaoOnPosQuestao?) {

		this.filtrosSelecaoQuestoesTO = selecaoQuestoesVH.getFiltrosSelecaoQuestoesTO();

		if (this.filtrosSelecaoQuestoesTO == null) {
			this.filtrosSelecaoQuestoesTO = {};
		}

		if (this.filtrosSelecaoQuestoesTO != null) {
			this.setHtml("resultado_busca", `<i>${this.getMsg("FP_FRONT_CadastroQuestaoVH_012")}</i>`);
			const listagemPaginadaTO = await this.call("CadastroQuestaoFCD/buscarQuestoes", this.filtrosSelecaoQuestoesTO, 0);
			this.buscarQuestoesCB(listagemPaginadaTO, funcaoOnPosQuestao);
		}
	}

	buscarQuestoesCB(listagemPaginadaTO, funcaoOnPosQuestao) {

		this.setHtml("resultado_busca", "");

		this.setIdTarget("resultado_busca");

		this.append("<div style='float: left; width: 100%' id='resultado_busca_questao'></div>");

		this.setIdTarget(null);

		this.exibir();

		this.setHtml("resultado_busca_questao", "");
		this.setIdTarget("resultado_busca_questao");

		listagemPaginadaTO = listagemPaginadaTO || {};
		let listListagemQuestaoTO = listagemPaginadaTO.listaPaginada || [];

		this.append("<div class='col-md-12 flex-center no-padding'>");

		this.append(`
			<div class='col-md-5 action action-order' ${this.hasValue(this.filtrosSelecaoQuestoesTO.textoBusca) ? 'style="visibility: hidden"' : ""}>
			<label style="display: contents;">${this.getMsg("MSG_VH_L_95")}</label>
			<div class='btn-group btn-toggle btn-order-group' style="margin-left: 5px">
		`);

		this.addBotao({
			label: "<i class='fa fa-sort-asc'></i> " + this.getMsg("MSG_VH_L_96"),
			classe: this.order === "ASC" ? "btn-primary-table active" : "btn-default",
			onClick: () => {
				this.order = "ASC";
				this.mudarOrder(funcaoOnPosQuestao, 'ASC');
			}
		});

		this.addBotao({
			label: "<i class='fa fa-sort-desc'></i> " + this.getMsg("MSG_VH_L_97"),
			classe: this.order === "DESC" ? "btn-primary-table active" : "btn-default",
			onClick: () => {
				this.order = "DESC";
				this.mudarOrder(funcaoOnPosQuestao, 'DESC');
			}
		});

		this.append("</div></div>");

		this.append("<div class='col-md-7'>");

		this.append(
			UtilPaginacao.criarPaginador({
				onCarregarPagina: async (paginacaoTO) => {
					this.mudarPagina(paginacaoTO.numPaginaAtual, funcaoOnPosQuestao);
				},
				paginacaoTO: {
					numPaginaAtual: listagemPaginadaTO.numeroPagina,
					numTotalItens: listagemPaginadaTO.qtdTotalRegistros,
					numItensPorPagina: listagemPaginadaTO.itensPorPagina,
				},
				// itensPorPagina: listagemPaginadaTO.itensPorPagina,
				// paginaAtiva: listagemPaginadaTO.numeroPagina,
				// totalItens: listagemPaginadaTO.qtdTotalRegistros,
				displayOpcao: null,
				displayOpcaoInicioFim: null,
				msgListaVazia: this.getMsg("FP_FRONT_CadastroQuestaoVH_013")
			})
		);

		this.append("</div></div>");

		exibicaoQuestaoVH.limparControleTextos();

		for (const listagemQuestaoTO of listListagemQuestaoTO) {
			let exibicaoQuestaoTO = listagemQuestaoTO.exibicaoQuestaoTO;
			this.append(`
				<div class="row">
					<div class="col-md-12" style="padding-top: 20px">
			`);

			exibicaoQuestaoVH.exibirLinkUsoQuestao(listagemQuestaoTO.usoResumidoQuestaoTO);
			exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO);
			this.append(`
					</div>
				</div>
			`);
		}

		if (listagemPaginadaTO.qtdTotalRegistros > 2) {
			this.append(UtilPaginacao.criarPaginador({
				onCarregarPagina: async (paginacaoTO) => {
					this.mudarPagina(paginacaoTO.numPaginaAtual, funcaoOnPosQuestao);
				},
				paginacaoTO: {
					numPaginaAtual: listagemPaginadaTO.numeroPagina,
					numTotalItens: listagemPaginadaTO.qtdTotalRegistros,
					numItensPorPagina: listagemPaginadaTO.itensPorPagina,
				},
				// itensPorPagina: listagemPaginadaTO.itensPorPagina,
				// paginaAtiva: listagemPaginadaTO.numeroPagina,
				// totalItens: listagemPaginadaTO.qtdTotalRegistros,
				displayOpcao: null,
				displayOpcaoInicioFim: null,
			}));
		}

		this.setIdTarget(null);

		this.appendJs(() => $('._popover').popover({html: true}));

		this.exibir();

		if (funcaoOnPosQuestao) {
			$("[questao]").on("atualizar-pos", ({ target }) => {
				funcaoOnPosQuestao.call(this, target);
			});
			funcaoOnPosQuestao.call(this);
		}
	}

	exibirInclusaoMidia(codQuestao) {

		this.addPopup({
			id: "anexacaoMidia",
			titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_020"),
			width: "400px",
			// height: "300px",
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_023"),
				onClick: () => this.anexarMidia()
			}]
		})

		this.setMetodoSubmit(async () => await this.anexarMidia())

		this.addInputHidden("questao_cod", codQuestao);

		this.addZonaUpload({
			id: "questao_midia_arquivo",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_021"),
			classe: "col-md-12 col-lg-12",
			privado: false,
		});

		this.addFormulario({
			id: "form_questao_midia",
			css: "padding: 0;"
		});

		this.addCampoTexto({
			id: "questao_midia_legenda",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_022"),
			classe: "col-md-12"
		});

		this.fecharFormulario();
		this.addEspacamentoHorizontal("1px");

		this.exibirPopups()
	}

	verificarItensIguais() {
		let tipoQuestao = this.getValor("tipoQuestaoEdicao");

		if (tipoQuestao === 'ME' || tipoQuestao ===  'CE') {

			let contador = 0;
			let achouItensIguais = false;
			let contador1 = 0;
			let contador2 = 0;
			let alternativas = [];

			$('.alternativas-iguais').remove();

			while (1) {
				let alternativa = this.getValor("descricaoItem" + contador);

				if (alternativa === null) {
					break;
				}

				alternativas[contador] = alternativa.trim();
				contador++;
			}

			for (contador1 = 0; contador1 < contador; contador1++) {
				if(!this.hasValue(alternativas[contador1])) continue;
				for (contador2 = contador1 + 1; contador2 < contador; contador2++) {

					if (alternativas[contador2] === alternativas[contador1]) {

						$("#componenteItem"+(contador-1)).append(`
							<div class='alert alert-danger alternativas-iguais col-md-12 text-center' role='alert'> 
								${this.getMsg("FP_FRONT_CadastroQuestaoVH_224", String.fromCharCode(65 + contador1).toLowerCase() + ")", String.fromCharCode(65 + contador2).toLowerCase() + ")")}
							</div>
						`);

						$("[id^='btnSalvar']").attr("disabled", true);

						achouItensIguais = true;
						break;
					}
				}

				if (achouItensIguais) {
					break;
				}
			}

			if (!achouItensIguais) {
				$("[id^='btnSalvar']").attr("disabled", false);
				$('.alternativas-iguais').remove();
			}
		} else{
			$("[id^='btnSalvar']").attr("disabled", false);
			$('.alternativas-iguais').remove();
		}
	}

	async anexarMidia() {
		if (!this.validarCamposObrigatorios("questao_midia_arquivo")) {
			return
		}

		let anexacaoMidiaTO = {
			codQuestao: this.getValor("questao_cod"),
			uploadTO: this.getValor("questao_midia_arquivo"),
			legenda: this.getValor("questao_midia_legenda")
		}

		const exibicaoQuestaoTO = await this.call("CadastroQuestaoFCD/anexarMidia", anexacaoMidiaTO);
		await this.atualizarMidias(exibicaoQuestaoTO);
	}

	async editarQuestao(codQuestao: number, idProvaResponsavel: number, codDemanda: number, onFinalizacaoEdicaoQuestao: Function, copiaConfiguracaoQuestaoTO?: CopiaConfiguracoesQuestaoTO, codSecao?: number) {

		const recuperacaoQuestaoTO = {
			codQuestao,
			idProvaResponsavel,
			codDemanda
		}

		let questaoCopiaTO = null;

		if (copiaConfiguracaoQuestaoTO?.codQuestao) {

			const recuperacaoCopiaQuestaoTO = {
				codQuestao: copiaConfiguracaoQuestaoTO.codQuestao,
				idProvaResponsavel,
				codDemanda
			}
			questaoCopiaTO = await this.call("CadastroQuestaoFCD/recuperarQuestao",recuperacaoCopiaQuestaoTO);
		}

		let edicaoQuestaoTO = await this.call("CadastroQuestaoFCD/recuperarQuestao", recuperacaoQuestaoTO);

		if (questaoCopiaTO?.exibicaoQuestaoTO) {
			if (this.hasValue(copiaConfiguracaoQuestaoTO.classificacoes) && questaoCopiaTO.exibicaoQuestaoTO.collectionExibicaoDisciplinaTO) {
				edicaoQuestaoTO.exibicaoQuestaoTO.collectionExibicaoDisciplinaTO = questaoCopiaTO.exibicaoQuestaoTO.collectionExibicaoDisciplinaTO.filter((cl: any) => {
					return copiaConfiguracaoQuestaoTO.classificacoes.indexOf(cl.codDisciplina) > -1;
				});
			}

			if (copiaConfiguracaoQuestaoTO.copiarConfiguracoes === true) {
				edicaoQuestaoTO.idCriterioCorrecao = questaoCopiaTO.idCriterioCorrecao;
				edicaoQuestaoTO.grauDificuldade = questaoCopiaTO.grauDificuldade;
				edicaoQuestaoTO.idUsuarioAutor = questaoCopiaTO.idUsuarioAutor;
				edicaoQuestaoTO.pontuacaoQuestao = questaoCopiaTO.pontuacaoQuestao;

				edicaoQuestaoTO.exibicaoQuestaoTO.tipoQuestao = questaoCopiaTO.exibicaoQuestaoTO.tipoQuestao;
				edicaoQuestaoTO.exibicaoQuestaoTO.isCalculadoraFinanceiraHabilitada = questaoCopiaTO.exibicaoQuestaoTO.isCalculadoraFinanceiraHabilitada;
				edicaoQuestaoTO.exibicaoQuestaoTO.isCalculadoraSimplesHabilitada = questaoCopiaTO.exibicaoQuestaoTO.isCalculadoraSimplesHabilitada;
				edicaoQuestaoTO.exibicaoQuestaoTO.pontuacaoMaxima = questaoCopiaTO.exibicaoQuestaoTO.pontuacaoMaxima;
				edicaoQuestaoTO.exibicaoQuestaoTO.tipoEspacoDiscursiva = questaoCopiaTO.exibicaoQuestaoTO.tipoEspacoDiscursiva;
				edicaoQuestaoTO.exibicaoQuestaoTO.tempoParaResposta = questaoCopiaTO.exibicaoQuestaoTO.tempoParaResposta;
				edicaoQuestaoTO.exibicaoQuestaoTO.maxLinhas = questaoCopiaTO.exibicaoQuestaoTO.maxLinhas;
				edicaoQuestaoTO.exibicaoQuestaoTO.minLinhas = questaoCopiaTO.exibicaoQuestaoTO.minLinhas;
				edicaoQuestaoTO.exibicaoQuestaoTO.isUploadHabilitado = questaoCopiaTO.exibicaoQuestaoTO.isUploadHabilitado;
			}
		}

		this.alertarImagensSemAlt = edicaoQuestaoTO.alertarImagensSemAlt;

		edicaoQuestaoTO.usoResumidoQuestaoTO = edicaoQuestaoTO.usoResumidoQuestaoTO || { numRespostas: 0 };
		edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas = edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas || 0;
		edicaoQuestaoTO.usoResumidoQuestaoTO.numProvasUsando = edicaoQuestaoTO.usoResumidoQuestaoTO.numProvasUsando || 0;

		const exibicaoQuestaoTO = edicaoQuestaoTO.exibicaoQuestaoTO;

		this.exibicaoQuestaoTO = exibicaoQuestaoTO;
		this.edicaoQuestaoTO = edicaoQuestaoTO;
		this.salvamentoQuestaoTO = {};

		let isDiscursiva = exibicaoQuestaoTO.tipoQuestao === edicaoQuestaoTO.tipoQuestaoDiscursiva;
		let isValorExato = exibicaoQuestaoTO.tipoQuestao === edicaoQuestaoTO.tipoQuestaoValorExato;
		let isME = exibicaoQuestaoTO.tipoQuestao === edicaoQuestaoTO.tipoQuestaoME;
		let isVF = exibicaoQuestaoTO.tipoQuestao === edicaoQuestaoTO.tipoQuestaoVF;
		let isPodeEditarConteudo = edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas <= 0 && !edicaoQuestaoTO.possuiCadernoComFR;

		this.limpar(true);
		this.setSubtitulo(codQuestao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_024") : this.getMsg("FP_FRONT_CadastroQuestaoVH_025"));
		if (codQuestao == null) $("#tituloSuperiorDireito").html("");

		const attrCodQuestao = exibicaoQuestaoTO.codQuestao ? " cod-questao='" + exibicaoQuestaoTO.codQuestao + "' " : "";
		this.append(`
			<div class="row">
				<div edicao-questao class="col-md-12" ${attrCodQuestao}>
		`);

		this.setMetodoSubmit(async () => await this.salvar());

		if (edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas || edicaoQuestaoTO.possuiCadernoComFR || edicaoQuestaoTO.usoResumidoQuestaoTO.numProvasUsando) {
			let gravidade = "warning";
			let msg = "";

			if (edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas || edicaoQuestaoTO.possuiCadernoComFR) {
				gravidade = "danger";
			}

			if (edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas) {
				msg = this.getMsg("FP_FRONT_CadastroQuestaoVH_029", edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas);
			} else if (edicaoQuestaoTO.possuiCadernoComFR) {
				msg = this.getMsg("FP_FRONT_CadastroQuestaoVH_027");
			} else if (edicaoQuestaoTO.usoResumidoQuestaoTO.numProvasUsando) {
				msg = this.getMsg("FP_FRONT_CadastroQuestaoVH_028", edicaoQuestaoTO.usoResumidoQuestaoTO.numProvasUsando);
			}

			this.append(`
					<div class="form-group col-xs-12">
						<div class="alert alert-${gravidade} col-xs-12" role="alert">
							<span class='label label-${gravidade}'>${this.getMsg("FP_FRONT_CadastroQuestaoVH_026")}</span>
							${msg}
							<a onclick='cadastroQuestaoVH.exibirAbasQuestao(2,${exibicaoQuestaoTO.codQuestao}, ${idProvaResponsavel || "null"}, ${codDemanda || "null"})'>
								${this.getMsg("FP_FRONT_CadastroQuestaoVH_030")}
							</a>
						</div>
					</div>
			`);
		}

		this.addCampoTexto({
			id: "titulo",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_031"),
			isVisivel: false
		})

		await this.addSelect({
			collection: this.getCollectionTipoQuestoes(),
			id: "tipoQuestaoEdicao",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_032"),
			valor: exibicaoQuestaoTO.tipoQuestao,
			obrigatorio: true,
			onChange: async (event) => await this.atualizarEdicaoQuestao(event),
			habilitado: isPodeEditarConteudo
		})

		await this.addSelect({
			collection: edicaoQuestaoTO.collectionDificuldadeTO,
			propId: "grauDificuldade",
			propLabel: "nome",
			propLabelDescricao: "descricaoPontuacaoPadrao",
			id: "grauDificuldadeQuestao",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_033"),
			valor: edicaoQuestaoTO.grauDificuldade,
			classe: "fp-small-business-hidden",
			onChange: async () => await this.atualizarEdicaoQuestao(),
		});

		this.addCampoTexto({
			id: "pontuacaoQuestao",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_034"),
			valor: edicaoQuestaoTO.pontuacaoQuestao,
			tipo: "NUMERO",
			habilitado: false
		})

		this.criarEdicaoTexto(edicaoQuestaoTO);

		this.addEditorHTML({
			id: "enunciado",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_035"),
			valor: exibicaoQuestaoTO.enunciado,
			classe: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
			habilitado: isPodeEditarConteudo,
			onChange: () => this.validarAcessibilidadeImg()
		});

		this.append(`
					<div id='edicao_midias' class='col-sm-12'>
		`);
		await this.exibirMidias(exibicaoQuestaoTO);
		this.append(`
					</div>
		`);

		if (exibicaoQuestaoTO.codQuestao != null) {
			this.append(`
					<div class='col-sm-12' style='margin-bottom: 8px'>
			`);
			this.addBotao({
				texto: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_037")}`,
				classe: "btn-sm",
				onClick: () => cadastroQuestaoVH.exibirInclusaoMidia(exibicaoQuestaoTO.codQuestao),
				css: "float: right; margin-bottom: 10px",
				habilitado: isPodeEditarConteudo
			});
			this.append(`
					</div>
			`);
		}

		this.addCampoTexto({
			id: "respostaValorExato",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_036"),
			valor: edicaoQuestaoTO.respostaValorExatoCorreta,
			maxLength: 40,
			visivel: isValorExato,
			onChange: isPodeEditarConteudo ? null : () => {
				this.handleAlteracaoGabaritoComResposta(codQuestao, null, this.getValor("respostaValorExato"));
			}
		});

		this.append(`
					<div id='edicaoItens' class='col-sm-12'>
		`);

		if (exibicaoQuestaoTO.tipoQuestao != edicaoQuestaoTO.tipoQuestaoValorExato && exibicaoQuestaoTO.collectionExibicaoItemTO) {
			for (let i = 0; i < exibicaoQuestaoTO.collectionExibicaoItemTO.length; i++) {
				let exibicaoItemTO = exibicaoQuestaoTO.collectionExibicaoItemTO[i];
				if (isME || isVF) {
					this.append(this.montarHtmlItem(i, exibicaoItemTO.codItem, exibicaoQuestaoTO.tipoQuestao, exibicaoItemTO.gabaritoDoItem, exibicaoItemTO.descricao, isPodeEditarConteudo));
				}
			}

			setTimeout(() => {
				for (let i = 0; i < exibicaoQuestaoTO.collectionExibicaoItemTO.length; i++) {
					this.validarAcessiblidadeAlternativa(i);
				}
			}, 300)
		}

		this.append(`
					</div>
		`);

		this.addBotao({
			texto: "<i class='fa fa-plus-circle'></i> " + (isME ? this.getMsg("FP_FRONT_CadastroQuestaoVH_038") : this.getMsg("FP_FRONT_CadastroQuestaoVH_039")),
			classe: "btn-sm",
			onClick: () => {
				let tipoQuestao = this.getValor("tipoQuestaoEdicao")
				let ultimoItem = -1;

				$("[id^='componenteItem']:last").each(function() {
					ultimoItem = Number(this.getAttribute("numItem"))
				})

				let numItem = ultimoItem + 1
				let html = null

				if (tipoQuestao == edicaoQuestaoTO.tipoQuestaoME || tipoQuestao == edicaoQuestaoTO.tipoQuestaoVF) {
					html = this.montarHtmlItem(numItem, null, tipoQuestao, false, "", true);
				}

				$("#edicaoItens").append(html);
				
				$("#componenteItem" + numItem).slideDown(400, async () => await cadastroQuestaoVH.atualizarEdicaoQuestao());
			},
			css: "float: right",
			visivel: exibicaoQuestaoTO.tipoQuestao != edicaoQuestaoTO.tipoQuestaoValorExato && (exibicaoQuestaoTO.tipoQuestao != edicaoQuestaoTO.tipoQuestaoDiscursiva),
			id: "linkNovoItem",
			habilitado: isPodeEditarConteudo
		});

		this.addSubtitulo({
			texto: this.getMsg("FP_FRONT_CadastroQuestaoVH_239"),
			htmlDireita: this.addBotao({
				label: "<i class='fa fa-plus-circle'></i>",
				onClick: async () => await exibicaoQuestaoVH.editarComentario(document,
					exibicaoQuestaoTO.codQuestao, null, edicaoQuestaoTO.collectionTipoComentarioQuestaoTO),
				retornarHtml: true
			})}
		);

		if (!exibicaoQuestaoTO.codQuestao) {
			this.addEspacamentoHorizontal("10px");
			this.append(`
					<div class='adicao_comentarios' id='adicao_comentarios'>
			`);
			for (const tipoComentarioQuestaoTO of edicaoQuestaoTO.collectionTipoComentarioQuestaoTO) {
				this.addEditorHTML({
					id: "comentarios_tipo_" + tipoComentarioQuestaoTO.codTipoComentario,
					label: tipoComentarioQuestaoTO.nome +
						"<a class='btn btn-link comentario-link-apagar'" +
						"onclick='exibicaoQuestaoVH.apagarComentario(this)'><i class='fa fa-trash-o'></i></a>",
					ajuda: tipoComentarioQuestaoTO.isPublico ?
						this.getMsg("FP_FRONT_CadastroQuestaoVH_040") :
						this.getMsg("FP_FRONT_CadastroQuestaoVH_041"),
					classe: "novo_comentario col-xs-12 col-sm-12 col-md-12 col-lg-12"
				});
			}
			this.append(`
					</div>
			`);
		} else if (exibicaoQuestaoTO.collectionExibicaoComentarioTO){

			this.append(`
					<div class='correcao_comentarios comentario_questao_${exibicaoQuestaoTO.codQuestao}'>
			`);

			for (const exibicaoComentarioTO of exibicaoQuestaoTO.collectionExibicaoComentarioTO) {

				const htmlComentario = exibicaoQuestaoVH.exibirComentario(exibicaoQuestaoTO, exibicaoComentarioTO);

				this.append(htmlComentario);
			}
			this.append(`
					</div>
			`);
		}

		this.append(`
					<div class='row'>
						<div class='col-md-12' questao-cadastro-classificacoes>
						</div>
					</div>
					<div class='row'>
						<div class='col-md-12' questao-cadastro-configuracoes>
		`);
		this.addSubtitulo({ texto: `<i class='fa fa-wrench'></i> ` + this.getMsg("FP_FRONT_CadastroQuestaoVH_042")});
		this.append(`
							<div data-fp-subsecao-cfg-questao='true' class='row fp-small-business-hidden' style='border-bottom: 1px dashed rgb(220, 220, 220); margin-top: 20px; margin-bottom: 20px; padding-bottom: 10px'>
								<div class='col-md-2'>
		`);
		this.addSubsubtitulo({ texto: this.getMsg("FP_FRONT_CadastroQuestaoVH_043") });
		this.append(`
								</div>
								<div class='col-md-10'>
		`);

		if (this.hasValue(edicaoQuestaoTO.collectionCriterioCorrecaoTO)) {
			await this.addSelect({
				collection: edicaoQuestaoTO.collectionCriterioCorrecaoTO,
				id: "idCriterioCorrecao",
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_044"),
				classe: "col-md-6",
				valor: edicaoQuestaoTO.idCriterioCorrecao,
				visivel: isDiscursiva,
				onChange: () => {
					let idCriterioCorrecao = this.getValor("idCriterioCorrecao");

					if (idCriterioCorrecao == null) return;

					for (const to of edicaoQuestaoTO.collectionCriterioCorrecaoTO) {
						if (to.id == idCriterioCorrecao) {
							this.setValor("pontuacaoQuestao", to.pontuacaoMaxima);
						}
					}
				}
			});
		}

		this.append(`
								</div>
							</div>
							<div data-fp-subsecao-cfg-questao='true' class='row' style='border-bottom: 1px dashed rgb(220, 220, 220); margin-top: 20px; margin-bottom: 20px; padding-bottom: 10px'>
								<div class='col-md-2'>
		`);
		this.addSubsubtitulo({ texto: this.getMsg("FP_FRONT_CadastroQuestaoVH_045")});
		this.append(`
								</div>
								<div class='col-md-10'>
		`);

		this.addCampoTexto({
			id: "tempoParaResposta",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_046"),
			valor: exibicaoQuestaoTO.tempoParaResposta,
			dicaComoValorInicial: "MM:SS",
			tipo: "TEMPO",
			classe: "col-md-6",
			visivel: true
		});

		this.append(`
									<div class='col-md-6 row flex-end'>
		`);

		this.addCampoTexto({
			id: "minLinhas",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_047"),
			valor: exibicaoQuestaoTO.minLinhas,
			sufixo: "Min. linhas",
			tipo: "NUMERO",
			classe: "col-md-6",
			visivel: isDiscursiva
		});

		this.addCampoTexto({
			id: "maxLinhas",
			valor: exibicaoQuestaoTO.maxLinhas,
			sufixo: "Max. linhas",
			tipo: "NUMERO",
			classe: "col-md-6",
			visivel: isDiscursiva
		});

		this.append(`
									</div>
		`);

		this.addCheckbox({
			id: "isCalculadoraFinanceiraHabilitada",
			classe: "col-md-4",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_048"),
			valor: exibicaoQuestaoTO.isCalculadoraFinanceiraHabilitada,
		});

		this.addCheckbox({
			id: "isCalculadoraSimplesHabilitada",
			classe: "col-md-4",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_232"),
			valor: exibicaoQuestaoTO.isCalculadoraSimplesHabilitada,
		});

		this.addCheckbox({
			id: "isUploadHabilitado",
			classe: "col-md-4",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_049"),
			valor: exibicaoQuestaoTO.isUploadHabilitado,
			visivel: isDiscursiva
		});

		this.addCheckbox({
			id: "isDownloadMidia",
			classe: "col-md-4",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_231"),
			valor: exibicaoQuestaoTO.isDownloadMidia,
			visivel: exibicaoQuestaoTO.collectionExibicaoMidiaTO?.length > 0
		});

		this.addRadioGroup({
			id: "tipoEspacoDiscursiva",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_050"),
			valor: exibicaoQuestaoTO.tipoEspacoDiscursiva || "NENHUM",
			collection: edicaoQuestaoTO.collectionTiposEspacoDiscursivaILTO,
			classe: "col-md-6",
		});

		this.append(`
								</div>
							</div>
							<div data-fp-subsecao-cfg-questao='true' class='row' style='border-bottom: 1px dashed rgb(220, 220, 220); margin-top: 20px; margin-bottom: 20px; padding-bottom: 10px'>
								<div class='col-md-2'>
		`);
		this.addSubsubtitulo({ texto: this.getMsg("FP_FRONT_CadastroQuestaoVH_051") });
		this.append(`
								</div>
								<div class='col-md-10'>
		`);

		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				id: "codEmpresa",
				propId: "id",
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_052"),
				valor: edicaoQuestaoTO.codEmpresa,
				obrigatorio: true,
				visivel: this.getIsAmbienteSegmentado(),
				classe: "col-md-6",
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarSegmentos",
					numMinimoCaracteres: 0,
				},
			});
		} else {
			this.addInputHidden("codEmpresa", edicaoQuestaoTO.codEmpresa);
		}

		await this.addSelect({
			collection: edicaoQuestaoTO.collectionUsuariosAutoresILTO,
			id: "idUsuarioAutor",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_053"),
			valor: exibicaoQuestaoTO.codQuestao ? edicaoQuestaoTO.idUsuarioAutor : this.getCodUsuarioLogado(),
			dica: (this.getIsAmbienteSegmentado() ? this.getMsg("FP_FRONT_CadastroQuestaoVH_054") : null),
			classe: "col-md-6 fp-small-business-hidden",
			onNovo: async (nome) => {
				return await this.confirmar(this.getMsg("FP_FRONT_CadastroQuestaoVH_055", nome));
			}
		});

		if (exibicaoQuestaoTO.codQuestao != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroQuestaoVH_056"), valor: exibicaoQuestaoTO.codQuestao });
		}

		if (edicaoQuestaoTO.nomeCriador != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroQuestaoVH_057"), valor: edicaoQuestaoTO.nomeCriador });
		}

		if (edicaoQuestaoTO.codQuestaoOriginal != null) {
			let href = UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 0, edicaoQuestaoTO.codQuestaoOriginal);
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroQuestaoVH_058"), valor: "<a href='" + href + "' target='_blank'>" + edicaoQuestaoTO.codQuestaoOriginal + "</a>" });
		}

		if (edicaoQuestaoTO.demandaTO) {
			
			if (edicaoQuestaoTO.demandaTO.secoes.length > 0){
				await this.addSelect({
					collection: edicaoQuestaoTO.demandaTO.secoes.map(s => {
						const novoS = JSON.parse(JSON.stringify(s));
						novoS.nome = edicaoQuestaoTO.demandaTO.nomeProva + " - " + s.nome;
						return novoS;
					}),
					id: "selectSecao",
					propId: "codSecao",
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_292"),
					valor: codSecao ?? edicaoQuestaoTO.demandaTO.secoes[edicaoQuestaoTO.demandaTO.secoes.length - 1].codSecao,
					classe: "col-md-6",
					habilitado: this.isEmpty(codSecao),
					obrigatorio: true
				});
			} else {
				this.addCheckbox({
				id: "isEmProva",
				classe: "col-md-6",
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_060", edicaoQuestaoTO.demandaTO.nomeProva),
				valor: true,
				habilitado: false
			});
			}

		}

		if (edicaoQuestaoTO.questaoEmDemandaTO) {
			this.addCheckbox({
				id: "isEmDemanda",
				classe: "col-md-6",
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_225", edicaoQuestaoTO.questaoEmDemandaTO.nomeDemanda),
				valor: true,
				habilitado: false
			});
		}

		this.append(`
								</div>
							</div>
		`);

		if (edicaoQuestaoTO.collectionEdicaoBloqueioQuestaoTO.length > 0){

			this.append(`
							<div id='bloqueioQuestao' data-fp-subsecao-cfg-questao='true' class='row' style='border-bottom: 1px dashed rgb(220, 220, 220); margin-top: 20px; margin-bottom: 20px; padding-bottom: 10px'>
								<div class='col-md-2'>
									<div class='row'>
			`);
			this.addSubsubtitulo({ texto: this.getMsg("FP_FRONT_CadastroQuestaoVH_059")});
			this.append(`
									</div>
								</div>
								<div class='col-md-10'>
			`);

			edicaoQuestaoTO.collectionEdicaoBloqueioQuestaoTO.forEach(edicaoBloqueioQuestaoTO => {
				this.addCheckbox({
					id: "hidden",
					classe: "col-md-6",
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_062"),
					valor: true,
					visivel: false
				});
				this.addTexto({
					texto: `
						<span class='label label-default'>${this.getMsg("FP_FRONT_CadastroQuestaoVH_061")}</span> 
						${this.getMsg("FP_FRONT_CadastroQuestaoVH_063", edicaoBloqueioQuestaoTO.nomeProva, edicaoBloqueioQuestaoTO.nomeUsuario)} 
						${this.getMsg("FP_FRONT_CadastroQuestaoVH_064", UtilData.toDDMMYYYY(edicaoBloqueioQuestaoTO.dataInicio), UtilData.toDDMMYYYY(edicaoBloqueioQuestaoTO.dataFim))}
					`, 
					id: "textoQuestaoBloqueada"
				});
			})

			this.append(`
								</div>
							</div>
			`);
		}

		this.append(`
						</div>
					</div>
				</div>
		`);

		this.addEspacamentoHorizontal("20px");

		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_065"), classe: "pull-left", onClick: async () => {
				if (onFinalizacaoEdicaoQuestao) {
					await onFinalizacaoEdicaoQuestao(exibicaoQuestaoTO.codQuestao);

				} else {
					UtilHash.voltar();
				}
			}
		});

		this.append(`
				<div class='btn-group pull-right'>
		`);

		if (exibicaoQuestaoTO.codQuestao != null) {

			if (UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_ANULACAO)) {
				if (!exibicaoQuestaoTO.isAnulada) {
					this.addBotao({
						label: `<span class='text-danger'><i class='fa fa-ban'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_071")}</span>`,
						classe: "btn-default",
						onClick: async () => await cadastroQuestaoVH.anular(exibicaoQuestaoTO.codQuestao),
						dica: this.getMsg("FP_FRONT_CadastroQuestaoVH_070")
					});
				}
			}

			this.addBotao({
				label: exibicaoQuestaoTO.isArquivada ? this.getMsg("FP_FRONT_CadastroQuestaoVH_067") : `<i class='fa fa-trash-o'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_072")}`,
				classe: exibicaoQuestaoTO.isArquivada ? null : "btn-danger",
				onClick: async () => {
					if ((!exibicaoQuestaoTO.isArquivada && await this.confirmar(this.getMsg("FP_FRONT_CadastroQuestaoVH_068")))
						|| (exibicaoQuestaoTO.isArquivada && await this.confirmar(this.getMsg("FP_FRONT_CadastroQuestaoVH_069")))) {

						await this.call("CadastroQuestaoFCD/arquivar", exibicaoQuestaoTO.codQuestao);
						await this.exibirAbasQuestao(1, codQuestao);
					}
				}
			});

			this.addBotao({
				id: "btnSalvarComo",
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_073"),
				onClick: async () => {
					await this.salvar(TipoSalvamento.SALVAR_COMO, onFinalizacaoEdicaoQuestao);
				}
			});
		}

		this.addBotao({
			id: "btnSalvarQuestaoECriarNova",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_074"),
			onClick: async () => {
				this.addPopup({
					id: "popup_salvar_questao_criar_nova",
					titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_074"),
					width: '50%',
					botoes: [{
						id: "continuarSalvarECriarNova",
						label: this.getMsg("FP_FRONT_CadastroQuestaoVH_260"),
						onClick: async () => {
							let classificacoes = null;
							const radioCopiarClassificacoes = this.getValor("radioCopiarClassificacoes");
							const radioCopiarConfiguracoes = this.getValor("radioCopiarConfiguracoes");
							if(radioCopiarClassificacoes === "COPIAR_TODAS"){
								classificacoes = this.getValor("codsDisciplinas");
							} else if(radioCopiarClassificacoes === "SELECIONAR"){
								classificacoes = this.getValor("codsDisciplinasCopia");
							}

							const copiarConfiguracoes = radioCopiarConfiguracoes === "COPIAR_TODAS";
							let copiaConfiguracoesQuestaoTO: CopiaConfiguracoesQuestaoTO = {
								codQuestao: this.exibicaoQuestaoTO.codQuestao,
								copiarConfiguracoes: copiarConfiguracoes,
								classificacoes: classificacoes ? classificacoes.map((codClassicacao: any) => Number(codClassicacao)): null
							}

							await this.salvar(TipoSalvamento.SALVAR_E_CRIAR_NOVA, onFinalizacaoEdicaoQuestao, copiaConfiguracoesQuestaoTO);
						}
					}]
				});

				this.addRadioGroup({
					id: "radioCopiarConfiguracoes",
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_261"),
					desabilitarDica: true,
					collection: [{
						id: "NAO_COPIAR",
						descricao: this.getMsg("FP_FRONT_CadastroQuestaoVH_262")
					}, {
						id: "COPIAR_TODAS",
						descricao: this.getMsg("FP_FRONT_CadastroQuestaoVH_263")
					}],
					classe: "col-md-12",
					valor: "NAO_COPIAR"
				});

				this.addRadioGroup({
					id: "radioCopiarClassificacoes",
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_264"),
					desabilitarDica: true,
					collection: [{
						id: "NAO_COPIAR",
						descricao: this.getMsg("FP_FRONT_CadastroQuestaoVH_265")
					}, {
						id: "COPIAR_TODAS",
						descricao: this.getMsg("FP_FRONT_CadastroQuestaoVH_266")
					}, {
						id: "SELECIONAR",
						descricao: this.getMsg("FP_FRONT_CadastroQuestaoVH_267")
					}],
					onChange: async () => {
						if(this.getValor("radioCopiarClassificacoes") === "SELECIONAR"){
							this.show("container-classificacoes-questao-copia");
						} else {
							this.hide("container-classificacoes-questao-copia");
						}
					},
					classe: "col-md-12",
					valor: "NAO_COPIAR"
				});

				let listagemDisciplinasTO = this.getCollectionTOListagem('codsDisciplinas');

				if(!this.isEmpty(listagemDisciplinasTO)){
					this.append(`<div id="container-classificacoes-questao-copia" class="col-md-12" style="display: none"></div>`);

					await this.exibirClassificacoesQuestaoCopia(listagemDisciplinasTO);
				}

				this.exibirPopups();
			}
		});

		if (onFinalizacaoEdicaoQuestao) {
			this.addBotao({
				id: "btnSalvarQuestaoEVoltar",
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_075"),
				onClick: async () => {
					await this.salvar(TipoSalvamento.SALVAR_E_VOLTAR, onFinalizacaoEdicaoQuestao);
				}
			});
		}

		this.addBotao({
			id: "btnSalvarSomente",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_076"),
			onClick: async () => {
				await this.salvar(TipoSalvamento.SO_SALVAR, onFinalizacaoEdicaoQuestao);
			}
		});

		this.append(`
				</div>
			</div>
		`);

		this.exibir({ isDeveFazerScrollParaTitulo: true });

		await this.atualizarEdicaoQuestao();
		$("#enunciado").css("height", "auto");
		await this.atualizarEdicaoTexto(edicaoQuestaoTO);
		await this.exibirClassificacoes(exibicaoQuestaoTO);

		setTimeout(()=> {
			if (this.alertarImagensSemAlt) {
				$("#enunciado")
					.parent()
					.append('<div id="alerta_acessibilidade" style="margin-top: 10px"></div>')
			}

			this.validarAcessibilidadeImg();
		}, 300)

	}

	async exibirClassificacoesQuestaoCopia(listagemDisciplinasTO:any){
		$('#container-classificacoes-questao-copia').html("");

		this.setIdTarget('container-classificacoes-questao-copia');

		const colunas: ColunaAddTabela[] = [
			{titulo: "", prop: (exibicaoDisciplinaTO: any) => exibicaoQuestaoVH.exibirNomeClassificacao(exibicaoDisciplinaTO), classe: "descricao"}
		];

		await this.addTabela({
			id: "codsDisciplinasCopia",
			collection: listagemDisciplinasTO,
			propId: "codDisciplina",
			colunas: colunas,
			desabilitarDownload: true,
			ocultarOrdernacao: true,
			msgListaVazia: this.getMsg("FP_FRONT_CadastroQuestaoVH_082"),
			onExclusao: async (botao: any, id: any) => {
				listagemDisciplinasTO = listagemDisciplinasTO.filter((exibicaoDisciplinaTO: any) => exibicaoDisciplinaTO.codDisciplina != id);
				await this.exibirClassificacoesQuestaoCopia(listagemDisciplinasTO);
				this.exibir();
			}
		});
		this.setIdTarget(null);
	}

	validarAcessiblidadeAlternativa(id) {
		this.verificarItensIguais();

		if (this.alertarImagensSemAlt) {
			const elContent = $('#alerta_acessibilidade-alternativa');
			elContent.remove();

			const imagensAlternativa = $(`#descricaoItem${id}`).find('img:not(.cke_reset)');

			let hasImagemSemAlt = false;

			if (imagensAlternativa?.length) {
				imagensAlternativa.each(function() {
					const alt = $(this).attr('alt');
					if (!alt || alt.trim() === '') {
						hasImagemSemAlt = true;
						return false;
					}
				});

				if (hasImagemSemAlt) {
					if (!this.alternativasImagensSemAlt.includes(id)) {
						this.alternativasImagensSemAlt.push(id);
					}
				} else {
					this.alternativasImagensSemAlt = this.alternativasImagensSemAlt.filter(item => item !== id);
				}
			} else {
				this.alternativasImagensSemAlt = this.alternativasImagensSemAlt.filter(item => item !== id);
			}


			if (this.alternativasImagensSemAlt.length) {
				this.alternativasImagensSemAlt.sort();
				const alternativasSemAlt = [];
				this.alternativasImagensSemAlt.forEach(it => {
					const alternativa = $(`#componenteItem${it} label h4`);
					alternativasSemAlt.push(`<b>${alternativa.text().trim()}</b>`)
				})

				$('#edicaoItens').append(`
					<div id="alerta_acessibilidade-alternativa" style="display: flex;flex-direction: column;margin-left: -15px;">
							<div style="width: fit-content;" class="alert alert-warning">
								<i class="fa fa-exclamation-triangle"></i>
								${alternativasSemAlt.length > 1 
								? this.getMsg('FP_FRONT_CadastroQuestaoVH_237', UtilString.converterArrayEmTexto(alternativasSemAlt)) 
								: this.getMsg('FP_FRONT_CadastroQuestaoVH_236', UtilString.converterArrayEmTexto(alternativasSemAlt))}
							</div>
							<div style="width: fit-content;" class="alert alert-info">
								<i class="fa fa-info-circle"></i>
								${this.getMsg('FP_FRONT_CadastroQuestaoVH_235')}
							</div>
						</div>
				`);

			}
		}
	}

	validarAcessibilidadeImg() {
		if (this.alertarImagensSemAlt) {
			const enunciado = this.getValor("enunciado");
			const parser = new DOMParser();
			const doc = parser.parseFromString(enunciado, 'text/html');
			const images = Array.from(doc.querySelectorAll('img'));
			const elContent = $('#alerta_acessibilidade-content');

			if (images?.length) {
				const hasImgSemAcessibilidade = Array.from(images).some(image => {
					const altAttribute = image.getAttribute('alt');
					return !image.hasAttribute('alt') || image.hasAttribute('alt') && altAttribute.trim() === '';
				});

				if (hasImgSemAcessibilidade) {
					const elWrapper = $('#alerta_acessibilidade');
					if (elContent?.length) {
						return;
					}
					if (elWrapper?.length) {
						elWrapper.append(`
						<div id="alerta_acessibilidade-content" style="display: flex;flex-direction: column;">
							<div style="width: fit-content;" class="alert alert-warning">
								<i class="fa fa-exclamation-triangle"></i>
								${this.getMsg('FP_FRONT_CadastroQuestaoVH_234')}
							</div>
							<div style="width: fit-content;" class="alert alert-info">
								<i class="fa fa-info-circle"></i>
									${this.getMsg('FP_FRONT_CadastroQuestaoVH_235')}
							</div>
						</div>
						`);
					}
					return;
				}
			}
			elContent.remove();
		}
	}

	async atualizarEdicaoQuestao(event?: any) {

		this.alternativasImagensSemAlt = [];
		const elContent = $('#alerta_acessibilidade-alternativa');
		elContent.remove();

		let isMudancaTipoQuestao = event?.target?.id === "tipoQuestaoEdicao";

		let tipoQuestao = this.getValor("tipoQuestaoEdicao");

		if(isMudancaTipoQuestao){
			this.verificarItensIguais();
		}

		// GRADE DE CORREÇÃO

		if (this.hasValue(this.edicaoQuestaoTO.collectionCriterioCorrecaoTO)) {
			if (tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoDiscursiva) {
				$("#idCriterioCorrecao").closest(".form-group").show();
			} else {
				$("#idCriterioCorrecao").closest(".form-group").hide();
			}
		}

		if (tipoQuestao === this.edicaoQuestaoTO.tipoQuestaoValorExato) {
			this.setHtml("edicaoItens", "");
			await this.hide("linkNovoItem", "maxLinhas", "minLinhas", "tipoEspacoDiscursiva", "isUploadHabilitado");
			await this.show("respostaValorExato");

		} else if (tipoQuestao === this.edicaoQuestaoTO.tipoQuestaoDiscursiva) {
			this.setHtml("edicaoItens", "");
			await this.show("maxLinhas", "minLinhas", "tipoEspacoDiscursiva", "isUploadHabilitado");
			await this.hide("linkNovoItem", "respostaValorExato");

		} else {
			await this.hide("respostaValorExato");

			if (isMudancaTipoQuestao) {
				$("[id^='inputsItem']").each(function() {
					$(this).html(cadastroQuestaoVH.montarInputsItem(
						tipoQuestao, this.getAttribute("numItem"),
						null, true)
					);
				});

				const linkNovoItem = $("#linkNovoItem");

				if (tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoME) {
					linkNovoItem.html(linkNovoItem.html().replace(
						this.getMsg("FP_FRONT_CadastroQuestaoVH_077"),
						this.getMsg("FP_FRONT_CadastroQuestaoVH_078"))
					);

				} else if (tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoVF) {
					linkNovoItem.html(linkNovoItem.html().replace(
						this.getMsg("FP_FRONT_CadastroQuestaoVH_079"),
						this.getMsg("FP_FRONT_CadastroQuestaoVH_080"))
					);

				} else {
					this.exibirAlerta({ msg: "implementar " + tipoQuestao });
				}
			}

			await this.show("linkNovoItem");
			await this.hide("maxLinhas", "minLinhas", "tipoEspacoDiscursiva", "isUploadHabilitado");
		}

		let grauDificuldadeSelecionado = this.getValor("grauDificuldadeQuestao");
		let pontuacaoPadraoSelecionado = null;

		if (this.edicaoQuestaoTO.collectionDificuldadeTO) {
			for (const dificuldadeTO of this.edicaoQuestaoTO.collectionDificuldadeTO) {
				if (dificuldadeTO.grauDificuldade == grauDificuldadeSelecionado) {
					pontuacaoPadraoSelecionado = dificuldadeTO.pontuacaoPadrao;
				}
			}
		}

		let pontuacaoPadrao = 1;
		let pontuacaoAtual = this.getValor("pontuacaoQuestao");

		if (tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoVF) {
			pontuacaoPadrao = $("[id^='inputsItem']").length;
		}

		if (pontuacaoPadraoSelecionado != null) {
			pontuacaoPadrao = pontuacaoPadrao * pontuacaoPadraoSelecionado;
		}

		if (this.isEmpty(pontuacaoAtual)) {
			this.setValor("pontuacaoQuestao", UtilNumero.floatToString(pontuacaoPadrao));
		}

		if (this.edicaoQuestaoTO.collectionTiposQuestaoForcarPontuacaoPadrao.includes(tipoQuestao)) {
			this.disable("pontuacaoQuestao");
			this.setValor("pontuacaoQuestao", UtilNumero.floatToString(pontuacaoPadrao));
		} else {
			this.enable("pontuacaoQuestao");
		}

		$("[data-fp-subsecao-cfg-questao]").each((i, subsecao) => {
			let $sub = $(subsecao);
			let mostrar = $sub.find("h4:contains('Bloqueio')").length > 0;
			$sub.find(".form-group").each((i, fg) => {
				if ($(fg).css("display") != "none") {
					mostrar = true;
				}
			});
			if (mostrar) {
				$sub.slideDown();
			} else {
				$sub.slideUp();
			}
		});

		if(isMudancaTipoQuestao && (tipoQuestao === this.edicaoQuestaoTO.tipoQuestaoME || tipoQuestao === this.edicaoQuestaoTO.tipoQuestaoVF)){
			const exibicaoQuestaoTO = this.edicaoQuestaoTO.exibicaoQuestaoTO;

			if(exibicaoQuestaoTO.collectionExibicaoItemTO && !this.isEmpty(exibicaoQuestaoTO.collectionExibicaoItemTO)){
				const edicaoItems = $("#edicaoItens");
				edicaoItems.html("");
				let htmlItems = [];
				for (let i = 0; i < exibicaoQuestaoTO.collectionExibicaoItemTO.length; i++) {
					let exibicaoItemTO = exibicaoQuestaoTO.collectionExibicaoItemTO[i];
					htmlItems.push(
						this.montarHtmlItem(i, exibicaoItemTO.codItem, tipoQuestao, exibicaoItemTO.gabaritoDoItem, exibicaoItemTO.descricao, true)
					);
				}
				edicaoItems.append(htmlItems.join(""));
			}
		}

		const CKEDITOR = UtilBoot.getCKEditor();

		CKEDITOR.instances = {}
		
		this.exibir()
	}

	async exibirClassificacoes(exibicaoQuestaoTO) {

		$("[questao-cadastro-classificacoes]").html("");
		this.setIdTarget("[questao-cadastro-classificacoes]");

		exibicaoQuestaoTO.collectionExibicaoDisciplinaTO = exibicaoQuestaoTO.collectionExibicaoDisciplinaTO || [];

		const colunas: ColunaAddTabela[] = [
			{titulo: "", prop: (exibicaoDisciplinaTO) => exibicaoQuestaoVH.exibirNomeClassificacao(exibicaoDisciplinaTO), classe: "descricao"}
		];

		await this.addTabela({
			titulo: `<i class='fa fa-tag'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_081")}`,
			id: "codsDisciplinas",
			collection: exibicaoQuestaoTO.collectionExibicaoDisciplinaTO,
			propId: "codDisciplina",
			colunas: colunas,
			desabilitarDownload: true,
			labelNovo: `<span adicionar-categoria>${this.getMsg("FP_FRONT_CadastroQuestaoVH_081")}</span>`,
			msgListaVazia: this.getMsg("FP_FRONT_CadastroQuestaoVH_082"),
			onNovo: () => { },
			onExclusao: async (botao, id) => {
				exibicaoQuestaoTO.collectionExibicaoDisciplinaTO = exibicaoQuestaoTO.collectionExibicaoDisciplinaTO.filter(exibicaoDisciplinaTO => exibicaoDisciplinaTO.codDisciplina != id);
				await this.exibirClassificacoes(exibicaoQuestaoTO);
			}
		});

		this.exibir();

		const $botaoClassificacao = $("[questao-cadastro-classificacoes] [adicionar-categoria]").closest("a");
		const $btnGroup = $("<div id='btn-group-classificacoes' class='btn-group'>");
		const categoriasClassificacao = this.getCategoriasUtilizadas() || {};

		const classificacoesAtuais = JSON.parse(JSON.stringify(exibicaoQuestaoTO.collectionExibicaoDisciplinaTO));

		for (const classificacao of classificacoesAtuais){
			let classificacaoPai = classificacao.exibicaoDisciplinaTOPai;
			if (classificacaoPai != null){
				classificacoesAtuais.push(classificacaoPai);
			}
		}

		for (const id in categoriasClassificacao) {
			const categoria = categoriasClassificacao[id];

			let classe = "";
			let icon = "fa fa-plus-circle";
			let isClassificacaoPreenchida = false;

			if (classificacoesAtuais.some(classificao => classificao.categoria == id)){
				classe = "class='classificacao-preenchida'";
				icon = "fa fa-check classificacao-preenchida";
				isClassificacaoPreenchida = true;
			}

			let nome = exibicaoQuestaoVH.exibirNomeClassificacao(
				{ nomeTipoClassificacao: categoria.nome, descricao: "" }, isClassificacaoPreenchida);
			nome += categoria.isObrigatoria ? "*" : "";

			$btnGroup.append(`<button class='btn btn-default btn-sm' title="${this.getMsg("FP_FRONT_CadastroQuestaoVH_293")} ${categoria.nome}"><i class='${icon}'></i> <span ${classe} adicionar-categoria id-categoria='${id}'>${nome}</span></button>`);
		}

		$btnGroup.append(`<button class='btn btn-default btn-sm'><i class='fa fa-plus-circle'></i> <span adicionar-categoria>${this.getMsg("FP_FRONT_CadastroQuestaoVH_081")}</span></button>`);

		$botaoClassificacao.replaceWith($btnGroup);

		this.exibir();

		$("[adicionar-categoria]").closest("button").on("click", async ({ target }) => {
			let $target = $(target);
			if (!$target.is("button")) $target = $target.closest("button");
			const categoria = $target.find("[adicionar-categoria]").attr("id-categoria");
			await cadastroQuestaoVH.mostrarOpcoesDeDisciplinas((exibicaoQuestaoTO.codQuestao || "[]"), null, categoria, async (codDisciplina, codsQuestoes, exibicaoDisciplinaTO) => {
				if (exibicaoQuestaoTO.collectionExibicaoDisciplinaTO.some(to => to.codDisciplina == codDisciplina)) {
					this.exibirAlerta({ msg: "Esta classificação já foi vinculada à questão." });
				} else {
					exibicaoQuestaoTO.collectionExibicaoDisciplinaTO.push(exibicaoDisciplinaTO);
					await this.exibirClassificacoes(exibicaoQuestaoTO);
				}
			});
		});
	}

	montarInputsItem(tipoQuestao, numItem, gabaritoDoItem, isPodeEditarConteudo: boolean) {

		const html = [];
		const handleChange = (event: Event) => {
			const codQuestao = Number($(event.target).closest("[cod-questao]").attr("cod-questao"));
			const codItemNovoGabarito = Number($(event.target).closest("[codItem]").attr("codItem"));

			this.handleAlteracaoGabaritoComResposta(codQuestao, codItemNovoGabarito, null);
		}

		if (tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoME) {
			const checked = gabaritoDoItem === true ? "checked='checked'" : "";

			html.push(`
				<input type="radio" name="gabaritoME" id="gabaritoItem${numItem}" ${checked} class="radio-grande-quadrado">
			`);

			if (!isPodeEditarConteudo && gabaritoDoItem === false) {
				this.appendJs(() => {
					$(`#gabaritoItem${numItem}`).on("change", handleChange);
				});
			}

		} else if (tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoVF) {

			const checkedC = gabaritoDoItem === true ? "checked='checked'" : "";
			const checkedE = gabaritoDoItem === false ? "checked='checked'" : "";

			html.push(`
				<span class="cadastroQuestaoDivGabaritoV">
					C
					<br>
					<input type="radio" name="item${numItem}" id="gabaritoItem${numItem}V" class="radio-grande-quadrado" ${checkedC} value="v">
				</span>
				<span class="cadastroQuestaoDivGabaritoF">
					E
					<br>
					<input type="radio" name="item${numItem}" id="gabaritoItem${numItem}F" class="radio-grande-quadrado" ${checkedE} value="f">
				</span>
			`);

			if (!isPodeEditarConteudo) {
				const letra = gabaritoDoItem === false ? "V" : "F";
				this.appendJs(() => {
					$(`#gabaritoItem${numItem}${letra}`).on("change", handleChange);
				});
			}

		} else {
			this.exibirAlerta({ msg: "implementar tipoQuestao " + tipoQuestao });
		}

		return html.join("")
	}

	montarHtmlItem(numItem, codItem, tipoQuestao, gabaritoDoItem, texto, isPodeEditarConteudo) {
		numItem = Number(numItem);

		let html = [];
		const propCodItem = this.hasValue(codItem) ? `codItem="${codItem}"`: ``;

		html.push(`
			<div class="edicao-questao-item" id="componenteItem${numItem}" numItem="${numItem}" ${propCodItem}>
				<h4 numItem="${numItem}">
					${String.fromCharCode(97 + numItem).toUpperCase()})
				</h4>
				<span id="inputsItem${numItem}" numItem="${numItem}">
					${this.montarInputsItem(tipoQuestao, numItem, gabaritoDoItem, isPodeEditarConteudo)}
				</span>
		`);

		html.push(this.addEditorHTML({
			id: "descricaoItem" + numItem,
			valor: texto,
			ignorarForm: true,
			retornarHtml: true,
			html: `onfocusout='cadastroQuestaoVH.validarAcessiblidadeAlternativa(${numItem})'`,
			habilitado: isPodeEditarConteudo,
			onChange:() => this.validarAcessiblidadeAlternativa(numItem)
		}));

		html.push(`
				<a class='btn btn-default btn-sm ${isPodeEditarConteudo ? "" : "disabled"}' title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_083")}' onclick='cadastroQuestaoVH.excluirItem(${numItem})'>
					<i class='fa fa-trash-o'></i>
				</a>
			</div>
		`);

		return html.join("");
	}

	limparCacheClassificacoes() {
		if(this.selecaoClassificacaoTO?.listagemExibicaoDisciplinaTO){
			this.selecaoClassificacaoTO.listagemExibicaoDisciplinaTO = null;
		}
	}

	montarSelecaoClassificacao(listagemExibicaoDisciplinaTO, categoria, exibir)  {


		this.setIdTarget("container-listagem-classificacoes", true);

		this.addListagemHierarquica(listagemExibicaoDisciplinaTO, {
			label: "",
			labelNovo: this.getMsg("FP_FRONT_CadastroQuestaoVH_088"),
			propId: "codDisciplina",
			width: "470px",
			propLabel: "nome",
			propDetalhe: (exibicaoDisciplinaTO: any) => {
				if(this.hasValue(exibicaoDisciplinaTO.nomeFantasiaEmpresa)){
					return `
						<i 
							data-toggle='tooltip' 
							data-placement='top' 
							title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_240")}: ${exibicaoDisciplinaTO.nomeFantasiaEmpresa}' 
							class='fa fa-info-circle'>
						</i>`;
				}
			},
			metodoMontarLabel: (exibicaoDisciplinaTO) => exibicaoQuestaoVH.exibirNomeClassificacao(exibicaoDisciplinaTO),
			onNovo: async (codDisciplinaSuperior) => {
				await this.onNovaDisciplina(codDisciplinaSuperior, categoria);
			},
			onSelecao: async (exibicaoDisciplinaTO) => {
				await this.onSelecaoDisciplina(exibicaoDisciplinaTO);
			},
			metodoMostrarFilhos: async (link, exibicaoDisciplinaTO, toConfigs) => {
				let filtroTO = {
					codDisciplinaPai: exibicaoDisciplinaTO.codDisciplina
				}
				const selecaoClassificacaoTO = await this.call("CadastroQuestaoFCD/recuperarDisciplinasParaClassificacao", filtroTO);
				return selecaoClassificacaoTO.listagemExibicaoDisciplinaTO;
			}
		});

		if(exibir) this.exibir();
		this.setIdTarget(null);
	}

	async mostrarOpcoesDeDisciplinas(codsQuestoes: number[], textoBusca: string, categoria: string, callbackDisciplinaEscolhida: Function) {

		if (textoBusca) {
			textoBusca = textoBusca.trim();
		}

		if (this.hasValue(codsQuestoes)) {
			this.codsQuestoes = codsQuestoes
		}
		
		this.callbackDisciplinaEscolhida = callbackDisciplinaEscolhida;

		this.filtroSelecaoTO = {
			paginacaoTO: null,
			codDisciplinaPai: null,
			categoria: categoria
		}

		this.selecaoClassificacaoTO = await this.call("CadastroQuestaoFCD/recuperarDisciplinasParaClassificacao", this.filtroSelecaoTO);

		let subtitulo = null;

		if (categoria) {
			subtitulo = this.getCategoriasUtilizadas()[categoria].nomePlural;
		}

		this.addPopup({
			id: "opcoesDeDisciplina",
			idPopup: "opcoesDeDisciplinaPopup",
			titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_084"),
			subtitulo: subtitulo,
			width: "90%",
			// height: "510px"
		});

		this.listaExibicaoDisciplinaTOUltimasEscolhidas = await this.call("CadastroQuestaoFCD/recuperarUltimasDisciplinasEscolhidas", categoria);

		this.append("<div class='row'>");
		this.append("<div class='col-md-4' busca_disciplina>");

		this.addSubsubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_085"));

		if (this.listaExibicaoDisciplinaTOUltimasEscolhidas.length > 0) {
			this.append("<ul>")

			this.listaExibicaoDisciplinaTOUltimasEscolhidas.forEach((exibicaoDisciplinaTO, i) => {
				this.append("<li>")
				this.addLink({
					label: exibicaoQuestaoVH.exibirNomeClassificacao(exibicaoDisciplinaTO),
					onClick: () => {
						this.onSelecaoDisciplina(exibicaoDisciplinaTO);
					}
				});
				this.append("</li>")
			});

			this.append("</ul>")
		}

		this.append("</div><div class='col-md-8'>");

		this.addFormulario({
			id: "form_filtro_objeto_avaliacao",
			classe: "row col-md-12"
		});

		this.addCampoTexto({
			id: "filtro_objeto_avaliacao",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_086"),
			sufixo: "<i class='fa fa-search'></i> ",
			onEnter: () => {
				this.buscarClassificacaoQuestao(this.getValor("filtro_objeto_avaliacao"), categoria);
			}
		});

		this.fecharFormulario();

		this.append(`
			<div class='row'>
				<div id='resultado_busca_disciplina' class='col-md-12'></div>
			</div>
		`);

		this.addSubsubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_087"));

		const selecaoPaginacaoTO = this.selecaoClassificacaoTO.paginacaoTO;

		this.append(
			UtilPaginacao.criarPaginador({
				paginacaoTO: selecaoPaginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					this.filtroSelecaoTO.paginacaoTO = paginacaoTO;
					this.selecaoClassificacaoTO = await this.call("CadastroQuestaoFCD/recuperarDisciplinasParaClassificacao", this.filtroSelecaoTO);
					this.montarSelecaoClassificacao(this.selecaoClassificacaoTO.listagemExibicaoDisciplinaTO, categoria, true);
				},
				classe: "paginador-classificacoes",
				// itensPorPagina: selecaoPaginacaoTO.numItensPorPagina,
				// paginaAtiva: selecaoPaginacaoTO.numPaginaAtual,
				// totalItens: selecaoPaginacaoTO.numTotalItens
				displayOpcao: null,
				displayOpcaoInicioFim: null,
			})
		);

		this.append("<div id='container-listagem-classificacoes'></div>");

		this.montarSelecaoClassificacao(this.selecaoClassificacaoTO.listagemExibicaoDisciplinaTO, categoria,false);

		this.append("</div>");
		this.append("</div>");

		this.exibirPopups();

		$("#opcoesDeDisciplinaPopup").on("shown.bs.modal", () => {
			this.focar("filtro_objeto_avaliacao");
			if (textoBusca) {
				this.setValor("filtro_objeto_avaliacao", textoBusca);
				$("#filtro_objeto_avaliacao").trigger("click");
			}
		})
	}

	async buscarClassificacaoQuestao(buscaTextual, categoria) {
		let buscaClassificacaoQuestaoTO = {
			busca: buscaTextual,
			categoria: categoria
		}

		this.setHtml("resultado_busca_disciplina", this.getMsg("FP_FRONT_CadastroQuestaoVH_089"));

		const resultadoBuscaClassificacaoQuestaoTO = await this.call("CadastroQuestaoFCD/buscarClassificacaoQuestao", buscaClassificacaoQuestaoTO);

		let possuiResultados = resultadoBuscaClassificacaoQuestaoTO.collectionExibicaoDisciplinaTO.length > 0
			|| resultadoBuscaClassificacaoQuestaoTO.collectionItemListaTOObjetosDeAvaliacao.length > 0

		if (possuiResultados) {
			this.setHtml("resultado_busca_disciplina", "")
			this.setIdTarget("resultado_busca_disciplina")
		}

		if (resultadoBuscaClassificacaoQuestaoTO.collectionExibicaoDisciplinaTO.length > 0) {

			this.append("<ul>");

			resultadoBuscaClassificacaoQuestaoTO.collectionExibicaoDisciplinaTO.forEach((exibicaoDisciplinaTO, i) => {

				let id = null;

				if (i === 0) {
					id = "primeiraClassificacaoEncontrada";
				}

				let tooltipSegmento = `
					<i 
						data-toggle='tooltip' 
						data-placement='top' 
						title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_240")}: ${exibicaoDisciplinaTO.nomeFantasiaEmpresa}' 
						class='fa fa-info-circle'>
					</i>
				`;

				this.append("<li>");
				this.addLink({
					id: id,
					label: exibicaoQuestaoVH.exibirNomeClassificacao(exibicaoDisciplinaTO) + tooltipSegmento,
					onClick: () => {
						this.onSelecaoDisciplina(exibicaoDisciplinaTO);
					}
				});

				this.append("</li>")
			});

			this.append("</ul>");
			possuiResultados = true;
		}

		if (resultadoBuscaClassificacaoQuestaoTO.collectionItemListaTOObjetosDeAvaliacao.length > 0) {

			this.addSubsubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_090"))

			for (const itemListaTOObjetoAvaliacao of resultadoBuscaClassificacaoQuestaoTO.collectionItemListaTOObjetosDeAvaliacao) {
				this.addTexto(itemListaTOObjetoAvaliacao.descricao)
			}

			possuiResultados = true
		}

		if (possuiResultados) {
			this.exibir();
			this.focar("primeiraClassificacaoEncontrada");
			this.setIdTarget(null)

		} else {
			this.setHtml("resultado_busca_disciplina", "<i>" + this.getMsg("MSG_VH_A_13") + "</i>")
		}
	}

	async onNovaDisciplina(codDisciplinaSuperior, categoria) {

		this.fecharPopup("opcoesDeDisciplinaPopup");

		await cadastroDisciplinaVH.cadastrarNovaDisciplina(
			codDisciplinaSuperior,
			categoria,
			async (codDisciplina, exibicaoDisciplinaTO) => {
				await this.callbackDisciplinaEscolhida(codDisciplina, this.codsQuestoes, exibicaoDisciplinaTO);
			}
		);
	}

	async onSelecaoDisciplina(exibicaoDisciplinaTO) {

		let encontrou = false;

		for (const to of this.listaExibicaoDisciplinaTOUltimasEscolhidas) {
			if (to.codDisciplina == exibicaoDisciplinaTO.codDisciplina) {
				encontrou = true;
			}
		}

		if (!encontrou) {
			this.listaExibicaoDisciplinaTOUltimasEscolhidas.unshift(exibicaoDisciplinaTO);
		}

		if (!exibicaoDisciplinaTO.isClassificacaoPermitida) {
			this.exibirAlerta({
				titulo: `<i class="fas fa-exclamation-triangle"></i> ${this.getMsg('FP_FRONT_CadastroAOVH_132')}`,
				msg: this.getMsg('FP_FRONT_CadastroQuestaoVH_238'),
				botoes: [{label: "OK"}]
			});
			return;
		}

		await this.callbackDisciplinaEscolhida(exibicaoDisciplinaTO.codDisciplina, this.codsQuestoes, exibicaoDisciplinaTO);
		this.fecharPopup("opcoesDeDisciplinaPopup");
	}

	async salvar(tipoSalvamento?: TipoSalvamento, onFinalizacaoEdicaoQuestao?: Function, copiaConfiguracoesQuestaoTO?: CopiaConfiguracoesQuestaoTO) {

        this.verificarObrigatorios();

		if (tipoSalvamento === TipoSalvamento.SALVAR_COMO && !await this.confirmar(this.getMsg("FP_FRONT_CadastroQuestaoVH_091"))) {
			return;
		}

		this.salvamentoQuestaoTO.codQuestao = this.exibicaoQuestaoTO.codQuestao;
		this.salvamentoQuestaoTO.enunciado = this.getValor("enunciado");
		this.salvamentoQuestaoTO.tipoQuestao = this.getValor("tipoQuestaoEdicao");
		this.salvamentoQuestaoTO.respostaValorExato = this.getValor("respostaValorExato");
		this.salvamentoQuestaoTO.codEmpresa = this.getValor("codEmpresa");
		this.salvamentoQuestaoTO.idUsuarioAutor = this.getValor("idUsuarioAutor");
		this.salvamentoQuestaoTO.codSecao = this.getValor("selectSecao");
		this.salvamentoQuestaoTO.isSalvarComo = (tipoSalvamento === TipoSalvamento.SALVAR_COMO);
		this.salvamentoQuestaoTO.grauDificuldade = this.getValor("grauDificuldadeQuestao");
		this.salvamentoQuestaoTO.pontuacaoQuestao = this.getValor("pontuacaoQuestao");
		this.salvamentoQuestaoTO.idCriterioCorrecao = this.getValor("idCriterioCorrecao");
		this.salvamentoQuestaoTO.maxLinhas = this.getValor("maxLinhas");
		this.salvamentoQuestaoTO.minLinhas = this.getValor("minLinhas");
		this.salvamentoQuestaoTO.tipoEspacoDiscursiva = this.getValor("tipoEspacoDiscursiva");
		this.salvamentoQuestaoTO.listaSalvamentoComentarioTO = [];
		this.salvamentoQuestaoTO.collectionSalvamentoItemTO = [];
		this.salvamentoQuestaoTO.tempoParaResposta = this.getValor("tempoParaResposta");

		this.salvamentoQuestaoTO.codsDisciplinas = this.getValor("codsDisciplinas");
		this.salvamentoQuestaoTO.collectionEdicaoQuestaoTextoTO = this.edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO;
		this.salvamentoQuestaoTO.isCalculadoraFinanceiraHabilitada = this.getValor("isCalculadoraFinanceiraHabilitada");
		this.salvamentoQuestaoTO.isCalculadoraSimplesHabilitada = this.getValor("isCalculadoraSimplesHabilitada");
		this.salvamentoQuestaoTO.isUploadHabilitado = this.getValor("isUploadHabilitado");
		this.salvamentoQuestaoTO.idProvaResponsavel = this.edicaoQuestaoTO.demandaTO?.idProvaResponsavel;
		this.salvamentoQuestaoTO.codDemanda = this.edicaoQuestaoTO.questaoEmDemandaTO?.codDemanda;
		this.salvamentoQuestaoTO.isDownloadMidia = this.getValor("isDownloadMidia");

		$("[id^='comentarios_tipo_']").each((i, input) => {
			
			const codTipoComentario = input.id.substring(input.id.lastIndexOf("_") + 1);
			const textoDoComentario = this.getValor(input);

			if (this.hasValue(textoDoComentario)) {
				this.salvamentoQuestaoTO.listaSalvamentoComentarioTO.push({
					codComentario: null,
					codQuestao: this.salvamentoQuestaoTO.codQuestao,
					codUsuarioAutor: this.getCodUsuarioLogado(),
					descricao: textoDoComentario,
					codTipoComentario: codTipoComentario
				});
			}
		});
		// const self = this;
		// const boxListagemComentarios = $(document).find(".adicao_comentarios")

        // boxListagemComentarios.children().each(function () {
		// 	const boxComentario = $(this).children("div");
		// 	const tipoComentario = boxComentario.attr('id');
		// 	const codTipoComentario = parseInt(tipoComentario.match(/(\d+)$/)[0], 10);
		// 	const textoComentario = boxComentario.text();

		// 	if (textoComentario) {
		// 		self.salvamentoQuestaoTO.listaSalvamentoComentarioTO.push({
		// 			codComentario: null,
		// 			codQuestao: self.exibicaoQuestaoTO.codQuestao,
		// 			codUsuarioAutor: self.getCodUsuarioLogado(),
		// 			descricao: textoComentario,
		// 			codTipoComentario: codTipoComentario
		// 		});
		// 	}

		// })

		if (this.salvamentoQuestaoTO.idUsuarioAutor && this.salvamentoQuestaoTO.idUsuarioAutor < 0) {
			this.salvamentoQuestaoTO.idUsuarioAutor = null;
			this.salvamentoQuestaoTO.nomeNovoUsuarioAutor = this.getTextoSelect("idUsuarioAutor");
		}

		if (!this.exibicaoQuestaoTO.isConteudoBloqueado && this.isEmpty(this.salvamentoQuestaoTO.enunciado)) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_092") });
			return;
		}

		if (this.isEmpty(this.salvamentoQuestaoTO.codEmpresa)) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_093") });
			this.focar("codEmpresa")
			return;
		}

		if (this.salvamentoQuestaoTO.tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoValorExato && this.isEmpty(this.salvamentoQuestaoTO.respostaValorExato)) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_094") });
			return
		}

		if (this.isEmpty(this.salvamentoQuestaoTO.pontuacaoQuestao)) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_095") });
			return;
		}

		let textareasDosItens = []

		$("[id^='descricaoItem']").each(function() {
			textareasDosItens.push(this);
		})

		let faltouGabaritoME = true

		for (const textareaDoItem of textareasDosItens) {
			let numItem = textareaDoItem.id.substring("descricaoItem".length)
			let gabaritoItem = null
			let textoDoItem = this.getValor(textareaDoItem.id);

			if (!this.exibicaoQuestaoTO.isConteudoBloqueado && this.isEmpty(textoDoItem)) {
				this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_096") });
				return;
			}

			if (this.salvamentoQuestaoTO.tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoME) {
				gabaritoItem = this.getElementoHtml("gabaritoItem" + numItem).checked

				if (gabaritoItem === true) {
					faltouGabaritoME = false
				}

			} else if (this.salvamentoQuestaoTO.tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoVF) {
				const gabaritoItemV = this.getElementoHtml("gabaritoItem" + numItem + "V").checked
				const gabaritoItemF = this.getElementoHtml("gabaritoItem" + numItem + "F").checked

				if (gabaritoItemV === true) {
					gabaritoItem = true
				} else if (gabaritoItemF === true) {
					gabaritoItem = false
				} else {
					this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_097") });
					return
				}

				faltouGabaritoME = false
			}

			this.salvamentoQuestaoTO.collectionSalvamentoItemTO.push({
				numItem: numItem,
				descricao: textoDoItem,
				isRespostaCerta: gabaritoItem
			});
		}

		if (this.salvamentoQuestaoTO.tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoVF) {
			if (this.salvamentoQuestaoTO.collectionSalvamentoItemTO.length < 1) {
				this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_223") });
				return;
			}
		}

		if (this.salvamentoQuestaoTO.tipoQuestao == this.edicaoQuestaoTO.tipoQuestaoME) {
			if (faltouGabaritoME) {
				this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_098") });
				return;
			}
			if (this.salvamentoQuestaoTO.collectionSalvamentoItemTO.length < 2) {
				this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_222") });
				return;
			}
		}

		if (tipoSalvamento !== TipoSalvamento.SALVAR_COMO && this.salvamentoQuestaoTO.tipoQuestao != this.exibicaoQuestaoTO.tipoQuestao && this.edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas > 0) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_099", this.edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas) });
			return;
		}

		let verificacaoAntesDeSalvarQuestaoTO = await this.call("CadastroQuestaoFCD/verificarAntesDeSalvar", this.salvamentoQuestaoTO);

		let funcaoSalvar = async (onGabaritoAlteradoDeveArquivarECriarNova: any) => {

			if (onGabaritoAlteradoDeveArquivarECriarNova === true) {
				this.salvamentoQuestaoTO.isSalvarComoArquivandoAnterior = true;
			}

			if (onGabaritoAlteradoDeveArquivarECriarNova === false) {
				this.salvamentoQuestaoTO.isSalvarAlteracaoDeGabaritoERecalcularNotas = true;
			}

			const salvamentoQuestaoTO = await this.fazerUploadImagensExternas(this.salvamentoQuestaoTO);

			const codQuestao = await this.call("CadastroQuestaoFCD/salvar", salvamentoQuestaoTO);

			if (tipoSalvamento === TipoSalvamento.SALVAR_E_CRIAR_NOVA) {
				if(!copiaConfiguracoesQuestaoTO.codQuestao){
					copiaConfiguracoesQuestaoTO.codQuestao = codQuestao;
				}
				await this.exibirAbasQuestao(1, null, this.salvamentoQuestaoTO.idProvaResponsavel, this.salvamentoQuestaoTO.codDemanda, onFinalizacaoEdicaoQuestao, copiaConfiguracoesQuestaoTO);

			} else if (onFinalizacaoEdicaoQuestao) {
				onFinalizacaoEdicaoQuestao(codQuestao);

			} else if (tipoSalvamento === TipoSalvamento.SALVAR_E_VOLTAR) {
				await cadastroProvaVH.exibirAbasProva(2, null, codQuestao, this.salvamentoQuestaoTO.idProvaResponsavel);

			} else if (this.salvamentoQuestaoTO.isSalvarComoArquivandoAnterior || this.salvamentoQuestaoTO.isSalvarComo) {
				await this.exibirAbasQuestao(1, codQuestao, this.salvamentoQuestaoTO.idProvaResponsavel);

			} else {
				await this.exibirAbasQuestao(1, codQuestao, this.salvamentoQuestaoTO.idProvaResponsavel);
			}

			this.fecharPopup("opcaoEmMudancaDeGabarito");
		}

		if (verificacaoAntesDeSalvarQuestaoTO.msgExplicandoSalvarComoObrigatorio != null) {

			if (!await this.confirmar(verificacaoAntesDeSalvarQuestaoTO.msgExplicandoSalvarComoObrigatorio)) {
				return;
			}

			this.salvamentoQuestaoTO.isSalvarComoArquivandoAnterior = true;

		} else if(verificacaoAntesDeSalvarQuestaoTO.possuiItensDuplicados === true){
			this.addPopup({
				id: "modalAlertaItensDuplicados",
				titulo: this.getMsg("MSG_VH_AP_44"),
				width: "400px", 
				// height: "400px"
			});

			this.addTexto(this.getMsg("FP_FRONT_CadastroQuestaoVH_214"));

			this.exibirPopups();
			return;
		} else {
			funcaoSalvar.call(this);
		}
	}

	async fazerUploadImagensExternas(salvamentoQuestaoTO) {
		const div = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_CadastroQuestaoVH_106"));

		salvamentoQuestaoTO.enunciado = await UtilHtml.enviarImagensParaArmazenamento(salvamentoQuestaoTO.enunciado);

		for (const salvamentoItemTO of salvamentoQuestaoTO.collectionSalvamentoItemTO) {
			salvamentoItemTO.descricao = await UtilHtml.enviarImagensParaArmazenamento(salvamentoItemTO.descricao);
		}

		for (const edicaoQuestaoTextoTO of salvamentoQuestaoTO.collectionEdicaoQuestaoTextoTO) {
			edicaoQuestaoTextoTO.titulo = await UtilHtml.enviarImagensParaArmazenamento(edicaoQuestaoTextoTO.titulo);
			edicaoQuestaoTextoTO.conteudo = await UtilHtml.enviarImagensParaArmazenamento(edicaoQuestaoTextoTO.conteudo);
			edicaoQuestaoTextoTO.rodape = await UtilHtml.enviarImagensParaArmazenamento(edicaoQuestaoTextoTO.rodape);
		}

		div.remove();

		return salvamentoQuestaoTO;
	}

	excluirItem(numItem) {

		if (this.salvamentoQuestaoTO.codsItensRemovidos == null) {
			this.salvamentoQuestaoTO.codsItensRemovidos = []
		}

		const excluir = async (elemento) => {
			try {
				const CKEDITOR = UtilBoot.getCKEditor();
				CKEDITOR.instances["descricaoItem" + numItem].destroy();
				CKEDITOR.remove("descricaoItem" + numItem);
			} catch (e) { }

			let codItem = elemento.getAttribute("codItem");

			if (codItem != null) {
				this.salvamentoQuestaoTO.codsItensRemovidos.push(codItem);
			}

			$("#componenteItem" + numItem).remove();

			await this.atualizarEdicaoQuestao();
		}

		$("#componenteItem" + numItem).slideUp(400, async function() {
			await excluir(this);
		});
	}

	async removerClassificacao(botao) {

		if (!await this.confirmar(this.getMsg("FP_FRONT_CadastroQuestaoVH_107"))) {
			return false;
		}

		const $botao = $(botao);
		const $questao = $botao.closest("[questao]");
		let codQuestao = $questao.attr("cod-questao");
		let codDisciplina = $botao.attr("id-disciplina");

		await this.call("CadastroQuestaoFCD/removerClassificacaoQuestao", codDisciplina, codQuestao);
		$questao.trigger("atualizar");
	}

	async atualizarMidias(exibicaoQuestaoTO) {
		this.setHtml("edicao_midias", "")
		this.setIdTarget("edicao_midias")
		await this.exibirMidias(exibicaoQuestaoTO)
		this.setIdTarget(null);
		this.exibir();
	}

	async exibirMidias(exibicaoQuestaoTO) {

		if (this.hasValue(exibicaoQuestaoTO.collectionExibicaoMidiaTO)) {

			for (const exibicaoMidiaTO of exibicaoQuestaoTO.collectionExibicaoMidiaTO) {

				this.append(`
					<div exibicao-midia cod-midia="${exibicaoMidiaTO.codMidia}" cod-questao="${exibicaoQuestaoTO.codQuestao}" class='col-xs-12 col-sm-12 col-md-6 col-lg-4' style="margin-top: 20px">
				`);

				if (UtilArquivo.isImagem(exibicaoMidiaTO.pathArmazenamento)) {
					this.append(`
						<img class='img-thumbnail img-thumbnail-resizable' src='${exibicaoMidiaTO.pathArmazenamento}' alt="">
					`);
				} else if (UtilArquivo.isPlanilha(exibicaoMidiaTO.pathArmazenamento)) {
					this.append(`
						<i class="fa fa-file-excel-o fa-5x img-thumbnail-resizable" aria-hidden="true"></i>
					`);
				} else if (UtilArquivo.isAudio(exibicaoMidiaTO.pathArmazenamento)) {
					this.exibirAudioQuestao({ path: exibicaoMidiaTO.pathArmazenamento });
					await this.show("componente_isDownloadMidia");
				} else if (UtilArquivo.isVideo(exibicaoMidiaTO.pathArmazenamento)) {
					this.exibirVideoQuestao({ path: exibicaoMidiaTO.pathArmazenamento });
					await this.show("componente_isDownloadMidia");
				}

				if (exibicaoMidiaTO.legenda) {
					this.append(`<small>${exibicaoMidiaTO.legenda}</small>`);
				}

				this.addBotao({
					classe: "exibicao-midia-btn-exclusao btn-danger btn-sm pull-right",
					texto: "<i class='fa fa-trash-o'></i>",
					dica: this.getMsg("FP_FRONT_CadastroQuestaoVH_233"),
					onClick: (event) => {
						const $exibicaoMidia = $(event.target).closest("[exibicao-midia]");
						this.excluirMidia($exibicaoMidia.attr("cod-midia"), $exibicaoMidia.attr("cod-questao"));
					}
				});

				this.append("</div>");
			}
		}
	}

	async excluirMidia(codMidia, codQuestao) {
		const exibicaoQuestaoTO = await this.call("CadastroQuestaoFCD/excluirMidia", codMidia, codQuestao);
		await this.atualizarMidias(exibicaoQuestaoTO);
	}

	async removerAnulacao(codQuestao, codProva?, codQuestaoAnulacao?) {
		if (!this.isAdministrador() && !UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_ANULACAO)) {
			this.logger.warn("Não deveria chegar aqui. Abortando silenciosamente.")
			return;
		}

		UtilHash.registrarHistorico(this.removerAnulacao, codQuestao, codProva, codQuestaoAnulacao);

		await this.montarTelaAnulacao(codQuestao, null, true, codProva, codQuestaoAnulacao)
	}

	async anular(codQuestao: number, cfgsFluxoTelaAnulacao?: CfgsFluxoTela,  codProva?: number) {
		if (!this.isAdministrador() && !UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_ANULACAO)) {
			this.logger.warn("Não deveria chegar aqui. Abortando silenciosamente.")
			return;
		}

		if (!cfgsFluxoTelaAnulacao) UtilHash.registrarHistorico(this.anular, codQuestao);

		await this.montarTelaAnulacao(codQuestao, cfgsFluxoTelaAnulacao, false, codProva);
	}

	async montarTelaReversaoAnulacao(codQuestao, codQuestaoAnulacao) {
		this.limpar();

		this.setTitulo(`${this.getMsg("FP_FRONT_CadastroQuestaoVH_310", codQuestaoAnulacao)}`);
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_108", codQuestao));

		const questaoAnulacaoTO = await this.call("CadastroQuestaoFCD/recuperarDadosAnulacaoQuestao", codQuestaoAnulacao);

		this.addCampoExibicao({label: this.getMsg("FP_FRONT_CadastroQuestaoVH_311"), valor: questaoAnulacaoTO.usuarioAnulacao});
		this.addCampoExibicao({label: this.getMsg("FP_FRONT_CadastroQuestaoVH_312"), valor: UtilData.toDDMMYYYYHHMM(questaoAnulacaoTO.dataAnulacao)});

		this.addCampoExibicao({label: this.getMsg("FP_FRONT_CadastroQuestaoVH_313"), valor: {
				SO_CADASTRO: this.getMsg('FP_FRONT_CadastroQuestaoVH_305'),
				SO_RESPOSTAS: this.getMsg('FP_FRONT_CadastroQuestaoVH_306'),
				CADASTRO_E_RESPOSTAS: this.getMsg('FP_FRONT_CadastroQuestaoVH_307'),
			}[questaoAnulacaoTO.tipoAnulacao]});

		this.addCampoExibicao({label: this.getMsg("FP_FRONT_CadastroQuestaoVH_314"), valor: questaoAnulacaoTO.motivo});

		const colunas: ColunaAddTabela[] = [
			{titulo: "", prop: (rq) => `<small>#${rq.codRespostaQuestao}</small>`},
			{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_315"), prop: 'tituloProva'},
			{titulo: this.getCfg("LABEL_ALUNO"), prop: 'nomeAluno'}
		];

		await this.addTabela({
			titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_316"),
			id: "respostas-alunadas",
			colunas,
			collection: questaoAnulacaoTO.collectionRespostaQuestaoAnulacaoTO
		})

		if (!questaoAnulacaoTO.isRevertida) {
			this.addBotao({
				label: `<i class="fa fa-undo""></i> ${this.getMsg('FP_FRONT_CadastroQuestaoVH_249')}`,
				classe: "btn-warning pull-right",
				onClick: async () => {

					await this.call("CadastroQuestaoFCD/reverterQuestaoAnulacao", codQuestaoAnulacao);

					try {
						await this.exibirAbasQuestao(4, codQuestao);
					} catch (e) {
						this.logger.error(e);
					}
				}
			});
		}

		this.exibir({ isDeveFazerScrollParaTitulo: true });
	}

	async montarTelaAnulacao(codQuestao, cfgsFluxoTelaAnulacao, isRemocaoAnulacao, codProva?, codQuestaoAnulacao?) {

		const exibicaoImpactoAnulacaoQuestaoTO = await this.call("CadastroQuestaoFCD/verificarAnulacao", codQuestao, cfgsFluxoTelaAnulacao?.isFluxoRecursos, isRemocaoAnulacao, codProva);

		const possuiRespostas = isRemocaoAnulacao ? exibicaoImpactoAnulacaoQuestaoTO.numRespostasJaAnuladas > 0 : exibicaoImpactoAnulacaoQuestaoTO.numRespostas > 0;
		const possuiCadernos = isRemocaoAnulacao ? exibicaoImpactoAnulacaoQuestaoTO.numCadernosAnulados > 0 : exibicaoImpactoAnulacaoQuestaoTO.numCadernos > 0;
		const possuiProvas = exibicaoImpactoAnulacaoQuestaoTO.listagemProvasQuestao?.length;
		const exibicaoQuestaoTO = exibicaoImpactoAnulacaoQuestaoTO.exibicaoQuestaoTO;

		if (!isRemocaoAnulacao && !possuiRespostas && !exibicaoQuestaoTO.podeAlterarQuestao) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_229") });
			UtilHash.voltar();
			return;
		}

		if (!isRemocaoAnulacao && !possuiRespostas && !exibicaoImpactoAnulacaoQuestaoTO.iraAnularQuestao) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_109") });
		}

		this.limpar();

		if (cfgsFluxoTelaAnulacao) {
			this.setTitulo(cfgsFluxoTelaAnulacao.titulo);
			this.setSubtitulo(cfgsFluxoTelaAnulacao.subtitulo);

		} else {
			this.setTitulo(isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_244") : this.getMsg("FP_FRONT_CadastroQuestaoVH_110"));
			this.setSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_108", codQuestao));
		}

		this.addSubtitulo(isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_243") :this.getMsg("FP_FRONT_CadastroQuestaoVH_111"));
		exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO);

		this.addSubtitulo(isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_242") : this.getMsg("FP_FRONT_CadastroQuestaoVH_112"));

		if (isRemocaoAnulacao) {
			await this.montarTelaReversaoAnulacao(codQuestao, codQuestaoAnulacao);
			return;
		}

		if (!isRemocaoAnulacao) {
			this.addEditorHTML({
				id: "motivoAnulacao",
				label: this.getMsg("FP_FRONT_GestaoPFVH_030"),
				classe: "col-md-12",
				obrigatorio: true
			});
		}

		if ((isRemocaoAnulacao && possuiProvas) || possuiRespostas || possuiCadernos) {

			if (!isRemocaoAnulacao) {
				
				const labelRespostas = this.getMsg("FP_FRONT_CadastroQuestaoVH_216", exibicaoImpactoAnulacaoQuestaoTO.numRespostas);
				const labelCadernos = this.getMsg("FP_FRONT_CadastroQuestaoVH_217", exibicaoImpactoAnulacaoQuestaoTO.numCadernos);
				let label = "";

				if (possuiRespostas && possuiCadernos) {
					label = this.getMsg("FP_FRONT_CadastroQuestaoVH_121", ", " + labelRespostas + this.getMsg("FP_FRONT_CadastroQuestaoVH_215") + labelCadernos);

				} else if (possuiRespostas) {
					label = this.getMsg("FP_FRONT_CadastroQuestaoVH_121", this.getMsg("FP_FRONT_CadastroQuestaoVH_215") + labelRespostas);

				} else if (possuiCadernos) {
					label = this.getMsg("FP_FRONT_CadastroQuestaoVH_121", this.getMsg("FP_FRONT_CadastroQuestaoVH_215") + labelCadernos);
				}

				let collectionTipoAnulacao = [];
				let valorTipoAnulacao = TipoAnulacao.SO_RESPOSTAS;

				if (exibicaoQuestaoTO.podeAlterarQuestao) {
					collectionTipoAnulacao.push({
						id: TipoAnulacao.SO_CADASTRO,
						label: this.getMsg("FP_FRONT_CadastroQuestaoVH_114"),
						dica: this.getMsg("FP_FRONT_CadastroQuestaoVH_115")
					}, {
						id: TipoAnulacao.CADASTRO_E_RESPOSTAS,
						label: label,
						dica: this.getMsg("FP_FRONT_CadastroQuestaoVH_116")
					});
					valorTipoAnulacao = TipoAnulacao.CADASTRO_E_RESPOSTAS;
				}

				collectionTipoAnulacao.push({
					id: TipoAnulacao.SO_RESPOSTAS,
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_226", exibicaoImpactoAnulacaoQuestaoTO.numRespostas),
					dica:  this.getMsg("FP_FRONT_CadastroQuestaoVH_116")
				});

				this.addRadioGroup({
					id: "tipoAnulacao",
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_113"),
					collection: collectionTipoAnulacao,
					classe: "col-md-6",
					valor: valorTipoAnulacao,
					obrigatorio: true,
					onChange: async () => {
						if ([TipoAnulacao.SO_RESPOSTAS, TipoAnulacao.CADASTRO_E_RESPOSTAS].includes(this.getValor("tipoAnulacao"))) {
							await this.show("tipoAlteracaoPontuacao", "isDesejaSelecionarPFs");
						} else {
							this.hide("tipoAlteracaoPontuacao", "isDesejaSelecionarPFs", "detalhamento-anulacao");
							this.setValor("isDesejaSelecionarPFs", false);
						}
					}
				});

				this.addRadioGroup({
					id: "tipoAlteracaoPontuacao",
					collection: [
						{ id: "CONCEDER", label: this.getMsg("FP_FRONT_CadastroQuestaoVH_117"), dica: this.getMsg("FP_FRONT_CadastroQuestaoVH_118") },
						{ id: "ZERAR", label: this.getMsg("FP_FRONT_CadastroQuestaoVH_120"), dica: this.getMsg("FP_FRONT_CadastroQuestaoVH_119") },
					],
					classe: "col-md-6",
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_122"),
					valor: "CONCEDER",
					obrigatorio: true,
					habilitado: true,
				});

				this.addCheckbox({
					id: "isDesejaSelecionarPFs",
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_123"),
					classe: "col-md-6",
					onChange: async () => {
						if (this.getValor("isDesejaSelecionarPFs")) {
							await this.show("detalhamento-anulacao");
						} else {
							await this.hide("detalhamento-anulacao");
						}
					}
				});

				this.fecharFormulario();

				this.addEspacamentoHorizontal();
			}

			await this.addListagemProvasQuestao(exibicaoImpactoAnulacaoQuestaoTO, isRemocaoAnulacao);

			await this.addListagemRespostasQuestao(possuiRespostas, isRemocaoAnulacao, exibicaoImpactoAnulacaoQuestaoTO);

		} else {
			this.append(`<div class='alert'>${isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_256") : this.getMsg("FP_FRONT_CadastroQuestaoVH_131")}</div>`);
		}

		if (!isRemocaoAnulacao) {
			const podeAnularRespostasQuestao = !exibicaoQuestaoTO.podeAlterarQuestao && (this.isAdministrador() || UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_ANULACAO));
			const msgBotaoFinalizacaoRespostas = this.getMsg("FP_FRONT_CadastroQuestaoVH_227")
			const msgBotaoFinalizacaoQuestao = this.getMsg("FP_FRONT_CadastroQuestaoVH_132")
			let labelBotaoFinalizacao = `
				${isRemocaoAnulacao ? '<i class="fa fa-undo""></i>': '<i class="fa fa-legal""></i>'}
				${podeAnularRespostasQuestao? msgBotaoFinalizacaoRespostas: msgBotaoFinalizacaoQuestao}
			`;
			let classeBotaoFinalizacao = isRemocaoAnulacao ? "btn-warning pull-right" : "btn-danger pull-right";
			let onClickBotaoFinalizacao: Function = async (anulacaoQuestaoTO: AnulacaoQuestaoTO) => {

				let isDesejaSelecionarPFs = this.getValor("isDesejaSelecionarPFs");
				let msgPosAnulacao;

				if (TipoAnulacao.SO_CADASTRO ==  anulacaoQuestaoTO.tipoAnulacao) {
					msgPosAnulacao = isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_251"): this.getMsg("FP_FRONT_CadastroQuestaoVH_133");
				} else if(TipoAnulacao.SO_RESPOSTAS ==  anulacaoQuestaoTO.tipoAnulacao) {
					msgPosAnulacao = isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_252", anulacaoQuestaoTO.idsProvasFeitas.length) : this.getMsg("FP_FRONT_CadastroQuestaoVH_228", anulacaoQuestaoTO.idsProvasFeitas.length);
				} else if (isDesejaSelecionarPFs) {
					if (this.isEmpty(anulacaoQuestaoTO.idsProvasFeitas)) {
						msgPosAnulacao = isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_257") : this.getMsg("FP_FRONT_CadastroQuestaoVH_134");
					} else {
						msgPosAnulacao = isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_250", anulacaoQuestaoTO.idsProvasFeitas.length) : this.getMsg("FP_FRONT_CadastroQuestaoVH_135", anulacaoQuestaoTO.idsProvasFeitas.length);
					}

				} else {
					msgPosAnulacao = isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_250", anulacaoQuestaoTO.idsProvasFeitas.length) :  this.getMsg("FP_FRONT_CadastroQuestaoVH_136", anulacaoQuestaoTO.idsProvasFeitas.length);
				}

				const url = isRemocaoAnulacao ? 'CadastroQuestaoFCD/removerAnulacao' : 'CadastroQuestaoFCD/anularERecalcularPontuacao';
				await this.call(url, anulacaoQuestaoTO);

				await this.exibirAlerta({ msg: msgPosAnulacao });

				try {
					await this.exibirAbasQuestao(1, codQuestao);
				} catch (e) {
					this.logger.error(e);
				}
			}

			if (cfgsFluxoTelaAnulacao) {
				labelBotaoFinalizacao = cfgsFluxoTelaAnulacao.labelBotaoFinalizacao;
				classeBotaoFinalizacao = cfgsFluxoTelaAnulacao.classeBotaoFinalizacao;
				onClickBotaoFinalizacao = cfgsFluxoTelaAnulacao.onClickBotaoFinalizacao;
			}

			this.addBotao({
				label: labelBotaoFinalizacao,
				classe: classeBotaoFinalizacao,
				onClick: () => {
					let tipoAnulacao = this.getValor("tipoAnulacao");
					const motivoAnulacao = this.getValor("motivoAnulacao")

					if (!possuiRespostas && !possuiCadernos && !tipoAnulacao) {
						tipoAnulacao = TipoAnulacao.SO_CADASTRO;
					}

					if (!this.validarCamposObrigatorios("motivoAnulacao")) {
						return;
					}

					const anulacaoQuestaoTO = {
						codQuestao: codQuestao,
						tipoAnulacao: tipoAnulacao,
						motivoAnulacao: motivoAnulacao,
						isZerarPontuacao: !possuiRespostas || this.getValor("tipoAlteracaoPontuacao") == "ZERAR",
						idsProvasFeitas: [],
					}

					if (anulacaoQuestaoTO.tipoAnulacao !== TipoAnulacao.SO_CADASTRO) {
						$("[id^='idsProvasFeitas']").each((i: number, e: Element) => {
							const ids = UtilNumero.from(amaisVH.getValor(e.id)) || [];
							anulacaoQuestaoTO.idsProvasFeitas = anulacaoQuestaoTO.idsProvasFeitas.concat(ids);
						});
					}

					const msgAnulacao = this.getMsgAnulacao(anulacaoQuestaoTO);
					this.exibirAlerta({
						titulo: `<i class="fas fa-exclamation-triangle"></i> ${this.getMsg('MSG_VH_A_15')}`,
						msg: `
							<p>
								${this.getMsg("FP_FRONT_CadastroQuestaoVH_273")} ${msgAnulacao}.
							</p>
							<p>
								${this.getMsg("FP_FRONT_CadastroQuestaoVH_274")}
							</p>
						`,
						botoes: [
							{
								label: this.getMsg("FP_FRONT_CadastroQuestaoVH_276")
							},
							{
								label: `${this.getMsg("FP_FRONT_CadastroQuestaoVH_275")}`,
								classe: "btn-danger",
								onClick: () => {
									onClickBotaoFinalizacao(anulacaoQuestaoTO);
								}
							}
						]
					});
				}
			});
		} else {
			this.addBotao({
				label: `<i class="fa fa-undo""></i> ${this.getMsg('FP_FRONT_CadastroQuestaoVH_249')}`,
				classe: "btn-warning pull-right",
				onClick: async () => {

					const anulacaoQuestaoTO = {
						codQuestao: codQuestao,
						reverterAnulacaoCadastroQuestao: this.getValor('isReverterAnulacaoCadastroQuestao'),
						reassociarQuestaoProva: this.getValor('isReassociarQuestaoProva'),
						reverterAnulacaoRespostas: this.getValor('isReverterAnulacaoRespostas'),
						idsProvasFeitas: [],
						idsProvas: [],
					}

					if (!anulacaoQuestaoTO.reverterAnulacaoCadastroQuestao && !anulacaoQuestaoTO.reverterAnulacaoRespostas) {
						this.exibirAlerta({ msg: this.getMsg('FP_FRONT_CadastroQuestaoVH_290') });
						return;
					}

					if (!this.getValor('isDesejaSelecionarProvasReassociacao')) {
						document.querySelectorAll("table#listagemProvasQuestao input[type='checkbox']").forEach((c: HTMLInputElement) => c.checked = true);
					}

					if (!this.getValor('isDesejaSelecionarRespostasReversaoAnulacao')) {
						document.querySelectorAll("table.listagemRespostas input[type='checkbox']").forEach((c: HTMLInputElement) => c.checked = true);
					}

					if (anulacaoQuestaoTO.reverterAnulacaoRespostas) {
						$("[id^='idsProvasFeitas']").each((i: number, e: Element) => {
							const ids = UtilNumero.from(amaisVH.getValor(e.id)) || [];
							anulacaoQuestaoTO.idsProvasFeitas = anulacaoQuestaoTO.idsProvasFeitas.concat(ids);
						});
					}

					if (anulacaoQuestaoTO.reassociarQuestaoProva) {
						$("[id^='listagemProvasQuestao']").each((i: number, e: Element) => {
							const ids = UtilNumero.from(amaisVH.getValor(e.id)) || [];
							anulacaoQuestaoTO.idsProvas = anulacaoQuestaoTO.idsProvas.concat(ids);
						});
					}

					await this.call('CadastroQuestaoFCD/removerAnulacao', anulacaoQuestaoTO);

					const msg = anulacaoQuestaoTO.reverterAnulacaoCadastroQuestao 
						? this.getMsg('FP_FRONT_CadastroQuestaoVH_251') 
						: this.getMsg('FP_FRONT_CadastroQuestaoVH_252', anulacaoQuestaoTO.idsProvasFeitas.length)
					
					await this.exibirAlerta({ msg: msg });

					try {
						await this.exibirAbasQuestao(1, codQuestao);
					} catch (e) {
						this.logger.error(e);
					}
				}
			});
		}

		this.exibir({ isDeveFazerScrollParaTitulo: true });

		// seleciona todas as PFs
		$("table[id^='idsProvasFeitas'] input[type='checkbox']").each(function() {
			$(this).prop('checked', true).on("change");
		})
	}

	async addListagemProvasQuestao(exibicaoImpactoAnulacaoQuestaoTO, isRemocaoAnulacao) {
		if (isRemocaoAnulacao) {
			this.append("<div id='listagem-provas-questao' style='display:none; margin-bottom: 20px'>");

			this.append(`<h3>${this.getMsg("FP_FRONT_CadastroQuestaoVH_286")}</h3>`);

			this.append(this.getMsg("FP_FRONT_CadastroQuestaoVH_287"))

			const alterarTodosCheckboxes = (checked) => {
				document.querySelectorAll("table#listagemProvasQuestao input[type='checkbox']").forEach((c: HTMLInputElement) => c.checked = checked);
			}

			this.addGrupoBotoes({
				classe: "pull-right", 
				botoes: [{ 
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_125"), 
					onClick: () => alterarTodosCheckboxes(false) 
				}, { 
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_126"), 
					onClick: () => alterarTodosCheckboxes(true) 
				}]
			});

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: "", prop: (item) => {
					return `<small>#${item.codProva}</small>`
				}
			});
			colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_315"), prop: (item) => {
					let hashEdicaoProva = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 1, item.codProva);
					return `
							<a href='${hashEdicaoProva}' target="_blank">${item.tituloProva}</a>
						`
				}
			});

			await this.addTabela({
				collection: exibicaoImpactoAnulacaoQuestaoTO.listagemProvasQuestao,
				id: "listagemProvasQuestao",
				propId: "codProva",
				colunas: colunas,
				selecao: true,
				ordenar: false
			});

			setTimeout(() => alterarTodosCheckboxes(true), 300);

			this.append("</div>");
		}
	}

	async addListagemRespostasQuestao(possuiRespostas, isRemocaoAnulacao, exibicaoImpactoAnulacaoQuestaoTO) {
		this.append("<div id='detalhamento-anulacao' style='display:none'>");

		if (possuiRespostas) {

			if (isRemocaoAnulacao) {
				this.append(`<h3>${this.getMsg("FP_FRONT_CadastroQuestaoVH_288")}</h3>`)
			}

			this.append(isRemocaoAnulacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_258") : this.getMsg("FP_FRONT_CadastroQuestaoVH_124"));

			const alterarTodosCheckboxes = (checked) => {
				document.querySelectorAll("table.listagemRespostas input[type='checkbox']").forEach((c: HTMLInputElement) => c.checked = checked);
			}

			this.addBotao({ label: this.getMsg("FP_FRONT_CadastroQuestaoVH_125"), classe: "pull-right", onClick: () => alterarTodosCheckboxes(false) });
			this.addBotao({ label: this.getMsg("FP_FRONT_CadastroQuestaoVH_126"), classe: "pull-right", onClick: () => alterarTodosCheckboxes(true) });

			let i = 0;

			for (let aplicacao in exibicaoImpactoAnulacaoQuestaoTO.mapaProvaFeitaImpactadaPorAnulacaoTOPorAplicacao) {
				let collection = exibicaoImpactoAnulacaoQuestaoTO.mapaProvaFeitaImpactadaPorAnulacaoTOPorAplicacao[aplicacao];

				if (!collection || collection.length <= 0) continue;

				if (aplicacao == "CADERNOS") {

					const colunasListagemCadernos: ColunaAddTabela[] = [
						{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_219"), prop: "nomeDaProvaDoCaderno"},
						{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_220"), prop: "nomeCaderno"},
						{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_221"), prop: "#${codProvaFeita}"},
					];

					await this.addTabela({
						collection: collection,
						titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_218"),
						id: "idsProvasFeitas" + (i++),
						classe: "listagemRespostas",
						propId: "codProvaFeita",
						colunas: colunasListagemCadernos,
						selecao: true
					});
				} else {

					const colunasProvaFeita: ColunaAddTabela[] = [];
					colunasProvaFeita.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_127"), prop: (provaFeitaImpactadaPorAnulacaoTO) => {
							const hash = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, provaFeitaImpactadaPorAnulacaoTO.codProvaFeita);
							const labelAnulada = `<br><span class="label label-danger">${this.getMsg("FP_FRONT_CadastroQuestaoVH_137")}</span>`;
							const labelRemovida = `<br><span class="label label-default">${this.getMsg("FP_FRONT_CadastroQuestaoVH_138")}</span>`;

							return `
										<a href='${hash}' target='_blank'>
											${provaFeitaImpactadaPorAnulacaoTO.nomeRespondente}
										</a>
										${provaFeitaImpactadaPorAnulacaoTO.isAnulada ? labelAnulada : ''}
										${provaFeitaImpactadaPorAnulacaoTO.isArquivada ? labelRemovida : ''}
									`;
						}
					});
					colunasProvaFeita.push({titulo: this.getMsg("FP_FRONT_CadastroAOVH_089", this.getCfg("LABEL_TURMA")), prop: this.montarColunaTurma});
					colunasProvaFeita.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_128"), prop: this.montarColunaData});

					if (!isRemocaoAnulacao) {
						colunasProvaFeita.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_129"), prop: (provasFeitasAlteraveisPorAnulacaoTO) => {
								let h = [];
								if (provasFeitasAlteraveisPorAnulacaoTO.pontuacaoObtida) {
									h.push(this.getMsg("FP_FRONT_CadastroQuestaoVH_142", UtilNumero.floatToString(provasFeitasAlteraveisPorAnulacaoTO.pontuacaoObtida), UtilNumero.floatToString(provasFeitasAlteraveisPorAnulacaoTO.pontuacaoMaxima)));
								}
								h.push(this.getMsg("FP_FRONT_CadastroQuestaoVH_143", UtilNumero.floatToString(provasFeitasAlteraveisPorAnulacaoTO.pontuacaoObtidaNaQuestaoSeAnular)));
								return h.join("<br>");
							}
						});
					}

					await this.addTabela({
						collection: collection,
						classe: "listagemRespostas",
						titulo: aplicacao,
						id: "idsProvasFeitas" + (i++),
						propId: "codProvaFeita",
						colunas: colunasProvaFeita,
						selecao: true
					});
				}

			}

			if (!exibicaoImpactoAnulacaoQuestaoTO.iraAnularQuestao) {
				this.append(`<div class='alert'>${this.getMsg("FP_FRONT_CadastroQuestaoVH_130")}</div>`);
			}
		}

		this.append("</div>");
	}

	async montarTelaRemocaoAnulacao(possuiRespostas?: boolean) {
		const checkboxReverterAnulacaoQuestao = this.addCheckbox({
			id: "isReverterAnulacaoCadastroQuestao",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_278"),
			classe: "col-md-12",
			onChange: async () => {
				const reverterAnulacaoCadastroQuestao = this.getValor('isReverterAnulacaoCadastroQuestao');
				if (!reverterAnulacaoCadastroQuestao) {
					this.setValor('isReassociarQuestaoProva', false);
					this.disable('isReassociarQuestaoProva');
					this.setValor('isDesejaSelecionarProvasReassociacao', false);
					this.disable('isDesejaSelecionarProvasReassociacao');
					await this.show('msg-reassociacao-questao');
					await this.hide("listagem-provas-questao");
					$('#card-provas').addClass('card-disabled')
				} else {
					await this.hide('msg-reassociacao-questao');
					this.enable('isReassociarQuestaoProva');
					$('#card-provas').removeClass('card-disabled')
				}
			},
			ignorarFormulario: true,
			retornarHtml: true
		});

		const checkboxReassociarQuestao = this.addCheckbox({
			id: "isReassociarQuestaoProva",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_279"),
			classe: "col-md-12",
			onChange: async () => {
				const reassociarQuestaoProva = this.getValor('isReassociarQuestaoProva');
				if (!reassociarQuestaoProva) {
					this.setValor('isDesejaSelecionarProvasReassociacao', false);
					this.disable('isDesejaSelecionarProvasReassociacao');
					await this.hide("listagem-provas-questao");
				} else {
					this.enable('isDesejaSelecionarProvasReassociacao');
				}
			},
			habilitado: false,
			ignorarFormulario: true,
			retornarHtml: true
		});

		const checkboxSelecaoProvas = this.addCheckbox({
			id: "isDesejaSelecionarProvasReassociacao",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_277"),
			classe: "col-md-12",
			onChange: async () => {
				if (this.getValor("isDesejaSelecionarProvasReassociacao")) {
					await this.show("listagem-provas-questao");
				} else {
					await this.hide("listagem-provas-questao");
				}
			},
			habilitado: false,
			ignorarFormulario: true,
			retornarHtml: true
		});

		const checkboxReverterResposta = this.addCheckbox({
			id: "isReverterAnulacaoRespostas",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_280"),
			classe: "col-md-12",
			onChange: async () => {
				const reverterAnulacaoRespostas = this.getValor('isReverterAnulacaoRespostas');
				if (!reverterAnulacaoRespostas) {
					this.setValor('isDesejaSelecionarRespostasReversaoAnulacao', false);
					this.disable('isDesejaSelecionarRespostasReversaoAnulacao');
					await this.hide("detalhamento-anulacao");
				} else {
					this.enable('isDesejaSelecionarRespostasReversaoAnulacao');
				}
			},
			habilitado: possuiRespostas,
			ignorarFormulario: true,
			retornarHtml: true,
		});

		const checkboxSelecaoRespostas = this.addCheckbox({
			id: "isDesejaSelecionarRespostasReversaoAnulacao",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_281"),
			classe: "col-md-12",
			onChange: async () => {
				if (this.getValor("isDesejaSelecionarRespostasReversaoAnulacao")) {
					await this.show("detalhamento-anulacao");
				} else {
					await this.hide("detalhamento-anulacao");
				}
			},
			habilitado: false,
			ignorarFormulario: true,
			retornarHtml: true
		});

		this.append(`
				<div class="row">
					<div class="col-md-12">
						<div class="fp-simple-card-container">
							<div class="fp-simple-card">
								<span class="fp-simple-card__label">
									${this.getMsg("FP_FRONT_CadastroQuestaoVH_282")}
								</span>
								
								<div class="fp-simple-card__content">
									${checkboxReverterAnulacaoQuestao}
								</div>
							</div>
							
							<div id="card-provas" class="fp-simple-card card-disabled">
								<span class="fp-simple-card__label">
									${this.getMsg("FP_FRONT_CadastroQuestaoVH_283")}
								</span>
								
								<div class="fp-simple-card__content">
									${checkboxReassociarQuestao}
									
									${checkboxSelecaoProvas}
									
									<small id="msg-reassociacao-questao">
										${this.getMsg("FP_FRONT_CadastroQuestaoVH_284")}
									</small>
								</div>
							</div>
							
							<div class="fp-simple-card ${possuiRespostas ? '' : 'card-disabled'}">
								<span class="fp-simple-card__label">
									${this.getMsg("FP_FRONT_CadastroQuestaoVH_285")}
								</span>
								
								<div class="fp-simple-card__content">
									${checkboxReverterResposta}
									
									${checkboxSelecaoRespostas}
								</div>
								
								${possuiRespostas ? '' : 
									`<small>${this.getMsg('FP_FRONT_CadastroQuestaoVH_289')}</small>`
								}	
							</div>
						</div>
					</div>
				</div>
			`);

		this.exibir();
	}

	getMsgAnulacao(anulacaoQuestaoTO) {
		let msgPontuacao = '';
		if (anulacaoQuestaoTO.tipoAnulacao == TipoAnulacao.SO_CADASTRO) {
			return this.getMsg("FP_FRONT_CadastroQuestaoVH_268")
		}
		msgPontuacao = anulacaoQuestaoTO.isZerarPontuacao ? this.getMsg("FP_FRONT_CadastroQuestaoVH_269") : this.getMsg("FP_FRONT_CadastroQuestaoVH_270");
		if (anulacaoQuestaoTO.tipoAnulacao == TipoAnulacao.SO_RESPOSTAS) {
			return this.getMsg("FP_FRONT_CadastroQuestaoVH_271", anulacaoQuestaoTO.idsProvasFeitas.length, msgPontuacao)
		}
		if (anulacaoQuestaoTO.tipoAnulacao == TipoAnulacao.CADASTRO_E_RESPOSTAS) {
			return this.getMsg("FP_FRONT_CadastroQuestaoVH_272", anulacaoQuestaoTO.idsProvasFeitas.length, msgPontuacao)
		}
	}

	montarColunaData(to) {
		if (to.dataFimProvaFeita) {
			return this.getMsg("FP_FRONT_CadastroQuestaoVH_139", UtilData.toDDMMYYYYHHMM(to.dataFimProvaFeita));

		} else if (this.hasValue(to.pontuacaoObtida)) {
			return this.getMsg("FP_FRONT_CadastroQuestaoVH_294");

		} else if (to.dataInicioProvaFeita) {
			return this.getMsg("FP_FRONT_CadastroQuestaoVH_140", UtilData.toDDMMYYYYHHMM(to.dataInicioProvaFeita));

		} else {
			return this.getMsg("FP_FRONT_CadastroQuestaoVH_141");
		}
	}

	montarColunaTurma(to) {
		return to.turmaNaAplicacao || `<small>${this.getMsg("FP_FRONT_CadastroAOVH_090")}</small>`;
	}

	async exibirVisualizacao(codQuestao) {
		const exibicaoQuestaoTO = await this.call("CadastroQuestaoFCD/recuperarQuestaoParaVisualizacao", codQuestao);
		exibicaoQuestaoVH.limparControleTextos();
		this.limpar(true);

		exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO);

		this.exibir();
		$("[questao] [disciplinas]").show();
	}

	async mostrarUso(codQuestao: number, isExibirEmPopup: boolean = false) {

		const historicoUsoQuestaoTO = await this.call("CadastroQuestaoFCD/recuperarHistoricoUsoQuestao", codQuestao);

		if (isExibirEmPopup) {
			this.addPopup({
				titulo: this.getMsg("FP_FRONT_ExibicaoQuestaoVH_023"),
				width: "80%",
				botoes: [{
					label: this.getMsg("FP_FRONT_ExibicaoQuestaoVH_010")
				}]
			});

		} else {
			this.limpar(true);
		}

		const hashAnulacao = UtilHash.getHash(cadastroQuestaoVH.anular) + "/";
		const hashRemoverAnulacao = UtilHash.getHash(cadastroQuestaoVH.removerAnulacao) + "/";

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_150"), prop:  (historicoUsoQuestaoProvaTO) => {
				let h = [];
				let hashProva = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 2, historicoUsoQuestaoProvaTO.codProva, codQuestao);
				h.push("<a href='" + hashProva + "'>" + historicoUsoQuestaoProvaTO.titulo + "</a>");

				if (historicoUsoQuestaoProvaTO.dataAssociacao) {
					h.push(this.getMsg("FP_FRONT_CadastroQuestaoVH_157", UtilData.toDDMMYYYYHHMM(historicoUsoQuestaoProvaTO.dataAssociacao), historicoUsoQuestaoProvaTO.nomeUsuarioAssociacao));
				}

				if (historicoUsoQuestaoProvaTO.dataRemocao) {
					h.push(this.getMsg("FP_FRONT_CadastroQuestaoVH_158", UtilData.toDDMMYYYYHHMM(historicoUsoQuestaoProvaTO.dataRemocao), historicoUsoQuestaoProvaTO.nomeUsuarioRemocao));
				}
				return h.join("<br>");
			}, classe: "descricao"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_159"), prop:  (historicoUsoQuestaoProvaTO) => {
				if (historicoUsoQuestaoProvaTO.isArquivada) {
					return `<span class='label label-default'>${this.getMsg("FP_FRONT_CadastroQuestaoVH_163")}</span>`;
				} else if (historicoUsoQuestaoProvaTO.numRespostasAlunos || historicoUsoQuestaoProvaTO.numCadernos) {
					return this.getMsg("FP_FRONT_CadastroQuestaoVH_162");
				} else {
					return historicoUsoQuestaoTO.tiposStatusProva[historicoUsoQuestaoProvaTO.status];
				}
			}
		});

		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_151"), prop: "numQuestao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_152"), prop: "numQuestao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_153"), prop: "numRespostasAlunos"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_259"), prop: "numRespostasAnuladas"});
		colunas.push({titulo: `<i class="fa fa-area-chart"></i> TRI`, prop:  (historicoUsoQuestaoProvaTO) => {
				const barra = calculoTRIVH.exibirBarraDificuldade(
					historicoUsoQuestaoTO.listaListagemDificuldadeTO,
					historicoUsoQuestaoProvaTO.dificuldadeInferida
				);

				if (this.isEmpty(barra)) return "";

				if (this.isEmpty(historicoUsoQuestaoProvaTO.turmasTRI)) {
					return barra;
				}

				return barra + `
						<small>
							${this.getCfg("LABEL_TURMA")}&nbsp;avaliada:
							<br>
							${historicoUsoQuestaoProvaTO.turmasTRI.join("<br>")}
						</small>
					`;
			}
		});
		colunas.push({titulo: "", prop: (historicoUsoQuestaoProvaTO) => {
				return `
						${(historicoUsoQuestaoProvaTO.numRespostasAlunos > historicoUsoQuestaoProvaTO.numRespostasAnuladas || historicoUsoQuestaoProvaTO.numCadernos > historicoUsoQuestaoProvaTO.numRespostasAnuladas) ? `
						<a class='btn btn-danger btn-table'
						   href='${hashAnulacao}${codQuestao}//${historicoUsoQuestaoProvaTO.codProva}'
						   title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_009")}'>
							<i class='fa fa-ban'></i>
						</a>` : ''}
					`;
			}
		});

		await this.addTabela({
			collection: historicoUsoQuestaoTO.listaHistoricoUsoQuestaoProvaTO,
			titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_149"),
			propId: "codProva",
			ordenar: false,
			id: "codsProvasOcorrencias",
			colunas: colunas,
			labelNovo: this.getMsg("FP_FRONT_CadastroQuestaoVH_154"),
			onNovo: !(historicoUsoQuestaoTO.isQuestaoBloqueada) ? async () => {
				this.addPopup({
					titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_155"),
					subtitulo: this.getValor("nome"),
					botoes: [{
						label: this.getMsg("FP_FRONT_CadastroQuestaoVH_156"),
						classe: "btn-primary",
						onClick: async () => {
							let codProvaParaInclusao = this.getValor("codProvaParaInclusao");

							if (this.hasValue(codProvaParaInclusao)) {
								await this.call("AssociacaoQuestaoFCD/inserirQuestaoNaProva", codProvaParaInclusao, this.getValor("codSecaoParaInclusao"), codQuestao);
								this.fecharTodasPopups();
								this.exibirAbasQuestao(2, codQuestao);
							}
						}
					}]
				});

				await this.addSelect({
					label: this.getMsg("FP_FRONT_CadastroQuestaoVH_161"),
					classe: "col-md-12",
					id: "codProvaParaInclusao",
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarProvas",
						numMinimoCaracteres: 3,
					},
					multiplo: false,
					obrigatorio: true,
					onChange: async () => {
						this.setHtml("select-secao-inclusao", "");

						let codProvaParaInclusao = this.getValor("codProvaParaInclusao");

						if (codProvaParaInclusao) {

							const listaSecoes = await this.call("ListagemSelecaoFCD/listarSecoes", codProvaParaInclusao);

							if (this.hasValue(listaSecoes)) {
								this.setIdTarget("select-secao-inclusao");

								await this.addSelect({
									collection: listaSecoes,
									label: this.getMsg("FP_FRONT_CadastroQuestaoVH_164"),
									classe: "col-md-12",
									id: "codSecaoParaInclusao"
								});

								this.exibir();
								this.setIdTarget(null);
							}
						}
					}
				});

				this.append("<div class='row'><div class='col-md-12' id='select-secao-inclusao'></div></div>");

				this.exibirPopups();
			} : null,
			selecao: historicoUsoQuestaoTO.isQuestaoBloqueada ? null : function(to) {
				return (to && !to.isArquivada && to.dataRemocao == null);
			}
		});

		if (!historicoUsoQuestaoTO.isQuestaoBloqueada && this.hasValue(historicoUsoQuestaoTO.listaHistoricoUsoQuestaoProvaTO)) {
			this.append("<div class='btn-group pull-right'>");
			this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_165"),
				classe: "btn-danger",
				onClick: async () => {
					let codsProvasOcorrencias = this.getValor("codsProvasOcorrencias");

					if (this.isEmpty(codsProvasOcorrencias)) {
						this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_166") });
						return;
					}

					for (const codProva of codsProvasOcorrencias) {

						let desassociacaoQuestaoTO = {
							codQuestao: codQuestao,
							codProva
						}

						await this.call("AssociacaoQuestaoFCD/desassociarQuestao", desassociacaoQuestaoTO);
					}

					await this.exibirAbasQuestao(2, codQuestao);
				}
			});

			if (UtilAuth.possuiAcesso(TipoFuncionalidade.QUESTAO_SUBSTITUICAO)) {
				this.addBotao({
					label: ` <i class='fa fa-exchange'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_168")}`,
					classe: "btn-warning",
					onClick: async () => {
						let codsProvasOcorrencias = this.getValor("codsProvasOcorrencias");

						if (this.isEmpty(codsProvasOcorrencias)) {
							this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_167") });
							return;
						}
						this.fecharTodasPopups();
						await this.exibirSubstituicaoQuestao(codQuestao, codsProvasOcorrencias);
					}
				});
			}
			this.append("</div>");
		}

		if (isExibirEmPopup) {
			this.exibirPopups();
		} else {
			this.exibir();
		}
	}

	async exibirSubstituicaoQuestao(codQuestaoASerSubstituida: number, codsProvasOcorrencias: number | number[] | string) {

		UtilHash.registrarHistorico(this.exibirSubstituicaoQuestao, codQuestaoASerSubstituida, codsProvasOcorrencias);

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_169"));
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_170"));

		this.codQuestaoASerSubstituida = codQuestaoASerSubstituida;

		if (codsProvasOcorrencias && typeof codsProvasOcorrencias === "string") {
			this.codsProvasOcorrencias = codsProvasOcorrencias.split(",").map(s => Number(s));
		} else if (typeof codsProvasOcorrencias === "number") {
			this.codsProvasOcorrencias = [codsProvasOcorrencias];
		} else if (Array.isArray(codsProvasOcorrencias)) {
			this.codsProvasOcorrencias = codsProvasOcorrencias;
		} else {
			throw new Error("tipo não suportado");
		}

		this.setMetodoSubmit(async () => await this.buscarQuestoes());

		await selecaoQuestoesVH.montarHtmlFiltros({
			onFiltroAlterado: async () => await cadastroQuestaoVH.buscarQuestoes(cadastroQuestaoVH.onPosAtualizarQuestaoParaSubstituicao),
			possuiAcessoQuestoesComConcurso: this.getIsBancoQuestoesHabilitado()
		});

		this.append("<div class='col-md-12' id='resultado_busca'></div>");

		this.exibir();

		cadastroQuestaoVH.buscarQuestoes(cadastroQuestaoVH.onPosAtualizarQuestaoParaSubstituicao);
	}

	onPosAtualizarQuestaoParaSubstituicao(questao) {

		let seletor = questao || "[questao]";

		exibicaoQuestaoVH.mostrarBarraDeFerramentas(seletor);

		$(seletor).find("[ferramentas]").prepend(`<a substituir class='btn btn-default btn-warning' title='${this.getMsg("FP_FRONT_CadastroQuestaoVH_171")}'><i class='fa fa-exchange'></i></a>`).find("[substituir]").off("click").on("click", async ({target}) => {

			let codQuestaoSubstituta = $(target).closest("[questao]").attr("cod-questao");

			let verificacaoSubstituicaoQuestaoTO = {
				codQuestaoSubstituida: cadastroQuestaoVH.codQuestaoASerSubstituida,
				codQuestaoSubstituta: codQuestaoSubstituta,
				codsProvas: cadastroQuestaoVH.codsProvasOcorrencias
			}

			const exibicaoSubstituicaoQuestaoTO = await this.call("CadastroQuestaoFCD/verificarSubstituicaoQuestao", verificacaoSubstituicaoQuestaoTO);

			this.limpar();

			this.setTitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_172"));
			this.setSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_173"));

			this.addSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_174"));
			exibicaoQuestaoVH.exibirQuestao(exibicaoSubstituicaoQuestaoTO.exibicaoQuestaoTOSubstituida);

			this.addSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_175"));
			exibicaoQuestaoVH.exibirQuestao(exibicaoSubstituicaoQuestaoTO.exibicaoQuestaoTOSubstituta);

			this.addSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_176"));

			const colunas: ColunaAddTabela[] = [
				{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_177"), prop: "nome", classe: "descricao"},
				{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_178"), prop: "descricao"},
			];

			await this.addTabela({
				collection: exibicaoSubstituicaoQuestaoTO.collectionOpcaoListaTOProvas,
				colunas: colunas,
			});

			this.addBotao({
				label: `<i class='fa fa-exchange'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_182")}`,
				classe: "btn-warning pull-right",
				onClick: async () => {

					await this.call("CadastroQuestaoFCD/substituirQuestaoEmProvas", verificacaoSubstituicaoQuestaoTO);

					this.limpar();

					this.setTitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_179"));
					this.setSubtitulo(this.getMsg("FP_FRONT_CadastroQuestaoVH_180"));

					let hash1 = UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 2, verificacaoSubstituicaoQuestaoTO.codQuestaoSubstituida);
					let hash2 = UtilHash.getHash(cadastroQuestaoVH.exibirAbasQuestao, 2, verificacaoSubstituicaoQuestaoTO.codQuestaoSubstituta);

					this.addMsgSucesso(this.getMsg("FP_FRONT_CadastroQuestaoVH_181"));

					this.addEspacamentoHorizontal("10px");

					this.append("<div class='btn-group'>");

					this.addBotao({
						label: this.getMsg("FP_FRONT_CadastroQuestaoVH_183"),
						href: hash1
					});

					this.addBotao({
						label: this.getMsg("FP_FRONT_CadastroQuestaoVH_184"),
						href: hash2
					});

					this.append("</div>");

					await this.addTabela({
						collection: exibicaoSubstituicaoQuestaoTO.collectionOpcaoListaTOProvas,
						propId: "id",
						colunas: [
							{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_185"), prop: "nome", classe: "descricao"},
						],
						onEdicao: cadastroProvaVH.exibirAbasProva,
						onEdicaoParam1: 2
					});

					this.exibir();
				}
			});

			this.exibir();
		});

		this.exibir();
	}

	questaoPodeSerAlterada() {
		return (this.edicaoQuestaoTO.usoResumidoQuestaoTO.numRespostas == 0) && (this.edicaoQuestaoTO.possuiCadernoComFR == 0);
	}

	criarEdicaoTexto(edicaoQuestaoTO) {

		this.append(`
			<div class="col-sm-12">
		`);
		this.append(this.addBotao({
			texto: `<i class='fa fa-plus-circle'></i> Criar novo texto`,
			onClick: async () => await this.editarTexto(cadastroQuestaoVH.edicaoQuestaoTO),
			css: "float:right; margin: 0px 0px 0px 10px",
			classe: "btn-sm",
			retornarHtml: true,
			habilitado: this.questaoPodeSerAlterada()
		}));
		this.append(`
				<div id="exibicao_textos_edicao_${edicaoQuestaoTO.codQuestao}">
				</div>
			</div>
		`);
	}


	async atualizarEdicaoTexto(edicaoQuestaoTO) {
		let exibicaoTexto = $("#exibicao_textos_edicao_" + edicaoQuestaoTO.codQuestao);
		exibicaoTexto.children().remove();
		exibicaoTexto.append(this.exibirEdicaoTexto(edicaoQuestaoTO));

		$("[pode-abrir-janela-edicao-exclusao='false']").find("[conteudo]").closest("[texto]").find("[conteudo], [titulo], [rodape], [refs]").tooltip({ container: "body", title: this.getMsg("FP_FRONT_CadastroQuestaoVH_186") });
		$("[pode-abrir-janela-edicao-exclusao='true']").find("[conteudo]").closest("[texto]").find("[conteudo], [titulo], [rodape], [refs]").css("cursor", "pointer").off("click").on("click", async ({ target }) => {
			await this.editarTexto(edicaoQuestaoTO, target);
		});
	}

	exibirEdicaoTexto(edicaoQuestaoTO) {

		let h = [];

		if (edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO) {

			for (let i = 0; i < edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO.length; i++) {

				let edicaoQuestaoTextoTO = edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO[i]
				if (!edicaoQuestaoTextoTO.isExcluido) {

					let podeAbrirJanelaEdicaoExclusao = edicaoQuestaoTextoTO.isPodeAlterar || edicaoQuestaoTextoTO.isPodeExcluir;
					h.push("<div texto index=" + i + " id='resposta_questao_" + edicaoQuestaoTO.codQuestao + "_texto_" + i + "' pode-alterar='" + edicaoQuestaoTextoTO.isPodeAlterar + "' " + "' pode-excluir='" + edicaoQuestaoTextoTO.isPodeExcluir + "' pode-abrir-janela-edicao-exclusao='" + podeAbrirJanelaEdicaoExclusao + "' >");
					let style = "";

					if (edicaoQuestaoTextoTO.titulo != null) {
						h.push("<div refs " + style + "></div>")
					}

					if (edicaoQuestaoTextoTO.titulo != null) {
						h.push("<div titulo " + style + ">" + edicaoQuestaoTextoTO.titulo + "</div>")
					}

					if (edicaoQuestaoTextoTO.conteudo != null) {
						if (edicaoQuestaoTextoTO.isLinhasNumeradas) {
							h.push("<div numeracao " + style + ">" + UtilHtml.gerarHTMLNumeracaoLinhasTexto(edicaoQuestaoTextoTO.conteudo) + "</div>")
						}
						h.push("<div conteudo " + style + ">" + edicaoQuestaoTextoTO.conteudo + "</div>")
					}

					if (edicaoQuestaoTextoTO.rodape != null) {
						h.push("<div rodape " + style + ">" + edicaoQuestaoTextoTO.rodape + "</div>")
					}

					h.push("</div>");
					h.push(this.addEspacamentoHorizontal("1px", true));
				}
			}
		}

		return h.join("");
	}

	async editarTexto(edicaoQuestaoTO, div?) {

		let indexTexto = $(div).closest("[texto]").attr("index");
		let podeAlterar = function(v) { return v == undefined || v == 'true'; }($(div).closest("[texto]").attr("pode-alterar"));
		let podeExcluir = $(div).closest("[texto]").attr("pode-excluir") == 'true';
		let isRedacaoImaginie = $(div).closest("[texto]").attr("redacao-imaginie") == 'true';

		let botoes = [];

		if (podeExcluir) {
			botoes.push({
				label: "<i class='fa fa-trash-o'></i> Remover", classe: "btn-danger",
				onClick: () => {
					this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_187"),
						botoes: [{
							label: this.getMsg("MSG_VH_004"),
							classe: "btn-primary",
							onClick: async () => {
								if (edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO) {
									edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO[indexTexto].isExcluido = true;
								}
								this.fecharPopup("edicao_texto");
								this.atualizarEdicaoTexto(edicaoQuestaoTO);
							}
						},{
							label: this.getMsg("MSG_VH_005")
						}]
					});
				}
			});
		}
		if (podeAlterar) {
			botoes.push({
				label: indexTexto ? this.getMsg("FP_FRONT_CadastroQuestaoVH_188") : this.getMsg("FP_FRONT_CadastroQuestaoVH_189"), classe: "btn-primary", onClick: () => {

					edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO = edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO || [];

					if (!this.validarCamposObrigatorios("edicao_texto_conteudo")) {
						return false;
					}

					let edicaoQuestaoTextoTO: any = {};

					if (indexTexto != undefined) {
						edicaoQuestaoTextoTO = edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO[indexTexto];

					} else {
						edicaoQuestaoTextoTO.isNovo = true;
						edicaoQuestaoTextoTO.isPodeAlterar = true;
						edicaoQuestaoTextoTO.isPodeExcluir = true;
						edicaoQuestaoTO.collectionEdicaoQuestaoTextoTO.push(edicaoQuestaoTextoTO);
					}

					edicaoQuestaoTextoTO.titulo = this.getValor("edicao_texto_titulo");
					edicaoQuestaoTextoTO.conteudo = this.getValor("edicao_texto_conteudo");
					edicaoQuestaoTextoTO.rodape = this.getValor("edicao_texto_rodape");
					edicaoQuestaoTextoTO.isLinhasNumeradas = this.getValor("edicao_texto_isLinhasNumeradas");
					edicaoQuestaoTextoTO.isAlterado = true;
					edicaoQuestaoTextoTO.isRedacaoImaginie = this.getValor("isRedacaoImaginie");
					edicaoQuestaoTextoTO.temaImaginie = this.getValor("temaImaginie");

					this.fecharPopup("edicao_texto");
					this.atualizarEdicaoTexto(edicaoQuestaoTO);

					return false;

				}
			});
		}

		this.addPopup({
			id: "edicao_texto",
			titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_190"),
			width: "710px",
			// height: "560px",
			botoes: botoes
		});

		this.addCheckbox({
			id: "edicao_texto_isLinhasNumeradas",
			label: this.getMsg("FP_FRONT_CadastroQuestaoVH_191"),
			habilitado: podeAlterar,
			valor: $(div).closest("[texto]").find("[numeracao]").length > 0,
			classe: "col-md-6"
		});

		this.addCheckbox({
			id: "isRedacaoImaginie",
			label: "Redação corrigida pela Imaginie",
			habilitado: podeAlterar,
			classe: "col-md-6",
			valor: isRedacaoImaginie,
			visivel: edicaoQuestaoTO.possuiIntegracaoImaginie,
			onChange: async () => {
				const checked = this.getValor("isRedacaoImaginie");
				if(checked){
					await this.show("temaImaginie");
				}
				else{
					await this.hide("temaImaginie");
				}
			}
		});

		await this.addSelect({
			id: "temaImaginie",
			label: "Selecione o tema para redação",
			classe: "col-md-12",
			loadOpcoesBack: {
				endpoint: "ImgTemasFCD/buscarTemas",
				numMinimoCaracteres: 5,
			},
			visivel: isRedacaoImaginie,
			obrigatorio: true,
			onChange: async () => {
				const tema = await this.call("ImgTemasFCD/recuperarTema", this.getValor("temaImaginie"));

				this.setValor("edicao_texto_titulo", tema.titulo);
				let conteudoHtml = `
					<strong>Texto de apoio:</strong>
					<a href="${tema.contentUrl}" target="_blank">Material de Apoio</a>
				`;
				this.setValor("edicao_texto_conteudo", conteudoHtml);
			}
		});

		let titulo = $(div).closest("[texto]").find("[titulo]").html();
		let conteudo = $(div).closest("[texto]").find("[conteudo]").html();
		let rodape = $(div).closest("[texto]").find("[rodape]").html();

		this.addEspacamentoHorizontal("1px");
		this.addEditorHTML({ label: this.getMsg("FP_FRONT_CadastroQuestaoVH_192"), id: "edicao_texto_titulo", habilitado: podeAlterar, valor: titulo, classe: "col-md-12", toolbar: "importacao" });
		this.addEditorHTML({ label: this.getMsg("FP_FRONT_CadastroQuestaoVH_193"), id: "edicao_texto_conteudo", habilitado: podeAlterar, valor: conteudo, classe: "col-md-12", toolbar: "importacao" })
		this.addEditorHTML({ label: this.getMsg("FP_FRONT_CadastroQuestaoVH_194"), id: "edicao_texto_rodape", habilitado: podeAlterar, valor: rodape, classe: "col-md-12", toolbar: "importacao" })

		this.exibirPopups();
	}

	async exibirImpactoAlteracaoGabarito(codQuestao: number, codItemNovoGabarito: number, novoGabaritoValorExato: string, cfgsFluxoTela?: CfgsFluxoTela) {

		this.fecharPopup("opcaoEmMudancaDeGabarito")

		const exibicaoImpactoAlteracaoGabaritoQuestaoTO = await this.call({
			endpoint: "CadastroQuestaoFCD/verificarAlteracaoGabarito",
			params: [{
				codQuestao,
				codItemNovoGabarito,
				novoGabaritoValorExato,
				isFluxoRecursos: cfgsFluxoTela?.isFluxoRecursos
			}],
			msgCarregando: this.getMsg("FP_FRONT_CadastroQuestaoVH_195")
		});

		let naoImpactaraProvas = exibicaoImpactoAlteracaoGabaritoQuestaoTO.numProvasEncerradasImpactadas == 0 && exibicaoImpactoAlteracaoGabaritoQuestaoTO.numProvasEmAndamentoImpactadas == 0;

		if (naoImpactaraProvas && !exibicaoImpactoAlteracaoGabaritoQuestaoTO.iraAlterarQuestao) {
			this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroQuestaoVH_196") });
			return;
		}

		if (!cfgsFluxoTela) {
			this.limpar(true);

		} else {
			this.limpar();
			this.setTitulo(cfgsFluxoTela.titulo);
			this.setSubtitulo(cfgsFluxoTela.subtitulo);
		}

		let labelBotaoAlterarGabarito = this.getMsg("FP_FRONT_CadastroQuestaoVH_197");

		if (naoImpactaraProvas) {

			if (exibicaoImpactoAlteracaoGabaritoQuestaoTO.iraAlterarQuestao) {
				this.addTexto(`<div class='alert'>${this.getMsg("FP_FRONT_CadastroQuestaoVH_200")}</div>`);
			}

		} else {

			this.addTexto(this.getMsg("FP_FRONT_CadastroQuestaoVH_201"));

			if (exibicaoImpactoAlteracaoGabaritoQuestaoTO.numProvasEmAndamentoImpactadas > 0) {
				let textoProvasEmAndamento = exibicaoImpactoAlteracaoGabaritoQuestaoTO.numProvasEmAndamentoImpactadas == 1 ?
					`</b> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_202")}</div>` :
					`</b> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_203")}</div>`;
				this.addTexto("<div class='alert alert-danger'><b>" + exibicaoImpactoAlteracaoGabaritoQuestaoTO.numProvasEmAndamentoImpactadas + textoProvasEmAndamento);
			}

			let i = 0;

			for (const aplicacao in exibicaoImpactoAlteracaoGabaritoQuestaoTO.mapaProvaFeitaImpactadaPorAlteracaoGabaritoTOPorAplicacao) {
				const collection = exibicaoImpactoAlteracaoGabaritoQuestaoTO.mapaProvaFeitaImpactadaPorAlteracaoGabaritoTOPorAplicacao[aplicacao];

				if (!collection || collection.length == 0) continue;

				if (aplicacao == "CADERNOS") {

					await this.addTabela({
						collection: collection,
						titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_218"),
						id: "idsProvasFeitas" + (i++),
						propId: "codProvaFeita",
						colunas: [
							{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_219"), prop: "nomeDaProvaDoCaderno"},
							{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_220"), prop: "nomeCaderno"},
							{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_221"), prop: "#${codProvaFeita}"},
						],
						selecao: true
					});

				} else {

					const colunas: ColunaAddTabela[] = [];
					colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_204"), prop: "nomeRespondente"});
					colunas.push({titulo: this.getMsg("FP_FRONT_CadastroAOVH_089", this.getCfg("LABEL_TURMA")), prop:  this.montarColunaTurma});
					colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_205"), prop: this.montarColunaData});
					colunas.push({titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_206"), prop: (provasFeitasAlteraveisPorAlteracaoTO) => {
							let colunaImpacto = '';

							if (provasFeitasAlteraveisPorAlteracaoTO.pontuacaoObtida != null) {
								const pontos = (provasFeitasAlteraveisPorAlteracaoTO.alteracaoPontuacaoNaProva > 0 ? "+" : "") + UtilNumero.floatToString(provasFeitasAlteraveisPorAlteracaoTO.alteracaoPontuacaoNaProva);
								const pontuacaoObtida = UtilNumero.floatToString(provasFeitasAlteraveisPorAlteracaoTO.pontuacaoObtida);
								const pontuacaMaxima = UtilNumero.floatToString(provasFeitasAlteraveisPorAlteracaoTO.pontuacaoMaxima);

								colunaImpacto += this.getMsg("FP_FRONT_CadastroQuestaoVH_211", pontuacaoObtida, pontuacaMaxima) + "<br>" + this.getMsg("FP_FRONT_CadastroQuestaoVH_212", pontos);
							} else {
								colunaImpacto += `<span class='alert-danger'> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_213")} </span>`;
							}
							return colunaImpacto;
						}
					});

					await this.addTabela({
						titulo: aplicacao,
						collection: collection,
						id: "idsProvasFeitas" + (i++),
						propId: "codProvaFeita",
						colunas: colunas,
						onEdicao: aplicacaoProvaVH.exibirDetalhesProvaRealizada,
						selecao: true
					});
				}
			}

			labelBotaoAlterarGabarito = this.getMsg("FP_FRONT_CadastroQuestaoVH_207");

			if (!exibicaoImpactoAlteracaoGabaritoQuestaoTO.iraAlterarQuestao) {
				this.addTexto(`<div class='alert'>${this.getMsg("FP_FRONT_CadastroQuestaoVH_209")}</div>`);
			}

			this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_208"), onClick: () => {
					$("table[id^='idsProvasFeitas'] input[type='checkbox']").each((i, checkbox) => {
						$(checkbox).prop('checked', true).on("change");
					})
				}
			});
		}

		labelBotaoAlterarGabarito = cfgsFluxoTela?.labelBotaoFinalizacao || labelBotaoAlterarGabarito;

		this.addBotao({
			label: labelBotaoAlterarGabarito,
			classe: cfgsFluxoTela?.classeBotaoFinalizacao || "btn-danger",
			onClick: async () => {

				const alteracaoGabaritoComRecalculoTO = new AlteracaoGabaritoComRecalculoTO();

				alteracaoGabaritoComRecalculoTO.codQuestao = codQuestao;
				alteracaoGabaritoComRecalculoTO.codItemNovoGabarito = codItemNovoGabarito;
				alteracaoGabaritoComRecalculoTO.novoGabaritoValorExato = novoGabaritoValorExato;

				$("[id^='idsProvasFeitas']").each((i, e: Element) => {
					const ids = UtilNumero.from(amaisVH.getValor(e.id)) || [];
					alteracaoGabaritoComRecalculoTO.idsProvasFeitas = alteracaoGabaritoComRecalculoTO.idsProvasFeitas.concat(ids);
				});

				if (cfgsFluxoTela?.onClickBotaoFinalizacao) {
					cfgsFluxoTela.onClickBotaoFinalizacao(alteracaoGabaritoComRecalculoTO);

				} else {
					await this.call("CadastroQuestaoFCD/alterarGabaritoComRecalculo", alteracaoGabaritoComRecalculoTO);
					document.location.reload();
				}

			}
		});

		this.exibir({ isDeveFazerScrollParaTitulo: true });
	}

	handleAlteracaoGabaritoComResposta(codQuestao: number, codItemNovoGabarito: number, novoGabaritoValorExato: string) {

		if (!codQuestao || (!codItemNovoGabarito && !novoGabaritoValorExato)) return;

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_100"),
			width: "400px",
			// height: "400px",
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroQuestaoVH_104"),
				classe: "col-md-12 btn-danger",
				onClick: () => this.exibirImpactoAlteracaoGabarito(codQuestao, codItemNovoGabarito, novoGabaritoValorExato)
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_CadastroQuestaoVH_101"));

		this.exibirPopups();
	}

	async exibirAnulacaoQuestao(codQuestao: number) {

		const anulacaoQuestao = await this.call("CadastroQuestaoFCD/recuperarAnulacaoQuestao", codQuestao);

		this.limpar(true);

		if (!anulacaoQuestao.isAnulada) {
			this.addBotao({
				label: `<span class='text-danger'><i class='fa fa-ban'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_071")}</span>`,
				classe: "btn-default",
				onClick: async () => await cadastroQuestaoVH.anular(codQuestao),
				dica: this.getMsg("FP_FRONT_CadastroQuestaoVH_070")
			});
		}

		const colunas: ColunaAddTabela[] = [
			{titulo: "ID", prop: (to) => `<small>#${to.codQuestaoAnulacao}</small>`},
			{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_297"), prop: (to) => {
					return {
						ANULACAO: this.getMsg("FP_FRONT_CadastroQuestaoVH_303"),
						REVERSAO: this.getMsg("FP_FRONT_CadastroQuestaoVH_304"),
					}[to.opcaoAnulacao];
				},
			},
			{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_298"), prop: (to) => {
					return {
						SO_CADASTRO: this.getMsg('FP_FRONT_CadastroQuestaoVH_305'),
						SO_RESPOSTAS: this.getMsg('FP_FRONT_CadastroQuestaoVH_306'),
						CADASTRO_E_RESPOSTAS: this.getMsg('FP_FRONT_CadastroQuestaoVH_307'),
					}[to.tipoAnulacao];
				},
			},
			{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_299"), prop: 'opcaoPontuacao'},
			{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_300"), prop: 'motivo'},
			{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_301"), prop: 'usuarioAnulacao'},
			{titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_302"), prop: 'dataAnulacao', formato: 'DD/MM/YY HH:mm'},
			{titulo: "", prop: (to) => {
					if (to.opcaoAnulacao == 'ANULACAO') {
						if (to.isRevertida) {
							return `<small>${this.getMsg("FP_FRONT_CadastroQuestaoVH_308")}</small>`
						}
						return `
							<a class="btn btn-warning btn-table" title="${this.getMsg('FP_FRONT_CadastroQuestaoVH_249')}" onclick="cadastroQuestaoVH.removerAnulacao(${codQuestao}, ${null}, ${to.codQuestaoAnulacao})">
								<i class="fa fa-undo"></i> ${this.getMsg('FP_FRONT_CadastroQuestaoVH_249')}
							</a>`;
					}
					if (to.opcaoAnulacao == 'REVERSAO' && to.codQuestaoAnulacaoRevertida) {
						return `<small>${this.getMsg("FP_FRONT_CadastroQuestaoVH_309", to.codQuestaoAnulacaoRevertida)}</small>`;
					}
				}
			},
		];

		await this.addTabela({
			collection: anulacaoQuestao.collectionQuestaoAnulacaoTO,
			titulo: this.getMsg("FP_FRONT_CadastroQuestaoVH_296"),
			propId: "codQuestao",
			ordenar: false,
			id: "tabelaQuestaoAnulacao",
			colunas: colunas
		});

		this.exibir();
	}

}

const cadastroQuestaoVH = new CadastroQuestaoVH();



class AnulacaoQuestaoTO {
	codQuestao: number;
	isAnularSoCadastro: boolean;
	isZerarPontuacao: boolean;
	idsProvasFeitas: number[];
	isAnularSoRespostas: boolean;
	tipoAnulacao: string
}

class AlteracaoGabaritoComRecalculoTO {
	idsProvasFeitas: number[] = [];
	codQuestao: number;
	codItemNovoGabarito: number;
	novoGabaritoValorExato: string;
}

class CopiaConfiguracoesQuestaoTO {
	codQuestao: number;
	classificacoes: number[];
	copiarConfiguracoes: boolean = false;
}

enum TipoSalvamento { 
	SO_SALVAR,
	SALVAR_COMO,
	SALVAR_E_VOLTAR,
	SALVAR_E_CRIAR_NOVA
}

enum TipoAnulacao {
	SO_CADASTRO = "SO_CADASTRO",
	SO_RESPOSTAS = "SO_RESPOSTAS",
	CADASTRO_E_RESPOSTAS = "CADASTRO_E_RESPOSTAS"
}