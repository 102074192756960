class ImportacaoProvaVH extends AmaisVH {

	constructor() {
		super(ImportacaoProvaVH.name);
		this.addOperacaoParaHash("ipee", this.exibirEditor);
		this.addOperacaoParaHash("ipei", this.editarImportacao);
		this.addOperacaoParaHash("ipiq", this.exibirTelaImportacaoQuestoes);
	}

	letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	jaResolveuMsgsTiposDeProva = false;
	isPluginInicializado = false;

	tiposDeProva = [
		{ id: "0", descricao: null, idMsgDescricao: "CESPE CE<br>numerador no item do tipo '1 ', '2 ', ...", patterns: [/\n(\d+)\s/g, /\n(\d+)\.<\/b>/g], grupoNumeracao: 1 },
		{ id: "1", descricao: null, idMsgDescricao: "FCC ME<br>numerador na questão do tipo '1.', '2.', ...", patterns: [/\n(\d+)\./g], grupoNumeracao: 1 },
		{ id: "2", descricao: null, idMsgDescricao: "IADES ME<br>numerador na questão do tipo 'QUESTÃO 1', 'QUESTÃO 2', ...", patterns: [/QUESTÃO\s(\d+)/g, /QUEST&Atilde;O\s(\d+)/g], grupoNumeracao: 1 },
		{ id: "3", descricao: null, idMsgDescricao: "ESAF<br>numerador na questão do tipo '1 - ', '2 - ', ...", patterns: [/\n(<\w+>)?(\d+)\s?(<\/?\w+>)*\s?[–-]/g], grupoNumeracao: 2 },
		{ id: "4", descricao: null, idMsgDescricao: "IMP CE<br>numerador na questão do tipo '1) ', '2) ', ...", patterns: [/\n(<strong>)?(\d+)\)/g], grupoNumeracao: 2 }
	];

	getTiposDeProva() {
		if (!this.jaResolveuMsgsTiposDeProva) {
			this.jaResolveuMsgsTiposDeProva = true;
			this.tiposDeProva.forEach(tp => tp.descricao = this.getMsg(tp.idMsgDescricao));
		}
		return this.tiposDeProva;
	}

	converterAcentos(s) {

		for (let i = 0; i < s.length; i++) {
			if (s.charCodeAt(i) == 771 && s.charCodeAt(i - 1) == 65) {
				s = s.substring(0, i - 1) + "Ã" + s.substring(i + 1);
			}
		}

		return s.replace(/<strong> <\/strong>/g, " ");
	}

	async exibirEditor(codImportacao) {
 
		UtilHash.registrarHistorico(this.exibirEditor, codImportacao);
		
		const dadosParaImportacaoTO = await this.call("ImportacaoProvaFCD/recuperarDadosParaImportacao", codImportacao);

		this.limpar();
		this.setTitulo(dadosParaImportacaoTO.tituloProva);
		this.setSubtitulo(this.getMsg("FP_FRONT_ImportacaoProvaVH_005"));

		if (dadosParaImportacaoTO.numeracaoInicial != null) {
			this.addTexto(this.getMsg("FP_FRONT_ImportacaoProvaVH_004", dadosParaImportacaoTO.numeracaoInicial));
		}

		this.addRadioGroup({
			id: "tipoDeProva",
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_006"),
			valor: 3,
			collection: this.getTiposDeProva()
		});

		this.addCheckbox({ id: "isRemoverQuebrasDeLinha", label: this.getMsg("FP_FRONT_ImportacaoProvaVH_007"), valor: true });

		this.addBotao({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_008"), onClick: () => {
				revisaoProvaVH.abrirPDFAoLado(dadosParaImportacaoTO.pathArquivoProva);
			}
		})

		this.addBotao({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_009"), 
			onClick: async () => {
				await importacaoProvaVH.call("ImportacaoProvaFCD/salvarHtml", dadosParaImportacaoTO.codImportacao, "&nbsp;");
				location.reload();
			}
		});

		this.addBotao({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_010"), 
			classe: "btn-success", 
			onClick: async () => {

				this.corrigirPosicaoSecoesETextos();

				let importacaoDeQuestoesTO = {
					codImportacao: dadosParaImportacaoTO.codImportacao,
					listElementoDaProvaTO: []
				}

				let numQuestoes = 0;
				let numSecoes = 0;

				$("secao").each(function() {

					let elementoDaProvaTO = {
						nomeSecao: $(this).attr("nome"),
						isSecaoOptativa: ($(this).attr("is-optativa") == "true")
					}

					numSecoes++;

					importacaoDeQuestoesTO.listElementoDaProvaTO.push(elementoDaProvaTO);

					$(this).find("texto, questao").each(function() {

						let elementoDaProvaTO: any = {};

						if (this.tagName == "TEXTO") {
							elementoDaProvaTO.conteudoDoTexto = $(this).html();
							$(this).remove();

						} else if (this.tagName == "QUESTAO") {
							elementoDaProvaTO.numeradorDaQuestao = UtilString.getSomenteNumeros($(this).find("numerador").text().trim());
							$(this).find("numerador").remove();
							elementoDaProvaTO.listaItemTO = [];

							if ($(this).find("item").length > 0) {
								$(this).find("item").each(function() {
									let itemTO: any = {
										letra: $(this).find("letra").text().trim()
									}

									$(this).find("letra").remove();

									itemTO.conteudoDoItem = $(this).html();
									$(this).remove();
									elementoDaProvaTO.listaItemTO.push(itemTO);
								})

								elementoDaProvaTO.conteudoDaQuestao = $(this).find("enunciado").html();

							} else {
								let itemTO = {
									conteudoDoItem: $(this).html()
								}

								elementoDaProvaTO.listaItemTO.push(itemTO);
								elementoDaProvaTO.tipoQuestao = "CE";
							}

							numQuestoes++;
						}

						importacaoDeQuestoesTO.listElementoDaProvaTO.push(elementoDaProvaTO);
					})
				})

				if (numSecoes == 0) {
					await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_011") });
					return;
				}

				if (numQuestoes == 0) {
					await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_012") });
					return;
				}

				await this.call("ImportacaoProvaFCD/inserirQuestoes", importacaoDeQuestoesTO);
				await cadastroProvaVH.exibirAbasProva(2, dadosParaImportacaoTO.codProva);
			}
		})

		this.addEspacamentoHorizontal("10px");

		let possuiSecao = false;

		try {
			possuiSecao = (dadosParaImportacaoTO.htmlDaProva != null && $(dadosParaImportacaoTO.htmlDaProva).filter("secao").length > 0);
		} catch (e) {
			this.logger.error(e);
			console.log(e);
		}

		const toggleEditorHtml = (id, tipoToolbar) => {
			const CKEDITOR = UtilBoot.getCKEditor();
			this.inicializarPluginImportacao(CKEDITOR, dadosParaImportacaoTO);
			let editor = CKEDITOR.instances[id];

			if (editor) {
				this.organizarSecoes();
				editor.destroy();

			} else {
				editor = this.ativarEditorHtml(id, tipoToolbar);
			}

			return editor;
		}
		
		if (possuiSecao) {
			let numSecao = 0;

			$(dadosParaImportacaoTO.htmlDaProva).filter("secao").each((i, secao) => {
				let nomeSecao = $(secao).attr("nome");
				let checked = $(secao).attr("is-optativa") == "true" ? "checked" : "";

				numSecao++;

				if (nomeSecao == null) {
					nomeSecao = "Seção " + numSecao;
				}

				revisaoProvaVH.abrirAbaAccordion({
					titulo: nomeSecao,
					texto: ` <small style='float: right'><input type='checkbox' ${checked}> ${this.getMsg("FP_FRONT_ImportacaoProvaVH_013")}</small>`
				});

				revisaoProvaVH.append("<div class='secao' id='secao" + numSecao + "'>" + $('<div>').append($(secao).clone()).html() + "</div>");

				revisaoProvaVH.fecharAbaAccordion();
			})

			this.fecharGrupoAccordion();
			this.exibir();

			this.organizarSecoes();

			$("div.secao").attr("contenteditable", "true");
			
			this.exibir();

			$(".panel-group .panel-title a").on("click", ({ target }) => {
				const id = $(target).closest(".panel").find("div.secao").attr("id");
				toggleEditorHtml(id, "importacao_conteudo_secao");
			})

			$(".panel-group .panel-title input").on("click", () => {
				this.organizarSecoes();
			})

		} else {

			this.append(`
				<label>
					${this.getMsg("FP_FRONT_ImportacaoProvaVH_014")}
				</label>
				<div id='conteudoProva' class='col-md-12' style='border: solid 1px gray' contenteditable='true'>
					${dadosParaImportacaoTO.htmlDaProva || ""}
				</div>
			`);

			this.exibir();

			toggleEditorHtml("conteudoProva", "importacao_conteudo_prova");
		}
	}

	corrigirPosicaoSecoesETextos() {

		// reposicionando textos dentro de questoes

		$($("texto").get().reverse()).each(function() {
			let texto = $(this).clone();

			if ($(this).closest("questao").length > 0) {
				$(this).closest("questao").after(texto);
				$(this).remove();
			}
		})
	}

	async editarImportacao(codImportacao, codProva?, codConcurso?) {

		UtilHash.registrarHistorico(this.editarImportacao, codImportacao, codProva, codConcurso);

		const edicaoImportacaoTO = await this.call("ImportacaoProvaFCD/recuperarImportacao", codImportacao, codProva, codConcurso);

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_ImportacaoProvaVH_015"));
		this.setSubtitulo(edicaoImportacaoTO.tituloProva != null ? edicaoImportacaoTO.tituloProva : this.getMsg("FP_FRONT_ImportacaoProvaVH_016"));

		this.addLink({ label: `<i class='fa fa-plus-circle fa-lg'></i> ${this.getMsg("FP_FRONT_ImportacaoProvaVH_024")}`, css: "float: right", onClick: () => {
			this.exibirEditor(edicaoImportacaoTO.codImportacao);
		}});

		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				collection: edicaoImportacaoTO.listas.segmentos,
				id: "codEmpresa",
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_017"),
				obrigatorio: true,
				valor: edicaoImportacaoTO.codEmpresa,
			});
		}

		if (edicaoImportacaoTO.codConcurso) {
			await this.addSelect({
				collection: edicaoImportacaoTO.listas.concursos,
				id: "codConcurso",
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_018"),
				valor: edicaoImportacaoTO.codConcurso
			});
			await this.addSelect({
				id: "codsCargos",
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_019"),
				multiplo: true,
				collection: edicaoImportacaoTO.listas.cargos,
				valor: edicaoImportacaoTO.codsCargos
			});
			this.addCampoTexto({ label: this.getMsg("FP_FRONT_ImportacaoProvaVH_020"), id: "identificadorProvaSegmentada", valor: edicaoImportacaoTO.identificadorProvaSegmentada })
			this.addEspacamentoHorizontal("1px")
		}

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_021"),
			id: "numQuestoes",
			valor: edicaoImportacaoTO.numQuestoes,
			classe: "col-md-3"
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_022"),
			id: "numeracaoInicial",
			valor: edicaoImportacaoTO.numeracaoInicial,
			classe: "col-md-3"
		});

		this.addRadioGroup({
			id: "visibilidadeQuestoes",
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_023"),
			valor: edicaoImportacaoTO.isQuestoesLiberadasParaEstudo == true ? "ALUNO" : "ADM",
			collection: [
				{ id: "ADM", descricao: this.getMsg("FP_FRONT_ImportacaoProvaVH_025") },
				{ id: "ALUNO", descricao: this.getMsg("FP_FRONT_ImportacaoProvaVH_026") }]
		});

		this.addSubtitulo("Arquivos");

		this.addZonaUpload({ id: "urlArquivoProva", label: this.getMsg("FP_FRONT_ImportacaoProvaVH_027"), /*maxArquivos: 1,*/ classe: "col-md-9" })
		if (edicaoImportacaoTO.pathArquivoProva) {
			this.addLink({ label: this.getMsg("FP_FRONT_ImportacaoProvaVH_027"), href: edicaoImportacaoTO.pathArquivoProva });
		}
		this.addEspacamentoHorizontal("1px")

		this.addZonaUpload({ id: "urlArquivoGabaritoPreliminar", label: this.getMsg("FP_FRONT_ImportacaoProvaVH_028"), /*maxArquivos: 1,*/ classe: "col-md-9" })
		if (edicaoImportacaoTO.pathArquivoGabaritoPreliminar) {
			this.addLink({ label: this.getMsg("FP_FRONT_ImportacaoProvaVH_028"), href: edicaoImportacaoTO.pathArquivoGabaritoPreliminar });
		}
		this.addEspacamentoHorizontal("1px")
		this.addZonaUpload({ id: "urlArquivoGabaritoDefinitivo", label: this.getMsg("FP_FRONT_ImportacaoProvaVH_029"), /*maxArquivos: 1,*/ classe: "col-md-9" })
		if (edicaoImportacaoTO.pathArquivoGabaritoDefinitivo) {
			this.addLink({ label: this.getMsg("FP_FRONT_ImportacaoProvaVH_029"), href: edicaoImportacaoTO.pathArquivoGabaritoDefinitivo });
		}
		this.addEspacamentoHorizontal("1px")
		this.addZonaUpload({ id: "urlArquivoAtualizacaoGabarito", label: this.getMsg("FP_FRONT_ImportacaoProvaVH_030"), /*maxArquivos: 1,*/ classe: "col-md-9" })
		if (edicaoImportacaoTO.pathArquivoAtualizacaoGabarito) {
			this.addLink({ label: this.getMsg("FP_FRONT_ImportacaoProvaVH_030"), href: edicaoImportacaoTO.pathArquivoAtualizacaoGabarito });
		}
		this.addEspacamentoHorizontal("1px")

		this.addBotao({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_031"), 
			css: "float: right", 
			classe: "primary", 
			onClick: async () => {

				let salvamentoImportacaoTO: any = {
					codImportacao: codImportacao,
					codProva: codProva,
					urlArquivoProva: this.getValor("urlArquivoProva"),
					urlArquivoGabaritoPreliminar: this.getValor("urlArquivoGabaritoPreliminar"),
					urlArquivoGabaritoDefinitivo: this.getValor("urlArquivoGabaritoDefinitivo"),
					urlArquivoAtualizacaoGabarito: this.getValor("urlArquivoAtualizacaoGabarito"),

					numQuestoes: this.getValor("numQuestoes"),
					identificadorProvaSegmentada: this.getValor("identificadorProvaSegmentada"),
					numeracaoInicial: this.getValor("numeracaoInicial"),
					isQuestoesLiberadasParaEstudo: false
				}

				let visibilidadeQuestoes = this.getValor("visibilidadeQuestoes");

				if (visibilidadeQuestoes == "ALUNO") {
					if (await this.confirmar(this.getMsg("FP_FRONT_ImportacaoProvaVH_032"))) {
						salvamentoImportacaoTO.isQuestoesLiberadasParaEstudo = true;
					} else {
						return;
					}
				}

				salvamentoImportacaoTO.codEmpresa = this.getValor("codEmpresa");

				if (edicaoImportacaoTO.codConcurso) {
					salvamentoImportacaoTO.codConcurso = this.getValor("codConcurso");
					salvamentoImportacaoTO.codsCargos = this.getValor("codsCargos");
					if (!this.validarCamposObrigatorios("codConcurso", "codsCargos")) {
						return;
					}
				}

				if (this.isEmpty(edicaoImportacaoTO.codImportacao)) {
					if ((edicaoImportacaoTO.pathArquivoGabaritoPreliminar === null && salvamentoImportacaoTO.urlArquivoGabaritoPreliminar === null) && (edicaoImportacaoTO.pathArquivoGabaritoDefinitivo === null && salvamentoImportacaoTO.urlArquivoGabaritoDefinitivo === null)) {
						await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_034") });
						return;
					}
				}

				await this.call("ImportacaoProvaFCD/salvarImportacao", salvamentoImportacaoTO);
				UtilHash.voltar();
			}
		});

		this.exibir();
	}

	async exibirTelaImportacaoQuestoes(idProvaResponsavel?: number, codSecao: number = null, codProva: number = null, callback: Function = null) {

		UtilHash.registrarHistorico(this.exibirTelaImportacaoQuestoes, idProvaResponsavel, codSecao, codProva, callback);

		const dadosNovaImportacaoProvaTO = await this.call("ImportacaoProvaFCD/recuperarDadosNovaImportacao");

		this.limpar();
		let secaoQuestaoTO = null;
		if(codSecao) {
			secaoQuestaoTO = await this.call("CadastroProvaFCD/recuperarEdicaoSecao", codSecao);
			codProva = secaoQuestaoTO.codProva;
			this.setTitulo(`${this.getMsg("FP_FRONT_ImportacaoProvaVH_035")} - ${secaoQuestaoTO.nome}`);
		} else {
			this.setTitulo(this.getMsg("FP_FRONT_ImportacaoProvaVH_035"));
		}

		this.addZonaUpload({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_036"), 
			arquivosAceitos: ".docx, .xlsx, .xml", 
			id: "docxQuestoes", 
			// maxArquivos: 1, 
			classe: "col-md-12 dropzone-import-questoes",
			onDepoisEnvio: async (file: any) => {
				
				const salvamentoImportacaoDocxTO = {
					uploadTO: this.getValor("docxQuestoes"),
					tituloProva: UtilArquivo.getNomeArquivoSemExtensao(file.name),
					idProvaResponsavel: idProvaResponsavel,
					listElementoDaProvaTO: null,
					codsDisciplinas: null
				}

				const conversaoArquivoImportacaoTO = await this.call("ImportacaoProvaFCD/recuperarPreDocx", salvamentoImportacaoDocxTO.uploadTO);
	
				const div = AmaisVH.criarDivMsgAjax(UtilMsg.getMsg("FP_FRONT_UtilArmazenamento_001"));
		
				if (file.name.toLowerCase().endsWith(".xlsx")) {

					const response = await UtilArmazenamento.aguardarGeracaoJson(conversaoArquivoImportacaoTO.path);

					if (response.msgErro) {
						this.exibirAlerta({ msg: response.msgErro });
						return;
					}

					const linhas = response;

					await this.exibirColunasPlanilha({
						linhas,
						listaListaTOTiposColunas: conversaoArquivoImportacaoTO.listaListaTOTipoColunas,
						salvamentoImportacaoDocxTO,
						callback
					});

				} else {

					const response = await UtilArmazenamento.aguardarGeracaoJson(conversaoArquivoImportacaoTO.path);
					
					if (response.msgErro) {
						this.exibirAlerta({ msg: response.msgErro });
						return;
					}

					salvamentoImportacaoDocxTO.listElementoDaProvaTO = response;

					await this.exibirPreVisualizacaoQuestoes({salvamentoImportacaoDocxTO, callback, secaoQuestaoTO}, codProva);
				}

				div.remove();
			}
		});

		this.addTexto(`
			<div class="pull-right">
				<em>
					${this.getMsg("FP_FRONT_ImportacaoProvaVH_084")} 
					<a target="_blank" href="${dadosNovaImportacaoProvaTO.urlAbertaExemploImportacaoQuestoesDocx}">
						${this.getMsg("FP_FRONT_ImportacaoProvaVH_085")}
					</a>
					${this.getMsg("FP_FRONT_ImportacaoProvaVH_086")} 
					<a target="_blank" href="${dadosNovaImportacaoProvaTO.urlAbertaExemploImportacaoQuestoesXlsx}">
						${this.getMsg("FP_FRONT_ImportacaoProvaVH_087")}
					</a>.
				</em>
			</div>
		`);
		
		this.exibir();
	}

	async fazerUploadImagensExternas(salvamentoImportacaoDocxTO) {
		const div = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_ImportacaoProvaVH_060"));

		for (const elementoDaProvaTO of salvamentoImportacaoDocxTO.listElementoDaProvaTO) {

			if (elementoDaProvaTO.comentarios) {
				elementoDaProvaTO.comentarios = await UtilHtml.enviarImagensParaArmazenamento(elementoDaProvaTO.comentarios);
			}

			if (elementoDaProvaTO.conteudoDoTexto) {
				elementoDaProvaTO.conteudoDoTexto = await UtilHtml.enviarImagensParaArmazenamento(elementoDaProvaTO.conteudoDoTexto);
			}

			if (elementoDaProvaTO.conteudoDaQuestao) {
				elementoDaProvaTO.conteudoDaQuestao = await UtilHtml.enviarImagensParaArmazenamento(elementoDaProvaTO.conteudoDaQuestao);
			}

			if (elementoDaProvaTO.listaItemTO) {
				for (const itemTO of elementoDaProvaTO.listaItemTO) {
					if (itemTO.conteudoDoItem) {
						itemTO.conteudoDoItem = await UtilHtml.enviarImagensParaArmazenamento(itemTO.conteudoDoItem);
					}
				}
			}
		}

		div.remove();

		return salvamentoImportacaoDocxTO;
	}

	async exibirPreVisualizacaoQuestoes({salvamentoImportacaoDocxTO, callback, secaoQuestaoTO}, codProva?: number) {

		this.limpar(true);

		this.append(`
			<div class="col-md-12" id="preVisualizacaoQuestoes">
				<div class="row">
		`);

		this.addSubtitulo(`
			${this.getMsg("FP_FRONT_ImportacaoProvaVH_038")} 
			<strong elemento-da-prova titulo-prova>${salvamentoImportacaoDocxTO.tituloProva}</strong>
		`);

		this.append(`
			<div questoes class="col-md-12">
				<div class="row">
		`);

		let listaSecoesProva = [];
		if(codProva){
			listaSecoesProva = await this.call("ListagemSelecaoFCD/listarSecoes", codProva);
		}

		let listElementoDaProvaTO =  salvamentoImportacaoDocxTO.listElementoDaProvaTO;

		const { possuiClassificacoes } = await this.exibirElementosDaProva({
			listElementoDaProvaTO: listElementoDaProvaTO, 
			secaoQuestaoTO: secaoQuestaoTO, 
			listaSecoesProva: listaSecoesProva
		});

		this.addSubtitulo(this.getMsg("FP_FRONT_ImportacaoProvaVH_049"));
		this.append(`
			<div class="row">
				<div class='col-md-6'>
		`);
		this.addFormulario();
		
		if (this.getIsAmbienteSegmentado() && callback == null) {
			await this.addSelect({
				id: "codEmpresa",
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_050"),
				classe: "col-md-12",
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarSegmentos",
					numMinimoCaracteres: 0,
				},
			});
		}

		await this.addSelect({
			id: "codsDisciplinas",
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_054"),
			classe: "col-md-12",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarDisciplinasTags",
				payload: {
					buscarSomentePermitidas: true,
				},
				numMinimoCaracteres: 3,
			}
		});

		if(!secaoQuestaoTO && listaSecoesProva.length > 0){
			await this.addSelect({
				id: "secaoTodasQuestoes",
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_067"),
				classe: "col-md-12",
				collection: listaSecoesProva,
				onChange: async () => {
					const secaoSelecionada = this.getValor("secaoTodasQuestoes");
					for(let elementoProva of listElementoDaProvaTO){
						this.setValor(`codsSecaoQuestao_${elementoProva.id}`, secaoSelecionada);
					}
				}
			});
		}

		this.append("</div>");
		this.fecharFormulario();
		this.append("<div class='col-md-6'>");
		this.addFormulario();

		if (this.getIsAmbienteSegmentado() && callback == null) {
			this.addRadioGroup({
				id: "visibilidadeQuestoes",
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_051"),
				valor: "ADM",
				obrigatorio: true,
				classe: "col-md-12",
				collection: [
					{ id: "ADM", descricao: this.getMsg("FP_FRONT_ImportacaoProvaVH_052") },
					{ id: "ALUNO", descricao: this.getMsg("FP_FRONT_ImportacaoProvaVH_053") }]
			});
		}

		this.addCheckbox({
			id: "isDesconsiderarClassificacoesInexistentes",
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_068"),
			valor: false,
			obrigatorio: true,
			visivel: possuiClassificacoes,
			classe: "col-md-12"
		});

		this.addCheckbox({
			id: "isDesconsiderarQuestoesSemClassificacoesObrigatorias",
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_065"),
			valor: true,
			visivel: possuiClassificacoes,
			classe: "col-md-12"
		});

		this.append("</div>");
		this.append("</div>");
		this.append("</div>");
		
		this.addGrupoBotoes({
			classe: "pull-right",
			botoes: [{
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_055"), 
				onClick: async (event: any) => {
					await this.exibirTelaImportacaoQuestoes();
				}
			}, {
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_056"),
				classe: "btn-primary",
				id: "btn-importar-questoes",
				onClick: async (event: any) => {
					const btnDisabled = $(event.target).attr("disabled");
					if ($(event.target).data('aguardando') || btnDisabled === 'disabled' ) {
						return;
					}
					
					$(event.target).removeClass("btn-primary").addClass("btn-warning").text(this.getMsg("FP_FRONT_ImportacaoProvaVH_066"));
					$(event.target).attr('disabled', true);

					salvamentoImportacaoDocxTO.tituloProva = $("[elemento-da-prova][titulo-prova]").text();
					salvamentoImportacaoDocxTO.codsDisciplinas = this.getValor("codsDisciplinas");
					salvamentoImportacaoDocxTO.isDesconsiderarClassificacoesInexistentes = this.getValor("isDesconsiderarClassificacoesInexistentes");
					salvamentoImportacaoDocxTO.isDesconsiderarQuestoesSemClassificacoesObrigatorias = this.getValor("isDesconsiderarQuestoesSemClassificacoesObrigatorias");

					{
						// SÓ PARA PERMITIR PERSONALIZAÇÕES PARA CLIENTE
						// NÃO APARECE NA TELA MAS OS CustomVH PODEM INSERIR ESSES VALORES
						salvamentoImportacaoDocxTO.porcentagemPontuacaoMinima = this.getValor("porcentagemPontuacaoMinima");
						salvamentoImportacaoDocxTO.notaMaxima = this.getValor("notaMaxima");
					}

					try {
						this.verificarObrigatorios()
					} catch (e){
						$(event.target).removeClass("btn-warning").addClass("btn-primary ").text(this.getMsg("FP_FRONT_ImportacaoProvaVH_056"));
						$(event.target).removeAttr('disabled');

						return;
					}

					if (this.getIsAmbienteSegmentado() || (amaisVH.isCfgHabilitada('PERMITIR_CADASTRO_DE_CONTEUDO_AMBIENTE_SUPERIORES') && this.getValor("codEmpresa"))) {
						salvamentoImportacaoDocxTO.codEmpresa = this.getValor("codEmpresa");

						if (this.getValor("visibilidadeQuestoes") == "ALUNO") {
							if (await this.confirmar(this.getMsg("FP_FRONT_ImportacaoProvaVH_057"))) {
								salvamentoImportacaoDocxTO.isQuestoesLiberadasParaEstudo = true;
							} else {
								return;
							}
						}
					}

					for (const elementoDaProvaTO of listElementoDaProvaTO) {

						const codSecaoQuestao = this.getValor(`codsSecaoQuestao_${elementoDaProvaTO.id}`);
						if(codSecaoQuestao){
							elementoDaProvaTO.codSecao = codSecaoQuestao;
						} else {
							elementoDaProvaTO.codSecao = null;
						}

						if (elementoDaProvaTO.nomeSecao != null) {
							let $elementoDaProva = $("[elemento-da-prova='" + elementoDaProvaTO.id + "']");
							elementoDaProvaTO.nomeSecao = $elementoDaProva.text();

						} else if (elementoDaProvaTO.conteudoDoTexto != null) {
							let $elementoDaProva = $("[elemento-da-prova='" + elementoDaProvaTO.id + "']");
							elementoDaProvaTO.conteudoDoTexto = $elementoDaProva.html();

						} else {

							elementoDaProvaTO.conteudoDaQuestao = elementoDaProvaTO.conteudoDaQuestao != null ?
								$("[elemento-da-prova='" + elementoDaProvaTO.id + "'][enunciado]").html(): null;

							if (elementoDaProvaTO.listaItemTO) {
								for (const itemTO of elementoDaProvaTO.listaItemTO) {
									itemTO.conteudoDoItem = $("[elemento-item-da-prova='" + itemTO.id + "']").html();
								}
							}

							if (this.hasValue(elementoDaProvaTO.comentarios)) {
								elementoDaProvaTO.comentarios = $("[elemento-da-prova='" + elementoDaProvaTO.id + "'][comentario]").html();
							}

							if (this.hasValue(elementoDaProvaTO.comentariosPrivados)) {
								elementoDaProvaTO.comentariosPrivados = $("[elemento-da-prova='" + elementoDaProvaTO.id + "'][comentariosPrivados]").html();
							}
						}
					}

					try {
						await this.fazerUploadImagensExternas(salvamentoImportacaoDocxTO);
					} catch (e) {
						console.error("Erro ao tentar fazer upload das imagens na importação de provas", e);
					}

					if (callback) {
						callback(salvamentoImportacaoDocxTO);

					} else if (salvamentoImportacaoDocxTO.isDesconsiderarQuestoesSemClassificacoesObrigatorias) {
						
						let isClassificacoesObrigatoriasPreenchidas = true;

						for(let elementoProva of salvamentoImportacaoDocxTO.listElementoDaProvaTO) {
							isClassificacoesObrigatoriasPreenchidas = await this.isClassificacoesObrigatoriasPreenchidas(elementoProva.id, salvamentoImportacaoDocxTO.listElementoDaProvaTO);
							if(!isClassificacoesObrigatoriasPreenchidas) break;
						}

						if (isClassificacoesObrigatoriasPreenchidas) {
							await this.seguirFluxoImportacao(salvamentoImportacaoDocxTO, secaoQuestaoTO);

						} else {
							await this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_075"),
								botoes: [{
									label: this.getMsg("FP_FRONT_ImportacaoProvaVH_076"),
									onClick: () => {
										$(event.target).removeClass("btn-warning").addClass("btn-primary ").text(this.getMsg("FP_FRONT_ImportacaoProvaVH_056"));
										$(event.target).removeAttr('disabled');
										return;
									}
								}, {
									label: this.getMsg("FP_FRONT_ImportacaoProvaVH_077"),
									classe: 'btn-primary',
									onClick: async () => {
										await this.seguirFluxoImportacao(salvamentoImportacaoDocxTO, secaoQuestaoTO);
									}
								}],
							});
							$(event.target).removeClass("btn-warning").addClass("btn-primary ").text(this.getMsg("FP_FRONT_ImportacaoProvaVH_056"));
							$(event.target).removeAttr('disabled');
						}

					} else {
						await this.seguirFluxoImportacao(salvamentoImportacaoDocxTO, secaoQuestaoTO);
					}
				}
			}]
		});

		this.append("</div>");
		this.append("</div>");

		this.exibir();
		UtilWindow.scrollTo("#preVisualizacaoQuestoes");
		$("[elemento-da-prova], [elemento-item-da-prova]").attr("contenteditable", "true");
		this.ativarBtnDeleteClassificacao(listElementoDaProvaTO);
		$(".excluir-questao-importacao").off("click").on("click", ({ target }) => {
			const $target = $(target);
			const idElementoProva = Number($target.closest("a").attr("excluir-elemento-da-prova"));
			const $e = $target.closest(`[linha-elemento-prova="${idElementoProva}"]`);
			$e.slideUp(() => $e.remove());
			$(".tooltip").remove();
			salvamentoImportacaoDocxTO.listElementoDaProvaTO = salvamentoImportacaoDocxTO.listElementoDaProvaTO.filter((el: any) => {
				return el.id !== idElementoProva;
			});
		});

	}

	async seguirFluxoImportacao(salvamentoImportacaoDocxTO: any, secaoQuestaoTO: any){
		const codProva = await this.call("ImportacaoProvaFCD/importarQuestoesDeDocx", salvamentoImportacaoDocxTO);
		this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_058", salvamentoImportacaoDocxTO.tituloProva),
			botoes: [{
				label: this.getMsg("FP_FRONT_ImportacaoProvaVH_059"),
				onClick: () => {
					if(secaoQuestaoTO){
						cadastroProvaVH.exibirAbasProva(1, codProva);
					} else {
						cadastroProvaVH.exibirAbasProva(2, codProva);
					}
				}
			}]
		});
	}

	async exibirElementosDaProva({listElementoDaProvaTO, secaoQuestaoTO, listaSecoesProva}) {

		let j: any;
		let numQuestao = 0;
		let possuiClassificacoes = true;

		this.append(`
			<table style="text-align=left;" class="table-fixed">
				<thead>
					<tr>
						<th style="width: 75%"></th>
						<th style="width: 25%">${this.getMsg("FP_FRONT_ImportacaoProvaVH_039")}</th>
					</tr>
				</thead>
				<tbody>
		`);

		const itemsRemover = [];

		for (let elementoDaProvaTO of listElementoDaProvaTO) {

			this.append(`
				<tr linha-elemento-prova="${elementoDaProvaTO.id}">
					<td width="75%" style="text-align: left; padding-right: 20px">
			`);
			
			this.addFormulario();

			if (elementoDaProvaTO.nomeSecao != null) {

				if(listaSecoesProva && listaSecoesProva.length > 0){
					itemsRemover.push(elementoDaProvaTO);
				} else {
					this.append("<div elemento-da-prova='" + elementoDaProvaTO.id + "' nome-secao class='col-sm-12' style='font-size: 120%; background-color: black; color: white; text-align: center; font-weigth: bold; padding: 5px 0; text-transform: uppercase'>" + elementoDaProvaTO.nomeSecao + "</div>");
					this.addEspacamentoHorizontal("10px");
				}

			} else if (elementoDaProvaTO.conteudoDoTexto != null) {
				
				this.append("<div class='col-sm-12'>");
				this.append("<div conteudo>");
				this.append("<span class='label label-warning' style='margin-bottom: 10px'>");
				this.append(elementoDaProvaTO.isInstrucoesDeProva ? this.getMsg("FP_FRONT_ImportacaoProvaVH_040") : this.getMsg("FP_FRONT_ImportacaoProvaVH_041"));
				this.append("</span><br><br>");
				this.append("<span elemento-da-prova='" + elementoDaProvaTO.id + "' " + (elementoDaProvaTO.isInstrucoesDeProva ? "instrucoes" : "texto") + ">" + elementoDaProvaTO.conteudoDoTexto + "</span>");
				this.append("</div></div>");
				this.addEspacamentoHorizontal("10px");

			} else if (elementoDaProvaTO.tipoQuestao != null) {

				if(listaSecoesProva.length > 0){
					elementoDaProvaTO.nomeSecao = null;
					await this.addSelect({
						id: "codsSecaoQuestao_" + elementoDaProvaTO.id,
						collection: listaSecoesProva,
						label: this.getMsg("FP_FRONT_ImportacaoProvaVH_069"),
						classe: "col-md-12 secao-questao-importacao",
						habilitado: !secaoQuestaoTO,
						valor: secaoQuestaoTO ? secaoQuestaoTO.codSecao: null
					});
				}

				this.append(`
					<div questao ${elementoDaProvaTO.tipoQuestao} class="col-md-12">
						<div exibicao class="row">
				`);

				if (this.hasValue(elementoDaProvaTO.listaClassificacaoTO)) {
					possuiClassificacoes = true;
					this.append(`
						<div class="col-md-12" disciplinas cod-elemento="${elementoDaProvaTO.id}" style="display: contents">
							${elementoDaProvaTO.listaClassificacaoTO.map((classificacaoTO: any) => `
								<a class="badge" style="margin-bottom: 12px" cod-disciplina="${classificacaoTO.codClassificacao}" title="${this.getMsg("FP_FRONT_ImportacaoProvaVH_073")}">
									<span class="label-tipo-classificacao">
										${classificacaoTO.nomeCategoria} &nbsp;
									</span>
									${classificacaoTO.nome}
									<i class="fa fa-trash"></i>
								</a>
							`).join("")}
						</div>
					`);
				} else {
					this.append(`
						<div class='col-md-12' disciplinas cod-elemento="${elementoDaProvaTO.id}" style='display: contents'></div>
					`);
				}

				this.addEspacamentoHorizontal("10px");

				this.append(`
					<div div-enunciado class="col-md-12">
				`);

				if (elementoDaProvaTO.tipoQuestao != "CE") {
					numQuestao++;
					this.append(`
						<div num-questao class='numerador'>
							${numQuestao}
						</div>
					`);
				}

				this.append("<div elemento-da-prova='" + elementoDaProvaTO.id + "' enunciado>" + (elementoDaProvaTO.conteudoDaQuestao || "") + "</div>");

				this.append(`
					<div class="botao-exclusao">
						<a excluir-elemento-da-prova="${elementoDaProvaTO.id}"
							class="btn btn-default btn-sm hover-tr excluir-questao-importacao" 
							title="${this.getMsg("FP_FRONT_ImportacaoProvaVH_072")}">
							
							<i class="fa fa-trash-o"></i>
						</a>
					</div>
				`);

				this.append("</div>");

				for (j = 0; j < elementoDaProvaTO.listaItemTO.length; j++) {

					let itemTO = elementoDaProvaTO.listaItemTO[j];

					this.append("<div class='col-md-12' item>");

					if (elementoDaProvaTO.tipoQuestao == "CE") {
						numQuestao++;
						const classeC = (itemTO.isRespostaCerta ? "btn-success" : "btn-default");
						const classeE = (itemTO.isRespostaCerta ? "btn-default" : "btn-success");
						this.append(`
							<span opcoes>
								<button opcao class="btn ${classeC}" title="Certo" disabled>C</button>
								<button opcao class="btn ${classeE}" title="Errado" disabled>E</button>
							</span>
							<span class="numerador" numerador="${numQuestao}">${numQuestao}</span>
						`);
					} else {
						this.append("<button class='btn " + (itemTO.isRespostaCerta ? "btn-success" : "btn-default") + "' disabled opcao>" + this.letras[j] + "</button>");
					}

					this.append("<div elemento-item-da-prova='" + itemTO.id + "' conteudo " + (elementoDaProvaTO.tipoQuestao == "ME" && itemTO.isRespostaCerta ? " certa" : "") + ">");
					this.append(itemTO.conteudoDoItem);
					this.append("</div>");
					
					this.append("</div>");
				}

				if (this.hasValue(elementoDaProvaTO.listaComentarioTO)) {
					this.append(`
						<div class="correcao_comentarios">
					`);
					for (const comentarioTO of elementoDaProvaTO.listaComentarioTO) {
						const labelComentario = comentarioTO.isPublico ? this.getMsg("FP_FRONT_ImportacaoProvaVH_061"): this.getMsg("FP_FRONT_ImportacaoProvaVH_062");
						this.addEspacamentoHorizontal("10px");
						this.append(`
							<div class="comentario comentario-${comentarioTO.isPublico ? 'publico': 'privado'}">
								<div class="comentario-infos">
									<div class="col-md-12">
										<span>
											<small>${comentarioTO.nome ? comentarioTO.nome : labelComentario}</small>
										</span>
									</div>
									<div class="col-md-12" elemento-da-prova='${elementoDaProvaTO.id}'>${comentarioTO.conteudo}</div>
								</div>
							</div>
						`);
					}
					this.append(`
						</div>
					`);
				}

				this.append("</div>");
				this.append("</div>");

				this.addEspacamentoHorizontal("10px");
			}

			this.fecharFormulario();
			this.append(`
				</td>
				<td class='importacao-questoes-infos-alertas'>
			`);

			if (elementoDaProvaTO.tipoQuestao == "DC") {
				this.append(`<p questao style='border: none'><strong>${this.getMsg("FP_FRONT_ImportacaoProvaVH_042")}</strong>`);
				this.append(elementoDaProvaTO.tipoEspacoDiscursiva == null || elementoDaProvaTO.tipoEspacoDiscursiva == this.getMsg("FP_FRONT_ImportacaoProvaVH_043") ? this.getMsg("FP_FRONT_ImportacaoProvaVH_044") : "");
				this.append(elementoDaProvaTO.tipoEspacoDiscursiva == this.getMsg("FP_FRONT_ImportacaoProvaVH_045") ? this.getMsg("FP_FRONT_ImportacaoProvaVH_046") : "");
				this.append(elementoDaProvaTO.numLinhas ? this.getMsg("FP_FRONT_ImportacaoProvaVH_047", elementoDaProvaTO.numLinhas) : "");
				this.append(elementoDaProvaTO.minNumLinhas ? this.getMsg("e mínimo de {0} linhas", elementoDaProvaTO.minNumLinhas) : "");
				this.append("</p>");
			}

			if (elementoDaProvaTO.tipoQuestao == "EX") {
				this.append(`<p questao style='border: none'><strong>${this.getMsg("FP_FRONT_ImportacaoProvaVH_063")}</strong>`);
				this.append(elementoDaProvaTO.gabaritoRespostaExata ? this.getMsg("FP_FRONT_ImportacaoProvaVH_064", elementoDaProvaTO.gabaritoRespostaExata) : "");
				this.append("</p>");
			}

			if (elementoDaProvaTO.grauDificuldade && elementoDaProvaTO.nomeDificuldade) {
				this.append(`
					<p questao style='border: none'>
						${this.getMsg("FP_FRONT_ImportacaoProvaVH_048")} 
						<strong dificuldade>${elementoDaProvaTO.nomeDificuldade}</strong>
					</p>
				`);
			}

			if (elementoDaProvaTO.alertas.length > 0) {
				this.append('<ul class="fa-ul">');
				for (j = 0; j < elementoDaProvaTO.alertas.length; j++) {
					const alerta = elementoDaProvaTO.alertas[j];
					this.append(`
						<li class="text-danger" id="container-alerta-clo-${elementoDaProvaTO.id}">
							<i class="fa-li fa fa-circle icon-alerta-clo"></i>${alerta}
						</li>`);
				}
				this.append('</ul>');
			}

			const idElementoDaProva = elementoDaProvaTO.id;

			this.append('<div class="container-importacao-questoes-tco">');

			if (elementoDaProvaTO.listaTipoClassificacaoTOObrigatorios?.length > 0) {
				for (let tipoClassificacaoTOObrigatorio of elementoDaProvaTO.listaTipoClassificacaoTOObrigatorios) {
					const nome = exibicaoQuestaoVH.exibirNomeClassificacao({
						nomeTipoClassificacao: tipoClassificacaoTOObrigatorio.nome, 
						descricao: ""
					});
					this.addBotao({
						id: `botao_tco_${tipoClassificacaoTOObrigatorio.codTipoClassificacao}`,
						label: `<i class='fa fa-plus-circle'></i> ${nome}`,
						classe: "btn btn-default btn-sm",
						onClick: async () => await this.adicionarClassificacaoElemento(idElementoDaProva, listElementoDaProvaTO, tipoClassificacaoTOObrigatorio.categoria)
					});
				}
				this.addBotao({
					id: `botao_classificacao_geral`,
					label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroQuestaoVH_081")}`,
					classe: "btn btn-default btn-sm",
					onClick: async () => await this.adicionarClassificacaoElemento(idElementoDaProva, listElementoDaProvaTO, null)
				});
			}

			this.append("</div></td></tr>");
		}

		listElementoDaProvaTO = listElementoDaProvaTO.filter((item: any) => !itemsRemover.includes(item));
		this.append("</tbody>");
		this.append("</table>");

		return { possuiClassificacoes };
	}

    async isClassificacoesObrigatoriasPreenchidas(idElementoDaProva: any, listElementoDaProvaTO: any){
		const elementoDaProvaTO = listElementoDaProvaTO.find(eTO => eTO.id === idElementoDaProva);
		
		if (!elementoDaProvaTO?.listaTipoClassificacaoTOObrigatorios) return true;

		const codsTiposClassificacaoObrigatorios = elementoDaProvaTO.listaTipoClassificacaoTOObrigatorios.map(tcTO => tcTO.codTipoClassificacao);
		const codsTiposClassificacao = elementoDaProvaTO.listaClassificacaoTO.map(cTO => cTO.codTipoClassificacao);
		
		return codsTiposClassificacaoObrigatorios.every(cod => codsTiposClassificacao.includes(cod));
	}

    async verificarClassificacoesObrigatorias(idElementoDaProva: any, listElementoDaProvaTO: any){
		const todasClassificacoesPreenchidas = await this.isClassificacoesObrigatoriasPreenchidas(idElementoDaProva, listElementoDaProvaTO);
		const alertaElement = $(`#alerta_classificacoes_obrigatorias_${idElementoDaProva}`);
		if(alertaElement){
			const containerAlerta = $(`#container-alerta-clo-${idElementoDaProva}`);
			const alertaIcon = containerAlerta.children('.icon-alerta-clo');
			if(todasClassificacoesPreenchidas){
				if(containerAlerta.children(".label-info-co-preenchidas").length === 0){
					containerAlerta.append(`
					<span class='label label-success label-info-co-preenchidas'>
						<i class="fa fa-check" aria-hidden="true"></i>
						${this.getMsg("FP_FRONT_ImportacaoProvaVH_074")}
					</span>`);
				}
				alertaIcon.hide();
				alertaElement.hide();
			} else {
				containerAlerta.children('.label-success').remove();
				alertaIcon.show();
				alertaElement.show();
			}
		}
	}

	async adicionarClassificacaoElemento(idElementoDaProva: any, listElementoDaProvaTO: any, categoria: any){
		await cadastroQuestaoVH.mostrarOpcoesDeDisciplinas(
			[],
			null,
			categoria, 
			async (codDisciplina: any, codsQuestoes: any, exibicaoDisciplinaTO: any) => {

				listElementoDaProvaTO.find((e: any, i: number) => {

					if (e.id === idElementoDaProva) {

						if (e.listaClassificacaoTO === null || !e.listaClassificacaoTO) {
							e['listaClassificacaoTO'] = [];
						}

						const classificacaoIdx = e.listaClassificacaoTO.findLastIndex((c: any) => {
							return c.codClassificacao === exibicaoDisciplinaTO.codDisciplina;
						});

						if (classificacaoIdx > -1) {
							this.exibirAlerta({
								titulo: this.getMsg("FP_FRONT_ImportacaoProvaVH_078"),
								msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_079")
							});
							return true;
						} else {
							e.listaClassificacaoTO.push({
								codClassificacao: exibicaoDisciplinaTO.codDisciplina,
								categoria: exibicaoDisciplinaTO.categoria,
								codTipoClassificacao: exibicaoDisciplinaTO.codTipoClassificacao
							});

							this.setIdTarget(`[disciplinas][cod-elemento="${idElementoDaProva}"]`);
							this.append(`
								<a class='badge' style='margin-bottom: 12px' cod-disciplina="${exibicaoDisciplinaTO.codDisciplina}">
									${exibicaoQuestaoVH.exibirNomeClassificacao(exibicaoDisciplinaTO)}
									
									<i class='fa fa-trash' style='visibility: unset;'></i>
								</a>
							`);
							this.exibir();
							this.setIdTarget(null);
						}

						return true;
					}
				});

				await this.verificarClassificacoesObrigatorias(idElementoDaProva, listElementoDaProvaTO);

				this.ativarBtnDeleteClassificacao(listElementoDaProvaTO);
			}
		);
	}

	ativarBtnDeleteClassificacao(listElementoDaProvaTO: any){
		$("[disciplinas]").find("a").off("click").on("click", async ({target}) => {
			let element = $(target).closest("a");
			let elementDisciplinas = element.closest("[disciplinas]");
			let codDisciplina = +element.attr("cod-disciplina");
			let idElementoDaProva = +elementDisciplinas.attr("cod-elemento");

			listElementoDaProvaTO.find((e: any, i: number) => {
				if (e.id === idElementoDaProva) {
					const classificacaoIdx = e.listaClassificacaoTO.findLastIndex((c: any) => {
						return c.codClassificacao === codDisciplina;
					});
					if(classificacaoIdx > -1) {
						e.listaClassificacaoTO = e.listaClassificacaoTO.filter((c: any, index: any) => {
							return index !== classificacaoIdx;
						})
					}

					return true;
				}
			});

			element.remove();
			$(".tooltip").remove();

			await this.verificarClassificacoesObrigatorias(idElementoDaProva, listElementoDaProvaTO);
		});
	}

	async exibirColunasPlanilha({ linhas, salvamentoImportacaoDocxTO, listaListaTOTiposColunas, callback }) {

		this.limpar(true);

		this.addSubtitulo(`Planilha <strong elemento-da-prova titulo-prova>${salvamentoImportacaoDocxTO.tituloProva}</strong>`);

		this.addTexto(`Para importar a planilha, informe os tipos das colunas abaixo:`);

		let numColunas = 0;
		let numLinhas = 0;

		if (linhas != null && linhas.length > 0 && linhas[0].length > 0) {
			numColunas = linhas[0].length;
			numLinhas = linhas.length;
		} else {
			await this.exibirAlerta({ msg: this.getMsg("MSG_VH_CU_152") });
			return;
		}

		this.append("<table id='tabelaQuestoes' class='table table-hover table-condensed importacao importacao-questoes-xlsx'>");

		this.append("<thead>");
		this.append("<tr>");

		for (let j = 0; j < numColunas; j++) {
			this.append("<td class='linha-select-tipo-coluna'><div class='row'>");
			await this.addSelect({
				id: "tipoColuna" + j,
				propId: "tipoCampoImportacaoPlanilha",
				classe: "col-md-12 select-tipo-coluna",
				dica: this.getMsg("MSG_VH_CU_153"),
				collection: listaListaTOTiposColunas,
			})
			this.append("</div></td>");
		}

		this.append("<td></td></tr>");
		this.append("<tr>");

		for (let j = 0; j < numColunas; j++) {
			this.append(`
				<td>
					<a onclick="amaisVH.removerColunaDeTabela($(this).closest('table'), $(this).parent().index());">
						<i class='fa fa-remove'></i>
					</a>
				</td>
			`);
		}

		this.append("<td></td></tr>");
		this.append("</thead>");
		this.append("<tbody>");

		for (let i = 0; i < numLinhas; i++) {
			this.append("<tr>");

			for (let j = 0; j < numColunas; j++) {
				this.append(`
					<td contenteditable="true">
						${linhas[i][j] != null ? linhas[i][j] : ""}
					</td>
				`);
			}

			this.append(`
				<td>
					<a onclick="$(this).closest('tr').remove()">
						<i class='fa fa-remove'></i>
					</a>
				</td>
			`);

			this.append("</tr>");
		}

		this.append("</tbody>");
		this.append("</table>");

		await this.hide("docxQuestoes");
		this.exibir();

		let tiposPreDefinidos = 0;

		for (let numColuna = 0; numColuna < linhas[0].length; numColuna++) {

			const valorPrimeiraLinha = UtilString.substituirAcentuacao(linhas[0][numColuna]).trim().toLowerCase();
			
			for (let listaTOTipoColuna of listaListaTOTiposColunas) {

				if (UtilString.substituirAcentuacao(listaTOTipoColuna.nome).trim().toLowerCase() == UtilString.substituirAcentuacao(valorPrimeiraLinha).trim().toLowerCase()) {

					this.setValor("tipoColuna" + numColuna, listaTOTipoColuna.id);

					if (this.getValor("tipoColuna" + numColuna) != null) {
						tiposPreDefinidos++;
					}
					
					break;
				}
			}
		}

		if (tiposPreDefinidos == linhas[0].length) {
			try {
				const a: HTMLAnchorElement = document.querySelector("table.importacao tbody tr:nth-child(1) td:last-child a");
				a.click();
			} catch (ignored) { }
		}

		
		this.addSubtitulo(`<span class='text-info'>${this.getMsg("FP_FRONT_ImportacaoProvaVH_049")}</span>`);
		this.append("<div class='row'>");

		this.addCheckbox({
			id: "isSepararCEs",
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_070"),
			valor: false,
			classe: "col-md-6"
		});

		this.append("</div>");

		this.append("<div class='btn-group pull-right'>");

		this.addBotao({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_055"), 
			onClick: async () => {
				await this.exibirTelaImportacaoQuestoes();
			}
		});

		this.addBotao({
			label: this.getMsg("FP_FRONT_ImportacaoProvaVH_071"),
			classe: "btn-primary",
			onClick: async () => {

				if (!this.existeColunaTipoQuestao()) {
					this.addPopup({
						id: "popupMensagemSemColunaTipoQuestao",
						titulo: this.getMsg("FP_FRONT_ImportacaoProvaVH_081"),
						botoes: [{
							label: "Voltar",
							onClick: async () => {
								this.fecharPopup("popupMensagemSemColunaTipoQuestao");
							}
						}]
					})

					this.addTexto(this.getMsg("FP_FRONT_ImportacaoProvaVH_080"));

					this.exibirPopups();
				} else {

					const planilhaTOElementoDaProvaTO = {
						isSepararCEs: this.getValor("isSepararCEs"),
						tiposColunas: [],
						planilha: this.getPlanilha(numColunas)
					}
					$("table thead td select").each((i, select) => {
						planilhaTOElementoDaProvaTO.tiposColunas.push(this.getValor(select));
					});

					if (planilhaTOElementoDaProvaTO.tiposColunas.some(tipo => tipo == null)) {
						await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_082") });
						return false;
					}

					const resultadoImportacaoPlanilhaProvaTO =
						await this.call("ImportacaoProvaFCD/planilhaTOElementoDaProva", planilhaTOElementoDaProvaTO);

					if (resultadoImportacaoPlanilhaProvaTO.listaErroImportacaoTO.length > 0){

						//removendo background vermelho indicando erro na célula
						const table = $("#tabelaQuestoes");
						const tdsToRemove = table.find("tbody td").filter(function() {
							return $(this).css("background-color").startsWith("rgba(255, 0, 0, 0.26)");
						});
						tdsToRemove.css("background-color", "");

						$("#textoDetalhesErrosimportacao").html("");

						table.find("tbody td").on('input', function() {
							$(this).css('background-color', '')});

                        let message = `<div id='textoDetalhesErrosimportacao'><p>${this.getMsg("FP_FRONT_ImportacaoProvaVH_083")}</p><ul>`;

						resultadoImportacaoPlanilhaProvaTO.listaErroImportacaoTO.forEach((error) => {
							message += `<li onclick="importacaoProvaVH.focusElement(${error.numLinhaErro}, ${error.numColunaErro})">[${error.numLinhaErro + 1}:${error.numColunaErro + 1}] ${error.msgErro} <i class="fas fa-chevron-up"></i></li>`;
							const element = table.find(`tbody tr:eq(${error.numLinhaErro}) 
							td:eq(${error.numColunaErro})`);
							element.css("background-color", "#ff000042");
							element.focus();
						});

						message += "</ul></div>";

						table.after(message);

						await this.exibirAlerta({ msg: message });

					} else if (resultadoImportacaoPlanilhaProvaTO.listaResultado != null) {
						salvamentoImportacaoDocxTO.listElementoDaProvaTO = resultadoImportacaoPlanilhaProvaTO.listaResultado;

						await this.exibirPreVisualizacaoQuestoes({
							salvamentoImportacaoDocxTO, 
							callback, 
							secaoQuestaoTO: null
						});
					}
				}

			}
		});

		this.append("</div>");

		this.exibir();
	}

	focusElement(row_index: number, col_index: number): void {
		const table = $("#tabelaQuestoes");
		const element = table.find(`tbody tr:eq(${row_index}) td:eq(${col_index})`);
		console.log("Focando");
		this.fecharTodasPopups();
		element.focus();
	}

	existeColunaTipoQuestao(): boolean {

		let found = false;

		$('#tabelaQuestoes thead select').each(function () {
			if ($(this).val() === "QUESTAO_TIPO") {
				found = true;
				return false;
			}
		});

		return found;
	}

	getPlanilha(numColunas) {
		
		let planilha = [];

		$("table tbody tr").each((i, tr) => {
			let linha = [];

			$(tr).find("td").each((j, td) => {
				if (numColunas != linha.length) {
					linha.push($(td).html().trim());
				}
			});

			planilha.push(linha);
		})

		return planilha;
	}

	inicializarPluginImportacao(CKEDITOR, dadosParaImportacaoTO) {
		if (this.isPluginInicializado) return;
		
		this.isPluginInicializado = true;

		CKEDITOR.plugins.add('importacao_conteudo_prova', {

			init : (editor) => {
				editor.addCommand('limparHtml', {exec : () => this.limparHtml(editor, dadosParaImportacaoTO)});
				editor.addCommand('salvarHtml', {exec : () => this.salvarHtml(editor, dadosParaImportacaoTO)});

				editor.ui.addButton('Limpar', {label : 'Limpar Html', title : 'Limpar o HTML antes de reconhecer seção, questão e item', command : 'limparHtml', toolbar : 'importacao_conteudo_prova'});
				editor.ui.addButton('Salvar', {label : 'Salvar Html', title : 'Salva o Html atual na base', command : 'salvarHtml', toolbar : 'importacao_conteudo_prova'});

				editor.setKeystroke(CKEDITOR.ALT + 115, 'bold');
			},
			
			on: {
				key: function( event ) {

					// Gather all styles
					let styles = [];
					this.getStylesSet( function( defs ) { styles = defs } );
					
					// CTRL+SHIFT+1
					if ( event.data.keyCode == CKEDITOR.CTRL + CKEDITOR.SHIFT + 49 ) {                
						this.fire( 'saveSnapshot' );
						const style = new CKEDITOR.style( styles[ 2 ] );
						const elementPath = this.elementPath();
						
						this[ style.checkActive( elementPath ) ? 'removeStyle' : 'applyStyle' ]( style );
						this.fire( 'saveSnapshot' );
					}        	
				}
			}
		});

		CKEDITOR.plugins.add('importacao_conteudo_secao', {

			init : (editor) => {
				editor.addCommand('separarTexto', {exec : () => this.handleSepararTexto(editor)});
				editor.addCommand('autoQuestao', {exec : async () => await this.onAutoQuestao(editor, dadosParaImportacaoTO)});
				editor.addCommand('limparHtml', {exec : () => this.limparHtml(editor, dadosParaImportacaoTO)});
				editor.addCommand('salvarHtml', {exec : () => this.salvarHtml(editor, dadosParaImportacaoTO)});
				editor.addCommand('autoItem', {exec : () => this.onAutoItem(editor)});

				editor.ui.addButton('Texto', {label : 'Separar texto', title : 'Separa o texto que ficou junto da questão', command : 'separarTexto', toolbar : 'importacao_conteudo_secao'});
				editor.ui.addButton('AutoQuestao', {label : 'Localizar questões', title : 'Localiza automaticamente as questões pela numeração', command : 'autoQuestao', toolbar : 'importacao_conteudo_secao'});
				editor.ui.addButton('Limpar', {label : 'Limpar Html', title : 'Limpar o HTML antes de reconhecer seção, questão e item', command : 'limparHtml', toolbar : 'importacao_conteudo_secao'});
				editor.ui.addButton('Salvar', {label : 'Salvar Html', title : 'Salva o Html atual na base', command : 'salvarHtml', toolbar : 'importacao_conteudo_secao'});
				editor.ui.addButton('AutoItem', {label : 'Localizar itens', title : 'Localiza automaticamente os itens da questão', command : 'autoItem', toolbar : 'importacao_conteudo_secao'});

				editor.setKeystroke(CKEDITOR.ALT + 115, 'bold');
			},
			
			on: {
				key: function( event ) {

					// Gather all styles
					let styles = [];
					this.getStylesSet( function( defs ) { styles = defs } );
					
					// CTRL+SHIFT+1
					if ( event.data.keyCode == CKEDITOR.CTRL + CKEDITOR.SHIFT + 49 ) {                
						this.fire( 'saveSnapshot' );
						const style = new CKEDITOR.style( styles[ 2 ] );
						const elementPath = this.elementPath();
						
						this[ style.checkActive( elementPath ) ? 'removeStyle' : 'applyStyle' ]( style );
						this.fire( 'saveSnapshot' );
					}        	
				}
			}
		});
	}

	handleSepararTexto(editor) {
		let range = editor.getSelection().getRanges()[0];
		let textNodeDaQuestao = range.startContainer;
		let textNodeDoTexto = textNodeDaQuestao.split(range.startOffset);

		let node = textNodeDoTexto;

		let htmlDoTexto = ["<texto>", (node.getOuterHtml ? node.getOuterHtml() : node.getText())];
		let nodesParaRemocao = [node];

		while (node.hasNext()) {
			node = node.getNext();

			if (node.getOuterHtml != null) {
				htmlDoTexto.push(node.getOuterHtml());
			} else {
				htmlDoTexto.push(node.getText());
			}

			nodesParaRemocao.push(node);
		}

		$(textNodeDaQuestao.$).closest("questao").after(htmlDoTexto.join("") + "</texto>");

		for (let n of nodesParaRemocao) {
			n.remove();
		}
	}

	async salvarHtml(editor, dadosParaImportacaoTO) {
		let html = null;

		if ($("#conteudoProva").length > 0) {
			try {
				const CKEDITOR = UtilBoot.getCKEditor();
				html = CKEDITOR.instances["conteudoProva"].getData();
			} catch (e) {
				html = $("#conteudoProva").html();
			}

		} else {
			let htmlArray = [];

			$("div.secao").each(function() {
				try {
					const CKEDITOR = UtilBoot.getCKEditor();
					htmlArray.push(CKEDITOR.instances[this.id].getData());
				} catch (e) {
					htmlArray.push($(this).html());
				}
			})

			html = htmlArray.join("");
		}
		html = UtilHtml.decodificarHtml(html);
		await this.call("ImportacaoProvaFCD/salvarHtml", dadosParaImportacaoTO.codImportacao, html);
		this.mostrarMsgAjax(this.getMsg("FP_FRONT_ImportacaoProvaVH_003"), 1);
	}

	async limparHtml(editor, dadosParaImportacaoTO) {
		let html = editor.getData();
		html = UtilHtml.decodificarHtml(html);
		html = await this.call("ImportacaoProvaFCD/limparHtml", dadosParaImportacaoTO.codImportacao, html);
		editor.setData(html);
	}

	async onAutoQuestao(editor, dadosParaImportacaoTO) {

		let ultimoNumerador: number = 0;
		const panelSecaoAnterior = $("#" + editor.name).closest(".panel").prev();

		if (panelSecaoAnterior.length == 0) {
			if (dadosParaImportacaoTO.numeracaoInicial != null) {
				ultimoNumerador = dadosParaImportacaoTO.numeracaoInicial - 1;
			}
		} else {
			let ultimoNumeradorSecaoAnterior = $(panelSecaoAnterior).find("secao").attr("ultimo-numerador");

			if (ultimoNumeradorSecaoAnterior == null) {
				await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_001") });
				return;

			} else if ($(panelSecaoAnterior).find(".panel-heading input:checked").length > 0 && $("#" + editor.name).closest(".panel").find(".panel-heading input:checked").length > 0) {
				ultimoNumerador = (Number($(panelSecaoAnterior).find("secao").attr("primeiro-numerador"))).valueOf() - 1;

			} else {
				ultimoNumerador = (Number(ultimoNumeradorSecaoAnterior)).valueOf();
			}
		}

		let primeiroNumerador = ultimoNumerador + 1;

		let tipoDeProva = this.getTiposDeProva()[(Number(this.getValor("tipoDeProva"))).valueOf()];
		let htmlOriginalDaSecao = editor.getData();
		htmlOriginalDaSecao = this.converterAcentos(htmlOriginalDaSecao);
		htmlOriginalDaSecao = UtilHtml.decodificarHtml(htmlOriginalDaSecao);

		let html = $(htmlOriginalDaSecao).html();
		let pattern = tipoDeProva.patterns[0];
		let matches = [];
		let match: any = pattern.exec(html);

		if (match == null && tipoDeProva.patterns.length > 1) {
			pattern = tipoDeProva.patterns[1];
			match = pattern.exec(html);
		}

		if (match == null) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_ImportacaoProvaVH_002") });
			return;
		}

		while (match != null) {
			let numerador = (Number(match[tipoDeProva.grupoNumeracao])).valueOf();

			if (numerador != ultimoNumerador + 1) {
				match = pattern.exec(html);
				continue;
			}

			match.indice = pattern.lastIndex - match[0].length;
			matches.push(match);
			match = pattern.exec(html);
			ultimoNumerador = numerador;
		}

		for (let i = matches.length - 1; i >= 0; i--) {
			let match = matches[i];
			let matchSeguinte = matches[i + 1];

			if (matchSeguinte != null) {
				html = UtilString.inserir(html, matchSeguinte.indice, "</questao>");
				html = UtilString.inserir(html, match.indice + match[0].length, "</numerador>");
				if (i == 0) {
					html = "<texto>" + UtilString.inserir(html, match.indice, "</texto><questao><numerador>");
				} else {
					html = UtilString.inserir(html, match.indice, "<questao><numerador>");
				}
			} else {
				html = html + "</questao>";
				html = UtilString.inserir(html, match.indice + match[0].length, "</numerador>");
				html = UtilString.inserir(html, match.indice, "<questao><numerador>");
			}
		}

		let novoHtmlDaSecao = $($('<div>').append($(htmlOriginalDaSecao))).find("secao").html(html).parent().html();
		editor.setData(novoHtmlDaSecao);

		this.corrigirPosicaoSecoesETextos();

		if (this.getValor("isRemoverQuebrasDeLinha")) {
			$(`#${editor.name} questao br`).replaceWith(" ");
		}

		$(`#${editor.name} texto:empty`).remove();
		$(`#${editor.name} secao`).attr("primeiro-numerador", primeiroNumerador);
		$(`#${editor.name} secao`).attr("ultimo-numerador", ultimoNumerador);

		this.organizarSecoes();
	}

	organizarSecoes() {

		$("div.secao:not(:has(*))").closest(".panel").remove();

		$("secao").each(function() {

			// atualizando o atributo de optativa

			$(this).attr("is-optativa", $(this).closest(".panel").find(".panel-heading input").is(":checked"));

			// transformando o <nome> da seção

			if ($(this).find("nome").length > 0) {
				let nome = $(this).find("nome").text();
				$(this).attr("nome", nome);
				$(this).closest(".panel").find(".panel-heading h4 a").text(nome);
				$(this).find("nome").remove();
			}

			// atualizando os labels da seção

			if ($(this).is("[primeiro-numerador]") && $(this).is("[ultimo-numerador]")) {
				let link = $(this).closest(".panel").find(".panel-heading h4 a");
				let textoNumeracao = $(this).attr("primeiro-numerador") + " a " + $(this).attr("ultimo-numerador");
				if ($(link).find("small").length > 0) {
					$(link).find("small").text(textoNumeracao);
				} else {
					$(link).append(" <small>" + textoNumeracao + "</small>");

				}
			}
		})
	}

	onAutoItem(editor) {
		$(`#${editor.name} questao`).each(function() {

			let questao = this;
			let html = $(this).html();
			html = UtilHtml.decodificarHtml(html);
			let letras = "abcdefg";
			let ultimoIndiceLetra = -1;
			let pattern = /\(?([a-gA-G])\)/g;
			let match: any = pattern.exec(html);
			let matches = [];

			while (match != null) {
				let letra = match[1];
				if (letras.charAt(ultimoIndiceLetra + 1) != letra.toLowerCase()) {
					match = pattern.exec(html);
					continue;
				}

				match.indice = pattern.lastIndex - match[0].length;
				matches.push(match);
				match = pattern.exec(html);
				ultimoIndiceLetra++;
			}

			if (matches.length == 0) {
				ultimoIndiceLetra = 0;
				let numerador = $(questao).find("numerador").text();
				$(questao).find("numerador").remove();
				let htmlItems = [];

				$(questao).find("ol li").each(function() {
					htmlItems.push("<item><letra>" + letras.charAt(ultimoIndiceLetra++) + "</letra>" + $(this).html() + "</item>")
				})

				if (ultimoIndiceLetra > 0) {
					$(questao).find("ol").remove();
				}

				let enunciado = $(questao).html();

				$(questao).append("<numerador>" + numerador + "</numerador><enunciado>" + enunciado + "</enunciado>" + htmlItems);

			} else {
				for (let i = matches.length - 1; i >= 0; i--) {
					let match = matches[i];
					let matchSeguinte = matches[i + 1];

					if (matchSeguinte != null) {
						html = UtilString.inserir(html, matchSeguinte.indice, "</item>");
						html = html.substring(0, match.indice) + match[1] + "</letra>" + html.substring(match.indice + match[0].length);
						if (i == 0) {
							html = "<enunciado>" + UtilString.inserir(html, match.indice, "</enunciado><item><letra>");
						} else {
							html = UtilString.inserir(html, match.indice, "<item><letra>");
						}
					} else {
						html = html + "</item>";
						html = html.substring(0, match.indice) + match[1] + "</letra>" + html.substring(match.indice + match[0].length);
						html = UtilString.inserir(html, match.indice, "<item><letra>");
					}
				}
				$(questao).html(html);
			}
		});
	}
}

const importacaoProvaVH = new ImportacaoProvaVH();
