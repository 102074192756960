class CronoTempoOfflineProvaVH {
    worker: Worker;
    $tempoElement: any;
    milisegundosRestantes: number;
    callback: Function;

    cronoListener: Function = (msDecorridos) => {
        this.handleCronoTick(msDecorridos);
    }

    iniciarCronometro(milisegundosRestantes, $tempoElement, callback: Function) {
        this.pararCronometro();

        this.milisegundosRestantes = milisegundosRestantes;
        this.$tempoElement = $tempoElement;
        this.callback = callback;

        UtilCrono.adicionarListener(this.cronoListener);
    }

    async handleCronoTick(msDecorridos: number) {
        if (msDecorridos == null || msDecorridos < 1) return;

        const duration = moment.duration(this.milisegundosRestantes);
        const tempoFormatado = duration.seconds();
        this.$tempoElement.text(tempoFormatado);

        if (this.isTempoEsgotado()) {
            this.pararCronometro();
            this.callback();
        }

        this.milisegundosRestantes -= msDecorridos;
    }

    isTempoEsgotado(): boolean {
        return this.milisegundosRestantes <= 0;
    }

    pararCronometro() {
        UtilCrono.removerListener(this.cronoListener);
    }
}
