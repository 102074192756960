class CadastroDemandaVH extends AmaisVH {

	codDemandaResponsavelNovo: number;
	isExibirApenasPendentesRevisao: boolean;
	observacoesRevisao = [];

	constructor() {
		super(CadastroDemandaVH.name);
		this.addOperacaoParaHash("cdli", this.listar);
		this.addOperacaoParaHash("cded", this.editar);
		this.addOperacaoParaHash("cdet", this.exibirTrabalho);
	}

	async listar() {

		if(this.getIdPerfilUsuarioLogado() !== 1 && this.isCfgHabilitada("FUNCIONALIDADE_CRIACAO_AUTOMATICA_DEMANDAS") === true){
			const codDemanda = await this.call("CadastroDemandaFCD/criarDemandaAutomatica");
			if(!this.isEmpty(codDemanda)){
				UtilHash.registrarHistorico(this.exibirTrabalho);
				await this.exibirTrabalho(codDemanda);
				return;
			}
		}

		UtilHash.registrarHistorico(this.listar);

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroDemandaVH_001"));

		this.setIdTarget("tituloSuperiorDireito");
		this.append("<div class='btn-group'>");
		if (UtilAuth.possuiAcesso(TipoFuncionalidade.DEMANDA_CRIACAO)) {
			this.addBotao({
				id: "botao_demanda_criacao",
                label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_008")}`,
				classe: "btn-success btn-sm",
				onClick: async () => await cadastroDemandaVH.editar(false)
			});
		}
		if (this.isAdministrador() || UtilAuth.possuiAcesso(TipoFuncionalidade.DEMANDA_AUTO_CRIACAO)) {
			this.addBotao({
				id: "botao_demanda_auto_criacao",
				label: `<i class='fa fa-plus-square'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_103")}`,
				classe: "btn-primary btn-sm",
				onClick: async () => await cadastroDemandaVH.editar(true)
			});
		}
		if (this.isAdministrador() || UtilAuth.possuiAcesso(TipoFuncionalidade.CHECKLIST_CRIACAO)) {
			this.addBotao({
				id: "botao_demanda_checklist",
				label: `<i class='fa fa-table'></i> ${this.getMsg("FP_FRONT_CadastroChecklistVH_001")}`,
				classe: "btn-sm",
				onClick: async () => await cadastroChecklistVH.exibirListagem()
			});
		}
		this.addBotaoBusca("filtrosDemandas", {classe: "btn-sm"});
		this.append("</div>");
		this.setIdTarget(null);

		this.append("<div id='filtrosDemandas' style='display: none'>");
		this.abrirAbaAccordion({
			titulo: this.getMsg("MSG_VH_001"),
			aberta: true
		});

		this.addFormulario();

		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				id: "busca_cods_empresas",
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_098"),
				classe: "col-xs-12 col-md-6 col-lg-4",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarSegmentos",
					numMinimoCaracteres: 0,
				},
			});
		}

		if (this.isAdministrador()) {
			await this.addSelect({
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_005"),
				classe: "col-xs-12 col-md-6 col-lg-4",
				id: "codsUsuariosResponsaveis",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarUsuariosComPermissao",
					payload: {
						tipoFuncionalidade: "DEMANDA_ELABORACAO_QUESTOES",
					},
					numMinimoCaracteres: 3,
				}
			});
		}

		this.addCampoTexto({
			id: "busca_demandas",
			sufixo: "<i class='fa fa-sm fa-search'></i> ",
			label: this.getMsg("MSG_VH_002"),
			valor: "",
			classe: "col-xs-12 col-md-6 col-lg-4",
			onEnter: async () => await this.montarListaDemandas()
		});

		this.addEspacamentoHorizontal();

		this.addCheckbox({
			id: "isExibirApenasPendentesRevisao",
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_125"),
			valor: this.isExibirApenasPendentesRevisao ?? this.isAdministrador(),
			classe: "col-xs-12 col-md-6 col-lg-4"
		});

		this.append("<div class='btn-group col-md-12' style='display: flex;justify-content: flex-end;'>");

		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_099"),
			onClick: async () => await this.montarListaDemandas(),
			classe: "btn-primary"
		});

		this.append("</div>");

		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		this.append("</div>");

		this.append("<div class='col-md-12 row' id='lista-demandas'></div>");
		
		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_007"),
			onClick: () => UtilHash.voltar()
		});
		
		this.exibir();

		await this.montarListaDemandas();
	}

	async montarListaDemandas() {

		const filtroListagemDemandaTO = this.getFiltroListagemDemandaTO();

		if (!filtroListagemDemandaTO) return;
		
		this.setIdTarget("lista-demandas", true);
		const listaDemandaTO = await this.call("CadastroDemandaFCD/listar", filtroListagemDemandaTO);

		let collectionListagemDemandaTO = listaDemandaTO.listagemDemandaTO;

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo : this.getMsg("FP_FRONT_CadastroDemandaVH_002"), prop: "nome", classe: "descricao"});
		colunas.push({titulo : this.getMsg("FP_FRONT_CadastroDemandaVH_003"), prop: this.montarHtmlQuestoesDemanda, nomeProp: "questoesParaElaboracao"});
		colunas.push({titulo : this.getMsg("FP_FRONT_CadastroDemandaVH_004"), prop: "classificacoes"});
		colunas.push({titulo : this.getMsg("FP_FRONT_CadastroDemandaVH_126"), prop: this.montarHtmlQuantidadeQuestoes, nomeProp: "quantidadeQuestoes"});
		colunas.push({titulo : this.getMsg("FP_FRONT_CadastroDemandaVH_005"), prop: this.montarHtmlResponsaveis, nomeProp: "demandaResponsaveis"});
		colunas.push({titulo : this.getMsg("FP_FRONT_CadastroDemandaVH_006"), prop: this.montarHtmlSituacao, nomeProp: "demandaSituacao"});
		colunas.push({titulo : "", prop: this.montarHtmlOperacoes, nomeProp: "demandaOperacao"});

		await this.addTabela({
			id: "listagem_demandas",
			collection: collectionListagemDemandaTO,
			propId: "codDemanda",
			colunas: colunas,
			onEdicao: async (codDemanda) => await this.exibirTrabalho(codDemanda),
			ordenacao: [["nome", 0]],
			paginacao: {
				paginacaoTO: listaDemandaTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					filtroListagemDemandaTO.paginacaoTO = paginacaoTO;
					const listaDemandaTO = await this.call("CadastroDemandaFCD/listar", filtroListagemDemandaTO);
					return listaDemandaTO.listagemDemandaTO;
				}
			},
		});

		this.setIdTarget(null);

		this.exibir();
	}

	getFiltroListagemDemandaTO(): any {

		this.isExibirApenasPendentesRevisao = this.getValor("isExibirApenasPendentesRevisao");

		const filtroListagemDemandaTO = {
			buscaTextual: this.getValor("busca_demandas"),
			codsEmpresas: this.getValor("busca_cods_empresas"),
			codsUsuariosResponsaveis: this.getValor("codsUsuariosResponsaveis"),
			isExibirApenasPendentesRevisao: this.isExibirApenasPendentesRevisao
		}

		if (filtroListagemDemandaTO.buscaTextual?.length < 3) {
			this.exibirAlerta({ msg: UtilMsg.getMsg("FP_FRONT_AmaisVH_047", 3) });

			return null;
		}

		return filtroListagemDemandaTO;
	}

	montarHtmlQuestoesDemanda(listagemDemandaTO) {
		if(listagemDemandaTO.isAutoCriacao){
			return this.getMsg("FP_FRONT_CadastroDemandaVH_105");
		}
		else{
			return UtilProgressBar.basic({
				evolucao: listagemDemandaTO.numQuestoesElaboradas,
				total: listagemDemandaTO.numQuestoesDemanda
			});
		}
	}

	montarHtmlResponsaveis(listagemDemandaTO){
		if(listagemDemandaTO.isAutoCriacao){
			return listagemDemandaTO.nomeUsuarioCriacao;
		}
		else{
			return listagemDemandaTO.responsaveis;
		}
	}

	montarHtmlSituacao(listagemDemandaTO) {

		if (listagemDemandaTO.dataRevisaoEncerrada) {
			return `<i class='fa fa-check' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_015")}'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_009")}`;
		} else if (listagemDemandaTO.isElaboracaoEncerrada) {
			return `${this.getMsg("FP_FRONT_CadastroDemandaVH_011")}<br>${this.getMsg("FP_FRONT_CadastroDemandaVH_010")}`;
		} else if (listagemDemandaTO.isElaboracaoEncerradaParaResponsavel) {
			return this.getMsg("FP_FRONT_CadastroDemandaVH_012");
		} else if (listagemDemandaTO.isElaboracaoIniciada || listagemDemandaTO.isAutoCriacao) {
			return `${this.getMsg("FP_FRONT_CadastroDemandaVH_013")}<br>${this.getMsg("FP_FRONT_CadastroDemandaVH_010")}`;
		} else if (listagemDemandaTO.elaboracaoIniciaEm) {
			return this.getMsg("FP_FRONT_CadastroDemandaVH_014") + UtilData.toDDMMYYYYHHMM(listagemDemandaTO.elaboracaoIniciaEm);
		} else {
			return this.getMsg("FP_FRONT_CadastroDemandaVH_016");
		}
	}

	montarHtmlQuantidadeQuestoes(listagemDemandaTO) {
		return `
			<span class="demanda_qtde_questoes_criadas">
				${listagemDemandaTO.qtdeQuestoesCriadas} ${this.getMsg("FP_FRONT_CadastroDemandaVH_129")}
				<br>
			</span>
			<span class="demanda_qtde_questoes_aprovadas">
				${listagemDemandaTO.qtdeQuestoesAprovadas} ${this.getMsg("FP_FRONT_CadastroDemandaVH_131")}
				<br>
			</span>
			<span class="demanda_qtde_questoes_reprovadas">
				${listagemDemandaTO.qtdeQuestoesReprovadas} ${this.getMsg("FP_FRONT_CadastroDemandaVH_132")}
			<br>
			</span>
			<span class="demanda_qtde_questoes_pendentes">
				${listagemDemandaTO.qtdePendentesRevisao} ${this.getMsg("FP_FRONT_CadastroDemandaVH_130")}
				<br>
			</span>
			<span class="demanda_qtde_questoes_pendente_envio">
				${listagemDemandaTO.qtdePendenteEnvioRevisao} ${this.getMsg("FP_FRONT_CadastroDemandaVH_137")}
			</span>
		`;
	}

	montarHtmlOperacoes(listagemDemandaTO) {
		if (UtilAuth.possuiAcesso(TipoFuncionalidade.DEMANDA_CRIACAO)) {
			return this.addBotao({
				id: "botao_edit_demanda_"+listagemDemandaTO.codDemanda,
				retornarHtml: true,
				label: "<i class='fa fa-pencil'></i>",
				abrirNovaPagina: false,
				href: UtilHash.getHash(cadastroDemandaVH.editar, listagemDemandaTO.isAutoCriacao, listagemDemandaTO.codDemanda)
			});
		}
	}

	async editar(isAutoDemanda = false, codDemanda?) {

		UtilHash.registrarHistorico(this.editar, isAutoDemanda, codDemanda);

		const edicaoDemandaTO = await this.call("CadastroDemandaFCD/editar", codDemanda);
	
		this.limpar();
		this.setTitulo(edicaoDemandaTO.nome || this.getMsg("FP_FRONT_CadastroDemandaVH_017"), this.getMsg("FP_FRONT_CadastroDemandaVH_018"));

		this.addCampoTexto({
			id: "nome",
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_019"),
			valor: edicaoDemandaTO.nome,
			obrigatorio: true,
			classe: "col-md-4"
		});

		if(!isAutoDemanda){
			this.addCampoTexto({
				id: "numQuestoesDemanda",
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_020"),
				tipo: "NUMERO",
				valor: edicaoDemandaTO.numQuestoesDemanda,
				obrigatorio: true,
				classe: "col-md-4"
			});
		}

		await this.addSelect({
			collection: edicaoDemandaTO.listaOpcaoListaTOChecklist,
			id: "codChecklist",
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_021"),
			obrigatorio: !isAutoDemanda,
			classe: "col-md-4",
		});

		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				collection: edicaoDemandaTO.collectionSegmentosTO,
				id: "codEmpresa",
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_098"),
				habilitado: !edicaoDemandaTO.codDemanda,
				valor: edicaoDemandaTO.codEmpresa,
				obrigatorio: !isAutoDemanda,
				classe: "col-md-4"
			});
		}

		if(isAutoDemanda){
			this.addEditorHTML({
				id: "detalhesAutoCriacao",
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_104"),
				valor: edicaoDemandaTO.detalhesAutoCriacao,
				classe: "col-md-12"
			});
		}
		else{
			this.addEditorHTML({
				id: "orientacoes",
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_022"),
				valor: edicaoDemandaTO.orientacoes,
				classe: "col-md-12"
			});
		}
		this.append("<div id='listagem_classificacoes' class='col-md-12'></div>");

		if(!isAutoDemanda){
			this.append("<div id='listagem_responsaveis' class='col-md-12'></div>");
		}

		this.addEspacamentoHorizontal();

		this.addBotao({ label: this.getMsg("FP_FRONT_CadastroDemandaVH_023"), onClick: () => UtilHash.voltar() });

		this.append("<div class='btn-group pull-right'>");
		if (codDemanda) {
			this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_024"),
				classe: "btn-danger",
				onClick: async () => {
					await this.exibirAlerta({
						msg: `<p>${this.getMsg("FP_FRONT_CadastroDemandaVH_027")}</p>`,
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroDemandaVH_025"),
							classe: "btn-danger",
							onClick: async () => {
								await this.call("CadastroDemandaFCD/arquivarDemanda", codDemanda);
								try {
									await this.listar();
								} catch (ignored) {}
							}
						}]
					});
				}
			});
		}
		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_026"),
			classe: "btn-primary",
			onClick: async () => {
				this.verificarObrigatorios();

				let salvamentoDemandaTO = {
					codDemanda: edicaoDemandaTO.codDemanda,
					codEmpresa: this.getValor("codEmpresa"),
					nome: this.getValor("nome"),
					numQuestoesDemanda: this.getValor("numQuestoesDemanda"),
					orientacoes: this.getValor("orientacoes"),
					codChecklist: this.getValor("codChecklist"),
					codsClassificacoes: this.getValor("codsClassificacoes"),
					detalhesAutoCriacao: this.getValor("detalhesAutoCriacao"),
					isAutoCriacao: isAutoDemanda,
					listaSalvamentoDemandaResponsavelTO: edicaoDemandaTO.listaEdicaoDemandaResponsavelTO.map(to => {
						return {
							codDemandaResponsavel: (to.codDemandaResponsavel < 0 ? null : to.codDemandaResponsavel),
							codUsuario: to.codUsuario,
							dataInicio: to.dataInicio,
							dataFim: to.dataFim
						};
					})
				}

				try{
					await this.call("CadastroDemandaFCD/salvar", salvamentoDemandaTO);
					await this.listar();
				} catch (e) {
					this.logger.error(e);
					console.log(e);
				}
			}
		});
		this.append("</div>");
		this.exibir();

		await this.atualizarListagemClassificacoes(edicaoDemandaTO);

		if (!isAutoDemanda) {
			await this.atualizarListagemResponsaveis(edicaoDemandaTO);
		}
	}

	async atualizarListagemClassificacoes(edicaoDemandaTO) {

		this.setHtml("listagem_classificacoes", "");
		this.setIdTarget("listagem_classificacoes");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_029"), prop: "nomeClassificacao", classe: "descricao"});

		await this.addTabela({
			collection: edicaoDemandaTO.listaEdicaoDemandaConteudoTO,
			titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_028"),
			id: "codsClassificacoes",
			propId: "codClassificacao",
			colunas: colunas,
			onNovo: async () => {
				this.addPopup({
					titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_030"),
					botoes: [{
						label: this.getMsg("FP_FRONT_CadastroDemandaVH_031")
					}, {
						label: this.getMsg("FP_FRONT_CadastroDemandaVH_032"),
						classe: "btn-primary",
						onClick: async () => {
							let codClassificacao = this.getValor("codClassificacao");
							let to = this.getTOSelectSelecionado("codClassificacao");
							if (codClassificacao) {
								edicaoDemandaTO.listaEdicaoDemandaConteudoTO.push({
									codClassificacao: codClassificacao,
									nomeClassificacao: to[0].nome
								});
								await this.atualizarListagemClassificacoes(edicaoDemandaTO);
							}
						}
					}]
				});

				await this.addSelect({
					label: this.getMsg("FP_FRONT_CadastroDemandaVH_033"),
					classe: "col-md-12",
					id: "codClassificacao",
					obrigatorio: true,
					loadOpcoesBack: {
						endpoint: "ListagemSelecaoFCD/listarDisciplinasTags",
						numMinimoCaracteres: 3,
					}
				});
				this.exibirPopups();
			},
			onExclusao: async (botaoExclusao) => {
				$(botaoExclusao).closest("tr").remove();
			},
			labelNovo: this.getMsg("FP_FRONT_CadastroDemandaVH_034"),
			msgListaVazia: this.getMsg("FP_FRONT_CadastroDemandaVH_035"),
		});

		this.exibir();
	}

	async atualizarListagemResponsaveis(edicaoDemandaTO) {

		this.setHtml("listagem_responsaveis", "");
		this.setIdTarget("listagem_responsaveis");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_037"), prop: "nomeUsuario", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_038"), prop: "dataInicio", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_039"), prop: "dataFim", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_040"), prop: "dataElaboracaoEncerrada", formato: "DD/MM/YYYY HH:mm"});

		await this.addTabela({
			collection: edicaoDemandaTO.listaEdicaoDemandaResponsavelTO,
			titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_036"),
			id: "demandaResponsaveis",
			propId: "codDemandaResponsavel",
			colunas: colunas,
			onNovo: async () => {
				await this.editarResponsavel(edicaoDemandaTO);
			},
			onEdicao: async (codDemandaResponsavel, edicaoDemandaResponsavelTO) => await this.editarResponsavel(edicaoDemandaTO, edicaoDemandaResponsavelTO),
			onExclusao: async (botaoExclusao, codDemandaResponsavel) => {
				$(botaoExclusao).closest("tr").remove();
				edicaoDemandaTO.listaEdicaoDemandaResponsavelTO = edicaoDemandaTO.listaEdicaoDemandaResponsavelTO
					.filter(edicaoDemandaResponsavelTO => codDemandaResponsavel != edicaoDemandaResponsavelTO.codDemandaResponsavel);
			},
			labelNovo: this.getMsg("FP_FRONT_CadastroDemandaVH_041"),
			msgListaVazia: this.getMsg("FP_FRONT_CadastroDemandaVH_042"),
		});

		this.exibir();
	}

	async editarResponsavel(edicaoDemandaTO: any, edicaoDemandaResponsavelTO?: any) {
		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_043"),
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_044")
			}, {
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_045"),
				classe: "btn-primary",
				onClick: async (botao) => {
					try {
						this.verificarObrigatorios(botao);
					} catch (e) {
						return false;
					}

					let codUsuario = this.getValor("codUsuario");

					if (!codUsuario) return;

					let to = this.getTOSelectSelecionado("codUsuario");


					if (!edicaoDemandaResponsavelTO) {
						this.codDemandaResponsavelNovo = this.codDemandaResponsavelNovo ? this.codDemandaResponsavelNovo - 1 : -1;
						edicaoDemandaResponsavelTO = {
							codDemandaResponsavel: this.codDemandaResponsavelNovo
						}
						edicaoDemandaTO.listaEdicaoDemandaResponsavelTO.push(edicaoDemandaResponsavelTO);
					}

					edicaoDemandaResponsavelTO.codUsuario = codUsuario;
					edicaoDemandaResponsavelTO.nomeUsuario = to[0].nome;
					edicaoDemandaResponsavelTO.dataInicio = this.getValor("dataInicio");
					edicaoDemandaResponsavelTO.dataFim = this.getValor("dataFim");

					await this.atualizarListagemResponsaveis(edicaoDemandaTO);
				}
			}]
		});

		const collectionOpcaoListaTOElaboradores = [];
		let valorElaborador = null;

		if (edicaoDemandaResponsavelTO) {
			collectionOpcaoListaTOElaboradores.push({
				id: edicaoDemandaResponsavelTO.codUsuario,
				nome: edicaoDemandaResponsavelTO.nomeUsuario
			});
			valorElaborador = edicaoDemandaResponsavelTO.codUsuario;
		}

		await this.addSelect({
			collection: collectionOpcaoListaTOElaboradores,
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_046"),
			classe: "col-md-12",
			id: "codUsuario",
			valor: valorElaborador,
			obrigatorio: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarUsuariosComPermissao",
				payload: {
					tipoFuncionalidade: "DEMANDA_ELABORACAO_QUESTOES",
				},
				numMinimoCaracteres: 3,
			},
			
		});
		this.addCampoTexto({
			id: "dataInicio",
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_047"),
			obrigatorio: true,
			tipo: "DATAHORA",
			valor: edicaoDemandaResponsavelTO ? edicaoDemandaResponsavelTO.dataInicio : null
		});
		this.addCampoTexto({
			id: "dataFim",
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_048"),
			obrigatorio: true,
			tipo: "DATAHORA",
			valor: edicaoDemandaResponsavelTO ? edicaoDemandaResponsavelTO.dataFim : null
		});
		this.exibirPopups();
	}

	addDetalhesAutoCriacao(exibicaoTrabalhoDemandaTO: any){

		this.append("<div class='col-md-12 row-no-padding'>");

		this.addCampoExibicao({
			id: 'num-questoes-elaboradas',
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_106"),
			valor: exibicaoTrabalhoDemandaTO.numQuestoesElaboradas,
			classe: 'col-md-3 col-lg-2'
		});
		this.addCampoExibicao({
			id: 'num-questoes-aprovadas',
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_113"),
			valor: exibicaoTrabalhoDemandaTO.numQuestoesAprovadas,
			classe: 'col-md-3 col-lg-2'
		});
		this.addCampoExibicao({
			id: 'num-questoes-reprovadas',
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_114"),
			valor: exibicaoTrabalhoDemandaTO.numQuestoesReprovadas,
			classe: 'col-md-3 col-lg-2'
		});
		this.addCampoExibicao({
			id: 'num-questoes-aguardando-revisao',
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_115"),
			valor: exibicaoTrabalhoDemandaTO.numQuestoesAguardandoRevisao,
			classe: 'col-md-3 col-lg-2'
		});
		this.addCampoExibicao({
			id: 'num-questoes-aguardando-envio',
			label: this.getMsg("FP_FRONT_CadastroDemandaVH_133"),
			valor: exibicaoTrabalhoDemandaTO.numQuestoesPendenteEnvio,
			classe: 'col-md-3 col-lg-2'
		});

		this.append("</div>");
	}

	async exibirTrabalho(codDemanda: number, codQuestao?: number) {

		UtilHash.registrarHistorico(this.exibirTrabalho, codDemanda, codQuestao);

		const exibicaoTrabalhoDemandaTO = await this.call("CadastroDemandaFCD/recuperarTrabalho", codDemanda);

		this.limpar();
		this.setTitulo(exibicaoTrabalhoDemandaTO.nomeDemanda, this.getMsg("FP_FRONT_CadastroDemandaVH_049"));

		const tituloClassificacao = this.hasValue(exibicaoTrabalhoDemandaTO.classificacoes) ? `
			${this.getMsg("FP_FRONT_CadastroDemandaVH_053")} 
			<strong>${exibicaoTrabalhoDemandaTO.classificacoes.join(', ')}</strong>
		` : "";

		this.addFormulario({id: "formulario-box-demanda"});

		if (exibicaoTrabalhoDemandaTO.isElaborador) {
			this.addBox({
				titulo: `${this.getMsg("FP_FRONT_CadastroDemandaVH_052")} ${tituloClassificacao}`,
				css: "margin-top: -10px",
				deixarAberto: true
			});

			if (exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada) {
				this.addCampoExibicao({label: this.getMsg("FP_FRONT_CadastroDemandaVH_054"), valor: UtilData.toDDMMYYYYHHMM(exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada)});
			} else if(exibicaoTrabalhoDemandaTO.isAutoCriacao) {
				this.addDetalhesAutoCriacao(exibicaoTrabalhoDemandaTO);
			} else {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroDemandaVH_055"), valor: UtilData.toDDMMYYYYHHMM(exibicaoTrabalhoDemandaTO.dataFim)});
				this.addCampoExibicao({
					label: this.getMsg("FP_FRONT_CadastroDemandaVH_060", exibicaoTrabalhoDemandaTO.numQuestoesElaboradas, exibicaoTrabalhoDemandaTO.numQuestoesDemanda),
					valor: UtilProgressBar.basic({
						evolucao: exibicaoTrabalhoDemandaTO.numQuestoesElaboradas,
						total: exibicaoTrabalhoDemandaTO.numQuestoesDemanda
					})
				});
			}
		} else {
			const tituloTrabalho = !exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada ? this.getMsg("FP_FRONT_CadastroDemandaVH_050") : this.getMsg("FP_FRONT_CadastroDemandaVH_051");
			this.addBox({
				titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_061", tituloTrabalho, tituloClassificacao),
				css: "margin-top: -10px",
				deixarAberto: true
			});

			if (this.hasValue(exibicaoTrabalhoDemandaTO.elaboradores)) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroDemandaVH_056"), valor: exibicaoTrabalhoDemandaTO.elaboradores.join("<br>")});
			}

			if (exibicaoTrabalhoDemandaTO.dataRevisaoEncerrada) {
				if(exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada){
					this.addCampoExibicao({
						label: this.getMsg("FP_FRONT_CadastroDemandaVH_057"),
						valor: UtilData.toDDMMYYYYHHMM(exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada)
					});
				}
				this.addCampoExibicao({
					label: this.getMsg("FP_FRONT_CadastroDemandaVH_058"),
					valor: UtilData.toDDMMYYYYHHMM(exibicaoTrabalhoDemandaTO.dataRevisaoEncerrada)
				});

			} else if (exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada) {
				this.addCampoExibicao({
					label: this.getMsg("FP_FRONT_CadastroDemandaVH_059"),
					valor: UtilData.toDDMMYYYYHHMM(exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada)
				});

				this.addCampoExibicao({
					label: this.getMsg("FP_FRONT_CadastroDemandaVH_062", exibicaoTrabalhoDemandaTO.numQuestoesRevisadas, exibicaoTrabalhoDemandaTO.collectionExibicaoQuestaoDemandaTO.length),
					valor: UtilProgressBar.basic({
						evolucao: exibicaoTrabalhoDemandaTO.numQuestoesRevisadas,
						total: exibicaoTrabalhoDemandaTO.collectionExibicaoQuestaoDemandaTO.length
					})
				});

			} else if(exibicaoTrabalhoDemandaTO.isAutoCriacao) {
				this.addDetalhesAutoCriacao(exibicaoTrabalhoDemandaTO);
			} else {
				this.addCampoExibicao({
					label: this.getMsg("FP_FRONT_CadastroDemandaVH_063"),
					valor: UtilData.toDDMMYYYYHHMM(exibicaoTrabalhoDemandaTO.dataFim)
				});

				this.addCampoExibicao({
					label: this.getMsg("FP_FRONT_CadastroDemandaVH_065", exibicaoTrabalhoDemandaTO.numQuestoesElaboradas, exibicaoTrabalhoDemandaTO.numQuestoesDemanda),
					valor: UtilProgressBar.basic({ 
						evolucao: exibicaoTrabalhoDemandaTO.numQuestoesElaboradas, 
						total: exibicaoTrabalhoDemandaTO.numQuestoesDemanda 
					})
				});
			}
		}

		this.addEspacamentoHorizontal();

		if (this.hasValue(exibicaoTrabalhoDemandaTO.orientacoes)) {
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_064"),
				valor: exibicaoTrabalhoDemandaTO.orientacoes,
				classe: "col-md-12"
			});
		}

		if(this.hasValue(exibicaoTrabalhoDemandaTO.detalhesAutoCriacao)){
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_107"),
				valor: exibicaoTrabalhoDemandaTO.detalhesAutoCriacao,
				classe: "col-md-12"
			});
		}

		const isEmElaboracao = exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada == null;
		const isAutoCriacao = exibicaoTrabalhoDemandaTO.isAutoCriacao;
		const isEmRevisao = exibicaoTrabalhoDemandaTO.dataRevisaoEncerrada == null;
		const isElaborador = exibicaoTrabalhoDemandaTO.isElaborador === true;

		this.append("<div class='col-md-12 row-no-padding'><div class='col-md-4 row-no-padding'>");

		if(isAutoCriacao === true) {
			this.addCheckbox({
				id: "checkbox_ver_somente_reprovadas",
				classe: "col-md-12",
				css: "min-height: unset;",
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_116"),
				visivel: isElaborador,
				onChange: () => {
					const isSomenteReprovadas = this.getValor("checkbox_ver_somente_reprovadas");
					const questoesReprovadas = $(`[revisao-reprovada='false']`).closest(`[questao]`);
					questoesReprovadas.css("display", isSomenteReprovadas ? "none" : "");
				}
			})
		}

		this.append("</div><div class='btn-group pull-right'>");

		if ((isAutoCriacao && isEmRevisao) || (!isAutoCriacao && isEmElaboracao)) {

			this.addBotao({
				id: "btn_demanda_importar_arquivo",
				label: `<i class='fa fa-upload'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_066")}`,
				classe: "btn-sm",
				onClick: () => {
					importacaoProvaVH.exibirTelaImportacaoQuestoes(null, null, null, async (salvamentoImportacaoDocxTO: any) => {
						await this.call("CadastroDemandaFCD/importarArquivo", codDemanda, salvamentoImportacaoDocxTO);
						await this.exibirTrabalho(codDemanda);
					});
				}
			});

			if(((isAutoCriacao === true && isElaborador) || isAutoCriacao === false) && !this.hasValue(exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada)){
				this.addBotao({
					id: "btn_add_questao",
					label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_067")}`,
					classe: "btn-success btn-sm",
					onClick: () => {
						cadastroQuestaoVH.exibirAbasQuestao(1, null, null, codDemanda, (codQuestao) => {
							this.exibirTrabalho(codDemanda, codQuestao);
						}).then(() => {},() => {});
					}
				});
			}

			if ((isAutoCriacao === false && isElaborador) || (isAutoCriacao === true && this.isAdministrador()) && !this.hasValue(exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada)) {
				this.addBotao({
					id: "btn_encerrar_elaboracao",
					label: `<i class='fa fa-stop-circle'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_068")}`,
					classe: "btn-warning btn-sm",
					ativo: exibicaoTrabalhoDemandaTO.numQuestoesElaboradas >= exibicaoTrabalhoDemandaTO.numQuestoesDemanda,
					onClick: async () => {
						await this.exibirAlerta({
							msg: this.getMsg("FP_FRONT_CadastroDemandaVH_070"),
							botoes: [{
								label: this.getMsg("FP_FRONT_CadastroDemandaVH_069"), 
								classe: "btn-primary", 
								onClick: async () => {
									await this.call("CadastroDemandaFCD/encerrarElaboracao", codDemanda);
									try {
										await this.listar();
									} catch (ignored) {}
								}
							}]
						});
					}
				});
			}
		}

		if (isEmRevisao && !isElaborador) {
			this.addBotao({
				id: "btn_encerrar_revisao",
				label: `<i class='fa fa-stop-circle'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_071")}`,
				classe: "btn-primary btn-sm",
				ativo: exibicaoTrabalhoDemandaTO.numQuestoesElaboradas >= exibicaoTrabalhoDemandaTO.numQuestoesDemanda,
				onClick: async () => {
					await this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_CadastroDemandaVH_072"),
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroDemandaVH_073"), 
							classe: "btn-primary", 
							onClick: async () => {
								
								const encerramentoRevisaoTO = await this.call("CadastroDemandaFCD/encerrarRevisao", codDemanda);

								if (encerramentoRevisaoTO && encerramentoRevisaoTO.codQuestaoSemRevisao) {
									await this.exibirTrabalho(codDemanda, encerramentoRevisaoTO.codQuestaoSemRevisao);
									if(exibicaoTrabalhoDemandaTO.isAutoCriacao === true){
										await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroDemandaVH_128", encerramentoRevisaoTO.numQuestoesSemRevisao) });
									} else {
										await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CadastroDemandaVH_074", encerramentoRevisaoTO.numQuestoesSemRevisao) });
									}
								} else {
									try {
										await this.listar();
									} catch (ignored) {}
								}
							}
						}]
					});
				}
			});
		}

		this.append("</div></div>");
		this.fecharFormulario();
		this.fecharBox();

		let autonumerar = { numQuestao: 1 };

		if (exibicaoTrabalhoDemandaTO.isElaborador && this.hasValue(exibicaoTrabalhoDemandaTO.listaExibicaoChecklistTO)) {
			this.addSubtitulo(this.getMsg("FP_FRONT_CadastroDemandaVH_075"));
			this.appendHtmlCheckList(exibicaoTrabalhoDemandaTO.listaExibicaoChecklistTO);
		}

		this.append(`<div demanda cod-demanda='${codDemanda}' class='row'><div class='col-md-12'>`);
		exibicaoTrabalhoDemandaTO.collectionExibicaoQuestaoDemandaTO.forEach((exibicaoQuestaoDemandaTO) => {
			const exibicaoQuestaoTO = exibicaoQuestaoDemandaTO.exibicaoQuestaoTO;
			exibicaoQuestaoVH.exibirQuestao(
				exibicaoQuestaoTO,
				this.getCfgsExibicao(exibicaoTrabalhoDemandaTO, exibicaoQuestaoDemandaTO, autonumerar)
			);
			if(this.hasValue(exibicaoQuestaoDemandaTO.observacoes)){
				this.observacoesRevisao.push({
					codQuestao: exibicaoQuestaoTO.codQuestao,
					observacao: exibicaoQuestaoDemandaTO.observacoes
				});
			}
		})
		this.append("</div></div>");
		this.addBotaoScrollParaTopo();
		this.exibir();

		revisaoProvaVH.permitirEditarTextoQuestao('[questao]', true);

		this.bindFerramentasRevisao();
		UtilWindow.scrollTo(`[cod-questao=${codQuestao}]`);
	}

	getCfgsExibicao(exibicaoTrabalhoDemandaTO, exibicaoQuestaoDemandaTO, autonumerar) {

		const isEmElaboracao = exibicaoTrabalhoDemandaTO.dataElaboracaoEncerrada === null;
		const isElaborador = exibicaoTrabalhoDemandaTO.isElaborador === true;
		let isReprovada = exibicaoQuestaoDemandaTO.dataRevisao !== null && !exibicaoQuestaoDemandaTO.isAprovada;

		const cfgs = {
			ferramentas: [],
			props: {},
			autonumerar: autonumerar
		};

		if (isEmElaboracao) {
			cfgs.ferramentas.push(`<a editar class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_076")}'><i class='fa fa-pencil fa-sm' ></i></a>`);
		}
		if (isEmElaboracao) {
			cfgs.ferramentas.push("<a reposicionar class='btn btn-default' data-placement='right'><i class='fa fa-chevron-left fa-sm' ></i><i class='fa fa-chevron-right fa-sm' ></i></a>");
			cfgs.ferramentas.push(`<a desassociar class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_077")}'><i class='fa fa-times fa-sm' ></i></a>`);
		}
		if (!exibicaoTrabalhoDemandaTO.isElaborador) {
			cfgs.ferramentas.push(`<a auditoria class='btn btn-default' data-placement='right' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_078")}'><i class='fa fa-eye fa-sm' ></i></a>`);
		}

		let h = [`<ul revisoes revisao-reprovada='${isReprovada}' class='list-group' style='margin-top: 5px; float: left; width: 100%'>`];

		h.push(`
			<li class='list-group-item' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_079")}'>
				<strong><small>#${exibicaoQuestaoDemandaTO.exibicaoQuestaoTO.codQuestao}</small></strong>
			</li>
		`);
		h.push(`
			<li class='list-group-item'>
				<strong>${this.getMsg("FP_FRONT_CadastroDemandaVH_080")}</strong><br>${exibicaoQuestaoDemandaTO.nomeUsuarioAssociacao}
			</li>
		`);

		if(exibicaoQuestaoDemandaTO.dataAssociacao){
			h.push(`
				<li class='list-group-item'>
					<strong>${this.getMsg("FP_FRONT_CadastroDemandaVH_127")}</strong><br>${UtilData.toDDMMYYYYHHMM(exibicaoQuestaoDemandaTO.dataAssociacao)}
				</li>
			`);
		}

		if(exibicaoTrabalhoDemandaTO.isAutoCriacao && !this.hasValue(exibicaoTrabalhoDemandaTO.listaExibicaoChecklistTO)){
			if(exibicaoQuestaoDemandaTO.dataRevisao){
				h.push(`
					<li class='list-group-item'>
						<strong>${this.getMsg("FP_FRONT_CadastroDemandaVH_110")}</strong>
						<p>
							${exibicaoQuestaoDemandaTO.nomeUsuarioRevisao}<br/>
							<span class=${exibicaoQuestaoDemandaTO.isAprovada === true ? 'text-success status-questao-aprovada': 'text-danger status-questao-reprovada'} style='text-transform: uppercase;'>
								${exibicaoQuestaoDemandaTO.isAprovada === true ? this.getMsg("FP_FRONT_CadastroDemandaVH_111"): this.getMsg("FP_FRONT_CadastroDemandaVH_112")}
							</span><br>
							<small>${UtilData.toDDMMYYYYHHMM(exibicaoQuestaoDemandaTO.dataRevisao)}</small><br/>
				`);
				if(!isElaborador){
					h.push(`
						<a class='btn btn-link' opcao-revisao value='null' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_119")}'>
							<i class='fa fa-trash'></i>
						</a>
					`);
				}
				if(this.hasValue(exibicaoQuestaoDemandaTO.observacoes)){
					h.push(`
						<li class='list-group-item'>
							<div class='btn-group-vertical' role='group' style='width: 100%'>
								<a ver-obs-revisao class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_118")}' >
									<i class='fa fa-list text-info'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_120")}
								</a>
					`);
					if(isElaborador){
						h.push(`
							<a reenviar-para-revisao class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_121")}' >
								<i class='fa fa-undo text-success'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_121")}
							</a>
						`)
					}
					h.push(`</div></li>`);
				}
				h.push(`</p>`);
			} else if(isElaborador && exibicaoQuestaoDemandaTO.status === 'PENDENTE_ENVIO_REVISAO'){
				h.push(`
					<li class='list-group-item'>
						<div class='btn-group-vertical' role='group' style='width: 100%'>
							<a enviar-para-revisao class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_134")}' >
								<i class='fa fa-share text-success'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_134")}
							</a>
						</div>
					</li>
				`);
			} else if(!isElaborador) {
				h.push(`
					<li class='list-group-item'>
						<div class='btn-group-vertical' role='group' style='width: 100%'>
							<a opcao-revisao class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_108")}' value='true'>
								<i class='fa fa-check text-success'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_108")}
							</a>
							<a opcao-revisao class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_109")}' value='false'>
								<i class='fa fa-ban text-danger'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_109")}
							</a>
						</div>
					</li>
				`);

				if(this.hasValue(exibicaoQuestaoDemandaTO.observacoes)){
					h.push(`
						<li class='list-group-item'>
							<div class='btn-group-vertical' role='group' style='width: 100%'>
								<a ver-obs-revisao class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_118")}' >
									<i class='fa fa-list text-info'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_120")}
								</a>
							</div>
						</li>
					`);
				}
			}
		} else if (exibicaoQuestaoDemandaTO.dataRevisao) {

			const situacao = exibicaoQuestaoDemandaTO.isAprovada ? `<i class='fa fa-check'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_081")}` : `<i class='fa fa-times'></i> ${this.getMsg("FP_FRONT_CadastroDemandaVH_082")}`;
			const botao = `<a abrir-checklist class='btn btn-default' title='${this.getMsg("FP_FRONT_CadastroDemandaVH_083")}'>${situacao}</a>`

			h.push(`<li class='list-group-item'>`);
			h.push(`<strong>${this.getMsg("FP_FRONT_CadastroDemandaVH_084")}</strong><br>${exibicaoQuestaoDemandaTO.nomeUsuarioRevisao}<br><br>${botao}`);
			h.push(`</li>`);

		} else if (!exibicaoTrabalhoDemandaTO.isElaborador) {
			h.push(`<li suas-revisoes class='list-group-item' style='background-color: white'><strong>${this.getMsg("FP_FRONT_CadastroDemandaVH_085")}</strong><br><br>`);
			h.push(`<a abrir-checklist class='btn btn-primary' title='Abrir checklist'>${this.getMsg("FP_FRONT_CadastroDemandaVH_086")}</a>`);
			h.push("</li>");
		}

		h.push("</ul>");

		cfgs.ferramentas.push(h.join(""));

		return cfgs;
	}

	bindFerramentasRevisao(seletor: string = "[questao]") {
		$(seletor).find("[ferramentas] a[editar]").on("click", ({ target }) => this.handleEditarQuestao(target));
		$(seletor).find("[ferramentas] a[reposicionar]").on("click", ({ target }) => this.handleReposicionarQuestao(target));
		$(seletor).find("[ferramentas] a[desassociar]").on("click", ({ target }) => this.handleRemoverQuestao(target));
		$(seletor).find("[ferramentas] a[auditoria]").on("click", ({ target }) => this.handleAuditoriaQuestao(target));
		$(seletor).find("[ferramentas] a[abrir-checklist]").on("click", ({ target }) => this.handleExibirChecklist(target));
		$(seletor).find("[ferramentas] a[opcao-revisao]").on("click", ({ target }) => this.handleAprovarQuestao(target));
		$(seletor).find("[ferramentas] a[ver-obs-revisao]").on("click", ({ target }) => this.handleVerObservacoesRevisao(target));
		$(seletor).find("[ferramentas] a[reenviar-para-revisao], a[enviar-para-revisao]").on("click", ({ target }) => this.handleEnviarParaRevisao(target));
		$(seletor).find("button[opcao]:not([gabarito])").removeAttr("disabled").on("click", ({ target }) => this.handleMudancaGabarito(target));
		$(seletor).find("[disciplinas]").show();
		$(seletor).find("[exibicao]").removeClass("col-md-11").addClass("col-md-10");
		$(seletor).find("[div-ferramentas]").removeClass("col-md-1").addClass("col-md-2").show();
		$(seletor).find(".operacoes_sobre_questao").find("a.encontrou-erros, a.estatisticas").hide()
	}

	async handleEnviarParaRevisao(botao: any) {

		try {

			const $botao = $(botao);
			let msg = null;
			let label = null;
			
			if ($botao.is("[reenviar-para-revisao]")) {
				msg = this.getMsg("FP_FRONT_CadastroDemandaVH_122");
				label = this.getMsg("FP_FRONT_CadastroDemandaVH_123");
				
			} else if ($botao.is("[enviar-para-revisao]")) {
				msg = this.getMsg("FP_FRONT_CadastroDemandaVH_135");
				label = this.getMsg("FP_FRONT_CadastroDemandaVH_136");
			}
			
			if (!msg) return;

			this.disable($botao);
			
			await this.exibirAlerta({
				msg: msg,
				botoes: [{
					label: label,
					onClick: async () => {
						const codQuestao = $botao.closest("[questao]").attr("cod-questao");
						const codDemanda = $botao.closest("[demanda]").attr("cod-demanda");
						let salvamentoRevisaoQuestao = {
							codQuestao: codQuestao,
							codDemanda: codDemanda,
							isAprovada: null
						}
						await this.salvarResultadoRevisao(codDemanda, codQuestao, salvamentoRevisaoQuestao);
						$botao.remove();
					}
				}]
			});

			this.enable($botao);
			
		} catch (e) {
			this.logger.error(`Erro ao enviar para revisão`, e);
		}
	}

	handleVerObservacoesRevisao(botao: any){
		const $botao = $(botao);
		const codQuestao = Number($botao.closest("[questao]").attr("cod-questao"));
		let observacao = "";

		for(const questao of this.observacoesRevisao){
			if(questao.codQuestao === codQuestao){
				observacao = questao.observacao;
				this.exibirModalObservacoes(null, null, null, observacao, true);
				break;
			}
		}
	}

	handleAprovarQuestao(botao) {
		const $botao = $(botao);
		const codQuestao = $botao.closest("[questao]").attr("cod-questao");
		const codDemanda = $botao.closest("[demanda]").attr("cod-demanda");
		const isAprovada = $botao.attr("value");
		let observacao = null;

		let salvamentoRevisaoQuestao = {
			codQuestao: codQuestao,
			codDemanda: codDemanda,
			isAprovada: isAprovada,
			observacoes: observacao
		}

		if(isAprovada === 'false'){
			for(const questao of this.observacoesRevisao){
				if(questao.codQuestao === +codQuestao){
					observacao = questao.observacao;
					break;
				}
			}
			salvamentoRevisaoQuestao.observacoes = observacao;
			this.exibirModalObservacoes(codDemanda, codQuestao, salvamentoRevisaoQuestao, observacao,false);
		} else {
			this.salvarResultadoRevisao(codDemanda, codQuestao, salvamentoRevisaoQuestao);
		}
	}

	exibirModalObservacoes(codDemanda, codQuestao, salvamentoRevisaoQuestao, observacoes = null,  visualizacao = false) {
		this.addPopup({
			id: "popup-reprovacao-revisao",
			titulo: visualizacao ? this.getMsg("FP_FRONT_CadastroDemandaVH_118"): this.getMsg("FP_FRONT_CadastroDemandaVH_117"),
			width: "50%",
			botoes: !visualizacao ? [{
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_109"),
				onClick: async () => {
					salvamentoRevisaoQuestao.observacoes = this.getValor("observacoes_revisao");
					this.salvarResultadoRevisao(codDemanda, codQuestao, salvamentoRevisaoQuestao);
				}
			}]: null,
		});

		this.addTextArea({
			id : "observacoes_revisao",
			label : !visualizacao ? this.getMsg("FP_FRONT_CadastroDemandaVH_118"): null,
			classe: "col-sm-12",
			cssTextarea: "min-height: 120px",
			cssTextareaWrapper: "width: 100%",
			valor: observacoes,
			habilitado: !visualizacao
		});

		this.exibirPopups();
	}

	async salvarResultadoRevisao(codDemanda, codQuestao, salvamentoRevisaoQuestao){
		await this.call("CadastroDemandaFCD/salvarRevisaoQuestao", salvamentoRevisaoQuestao);
		await this.exibirTrabalho(codDemanda, codQuestao);
	}

	async handleExibirChecklist(botao) {
		const $botao = $(botao);
		const codQuestao = $botao.closest("[questao]").attr("cod-questao");
		const codDemanda = $botao.closest("[demanda]").attr("cod-demanda");

		const checklistQuestaoTO = await this.call("CadastroDemandaFCD/recuperarChecklist", codDemanda, codQuestao);

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CadastroDemandaVH_087"),
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_088"),
				id: "botao-salvar-checklist",
				visivel: !checklistQuestaoTO.isElaborador,
				onClick: async () => {
					const $botaoSalvar = $("#botao-salvar-checklist");
					const salvamentoDemandaChecklistTO = {
						codDemanda: codDemanda,
						codQuestao: codQuestao,
						listaSalvamentoChecklistItemTO: [],
						isAprovada: null
					}

					if ($botaoSalvar.text() == this.getMsg("FP_FRONT_CadastroDemandaVH_089")) {
						salvamentoDemandaChecklistTO.isAprovada = true;
					} else if ($botaoSalvar.text() == this.getMsg("FP_FRONT_CadastroDemandaVH_090")) {
						salvamentoDemandaChecklistTO.isAprovada = false;
					}

					$("table[checklist] tbody tr").each((i, tr) => {
						const $tr = $(tr);
						let isAtende = null;
						let isIgnorado = null;
						if ($tr.find("[atende='sim']").is("[marcado=true]")) {
							isAtende = true;
						} else if ($tr.find("[atende='nao']").is("[marcado=true]")) {
							isAtende = false;
						}

						if ($tr.find("[ignorado='sim']").is("[marcado=true]")) {
							isIgnorado = true;
						}
						salvamentoDemandaChecklistTO.listaSalvamentoChecklistItemTO.push({
							codChecklistItem: $tr.attr("cod-checklist-item"),
							isAtende: isAtende,
							isIgnorado: isIgnorado
						});
					});

					await this.call("CadastroDemandaFCD/salvarChecklist", salvamentoDemandaChecklistTO);

					this.exibirTrabalho(codDemanda, codQuestao);
				}
			}]
		});

		this.appendHtmlCheckList(checklistQuestaoTO.listaChecklistItemTO);
		this.exibirPopups();

		if (checklistQuestaoTO.isElaborador) {
			$("table[checklist] button[atende],button[ignorado]").attr("disabled", "disabled");
		} else {
			$("table[checklist] button[atende],button[ignorado]").on("click", ({ target }) => this.handleMarcacaoChecklist(target));
			this.atualizarBotaoSalvamentoChecklist();
		}
	}

	appendHtmlCheckList(tos) {
		const possuiBotoes = Object.keys(tos[0]).includes("isAtende");
		this.append(`
			<table checklist>
				<thead>
					<tr>
						<th></th>
						<th></th>
					${possuiBotoes ? "<th width='150'></th>" : ""}
					</tr>
				</thead>
			<tbody>
		`);
		tos.forEach((to, i) => {
			const checkedAtende = to.isAtende == true ? "true" : "false";
			const checkedNaoAtende = to.isAtende == false ? "true" : "false";
			const checkedIgnorado = to.isIgnorado === true ? "true" : "false";
			const htmlBotao = possuiBotoes ? `
				<td>
					<div class='btn-group'>
						<button class='btn btn-default' title='${this.getMsg('FP_FRONT_CadastroDemandaVH_100')}' atende='sim' marcado='${checkedAtende}'><i class='fa fa-check'></i></button>
						<button class='btn btn-default' title='${this.getMsg('FP_FRONT_CadastroDemandaVH_101')}' atende='nao' marcado='${checkedNaoAtende}'><i class='fa fa-times'></i></button>
						<button class='btn btn-default' title='${this.getMsg('FP_FRONT_CadastroDemandaVH_102')}' ${to.isObrigatorio === true ? 'disabled=true': 'ignorado=sim'} marcado='${checkedIgnorado}'><i class='fa fa-fast-forward'></i></button>
					</div>
				</td>
			` : "";
			this.append(`
				<tr cod-checklist-item='${to.codChecklistItem}' item-obrigatorio='${to.isObrigatorio}'>
					<td style="width: 3%;">
						<strong>${i + 1})</strong>
					</td>
					<td ${possuiBotoes ? "style='width: 67%;'" : "style='text-align: left;width: 97%;'"}>
						${to.texto} ${!to.isObrigatorio ? "(" + this.getMsg('FP_FRONT_CadastroChecklistVH_013') + ")": ""}
					</td>
					${htmlBotao}
				</tr>
			`);
		});
		this.append("</tbody></table>");
	}

	handleMarcacaoChecklist(target) {
		target = $(target).is("button") ? target : $(target).closest("button").get(0);
		$(target).attr("marcado", "true");
		$(target).closest("tr").find("button[atende],button[ignorado]").get().filter((button) => button != target).forEach((button) => {
			$(button).attr("marcado", "false");
		});
		this.atualizarBotaoSalvamentoChecklist();
	}

	atualizarBotaoSalvamentoChecklist() {

		const $botaoSalvar = $("#botao-salvar-checklist");

		if ($("table[checklist] button[atende='nao'][marcado='true']").length > 0) {
			$botaoSalvar.text(this.getMsg("FP_FRONT_CadastroDemandaVH_091")).removeClass("btn-success").addClass("btn-danger");

		} else if ($("table[checklist] button[marcado='true']").length == $("table[checklist] tbody tr").length) {
			$botaoSalvar.text(this.getMsg("FP_FRONT_CadastroDemandaVH_092")).removeClass("btn-danger").addClass("btn-success");

		} else {
			$botaoSalvar.text(this.getMsg("FP_FRONT_CadastroDemandaVH_093")).removeClass("btn-danger").removeClass("btn-success");
		}
	}

	handleEditarQuestao(botao) {
		const $botao = $(botao);
		const codQuestao = $botao.closest("[questao]").attr("cod-questao");
		const codDemanda = $botao.closest("[demanda]").attr("cod-demanda");

		cadastroQuestaoVH.exibirAbasQuestao(1, codQuestao, null, codDemanda, (codQuestao) => {
			this.exibirTrabalho(codDemanda, codQuestao);
		}).then(() => {}, () => {});
	}

	handleAuditoriaQuestao(botao) {
		const codQuestao = $(botao).closest("[questao]").attr("cod-questao");
		loginVH.exibirAcessosDaQuestao(codQuestao, true);
	}

	handleRemoverQuestao(botao) {
		const $botao = $(botao);
		const numero = $botao.closest("[questao]").find("[numerador]").get().map(n => $(n).attr("numerador")).join(", ");
		this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_CadastroDemandaVH_095", numero),
			botoes: [{
				label: this.getMsg("FP_FRONT_CadastroDemandaVH_094"), 
				classe: "btn-danger", 
				onClick: async () => {
					const codDemanda = $botao.closest("[demanda]").attr("cod-demanda");
					const codQuestao = $botao.closest("[questao]").attr("cod-questao");

					await this.call("CadastroDemandaFCD/removerQuestao", codDemanda, codQuestao);
					this.exibirTrabalho(codDemanda, codQuestao);
				}
			}]
		});
	}

	handleMudancaGabarito(botao) {
		const $botao = $(botao);
		const letra = $botao.attr("letra") || $botao.attr("value");
		this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_CadastroDemandaVH_096", letra),
			botoes: [{
				label: "Alterar", 
				classe: "btn-primary", 
				onClick: async () => {
					const codDemanda = $botao.closest("[demanda]").attr("cod-demanda");
					const codQuestao = $botao.closest("[questao]").attr("cod-questao");
					const codItem = $botao.attr("cod-item");

					await this.call("CadastroDemandaFCD/alterarGabarito", codDemanda, codItem);
					this.exibirTrabalho(codDemanda, codQuestao);
				}
			}]
		});
	}

	handleReposicionarQuestao(botao) {
		const $botao = $(botao);
		const numero = $botao.closest("[questao]").find("[numerador]").attr("numerador");
		let idInput = $botao.data("id-input");
		if (!idInput) {
			idInput = Date.now();
			$botao.popover({
				placement: "right",
				html: true,
				content: "<input id='" + idInput + "' class='form-control' type='number' min='1' value='" + numero + "'>",
				title: this.getMsg("FP_FRONT_CadastroDemandaVH_097")
			});
			$botao.data("id-input", idInput);
		}

		$botao.popover("toggle");

		$("#" + idInput).focus().off("keypress").on("keypress", async (event) => {
			if (this.isTeclaPressionada(AmaisVH.TECLA_ENTER, event)) {
				event.preventDefault();

				const novoNumQuestao = $("#" + idInput).val();

				const reposicionamentoQuestaoDemandaTO = {
					codDemanda: $botao.closest("[demanda]").attr("cod-demanda"),
					codQuestaoReposicionada: $botao.closest("[questao]").attr("cod-questao"),
					codQuestaoNovaPosicao: $(`[numerador='${novoNumQuestao}']`).closest("[questao]").attr("cod-questao")
				}

				await this.call("CadastroDemandaFCD/reposicionarQuestao", reposicionamentoQuestaoDemandaTO);

				this.exibirTrabalho(reposicionamentoQuestaoDemandaTO.codDemanda, reposicionamentoQuestaoDemandaTO.codQuestaoReposicionada);
			}
		});
	}
}

const cadastroDemandaVH = new CadastroDemandaVH();