class CronoContagemLinhasDiscursivaVH {
    worker: Worker;
    callback: Function;
    milisegundosPassados: number;
    $aplicacaoProva: any;

    cronoListener: Function = (msDecorridos) => {
        this.handleCronoTick(msDecorridos);
    }

    iniciarCronometro($aplicacaoProva: any, callback: Function) {
        this.pararCronometro();
        this.callback = callback;
        this.$aplicacaoProva = $aplicacaoProva;
        this.milisegundosPassados = 0;

        UtilCrono.adicionarListener(this.cronoListener);
    }

    async handleCronoTick(msDecorridos: number) {
        if (this.$aplicacaoProva == null || this.$aplicacaoProva.closest("body").length == 0) {
            this.pararCronometro();
            return;
        }

        const discursivaVisivel = $('div[tipo="DC"]').filter(function() {
            return $(this).is(':visible');
        });

        if (!discursivaVisivel || discursivaVisivel.length == 0) {
            this.milisegundosPassados = 0;
            return;
        }

        if (msDecorridos == null || msDecorridos < 1) return;

        this.milisegundosPassados += msDecorridos;

        if (Math.floor(this.milisegundosPassados / 1000) % 30 == 0) {
            await this.callback();
        }
    }

    pararCronometro() {
        UtilCrono.removerListener(this.cronoListener);
    }
}
