class CalculadoraSimplesVH extends AmaisVH {

	keyboardKeys = [
		"Enter", "Backspace", "Delete", "-", "+", "*", "/", "%", ",", "."
	]

	valorReal = null;
	constructor() {
		super("CalculadoraSimplesVH");
	}

	fecharCalculadora() {
		$('#calculadora-simples-panel').hide();
		$(document).off("keydown");
	}

	exibirCalculadora(botao){
		const $botao = $(botao);

		if (!$botao.data("fp-ja-inicializou")) {
			let h = [];

			h.push(`
				<div id="calculadora-simples-panel" class="calculadora-simples-panel" style="display:none; z-index:99999;" ondragstart="return false" ondrop="return false">
					 <div class="panel-heading">
					 	<i class="hidden-xs fa fa-calculator"></i> ${this.getMsg("FP_FRONT_CalculadoraSimplesVH_001")}
					 	<div class="btn-group pull-right">
					 		<button type="button" class="btn btn-default btn-xs" onclick="calculadoraSimplesVH.fecharCalculadora()">
					 			<span>x</span>
							</button>
					 	</div>
					 </div>
					 <div class="panel-body-calculadora">
					 	<div id="calculator">
							<div id="result">
								<div id="history">
									<p id="history-value"></p>
								</div>
								<div id="output">
									<p id="output-value"></p>
								</div>
							</div>
							<div id="keyboard">
								<button class="operator skip-duploclick" id="clear">C</button>
								<button class="operator skip-duploclick" id="backspace">CE</button>
								<button class="operator skip-duploclick percentage" id="%">%</button>
								<button class="operator skip-duploclick divide" id="÷">&#247;</button>
								<button class="number skip-duploclick" id="7">7</button>
								<button class="number skip-duploclick" id="8">8</button>
								<button class="number skip-duploclick" id="9">9</button>
								<button class="operator skip-duploclick multiply" id="×">&times;</button>
								<button class="number skip-duploclick" id="4">4</button>
								<button class="number skip-duploclick" id="5">5</button>
								<button class="number skip-duploclick" id="6">6</button>
								<button class="operator skip-duploclick subtract" id="-">-</button>
								<button class="number skip-duploclick" id="1">1</button>
								<button class="number skip-duploclick" id="2">2</button>
								<button class="number skip-duploclick" id="3">3</button>
								<button class="operator skip-duploclick sum" id="+">+</button>
								<button class="empty" id="empty"></button>
								<button class="number skip-duploclick" id="0">0</button>
								<button class="number skip-duploclick point" id=".">.</button>
								<button class="operator skip-duploclick equals" id="=">=</button>
							</div>
						</div>
					 </div>
				</div>
			`);

			let html = h.join("");
			this.addFerramenta({
				id: "container-calculadora-simples",
				html: html
			});

			$(botao).on("remove", () => {
				this.fecharCalculadora();
			});
		}

		$('#calculadora-simples-panel').toggle();
		this.addActions();
	}

	addActions() {
		const operators = $(".calculadora-simples-panel .operator");
		for(let operator of operators){
			operator.addEventListener('keydown', (event) => {
				if(event.key === "Enter") event.preventDefault();
			});
			operator.addEventListener('click', (event) => {
				const target = event && event.target? event.target: null;
				if(!target) return;

				this.processTargetOperation(target);
			});
		}

		const numbers = $(".calculadora-simples-panel .number");
		for(let number of numbers){
			number.addEventListener('keydown', (event) => {
				if(event.key === "Enter") event.preventDefault();
			});
			number.addEventListener('click', (event) => {
				const target = event && event.target? event.target: null;
				if(!target) return;

				this.putNumber(target.id);
			});
		}

		$(document).on("keydown", (event) => {
			const isNumber = isFinite(event.key);
			if(isNumber){
				this.putNumber(event.key);
			} else {
				if(this.keyboardKeys.includes(event.key)){
					let element = $(".panel-body-calculadora #keyboard");
					let type = "operation";
					switch (event.key) {
						case "Enter":
							element = element.find(".equals");
							break;
						case "Backspace":
							element = element.find("#backspace");
							break;
						case "Delete":
							element = element.find("#clear");
							break;
						case "+":
							element = element.find(".sum");
							break;
						case "-":
							element = element.find(".subtract");
							break;
						case "/":
							element = element.find(".divide");
							break;
						case "*":
							element = element.find(".multiply");
							break;
						case "%":
							element = element.find(".percentage");
							break;
						case ".":
						case ",":
							element = element.find(".point");
							type = "number";
							break;
						default:
							console.log("Tecla não mapeada: " + event.key);
							return;
					}

					if(type === "operation"){
						this.processTargetOperation(element.get(0));
					} else{
						this.putNumber(element.get(0).id);
					}
				}
			}
		});
	}

	processTargetOperation(target){

		if(target.id === "clear"){
			this.printHistory("");
			this.printOutput("");
		} else if(target.id === "backspace"){
			let output = this.getOutput().toString();
			if(output){
				output = output.substr(0,output.length-1);
				this.printOutput(output);
			}
		} else {
			let output: any = this.getOutput();
			let history = this.getHistory();
			if(output === "" && history !== "" && !history.endsWith("%")){
				if(isNaN(Number(history[history.length - 1]))){
					history = history.substr(0,history.length-1);
				}
			}
			if(output !== "" || history !== ""){
				history = history + output;
				if(target.id === "="){
					let result = window['math'].evaluate(history);
					this.printOutput(result);
					this.printHistory("");
				} else {
					history = history + target.id;
					this.printHistory(history);
					this.printOutput("");
				}
			}
		}
	}

	putNumber(number){
		let output = this.getOutput();
		if(!isNaN(output)){
			output = output + number;
			this.printOutput(output);
		}
	}

	getHistory() {
		return document.getElementById("history-value").innerText.replace("÷","/").replace("×","*");
	}

	printHistory(num) {
		document.getElementById("history-value").innerText = num;
	}

	getOutput() {
		return this.valorReal || "";
	}

	printOutput(num) {
		let valorReal = null;
		if(num !== ""){
			valorReal = num;
			num = this.getFormattedNumber(num);
		}
		document.getElementById("output-value").innerText = num;
		this.valorReal = valorReal;
	}

	getFormattedNumber(num) {
		if(num === "-"){
			return "";
		}
		if(num && (num.toString().endsWith(".") || num.toString().endsWith("0"))){
			return num;
		}
		return Number(num);
	}

	reverseNumberFormat(num) {
		return Number(num.replace(/,/g,''));
	}
}

const calculadoraSimplesVH = new CalculadoraSimplesVH();
