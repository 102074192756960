class ControleCotaVH extends AmaisVH {
    cotasSelecionadas: ControleCotasTO[] = [];
    listaControleCotaTO: ControleCotasTO[] = [];
    collectionDocumentoCotaTO: DocumentoCotaTO[] = [];
    codAgendamentoUsuario: number;

    constructor() {
        super(ControleCotaVH.name);
    }

    validarCotas(listaControleCotaTO, codAgendamentoUsuario) {
        this.cotasSelecionadas = [];
        this.collectionDocumentoCotaTO = [];
        this.listaControleCotaTO = listaControleCotaTO;
        this.codAgendamentoUsuario = codAgendamentoUsuario;
        return new Promise<boolean>((resolve) => {
            this.validarControleCotas(resolve);
        })
    }

    validarControleCotas(resolve) {
        this.addPopup({
            titulo: this.getMsg("FP_FRONT_ControleCotaVH_001"),
            id: "popupCotas",
            onHidden: () => resolve(false),
            botoes: [
                {
                    label: this.getMsg("FP_FRONT_ControleCotaVH_002"),
                    onClick: async () => {
                        await this.fecharPopup("popupCotas");
                        resolve(true);
                    }
                },
                {
                    label: this.getMsg("FP_FRONT_ControleCotaVH_003"),
                    classe: "btn-primary",
                    onClick: async () => {
                       if (this.cotasSelecionadas.length == 0) {
                           $('#alerta-selecao-cota').slideDown(300);
                           return false;
                       }
                       const isComprovantesValidos = await this.validarDocumentosCotas();
                       if (isComprovantesValidos) {
                           await this.fecharPopup("popupCotas");
                           resolve(true);
                           return;
                       }
                       return false;
                    },
                },
            ],
        });

        if (this.listaControleCotaTO.length == 1) {
            this.exibirValidacaoUmaCota();
            this.exibirPopups();
            return;
        }

        this.exibirValidacaoMaisDeUmaCota();
        this.exibirPopups();
    }

    exibirValidacaoUmaCota() {

        this.append(`<p>${this.getMsg("FP_FRONT_ControleCotaVH_004", this.listaControleCotaTO[0].descricaoTipoCota)}</p>`);



        this.append(`<p>${this.getMsg("FP_FRONT_ControleCotaVH_005", this.listaControleCotaTO[0].descricaoTipoDocumento)}</p>`)
        this.cotasSelecionadas = this.listaControleCotaTO;
    }

    exibirValidacaoMaisDeUmaCota() {
        this.append(`<p>${this.getMsg("FP_FRONT_ControleCotaVH_006")}</p>`);

        const checkboxes = [];

        for (const controleCota of this.listaControleCotaTO) {
            checkboxes.push({
                id: controleCota.tipoCota,
                label: `${controleCota.descricaoTipoCota} <small>* ${controleCota.descricaoTipoDocumento}</small>`,
                css: "width: 100%; margin-left: 0"
            });
        }

        this.append("<div class='alert alert-warning col-md-12' id='alerta-selecao-cota' style='display: none'><i class='fa fa-exclamation-triangle'></i> Selecione uma cota para prosseguir</div>")

        this.addCheckboxGroup({
            label: this.getMsg("FP_FRONT_ControleCotaVH_007"),
            id: "tiposDeArquivos",
            classe: "col-md-12",
            checkboxes,
            onChange: async () => {
                const tiposArquivos = this.getValor("tiposDeArquivos") || [];
                $('#alerta-selecao-cota').slideUp(300);
                this.cotasSelecionadas = tiposArquivos.map(it => {
                    return this.listaControleCotaTO.find(c => c.tipoCota === it);
                }).filter((c: any) => c);
            }
        });

        this.append(`<p>${this.getMsg("FP_FRONT_ControleCotaVH_008")}</p>`);
    }

    async validarDocumentosCotas() {
        this.logger.info("Exibiu popup de envio de documentos para as cotas: ", JSON.stringify(this.cotasSelecionadas.map(c => c.descricaoTipoDocumento)));
        return new Promise<boolean>((resolve) => {
            this.addPopup({
                titulo: this.getMsg("FP_FRONT_ControleCotaVH_009"),
                id: "popupEnvioDocumentosCotas",
                fullWidth: true,
                onHidden: () => resolve(false),
                botoes: [
                    {
                        label: this.getMsg("FP_FRONT_ControleCotaVH_010"),
                        onClick: async () => {
                            resolve(false);
                        }
                    },
                    {
                        label: this.getMsg("FP_FRONT_ControleCotaVH_011"),
                        classe: "btn-primary",
                        onClick: async () => {
                            const tiposCotasComArquivos = new Set(this.collectionDocumentoCotaTO.map(obj => obj.tipoCota));

                            let faltandoArquivo = false;

                            this.cotasSelecionadas.forEach(cota => {
                                if (!tiposCotasComArquivos.has(cota.tipoCota)) {
                                    $(`#alerta-envio-documento_cota_${cota.tipoCota}`).slideDown(300);
                                    faltandoArquivo = true;
                                }
                            });

                            if (faltandoArquivo) {
                                return false;
                            }

                            try {
                                this.logger.info(`Salvando documentos de cotas (codAgendamentoUsuario: ${this.codAgendamentoUsuario}): `, JSON.stringify(this.collectionDocumentoCotaTO, null, 2));
                                await this.call("ControleCotaFCD/salvarCotasAgendamentoUsuario", this.codAgendamentoUsuario, this.collectionDocumentoCotaTO);
                                await this.fecharPopup("popupEnvioDocumentosCotas");
                                resolve(true);
                            } catch (e) {
                                this.logger.error(e);
                                return false;
                            }
                        }
                    },
                ],
            })



            this.append(`<p> ${this.getMsg("FP_FRONT_ControleCotaVH_012")}</p>`);

            this.cotasSelecionadas.forEach(cota => {
                this.adicionarExibicaoArquivosCotas(cota);
            });

            this.exibirPopups();
        });

    }

    adicionarExibicaoArquivosCotas(cota) {
        const btn = this.addBotao({
            label: `<i class="fa fa-upload"></i> Enviar ${cota.descricaoTipoDocumento}`,
            classe: "btn-sm",
            onClick: async () => {
                $(`#upload_${cota.tipoCota}`).click();
            },
            retornarHtml: true
        });

        this.append(`
            <div class="panel panel-default" id="panel_${cota.tipoCota}">
                <div class="panel-heading">
                    <h3 class="panel-title">${cota.descricaoTipoCota}</h3>
                </div>
                <div class="panel-body">
                    <input type="file" id="upload_${cota.tipoCota}" style="display: none;" accept="image/png,image/jpeg,application/pdf"/>
                    ${btn}
                    <div id='arquivos_${cota.tipoCota}' class='exibicao-arquivo-cota-container'> </div>
                    
                    <div class="alert alert-warning col-md-12" id="alerta-envio-documento_cota_${cota.tipoCota}" style="display: none"><i class="fa fa-exclamation-triangle"></i> Envie <strong>${cota.descricaoTipoDocumento}</strong> para prosseguir!</div>
                </div> 
            </div>
        `)

        this.appendJs(() => this.listenToChange(`#upload_${cota.tipoCota}`, async (e) => {
            const file = e.target.files[0];
            $(e.target).val('');
            await this.adicionarArquivo(file, cota);
        }));
    }

    async adicionarArquivo(file, cota) {
        const uploadTO = await UtilArmazenamento.upload(file, true);

        this.logger.info(`Adicionou arquivo "${uploadTO.nomeArquivo}"(${cota.tipoDocumento}) para cota ${cota.tipoCota}`);

        this.collectionDocumentoCotaTO.push({
            tipoCota: cota.tipoCota,
            tipoDocumento: cota.tipoDocumento,
            uploadTO
        });

        const arquivos = $(`#arquivos_${cota.tipoCota}`);

        arquivos.append(`
            <div class="exibicao-arquivo-cota" nome-arquivo="${uploadTO.nomeArquivo}">
                <a target="_blank" href="${uploadTO.url}"  title="Visualizar arquivo">
                    <i class="fa fa-file-alt"></i>
                    ${file.name}
                </a>
                <button class="delete-button" onclick="controleCotaVH.excluirArquivo('${uploadTO.nomeArquivo}')">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        `);

        $(`#alerta-envio-documento_cota_${cota.tipoCota}`).slideUp(300);
    }

    excluirArquivo(nomeArquivo) {
        const indiceArquivo = this.collectionDocumentoCotaTO.findIndex(it => it.uploadTO.nomeArquivo === nomeArquivo);
        if (indiceArquivo == -1) return;

        this.logger.info(`Excluiu arquivo "${nomeArquivo}"(${this.collectionDocumentoCotaTO[indiceArquivo].tipoDocumento}) para cota ${this.collectionDocumentoCotaTO[indiceArquivo].tipoCota}`);

        this.collectionDocumentoCotaTO.splice(indiceArquivo, 1);

        $(`div[nome-arquivo="${nomeArquivo}"]`).remove();

    }
	
	async modalControleCotas(codAgendamentoUsuario: number) {
		this.addPopup({
			id: "modal_documento_identificacao",
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_187"),
			width: "60%",
		});

		const listaAgendamentoUsuarioCotaTO = await this.call("ControleCotaFCD/listarCotasAgendamentoUsuario", codAgendamentoUsuario);
		
		const colunas: ColunaAddTabela[] = [
			{titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_188"), prop: "descricaoTipoCota"},
			{titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_189"), prop: "descricaoTipoDocumento"},
			{titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_190"), prop: "dataUpload", formato: "DD/MM/YY HH:mm"},
			{titulo: "", prop: (to) => {
				return `
					<a href="${to.urlDocumento}" target="_blank" class="btn btn-primary btn-sm">
						<i class="fa fa-file-alt"></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_191")}
					</a>
				`
			}}
		]

		await this.addTabela({
			colunas,
			collection: listaAgendamentoUsuarioCotaTO,
		});

		this.exibirPopups();
	}
}

type DocumentoCotaTO = {
    tipoCota: TipoCota;
    tipoDocumento: TipoDocumento;
    uploadTO: UploadTO;
}
enum TipoCota {
    PCD = 'PCD',
    PRETO_PARDO = 'PRETO_PARDO',
    INDIGENA = 'INDIGENA'
}

enum TipoDocumento {
    LAUDO_MEDICO = 'LAUDO_MEDICO',
    AUTODECLARACAO_ETNICO_RACIAL = 'AUTODECLARACAO_ETNICO_RACIAL'
}

type ControleCotasTO = {
    tipoCota: TipoCota;
    tipoDocumento: TipoDocumento;
    descricaoTipoCota: string;
    descricaoTipoDocumento: string;
}

const controleCotaVH = new ControleCotaVH();