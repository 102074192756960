class GestaoPFVH extends AmaisVH {
	
	constructor() {
		super("GestaoPFVH");
	}

	async editarReaberturaProvaRespondida(codProvaFeita, codPFLogReabertura) {
		const infosReaberturaPFTO = await this.call("AplicacaoProvaFCD/recuperarInfosReaberturaPF", codProvaFeita, codPFLogReabertura);

		if (!infosReaberturaPFTO) {
			// pf já foi reaberta mas adm tentou editar
			document.location.reload();
			return;
		}

		this.addPopup({
			width: "800px",
			// height: "500px",
			titulo: this.getMsg("FP_FRONT_GestaoPFVH_036"),
			botoes: [
				{
					label: this.getMsg("FP_FRONT_GestaoPFVH_037"),
					classe: "btn-danger",
					onClick: async () => {
						let prosseguirComRevogacao = false;
						await this.exibirAlerta({
							titulo:`<i class='fa fa-exclamation-circle'></i> ${this.getMsg('MSG_VH_AP_44')}`,
							msg: this.getMsg("FP_FRONT_GestaoPFVH_038"),
							botoes: [
								{label: this.getMsg("MSG_VH_004"), classe: 'btn-primary', onClick: () => {prosseguirComRevogacao = true}},
								{label: this.getMsg("MSG_VH_005")}
							]
						})
						if (prosseguirComRevogacao) {
							await this.call("AplicacaoProvaFCD/editarReaberturaProvaFeita", {
								codPFLogReabertura,
								codProvaFeita,
								isRevogacaoAbertura: true
							});
							document.location.reload();
						} else {
							return false;
						}
					}
				},
				{
				label: "Salvar",
				classe: "btn-primary",
				onClick: async () => {
					const reaberturaPFTO = {
						codPFLogReabertura,
						codProvaFeita,
						minutosConcedidosToleranciaRetomada: this.getValor("minutosConcedidosToleranciaRetomada"),
						segundosConcedidosParaResponder: null
					}

					if (infosReaberturaPFTO.tempoQueRestavaParaResponderAProva != null) {
						const tempoRestanteASerConcedido = this.getValor("tempoRestanteASerConcedido");

						if (tempoRestanteASerConcedido == null || tempoRestanteASerConcedido == 0) {
							await this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_GestaoPFVH_002"),
								botoes: [{ label: this.getMsg("MSG_VH_003") }],
							});
							this.focar("tempoRestanteASerConcedido");
							return false;
						}

						reaberturaPFTO.segundosConcedidosParaResponder = tempoRestanteASerConcedido * 60;
					}

					await this.call("AplicacaoProvaFCD/editarReaberturaProvaFeita", reaberturaPFTO);
					document.location.reload();
				}
			},

			]
		});

		if (infosReaberturaPFTO.dataLimiteInicioDaProva) {

			const millisEntreLimiteEAgora = UtilData.getDiferencaMillisUltimaRequisicao(infosReaberturaPFTO.dataLimiteInicioDaProva);

			this.addTexto("<br>");
			this.addSubsubtitulo(this.getMsg("FP_FRONT_GestaoPFVH_004"));

			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_005"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataLimiteInicioDaProva)});

			this.addEspacamentoHorizontal();

			this.addCampoTexto({
				id: "minutosConcedidosToleranciaRetomada",
				label: this.getMsg("FP_FRONT_GestaoPFVH_039"),
				valor: infosReaberturaPFTO.minutosConcedidosToleranciaRetomada,
				sufixo: this.getMsg("FP_FRONT_GestaoPFVH_007"),
				tipo: "NUMERO"
			});
		}

		this.addTexto("<br>");
		this.addSubsubtitulo(this.getMsg("FP_FRONT_GestaoPFVH_009"));

		if (infosReaberturaPFTO.tempoQueRestavaParaResponderAProva == null) {
			this.addTexto(this.getMsg("FP_FRONT_GestaoPFVH_010"));

		} else {
			if (infosReaberturaPFTO.tempoTotalConfiguradoPorProvaNaAplicacao != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_011"), valor: Math.round(infosReaberturaPFTO.tempoTotalConfiguradoPorProvaNaAplicacao / 60) + this.getMsg("FP_FRONT_GestaoPFVH_007")});
			}
			if (infosReaberturaPFTO.tempoTotalConfiguradoPorQuestaoNaAplicacao != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_012"), valor: Math.round(infosReaberturaPFTO.tempoTotalConfiguradoPorQuestaoNaAplicacao / 60) + this.getMsg("FP_FRONT_GestaoPFVH_007")});
			}
			if (infosReaberturaPFTO.dataInicio != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_013"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataInicio)});
			}
			if (infosReaberturaPFTO.dataUltimoKA != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_014"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataUltimoKA)});
			}
			if (infosReaberturaPFTO.dataFim != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_015"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataFim)});
			}
			if (infosReaberturaPFTO.tempoTotalDisponivelParaResposta != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_016"), valor: UtilData.getDuracao({segundos: infosReaberturaPFTO.tempoTotalDisponivelParaResposta})});
			}
			if (infosReaberturaPFTO.tempoQueRestavaParaResponderAProva != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_017"), valor: UtilData.getDuracao({segundos: infosReaberturaPFTO.tempoQueRestavaParaResponderAProva})});
			}

			this.addCampoTexto({
				id: "tempoRestanteASerConcedido",
				label: this.getMsg("FP_FRONT_GestaoPFVH_040"),
				sufixo: this.getMsg("FP_FRONT_GestaoPFVH_007"),
				tipo: "NUMERO",
				valor: Math.round(infosReaberturaPFTO.segundosConcedidosParaResponder / 60)
			});
		}

		this.exibirPopups();
	}
	
	async reabrirProvaRespondida(codProvaFeita, onPFReaberta) {

		const infosReaberturaPFTO = await this.call("AplicacaoProvaFCD/recuperarInfosReaberturaPF", codProvaFeita);
		
		if (!infosReaberturaPFTO) {
			// pf já foi reaberta mas adm tentou editar
			document.location.reload();
			return;
		}
		
		this.addPopup({
			width: "800px", 
			// height: "500px",
			titulo: this.getMsg("FP_FRONT_GestaoPFVH_001"),
			botoes: [{
				label: this.getMsg("FP_FRONT_GestaoPFVH_001"),
				classe: "btn-primary",
				onClick: async () => {
					const reaberturaPFTO = {
						codProvaFeita: codProvaFeita,
						minutosConcedidosToleranciaRetomada: this.getValor("minutosConcedidosToleranciaRetomada"),
						segundosConcedidosParaResponder: null
					}

					if (infosReaberturaPFTO.tempoQueRestavaParaResponderAProva != null) {
						const tempoRestanteASerConcedido = this.getValor("tempoRestanteASerConcedido");
						
						if (tempoRestanteASerConcedido == null || tempoRestanteASerConcedido == 0) {
							await this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_GestaoPFVH_002"),
								botoes: [{ label: this.getMsg("MSG_VH_003") }],
							});
							this.focar("tempoRestanteASerConcedido");
							return false;
						}
						
						reaberturaPFTO.segundosConcedidosParaResponder = tempoRestanteASerConcedido * 60;
					}
					
					await this.call("AplicacaoProvaFCD/reabrirProvaFeita", reaberturaPFTO);
					onPFReaberta();
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_GestaoPFVH_003"));

		if (infosReaberturaPFTO.dataLimiteInicioDaProva) {

			const millisEntreLimiteEAgora = UtilData.getDiferencaMillisUltimaRequisicao(infosReaberturaPFTO.dataLimiteInicioDaProva);

			this.addTexto("<br>");
			this.addSubsubtitulo(this.getMsg("FP_FRONT_GestaoPFVH_004"));

			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_005"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataLimiteInicioDaProva)});

			if (millisEntreLimiteEAgora > 0) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_006"), valor: Math.round(millisEntreLimiteEAgora / (60 * 1000)) + this.getMsg("FP_FRONT_GestaoPFVH_007")});
			}

			this.addCampoTexto({
				id: "minutosConcedidosToleranciaRetomada", 
				label: this.getMsg("FP_FRONT_GestaoPFVH_008"),
				valor: Math.max(10, Math.round(millisEntreLimiteEAgora / (60 * 1000))),
				sufixo: this.getMsg("FP_FRONT_GestaoPFVH_007"),
				tipo: "NUMERO"
			});
		}

		this.addTexto("<br>");
		this.addSubsubtitulo(this.getMsg("FP_FRONT_GestaoPFVH_009"));

		if (infosReaberturaPFTO.tempoQueRestavaParaResponderAProva == null) {
			this.addTexto(this.getMsg("FP_FRONT_GestaoPFVH_010"));
			
		} else {
			if (infosReaberturaPFTO.tempoTotalConfiguradoPorProvaNaAplicacao != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_011"), valor: Math.round(infosReaberturaPFTO.tempoTotalConfiguradoPorProvaNaAplicacao / 60) + this.getMsg("FP_FRONT_GestaoPFVH_007")});
			}
			if (infosReaberturaPFTO.tempoTotalConfiguradoPorQuestaoNaAplicacao != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_012"), valor: Math.round(infosReaberturaPFTO.tempoTotalConfiguradoPorQuestaoNaAplicacao / 60) + this.getMsg("FP_FRONT_GestaoPFVH_007")});
			}
			if (infosReaberturaPFTO.dataInicio != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_013"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataInicio)});
			}
			if (infosReaberturaPFTO.dataUltimoKA != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_014"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataUltimoKA)});
			}
			if (infosReaberturaPFTO.dataFim != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_015"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataFim)});
			}
			if (infosReaberturaPFTO.tempoTotalDisponivelParaResposta != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_016"), valor: UtilData.getDuracao({segundos: infosReaberturaPFTO.tempoTotalDisponivelParaResposta})});
			}
			if (infosReaberturaPFTO.tempoQueRestavaParaResponderAProva != null) {
				this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_017"), valor: UtilData.getDuracao({segundos: infosReaberturaPFTO.tempoQueRestavaParaResponderAProva})});
			}
			
			this.addCampoTexto({
				id: "tempoRestanteASerConcedido", 
				label: this.getMsg("FP_FRONT_GestaoPFVH_018"),
				sufixo: this.getMsg("FP_FRONT_GestaoPFVH_007"),
				tipo: "NUMERO",
				valor: Math.round(infosReaberturaPFTO.tempoQueRestavaParaResponderAProva / 60)
			});
		}
		
		this.exibirPopups();
	}

	removerAnulacaoProvaRespondida(codProvaFeita, onAnulacaoRemovida?: Function) {
		this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_GestaoPFVH_019"),
			botoes: [{
				label: this.getMsg("FP_FRONT_GestaoPFVH_020"),
				classe: "btn-primary",
				onClick: async () => {
					await this.call("AgendamentoProvaFCD/removerAnulacaoProvaFeita", codProvaFeita);
					onAnulacaoRemovida ? onAnulacaoRemovida() : document.location.reload();
				}
			}]
		});
	}

	async anularProvaRespondida(codProvaFeita, onAnulacao?: Function) {

		const infosReaberturaPFTO = await this.call("AplicacaoProvaFCD/recuperarInfosReaberturaPF", codProvaFeita);
			
		this.addPopup({
			width: "800px", 
			// height: "500px", 
			titulo: this.getMsg("FP_FRONT_GestaoPFVH_021"),
			subtitulo: "#" + codProvaFeita,
			botoes: [{
				label: this.getMsg("FP_FRONT_GestaoPFVH_021"),
				classe: "btn-danger",
				onClick: async (btn) => {
					this.verificarObrigatorios(btn);
					
					await this.call("AplicacaoProvaFCD/anularProvaFeita", [codProvaFeita], this.getValor("motivoAnulacao"));
					onAnulacao ? onAnulacao() : document.location.reload();
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_GestaoPFVH_022"));

		if (infosReaberturaPFTO?.tempoTotalConfiguradoPorProvaNaAplicacao != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_023"), valor: Math.round(infosReaberturaPFTO.tempoTotalConfiguradoPorProvaNaAplicacao / 60) + " minutos"});
		}
		if (infosReaberturaPFTO?.tempoTotalConfiguradoPorQuestaoNaAplicacao != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_024"), valor: Math.round(infosReaberturaPFTO.tempoTotalConfiguradoPorQuestaoNaAplicacao / 60) + " minutos"});
		}
		if (infosReaberturaPFTO?.dataInicio != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_025"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataInicio)});
		}
		if (infosReaberturaPFTO?.dataUltimoKA != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_026"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataUltimoKA)});
		}
		if (infosReaberturaPFTO?.dataFim != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_027"), valor: UtilData.toDDMMYYYYHHMMSS(infosReaberturaPFTO.dataFim)});
		}
		if (infosReaberturaPFTO?.tempoTotalDisponivelParaResposta != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_028"), valor: UtilData.getDuracao({segundos: infosReaberturaPFTO.tempoTotalDisponivelParaResposta})});
		}
		if (infosReaberturaPFTO?.tempoQueRestavaParaResponderAProva != null) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_GestaoPFVH_029"), valor: UtilData.getDuracao({segundos: infosReaberturaPFTO.tempoQueRestavaParaResponderAProva})});
		}
		
		this.addEditorHTML({
			id: "motivoAnulacao", 
			label: this.getMsg("FP_FRONT_GestaoPFVH_030"),
			classe: "col-md-12",
			obrigatorio: true
		});
		
		this.exibirPopups();
	}

	arquivarProvaFeita(codProvaFeita, onArquivamento) {
		this.exibirAlerta({
			msg: this.getMsg("MSG_VH_CU_117"),
			botoes: [{
				label: "<i class='fa fa-trash'></i> " + this.getMsg("FP_FRONT_GestaoPFVH_031"),
				classe: "btn-danger",
				onClick: async () => {
					await this.call("AgendamentoProvaFCD/arquivarProvaFeita", codProvaFeita);
					onArquivamento ? onArquivamento() : document.location.reload();
				}
			}]
		});
	}

	async pausar(codProvaFeita, onProvaPausada) {
		
		const infosReaberturaPFTO = await this.call("AplicacaoProvaFCD/recuperarInfosReaberturaPF", codProvaFeita);
			
		this.addPopup({
			width: "600px", 
			titulo: this.getMsg("FP_FRONT_GestaoPFVH_032"),
			botoes: [{
				label: this.getMsg("FP_FRONT_GestaoPFVH_032"),
				classe: "btn-primary",
				onClick: async () => {
					this.verificarObrigatorios();
					
					const pausaPFTO = {
						codProvaFeita: codProvaFeita,
						minutosPausa: this.getValor("minutosPausa"),
						motivoPausa: this.getValor("motivoPausa"),
					}

					if (this.isEmpty(pausaPFTO.motivoPausa)) {

					}
					
					await this.call("AplicacaoProvaFCD/pausarProvaFeita", pausaPFTO);
					onProvaPausada();
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_GestaoPFVH_033"));

		this.addCampoTexto({
			id: "minutosPausa", 
			label: this.getMsg("FP_FRONT_GestaoPFVH_034"),
			valor: 10,
			sufixo: this.getMsg("FP_FRONT_GestaoPFVH_007"),
			tipo: "NUMERO",
			classe: "col-md-12",
			obrigatorio: true
		});

		this.addEditorHTML({
			id: "motivoPausa", 
			label: this.getMsg("FP_FRONT_GestaoPFVH_035"),
			classe: "col-md-12",
			obrigatorio: true
		});
		
		this.exibirPopups();
	}
	
	anularProvasRespondidas(codsPFs: number[], onAnulacao: Function) {

		if (this.isEmpty(codsPFs)) return;

		this.addPopup({
			width: "800px",
			// height: "500px",
			titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_023"),
			botoes: [{
				label: this.getMsg("FP_FRONT_RelatorioProvasVH_024"),
				classe: "btn-danger",
				onClick: async (event) => {

					this.verificarObrigatorios(event.target);

					await this.call("AplicacaoProvaFCD/anularProvaFeita", codsPFs, this.getValor("motivoAnulacao"));

					await onAnulacao();
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_RelatorioProvasVH_025"));

		this.addEditorHTML({
			id: "motivoAnulacao",
			label: this.getMsg("FP_FRONT_RelatorioProvasVH_026"),
			classe: "col-md-12",
			obrigatorio: true
		});

		this.exibirPopups();
	}
	
	enviarEmailComCorrecoes(codsPFs, onEnviado: Function) {

		if (this.isEmpty(codsPFs)) return;

		this.addPopup({
			width: "600px",
			// height: "320px",
			titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_055"),
			botoes: [{
				label: this.getMsg("MSG_VH_RP_45"), classe: "btn-primary", onClick: async () => {

					const isPDF = this.getValor("formatoArquivoCorrecao") == "PDF";
					const isApenasResultados = this.getValor("formatoArquivoCorrecao") == "RESULTADOS";

					const agendamentoEnvioCorrecaoTO = await this.call("RelatorioProvasFCD/agendarEnvioDeCorrecao", codsPFs, isPDF, isApenasResultados);

					await onEnviado();
					
					this.addMsgSucesso({
						id: "alerta_agendamento",
						css: "width: 100%",
						texto: this.getMsg("MSG_VH_RP_47") + ": "
							+ agendamentoEnvioCorrecaoTO.numCorrecoesAgendadas
							+ (agendamentoEnvioCorrecaoTO.numUsuarioSemEmail > 0 ? "<br>" + this.getMsg("MSG_VH_RP_48") + ": "
								+ agendamentoEnvioCorrecaoTO.numUsuarioSemEmail : "")
							+ (this.hasValue(agendamentoEnvioCorrecaoTO.msgsErro) ? "<br>" + this.getMsg("MSG_VH_RP_49") + ": <br><i>"
								+ agendamentoEnvioCorrecaoTO.msgsErro.join("<br>") + "</i>" : "")
					});

					this.exibir();
					
					UtilWindow.scrollTo("#alerta_agendamento");
				}
			}]
		});

		this.addRadioGroup({
			id: "formatoArquivoCorrecao",
			label: "Formato",
			collection: [{ id: "RESULTADOS", descricao: this.getMsg("FP_FRONT_RelatorioProvasVH_056") }, { id: "PDF", descricao: "PDF" }, { id: "DOCX", descricao: "Word (DOCX)" }],
			valor: "DOCX",
		});

		this.addTexto(this.getMsg("MSG_VH_RP_44"));

		this.exibirPopups();
	}
	
	registrarEntregaCertificado(pfNotasEPontuacoesTO, onRegistrado: Function) {

		this.addPopup({
			width: "600px",
			// height: "320px",
			id: "entrega_certificado",
			titulo: this.getMsg("MSG_VH_RP_55"),
			botoes: [{
				label: this.getMsg("MSG_VH_RP_66"),
				onClick: async () => {
					let salvamentoDadosCertificadoTO = {
						codProvaFeita: pfNotasEPontuacoesTO.codProvaFeita,
						dataEntregaCertificado: this.getValor("dataEntregaCertificado"),
						numCertificado: this.getValor("numCertificado"),
						obsCertificado: this.getValor("obsCertificado")
					}
			
					const codProva = await this.call("AgendamentoProvaFCD/salvarEntregaCertificado", salvamentoDadosCertificadoTO);
			
					await onRegistrado();
				},
			}]
		})

		this.addCampoTexto({
			id: "dataEntregaCertificado",
			label: this.getMsg("MSG_VH_RP_56"),
			valor: pfNotasEPontuacoesTO.dataEntregaCertificado,
			// widthComponente: "43%",
			tipo: "DATA"
		})

		this.addCampoTexto({
			id: "numCertificado",
			label: this.getMsg("MSG_VH_RP_57"),
			valor: pfNotasEPontuacoesTO.numCertificado,
			// widthComponente: "43%"
		})

		this.addEditorHTML({
			id: "obsCertificado",
			label: this.getMsg("MSG_VH_RP_58"),
			valor: pfNotasEPontuacoesTO.obsCertificado,
			width: "550px",
			height: "70px",
			toolbar: "basica"
		})

		this.exibirPopups();
	}
	
	async abrirTelaCertificado(codAgendamentoUsuario) {

		const dadosGeracaoCertificaoTO = await this.call("EmissaoCertificadoFCD/recuperarInfoCertificado", codAgendamentoUsuario);
		let botoes = [];

		this.addPopup({
			id: "popup_certificado",
			width: "400px",
			// height: "140px",
			titulo: this.getMsg("MSG_VH_RP_61"),
			botoes: botoes
		});

		if (dadosGeracaoCertificaoTO.caminhoCompletoPDF) {
			this.addLink({
				label: UtilArquivo.getNomeArquivo(dadosGeracaoCertificaoTO.caminhoCompletoPDF),
				href: dadosGeracaoCertificaoTO.caminhoCompletoPDF,
				classe: "fp-link-aguardar-geracao-arquivo"
			});
		}

		if (this.isCfgHabilitada("APLICA_PROVAS_PRATICAS")) {
			let htmlLink;
			if (dadosGeracaoCertificaoTO.dataAprovacaoProvaPratica != null) {
				htmlLink = this.addLink({
					label: this.getMsg("MSG_VH_RP_62"),
					retornarHtml: true,
					css: "float: none;",
					onClick: async () => {
						await this.call("EmissaoCertificadoFCD/removerDataProvacaoProvaPratica", codAgendamentoUsuario);
						this.fecharPopup("popup_certificado");
						setTimeout(() => this.abrirTelaCertificado(codAgendamentoUsuario), 700);
					}
				});

				this.addTexto(this.getMsg("MSG_VH_RP_63") + " " + UtilData.toDDMMYYYY(dadosGeracaoCertificaoTO.dataAprovacaoProvaPratica) + ". " + htmlLink);

			} else {
				htmlLink = this.addLink({
					label: this.getMsg("MSG_VH_RP_64"),
					retornarHtml: true,
					css: "float: none;",
					onClick: () => {
						this.fecharPopup("popup_certificado");

						this.addPopup({
							id: "popup_data_prova_pratica",
							width: "400px",
							// height: "220px",
							titulo: this.getMsg("MSG_VH_RP_65"),
							botoes: [{
								label: this.getMsg("MSG_VH_RP_66"),
								onClick: async () => {
									await this.call("EmissaoCertificadoFCD/salvarDataAprovacaoProvaPratica", codAgendamentoUsuario, this.getValor("dataAprovacaoProvaPratica"));
									this.abrirTelaCertificado(codAgendamentoUsuario);
								},
							}]
						});

						this.addCampoTexto({
							id: "dataAprovacaoProvaPratica",
							label: this.getMsg("MSG_VH_RP_67"),
							tipo: "DATA",
							// widthComponente: "100%"
						});

						this.exibirPopups();
					}
				});

				this.addTexto(this.getMsg("MSG_VH_RP_68") + " " + htmlLink);
			}
		}

		this.exibirPopups();
		this.ativarLinksAguardandoGeracao();
	}

	permitirAlteracaoRespostasDiscursivasDoAvaliado() {

		$("[questao][cod-resposta-questao][tipo='DC'] [ferramentas]").parent().append(`
			<br>
			<br>
			<br>
			<br>
			<span class="label label-danger">ADM</span>
			<br>
			<br>
			<div ferramentas="" class="btn-group-vertical" role="toolbar">
				${this.addBotao({
					label: `<i class="fa fa-edit"></i>`,
					classe: "btn-danger",
					dica: "Alterar a resposta do avaliado",
					onClick: ({target}) => {

						const botaoAtivacaoAlteracao = target;
						const $questao = $(botaoAtivacaoAlteracao).closest("[questao]");
						const $textarea = $questao.find(`textarea[tipo="discursiva"]`);

						$textarea.removeAttr("disabled");

						this.focar($textarea);

						$textarea.closest(".form-group").append(this.addBotao({
							label: `Salvar`,
							classe: "btn-primary",
							css: "margin-top: 5px; margin-left: 30px",
							onClick: async ({target}) => {
								const botaoSalvamento = target;
								let respostaDiscursivaAlterada;

								try {
									respostaDiscursivaAlterada = UtilHtml.converterTextoTextareaParaHtml(this.getValor($textarea));
								} catch (e) {
									try {
										respostaDiscursivaAlterada = this.getValor($textarea);
									} catch (e2) {
										respostaDiscursivaAlterada = $textarea.val();
									}
								}

								const alteracaoDeRespostaDiscursivaDeAvaliadoTO = {
									codRespostaQuestao: Number($questao.attr("cod-resposta-questao")),
									respostaDiscursivaAlterada,
									numLinhasPreenchidas: salvamentoPFVH.getNumLinhasRespostaDiscursiva($textarea)
								};

								await this.call("GestaoPFFCD/alterarRespostaDiscursivaDeAvaliado", alteracaoDeRespostaDiscursivaDeAvaliadoTO);

								$(botaoSalvamento).remove();
								$(botaoAtivacaoAlteracao).closest("a").show();
								$textarea.attr("disabled", "disabled");
							},
							retornarHtml: true,
						}));

						this.exibir();

						$(botaoAtivacaoAlteracao).closest("a").hide();

					},
					retornarHtml: true,
				})}
				${this.addBotao({
					label: `<i class="fa fa-upload"></i>`,
					classe: "btn-danger",
					dica: "Enviar arquivo via upload para complementar a resposta do avaliado",
					onClick: ({target}) => {

						const botaoUpload = target;
						const $questao = $(botaoUpload).closest("[questao]");
						const codRespostaQuestao = Number($questao.attr("cod-resposta-questao"));
	
						const uploadMidiaAdmSistemaComplementarRespostaAvaliado = async () => {
							
							const uploadTO = this.getValor("upload_questao_midia_adm_sistema");
							const alteracaoDeRespostaDiscursivaDeAvaliadoTO = {
								codRespostaQuestao,
								pathImagemResposta: uploadTO.pathArmazenamento
							};
							
							if (this.isEmpty(alteracaoDeRespostaDiscursivaDeAvaliadoTO.pathImagemResposta)) {
								await this.exibirAlerta({ msg: "Selecione o arquivo." });
								return false;
							}

							const exibicaoQuestaoTO = await this.call("GestaoPFFCD/alterarRespostaDiscursivaDeAvaliado", alteracaoDeRespostaDiscursivaDeAvaliadoTO);

							salvamentoPFVH.atualizarMidiasResposta(exibicaoQuestaoTO.exibicaoRQTO);
						}
				
						this.addPopup({
							id: "uploadMidiaAdmSistemaComplementarRespostaAvaliado",
							titulo: "Upload para complementar resposta do avaliado",
							width: "400px",
							// height: "300px",
							botoes: [{label: "Enviar", onClick: uploadMidiaAdmSistemaComplementarRespostaAvaliado}]
						});
				
						this.setMetodoSubmit(uploadMidiaAdmSistemaComplementarRespostaAvaliado);
				
						this.addZonaUpload({
							id: "upload_questao_midia_adm_sistema", 
							label: "Selecione o arquivo", 
							classe: "col-md-12 col-lg-12", 
							maxFilesize: 1000, 
							maxWidthOuHeight: 2000,
							css: "height: 270px;",
							onErro: () => {
								this.setValor("questao_midia", null);
							}
						});
						
						this.fecharFormulario();
						this.addEspacamentoHorizontal("1px");
				
						this.exibirPopups()

					},
					retornarHtml: true,
				})}
			</div>
		`);

		this.exibir();
	}
}

const gestaoPFVH = new GestaoPFVH();
