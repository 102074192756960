class RelatorioVH extends AmaisVH {

	static jaRegistrouHashs: boolean;
	descricoesDosFiltros: any;
	
	constructor(nomeVH) {
		super(nomeVH);
		if (!RelatorioVH.jaRegistrouHashs) {
			this.addOperacaoParaHash("reor", this.exibirOpcoesDeRelatorios);
			this.addOperacaoParaHash("reea", this.exibirEstatisticasDeAcessos);
			this.addOperacaoParaHash("rere", this.gerarRelatorioMensalPorEmpresa);
			RelatorioVH.jaRegistrouHashs = true;
		}
	}

	relatorios_periodo_padrao = [
		"acertos_erros_por_prova", "acertos_erros_por_disciplina", "acertos_erros_por_turma",
		"acertos_erros_por_usuario", "acertos_erros_por_agendamento_sala", "relatorio_desempenho_por_orgao",
		"relatorio_desempenho_por_disciplina", "relatorio_desempenho_por_prova", "histograma_provas",
		"histograma_disciplina", "histograma_turma", "histograma_usuario", "histograma_agendamento_sala",
		"relatorio_eficiencia_provas", "relatorio_eficiencia_disciplina", "relatorio_eficiencia_turma",
		"relatorio_eficiencia_usuario"
	];

	async exibirOpcoesDeRelatorios(numAbaESubaba) {
		
		if (numAbaESubaba == null) {
			this.exibirOpcoesDeRelatorios(0);
			return;
		}

		const abasEstatiticasHabilitadasTO: AbasEstatiticasHabilitadasTO = await this.call("RelatorioFCD/recuperarAbasEstatisticasHabilitadas");

		UtilHash.registrarHistorico(this.exibirOpcoesDeRelatorios, numAbaESubaba);

		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_RL_00"));

		this.addAbas({ 
			numAbaAtiva: numAbaESubaba,
			abas: [{
				id: "estatiticasAbaAlunos",
				label: this.isAluno() ? this.getMsg("FP_FRONT_RelatorioVH_001") : this.getCfg("LABEL_MENU_CADASTRO_ALUNO"),
				onClick: async () => await this.exibirEstatisticasAlunos(),
				habilitada: abasEstatiticasHabilitadasTO.isExibirAlunos
			}, {
				id: "estatiticasAbaProvas",
				label: this.getMsg("MSG_VH_RL_02"),
				onClick: async () => await this.exibirEstatisticasProvas(),
				habilitada: abasEstatiticasHabilitadasTO.isExibirProvas
			}, {
				id: "estatiticasAbaDisciplinas",
				label: this.getMsg("MSG_VH_RL_03"),
				onClick: async () => await this.exibirEstatisticasDisciplinas(),
				habilitada: abasEstatiticasHabilitadasTO.isExibirClassificacoes
			}, {
				id: "estatiticasAbaTurmas",
				label: this.getCfg("LABEL_LISTAGEM_TURMAS"),
				onClick: async () => await this.exibirEstatisticasTurmas(),
				habilitada: abasEstatiticasHabilitadasTO.isExibirTurmas
			}, {
				id: "estatiticasAbaQuestoes",
				label: this.getMsg("MSG_VH_RL_06"),
				onClick: async () => await this.exibirEstatisticasQuestoes(),
				habilitada: abasEstatiticasHabilitadasTO.isExibirQuestoes
			}, {
				id: "estatiticasAbaSegmentos",
				label: this.getMsg("MSG_VH_RL_43"),
				onClick: async () => await this.exibirEstatisticasSegmentos(),
				habilitada: abasEstatiticasHabilitadasTO.isExibirSegmentos
			}]
		});
		
		this.exibir();
	}

	async toggleExibicaoFiltrosRespostas(isExibir) {
		const abaRelatorio = $('#tituloSuperiorDireito').find('li.active').attr('id');
		const el = $(`#filtro_secao_respostas_${abaRelatorio}`);
		this.toggleVisualizacaoElemento(isExibir, el);
		this.toggleBotaoFiltroRespostas(isExibir);
	}

	async toggleExibicaoFiltrosProvasRespondidas(isExibir?) {
		const el = $(`#filtro_secao_provas_respondidas`);
		this.toggleVisualizacaoElemento(isExibir, el);
		this.toggleBotaoFiltroProvasRespondidas(isExibir);
	}

	toggleVisualizacaoElemento(isExibir, el) {
		if (isExibir) {
			setTimeout(() => el.fadeIn(), 100);
		} else {
			el.fadeOut();
		}
	}

	async toggleExibicaoTabela(isExibir: boolean, tabela: string) {
		const table = $(`#${tabela}_container`);
		this.toggleVisualizacaoElemento(isExibir, table)
		this.toggleBotaoTabela(isExibir, tabela)
	}

	toggleBotaoTabela(isExibir, tabela) {
		const container = $(`#btn-${tabela}`);
		const textoTooltip = isExibir ? this.getMsg("FP_FRONT_RelatorioVH_002") : this.getMsg("FP_FRONT_RelatorioVH_003");

		const a = ` 
			<a class='btn btn-info btn-table'
			   onclick="relatorioVH.toggleExibicaoTabela(${!isExibir}, '${tabela}')"
			   title='${textoTooltip}'>
				<i class='fa fa-table'></i>
			</a>
		`
		container.html(a);
		this.exibir();
	}

	toggleBotaoFiltroRespostas(isExibir) {
		const cfgsRelIndiceRespostasTO = relatorioVH.getCfgsRelIndiceRespostasTO();
		const possuiFiltros = Object.keys(cfgsRelIndiceRespostasTO).filter(k => k != 'campoAgrupamento').some(k => cfgsRelIndiceRespostasTO[k]);
		const container = $('#btn-filtros-respostas-container');
		const textoTooltip = isExibir ? this.getMsg("FP_FRONT_RelatorioVH_004") : possuiFiltros ? this.getMsg("FP_FRONT_RelatorioVH_005") : this.getMsg("FP_FRONT_RelatorioVH_006");
		const estiloBtn = possuiFiltros? 'btn-primary' : 'btn-white';
		const a = ` 
			<a class='btn ${estiloBtn} btn-table'
			   onclick='relatorioVH.toggleExibicaoFiltrosRespostas(${!isExibir})'
			   title='${textoTooltip}'>
				<i class='fa fa-search'></i>
			</a>
		`
		container.html(a);
		this.exibir()
	}

	toggleBotaoFiltroProvasRespondidas(isExibir) {
		const cfgsConsultaIndicePFsTO = relatorioVH.getCfgsConsultaIndicePFsTO();
		const possuiFiltros = Object.keys(cfgsConsultaIndicePFsTO.filtrosAtivos).some(k => cfgsConsultaIndicePFsTO.filtrosAtivos[k] && cfgsConsultaIndicePFsTO.filtrosAtivos[k][0]) || !cfgsConsultaIndicePFsTO.isExibirSomentePFDaAU;
		const container = $('#btn-filtros-provas-respondidas-container');
		const textoTooltip = isExibir ? this.getMsg("FP_FRONT_RelatorioVH_004") : possuiFiltros ? this.getMsg("FP_FRONT_RelatorioVH_005") : this.getMsg("FP_FRONT_RelatorioVH_006");
		const estiloBtn = possuiFiltros? 'btn-primary' : 'btn-white';
		const a = ` 
			<a class='btn ${estiloBtn} btn-table'
			   onclick='relatorioVH.toggleExibicaoFiltrosProvasRespondidas(${!isExibir})'
			   title='${textoTooltip}'>
				<i class='fa fa-search'></i>
			</a>
		`
		container.html(a);
		this.exibir()
	}

	async realizarFiltro() {
		const cfgsRelIndiceRespostasTO = relatorioVH.getCfgsRelIndiceRespostasTO();

		let resultadoDesempenhoTO = await amaisVH.call({
			endpoint: "RelatorioDesempenhoFCD/gerar",
			params: [cfgsRelIndiceRespostasTO],
			blockUiOnCarregando: true,
		});
		await relDesempenhoVH.exibirTabelaEGrafico(cfgsRelIndiceRespostasTO, resultadoDesempenhoTO);

		let resultadoAcertosErrosTO = await amaisVH.call({
			endpoint: "RelatorioAcertosErrosFCD/gerar",
			params: [cfgsRelIndiceRespostasTO],
			blockUiOnCarregando: true,
		});
		await relAcertosErrosVH.exibirTabelaEGrafico(cfgsRelIndiceRespostasTO, resultadoAcertosErrosTO);

		let resultadoErrosAnuladasTO = await amaisVH.call({
			endpoint: "RelatorioErrosAnuladasFCD/gerar",
			params: [cfgsRelIndiceRespostasTO],
			blockUiOnCarregando: true,
		});
		await relErrosAnuladasVH.exibirTabelaEGrafico(cfgsRelIndiceRespostasTO, resultadoErrosAnuladasTO);
	}

	async realizarFiltroProvasRespondidas() {
		const cfgsConsultaIndicePFsTO = relatorioVH.getCfgsConsultaIndicePFsTO();

		let resultadoHistogramaTO = await amaisVH.call({
			endpoint: "RelatorioHistogramaFCD/gerar",
			params: [cfgsConsultaIndicePFsTO],
			blockUiOnCarregando: true,
		});
		await relHistogramaVH.exibirTabelaEGrafico(cfgsConsultaIndicePFsTO, resultadoHistogramaTO);
	}

	getBotaoExibicaoTabela(tabela: string) {
		return `
				<span id="btn-${tabela}">
					<a class='btn btn-info btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_003')}" onclick="relatorioVH.toggleExibicaoTabela(true, '${tabela}')"><i class='fa fa-table'></i></a>
				</span>
		`;
	}

	async exibirEstatisticasAlunos(){
		this.limpar(true);

		if(this.isAluno()){
			// this.addSubtitulo({
			// 	texto: this.getMsg("MSG_VH_RL_05"),
			// 	cssContainer: 'margin: 5px 0;padding: 5px 0;'
			// });
			await relDesempenhoVH.exibirRelDesempenhoPorUsuario();
		} else {
			const containerBotaoExibicaoFiltrosRespostas = `
				<span id="btn-filtros-respostas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosRespostas(true)"><i class='fa fa-search'></i></a>
				</span>
			`;

			this.addTitulo({texto: this.getMsg("FP_FRONT_RelatorioVH_007"), id: "secao_respostas", textoADireita: containerBotaoExibicaoFiltrosRespostas});

			await this.addFiltrosRespostas();

			this.append("<div style='padding-left: 15px; margin-top: 30px'>")
			this.addSubtitulo({
				texto: this.getMsg("MSG_VH_RL_05"),
				cssContainer: 'margin: 5px 0;padding: 5px 0;',
				htmlDireita: this.getBotaoExibicaoTabela('tabela_desempenho')
			});
			await relDesempenhoVH.exibirRelDesempenhoPorUsuario();
			this.append('</div>')

			this.append("<div style='padding-left: 15px; margin-top: 30px'>")
			this.addSubtitulo({
				texto: this.getMsg("MSG_VH_RL_01"),
				cssContainer: 'margin: 5px 0;padding: 5px 0;',
				htmlDireita: this.getBotaoExibicaoTabela('tabela_acertos_erros')
			});
			await relAcertosErrosVH.exibirRelatorioAcertosErrosPorUsuario();
			this.append('</div>')

			this.append("<div style='padding-left: 15px; margin-top: 30px'>")
			this.addSubtitulo({
				texto: this.getMsg("MSG_VH_RL_15"),
				cssContainer: 'margin: 5px 0;padding: 5px 0;',
				htmlDireita: this.getBotaoExibicaoTabela('tabela_erros_anuladas')
			});
			await relErrosAnuladasVH.exibirRelErrosAnuladasPorUsuario();
			this.append('</div>')

			const containerBotaoExibicaoFiltrosProvasRespondidas = `
				<span id="btn-filtros-provas-respondidas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosProvasRespondidas(true)"><i class='fa fa-search'></i></a>
				</span>
			`;

			this.addTitulo({texto: this.getMsg('FP_FRONT_RelatorioVH_008'), id: "secao_provas_respondidas", textoADireita: containerBotaoExibicaoFiltrosProvasRespondidas});

			await this.addFiltrosProvasRespondidas();

			this.append("<div style='padding-left: 15px; margin-top: 30px'>")
			this.addSubtitulo({
				texto: this.getMsg("MSG_VH_RL_11"),
				cssContainer: 'margin: 5px 0;padding: 5px 0;',
				htmlDireita: this.getBotaoExibicaoTabela('tabela_histograma')
			});
			await relHistogramaVH.exibirRelHistogramaPorUsuario();
			this.append("</div>")
		}

		this.exibir();
	}

	async exibirEstatisticasProvas(){
		this.limpar(true);

		const containerBotaoExibicaoFiltrosRespostas = `
				<span id="btn-filtros-respostas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosRespostas(true)"><i class='fa fa-search'></i></a>
				</span>
		`;

		this.addTitulo({texto: this.getMsg("FP_FRONT_RelatorioVH_007"), id: "secao_respostas", textoADireita: containerBotaoExibicaoFiltrosRespostas});

		await this.addFiltrosRespostas();

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_05"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_desempenho')
		});
		await relDesempenhoVH.exibirRelDesempenhoPorProva();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_01"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_acertos_erros')
		});
		await relAcertosErrosVH.exibirRelatorioAcertosErrosPorProva();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_15"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_erros_anuladas')
		});
		await relErrosAnuladasVH.exibirRelErrosAnuladasPorProva();
		this.append('</div>')

		const containerBotaoExibicaoFiltrosProvasRespondidas = `
				<span id="btn-filtros-provas-respondidas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosProvasRespondidas(true)"><i class='fa fa-search'></i></a>
				</span>
		`;

		this.addTitulo({texto: this.getMsg('FP_FRONT_RelatorioVH_008'), id: "secao_provas_respondidas", textoADireita: containerBotaoExibicaoFiltrosProvasRespondidas});

		await this.addFiltrosProvasRespondidas();

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_11"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_histograma')
		});
		await relHistogramaVH.exibirRelHistogramaPorProva();
		this.append("</div>")

		this.exibir();
	}

	async exibirEstatisticasDisciplinas(){
		this.limpar(true);

		const containerBotaoExibicaoFiltrosRespostas = `
				<span id="btn-filtros-respostas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosRespostas(true)"><i class='fa fa-search'></i></a>
				</span>
		`;

		this.addTitulo({texto: this.getMsg("FP_FRONT_RelatorioVH_007"), id: "secao_respostas", textoADireita: containerBotaoExibicaoFiltrosRespostas});

		await this.addFiltrosRespostas();

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_05"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_desempenho')
		});
		await relDesempenhoVH.exibirRelDesempenhoPorDisciplina();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_01"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_acertos_erros')
		});
		await relAcertosErrosVH.exibirRelatorioAcertosErrosPorDisciplina();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_15"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_erros_anuladas')
		});
		await relErrosAnuladasVH.exibirRelErrosAnuladasPorDisciplina();
		this.append('</div>')

		this.exibir();
	}

	async exibirEstatisticasTurmas(){
		this.limpar(true);

		const containerBotaoExibicaoFiltrosRespostas = `
				<span id="btn-filtros-respostas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosRespostas(true)"><i class='fa fa-search'></i></a>
				</span>
		`;

		this.addTitulo({texto: this.getMsg("FP_FRONT_RelatorioVH_007"), id: "secao_respostas", textoADireita: containerBotaoExibicaoFiltrosRespostas});

		await this.addFiltrosRespostas();

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_05"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_desempenho')
		});
		await relDesempenhoVH.exibirRelDesempenhoPorTurma();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_01"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_acertos_erros')
		});
		await relAcertosErrosVH.exibirRelatorioAcertosErrosPorTurma();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_15"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_erros_anuladas')
		});
		await relErrosAnuladasVH.exibirRelErrosAnuladasPorTurma();
		this.append('</div>')

		const containerBotaoExibicaoFiltrosProvasRespondidas = `
				<span id="btn-filtros-provas-respondidas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosProvasRespondidas(true)"><i class='fa fa-search'></i></a>
				</span>
		`;

		this.addTitulo({texto: this.getMsg('FP_FRONT_RelatorioVH_008'), id: "secao_provas_respondidas", textoADireita: containerBotaoExibicaoFiltrosProvasRespondidas});

		await this.addFiltrosProvasRespondidas();

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_11"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_histograma')
		});
		await relHistogramaVH.exibirRelHistogramaPorTurma();
		this.append("</div>")

		this.exibir();
	}

	async exibirEstatisticasQuestoes(){
		this.limpar(true);

		const containerBotaoExibicaoFiltrosRespostas = `
				<span id="btn-filtros-respostas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosRespostas(true)"><i class='fa fa-search'></i></a>
				</span>
		`;

		this.addTitulo({texto: this.getMsg("FP_FRONT_RelatorioVH_007"), id: "secao_respostas", textoADireita: containerBotaoExibicaoFiltrosRespostas});

		await this.addFiltrosRespostas();

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_05"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_desempenho')
		});
		await relDesempenhoVH.exibirRelDesempenhoPorQuestao();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_01"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_acertos_erros')
		});
		await relAcertosErrosVH.exibirRelatorioAcertosErrosPorQuestao();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_15"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_erros_anuladas')
		});
		await relErrosAnuladasVH.exibirRelErrosAnuladasPorQuestao();
		this.append('</div>')

		this.exibir();
	}

	async exibirEstatisticasSegmentos(){
		this.limpar(true);

		const containerBotaoExibicaoFiltrosRespostas = `
				<span id="btn-filtros-respostas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosRespostas(true)"><i class='fa fa-search'></i></a>
				</span>
		`;

		this.addTitulo({texto: this.getMsg("FP_FRONT_RelatorioVH_007"), id: "secao_respostas", textoADireita: containerBotaoExibicaoFiltrosRespostas});

		await this.addFiltrosRespostas();

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_05"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_desempenho')
		});
		await relDesempenhoVH.exibirRelDesempenhoPorSegmento();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_01"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_acertos_erros')
		});
		await relAcertosErrosVH.exibirRelatorioAcertosErrosPorSegmento();
		this.append('</div>')

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_15"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_erros_anuladas')
		});
		await relErrosAnuladasVH.exibirRelErrosAnuladasPorSegmento();
		this.append('</div>')

		const containerBotaoExibicaoFiltrosProvasRespondidas = `
				<span id="btn-filtros-provas-respondidas-container">
					<a class='btn btn-white btn-table' title="${this.getMsg('FP_FRONT_RelatorioVH_006')}" onclick="relatorioVH.toggleExibicaoFiltrosProvasRespondidas(true)"><i class='fa fa-search'></i></a>
				</span>
			`;

		this.addTitulo({texto: this.getMsg('FP_FRONT_RelatorioVH_008'), id: "secao_provas_respondidas", textoADireita: containerBotaoExibicaoFiltrosProvasRespondidas});

		await this.addFiltrosProvasRespondidas();

		this.append("<div style='padding-left: 15px; margin-top: 30px'>")
		this.addSubtitulo({
			texto: this.getMsg("MSG_VH_RL_11"),
			cssContainer: 'margin: 5px 0;padding: 5px 0;',
			htmlDireita: this.getBotaoExibicaoTabela('tabela_histograma')
		});
		await relHistogramaVH.exibirRelHistogramaPorSegmento();
		this.append("</div>")

		this.exibir();
	}
	
	async exibirEstatisticasDeAcessos() {

		UtilHash.registrarHistorico(this.exibirEstatisticasDeAcessos);

		const collectionRelatorioAcessoGeralPorEmpresaTO = await this.call("RelatorioFCD/gerarRelatorioDeAcessoGeral");
		
		this.limpar();
		
		this.setTitulo(this.getMsg("MSG_VH_RL_18"));

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_RL_19"), prop: "empresa", classe: "descricao"});
		colunas.push({titulo: this.getMsg("MSG_VH_RL_20"), prop: "hostDominio"});
		colunas.push({titulo: this.getCfg("LABEL_ALUNO") + " " + this.getMsg("MSG_VH_RL_22"), prop: "totalDeAlunosCadastrados"});
		colunas.push({titulo: this.getMsg("MSG_VH_RL_21") + " " + this.getCfg("LABEL_ALUNO").toLowerCase() + " " + this.getMsg("MSG_VH_RL_71"), prop: "loginsPorAluno"});
		colunas.push({titulo: "% " + this.getMsg("MSG_VH_RL_23") + " " + this.getCfg("LABEL_ALUNO").toLowerCase() + " " + this.getMsg("MSG_VH_RL_24"), prop: "porcentagemDeAlunosQueAcessaram", formato: "porcentagem"});

		await this.addTabela({
			collection: collectionRelatorioAcessoGeralPorEmpresaTO,
			propId: "codEmpresa",
			onEdicao: this.gerarRelatorioMensalPorEmpresa,
			colunas: colunas
		});
		
		this.exibir();
	}
	
	async gerarRelatorioMensalPorEmpresa(codEmpresa) {
		
		UtilHash.registrarHistorico(this.gerarRelatorioMensalPorEmpresa, codEmpresa);

		const relatorioAcessoPorEmpresaTO = await this.call({
			endpoint: "RelatorioFCD/gerarRelatorioMensalPorEmpresa", 
			params: [codEmpresa],
			msgCarregando: this.getMsg("MSG_VH_RL_25") + "..."
		});

		this.limpar();
		
		this.setTitulo(this.getMsg("MSG_VH_RL_27") + " " + relatorioAcessoPorEmpresaTO.nomeEmpresa);

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_RL_28"), prop: "ano"});
		colunas.push({titulo: this.getMsg("MSG_VH_RL_29"), prop: "mes"});
		colunas.push({titulo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO") + " " + this.getMsg("MSG_VH_RL_30"), prop: "numAlunosHabilitados"});
		colunas.push({titulo: this.getCfg("LABEL_MENU_CADASTRO_ALUNO") + " " + this.getMsg("MSG_VH_RL_31"), prop: "numAlunosQueAcessaram"});
		colunas.push({titulo: this.getMsg("MSG_VH_RL_32"), prop: "numAcessos"});
		colunas.push({titulo: this.getMsg("MSG_VH_RL_33") + " " + this.getCfg("LABEL_ALUNO").toLowerCase() + " " + this.getMsg("MSG_VH_RL_34"), prop: "mediaDeLoginAlunosHabilitados", formato: "numero"});
		colunas.push({titulo: "% " + this.getMsg("MSG_VH_RL_35"), prop: "porcentagemAlunosHabilitadosQueAcessaram", formato: "porcentagem"});

		await this.addTabela({
			collection: relatorioAcessoPorEmpresaTO.collectionRelatorioAcessoPorEmpresaPorMesTO,
			colunas: colunas,
			ordenacao: [["ano",0],["mes",0]]
		});
		
		this.exibir();
	}

	getFiltroRelatorioTO() {

		const filtroRelatorioTO: any = {
			codsTurmas: this.getValor("turma"),
			codsBancas: this.getValor("banca"),
			periodoInicial: this.getValor("periodo_inicio"),
			periodoFinal: this.getValor("periodo_fim"),
			codsDisciplinas: this.getValor("disciplina"),
			palavraChave: this.getValor("palavra_chave"),
			codsUsuarios: this.getValor("aluno"),
			codsProvas: this.getValor("prova"),
			codsOrgaos: this.getValor("orgao"),
			anos: this.getValor("ano"),
			codsEmpresas: this.getValor("filial"),
			porcMinimaErro: this.getValor("porcMinimaErro"),
			ocultarQuestoesAnuladas: this.getValor("ocultarQuestoesAnuladas"),
			mostrarHistoricoQuestao: this.getValor("mostrarHistoricoQuestao"),
			isVazio: function() {
				for (var prop in this) {
					var valor = this[prop];
					
					if (!valor || typeof valor == "function" || relatorioVH.isEmpty(valor)) continue;
					
					return false;
				}
				return true;
			},
			selecionados: {
				alunos: this.getTOSelectSelecionado("aluno"),
				segmentos: this.getTOSelectSelecionado("filial"),
				disciplinas: this.getTOSelectSelecionado("disciplina"),
				provas: this.getTOSelectSelecionado("prova"),
				bancas: this.getTOSelectSelecionado("banca"),
				turmas: this.getTOSelectSelecionado("turma"),
				orgaos: this.getTOSelectSelecionado("orgao"),
				anos: this.getTOSelectSelecionado("ano"),
			}
		}

		if (filtroRelatorioTO.porcMinimaErro == null) {
			filtroRelatorioTO.porcMinimaErro = 50;
		}

		if (this.isEmpty(filtroRelatorioTO.periodoInicial) && this.isEmpty(filtroRelatorioTO.periodoFinal)) {
			filtroRelatorioTO.periodoFinal = Date.now();
			filtroRelatorioTO.periodoInicial = UtilData.add(filtroRelatorioTO.periodoFinal, {meses: -1});
		}
		
		return filtroRelatorioTO;
	}

	possuiFiltros(idRelatorio) {
		var descricoes = {};
		
		if (this.descricoesDosFiltros != null) {
			descricoes = this.descricoesDosFiltros[idRelatorio] || {};
		}
		
		for (var id in descricoes) {
			if (id == "nomeAluno" && this.isAluno()) continue;
			if (this.hasValue(id) && this.hasValue(descricoes[id])) {
				return true;
			}
		}
	}
	
	exibirCriterio(label, texto) {
		if (! this.isEmpty(texto)) {
			this.addCampoExibicao({ label, valor: texto })
		}
	}

	async addFiltrosRespostas() {
		const relatorio = $('#tituloSuperiorDireito').find('li.active').attr('id');

		this.append(`<div class="fp-simple-card" id="filtro_secao_respostas_${relatorio}" style="display: none">`);
		this.append(`
			<span class="fp-simple-card__label">
				${this.getMsg("FP_FRONT_RelatorioVH_010")}
			</span>
		`)

		this.append('<div class="fp-simple-card__content">')
		this.append('<div class="row">')
		await this.addSelect({
			id: `secao_respostas_${relatorio}_${CampoRelRespostas.COD_PROVA}`,
			dica: this.getMsg("MSG_VH_RL_38"),
			propId: "id",
			propLabel: "descricao",
			classe: "col-md-6",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoRelRespostas: CampoRelRespostas.NOME_PROVA
					}
				},
				numMinimoCaracteres: 3,
			}
		})

		await this.addSelect({
			id: `secao_respostas_${relatorio}_${CampoRelRespostas.CODS_DISCIPLINAS}`,
			dica: this.getMsg("MSG_VH_RL_39"),
			classe: "col-md-6",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoRelRespostas: CampoRelRespostas.NOMES_DISCIPLINAS
					}
				},
				numMinimoCaracteres: 3,
			}
		});

		if (this.isAdministrador() && this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				id: `secao_respostas_${relatorio}_${CampoRelRespostas.COD_EMPRESA_USUARIO}`,
				dica: this.getMsg("FP_FRONT_RelatorioAcertosErrosVH_001"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOME_EMPRESA_USUARIO
						}
					},
					numMinimoCaracteres: 0,
				}
			});
			await this.addSelect({
				id: `secao_respostas_${relatorio}_${CampoRelRespostas.COD_EMPRESA_AGENDAMENTO}`,
				dica: this.getMsg("FP_FRONT_RelatorioAcertosErrosVH_002"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOME_EMPRESA_AGENDAMENTO
						}
					},
					numMinimoCaracteres: 0,
				}
			});
		}

		if (this.isAdministrador() || (!this.isAluno() && !this.isCfgHabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL"))) {
			await this.addSelect({
				id: `secao_respostas_${relatorio}_${CampoRelRespostas.COD_USUARIO}`,
				dica: this.getCfg("LABEL_ALUNO"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOME_USUARIO
						}
					},
					numMinimoCaracteres: 3,
				}
			})

			await this.addSelect({
				id: `secao_respostas_${relatorio}_${CampoRelRespostas.CODS_TURMAS_USUARIO}`,
				dica: this.getCfg("LABEL_TURMA"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOMES_TURMAS_USUARIO
						}
					},
					numMinimoCaracteres: 3,
				}
			});
		}

		await this.addSelect({
			id: `secao_respostas_${relatorio}_${CampoRelRespostas.NUM_QUESTAO_NA_PROVA}`,
			dica: this.getMsg("FP_FRONT_DataWarehouseVH_001"),
			propId: "id",
			propLabel: "descricao",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoRelRespostas: CampoRelRespostas.NUM_QUESTAO_NA_PROVA
					}
				},
				numMinimoCaracteres: 0
			}
		});

		if (!this.isAluno()) {
			this.addCampoTexto({
				id: `secao_respostas_${relatorio}_palavra_chave`,
				dica: this.getMsg("MSG_VH_RL_50"),
				prefixo: "<i class='fa fa-search'></i> ",
				onEnter: this.realizarFiltro,
			});
		}

		this.addCamposPeriodo({
			dica: this.getMsg("FP_FRONT_RelatorioVH_009"),
			idInicio: `secao_respostas_${relatorio}_periodo_inicio`,
			idFim: `secao_respostas_${relatorio}_periodo_fim`,
			labelFim: this.getMsg("MSG_VH_RL_49"),
			onChange: this.realizarFiltro,
		});

		this.append("</div>");

		this.addBotao({
			label: this.getMsg("MSG_VH_RL_37"),
			classe: "btn-primary pull-right",
			onClick: this.realizarFiltro,
		});

		this.fecharFormulario();
		this.append('</div>');
		this.append('</div>');
	}

	async addFiltrosProvasRespondidas() {
		this.append('<div class="fp-simple-card" id="filtro_secao_provas_respondidas" style="display: none">');
		this.append(`
			<span class="fp-simple-card__label">
				${this.getMsg("FP_FRONT_RelatorioVH_011")}
			</span>
		`)

		this.append('<div class="fp-simple-card__content">')
		this.append('<div class="row">')

		await this.addSelect({
			id: CampoIndicePFs.COD_PROVA.id,
			dica: this.getMsg("MSG_VH_RL_38"),
			propId: "id",
			propLabel: "descricao",
			classe: "col-md-6",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoIndicePFs: CampoIndicePFs.NOME_PROVA,
					}
				},
				numMinimoCaracteres: 3,
			}
		});


		if (this.isAdministrador() && this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				id: CampoIndicePFs.COD_EMPRESA_AGENDAMENTO.id,
				dica: this.getMsg("FP_FRONT_RelatorioAcertosErrosVH_002"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoIndicePFs: CampoIndicePFs.NOME_EMPRESA_AGENDAMENTO,
						}
					},
					numMinimoCaracteres: 0,
				}
			});

			await this.addSelect({
				id: CampoIndicePFs.COD_EMPRESA_USUARIO.id,
				dica: this.getMsg("FP_FRONT_RelatorioAcertosErrosVH_001"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoIndicePFs: CampoIndicePFs.NOME_EMPRESA_USUARIO,
						}
					},
					numMinimoCaracteres: 0,
				}
			});
		}

		await this.addSelect({
			id: CampoIndicePFs.TIPO_APLICACAO.id,
			dica: CampoIndicePFs.TIPO_APLICACAO.getNome(),
			propId: "id",
			propLabel: "descricao",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoIndicePFs: CampoIndicePFs.TIPO_APLICACAO,
					}
				},
				numMinimoCaracteres: 0,
			}
		});

		await this.addSelect({
			id: CampoIndicePFs.COD_AGENDAMENTO.id,
			dica: CampoIndicePFs.COD_AGENDAMENTO.getNome(),
			propId: "id",
			propLabel: "descricao",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoIndicePFs: CampoIndicePFs.NOME_APLICACAO,
					}
				},
				numMinimoCaracteres: 0,
			}
		});

		if (this.isAdministrador() || (!this.isAluno() && !this.isCfgHabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL"))) {
			await this.addSelect({
				id: CampoIndicePFs.COD_TURMA_NA_APLICACAO.id,
				dica: this.getCfg("LABEL_TURMA"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoIndicePFs: CampoIndicePFs.NOME_TURMA_NA_APLICACAO,
						}
					},
					numMinimoCaracteres: 0,
				}
			});

			await this.addSelect({
				id: CampoIndicePFs.COD_USUARIO.id,
				dica: this.getCfg("LABEL_ALUNO"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoIndicePFs: CampoIndicePFs.NOME_USUARIO,
						}
					},
					numMinimoCaracteres: 3,
				}
			});
		}

		this.addCampoTexto({
			id: "palavra_chave",
			dica: this.getMsg("MSG_VH_RL_50"),
			prefixo: "<i class='fa fa-search'></i> ",
			onEnter: this.realizarFiltroProvasRespondidas,
		});

		this.addCamposPeriodo({
			dica: this.getMsg("FP_FRONT_RelatorioVH_012"),
			idInicio: "periodo_inicio",
			idFim: "periodo_fim",
			labelFim: this.getMsg("MSG_VH_RL_49"),
			onChange: this.realizarFiltroProvasRespondidas,
		});

		this.addEspacamentoHorizontal("1px");

		this.addCheckbox({
			id: CampoIndicePFs.IS_ANULADA.id,
			label: CampoIndicePFs.IS_ANULADA.getNome(),

		});

		this.addCheckbox({
			id: CampoIndicePFs.IS_ARQUIVADA.id,
			label: CampoIndicePFs.IS_ARQUIVADA.getNome(),

		});

		this.addCheckbox({
			id: "isExibirSomentePFDaAU",
			label: this.getMsg("FP_FRONT_RelatorioVH_013"),

		});

		this.append("</div>");

		this.addBotao({
			label: this.getMsg("MSG_VH_RL_37"),
			classe: "btn-primary pull-right",
			onClick: this.realizarFiltroProvasRespondidas,
		});

		this.fecharFormulario();
		this.append('</div>');
		this.append('</div>');
	}

	addNotNull(to, propObj, chave, valor) {
		if (valor === null) return;
		if (!Array.isArray(valor)) valor = [valor];
		if (valor.length == 0) return;
		propObj = propObj.id || propObj;
		to[propObj] = to[propObj] || {};
		const obj = to[propObj];
		chave = chave.id || chave;
		if (!obj[chave]) {
			obj[chave] = valor;
		} else {
			obj[chave] = obj[chave].concat(valor);
		}
	}

	getArrayTOsSelect(campo: any) {
		let tos = this.getTOsSelecionadosSelect(campo.id || campo);
		if (!tos) return null;
		if (!Array.isArray(tos)) tos = [tos];
		return tos.map(to => {
			return {
				id: to.id,
				text: to.text
			}
		})
	}

	getCfgsRelIndiceRespostasTO(): CfgsRelIndiceRespostasTO {

		const abaRelatorio = $('#tituloSuperiorDireito').find('li.active').attr('id');
		let campoAgrupamento: CampoRelRespostas;

		if (abaRelatorio == 'estatiticasAbaAlunos') {
			campoAgrupamento = CampoRelRespostas.NOME_USUARIO;
		} else if (abaRelatorio == 'estatiticasAbaProvas') {
			campoAgrupamento = CampoRelRespostas.NOME_PROVA;
		} else if (abaRelatorio == 'estatiticasAbaDisciplinas') {
			campoAgrupamento = CampoRelRespostas.NOMES_DISCIPLINAS;
		}else if (abaRelatorio == 'estatiticasAbaTurmas') {
			campoAgrupamento = CampoRelRespostas.NOME_TURMA_NA_APLICACAO;
		} else if (abaRelatorio == 'estatiticasAbaQuestoes') {
			campoAgrupamento = CampoRelRespostas.CONTEUDO_QUESTAO;
		} else if (abaRelatorio == 'estatiticasAbaSegmentos') {
			campoAgrupamento = CampoRelRespostas.NOME_EMPRESA_USUARIO;
		}

		const to: CfgsRelIndiceRespostasTO = {};
		const filtrosTO: FiltrosIndiceRespostasTO = {};

		let idElementos = `secao_respostas_${abaRelatorio}_`;
		to.campoAgrupamento = campoAgrupamento.id;
		to.filtrosTO = filtrosTO;

		if (this.getValor(idElementos.concat("palavra_chave"))) {
			filtrosTO.buscaTextual = this.getValor(idElementos.concat("palavra_chave"));
		}

		this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.COD_EMPRESA_USUARIO, this.getValor(idElementos.concat(CampoRelRespostas.COD_EMPRESA_USUARIO.id)));
		this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.COD_EMPRESA_AGENDAMENTO, this.getValor(idElementos.concat(CampoRelRespostas.COD_EMPRESA_AGENDAMENTO.id)));
		this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.COD_USUARIO, this.getValor(idElementos.concat(CampoRelRespostas.COD_USUARIO.id)));
		this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.CODS_TURMAS_USUARIO, this.getValor(idElementos.concat(CampoRelRespostas.CODS_TURMAS_USUARIO.id)));
		this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.COD_PROVA, this.getValor(idElementos.concat(CampoRelRespostas.COD_PROVA.id)));
		this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.CODS_DISCIPLINAS, this.getValor(idElementos.concat(CampoRelRespostas.CODS_DISCIPLINAS.id)));
		this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.CODS_USUARIOS_CORRETORES, this.getValor(idElementos.concat(CampoRelRespostas.CODS_USUARIOS_CORRETORES.id)));
		this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.NUM_QUESTAO_NA_PROVA, this.getValor(idElementos.concat(CampoRelRespostas.NUM_QUESTAO_NA_PROVA.id)));

		if (this.getValor(idElementos.concat("periodo_inicio")) || this.getValor(idElementos.concat("periodo_fim"))) {
			this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.DATA_INICIO_PF, [this.getValor(idElementos.concat("periodo_inicio")), this.getValor(idElementos.concat("periodo_fim"))]);
		}

		if (this.getValor(idElementos.concat("periodo_inicio_envio_discursiva")) || this.getValor(idElementos.concat("periodo_fim_envio_discursiva"))) {
			this.addNotNull(filtrosTO, "ativos", CampoRelRespostas.DATA_ENVIO_DISCURSIVA, [this.getValor(idElementos.concat("periodo_inicio_envio_discursiva")), this.getValor(idElementos.concat("periodo_fim_envio_discursiva"))]);
		}

		this.addNotNull(filtrosTO, "ativosListaTO", CampoRelRespostas.COD_EMPRESA_USUARIO, this.getArrayTOsSelect(idElementos.concat(CampoRelRespostas.COD_EMPRESA_USUARIO.id)));
		this.addNotNull(filtrosTO, "ativosListaTO", CampoRelRespostas.COD_EMPRESA_AGENDAMENTO, this.getArrayTOsSelect(idElementos.concat(CampoRelRespostas.COD_EMPRESA_AGENDAMENTO.id)));
		this.addNotNull(filtrosTO, "ativosListaTO", CampoRelRespostas.COD_USUARIO, this.getArrayTOsSelect(idElementos.concat(CampoRelRespostas.COD_USUARIO.id)));
		this.addNotNull(filtrosTO, "ativosListaTO", CampoRelRespostas.CODS_TURMAS_USUARIO, this.getArrayTOsSelect(idElementos.concat(CampoRelRespostas.CODS_TURMAS_USUARIO.id)));
		this.addNotNull(filtrosTO, "ativosListaTO", CampoRelRespostas.COD_PROVA, this.getArrayTOsSelect(idElementos.concat(CampoRelRespostas.COD_PROVA.id)));
		this.addNotNull(filtrosTO, "ativosListaTO", CampoRelRespostas.CODS_DISCIPLINAS, this.getArrayTOsSelect(idElementos.concat(CampoRelRespostas.CODS_DISCIPLINAS.id)));
		this.addNotNull(filtrosTO, "ativosListaTO", CampoRelRespostas.NUM_QUESTAO_NA_PROVA, this.getArrayTOsSelect(idElementos.concat(CampoRelRespostas.NUM_QUESTAO_NA_PROVA.id)));
		this.addNotNull(filtrosTO, "ativosListaTO", CampoRelRespostas.CODS_USUARIOS_CORRETORES, this.getArrayTOsSelect(idElementos.concat(CampoRelRespostas.CODS_USUARIOS_CORRETORES.id)));

		return to;
	}

	getCfgsConsultaIndicePFsTO(): CfgsConsultaIndicePFsTO {
		const abaRelatorio = $('#tituloSuperiorDireito').find('li.active').attr('id');
		let campoAgrupamento: CampoIndicePFs;
		if (abaRelatorio == 'estatiticasAbaAlunos') {
			campoAgrupamento = CampoIndicePFs.NOME_USUARIO;
		} else if (abaRelatorio == 'estatiticasAbaProvas') {
			campoAgrupamento = CampoIndicePFs.NOME_PROVA;
		} else if (abaRelatorio == 'estatiticasAbaTurmas') {
			campoAgrupamento = CampoIndicePFs.NOME_TURMA_NA_APLICACAO;
		} else if (abaRelatorio == 'estatiticasAbaSegmentos') {
			campoAgrupamento = CampoIndicePFs.NOME_EMPRESA_USUARIO;
		}
		const to = new CfgsConsultaIndicePFsTO();

		to.campoAgrupamento = campoAgrupamento;
		to.isExibirSomentePFDaAU = !this.getValor("isExibirSomentePFDaAU");

		if (this.getValor("palavra_chave")) {
			to.buscaTextualNoNomeDoAgrupamento = this.getValor("palavra_chave");
		}

		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_EMPRESA_USUARIO, this.getValor(CampoIndicePFs.COD_EMPRESA_USUARIO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_EMPRESA_AGENDAMENTO, this.getValor(CampoIndicePFs.COD_EMPRESA_AGENDAMENTO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_USUARIO, this.getValor(CampoIndicePFs.COD_USUARIO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_TURMA_NA_APLICACAO, this.getValor(CampoIndicePFs.COD_TURMA_NA_APLICACAO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_PROVA, this.getValor(CampoIndicePFs.COD_PROVA.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.TIPO_APLICACAO, this.getValor(CampoIndicePFs.TIPO_APLICACAO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_AGENDAMENTO, this.getValor(CampoIndicePFs.COD_AGENDAMENTO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.IS_ANULADA, this.getValor(CampoIndicePFs.IS_ANULADA.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.IS_ARQUIVADA, this.getValor(CampoIndicePFs.IS_ARQUIVADA.id));

		if (this.getValor("periodo_inicio") || this.getValor("periodo_fim")) {
			this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.DATA_INICIO_PF, [this.getValor("periodo_inicio"), this.getValor("periodo_fim")]);
		}

		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_EMPRESA_USUARIO, this.getArrayTOsSelect(CampoIndicePFs.COD_EMPRESA_USUARIO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_EMPRESA_AGENDAMENTO, this.getArrayTOsSelect(CampoIndicePFs.COD_EMPRESA_AGENDAMENTO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_USUARIO, this.getArrayTOsSelect(CampoIndicePFs.COD_USUARIO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_TURMA_NA_APLICACAO, this.getArrayTOsSelect(CampoIndicePFs.COD_TURMA_NA_APLICACAO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_PROVA, this.getArrayTOsSelect(CampoIndicePFs.COD_PROVA));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.TIPO_APLICACAO, this.getArrayTOsSelect(CampoIndicePFs.TIPO_APLICACAO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_AGENDAMENTO, this.getArrayTOsSelect(CampoIndicePFs.COD_AGENDAMENTO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.IS_ANULADA, this.getArrayTOsSelect(CampoIndicePFs.IS_ANULADA));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.IS_ARQUIVADA, this.getArrayTOsSelect(CampoIndicePFs.IS_ARQUIVADA));

		const camposOpcionaisParaExibicao = this.getValor("camposOpcionaisParaExibicao");

		if (camposOpcionaisParaExibicao) {
			to.camposOpcionaisParaExibicao = [];
			for (let campo of camposOpcionaisParaExibicao) {
				to.camposOpcionaisParaExibicao.push(campo);
			}
		}

		return to;
	}
}

const relatorioVH = new RelatorioVH(RelatorioVH.name);

type AbasEstatiticasHabilitadasTO = {
	isExibirAlunos: boolean;
	isExibirProvas: boolean;
	isExibirClassificacoes: boolean;
	isExibirTurmas: boolean;
	isExibirQuestoes: boolean;
	isExibirSegmentos: boolean;
}