class SalvamentoPFVH extends PFVH {
	
	constructor() {
		super(SalvamentoPFVH.name);
	}

	listaDeQuestoesEmSalvamento: SalvamentoRespostaTO[];

	async bindEventosSalvamento() {

		this.listaDeQuestoesEmSalvamento = [];

		$("[item] [opcao]").each((i, button: HTMLButtonElement) => {

			if (button.getAttribute("is-eventos-salvamento-binded")) return;
			
			button.setAttribute("is-eventos-salvamento-binded", "true");

			$(button).on("mouseup", ({target}) => {
				this.tratarRespostaDeQuestao(target, !$(target).is(".active"));
			})
		});

		$("input[cod-questao]").on("change", ({target}) => {
			this.tratarRespostaDeQuestao(target);
		});
		
		$('[is-discursiva][cod-questao] textarea[tipo="discursiva"]').on("blur", async ({target}, callback) => {
			this.tratarRespostaDeQuestao(target);
			if (callback) await callback();
		}).on("input", ({target}) => {
			this.handleOnInputRespostaDiscursiva(target);
		});

		$(`[questao][tipo="DC"][cod-resposta-questao]`).on("fp-questao-shown", async ({target}) => {
			this.buscarRespostaLocal(target);
		});

		$("a.botao_resolver").on("click", ({target}) => {
			this.salvarRespostaEMostrarCorrecao(target);
		});

		$("[questao]").on("atualizar-executar", async (event) => {
			event.preventDefault();
			event.stopPropagation();
			await this.onAtualizarQuestao(event.target);
		});
	}
	
	salvarRespostaEMostrarCorrecao(link) {
		const questao = $(link).closest("[questao]")[0];

		if (this.possuiRespostasParaSalvar()) {

			if ($(link).attr("label-original") == null) {
				$(link).attr("label-original", $(link).text()).text(this.getMsg("MSG_VH_APR_160") + "...");
				this.salvarProvaFeita();
			}

			setTimeout(() => this.salvarRespostaEMostrarCorrecao(link), 300);

			return;
		}

		if ($(link).attr("label-original") != null) {
			$(link).text($(link).attr("label-original"));
			$(link).attr("label-original", null);
		}

		$(questao).trigger("atualizar");
	}

	possuiRespostasParaSalvar() {
		return this.hasValue(this.listaDeQuestoesEmSalvamento);
	}

	getSalvamentoProvaFeitaTO() {

		const $aplicacaoProva = this.get$AplicacaoProva();

		if (!$aplicacaoProva) return null;

		const salvamentoProvaFeitaTO: any = {
			arraySalvamentoRespostaTOs: this.listaDeQuestoesEmSalvamento,
			codProvaFeita: $aplicacaoProva.attr("cod-prova-feita"),
			codProvaFeitaLogUltimoKA: $aplicacaoProva.attr("cod-prova-feita-log-ultimo-ka"),
			codProvaFeitaLogUltimaQuestaoVisualizada: $aplicacaoProva.attr("cod-prova-feita-log-ultima-questao-visualizada"),
			arraySalvamentoTempoRespostaTO: [],
		}

		$("[questao][fp-teve-tempo-consumido='true']").each((i, questao) => {
			const salvamentoTempoRespostaTO = {
				codRespostaQuestao: $(questao).attr("cod-resposta-questao"),
				numQuestao: $(questao).find("[numerador]:first").attr("numerador"),
				tempoResposta: $(questao).data("fp-tempo-utilizado")
			}

			salvamentoProvaFeitaTO.arraySalvamentoTempoRespostaTO.push(salvamentoTempoRespostaTO);
		});

		return salvamentoProvaFeitaTO;
	}

	async salvarProvaFeita(msg = "", isPausaDeProva = false) {
		
		const $aplicacaoProva = this.get$AplicacaoProva();

		if (!$aplicacaoProva || $aplicacaoProva.data("fp-is-salvando-prova-feita")) {
			return;
		}

		await navegacaoPFVH.forcarBlurRespostaDiscursiva();

		let salvamentoProvaFeitaTOEnvio = this.getSalvamentoProvaFeitaTO();

		if (!salvamentoProvaFeitaTOEnvio || (this.isEmpty(salvamentoProvaFeitaTOEnvio.arraySalvamentoRespostaTOs) && this.isEmpty(salvamentoProvaFeitaTOEnvio.arraySalvamentoTempoRespostaTO))) {
			return false;
		}

		msg = msg || "";

		$aplicacaoProva.data("fp-is-enviando-keep-alive", true);
		$aplicacaoProva.data("fp-is-salvando-prova-feita", true);

		try {
			const salvamentoProvaFeitaTORecebido = await this.call({
				endpoint: "AplicacaoProvaFCD/salvarProvaFeita",
				params: [salvamentoProvaFeitaTOEnvio, false],
				msgCarregando: msg || this.getMsg('FP_FRONT_SalvamentoPFVH_016'),
				blockUiOnCarregando: true,
				onRequestError: async (e: BackendRequestError) => {

					$aplicacaoProva.data("fp-is-enviando-keep-alive", false);
					$aplicacaoProva.data("fp-is-salvando-prova-feita", false);

					if (e.isProvaAnulada() || e.isAplicacaoEncerrada()) {
						encerramentoPFVH.handleProvaAnulada(e.msgErro);
						return BackendRequestError.ERRO_TRATADO;
					}
					
					$("#alerta-pf-com-respostas-nao-salvas").fadeIn(500);

					navegacaoPFVH.habilitarBotoes();

					return BackendRequestError.ERRO_NAO_TRATADO;
				}
			});

			navegacaoPFVH.habilitarBotoes();

			if (isPausaDeProva) return;

			try {
				$('#alerta-pf-com-respostas-nao-salvas').hide();
			} catch (ignored) {}

			try {
				$(".botao-salvar-resposta").fadeOut('slow');
			} catch (ignored) {}

			try {
				let codProvaFeitaLogUltimaQuestaoVisualizada = $aplicacaoProva.attr("cod-prova-feita-log-ultima-questao-visualizada");

				if (!codProvaFeitaLogUltimaQuestaoVisualizada && salvamentoProvaFeitaTORecebido.codProvaFeitaLogUltimaQuestaoVisualizada) {
					$aplicacaoProva.attr("cod-prova-feita-log-ultima-questao-visualizada", salvamentoProvaFeitaTORecebido.codProvaFeitaLogUltimaQuestaoVisualizada);
				}

			} catch (ignored) {}
			
			try {
				pausaPFVH.handleResponsePausa(salvamentoProvaFeitaTORecebido.codProvaFeita, salvamentoProvaFeitaTORecebido);
			} catch (ignored) {}
			
			try {

				if (salvamentoProvaFeitaTORecebido.isTempoEsgotado === true) {
					// não compara pois não salvou nada

				} else {

					try {
						salvamentoProvaFeitaTORecebido.arraySalvamentoRespostaTOs.forEach(
							to => SalvamentoLocalRespostas.remover(to.codRespostaQuestao)
						);
					} catch (ignored) {}
				
					const tosOK = [];
					
					for (const to of this.listaDeQuestoesEmSalvamento) {
						for (const toRecebido of salvamentoProvaFeitaTORecebido.arraySalvamentoRespostaTOs) {
							if (toRecebido.idSalvamento == to.idSalvamento) {
								tosOK.push(to);
								break;
							}
						}
					}
					
					for (const toListaSalvamento of tosOK) {
						this.listaDeQuestoesEmSalvamento = this.listaDeQuestoesEmSalvamento.filter(to => to !== toListaSalvamento);
					}
					
					for (const salvamentoTempoRespostaTO of salvamentoProvaFeitaTORecebido.arraySalvamentoTempoRespostaTO) {
						const $questao = $(`[questao][cod-resposta-questao="${salvamentoTempoRespostaTO.codRespostaQuestao}"]`);
						
						if ($questao.length > 0 && $questao.data("fp-tempo-utilizado") == salvamentoTempoRespostaTO.tempoResposta) {
							$questao.removeAttr("fp-teve-tempo-consumido");
						}
					}
					
					if (this.listaDeQuestoesEmSalvamento.length == 0) {
						setTimeout(function() {
							$aplicacaoProva.trigger("todasRespostasSalvas");
						}, 600)
					}
				}
				
			} catch (erro) {
				this.logger.error(this.getMsg("FP_FRONT_SalvamentoPFVH_001"), erro);
			}
			
			$aplicacaoProva.data("fp-segundos-sem-keep-alive", 0);

		} catch (ignored) {

		} finally {
			$aplicacaoProva.data("fp-is-enviando-keep-alive", false);
			$aplicacaoProva.data("fp-is-salvando-prova-feita", false);
		}

		return true;
	}

	salvarRespostasPendentes() {
		$("#alerta-pf-com-respostas-nao-salvas").fadeOut(500, () => {
			const numPendentes = this.listaDeQuestoesEmSalvamento.length;
			this.salvarProvaFeita(numPendentes <= 1 ? this.getMsg("MSG_VH_APR_60") + "..." : this.getMsg("MSG_VH_APR_60") + " " + numPendentes + " " + this.getMsg("MSG_VH_APR_61") + "...");
		});
	}
	
	/**
	 * @returns se o encerramento foi feito com sucesso
	 */
	async verificarSalvamentoEEncerrar(encerradaPeloCronometro: boolean, msg?: string): Promise<boolean> {

		await navegacaoPFVH.forcarBlurRespostaDiscursiva();
		
		const $aplicacaoProva = this.get$AplicacaoProva();
		
		if (encerramentoPFVH.isEncerrada($aplicacaoProva)) return false;
		
		const numQuestoesEmBranco = $("[painel-respostas] .item-em-branco").length;
		const isImpedirFinalizarComEmBranco = $aplicacaoProva.data('fp-impedir-respostas-em-branco-antes-finalizacao');
		const isBloquearJaVisualizadas = $aplicacaoProva.data('fp-deve-bloquear-ja-visualizadas');
		
		if (encerradaPeloCronometro !== true && numQuestoesEmBranco > 0 && isImpedirFinalizarComEmBranco) {
			
			await this.exibirAlerta({
				titulo: "<i class='fa fa-pencil-square-o'></i> " + this.getMsg("MSG_VH_APR_178"),
				msg: this.getMsg("MSG_VH_APR_179"),
				botoes: [{label: "OK"}]
			});
			
			UtilWindow.scrollTo("[painel-respostas]");
			
			return false;
			
		} else {
			
			const salvamentoProvaFeitaTO = this.getSalvamentoProvaFeitaTO();

			if (!salvamentoProvaFeitaTO) return false;
			
			const msgConfirmacao = msg || this.getMsg("MSG_VH_APR_153");
			
			if (encerradaPeloCronometro === true) {

				this.logger.info("Chamando encerrarProva com encerradaPeloCronometro true");

				const finalizou = await encerramentoPFVH.encerrarProva(salvamentoProvaFeitaTO.codProvaFeita, true);

				if (finalizou) {
					await this.exibirAlerta({
						titulo: "<i class='fa fa-clock-o'></i> " + this.getMsg("MSG_VH_APR_155"),
						msg: this.getMsg("MSG_VH_APR_156"),
						botoes: [{label: "OK"}]
					});
				}

				return finalizou;
			}

			if (encerramentoPFVH.isEncerrando($aplicacaoProva)) {
				this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_SalvamentoPFVH_002")
				});
				return false;
			}

			const msgEmBranco = numQuestoesEmBranco > 0 && !isBloquearJaVisualizadas ? "<p>" + this.getMsg("MSG_VH_APR_187", numQuestoesEmBranco) + "</p>" : "";

			let resolveBoolean;
			let finalizou = false;
			const promise = new Promise<boolean>((resolve) => {
				resolveBoolean = resolve;
			});

			this.exibirAlerta({
				msg: msgConfirmacao + msgEmBranco,
				onHidden: () => resolveBoolean(finalizou),
				botoes: [{
					id: "bntCancelarConcluirProva",
					label: "Retomar"
				},{
					id: "bntConfirmacaoConcluirProva",
					label: this.getMsg("MSG_VH_APR_157"),
					classe: "btn-primary",
					onClick: async () => {
						finalizou = await encerramentoPFVH.encerrarProva(salvamentoProvaFeitaTO.codProvaFeita, false);
					}
				}]
			});

			return promise;
		}
	}
	
	tratarRespostaDeQuestao(input: HTMLInputElement, isDesmarcacao = false) {

		const $input = $(input); 
		const valorDoInput = $input.val();

		if (valorDoInput == null) {
			return;
		}
		
		const $questao = $input.closest("[questao]");
		const $item = $input.closest("[item]");
		const codRespostaQuestao = $questao.attr("cod-resposta-questao");

		let codItem: number = <number> UtilNumero.from($item.attr("cod-item"));
		let questaoTipo: QuestaoTipo;
		let respostaValorExato = null;
		let respostaVF: boolean = null;
		let respostaDoItem: string;
		let respostaDiscursiva: string;
		let numLinhasPreenchidas: number = null;

		if ($questao.is("[ME]")) {
			questaoTipo = QuestaoTipo.ME;
			respostaDoItem = $input.attr("letra");

		} else if ($questao.is("[CE]")) {
			if (valorDoInput == "E") {
				respostaVF = false;
				respostaDoItem = "E";
				questaoTipo = QuestaoTipo.CE;

			} else if (valorDoInput == "C") {
				respostaVF = true;
				respostaDoItem = "C";
				questaoTipo = QuestaoTipo.CE;
			}

		} else if ($questao.is("[tipo='DC']")) {
			try {
				respostaDiscursiva = UtilHtml.converterTextoTextareaParaHtml(this.getValor(input));
			} catch (e) {
				try {
					respostaDiscursiva = this.getValor(input);
				} catch (e2) {
					respostaDiscursiva = input.value;
				}
			}
			questaoTipo = QuestaoTipo.DC;
			respostaDoItem = respostaDiscursiva;
			numLinhasPreenchidas = this.getNumLinhasRespostaDiscursiva($input);

		} else {
			respostaValorExato = $input.val();
			questaoTipo = QuestaoTipo.EX;
			respostaDoItem = respostaValorExato;
		}

		if (isDesmarcacao) {
			respostaDoItem = "";
			if (questaoTipo == "CE") {
				respostaVF = null;

			} else if (questaoTipo == "ME") {
				codItem = null;
			}
		}
		
		const possuiUploadResposta: boolean = $questao.find("[midia-resposta]").length > 0;
		let numeradorResposta;

		if ($questao.is("[CE]")) {
			numeradorResposta = $item.find("[numerador]").attr("numerador");
			$item.data("fp-is-respondido", this.hasValue(respostaDoItem));
		} else {
			numeradorResposta = $questao.find("[numerador]").attr("numerador");
			$questao.data("fp-is-respondida", this.hasValue(respostaDoItem) || possuiUploadResposta);
		}
		
		const $aplicacaoProva = $questao.closest("#divAplicacaoProva");
		const idSalvamento = ($aplicacaoProva.data("id-salvamento-anterior") || 0) + 1;

		$aplicacaoProva.data("id-salvamento-anterior", idSalvamento);

		const salvamentoRespostaTO: SalvamentoRespostaTO = {
			idSalvamento: idSalvamento,
			codItem: codItem,
			codRespostaQuestao: codRespostaQuestao,
			questaoTipo: questaoTipo,
			respostaValorExato: respostaValorExato,
			respostaDiscursiva: respostaDiscursiva,
			respostaVF: respostaVF,
			numeradorResposta: numeradorResposta,
			respostaDoItem: respostaDoItem,
			numLinhasPreenchidas: numLinhasPreenchidas,
			possuiUploadResposta: possuiUploadResposta
		}

		this.listaDeQuestoesEmSalvamento.push(salvamentoRespostaTO);
		
		if (!$aplicacaoProva.data("fp-is-questao-por-questao")) {
			this.salvarProvaFeita();
		}

		const isAnulada = $questao.is("[anulada]");
		const isTempoEsgotado = cronoPFVH.isTempoEsgotado($questao);

		navegacaoPFVH.atualizarItemPainelDeRespostas(numeradorResposta, respostaDoItem, salvamentoRespostaTO, possuiUploadResposta, isAnulada, isTempoEsgotado);
	}
	
	inserirUploadMidiaResposta(exibicaoRQTO) {
		return this.addLink({
			retornarHtml: true, 
			label: `<i class='hidden-xs fa fa-upload fa-lg'></i> ${this.getMsg("FP_FRONT_SalvamentoPFVH_003")}`,
			css: "font-size: 100%;",
			onClick: () => this.exibirInclusaoMidiaResposta(exibicaoRQTO.codRespostaQuestao),
		})
	}

	exibirInclusaoMidiaResposta(codRespostaQuestao: number) {

		let contadorErro = 0;
		
		const anexarMidiaResposta = async () => {
			
			const anexacaoMidiaTO = {
				codRespostaQuestao : codRespostaQuestao,
				pathArquivoUpload: this.getValor("questao_midia"),
				legenda: ""
			}
			
			if (anexacaoMidiaTO.pathArquivoUpload == null) {
				contadorErro++;

				if (contadorErro == 1) {
					await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_SalvamentoPFVH_004") });
					return false;
				} else {
					await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_SalvamentoPFVH_005") });
					return true;
				}
			}
			
			const exibicaoQuestaoTO = await this.call("AplicacaoProvaFCD/anexarMidiaResposta", anexacaoMidiaTO);

			const textarea = <HTMLInputElement> document.querySelector(`[questao][cod-resposta-questao='${codRespostaQuestao}'] textarea[tipo="discursiva"]`);
			this.atualizarMidiasResposta(exibicaoQuestaoTO.exibicaoRQTO);
			this.tratarRespostaDeQuestao(textarea);
		}

		this.addPopup({
			id: "opcoesUploadMidia",
            titulo: this.getMsg("FP_FRONT_SalvamentoPFVH_006"),
            width: "400px",
            botoes: [{label: this.getMsg("FP_FRONT_SalvamentoPFVH_007"), onClick: anexarMidiaResposta}]
		});

		this.setMetodoSubmit(anexarMidiaResposta);

		this.addZonaUpload({
			id: "questao_midia", 
			label: this.getMsg("FP_FRONT_SalvamentoPFVH_008"), 
			classe: "col-md-12 col-lg-12", 
			maxFilesize: 1000, 
			maxWidthOuHeight: 2000,
			arquivosAceitos: this.getCfg("FUNCIONALIDADE_RESTRINGIR_UPLOAD_RESPOSTA_TIPO_ARQUIVO"), 
			css: "height: 270px;",
			onSelecaoArquivosAberta: (div) => {
				setTimeout(async () => {
					if (this.isJanelaUploadAberta()) {
						await monitoramentoPFVH.onSaidaDoNavegador(this.getMsg("FP_FRONT_SalvamentoPFVH_009"));
					}
				}, 45000);
			},
			onErro: () => {
				this.setValor("questao_midia", null);
			}
		});
		
		this.fecharFormulario();
		this.addEspacamentoHorizontal("1px");

		this.exibirPopups()
	}

	atualizarMidiasResposta(exibicaoRQTO) {
		const idEdicaoMidias = "edicao_midias_" + exibicaoRQTO.codRespostaQuestao;
		this.setHtml(idEdicaoMidias, "");
		this.setIdTarget(idEdicaoMidias);
		this.append(exibicaoQuestaoVH.exibirMidiasResposta(exibicaoRQTO, true));
		this.setIdTarget(null);
		this.exibir();
	}
	
	async excluirMidiaResposta(codMidia: number, codRespostaQuestao: number) {
		const exibicaoQuestaoTO = await this.call("AplicacaoProvaFCD/excluirMidiaResposta", codMidia, codRespostaQuestao);
		const textarea = <HTMLInputElement> document.querySelector(`[questao][cod-resposta-questao='${codRespostaQuestao}'] textarea[tipo="discursiva"]`);
		this.atualizarMidiasResposta(exibicaoQuestaoTO.exibicaoRQTO);
		this.tratarRespostaDeQuestao(textarea);
	}
	
	async onAtualizarQuestao(questao) {
		const codQuestao = $(questao).attr("cod-questao");
		const codProvaFeita = $(questao).closest("[cod-prova-feita]").attr("cod-prova-feita");
		const exibicaoQuestaoTO = await this.call("AplicacaoProvaFCD/recuperarCorrecao", codProvaFeita, codQuestao);
		exibicaoQuestaoVH.atualizarExibicaoQuestao(questao, exibicaoQuestaoTO);
	}

	async handleOnClickBotaoSalvarResposta() {
		await this.salvarProvaFeita();
	}

	buscarRespostaLocal(questao) {

		if (!questao) return;

		const $questao = $(questao);
		const $textarea = $questao.find(`textarea[tipo="discursiva"]`);
		const $formGroup = $textarea.closest(".form-group[is-discursiva]");
		const salvamentoLocalRespostaDiscursiva = SalvamentoLocalRespostas.recuperar(
			$questao.attr("cod-resposta-questao"),
			$textarea.val()
		);

		if (!salvamentoLocalRespostaDiscursiva) return;

		this.addPopup({
			id: "popupRecuperacaoQuestao",
			titulo: `<i class="fa fa-exclamation-circle"></i> Recuperação de resposta`,
			width: "750px",
			botoes: [{
				label: this.getMsg("FP_FRONT_SalvamentoPFVH_013"),
				onClick: async () => {
					SalvamentoLocalRespostas.remover($questao.attr("cod-resposta-questao"));
				}
			}, {
				label: this.getMsg("FP_FRONT_SalvamentoPFVH_012"),
				classe: "btn btn-primary",
				onClick: async () => {
					$textarea.html(salvamentoLocalRespostaDiscursiva.respostaDiscursiva);
					this.tratarRespostaDeQuestao($textarea.get(0));
					if (!$formGroup.is("[max-linhas]")) {
						exibicaoQuestaoVH.expandirTextareaParaCaberTexto($textarea.get(0));
					}
					await this.salvarProvaFeita();
					SalvamentoLocalRespostas.remover($questao.attr("cod-resposta-questao"));
				}
			}]
		});

		this.addTexto({
			texto: this.getMsg("FP_FRONT_SalvamentoPFVH_017")
		})
		
		this.addTextArea({
			label: `
				${this.getMsg("FP_FRONT_SalvamentoPFVH_014")} 
				${UtilData.toDDMMYYYYHHMM(salvamentoLocalRespostaDiscursiva.dataSalvamento)}
			`,
			valor: salvamentoLocalRespostaDiscursiva.respostaDiscursiva,
			css: 'text-align: center',
			cssTextarea: 'resize: none',
			classe: "col-sm-12 col-lg-12",
			habilitado: false,
			isEnumerado: true
		});

		this.exibirPopups();
	}

	handleOnInputRespostaDiscursiva(textarea) {
		const $textarea = $(textarea);
		const $questao = $textarea.closest("[questao]");
		const respostaDiscursiva = $textarea.val();

		$questao.find(".botao-salvar-resposta").fadeIn('slow');

		SalvamentoLocalRespostas.upsert(
			$questao.attr("cod-resposta-questao"), 
			respostaDiscursiva
		);

		let contadorOnInput = $textarea.data("fp-textarea-contador-on-input") ?? 0;

		contadorOnInput++;

		$textarea.data("fp-textarea-contador-on-input", contadorOnInput);

		if (contadorOnInput % 20 === 0) {
			const numeroQuestao = $questao.find('span.numerador').text().trim();
			this.logger.info(`Resposta discursiva para Q${numeroQuestao}: "${respostaDiscursiva}"`);
		}
	}

	getNumLinhasRespostaDiscursiva($textarea) {
		try {
			let texto = $textarea.val().trim();

			if (texto.length === 0) return 0;

			texto = texto.replaceAll(/\n\s*\n/g, "\n").trim();

			if (texto.length === 0) return 0;

			const idTextareaContagemLinhas = $textarea.closest("[id-textarea-contagem-linhas]").attr("id-textarea-contagem-linhas");
			const $textareaContagemLinhas = $("#" + idTextareaContagemLinhas);
			const textareaContagemLinhas = $textareaContagemLinhas[0];

			$textareaContagemLinhas.val(texto);
			$textareaContagemLinhas.show();

			exibicaoQuestaoVH.expandirTextareaParaCaberTexto(textareaContagemLinhas);
			let heightTexto = textareaContagemLinhas.scrollHeight;
			let numLinhasPreenchidas = heightTexto / 23.0;

			$textareaContagemLinhas.hide();

			if (numLinhasPreenchidas) {
				return Math.round(numLinhasPreenchidas);
			} else {
				return 0;
			}
		} catch (e) {
			this.logger.warn("Erro na contagem de linhas", e);
			return 0;
		}
	}
}

const salvamentoPFVH = new SalvamentoPFVH();

class SalvamentoRespostaTO {
	idSalvamento: number;
	codRespostaQuestao: number;
	codItem: number;
	numeradorResposta: string;
	questaoTipo: QuestaoTipo;
	respostaDoItem: string;
	respostaValorExato: string;
	respostaDiscursiva: string;
	respostaVF: boolean;
	numLinhasPreenchidas: number;
	possuiUploadResposta: boolean;
}

class SalvamentoLocalRespostas {
	
	static recuperar(codRespostaQuestaoStr: string, respostaDiscursivaAtual: string): SalvamentoLocalRespostasTO {
		if (!codRespostaQuestaoStr) return;

		const json = localStorage.getItem("fp-salvamento-local-resposta-discursiva-" + codRespostaQuestaoStr);

		if (!json) return;

		const salvamentoLocalRespostaDiscursiva: SalvamentoLocalRespostasTO = JSON.parse(json);
		
		if (respostaDiscursivaAtual === salvamentoLocalRespostaDiscursiva.respostaDiscursiva) {
			localStorage.removeItem("fp-salvamento-local-resposta-discursiva-" + codRespostaQuestaoStr);
			return null;
		}

		return salvamentoLocalRespostaDiscursiva;
	}

	static upsert(codRespostaQuestao: string, respostaDiscursiva: string) {
		
		if (!codRespostaQuestao || !respostaDiscursiva || respostaDiscursiva.trim().length == 0) return;

		const to: SalvamentoLocalRespostasTO = {
			dataSalvamento: Date.now(),
			respostaDiscursiva,
		}

		localStorage.setItem(
			"fp-salvamento-local-resposta-discursiva-" + codRespostaQuestao, 
			JSON.stringify(to)
		);
	}
	
	static remover(codRespostaQuestao: string) {
		localStorage.removeItem("fp-salvamento-local-resposta-discursiva-" + codRespostaQuestao);
	}
}

type SalvamentoLocalRespostasTO = {
	respostaDiscursiva: string;
	dataSalvamento: number;	
}

enum QuestaoTipo {
	EX = "EX",
	CE = "CE",
	ME = "ME",
	DC = "DC"
}