class AcompanhamentoCDsVH extends DataWarehouseVH {
	
	ordenacaoAgrupamento: any;
	ordenacaoDetalhamento: any;
	mapAbas = new Map();
	detalhesAplicacao: any;

	constructor() {
		super(AcompanhamentoCDsVH.name);
		this.addOperacaoParaHash("acea", this.exibirAbasAcompanhamento);
		this.addOperacaoParaHash("aced", this.exibirDetalhamentoGrupo);

		this.mapAbas.set(CampoRelRespostas.NOME_PROVA, "0");
		this.mapAbas.set(CampoRelRespostas.NOMES_TURMAS_USUARIO, "1");
		this.mapAbas.set(CampoRelRespostas.NOMES_DISCIPLINAS, "2");
	}

	exibirAbasAcompanhamento(numAbaESubaba: string, cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO) {

		numAbaESubaba = numAbaESubaba ?? "0";

		if (this.isAdministrador()) {
			UtilHash.registrarHistorico(this.exibirAbasAcompanhamento, numAbaESubaba, cfgsRelIndiceRespostasTO);
		} else {
			UtilHash.registrarHistorico(this.exibirAbasAcompanhamento, cfgsRelIndiceRespostasTO);
		}

		if (!this.isAdministrador()) {
			this.exibirAgrupamentosParaCorretor(<CfgsRelIndiceRespostasTO> {});
			return;
		}

		let cfgsRelIndiceRespostasTOProvas = numAbaESubaba == "0" ? cfgsRelIndiceRespostasTO : null;
		let cfgsRelIndiceRespostasTOTurmas = numAbaESubaba == "1" ? cfgsRelIndiceRespostasTO : null;
		let cfgsRelIndiceRespostasTODisciplinas = numAbaESubaba == "2" ? cfgsRelIndiceRespostasTO : null;

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_002"));

		this.addAbas({
			abas: [{
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_003"),
				onClick: () => this.exibirGrupoAcompanhamento(cfgsRelIndiceRespostasTOProvas || <CfgsRelIndiceRespostasTO> {
					campoAgrupamento: CampoRelRespostas.NOME_PROVA.id
				}),
			}, {
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_005", this.getCfg("LABEL_TURMA").toLowerCase()),
				onClick: () => this.exibirGrupoAcompanhamento(cfgsRelIndiceRespostasTOTurmas || <CfgsRelIndiceRespostasTO> {
					campoAgrupamento: CampoRelRespostas.NOMES_TURMAS_USUARIO.id
				})
			}, {
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_004"),
				onClick: () => this.exibirGrupoAcompanhamento(cfgsRelIndiceRespostasTODisciplinas || <CfgsRelIndiceRespostasTO> {
					campoAgrupamento: CampoRelRespostas.NOMES_DISCIPLINAS.id
				})
			}], 
			numAbaAtiva: numAbaESubaba
		});

		this.exibir({ isDeveFazerScrollParaTitulo: true });
	}

	async exibirGrupoAcompanhamento(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO) {

		cfgsRelIndiceRespostasTO = this.verificar(cfgsRelIndiceRespostasTO);

		this.ordenacaoAgrupamento = null;

		this.limpar(true);

		this.append("<div class='d-flex justify-end' style='margin-bottom: 15px'>");
		this.addBotaoBusca("filtrosIndiceRespostas", {classe: "btn-sm"});
		this.append("</div>");

		await this.addFiltrosIndiceRespostas({
			campoAgrupamento: cfgsRelIndiceRespostasTO.campoAgrupamento,			
			filtrosTO: cfgsRelIndiceRespostasTO.filtrosTO,
			isExibirFiltrosCDs: true,
			isExibirBuscaTextual: true,
			isExibirNumQuestaoNaProva: true,
			onAplicarFiltros: async () =>  {
				cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();
				const resultadoAcompanhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarAgrupamentoParaAdm", cfgsRelIndiceRespostasTO);
				await this.exibirTabelaAgrupamento(cfgsRelIndiceRespostasTO, resultadoAcompanhamentoCDTO);
			}
		});
		
		this.append(`
			<div class="row">
				<div id="tabela_cds_agrupamento_${cfgsRelIndiceRespostasTO.campoAgrupamento}" class="col-md-12">
				</div>
			</div>
		`);

		this.exibir({ isDeveFazerScrollParaTitulo: true });

		cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();

		const resultadoAcompanhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarAgrupamentoParaAdm", cfgsRelIndiceRespostasTO);

		await this.exibirTabelaAgrupamento(cfgsRelIndiceRespostasTO, resultadoAcompanhamentoCDTO);
	}

	async exibirTabelaAgrupamento(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, resultadoAcompanhamentoCDTO: any) {

		$(`#tabela_cds_agrupamento_${cfgsRelIndiceRespostasTO.campoAgrupamento}`).html("");

		if (!resultadoAcompanhamentoCDTO) return;

		this.setIdTarget(`tabela_cds_agrupamento_${cfgsRelIndiceRespostasTO.campoAgrupamento}`);

		const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);
		const hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);
		const colunas: ColunaAddTabela[] = [{
			titulo: campoAgrupamento.getNome(), 
			prop: "nome", 
			classe: "descricao"
		},{
			titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_074"), 
			regraExibicao: () => resultadoAcompanhamentoCDTO.listaGrupoAcompanhamentoCDTO?.some(to => to.listaListaTOCorretores),
			prop: (grupoAcompanhamentoCDTO) => {

				if (!grupoAcompanhamentoCDTO.listaListaTOCorretores) return;

				return grupoAcompanhamentoCDTO.listaListaTOCorretores.map(
					to => `
					<a href="${hashEdicaoUsuario}/${to.id}">
						<i class='fa fa-user' ></i>
					</a>
					${to.text}
				`
				).join("<br>");

			}, classe: "descricao"
		},{
			titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_006"), 
			prop: "numRespostas"
		},{
			titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_007"), 
			prop: "numCorrecoesPendentes"
		},{
			titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_008"), 
			prop: (grupoAcompanhamentoCDTO) => {

				if (grupoAcompanhamentoCDTO.numCorrecoesPendentes == 0) return "<i class='fa fa-check' style='color: #4a924a'></i>";

				return UtilProgressBar.basic({
					evolucao: (grupoAcompanhamentoCDTO.numCorrecoes - grupoAcompanhamentoCDTO.numCorrecoesPendentes),
					total: grupoAcompanhamentoCDTO.numCorrecoes
				});

			}
		},{
			titulo: "", 
			prop: (grupoAcompanhamentoCDTO) => {

				const h = ["<div class='btn-group'>"];

				if (grupoAcompanhamentoCDTO.numCorrecoesPendentes > 0 && UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO)) {

					h.push(this.addBotao({
						label: `<i class='fa fa-pencil-square'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_010")}`,
						classe: "btn-sm btn-primary",
						onClick: () => {
							cfgsRelIndiceRespostasTO.paginacaoTO = null;
							this.irParaProximaCorrecao(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO, () => {
								this.exibirGrupoAcompanhamento(cfgsRelIndiceRespostasTO);
							});
						},
						retornarHtml: true
					}));
				}

				if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_CFG_CORRETORES)) {

					if (cfgsRelIndiceRespostasTO.campoAgrupamento === CampoRelRespostas.NOMES_TURMAS_USUARIO.id) {
						h.push(this.addBotao({
							label: "<i class='fa fa-gear'></i>",
							classe: "btn-sm",
							onClick: () => cfgsDelegacaoCDsVH.editarCorretoresTurma(grupoAcompanhamentoCDTO.cod, () => {
								this.exibirGrupoAcompanhamento(cfgsRelIndiceRespostasTO);
							}),
							retornarHtml: true
						}));
					} else if (cfgsRelIndiceRespostasTO.campoAgrupamento === CampoRelRespostas.NOMES_DISCIPLINAS.id) {
						h.push(this.addBotao({
							label: "<i class='fa fa-gear'></i>",
							classe: "btn-sm",
							onClick: () => cfgsDelegacaoCDsVH.editarCorretoresDisciplina(grupoAcompanhamentoCDTO.cod, () => {
								this.exibirGrupoAcompanhamento(cfgsRelIndiceRespostasTO);
							}),
							retornarHtml: true
						}));
					}
				}


				h.push("</div>");
				return h.join("");

			}
		}];

		await this.addTabela({
			id: cfgsRelIndiceRespostasTO.campoAgrupamento,
			collection: resultadoAcompanhamentoCDTO.listaGrupoAcompanhamentoCDTO,
			propId: "cod",
			colunas: colunas,
			msgListaVazia: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_009"),
			paginacao: {
				paginacaoTO: resultadoAcompanhamentoCDTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					cfgsRelIndiceRespostasTO.paginacaoTO = paginacaoTO;
					resultadoAcompanhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarAgrupamentoParaAdm", cfgsRelIndiceRespostasTO);
					await this.exibirTabelaAgrupamento(cfgsRelIndiceRespostasTO, resultadoAcompanhamentoCDTO);
				}
			},
			onEdicao: async (cod, grupoAcompanhamentoCDTO) => {
				delete cfgsRelIndiceRespostasTO.paginacaoTO;
				delete cfgsRelIndiceRespostasTO.campoOrdenacao;
				delete cfgsRelIndiceRespostasTO.isOrdenacaoDecrescente;
				
				await this.exibirDetalhamentoGrupo(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO)
			},
			ordenacao: this.ordenacaoAgrupamento,
			onOrdenacaoColuna: async (coluna: ColunaAddTabela, isOrdenacaoDecrescente: boolean) => {

				if (typeof coluna.prop !== "string") return;
	
				cfgsRelIndiceRespostasTO.campoOrdenacao = coluna.prop;
				cfgsRelIndiceRespostasTO.isOrdenacaoDecrescente = isOrdenacaoDecrescente;
				cfgsRelIndiceRespostasTO.paginacaoTO = null;
	
				const resultadoAcompanhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarAgrupamentoParaAdm", cfgsRelIndiceRespostasTO);

				this.ordenacaoAgrupamento = [[coluna.prop, isOrdenacaoDecrescente ? 1 : 0]];

				await this.exibirTabelaAgrupamento(cfgsRelIndiceRespostasTO, resultadoAcompanhamentoCDTO);
			}
		});
		this.exibir();
	}

	async exibirDetalhamentoGrupo(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO: any, detalhesAplicacao: any = null) {
		
		this.ordenacaoDetalhamento = null;
		this.detalhesAplicacao = detalhesAplicacao !== null ? detalhesAplicacao : this.detalhesAplicacao;

		UtilHash.registrarHistorico(this.exibirDetalhamentoGrupo, cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO);

		this.limpar();

		this.setTitulo(grupoAcompanhamentoCDTO.nome);
		this.setSubtitulo(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_026"));
		
		if (cfgsRelIndiceRespostasTO.campoAgrupamento === CampoRelRespostas.NOME_PROVA.id) {

			if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EXPORTACAO)) { 
				this.setIdTarget("tituloSuperiorDireito");
				this.addBotao({
					label: `<i class='fa fa-file-download'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_031")}`,
					onClick: () => this.exibirExportacao(grupoAcompanhamentoCDTO.cod),
					classe: "btn-sm"
				});
				this.setIdTarget(null);
			}

			if (this.detalhesAplicacao && this.detalhesAplicacao.codProva === grupoAcompanhamentoCDTO.cod) {
				this.setIdTarget("tituloSuperiorDireito");
				this.addBotao({
					label: `<i class='fa fa-list'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_119")}`,
					onClick: async () => await cadastroProvaVH.exibirAbasProva(5, this.detalhesAplicacao.codProva, null, null, false, this.detalhesAplicacao.codAgendamento),
					classe: "btn-sm"
				});

				if(UtilAuth.possuiAcesso(TipoFuncionalidade.RECURSOS_VISUALIZACAO)) {
					this.addBotao({
						label: `<i class='fa fa-bars'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_120")}`,
						onClick: async () => await recursoVH.listarRecursosDaProva(null, this.detalhesAplicacao.codProva),
						classe: "btn-sm"
					});
				}

				this.setIdTarget(null);
			}
		}

		this.setIdTarget("tituloSuperiorDireito");
		this.addBotaoBusca("filtrosIndiceRespostas", {classe: "btn-sm"});
		this.setIdTarget(null);

		cfgsRelIndiceRespostasTO = this.verificar(cfgsRelIndiceRespostasTO);

		await this.addFiltrosIndiceRespostas({
			campoAgrupamento: cfgsRelIndiceRespostasTO.campoAgrupamento,
			filtrosTO: cfgsRelIndiceRespostasTO.filtrosTO,
			isExibirFiltrosCDs: true,
			isExibirBuscaTextual: false,
			isExibirNumQuestaoNaProva: true,
			onAplicarFiltros: async () =>  {
				cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();
				const resultadoDetalhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarCDs", cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO.cod);
				await this.exibirTabelaDetalhamento(cfgsRelIndiceRespostasTO, resultadoDetalhamentoCDTO, grupoAcompanhamentoCDTO);
			}
		});
		
		this.append(`
			<div class="row">
				<div id="tabela_cds_detalhamento" class="col-md-12">
				</div>
			</div>
		`);

		this.addBotao({
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_056"),
			onClick: () => {
				cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();
				this.exibirAbasAcompanhamento(
					this.mapAbas.get(cfgsRelIndiceRespostasTO.campoAgrupamento),
					cfgsRelIndiceRespostasTO
				);
			}
		});

		this.exibir();

		const resultadoDetalhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarCDs", cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO.cod);

		await this.exibirTabelaDetalhamento(cfgsRelIndiceRespostasTO, resultadoDetalhamentoCDTO, grupoAcompanhamentoCDTO);
	}

	async exibirTabelaDetalhamento(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, resultadoDetalhamentoCDTO: any, grupoAcompanhamentoCDTO: any) {

		$("#tabela_cds_detalhamento").html("");
		this.setIdTarget("tabela_cds_detalhamento");

		const colunas: ColunaAddTabela[] = [];

		if (resultadoDetalhamentoCDTO.listaDetalhamentoCDTO?.some((to: any) => to.nomeUsuario)) {
			colunas.push({titulo: this.getCfg("LABEL_ALUNO"), prop: "nomeUsuario", classe: "descricao"});
		} else {
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_037"), prop: "codRespostaQuestao"});
		}


		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_027"), prop: "dataEnvioDiscursiva", formato: "DD/MM/YY HH:mm"});

		if (cfgsRelIndiceRespostasTO.campoAgrupamento !== CampoRelRespostas.NOMES_TURMAS_USUARIO.id
			&& resultadoDetalhamentoCDTO.listaDetalhamentoCDTO?.some((to: any) => to.nomeTurmaNaAplicacao)) {

			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_033"), prop: "nomeTurmaNaAplicacao"});
		}
		
		if (cfgsRelIndiceRespostasTO.campoAgrupamento !== CampoRelRespostas.NOME_PROVA.id) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_034"), prop: "nomeProva"});
		}

		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_035"), prop: "numQuestaoNaProva"});
		
		if (resultadoDetalhamentoCDTO.listaDetalhamentoCDTO?.some((to: any) => to.nomesDisciplinas)) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_036"), prop: "nomesDisciplinas"});
		}

		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_038"), prop: "pontuacaoMaxima", formato: "numero"});

		let possuiColunaPontuacaoConcedida = false;

		if (resultadoDetalhamentoCDTO.listaDetalhamentoCDTO?.some((to: any) => to.dataCorrecao)) {

			if (resultadoDetalhamentoCDTO.listaDetalhamentoCDTO?.some((to: any) => to.nomesUsuariosCorretores)) {
				colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_041"), prop: "nomesUsuariosCorretores"});
			}

			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_040"), prop: "dataCorrecao", formato: "DD/MM/YY HH:mm"});

			if (resultadoDetalhamentoCDTO.listaDetalhamentoCDTO?.some((to: any) => to.mencaoObtida)) {
				colunas.push({titulo: this.getMsg("FP_FRONT_AcompanhamentoCDsVH_003"), prop: "mencaoObtida"});
			} else{
				colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_039"), prop: "pontuacaoObtida", formato: "numero"});
			}

			possuiColunaPontuacaoConcedida = true;
		}

		const classeId = this.gerarId();


		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_008"), prop: (detalhamentoCDTO: any) => {
				if (detalhamentoCDTO.isEmCorrecaoPorOutroUsuario) {
					return `<i>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_134")}</i>`
				}

				if (detalhamentoCDTO.listaDetalhamentoCorretorCDTO) {
					if (detalhamentoCDTO.numCorrecoes === 1) {
						const detalhamentoCorretorCDTO = detalhamentoCDTO.listaDetalhamentoCorretorCDTO[0];
						return this.addBotao({
							label: `<i class='fa fa-pencil-square'></i>`,
							html: `cod-resposta-questao="${detalhamentoCDTO.codRespostaQuestao}"`,
							classe: "btn-sm " + classeId + (detalhamentoCorretorCDTO.data ? "" : " btn-primary"),
							hash: UtilHash.getHash(correcaoDiscursivasVH.exibirTelaCorrecao, detalhamentoCDTO.codRespostaQuestao),
							retornarHtml: true
						});

					} else {
						return "<div class='btn-group-vertical'>" + detalhamentoCDTO.listaDetalhamentoCorretorCDTO.map(detalhamentoCorretorCDTO => this.addBotao({
							label: `
							<i class='fa fa-pencil-square'></i>
							${detalhamentoCorretorCDTO.nome}
							${detalhamentoCorretorCDTO.pontuacao !== null ?
								"<br><small title='Em " + UtilData.toDDMMYYYYHHMMSS(detalhamentoCorretorCDTO.data) + "'>Pontuação: " + UtilNumero.floatToString(detalhamentoCorretorCDTO.pontuacao) + "</small>"
								: ""
							}
						`,
							html: `cod-resposta-questao="${detalhamentoCDTO.codRespostaQuestao}" cod-usuario-corretor="${detalhamentoCorretorCDTO.cod}"`,
							classe: "btn-sm " + classeId + (detalhamentoCorretorCDTO.data ? "" : " btn-primary"),
							hash: UtilHash.getHash(correcaoDiscursivasVH.exibirTelaCorrecao, detalhamentoCDTO.codRespostaQuestao, detalhamentoCorretorCDTO.cod),
							retornarHtml: true
						})).join("") + "</div>";
					}

				} else if (detalhamentoCDTO.detalhamentoCorretorCDTO) {

					const detalhamentoCorretorCDTO = detalhamentoCDTO.detalhamentoCorretorCDTO;
					const classe = detalhamentoCorretorCDTO.data !== null && detalhamentoCorretorCDTO.pontuacao !== null ? "" : "btn-primary";
					let label = "";

					if (detalhamentoCDTO.numCorrecoes > 1 && !possuiColunaPontuacaoConcedida && detalhamentoCorretorCDTO.data) {
						label = `
						<small title="Em ${UtilData.toDDMMYYYYHHMMSS(detalhamentoCorretorCDTO.data)}">
							Pontuação: ${UtilNumero.floatToString(detalhamentoCorretorCDTO.pontuacao)}
						</small>
					`;
					}

					return this.addBotao({
						label: `<i class='fa fa-pencil-square'></i> ${label}`,
						html: `cod-resposta-questao="${detalhamentoCDTO.codRespostaQuestao}"`,
						classe: `btn-sm ${classeId} ${classe}`,
						hash: UtilHash.getHash(correcaoDiscursivasVH.exibirTelaCorrecao, detalhamentoCDTO.codRespostaQuestao),
						retornarHtml: true
					})
				}
			}
		});

		await this.addTabela({
			collection: resultadoDetalhamentoCDTO.listaDetalhamentoCDTO,
			id: "listagemCorrecoes",
			propId: "codRespostaQuestao",
			colunas: colunas,
			msgListaVazia: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_045"),
			// salvarOrdenacao: true,
			htmlAcoes: resultadoDetalhamentoCDTO.listaDetalhamentoCDTO && (this.isAdministrador() || UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO)) ? this.addBotao({
				label: `<i class='fa fa-pencil-square'></i> ${this.getMsg("FP_FRONT_AcompanhamentoCDsVH_002")}`,
				classe: (resultadoDetalhamentoCDTO.possuiCorrecoesPendentes ? "btn-primary" : ""),
				onClick: async () => {
					cfgsRelIndiceRespostasTO.paginacaoTO = null;
					await this.irParaProximaCorrecao(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO, () => {
						cfgsRelIndiceRespostasTO.paginacaoTO = null;
						this.exibirDetalhamentoGrupo(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO);
					});
				},
				retornarHtml: true
			}) : null,
			paginacao: {
				paginacaoTO: resultadoDetalhamentoCDTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					cfgsRelIndiceRespostasTO.paginacaoTO = paginacaoTO;
					const resultadoDetalhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarCDs", cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO.cod);
					return resultadoDetalhamentoCDTO.listaDetalhamentoCDTO;
				}
			},
			ordenar: false,
			ordenacao: this.ordenacaoDetalhamento,
			onOrdenacaoColuna: async (coluna: ColunaAddTabela, isOrdenacaoDecrescente: boolean) => {
				
				if (typeof coluna.prop !== "string") return;

				cfgsRelIndiceRespostasTO.campoOrdenacao = coluna.prop;
				cfgsRelIndiceRespostasTO.isOrdenacaoDecrescente = isOrdenacaoDecrescente;
				cfgsRelIndiceRespostasTO.paginacaoTO = null;
	
				const resultadoDetalhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarCDs", cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO.cod);

				this.ordenacaoDetalhamento = [[coluna.prop, isOrdenacaoDecrescente ? 1 : 0]];
				await this.exibirTabelaDetalhamento(cfgsRelIndiceRespostasTO, resultadoDetalhamentoCDTO, grupoAcompanhamentoCDTO);
			}
		});

		this.exibir({ isDeveFazerScrollParaTitulo: true });

		if (!resultadoDetalhamentoCDTO.listaDetalhamentoCDTO) {
			return
		}

		{
			// ADICIONANDO <small>
			const numColunaIdResposta = this.getNumColunaListagem("codRespostaQuestao");
	
			if (numColunaIdResposta != null) {
				$(`tbody td:nth-child(${numColunaIdResposta + 1})`).each((i: number, td: HTMLElement) => {
					td.innerHTML = "<small>#" + td.innerHTML + "</small>";
				});
			}
		}

		{
			// LINK PARA TELA DE CORREÇÃO
			$(`.${classeId}`).on("click", (event) => {
	
				if (event.ctrlKey) return; // deixa abrir via href em outra aba
	
				event.preventDefault(); // não segue href pra manter filtro
	
				const $target = $(event.target).closest("a");
				const codRespostaQuestao = Number($target.attr("cod-resposta-questao"));
				const codUsuarioCorretor = Number($target.attr("cod-usuario-corretor"));

				this.irParaCorrecao(codRespostaQuestao, codUsuarioCorretor, null, null, () => {
					this.exibirDetalhamentoGrupo(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO);
				});
			})
		}
	}

	irParaCorrecao(codRespostaQuestao: number, codUsuarioCorretor: number, cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO: any, onCorrecoesFinalizadas: Function) {
		
		const botoesAposSalvar = [];

		if (cfgsRelIndiceRespostasTO && grupoAcompanhamentoCDTO) {
			botoesAposSalvar.push({
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_057"),
				classe: "btn-primary",
				onClick: async () => this.irParaProximaCorrecao(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO, onCorrecoesFinalizadas)
			});
		}

		botoesAposSalvar.push({
			label: this.getMsg("FP_FRONT_AcompanhamentoCDsVH_001"),
			classe: "btn-primary",
			onClick: () => onCorrecoesFinalizadas()
		})

		correcaoDiscursivasVH.exibirTelaCorrecao(codRespostaQuestao, codUsuarioCorretor, {
			titulo: null,
			subtitulo: null,
			labelBotaoFinalizacao: null,
			classeBotaoFinalizacao: null,
			onClickBotaoFinalizacao: null,
			botoesAposSalvar,
			isFluxoRecursos: false,
			registrarHistorico: true
		});
	}

	async irParaProximaCorrecao(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO: any, onCorrecoesFinalizadas: Function) {

		const proximaCorrecaoTO = await this.call("AcompanhamentoCDsFCD/recuperarProximaCorrecaoPendente", cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO.cod);
		
		if (proximaCorrecaoTO != null) {
			cfgsRelIndiceRespostasTO.paginacaoTO = proximaCorrecaoTO.paginacaoTO;
			this.irParaCorrecao(proximaCorrecaoTO.codRespostaQuestao, proximaCorrecaoTO.codUsuarioCorretor, cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO, onCorrecoesFinalizadas);
		} else {
			onCorrecoesFinalizadas();
		}
	}
	
	async exibirExportacao(codProva: number) {

		const opcoesExportacaoRespostasTO = await this.call("CorrecaoDiscursivasExportacaoFCD/recuperarInfosParaExportacaoRespostas", codProva);

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_087"),
			botoes: [{ 
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_088") 
			}, {
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_089"), 
				classe: "btn-primary", 
				onClick: async () => {

					this.verificarObrigatorios();

					const exportacaoRespostasTO: any = {
						codProva: codProva,
						codQuestao: this.getValor("codQuestaoRelatorio"),
						tipoRespostasExportacaoPDF: this.getValor("tipoRespostasExportacaoPDF"),
						infosAvaliados: this.getValor("infosAvaliados"),
						infosNomeArquivo: this.getValor("infosNomeArquivo"),
						isUmArquivoPorResposta: this.getValor("tipoGeracaoArquivo") === "UM_POR_RESPOSTA",
						numQuestaoNaProva: this.getTOSelectSelecionado("codQuestaoRelatorio")[0].text
					};

					if (exportacaoRespostasTO.isUmArquivoPorResposta) {
						exportacaoRespostasTO.infosNomeArquivo = this.getValor("infosNomeArquivo");
					}

					const url = await this.call("CorrecaoDiscursivasExportacaoFCD/exportarRespostas", exportacaoRespostasTO);

					this.setIdTarget("link_pdf_respostas");
					this.setHtml("link_pdf_respostas", "");
					this.addLink({
						classe: `fp-link-aguardar-geracao-arquivo`,
						href: url,
						html: `target='_blank'`,
						label: UtilArquivo.getNomeArquivoSemHifens(url)
					});
					this.exibir();

					return false;
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_090"));

		await this.addSelect({
			collection: opcoesExportacaoRespostasTO.collectionListaTOQuestoes,
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_091"),
			classe: "col-md-12",
			id: "codQuestaoRelatorio",
			obrigatorio: true
		});

		await this.addSelect({
			collection: opcoesExportacaoRespostasTO.collectionListaTOTiposRespostas,
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_092"),
			classe: "col-md-12",
			id: "tipoRespostasExportacaoPDF",
			obrigatorio: true,
			valor: "TODAS"
		});

		await this.addSelect({
			id: "infosAvaliados",
			collection: opcoesExportacaoRespostasTO.listaInfoAvaliado,
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_093"),
			multiplo: true,
			classe: "col-md-12",
			valor: ["NOME", "MATRICULA"]
		});

		this.addRadioGroup({
			id: "tipoGeracaoArquivo",
			label: this.getMsg("FP_FRONT_AcompanhamentoCDsVH_004"),
			collection: [
				{ id: "UM_PDF", descricao: this.getMsg("FP_FRONT_AcompanhamentoCDsVH_005") },
				{ id: "UM_POR_RESPOSTA", descricao: this.getMsg("FP_FRONT_AcompanhamentoCDsVH_006") }
			],
			classe: "col-md-12",
			valor: "UM_PDF",
			onChange: () => {
				this.show("infosNomeArquivo", this.getValor("tipoGeracaoArquivo") === "UM_POR_RESPOSTA");
			}
		});

		await this.addSelect({
			id: "infosNomeArquivo",
			collection: opcoesExportacaoRespostasTO.listaInfoAvaliado,
			label: this.getMsg("FP_FRONT_AcompanhamentoCDsVH_007"),
			multiplo: true,
			visivel: false,
			classe: "col-md-12",
			valor: ["MATRICULA"]
		});

		this.append("<div id='link_pdf_respostas' class='col-md-12'></div>")

		this.exibirPopups();
	}
	
	async exibirAgrupamentosParaCorretor(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO) {

		this.ordenacaoAgrupamento = null;

		this.limpar();

		this.addTitulo({texto: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_002"), textoADireita: `
			<div class="btn-group"> 
				${this.addBotaoBusca("filtrosIndiceRespostas", {classe: "btn-sm", retornarHtml: true})}
			</div>
		`});

		const atualizarTabelas = async (cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO) => {
			const resultadoAcompanhamentoParaCorretorTO = await this.call("AcompanhamentoCDsFCD/listarAgrupamentosParaCorretor", cfgsRelIndiceRespostasTO);

			for (const a of [
				[
					CampoRelRespostas.NOMES_DISCIPLINAS,
					resultadoAcompanhamentoParaCorretorTO.resultadoAcompanhamentoCDTODisciplina,
					"FP_FRONT_CorrecaoDiscursivasVH_015"
				],
				[
					CampoRelRespostas.NOMES_TURMAS_USUARIO,
					resultadoAcompanhamentoParaCorretorTO.resultadoAcompanhamentoCDTOTurma,
					"FP_FRONT_CorrecaoDiscursivasVH_016"
				],
				[
					CampoRelRespostas.NOME_AGENDAMENTO,
					resultadoAcompanhamentoParaCorretorTO.resultadoAcompanhamentoCDTOAplicacao,
					"FP_FRONT_CorrecaoDiscursivasVH_017"
				],
				[
					CampoRelRespostas.NOME_PROVA,
					resultadoAcompanhamentoParaCorretorTO.resultadoAcompanhamentoCDTOProva,
					"FP_FRONT_CorrecaoDiscursivasVH_018"
				],
			]) {

				const cfgsRelIndiceRespostasTOAgrupamento = UtilJson.clonar(cfgsRelIndiceRespostasTO);

				cfgsRelIndiceRespostasTOAgrupamento.campoAgrupamento = a[0];

				await this.exibirTabelaAgrupamentoParaCorretor(cfgsRelIndiceRespostasTOAgrupamento, a[1], a[2]);
			}
		}

		cfgsRelIndiceRespostasTO = this.verificar(cfgsRelIndiceRespostasTO);

		await this.addFiltrosIndiceRespostas({
			campoAgrupamento: cfgsRelIndiceRespostasTO.campoAgrupamento,			
			filtrosTO: cfgsRelIndiceRespostasTO.filtrosTO,
			isExibirFiltrosCDs: true,
			isExibirBuscaTextual: true,
			isExibirNumQuestaoNaProva: true,
			onAplicarFiltros: async () =>  {
				cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();
				await atualizarTabelas(cfgsRelIndiceRespostasTO);
			}
		});
		
		this.append(`
			<div class="row">
				<div id="tabela_cds_agrupamento_corretor_${CampoRelRespostas.NOMES_DISCIPLINAS}" class="col-md-12">
				</div>
			</div>
			<div class="row">
				<div id="tabela_cds_agrupamento_corretor_${CampoRelRespostas.NOMES_TURMAS_USUARIO}" class="col-md-12">
				</div>
			</div>
			<div class="row">
				<div id="tabela_cds_agrupamento_corretor_${CampoRelRespostas.NOME_AGENDAMENTO}" class="col-md-12">
				</div>
			</div>
			<div class="row">
				<div id="tabela_cds_agrupamento_corretor_${CampoRelRespostas.NOME_PROVA}" class="col-md-12">
				</div>
			</div>
		`);

		this.exibir({ isDeveFazerScrollParaTitulo: true });

		cfgsRelIndiceRespostasTO.filtrosTO = this.getFiltrosIndiceRespostasTO();
		await atualizarTabelas(cfgsRelIndiceRespostasTO);
	}

	async exibirTabelaAgrupamentoParaCorretor(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, resultadoAcompanhamentoCDTO: any, idMsgTituloTabela: string) {

		$(`#tabela_cds_agrupamento_corretor_${cfgsRelIndiceRespostasTO.campoAgrupamento}`).html("");
		
		if (!resultadoAcompanhamentoCDTO) return;

		this.setIdTarget(`tabela_cds_agrupamento_corretor_${cfgsRelIndiceRespostasTO.campoAgrupamento}`);
		const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);

		const colunas: ColunaAddTabela[] = [
			{titulo: campoAgrupamento.getNome(), prop: "nome", classe: "descricao"},
			{titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_006"), prop: "numRespostas"},
			{titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_007"), prop: "numCorrecoesPendentes"},
			{titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_008"), prop: (grupoAcompanhamentoCDTO) => {

					if (grupoAcompanhamentoCDTO.numCorrecoesPendentes == 0) return "<i class='fa fa-check' style='color: #4a924a'></i>";

					return UtilProgressBar.basic({
						evolucao: (grupoAcompanhamentoCDTO.numCorrecoes - grupoAcompanhamentoCDTO.numCorrecoesPendentes),
						total: grupoAcompanhamentoCDTO.numCorrecoes
					});

				}
			},
			{titulo: "", prop: (grupoAcompanhamentoCDTO) => {

					const h = ["<div class='btn-group'>"];

					if (grupoAcompanhamentoCDTO.numCorrecoesPendentes > 0 && UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO)) {

						h.push(this.addBotao({
							label: `<i class='fa fa-pencil-square'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_010")}`,
							classe: "btn-primary",
							onClick: () => {
								cfgsRelIndiceRespostasTO.paginacaoTO = null;
								this.irParaProximaCorrecao(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO, () => {
									cfgsRelIndiceRespostasTO.paginacaoTO = null;
									this.exibirAgrupamentosParaCorretor(cfgsRelIndiceRespostasTO);
								});
							},
							retornarHtml: true
						}));
					}

					h.push("</div>");
					return h.join("");

				}
			}
		];

		await this.addTabela({
			id: cfgsRelIndiceRespostasTO.campoAgrupamento,
			collection: resultadoAcompanhamentoCDTO.listaGrupoAcompanhamentoCDTO,
			propId: "cod",
			colunas: colunas,
			titulo: this.getMsg(idMsgTituloTabela),
			msgListaVazia: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_009"),
			onEdicao: (cod, grupoAcompanhamentoCDTO) => {
				Reflect.deleteProperty(cfgsRelIndiceRespostasTO, "paginacaoTO");
				this.exibirDetalhamentoGrupo(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO)
			}
		});
		
		this.exibir();
	}

	exibirDetalhamentoCorrecoesAgendamento(codAgendamento: number, nomeProva: string) {
		const cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO = {
			campoAgrupamento: CampoRelRespostas.NOME_AGENDAMENTO.id,
			filtrosTO: {
				ativos: {}
			}
		}
		cfgsRelIndiceRespostasTO.filtrosTO.situacaoCD = SituacaoCD.AGUARDANDO_CORRECAO;
		cfgsRelIndiceRespostasTO.filtrosTO.ativos[CampoRelRespostas.COD_AGENDAMENTO.id] = [String(codAgendamento)];

		const grupoAcompanhamentoCDTO = {
			cod: codAgendamento,
			nome: nomeProva + " - Aplicação #" + codAgendamento
		}
		this.exibirDetalhamentoGrupo(cfgsRelIndiceRespostasTO, grupoAcompanhamentoCDTO).then(
			() => {}, () => {});
	}

	exibirDetalhamentoCorrecoesProva(codProva: number, nomeProva: string, detalhesAplicacao: any = null) {
		const filtros = this.filtrosDetalhamentoCorrecoesProva(codProva, nomeProva);
		this.exibirDetalhamentoGrupo(filtros.cfgsRelIndiceRespostasTO, filtros.grupoAcompanhamentoCDTO, detalhesAplicacao).then(
			() => {}, () => {});
	}

	filtrosDetalhamentoCorrecoesProva(codProva: number, nomeProva: string) {
		const cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO = {
			campoAgrupamento: CampoRelRespostas.NOME_PROVA.id,
			filtrosTO: {
				ativos: {}
			}
		}

		cfgsRelIndiceRespostasTO.filtrosTO.situacaoCD = SituacaoCD.AGUARDANDO_CORRECAO;
		cfgsRelIndiceRespostasTO.filtrosTO.ativos[CampoRelRespostas.COD_PROVA.id] = [String(codProva)];

		const grupoAcompanhamentoCDTO = {
			cod: codProva,
			nome: nomeProva
		}

		return {
			cfgsRelIndiceRespostasTO: cfgsRelIndiceRespostasTO,
			grupoAcompanhamentoCDTO: grupoAcompanhamentoCDTO
		}
	}
}

const acompanhamentoCDsVH = new AcompanhamentoCDsVH();