class CalculoTRIVH extends AmaisVH {

	constructor() {
		super(CalculoTRIVH.name);
		this.addOperacaoParaHash("ctld", this.listarDatasets);
	}

	async listarDatasets() {
		
		UtilHash.registrarHistorico(this.listarDatasets);

		const exibirListagemDatasets = async () => {
			
			let filtrosExibicaoDatasetsTO: FiltrosExibicaoDatasetsTO = {
				numMinimosAvaliados: this.getValor("numMinimosAvaliados"),
				// coeficienteAvaliadosQuestoesMinimo: this.getValor("coeficienteAvaliadosQuestoesMinimo"),
				codsEmpresas: this.getValor("codsEmpresas"),
				buscaTextual: this.getValor("buscaTextual"),
				paginacaoTO: {
					numItensPorPagina: 10,
					numPaginaAtual: null,
					numTotalItens: null
				}
			}
	
			let listagemDatasetsTO: ListagemDatasetsTO = await this.call("CalculoTRIFCD/listarDatasets", filtrosExibicaoDatasetsTO);

			$("#calculo-tri-listagem").html("");
			this.setIdTarget("calculo-tri-listagem");

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: "Prova", prop: "tituloProva"});
			colunas.push({titulo: "Questões respondidas", prop: "numQuestoes"});
			colunas.push({titulo: "Avaliados", prop: "numAvaliados"});
			colunas.push({titulo: "", prop: (provaDatasetTO) => {
					// if (provaDatasetTO.isAtendeCoeficiente) {
					return this.addBotao({
						label: "<i class='fa fa-area-chart'></i>",
						classe: "btn-primary btn-sm",
						retornarHtml: true,
						onClick: () => this.exibirDataset(provaDatasetTO)
					});
					// }
				}
			})

			await this.addTabela({
				collection: listagemDatasetsTO.listaProvaDatasetTO,
				titulo: `
					Datasets disponíveis 
					<small class="pull-right">
						Provas com pelo menos ${listagemDatasetsTO.numMinimosAvaliados} avaliados
					</small>
				`,
				colunas: colunas,
				propId: "codProva",
				paginacao: {
					paginacaoTO: listagemDatasetsTO.paginacaoTO,
					onCarregarPagina: async (paginacaoTO) => {
						filtrosExibicaoDatasetsTO.paginacaoTO = paginacaoTO;
						listagemDatasetsTO = await this.call("CalculoTRIFCD/listarDatasets", filtrosExibicaoDatasetsTO);
						return listagemDatasetsTO.listaProvaDatasetTO;
					}
				},
			});

			this.exibir();
		}

		this.limpar();

		this.setTitulo("<i class='fa fa-area-chart'></i> Cálculo de TRI");

		this.abrirAbaAccordion({ 
			titulo: this.getMsg("MSG_VH_001")
		});

		if (this.getIsAmbienteSegmentado()) {
			await this.addSelect({ 
				label: "Segmentos das provas",
				id: "codsEmpresas", 
				multiplo: true, 
				onChange: async () => await exibirListagemDatasets(),
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarSegmentos",
					numMinimoCaracteres: 0,
				},
			});
		}

		this.addCampoTexto({ 
			label: "Número de avaliados mínimo",
			id: "numMinimosAvaliados", 
			tipo: "NUMERO",
			onEnter: async () => await exibirListagemDatasets()
		});
		
		// this.addCampoTexto({ 
		// 	label: "Coeficiente avaliados/questões mínimo",
		// 	id: "coeficienteAvaliadosQuestoesMinimo", 
		// 	tipo: "NUMERO",
		// 	onEnter: async () => await exibirListagemDatasets()
		// });
		
		this.addCampoTexto({ 
			label: "Busca textual",
			id: "buscaTextual", 
			prefixo: "<i class='fa fa-search'></i> ", 
			onEnter: async () => await exibirListagemDatasets()
		});
		
		// this.addCamposPeriodo({
		// 	idInicio: "inicioPeriodoCadastroBusca",
		// 	idFim: "fimPeriodoCadastroBusca",
		// 	dica: this.getMsg("FP_FRONT_CadastroUsuarioVH_049"),
		// 	onChange: async () => await exibirListagemDatasets()
		// });

		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();

		this.append(`
			<div class="row">
				<div class="col-md-12" id="calculo-tri-listagem">
				</div>
			</dvi>
		`);

		this.exibir();

		await exibirListagemDatasets();
	}

	async exibirDataset(provaDatasetTO: ProvaDatasetTO) {

		const exibicaoDatasetTO: ExibicaoDatasetTO = await this.call("CalculoTRIFCD/exibirDataset", provaDatasetTO);

		this.limpar(true);

		this.setSubtitulo(provaDatasetTO.tituloProva);

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "Prova respondida", prop: "pf"});

		for (let codQuestao of exibicaoDatasetTO.codsQuestoes) {
			const nomeColuna = "Q" + codQuestao;
			const propColuna = "Q" + codQuestao;
			colunas.push({titulo: nomeColuna, prop: propColuna});
		}

		await this.addTabela({
			collection: exibicaoDatasetTO.dataset,
			titulo: "Dataset",
			colunas: colunas,
			propId: "pf",
			cssDiv: `max-width: ${Math.round(0.9 * window.document.body.offsetWidth)}px; max-height: 400px`,
			hashEdicao: UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada)
		});

		this.addSubtitulo("Cálculos para modelo Rash");

		const resultadoCalculoTRITO = exibicaoDatasetTO.resultadoCalculoTRITO;

		this.addImagem({
			titulo: "Informação da prova",
			src: resultadoCalculoTRITO.urlGraficoCurvaInformacaoProva,
			classe: "col-md-4"
		});
		
		this.addImagem({
			titulo: "Informação das questões",
			src: resultadoCalculoTRITO.urlGraficoCurvaInformacaoQuestoes,
			classe: "col-md-4"
		});
		
		this.addImagem({
			titulo: "Informação das questões consolidado",
			src: resultadoCalculoTRITO.urlGraficoProbabilidadeAcertoQuestoesConsolidado,
			classe: "col-md-4"
		});
		
		this.addImagem({
			titulo: "Probabilidade de acerto por capacidade do avaliado",
			src: resultadoCalculoTRITO.urlGraficoProbabilidadeAcertoQuestoes,
			classe: "col-md-4"
		});

		const colunasTRI: ColunaAddTabela[] = [];
		colunasTRI.push({titulo: "", prop: "identificadorQuestao"});
		colunasTRI.push({titulo: "a", prop: "discriminacao"});
		colunasTRI.push({titulo: "b", prop: "dificuldade"});
		colunasTRI.push({titulo: "g", prop: "chute"});
		colunasTRI.push({titulo: "u", prop: "u"});
		colunasTRI.push({titulo: "infit", prop: "infit"});
		colunasTRI.push({titulo: "z.infit", prop: "zInfit"});
		colunasTRI.push({titulo: "outfit", prop: "outfit"});
		colunasTRI.push({titulo: "z.outfit", prop: "zOutfit"});
		colunasTRI.push({titulo: "Dificuldade", prop: (resultadoCalculoTRIQuestaoTO: ResultadoCalculoTRIQuestaoTO) => {
				return resultadoCalculoTRIQuestaoTO.motivoDescarte || this.exibirBarraDificuldade(
					exibicaoDatasetTO.listaListagemDificuldadeTO,
					resultadoCalculoTRIQuestaoTO.dificuldade
				);
			}
		});

		await this.addTabela({
			collection: resultadoCalculoTRITO.listaResultadoCalculoTRIQuestaoTO,
			titulo: "Resultado dos cálculos por questão",
			colunas: colunasTRI,
		});

		this.addBotao({
			label: "Publicar inferências",
			classe: "btn-primary",
			onClick: async () => {
				
			}
		});

		this.exibir();
	}

	exibirBarraDificuldade(listaListagemDificuldadeTO, dificuldadeInferida = null) {
		if (dificuldadeInferida === null) return;
		return UtilProgressBar.dificuldade({
			limiteTotalInferior: -6,
			limiteTotalSuperior: 6,
			dificuldades: listaListagemDificuldadeTO,
			progresso: dificuldadeInferida
		})
	}
}

const calculoTRIVH = new CalculoTRIVH();

type FiltrosExibicaoDatasetsTO = {
	numMinimosAvaliados: number;
	// coeficienteAvaliadosQuestoesMinimo: number;
	paginacaoTO: PaginacaoTO;
	codsEmpresas: number[];
	buscaTextual: string;
}
type ListagemDatasetsTO = {
	paginacaoTO: PaginacaoTO;
	numMinimosAvaliados: number;
	// coeficienteAvaliadosQuestoesMinimo: number;
	listaProvaDatasetTO: ProvaDatasetTO[];
}
type ProvaDatasetTO = {
	codProva: number;
	tituloProva: string;
	numQuestoes: number;
	numAvaliados: number;
	coeficienteAvaliadosQuestoes: number;
	// isAtendeCoeficiente: boolean;
	codsPFsComTodasQuestoes: number[];
	codsQuestoes: number[];
}
type ExibicaoDatasetTO = {
	codProva: number;
	pathDatasetArmazenamento: string;
	identificadorGeracao: string;
	codsQuestoesSemVariancia: number[];
	codsQuestoes: number[];
	listaListagemDificuldadeTO: any[];
	dataset: object[];
	resultadoCalculoTRITO: ResultadoCalculoTRITO
}
type ResultadoCalculoTRITO = {
	tipoModelo: TipoModeloTRI;
	listaResultadoCalculoTRIQuestaoTO: ResultadoCalculoTRIQuestaoTO[];
	urlGraficoCurvaInformacaoProva: string;
	urlGraficoCurvaInformacaoQuestoes: string;
	urlGraficoProbabilidadeAcertoQuestoes: string;
	urlGraficoProbabilidadeAcertoQuestoesConsolidado: string;
}
type ResultadoCalculoTRIQuestaoTO = {
	identificadorQuestao: string;
	discriminacao: number;
	dificuldade: number;
	chute: number;
	u: number;
	means: number;
	f1: number;
	infit: number;
	zInfit: number;
	outfit: number;
	zOutfit: number;
	motivoDescarte: string;
	dificuldadeTRI: string;
}
enum TipoModeloTRI {
	RASCH, DOISPL, TRESPL
}
enum TipoOperacaoTRI {
	COEF, TRACE, INFO, INFOTRACE, INFIT
}
