class CadastroBancaVH extends AmaisVH {

	constructor() {
		super(CadastroBancaVH.name);
	}

	buscaTextual = "";

	async listar() {
		const collectionListagemBancaTO = await this.call("CadastroBancaFCD/listarBancas", this.buscaTextual);
		await this.exibirListagem(collectionListagemBancaTO);
	}

	async exibirListagem(collectionListagemBancaTO) {

		this.limpar(true)

		this.addBotao({ label: "<i class='fa fa-plus-circle'></i> " + this.getMsg("MSG_VH_CB_01"), onClick: () => this.editar(), css: "float: right", classe: "btn-success btn-sm" });

		this.addCampoTexto({
			dica: this.getMsg("MSG_VH_CB_02"), 
			css: "width: 60%", 
			id: "busca", 
			valor: this.buscaTextual, 
			onEnter: async () => {

				this.buscaTextual = this.getValor("busca");

				collectionListagemBancaTO = await this.call("CadastroBancaFCD/listarBancas", this.getValor("busca"));

				await this.exibirBancas(collectionListagemBancaTO);
				this.buscaTextual = this.getValor("busca");
			}
		});

		this.append("<div id='listagem'></div>")

		this.exibir();

		await this.exibirBancas(collectionListagemBancaTO);
	}

	async exibirBancas(collectionListagemBancaTO) {
		this.setIdTarget("listagem")
		this.setHtml("listagem", "")

		const colunas: ColunaAddTabela[] = [];
		colunas.push({ titulo: this.getMsg("MSG_VH_CB_03"), prop: "sigla"});
		colunas.push({ titulo: this.getMsg("MSG_VH_CB_04"), prop: "nome", classe: "descricao"  });

		await this.addTabela({
			collection: collectionListagemBancaTO,
			propId: "codBanca",
			colunas: colunas,
			onEdicao: async (codBanca) => await this.editar(codBanca),
			propIsExcluivel: "isExcluivel"
		});
		this.exibir();
	}

	async editar(codBanca?) {
		const recuperacaoBancaTO = await this.call("CadastroBancaFCD/recuperar", codBanca);
		await this.exibirEdicao(recuperacaoBancaTO);
	}

	async exibirEdicao(recuperacaoBancaTO) {

		recuperacaoBancaTO = recuperacaoBancaTO || {};

		var nomePopUp = this.getMsg("MSG_VH_CB_06");

		if (recuperacaoBancaTO.codBanca == null) {
			nomePopUp = this.getMsg("MSG_VH_CB_05");
		}

		this.addPopup({
			id: "exibicao_banca",
			titulo: nomePopUp,
			width: "400px",
			// height: "400px",
			botoes: [{ label: this.getMsg("MSG_VH_CB_07"), onClick: async () => this.salvar() }],
		});

		this.addInputHidden("codBanca", recuperacaoBancaTO.codBanca);

		this.addCampoTexto({ id: "sigla_banca", label: this.getMsg("MSG_VH_CB_03"), valor: recuperacaoBancaTO.sigla, classe: "col-md-12" });
		this.addCampoTexto({ id: "nome_banca", label: this.getMsg("MSG_VH_CB_04"), valor: recuperacaoBancaTO.nome, classe: "col-md-12" });

		this.addBotao({
			label: "<i class='fa fa-plus-circle'></i> " + this.getMsg("MSG_VH_CB_08"), 
			css: "float: right", 
			classe: "btn-xs", 
			onClick: () => {
				this.setIdTarget("exibicao_banca")
				this.addCampoTexto({
					id: "equivalencia", 
					label: this.getMsg("MSG_VH_CB_08"), 
					classe: "col-md-12", 
					onEnter: async () => {

						recuperacaoBancaTO = await this.call("CadastroBancaFCD/adicionarEquivalencia", recuperacaoBancaTO.codBanca, this.getValor("equivalencia"));

						this.fecharPopup("exibicao_banca");

						setTimeout(async () => await cadastroBancaVH.exibirEdicao(recuperacaoBancaTO), 500);
					}
				});
				this.exibir();
			}
		});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({ titulo: this.getMsg("MSG_VH_CB_09"), prop: "descricao" });

		await this.addTabela({
			collection: recuperacaoBancaTO.equivalencias,
			propId: "descricao",
			colunas: colunas,
			onExclusao: async (botao, equivalencia) => {
				
				recuperacaoBancaTO = await this.call("CadastroBancaFCD/removerEquivalencia", recuperacaoBancaTO.codBanca, equivalencia);

				this.fecharPopup("exibicao_banca");

				setTimeout(async () => await cadastroBancaVH.exibirEdicao(recuperacaoBancaTO), 500);
			},
		})

		this.exibirPopups();
	}

	async salvar() {
		var salvamentoBancaTO = {
			codBanca: this.getValor("codBanca"),
			sigla: this.getValor("sigla_banca"),
			nome: this.getValor("nome_banca")
		}

		if (this.isEmpty(salvamentoBancaTO.sigla) && this.isEmpty(salvamentoBancaTO.nome)) {
			this.validarCamposObrigatorios("sigla_banca", "nome_banca");
			return false;
		}

		if (this.isEmpty(salvamentoBancaTO.sigla) || this.isEmpty(salvamentoBancaTO.nome)) {
			this.validarCamposObrigatorios("sigla_banca", "nome_banca");
			return false;
		}

		await this.call("CadastroBancaFCD/salvar", salvamentoBancaTO);
		this.listar();
	}

	async excluir(codBanca) {
		await this.call("CadastroBancaFCD/excluir", codBanca);
		this.listar();
	}

}

const cadastroBancaVH = new CadastroBancaVH();

