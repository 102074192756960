class FeedbacksVH extends AmaisVH {

	collectionListagemFeedbackConteudoTO: any;
	codFeedbackConteudoFinalizacao: any;
	isSalvamentoNovoFeedback: boolean;

	constructor() {
		super(FeedbacksVH.name);
		this.addOperacaoParaHash("fblf", this.listarFeedbacks);
		this.addOperacaoParaHash("fbin", this.iniciar);
		this.addOperacaoParaHash("fbic", this.iniciarFeedbackConteudo);
		this.addOperacaoParaHash("fbqt", this.exibirFeedbacksConteudoPorQuestao);
	}

	iniciar() {

		UtilHash.registrarHistorico(this.iniciar);

		this.limpar()

		this.setTitulo(this.getMsg("MSG_VH_F_01"))
		
		this.addLink({label: this.getMsg("MSG_VH_F_02"), onClick: () => this.iniciarFeedbackConteudo()})
		this.addLink({label: this.getMsg("MSG_VH_F_03"), onClick: () => this.listarFeedbacks()})
		
		this.exibir();
	}
	
	iniciarFeedbackConteudo() {
		UtilHash.registrarHistorico(this.iniciarFeedbackConteudo);

		this.exibirListagem();
	}

	async exibirListagem() {

		const collectionListagemFeedbackConteudoTO = await this.call("FeedbacksFCD/listarFeedbacksUsuarioLogado");

		this.collectionListagemFeedbackConteudoTO = collectionListagemFeedbackConteudoTO
		this.limpar()
		this.setTitulo(this.getMsg("MSG_VH_F_04"))
		this.setSubtitulo(this.getMsg("MSG_VH_F_05"))

		this.addTexto({
			texto: this.getMsg("MSG_VH_F_06") + ": ",
	        css: "margin-top: 10px"
		})

		this.addCampoTexto({
			id: "codFeedbackConteudo",
			onEnter: async () => await this.recuperarCodQuestaoDoFeedbackConteudo()
		})
		
		this.addTexto({
			texto: " " + this.getMsg("MSG_VH_F_07") + ":",
	        css: "margin-top: 10px"
		})

		this.addCampoTexto({
			id: "codQuestao",
			onEnter: async () => await this.exibirFeedbacksConteudoPorQuestao(this.getValor("codQuestao"))
		})
		
		if (collectionListagemFeedbackConteudoTO.length == 0) {
			this.addTexto(this.getMsg("MSG_VH_F_08"))

		} else {

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("MSG_VH_F_09"), prop: "codsFeedbacksConteudo"});
			colunas.push({titulo: this.getMsg("MSG_VH_F_10"), prop: "empresaDaQuestao"});
			colunas.push({titulo: this.getMsg("MSG_VH_F_11"), prop: "nomeDaProvaDaQualFoiExtraida", classe: "descricao"});
			colunas.push({titulo: this.getMsg("MSG_VH_F_12"), prop: this.montarTempoFeedbackMaisAntigo});
			
			await this.addTabela({
				collection: this.collectionListagemFeedbackConteudoTO,
				propId: "codQuestao",
				colunas: colunas,
				ordenar: false,
				onEdicao: this.exibirFeedbacksConteudoPorQuestao
			})
		}
		
		this.exibir();
	}
	
	montarTempoFeedbackMaisAntigo(listagemFeedbackConteudoTO) {
		return UtilData.getTempoUserFriendly(listagemFeedbackConteudoTO.dataFeedbackConteudoMaisAntigo)
	}
	
	async recuperarCodQuestaoDoFeedbackConteudo() {
		var codFeedbackConteudo = this.getValor("codFeedbackConteudo")
		const codQuestao = await this.call("FeedbacksFCD/recuperarCodQuestaoDoFeedbackConteudo", codFeedbackConteudo);
		this.exibirFeedbacksConteudoPorQuestao(codQuestao);
	}
	
	async exibirFeedbacksConteudoPorQuestao(codQuestao) {

		UtilHash.registrarHistorico(this.exibirFeedbacksConteudoPorQuestao, codQuestao);

		if (codQuestao == null) {
			codQuestao = this.getValor("codQuestao")
		}

		const exibicaoFeedbacksConteudoTO = await this.call("FeedbacksFCD/recuperarExibicaoFeedbacksConteudo", codQuestao);

		this.limpar()
		
		this.setTitulo(this.getMsg("MSG_VH_F_13"))
		
		exibicaoFeedbacksConteudoTO.listaFeedbackConteudoTO.forEach((feedbackConteudoTO, i) => {
			
			var idade = UtilData.getTempoUserFriendly(feedbackConteudoTO.dataCadastro);

			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_F_14"), 
				valor:  feedbackConteudoTO.codFeedbackConteudo,
				css: "width: 20%; margin: 30px 0 0 0"
			})
			
			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_F_15"), 
				valor:  idade,
				css: "width: 30%; margin-left:0;margin: 30px 90px 0px 0px"
			})
			
			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_F_16"), 
				valor:  this.montarSituacao(feedbackConteudoTO),
				css: "width: 20%; margin: 30px 0 0 0"
			})
			
			if (!feedbackConteudoTO.isFinalizado) {
				this.addBotao({
					texto: this.getMsg("MSG_VH_F_17"),
					onClick: () => this.exibirTelaFinalizacao(feedbackConteudoTO.codFeedbackConteudo),
				})
			
				this.addBotao({
					texto: this.getMsg("MSG_VH_F_18"),
					onClick: () => this.exibirTelaFeedbackConteudoEncaminhamento(feedbackConteudoTO.codFeedbackConteudo),
				})
			}

			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_F_19"), 
				valor:  feedbackConteudoTO.nomeUsuario,
				css: "width: 100%; margin: 10px 0 0"
			})
			
			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_F_20"), 
				valor:  feedbackConteudoTO.nomeEmpresa,
				css: "width: 100%; margin: 10px 0 0"
			})
			
			if(this.hasValue(feedbackConteudoTO.emailUsuario)) {
				this.addCampoExibicao({
					label: this.getMsg("MSG_VH_F_21"), 
					valor:  feedbackConteudoTO.emailUsuario,
					css: "width: 100%; margin: 10px 0 0"
				})
			}

			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_F_22"), 
				valor:  feedbackConteudoTO.observacao,
				css: "width: 100%; margin: 10px 0 0"
			})
			
			if(this.hasValue(feedbackConteudoTO.classificacaoSugerida)) {
				this.addCampoExibicao({
					label: this.getMsg("MSG_VH_F_23"), 
					valor:  feedbackConteudoTO.classificacaoSugerida,
					css: "width: 100%; margin: 10px 0 0"
				})
			}

			this.addEspacamentoHorizontal("10px");

			if (feedbackConteudoTO.exibicaoQuestaoTO) {
				this.addSubtitulo(this.getMsg("MSG_VH_F_24"));
				exibicaoQuestaoVH.limparControleTextos();
				exibicaoQuestaoVH.exibirQuestao(feedbackConteudoTO.exibicaoQuestaoTO);
			}
			
			this.append("<hr style= 'width: 100%; float: left'>");
		});
		
		this.addEspacamentoHorizontal("10px");
		
		this.addSubtitulo(this.getMsg("MSG_VH_F_25"));
		
		this.addBotao({onClick: () => cadastroQuestaoVH.exibirAbasQuestao(1, exibicaoFeedbacksConteudoTO.codQuestao), texto: "<i class='fa fa-pencil'></i> " + this.getMsg("MSG_VH_F_26"), classe: "btn-sm"});
		
		this.addBotao({
			label : "<i class='fa fa-refresh'></i>",
			onClick : () => this.atualizarFeedbacksDaQuestao(exibicaoFeedbacksConteudoTO.codQuestao),
			classe: "btn-sm",
			css: "float: right"
		})
		
		this.addBotao({
			label : this.getMsg("MSG_VH_F_27"),
			onClick: () => cadastroProvaVH.exibirAbasProva(2, exibicaoFeedbacksConteudoTO.codProva, exibicaoFeedbacksConteudoTO.codQuestao),
			classe: "btn-sm",
			html: " target='_blank' "
		})
		
		exibicaoQuestaoVH.limparControleTextos();
		exibicaoQuestaoVH.exibirQuestao(exibicaoFeedbacksConteudoTO.exibicaoQuestaoTO);
		
		this.addEspacamentoHorizontal("20px")

		this.addBotao({
			label : this.getMsg("MSG_VH_F_28"),
			onClick: () => this.voltarListagem()
		})

		this.exibir();
	}

	montarTempoFeedback(feedbackConteudoTO) {
		return UtilData.getTempoUserFriendly(feedbackConteudoTO.dataCadastro)
	}
	
	montarSituacao(feedbackConteudoTO) {
		if (feedbackConteudoTO.isFinalizado) {
			return this.getMsg("MSG_VH_F_29");
		} else if (feedbackConteudoTO.codFeedbackConteudoOrigem != null) {
			return this.getMsg("MSG_VH_F_30") +  " " + feedbackConteudoTO.codFeedbackConteudoOrigem
		} else {
			return this.getMsg("MSG_VH_F_31");
		}
	}

	atualizarFeedbacksDaQuestao(codQuestao) {
		this.exibirFeedbacksConteudoPorQuestao(codQuestao);
	}

	voltarListagem() {
		this.exibirListagem();
	}

	exibirTelaFinalizacao(codFeedbackConteudo) {
		this.addPopup({
			id : "feedback_conteudo",
			titulo : this.getMsg("MSG_VH_F_32"),
			botoes : [{label : this.getMsg("MSG_VH_F_33"), onClick : () => this.finalizarFeedbackConteudo()}]
		})
		
		this.addCheckbox({
			id : "enviarEmailParaUsuario",
			label : this.getMsg("MSG_VH_F_34"),
			valor : true,
			css: "margin: 5px 30px 5px 0"
		})
		
		this.addCheckbox({
			id : "feedBackProcedente",
			label : this.getMsg("MSG_VH_F_61"),
			valor : true,
			css: "margin: 5px 30px 5px 0"
		})
		
		this.addEditorHTML({
			id: "respostaParaSolicitante", 
			label: this.getMsg("MSG_VH_F_35"), 
			width: "520px", 
			height: "150px", toolbar: "basica"
		})
		
		this.addTexto(this.getMsg("MSG_VH_F_36"));
		
		this.exibirPopups();
		
		this.codFeedbackConteudoFinalizacao = codFeedbackConteudo
	}
	
	async finalizarFeedbackConteudo() {
		var finalizacaoFeedbackConteudoTO = {
			codFeedbackConteudo: this.codFeedbackConteudoFinalizacao,
			enviarEmailParaUsuarioQueAbriu: this.getValor("enviarEmailParaUsuario"),
			isProcedente: this.getValor("feedBackProcedente"),
			respostaParaSolicitante: this.getValor("respostaParaSolicitante")
		}
		const codQuestao = await this.call("FeedbacksFCD/finalizarFeedbackConteudo", finalizacaoFeedbackConteudoTO);
		this.atualizarFeedbacksDaQuestao(codQuestao);
	}

	async exibirTelaFeedbackConteudoEncaminhamento(id) {
		const edicaoFeedbackConteudoTO = await this.call("FeedbacksFCD/recuperarEdicaoFeedbackConteudoTO", id);
		await this.exibirTelaFeedbackConteudoEncaminhamentoCB(edicaoFeedbackConteudoTO);
	}

	async exibirTelaFeedbackConteudoEncaminhamentoCB(edicaoFeedbackConteudoTO) {

		this.getElementoHtml("div_questao_" + edicaoFeedbackConteudoTO.codQuestao).style.backgroundcolor = "yellow";

		this.addPopup({
			id : "feedback_conteudo",
			titulo : this.getMsg("MSG_VH_F_37"),
			botoes: [{label: this.getMsg("MSG_VH_F_38"), onClick: () => this.salvarFeedbackConteudo(edicaoFeedbackConteudoTO) }]
		})

		this.addTexto(this.getMsg("MSG_VH_F_39") + ":");

		this.addCheckbox({
			id : "isQualidadeConteudo",
			label : this.getMsg("MSG_VH_F_40"),
			valor : edicaoFeedbackConteudoTO.isQualidadeConteudo,
		})

		this.addCheckbox({
			id : "isGabarito",
			label : this.getMsg("MSG_VH_F_41"),
			valor : edicaoFeedbackConteudoTO.isGabarito,
		})

		if (!this.isAluno()) {
			this.addCheckbox({
				id : "isQualidadeComentario",
				label : this.getMsg("MSG_VH_F_42"),
				valor : edicaoFeedbackConteudoTO.isQualidadeComentario,
			});
		}

		this.addCheckbox({
			id : "isClassificacao",
			label : this.getMsg("MSG_VH_F_43"),
			valor : edicaoFeedbackConteudoTO.isClassificacao,
			onChange : async () => await this.exibirClassificacaoSugeridaPeloAluno()
		})

		this.addCampoTexto({
			id : "classificacao_sugerida_pelo_aluno",
			label : this.getMsg("MSG_VH_F_44"),
			isVisivel : false,
			valor : edicaoFeedbackConteudoTO.classificacaoSugerida,
		})
		
		this.addTextArea({
			id : "observacoes",
			dica: this.getMsg("MSG_VH_F_46"),
			valor : edicaoFeedbackConteudoTO.observacoes,
			cssTextarea: "min-height: 120px",
		})
		
		this.exibirPopups()

		if (edicaoFeedbackConteudoTO.isClassificacao) {
			await this.show("input_classificacao_sugerida_pelo_aluno");
		} else {
			await this.hide("input_classificacao_sugerida_pelo_aluno");
		}
	}

	async exibirTelaFeedbackConteudoNovo(codQuestao, codRespostaQuestao, isClassificacao) {

		var edicaoFeedbackConteudoTO = {
				id: null,
				codQuestao: codQuestao,
				codRespostaQuestao: codRespostaQuestao,
                isQualidadeConteudo: null,
                isClassificacao: isClassificacao,
                classificacaoSugerida: null,
                isGabarito: null,
                isQualidadeComentario: null,
                observacoes: null
		}
		
		await this.exibirTelaFeedbackConteudoEncaminhamentoCB(edicaoFeedbackConteudoTO);
	}

	async salvarFeedbackConteudo(edicaoFeedbackConteudoTO) {
		var algumaOpcaoMarcadaExcetoClassificacao = (this.getValor("isQualidadeConteudo") == true
													|| this.getValor("isGabarito") == true 
													|| this.getValor("isClassificacao") == true 
													|| this.getValor("isQualidadeComentario") == true)

		if (!algumaOpcaoMarcadaExcetoClassificacao) {
			await this.exibirAlerta({ msg: this.getMsg("MSG_VH_F_47") });
			return false;
		}
		
		if (this.getValor("isClassificacao") == false && this.isEmpty(this.getValor("observacoes"))) {
			await this.exibirAlerta({
				msg: this.getMsg("MSG_VH_F_48"),
				botoes: [{ label: this.getMsg("MSG_VH_003") }],
			});
			this.focar("observacoes");
			return false

		} else if (this.getValor("isClassificacao") == true && this.isEmpty(this.getValor("classificacao_sugerida_pelo_aluno"))) {

			await this.exibirAlerta({
				msg: this.getMsg("MSG_VH_F_48"),
				botoes: [{ label: this.getMsg("MSG_VH_F_49") }],
			});
			this.focar("classificacao_sugerida_pelo_aluno");
			return false
		}
		
		if(this.getValor("observacoes") == null) {
			edicaoFeedbackConteudoTO.observacoes = "";
		} else {
			edicaoFeedbackConteudoTO.observacoes = this.getValor("observacoes");
		}

		var salvamentoFeedbackConteudoTO = {
			id : edicaoFeedbackConteudoTO.id,
			codQuestao : edicaoFeedbackConteudoTO.codQuestao,
			codRespostaQuestao : edicaoFeedbackConteudoTO.codRespostaQuestao,
			isQualidadeConteudo : this.getValor("isQualidadeConteudo"),
			isClassificacao : this.getValor("isClassificacao"),
			classificacaoSugerida : this.getValor("classificacao_sugerida_pelo_aluno"),
			isGabarito : this.getValor("isGabarito"),
			isQualidadeComentario : this.getValor("isQualidadeComentario"),
			observacoes : edicaoFeedbackConteudoTO.observacoes
		}

		let codQuestao;

		if (salvamentoFeedbackConteudoTO.id == null) {
			this.isSalvamentoNovoFeedback = true
			codQuestao = await this.call("FeedbacksFCD/salvarFeedbackConteudo", salvamentoFeedbackConteudoTO);
		} else {
			codQuestao = await this.call("FeedbacksFCD/encaminharFeedbackConteudo", salvamentoFeedbackConteudoTO);
		}

		this.salvarFeedbackConteudoCB(codQuestao);
	}
	
	salvarFeedbackConteudoCB(codQuestao) {
		if (this.isSalvamentoNovoFeedback) {
			this.mostrarMsgAjax(this.getMsg("MSG_VH_F_50") + " <br>" + this.getMsg("MSG_VH_F_51") + "!", 4)
			this.isSalvamentoNovoFeedback = null
		} else {
			this.exibirFeedbacksConteudoPorQuestao(codQuestao);
		}
	}

	async exibirClassificacaoSugeridaPeloAluno() {
		if (this.isShown("input_classificacao_sugerida_pelo_aluno")) {
			await this.hide("input_classificacao_sugerida_pelo_aluno")
		} else {
			await this.show("input_classificacao_sugerida_pelo_aluno")
		}
	}
	
	exibirTelaRegistroFeedback(codProvaFeita) {
		this.addPopup({
			id : "registro_feedback",
			titulo : this.getMsg("MSG_VH_F_52"),
			botoes : [{
				label : this.getMsg("MSG_VH_F_53"), 
				id: "btEnviarFeedback", 
				onClick: async () => {
					if (!this.validarCamposObrigatorios("observacoes_feedback")) {
						return false
					}
					
					var salvamentoFeedbackTO = {
						codProvaFeita,
						comentarios: this.getValor("observacoes_feedback")
					}
					
					await this.call("FeedbacksFCD/salvarFeedback", salvamentoFeedbackTO);
					this.registrarFeedbackCB();
					this.disable("btEnviarFeedback");
					return false
				}
			}]
		})
		
		this.addTextArea({
			id : "observacoes_feedback",
			label : this.getMsg("MSG_VH_F_54"),
			classe: "col-sm-12",
			cssTextarea: "min-height: 120px",
			cssTextareaWrapper: "width: 100%"
		})
		
		this.exibirPopups()
		this.focar("observacoes_feedback")
	}
	
	registrarFeedbackCB() {
		this.setHtml("registro_feedback", "")
		this.setIdTarget("registro_feedback")
		this.addTexto(this.getMsg("MSG_VH_F_55"))
		this.setIdTarget(null)
		this.exibir();
		setTimeout("feedbacksVH.fecharPopup('registro_feedback')", 1500)
	}

	async listarFeedbacks(idAplicacao?: number, codProva?: number) {

		UtilHash.registrarHistorico(this.listarFeedbacks, idAplicacao, codProva);

		const exibicaoFeedbacksAplicacaoOuProvaTO = await this.call("FeedbacksFCD/listarFeedbacks", idAplicacao, codProva);

		this.limpar();
		
		this.setTitulo(exibicaoFeedbacksAplicacaoOuProvaTO.tituloProva);
		this.setSubtitulo(this.getMsg("MSG_VH_F_56"));
		
		var hashEdicaoAluno = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_F_57"), prop: "<a href='" + hashEdicaoAluno + "/${codUsuario}'><i class='fa fa-user'></i></a> ${nomeUsuario}"});
		colunas.push({titulo: this.getMsg("MSG_VH_F_58"), prop: "dataDoFeedback", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: this.getMsg("MSG_VH_F_59"), prop: "comentario", classe: "descricao"});
		colunas.push({titulo: this.getMsg("MSG_VH_F_60"), prop: this.montarLinkParaCorrecao});

		await this.addTabela({
			collection: exibicaoFeedbacksAplicacaoOuProvaTO.collectionListagemFeedbacksTO,
			colunas: colunas,
			ordenacao: [["dataDoFeedback", 1]],
		});
		
		this.exibir();
	}
	
	montarLinkParaCorrecao(listagemFeedbacksTO) {
		if (listagemFeedbacksTO.codProvaFeita == null || listagemFeedbacksTO.nota == null) {
			return "";
		}
		
		return "<a href='" + UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, listagemFeedbacksTO.codProvaFeita) + "'>" + UtilNumero.floatToString(listagemFeedbacksTO.nota) + "</a>"
	}
}

const feedbacksVH = new FeedbacksVH();