class RelDesempenhoVH extends DataWarehouseVH {

	colunas: ColunaAddTabela[];

	constructor() {
		super(RelDesempenhoVH.name);
	}

	async exibirRelDesempenhoPorQuestao() {
		return this.exibirRelDesempenho({
			campoAgrupamento: CampoRelRespostas.CONTEUDO_QUESTAO.id
		});
	}

	async exibirRelDesempenhoPorSegmento() {
		return this.exibirRelDesempenho({
			campoAgrupamento: CampoRelRespostas.NOME_EMPRESA_USUARIO.id
		});
	}

	async exibirRelDesempenhoPorProva() {
		return this.exibirRelDesempenho({
			campoAgrupamento: CampoRelRespostas.NOME_PROVA.id
		});
	}

	async exibirRelDesempenhoPorDisciplina() {
		return this.exibirRelDesempenho({
			campoAgrupamento: CampoRelRespostas.NOMES_DISCIPLINAS.id
		});
	}
	
	async exibirRelDesempenhoPorTurma() {
		return this.exibirRelDesempenho({
			campoAgrupamento: CampoRelRespostas.NOME_TURMA_NA_APLICACAO.id
		});
	}

	async exibirRelDesempenhoPorUsuario() {
		return this.exibirRelDesempenho({
			campoAgrupamento: CampoRelRespostas.NOME_USUARIO.id
		});
	}

	async exibirRelDesempenho(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO) {
		
		cfgsRelIndiceRespostasTO = this.verificar(cfgsRelIndiceRespostasTO);

		cfgsRelIndiceRespostasTO.paginacaoTO  = {
			numItensPorPagina: 10,
			numPaginaAtual: 0,
			numTotalItens: null
		};
		
		let resultadoDesempenhoTO = await this.call({
			endpoint: "RelatorioDesempenhoFCD/gerar",
			params: [cfgsRelIndiceRespostasTO],
			blockUiOnCarregando: true,
		});

		const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);
		let listaGrupoDesempenhoTO = resultadoDesempenhoTO.listaGrupoDesempenhoTO;

		this.colunas = [
			{titulo: campoAgrupamento.getNome(), prop: "nome", classe: "descricao"}
		];

		if (campoAgrupamento === CampoRelRespostas.CONTEUDO_QUESTAO) {
			this.colunas.push({titulo: this.getMsg("MSG_VH_RD_16"), prop: "codQuestao"});
		}
		
		if (listaGrupoDesempenhoTO?.some(to => to.nomeDisciplina != null)) {
			this.colunas.push({titulo: this.getMsg("MSG_VH_RD_22"), prop: "nomeDisciplina"});
		}

		if (listaGrupoDesempenhoTO?.some(to => to.numPFs != null)) {
			this.colunas.push({titulo: this.getMsg("MSG_VH_RP_19"), prop: "numPFs", formato: "numero"});
		}

		if (listaGrupoDesempenhoTO?.some(to => to.numRespostas != null)) {
			this.colunas.push({titulo: this.getMsg("MSG_VH_RD_05"), prop: "numRespostas", formato: "numero"});
		}

		if (listaGrupoDesempenhoTO?.some(to => to.acertos != null)) {
			this.colunas.push({titulo: this.getMsg("MSG_VH_RAE_04"), prop: "acertos", formato: "numero"});
		}

		if (listaGrupoDesempenhoTO?.some(to => to.emBranco != null)) {
			this.colunas.push({titulo: this.getMsg("MSG_VH_RAE_06"), prop: "emBranco", formato: "numero"});
		}
		
		if (resultadoDesempenhoTO.numColunasParaItensME > 0) {
			for (let i = 0; i < resultadoDesempenhoTO.numColunasParaItensME; i++) {
				const letra = String.fromCharCode(65 + i);

				this.colunas.push({titulo: letra, prop: (grupoDesempenhoTO) => {
						if (grupoDesempenhoTO.mapaOcorrenciaNumItemMEEscolhido) {
							return grupoDesempenhoTO.mapaOcorrenciaNumItemMEEscolhido[i] || 0;
						} else {
							return null;
						}
					}
				});
			}
		}

		this.colunas.push({titulo: this.getMsg("MSG_VH_RD_07"), prop: "aproveitamento", formato: "porcentagem"});

		if (campoAgrupamento === CampoRelRespostas.NOME_USUARIO) {

			this.colunas.push({titulo: this.getMsg("FP_FRONT_RelDesempenhoVH_001"), prop: (resultadoDesempenhoTO) => {
					return this.addBotao({
						label: `<i class='fa fa-line-chart fa-lg' title="${this.getMsg("FP_FRONT_RelDesempenhoVH_002")}"></i>`,
						onClick: async () => await relDesempenhoVH.exibirGraficoDesempenhoMensal(resultadoDesempenhoTO),
						retornarHtml: true
					});
				}
			});
		}

		this.append(`
				<div id='grafico_rel_desempenho_wrapper' style='width: 100%; overflow: auto; margin: 10px 0'>
				</div>
		`);

		if(this.isAluno()){
			this.abrirAbaAccordion({
				id: 'accordion_tabela_rel_desempenho',
				titulo: this.getMsg("FP_FRONT_RelAcertosErrosVH_002"),
				aberta: false
			});

			this.append(`
				<div id="tabela_rel_desempenho" class="tabela-estatisticas col-md-12"></div>
			`);

			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();
		} else {
			this.append(`
				<div class="row">
					<div id="tabela_rel_desempenho" class="tabela-estatisticas col-md-12"></div>
				</div>
			`);
		}

		this.exibir();
		
		await this.exibirTabelaEGrafico(cfgsRelIndiceRespostasTO, resultadoDesempenhoTO);

		if(!this.isAluno()) this.collapseAccordion("accordion_grafico_rel_desempenho");
	}

	async exibirGraficoDesempenhoMensal(resultadoDesempenhoTO){
		this.addPopup({
			id: "exibicao_desempenho_mensal",
			titulo: `${this.getMsg("FP_FRONT_RelDesempenhoVH_003")} ${resultadoDesempenhoTO.nome}`,
			width: "70%",
			// height: "45rem",
			onShown: async () => {
				let consolidado = {};
				resultadoDesempenhoTO.desempenhoMensal.forEach(elemento => {
					const dataFormatada = moment(elemento.data).format("MM/YYYY");
					consolidado[dataFormatada] = elemento.aproveitamento;
				});
				await UtilGrafico.criarEChart("#grafico_desempenho_mensal", {
					tos: [consolidado],
					tipo: "LINHAS",
					yAxisFormatter: "{value} %",
					xAxisLabel: {
						interval: 0,
						rotate: 30
					},
					titulo: this.getMsg("FP_FRONT_RelDesempenhoVH_001")
				});

				this.exibir();
			}
		});

		this.append(`
			<div class="col-md-12">
				<div id="grafico_desempenho_mensal" style="min-height: 40rem; width: 100%;"></div>	
			</div>	
		`);

		this.exibirPopups();
		this.exibir();
	}
	
	async exibirGrafico(listaGrupoDesempenhoTO: any) {
		const tamanho = listaGrupoDesempenhoTO && listaGrupoDesempenhoTO.length || 0
		const h = tamanho * 75;

		const el = $("#grafico_rel_desempenho_wrapper");

		el.css('height',tamanho > 0 ? '320px' : '')

		if (!listaGrupoDesempenhoTO || !listaGrupoDesempenhoTO.length) {
			el.html(`
				<div>${this.getMsg('MSG_VH_RAE_46')}</div>
			`);
			return;
		}

		el.html(`
			<div id='grafico_rel_desempenho' class='ct-chart' style='width: 100%; height: ${h > 300 ? h+'px' : tamanho > 0 ? '300px' : ''}; margin: 0 auto;'>
			</div>
		`);

		await UtilGrafico.criarEChart("#grafico_rel_desempenho", {
			tos: listaGrupoDesempenhoTO.reverse(),
			labelBarra: "nome",
			labelSeries: "aproveitamento",
			isValorPercentual: true,
			tipo: "BARRAS_HORIZONTAIS",
		});
	}

	async exibirTabelaEGrafico(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, resultadoDesempenhoTO: any) {

		$("#tabela_rel_desempenho").html("");
		this.setIdTarget("tabela_rel_desempenho");

		const exportTodosResultados = async() => {
			this.disable("botao_export_all");
			const divMsgAjax = AmaisVH.criarDivMsgAjax(this.getMsg("MSG_VH_RAE_41"));

			let cfgsRelIndiceRespostasCopyTO: any = {...cfgsRelIndiceRespostasTO};
			let colunasXlsx = this.colunas.map(item => {
				let identificador = item.prop;
				if (typeof identificador === 'function') {
					identificador = item.titulo;
				}
				return {
					nome: item.titulo, identificador: identificador, tipo: item.formato
				}
			});

			cfgsRelIndiceRespostasCopyTO['paginacaoTO'] = {
				numPaginaAtual: 0, numItensPorPagina: 9999
			}

			let resultados = await this.call("RelatorioDesempenhoFCD/gerar", cfgsRelIndiceRespostasCopyTO);
			const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);

			if(campoAgrupamento === CampoRelRespostas.CONTEUDO_QUESTAO){
				if (resultados.numColunasParaItensME > 0) {
					for (let i = 0; i < resultadoDesempenhoTO.numColunasParaItensME; i++) {
						const letra = String.fromCharCode(65 + i);
						for(let item of resultados.listaGrupoDesempenhoTO){
							if (item.mapaOcorrenciaNumItemMEEscolhido) {
								item[letra] = item.mapaOcorrenciaNumItemMEEscolhido[i] || 0;
							} else {
								item[letra] = null;
							}
						}
					}
				}
			}

			await UtilXlsx.criarXlsx(
				colunasXlsx,
				resultados.listaGrupoDesempenhoTO,
				"export_desempenho_" + campoAgrupamento.getNome().toString().toLowerCase()
			);

			divMsgAjax.remove();
			this.enable("botao_export_all");
		}

		const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);

		this.append('<div id="tabela_desempenho_container" style="display: none">');

		await this.addTabela({
			id: 'rel_desempenho',
			collection: resultadoDesempenhoTO.listaGrupoDesempenhoTO,
			propId: "cod",
			colunas: this.colunas,
			paginacao: {
				paginacaoTO: resultadoDesempenhoTO.paginacaoTO,
				exibirPaginacaoRodape: true,
				onCarregarPagina: async (paginacaoTO) => {

					cfgsRelIndiceRespostasTO.paginacaoTO = paginacaoTO;

					resultadoDesempenhoTO = await this.call("RelatorioDesempenhoFCD/gerar", cfgsRelIndiceRespostasTO);

					await this.exibirGrafico(resultadoDesempenhoTO.listaGrupoDesempenhoTO);

					return resultadoDesempenhoTO.listaGrupoDesempenhoTO.reverse();
				}
			},
			onEdicao: !this.isAdministrador() ? null : campoAgrupamento.onEdicao,
			onEdicaoParam1: campoAgrupamento.onEdicaoParam1,
		});

		this.append('</div>')

		this.exibir();

		console.log(resultadoDesempenhoTO.listaGrupoDesempenhoTO)

		await this.exibirGrafico(resultadoDesempenhoTO.listaGrupoDesempenhoTO);

		$("#botao_export_all").click(exportTodosResultados);
	}
}

const relDesempenhoVH = new RelDesempenhoVH();
