class RelErrosAnuladasVH extends DataWarehouseVH {

	colunas: ColunaAddTabela[];

	constructor() {
		super(RelErrosAnuladasVH.name);
	}

	async exibirRelErrosAnuladasPorSegmento() {
		return this.exibirRelErrosAnuladas({
			campoAgrupamento: CampoRelRespostas.NOME_EMPRESA_USUARIO.id
		});
	}

	async exibirRelErrosAnuladasPorProva() {
		return this.exibirRelErrosAnuladas({
			campoAgrupamento: CampoRelRespostas.NOME_PROVA.id
		});
	}

	async exibirRelErrosAnuladasPorTurma() {
		return this.exibirRelErrosAnuladas({
			campoAgrupamento: CampoRelRespostas.NOME_TURMA_NA_APLICACAO.id
		});
	}

	async exibirRelErrosAnuladasPorUsuario() {
		return this.exibirRelErrosAnuladas({
			campoAgrupamento: CampoRelRespostas.NOME_USUARIO.id
		});
	}

	async exibirRelErrosAnuladasPorQuestao() {
		return this.exibirRelErrosAnuladas({
			campoAgrupamento: CampoRelRespostas.CONTEUDO_QUESTAO.id
		});
	}

	async exibirRelErrosAnuladasPorDisciplina() {
		return this.exibirRelErrosAnuladas({
			campoAgrupamento: CampoRelRespostas.NOMES_DISCIPLINAS.id
		});
	}

	async exibirRelErrosAnuladas(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO) {

		cfgsRelIndiceRespostasTO = this.verificar(cfgsRelIndiceRespostasTO);

		cfgsRelIndiceRespostasTO.campoOrdenacao = "erros";
		cfgsRelIndiceRespostasTO.isOrdenacaoDecrescente = true;

		cfgsRelIndiceRespostasTO.paginacaoTO  = {
			numItensPorPagina: 10,
			numPaginaAtual: 0,
			numTotalItens: null
		};

		let resultadoAcertosErrosTO = await this.call({
			endpoint: "RelatorioErrosAnuladasFCD/gerar",
			params: [cfgsRelIndiceRespostasTO],
			blockUiOnCarregando: true,
		});
		const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);

		 this.colunas = [
			 {titulo: campoAgrupamento.getNome(), prop: "nome", classe: "descricao"},
			 {titulo: this.getMsg("MSG_VH_RAE_05"), prop: "erros", formato: "numero"},
			 {titulo: this.getMsg("MSG_VH_RAE_07"), prop: "anuladas", formato: "numero"}
		];

		this.append(`
				<div id="grafico_rel_erros_anuladas_wrapper" style='width: 100%; overflow: auto; margin: 10px 0'>
				</div>
		`);

		if(this.isAluno()){
			this.abrirAbaAccordion({
				id: 'accordion_tabela_rel_erros_anuladas',
				titulo: this.getMsg("FP_FRONT_RelAcertosErrosVH_002"),
				aberta: false
			});

			this.append(`
				<div id="tabela_rel_erros_anuladas" class="tabela-estatisticas"></div>
			`);

			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();
		} else {
			this.append(`
				<div class="row">
					<div id="tabela_rel_erros_anuladas" class="tabela-estatisticas col-md-12"></div>
				</div>
			`);
		}

		this.exibir();

		await this.exibirTabelaEGrafico(cfgsRelIndiceRespostasTO, resultadoAcertosErrosTO);

		if(!this.isAluno()) this.collapseAccordion("accordion_grafico_rel_erros_anuladas");
	}

	async exibirGrafico(listaGrupoAcertosErrosTO: any) {
		const tamanho = listaGrupoAcertosErrosTO?.length || 0
		const h = tamanho * 75;

		const el = $("#grafico_rel_erros_anuladas_wrapper");

		el.css('height',tamanho > 0 ? '320px' : '')

		if (!listaGrupoAcertosErrosTO?.length) {
			el.html(`
				<div>${this.getMsg('MSG_VH_RAE_46')}</div>
			`);
			return;
		}

		el.html(` 
			<div id='grafico_rel_erros_anuladas' class='ct-chart' style='width: 100%; height: ${h > 300 ? h+'px' : tamanho > 0 ? '300px' : ''}; margin: 0 auto;'>
			</div>
		`);

		const labels = {
			"erros": this.getMsg('MSG_VH_RAE_43'),
			"anuladas": this.getMsg('MSG_VH_RAE_45'),
		}

		const cores = {
			"erros": UtilGrafico.COR_VERMELHO,
			"anuladas": UtilGrafico.COR_AMARELO,
		}

		await UtilGrafico.criarEChart("#grafico_rel_erros_anuladas", {
			tos: listaGrupoAcertosErrosTO.reverse(),
			labels: labels,
			cores: cores,
			labelBarra: "nome",
			tipo: "BARRAS_HORIZONTAIS_EMPILHADAS",
		});

	}

	async exibirTabelaEGrafico(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, resultadoHistogramaTO: any) {

		$("#tabela_rel_erros_anuladas").html("");
		this.setIdTarget("tabela_rel_erros_anuladas");

		const exportTodosResultados = async() => {
			this.disable("botao_export_all");
			const divMsgAjax = AmaisVH.criarDivMsgAjax(this.getMsg("MSG_VH_RAE_41"));

			let cfgsRelIndiceRespostasCopyTO: any = {...cfgsRelIndiceRespostasTO};
			let colunasXlsx = this.colunas.map(item => {
				return {
					nome: item.titulo, identificador: item.prop, tipo: item.formato
				}
			});

			cfgsRelIndiceRespostasCopyTO['paginacaoTO'] = {
				numPaginaAtual: 0, numItensPorPagina: 9999
			}

			let resultados = await this.call("RelatorioErrosAnuladasFCD/gerar", cfgsRelIndiceRespostasCopyTO);
			const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);

			await UtilXlsx.criarXlsx(
				colunasXlsx,
				resultados.listaGrupoAcertosErrosTO,
				"export_acertoseerrosanuladas_" + campoAgrupamento.getNome().toString().toLowerCase()
			);

			divMsgAjax.remove();
			this.enable("botao_export_all");
		}

		const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);
		this.append('<div id="tabela_erros_anuladas_container" style="display: none">');

		await this.addTabela({
			id: "rel_erros_anuladas",
			collection: resultadoHistogramaTO.listaGrupoAcertosErrosTO,
			propId: "cod",
			colunas: this.colunas,
			paginacao: {
				paginacaoTO: resultadoHistogramaTO.paginacaoTO,
				exibirPaginacaoRodape: true,
				onCarregarPagina: async (paginacaoTO) => {

					cfgsRelIndiceRespostasTO.paginacaoTO = paginacaoTO;

					resultadoHistogramaTO = await this.call("RelatorioErrosAnuladasFCD/gerar", cfgsRelIndiceRespostasTO);

					await this.exibirGrafico(resultadoHistogramaTO.listaGrupoAcertosErrosTO);

					return resultadoHistogramaTO.listaGrupoAcertosErrosTO.reverse();
				}
			},
			onEdicao: !this.isAdministrador() ? null : campoAgrupamento.onEdicao,
			onEdicaoParam1: campoAgrupamento.onEdicaoParam1,
		});
		this.append('</div>');

		this.exibir();

		await this.exibirGrafico(resultadoHistogramaTO.listaGrupoAcertosErrosTO);

		$("#botao_export_all").click(exportTodosResultados);
	}
}

const relErrosAnuladasVH = new RelErrosAnuladasVH();
