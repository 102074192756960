class EstatisticaQuestaoVH extends AmaisVH {

	prefixoDivExibicaoEstatisticasQuestao: any;
	prefixoDivExibicaoEstatisticasItens: any;

	constructor() {
		super(EstatisticaQuestaoVH.name);
	}
	
	async exibirEstatisticas(codQuestao, prefixoDivQuestao, prefixoDivItens) {
		let estatisticasJaExibidas = $("#" + prefixoDivQuestao + codQuestao).attr("estatisticasExibidas");
		
		if (estatisticasJaExibidas == 'true') {
			return;
		}
		
		this.prefixoDivExibicaoEstatisticasQuestao = prefixoDivQuestao;
		this.prefixoDivExibicaoEstatisticasItens = prefixoDivItens;
		
		const exibicaoEstatisticasQuestaoTO = await this.call("EstatisticaQuestaoFCD/recuperarEstatisticasQuestao", codQuestao);
		
		if (this.prefixoDivExibicaoEstatisticasQuestao == null) {
			this.prefixoDivExibicaoEstatisticasQuestao = "estatisticasQuestao";
		}
		
		if (this.prefixoDivExibicaoEstatisticasItens == null) {
			this.prefixoDivExibicaoEstatisticasItens = "estatisticasItem";
		}

		if (exibicaoEstatisticasQuestaoTO.dificuldadeDaQuestao) {
			this.setIdTarget(this.prefixoDivExibicaoEstatisticasQuestao + exibicaoEstatisticasQuestaoTO.codQuestao);
			this.append("<p><strong>" + this.getMsg("MSG_VH_EQT_01") + ": </strong>" + exibicaoEstatisticasQuestaoTO.dificuldadeDaQuestao + "</p>");
			this.exibir();
		}
		
		let exibiuGrafico = false
		
		for (const exibicaoGraficoTO of exibicaoEstatisticasQuestaoTO.collectionExibicaoGraficoTO) {
			exibiuGrafico = true
			const div = $(`#${this.prefixoDivExibicaoEstatisticasQuestao}${exibicaoEstatisticasQuestaoTO.codQuestao}`);
			if (exibicaoGraficoTO.tipo == 'PIZZA') {
				const idGrafico =  `fp_grafico_pizza_${exibicaoEstatisticasQuestaoTO.codQuestao}`;
				div.append(`
						<div id="${idGrafico}" class="col-sm-12 col-xs-12 col-md-6 col-lg-6" style="height: 250px"></div>
					`)
				const to = {};
				const cores = {};
				const labels = {};
				for (let i = 0; i < exibicaoGraficoTO.labels.length; i++) {
					const nomeAtributo = exibicaoGraficoTO.labels[i].toLowerCase().replace(/ /g, '_'); // Substitui espaços por underscores
					to[nomeAtributo] = exibicaoGraficoTO.valores[i];
					cores[nomeAtributo] = `#${exibicaoGraficoTO.cores[i]}`;
					labels[nomeAtributo] = exibicaoGraficoTO.labels[i];
				}

				await UtilGrafico.criarEChart(`#${idGrafico}`, {
					tos: [to],
					labels: labels,
					cores: cores,
					tipo: "PIZZA",
					showLabel: true,
					labelPosition: 'inside',
					nome: exibicaoGraficoTO.tituloGrafico
				});

			} else if (exibicaoGraficoTO.tipo == 'BARRAS') {
				const tos = [];
				for (let i = 0; i < exibicaoGraficoTO.labels.length; i++) {
					const label = exibicaoGraficoTO.labels[i];
					const valor = exibicaoGraficoTO.valores[i];
					const novoObjeto = {
						alternativa: label,
						qtdMarcacoes: valor
					};
					tos.push(novoObjeto);
				}
				const idGrafico = `fp_grafico_barras_${exibicaoEstatisticasQuestaoTO.codQuestao}`;
				div.append(`
						<div id="${idGrafico}" class="col-sm-12 col-xs-12 col-md-6 col-lg-6" style="height: 250px">
						</div>
					`)

				await UtilGrafico.criarEChart(`#${idGrafico}`, {
					tos: tos,
					labelBarra: "alternativa",
					labelSeries: "qtdMarcacoes",
					title: exibicaoGraficoTO.tituloGrafico,
					tipo: "BARRAS"
				});
			}

		}
		
		if (!exibiuGrafico) {
			await this.exibirAlerta({ msg: this.getMsg("MSG_VH_EQT_02") });
		} else {
			$("#" + this.prefixoDivExibicaoEstatisticasQuestao + exibicaoEstatisticasQuestaoTO.codQuestao).show().attr("estatisticasExibidas", true)
		}
	}
}

const estatisticaQuestaoVH = new EstatisticaQuestaoVH();