class CadastroConcursoVH extends AmaisVH {

	listas: any;
	collectionListagemConcursoTO: any;
	codConcursoRepresentante: any;
	codsConcursosRepetidos: any;
	deveAssociarOrgaos: any;
	edicaoConcursoTO: any;

	constructor() {
		super(CadastroConcursoVH.name);
		this.addOperacaoParaHash("cclc", this.listar);
		this.addOperacaoParaHash("ccec", this.editarConcurso);	
	}

	listar(numAba = 0) {

		UtilHash.registrarHistorico(this.listar, numAba);

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_CadastroConcursoVH_001"));

		this.addAbas({
			abas: [
				{ label: this.getMsg("FP_FRONT_CadastroConcursoVH_002"), onClick: () => this.listarConcursos() },
				{ label: this.getMsg("FP_FRONT_CadastroConcursoVH_003"), onClick: () => cadastroOrgaoVH.listar() },
				{ label: this.getMsg("FP_FRONT_CadastroConcursoVH_004"), onClick: () => cadastroBancaVH.listar() },
				{ label: this.getMsg("FP_FRONT_CadastroConcursoVH_005"), onClick: () => cadastroCargoVH.listar() }
			], 
			numAbaAtiva: numAba
		});

		this.exibir();
	}

	async listarConcursos() {

		var filtrosListagemConcursosTO = {
			codOrgao: null,
			codBanca: null,
			ano: null,
		}

		const collectionListagemConcursoTO = await this.call("CadastroConcursoFCD/listar", filtrosListagemConcursosTO);

		await this.carregarListagens();

		this.limpar(true);

		await this.addSelect({
			collection: this.listas.cargos,
			id: "filtro_codCargo",
			onChange: async () => await this.listarComFiltros(),
			dica: this.getMsg("FP_FRONT_CadastroConcursoVH_006")
		})

		await this.addSelect({
			collection: this.listas.orgaos,
			id: "filtro_codOrgao",
			onChange: async () => await this.listarComFiltros(),
			dica: this.getMsg("FP_FRONT_CadastroConcursoVH_007")
		})

		await this.addSelect({
			collection: this.listas.anos,
			id: "filtro_ano",
			onChange: async () => await this.listarComFiltros(),
			dica: this.getMsg("FP_FRONT_CadastroConcursoVH_008")
		})

		await this.addSelect({
			collection: this.listas.bancas,
			id: "filtro_codBanca",
			onChange: async () => await this.listarComFiltros(),
			dica: this.getMsg("FP_FRONT_CadastroConcursoVH_009")
		})

		this.append("<div class='btn-group pull-right'>");
		this.addBotao({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_010"), onClick: () => this.exibirSelecaoEquivalentes(), classe: "btn-sm" });
		this.addBotao({ label: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroConcursoVH_011")}`, onClick: () => this.editarConcurso(), classe: "btn-success btn-sm" });
		this.append("</div>");

		this.append("<div id='listagem_concursos' style='float: left; width: 100%'>")

		this.append("</div>")

		this.exibir();

		this.collectionListagemConcursoTO = collectionListagemConcursoTO;

		await this.atualizarListagem(this.collectionListagemConcursoTO);
	}

	async listarComFiltros() {

		var filtrosListagemConcursosTO = {
			codCargo: this.getValor("filtro_codCargo"),
			codOrgao: this.getValor("filtro_codOrgao"),
			codBanca: this.getValor("filtro_codBanca"),
			ano: this.getValor("filtro_ano"),
		}

		const collectionListagemConcursoTO = await this.call("CadastroConcursoFCD/listar", filtrosListagemConcursosTO);

		await this.atualizarListagem(collectionListagemConcursoTO);
	}

	async carregarListagens() {
		if (this.listas == null) {
			this.listas = await this.call("CadastroConcursoFCD/recuperarListagens");
		}
	}

	async atualizarListagem(collectionListagemConcursoTO) {
		this.setHtml("listagem_concursos", "")
		this.setIdTarget("listagem_concursos")
		await this.montarListagem(collectionListagemConcursoTO)
		this.exibir();
		this.ativarTablesorters()
		this.setIdTarget(null)
	}

	async montarListagem(collectionListagemConcursoTO) {
		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: "nome"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroConcursoVH_012"), prop: "numProvas"});

		await this.addTabela({
			collection: collectionListagemConcursoTO,
			propId: "codConcurso",
			colunas: colunas,
			onEdicao: this.editarConcurso,
			ordenar: false
		})
	}

	editarOrgao(codOrgao) {
		cadastroOrgaoVH.editar(codOrgao);
	}

	irPara(metodoStr) {
		eval(metodoStr)
	}

	async excluir(codConcurso) {
		await this.call("CadastroConcursoFCD/excluir", codConcurso);
		this.listar();
	}

	async exibirSelecaoEquivalentes() {

		const collectionItemListaTOConcursos = await this.call("CadastroConcursoFCD/recuperarListagemConcursos");

		this.addPopup({
			id: "exibir_equivalentes",
			titulo: this.getMsg("FP_FRONT_CadastroConcursoVH_013"),
			width: "650px",
			// height: "435px",
			botoes: [{ label: "Associar", onClick: async () => this.exibirEquivalentesEscolhidos() }],
		});

		await this.addSelect({
			collection: collectionItemListaTOConcursos,
			id: "cod_concurso_representante",
			label: this.getMsg("FP_FRONT_CadastroConcursoVH_014")
		})

		await this.addSelect({
			collection: collectionItemListaTOConcursos,
			id: "cods_orgaos_repetidos",
			multiplo: true,
			label: this.getMsg("FP_FRONT_CadastroConcursoVH_015")
		})

		this.addCheckbox({
			id: "deve_associar_orgaos",
			label: this.getMsg("FP_FRONT_CadastroConcursoVH_016"),
			valor: true
		})

		this.exibirPopups();
	}

	exibirEquivalentesEscolhidos() {

		if (!this.validarCamposObrigatorios("cod_concurso_representante", "cods_orgaos_repetidos")) {
			return false
		}

		this.codConcursoRepresentante = this.getValor("cod_concurso_representante")
		this.codsConcursosRepetidos = this.getValor("cods_orgaos_repetidos")
		this.deveAssociarOrgaos = this.getValor("deve_associar_orgaos")


		this.addPopup({
			id: "associacao_de_equivalentes",
			titulo: this.getMsg("FP_FRONT_CadastroConcursoVH_017"),
			width: "400px",
			// height: "215px",
			botoes: [{ label: "Associar", onClick: () => this.associarEquivalentes() }],
		});

		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_018"), valor: this.getTextoSelect("cod_concurso_representante")})
		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_019"), valor: (<string[]> this.getTextoSelect("cods_orgaos_repetidos")).join("<br>")})

		this.exibirPopups();
	}

	async associarEquivalentes() {

		var associacaoConcursosEquivalentesTO = {
			codConcursoRepresentante: this.codConcursoRepresentante,
			codsConcursosRepetidos: this.codsConcursosRepetidos,
			deveAssociarOrgaos: this.deveAssociarOrgaos
		}

		await this.call("CadastroConcursoFCD/associarEquivalentes", associacaoConcursosEquivalentesTO);

		this.listar();
	}

	async editarConcurso(codConcurso?) {

		UtilHash.registrarHistorico(this.editarConcurso, codConcurso);

		await this.carregarListagens();

		if (codConcurso == null) {

			var edicaoConcursoTO = {
				nomeConcurso: this.getMsg("FP_FRONT_CadastroConcursoVH_020"),
			}

			await this.editarConcursoCB(edicaoConcursoTO);

		} else {
			const edicaoConcursoTO = await this.call("CadastroConcursoFCD/recuperarConcurso", codConcurso);
			await this.editarConcursoCB(edicaoConcursoTO);
		}
	}

	async editarConcursoCB(edicaoConcursoTO) {

		this.edicaoConcursoTO = edicaoConcursoTO;

		this.limpar();

		this.setTitulo(edicaoConcursoTO.nomeConcurso);
		this.setSubtitulo(this.getMsg("FP_FRONT_CadastroConcursoVH_021"));

		if (edicaoConcursoTO.pathArquivoEdital != null) {
			this.addLink({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_022"), href: edicaoConcursoTO.pathArquivoEdital });
		}

		if (edicaoConcursoTO.pathArquivoGabaritoPreliminar != null) {
			this.addLink({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_023"), href: edicaoConcursoTO.pathArquivoGabaritoPreliminar });
		}

		if (edicaoConcursoTO.pathArquivoGabaritoDefinitivo != null) {
			this.addLink({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_024"), href: edicaoConcursoTO.pathArquivoGabaritoDefinitivo });
		}

		if (edicaoConcursoTO.pathArquivoAtualizacaoDeGabarito != null) {
			this.addLink({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_025"), href: edicaoConcursoTO.pathArquivoAtualizacaoDeGabarito });
		}

		this.addEspacamentoHorizontal("10px");

		await this.addSelect({
			collection: this.listas.orgaos,
			id: "concurso_codOrgao", 
			label: this.getMsg("FP_FRONT_CadastroConcursoVH_026"), 
			valor: edicaoConcursoTO.codOrgao, 
			css: "width: 37%; float: left", 
			onChange: async () => await this.onChange() 
		});

		await this.addSelect({
			collection: this.listas.bancas,
			id: "concurso_codBanca", 
			label: this.getMsg("FP_FRONT_CadastroConcursoVH_027"), 
			valor: edicaoConcursoTO.codBanca, 
			css: "width: 37%; float: left", 
			onChange: async () => await this.onChange() 
		});

		this.addCampoTexto({ id: "concurso_ano", label: this.getMsg("FP_FRONT_CadastroConcursoVH_028"), valor: edicaoConcursoTO.ano, css: "width: 20%; float: left", onChange: async () => await this.onChange() });

		this.append("<div id='verificacaoDeConcursos' class='alert alert-dismissable' style='min-height:1px; display: inline-block; width: 100%'></div>");

		this.addCampoTexto({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_029"), id: "urlSiteConcurso", valor: edicaoConcursoTO.urlSiteConcurso, classe: "col-md-12" });

		this.addCampoTexto({ dica: this.getMsg("FP_FRONT_CadastroConcursoVH_030"), id: "urlUploadEdital", classe: "col-md-12" });

		this.addCampoTexto({ dica: this.getMsg("FP_FRONT_CadastroConcursoVH_031"), id: "urlUploadGabaritoPreliminar", classe: "col-md-12" });

		this.addCampoTexto({ dica: this.getMsg("FP_FRONT_CadastroConcursoVH_032"), id: "urlUploadGabaritoDefinitivo", classe: "col-md-12" });

		this.addCampoTexto({ dica: this.getMsg("FP_FRONT_CadastroConcursoVH_033"), id: "urlUploadAtualizacaoDeGabarito", classe: "col-md-12" });

		this.addBotao({
			label: this.getMsg("FP_FRONT_CadastroConcursoVH_034"), 
			css: "float: right", 
			onClick: async () => {

				if (!this.validarCamposObrigatorios("concurso_codBanca", "concurso_codOrgao", "concurso_ano")) {
					return false;
				}

				var salvamentoConcursoTO = {
					codConcurso: edicaoConcursoTO.codConcurso,
					codBanca: this.getValor("concurso_codBanca"),
					codOrgao: this.getValor("concurso_codOrgao"),
					ano: this.getValor("concurso_ano"),
					urlSiteConcurso: this.getValor("urlSiteConcurso"),
					urlUploadEdital: this.getValor("urlUploadEdital"),
					urlUploadGabaritoPreliminar: this.getValor("urlUploadGabaritoPreliminar"),
					urlUploadGabaritoDefinitivo: this.getValor("urlUploadGabaritoDefinitivo"),
					urlUploadAtualizacaoDeGabarito: this.getValor("urlUploadAtualizacaoDeGabarito")
				}

				const codConcurso = await this.call("CadastroConcursoFCD/salvarConcurso", salvamentoConcursoTO);

				this.editarConcurso(codConcurso);
			}
		});

		this.addSubtitulo(this.getMsg("FP_FRONT_CadastroConcursoVH_035"));

		this.addEspacamentoHorizontal("10px");

		this.addBotao({
			label: "<i class='fa fa-plus-circle'></i> Prova", css: "float: right", classe: "btn-sm btn-success", onClick: () => {
				importacaoProvaVH.editarImportacao(null, null, edicaoConcursoTO.codConcurso);
			}
		});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroConcursoVH_036"), prop: "tituloProva"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroConcursoVH_037"), prop: "nomesDosCargos"});
		colunas.push({titulo: "PDFs", prop: this.montarLinks});
		colunas.push({titulo: "", prop: "<i class='fa fa-pencil fa-lg'></i>"});
		colunas.push({titulo: "", prop: "<i class='fa fa-plus-circle fa-lg'></i>"});

		await this.addTabela({
			collection: edicaoConcursoTO.collectionListagemProvaNaEdicaoConcursoTO,
			propId: ["codProva", null, null, "codImportacao", "codImportacao"],
			colunas: colunas,
			onEdicao: [
				async (codProva) => await cadastroProvaVH.exibirAbasProva(2, codProva),
				null,
				null,
				async (codImportacao) => await importacaoProvaVH.editarImportacao(codImportacao),
				async (codImportacao) => await importacaoProvaVH.exibirEditor(codImportacao)
			]
		});

		this.addEspacamentoHorizontal("10px");

		this.exibir();
	}

	montarLinks(listagemProvaNaEdicaoConcursoTO) {
		var html = ["<span id='linksProva" + listagemProvaNaEdicaoConcursoTO.codProva + "'>"];

		if (listagemProvaNaEdicaoConcursoTO.pathArquivoProva != null) {
			html.push(this.addLink({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_038"), href: listagemProvaNaEdicaoConcursoTO.pathArquivoProva, retornarHtml: true }) + "<br>");
		}

		if (listagemProvaNaEdicaoConcursoTO.pathArquivoGabaritoPreliminar != null) {
			html.push(this.addLink({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_039"), href: listagemProvaNaEdicaoConcursoTO.pathArquivoGabaritoPreliminar, retornarHtml: true }) + "<br>");
		}

		if (listagemProvaNaEdicaoConcursoTO.pathArquivoGabaritoDefinitivo != null) {
			html.push(this.addLink({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_040"), href: listagemProvaNaEdicaoConcursoTO.pathArquivoGabaritoDefinitivo, retornarHtml: true }) + "<br>");
		}

		if (listagemProvaNaEdicaoConcursoTO.pathArquivoAtualizacaoGabarito != null) {
			html.push(this.addLink({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_041"), href: listagemProvaNaEdicaoConcursoTO.pathArquivoAtualizacaoGabarito, retornarHtml: true }) + "<br>");
		}

		return html.join("") + "</span>";
	}

	montarAcoes(listagemProvaNaEdicaoConcursoTO) {
		var html = [""];

		html.push(this.addBotao({ label: this.getMsg("FP_FRONT_CadastroConcursoVH_042"), retornarHtml: true, classe: "btn-sm", onClick: (event) => { } }));

		return html.join("");
	}

	async onChange() {

		var ano = this.getValor("concurso_ano");
		var codBanca = this.getValor("concurso_codBanca");
		var codOrgao = this.getValor("concurso_codOrgao");

		if (ano == null && codBanca == null && codOrgao == null) {
			$("#verificacaoDeConcursos").html("");
			return;
		}

		const collEdicaoConcursoTO = await this.call("CadastroConcursoFCD/listarConcursosParaVerificacao", ano, codBanca, codOrgao);

		var titulo = "";

		if (ano != null) titulo += ano + " ";
		if (codBanca != null) titulo += this.getTextoSelect("concurso_codBanca") + " ";
		if (codOrgao != null) titulo += this.getTextoSelect("concurso_codOrgao") + " ";

		if (collEdicaoConcursoTO.length > 0) {
			$("#verificacaoDeConcursos").html("<h4><b>" + this.getMsg("FP_FRONT_CadastroConcursoVH_043", titulo) + "</b></h4>");
			var table = ["<table style='width:100%'><tr>"];

			for (var i = 0; i < collEdicaoConcursoTO.length; i++) {

				if (i % 3 == 0) table.push("</tr><tr>")

				var edicaoConcursoTO = collEdicaoConcursoTO[i];
				var hash = UtilHash.getHash(this.editarConcurso, edicaoConcursoTO.codConcurso);
				var nome = "";

				if (ano == null) nome += edicaoConcursoTO.ano + " ";
				if (codOrgao == null) nome += edicaoConcursoTO.nomeOrgao + " ";
				if (codBanca == null) nome += edicaoConcursoTO.nomeBanca + " ";

				table.push("<td><a href='" + hash + "'>" + nome + "</a></td>")
			}

			table.push("</tr></table>")

			$("#verificacaoDeConcursos").append(table.join(""));

		} else {
			$("#verificacaoDeConcursos").html(`<small>${this.getMsg("FP_FRONT_CadastroConcursoVH_044")}</small>`);
		}
	}
}

const cadastroConcursoVH = new CadastroConcursoVH();
