class MettlProctoringService extends ProctoringService {
	
	proctoringSession: any;
	mp: MP;
	isTratandoEventoDeErro = false;
	
	constructor() {
		super(MettlProctoringService.name);
	}
	
	static TIPOS_DE_EVENTO = {
		"SCREEN_UNAVAILABLE": {
			msg: "O compartilhamento de tela foi interrompido ou parou de funcionar.",
			anular: true
		},
		"CAMERA_UNAVAILABLE": {
			msg: "O compartilhamento da câmera foi interrompido ou parou de funcionar.",
			anular: true
		},
		"SESSION_EXPIRED": {
			msg: "A sessão do usuário expirou.",
			anular: true
		},
		"DIAGONISTIC_CLOSED": {
			msg: "A aba do proctoring foi fechada.",
			anular: true
		},
    	"MICROPHONE_UNAVAILABLE": {
			msg: "O compartilhamento do microfone foi interrompido ou parou de funcionar.",
		},
    	"WEB_SOCKET_DISCONNECTION": {
			msg: "A conexão com o servidor de proctoring foi interrompida.",
		},
    	"INTERNET_DISCONNECTED": {
			msg: "A conexão com a internet foi interrompida.",
		},
    	"MICROPHONE_MUTED": {
			msg: "O microfone foi mutado.",
		},
    	"PROCTORING_STOPPED": {
			msg: "O monitoramento do proctoring foi interrompido.",
		},
		"KEEP_ALIVE": {},
		"KEEP_ALIVE_ACK": {},
		"START": {},
    	"STOP": {},
    	"CHAT": {},
		"MESSAGE": {},
    	"DIAGONISTIC_SUCCESS": {},
    	"DIAGONISTIC_FAILED": {},
    	"ANNOUNCEMENT": {},
    	"CANDIDATE_BLUR_OUT": {},
    	"CANDIDATE_BLUR_IN": {},
    	"LOG_NAVIGATE_AWAY_EVENT": {},
    	"MICROPHONE_UNMUTED": {},
    	"EXTENSION_UNAVAILABLE": {},
    	"INTERNET_CONNECTED": {},
    	"FOCUS_OUT": {},
    	"FOCUS_IN": {},
		"PAUSE_TEST": {},
		"PAUSE_ACK": {},
		"RESUME_TEST": {},
		"RESUME_ACK": {},
		"END_TEST": {},
		"END_ACK": {},
		"UPDATE_TEST_STATE": {},
		"MULTIPLE_SCREEN_DETECTED": {},
		"EXTERNAL_WEBCAM_DETECTED": {}
	}

	async pedirAutorizacoesUsuario(onAutorizacoesConcedidas: Function) {

		this.isTratandoEventoDeErro = false;

		const { mp } = await UtilBoot.recarregarProctoringMettl();

		this.mp = mp;

		this.mp.launch(
			this.fiscalizacaoMonitoradaTO.dadosSessao.token,
			// this.fiscalizacaoMonitoradaTO.chavePublica, 
			(mettlProctoringSession: any) => {
				
				this.proctoringSession = mettlProctoringSession;

				this.logger.info('Proctoring - launch successCallback', this.proctoringSession);
		
				this.proctoringSession.setMessageListener((message: any) => {
					if (message.type == 'MESSAGE') {
						this.triggerMsgChat({
							msg: message.data
						});
					}
				});

				this.proctoringSession.setErrorListener((error) => {
					
					if (this.isTratandoEventoDeErro) {
						this.logger.warn("Ignorando evento de erro. Já está tratando evento de erro mas recebeu um segundo: " + JSON.stringify(error));
						return;
					}

					this.isTratandoEventoDeErro = true;
					
					const errorType = error.type;
					const tipoDoEvento = MettlProctoringService.TIPOS_DE_EVENTO[errorType];
					let msg = this.getMsg("FP_FRONT_MettlProctoringService_001");
					let anular = false;

					if (tipoDoEvento != null) {
						if (tipoDoEvento.msg) {
							msg = tipoDoEvento.msg + "<br/>" + msg;
						}
						anular = (tipoDoEvento.anular === true);
					} else {
						this.logger.warn("Recebeu evento não previsto: ", error);
					}
					
					if (anular) {
						
						this.triggerMsgLog({
							msg,
							msgIntegracao: error
						});

						this.triggerInterrupcao();

					} else {

						this.triggerErro({
							msgUsuario: null,
							msgAuditoria: msg,
							msgIntegracaoAuditoria: error
						});
					}
				});

				onAutorizacoesConcedidas();
			}, 
			(data) => {
				this.logger.warn('Proctoring - launch errorCallback', data);
			},
			// this.fiscalizacaoMonitoradaTO.urlFrontend
		);
	}
	
	iniciarGravacao() {
		try {
			if (this.mp.getMettlWindow().closed) {
				throw "Mettl window closed.";
			}
		} catch (e) {
			this.logger("Erro ao verificar se janela da mettl está fechada", e);
		}
		this.proctoringSession.start();
	}
	
	isIniciado() {
		 return this.proctoringSession != null;
	}
	
	async parar() {
		if (this.proctoringSession) {
			try {
				this.proctoringSession.stop();
			} catch (e) {
				console.error(e);
			} finally {
				this.proctoringSession = null;
			}
		}
	}
	
	enviarMsgChat(msg: string) {
		this.proctoringSession.sendMessage(msg);
	}
}


type MP = {
	launch: (token: string, successCallback: Function, errorCallback: Function) => void;
	getMettlWindow: () => Window;
}