class CfgsDelegacaoCDsVH extends AmaisVH {
			
	constructor() {
		super(CfgsDelegacaoCDsVH.name);
	}

	async editarCorretoresTurma(codTurma: number, onAposSalvar: Function) {

		const edicaoTurmaECorretoresTO = await this.call("CorrecaoDiscursivasCfgFCD/recuperarEdicaoTurmaComCorretores", codTurma);

		this.addPopup({
			id: "edicao_corretores_turma",
			titulo: edicaoTurmaECorretoresTO.nomeTurma,
			botoes: [{
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_085"), 
				classe: "btn-primary", 
				onClick: async () => {
					const salvamentoCorretoresDeTurmaTO = {
						codTurma: codTurma,
						codsUsuariosCorretores: this.getValor("codsUsuariosCorretores")
					}
					await this.call("CorrecaoDiscursivasCfgFCD/salvarCorretoresDeTurma", salvamentoCorretoresDeTurmaTO);
					onAposSalvar();
				}
			}]
		});

		await this.addSelect({
			collection: edicaoTurmaECorretoresTO.listaOpcaoListaTOCorretores,
			id: "codsUsuariosCorretores",
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_086"),
			classe: "col-md-12",
			multiplo: true
		});

		this.exibirPopups();
	}

	async editarCorretoresDisciplina(codDisciplina: any, onAposSalvar: Function) {

		const edicaoDisciplinaECorretoresTO = await this.call("CorrecaoDiscursivasCfgFCD/recuperarEdicaoDisciplinaComCorretores", codDisciplina);

		this.addPopup({
			id: "edicao_corretores_disciplina", 
			titulo: edicaoDisciplinaECorretoresTO.nomeDisciplina, 
			botoes: [{
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_078"), 
				classe: "btn-primary", 
				onClick: async () => {
					const salvamentoCorretoresDeDisciplinaTO = {
						codDisciplina: codDisciplina,
						codsUsuariosCorretores: this.getValor("codsUsuariosCorretores")
					}
					await this.call("CorrecaoDiscursivasCfgFCD/salvarCorretoresDeDisciplina", salvamentoCorretoresDeDisciplinaTO);
					onAposSalvar();
				}
			}]
		});

		await this.addSelect({
			collection: edicaoDisciplinaECorretoresTO.listaOpcaoListaTOCorretores,
			id: "codsUsuariosCorretores",
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_079"),
			classe: "col-md-12",
			multiplo: true
		});

		this.exibirPopups();
	}
}

const cfgsDelegacaoCDsVH = new CfgsDelegacaoCDsVH();