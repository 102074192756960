class CadastroPerfilVH extends AmaisVH {

	constructor() {
		super(CadastroPerfilVH.name);
		this.addOperacaoParaHash("cpli", this.listar);
		this.addOperacaoParaHash("cpep", this.editarPerfil);
	}

	async listar() {
		
		UtilHash.registrarHistorico(this.listar);

		const exibicaoCadastroPerfisTO = await this.call("CadastroPerfilFCD/listar");

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CadastroPerfilVH_001"));

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroPerfilVH_002"), prop: "nome"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroPerfilVH_003"), prop: "nomeFuncionalidadeTelaInicial"});
		colunas.push({titulo: "", prop: "descricao"});

		await this.addTabela({
			collection: exibicaoCadastroPerfisTO.collectionPerfis,
			colunas: colunas,
			labelNovo: this.getMsg("FP_FRONT_CadastroPerfilVH_004"),
			onEdicao: async (idPerfil) => {
				
				if (idPerfil == null) {
					this.addPopup({
						titulo: this.getMsg("FP_FRONT_CadastroPerfilVH_005"), 
						botoes: [{
							label: this.getMsg("FP_FRONT_CadastroPerfilVH_006"), 
							onClick: async () => {
								if (!this.validarCamposObrigatorios("idPerfilPai")) return;
								await this.editarPerfil(null, this.getValor("idPerfilPai"));
								return true;
							}
						}]
					});
					
					await this.addSelect({
						collection: exibicaoCadastroPerfisTO.collectionPerfis.filter(p => p.idPerfilPai === null), 
						label: this.getMsg("FP_FRONT_CadastroPerfilVH_007"), 
						id: "idPerfilPai", 
						classe: "col-sm-12"
					});
					
					this.exibirPopups();
					
				} else {
					await this.editarPerfil(idPerfil);
				}
			}
		})
		
		this.exibir();
	}

	async editarPerfil(idPerfil, idPerfilPai?) {
		
		UtilHash.registrarHistorico(this.editarPerfil, idPerfil, idPerfilPai);

		const edicaoPerfilTO = await this.call("CadastroPerfilFCD/recuperarPerfil", idPerfil, idPerfilPai);

		this.limpar();
		this.setTitulo(edicaoPerfilTO.nome || this.getMsg("FP_FRONT_CadastroPerfilVH_008"))
		
		if (edicaoPerfilTO.isEditavel && edicaoPerfilTO.nome) this.setSubtitulo(this.getMsg("FP_FRONT_CadastroPerfilVH_009"));
		
		if (edicaoPerfilTO.isEditavel) {
			this.addCampoTexto({id: "nome", label: this.getMsg("FP_FRONT_CadastroPerfilVH_010"), valor: edicaoPerfilTO.nome});
			await this.addSelect({collection:[], label: this.getMsg("FP_FRONT_CadastroPerfilVH_011"), id: "idFuncionalidadeTelaInicial", valor: edicaoPerfilTO.idFuncionalidadeTelaInicial});
		}
		
		if (edicaoPerfilTO.idPerfilPai) {
			this.addCampoExibicao({label: this.getMsg("FP_FRONT_CadastroPerfilVH_012"), valor: edicaoPerfilTO.nomePerfilPai});
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroPerfilVH_014"), prop: "nome", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroPerfilVH_015"), prop: "vhEntrada"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroPerfilVH_016"), prop: "identificador"});

		await this.addTabela({
			titulo: this.getMsg("FP_FRONT_CadastroPerfilVH_013"),
			id: "idsFuncionalidades",
			collection: edicaoPerfilTO.collectionEdicaoPerfilFuncionalidadesTO,
			colunas: colunas,
			onEdicao: (edicaoPerfilTO.idPerfilPai == null ? cadastroModuloVH.editarFuncionalidade : null),
			selecao: edicaoPerfilTO.isEditavel
		});
		
		if (edicaoPerfilTO.isEditavel) {
			
			this.addBotao({
				label: this.getMsg("FP_FRONT_CadastroPerfilVH_017"), 
				amarrarCampos: ["idsFuncionalidades","nome"], 
				css: "float: right", 
				onClick: async () => {

					if (!this.validarCamposObrigatorios("nome", "idFuncionalidadeTelaInicial")) return;

					const gravacaoPerfilTO = {
						idPerfil: idPerfil,
						idPerfilPai: edicaoPerfilTO.idPerfilPai,
						idFuncionalidadeTelaInicial: this.getValor("idFuncionalidadeTelaInicial"),
						nome: this.getValor("nome"),
						idsFuncionalidades: this.getValor("idsFuncionalidades")
					}

					await this.call("CadastroPerfilFCD/salvarPerfil", gravacaoPerfilTO);
											
					const key = UtilBoot.getKeymaster();
					
					if (key?.shift) return;
					
					this.listar();
				}
			});
				
			if (idPerfil) {
				this.addBotao({
					label: this.getMsg("FP_FRONT_CadastroPerfilVH_018"), 
					css: "float: right", 
					onClick: async () => {
						await this.call("CadastroPerfilFCD/removerPerfil", idPerfil);
						this.listar();
					}
				});
			}
		}
		this.exibir({ isAffixIdentificacaoConteudo: true });
		
		this.atualizarSelectFuncionalidadesTelaInicial(edicaoPerfilTO.idFuncionalidadeTelaInicial);
		$("#idsFuncionalidades input[type='checkbox']").on("change", () => {
			this.atualizarSelectFuncionalidadesTelaInicial();
		});
	}
	
	atualizarSelectFuncionalidadesTelaInicial(idFuncionalidadeTelaInicial?) {
		const id = idFuncionalidadeTelaInicial || this.getValor("idFuncionalidadeTelaInicial");
		const c = [];
		$.each(this.getValor("idsFuncionalidades"), (i, id) => {
			const nome = $("tr[identificador='" + id + "'] td:nth-child(2)").text();
			const vhEntrada = $("tr[identificador='" + id + "'] td:nth-child(3)").text();
			if (vhEntrada) {
				c.push({
					id: id,
					nome: nome,
					descricao: vhEntrada
				});
			}
		});
		
		this.updateSelect({
			id: "idFuncionalidadeTelaInicial",
			collection: c,
		})
		
		this.setValor("idFuncionalidadeTelaInicial", id);
	}
}

const cadastroPerfilVH = new CadastroPerfilVH();