class CadastroOrgaoVH extends AmaisVH {
	codOrgaoRepresentante: any;
	codsOrgaosRepetidos: any;

	constructor() {
		super(CadastroOrgaoVH.name);
	}
	
	collectionListagemOrgaoTO = null;

	async listar() {
		const collectionListagemOrgaoTO = await this.call("CadastroOrgaoFCD/listarOrgaosEmpresaCorrente");
	
		this.limpar(true);

		this.addBotao({label: "<i class='fa fa-plus-circle'></i> Órgao", onClick: () => this.editar(), css: "float: right", classe: "btn-success btn-sm"});
		
		this.addLink({label:"Associar Equivalentes", onClick: async () => await this.exibirSelecaoEquivalentes(), css: "float: right", classe: "btn-sm"});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "Nome", prop: "descricao"});
		colunas.push({titulo: "Sigla", prop: "sigla"});

		await this.addTabela({
			collection: collectionListagemOrgaoTO,
			propId: "codOrgao",
			colunas: colunas,
			onEdicao: async (codOrgao) => await this.editar(codOrgao),
			propIsExcluivel: "isExcluivel",
			ordenar: false
		})
		
		this.exibir();
		
		this.collectionListagemOrgaoTO = collectionListagemOrgaoTO
	}
	
	async editar(codOrgao?) {
		
		const recuperacaoOrgaoTO = await this.call("CadastroOrgaoFCD/recuperar", codOrgao);
	
		var nomePopUp;
		if(recuperacaoOrgaoTO.codOrgao == null) {
			nomePopUp = "Novo órgão";
		} else {
			nomePopUp = "Edição de órgão";
		}
		
		this.addPopup({
			id: "exibicao_orgao", 
			titulo: nomePopUp,
			width: "650px",
			// height: "280px",
			botoes: [{label:"Salvar", onClick: () => this.salvar()}],
		});
		
		this.addInputHidden("codOrgao", recuperacaoOrgaoTO.codOrgao);
		
		await this.addSelect({
			collection: this.collectionListagemOrgaoTO,
			id: "orgao_superior",
			label: "&Oacute;rg&atilde;o Superior",
			propId: "codOrgao",
			propLabel: "descricao",
			valor: recuperacaoOrgaoTO.codOrgaoSuperior
		})
		
		await this.addSelect({
			collection: this.getCollectionUFs(),
			id: "orgao_uf", 
			label: "UF", 
			valor: recuperacaoOrgaoTO.uf
		});
		this.addCampoTexto({id: "nome_orgao", label: "Nome", valor: recuperacaoOrgaoTO.descricao});
		this.addCampoTexto({id: "sigla_orgao", label: "Sigla", valor: recuperacaoOrgaoTO.sigla});

		this.addBotao({label: "<i class='fa fa-plus-circle'></i> Equivalência", css: "float: right", classe: "btn-xs", onClick: () => {
			this.setIdTarget("exibicao_orgao");
			this.addCampoTexto({
				id: "equivalencia", 
				label: "Equivalência", 
				classe: "col-md-12", 
				onEnter: async () => {
					
					await this.call("CadastroOrgaoFCD/adicionarEquivalencia", recuperacaoOrgaoTO.codOrgao, this.getValor("equivalencia"));

					this.fecharPopup("exibicao_orgao");

					setTimeout(() => this.editar(recuperacaoOrgaoTO.codOrgao), 500);
				}
			});
			this.exibir();
		}});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "Equivalências", prop: "descricao"});
		
		await this.addTabela({
			collection: recuperacaoOrgaoTO.equivalencias,
			propId: "descricao",
			colunas: colunas,
			onExclusao: async (botao, equivalencia) => {
				await this.call("CadastroOrgaoFCD/removerEquivalencia", recuperacaoOrgaoTO.codOrgao, equivalencia);
				this.fecharPopup("exibicao_orgao");
				setTimeout(() => this.editar(recuperacaoOrgaoTO.codOrgao), 500);
			},
		})
		
		this.exibirPopups();
	}
	
	async salvar() {
		var salvamentoOrgaoTO = {
			codOrgao: this.getValor("codOrgao"),
			descricao: this.getValor("nome_orgao"),
			sigla: this.getValor("sigla_orgao"),
			codOrgaoSuperior: this.getValor("orgao_superior"),
			uf: this.getValor("orgao_uf")
		}
			
		if (this.isEmpty(salvamentoOrgaoTO.descricao) && this.isEmpty(salvamentoOrgaoTO.sigla)) {
			this.validarCamposObrigatorios("nome_orgao", "sigla_orgao")
			return false;
		}
		
		if (this.isEmpty(salvamentoOrgaoTO.descricao) || this.isEmpty(salvamentoOrgaoTO.sigla)) {
			this.validarCamposObrigatorios("nome_orgao", "sigla_orgao")
			return false;
		}

		await this.call("CadastroOrgaoFCD/salvar", salvamentoOrgaoTO);
		this.listar();
	}
	
	async excluir(codOrgao) {
		await this.call("CadastroOrgaoFCD/excluir", codOrgao);
		this.listar();
	}
	
	async exibirSelecaoEquivalentes() {
		
		this.addPopup({
			id: "associar_equivalencia", 
	        titulo: "Associação de equivalências",
	        width: "570px",
	        // height: "440px",
	        botoes: [{label:"Associar", onClick: async () => this.exibirEquivalentesEscolhidos()}],
		});
		
		await this.addSelect({ 
			collection: this.collectionListagemOrgaoTO,
			id: "orgao_representante",
			label: "Escolha o órgão que irá representar as equivalências",
			propId: "codOrgao",
			classe: "col-md-6"
		})
		
		await this.addSelect({
			collection: this.collectionListagemOrgaoTO,
			id: "orgaos_repetidos",
			multiplo: true,
			label: "Escolha os órgãos repetidos",
			propId: "codOrgao",
			classe: "col-md-6"
		})
		
		this.exibirPopups();
	}

	exibirEquivalentesEscolhidos() {
		
		if (!this.validarCamposObrigatorios("orgao_representante", "orgaos_repetidos")) {
			return false
		}
		
		this.codOrgaoRepresentante = this.getValor("orgao_representante")
		this.codsOrgaosRepetidos = this.getValor("orgaos_repetidos")
		
		if (this.codsOrgaosRepetidos.push == null) {
			// um valor selecionado, o retorno é o próprio código
			var cod = this.codsOrgaosRepetidos
			this.codsOrgaosRepetidos = []
			this.codsOrgaosRepetidos.push(cod)
		}
		
//		this.limpar()
//
//		this.setTitulo("&Oacute;rg&atilde;os")
//		this.setSubtitulo("Associação de equivalências")
//		
//		this.addCampoExibicao("Órgão representante", this.getNomeOrgao(this.codOrgaoRepresentante, this.collectionListagemOrgaoTO))
//		
//		var nomesOrgaosRepetidos = []
//		
//		for (var i = 0; i < this.codsOrgaosRepetidos.length; i++) {
//			nomesOrgaosRepetidos.push(this.getNomeOrgao(this.codsOrgaosRepetidos[i], this.collectionListagemOrgaoTO))
//		}
//		
//		this.addCampoExibicao("Órgãos repetidos", nomesOrgaosRepetidos.join(", "))
//
//		this.addBotao({label: "Cancelar", onClick: () => this.listar(), css: "float: left"})
//		this.addBotao({label: "Associar", onClick: () => this.associarEquivalentes(), css: "float: right"})
//
//		this.exibir();
		
		this.addPopup({
			id: "associacao_de_equivalencia", 
	        titulo: "Associação de equivalências",
	        width: "400px",
	        // height: "250px",
	        botoes: [{label:"Associar", onClick: () => this.associarEquivalentes()}],
		});
		
		this.addCampoExibicao({ label: "Órgão representante", valor: this.getNomeOrgao(this.codOrgaoRepresentante, this.collectionListagemOrgaoTO)})
		
		var nomesOrgaosRepetidos = []
		
		for (var i = 0; i < this.codsOrgaosRepetidos.length; i++) {
			nomesOrgaosRepetidos.push(this.getNomeOrgao(this.codsOrgaosRepetidos[i], this.collectionListagemOrgaoTO))
		}
		
		this.addCampoExibicao({ label: "Órgãos repetidos", valor: nomesOrgaosRepetidos.join(", ")})
		
		this.exibirPopups();
	}

	getNomeOrgao(codOrgao, collectionListagemOrgaoTO) {
		
		for (var i = 0; i < collectionListagemOrgaoTO.length; i++) {
			var listagemOrgaoTO = collectionListagemOrgaoTO[i]
			
			if (listagemOrgaoTO.codOrgao == codOrgao) {
				return listagemOrgaoTO.descricao
			} else if (this.hasValue(listagemOrgaoTO.orgaosInferiores)) {
				var descricao = this.getNomeOrgao(codOrgao, listagemOrgaoTO.orgaosInferiores)
				
				if (descricao != null) {
					return descricao
				}
			}
		}
		
		return null
	}
	
	async associarEquivalentes() {
		var associacaoOrgaosEquivalentesTO = {
			codOrgaoRepresentante: this.codOrgaoRepresentante,
			codsOrgaosRepetidos: this.codsOrgaosRepetidos
		}

		await this.call("CadastroOrgaoFCD/associarEquivalentes", associacaoOrgaosEquivalentesTO);
		this.listar();
	}

}

const cadastroOrgaoVH = new CadastroOrgaoVH();
	