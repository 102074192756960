class CorrecaoDiscursivasVH extends AmaisVH {

	filtroCorrecoesDelegacaoTO: any = null;
	canvas: any = null;
	filtrosSituacaoCorrecoesDisciplinasTO: any = null;
	filtrosCorrecoesSelecionadosPeloUsuarioTO: any = null;
	somenteComCorrecoesPendentes: any = null;
	codsAvaliadoresFiltro: any;
	tipoSituacaoCorrecaoDisciplinas: any;

	constructor() {
		super(CorrecaoDiscursivasVH.name);
		// this.addOperacaoParaHash("cdsc", this.exibirSituacaoCorrecoes);
		this.addOperacaoParaHash("correcoes", this.exibirDetalhamentoCorrecoes);
		this.addOperacaoParaHash("correcao", this.exibirTelaCorrecao);
		this.addOperacaoParaHash("cdac", this.exibirAcompanhamentoCorrecoes);
		this.addOperacaoParaHash("cdqd", this.exibirCorrecoesDisciplina);	
	}

	exibirSituacaoCorrecoes(numAbaESubaba?) {

		if (!this.isAdministrador()) {
			this.limpar();
			this.setTitulo(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_001"));
			this.exibirConsolidadoPorProva();
			return;
		}

		if (numAbaESubaba == null) {
			this.exibirSituacaoCorrecoes(0);
			return;
		}

		UtilHash.registrarHistorico(this.exibirSituacaoCorrecoes, numAbaESubaba);

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_002"));

		this.addAbas({
			abas: [{
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_003"),
				onClick: async () => await this.exibirConsolidadoPorProva()
			}, {
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_005", this.getCfg("LABEL_TURMA").toLowerCase()),
				onClick: async () => await this.exibirCorretoresPorTurma(),
				habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_CFG_CORRETORES)
			}, {
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_004"),
				onClick: async () => await this.exibirCorretoresPorDisciplina(),
				habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_CFG_CORRETORES)
			}], 
			numAbaAtiva: numAbaESubaba
		});

		this.exibir();
	}

	async exibirConsolidadoPorProva() {

		const filtrosCorrecoesSelecionadosPeloUsuarioTO = this.getFiltrosCorrecoesSelecionadosPeloUsuarioTO();

		filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = 0;

		const exibicaoCorrecoesConsolidadaTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/recuperarSituacaoConsolidada", filtrosCorrecoesSelecionadosPeloUsuarioTO);

		this.limpar(true);

		await this.addFiltros(
			filtrosCorrecoesSelecionadosPeloUsuarioTO, 
			exibicaoCorrecoesConsolidadaTO.exibicaoCorrecoesInfosFiltrosTO, 
			async () => await this.exibirConsolidadoPorProva()
		);

		if (exibicaoCorrecoesConsolidadaTO.listaCorrecoesConsolidadaParaAdmTO) {

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_117"), prop: "nomeGrupo", classe: "descricao"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_006"), prop: "numRespostas"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_007"), prop: "numCorrecoesPendentes"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_008"), prop: (listagemCorrecoesProvaTO) => {
					if (listagemCorrecoesProvaTO.numCorrecoesPendentes == 0) return;
					return UtilProgressBar.basic({
						evolucao: (listagemCorrecoesProvaTO.numRespostas - listagemCorrecoesProvaTO.numCorrecoesPendentes),
						total: listagemCorrecoesProvaTO.numRespostas
					});
				}
			});
			colunas.push({titulo: "", prop: (listagemCorrecoesProvaTO) => {
					if (listagemCorrecoesProvaTO.numCorrecoesPendentes > 0 && UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO)) {
						return this.addBotao({
							label: `<i class='fa fa-pencil-square'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_010")}`,
							classe: "btn-primary",
							onClick: (event) => {
								const to = this.getTOListagem(event.target);
								this.irParaProximaResposta(this.getFiltroCorrecoesDelegacaoTO(to));
							},
							retornarHtml: true
						});
					}
				}
			});

			await this.addTabela({
				collection: exibicaoCorrecoesConsolidadaTO.listaCorrecoesConsolidadaParaAdmTO,
				propId: "codProva",
				onEdicao: async (id, to) => {
					await this.exibirDetalhamentoCorrecoes(this.getFiltroCorrecoesDelegacaoTO(to));
				},
				colunas: colunas,
				msgListaVazia: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_009"),
				itensPorPagina: exibicaoCorrecoesConsolidadaTO.itensPorPagina,
				numTotalItensPaginacao: exibicaoCorrecoesConsolidadaTO.numTotalProvas,
				onCarregarPagina: async (paginacaoTO) => {
					filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = paginacaoTO.numPaginaAtual;
					filtrosCorrecoesSelecionadosPeloUsuarioTO.itensPorPagina = paginacaoTO.numItensPorPagina;
					const exibicaoCorrecoesConsolidadaTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/recuperarSituacaoConsolidada", filtrosCorrecoesSelecionadosPeloUsuarioTO);
					return exibicaoCorrecoesConsolidadaTO.listaCorrecoesConsolidadaParaAdmTO;
				},
				paginaAtiva: filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina,
				ordenar: false
			});
		}


		const exibirListagemDelegacao = async (propId, titulo, listaTOs) => {

			if (!listaTOs) return;

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: "", prop: "nomeGrupo", classe: "descricao"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_011"), prop: "numRespostas"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_012"), prop: "numCorrecoesPendentes"});
			colunas.push({titulo: "", prop: (listagemCorrecoesProvaTO) => {
					if (listagemCorrecoesProvaTO.numCorrecoesPendentes > 0) {
						return this.addBotao({
							label: `<i class='fa fa-pencil-square'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_014")}`,
							classe: "btn-primary",
							onClick: (event) => {
								const to = this.getTOListagem(event.target);
								this.irParaProximaResposta(this.getFiltroCorrecoesDelegacaoTO(to));
							},
							retornarHtml: true
						});
					}
				}
			});


			await this.addTabela({
				collection: listaTOs,
				propId,
				titulo,
				onEdicao: async (id, to) => {
					await this.exibirDetalhamentoCorrecoes(this.getFiltroCorrecoesDelegacaoTO(to));
				},
				colunas: colunas,
				msgListaVazia: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_013"),
			});
		}

		await exibirListagemDelegacao("codDisciplina", this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_015"), exibicaoCorrecoesConsolidadaTO.listaCorrecoesConsolidadaDelegacaoDisciplinaTO);
		await exibirListagemDelegacao("codTurma", this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_016"), exibicaoCorrecoesConsolidadaTO.listaCorrecoesConsolidadaDelegacaoTurmaTO);
		await exibirListagemDelegacao("codAgendamento", this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_017"), exibicaoCorrecoesConsolidadaTO.listaCorrecoesConsolidadaDelegacaoAplicacaoTO);
		await exibirListagemDelegacao("codProva", this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_018"), exibicaoCorrecoesConsolidadaTO.listaCorrecoesConsolidadaDelegacaoProvaTO);

		this.exibir();
	}

	async addFiltros(filtrosCorrecoesSelecionadosPeloUsuarioTO, exibicaoCorrecoesInfosFiltrosTO, onFiltrar) {

		this.abrirAbaAccordion({
			titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_019"),
			aberta: true,
		});

		const handleFiltrar = async () => {
			filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = null;
			filtrosCorrecoesSelecionadosPeloUsuarioTO.itensPorPagina = null;
			await onFiltrar.call(this);
		}

		this.addCampoTexto({
			dica: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_020"),
			id: "buscaTextualTurma",
			sufixo: "<i class='fa fa-search'></i>",
			valor: filtrosCorrecoesSelecionadosPeloUsuarioTO.buscaTextual,
			onChange: () => {
				filtrosCorrecoesSelecionadosPeloUsuarioTO.buscaTextual = this.getValor("buscaTextualTurma");
			},
			onEnter: async () => {
				filtrosCorrecoesSelecionadosPeloUsuarioTO.buscaTextual = this.getValor("buscaTextualTurma");
				await handleFiltrar();
			}
		});

		if (this.hasValue(exibicaoCorrecoesInfosFiltrosTO.nomesDisciplinasQueUsuarioLogadoPodeCorrigir)) {
			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_021"), valor: exibicaoCorrecoesInfosFiltrosTO.nomesDisciplinasQueUsuarioLogadoPodeCorrigir.join("<br>") });
		}

		const listaOpcaoListaTOTurmasSelecionadas = exibicaoCorrecoesInfosFiltrosTO.listaOpcaoListaTOTurmasSelecionadas;

		await this.addSelect({
			collection: listaOpcaoListaTOTurmasSelecionadas,
			id: "codsTurmas",
			dica: this.getCfg("LABEL_LISTAGEM_TURMAS"),
			multiplo: true,
			valor: listaOpcaoListaTOTurmasSelecionadas ? listaOpcaoListaTOTurmasSelecionadas.map(to => to.id) : null,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarTurmas",
				numMinimoCaracteres: 3,
			},
			onChange: () => {
				filtrosCorrecoesSelecionadosPeloUsuarioTO.codsTurmas = this.getValor("codsTurmas");
			}
		})

		const listaOpcaoListaTOClassificacoesSelecionadas = exibicaoCorrecoesInfosFiltrosTO.listaOpcaoListaTOClassificacoesSelecionadas;

		await this.addSelect({
			collection: listaOpcaoListaTOClassificacoesSelecionadas,
			id: "codsClassificacoes",
			dica: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_022"),
			valor: listaOpcaoListaTOClassificacoesSelecionadas ? listaOpcaoListaTOClassificacoesSelecionadas.map(to => to.id) : null,
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarClassificacoesUtilizadas",
				numMinimoCaracteres: 0,
			},
			onChange: () => {
				filtrosCorrecoesSelecionadosPeloUsuarioTO.codsClassificacoes = this.getValor("codsClassificacoes");
			}
		})

		if (this.isAnalista()) {

			const listaOpcaoListaTOCorretoresSelecionados = exibicaoCorrecoesInfosFiltrosTO.listaOpcaoListaTOCorretoresSelecionados;

			await this.addSelect({
				collection: listaOpcaoListaTOCorretoresSelecionados,
				id: "codsAvaliadores",
				dica: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_023"),
				valor: listaOpcaoListaTOCorretoresSelecionados ? listaOpcaoListaTOCorretoresSelecionados.map(to => to.id) : null,
				multiplo: true,
				visivel: !filtrosCorrecoesSelecionadosPeloUsuarioTO.somenteComCorrecoesPendentes,
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarCorretoresDiscursiva",
					numMinimoCaracteres: 0,
				},
				onChange: () => {
					filtrosCorrecoesSelecionadosPeloUsuarioTO.codsAvaliadores = this.getValor("codsAvaliadores");
				}
			})
		}

		this.addCheckbox({
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_024"),
			id: "somenteComCorrecoesPendentes",
			valor: filtrosCorrecoesSelecionadosPeloUsuarioTO.somenteComCorrecoesPendentes,
			onChange: async () => {
				filtrosCorrecoesSelecionadosPeloUsuarioTO.somenteComCorrecoesPendentes = this.getValor("somenteComCorrecoesPendentes");
				if (filtrosCorrecoesSelecionadosPeloUsuarioTO.somenteComCorrecoesPendentes) {
					this.setValor("codsAvaliadores", null);
					await this.hide("codsAvaliadores");
				} else {
					await this.show("codsAvaliadores");
				}
			}
		});

		if(exibicaoCorrecoesInfosFiltrosTO.listaOpcaoListaTOQuestoes != null){
			await this.addSelect({
				id: "codQuestao",
				collection: exibicaoCorrecoesInfosFiltrosTO.listaOpcaoListaTOQuestoes,
				dica: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_116"),
				multiplo: false,
				onChange: () => {
					filtrosCorrecoesSelecionadosPeloUsuarioTO.codQuestao = this.getValor("codQuestao");
				}
			});
		}

		this.append("<div class='col-md-12'>");
		this.addBotao({
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_025"),
			classe: "btn-primary pull-right",
			onClick: handleFiltrar
		});
		this.append("</div>");

		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
	}

	getFiltroCorrecoesDelegacaoTO(to) {
		if (to.codProva != null) {
			return { codProva: to.codProva };
		} else if (to.codTurma != null) {
			return { codTurma: to.codTurma };
		} else if (to.codAgendamento != null) {
			return { codAgendamento: to.codAgendamento };
		} else if (to.codDisciplina != null) {
			return { codDisciplina: to.codDisciplina };
		}
	}

	async irParaProximaResposta(filtroCorrecoesDelegacaoTO) {

		const filtrosCorrecoesSelecionadosPeloUsuarioTO = this.getFiltrosCorrecoesSelecionadosPeloUsuarioTO();
		
		const codRespostaQuestao = await this.call("CorrecaoDiscursivasFCD/recuperarProximaRespostaParaCorrecao", filtroCorrecoesDelegacaoTO, filtrosCorrecoesSelecionadosPeloUsuarioTO);

		if (codRespostaQuestao) {
			this.filtroCorrecoesDelegacaoTO = filtroCorrecoesDelegacaoTO;
			this.exibirTelaCorrecao(codRespostaQuestao);
		} else {
			if (this.filtroCorrecoesDelegacaoTO) {
				this.exibirDetalhamentoCorrecoes();
			} else {
				this.exibirSituacaoCorrecoes(0);
			}
		}
	}

	async exibirDetalhamentoCorrecoes(filtroCorrecoesDelegacaoTO = null) {

		if (!filtroCorrecoesDelegacaoTO) {
			this.exibirDetalhamentoCorrecoes(this.filtroCorrecoesDelegacaoTO || {});
			return;
		}

		UtilHash.registrarHistorico(this.exibirDetalhamentoCorrecoes, filtroCorrecoesDelegacaoTO);

		if (typeof filtroCorrecoesDelegacaoTO === 'string' || typeof filtroCorrecoesDelegacaoTO === 'number') {
			const codProva = filtroCorrecoesDelegacaoTO;
			filtroCorrecoesDelegacaoTO = {
				codProva: codProva
			}
		}

		this.filtroCorrecoesDelegacaoTO = filtroCorrecoesDelegacaoTO;

		const filtrosCorrecoesSelecionadosPeloUsuarioTO = this.getFiltrosCorrecoesSelecionadosPeloUsuarioTO();

		filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = 0;

		const exibicaoCorrecoesDetalhamentoTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/recuperarDetalhamento", filtroCorrecoesDelegacaoTO, filtrosCorrecoesSelecionadosPeloUsuarioTO);

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_026"));
		this.setSubtitulo(exibicaoCorrecoesDetalhamentoTO.nomeGrupo);

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EXPORTACAO)) {
			this.setIdTarget("tituloSuperiorDireito");
			this.addBotao({ 
				label: `<i class='fa fa-file-download'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_031")}`, 
				onClick: async () => await this.exibirOpcoesExportacaoRespostas(filtroCorrecoesDelegacaoTO.codProva), 
				classe: "btn-sm" 
			});
			this.setIdTarget(null);
		}

		await this.addFiltros(
			filtrosCorrecoesSelecionadosPeloUsuarioTO, 
			exibicaoCorrecoesDetalhamentoTO.exibicaoCorrecoesInfosFiltrosTO, 
			async () => await this.exibirDetalhamentoCorrecoes()
		);


		const onEdicao = [];

		const colunas: ColunaAddTabela[] = [];

		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_027"), prop: "dataEnvioDiscursiva", formato: "DD/MM/YY HH:mm"});
		onEdicao.push(null);

		if (filtrosCorrecoesSelecionadosPeloUsuarioTO.somenteComCorrecoesPendentes) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_028"), prop: function(detalhamentoCorrecaoTO) {
					if (detalhamentoCorrecaoTO.diasPrazoCorrecao == null || detalhamentoCorrecaoTO.dataEnvioDiscursiva == null || detalhamentoCorrecaoTO.dataCorrecao != null) return null;

					const dataLimite = UtilData.add(detalhamentoCorrecaoTO.dataEnvioDiscursiva, { dias: detalhamentoCorrecaoTO.diasPrazoCorrecao });
					const millisServidorUltimoFetch = UtilData.getDataServidorUltimoResponseMillis();
					const diasRestantes = UtilData.getDuracaoEmDias({ millis: dataLimite - millisServidorUltimoFetch });
					const tempoRestante = UtilData.getDuracao({ millis: Math.abs(dataLimite - millisServidorUltimoFetch) });

					if (diasRestantes >= 2) {
						return Math.floor(diasRestantes) + this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_029");
					} else if (diasRestantes > 1) {
						return Math.floor(diasRestantes) + this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_030");
					} else if (diasRestantes > 0.1) {
						return "<strong>" + tempoRestante + "</strong>";
					} else if (diasRestantes > 0) {
						return "<strong class='text-danger'>" + tempoRestante + "</strong>";
					} else {
						return `<span class='label label-danger'>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_032", tempoRestante)}</span>`;
					}
				}
			})
			onEdicao.push(null);
		}

		if (filtroCorrecoesDelegacaoTO.codTurma) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_033"), prop: () => exibicaoCorrecoesDetalhamentoTO.nomeGrupo});
		}
		else{
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_033"), prop: "nomeTurma"});
		}
		onEdicao.push(null);

		if (!filtroCorrecoesDelegacaoTO.codProva) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_034"), prop: "tituloProva"});
			onEdicao.push(null);
		}

		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_035"), prop: (detalhamentoCorrecaoTO) => {
				return detalhamentoCorrecaoTO.numQuestaoNaProva || "<small>#" + detalhamentoCorrecaoTO.codQuestao + "</small>";
			}
		});
		onEdicao.push(null);

		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_036"), prop: "classificacaoDaQuestao"});
		onEdicao.push(null);

		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_037"), prop: "identificadorDaReposta"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_038"), prop: "pontuacaoMaxima", formato: "numero"});

		onEdicao.push(this.exibirTelaCorrecao);

		if (!filtrosCorrecoesSelecionadosPeloUsuarioTO.somenteComCorrecoesPendentes) {
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_039"), prop: "pontuacaoObtida", formato: "numero"});
			colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_040"), prop: "dataCorrecao", formato: "DD/MM/YY HH:mm"});

			if (exibicaoCorrecoesDetalhamentoTO.mostrarColunaUsuarioCorrecao) {
				colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_041"), prop: "nomeUsuarioCorrecao"});
			}
		}

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EXPORTACAO) && !filtrosCorrecoesSelecionadosPeloUsuarioTO.somenteComCorrecoesPendentes) {
			this.addBotao({
				label: `<i class='fa fa-download'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_043")}`, 
				css: "float: right", 
				onClick: async () => {
					const codsRespostaQuestao = this.getValor("listagemCorrecoes");

					if (this.isEmpty(codsRespostaQuestao)) {
						this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_042") });
						return;
					}

					const urlZip = await this.call("CorrecaoDiscursivasExportacaoFCD/baixarCorrecoes", codsRespostaQuestao);
					this.exibirAlerta({ msg: `${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_044")}: <a href='${urlZip}'>${UtilArquivo.getNomeArquivo(urlZip)}</a>` });
				}
			})
		}

		await this.addTabela({
			collection: exibicaoCorrecoesDetalhamentoTO.listaDetalhamentoCorrecaoTO,
			id: "listagemCorrecoes",
			propId: "codRespostaQuestao",
			onEdicao: onEdicao,
			colunas: colunas,
			selecao: this.isAdministrador(),
			msgListaVazia: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_045"),
			salvarOrdenacao: true,
			itensPorPagina: exibicaoCorrecoesDetalhamentoTO.itensPorPagina,
			numTotalItensPaginacao: exibicaoCorrecoesDetalhamentoTO.numTotalCorrecoes,
			onCarregarPagina: async (paginacaoTO) => {
				filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = paginacaoTO.numPaginaAtual;
				filtrosCorrecoesSelecionadosPeloUsuarioTO.itensPorPagina = paginacaoTO.numItensPorPagina;
				const exibicaoCorrecoesConsolidadaTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/recuperarDetalhamento", filtroCorrecoesDelegacaoTO, filtrosCorrecoesSelecionadosPeloUsuarioTO);
				return exibicaoCorrecoesConsolidadaTO.listaDetalhamentoCorrecaoTO;
			},
			paginaAtiva: filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina,
			ordenar: false
		})

		this.exibir();
	}

	async exibirTelaCorrecao(codRespostaQuestao: number, codUsuarioCorretor: number = null, cfgsFluxoTela?: CfgsFluxoTela) {

		if (!cfgsFluxoTela || cfgsFluxoTela.registrarHistorico) UtilHash.registrarHistorico(this.exibirTelaCorrecao, codRespostaQuestao, codUsuarioCorretor);

		const exibicaoCorrecaoTO = await this.call("CorrecaoDiscursivasFCD/recuperarCorrecao", this.filtroCorrecoesDelegacaoTO, codRespostaQuestao, codUsuarioCorretor);
			
		this.limpar();

		this.canvas = {};

		this.setTitulo(cfgsFluxoTela?.titulo || `${exibicaoCorrecaoTO.identificadorDaReposta} <small> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_046")}</small>`);
		this.setSubtitulo(cfgsFluxoTela?.subtitulo || exibicaoCorrecaoTO.nomeGrupo);

		if (exibicaoCorrecaoTO.tempoRestanteParaCorrecao && exibicaoCorrecaoTO.tempoRestanteParaCorrecao > 0) {
			this.setIdTarget("tituloSuperiorDireito");
			this.append(`
			<div painel-cronometro class="col-md-6 col-xs-6">
				<span class="label label-info"  title="${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_136")}">
					...
				</span>
			</div>
		`);
			this.setIdTarget(null);
		}



		if (this.filtroCorrecoesDelegacaoTO == null || !this.filtroCorrecoesDelegacaoTO.codProva) {
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_047"),
				valor: exibicaoCorrecaoTO.tituloProva,
				classe: "col-md-12"
			});
		}

		const possuiRespostaOuMidia = exibicaoCorrecaoTO.resposta || exibicaoCorrecaoTO.exibicaoQuestaoTOResposta?.exibicaoRQTO?.collectionExibicaoMidiaTO?.length > 0
		
		exibicaoQuestaoVH.exibirQuestao(exibicaoCorrecaoTO.exibicaoQuestaoTO);

		let paramsCorrecaoDiscursiva = ['correcao-discursiva'];
		let listaMencoes = null;

		if (exibicaoCorrecaoTO.isProvaPorMencao === true) {
			paramsCorrecaoDiscursiva.push('correcao-discursiva-mencao');

			if(!this.isEmpty(exibicaoCorrecaoTO.listaMencoes)){
				listaMencoes = JSON.stringify(exibicaoCorrecaoTO.listaMencoes);
			}
		}

		this.append(`
			<div 
				${paramsCorrecaoDiscursiva.join(" ")} 
				class="col-sm-12" 
				data-lista-mencoes='${listaMencoes}' 
				cod-resposta-questao="${exibicaoCorrecaoTO.codRespostaQuestao}"
				pontuacao-maxima="${exibicaoCorrecaoTO.pontuacaoMaxima}"
			> 
		`);
		this.addEspacamentoHorizontal("20px");

		this.append('<div class="row flex-center-row">');

		if (this.hasValue(exibicaoCorrecaoTO.listaExibicaoCorrecaoFolhaRespostaQuestaoTO)) {
			this.append(`<h3>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_049")}</h3>`);

			for (let i = 0; i < exibicaoCorrecaoTO.listaExibicaoCorrecaoFolhaRespostaQuestaoTO.length; i++) {
				const exibicaoCorrecaoFolhaRespostaQuestaoTO = exibicaoCorrecaoTO.listaExibicaoCorrecaoFolhaRespostaQuestaoTO[i];

				if (exibicaoCorrecaoFolhaRespostaQuestaoTO.permiteCorrecaoNoCanvas) {
					this.append(`
						<div id='imagem-fr-${i}' id-frq='${exibicaoCorrecaoFolhaRespostaQuestaoTO.idFolhaRespostaQuestao}' class='col-md-12'>
							<i class='fa fa-spinner fa-pulse fa-2x fa-fw'></i> 
							<i>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_048")}</i>
						</div>
					`);
				} else {
					this.append(`
						<div correcao-fr-questao class='col-md-12'>
							<img src="${exibicaoCorrecaoFolhaRespostaQuestaoTO.pathAbsolutoImagemResposta}" />
						</div>
					`);
				}
			}

		} else if (possuiRespostaOuMidia) {
			exibicaoQuestaoVH.exibirQuestao(exibicaoCorrecaoTO.exibicaoQuestaoTOResposta);

		} else if (exibicaoCorrecaoTO.dataFimPF || exibicaoCorrecaoTO.isProvaJaCorrigida) {
			this.addTexto(`<i><strong><i class='fa fa-warning'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_050")}</strong></i>`);

		} else {
			this.addTexto(`<i><strong><i class='fa fa-warning'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_051")}</strong></i>`);
		}

		let possuiGradeDeCorrecao = false;

		if (exibicaoCorrecaoTO.exibicaoCriterioCorrecaoTO?.tipoCriterioCorrecao) {
			possuiGradeDeCorrecao = true;
		}

		if (possuiGradeDeCorrecao) {
			this.append(this.montarHtmlCriteriosCorrecao(exibicaoCorrecaoTO.exibicaoCriterioCorrecaoTO, true));
		}

		this.append('</div>');

		this.addEspacamentoHorizontal("20px");

		if (exibicaoCorrecaoTO.sugestaoCorrecao) {
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_121"),
				valor: exibicaoCorrecaoTO.sugestaoCorrecao,
				css: "word-wrap: anywhere",
				id: "sugestao-correcao-discursiva"
			});
		}

		if (this.hasValue(exibicaoCorrecaoTO.sugestaoDesempenho0a1)) {
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_122"), 
				valor: UtilNumero.floatToString(100 * exibicaoCorrecaoTO.sugestaoDesempenho0a1) + "%"
			});
		}

		if (this.hasValue(exibicaoCorrecaoTO.mencaoSugestaoCorrecao)) {
			this.addCampoExibicao({
				id: "mencao-sugestao-correcao-discursiva",
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_123"),
				valor: exibicaoCorrecaoTO.mencaoSugestaoCorrecao,
				css: "word-wrap: anywhere"
			});
		}

		if (this.hasValue(exibicaoCorrecaoTO.scoreFeedbackSugestao)) {
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_124"),
				valor: `
					${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_125", exibicaoCorrecaoTO.scoreFeedbackSugestao)}
					</br>
					${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_126", exibicaoCorrecaoTO.nomeUsuarioFeedbackSugestao)}
					</br>
					<small>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_127", UtilData.toDDMMYYYYHHMM(exibicaoCorrecaoTO.dataFeedbackSugestao))}</small>
				`,
				css: "word-wrap: anywhere"
			});
		}

		this.addEspacamentoHorizontal("20px");

		if (exibicaoCorrecaoTO.codCorrecaoDiscursivaSugestao && !exibicaoCorrecaoTO.scoreFeedbackSugestao && exibicaoCorrecaoTO.scoreFeedbackSugestao != 0) {
			const b = [];
			const valorMinimoAvaliacao = 1;
			const valorMaximoAvaliacao = 5;

			for (let i = valorMinimoAvaliacao; i <= valorMaximoAvaliacao; i++) {
				b.push(`
					<div style="display: flex; flex-direction: column; align-items: center; width: 60px; gap: 5px">
						<a style="border-radius: 50%" class="btn btn-default"
							onclick="correcaoDiscursivasVH.avaliarSugestaoCorrecao(this, ${i}, ${exibicaoCorrecaoTO.codCorrecaoDiscursivaSugestao})"
							title="${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_128", i)}">
							${i}
						</a>
						
						${i == valorMinimoAvaliacao ? `
							<small style="text-align: center">
								${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_129")}
							</small>
						`: ''}
						
						${i == valorMaximoAvaliacao ? `
							<small style="text-align: center">
								${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_130")}
							</small>
						`: ''}
					</div>
				`);
			}

			this.append(`
				<div style="margin-left: 15px;">
					<label>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_131")}</label>
					<div class="btn-group-avaliacao-sugestao-correcao" style="display: flex; margin-top: 30px">
						${b.join("")}
					</div>
				</div>
			`);
		}

		this.addEspacamentoHorizontal("20px");

		if (exibicaoCorrecaoTO.sugestaoCorrecao) {
			
			const btn = this.addBotao({
				label: `<i class='fa fa-copy'> </i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_132")}`,
				id: 'btn-copiar-sugestao-correcao',
				onClick: async () => {
					const textoSugestao = $('#sugestao-correcao-discursiva').html();
					const lastIndex = textoSugestao.lastIndexOf("<strong>");
					const textoSelecionado = textoSugestao.substring(lastIndex)
					if (textoSelecionado) {
						const obsCorrecao = $('.obsCorrecao > div[tipo="EDITOR_HTML"]');
						if (obsCorrecao.length) {
							let textoObsCorrecao = exibicaoCorrecaoTO.sugestaoCorrecao;
							if (obsCorrecao.text()) {
								textoObsCorrecao = `<br>${textoObsCorrecao}`;
							}
							obsCorrecao.append(textoObsCorrecao);
						}
					}
				},
				retornarHtml: true
			});

			this.append(`
				<div style="margin-left: 15px">${btn}</div>
			`);
		}

		this.addEspacamentoHorizontal("20px");

		this.addEditorHTML({
			id: "obsCorrecao" + exibicaoCorrecaoTO.codRespostaQuestao,
			classe: "col-lg-12 obsCorrecao",
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_052"),
			toolbar: "basica",
			valor: exibicaoCorrecaoTO.obsCorrecao,
			habilitado: UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO) && !exibicaoCorrecaoTO.isSomenteVisualizacao
		});
		this.addEspacamentoHorizontal("10px");

		let pontuacaoMaximaExibidaStr = UtilNumero.floatToString(exibicaoCorrecaoTO.pontuacaoMaxima);

		if (!possuiGradeDeCorrecao) {
			if (exibicaoCorrecaoTO.pontuacaoCorrecao) {
				this.addCampoTexto({
					id: "pontuacaoCorrecao" + exibicaoCorrecaoTO.codRespostaQuestao,
					classe: "col-sm-4 pontuacaoCorrecao",
					label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_053"),
					sufixo: "em " + pontuacaoMaximaExibidaStr, tipo: "NUMERO",
					valor: exibicaoCorrecaoTO.pontuacaoCorrecao,
					casasDecimais: 3,
					habilitado: UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO) && !exibicaoCorrecaoTO.isSomenteVisualizacao
				});
				this.addCampoTexto({
					id: "pontuacaoObtida" + exibicaoCorrecaoTO.codRespostaQuestao,
					classe: "col-sm-4 pontuacaoObtida",
					label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_054"),
					sufixo: "em " + pontuacaoMaximaExibidaStr, tipo: "NUMERO",
					valor: exibicaoCorrecaoTO.pontuacaoObtida,
					habilitado: false,
					casasDecimais: 3
				});
			}
			else {
				this.addCampoTexto({
					id: "pontuacaoObtida" + exibicaoCorrecaoTO.codRespostaQuestao,
					classe: "col-sm-4 pontuacaoObtida",
					label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_055"),
					sufixo: "em " + pontuacaoMaximaExibidaStr, tipo: "NUMERO",
					valor: exibicaoCorrecaoTO.pontuacaoObtida,
					casasDecimais: 3,
					habilitado: UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO) && !exibicaoCorrecaoTO.isSomenteVisualizacao
				});
			}
		}

		this.addEspacamentoHorizontal("10px");

		if (this.filtroCorrecoesDelegacaoTO) {
			this.addBotao({
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_056"), onClick: () => {
					if (this.filtroCorrecoesDelegacaoTO) {
						this.exibirDetalhamentoCorrecoes(this.filtroCorrecoesDelegacaoTO);
					} else {
						this.exibirSituacaoCorrecoes();
					}
				}
			});
		}

		this.append("<div class='btn-group pull-right'>");

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_AUDITORIA)) {
			this.addBotao({
				label: "Auditoria",
				html: "target='_blank'",
				onClick: () => loginVH.exibirAcessosDaCorrecaoDiscursiva(exibicaoCorrecaoTO.codRespostaQuestao)
			});
		}

		let labelBotaoFinalizacao = this.filtroCorrecoesDelegacaoTO ? this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_057") : this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_058");
		let classeBotaoFinalizacao = "btn-primary";

		if (cfgsFluxoTela) {
			labelBotaoFinalizacao = cfgsFluxoTela.labelBotaoFinalizacao;
			classeBotaoFinalizacao = cfgsFluxoTela.classeBotaoFinalizacao;
		}

		if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO) && !exibicaoCorrecaoTO.isSomenteVisualizacao) {

			if (cfgsFluxoTela?.botoesAposSalvar) {

				if (exibicaoCorrecaoTO.tempoRestanteParaCorrecao && exibicaoCorrecaoTO.tempoRestanteParaCorrecao > 0) {
					this.addBotao({
						label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_135"),
						classe: "btn-warning",
						onClick: async () => {
							await this.call("CorrecaoDiscursivasFCD/desatribuirUsuarioCorrecaoDiscursiva", exibicaoCorrecaoTO.codRespostaQuestao);

							acompanhamentoCDsVH.exibirDetalhamentoCorrecoesProva(exibicaoCorrecaoTO.codProva, exibicaoCorrecaoTO.tituloProva);
						}
					})
				}

				cfgsFluxoTela.botoesAposSalvar.forEach(botao => {
					this.addBotao({
						label: botao.label, 
						classe: botao.classe, 
						css: "float: right", 
						onClick: async () => {
							if (!await this.deveProsseguirComCorrecao(exibicaoCorrecaoTO.isReaberturaVigente)) {
								return;
							}
							const salvamentoCorrecaoDiscursivaTO = await this.getSalvamentoCorrecaoDiscursivaTO(
								exibicaoCorrecaoTO.codRespostaQuestao,
								codUsuarioCorretor,
								pontuacaoMaximaExibidaStr,
								exibicaoCorrecaoTO.pontuacaoMaxima
							);

							if (salvamentoCorrecaoDiscursivaTO == null) return;

							await this.call("CorrecaoDiscursivasFCD/salvarCorrecao", salvamentoCorrecaoDiscursivaTO);
							botao.onClick();
						}
					});
				});

			} else if(!exibicaoCorrecaoTO.isSomenteVisualizacao) {

				if (exibicaoCorrecaoTO.tempoRestanteParaCorrecao && exibicaoCorrecaoTO.tempoRestanteParaCorrecao > 0) {
					this.addBotao({
						label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_135"),
						classe: "btn-warning",
						onClick: async () => {
							await this.call("CorrecaoDiscursivasFCD/desatribuirUsuarioCorrecaoDiscursiva", exibicaoCorrecaoTO.codRespostaQuestao);

							acompanhamentoCDsVH.exibirDetalhamentoCorrecoesProva(exibicaoCorrecaoTO.codProva, exibicaoCorrecaoTO.tituloProva);
						}
					})
				}

				this.addBotao({
					label: labelBotaoFinalizacao, 
					classe: classeBotaoFinalizacao, 
					css: "float: right", 
					onClick: async () => {
						if (!await this.deveProsseguirComCorrecao(exibicaoCorrecaoTO.isReaberturaVigente)) {
							return;
						}

						const salvamentoCorrecaoDiscursivaTO = await this.getSalvamentoCorrecaoDiscursivaTO(
							exibicaoCorrecaoTO.codRespostaQuestao,
							codUsuarioCorretor,
							pontuacaoMaximaExibidaStr,
							exibicaoCorrecaoTO.pontuacaoMaxima
						)

						if (salvamentoCorrecaoDiscursivaTO == null) return;

						if (cfgsFluxoTela?.onClickBotaoFinalizacao) {
							cfgsFluxoTela.onClickBotaoFinalizacao(salvamentoCorrecaoDiscursivaTO);
							return;
						}

						await this.call("CorrecaoDiscursivasFCD/salvarCorrecao", salvamentoCorrecaoDiscursivaTO);
						UtilHash.voltar();
					}
				});

			}
		}

		this.append("</div>");

		this.addEspacamentoHorizontal("10px");
		this.append("</div>");

		this.exibir({ isDeveFazerScrollParaTitulo: true });

		$(".correcao-discursiva [questao]").find(".referenciaProva,[enunciado],[texto],.operacoes_sobre_questao,.exibicaoQuestaoEstatisticas").hide();

		if (exibicaoCorrecaoTO.exibicaoCriterioCorrecaoTO) {

			correcaoDiscursivasVH.atualizarPontuacoes(exibicaoCorrecaoTO.exibicaoCriterioCorrecaoTO);

			$("tr[id-correcao] input").on("change", () => {
				$(".pontuacao").text("");
				this.atualizarPontuacoes(exibicaoCorrecaoTO.exibicaoCriterioCorrecaoTO);
			})
		}

		if (this.hasValue(exibicaoCorrecaoTO.listaExibicaoCorrecaoFolhaRespostaQuestaoTO)) {

			for (let i = 0; i < exibicaoCorrecaoTO.listaExibicaoCorrecaoFolhaRespostaQuestaoTO.length; i++) {

				const exibicaoCorrecaoFolhaRespostaQuestaoTO = exibicaoCorrecaoTO.listaExibicaoCorrecaoFolhaRespostaQuestaoTO[i];
				
				if (exibicaoCorrecaoFolhaRespostaQuestaoTO.permiteCorrecaoNoCanvas) {
					const tag = "imagem-fr-" + i;
	
					let srcBase64 = await UtilImg.urlToBase64(exibicaoCorrecaoFolhaRespostaQuestaoTO.pathAbsolutoImagemResposta);
					await this.montarCanvasCorrecao(exibicaoCorrecaoFolhaRespostaQuestaoTO, srcBase64, tag, () => {
						this.exibirAlerta({ msg: "Ocorreu um erro ao carregar as imagens para correção." });
					});
				}
			}
		}

		if (exibicaoCorrecaoTO.tempoRestanteParaCorrecao === 0) {
			await this.handleTempoCorrecaoEsgotado(exibicaoCorrecaoTO);
		} else if (exibicaoCorrecaoTO.tempoRestanteParaCorrecao && exibicaoCorrecaoTO.tempoRestanteParaCorrecao > 0) {
			setTimeout(() => this.iniciarCronometroCorrecaoDiscursiva(exibicaoCorrecaoTO), 300);
		}

		if (exibicaoCorrecaoTO.isEmCorrecaoPorOutroUsuario) {
			await this.exibirAlerta({
				titulo: `<i class="fas fa-exclamation-triangle"></i> ${this.getMsg('MSG_VH_A_15')}`,
				msg: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_138"),
				botoes: [{label: 'Ok', classe: 'btn-primary'}]
			});
		}
	}

	iniciarCronometroCorrecaoDiscursiva(exibicaoCorrecaoTO) {
		const $tempoElement = $('[painel-cronometro] span');
		const crono = new CronoCorrecaoDiscursivaVH();
		crono.iniciarCronometro(exibicaoCorrecaoTO.tempoRestanteParaCorrecao*1000, $tempoElement, async () => {
			await this.handleTempoCorrecaoEsgotado(exibicaoCorrecaoTO);
		})
	}

	async handleTempoCorrecaoEsgotado(exibicaoCorrecaoTO) {
		await this.exibirAlerta({
			titulo: `<i class="fas fa-exclamation-triangle"></i> ${this.getMsg('MSG_VH_A_15')}`,
			msg: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_137"),
		});

		acompanhamentoCDsVH.exibirDetalhamentoCorrecoesProva(exibicaoCorrecaoTO.codProva, exibicaoCorrecaoTO.tituloProva);
	}

	avaliarSugestaoCorrecao(btnSelecionado, scoreFeedbackSugestao, codCorrecaoDiscursivaSugestao) {

		const btnGroup = btnSelecionado.closest(".btn-group-avaliacao-sugestao-correcao");
		let antesDoSelecionado = true;

		for (const btn of btnGroup.querySelectorAll("a")) {
			if (antesDoSelecionado) {
				btn.classList.remove("btn-default");
				btn.classList.add("btn-success");
			} else {
				btn.classList.add("btn-default");
				btn.classList.remove("btn-success");
			}

			if (btnSelecionado === btn) {
				antesDoSelecionado = false;
			}
		}

		btnGroup.setAttribute("score-selecionado", scoreFeedbackSugestao);
		btnGroup.setAttribute("cod-correcao-discursiva-sugestao", codCorrecaoDiscursivaSugestao);
	}

	async getSalvamentoCorrecaoDiscursivaTO(codRespostaQuestao: number, codUsuarioCorretor: number, pontuacaoMaximaExibidaStr: string, pontuacaoMaxima: number): Promise<SalvamentoCorrecaoDiscursivaTO> {

		let labelPontuacaoObtida = 'pontuacaoObtida';

		if (codUsuarioCorretor && this.getValor('pontuacaoCorrecao' + codRespostaQuestao)) {
			labelPontuacaoObtida = 'pontuacaoCorrecao';
		}

		let salvamentoCorrecaoDiscursivaTO: SalvamentoCorrecaoDiscursivaTO = {
			codRespostaQuestao: codRespostaQuestao,
			codUsuarioCorretor: codUsuarioCorretor,
			obsCorrecao: this.getValor("obsCorrecao" + codRespostaQuestao),
			pontuacaoObtida: this.getValor(labelPontuacaoObtida + codRespostaQuestao),
			scoreFeedbackSugestao: null,
			codCorrecaoDiscursivaSugestao: null,
			listaSalvamentoImagemRespostaTO: [],
			listaSalvamentoCorrecaoComCriterioTO: []
		}

		const btnGroup = document.querySelector(".btn-group-avaliacao-sugestao-correcao");
		const codCorrecaoDiscursivaSugestao = btnGroup?.getAttribute("cod-correcao-discursiva-sugestao");
		const scoreFeedbackSugestao = btnGroup?.getAttribute("score-selecionado");

		if (this.hasValue(codCorrecaoDiscursivaSugestao) && this.hasValue(scoreFeedbackSugestao)) {
			salvamentoCorrecaoDiscursivaTO.codCorrecaoDiscursivaSugestao = Number(codCorrecaoDiscursivaSugestao);
			salvamentoCorrecaoDiscursivaTO.scoreFeedbackSugestao = Number(scoreFeedbackSugestao);
		}

		$("[id-correcao]").each(function() {
			let salvamentoCorrecaoComCriterioTO: SalvamentoCorrecaoComCriterioTO = {
				pontuacao: Number($(this).attr("pontuacao")),
				idCorrecao: Number($(this).attr("id-correcao"))
			}
			salvamentoCorrecaoDiscursivaTO.listaSalvamentoCorrecaoComCriterioTO.push(salvamentoCorrecaoComCriterioTO);
		})

		let itens = 0;
		let itensMarcados = 0;

		if (this.isEmpty(salvamentoCorrecaoDiscursivaTO.pontuacaoObtida)) {
			$.unique($("[type=radio]").closest("[id-correcao]")).each(function() {
				itens++;
				$(this).find("td label.active input").each(function() {
					itensMarcados++;
				})
			})
			$.unique($("[type=number]").closest("[id-correcao]")).each(function() {
				itens++;
				$(this).find("td [type=number]").each(function() {
					if ($(this).val()) itensMarcados++;
				})
			})
		}

		if (itens != itensMarcados) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_059") });
			return;
		}

		const pontuacaoMaximaExibida = UtilNumero.stringToFloat(pontuacaoMaximaExibidaStr); // a pontuação máxima exibida arredonda para 3 casas decimais

		if (itens == 0 && (this.isEmpty(salvamentoCorrecaoDiscursivaTO.pontuacaoObtida) || salvamentoCorrecaoDiscursivaTO.pontuacaoObtida < 0 || salvamentoCorrecaoDiscursivaTO.pontuacaoObtida > pontuacaoMaximaExibida)) {
			await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_060") });
			$("#pontuacaoObtida" + codRespostaQuestao).focus();
			return;
		}

		if (salvamentoCorrecaoDiscursivaTO.pontuacaoObtida > pontuacaoMaxima) {
			salvamentoCorrecaoDiscursivaTO.pontuacaoObtida = pontuacaoMaxima;
		}

		$("[id-frq]").each(function() {
			const $fr = $(this);
			const canvas = correcaoDiscursivasVH.canvas[$fr.attr("id")];

			const salvamentoImagemRespostaTO: SalvamentoImagemRespostaTO = {
				pathImagemCorrecao: null,
				idFolhaRespostaQuestao: $fr.attr("id-frq"),
				novoPathImagemResposta: canvas._novoPathImagemResposta,
				dadosCorrecaoNoCanvas: JSON.stringify(canvas.getSnapshot())
			};

			salvamentoCorrecaoDiscursivaTO.listaSalvamentoImagemRespostaTO.push(salvamentoImagemRespostaTO);
		})

		const erroImagemCorrecao = () => {
			this.exibirAlerta({
				titulo: "Operação interrompida",
				msg: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_118")
			});
		}

		if (salvamentoCorrecaoDiscursivaTO.listaSalvamentoImagemRespostaTO.length > 0) {

			const div = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_061"));

			for (let salvamentoImagemRespostaTO of salvamentoCorrecaoDiscursivaTO.listaSalvamentoImagemRespostaTO) {

				if (salvamentoImagemRespostaTO.pathImagemCorrecao != null) continue;
				
				try {
					const url = await UtilImg.uploadCanvasesLC(`[id-frq="${salvamentoImagemRespostaTO.idFolhaRespostaQuestao}"] .lc-drawing`);

					salvamentoImagemRespostaTO.pathImagemCorrecao = UtilArmazenamento.converterPathAbsolutoParaRelativo(url);
					
				} catch (e) {
					div.remove();
					erroImagemCorrecao();
					this.logger.error("Ocorreu um erro ao salvar a imagem da correção", e);
				}
			}

			div.remove();
		}

		return salvamentoCorrecaoDiscursivaTO;
	}

	async montarCanvasCorrecao(exibicaoCorrecaoFolhaRespostaQuestaoTO, urlImagem, tag, onError) {
		await this.addCanvas({
			id: tag,
			urlImagem: urlImagem,
			snapshot: exibicaoCorrecaoFolhaRespostaQuestaoTO.dadosCorrecaoNoCanvas,
			onLoad: (canvas) => {
				this.canvas[canvas.idComponente] = canvas;
				if (!UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_EDICAO)) {
					$(`
						.lc-picker,
						.lc-options.horz-toolbar
					`).hide();
				}
			},
			onError: onError,
			onRotacionar: (canvas) => {
				let $canvas = $("#" + canvas.idComponente + " .lc-drawing > canvas:first-child");
				let vTransform = $canvas.css("transform");

				if (vTransform && vTransform != "none") {
					$canvas.css("transform", "");
					canvas._novoPathImagemResposta = null;

				} else {
					$canvas.css("transform", "rotate(180deg)");
					UtilImg.rotacionar(exibicaoCorrecaoFolhaRespostaQuestaoTO.pathAbsolutoImagemResposta, 180).then((url: string) => {
						canvas._novoPathImagemResposta = UtilArmazenamento.converterPathAbsolutoParaRelativo(url);
					});
				}
			},
			onDescartarImagem: () => {
				this.addPopup({
					id: "edicao_prova",
					titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_062"),
					botoes: [{
						label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_063"),
						classe: "btn-danger",
						onClick: async () => {}
					}]
				});

				this.addTexto(this.getMsg(
					"FP_FRONT_CorrecaoDiscursivasVH_064",
					UtilData.toDDMMYYYYHHMM(exibicaoCorrecaoFolhaRespostaQuestaoTO.dataEnvio)
				));

				if (exibicaoCorrecaoFolhaRespostaQuestaoTO.nomeUsuarioEnvio) {
					this.addTexto(this.getMsg(
						"FP_FRONT_CorrecaoDiscursivasVH_065",
						exibicaoCorrecaoFolhaRespostaQuestaoTO.nomeUsuarioEnvio,
						exibicaoCorrecaoFolhaRespostaQuestaoTO.nomePerfilUsuarioEnvio
					));

					this.addTextArea({
						label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_066"),
						id: "obsEnvioEmail",
						classe: "col-md-12",
						cssTextarea: "min-height: 150px"
					});

				} else {
					this.addTexto(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_067"));
				}

				this.exibirPopups();
			}
		});
	}

	montarHtmlCriteriosCorrecao(exibicaoCriterioCorrecaoTO, podeEditar) {
		let html = [];

		html.push(`<div class="col-md-12"><table class="table table-hover" style="width: auto; text-align: left;"><caption><h3>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_068")}</h3></caption>`);

		html.push(`<thead><th colspan=2>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_071")}</th><th style="width: 25%">${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_070")}</th><th>${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_069")}</th></thead>`);

		for (let i = 0; i < exibicaoCriterioCorrecaoTO.listaFilhos?.length; i++) {

			let exibicaoCriterioCorrecaoTOFilho = exibicaoCriterioCorrecaoTO.listaFilhos[i];

			html.push('<tr class="active" id-correcao="' + exibicaoCriterioCorrecaoTOFilho.idCorrecao + '" tipo="' + exibicaoCriterioCorrecaoTOFilho.tipoCriterioCorrecao + '" style="font-size: 105%; font-weight: bold">');
			html.push('<td numerador style="text-align: right">' + exibicaoCriterioCorrecaoTOFilho.numerador + '.</td>');
			html.push('<td style="text-align: left;">' + exibicaoCriterioCorrecaoTOFilho.nome + '</td>');
			html.push('<td class="active pontuacao">' + (UtilNumero.floatToString(exibicaoCriterioCorrecaoTOFilho.pontuacaoObtida) || "0") + '</td><td class="active">' + (exibicaoCriterioCorrecaoTOFilho.pontuacaoMaxima > 0 ? UtilNumero.floatToString(exibicaoCriterioCorrecaoTOFilho.pontuacaoMaxima) : "") + '</td>');
			html.push('</tr>');

			for (let j = 0; j < exibicaoCriterioCorrecaoTOFilho.listaFilhos?.length; j++) {
				let exibicaoCriterioCorrecaoTOFolha = exibicaoCriterioCorrecaoTOFilho.listaFilhos[j];

				html.push('<tr id-correcao="' + exibicaoCriterioCorrecaoTOFolha.idCorrecao + '" tipo="' + exibicaoCriterioCorrecaoTOFolha.tipoCriterioCorrecao + '">');
				html.push('<td numerador style="text-align: right">' + exibicaoCriterioCorrecaoTOFolha.numerador + ')</td>');
				html.push('<td style="text-align: left; font-size: 105%">');
				html.push(exibicaoCriterioCorrecaoTOFolha.nome + '</td>');

				if (podeEditar) {
					if (this.hasValue(exibicaoCriterioCorrecaoTOFolha.opcoesPontuacao)) {

						html.push('<td style="text-align: center"><div class="btn-group btn-opcoes-criterios" data-toggle="buttons">');

						for (const opcao of exibicaoCriterioCorrecaoTOFolha.opcoesPontuacao) {
							html.push('<label class="btn btn-default btn-label-criterios ' + (exibicaoCriterioCorrecaoTOFolha.pontuacaoObtida != null && opcao.id.toFixed(2) == exibicaoCriterioCorrecaoTOFolha.pontuacaoObtida.toFixed(2) ? 'active' : '') + '">')
							html.push('<input type="radio" name="' + exibicaoCriterioCorrecaoTOFolha.idCorrecao
								+ '" id="' + exibicaoCriterioCorrecaoTOFolha.idCorrecao + '" autocomplete="off" '
								+ ' value="' + opcao.id + '">');
							html.push(this.getPontuacaoStr(opcao.id));
							html.push('</label>');
						}

						html.push('</div></td>');

					} else {
						html.push('<td style="text-align: left"><input type="number" class="form-control" style="text-align: center" name="' + exibicaoCriterioCorrecaoTOFolha.idCorrecao
							+ '" id="' + exibicaoCriterioCorrecaoTOFolha.idCorrecao + '" autocomplete="off" '
							+ ' value="' + exibicaoCriterioCorrecaoTOFolha.pontuacaoObtida + '"></td>');
					}
				} else {
					html.push('<td>' + UtilNumero.floatToString(exibicaoCriterioCorrecaoTOFolha.pontuacaoObtida) + '</td>');
				}

				if (exibicaoCriterioCorrecaoTOFolha.tipoCriterioCorrecao === "VALOR") {
					html.push('<td class="active"></td></tr>');
				} else {
					let pontuacaoMaxima = exibicaoCriterioCorrecaoTOFolha.pontuacaoMaxima;
					if(pontuacaoMaxima){
						pontuacaoMaxima = UtilNumero.floatToString(exibicaoCriterioCorrecaoTOFolha.pontuacaoMaxima);
					}
					else{
						pontuacaoMaxima = " - ";
					}
					html.push('<td class="active">' + pontuacaoMaxima + '</td></tr>');
				}
			}
		}

		html.push('<tr class="active" id-correcao="' + exibicaoCriterioCorrecaoTO.idCorrecao + '" tipo="' + exibicaoCriterioCorrecaoTO.tipoCriterioCorrecao + '" style="font-size: 120%; font-weight: bold">');
		html.push(`<td colspan=2 style="text-align: left">${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_072")}</td>`);
		html.push('<td class="pontuacao">' + (UtilNumero.floatToString(exibicaoCriterioCorrecaoTO.pontuacaoObtida) || "") + '</td><td>' + UtilNumero.floatToString(exibicaoCriterioCorrecaoTO.pontuacaoMaxima) + '</td>');
		html.push('</tr>');
		html.push('</table></div>');

		return html.join("");
	}

	atualizarPontuacoes(exibicaoCriterioCorrecaoTO): number {

		let pontuacao = 0;
		let formula = exibicaoCriterioCorrecaoTO.formula;

		if (exibicaoCriterioCorrecaoTO.listaFilhos?.length > 0) {

			for (const exibicaoCriterioCorrecaoTOFilho of exibicaoCriterioCorrecaoTO.listaFilhos) {
				const pontuacaoFilho = this.atualizarPontuacoes(exibicaoCriterioCorrecaoTOFilho);
				if (formula) {
					formula = cadastroCriteriosCorrecaoVH.substituirVariavelFormula(formula, exibicaoCriterioCorrecaoTOFilho.numerador, pontuacaoFilho);
				} else {
					pontuacao += pontuacaoFilho;
				}
			}

		} else if (exibicaoCriterioCorrecaoTO.tipoCriterioCorrecao == "VALOR") {
			$("[id-correcao='" + exibicaoCriterioCorrecaoTO.idCorrecao + "'] > td > input").each((i, input) => {
				pontuacao = Number($(input).val());
			});
		} else {
			$("[id-correcao='" + exibicaoCriterioCorrecaoTO.idCorrecao + "'] td label.active input").each((i, input) => {
				pontuacao = Number($(input).val());
			});
		}

		if (formula) {
			pontuacao = cadastroCriteriosCorrecaoVH.calcularFormula(formula);
		}

		$("[id-correcao='" + exibicaoCriterioCorrecaoTO.idCorrecao + "']").attr("pontuacao", pontuacao);

		if (exibicaoCriterioCorrecaoTO.tipoCriterioCorrecao == "VALOR") {
			return pontuacao;

		}

		$("[id-correcao='" + exibicaoCriterioCorrecaoTO.idCorrecao + "'] > .pontuacao").text(this.getPontuacaoStr(pontuacao));

		return pontuacao;
	}

	getPontuacaoStr(pontuacao) {

		if (pontuacao == null) return null;

		let pontuacaoStr;

		if (Math.round(pontuacao) != pontuacao) {
			if (Math.round(pontuacao) != pontuacao.toFixed(2)) {
				pontuacaoStr = UtilNumero.floatToString(pontuacao.toFixed(2));
			} else {
				pontuacaoStr = new String(Math.round(pontuacao));
			}
		} else {
			pontuacaoStr = new String(pontuacao);
		}

		return pontuacaoStr;
	}

	async exibirCorretoresPorDisciplina() {

		const filtrosCorrecoesSelecionadosPeloUsuarioTO = this.getFiltrosCorrecoesSelecionadosPeloUsuarioTO();

		filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = 0;

		const exibicaoDisciplinasECorretoresTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/listarConsolidadoDisciplina", filtrosCorrecoesSelecionadosPeloUsuarioTO);

		this.limpar(true);

		await this.addFiltros(
			filtrosCorrecoesSelecionadosPeloUsuarioTO, 
			exibicaoDisciplinasECorretoresTO.exibicaoCorrecoesInfosFiltrosTO, 
			async () => await this.exibirCorretoresPorDisciplina()
		);

		const hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_073"), prop: "nomeDisciplina", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_074"), prop: function(listagemDisciplinasECorretoresTO) {

				if (!listagemDisciplinasECorretoresTO.listaOpcaoListaTOCorretoresJSON) return;

				const listaOpcaoListaTOCorretores = JSON.parse(listagemDisciplinasECorretoresTO.listaOpcaoListaTOCorretoresJSON);

				const h = [];

				if (listaOpcaoListaTOCorretores) {
					for (const opcaoListaTOCorretor of listaOpcaoListaTOCorretores) {
						h.push("<a href='" + hashEdicaoUsuario + "/" + opcaoListaTOCorretor.id + "'><i class='fa fa-user' ></i></a> " + opcaoListaTOCorretor.nome);
					}
				}

				return h.join("<br>");

			}, classe: "descricao"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_075"), prop: (listagemDisciplinasECorretoresTO) => {
				const to = listagemDisciplinasECorretoresTO.infosRespostasECorrecoesDisciplinaTO;
				if (!to) return null;
				return to.numRespostas;
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_076"), prop: (listagemDisciplinasECorretoresTO) => {
				const to = listagemDisciplinasECorretoresTO.infosRespostasECorrecoesDisciplinaTO;
				if (!to) return null;
				return to.numCorrecoesPendentes;
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_077"), prop: (listagemDisciplinasECorretoresTO) => {
				const to = listagemDisciplinasECorretoresTO.infosRespostasECorrecoesDisciplinaTO;
				if (!to) return null;
				if (to.numCorrecoesPendentes == 0) return;
				return UtilProgressBar.basic({
					evolucao: (to.numRespostas - to.numCorrecoesPendentes),
					total: to.numRespostas
				});
			}
		});
		colunas.push({titulo: "", prop: (listagemDisciplinasECorretoresTO) => {
				const to = listagemDisciplinasECorretoresTO.infosRespostasECorrecoesDisciplinaTO;

				const h = ["<div class='btn-group'>"]

				if (to && to.numCorrecoesPendentes > 0) {
					h.push(this.addBotao({
						label: "<i class='fa fa-pencil-square'></i> Corrigir",
						classe: "btn-primary",
						onClick: (event) => {
							const to = this.getTOListagem(event.target);
							this.irParaProximaResposta(this.getFiltroCorrecoesDelegacaoTO(to));
						},
						retornarHtml: true
					}));
				}

				if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_CFG_CORRETORES)) {
					h.push(this.addBotao({
						label: "<i class='fa fa-gear'></i>",
						onClick: (event) => {
							const listagemDisciplinasECorretoresTO = this.getTOListagem(event.target);
							this.exibirEdicaoCorretoresDisciplina(listagemDisciplinasECorretoresTO.codDisciplina, () => {
								this.exibirCorretoresPorDisciplina();
							});
						},
						retornarHtml: true
					}));
				}

				h.push("</div>");
				return h.join("");
			}
		});

		await this.addTabela({
			collection: exibicaoDisciplinasECorretoresTO.listaListagemDisciplinasECorretoresTO,
			id: "tabela_corretores_disciplinas",
			propId: "codDisciplina",
			colunas: colunas,
			onEdicao: async (codDisciplina) => {
				await this.exibirDetalhamentoCorrecoes({ codDisciplina: codDisciplina });
			},
			itensPorPagina: exibicaoDisciplinasECorretoresTO.itensPorPagina,
			numTotalItensPaginacao: exibicaoDisciplinasECorretoresTO.numTotalDisciplinas,
			onCarregarPagina: async (paginacaoTO) => {
				filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = paginacaoTO.numPaginaAtual;
				filtrosCorrecoesSelecionadosPeloUsuarioTO.itensPorPagina = paginacaoTO.numItensPorPagina;
				const exibicaoDisciplinasECorretoresTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/listarConsolidadoDisciplina", filtrosCorrecoesSelecionadosPeloUsuarioTO);
				return exibicaoDisciplinasECorretoresTO.listaListagemDisciplinasECorretoresTO;
			},
			paginaAtiva: filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina,
			ordenar: false
		});

		this.exibir({ isAffixIdentificacaoConteudo: true });
	}

	async exibirEdicaoCorretoresDisciplina(codDisciplina, onAfterSalvamento) {

		const edicaoDisciplinaECorretoresTO = await this.call("CorrecaoDiscursivasCfgFCD/recuperarEdicaoDisciplinaComCorretores", codDisciplina);
	
		this.addPopup({
			id: "edicao_prova", 
			titulo: edicaoDisciplinaECorretoresTO.nomeDisciplina, 
			botoes: [{
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_078"), 
				classe: "btn-primary", 
				onClick: async () => {
					const salvamentoCorretoresDeDisciplinaTO = {
						codDisciplina: codDisciplina,
						codsUsuariosCorretores: this.getValor("codsUsuariosCorretores")
					}
					await this.call("CorrecaoDiscursivasCfgFCD/salvarCorretoresDeDisciplina", salvamentoCorretoresDeDisciplinaTO);
					onAfterSalvamento();
				}
			}]
		});

		await this.addSelect({
			collection: edicaoDisciplinaECorretoresTO.listaOpcaoListaTOCorretores,
			id: "codsUsuariosCorretores",
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_079"),
			classe: "col-md-12",
			multiplo: true
		});

		this.exibirPopups();
	}

	async exibirCorretoresPorTurma() {

		const filtrosCorrecoesSelecionadosPeloUsuarioTO = this.getFiltrosCorrecoesSelecionadosPeloUsuarioTO();

		filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = 0;

		const exibicaoListagemTurmaECorretoresTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/listarConsolidadoTurma", filtrosCorrecoesSelecionadosPeloUsuarioTO);
	
		this.limpar(true);

		await this.addFiltros(filtrosCorrecoesSelecionadosPeloUsuarioTO, exibicaoListagemTurmaECorretoresTO.exibicaoCorrecoesInfosFiltrosTO, this.exibirCorretoresPorTurma);

		const hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);

		const colunas: ColunaAddTabela[] = [];

		colunas.push({titulo: this.getCfg("LABEL_TURMA"), prop: "nomeTurma", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_080"), prop: (listagemTurmasECorretoresTO) => {
				const h = [];
				if (listagemTurmasECorretoresTO.listaOpcaoListaTOCorretoresJSON) {
					const listaOpcaoListaTOCorretores = JSON.parse(listagemTurmasECorretoresTO.listaOpcaoListaTOCorretoresJSON);
					for (const opcaoListaTOCorretor of listaOpcaoListaTOCorretores) {
						h.push("<a href='" + hashEdicaoUsuario + "/" + opcaoListaTOCorretor.id + "'><i class='fa fa-user' ></i></a> " + opcaoListaTOCorretor.nome);
					}
				}
				return h.join("<br>");
			}, classe: "descricao"
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_081"), prop: (listagemTurmasECorretoresTO) => {
				const to = listagemTurmasECorretoresTO.infosRespostasECorrecoesTO;
				if (!to) return null;
				return to.numRespostas;
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_082"), prop: (listagemTurmasECorretoresTO) => {
				const to = listagemTurmasECorretoresTO.infosRespostasECorrecoesTO;
				if (!to) return null;
				return to.numCorrecoesPendentes;
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_083"), prop: (listagemTurmasECorretoresTO) => {
				const to = listagemTurmasECorretoresTO.infosRespostasECorrecoesTO;
				if (!to) return null;
				if (to.numCorrecoesPendentes == 0) return;
				return UtilProgressBar.basic({
					evolucao: (to.numRespostas - to.numCorrecoesPendentes),
					total: to.numRespostas
				});
			}
		});
		colunas.push({titulo: "", prop: (listagemTurmasECorretoresTO) => {
				const to = listagemTurmasECorretoresTO.infosRespostasECorrecoesTO;
				const h = ["<div class='btn-group'>"]

				if (to && to.numCorrecoesPendentes > 0) {
					h.push(this.addBotao({
						label: `<i class='fa fa-pencil-square'></i> ${this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_084")}`,
						classe: "btn-primary",
						onClick: (event) => {
							const to = this.getTOListagem(event.target);
							this.irParaProximaResposta(this.getFiltroCorrecoesDelegacaoTO(to));
						},
						retornarHtml: true
					}));
				}

				if (UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_DISCURSIVA_CFG_CORRETORES)) {
					h.push(this.addBotao({
						label: "<i class='fa fa-gear'></i>",
						onClick: async (event) => {

							const listagemTurmasECorretoresTO = this.getTOListagem(event.target);

							const edicaoTurmaECorretoresTO = await this.call("CorrecaoDiscursivasCfgFCD/recuperarEdicaoTurmaComCorretores", listagemTurmasECorretoresTO.codTurma);

							this.addPopup({
								id: "edicao_corretores_turma",
								titulo: edicaoTurmaECorretoresTO.nomeTurma,
								botoes: [{
									label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_085"),
									classe: "btn-primary",
									onClick: async () => {

										const salvamentoCorretoresDeTurmaTO = {
											codTurma: listagemTurmasECorretoresTO.codTurma,
											codsUsuariosCorretores: this.getValor("codsUsuariosCorretores")
										}

										await this.call("CorrecaoDiscursivasCfgFCD/salvarCorretoresDeTurma", salvamentoCorretoresDeTurmaTO);

										this.exibirCorretoresPorTurma();
									}
								}]
							});

							await this.addSelect({
								collection: edicaoTurmaECorretoresTO.listaOpcaoListaTOCorretores,
								id: "codsUsuariosCorretores",
								label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_086"),
								classe: "col-md-12",
								multiplo: true
							});

							this.exibirPopups();
						},
						retornarHtml: true
					}));
				}

				h.push("</div>");
				return h.join("");
			}
		});

		await this.addTabela({
			collection: exibicaoListagemTurmaECorretoresTO.listaListagemTurmasECorretoresTO,
			id: "tabela_corretores_turmas",
			propId: "codTurma",
			colunas: colunas,
			onEdicao: async (codTurma) => {
				await this.exibirDetalhamentoCorrecoes({ codTurma: codTurma });
			},
			itensPorPagina: exibicaoListagemTurmaECorretoresTO.itensPorPagina,
			numTotalItensPaginacao: exibicaoListagemTurmaECorretoresTO.numTotalTurmas,
			onCarregarPagina: async (paginacaoTO) => {
				filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina = paginacaoTO.numPaginaAtual;
				filtrosCorrecoesSelecionadosPeloUsuarioTO.itensPorPagina = paginacaoTO.numItensPorPagina;
				const exibicaoListagemTurmaECorretoresTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/listarConsolidadoTurma", filtrosCorrecoesSelecionadosPeloUsuarioTO);
				return exibicaoListagemTurmaECorretoresTO.listaListagemTurmasECorretoresTO;
			},
			paginaAtiva: filtrosCorrecoesSelecionadosPeloUsuarioTO.numeroDaPagina,
			ordenar: false
		});

		this.exibir({ isAffixIdentificacaoConteudo: true });
		this.focar("buscaTextualTurma");
	}

	async exibirOpcoesExportacaoRespostas(codProva) {
		
		const opcoesExportacaoRespostasTO = await this.call("CorrecaoDiscursivasExportacaoFCD/recuperarInfosParaExportacaoRespostas", codProva);

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_087"),
			botoes: [{ label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_088") }, {
				label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_089"), 
				classe: "btn-primary", 
				onClick: async () => {

					this.verificarObrigatorios();

					const exportacaoRespostasTO = {
						codProva: codProva,
						codQuestao: this.getValor("codQuestaoRelatorio"),
						tipoRespostasExportacaoPDF: this.getValor("tipoRespostasExportacaoPDF"),
						infosAvaliados: this.getValor("infosAvaliados"),
						numQuestaoNaProva: this.getTOSelectSelecionado("codQuestaoRelatorio")[0].text
					};

					const url = await this.call("CorrecaoDiscursivasExportacaoFCD/exportarRespostas", exportacaoRespostasTO);

					this.setIdTarget("link_pdf_respostas");
					this.setHtml("link_pdf_respostas", "");
					this.addLink({
						classe: `fp-link-aguardar-geracao-arquivo`,
						href: url,
						html: `target='_blank'`,
						label: UtilArquivo.getNomeArquivoSemHifens(url)
					});
					this.exibir();

					return false;
				}
			}]
		});

		this.addTexto(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_090"));

		await this.addSelect({
			collection: opcoesExportacaoRespostasTO.collectionListaTOQuestoes,
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_091"),
			classe: "col-md-12",
			id: "codQuestaoRelatorio",
			obrigatorio: true
		});

		await this.addSelect({
			collection: opcoesExportacaoRespostasTO.collectionListaTOTiposRespostas,
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_092"),
			classe: "col-md-12",
			id: "tipoRespostasExportacaoPDF",
			obrigatorio: true,
			valor: "TODAS"
		});

		await this.addSelect({
			id: "infosAvaliados",
			collection: opcoesExportacaoRespostasTO.listaInfoAvaliado,
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_093"),
			multiplo: true,
			classe: "col-md-12",
			valor: ["NOME", "MATRICULA"]
		});

		this.append("<div id='link_pdf_respostas' class='col-md-12'></div>")

		this.exibirPopups();
	}

	async exibirCorrecoesDisciplina(codDisciplina) {

		UtilHash.registrarHistorico(this.exibirCorrecoesDisciplina, codDisciplina);

		this.filtrosSituacaoCorrecoesDisciplinasTO = this.filtrosSituacaoCorrecoesDisciplinasTO || {
			codDisciplina: codDisciplina,
			somenteComCorrecoesPendentes: true
		}

		this.filtrosSituacaoCorrecoesDisciplinasTO.codDisciplina = codDisciplina;
		
		const exibicaoCorrecoesDisciplinasTO = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/recuperarCorrecoesDisciplinas", this.filtrosSituacaoCorrecoesDisciplinasTO);

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_094"));

		this.abrirAbaAccordion({
			titulo: "Filtros",
			aberta: true
		});

		const listaOpcaoListaTOCorretoresSelecionados = exibicaoCorrecoesDisciplinasTO.exibicaoCorrecoesInfosFiltrosTO.listaOpcaoListaTOCorretoresSelecionados;

		await this.addSelect({
			collection: listaOpcaoListaTOCorretoresSelecionados,
			id: "codsAvaliadores",
			dica: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_095"),
			valor: listaOpcaoListaTOCorretoresSelecionados ? listaOpcaoListaTOCorretoresSelecionados.map(to => to.id) : null,
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarCorretoresDiscursiva",
				numMinimoCaracteres: 0,
			},
			onChange: () => {
				this.filtrosSituacaoCorrecoesDisciplinasTO.codsAvaliadores = this.getValor("codsAvaliadores");
			}
		});

		this.addCheckbox({
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_096"),
			id: "somenteComCorrecoesPendentes",
			valor: this.filtrosSituacaoCorrecoesDisciplinasTO.somenteComCorrecoesPendentes,
			onChange: () => {
				this.filtrosSituacaoCorrecoesDisciplinasTO.somenteComCorrecoesPendentes = this.getValor("somenteComCorrecoesPendentes");
			}
		});
		this.append("<div class='col-md-12'>");
		this.addBotao({
			label: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_097"),
			classe: "btn-primary pull-right",
			onClick: () => {
				this.codsAvaliadoresFiltro = this.getValor("codsAvaliadores");
				this.tipoSituacaoCorrecaoDisciplinas = this.getValor("tipoSituacaoCorrecaoDisciplinas");
				this.exibirCorrecoesDisciplina(codDisciplina);
			}
		});
		this.append("</div>");

		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_098"), prop: function(correcao) {
				return `<a onclick='correcaoDiscursivasVH.exibirTelaCorrecao(${correcao.codRespostaQuestao},${correcao.codCorrecaoDiscursiva})'><small><i class='fa fa-pencil-square-o' ></i> #${correcao.codRespostaQuestao}</small> ${correcao.identificadorDaReposta}</a>`;
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_099"), prop: "dataEnvioDiscursiva", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_100"), prop: "tituloProva"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_101"), prop: function(correcao) {
				return correcao.numQuestaoNaProva != null ? correcao.numQuestaoNaProva : "<small>#" + correcao.codQuestao + "</small>";
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_102"), prop: "classificacaoDaQuestao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_103"), prop: "nomeUsuarioCorrecao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_104"), prop: "pontuacaoCorretor", formato: "numero"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_105"), prop: "pontuacaoMediaObtida", formato: "numero"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_106"), prop: "pontuacaoMaxima", formato: "numero"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_107"), prop: "dataCorrecao", formato: "DD/MM/YY HH:mm"});

		await this.addTabela({
			collection: exibicaoCorrecoesDisciplinasTO.listaCorrecoes,
			id: "listagemCorrecoesDisciplina",
			propId: "codRespostaQuestao",
			colunas: colunas,
			selecao: this.isAdministrador(),
			msgListaVazia: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_108"),
			salvarOrdenacao: true,
		})

		this.exibir();
	}

	async exibirAcompanhamentoCorrecoes() {

		UtilHash.registrarHistorico(this.exibirAcompanhamentoCorrecoes);

		const collectionListagemAcompanhamentoCorrecoes = await this.call("CorrecaoDiscursivasAcompanhamentoFCD/recuperarAcompanhamentoCorrecoes");
	
		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_109"));

		const hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_110"), prop: "nomeDisciplina", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_111"), prop: "numQuestoes"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_112"), prop: "numRespostas"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_113"), prop: "numRespostasCorrigidas"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_114"), prop: function(listagemAcompanhamentoCorrecoesTO) {
				return listagemAcompanhamentoCorrecoesTO.numRespostasSemCorrecao || 0;
			}
		});
		colunas.push({titulo: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_115"), prop: function(listagemAcompanhamentoCorrecoesTO) {
				let h = [];
				if (listagemAcompanhamentoCorrecoesTO.listaOpcaoListaTOCorretores) {
					for (const opcaoListaTOCorretor of listagemAcompanhamentoCorrecoesTO.listaOpcaoListaTOCorretores) {
						h.push("<a href='" + hashEdicaoUsuario + "/" + opcaoListaTOCorretor.id + "'><i class='fa fa-user' ></i></a> " + opcaoListaTOCorretor.nome);
					}
				}
				return h.join("<br>");
			}, classe: "descricao"
		});
		colunas.push({titulo: "", prop: function(listagemAcompanhamentoCorrecoesTO) {
				return this.addBotao({
					label: "<i class='fa fa-gear'></i>",
					onClick: (event) => {
						this.exibirEdicaoCorretoresDisciplina(listagemAcompanhamentoCorrecoesTO.codDisciplina, () => {
							this.exibirAcompanhamentoCorrecoes();
						})
					},
					retornarHtml: true
				});
			}
		});

		await this.addTabela({
			collection: collectionListagemAcompanhamentoCorrecoes,
			id: "tabela_acompanhamento_correcoes",
			propId: "codDisciplina",
			colunas: colunas,
			onEdicao: async (codDisciplina) => {
				await this.exibirCorrecoesDisciplina(codDisciplina);
			}
		});

		this.exibir({ isAffixIdentificacaoConteudo: true });
	}

	getFiltrosCorrecoesSelecionadosPeloUsuarioTO() {
		if (this.filtrosCorrecoesSelecionadosPeloUsuarioTO == null) {
			this.filtrosCorrecoesSelecionadosPeloUsuarioTO = {
				somenteComCorrecoesPendentes: this.somenteComCorrecoesPendentes != null ? this.somenteComCorrecoesPendentes : true,
				buscaTextual: null,
				codsTurmas: null,
				codsClassificacoes: null,
				codsAvaliadores: null,
				codQuestao: null
			}
		}
		return this.filtrosCorrecoesSelecionadosPeloUsuarioTO;
	}

	async deveProsseguirComCorrecao(isReaberturaVigente: boolean) {
		if (!isReaberturaVigente) {
			return true;
		}
		let prosseguirComCorrecao = false;
		await this.exibirAlerta({
			titulo:`<i class='fa fa-exclamation-circle'></i> ${this.getMsg('MSG_VH_AP_44')}`,
			msg: 'A prova está reaberta. Se você corrigir esta questão discursiva, a reabertura será revogada. <br/><br/> Deseja realmente continuar?',
			botoes: [
				{label: this.getMsg("MSG_VH_004"), classe: 'btn-primary', onClick: () => {prosseguirComCorrecao = true}},
				{label: this.getMsg("MSG_VH_005"), }
			]
		})

		return prosseguirComCorrecao;
	}
}

const correcaoDiscursivasVH = new CorrecaoDiscursivasVH();

class SalvamentoCorrecaoDiscursivaTO {
	codRespostaQuestao: number;
	codUsuarioCorretor: number;
	obsCorrecao: string;
	pontuacaoObtida: number;
	scoreFeedbackSugestao: number;
	codCorrecaoDiscursivaSugestao: number;
	listaSalvamentoCorrecaoComCriterioTO: SalvamentoCorrecaoComCriterioTO[];
	listaSalvamentoImagemRespostaTO: SalvamentoImagemRespostaTO[];
}
class SalvamentoImagemRespostaTO {
	idFolhaRespostaQuestao: number;
	dadosCorrecaoNoCanvas: string;
	pathImagemCorrecao: string;
	novoPathImagemResposta: string;
}
class SalvamentoCorrecaoComCriterioTO {
	pontuacao: number;
	idCorrecao: number;
}