class ImportacaoCartaoRespostaVH extends AmaisVH {

	filtroProcessamentosFRsTO: any;
	idInterval: any;
	isChamando: any;
	filtrosRelAplicacoesTO: any = {};
	idUpload = 1;

	constructor() {
		super(ImportacaoCartaoRespostaVH.name);
		this.addOperacaoParaHash("icii", this.iniciarImportacao);
		this.addOperacaoParaHash("iccp", this.criarNovaProvaVazia);
		this.addOperacaoParaHash("icui", this.exibirEnvioFRs);
		this.addOperacaoParaHash("icsa", this.exibirSituacaoEnvioAgendamento);
	}

	async iniciarImportacao(idAplicacao) {

		UtilHash.registrarHistorico(this.iniciarImportacao, idAplicacao);

		const listagensImportacaoCartaoTO = await this.call("ImportacaoCartaoRespostaFCD/recuperarListagens", idAplicacao);

		this.limpar();

		this.setTitulo(listagensImportacaoCartaoTO.nomeProvaECaderno);
		this.setSubtitulo(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_001"));
		this.limpar();

		this.addSubtitulo({ texto: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_002"), id: "tituloPasso1" });

		this.addZonaUpload({
			id: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_003"),
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_004"),
			classe: "col-md-12",
			onDepoisEnvio: async (file) => {
				const conteudo: any = await UtilXlsx.getConteudoFile(file);

				let linhas = this.getLinhasComConteudo(conteudo.split("\n"));
				let numDigitosId = this.getNumDigitosId(linhas, listagensImportacaoCartaoTO.possuiSecoesOptativas);
				let linhasSeparadas = await this.separarIdentificadorERespostas(linhas, numDigitosId, listagensImportacaoCartaoTO.numQuestoes, listagensImportacaoCartaoTO.possuiSecoesOptativas);
				let numDigitosIdInferido = await this.call("ImportacaoCartaoRespostaFCD/inferirNumDigitosId", listagensImportacaoCartaoTO.codEmpresaAplicacao, numDigitosId, linhas);

				if (numDigitosIdInferido != numDigitosId) {
					numDigitosId = numDigitosIdInferido;
					linhasSeparadas = await this.separarIdentificadorERespostas(linhas, numDigitosId, listagensImportacaoCartaoTO.numQuestoes, listagensImportacaoCartaoTO.possuiSecoesOptativas);
				}

				this.addSubtitulo(`${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_006")} <strong>${file.name}</strong>`)
				this.addTexto(`<span class='badge'>${linhas.length}</span> ${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_007")}`);
				this.addTexto(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_008", this.getCfg("LABEL_ALUNO").toLowerCase(), numDigitosId));

				this.addRadioGroup({
					label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_005"),
					id: "id_avaliado",
					collection: [
						{ id: "cpf", descricao: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_009") },
						{ id: "matricula", descricao: this.getCfg("LABEL_MATRICULA")
					}],
					classe: "col-md-4",
					visivel: false,
					valor: (numDigitosId != 11 ? "matricula" : "cpf")
				});

				let tabindex = 100;

				this.append(`
					<table tabela-arquivo class='table table-hover table-condensed importacao-cartao-resposta'>
						<thead><tr><th></th>
							<th>
								${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_010")}
							</th>
				`);

				if (listagensImportacaoCartaoTO.possuiSecoesOptativas) {
					this.append(`<th>${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_011")}</th>`);
				}

				this.append(`<th>${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_012")}</th></tr></thead><tbody>`);

				for (let i = 0; i < linhasSeparadas.length; i++) {
					let j = 0;
					let id = linhasSeparadas[i][j++];
					let opcaoDeProva = null;

					if (listagensImportacaoCartaoTO.possuiSecoesOptativas) {
						opcaoDeProva = linhasSeparadas[i][j++];
					}

					let respostas = linhasSeparadas[i][j++];

					this.append("<tr linha><td>" + (i + 1) + "</td><td identificador tabindex='" + (tabindex++) + "' contenteditable='true'>" + id + "</td>");

					if (listagensImportacaoCartaoTO.possuiSecoesOptativas) {
						this.append("<td opcaoDeProva tabindex='" + (tabindex++) + "' contenteditable='true'>" + opcaoDeProva + "</td>");
					}

					this.append(`<td respostas contenteditable='true' tabindex='${tabindex++}' title='${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_013")}: ${respostas ? respostas.length : 0}'>${respostas}</td></tr>`);
				}

				this.append("</tbody></table>");
				this.addEspacamentoHorizontal("1px");

				this.addBotao({
					label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_014"),
					id: "link_upload",
					classe: "btn-primary",
					onClick: async () => {
						if (!this.validarCamposObrigatorios("id_avaliado")) {
							return
						}

						let uploadCartaoRespostaTO = {
							linhas: [],
							campoIdDoAvaliado: this.getValor("id_avaliado"),
							idAplicacao: idAplicacao
						}
						$("[tabela-arquivo] [linha]").each(function () {
							let identificador = $(this).find("[identificador]").text().trim();
							let opcaoDeProva = $(this).find("[opcaoDeProva]").text().trim();
							let respostas = $(this).find("[respostas]").text().trim();
							uploadCartaoRespostaTO.linhas.push([identificador, opcaoDeProva, respostas]);
						});

						const resultadoProcessamentoArquivoTO = await this.call("ImportacaoCartaoRespostaFCD/fazerUploadRespostas", uploadCartaoRespostaTO);

						this.disable("importacao_respostas", "id_avaliado", "num_digitos_matricula");

						await this.hide("link_upload");

						this.setIdTarget("confirmacao_para_importar");
						this.setHtml("confirmacao_para_importar", "");

						if (resultadoProcessamentoArquivoTO.numAlunosNovos > 0) {
							this.addTexto(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_015", resultadoProcessamentoArquivoTO.numAlunosNovos));
						} else {
							this.addTexto(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_016"));
						}

						this.addEspacamentoHorizontal("10px");

						this.addCampoTexto({
							id: "duracao",
							classe: "col-md-3",
							label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_017"),
							valor: 120,
							textoAposCampo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_018"),
							mascara: "9?99"
						})

						await this.addSelect({
							collection: listagensImportacaoCartaoTO.collectionItemListaTOTurmas,
							id: "codTurma",
							label: this.getCfg("LABEL_TURMA"),
							classe: "col-md-6",
						});

						this.addEspacamentoHorizontal("20px");

						this.addBotao({
							label: `${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_019")} &raquo;`,
							id: "link_importar",
							classe: "btn-primary",
							onClick: async () => {
								if (!this.validarCamposObrigatorios("duracao")) {
									return;
								}

								this.addTexto(`<i id='msg_aguardando'>${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_020")}</i>`);
								this.exibir();

								let importacaoCartoesRespostaTO = {
									minutosDeDuracaoDaProva: this.getValor("duracao"),
									codTurma: this.getValor("codTurma"),
									arquivoTO: resultadoProcessamentoArquivoTO.arquivoTO
								}

								const file: any = {type: "application/json", name: "importacao-cartao-resposta.json"};
								const uploadURLTO = await UtilArmazenamento.gerarUrlParaUpload(file, true);
								uploadURLTO.uploadTO.metadata = {};
								await UtilArmazenamento.enviar(file, uploadURLTO.urlUploadViaPUT);
								await UtilArmazenamento.finalizar(uploadURLTO.uploadTO);

								const path = await this.call({
									endpoint: "ImportacaoCartaoRespostaFCD/importarCartoesResposta",
									params: [importacaoCartoesRespostaTO, uploadURLTO.uploadTO],
									msgCarregando: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_021"),
								});

								const resultadoImportacao = await UtilArmazenamento.aguardarGeracaoJson(path);
								const codAgendamento = resultadoImportacao.codAgendamento;
																	
								agendamentoProvaVH.finalizarAgendamento(codAgendamento, async (codAgendamento) => {

									await this.hide("link_importar");
									this.disable("dataAplicacao", "minutos");
									await this.hide("msg_aguardando");

									this.addBotao({
										label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_022"),
										classe: "btn-success",
										onClick: () => {
											cadastroProvaVH.exibirAbasProva(5, listagensImportacaoCartaoTO.codProva, codAgendamento);
										}
									});

									this.exibir();
								});
							}
						});

						this.exibir();
					}
				});

				this.append("<div id='confirmacao_para_importar' style='float: left; width: 100%'>");
				this.append("</div>");
				await this.hide("importacao_respostas", "tituloPasso1", "textoAguardando");
				this.exibir();
			}
		});

		this.addTexto(`<i id='textoAguardando'>${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_023")}</i>`);

		this.exibir();
	}

	reiniciarImportacao() {
		this.iniciarImportacao(null);
	}

	async criarNovaProvaVazia() {

		UtilHash.registrarHistorico(this.criarNovaProvaVazia);

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_024"));
		this.setSubtitulo(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_025"));

		this.addTexto(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_026"));

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_027"),
			classe: "col-md-9",
			id: "nomeProva",
			obrigatorio: true
		});

		this.addEspacamentoHorizontal("1px");

		await this.addSelect({
			collection: this.getCollectionTipoQuestoes(),
			id: "tipoQuestao",
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_028"),
			obrigatorio: true,
			classe: "col-md-3",
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_029"),
			classe: "col-md-3",
			id: "numeroDeQuestoes",
			obrigatorio: true
		})

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_030"),
			classe: "col-md-3",
			id: "numeroDeItens",
			obrigatorio: true
		});

		this.addEspacamentoHorizontal("1px");

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_031"),
			classe: "col-md-9",
			id: "gabaritoObjetivas",
			obrigatorio: true
		});

		this.addGrupoBotoes({
			botoes: [{
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_032"),
				classe: "btn-primary",
				onClick: async () => {
					cadastroProvaVH.verificarObrigatorios();
					const codProva = await this.call("ImportacaoCartaoRespostaFCD/criarNovaProvaVazia", this.getValor("nomeProva"), this.getValor("tipoQuestao"), this.getValor("numeroDeQuestoes"), this.getValor("numeroDeItens"), this.getValor("gabaritoObjetivas"));
					try {
						await cadastroProvaVH.exibirAbasProva(1, codProva);
					} catch (ignored) {}
				}
			}]
		});

		this.addEspacamentoHorizontal("20px");

		this.exibir();
	}

	getLinhasComConteudo(linhasIncluindoVazias) {
		let linhas = [];

		for (const linha of linhasIncluindoVazias) {

			if (this.isEmpty(linha)) continue;

			linhas.push(linha.trim());
		}

		return linhas;
	}

	getNumDigitosId(linhas, possuiSecoesOptativas) {
		let somatorioNumCharsId = 0;

		for (const linha of linhas) {

			let i = linha.length - 1;

			while (i >= 0 && isNaN(linha.charAt(i))) {
				i--;
			}

			if (possuiSecoesOptativas) {
				somatorioNumCharsId += i;
			} else {
				somatorioNumCharsId += (i + 1);
			}
		}

		return Math.round(somatorioNumCharsId / linhas.length);
	}

	async separarIdentificadorERespostas(linhas, numDigitosId, numQuestoes, possuiSecoesOptativas) {
		let inicioRespostas = numDigitosId;
		let inicioResto = inicioRespostas + numQuestoes;
		let isInicioRestoOK = true;

		for (const linha of linhas) {
			let caracterInicioResto = linha.charAt(inicioResto);
			if (caracterInicioResto != "Z" && inicioResto < linha.length) {
				isInicioRestoOK = false;
			}
		}

		if (isInicioRestoOK === false) {
			inicioRespostas++;
			inicioResto++;
		}

		for (const linha of linhas) {

			let caracterInicioResto = linha.charAt(inicioResto);

			if (caracterInicioResto != "Z" && inicioResto < linha.length) {
				await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_033") });
				return null;
			}
		}

		let linhasSeparadas = [];

		for (const linha of linhas) {

			let array = []

			array.push(linha.substring(0, numDigitosId));

			if (possuiSecoesOptativas) {
				array.push(linha.substring(numDigitosId, numDigitosId + 1));
			}

			array.push(linha.substring(inicioRespostas, inicioResto));

			linhasSeparadas.push(array);
		}

		return linhasSeparadas;
	}

	exibirEnvioFRs(numAba) {

		if (numAba == null) {
			this.exibirEnvioFRs(0);
			return;
		}

		UtilHash.registrarHistorico(this.exibirEnvioFRs, numAba);

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_034"));

		let abas = [];

		abas.push({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_035"),
			onClick: async () => await this.exibirUploadImagens(),
			habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_FR_ENVIO)
		});
		abas.push({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_036"),
			onClick: () => this.exibirRelatorioEnvioFRs(),
			habilitada: UtilAuth.possuiAcesso(TipoFuncionalidade.CORRECAO_FR_REL)
		});

		this.addAbas({ abas, numAbaAtiva: numAba });

		this.exibir();
	}

	async exibirUploadImagens() {

		this.filtroProcessamentosFRsTO = this.filtroProcessamentosFRsTO || {};

		this.limpar(true);

		this.addZonaUpload({
			id: "importacao_imagens",
			arquivosAceitos: ".png,.jpg,.gif,.jpeg,.pdf,.zip,.xlsx,.heic,.heif",
			classe: "hidden",
			maxWidthOuHeight: 1200,
			// multiplosArquivos: true,
			onAntesEnvio: (file) => {
				file.idUpload = this.idUpload++;
				let exibicaoProcessamentoFRTO = {
					idUpload: file.idUpload,
					pathArquivoOriginal: file.name
				}

				importacaoCartaoRespostaVH.atualizarProcessamento("#listagemProcessamentos", exibicaoProcessamentoFRTO);
				this.exibir();
				UtilWindow.scrollTo("#listagemProcessamentos");
			},
			onProgressoEnvio: (file, progresss0a100) => {
				$("[id-upload='" + file.idUpload + "']").find(".progress-bar").attr("aria-valuenow", progresss0a100).css("width", progresss0a100 + "%");
			},
			onErro: (file) => {
				let exibicaoProcessamentoFRTO = {
					idUpload: file.idUpload,
					pathArquivoOriginal: file.name,
					msgErro: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_037")
				}

				importacaoCartaoRespostaVH.atualizarProcessamento("#listagemProcessamentos", exibicaoProcessamentoFRTO);
				this.exibir();
			},
			onDepoisEnvio: async (file, uploadTO) => {
				let envioProcessamentoFRTO = {
					idUpload: file.idUpload,
					pathRelativo: uploadTO.pathArmazenamento,
					pathCompleto: uploadTO.url,
					dataAplicacao: this.getValor("dataAplicacao"),
					codEmpresa: this.getValor("codEmpresa"),
				}

				const exibicaoProcessamentoFRTO = await this.call({
					endpoint: "ImportacaoCartaoRespostaFCD/registrarNovoProcessamento",
					params: [envioProcessamentoFRTO],
					desativarMsgCarregando: true
				});

				this.atualizarProcessamento("#listagemProcessamentos", exibicaoProcessamentoFRTO);
				this.exibir();
			}
		});

		this.append('<div class="row">');
		this.append('<div class="col-md-10">');


		this.abrirAbaAccordion({ titulo: "Filtros", aberta: true });

		this.addFormulario({ classe: "col-md-12" });
		this.append("<div class='row'>");
		this.append('<div class="col-md-6">');
		this.append("<div class='row'>");
		this.append('<div class="col-md-12">');

		this.addCheckboxGroup({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_038"),
			id: "tiposDeArquivos",
			classe: "col-md-12",
			checkboxes: [{
				label: "ZIP",
				id: "ZIP",
			}, {
				label: "PDF",
				id: "PDF",
			}, {
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_039"),
				id: "IMAGEM",
			}],
			valores: this.filtroProcessamentosFRsTO.tiposDeArquivos,
			onChange: async () => await this.verificarConsistenciaFiltros()
		});

		this.addCheckboxGroup({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_040"),
			id: "situacoesProcessamento",
			classe: "col-md-12",
			checkboxes: [{
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_041"),
				id: "SUCESSO",
			}, {
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_042"),
				id: "ALERTA",
			}, {
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_043"),
				id: "ERRO",
			}, {
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_044"),
				id: "PENDENTE",
			}, {
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_098"),
				id: "OCULTO",
			}],
			valores: this.filtroProcessamentosFRsTO.situacoesProcessamento,
			onChange: async () => await this.verificarConsistenciaFiltros()
		});

		this.addCheckboxGroup({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_045"),
			id: "situacoesCorrecao",
			classe: "col-md-12",
			checkboxes: [{
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_046"),
				id: "CORRIGIDA",
			}, {
				label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_047"),
				id: "NAO_CORRIGIDA",
			}],
			valores: this.filtroProcessamentosFRsTO.situacoesCorrecao,
			onChange: async () => await this.verificarConsistenciaFiltros()
		});

		this.append("</div>");
		this.append("</div>");
		this.append("</div>");
		this.append('<div class="col-md-6">');
		this.append("<div class='row'>");
		this.append('<div class="col-md-12">');

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_100"),
			classe: "col-md-12",
			id: "dataInicioPeriodoEnvio",
			tipo: "DATAHORA",
			valor: this.filtroProcessamentosFRsTO.dataInicioPeriodoEnvio,
		});
		
		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_101"),
			classe: "col-md-12",
			id: "dataFimPeriodoEnvio",
			tipo: "DATAHORA",
			valor: this.filtroProcessamentosFRsTO.dataFimPeriodoEnvio,
		});
		
		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_049"),
			classe: "col-md-12",
			id: "buscaTextualNomeArquivo",
			valor: this.filtroProcessamentosFRsTO.buscaTextualNomeArquivo,
			prefixo: "<i class='fa fa-search'></i> ",
		});

		this.append("</div></div></div></div>");

		this.fecharFormulario();

		this.addFormulario({ classe: "col-md-12" });
		this.append('<div class="col-md-12" style="text-align: right">');
		this.addBotao({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_050"),
			classe: "btn-primary",
			onClick: () => this.listarProcessamentos()
		});
		this.append("</div>");
		this.fecharFormulario();

		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();

		this.append("</div>");

		this.append('<div area-envio-frs class="col-md-2">');

		this.addBotao({
			label: `<i class='fa fa-upload'></i> ${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_051")}`,
			classe: "col-md-12 btn-primary",
			onClick: () => {
				$("#importacao_imagens").click();
			}
		});
		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_052"),
			id: "dataAplicacao",
			tipo: "DATAHORA",
			classe: "col-md-12",
			css: "padding: 0px; padding-top: 15px",
			visivel: false
		});
		await this.addSelect({
			id: "codEmpresa",
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_053"),
			dica: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_054"),
			classe: "col-md-12",
			css: "padding: 0px",
			visivel: false,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarSegmentos",
				numMinimoCaracteres: 0,
			},
		});
		this.addLink({
			label: `<small>${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_055")}</small>`,
			classe: "pull-right",
			id: "botao-avancado",
			onClick: async () => {
				$("#botao-avancado").hide()
				await this.show("codEmpresa", "dataAplicacao");
			}
		});
		this.append('</div></div></div>');

		this.append('</div>');
		this.append("<div class='row'>");
		this.append('<div id="paginacaoProcessamento" class="col-md-12"></div>');
		this.append('<div id="listagemProcessamentos" listagem-processamentos class="col-md-12"></div>');
		this.append('</div>');

		this.exibir();

		this.listarProcessamentos();
	}

	verificarConsistenciaFiltros() {
		let situacoesCorrecao = this.getValor("situacoesCorrecao");

		if (situacoesCorrecao.length > 0) {
			this.setValor("tiposDeArquivos", ["IMAGEM"])
			this.disable("tiposDeArquivos");
		} else {
			this.enable("tiposDeArquivos");
		}
	}

	async listarProcessamentos(paginacaoTO = null) {

		this.filtroProcessamentosFRsTO = {
			paginacaoTO: paginacaoTO,
			idAplicacaoAlertas: null,
			idAplicacaoErros: null,
			dataInicioPeriodoEnvio: this.getValor("dataInicioPeriodoEnvio"),
			dataFimPeriodoEnvio: this.getValor("dataFimPeriodoEnvio"),
			idsPendentes: null,
			tiposDeArquivos: this.getValor("tiposDeArquivos"),
			situacoesProcessamento: this.getValor("situacoesProcessamento"),
			situacoesCorrecao: this.getValor("situacoesCorrecao"),
			buscaTextualNomeArquivo: this.getValor("buscaTextualNomeArquivo"),
		}

		const exibicaoCorrecoesTO = await this.call("ImportacaoCartaoRespostaFCD/recuperarProcessamentos", this.filtroProcessamentosFRsTO);

		$("#listagemProcessamentos, #paginacaoProcessamento").html("");

		this.setIdTarget("paginacaoProcessamento");

		this.append(UtilPaginacao.criarPaginador({
			onCarregarPagina: async (paginacaoTO) => {
				exibicaoCorrecoesTO.paginacaoTO.numPaginaAtual = paginacaoTO.numPaginaAtual;
				this.listarProcessamentos(exibicaoCorrecoesTO.paginacaoTO);
			},
			paginacaoTO: exibicaoCorrecoesTO.paginacaoTO,
			// itensPorPagina: exibicaoCorrecoesTO.paginacaoTO.numItensPorPagina,
			// totalItens: exibicaoCorrecoesTO.paginacaoTO.numTotalItens,
			// paginaAtiva: exibicaoCorrecoesTO.paginacaoTO.numPaginaAtual
		}));

		this.exibir();

		for (const exibicaoProcessamentoFRTO of exibicaoCorrecoesTO.listaExibicaoProcessamentoFRTO) {
			this.atualizarProcessamento("#listagemProcessamentos", exibicaoProcessamentoFRTO);
		}

		this.exibir();

		this.atualizarProcessamentos();
	}

	async exibirRelatorioEnvioFRs() {

		const filtrosParaGeracaoRelEnvioFRsTO = await this.call("ImportacaoCartaoRespostaFCD/recuperarFiltrosRelEnvioFRs");
	
		this.limpar(true);

		this.filtrosRelAplicacoesTO = this.filtrosRelAplicacoesTO || {};
		let isFiscal = this.isFiscal(false);

		this.abrirAbaAccordion({ titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_056"), aberta: true });

		await this.addSelect({
			collection: filtrosParaGeracaoRelEnvioFRsTO.collectionSegmentosTO,
			id: "codsEmpresas",
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_057"),
			multiplo: true,
			classe: "col-xs-12 col-md-6 col-lg-4",
			valor: this.filtrosRelAplicacoesTO.codsEmpresas,
			visivel: !isFiscal
		});

		await this.addSelect({
			collection: filtrosParaGeracaoRelEnvioFRsTO.listaTurmasItemListaTO,
			id: "codsTurmas",
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_058"),
			multiplo: true,
			classe: "col-xs-12 col-md-6 col-lg-4",
			valor: this.filtrosRelAplicacoesTO.codsTurmas,
			visivel: !isFiscal
		});

		await this.addSelect({
			collection: filtrosParaGeracaoRelEnvioFRsTO.listaSalasItemListaTO,
			id: "codsSalas",
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_059"),
			multiplo: true,
			classe: "col-xs-12 col-md-6 col-lg-4",
			valor: this.filtrosRelAplicacoesTO.codsSalas,
			visivel: !isFiscal
		});

		await this.addSelect({
			collection: filtrosParaGeracaoRelEnvioFRsTO.listaOpcaoListaTOProvas,
			classe: "col-xs-12 col-md-6 col-lg-4",
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_060"),
			multiplo: true,
			id: "codsProvas",
			valor: this.filtrosRelAplicacoesTO.codsProvas,
			visivel: !isFiscal
		});

		this.addCamposPeriodo({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_061"),
			idInicio: "dataAplicacaoInicio",
			idFim: "dataAplicacaoFim",
			valorInicio: this.filtrosRelAplicacoesTO.dataAplicacaoInicio,
			valorFim: this.filtrosRelAplicacoesTO.dataAplicacaoFim
		});

		this.addEspacamentoHorizontal("1px");

		this.addBotao({
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_062"),
			classe: "btn-primary pull-right",
			onClick: async () => await this.atualizarProvas()
		});

		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();

		this.append('<div id="listagemProvas" class="row"></div>');
		this.exibir();

		await this.atualizarProvas();
	}

	async atualizarProvas(paginacaoTO?: PaginacaoTO) {

		this.filtrosRelAplicacoesTO = {
			codsEmpresas: this.getValor("codsEmpresas"),
			codsTurmas: this.getValor("codsTurmas"),
			codsProvas: this.getValor("codsProvas"),
			codsSalas: this.getValor("codsSalas"),
			dataAplicacaoInicio: this.getValor("dataAplicacaoInicio"),
			dataAplicacaoFim: this.getValor("dataAplicacaoFim"),
			paginacaoTO: paginacaoTO
		}

		const relEnvioFRsTO = await this.call("ImportacaoCartaoRespostaFCD/recuperarRelatorioEnvioFRs", this.filtrosRelAplicacoesTO);

		$("#listagemProvas").html("");
		this.setIdTarget("listagemProvas");

		let hashCaderno = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 4);
		let hashRespostas = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 5);
		let isFiscal = this.isFiscal(false);

		const colunas: ColunaAddTabela[] = [{
			titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_063"), 
			prop: (linhaRelEnvioFRsTO) => {

				let linhas = [];

				if (this.getIsAmbienteSegmentado()) {
					linhas.push(linhaRelEnvioFRsTO.nomeSegmento.toUpperCase());
				}

				if (!isFiscal) {
					linhas.push("<a href='" + hashCaderno + "/" + linhaRelEnvioFRsTO.codProva + "////" + linhaRelEnvioFRsTO.codAgendamento + "'>" + linhaRelEnvioFRsTO.tituloProva + "</a>");
				} else {
					linhas.push(linhaRelEnvioFRsTO.tituloProva);
				}

				if (linhaRelEnvioFRsTO.numFRsNNs) {
					if (linhaRelEnvioFRsTO.numFRsNNs == 1) {
						linhas.push(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_067"));
					} else {
						linhas.push(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_068", linhaRelEnvioFRsTO.numFRsNNs));
					}
				}

				linhas.push("<small>#" + linhaRelEnvioFRsTO.codAgendamento + "</small>");

				return linhas.join("<br>");

			}, 
			classe: "descricao"
		}, {
			titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_064"), 
			prop: "dataAplicacao", 
			formato: "DD/MM/YYYY HH:mm"
		}, {
			titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_065"), 
			prop: (linhaRelEnvioFRsTO) => {
				if (linhaRelEnvioFRsTO.numTurmas > 2) {
					return linhaRelEnvioFRsTO.numTurmas + " turmas";
				} else {
					return linhaRelEnvioFRsTO.nomesTurmas;
				}
			}
		}, {
			titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_066"), 
			prop: (linhaRelEnvioFRsTO) => {
				return linhaRelEnvioFRsTO.numPaginas;
			}
		}, {
			titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_069"), 
			prop: (linhaRelEnvioFRsTO) => {
				if (!isFiscal && linhaRelEnvioFRsTO.numPaginasCorrigidas) {
					return "<a href='" + hashRespostas + "/" + linhaRelEnvioFRsTO.codProva + "////" + linhaRelEnvioFRsTO.codAgendamento + "'>" + linhaRelEnvioFRsTO.numPaginasCorrigidas + "</a>";
				} else {
					return linhaRelEnvioFRsTO.numPaginasCorrigidas;
				}
			}
		}, {
			titulo: "Páginas anuladas", 
			prop: (linhaRelEnvioFRsTO) => {
				return linhaRelEnvioFRsTO.numPaginasCorrigidasAnuladas;
			}
		}, {
			titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_071"), 
			prop: (linhaRelEnvioFRsTO) => {
				const h = [];
				if (linhaRelEnvioFRsTO.numFRsPendentes) {
					h.push(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_072", linhaRelEnvioFRsTO.numFRsPendentes));
				}
				if (linhaRelEnvioFRsTO.numAlertas) {
					h.push(`<a onclick='importacaoCartaoRespostaVH.exibirAlertas(this, ${linhaRelEnvioFRsTO.codAgendamento}, false)' class='text-warning'>${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_073", linhaRelEnvioFRsTO.numAlertas)}</a>`);
				}
				if (linhaRelEnvioFRsTO.numErros) {
					h.push(`<a onclick='importacaoCartaoRespostaVH.exibirAlertas(this, ${linhaRelEnvioFRsTO.codAgendamento}, true)' class='text-danger'>${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_074", linhaRelEnvioFRsTO.numErros)}</a>`);
				}
				return h.join("<br>");
			}
		}, {
			titulo: "", 
			prop: (linhaRelEnvioFRsTO) => {
				const b = [];

				if (linhaRelEnvioFRsTO.numPaginas) {
					b.push(`<button class='btn btn-default btn-primary' onclick='importacaoCartaoRespostaVH.exibirSituacaoEnvioAgendamento(${linhaRelEnvioFRsTO.codAgendamento})' title='${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_075")}'>${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_077")}</button>`);
				}

				if (!isFiscal) {
					if (linhaRelEnvioFRsTO.codAgendamento) {
						b.push(`<button class='btn btn-default' onclick='importacaoCartaoRespostaVH.finalizarAplicacao(${linhaRelEnvioFRsTO.codAgendamento},${linhaRelEnvioFRsTO.codProva})' title='${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_076")}'><i class='fa fa-stop'></i></button>`);
					}
				}

				if (b.length) {
					return "<div class='btn-group'>" + b.join("") + "</div>";
				} else {
					return "";
				}
			}
		}];

		await this.addTabela({
			collection: relEnvioFRsTO.listaLinhaRelEnvioFRsTO,
			id: "listagem_cadernos",
			propId: "codProva",
			ordenar: false,
			colunas: colunas,
			paginacao: {
				paginacaoTO: relEnvioFRsTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					this.filtrosRelAplicacoesTO.paginacaoTO = paginacaoTO;
					const relEnvioFRsTO = await this.call("ImportacaoCartaoRespostaFCD/recuperarRelatorioEnvioFRs", this.filtrosRelAplicacoesTO);
					return relEnvioFRsTO.listaLinhaRelEnvioFRsTO;
				}
			},
		});

		this.exibir();
	}

	finalizarAplicacao(codAgendamento, codProva) {
		agendamentoProvaVH.finalizarAgendamento(codAgendamento, () => {
			cadastroProvaVH.exibirAbasProva("5C", codProva).then(() => {}, () => {});
		});
	}

	async exibirAlertas(botao, codAgendamento, isErros) {
		let $tdAlertas = $("td[div-aplicacao='" + codAgendamento + "']");
		let criarNovo = true;

		if ($tdAlertas.length) {
			let isErrosTela = $tdAlertas.data("isErros");

			if (isErrosTela == isErros) {
				$tdAlertas.parent().collapse('toggle');
				criarNovo = false;
			} else {
				$tdAlertas.parent().remove();
			}
		}

		if (criarNovo) {
			let $tr = $(botao).closest("tr");
			let numTds = $tr.find("td").length;

			$tdAlertas = $(`
				<tr class='collapse'>
					<td div-aplicacao='${codAgendamento}' colspan='${numTds}' class='text-left'>
						<div listagem-processamentos></div></td></tr>`).insertAfter($tr).find("[div-aplicacao]");
			$tdAlertas.data("isErros", isErros);


			let filtroProcessamentosFRsTO = {
				idAplicacaoAlertas: isErros ? null : codAgendamento,
				idAplicacaoErros: isErros ? codAgendamento : null,
			}

			const exibicaoCorrecoesTO = await this.call("ImportacaoCartaoRespostaFCD/recuperarProcessamentos", filtroProcessamentosFRsTO);

			for (const exibicaoProcessamentoFRTO of exibicaoCorrecoesTO.listaExibicaoProcessamentoFRTO) {
				this.atualizarProcessamento("[div-aplicacao='" + codAgendamento + "'] [listagem-processamentos]", exibicaoProcessamentoFRTO);
			}

			this.exibir();

			$tdAlertas.closest("tr").collapse('show');
		}
	}

	atualizarProcessamentos() {

		if (this.idInterval) return;

		this.idInterval = setInterval(async () => {

			if (!this.isUsuarioAutenticado()) return;
			if (this.isChamando) return;

			let idsPendentes = [];

			$("#listagemProcessamentos [processamento][pendente]").each(function () {
				let idProcessamento = $(this).attr("id-p");

				if (idProcessamento) {
					idsPendentes.push(idProcessamento);
				}
			});

			if (idsPendentes.length == 0) {
				return;
			}

			this.isChamando = true;

			try {
				const exibicaoCorrecoesTO = await this.call({
					endpoint: "ImportacaoCartaoRespostaFCD/recuperarProcessamentos",
					params: [{ idsPendentes }],
					desativarMsgCarregando: true,
					onRequestError: async () => BackendRequestError.ERRO_TRATADO
				});

				for (const exibicaoProcessamentoFRTO of exibicaoCorrecoesTO.listaExibicaoProcessamentoFRTO) {
					this.atualizarProcessamento("#listagemProcessamentos", exibicaoProcessamentoFRTO, true);
				}

				this.exibir();

			} catch (ignored) {

			} finally {
				this.isChamando = false;
			}
		}, 5000);
	}

	atualizarProcessamento(seletorContainer, exibicaoProcessamentoFRTO = null, isIncluirInicio = null) {
		let $div = $(seletorContainer).find('[id-p="' + exibicaoProcessamentoFRTO.idProcessamento + '"]');
		let isUpload = false;

		if ($div.length == 0 && exibicaoProcessamentoFRTO.idUpload != null) {
			$div = $(seletorContainer).find('[id-upload="' + exibicaoProcessamentoFRTO.idUpload + '"]');
			isUpload = true;
		}

		let $h = $(this.criarExibicaoProcessamento(exibicaoProcessamentoFRTO));

		if ($div.length == 0) {

			let $row = $(seletorContainer);

			if (isUpload || isIncluirInicio) {
				$row.prepend($h);
			} else {
				$row.append($h);
			}

		} else {
			$div.replaceWith($h);
		}
	}

	classeItemSucesso = "fa-circle";
	classeItemErro = "fa-circle text-danger";
	classeItemPendente = "fa-circle-o";

	criarExibicaoProcessamento(exibicaoProcessamentoFRTO: ExibicaoProcessamentoFRTO) {

		let isPDF = exibicaoProcessamentoFRTO.pathArquivoOriginal.toLowerCase().includes(".pdf");
		let isZIP = exibicaoProcessamentoFRTO.pathArquivoOriginal.toLowerCase().includes(".zip");
		let isXLSX = exibicaoProcessamentoFRTO.pathArquivoOriginal.toLowerCase().includes(".xlsx");
		let isDocAuxiliar = exibicaoProcessamentoFRTO.idDocPagina != null;
		let isImagem = !(isPDF || isZIP || isXLSX || isDocAuxiliar);
		let isFinalizado = exibicaoProcessamentoFRTO.idProcessamento != null && (exibicaoProcessamentoFRTO.msgErro != null || (isImagem && exibicaoProcessamentoFRTO.isCorrigida) || (!isImagem && exibicaoProcessamentoFRTO.isImagemTratada));

		let h = ['<div processamento '];

		if (exibicaoProcessamentoFRTO.idProcessamento) h.push(' id-p="' + exibicaoProcessamentoFRTO.idProcessamento + '" ');
		if (exibicaoProcessamentoFRTO.idUpload) h.push(' id-upload="' + exibicaoProcessamentoFRTO.idUpload + '" ');
		if (!isFinalizado) h.push(" pendente ");
		if (isImagem || isDocAuxiliar) h.push(" imagem ");

		h.push('>');
		h.push('<div dados-arquivo class="col-md-12">');
		h.push('<p><a link-simbolo-arquivo target="_blank" href="' + exibicaoProcessamentoFRTO.pathArquivoOriginal + '"><i class="fa ');

		if (exibicaoProcessamentoFRTO.idProcessamento == null) {
			h.push('fa-spinner' + (exibicaoProcessamentoFRTO.msgErro ? '' : ' fa-spin'));
		} else if (isPDF) {
			h.push('fa-file-pdf-o');
		} else if (isZIP) {
			h.push('fa-file-zip-o');
		} else if (isXLSX) {
			h.push('fa-file-excel');
		} else if (isDocAuxiliar) {
			h.push('fa-file-alt');
		} else {
			h.push('fa-qrcode');
		}

		h.push('"></i><span nome-arquivo>' + UtilArquivo.getNomeArquivo(exibicaoProcessamentoFRTO.pathArquivoOriginal) + '</span></a></p>');
		h.push('</div>');

		h.push('<div painel class="col-md-12">');

		if (exibicaoProcessamentoFRTO.msgErro || (isImagem && !exibicaoProcessamentoFRTO.isCorrigida) || (!isImagem && !exibicaoProcessamentoFRTO.isImagemTratada)) {

			h.push('<p checks>');

			let classePasso1 = exibicaoProcessamentoFRTO.isImagemTratada ? this.classeItemSucesso : (exibicaoProcessamentoFRTO.msgErro ? this.classeItemErro : this.classeItemPendente);
			let classeCorrecao = null;
			if (isImagem) {

				h.push(` <i qrcode class='fa ${classePasso1}'></i> ${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_078")} `);

				if (exibicaoProcessamentoFRTO.isIntegracaoQuestionario) {
					let classe = exibicaoProcessamentoFRTO.isQuestionarioProcessado ? this.classeItemSucesso : (exibicaoProcessamentoFRTO.isImagemTratada && exibicaoProcessamentoFRTO.msgErro ? this.classeItemErro : this.classeItemPendente);
					h.push(`
						<span passo-questionario><i questionario class='fa ${classe}'></i> 
							${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_079")}
						</span>
					`);
				}

				classeCorrecao = exibicaoProcessamentoFRTO.isCorrigida ? this.classeItemSucesso : (exibicaoProcessamentoFRTO.isImagemTratada && exibicaoProcessamentoFRTO.msgErro ? this.classeItemErro : this.classeItemPendente);
				h.push(` <i correcao class='fa ${classeCorrecao}'></i> ${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_080")} `);

			} else if (isDocAuxiliar) {

				h.push(` <i qrcode class='fa ${classePasso1}'></i> ${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_081")} `);

				classeCorrecao = exibicaoProcessamentoFRTO.isCorrigida ? this.classeItemSucesso : (exibicaoProcessamentoFRTO.isImagemTratada && exibicaoProcessamentoFRTO.msgErro ? this.classeItemErro : this.classeItemPendente);
				h.push(` <i correcao class='fa ${classeCorrecao}'></i> ${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_082")} `);

			} else {
				h.push(` <i extracao class='fa ${classePasso1}'></i> ${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_083")} `);
			}

			h.push('</p>');
		}

		if (exibicaoProcessamentoFRTO.msgErro) {
			const labelMsgErro = `<p msg-erro class="text-danger">${exibicaoProcessamentoFRTO.msgErro}</p>`;

			if (exibicaoProcessamentoFRTO.isOculto) {
				h.push(labelMsgErro);
			} else {
				h.push(this.addLink({
					dica: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_099"),
					label: labelMsgErro,
					css: "padding: 0px; font-family: Arial, sans-serif",
					onClick: async ({ target }) => {
						await this.exibirAlerta({
							msg: "Deseja excluir este envio?",
							botoes: [{
								label: "Excluir",
								classe: "btn-danger",
								onClick: async () => {
									await this.call("ImportacaoCartaoRespostaFCD/limparSeusEnvios", [exibicaoProcessamentoFRTO.idProcessamento]);
									$(target).closest("[processamento]").remove();
								}
							}]
						})
					},
					retornarHtml: true
				}));
			}

		} else {

			if (exibicaoProcessamentoFRTO.msgAlerta) {
				h.push(`<p msg-alerta class="text-warning">${exibicaoProcessamentoFRTO.msgAlerta}</p>`);
			}

			if (exibicaoProcessamentoFRTO.msgInfo) {
				h.push(`<p msg-info>${exibicaoProcessamentoFRTO.msgInfo}</p>`);
			}
		}

		if (exibicaoProcessamentoFRTO.codUsuarioAvaliado) {
			let hashEdicaoAluno = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, exibicaoProcessamentoFRTO.codUsuarioAvaliado);
			h.push('<p><a target="_blank" href="' + hashEdicaoAluno + '"><i class="fa fa-user"></i> ' + exibicaoProcessamentoFRTO.nomeAvaliado + '</a></p>');

		} else if (exibicaoProcessamentoFRTO.codUsuarioAvaliadoFRIdentificada) {
			let hashEdicaoAluno = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, exibicaoProcessamentoFRTO.codUsuarioAvaliadoFRIdentificada);
			h.push('<p><a target="_blank" href="' + hashEdicaoAluno + '" style="color: #333"><i class="fa fa-user"></i> ' + exibicaoProcessamentoFRTO.nomeAvaliadoFRIdentificada + '</a></p>');
		}

		if (exibicaoProcessamentoFRTO.codProvaFeitaCorrigida && exibicaoProcessamentoFRTO.isCorrigida && !this.isFiscal(false)) {
			let href = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, exibicaoProcessamentoFRTO.codProvaFeitaCorrigida);
			h.push(`<p><a target="_blank" href="${href}">${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_084")}</a></p>`);
		} else if (exibicaoProcessamentoFRTO.codProvaFeitaCorrigidaFRIdentificada && exibicaoProcessamentoFRTO.msgErro && !this.isFiscal(false)) {
			let href = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, exibicaoProcessamentoFRTO.codProvaFeitaCorrigidaFRIdentificada);
			h.push(`<p><a target="_blank" href="${href}" style="color: #333">${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_084")}</a></p>`);
		}

		if (!exibicaoProcessamentoFRTO.msgErro) {
			if (!exibicaoProcessamentoFRTO.idProcessamento) {
				h.push('<div barra-upload class="progress" style="height: 18px"><div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0"></div></div>');

			} else if (isImagem) {
				if (!exibicaoProcessamentoFRTO.isImagemTratada) {
					h.push(`<div barra-qrcode class="progress" style="height: 18px"><div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_085")}</div></div>`);
				} else if (exibicaoProcessamentoFRTO.isIntegracaoQuestionario && !exibicaoProcessamentoFRTO.isQuestionarioProcessado) {
					h.push(`<div barra-questionario class="progress" style="height: 18px"><div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_086")}</div></div>`);
				} else if (!exibicaoProcessamentoFRTO.isCorrigida) {
					h.push(`<div barra-correcao class="progress" style="height: 18px"><div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_087")}</div></div>`);
				}

			} else if (!exibicaoProcessamentoFRTO.isImagemTratada) {
				h.push(`<div barra-extracao class="progress" style="height: 18px"><div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_088")}</div></div>`);
			}
		}

		h.push('</div></div>');

		return h.join("");
	}

	async exibirSituacaoEnvioAgendamento(codAgendamento) {

		UtilHash.registrarHistorico(this.exibirSituacaoEnvioAgendamento, codAgendamento);

		const situacaoEnvioAgendamentoTO: SituacaoEnvioAgendamentoTO = await this.call("ImportacaoCartaoRespostaFCD/recuperarSituacaoAgendamento", codAgendamento);
	
		this.limpar();

		this.setTitulo(situacaoEnvioAgendamentoTO.nomeProva);
		this.setSubtitulo(this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_089"));

		this.setIdTarget("tituloSuperiorDireito");

		if (this.isAnalista() || this.isSecretaria()) {
			this.addBotao({
				label: `<i class='fa fa-check-square-o'></i> ${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_092")} `, classe: "btn-primary", onClick: () => {
					importacaoCartaoRespostaVH.exibirEnvioFRs(0);
				}
			});
		}

		this.setIdTarget(null);

		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_090"), valor: "#" + codAgendamento});
		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_091"), valor: situacaoEnvioAgendamentoTO.nomeSegmento});
		this.addCampoExibicao({ label: this.getCfg("LABEL_TURMA"), valor: situacaoEnvioAgendamentoTO.nomesTurmas});

		let codsAUsComEnvioFR = [];

		this.addCheckbox({
			id: 'filtroEnviosPendentes',
			label: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_097"),
			classe: "col-sm-6 col-md-4 col-lg-3",
			valor: false,
			onChange: () => {
				let tableSituacaoEnvio = $("#tabela_situacao_envio tbody");
				if (this.getValor("filtroEnviosPendentes") === true) {
					for (let codAU of codsAUsComEnvioFR) {
						tableSituacaoEnvio.find(`tr[identificador="${codAU}"]`).hide();
					}
				} else {
					tableSituacaoEnvio.find(`tr[identificador]`).show();
				}
			}
		});

		this.addEspacamentoHorizontal();

		let hashUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);
		let hashCorrecaoProva = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada);

		await this.addTabela({
			collection: situacaoEnvioAgendamentoTO.listaSituacaoEnvioAUTO,
			id: "tabela_situacao_envio",
			propId: "codAgendamentoUsuario",
			colunas: [{
				titulo: this.getCfg("LABEL_ALUNO"),
				prop: (situacaoEnvioAUTO: SituacaoEnvioAUTO) => {
					const h = ["<a target='_blank' href='" + hashUsuario + "/" + situacaoEnvioAUTO.codUsuario + "'><i class='fa fa-user'></i></a> "];

					if (situacaoEnvioAUTO.codProvaFeita) {
						h.push("<a target='_blank' href='" + hashCorrecaoProva + "/" + situacaoEnvioAUTO.codProvaFeita + "'>");
					}

					h.push(situacaoEnvioAUTO.nomeAvaliado);

					return h.join("");
				},
				classe: "descricao"
			}, {
				titulo: this.getCfg("LABEL_TURMA"),
				prop: (situacaoEnvioAUTO: SituacaoEnvioAUTO) => {
					return situacaoEnvioAUTO.nomeTurma || ""
				},
				isVisivel: situacaoEnvioAgendamentoTO.listaSituacaoEnvioAUTO.some(to => this.hasValue(to.nomeTurma))
			}, {
				titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_094"),
				prop: (situacaoEnvioAUTO: SituacaoEnvioAUTO) => {
					const h = ["<div class='btn-group' style='display: inline-block'>"];

					for (const situacaoEnvioAUFRTO of situacaoEnvioAUTO.listaSituacaoEnvioAUFRTO) {
	
						if ((situacaoEnvioAUFRTO.pathFREnviada || situacaoEnvioAUFRTO.pathFRCorrigida) && !codsAUsComEnvioFR.includes(situacaoEnvioAUTO.codAgendamentoUsuario)) {
							codsAUsComEnvioFR.push(situacaoEnvioAUTO.codAgendamentoUsuario);
						}
	
						h.push(" <a class='btn btn-default btn-sm' target='_blank' ");
	
						if (situacaoEnvioAUFRTO.pathFRCorrigida) {
							h.push(` title='${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_095")}' href='${situacaoEnvioAUFRTO.pathFRCorrigida}' `);
						} else {
							h.push(` title='${this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_096")}' `);
							h.push(" style='background-color: lightgray' ");
							h.push(" href='" + situacaoEnvioAUFRTO.pathFRPdf + "' ");
						}
	
						h.push(">" + situacaoEnvioAUFRTO.numPagina + "</a>");
					}
	
					h.push("</div>");
					return h.join("");
				},
			}, {
				titulo: this.getMsg("FP_FRONT_ImportacaoCartaoRespostaVH_093"),
				prop: (situacaoEnvioAUTO: SituacaoEnvioAUTO) => {
					const h = ["<div class='btn-group' style='display: inline-block'>"];

					for (const situacaoEnvioAUFRTO of situacaoEnvioAUTO.listaSituacaoEnvioAUFRDescartadaTO) {
						h.push("<a class='btn btn-default btn-sm' target='_blank' ");
						h.push(" style='background-color: #ff9999' ");
						h.push(" title='" + (situacaoEnvioAUFRTO.msgErro ? situacaoEnvioAUFRTO.msgErro.replace(/\'/g, "\"") : "") + "' ");
						h.push(" href='" + situacaoEnvioAUFRTO.pathFREnviada + "' ");
						h.push(">" + situacaoEnvioAUFRTO.numPagina + "</a>");
					}
	
					h.push("</div>");
					return h.join("");
				},
			}],
		});

		this.exibir();
	}
}

const importacaoCartaoRespostaVH = new ImportacaoCartaoRespostaVH();

type SituacaoEnvioAgendamentoTO = {
	codAgendamento: number;
	nomeProva: string;
	nomeSegmento: string;
	nomesTurmas: string[];
	listaSituacaoEnvioAUTO: SituacaoEnvioAUTO[];
}
type SituacaoEnvioAUTO = {
	codUsuario: number;
	nomeAvaliado: string;
	nomeTurma: string;
	codProvaFeita: number;
	codAgendamentoUsuario: number;
	listaSituacaoEnvioAUFRTO: SituacaoEnvioAUFRTO[];
	listaSituacaoEnvioAUFRDescartadaTO: SituacaoEnvioAUFRTO[];
}
type SituacaoEnvioAUFRTO = {		
	numPagina: number;
	pathFRPdf: string;
	pathFREnviada: string;
	pathFRCorrigida: string;
	msgErro: string;
}
type ExibicaoProcessamentoFRTO = {
	idProcessamento: number;
	idUpload: number;
	idDocPagina: number;
	msgAlerta: string;
	msgErro: string;
	msgInfo: string;
	dataEnvio: string;
	isIntegracaoQuestionario: boolean;
	isQuestionarioProcessado: boolean;
	isImagemTratada: boolean;
	isExtracao: boolean;
	isCorrigida: boolean;
	isOculto: boolean;
	codProvaFeitaCorrigida: number;
	codUsuarioAvaliado: number;
	nomeAvaliado: string;
	codProvaFeitaCorrigidaFRIdentificada: number;
	codUsuarioAvaliadoFRIdentificada: number;
	nomeAvaliadoFRIdentificada: string;
	pathArquivoOriginal: string;
}