class DashboardProvaVH extends AmaisVH {

	tsInicioAtualizacao: number;

	idsMgsLabelsSituacaoAvaliados = {
		"numAguardandoAvaliado": "FP_FRONT_DashboardProvaVH_001",
		"numCadernoGerado": "FP_FRONT_DashboardProvaVH_015",
		"numEmProva": "FP_FRONT_DashboardProvaVH_003",
		"numEmProvaOffline": "FP_FRONT_DashboardProvaVH_002",
		"numEncerrados": "FP_FRONT_DashboardProvaVH_004",
		"numAnulados": "FP_FRONT_DashboardProvaVH_005",
		"numAusentes": "FP_FRONT_DashboardProvaVH_006",
	}

	coresSituacaoAvaliados = {
		"numAguardandoAvaliado": UtilGrafico.COR_AMARELO,
		"numCadernoGerado": UtilGrafico.COR_LARANJA,
		"numEmProva": UtilGrafico.COR_VERDE_CLARO,
		"numEmProvaOffline": UtilGrafico.COR_AZUL_CLARO,
		"numEncerrados": UtilGrafico.COR_AZUL_ESCURO,
		"numAnulados": UtilGrafico.COR_VERMELHO,
		"numAusentes": UtilGrafico.COR_PRETA,
	}

	labelsSituacaoAvaliados: any;

	constructor() {
		super("DashboardProvaVH");
		this.addOperacaoParaHash("dpep", this.exibirPorProva);
	}

	getLabelsSituacaoAvaliados() {
		if (this.labelsSituacaoAvaliados == null) {
			this.labelsSituacaoAvaliados = {};
			for (const k of Object.keys(this.idsMgsLabelsSituacaoAvaliados)) {
				this.labelsSituacaoAvaliados[k] = this.getMsg(this.idsMgsLabelsSituacaoAvaliados[k]);
			};
		}
		return this.labelsSituacaoAvaliados;
	}

	async exibirPorProva(codProva) {

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_DashboardProvaVH_007"), ``);

		this.setIdTarget("tituloSuperiorDireito");

		this.addBotao({
			id: "botaoAtualizarDashboardProva", 
			label: `<i class='fa fa-refresh'></i> ${this.getMsg("FP_FRONT_DashboardProvaVH_008")}`, 
			dica: this.getMsg("FP_FRONT_DashboardProvaVH_009"), 
			onClick: async () => {
				await this.atualizarPorProva(codProva);
				$("#botaoAtualizarDashboardProva").data("ultima-atualizacao", Date.now());
			}
		});

		this.setIdTarget(null);

		this.append("<div class='row' dash-provas>");
		this.append("<div dash-linha>");

		this.append("<div>");
		this.append("<div class='panel panel-default' style='background-color: transparent; height: 320px'>");
		this.append(`<div class='panel-heading' style='background-color: transparent;'><h3>${this.getCfg("LABEL_MENU_CADASTRO_ALUNO")}</h3></div>`);
		this.append("<div class='panel-body'><div id='grafico_avaliados' class='ct-chart' style='width: 500px; height: 250px; margin: 0px auto;'></div>");
		this.append("</div>");
		this.append("</div>");
		this.append("</div>");

		this.append("<div>");
		this.append("<div class='panel panel-default' style='background-color: transparent; height: 320px'>");
		this.append(`<div class='panel-heading' style='background-color: transparent;'><h3>Respostas</h3></div>`);
		this.append("<div class='panel-body'><div id='grafico_questoes' class='ct-chart' style='width: 500px; height: 250px; margin: 0px auto;'></div>");
		this.append("</div>");
		this.append("</div>");
		this.append("</div>");

		this.append("</div>");
		this.append("</div>");


		this.append("<div class='row' dash-provas>");
		this.append("<div dash-linha>");

		this.append("<div>");
		this.append("<div class='panel panel-default' style='background-color: transparent; height: 390px'>");
		this.append(`<div class='panel-heading' style='background-color: transparent;'><h3>${this.getCfg("LABEL_MENU_CADASTRO_ALUNO")} por ${this.getCfg("LABEL_TURMA").toLowerCase()}</h3></div>`);
		this.append("<div class='panel-body'><div id='grafico_turmas' class='ct-chart' style='width: 100%; height: 300px; margin: 0px auto;'></div>");
		this.append("</div>");
		this.append("</div>");
		this.append("</div>");

		this.append("</div>");
		this.append("</div>");

		this.exibir();

		UtilHash.registrarHistorico(this.exibirPorProva, codProva);

		await this.atualizarPorProva(codProva);

		this.tsInicioAtualizacao = Date.now();
		this.atualizarAutomaticamente(this.tsInicioAtualizacao);
	}

	async atualizarPorProva(codProva) {

		const dashProvaQuestoesTO = await this.call("DashboardProvaFCD/recuperarQuestoes", codProva);

		var hashEdicaoProva = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 3, codProva);
		this.setSubtitulo(`${this.getMsg("FP_FRONT_DashboardProvaVH_010")} <a href='${hashEdicaoProva}'>${dashProvaQuestoesTO.nomeProva}</a>`);

		if (dashProvaQuestoesTO.numTotal == 0) {
			this.setIdTarget("#grafico_questoes");
			this.addTexto({ texto: this.getMsg("FP_FRONT_DashboardProvaVH_011"), css: "text-align: center" });
			this.exibir();

		} else {
			delete dashProvaQuestoesTO["nomeProva"];

			const labels = this.tratarDadosRespostas(dashProvaQuestoesTO);

			await UtilGrafico.criarEChart("#grafico_questoes", {
				tos: [dashProvaQuestoesTO],
				labels: labels,
				cores: {
					"numRespondidas": UtilGrafico.COR_AZUL_CLARO,
					"numNaoRespondidas": UtilGrafico.COR_AMARELO,
					"numEmBranco": UtilGrafico.COR_LARANJA,
				},
				tipo: "PIZZA",
			});

		}

		const listaDashProvaAvaliadosPorTurmaTO = await this.call("DashboardProvaFCD/recuperarAvaliadosPorTurma", codProva);

		let labelsDoConsolidado = Object.assign({}, this.getLabelsSituacaoAvaliados());

		let dashProvaAvaliadosTO = this.tratarDadosAvaliadosEConsolidar(listaDashProvaAvaliadosPorTurmaTO, labelsDoConsolidado);

		await UtilGrafico.criarEChart("#grafico_avaliados", {
			tos: [dashProvaAvaliadosTO],
			labels: labelsDoConsolidado,
			cores: this.coresSituacaoAvaliados,
			tipo: "PIZZA",
		});

		await UtilGrafico.criarEChart("#grafico_turmas", {
			tos: listaDashProvaAvaliadosPorTurmaTO,
			labels: this.getLabelsSituacaoAvaliados(),
			cores: this.coresSituacaoAvaliados,
			labelBarra: "nomeTurma",
			tipo: "BARRAS_EMPILHADAS",
		});
	}

	atualizarAutomaticamente(tsInicioAtualizacao: number) {

		if (tsInicioAtualizacao !== this.tsInicioAtualizacao) return;

		const $botao = $("#botaoAtualizarDashboardProva");

		if ($botao.length == 0) return;

		const agora = Date.now();
		let tsUltima = $botao.data("ultima-atualizacao");

		if (tsUltima == null) {
			$botao.data("ultima-atualizacao", agora);
			tsUltima = agora;
		}

		const porcentagemPercorrida = Math.round(100 * ((agora - tsUltima) / 1000) / 120);

		$botao.css("background", `linear-gradient(90deg, #ddd ${porcentagemPercorrida}%, white 0%)`);

		if (porcentagemPercorrida >= 100) {
			$botao.click();
		}

		setTimeout(() => this.atualizarAutomaticamente(tsInicioAtualizacao), 1000);
	}

	tratarDadosRespostas(dashProvaQuestoesTO) {
		const labels = {
			"numRespondidas": this.getMsg("FP_FRONT_DashboardProvaVH_012"),
			"numNaoRespondidas": this.getMsg("FP_FRONT_DashboardProvaVH_013"),
			"numEmBranco": this.getMsg("FP_FRONT_DashboardProvaVH_014"),
		}

		Object.keys(dashProvaQuestoesTO).filter(atributo => atributo != "numTotal").forEach(atributo => {

			if (dashProvaQuestoesTO[atributo] === 0) {
				delete dashProvaQuestoesTO[atributo];
			} else {

				labels[atributo] = labels[atributo]
					+ " "
					+ UtilNumero.floatToString(100 * dashProvaQuestoesTO[atributo] / dashProvaQuestoesTO["numTotal"], 1)
					+ "%";
			}
		});

		delete dashProvaQuestoesTO["numTotal"];

		return labels;
	}

	tratarDadosAvaliadosEConsolidar(listaDashProvaAvaliadosPorTurmaTO, labelsDoConsolidado) {

		let dashProvaAvaliadosTO = {};
		let atributosNaoConsolidaveis = ["nomeTurma"];

		listaDashProvaAvaliadosPorTurmaTO.filter(to => to.nomeTurma == null).forEach(to => {
			to.nomeTurma = "(Avulso)";
		})

		dashProvaAvaliadosTO = listaDashProvaAvaliadosPorTurmaTO.reduce((dashProvaAvaliadosTO, dashProvaAvaliadosPorTurmaTO) => {

			Object.keys(dashProvaAvaliadosPorTurmaTO)
				.filter(atributo => !atributosNaoConsolidaveis.includes(atributo))
				.forEach(atributo => {
					if (dashProvaAvaliadosPorTurmaTO[atributo] > 0) {
						// soma no consolidado
						dashProvaAvaliadosTO[atributo] = (dashProvaAvaliadosTO[atributo] || 0) + dashProvaAvaliadosPorTurmaTO[atributo];
					}
				});

			delete dashProvaAvaliadosPorTurmaTO["numTotal"];

			return dashProvaAvaliadosTO;

		}, dashProvaAvaliadosTO);

		Object.keys(dashProvaAvaliadosTO).filter(atributo => atributo != "nomeTurma").forEach(atributo => {
			labelsDoConsolidado[atributo] = labelsDoConsolidado[atributo]
				+ " "
				+ UtilNumero.floatToString(100 * dashProvaAvaliadosTO[atributo] / dashProvaAvaliadosTO["numTotal"], 1)
				+ "%";
		});

		delete dashProvaAvaliadosTO["numTotal"];

		return dashProvaAvaliadosTO;
	}
}

const dashboardProvaVH = new DashboardProvaVH();
