class DataWarehouseVH extends AmaisVH {

	listaInfosAvaliados: any[];

	constructor(nomeVH: string) {
		super(nomeVH);
	}
	
	getFiltrosIndiceRespostasTO(): FiltrosIndiceRespostasTO {

		const to: FiltrosIndiceRespostasTO = { ativos: {}, ativosListaTO: {} };
		const campoAgrupamento = CampoRelRespostas.from($("[rel-dataw-campo-agrupamento]").attr("rel-dataw-campo-agrupamento")).id;
		const prefixoId = `${this.nomeVH}_${campoAgrupamento}`;
		
		to.situacaoCD = this.getValor(`${prefixoId}_SituacaoCD`);
		to.buscaTextual = this.getValor(`${prefixoId}_palavra_chave`);
		
		for (const c of CampoRelRespostas.values()) {
			if (c.isData) {
				this.addFiltro(
					to, 
					c, 
					[this.getValor(`${prefixoId}_${c.id}_periodo_inicio`), this.getValor(`${prefixoId}_${c.id}_periodo_fim`)]
				);
			} else {
				this.addFiltro(
					to,
					c, 
					this.getValor(`${prefixoId}_${c.id}`), 
					this.getArrayTOsSelect(`${prefixoId}_${c.id}`)
				);
			}
		}

		return to;
	}

	getCfgsConsultaIndicePFsTO(): CfgsConsultaIndicePFsTO {

		const to = new CfgsConsultaIndicePFsTO();

		to.campoAgrupamento = CampoIndicePFs.from($("[rel-dataw-campo-agrupamento]").attr("rel-dataw-campo-agrupamento"));
		to.isExibirSomentePFDaAU = !this.getValor("isExibirSomentePFDaAU");

		if (this.getValor("palavra_chave")) {
			to.buscaTextualNoNomeDoAgrupamento = this.getValor("palavra_chave");
		}

		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_EMPRESA_USUARIO, this.getValor(CampoIndicePFs.COD_EMPRESA_USUARIO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_EMPRESA_AGENDAMENTO, this.getValor(CampoIndicePFs.COD_EMPRESA_AGENDAMENTO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_USUARIO, this.getValor(CampoIndicePFs.COD_USUARIO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_TURMA_NA_APLICACAO, this.getValor(CampoIndicePFs.COD_TURMA_NA_APLICACAO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_PROVA, this.getValor(CampoIndicePFs.COD_PROVA.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.TIPO_APLICACAO, this.getValor(CampoIndicePFs.TIPO_APLICACAO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.COD_AGENDAMENTO, this.getValor(CampoIndicePFs.COD_AGENDAMENTO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.IS_ANULADA, this.getValor(CampoIndicePFs.IS_ANULADA.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.IS_ARQUIVADA, this.getValor(CampoIndicePFs.IS_ARQUIVADA.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.IS_USUARIO_ARQUIVADO, this.getValor(CampoIndicePFs.IS_USUARIO_ARQUIVADO.id));
		this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.IS_APLICACAO_ARQUIVADA, this.getValor(CampoIndicePFs.IS_APLICACAO_ARQUIVADA.id));

		if (this.getValor("periodo_inicio") || this.getValor("periodo_fim")) {
			this.addNotNull(to, "filtrosAtivos", CampoIndicePFs.DATA_INICIO_PF, [this.getValor("periodo_inicio"), this.getValor("periodo_fim")]);
		}

		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_EMPRESA_USUARIO, this.getArrayTOsSelect(CampoIndicePFs.COD_EMPRESA_USUARIO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_EMPRESA_AGENDAMENTO, this.getArrayTOsSelect(CampoIndicePFs.COD_EMPRESA_AGENDAMENTO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_USUARIO, this.getArrayTOsSelect(CampoIndicePFs.COD_USUARIO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_TURMA_NA_APLICACAO, this.getArrayTOsSelect(CampoIndicePFs.COD_TURMA_NA_APLICACAO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_PROVA, this.getArrayTOsSelect(CampoIndicePFs.COD_PROVA));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.TIPO_APLICACAO, this.getArrayTOsSelect(CampoIndicePFs.TIPO_APLICACAO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.COD_AGENDAMENTO, this.getArrayTOsSelect(CampoIndicePFs.COD_AGENDAMENTO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.IS_ANULADA, this.getArrayTOsSelect(CampoIndicePFs.IS_ANULADA));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.IS_ARQUIVADA, this.getArrayTOsSelect(CampoIndicePFs.IS_ARQUIVADA));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.IS_USUARIO_ARQUIVADO, this.getArrayTOsSelect(CampoIndicePFs.IS_USUARIO_ARQUIVADO));
		this.addNotNull(to, "tosFiltrosAtivos", CampoIndicePFs.IS_APLICACAO_ARQUIVADA, this.getArrayTOsSelect(CampoIndicePFs.IS_APLICACAO_ARQUIVADA));

		const camposOpcionaisParaExibicao = this.getValor("camposOpcionaisParaExibicao");

		if (camposOpcionaisParaExibicao) {
			to.camposOpcionaisParaExibicao = [];
			for (let campo of camposOpcionaisParaExibicao) {
				to.camposOpcionaisParaExibicao.push(campo);
			}
		}

		return to;
	}

	addNotNull(to, propObj, chave, valor) {
		if (valor === null) return;
		if (!Array.isArray(valor)) valor = [valor];
		if (!valor.some(v => v !== null && v !== undefined)) return;
		if (valor.length == 0) return;
		propObj = propObj.id || propObj;
		to[propObj] = to[propObj] || {};
		const obj = to[propObj];
		chave = chave.id || chave;
		if (!obj[chave]) {
			obj[chave] = valor;
		} else {
			obj[chave] = obj[chave].concat(valor);
		}
	}

	getArrayTOsSelect(campo: any) {
		let tos = this.getTOsSelecionadosSelect(campo.id || campo);
		if (!tos) return null;
		if (!Array.isArray(tos)) tos = [tos];
		return tos.map(to => {
			return {
				id: String(to.id),
				text: to.text
			}
		})
	}
	
	async addFiltrosIndiceRespostas(cfgs: CfgsFiltrosIndiceRespostas) {

		const filtrosTO = cfgs.filtrosTO;

		filtrosTO.ativos = filtrosTO.ativos || {};
		filtrosTO.ativosListaTO = filtrosTO.ativosListaTO || {};

		cfgs.omitidos = cfgs.omitidos ?? [];
		cfgs.desabilitados = cfgs.desabilitados ?? [];

		const aplicarFiltros = async () => {
		
			$("[msg-erro]").slideUp();
			
			const retorno = await cfgs.onAplicarFiltros();
			
			if (retorno) {
				$("[msg-erro]").html(retorno).slideDown();
				return false;
			}
		}

		this.append("<div id='filtrosIndiceRespostas' style='display: none'>");

		this.abrirAbaAccordion({
			html: `rel-dataw-campo-agrupamento="${cfgs.campoAgrupamento}"`,
			titulo: this.getMsg("MSG_VH_RL_70"),
			aberta: true
		});

		this.addFormulario();

		const prefixoId = `${this.nomeVH}_${cfgs.campoAgrupamento}`;

		await this.addSelect({
			collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.COD_PROVA),
			valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.COD_PROVA),
			id: `${prefixoId}_${CampoRelRespostas.COD_PROVA}`,
			dica: this.getMsg("MSG_VH_RL_38"),
			propId: "id",
			propLabel: "descricao",
			classe: "col-md-6",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoRelRespostas: CampoRelRespostas.NOME_PROVA
					}
				},
				numMinimoCaracteres: 3,
			},
			visivel: !cfgs.omitidos.includes(CampoRelRespostas.COD_PROVA.id),
			habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.COD_PROVA.id),
		});
		
		await this.addSelect({
			collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.COD_AGENDAMENTO),
			valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.COD_AGENDAMENTO),
			id: `${prefixoId}_${CampoRelRespostas.COD_AGENDAMENTO}`,
			dica: this.getMsg(CampoRelRespostas.COD_AGENDAMENTO.idMsg),
			propId: "id",
			propLabel: "descricao",
			classe: "col-md-6",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoRelRespostas: CampoRelRespostas.NOME_AGENDAMENTO
					}
				},
				numMinimoCaracteres: 3,
			},
			habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.COD_AGENDAMENTO.id),
		});

		await this.addSelect({
			collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.CODS_DISCIPLINAS),
			valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.CODS_DISCIPLINAS),
			id: `${prefixoId}_${CampoRelRespostas.CODS_DISCIPLINAS}`,
			dica: this.getMsg("MSG_VH_RL_39"),
			classe: "col-md-6",
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
				payload: {
					cfgsRecuperacaoFiltrosTO: {
						campoRelRespostas: CampoRelRespostas.NOMES_DISCIPLINAS
					}
				},
				numMinimoCaracteres: 3,
			},
			habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.CODS_DISCIPLINAS.id),
		});

		if (this.isAdministrador() && this.getIsAmbienteSegmentado()) {
			await this.addSelect({
				collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.COD_EMPRESA_USUARIO),
				valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.COD_EMPRESA_USUARIO),
				id: `${prefixoId}_${CampoRelRespostas.COD_EMPRESA_USUARIO}`,
				dica: this.getMsg("FP_FRONT_RelatorioAcertosErrosVH_001"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOME_EMPRESA_USUARIO
						}
					},
					numMinimoCaracteres: 0,
				},
				habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.COD_EMPRESA_USUARIO.id),
			});
			await this.addSelect({
				collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.COD_EMPRESA_AGENDAMENTO),
				valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.COD_EMPRESA_AGENDAMENTO),
				id: `${prefixoId}_${CampoRelRespostas.COD_EMPRESA_AGENDAMENTO}`,
				dica: this.getMsg("FP_FRONT_RelatorioAcertosErrosVH_002"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOME_EMPRESA_AGENDAMENTO
						}
					},
					numMinimoCaracteres: 0,
				},
				habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.COD_EMPRESA_AGENDAMENTO.id),
			});
		}

		if (this.isAdministrador() || (!this.isAluno() && !this.isCfgHabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL"))) {
			await this.addSelect({
				collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.COD_USUARIO),
				valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.COD_USUARIO),
				id: `${prefixoId}_${CampoRelRespostas.COD_USUARIO}`,
				dica: this.getCfg("LABEL_ALUNO"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOME_USUARIO
						}
					},
					numMinimoCaracteres: 3,
				},
				habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.COD_USUARIO.id),
			})
			
			await this.addSelect({
				collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.CODS_TURMAS_USUARIO),
				valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.CODS_TURMAS_USUARIO),
				id: `${prefixoId}_${CampoRelRespostas.CODS_TURMAS_USUARIO}`,
				dica: this.getCfg("LABEL_TURMA"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOMES_TURMAS_USUARIO
						}
					},
					numMinimoCaracteres: 3,
				},
				habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.CODS_TURMAS_USUARIO.id),
			});
		}
		
		if (cfgs.isExibirNumQuestaoNaProva) {
			await this.addSelect({
				collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.NUM_QUESTAO_NA_PROVA),
				valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.NUM_QUESTAO_NA_PROVA),
				id: `${prefixoId}_${CampoRelRespostas.NUM_QUESTAO_NA_PROVA}`,
				dica: this.getMsg("FP_FRONT_DataWarehouseVH_001"),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NUM_QUESTAO_NA_PROVA
						}
					},
					numMinimoCaracteres: 0,
				},
				habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.NUM_QUESTAO_NA_PROVA.id),
			});
		}

		if (cfgs.isExibirBuscaTextual && !this.isAluno() && ![CampoRelRespostas.NOMES_DISCIPLINAS.id, CampoRelRespostas.NOMES_TURMAS_USUARIO.id].includes(cfgs.campoAgrupamento)) {
			this.addCampoTexto({
				id: `${prefixoId}_palavra_chave`,
				dica: this.getMsg("MSG_VH_RL_50"),
				prefixo: "<i class='fa fa-search'></i> ",
				onEnter: aplicarFiltros,
			});		
		}
		if (cfgs.isExibirFiltrosCDs) {
			this.addCamposPeriodo({
				dica: this.getMsg("FP_FRONT_DataWarehouseVH_013"),
				idInicio: `${prefixoId}_${CampoRelRespostas.DATA_ENVIO_DISCURSIVA}_periodo_inicio`,
				idFim: `${prefixoId}_${CampoRelRespostas.DATA_ENVIO_DISCURSIVA}_periodo_fim`,
				labelFim: this.getMsg("MSG_VH_RL_49"),
				valorInicio: this.getFiltroId(filtrosTO, CampoRelRespostas.DATA_ENVIO_DISCURSIVA, 0),
				valorFim: this.getFiltroId(filtrosTO, CampoRelRespostas.DATA_ENVIO_DISCURSIVA, 1),
			});
		} else {
			this.addCamposPeriodo({
				dica: this.getMsg("FP_FRONT_DataWarehouseVH_014"),
				idInicio: `${prefixoId}_${CampoRelRespostas.DATA_INICIO_PF}_periodo_inicio`,
				idFim: `${prefixoId}_${CampoRelRespostas.DATA_INICIO_PF}_periodo_fim`,
				labelFim: this.getMsg("MSG_VH_RL_49"),
				valorInicio: this.getFiltroId(filtrosTO, CampoRelRespostas.DATA_INICIO_PF, 0),
				valorFim: this.getFiltroId(filtrosTO, CampoRelRespostas.DATA_INICIO_PF, 1),
			});
		}

		if (cfgs.isExibirFiltrosCDs) {

			await this.addSelect({
				id: `${prefixoId}_SituacaoCD`,
				dica: "Situacao",
				collection: [{
					id: SituacaoCD.AGUARDANDO_CORRECAO, 
					nome: this.getMsg("FP_FRONT_DataWarehouseVH_010")
				},{
					id: SituacaoCD.CORRIGIDA, 
					nome: this.getMsg("FP_FRONT_DataWarehouseVH_011")
				},{
					id: SituacaoCD.TODAS, 
					nome: this.getMsg("FP_FRONT_DataWarehouseVH_012")
				}],
				valor: filtrosTO.situacaoCD || SituacaoCD.AGUARDANDO_CORRECAO,
				obrigatorio: true
			});

			await this.addSelect({
				collection: this.getFiltroArrayListaTO(filtrosTO, CampoRelRespostas.CODS_USUARIOS_CORRETORES),
				valor: this.getFiltroArrayIds(filtrosTO, CampoRelRespostas.CODS_USUARIOS_CORRETORES),
				id: `${prefixoId}_${CampoRelRespostas.CODS_USUARIOS_CORRETORES}`,
				dica: this.getMsg("FP_FRONT_CorrecaoDiscursivasVH_023"),
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoRelRespostas: CampoRelRespostas.NOMES_USUARIOS_CORRETORES
						},
					},
					numMinimoCaracteres: 3,
				},
				visivel: this.isAdministrador(),
				habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.CODS_USUARIOS_CORRETORES.id),
			});
		}

		if (cfgs.isExibirCodRecurso && !this.isAluno()) {
			this.addCampoTexto({
				id: `${prefixoId}_${CampoRelRespostas.COD_RECURSO}`,
				valor: this.getFiltroId(filtrosTO, CampoRelRespostas.COD_RECURSO, 0),
				dica: this.getMsg(CampoRelRespostas.COD_RECURSO.idMsg),
				css: "text-align: center",
				onEnter: aplicarFiltros,
				habilitado: !cfgs.desabilitados.includes(CampoRelRespostas.COD_RECURSO.id),
			});		
		}

		this.append(`
			<div class='col-md-12'>
				<div ${cfgs.campoAgrupamento}_msg-erro class='alert alert-danger' role='alert' style='display: none; width: 100%; margin-top: 10px'>
				</div>
			</div>
			<div class='col-md-12'>
				<div class='col-md-6 no-padding'>
				</div>
				<div class='col-md-6 no-padding'>
		`);

		this.addBotao({
			label: this.getMsg("MSG_VH_RL_37"),
			classe: "btn-primary pull-right",
			onClick: aplicarFiltros
		});

		this.append(`
				</div>
			</div>
		`);
		
		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		this.append("</div>");
	}
	
	async addFiltrosPFs(cfgsFiltrosPFs: CfgsFiltrosPFs) {

		const cfgsConsultaIndicePFsTO = cfgsFiltrosPFs.cfgsConsultaIndicePFsTO;
		const filtrosAtivos = cfgsFiltrosPFs.cfgsConsultaIndicePFsTO?.filtrosAtivos || {};
		const tosFiltrosAtivos = cfgsFiltrosPFs.cfgsConsultaIndicePFsTO?.tosFiltrosAtivos || {};
		let valorDataInicio = null;
		let valorDataFim = null;

		if (filtrosAtivos[CampoIndicePFs.DATA_INICIO_PF.id]) {
			valorDataInicio = filtrosAtivos[CampoIndicePFs.DATA_INICIO_PF.id][0];
			valorDataFim = filtrosAtivos[CampoIndicePFs.DATA_INICIO_PF.id][1];
		} 

		const aplicarFiltros = async () => {
				
			$(`[${cfgsConsultaIndicePFsTO.campoAgrupamento?.id}_msg-erro]`).slideUp();
			
			const retorno = await cfgsFiltrosPFs.onAplicarFiltros();
			
			if (retorno) {
				$(`[${cfgsConsultaIndicePFsTO.campoAgrupamento?.id}_msg-erro]`).html(retorno).slideDown();
				return false;
			}
		}

		const getCollection = (campoIndicePFs: CampoIndicePFs) => {
			return tosFiltrosAtivos[campoIndicePFs.id] || [];
		}
		const getIds = (campoIndicePFs: CampoIndicePFs) => {
			return getCollection(campoIndicePFs).map(to => to.id);
		}
		const getValorBoolean = (campoIndicePFs: CampoIndicePFs) => {
			try {
				return filtrosAtivos[campoIndicePFs.id][0];
			} catch (ignored) {}
		}

		const isVisivel =  valorDataInicio || valorDataFim || Object.keys(tosFiltrosAtivos).length > 0;

		this.append(`<div id='filtrosPFs' ${isVisivel ? "" : "style='display: none'"}>`);
		this.abrirAbaAccordion({
			id: 'rel-dataw-provas-respondidas',
			html: `rel-dataw-campo-agrupamento="${cfgsConsultaIndicePFsTO.campoAgrupamento?.id}"`,
			titulo: this.getMsg("MSG_VH_RL_70"),
			aberta: true
		});

		this.addFormulario();

		if (!cfgsFiltrosPFs.filtrosSuprimidos?.includes(CampoIndicePFs.COD_PROVA)) {
			await this.addSelect({
				collection: getCollection(CampoIndicePFs.COD_PROVA),
				valor: getIds(CampoIndicePFs.COD_PROVA),
				id: CampoIndicePFs.COD_PROVA.id,
				dica: this.getMsg("MSG_VH_RL_38"),
				propId: "id",
				propLabel: "descricao",
				classe: "col-md-6",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoIndicePFs: CampoIndicePFs.NOME_PROVA,
							restricoesListagensPF: cfgsFiltrosPFs.restricoesListagensPF
						}
					},
					numMinimoCaracteres: 3,
				}
			});
		}

		if (this.isAdministrador() && this.getIsAmbienteSegmentado()) {
			if (!cfgsFiltrosPFs.filtrosSuprimidos?.includes(CampoIndicePFs.COD_EMPRESA_AGENDAMENTO)) {
				await this.addSelect({
					collection: getCollection(CampoIndicePFs.COD_EMPRESA_AGENDAMENTO),
					valor: getIds(CampoIndicePFs.COD_EMPRESA_AGENDAMENTO),
					id: CampoIndicePFs.COD_EMPRESA_AGENDAMENTO.id,
					dica: this.getMsg("FP_FRONT_RelatorioAcertosErrosVH_002"),
					propId: "id",
					propLabel: "descricao",
					multiplo: true,
					loadOpcoesBack: {
						endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
						payload: {
							cfgsRecuperacaoFiltrosTO: {
								campoIndicePFs: CampoIndicePFs.NOME_EMPRESA_AGENDAMENTO,
								restricoesListagensPF: cfgsFiltrosPFs.restricoesListagensPF
							}
						},
						numMinimoCaracteres: 0,
					}
				});
			}
			if (!cfgsFiltrosPFs.filtrosSuprimidos?.includes(CampoIndicePFs.COD_EMPRESA_USUARIO)) {
				await this.addSelect({
					collection: getCollection(CampoIndicePFs.COD_EMPRESA_USUARIO),
					valor: getIds(CampoIndicePFs.COD_EMPRESA_USUARIO),
					id: CampoIndicePFs.COD_EMPRESA_USUARIO.id,
					dica: this.getMsg("FP_FRONT_RelatorioAcertosErrosVH_001"),
					propId: "id",
					propLabel: "descricao",
					multiplo: true,
					loadOpcoesBack: {
						endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
						payload: {
							cfgsRecuperacaoFiltrosTO: {
								campoIndicePFs: CampoIndicePFs.NOME_EMPRESA_USUARIO,
								restricoesListagensPF: cfgsFiltrosPFs.restricoesListagensPF
							}
						},
						numMinimoCaracteres: 0,
					}
				});
			}
		}

		if (!cfgsFiltrosPFs.filtrosSuprimidos?.includes(CampoIndicePFs.TIPO_APLICACAO)) {
			await this.addSelect({
				collection: getCollection(CampoIndicePFs.TIPO_APLICACAO),
				valor: getIds(CampoIndicePFs.TIPO_APLICACAO),
				id: CampoIndicePFs.TIPO_APLICACAO.id,
				dica: CampoIndicePFs.TIPO_APLICACAO.getNome(),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoIndicePFs: CampoIndicePFs.TIPO_APLICACAO,
							restricoesListagensPF: cfgsFiltrosPFs.restricoesListagensPF
						}
					},
					numMinimoCaracteres: 0,
				}
			});
		}

		if (!cfgsFiltrosPFs.filtrosSuprimidos?.includes(CampoIndicePFs.COD_AGENDAMENTO)) {
			await this.addSelect({
				collection: getCollection(CampoIndicePFs.COD_AGENDAMENTO),
				valor: getIds(CampoIndicePFs.COD_AGENDAMENTO),
				id: CampoIndicePFs.COD_AGENDAMENTO.id,
				dica: CampoIndicePFs.COD_AGENDAMENTO.getNome(),
				propId: "id",
				propLabel: "descricao",
				multiplo: true,
				loadOpcoesBack: {
					endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
					payload: {
						cfgsRecuperacaoFiltrosTO: {
							campoIndicePFs: CampoIndicePFs.NOME_APLICACAO,
							restricoesListagensPF: cfgsFiltrosPFs.restricoesListagensPF
						}
					},
					numMinimoCaracteres: 0,
				}
			});
		}

		if (this.isAdministrador() || (!this.isAluno() && !this.isCfgHabilitada("FUNCIONALIDADE_CORRECAO_DISCURSIVAS_IMPARCIAL"))) {
			
			if (!cfgsFiltrosPFs.filtrosSuprimidos?.includes(CampoIndicePFs.COD_USUARIO)) {
				await this.addSelect({
					collection: getCollection(CampoIndicePFs.COD_TURMA_NA_APLICACAO),
					valor: getIds(CampoIndicePFs.COD_TURMA_NA_APLICACAO),
					id: CampoIndicePFs.COD_TURMA_NA_APLICACAO.id,
					dica: this.getCfg("LABEL_TURMA"),
					propId: "id",
					propLabel: "descricao",
					multiplo: true,
					loadOpcoesBack: {
						endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
						payload: {
							cfgsRecuperacaoFiltrosTO: {
								campoIndicePFs: CampoIndicePFs.NOME_TURMA_NA_APLICACAO,
								restricoesListagensPF: cfgsFiltrosPFs.restricoesListagensPF
							}
						},
						numMinimoCaracteres: 0,
					}
				});
			}

			if (!cfgsFiltrosPFs.filtrosSuprimidos?.includes(CampoIndicePFs.COD_USUARIO)) {
				await this.addSelect({
					collection: getCollection(CampoIndicePFs.COD_USUARIO),
					valor: getIds(CampoIndicePFs.COD_USUARIO),
					id: CampoIndicePFs.COD_USUARIO.id,
					dica: this.getCfg("LABEL_ALUNO"),
					propId: "id",
					propLabel: "descricao",
					multiplo: true,
					loadOpcoesBack: {
						endpoint: "RelatorioFCD/recuperarListagemFiltroRespostas",
						payload: {
							cfgsRecuperacaoFiltrosTO: {
								campoIndicePFs: CampoIndicePFs.NOME_USUARIO,
								restricoesListagensPF: cfgsFiltrosPFs.restricoesListagensPF
							}
						},
						numMinimoCaracteres: 3,
					}
				});
			}

			if (!cfgsFiltrosPFs.suprimirCamposOpcionais) {
				await this.addSelect({
					id: "camposOpcionaisParaExibicao",
					collection: this.getListaColunasOpcionais(),
					valor: null,
					dica: this.getMsg("FP_FRONT_DataWarehouseVH_003"),
					propId: "id",
					propLabel: "nome",
					multiplo: true
				});
			}
		}

		if (!cfgsFiltrosPFs.esconderFiltroBuscaTextual) {
			this.addCampoTexto({
				id: "palavra_chave",
				dica: this.getMsg("MSG_VH_RL_50"),
				prefixo: "<i class='fa fa-search'></i> ",
				onEnter: aplicarFiltros,
			});
		}
		
		this.addCamposPeriodo({
			dica: this.getMsg("FP_FRONT_DataWarehouseVH_015"),
			idInicio: "periodo_inicio",
			idFim: "periodo_fim",
			labelFim: this.getMsg("MSG_VH_RL_49"),
			valorInicio: valorDataInicio,
			valorFim: valorDataFim,
			onChange: aplicarFiltros,
		});

		this.addEspacamentoHorizontal("1px");

		this.addCheckbox({
			id: CampoIndicePFs.IS_ANULADA.id,
			label: CampoIndicePFs.IS_ANULADA.getNome(),
			valor: getValorBoolean(CampoIndicePFs.IS_ANULADA),
		});
		this.addCheckbox({
			id: CampoIndicePFs.IS_ARQUIVADA.id,
			label: CampoIndicePFs.IS_ARQUIVADA.getNome(),
			valor: getValorBoolean(CampoIndicePFs.IS_ARQUIVADA),
			css: "display: none"
		});
		this.addCheckbox({
			id: CampoIndicePFs.IS_USUARIO_ARQUIVADO.id,
			label: CampoIndicePFs.IS_USUARIO_ARQUIVADO.getNome(),
			valor: getValorBoolean(CampoIndicePFs.IS_USUARIO_ARQUIVADO),
			css: "display: none"
		});
		this.addCheckbox({
			id: CampoIndicePFs.IS_APLICACAO_ARQUIVADA.id,
			label: CampoIndicePFs.IS_APLICACAO_ARQUIVADA.getNome(),
			valor: getValorBoolean(CampoIndicePFs.IS_APLICACAO_ARQUIVADA),
			css: "display: none"
		});
		this.addCheckbox({
			id:  "EXIBIR_EXCLUIDOS",
			label: this.getMsg("FP_FRONT_DataWarehouseVH_009"),
			valor: getValorBoolean(CampoIndicePFs.IS_USUARIO_ARQUIVADO) ||getValorBoolean(CampoIndicePFs.IS_APLICACAO_ARQUIVADA) || getValorBoolean(CampoIndicePFs.IS_ARQUIVADA),
			onChange: () => {
				const exibirExcluidos = this.getValor("EXIBIR_EXCLUIDOS");
				this.setValor(CampoIndicePFs.IS_USUARIO_ARQUIVADO.id, exibirExcluidos);
				this.setValor(CampoIndicePFs.IS_APLICACAO_ARQUIVADA.id, exibirExcluidos);
				this.setValor(CampoIndicePFs.IS_ARQUIVADA.id, exibirExcluidos);
			}
		});
		if (cfgsFiltrosPFs.suprimirExibirTodasTentativas !== true) {
			this.addCheckbox({
				id: "isExibirSomentePFDaAU",
				label: this.getMsg("FP_FRONT_DataWarehouseVH_016"),
				valor: !cfgsConsultaIndicePFsTO.isExibirSomentePFDaAU
			});
		}
		if (cfgsFiltrosPFs.suprimirExibirNotasEPontuacoes !== true) {
			this.addCheckbox({
				id: "isExibirNotasEPontuacoes",
				label: this.getMsg("MSG_VH_RL_75"),
				valor: cfgsFiltrosPFs.valorIsExibirNotasEPontuacoes
			});
		}

		this.append(`
			<div class='col-md-12'>
				<div msg-erro class='alert alert-danger' role='alert' style='display: none; width: 100%; margin-top: 10px'>
				</div>
			</div>
			<div class='col-md-12'>
				<div class='col-md-6 no-padding'>
				</div>
				<div class='col-md-6 no-padding'>
		`);

		this.addBotao({
			label: this.getMsg("MSG_VH_RL_37"),
			classe: "btn-primary pull-right",
			onClick: aplicarFiltros
		});

		this.append(`
				</div>
			</div>
		`);
		
		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		this.append("</div>");
	}

	getListaColunasOpcionais() {

		if (this.listaInfosAvaliados == null) {
			this.listaInfosAvaliados = [
				CampoIndicePFs.CPF, CampoIndicePFs.EMAIL, CampoIndicePFs.LOGIN, CampoIndicePFs.DATA_NASCIMENTO, 
				CampoIndicePFs.TELEFONE, CampoIndicePFs.RG, CampoIndicePFs.MATRICULA, CampoIndicePFs.ENDERECO, 
				CampoIndicePFs.CIDADE, CampoIndicePFs.CEP, CampoIndicePFs.UF, CampoIndicePFs.NOME_MAE, 
				CampoIndicePFs.CODIGO_USUARIO_SISTEMA_ORIGEM, CampoIndicePFs.APELIDO, CampoIndicePFs.NOME_TURMA_NA_APLICACAO,
				CampoIndicePFs.NOME_EMPRESA_USUARIO, CampoIndicePFs.NUM_SAIDAS_NAVEGADOR, CampoIndicePFs.APROVEITAMENTO,
				CampoIndicePFs.NUM_DISCURSIVAS_PENDENTES
			].map(c => {
				return {
					id: c.id,
					nome: c.getNome()
				}
			}).sort((a, b) => a.nome.localeCompare(b.nome));
		}

		return this.listaInfosAvaliados;
	}
	
	addFiltro(to: FiltrosIndiceRespostasTO, c: CampoRelRespostas, ids: any, tos?: ListaTO[]) {
		
		if (!c) return;

		if (ids === null || ids === undefined || ids === "") return;
		
		if (!Array.isArray(ids)) ids = [String(ids)];
		
		if (!ids.some(v => v !== null && v !== undefined)) return;

		to.ativos[c.id] = to.ativos[c.id] || [];
		to.ativosListaTO[c.id] = to.ativosListaTO[c.id] || [];

		to.ativos[c.id] = to.ativos[c.id].concat(
			ids.filter(v => !to.ativos[c.id].includes(v))
		);

		const idsTOs = to.ativosListaTO[c.id].map(v => v.id);

		if (tos?.length > 0) {
			to.ativosListaTO[c.id] = to.ativosListaTO[c.id].concat(
				tos.filter(v => !idsTOs.includes(v.id))
			);
		} else {
			to.ativosListaTO[c.id] = to.ativosListaTO[c.id].concat(
				ids.filter(v => !idsTOs.includes(v)).map(v => {
					return { id: v, text: v }
				})
			);
		}
	}
	
	getFiltroId(to: FiltrosIndiceRespostasTO, c: CampoRelRespostas, indice: number) {
		if (!Array.isArray(to.ativos[c.id])) return null;
		if (!to.ativos[c.id][indice]) return null;
		return to.ativos[c.id][indice];
	}

	getFiltroArrayIds(to: FiltrosIndiceRespostasTO, c: CampoRelRespostas) {
		if (!Array.isArray(to.ativos[c.id])) return null;
		return to.ativos[c.id].concat([]); // só para não retornar o array original
	}

	getFiltroArrayListaTO(to: FiltrosIndiceRespostasTO, c: CampoRelRespostas) {
		if (!Array.isArray(to.ativosListaTO[c.id])) return null;
		return to.ativosListaTO[c.id].concat([]); // só para não retornar o array original
	}
	
	verificar(cfgsRelProvasComRecursoTO: CfgsRelIndiceRespostasTO, campoAgrupamentoDefault = CampoRelRespostas.COD_RESPOSTA_QUESTAO, campoOrdenacaoDefault?: string): CfgsRelIndiceRespostasTO {

		const cfgs = cfgsRelProvasComRecursoTO ?? {};
		
		cfgs.campoAgrupamento = cfgs.campoAgrupamento ?? campoAgrupamentoDefault.id;
		cfgs.campoOrdenacao = cfgs.campoOrdenacao ?? campoOrdenacaoDefault;
		cfgs.isOrdenacaoDecrescente = cfgs.isOrdenacaoDecrescente ?? false;

		cfgs.filtrosTO = cfgs.filtrosTO ?? {};
		cfgs.filtrosTO.ativos = cfgs.filtrosTO.ativos ?? {};
		cfgs.filtrosTO.ativosListaTO = cfgs.filtrosTO.ativosListaTO ?? {};

		return cfgs;
	}

}

type CfgsRelIndiceRespostasTO = {
	campoAgrupamento?: string;
	campoOrdenacao?: string;
	isOrdenacaoDecrescente?: boolean;
	paginacaoTO?: PaginacaoTO;
	filtrosTO?: FiltrosIndiceRespostasTO;
}

type CfgsFiltrosIndiceRespostas = {
	campoAgrupamento: string;
	filtrosTO: FiltrosIndiceRespostasTO;
	onAplicarFiltros: Function;
	isExibirFiltrosCDs?: boolean;
	isExibirBuscaTextual?: boolean;
	isExibirNumQuestaoNaProva?: boolean;
	isExibirCodRecurso?: boolean;
	omitidos?: string[];
	desabilitados?: string[];
}

type FiltrosIndiceRespostasTO = {
	ativos?: { [key: string]: string[] };
	ativosListaTO?: { [key: string]: ListaTO[] };
	situacaoCD?: SituacaoCD;
	buscaTextual?: string;
}
class TipoIndice extends EnumFP { 

	static QUESTOES = new TipoIndice("QUESTOES");
	static RESPOSTAS = new TipoIndice("RESPOSTAS");
	static PFS = new TipoIndice("PFS");

	constructor(id: string) {
		super(TipoIndice.name, id, "");
	}
}

class CampoRelRespostas extends EnumFP { 

	static COD_RESPOSTA_QUESTAO = new CampoRelRespostas("COD_RESPOSTA_QUESTAO", "");

	static COD_PROVA = new CampoRelRespostas("COD_PROVA", "MSG_VH_RAE_03", cadastroProvaVH.exibirAbasProva, "5");
	static NOME_PROVA = new CampoRelRespostas("NOME_PROVA", "MSG_VH_RAE_03", cadastroProvaVH.exibirAbasProva, "5");
	static CODS_TURMAS_USUARIO = new CampoRelRespostas("CODS_TURMAS_USUARIO", () => amaisVH.getCfg("LABEL_TURMA"));
	static NOMES_TURMAS_USUARIO = new CampoRelRespostas("NOMES_TURMAS_USUARIO", () => amaisVH.getCfg("LABEL_TURMA"));
	static CODS_DISCIPLINAS = new CampoRelRespostas("CODS_DISCIPLINAS", "MSG_VH_RAE_34");
	static NOMES_DISCIPLINAS = new CampoRelRespostas("NOMES_DISCIPLINAS", "MSG_VH_RAE_34");
	static CODS_USUARIOS_CORRETORES = new CampoRelRespostas("CODS_USUARIOS_CORRETORES", "FP_FRONT_CorrecaoDiscursivasVH_041");
	static NOMES_USUARIOS_CORRETORES = new CampoRelRespostas("NOMES_USUARIOS_CORRETORES", "FP_FRONT_CorrecaoDiscursivasVH_041");
	static COD_QUESTAO = new CampoRelRespostas("COD_QUESTAO", "MSG_VH_RD_16");
	static CONTEUDO_QUESTAO = new CampoRelRespostas("CONTEUDO_QUESTAO", "MSG_VH_RD_21", (codQuestao) => exibicaoQuestaoVH.exibirQuestaoEmPopup(codQuestao));
	static COD_EMPRESA_AGENDAMENTO = new CampoRelRespostas("COD_EMPRESA_AGENDAMENTO", "");
	static NOME_EMPRESA_AGENDAMENTO = new CampoRelRespostas("NOME_EMPRESA_AGENDAMENTO", "");
	static COD_EMPRESA_USUARIO = new CampoRelRespostas("COD_EMPRESA_USUARIO", "");
	static NOME_EMPRESA_USUARIO = new CampoRelRespostas("NOME_EMPRESA_USUARIO", "");
	static COD_USUARIO = new CampoRelRespostas("COD_USUARIO", "", cadastroUsuarioVH.editarUsuario);
	static NOME_USUARIO = new CampoRelRespostas("NOME_USUARIO", () => amaisVH.getCfg("LABEL_ALUNO"), cadastroUsuarioVH.editarUsuario);
	static COD_TURMA_NA_APLICACAO = new CampoRelRespostas("COD_TURMA_NA_APLICACAO", "", cadastroTurmaVH.exibirAbasTurma, "0");
	static NOME_TURMA_NA_APLICACAO = new CampoRelRespostas("NOME_TURMA_NA_APLICACAO", "", cadastroTurmaVH.exibirAbasTurma, "0");
	static COD_AGENDAMENTO = new CampoRelRespostas("COD_AGENDAMENTO", "MSG_VH_RD_11");
	static NOME_AGENDAMENTO = new CampoRelRespostas("NOME_AGENDAMENTO", "MSG_VH_RD_11");
	static COD_RECURSO = new CampoRelRespostas("COD_RECURSO", "FP_FRONT_DataWarehouseVH_007");

	static ACERTOS = new CampoRelRespostas("ACERTOS", "");
	static ERROS = new CampoRelRespostas("ERROS", "");
	static EM_BRANCO = new CampoRelRespostas("EM_BRANCO", "");
	static ANULADAS = new CampoRelRespostas("ANULADAS", "");
	static APROVEITAMENTO = new CampoRelRespostas("APROVEITAMENTO", "");
	static PONTUACAO_MAXIMA = new CampoRelRespostas("PONTUACAO_MAXIMA", "");
	static PONTUACAO_OBTIDA = new CampoRelRespostas("PONTUACAO_OBTIDA", "");

	static NUM_PFS = new CampoRelRespostas("NUM_PFS", "");
	static NUM_RESPOSTAS = new CampoRelRespostas("NUM_RESPOSTAS", "");
	static NUM_CORRECOES_PENDENTES = new CampoRelRespostas("NUM_CORRECOES_PENDENTES", "");
	static NUM_QUESTAO_NA_PROVA = new CampoRelRespostas("NUM_QUESTAO_NA_PROVA", "");

	static DATA_INICIO_PF = new CampoRelRespostas("DATA_INICIO_PF", "", null, null, true);
	static DATA_ENVIO_DISCURSIVA = new CampoRelRespostas("DATA_ENVIO_DISCURSIVA", "", null, null, true);
	static DATA_CORRECAO = new CampoRelRespostas("DATA_CORRECAO", "", null, null, true);
	static DATA_RECURSO = new CampoRelRespostas("DATA_RECURSO", "", null, null, true);
	static DATA_RESPOSTA_RECURSO = new CampoRelRespostas("DATA_RESPOSTA_RECURSO", "", null, null, true);


	readonly onEdicao: Function;
	readonly onEdicaoParam1: string;
	readonly isData: boolean;

	constructor(id: string, idMsg: string | Function, onEdicao?: Function, onEdicaoParam1?: string, isData?: boolean) {
		super(CampoRelRespostas.name, id, idMsg);
		this.onEdicao = onEdicao;
		this.onEdicaoParam1 = onEdicaoParam1;
		this.isData = isData ?? false;
	}

	static from(id: string): CampoRelRespostas {
		return <CampoRelRespostas> EnumFP.from(CampoRelRespostas.name, id);
	}
	
	static fromNomeProp(prop: string): CampoRelRespostas {
		const id = prop.replace(/([A-Z]+)/g, "_$1").toUpperCase();
		return CampoRelRespostas.from(id);
	}
	
	static values(): CampoRelRespostas[] {
		return <CampoRelRespostas[]> EnumFP.values(CampoRelRespostas.name);
	}
}

class CampoIndicePFs extends EnumFP { 

	static COD_EMPRESA_AGENDAMENTO = new CampoIndicePFs("COD_EMPRESA_AGENDAMENTO", "");
	static NOME_EMPRESA_AGENDAMENTO = new CampoIndicePFs("NOME_EMPRESA_AGENDAMENTO", "");
	static COD_EMPRESA_USUARIO = new CampoIndicePFs("COD_EMPRESA_USUARIO", "");
	static NOME_EMPRESA_USUARIO = new CampoIndicePFs("NOME_EMPRESA_USUARIO", "FP_FRONT_RelatorioProvasVH_004");
	static COD_USUARIO = new CampoIndicePFs("COD_USUARIO", "", UtilHash.getHash(cadastroUsuarioVH.editarUsuario));
	static NOME_USUARIO = new CampoIndicePFs("NOME_USUARIO", "", UtilHash.getHash(cadastroUsuarioVH.editarUsuario));
	static CPF = new CampoIndicePFs("CPF", "MSG_VH_RP_30");
	static EMAIL = new CampoIndicePFs("EMAIL", "FP_FRONT_RelatorioProvasVH_009");
	static LOGIN = new CampoIndicePFs("LOGIN", "FP_FRONT_RelatorioProvasVH_005");
	static DATA_NASCIMENTO = new CampoIndicePFs("DATA_NASCIMENTO", "FP_FRONT_RelatorioProvasVH_010");
	static TELEFONE = new CampoIndicePFs("TELEFONE", "FP_FRONT_RelatorioProvasVH_012");
	static RG = new CampoIndicePFs("RG", "FP_FRONT_RelatorioProvasVH_013");
	static MATRICULA = new CampoIndicePFs("MATRICULA", "MSG_VH_RP_31");
	static ENDERECO = new CampoIndicePFs("ENDERECO", "FP_FRONT_RelatorioProvasVH_014");
	static CIDADE = new CampoIndicePFs("CIDADE", "FP_FRONT_RelatorioProvasVH_015");
	static CEP = new CampoIndicePFs("CEP", "FP_FRONT_RelatorioProvasVH_016");
	static UF = new CampoIndicePFs("UF", "FP_FRONT_RelatorioProvasVH_017");
	static NOME_MAE = new CampoIndicePFs("NOME_MAE", "FP_FRONT_RelatorioProvasVH_018");
	static APELIDO = new CampoIndicePFs("APELIDO", "FP_FRONT_RelatorioProvasVH_019");
	static CODIGO_USUARIO_SISTEMA_ORIGEM = new CampoIndicePFs("CODIGO_USUARIO_SISTEMA_ORIGEM", "FP_FRONT_RelatorioProvasVH_011");
	static IS_USUARIO_ARQUIVADO = new CampoIndicePFs("IS_USUARIO_ARQUIVADO", "FP_FRONT_RelatorioProvasVH_113");
	static IS_APLICACAO_ARQUIVADA = new CampoIndicePFs("IS_APLICACAO_ARQUIVADA", "FP_FRONT_RelatorioProvasVH_115");
	static COD_AGENDAMENTO = new CampoIndicePFs("COD_AGENDAMENTO", "MSG_VH_RP_25");
	static COD_AGENDAMENTO_USUARIO = new CampoIndicePFs("COD_AGENDAMENTO_USUARIO", "");
	static TIPO_APLICACAO = new CampoIndicePFs("TIPO_APLICACAO", "MSG_VH_RP_149");
	static NOME_APLICACAO = new CampoIndicePFs("NOME_APLICACAO", "MSG_VH_RP_25");
	static COD_PROVA = new CampoIndicePFs("COD_PROVA", "MSG_VH_RAE_03", UtilHash.getHash(cadastroProvaVH.exibirAbasProva, "5"));
	static NOME_PROVA = new CampoIndicePFs("NOME_PROVA", "MSG_VH_RAE_03", UtilHash.getHash(cadastroProvaVH.exibirAbasProva, "5"));
	static COD_PROVA_FEITA = new CampoIndicePFs("COD_PROVA_FEITA", "MSG_VH_RAE_03", UtilHash.getHash(cadastroProvaVH.exibirAbasProva, "5"));
	static COD_TURMA_NA_APLICACAO = new CampoIndicePFs("COD_TURMA_NA_APLICACAO", "", UtilHash.getHash(cadastroTurmaVH.exibirAbasTurma, "0"));
	static NOME_TURMA_NA_APLICACAO = new CampoIndicePFs("NOME_TURMA_NA_APLICACAO", () => amaisVH.getCfg("LABEL_TURMA"), UtilHash.getHash(cadastroTurmaVH.exibirAbasTurma, "0"));
	static DATA_INICIO_PF = new CampoIndicePFs("DATA_INICIO_PF", "");
	static DATA_FIM_PF = new CampoIndicePFs("DATA_FIM_PF", "");
	static PONTUACAO_OBTIDA = new CampoIndicePFs("PONTUACAO_OBTIDA", "");
	static PONTUACAO_MAXIMA = new CampoIndicePFs("PONTUACAO_MAXIMA", "");
	static APROVEITAMENTO = new CampoIndicePFs("APROVEITAMENTO", "FP_FRONT_DataWarehouseVH_004", null, "%");
	static NOTA = new CampoIndicePFs("NOTA", "");
	static NOTA_MAXIMA = new CampoIndicePFs("NOTA_MAXIMA", "");
	static IS_ANULADA = new CampoIndicePFs("IS_ANULADA", "FP_FRONT_RelatorioProvasVH_002");
	static IS_ARQUIVADA = new CampoIndicePFs("IS_ARQUIVADA", "FP_FRONT_RelatorioProvasVH_003");
	static NUM_DISCURSIVAS_PENDENTES = new CampoIndicePFs("NUM_DISCURSIVAS_PENDENTES", "FP_FRONT_DataWarehouseVH_005");
	static NUM_TENTATIVAS_NA_APLICACAO = new CampoIndicePFs("NUM_TENTATIVAS_NA_APLICACAO", "");
	static NUM_SAIDAS_NAVEGADOR = new CampoIndicePFs("NUM_SAIDAS_NAVEGADOR", "FP_FRONT_DataWarehouseVH_002");
	static IS_TEVE_PROBLEMA_FACEMATCH = new CampoIndicePFs("IS_TEVE_PROBLEMA_FACEMATCH", "");
	static DATA_ENVIO_CORRECAO = new CampoIndicePFs("DATA_ENVIO_CORRECAO", "");
	static IS_ATINGIU_PONTUACAO_MINIMA = new CampoIndicePFs("IS_ATINGIU_PONTUACAO_MINIMA", "");
	static DATA_ENTREGA_CERTIFICADO = new CampoIndicePFs("DATA_ENTREGA_CERTIFICADO", "");
	
	static PONTUACAO_MEDIA = new CampoIndicePFs("PONTUACAO_MEDIA", "");
	static NOTA_MEDIA = new CampoIndicePFs("NOTA_MEDIA", "");
	static APROVEITAMENTO_MEDIO = new CampoIndicePFs("APROVEITAMENTO_MEDIO", "");
	static NUM_PROVAS_RESPONDIDAS = new CampoIndicePFs("NUM_PROVAS_RESPONDIDAS", "");
	static DATA_ULTIMO_FIM_PF = new CampoIndicePFs("DATA_ULTIMO_FIM_PF", "");

	readonly hashEdicao: string[];
	readonly formato: string;

	constructor(id: string, idMsg: string | Function, hashEdicao?: string, formato?: string) {
		super(CampoIndicePFs.name, id, idMsg);
		this.hashEdicao = hashEdicao?.startsWith("#") ? hashEdicao.substring(2).split("/") : null;
		this.formato = formato;
	}

	static from(id: string): CampoIndicePFs {
		return <CampoIndicePFs> EnumFP.from(CampoIndicePFs.name, id);
	}
	
	static fromNomeProp(prop: string): CampoIndicePFs {
		const id = prop.replace(/([A-Z]+)/g, "_$1").toUpperCase();
		return CampoIndicePFs.from(id);
	}

	getHashEdicao() {
		if (!this.hashEdicao) return null;
		return "#" + this.hashEdicao.join("/");
	}
}

class CfgsConsultaIndicePFsTO {
	campoAgrupamento?: CampoIndicePFs;
	camposOpcionaisParaExibicao?: CampoIndicePFs[];
	paginacaoTO?: PaginacaoTO;
	filtrosAtivos?: any;
	buscaTextualNoNomeDoAgrupamento?: string;
	campoOrdenacao?: string;
	isOrdenacaoDecrescente?: boolean;
	tosFiltrosAtivos?: any;
	grupoDetalhamentoTO?: GrupoDetalhamentoTO;
	isExibirSomentePFDaAU?: boolean = true;
}

class GrupoDetalhamentoTO {
	cod: number;
	nome: string;
}


enum SituacaoCD {
	AGUARDANDO_CORRECAO = "AGUARDANDO_CORRECAO",
	CORRIGIDA = "CORRIGIDA",
	TODAS = "TODAS"
}

class CfgsFiltrosPFs {
	onAplicarFiltros: Function;
	cfgsConsultaIndicePFsTO: CfgsConsultaIndicePFsTO;
	restricoesListagensPF?: RestricaoFiltroPFTO[];
	filtrosSuprimidos?: CampoIndicePFs[];
	suprimirExibirNotasEPontuacoes?: boolean = false;
	suprimirExibirTodasTentativas?: boolean = true;
	valorIsExibirNotasEPontuacoes?: boolean = true;
	suprimirCamposOpcionais?: boolean = false;
	esconderFiltroBuscaTextual?: boolean = false;
}
class RestricaoFiltroPFTO {
	campo: CampoIndicePFs;
	valores: any[];
}