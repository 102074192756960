class AplicacaoAbertaVH extends AmaisVH {
	
	listaRecuperacaoAplicacaoAbertaAgendamentoTO: any;
	listaRecuperacaoAplicacaoAbertaTurmaTO: any;

	constructor() {
		super("AplicacaoAbertaVH");
		this.addOperacaoParaHash("aali", this.listar);
		this.addOperacaoParaHash("aaed", this.editar);
	}

	async listar() {

		UtilHash.registrarHistorico(this.listar);

		const listaListagemAplicacaoAbertaTO = await this.call("AplicacaoAbertaFCD/listar")

		this.limpar();

		this.setTitulo(this.getMsg("FP_FRONT_AplicacaoAbertaVH_001"));

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_002"), prop: "nome", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_003"), prop: "tokenIdentificacao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_004"), prop: (listagemAplicacaoAbertaTO) => listagemAplicacaoAbertaTO.listaListagemAplicacaoAbertaAgendamentoTO.map(listagemAplicacaoAbertaAgendamentoTO => {
				const h = [listagemAplicacaoAbertaAgendamentoTO.tituloProva, " <small>"];
				if (listagemAplicacaoAbertaAgendamentoTO.dataFimAplicacao) {
					h.push(this.getMsg("FP_FRONT_AplicacaoAbertaVH_006", UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaAgendamentoTO.dataInicioAplicacao), UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaAgendamentoTO.dataFimAplicacao)));
				} else {
					h.push(this.getMsg("FP_FRONT_AplicacaoAbertaVH_007", UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaAgendamentoTO.dataInicioAplicacao)))
				}
				h.push("</small>");
				return h.join("");
			}).join("<br>")
		});
		colunas.push({titulo: this.getCfg("LABEL_LISTAGEM_TURMAS"), prop: (listagemAplicacaoAbertaTO) => {
			const turmas = listagemAplicacaoAbertaTO.listaListagemAplicacaoAbertaTurmaTO;
			if (turmas.length > 3) {
				return `
						<small title="${turmas.map(turma => `${turma.nome} - <small>${turma.segmento}</small>`).join('&lt;br&gt;')}">
							${turmas.length} ${this.getCfg("LABEL_LISTAGEM_TURMAS")}
						</small>
					`;
			}
			return turmas.map(listagemAplicacaoAbertaTurmaTO => {
				return `${listagemAplicacaoAbertaTurmaTO.nome} <small>${listagemAplicacaoAbertaTurmaTO.segmento}</small>`
			}).join("<br>")
		}});
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_035"), prop: (listagemAplicacaoAbertaTO) => {
			if (listagemAplicacaoAbertaTO.dataInicioVigencia && listagemAplicacaoAbertaTO.dataFimVigencia) {
				return this.getMsg("FP_FRONT_AplicacaoAbertaVH_025", UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaTO.dataInicioVigencia), UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaTO.dataFimVigencia))
			}
			if (listagemAplicacaoAbertaTO.dataInicioVigencia) {
				return this.getMsg("FP_FRONT_AplicacaoAbertaVH_007", UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaTO.dataInicioVigencia))
			}
			if (listagemAplicacaoAbertaTO.dataFimVigencia) {
				return this.getMsg("FP_FRONT_AplicacaoAbertaVH_034", UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaTO.dataFimVigencia));
			}
			return  '';
		}});

		await this.addTabela({
			collection: listaListagemAplicacaoAbertaTO,
			colunas: colunas,
			labelNovo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_005"),
			propId: "codAplicacaoAberta",
			onEdicao: async (codAplicacaoAberta) => await this.editar(codAplicacaoAberta)
		});

		this.addBotao({ label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_008"), onClick: () => UtilHash.voltar() });

		this.exibir();
	}

	async editar(codAplicacaoAberta) {

		UtilHash.registrarHistorico(this.editar, codAplicacaoAberta);

		let recuperacaoAplicacaoAbertaTO = await this.call("AplicacaoAbertaFCD/recuperar", codAplicacaoAberta);

		recuperacaoAplicacaoAbertaTO = recuperacaoAplicacaoAbertaTO || {};

		this.limpar();
		this.setTitulo(recuperacaoAplicacaoAbertaTO.nome || this.getMsg("FP_FRONT_AplicacaoAbertaVH_009"))
		this.setSubtitulo(recuperacaoAplicacaoAbertaTO.nome ? this.getMsg("FP_FRONT_AplicacaoAbertaVH_010") : null);

		this.addCampoTexto({
			id: "nome",
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_011"),
			valor: recuperacaoAplicacaoAbertaTO.nome,
			obrigatorio: true,
			classe: "col-md-4"
		});
		this.addCampoTexto({
			id: "tokenIdentificacao",
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_012"),
			prefixo: recuperacaoAplicacaoAbertaTO.urlExterna,
			valor: recuperacaoAplicacaoAbertaTO.tokenIdentificacao,
			obrigatorio: true,
			classe: "col-md-4",
			onChange: async () => {
				var token = this.getValor("tokenIdentificacao");
				const isTokenDisponivel = await this.call("AplicacaoAbertaFCD/verificarTokenDisponivel", codAplicacaoAberta, token);

				if (!isTokenDisponivel) {
					await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AplicacaoAbertaVH_013", token) });
				}
			}
		});
		this.addCampoTexto({
			id: "tituloPagina",
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_014"),
			valor: recuperacaoAplicacaoAbertaTO.tituloPagina,
			obrigatorio: true,
			classe: "col-md-4"
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_036"),
			classe: "col-md-4",
			id: "inicioPeriodoVigenciaLink",
			tipo: "DATAHORA",
			valor: recuperacaoAplicacaoAbertaTO.inicioPeriodoVigencia,
		});

		this.addCampoTexto({
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_037"),
			classe: "col-md-4",
			id: "fimPeriodoVigenciaLink",
			tipo: "DATAHORA",
			valor: recuperacaoAplicacaoAbertaTO.fimPeriodoVigencia,
		});

		this.addEditorHTML({
			id: "textoApresentacao",
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_015"),
			valor: recuperacaoAplicacaoAbertaTO.textoApresentacao,
			obrigatorio: true,
			classe: "col-md-8"
		});
		this.addCheckbox({
			id: "isIgnorarEmailBoasVindas",
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_040"),
			valor: recuperacaoAplicacaoAbertaTO.isIgnorarEmailBoasVindas !== true,
			obrigatorio: true,
			classe: "col-md-4",
			css: "margin-top: 25px"
		});
		this.append("<div id='listagem_aplicacoes_online'></div>");
		this.append("<div id='listagem_turmas'></div>");

		this.addBotao({ label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_016"), onClick: () => UtilHash.voltar() });

		this.append("<div class='btn-group pull-right'>");
		this.addBotao({
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_017"),
			classe: "btn-danger",
			onClick: async () => {
				await this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_AplicacaoAbertaVH_018"),
					botoes: [{
						label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_019"),
						classe: "btn-danger",
						onClick: async () => {
							await this.call("AplicacaoAbertaFCD/remover", codAplicacaoAberta);
							this.listar();
						}
					}]
				});
			}
		});
		this.addBotao({
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_020"),
			classe: "btn-primary",
			onClick: async () => {
				this.verificarObrigatorios();

				const salvamentoAplicacaoAbertaTO = {
					codAplicacaoAberta: codAplicacaoAberta,
					nome: this.getValor("nome"),
					tituloPagina: this.getValor("tituloPagina"),
					textoApresentacao: this.getValor("textoApresentacao"),
					isIgnorarEmailBoasVindas: this.getValor("isIgnorarEmailBoasVindas") !== true,
					tokenIdentificacao: this.getValor("tokenIdentificacao"),
					codsAgendamentos: this.getValor("codsAgendamentos"),
					turmas: new Map(),
					inicioPeriodoVigencia: this.getValor("inicioPeriodoVigenciaLink"),
					fimPeriodoVigencia: this.getValor("fimPeriodoVigenciaLink"),
				}

				$("table#codsTurmas tbody tr").each((_, tr) => {
					const codTurma = tr.getAttribute("identificador");
					if (!codTurma) return;
					const isPropagarInscricaoAplicacoes = $(tr).find("input").is(":checked");
					salvamentoAplicacaoAbertaTO.turmas.set(Number(codTurma), isPropagarInscricaoAplicacoes);
				});

				await this.call("AplicacaoAbertaFCD/salvar", salvamentoAplicacaoAbertaTO);

				this.listar();
			}
		});
		this.append("</div>");

		this.exibir();
		this.listaRecuperacaoAplicacaoAbertaAgendamentoTO = recuperacaoAplicacaoAbertaTO.listaRecuperacaoAplicacaoAbertaAgendamentoTO || [];
		await this.atualizarListagemAplicacoesOnline();

		this.listaRecuperacaoAplicacaoAbertaTurmaTO = recuperacaoAplicacaoAbertaTO.listaRecuperacaoAplicacaoAbertaTurmaTO || [];
		await this.atualizarListagemTurmasLink();
	}

	async atualizarListagemAplicacoesOnline() {

		$("#listagem_aplicacoes_online").html("");
		this.setIdTarget("listagem_aplicacoes_online");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_022"), prop: "tituloProva", classe: "descricao"});
		colunas.push({titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_023"), prop: (listagemAplicacaoAbertaAgendamentoTO) => {
				const hash = UtilHash.getHash(cadastroProvaVH.exibirAbasProva, 3, listagemAplicacaoAbertaAgendamentoTO.codProva, null, null, null, listagemAplicacaoAbertaAgendamentoTO.codAgendamento);
				let texto = this.getMsg("FP_FRONT_AplicacaoAbertaVH_024", UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaAgendamentoTO.dataInicioAplicacao));
				if (listagemAplicacaoAbertaAgendamentoTO.dataFimAplicacao) {
					texto = this.getMsg("FP_FRONT_AplicacaoAbertaVH_025", UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaAgendamentoTO.dataInicioAplicacao), UtilData.toDDMMYYYYHHMM(listagemAplicacaoAbertaAgendamentoTO.dataFimAplicacao));
				}
				return "<a href='" + hash + "'>" + texto + "</a>";
			}
		});

		await this.addTabela({
			titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_021"),
			id: "codsAgendamentos",
			collection: this.listaRecuperacaoAplicacaoAbertaAgendamentoTO,
			propId: "codAgendamento",
			colunas: colunas,
			onExclusao: async (botao, codAgendamento) => {
				var $tr = $(botao).closest("tr");
				$tr.fadeOut("slow", () => $tr.remove());
				this.listaRecuperacaoAplicacaoAbertaAgendamentoTO = this.listaRecuperacaoAplicacaoAbertaAgendamentoTO.filter(to => to.codAgendamento != codAgendamento);
			},
			msgListaVazia: this.getMsg("FP_FRONT_AplicacaoAbertaVH_026"),
			labelNovo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_027"),
			onNovo: async () => await this.onAdicionarAgendamentos()
		});

		this.exibir();
	}

	async atualizarListagemTurmasLink() {
		$("#listagem_turmas").html("");
		this.setIdTarget("listagem_turmas");

		await this.addTabela({
			collection: this.listaRecuperacaoAplicacaoAbertaTurmaTO,
			titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_030", this.getCfg("LABEL_LISTAGEM_TURMAS")),
			id: "codsTurmas",
			propId: "codTurma",
			colunas: [{
				titulo: this.getCfg("LABEL_TURMA"), 
				prop: "nome", 
				classe: "descricao"
			}, {
				titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_038"), 
				prop: (to) => {
					return `<input type="checkbox" ${to.isPropagarInscricaoAplicacoes ? 'checked' : ''} />`;
				}
			}, {
				titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_031"), 
				prop: "segmento"
			}],
			labelNovo: this.getCfg("LABEL_TURMA"),
			msgListaVazia: this.getMsg("FP_FRONT_AplicacaoAbertaVH_032", this.getCfg("LABEL_TURMA").toLowerCase()),
			onExclusao: async (botao, codTurma) => {
				const $tr = $(botao).closest("tr");
				$tr.fadeOut("slow", () => $tr.remove());
				this.listaRecuperacaoAplicacaoAbertaTurmaTO = this.listaRecuperacaoAplicacaoAbertaTurmaTO.filter(to => to.codTurma != codTurma);
			},
			onNovo: async () => await this.onAdicionarTurmas()
		})

		this.exibir();
	}

	async onAdicionarAgendamentos() {
		this.addPopup({
			titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_028"),
			botoes: [{
				label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_029"),
				classe: "btn-primary",
				onClick: async () => {

					const codsAgendamentos = this.getValor("codsAgendamentos").map((codAgendamento) => parseInt(codAgendamento));
					const tos = this.getTOSelectSelecionado("codsAgendamentosParaAdicionar");

					if (!tos || tos.length == 0) return;

					for (const to of tos) {
						if (codsAgendamentos.includes(to.codAgendamento)) continue;
						this.listaRecuperacaoAplicacaoAbertaAgendamentoTO.push({
							codProva: to.codProva,
							codAgendamento: to.codAgendamento,
							dataInicioAplicacao: to.dataAplicacao,
							dataFimAplicacao: to.dataFimAplicacao,
							tituloProva: to.nome
						});
					}

					await this.atualizarListagemAplicacoesOnline();
				}
			}]
		})
		await this.addSelect({
			id: "codsAgendamentosParaAdicionar",
			label: this.getMsg("MSG_VH_CU_155"),
			multiplo: true,
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarAplicacoes",
				payload: {
					inicioAplicacao: UtilData.getDate(2000, 1, 1), // para trazer só com fase de aplicação
					tipoAplicacao: TipoAplicacao.P,
				},
				numMinimoCaracteres: 3,
			}
		});
		this.exibirPopups();
	}

	async onAdicionarTurmas() {
		this.addPopup({
			titulo: this.getMsg("FP_FRONT_AplicacaoAbertaVH_033", this.getCfg("LABEL_LISTAGEM_TURMAS")),
			botoes: [{
				label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_029"),
				classe: "btn-primary",
				onClick: async () => {

					const codsTurmas = this.getValor("codsTurmas").map((codTurma) => parseInt(codTurma));
					const tos = this.getTOSelectSelecionado("codsTurmasParaAdicionar");
					const isPropagarInscricaoAplicacoes = this.getValor("isPropagarInscricaoAplicacoes");

					if (!tos || tos.length == 0) return;

					for (const to of tos) {
						if (codsTurmas.includes(to.id)) continue;
						this.listaRecuperacaoAplicacaoAbertaTurmaTO.push({
							codTurma: to.id,
							nome: to.nome,
							segmento: to.descricao,
							isPropagarInscricaoAplicacoes
						});
					}

					await this.atualizarListagemTurmasLink();
				}
			}]
		})
		await this.addSelect({
			id: "codsTurmasParaAdicionar",
			label: this.getCfg("LABEL_LISTAGEM_TURMAS"),
			multiplo: true,
			classe: "col-md-12",
			loadOpcoesBack: {
				endpoint: "ListagemSelecaoFCD/listarTurmas",
				numMinimoCaracteres: 3,
			}
		});
		this.addCheckbox({
			id: "isPropagarInscricaoAplicacoes",
			label: this.getMsg("FP_FRONT_AplicacaoAbertaVH_039"),
			classe: "col-md-12",
			valor: false
		})
		this.exibirPopups();
	}
}

const aplicacaoAbertaVH = new AplicacaoAbertaVH();
