
// if (vhResposta != null) {
// 	resposta = vhResposta.resposta;
// 	if (vhResposta.numNotificacoesNaoLidas != null) {
// 		amaisVH.atualizarSinalNotificacoes(vhResposta.numNotificacoesNaoLidas);
// 	}
// }

$(document).ready(() => {
	notificacaoVH.handleDocumentReady();
});
class NotificacaoVH extends AmaisVH {
	
	constructor() {
		super("NotificacaoVH");
	}

	handleDocumentReady() {
		$("body").on("click", ({target}) => {
			if ($(target).closest("[botao-notificacoes]").length == 0 && $(target).closest("[notificacao]").length == 0) {
				this.removerPopoverNotificacoes();
			}
		});

		$('#itensDeMenu [botao-notificacoes]').on("click", () => {
			this.onClickBotaoNotificacoes();
		});

		// funcionalidade retirada do ar perto de junho/2021 sem ninguém ter reclamado da ausência dela
		// setInterval(() => this.verificarSeHaNaoLidas(), 5 * 60 * 1000);
		// setTimeout(() => this.verificarSeHaNaoLidas(), 5000);
	}
	
	async verificarSeHaNaoLidas() {
		try {
			const numNotificacoesNaoLidas = await this.call({
				endpoint: "NotificacaoFCD/recuperarNumNaoLidas", 
				desativarMsgCarregando: true,
				onRequestError: async () => BackendRequestError.ERRO_TRATADO
			});

			this.atualizarSinalNotificacoes(numNotificacoesNaoLidas);
		} catch (ignored) {}
	}

	atualizarSinalNotificacoes(numNaoLidas) {
		numNaoLidas = numNaoLidas || 0;
		//		numNotificacoes = numNotificacoes || 0;

		if (numNaoLidas > 0) {
			$('#itensDeMenu [botao-notificacoes]').html("<span class='label label-danger' style='border-radius: 30px; padding: 5px 7px 5px 7px'>" + numNaoLidas + "</span>");
			//			
			//		} else if (numNotificacoes > 0) {
			//			$('#itensDeMenu [botao-notificacoes]').html("<i class='fa fa-bell'></i>");
			//			
		} else {
			//			$('#itensDeMenu [botao-notificacoes]').html("<i class='fa fa-bell-o'></i>");
			$('#itensDeMenu [botao-notificacoes]').html("<i class='fa fa-bell'></i>");
		}
	}
	
	async onClickBotaoNotificacoes() {

		if ($("[botao-notificacoes]").data("ativo")) {
			this.removerPopoverNotificacoes();
			return;
		}

		const listExibicaoNotificacaoTO = await this.call("NotificacaoFCD/listarUltimas");

		const html = ["<div class='list-group'>"];
		let numNotificacoes = 0;
		let numNaoLidas = 0;

		for (const exibicaoNotificacaoTO of listExibicaoNotificacaoTO) {
			let tag = "a"
			numNotificacoes++;

			if (exibicaoNotificacaoTO.isLida && !exibicaoNotificacaoTO.link) {
				tag = 'li'
			}
			if (!exibicaoNotificacaoTO.isLida) {
				numNaoLidas++;
			}
			let $texto = null;
			try {
				$texto = $(exibicaoNotificacaoTO.texto);
			} catch (e) {
				$texto = $("<span>" + exibicaoNotificacaoTO.texto + "</span>");
			}

			$texto.find("a,p,br").replaceWith(function() {
				return $(this).text();
			});

			const h = ['<' + tag + ' notificacao ' + (exibicaoNotificacaoTO.link ? 'href="' + exibicaoNotificacaoTO.link + '"' : "") + ' id-notificacao="' + exibicaoNotificacaoTO.id + '" class="list-group-item ' + (exibicaoNotificacaoTO.isLida ? 'list-group-item-disabled' : 'list-group-item-danger') + '">'];
			h.push('<button type="button" class="close" data-dismiss="popover" aria-label="Close">');
			h.push('<span aria-hidden="true" style="font-size: 66%">&times;</span>');
			h.push('</button>');
			h.push('<p class="list-group-item-text">' + $texto.html() + '<br>' + '<span style="display: block; text-align: right;"><i>Há ' + UtilData.getTempoUserFriendly(exibicaoNotificacaoTO.inicioExibicao) + '.</i></span></p>');
			h.push('</' + tag + '>');

			html.push(h.join(""));
		}

		if (numNotificacoes == 0) {
			html.push('<li class="list-group-item">' + this.getMsg("MSG_VH_A_10") + '</li>');
		}

		html.push("</div>");

		$("[botao-notificacoes]").popover({
			placement: "bottom",
			html: true,
			container: "body",
			content: html.join(""),
			template: '<div class="popover popover-sem-padding" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
		});

		$("[botao-notificacoes]").popover("show");
		$("[botao-notificacoes]").data("ativo", true);

		$("[notificacao] .close").off("click").on("click", ({target}) => {
			this.removerNotificacao($(target).closest("[notificacao]").attr("id-notificacao"));
		})

		if (numNaoLidas > 0) {
			try{
				await this.call({
					endpoint: "NotificacaoFCD/marcarTodasComoLidas",
					params: [],
					desativarMsgCarregando: true,
					onRequestError: async () => BackendRequestError.ERRO_TRATADO
				});
			} catch (ignored) {}
		}
	}

	removerPopoverNotificacoes() {
		if ($("[botao-notificacoes]").data("ativo")) {
			$("[botao-notificacoes]").data("ativo", false);
			$("[botao-notificacoes]").popover("destroy");
		}
	}

	async removerNotificacao(idNotificacao) {
		try {
			await this.call({
				endpoint: "NotificacaoFCD/marcar",
				params: [idNotificacao],
				desativarMsgCarregando: true,
				onRequestError: async () => BackendRequestError.ERRO_TRATADO
			});

			$("[notificacao][id-notificacao='" + idNotificacao + "']").slideUp(function() {
				$(this).remove();
			});
		} catch (ignored) {}
	}
}

const notificacaoVH = new NotificacaoVH();
