class DiagnosticoVH extends AmaisVH {
	
	constructor() {
		super(DiagnosticoVH.name);
	}

	paginacaoTO: PaginacaoTO;

	async exibirDiagnosticosDaAplicacao(codAgendamento: number) {

		const filtrosDiagnosticoAplicacaoTO: FiltrosDiagnosticoAplicacaoTO = {
			codAgendamento: codAgendamento,
			buscaTextual: null,
			paginacaoTO: this.paginacaoTO
		};

		const exibicaoDiagnosticoAplicacaoTO: ExibicaoDiagnosticoAplicacaoTO = await this.call(
			"DiagnosticoFCD/listarDiagnosticosDaAplicacao", 
			filtrosDiagnosticoAplicacaoTO
		);

		this.setIdTarget("sala_de_avaliacao", true);

		this.addSubtitulo({
			texto: this.getCfg("LABEL_DIAGNOSTICO_PLURAL"), 
			textoSecundario: "#" + codAgendamento
		});

		this.addCampoExibicao({
			label: "Início da fase",
			valor: exibicaoDiagnosticoAplicacaoTO.dataInicioFase,
			formato: "DD/MM/YYYY HH:mm"
		});

		if (exibicaoDiagnosticoAplicacaoTO.dataFimFase) {
			this.addCampoExibicao({
				label: "Fim da fase",
				valor: exibicaoDiagnosticoAplicacaoTO.dataFimFase,
				formato: "DD/MM/YYYY HH:mm"
			});
		}

		this.addCampoExibicao({
			label: this.getCfg("LABEL_ROTEIRO"),
			valor: exibicaoDiagnosticoAplicacaoTO.nomeRoteiro
		});

		this.addCampoExibicao({
			label: "Nota máxima",
			valor: exibicaoDiagnosticoAplicacaoTO.notaMaximaRoteiro
		});

		await this.addTabela({
			collection: exibicaoDiagnosticoAplicacaoTO.listaDetalhesDiagnosticoTO,
			paginacao: {
				paginacaoTO: exibicaoDiagnosticoAplicacaoTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO: PaginacaoTO) => {
					this.paginacaoTO = paginacaoTO;
					await this.exibirDiagnosticosDaAplicacao(codAgendamento);
				}
			},
			colunas: [{
				titulo: "Inscrito",
				prop: async (detalhesDiagnosticoTO) => agendamentoProvaVH.montarLinkNomeUsuario({
					nomeUsuario: detalhesDiagnosticoTO.nomeDiagnosticado,
					codProvaFeita: detalhesDiagnosticoTO.codProvaFeita,
					isCpfVerificadoOCR: detalhesDiagnosticoTO.isCpfVerificadoOCR,
					codUsuario: detalhesDiagnosticoTO.codUsuario
				})
			}, {
				titulo: "Avaliador",
				prop: "nomeAvaliador"
			}, {
				titulo: "Data",
				prop: "dataDiagnostico",
				formato: "DD/MM/YYYY HH:mm"
			}, {
				titulo: "Nota",
				prop: "notaConcedida",
				formato: "NUMERO",
			}, {
				titulo: "",
				prop: (detalhesDiagnosticoTO) => {
					console.info(detalhesDiagnosticoTO)
					return this.addBotao({
						label: `<i class="far fa-clipboard"></i>`,
						onClick: async () => await this.exibirPopupDiagnostico(detalhesDiagnosticoTO.codAgendamentoUsuario),
						retornarHtml: true
					});
				}
			}]
		})

		this.exibir();
	}

	async exibirPopupDiagnostico(codAgendamentoUsuario: number) {

		const edicaoDiagnosticoTO: EdicaoDiagnosticoTO = await this.call(
			"DiagnosticoFCD/recuperarDiagnosticoNaAplicacao", 
			codAgendamentoUsuario
		);

		this.addPopup({
			titulo: this.getMsg("FP_FRONT_DiagnosticoVH_001"),
			subtitulo: edicaoDiagnosticoTO.nomeDiagnosticado,
			botoes: [{
				label: this.getMsg("MSG_VH_007"),
				classe: "btn-danger",
				visivel: this.hasValue(edicaoDiagnosticoTO.notaConcedida),
				onClick: async () => {
					let excluiu = false;
					await this.exibirAlerta({
						msg: "Tem certeza que deseja excluir?",
						botoes: [{
							label: this.getMsg("MSG_VH_006"), 
							classe: "btn-danger",
							onClick: async () => {
								excluiu = true;
							}
						}],
						onHidden: async () => {
							if (excluiu) {
								await this.call("DiagnosticoFCD/excluirDiagnosticoNaAplicacao", codAgendamentoUsuario);
								await this.exibirDiagnosticosDaAplicacao(edicaoDiagnosticoTO.codAgendamento);
							}
						}
					});
					return excluiu;
				}
			}, {
				label: this.getMsg("MSG_VH_008"),
				classe: "btn-primary",
				onClick: async () => {
					this.verificarObrigatorios();

					const salvamentoDiagnosticoTO: SalvamentoDiagnosticoTO = {
						codAgendamentoUsuario,
						anotacoesDiagnostico: this.getValor("anotacoesDiagnostico"),
						dataDiagnostico: this.getValor("dataDiagnostico"),
						notaConcedida: this.getValor("notaConcedida")
					};

					await this.call("DiagnosticoFCD/salvarDiagnosticoNaAplicacao", salvamentoDiagnosticoTO);
					await this.exibirDiagnosticosDaAplicacao(edicaoDiagnosticoTO.codAgendamento);
				}
			}]
		});

		this.addCampoExibicao({
			label: edicaoDiagnosticoTO.nomeRoteiro,
			valor: edicaoDiagnosticoTO.orientacoesRoteiro,
			classe: "col-md-12"
		});

		this.addEditorHTML({
			label: `Anotações`,
			id: "anotacoesDiagnostico",
			valor: edicaoDiagnosticoTO.anotacoesDiagnostico,
			classe: "col-md-12",
			obrigatorio: true
		});

		this.addCampoTexto({
			label: "Nota concedida",
			id: "notaConcedida",
			valor: edicaoDiagnosticoTO.notaConcedida,
			sufixo: "em " + UtilNumero.floatToString(edicaoDiagnosticoTO.notaMaximaRoteiro),
			tipo: "NUMERO",
			obrigatorio: true
		});

		this.addCampoTexto({
			label: "Data",
			id: "dataDiagnostico",
			valor: edicaoDiagnosticoTO.dataDiagnostico,
			tipo: "DATAHORA", 
			obrigatorio: true
		});
		
		this.exibirPopups();
	}
}

const diagnosticoVH = new DiagnosticoVH();

type FiltrosDiagnosticoAplicacaoTO = {
	codAgendamento: number;
	buscaTextual: string;
	paginacaoTO: PaginacaoTO;
}
type ExibicaoDiagnosticoAplicacaoTO = {
	dataInicioFase: string;
	dataFimFase: string;
	nomeRoteiro: string;
	notaMaximaRoteiro: number;
	paginacaoTO: PaginacaoTO;
	listaDetalhesDiagnosticoTO: DetalhesDiagnosticoTO[];
}
type DetalhesDiagnosticoTO = {
	codAgendamentoUsuario: number;
	codProvaFeita: number;
	nomeDiagnosticado: string;
	nomeAvaliador: string;
	dataDiagnostico: string;
	notaConcedida: number;
}
type EdicaoDiagnosticoTO = {
	codAgendamentoUsuario: number;
	codAgendamento: number;
	nomeRoteiro: string;
	nomeDiagnosticado: string;
	orientacoesRoteiro: string;
	anotacoesDiagnostico: string;
	notaConcedida: number;
	notaMaximaRoteiro: number;
	dataDiagnostico: string;
} 
type SalvamentoDiagnosticoTO = {
	codAgendamentoUsuario: number;
	anotacoesDiagnostico: string;
	notaConcedida: number;
	dataDiagnostico: string;
}