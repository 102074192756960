class CadastroModuloVH extends AmaisVH {
	funcionalidadeParaAdicionarASelecao: any;
	collectionModulos: null;

	constructor() {
		super(CadastroModuloVH.name);
		this.addOperacaoParaHash("cmli", this.listar);
		this.addOperacaoParaHash("cmeo", this.editarOperacao);
		this.addOperacaoParaHash("cmef", this.editarFuncionalidade);
		this.addOperacaoParaHash("cmem", this.editarModulo);
	}
 
	async listar() {

		UtilHash.registrarHistorico(this.listar);

		const exibicaoCadastroModulosTO = await this.call("CadastroModuloFCD/listar");

		this.limpar();

		this.setTitulo("Módulos e funcionalidades");

		const colunasModulo: ColunaAddTabela[] = [];
		colunasModulo.push({titulo: "Módulos", prop: "nome"});
		colunasModulo.push({titulo: "", prop: "descricao"});

		await this.addTabela({
			collection: exibicaoCadastroModulosTO.collectionModulos,
			colunas: colunasModulo,
			labelNovo: "Novo módulo",
			onEdicao: this.editarModulo
		})

		const colunasFuncionalidade: ColunaAddTabela[] = [];
		colunasFuncionalidade.push({titulo: "Módulo", prop: "nomeModulo"});
		colunasFuncionalidade.push({titulo: "Funcionalidades", prop: "nome"});
		colunasFuncionalidade.push({titulo: "Tela de entrada", prop: "vhEntrada"});
		colunasFuncionalidade.push({titulo: "Identificador", prop: "identificador"});
		colunasFuncionalidade.push({titulo: "Perfis", prop: "nomesPerfis"});
		colunasFuncionalidade.push({titulo: "# operações", prop: "numOperacoes"});

		await this.addTabela({
			collection: exibicaoCadastroModulosTO.collectionListagemFuncionalidadeTO,
			colunas: colunasFuncionalidade,
			propId: ["idModulo", "id"],
			labelNovo: "Nova funcionalidade",
			onEdicao: [this.editarModulo, this.editarFuncionalidade],
			onExclusao: async (botao, id) => {
				this.exibirAlerta({
					msg: "Tem certeza que deseja excluir esta funcionalidade em definitivo?",
					botoes: [
						{
							label: this.getMsg("MSG_VH_004"),
							classe: "btn-primary",
							onClick: async () => {
								await this.call("CadastroModuloFCD/excluirFuncionalidade", id);
								await this.listar();
							}
						},
						{label: this.getMsg("MSG_VH_005"),}
					],
				});
			}
		})

		const colunasOperacao: ColunaAddTabela[] = [];
		colunasOperacao.push({titulo: "Operações", prop: "identificador"});
		colunasOperacao.push({titulo: "", prop: "descricao"});
		colunasOperacao.push({titulo: "Necessita de autenticação", prop: "isNecessitaAutenticacao"});
		colunasOperacao.push({titulo: "Funcionalidades que a incluem", prop: "numFuncionalidades"});
		
		await this.addTabela({
			collection: exibicaoCadastroModulosTO.collectionListagemOperacaoTO,
			colunas: colunasOperacao,
			onEdicao: this.editarOperacao
		})
		
		this.exibir();
	}

	async editarOperacao(id) {
		
		UtilHash.registrarHistorico(this.editarOperacao, id);

		const edicaoOperacaoTO = await this.call("CadastroModuloFCD/recuperarOperacao", id);

		this.limpar();
		this.setTitulo(edicaoOperacaoTO.identificador)
		this.setSubtitulo("Edição de operação");
		
		this.addCampoTexto({id: "identificador", label: "Identificador", valor: edicaoOperacaoTO.identificador, habilitado: false});
		this.addCampoTexto({id: "descricao", label: "Descrição", valor: edicaoOperacaoTO.descricao});
		this.addCheckbox({id: "isRelevanteAuditoria", label: "Relevante para auditoria", valor: edicaoOperacaoTO.isRelevanteAuditoria});
		this.addCheckbox({id: "isExcluida", label: "Excluir esta operação", valor: edicaoOperacaoTO.isExcluida});
		this.addCheckbox({id: "isNecessitaAutenticacao", label: "Necessita de autenticação", valor: edicaoOperacaoTO.isNecessitaAutenticacao});
		
		this.addSubtitulo("Funcionalidades que incorporam esta operação");
		
		if (this.funcionalidadeParaAdicionarASelecao) edicaoOperacaoTO.collectionFuncionalidades.push(this.funcionalidadeParaAdicionarASelecao);

		this.funcionalidadeParaAdicionarASelecao = null;
		
		await this.addSelect({
			collection: edicaoOperacaoTO.collectionFuncionalidades,
			id: "idsFuncionalidades",
			label: "Funcionalidades que incluem esta operação",
			multiplo: true,
			classe: "col-md-12"
		});

		this.addEspacamentoHorizontal("1px");
		
		this.addBotao({
			label: "Salvar", 
			amarrarCampos: ["descricao","isRelevanteAuditoria","isExcluida"], 
			css: "float: right", 
			onClick: async () => {

				if (!this.validarCamposObrigatorios("descricao")) return;

				var salvamentoOperacaoTO = {
					id: id,
					descricao: this.getValor("descricao"),
					isRelevanteAuditoria: this.getValor("isRelevanteAuditoria"),
					isExcluida: this.getValor("isExcluida"),
					idsFuncionalidades: this.getValor("idsFuncionalidades"),
					isNecessitaAutenticacao: this.getValor("isNecessitaAutenticacao")
				}

				await this.call("CadastroModuloFCD/salvarOperacao", salvamentoOperacaoTO);

				UtilHash.voltar();
			}
		});

		this.addBotao({
			label: "<i class='fa fa-plus-circle'></i> Funcionalidade", 
			css: "float: right", 
			classe: "btn-success", 
			onClick: async () => {

				const collectionModulos = await this.call("CadastroModuloFCD/listarModulos");

				this.addPopup({
					titulo: "Nova funcionalidade",
					botoes: [{
						label:"Salvar", 
						onClick: async () => {

							const salvamentoFuncionalidadeTO = {
								nome: this.getValor("nome_funcionalidade"),
								idModulo: this.getValor("idModulo_funcionalidade"),
							}
							
							const funcionalidade = await this.call("CadastroModuloFCD/salvarFuncionalidade", salvamentoFuncionalidadeTO);

							this.funcionalidadeParaAdicionarASelecao = funcionalidade;

							this.editarOperacao(id);
						}
					}]
				})

				await this.addSelect({collection: collectionModulos, id: "idModulo_funcionalidade", label: "Módulo", classe: "col-md-6"});

				this.addCampoTexto({id: "nome_funcionalidade", label: "Nome da funcionalidade", classe: "col-md-6"});
				
				this.exibirPopups();
			}
		});

		const colunasFuncionalidadeOperacao: ColunaAddTabela[] = [];
		colunasFuncionalidadeOperacao.push({titulo: "Funcionalidades", prop: "nome"});
		colunasFuncionalidadeOperacao.push({titulo: "# operações", prop: "descricao"});
		
		await this.addTabela({
			collection: edicaoOperacaoTO.collectionFuncionalidadesDaOperacao,
			colunas: colunasFuncionalidadeOperacao,
			onEdicao: this.editarFuncionalidade
		});

		this.exibir();
	}

	async editarFuncionalidade(id) {

		UtilHash.registrarHistorico(this.editarFuncionalidade, id);

		const edicaoFuncionalidadeTO = await this.call("CadastroModuloFCD/recuperarFuncionalidade", id);

		this.limpar();
		this.setTitulo(edicaoFuncionalidadeTO.nome)
		this.setSubtitulo("Edição de funcionalidade");
		
		await this.addSelect({collection: this.collectionModulos || edicaoFuncionalidadeTO.collectionModulos, id: "idModulo", label: "Módulo"});
		this.collectionModulos = null;
		
		this.addCampoTexto({id: "nome", label: "Nome da funcionalidade", valor: edicaoFuncionalidadeTO.nome});
		this.addCampoTexto({id: "vhEntrada", label: "Tela de entrada", valor: edicaoFuncionalidadeTO.vhEntrada});
		this.addCampoTexto({id: "identificador", label: "Identificador", valor: edicaoFuncionalidadeTO.identificador});
		await this.addSelect({
			collection: edicaoFuncionalidadeTO.collectionOperacoes,
			id: "idsOperacoes",
			label: "Operações que compõem esta funcionalidade",
			multiplo: true,
			classe: "col-md-12"
		});
		await this.addSelect({
			collection: edicaoFuncionalidadeTO.collectionPerfis,
			id: "idsPerfis",
			label: "Perfis autorizados",
			multiplo: true,
			classe: "col-md-12"
		});
		
		this.addEspacamentoHorizontal("1px");
		
		this.addBotao({
			label: "Salvar", 
			css: "float: right", 
			amarrarCampos: ["nome"], 
			onClick: async () => {
				
				if (!this.validarCamposObrigatorios("nome", "idModulo")) return;
				
				var salvamentoFuncionalidadeTO = {
					id: id,
					nome: this.getValor("nome"),
					identificador: this.getValor("identificador"),
					vhEntrada: this.getValor("vhEntrada"),
					idModulo: this.getValor("idModulo"),
					idsOperacoes: this.getValor("idsOperacoes"),
					idsPerfis: this.getValor("idsPerfis"),
				}
				
				await this.call("CadastroModuloFCD/salvarFuncionalidade", salvamentoFuncionalidadeTO);

				UtilHash.voltar();
			}
		});
		this.addBotao({
			label: "Excluir", 
			classe: "btn-danger", 
			css: "float: right", 
			onClick: async () => {
				this.exibirAlerta({
					msg: "Tem certeza que deseja excluir esta funcionalidade em definitivo?",
					botoes: [
						{
							label: this.getMsg("MSG_VH_004"),
							classe: "btn-primary",
							onClick: async () => {
								await this.call("CadastroModuloFCD/excluirFuncionalidade", id);

								UtilHash.voltar();
							}
						},
						{label: this.getMsg("MSG_VH_005"),}
					],
				});
			}
		});
		this.addBotao({label: "<i class='fa fa-plus-circle'></i> Módulo", css: "float: right", classe: "btn-success", onClick: () => {
			this.addPopup({
				titulo: "Novo módulo",
				botoes: [{
					label:"Salvar", 
					onClick: async () => {
						var salvamentoModuloTO = {
							nome: this.getValor("nome_modulo"),
						}
						const collectionModulos = await this.call("CadastroModuloFCD/salvarModulo", salvamentoModuloTO);
						this.collectionModulos = collectionModulos;
						this.editarFuncionalidade(id);
					}
				}]
			})
			this.addCampoTexto({id: "nome_modulo", label: "Nome do módulo", classe: "col-md-6"});
			
			this.exibirPopups();
		}});

		const colunasOperacaoFuncionalidade: ColunaAddTabela[] = [];
		colunasOperacaoFuncionalidade.push({titulo: "", prop: "identificador"});
		colunasOperacaoFuncionalidade.push({titulo: "", prop: "descricao"});
		colunasOperacaoFuncionalidade.push({titulo: "Relevante para auditoria", prop: "isRelevanteAuditoria"});

		await this.addTabela({
			titulo: "Operações",
			collection: edicaoFuncionalidadeTO.collectionListagemOperacaoTODaFuncionalidade,
			colunas: colunasOperacaoFuncionalidade,
			onEdicao: this.editarOperacao
		});
		
		this.exibir();
	}
	
	async editarModulo(id) {

		UtilHash.registrarHistorico(this.editarModulo, id);

		const edicaoModuloTO = await this.call("CadastroModuloFCD/recuperarModulo", id);

		this.limpar();
		this.setTitulo(edicaoModuloTO.nome)
		this.setSubtitulo("Edição de módulo");
		this.addCampoTexto({id: "nome", label: "Nome", valor: edicaoModuloTO.nome});
		this.addEspacamentoHorizontal("1px");
		
		this.addBotao({
			label: "Salvar", 
			css: "float: right", 
			amarrarCampos: ["nome"], 
			onClick: async () => {

				if (!this.validarCamposObrigatorios("nome")) return;
				
				var salvamentoModuloTO = {
					id: id,
					nome: this.getValor("nome"),
				}
				
				await this.call("CadastroModuloFCD/salvarModulo", salvamentoModuloTO);
				this.listar();
			}
		});
		
		this.addBotao({
			label: "Excluir", 
			classe: "btn-danger", 
			css: "float: right", 
			onClick: async () => {
				this.exibirAlerta({
					msg: "Tem certeza que deseja excluir este módulo em definitivo?",
					botoes: [
						{
							label: this.getMsg("MSG_VH_004"),
							classe: "btn-primary",
							onClick: async () => {
								await this.call("CadastroModuloFCD/excluirModulo", id);
								this.listar();
							}
						},
						{label: this.getMsg("MSG_VH_005"),}
					],
				});
			}
		});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "", prop: "nome", classe: "descricao"});
		colunas.push({titulo: "# operações", prop: "numOperacoes"});
		colunas.push({titulo: "Tela de entrada", prop: "vhEntrada"});
		colunas.push({titulo: "Identificador", prop: "identificador"});
		colunas.push({titulo: "Perfis", prop: "nomesPerfis"});

		await this.addTabela({
			collection: edicaoModuloTO.collectionListagemFuncionalidadeTO,
			titulo: "Funcionalidades",
			colunas: colunas,
			labelNovo: "Nova funcionalidade",
			onEdicao: this.editarFuncionalidade,
			totalizar: ["numOperacoes"]
		});
		
		this.exibir();
	}
}

const cadastroModuloVH = new CadastroModuloVH();
