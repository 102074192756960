class ControleFilasProcessamentoVH extends AmaisVH {

	constructor() {
		super(ControleFilasProcessamentoVH.name);
		this.addOperacaoParaHash("fcp", this.exibirFilas);
	}

	async exibirFilas() {

		this.limpar();
		this.setTitulo(this.getMsg("FP_FRONT_ControleFilasProcessamentoVH_001"));
		let alteracoesFilas = {};

		let listaFilas = await this.call("ControleFilaProcessamentoFCD/listarFilas");

		this.addFormulario();

		this.append(`<div id="container-lista-filas">`);

		for(let item of listaFilas){

			this.append("<div class='container_fila row col-md-12'>");

			this.addCampoExibicao({
				id: `IDENTIFICADOR_${item.fila}`,
				label: "Identificador Fila",
				valor: item.fila,
				classe: "col-md-6"
			});

			this.addRadioGroup({
				id: item.fila,
				label: "Fila ativa",
				collection: [
					{ id: "S", descricao: "Ativa" },
					{ id: "N", descricao: "Inativa" }
				],
				classe: "col-md-6",
				valor: item.ativa === true ? "S": "N",
				classeRadio: "radio-inline",
				onChange: () => {
					alteracoesFilas[item.fila] = this.getValor(item.fila) === 'S';
				}
			});

			this.append("</div>");
		}

		this.addBotao({
			id: "botaoSalvarAlteracoes",
			label: `Salvar alterações`,
			css: "float: right",
			classe: "btn-success btn-sm",
			onClick: async () => {
				let listaFilas = [];
				Object.entries(alteracoesFilas).forEach(entry => {
					const [key, value] = entry;
					listaFilas.push({
						fila: key,
						ativa: value
					})
				});
				if(!this.isEmpty(listaFilas)){
					await this.call("ControleFilaProcessamentoFCD/salvarFilas", listaFilas);
					await this.exibirFilas();
				}
			}
		});

		this.append(`</div>`);

		this.fecharFormulario();

		this.exibir();

		$("#container-lista-filas i.fa-question-circle-o").hide();

		UtilHash.registrarHistorico(this.exibirFilas);
	}
}

const controleFilasProcessamentoVH = new ControleFilasProcessamentoVH();