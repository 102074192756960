class AgendamentoProvaVH extends AmaisVH {

	isExibirSomenteAguardandoLiberacao: boolean;
	isExibirExcluidas: boolean;
	exibicaoAbasAplicacaoTO: any;
	isAtualizacaoAutomatica: boolean;
	salaAvaliacaoTO: SalaAvaliacaoTO;
	codAgendamentoDaSalaDeAvaliacao: number;
	idIntervalAtualizacaoSalaDeAvaliacao: any;

	situacoesAlunos: string[] = [];

	tsInicioAtualizacaoScreenshots: any;
	atualizandoScreeshots = false;
	proctoringBackendUrl = "https://proctoring-service.imaginie.com.br";

	constructor() {
		super(AgendamentoProvaVH.name);
		this.addOperacaoParaHash("apee", this.exibirEdicaoEmail);
		this.addOperacaoParaHash("apfi", this.exibirAbasFiscal);
	}

	async liberarProvaUsuario(codAgendamentoUsuario) {
		const codAgendamento = await this.call("AgendamentoProvaFCD/liberarAgendamentoUsuario", codAgendamentoUsuario);
		await this.exibirSalaDeAvaliacao(codAgendamento);
	}

	async cancelarLiberacaoProva(codAgendamentoUsuario) {
		const codAgendamento = await this.call("AgendamentoProvaFCD/cancelarLiberacaoAgendamentoUsuario", codAgendamentoUsuario);
		await this.exibirSalaDeAvaliacao(codAgendamento);
	}

	async listarAOsProva(codProva: number, codAgendamento?: number, isExibirExcluidas: boolean = false) {
		await this.listarAOs(codProva, codAgendamento, isExibirExcluidas);
	}

	async listarAOs(codProva, codAgendamento, isExibirExcluidas) {

		if ([true, false].includes(isExibirExcluidas)) {
			this.isExibirExcluidas = isExibirExcluidas;
		}

		const filtroTO = {
			codProva,
			isExibirExcluidas: this.isExibirExcluidas,
			isExibirSomenteAguardandoLiberacao: this.isExibirSomenteAguardandoLiberacao
		};

		const exibicaoAplicacoesProvaTO = await this.call("AgendamentoProvaFCD/exibirAgendamentos", filtroTO);

		if (exibicaoAplicacoesProvaTO.exibirAlertaRanking) {
			cadastroProvaVH.exibirMsgAlertaRanking(exibicaoAplicacoesProvaTO.isRankingDesatualizado, exibicaoAplicacoesProvaTO.isRankingEmProcessamento, codProva);
		}

		await this.exibirAgendamentosCB(exibicaoAplicacoesProvaTO, filtroTO);

		if (this.hasValue(codAgendamento)) {
			await this.exibirSalaDeAvaliacao(codAgendamento, true);
		}
	}

	async exibirAbasFiscal(numAbaAtiva: number = 0) {

		UtilHash.registrarHistorico(this.exibirAbasFiscal, numAbaAtiva);

		this.limpar();

		this.exibicaoAbasAplicacaoTO = await this.call("AgendamentoProvaFCD/recuperarDadosAbas");
		this.setTitulo(this.getMsg("FP_FRONT_AgendamentoProvaVH_001"));

		const abas = <CfgsAba[]> [{ 
			label: `<span class="">${this.getMsg("FP_FRONT_AgendamentoProvaVH_112")}</span>`,
			onClick: () => this.listarAOsPorAplicacaoParaFiscal(),
			id: "porAplicacao"
		},{ 
			label: `<span>${this.getMsg("FP_FRONT_AgendamentoProvaVH_113")}</span>`,
			onClick: () => this.listarAOsPorAvaliadoParaFiscal(null),
			id: "porAvaliado"
		}];

		this.addAbas({ abas, numAbaAtiva });

		this.exibir();
	}
	
	async listarAOsPorAvaliadoParaFiscal(filtrosFiscalizacaoPorAvaliadoTO: FiltrosFiscalizacaoPorAvaliadoTO) {

		filtrosFiscalizacaoPorAvaliadoTO = filtrosFiscalizacaoPorAvaliadoTO || new FiltrosFiscalizacaoPorAvaliadoTO();

		const exibicaoFiscalizacaoPorAvaliadoTO: ExibicaoFiscalizacaoPorAvaliadoTO = await this.call("AgendamentoProvaFCD/listarAOsPorAvaliadoParaFiscal", filtrosFiscalizacaoPorAvaliadoTO);
		const listaDetalhesFiscalizacaoPorAvaliadoTO: DetalhesFiscalizacaoPorAvaliadoTO[] = exibicaoFiscalizacaoPorAvaliadoTO.listaDetalhesFiscalizacaoPorAvaliadoTO;
		const isExibirNomeTurma = listaDetalhesFiscalizacaoPorAvaliadoTO.find(detalhesFiscalizacaoPorAvaliadoTO => this.hasValue(detalhesFiscalizacaoPorAvaliadoTO.nomeTurma));
		this.limpar(true);

		this.addFiltrosAvaliados(exibicaoFiscalizacaoPorAvaliadoTO.filtrosFiscalizacaoPorAvaliadoTO);

		let montarBotoes = (detalhesFiscalizacaoPorAvaliadoTO: DetalhesFiscalizacaoPorAvaliadoTO) => {
			let h = ["<div class='btn-group'>"];

			h.push(this.addBotao({ 
				label: "<i class='fa fa-desktop'></i>", 
				dica: this.getMsg("MSG_VH_AP_03"), 
				onClick: () => agendamentoProvaVH.exibirSalaDeAvaliacao(detalhesFiscalizacaoPorAvaliadoTO.codAgendamento), 
				retornarHtml: true 
			}));

			if (detalhesFiscalizacaoPorAvaliadoTO.isPermiteLiberacao || detalhesFiscalizacaoPorAvaliadoTO.isPermiteCancelamentoLiberacao) {

				let classe = "";
				let classeIcone = "";
				let tooltip = "";

				if (detalhesFiscalizacaoPorAvaliadoTO.isAusenciaRegistrada) {
					classe = "btn-warning";
					tooltip = this.getMsg("FP_FRONT_AgendamentoProvaVH_056");
					classeIcone = "fa-calendar-times-o";

				} else if (detalhesFiscalizacaoPorAvaliadoTO.isPermiteLiberacao) {
					classe = "btn-primary";
					tooltip = this.getMsg("FP_FRONT_AgendamentoProvaVH_056");
					classeIcone = "fa-calendar-check-o";

				} else if (detalhesFiscalizacaoPorAvaliadoTO.isPermiteCancelamentoLiberacao) {
					tooltip = this.getMsg("FP_FRONT_AgendamentoProvaVH_056");
					classeIcone = "fa-calendar-check-o";
				}

				h.push(this.addBotao({
					label: `<i class='fa ${classeIcone}' title='${tooltip}'></i>`,
					classe: classe,
					onClick: () => {
						fiscalizacaoVH.exibirDadosAlunoParaLiberacao(detalhesFiscalizacaoPorAvaliadoTO.codAgendamentoUsuario, () => {
							this.listarAOsPorAvaliadoParaFiscal(null);
						})
					},
					retornarHtml: true 
				}));
			}

			h.push("</div>");

			return h.join("");
		}

		let montarColunaSituacao = (detalhesFiscalizacaoPorAvaliadoTO: DetalhesFiscalizacaoPorAvaliadoTO) => {
			return this.getLabelSituacaoAvaliadoNaAO({
				ultimoKA: null,
				agora: null,
				codProvaFeita: detalhesFiscalizacaoPorAvaliadoTO.codProvaFeita,
				dataEncerramento: null,
				codUsuarioFiscal: this.getCodUsuarioLogado(),
				isLiberado: detalhesFiscalizacaoPorAvaliadoTO.isPermiteCancelamentoLiberacao,
				isAusente: detalhesFiscalizacaoPorAvaliadoTO.isAusenciaRegistrada,
				isCadernoPreGerado: null,
				validacaoFaceMatch: null,
				codAgendamento: detalhesFiscalizacaoPorAvaliadoTO.codAgendamento,
				isAnulada: detalhesFiscalizacaoPorAvaliadoTO.isAnulada,
				possuiNota: detalhesFiscalizacaoPorAvaliadoTO.isFinalizouProva || detalhesFiscalizacaoPorAvaliadoTO.isAnulada,
				isProvaIniciada: detalhesFiscalizacaoPorAvaliadoTO.isFazendoProva || detalhesFiscalizacaoPorAvaliadoTO.isFinalizouProva || detalhesFiscalizacaoPorAvaliadoTO.isAnulada,
				isProvaFinalizada: detalhesFiscalizacaoPorAvaliadoTO.isFinalizouProva || detalhesFiscalizacaoPorAvaliadoTO.isAnulada,
				isOnline: detalhesFiscalizacaoPorAvaliadoTO.isOnline,
				revalidacaoFaceMatch: null
			});
		}

		const colunas: ColunaAddTabela[] = [];

		colunas.push({titulo: "", prop: (detalhesFiscalizacaoPorAvaliadoTO: DetalhesFiscalizacaoPorAvaliadoTO) => {
				const arquivada = detalhesFiscalizacaoPorAvaliadoTO.isAplicacaoArquivada
					? `<br><span class='label label-default'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_012")}</span>`
					: "";

				return `
					<small>#${detalhesFiscalizacaoPorAvaliadoTO.codAgendamento}</small> 
					${arquivada}
				`;
			}
		});

		colunas.push({titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_114"), prop: "nomeProva"});
		colunas.push({titulo: this.getMsg("MSG_VH_AP_14"), prop: "inicioFaseAplicacao", formato: "DD/MM/YYYY HH:mm"});
		colunas.push({titulo: this.getMsg("MSG_VH_AP_09"), prop: "tipoAplicacao"});
		colunas.push({titulo: this.getCfg("LABEL_ALUNO"), prop: "nomeAluno"});

		if (isExibirNomeTurma) {
			colunas.push({titulo: this.getCfg("LABEL_TURMA"), prop: (listagemAgendamentoTO: ListagemAgendamentoTO) => {
					if (listagemAgendamentoTO.numTurmas > 3) {
						return "<small title='" + listagemAgendamentoTO.nomeTurma.replace(/<br>/g, "&lt;br&gt;") + "'>"
							+ listagemAgendamentoTO.numTurmas + " " + this.getCfg("LABEL_ALUNO").toLowerCase()
							+ "</small>";
					} else {
						return listagemAgendamentoTO.nomeTurma
					}
				}
			});
		}

		colunas.push({titulo: this.getMsg("MSG_VH_AP_11"), prop: montarColunaSituacao});
		colunas.push({titulo: "", prop: montarBotoes});

		await this.addTabela({
			collection: listaDetalhesFiscalizacaoPorAvaliadoTO,
			propId: "codAgendamento",
			colunas: colunas,
			ordenar: false
		});
		
		this.append("<div id='sala_de_avaliacao' class='col-sm-12'></div>");

		this.exibir();
	}

	async listarAOsPorAplicacaoParaFiscal() {

		if (this.isFiscal(false) && this.isExibirSomenteAguardandoLiberacao == null) {
			this.isExibirSomenteAguardandoLiberacao = true;
		}

		//this.limpar();

		if (this.isFiscal(false)) {
			this.setTitulo(this.getMsg("FP_FRONT_AgendamentoProvaVH_001"));
		} else {
			this.setTitulo(this.exibicaoAbasAplicacaoTO.labelMenuAgendamentos);
		}

		const filtroTO = {
			isExibirSomenteAguardandoLiberacao: this.isExibirSomenteAguardandoLiberacao
		};

		const exibicaoAplicacoesProvaTO = await this.call("AgendamentoProvaFCD/exibirAgendamentos", filtroTO);
		await this.exibirAgendamentosCB(exibicaoAplicacoesProvaTO, filtroTO);
	}

	async exibirAgendamentosCB(exibicaoAplicacoesProvaTO, filtroTO) {

		let collectionListagemAgendamentoTO = exibicaoAplicacoesProvaTO.collectionListagemAgendamentoTO;
		const isProvaSemQuestoes = exibicaoAplicacoesProvaTO.numQuestoesNaProva != null && exibicaoAplicacoesProvaTO.numQuestoesNaProva == 0;
		const isProvaComQuestaoSemGabarito = exibicaoAplicacoesProvaTO.listQuestoesSemGabarito != null &&
			exibicaoAplicacoesProvaTO.listQuestoesSemGabarito.length > 0;
		this.limpar(true);

		const botoes = [];

		if (!exibicaoAplicacoesProvaTO.isArquivada) {
			if (this.isCfgDesabilitada("FUNCIONALIDADE_CRIAR_APLICACAO_SEM_QUESTAO") && isProvaSemQuestoes) {
				this.addTexto(this.getMsg("FP_FRONT_AgendamentoProvaVH_002"));

			} else if (isProvaComQuestaoSemGabarito) {
				this.addTexto(this.getMsg("FP_FRONT_AgendamentoProvaVH_003"));

				exibicaoAplicacoesProvaTO.listQuestoesSemGabarito.sort((a, b) => {
					const comparacaoSecao = a.nomeSecao.localeCompare(b.nomeSecao);
					return comparacaoSecao === 0 ? a.numQuestao - b.numQuestao : comparacaoSecao;
				});

				this.append("<ul>");

				exibicaoAplicacoesProvaTO.listQuestoesSemGabarito.forEach((questao) => {
					this.append(`<li><strong>${questao.nomeSecao}</strong> : Questão ${questao.numQuestao}</li>`)
				});

				this.append("</ul>");

			} else if (exibicaoAplicacoesProvaTO.codProva != null && (this.exibicaoAbasAplicacaoTO == null || this.exibicaoAbasAplicacaoTO.exibirNovoAgendamento)) {

				botoes.push(this.addBotao({
					id: "btnCriarAplicacaoOnline",
					label: "<i class='fa fa-plus-circle'></i> " + this.getMsg("MSG_VH_AP_02"),
					classe: "ao-botao-nova btn-success btn-sm", 
					onClick: async () => {
						await cadastroAOVH.exibirAbasEdicaoAO(0, null, exibicaoAplicacoesProvaTO.codProva);
					}, retornarHtml: true
				}));

				if (collectionListagemAgendamentoTO && collectionListagemAgendamentoTO.length > 0) {

					const hash = UtilHash.getHash(dashboardProvaVH.exibirPorProva, exibicaoAplicacoesProvaTO.codProva);

					botoes.push(this.addBotao({
						label: `<i class="fa fa-tachometer-alt"></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_005")}`,
						classe: "btn-sm", 
						href: hash,
						retornarHtml: true
					}));

					const temAONaoEncerrada = collectionListagemAgendamentoTO.filter(listagemAgendamentoTO => !listagemAgendamentoTO.dataEncerramentoDoAgendamento && !listagemAgendamentoTO.dataFinalizacaoDoAgendamento).length;

					if (temAONaoEncerrada) {
						botoes.push(this.addBotao({
							label: "<i class='fa fa-stop'></i> " + this.getMsg("FP_FRONT_AgendamentoProvaVH_019"),
							dica: this.getMsg("FP_FRONT_AgendamentoProvaVH_130"),
							classe: "btn-sm fp-small-business-hidden",
							onClick: async () => {
								await this.exibirAlerta({
									titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_130"),
									msg: this.getMsg("MSG_VH_AP_90"),
									botoes: [{
										label: "<i class='fa fa-stop'></i> " + this.getMsg("FP_FRONT_AgendamentoProvaVH_019"),
										classe: "btn-primary",
										onClick: async () => {
											await this.encerrarEnvioDeRespostasEmTodasAplicacoes(collectionListagemAgendamentoTO);
										}
									}]
								});
							},
							retornarHtml: true
						}));
					}
				}

				this.append("</div>");
			}

			botoes.push(this.addBotaoBusca("filtrosAOs", {classe: "btn-sm", retornarHtml: true}));

			this.append("<div class='w-full btn-group d-flex justify-end' style='margin-bottom: 15px'>");
			this.append(botoes.join(''));
			this.append("</div>");

			this.addFiltrosAOs(exibicaoAplicacoesProvaTO);
		}

		const montarBotoesAplicacao = (listagemAgendamentoTO: ListagemAgendamentoTO) => {

			const html = ["<div class='btn-group'>"];

			html.push(this.addBotao({
				label: "<i class='fa fa-share'></i>",
				dica: this.getMsg("FP_FRONT_AgendamentoProvaVH_010"),
				onClick: () => this.exibirExportarCorrecoes(listagemAgendamentoTO.codAgendamento),
				retornarHtml: true
			}));

			const detalhes = listagemAgendamentoTO.detalhesAgendamentoUsuarios;

			if (listagemAgendamentoTO.tipoAplicacaoEnum === TipoAplicacao.F.id) {
				if (listagemAgendamentoTO.pathZip) {
					if (listagemAgendamentoTO.pathZip.toUpperCase().startsWith("ERRO:")) {
						html.push(`
							<a class='btn btn-default' title='${listagemAgendamentoTO.pathZip.replace(/'/g, '"')}'> 
								<i class='fa fa-exclamation-circle'></i> 
								${this.getMsg("FP_FRONT_AgendamentoProvaVH_006")}
							</a>`);
					} else {
						html.push(`
							<a class='btn btn-default' target='_blank' href='${listagemAgendamentoTO.pathZip}' title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_007")}'> 
							<i class='fa fa-download'></i></a>
						`);
					}
				} else {
					html.push(this.addBotao({
						label: "<i class='fa fa-print'></i>",
						dica: this.getMsg("FP_FRONT_AgendamentoProvaVH_008"),
						onClick: () => this.carregarAplicacaoImpressa(listagemAgendamentoTO.codAgendamento),
						retornarHtml: true
					}));
				}

				html.push(this.addBotao({
					label: "<i class='fa fa-check-square-o'></i>",
					dica: this.getMsg("FP_FRONT_AgendamentoProvaVH_009"),
					classe: "btn-primary",
					retornarHtml: true,
					onClick: () => {
						importacaoCartaoRespostaVH.exibirEnvioFRs(0);
					}
				}));

			} else {

				html.push(this.addBotao({ 
					label: "<i class='fa fa-desktop'></i>", 
					dica: this.getMsg("MSG_VH_AP_03"), 
					onClick: () => this.exibirSalaDeAvaliacao(listagemAgendamentoTO.codAgendamento), 
					retornarHtml: true 
				}));

				if (this.isAnalista() && detalhes.numAlunosAgendados > 0) {
					html.push(this.addBotao({ 
						label: "<i class='fa fa-envelope-o'></i>", 
						dica: this.getMsg("MSG_VH_AP_04"), 
						onClick: () => this.exibirEdicaoEmail(listagemAgendamentoTO.codAgendamento), 
						retornarHtml: true 
					}));
				}

				if (this.isAnalista() && listagemAgendamentoTO.dataFinalizacaoDoAgendamento && detalhes.numAlunosFinalizaramProva > 0) {
					html.push(this.addBotao({
						label: "<i class='fa fa-share'></i>",
						dica: this.getMsg("FP_FRONT_AgendamentoProvaVH_010"),
						onClick: () => this.exibirExportarCorrecoes(listagemAgendamentoTO.codAgendamento),
						retornarHtml: true
					}));
				}

				if (detalhes.numFeedbacks > 0) {
					html.push(this.addBotao({ 
						label: "<i class='fa fa-comment'></i>", 
						dica: this.getMsg("MSG_VH_AP_05"), 
						onClick: async () => await feedbacksVH.listarFeedbacks(listagemAgendamentoTO.codAgendamento), 
						retornarHtml: true 
					}));
				}

				if (detalhes.numDiscursivasACorrigir > 0) {
					html.push(this.addBotao({
						label: "<i class='fa fa-pencil-square'></i>", 
						dica: this.getMsg("MSG_VH_AP_06") + " " + detalhes.numDiscursivasACorrigir,
						retornarHtml: true, 
						onClick: () => acompanhamentoCDsVH.exibirDetalhamentoCorrecoesAgendamento(listagemAgendamentoTO.codAgendamento, listagemAgendamentoTO.nomeProva)
					}));
				}

				if (detalhes.numRecursos > 0) {
					html.push(this.addBotao({
						label: "<i class='fa fa-legal'></i>", 
						dica: this.getMsg("MSG_VH_AP_07"),
						onClick: async () => await recursoVH.listarRecursosDaProva(null, null, listagemAgendamentoTO.codAgendamento), 
						retornarHtml: true
					}));
				}

				if (exibicaoAplicacoesProvaTO.possuiDocumentos > 0 && UtilAuth.possuiAcesso(TipoFuncionalidade.APLICACAO_ONLINE_CADASTRO)) {
					html.push(this.addBotao({
						label: "<i class='fa fa-file-text'></i>", 
						dica: this.getMsg("MSG_VH_AP_08"),
						onClick: async () => await this.exibirDocumentosParaDownload(listagemAgendamentoTO.codAgendamento), 
						retornarHtml: true
					}));
				}
			}

			if (listagemAgendamentoTO.possuiAvaliacaoPessoal) {
				html.push(this.addBotao({ 
					label: "<i class='fa fa-clipboard-list'></i>", 
					dica: this.getCfg("LABEL_DIAGNOSTICO_PLURAL"),
					onClick: () => diagnosticoVH.exibirDiagnosticosDaAplicacao(listagemAgendamentoTO.codAgendamento), 
					retornarHtml: true 
				}));
			}

			html.push("</div>");

			return html.join("");
		}

		let montarColunaSituacao = function (listagemAgendamentoTO: ListagemAgendamentoTO) {
			// FOI COMENTADO POIS numAlunosNaoComecaramProva NÃO EXISTE
			// if (listagemAgendamentoTO.tipoAplicacaoEnum == "F") {
			// 	const detalhes = listagemAgendamentoTO.detalhesAgendamentoUsuarios;
			// 	if (detalhes.numAlunosAgendados == listagemAgendamentoTO.numAlunosNaoComecaramProva) {
			// 		return this.getMsg("FP_FRONT_AgendamentoProvaVH_011");
			// 	}
			// } else {
				return listagemAgendamentoTO.situacao;
			// }
		}

		const colunas: ColunaAddTabela[] = [];

		colunas.push({titulo: this.getMsg("MSG_VH_AP_09"), prop: "tipoAplicacao"});
		colunas.push({titulo: this.getMsg("MSG_VH_AP_10"), prop: this.exibirEvolucaoDoAgendamento});
		colunas.push({titulo: this.getMsg("MSG_VH_AP_11"), prop: montarColunaSituacao});


		if (exibicaoAplicacoesProvaTO.deveExibirSegmentos) {
			colunas.unshift({titulo: this.getMsg("MSG_VH_AP_12"), prop: "nomeSegmento", classe: "descricao"});
		}

		if (exibicaoAplicacoesProvaTO.deveExibirNomeTurma) {
			colunas.push({
				titulo: exibicaoAplicacoesProvaTO.labelColunaTurmasSalas, 
				prop: (listagemAgendamentoTO: ListagemAgendamentoTO) => {
					if (listagemAgendamentoTO.numTurmas > 3) {
						return "<small title='" + listagemAgendamentoTO.nomeTurma.replace(/<br>/g, "&lt;br&gt;") + "'>"
							+ listagemAgendamentoTO.numTurmas + " " + this.getCfg("LABEL_LISTAGEM_TURMAS").toLowerCase()
							+ "</small>";
					} else {
						return listagemAgendamentoTO.nomeTurma
					}
				}
			});
		}

		let hashEdicaoUsuario = UtilHash.getHash(cadastroUsuarioVH.editarUsuario);

		if (exibicaoAplicacoesProvaTO.deveExibirFiscal) {
			colunas.push({
				titulo: "Fiscal", 
				prop: (listagemAgendamentoTO: ListagemAgendamentoTO) => {
					if (listagemAgendamentoTO.codUsuarioFiscal) {
						if (this.isFiscal(false)) {
							return listagemAgendamentoTO.nomeFiscal;
						} else {
							return "<a href='" + hashEdicaoUsuario + "/" + listagemAgendamentoTO.codUsuarioFiscal + "'><i class='fa fa-user'></i> " + listagemAgendamentoTO.nomeFiscal + " </a>";
						}
					} else {
						return null;
					}
				}
			});
		}

		colunas.push({titulo: "", prop: montarBotoesAplicacao});

		colunas.unshift({titulo: this.getMsg("MSG_VH_AP_14"), prop: (to) => {
				if (to.inicioFaseAplicacao) return UtilData.toDDMMYYYYHHMM(to.inicioFaseAplicacao); else return this.getMsg("MSG_VH_AP_15");
			}, formato: "DD/MM/YYYY HH:mm"
		});

		colunas.unshift({
			titulo: "", prop: (to) => {
				const labelArquivada = to.isArquivada === true ? "<br><span class='label label-default'>" + this.getMsg("FP_FRONT_AgendamentoProvaVH_012") + "</span>" : "";
				const labelAplicacaoTemplate = to.isAplicacaoTemplate === true ? "<br><span class='label label-warning'>TEMPLATE</span>" : "";
				return `
				<small>#${to.codAgendamento}</small> ${labelArquivada}${labelAplicacaoTemplate}`;
			}
		})

		await this.addTabela({
			collection: collectionListagemAgendamentoTO,
			propId: "codAgendamento",
			colunas: colunas,
			ordenar: false,
			onEdicao: [
				null,
				this.isFiscal(false) ? null : cadastroAOVH.exibirAbasEdicaoAO
			],
			onEdicaoParam1: 0,
			msgListaVazia: isProvaSemQuestoes || isProvaComQuestaoSemGabarito ? "&nbsp;" : this.getMsg("FP_FRONT_AgendamentoProvaVH_013"),
			paginacao: {
				paginacaoTO: exibicaoAplicacoesProvaTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					filtroTO['paginacaoTO'] = paginacaoTO;
					exibicaoAplicacoesProvaTO = await this.call("AgendamentoProvaFCD/exibirAgendamentos", filtroTO);
					await this.exibirAgendamentosCB(exibicaoAplicacoesProvaTO, filtroTO);
				}
			},
		});

		this.append("<div id='sala_de_avaliacao' class='col-sm-12'></div>");

		this.exibir();
	}

	addFiltrosAvaliados(filtrosFiscalizacaoPorAvaliadoTO: FiltrosFiscalizacaoPorAvaliadoTO) {

		this.abrirAbaAccordion({ titulo: "Filtros", aberta: filtrosFiscalizacaoPorAvaliadoTO.buscaTextual || filtrosFiscalizacaoPorAvaliadoTO.dataInicioFaseAplicacao || !filtrosFiscalizacaoPorAvaliadoTO.isExibirSomenteAguardandoLiberacao });

		this.addFormulario({ classe: "col-md-12" });

		this.addCampoTexto({ 
			label: "Busca textual", 
			id: "buscaTextual",
			valor: filtrosFiscalizacaoPorAvaliadoTO.buscaTextual,
			sufixo: "<i class='fa fa-sm fa-search'></i> ", 
		});

		this.addCampoTexto({
			id: "dataInicioFaseAplicacao",
			valor: filtrosFiscalizacaoPorAvaliadoTO.dataInicioFaseAplicacao,
			tipo: "DATA",
			label: this.getMsg("FP_FRONT_AgendamentoProvaVH_089"),
		});

		this.addCheckbox({
			id: "isExibirSomenteAguardandoLiberacao",
			label: "Somente com avaliados aguardando liberação",
			valor: filtrosFiscalizacaoPorAvaliadoTO.isExibirSomenteAguardandoLiberacao
		});

		this.append("</div>");

		this.append("<div class='row'>");

		this.addBotao({
			label: "Filtrar",
			classe: "btn-primary pull-right",
			onClick: async () => {
				const filtrosFiscalizacaoPorAvaliadoTO = {
					dataInicioFaseAplicacao: this.getValor("dataIniciofinalizarAgendamentoSemPopupConfirmacaoFaseAplicacao"),
					buscaTextual: this.getValor("buscaTextual"),
					isExibirSomenteAguardandoLiberacao: this.getValor("isExibirSomenteAguardandoLiberacao")
				}
				await this.listarAOsPorAvaliadoParaFiscal(filtrosFiscalizacaoPorAvaliadoTO);
			}
		});

		this.append("</div>");

		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
	}

	exibirEvolucaoDoAgendamento(listagemAgendamentoTO: ListagemAgendamentoTO) {

		const detalhes = listagemAgendamentoTO.detalhesAgendamentoUsuarios;

		return UtilProgressBar.basic({ 
			evolucao: detalhes.numAlunosFinalizaramProva, 
			total: detalhes.numAlunosAgendados 
		});
	}

	async getDadosSalaAvaliacao(codAgendamento: number): Promise<SalaAvaliacaoTO> {
		const salaAvaliacaoTO: SalaAvaliacaoTO = await this.call({
			endpoint: "AgendamentoProvaFCD/recuperarSalaDeAvaliacao",
			params: [codAgendamento, this.isExibirDispositivosNaSalaDeAvaliacao()],
			desativarMsgCarregando: false,
		});

		return salaAvaliacaoTO;
	}

	async exibirSalaDeAvaliacao(codAgendamento: number, fazerScroll: boolean = true) {

		this.isAtualizacaoAutomatica = this.isAtualizacaoAutomatica || false;

		const salaAvaliacaoTO = await this.getDadosSalaAvaliacao(codAgendamento);
		const listagemAgendamentoTO: ListagemAgendamentoTO = salaAvaliacaoTO.listagemAgendamentoTO;
		const detalhes = listagemAgendamentoTO.detalhesAgendamentoUsuarios;
		this.salaAvaliacaoTO = salaAvaliacaoTO;
		this.codAgendamentoDaSalaDeAvaliacao = codAgendamento;

		let codsUsuariosSelecionados: number[] = [];

		try {
			codsUsuariosSelecionados = this.getValor("listagemAvaliados").map(Number);
		} catch (e) { }

		$("tr[identificador]").removeClass("active");
		$("tr[identificador='" + codAgendamento + "']").addClass("active");

		const salaAvaliacaoSelector = $("#sala_de_avaliacao");

		salaAvaliacaoSelector.html("");
		salaAvaliacaoSelector.data("fp-cod-agendamento", codAgendamento);

		this.setIdTarget("sala_de_avaliacao");

		this.addSubtitulo({
			texto: this.getMsg("FP_FRONT_AgendamentoProvaVH_014"), textoSecundario: "#" + codAgendamento,
			htmlDireita: "<span id='botoes-sala-avaliacao'></span>"
		});

		this.setIdTarget("botoes-sala-avaliacao");

		this.append("<div class='btn-group pull-right'>");

		this.addBotaoDropdown({
			label: this.getMsg("FP_FRONT_AgendamentoProvaVH_146"),
			classe: "btn-sm",
			opcoes: [{
				id: "botaoExibirIP",
				label: this.getLabelExibirIP(),
				onClick: async () => await agendamentoProvaVH.exibirIPs()
			}, {
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_145"),
				onClick: async () => await agendamentoProvaVH.analisarIPs()
			}]
		});

		if (this.isAnalista()) {
			this.addBotao({
				label: "Alterar",
				classe: "btn-sm",
				onClick: async () => await cadastroAOVH.exibirAbasEdicaoAO(0, this.salaAvaliacaoTO.codAgendamento)
			});
		}

		if (this.isAdministrador()) {
			this.addBotao({
				label: `<i class='fa fa-eye'></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_015")}`,
				classe: "btn-sm",
				onClick: async () => await loginVH.exibirAcessosDoAgendamento(this.salaAvaliacaoTO.codAgendamento)
			});
		}

		if (this.isAnalista() && this.salaAvaliacaoTO.isScreenshotHabilitado === true) {
			this.addBotao({
				label: `<i class='fa fa-desktop'></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_127")}`,
				classe: "btn-sm",
				onClick: async () => await agendamentoProvaVH.exibirSalaVirtualScreenshots(this.salaAvaliacaoTO.tokenJwtProctoring, this.salaAvaliacaoTO.codAgendamento)
			});
		}

		if (this.isAdministrador() && detalhes.numAlunosAguardandoAvaliado > 0
			&& UtilData.isFutura(listagemAgendamentoTO.inicioFaseAplicacao)) {
			this.addBotao({
				label: `Pré-gerar cadernos de questões`,
				classe: "btn-sm",
				onClick: () => this.preGerarCadernosDeQuestoes(this.salaAvaliacaoTO.codAgendamento)
			});
		}

		if (detalhes.numAlunosAgendados > 0 && this.salaAvaliacaoTO.dataFinalizacao == null) {
			if (this.isAnalista()) {
				this.addBotao({ 
					label: this.getMsg("MSG_VH_AP_17"), 
					classe: "btn-sm", 
					onClick: () => aplicacaoImpressaVH.atualizarGabaritosAplicacaoImpressa(this.salaAvaliacaoTO.codAgendamento, false)
				});
			}
		}
		if (detalhes.numAlunosAgendados > 0 && this.salaAvaliacaoTO.dataEncerramento == null) {
			this.append("<div class='btn-group'>");
			this.append("<button id='botaoAtualizarSalaAvaliacao' type='button' class='btn btn-default btn-sm dropdown-toggle' data-toggle='dropdown' aria-haspopup='true'' aria-expanded='false' data-container='body'>");
			this.append(`<i class='fa fa-refresh'></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_016")} <span class='caret'></span>`);
			this.append("<span class='sr-only'>Toggle Dropdown</span>");
			this.append("</button>");
			this.append("<ul class='dropdown-menu'>");
			this.append(`<li><a onclick='agendamentoProvaVH.exibirSalaDeAvaliacao(${this.salaAvaliacaoTO.codAgendamento})'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_017")}</a></li>`);
			this.append(`<li><a onclick='agendamentoProvaVH.toggleAtualizacaoAutomaticaSalaDeAvaliacao(this)'>${this.getLabelToggleAtualizacaoAutomatica()}</a></li>`);
			this.append("</ul>");
			this.append("</div>");
		}

		if (this.salaAvaliacaoTO.dataEncerramento != null) {
			this.addBotao({
				label: " <i class='fa fa-undo'></i> " + this.getMsg("MSG_VH_AP_19") + " ", 
				classe: "btn-sm", 
				onClick: async () => {
					await this.call("AgendamentoProvaFCD/reabrirAplicacao", salaAvaliacaoTO.codAgendamento);
					await cadastroProvaVH.exibirAbasProva(3, salaAvaliacaoTO.codProva);
				}
			});
		}

		if (this.salaAvaliacaoTO.dataEncerramento == null && this.salaAvaliacaoTO.listagemAgendamentoTO?.isUsuarioLogadoPodeEncerrar) {
			this.addBotao({
				label: "<i class='fa fa-stop'></i> " + this.getMsg("FP_FRONT_AgendamentoProvaVH_018"), 
				classe: "btn-primary btn-sm", 
				onClick: async (codAgendamento: any) => {
					await this.exibirAlerta({
						titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_018"),
						msg: this.getMsg("MSG_VH_AP_76"),
						botoes: [{
							label: "<i class='fa fa-stop'></i> " + this.getMsg("FP_FRONT_AgendamentoProvaVH_019"),
							classe: "btn-primary",
							onClick: () => {
								this.encerrarAgendamento(this.salaAvaliacaoTO.codAgendamento, () => {
									cadastroProvaVH.exibirAbasProva(3, salaAvaliacaoTO.codProva)
								});
							}
						}]
					});
				}
			});
		}

		if (this.salaAvaliacaoTO.dataFinalizacao == null && this.salaAvaliacaoTO.listagemAgendamentoTO?.isUsuarioLogadoPodeFinalizar) {
			this.addBotao({
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_020"),
				classe: "btn-danger btn-sm",
				onClick: async (codAgendamento: any) => {
					await this.exibirAlerta({
						titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_020"),
						msg: this.getMsg("MSG_VH_AP_20"),
						botoes: [{
							label: this.getMsg("FP_FRONT_AgendamentoProvaVH_020"), classe: "btn-danger", onClick: () => {
								this.finalizarAgendamento(this.salaAvaliacaoTO.codAgendamento, () => {
									cadastroProvaVH.exibirAbasProva(3, salaAvaliacaoTO.codProva)
								});
							}
						}]
					});
				}
			})
		}

		if (this.salaAvaliacaoTO.isAguardandoAutorizacaoAdministrador === true) {
			if (this.isAdministrador()) {
				this.addBotao({ 
					label: this.getMsg("MSG_VH_AP_28"), 
					classe: "btn-sm btn-primary", 
					onClick: () => this.autorizarAgendamento(this.salaAvaliacaoTO.codAgendamento) 
				});
			}
		}

		this.append("</div>");

		this.setIdTarget("sala_de_avaliacao");

		if (this.isAdministradorSistema()) {
			this.addSubtitulo(this.getMsg("MSG_VH_AP_21"));

			this.addSubsubtitulo(this.getMsg("MSG_VH_AP_22"));

			this.addTexto(this.getMsg("MSG_VH_AP_23"));
			this.addTexto("<img src='https://s3-sa-east-1.amazonaws.com/mxmz1/arqs/MAX/formula_nota_sistema.png' alt='Formula'/>");

			this.addBotao({
				label: this.getMsg("MSG_VH_AP_24"), 
				classe: "btn-danger", 
				onClick: async () => {
					await this.call("CalculoPontuacaoFCD/calcularNotaPadraoDoSistema", codAgendamento);
				}
			});

			this.addEspacamentoHorizontal("20px");

			this.addSubsubtitulo(this.getMsg("MSG_VH_AP_25"));

			this.addTexto(this.getMsg("FP_FRONT_AgendamentoProvaVH_021"));
			this.addTexto("<span style='font-size: 140%'><b>NP</b> = ((<b>A</b> - <b><span style='text-decoration: overline;'>x</span></b>) / <b>s</b>) x <b><i>f</i></b> + <b><i>n</i></b></span>");
			this.addCampoTexto({ id: "formula2FatorMultiplicador", label: this.getMsg("MSG_VH_AP_26") + " <i>f</i>", tipo: "NUMERO" });
			this.addCampoTexto({ id: "formula2NumeroSomaFinal", label: this.getMsg("MSG_VH_AP_26") + " <i>n</i>", tipo: "NUMERO" })
			this.addEspacamentoHorizontal("1px");
			this.addBotao({
				label: this.getMsg("MSG_VH_AP_24"), 
				classe: "btn-danger", 
				onClick: async () => {
					let calculoNotasFormula2TO = {
						codAgendamento: codAgendamento,
						fatorMultiplicador: this.getValor("formula2FatorMultiplicador"),
						numeroParaSomatorioFinal: this.getValor("formula2NumeroSomaFinal")
					}
					await this.call("CalculoPontuacaoFCD/calcularEPersistirNotaConsiderandoDesvioPadrao", calculoNotasFormula2TO);
				}
			})
		}

		this.addEspacamentoHorizontal("1px");

		if (this.salaAvaliacaoTO.codigoAcessoRotativoTO?.codigoAcesso) {
			this.exibirCodAcesso(codAgendamento);
		}

		this.append("<dl id='dl-infos' class='dl-horizontal col-md-6'>");

		this.append("<dt>" + this.getMsg("MSG_VH_AP_13") + "</dt><dd>" + this.salaAvaliacaoTO.listagemAgendamentoTO.nomeProva + "</dd>");
		this.append("<dt>" + this.getMsg("MSG_VH_AP_11") + "</dt><dd>" + this.salaAvaliacaoTO.listagemAgendamentoTO.situacao + "</dd>");

		this.salaAvaliacaoTO.collectionSalaAvaliacaoFaseTO.forEach((salaAvaliacaoFaseTO) => {
			this.append(`
				<dt>
					${this.getMsg("FP_FRONT_AgendamentoProvaVH_022")} - ${salaAvaliacaoFaseTO.nomeFase}
				</dt>
				<dd>
					${UtilData.getPeriodo(salaAvaliacaoFaseTO.dataInicio, salaAvaliacaoFaseTO.dataFim)} 
					${salaAvaliacaoFaseTO.isVigente ? " <strong>(" + this.getMsg("FP_FRONT_AgendamentoProvaVH_023") + ")</strong>" : ""}
				</dd>
			`);
		});

		if (this.hasValue(this.salaAvaliacaoTO.listagemAgendamentoTO.nomeFiscal)) {
			this.append(`
				<dt>
					${this.getMsg("FP_FRONT_AgendamentoProvaVH_024")}
				</dt>
				<dd>
					${this.salaAvaliacaoTO.listagemAgendamentoTO.nomeFiscal}
				</dd>
			`);
		}

		if (this.salaAvaliacaoTO.listagemAgendamentoTO.dataEncerramentoDoAgendamento != null) {
			this.append(`
				<dt>
					${this.getMsg("FP_FRONT_AgendamentoProvaVH_025")}
				</dt><dd>
					${UtilData.toDDMMYYYYHHMM(this.salaAvaliacaoTO.listagemAgendamentoTO.dataEncerramentoDoAgendamento)}
				</dd>
			`);
		}

		if (this.salaAvaliacaoTO.listagemAgendamentoTO.dataFinalizacaoDoAgendamento != null) {
			this.append(`
				<dt>
					${this.getMsg("FP_FRONT_AgendamentoProvaVH_026")}
				</dt>
				<dd>
					${UtilData.toDDMMYYYYHHMM(this.salaAvaliacaoTO.listagemAgendamentoTO.dataFinalizacaoDoAgendamento)}
				</dd>
			`);
		}

		this.append("</dl>");

		this.append("<dl class='dl-horizontal col-md-6' id='dl-filtro'>");

		if (detalhes.numAlunosAgendados > 1) {
			this.append(`
				<div>
					<dt>
						<a onclick='agendamentoProvaVH.filtrarSalaAvaliacao()'>
							${this.getMsg("FP_FRONT_AgendamentoProvaVH_027")}
						</a>
					</dt>
					<dd>
						${detalhes.numAlunosAgendados}
					</dd>
				</div>
			`);
		}

		this.append("</dl>");

		this.addEspacamentoHorizontal("1px");

		setTimeout(() => this.addFiltrosPorSituacao(), 100);

		if (this.salaAvaliacaoTO.collectionAgendamentoEmAndamentoUsuarioTO.length > 0) {

			this.append("<div id='divListagemUsuarios'></div>");
			this.setIdTarget("divListagemUsuarios");

			await this.addTabela({
				collection: this.salaAvaliacaoTO.collectionAgendamentoEmAndamentoUsuarioTO,
				propId: "codAgendamentoUsuario",
				id: "listagemAvaliados",
				colunas: [{
					titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_052"),
					prop: (agendamentoEmAndamentoUsuarioTO: any) => this.montarLinkNomeUsuario({
						codUsuario: agendamentoEmAndamentoUsuarioTO.codUsuario,
						isCpfVerificadoOCR: agendamentoEmAndamentoUsuarioTO.isCpfVerificadoOCR,
						codProvaFeita: agendamentoEmAndamentoUsuarioTO.codProvaFeita,
						nomeUsuario: agendamentoEmAndamentoUsuarioTO.nomeUsuario,
						tipoAcessibilidade: agendamentoEmAndamentoUsuarioTO.tipoAcessibilidade
					}),
					classe: 'descricao'
				}, {
					titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_156"), 
					regraExibicao: () => this.isExibirDispositivosNaSalaDeAvaliacao(), 
					prop: async (agendamentoEmAndamentoUsuarioTO) => {
						return await loginVH.montarUserAgent(agendamentoEmAndamentoUsuarioTO.agente);
					}
				}, {
					titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_147"), 
					regraExibicao: () => this.isExibirDispositivosNaSalaDeAvaliacao(), 
					prop: "ip"
				}, {
					titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_053"), 
					prop: (agendamentoEmAndamentoUsuarioTO) => this.exibirSituacaoProva(salaAvaliacaoTO, agendamentoEmAndamentoUsuarioTO)
				}, {
					titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_054"), 
					prop: "dataInicio", 
					formato: "DD/MM/YY HH:mm"
				}, {
					titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_055"), 
					prop: (agendamentoEmAndamentoUsuarioTO) => this.exibirPorcentagemConclusaoProva(agendamentoEmAndamentoUsuarioTO), 
					formato: "porcentagem"
				}, {
					titulo: "", 
					prop: this.exibirBotoesAvaliado(salaAvaliacaoTO, codAgendamento), 
					formato: "porcentagem"
				}],
				ordenacao: false,
				selecao: true,
				atibutoParaDesabilitarCheckbox: 'isLiberado',
				valoresSelecionados: codsUsuariosSelecionados
			});

			this.setIdTarget(null);

			if($('#btn_liberacao_provas').length === 0) {
				this.addBotao({
					id: "btn_liberacao_provas",
					label: "<i class='fa fa-calendar-check-o'></i> " + this.getMsg("FP_FRONT_AgendamentoProvaVH_056"),
					classe: "btn-primary",
					visivel: this.salaAvaliacaoTO.codUsuarioFiscal !== null,
					onClick: async () => {
						let cods = this.getValor("listagemAvaliados");

						if (this.isEmpty(cods)) {
							await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AgendamentoProvaVH_057") });
							return;
						}

						const codAgendamento = await this.call("AgendamentoProvaFCD/liberarAgendamentosUsuarios", cods);
						await this.exibirSalaDeAvaliacao(codAgendamento);
					}
				});
			}

			if(this.isAdministrador() && this.salaAvaliacaoTO.isFacematchHabilitado === true && $('#btn_exclusao_documentos_identificacao').length === 0){

				this.addBotao({
					id: "btn_exclusao_documentos_identificacao",
					label: "<i class='fa fa-address-card'></i> " + this.getMsg("FP_FRONT_AgendamentoSalaVH_102"),
					classe: "btn-warning",
					onClick: async () => {
						let cods = this.getValor("listagemAvaliados");

						if (this.isEmpty(cods)) {
							await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AgendamentoProvaVH_057") });
						} else {
							await this.exibirAlerta({
								msg: this.getMsg("FP_FRONT_AgendamentoSalaVH_101"),
								botoes: [{
									label: this.getMsg("FP_FRONT_AgendamentoSalaVH_099"),
									onClick: async () => {
										const codsUsuarios = this.salaAvaliacaoTO.collectionAgendamentoEmAndamentoUsuarioTO.map(au => {
											if(cods.indexOf(au.codAgendamento)){
												return au.codUsuario;
											}
										});
										await this.call("CadastroUsuarioFCD/excluirDocumentosIdentificacao", codsUsuarios);
										if (this.salaAvaliacaoTO?.codAgendamento) {
											await this.exibirSalaDeAvaliacao(this.salaAvaliacaoTO.codAgendamento, true);
										}
									}
								}]
							})
						}
					}
				});
			}
		}

		this.setIdTarget(null);
		this.exibir();

		if (salaAvaliacaoTO.dataEncerramento == null && !this.idIntervalAtualizacaoSalaDeAvaliacao) {
			if (!this.isUsuarioAutenticado() || !this.isAtualizacaoAutomatica) return;
			// this.atualizarSalaAvaliacao();
		}

		if (fazerScroll == null || fazerScroll == true) {
			UtilWindow.scrollTo("#sala_de_avaliacao");
		}

		setTimeout(() => this.addFiltrosPorSituacao(), 300);
	}

	async analisarIPs() {

		if (this.isEmpty(this.salaAvaliacaoTO.collectionAgendamentoEmAndamentoUsuarioTO)) return;

		if (!this.isExibirDispositivosNaSalaDeAvaliacao()) {
			await this.exibirIPs();
		}

		const listaAgendamentoEmAndamentoUsuarioTOComIPs = this.salaAvaliacaoTO.collectionAgendamentoEmAndamentoUsuarioTO
			.filter(to => this.hasValue(to.ip));

		for (let to of listaAgendamentoEmAndamentoUsuarioTOComIPs) {
			to.ipSplitted = to.ip.split(".");
			to.ipNiveis = [
				to.ipSplitted[0],
				to.ipSplitted[0] + "." + to.ipSplitted[1],
				to.ipSplitted[0] + "." + to.ipSplitted[1] + "." + to.ipSplitted[2],
				to.ipSplitted[0] + "." + to.ipSplitted[1] + "." + to.ipSplitted[2] + "." + to.ipSplitted[3]
			];
		};

		if (listaAgendamentoEmAndamentoUsuarioTOComIPs.length == 0) return;

		this.addPopup({
			id: "popupAnalisarIPs",
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_145"),
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_150"),
				onClick: async () => {
					this.fecharPopup("popupAnalisarIPs");
				}
			}]
		});


		let abas = [];
		abas.push({
			label: this.getMsg("FP_FRONT_AgendamentoProvaVH_151"),
            id: "agruparPorIP",
			onClick: () => {this.exibirListagemAgrupadoPorIP(listaAgendamentoEmAndamentoUsuarioTOComIPs)}
		});
		abas.push({
			label: this.getMsg("FP_FRONT_AgendamentoProvaVH_152"),
			id: "agruparPorOcteto",
			onClick: () => {this.exibirListagemAgrupadoPorOcteto(listaAgendamentoEmAndamentoUsuarioTOComIPs)}
		});

		this.addAbas({ 
			abas, 
			numAbaAtiva: 1, 
			idAlvo: "popupAnalisarIPs", 
			noHref: true
		});

		this.append("<div id='divListagemIPs'></div>");
		this.exibirPopups();

		//Workaround para vir com a primeira aba ativa no popup
		$('a[id="agruparPorIP"]').click();
	}

	exibirListagemAgrupadoPorOcteto(listaAgendamentoEmAndamentoUsuarioTOComIPs) {

		this.setIdTarget("divListagemIPs", true);
		const contagemPrimeiroOctetos = this.agruparPorOcteto(listaAgendamentoEmAndamentoUsuarioTOComIPs, 0);

		this.addListagemHierarquica(contagemPrimeiroOctetos, {
			label: "",
			propIp: "listagemIPs",
			width: "470px",
			propLabel: "ip",
			metodoMontarLabel: (grupo) => {

				let msgAvaliados = "";
				if (grupo.qntAvaliados) {
					msgAvaliados = ` (${grupo.qntAvaliados} ${((grupo.qntAvaliados > 1)
						? this.getMsg("FP_FRONT_AgendamentoProvaVH_148")
						: this.getMsg("FP_FRONT_AgendamentoProvaVH_149"))})`;
				}

				return grupo.octeto.toString() +
					((grupo.nomeUsuario)
						? " <strong>" + grupo.nomeUsuario + "</strong>"
						: "") + msgAvaliados;

			},
			metodoMostrarFilhos: (link, toGrupo) => {
				return this.agruparPorOcteto(
					listaAgendamentoEmAndamentoUsuarioTOComIPs.filter(to => to.ip.startsWith(toGrupo.octeto + ".")),
					toGrupo.nivelHierarquia + 1
				);
			},

		});

		this.exibir();
	}

	exibirListagemAgrupadoPorIP(listaAgendamentoEmAndamentoUsuarioTOComIPs) {

		this.setIdTarget("divListagemIPs", true);

		const ipListCompleta =
			this.agruparIPs(JSON.parse((JSON.stringify(listaAgendamentoEmAndamentoUsuarioTOComIPs))));

		this.addListagemHierarquica(ipListCompleta, {
			label: "",
			width: "470px",
			propLabel: "ip",
			metodoMontarLabel: (ipMap) => {
				if (ipMap.nivelHierarquia > 0) {
					return "<strong>" + ipMap.nomeUsuario + "</strong>";
				} else {
					return ipMap.ip + " (" + ipMap.agendamentos.length + " " +
						this.getMsg("FP_FRONT_AgendamentoProvaVH_148") + ")";
				}
			},
			metodoMostrarFilhos: (link, to) => {
				return to.agendamentos;
			},
		});

		this.exibir();
	}

	private agruparIPs(listaAgendamentoEmAndamentoUsuarioTOComIPs) {
		const ipMaps = [4, 3, 2, 1].map(octeto =>
			Array.from(this.agruparPorIP(listaAgendamentoEmAndamentoUsuarioTOComIPs, octeto).entries(),
				([key, value]) => ({ip: key, agendamentos: value, numFilhos: value.length}))
                .sort((a, b) => b.numFilhos - a.numFilhos)
		);
		const ipMapUnicos = new Map();
		listaAgendamentoEmAndamentoUsuarioTOComIPs.forEach(obj => ipMapUnicos.set(obj.ip, [obj]));
		const ipListUnicos = Array.from(ipMapUnicos.entries(),
			([key, value]) => ({ip: key, agendamentos: value, numFilhos: value.length}))
			.sort((a, b) => b.numFilhos - a.numFilhos);
		return [].concat(...ipMaps, ipListUnicos);
	}

	private agruparPorIP(listaAgendamentoEmAndamentoUsuarioTOComIPs, nivelOcteto) {
		const ipMap = new Map();

		listaAgendamentoEmAndamentoUsuarioTOComIPs.forEach(obj => {
			const ip = obj.ip.split('.').slice(0, nivelOcteto).join('.');
			if (ipMap.has(ip)) {
				ipMap.get(ip)!.push(obj);
			} else {
				ipMap.set(ip, [obj]);
			}
		});

		ipMap.forEach((list, ip) => {
			if (list.length > 1) {
				list.forEach(agendamento => {
					const index = listaAgendamentoEmAndamentoUsuarioTOComIPs.findIndex(obj => obj.ip == agendamento.ip);
					if (index != -1) {
						listaAgendamentoEmAndamentoUsuarioTOComIPs.splice(index, 1);
					}
				});
			} else if (list.length <= 1) {
				ipMap.delete(ip);
			}
		});
		return ipMap;
	}

	agruparPorOcteto(tos, nivelHierarquia) {

		if (nivelHierarquia < 3) {
				
			const grupos = {};

			tos.forEach((agendamentoEmAndamentoUsuarioTO) => {

				const octetoAtual = agendamentoEmAndamentoUsuarioTO.ipSplitted[nivelHierarquia];
				const proxOcteto = agendamentoEmAndamentoUsuarioTO.ipSplitted[nivelHierarquia + 1];
				
				let grupo = grupos[octetoAtual];
				
				if (!grupo) {
					const nomeGrupo = agendamentoEmAndamentoUsuarioTO.ipNiveis[nivelHierarquia];
					grupo = {
						octeto: nomeGrupo,
						numFilhos: 0,
						qntAvaliados: tos.filter(to => to.ip.startsWith(nomeGrupo + ".")).length,
						nivelHierarquia: nivelHierarquia,
						ips: []
					};
					grupos[octetoAtual] = grupo;
				}

				if (!grupo.ips.includes(proxOcteto)){
					grupo.ips.push(proxOcteto);
					grupo.numFilhos++;
				}
			});

			return Object.values(grupos).sort((a: any, b: any) => b.qntAvaliados - a.qntAvaliados);

		} else {
			return tos.map(agendamentoEmAndamentoUsuarioTO => {
				return {
					octeto: agendamentoEmAndamentoUsuarioTO.ip,
					numFilhos: 0,
					nivelHierarquia: nivelHierarquia,
					nomeUsuario: agendamentoEmAndamentoUsuarioTO.nomeUsuario
				};
			});
		}
	}

	private async exibirIPs() {

		this.setExibirSalaDeAvaliacaoComIPs(!this.isExibirDispositivosNaSalaDeAvaliacao());

		await this.exibirSalaDeAvaliacao(this.salaAvaliacaoTO.codAgendamento, true);
	}

	private exibirBotoesAvaliado(salaAvaliacaoTO: SalaAvaliacaoTO, codAgendamento: number) {

		const exibirBotoesAvaliado = (agendamentoEmAndamentoUsuarioTO) => {
			let b = [`
				<div class="btn-group pull-right">
			`];

			if (salaAvaliacaoTO.codUsuarioFiscal &&
				!salaAvaliacaoTO.dataEncerramento &&
				(agendamentoEmAndamentoUsuarioTO.isCadernoPreGerado || !agendamentoEmAndamentoUsuarioTO.codProvaFeita) &&
				!agendamentoEmAndamentoUsuarioTO.isLiberado
			) {

				if (!agendamentoEmAndamentoUsuarioTO.isAusente) {
					b.push(`
					<a class='btn btn-primary btn-table'
					 onclick='fiscalizacaoVH.exibirDadosAlunoParaLiberacao(${agendamentoEmAndamentoUsuarioTO.codAgendamentoUsuario})'
					 title='${this.getMsg("FP_FRONT_FiscalizacaoVH_024")}'>
						<i class='fa fa-calendar-check-o'></i>
					</a>
				`);
				} else {
					b.push(`
					<a class='btn btn-warning btn-table'
					 onclick='fiscalizacaoVH.exibirDadosAlunoParaLiberacao(${agendamentoEmAndamentoUsuarioTO.codAgendamentoUsuario})'
					 title='${this.getMsg("FP_FRONT_FiscalizacaoVH_023")}'>
						<i class='fa fa-calendar-times-o'></i>
					</a>
				`);
				}
			}

			if (salaAvaliacaoTO.codUsuarioFiscal &&
				!salaAvaliacaoTO.dataEncerramento &&
				(agendamentoEmAndamentoUsuarioTO.isCadernoPreGerado || !agendamentoEmAndamentoUsuarioTO.codProvaFeita) &&
				agendamentoEmAndamentoUsuarioTO.isLiberado
				&& !agendamentoEmAndamentoUsuarioTO.isAusente) {
				b.push(`
					<a class='btn btn-table' style="color: #333; background-color: #fff; border-color: #ccc;"
					 onclick='agendamentoProvaVH.cancelarLiberacaoProva(${agendamentoEmAndamentoUsuarioTO.codAgendamentoUsuario})'
					 title='${this.getMsg("FP_FRONT_FiscalizacaoVH_001")}'>
						<i class='fa fa-calendar-times-o'></i>
					</a>
				`);
			}

			if (agendamentoEmAndamentoUsuarioTO.numRecursos > 0) {
				b.push(`
					<a class='btn btn-default' onclick='recursoVH.listarRecursosDaProva(null, ${salaAvaliacaoTO.codProva}, null)' title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_050")}'>
						<i class='fa fa-legal'></i>
					</a>
				`);
			}

			if (salaAvaliacaoTO.isFacematchHabilitado && this.hasValue(agendamentoEmAndamentoUsuarioTO.tipoFaceMatchLog)) {
				const tipoFaceMatchLog = agendamentoEmAndamentoUsuarioTO.tipoFaceMatchLog;

				const isRevalidacao = [
					TipoFaceMatchLog.REVALIDACAO_MANUAL_SOLICITADA,
					TipoFaceMatchLog.REVALIDACAO_MANUAL_ACEITA,
					TipoFaceMatchLog.REVALIDACAO_MANUAL_RECUSADA,
					TipoFaceMatchLog.REVALIDACAO_SUCESSO
				].includes(tipoFaceMatchLog);

				if ([TipoFaceMatchLog.VALIDACAO_MANUAL_SOLICITADA, TipoFaceMatchLog.REVALIDACAO_MANUAL_SOLICITADA].includes(tipoFaceMatchLog)) {
					b.push(`
						<a class='btn btn-warning btn-table' 
							onclick='agendamentoProvaVH.modalConfirmacaoManualFaceMatch(${agendamentoEmAndamentoUsuarioTO.codProvaFeita}, ${codAgendamento}, ${isRevalidacao})' 
							title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_051")}'>
							<i class='fa fa-user-circle fa-lg'></i>
						</a>
					`);

				} else if ([TipoFaceMatchLog.VALIDACAO_MANUAL_ACEITA, TipoFaceMatchLog.REVALIDACAO_MANUAL_ACEITA, 
						TipoFaceMatchLog.VALIDACAO_MANUAL_RECUSADA, TipoFaceMatchLog.REVALIDACAO_MANUAL_RECUSADA, 
						TipoFaceMatchLog.VALIDACAO_SUCESSO, TipoFaceMatchLog.REVALIDACAO_SUCESSO].includes(tipoFaceMatchLog)) {

					let titulo = "";
					let classe = `btn-default`;
					let style = "";

					if (tipoFaceMatchLog === TipoFaceMatchLog.VALIDACAO_MANUAL_ACEITA) {
						titulo = this.getMsg("FP_FRONT_AgendamentoProvaVH_157");
					} else if (tipoFaceMatchLog === TipoFaceMatchLog.VALIDACAO_MANUAL_RECUSADA) {
						classe = `btn-danger`;
						titulo = this.getMsg("FP_FRONT_AgendamentoProvaVH_158");
					} else if (tipoFaceMatchLog === TipoFaceMatchLog.VALIDACAO_SUCESSO) {
						style = `style="opacity: 0.5"`;
						titulo = "Sucesso no reconhecimento automático";
					}

					b.push(`
						<a class="btn ${classe} btn-table" ${style} title="${titulo}"
							onclick="agendamentoProvaVH.modalEdicaoManualFaceMatch(${agendamentoEmAndamentoUsuarioTO.codProvaFeita}, ${codAgendamento}, ${isRevalidacao})">
							<i class="fa fa-user-circle fa-lg"></i>
						</a>
					`);
				}
			}

			if (salaAvaliacaoTO.isFacematchHabilitado && salaAvaliacaoTO.isAvaliadoPodeProsseguirSemFacematch && agendamentoEmAndamentoUsuarioTO.isAguardandoValidacaoFacematch) {
				b.push(`
					<a class='btn btn-warning btn-table' 
						onclick='agendamentoProvaVH.modalConfirmacaoManualFaceMatch(${agendamentoEmAndamentoUsuarioTO.codProvaFeita}, ${codAgendamento})' 
						title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_051")}'>
						<i class='fa fa-user-circle fa-lg'></i>
					</a>
				`);
			}

			if (salaAvaliacaoTO.isFacematchHabilitado && salaAvaliacaoTO.isAvaliadoPodeProsseguirSemFacematch && agendamentoEmAndamentoUsuarioTO.isAguardandoRevalidacaoFacematch) {
				b.push(`
					<a class='btn btn-warning btn-table' 
						onclick='agendamentoProvaVH.modalConfirmacaoManualFaceMatch(${agendamentoEmAndamentoUsuarioTO.codProvaFeita}, ${codAgendamento}, true)' 
						title='${this.getMsg("FP_FRONT_AgendamentoSalaVH_106")}'>
						<i class='fa fa-user-circle fa-lg'></i>
					</a>
				`);
			}

			if (this.isAdministrador() && salaAvaliacaoTO.isFacematchHabilitado && agendamentoEmAndamentoUsuarioTO.documentoIdentificacao) {
				b.push(`
					<a class='btn btn-default btn-table' 
						onclick='agendamentoProvaVH.modalDocumentoIdentificacao(${agendamentoEmAndamentoUsuarioTO.codUsuario}, "${agendamentoEmAndamentoUsuarioTO.documentoIdentificacao}", "${agendamentoEmAndamentoUsuarioTO.cpfUsuario}")' 
						title='${this.getMsg("FP_FRONT_AgendamentoSalaVH_096")}'>
						<i class='far fa-address-card fa-lg'></i>
					</a>
				`);
			}

			if (this.isAdministrador() && salaAvaliacaoTO.isScreenshotHabilitado && agendamentoEmAndamentoUsuarioTO.dataInicio && salaAvaliacaoTO.tokenJwtProctoring && agendamentoEmAndamentoUsuarioTO.tokenProctoring) {
				b.push(`
					<a class='btn btn-default btn-table' 
						onclick='agendamentoProvaVH.modalScreenshots("${salaAvaliacaoTO.tokenJwtProctoring}", "${agendamentoEmAndamentoUsuarioTO.tokenProctoring}")'
						title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_123")}'>
						<i class='fa fa-desktop fa-lg'></i>
					</a>
				`);
			}

			if (salaAvaliacaoTO.possuiConfirmacaoInscricao) {
				if (!agendamentoEmAndamentoUsuarioTO.statusInscricao || agendamentoEmAndamentoUsuarioTO.statusInscricao == StatusInscricao.PENDENTE) {
					b.push(`
						<a class='btn btn-default btn-table' 
							onclick='agendamentoProvaVH.modalConfirmacaoInscricao(${agendamentoEmAndamentoUsuarioTO.codAgendamentoUsuario}, ${codAgendamento})'
							title='Confirmar inscrição'>
							<i class="fas fa-circle text-danger"></i>
						</a>
					`)
				} else if (agendamentoEmAndamentoUsuarioTO.statusInscricao == StatusInscricao.CONFIRMADO && !agendamentoEmAndamentoUsuarioTO.dataInicio) {
					b.push(`
						<a class='btn btn-default btn-table' 
							onclick='agendamentoProvaVH.modalReversaoConfirmacaoInscricao(${agendamentoEmAndamentoUsuarioTO.codAgendamentoUsuario}, ${codAgendamento})'
							title='Reverter confirmação de inscrição'>
							<i class="fas fa-circle text-success"></i>
						</a>
					`)
				}
			}

			if (agendamentoEmAndamentoUsuarioTO.possuiDocumentoCota) {
				b.push(`
					<a class="btn btn-default btn-table" 
						onclick="controleCotaVH.modalControleCotas(${agendamentoEmAndamentoUsuarioTO.codAgendamentoUsuario})"
						title="${this.getMsg("FP_FRONT_AgendamentoProvaVH_187")}">
						<i class="fas fa-id-badge fa-lg"></i>
					</a>
				`);
			}

			b.push(`
				</div>
			`);
			return b.join("");
		}
		return exibirBotoesAvaliado;
	}

	private exibirPorcentagemConclusaoProva(agendamentoEmAndamentoUsuarioTO) {

		const tempoParaResposta = agendamentoEmAndamentoUsuarioTO.tempoParaResposta;
		const dataInicio: number = UtilData.toMillis(agendamentoEmAndamentoUsuarioTO.dataInicio);
		const agora: number = UtilData.toMillis(this.salaAvaliacaoTO.agora);

		if (tempoParaResposta != null && dataInicio != null) {
			const dataReinicio = UtilData.toMillis(agendamentoEmAndamentoUsuarioTO.dataReinicio);
			const dataFim = UtilData.toMillis(agendamentoEmAndamentoUsuarioTO.dataFim);
			const ultimoKA = UtilData.toMillis(agendamentoEmAndamentoUsuarioTO.dataUltimoKeepAlive);
			const segundosRestantes = agendamentoEmAndamentoUsuarioTO.segundosRestantes;
			let evolucao;

			if (this.salaAvaliacaoTO.isInterrupcoesParamCronometro) {
				let ultimoInicio = dataInicio;

				if (dataReinicio != null) {
					ultimoInicio = dataReinicio;
				}

				let segundosDecorridosPosUltimoInicio = 0;
				let ultimoSinal = dataFim;

				if (ultimoSinal == null && ultimoKA != null && ultimoKA > ultimoInicio) {
					ultimoSinal = ultimoKA;
				}

				if (ultimoSinal != null) {
					segundosDecorridosPosUltimoInicio = Math.round((ultimoSinal - ultimoInicio) / 1000);
				}

				let segundosRestantesPosUltimoInicio = tempoParaResposta;

				if (segundosRestantes != null) {
					segundosRestantesPosUltimoInicio = segundosRestantes;
				}

				evolucao = tempoParaResposta - (segundosRestantesPosUltimoInicio - segundosDecorridosPosUltimoInicio);

			} else {
				let ultimoSinal = dataFim;

				if (ultimoSinal == null) {
					ultimoSinal = agora;
				}

				evolucao = Math.round((ultimoSinal - dataInicio) / 1000);
			}

			if (evolucao > tempoParaResposta) {
				evolucao = tempoParaResposta;
			} else if (evolucao < 0) {
				evolucao = 0;
			}

			if (dataFim) {
				return UtilData.getDuracao({segundos: evolucao});
			}

			return UtilProgressBar.basic({
				evolucao: evolucao,
				total: tempoParaResposta,
				css: "height: 16px",
				label: UtilData.getDuracao({segundos: evolucao})
			})
		} else {
			return ""
		}
	}

	atualizarSalaAvaliacao() {
		$("#botaoAtualizarSalaAvaliacao").data("ultima-atualizacao", Date.now());
		let $sala = $("#sala_de_avaliacao");
		if ($sala.length > 0 && $sala.is(":visible")) {
			let codAgendamento = $sala.data("fp-cod-agendamento");
			if (codAgendamento) {
				this.exibirSalaDeAvaliacao(codAgendamento, false);
			}
		}
	}

	async exibirSalaVirtualScreenshots(jwtProctoring: any, applicationId: any){
		const pageSize = 60;
		this.addPopup({
			id: "modal_sala_virtual_screenshots",
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_127"),
			width: "90%",
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_125"),
				classe: "btn-primary",
				onClick: () => this.fecharPopup("modal_sala_virtual_screenshots")
			}]
		});

		this.append(`
			<div id="barra_superior_screenshot" class="barra_superior_screenshot col-md-12"></div>
			<div class="col-md-12 body_screenshots" id="body_screenshots"></div>
			<div class="col-md-12" id="body_screenshots_paginador"></div>
		`);

		this.exibirPopups();

		let response = await this.getScreenshotsSalaVirtualProctoring(jwtProctoring, applicationId, 1, pageSize);
		const salaAvaliacaoTO = await this.getDadosSalaAvaliacao(this.codAgendamentoDaSalaDeAvaliacao);
		await this.exibirScreenshotsSalaVirtual(response, salaAvaliacaoTO);

		this.setIdTarget("barra_superior_screenshot");

		this.addBotaoAtualizarScreenshots(async () => {
			let response = await this.getScreenshotsSalaVirtualProctoring(jwtProctoring, applicationId, 1, pageSize);
			await this.exibirScreenshotsSalaVirtual(response, salaAvaliacaoTO);
			this.atualizarPaginadorSalaVirtualProctoring(jwtProctoring, applicationId, response, pageSize, salaAvaliacaoTO);
			$("#botaoAtualizarScreenshots").data("ultima-atualizacao", Date.now());
			this.atualizandoScreeshots = false;
		});

		this.atualizarPaginadorSalaVirtualProctoring(jwtProctoring, applicationId, response, pageSize, salaAvaliacaoTO);

		this.exibir();

		this.tsInicioAtualizacaoScreenshots = Date.now();
		this.atualizarAutomaticamenteScreenshots(this.tsInicioAtualizacaoScreenshots);
	}

	atualizarPaginadorSalaVirtualProctoring(jwtProctoring: any, applicationId: any, response: any, pageSize: any, salaAvaliacaoTO: SalaAvaliacaoTO){
		$("#body_screenshots_paginador").html("");

		this.setIdTarget("body_screenshots_paginador");

		this.append(UtilPaginacao.criarPaginador({
			onCarregarPagina: async (paginacaoTO: any) => {
				let response = await this.getScreenshotsSalaVirtualProctoring(jwtProctoring, applicationId, Number(paginacaoTO.numPaginaAtual) + 1, pageSize);
				await this.exibirScreenshotsSalaVirtual(response, salaAvaliacaoTO);
			},
			paginacaoTO: {
				numPaginaAtual: response.page - 1,
				numTotalItens: response.totalObjects,
				numItensPorPagina: pageSize,
			}
			// itensPorPagina: pageSize,
			// totalItens: response.totalObjects,
			// paginaAtiva: response.page - 1
		}));

		this.setIdTarget(null);

		this.exibir();
	}

	async modalScreenshots(jwtProctoring: any, tokenProctoring: any){
		const pageSize = 60;
		this.addPopup({
			id: "modal_screenshots_avaliado",
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_124"),
			width: "80%",
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_125"),
				classe: "btn-primary",
				onClick: () => this.fecharPopup("modal_screenshots_avaliado")
			}]
		});

		this.append(`
			<div id="barra_superior_screenshot" class="barra_superior_screenshot col-md-12"></div>
			<div class="col-md-12 body_screenshots" id="body_screenshots"></div>
			<div class="col-md-12" id="body_screenshots_paginador"></div>
		`);

		this.exibirPopups();

		let response = await this.getScreenshotsProctoring(jwtProctoring, tokenProctoring, 1, pageSize);
		await this.exibirListagemScreenshots(response);

		this.setIdTarget("barra_superior_screenshot");

		this.addBotaoAtualizarScreenshots(async () => {
			let response = await this.getScreenshotsProctoring(jwtProctoring, tokenProctoring, 1, pageSize);
			await this.exibirListagemScreenshots(response);
			this.atualizarPaginadorScreenshots(jwtProctoring, tokenProctoring, response, pageSize);
			$("#botaoAtualizarScreenshots").data("ultima-atualizacao", Date.now());
			this.atualizandoScreeshots = false;
		});

		this.atualizarPaginadorScreenshots(jwtProctoring, tokenProctoring, response, pageSize);

		this.exibir();

		this.tsInicioAtualizacaoScreenshots = Date.now();
		this.atualizarAutomaticamenteScreenshots(this.tsInicioAtualizacaoScreenshots);
	}

	addBotaoAtualizarScreenshots(callback: any){

		this.addFormulario({id: "formAtualizarScreenshots"});

		this.addCheckbox({
			id: "isAtualizarScreenshotsAutomaticamente",
			label: "Atualizar automaticamente",
			valor: true,
			onChange: () => {
				const $botao = $("#botaoAtualizarScreenshots");

				if ($botao.length == 0) return;
				const isAtualizar = this.getValor('isAtualizarScreenshotsAutomaticamente');

				$botao.data('atualizacao-automatica', isAtualizar);

				if(isAtualizar === true) {
					this.tsInicioAtualizacaoScreenshots = Date.now();
					$botao.data("ultima-atualizacao", this.tsInicioAtualizacaoScreenshots);
					this.atualizarAutomaticamenteScreenshots(this.tsInicioAtualizacaoScreenshots);
				}
			}
		});

		this.addBotao({
			id: "botaoAtualizarScreenshots",
			label: `<i class='fa fa-refresh'></i> ${this.getMsg("FP_FRONT_DashboardProvaVH_008")}`,
			onClick: async () => callback()
		});

		this.fecharFormulario();
	}

	atualizarPaginadorScreenshots(jwtProctoring: any, tokenProctoring: any, response: any, pageSize: any){
		$("#body_screenshots_paginador").html("");

		this.setIdTarget("body_screenshots_paginador");

		this.append(UtilPaginacao.criarPaginador({
			onCarregarPagina: async (paginacaoTO: any) => {
				let response = await this.getScreenshotsProctoring(jwtProctoring, tokenProctoring, Number(paginacaoTO.numPaginaAtual) + 1, pageSize);
				await this.exibirListagemScreenshots(response);
			},
			paginacaoTO: {
				numPaginaAtual: response.page - 1,
				numTotalItens: response.totalObjects,
				numItensPorPagina: pageSize,
			}
			// itensPorPagina: pageSize,
			// totalItens: response.totalObjects,
			// paginaAtiva: response.page - 1
		}));

		this.setIdTarget(null);

		this.exibir();
	}

	async createThumbScreenshot(screenshot: any, extraInfos?: any){
		return `
			<div class="col-md-3">
				<div class="thumbnail thumbnail-screenshot col-md-12">
					<a href="${screenshot.url}" target="_blank">
						<div class="spinner-screenshot">
							<i class="fa fa-spinner fa-lg" aria-hidden="true"></i>
						</div>				
						<img src="${screenshot.thumb}" alt="" class="images-screenshot">
						<div class="caption">
							${UtilData.toDDMMYYYYHHMMSS(screenshot.created)}
							${extraInfos ? extraInfos: ''}
						</div>
					</a>
				</div>
			</div>
		`;
	}

	async irParaScreenshotsDoUsuario(tokenJwtProctoring: any, token: any){
		this.fecharPopup('modal_sala_virtual_screenshots');
		await agendamentoProvaVH.modalScreenshots(tokenJwtProctoring, token);
	}

	async exibirScreenshotsSalaVirtual(response: any, salaAvaliacaoTO: SalaAvaliacaoTO){
		$("#body_screenshots").html("");
		this.setIdTarget("body_screenshots");
		if(response && response.sessions && response.sessions.length > 0){
			for(let session of response.sessions){
				if(session.screenshot){
					let alert = "";
					let screenshot = session.screenshot;
					const agendamentoUsuario = salaAvaliacaoTO.collectionAgendamentoEmAndamentoUsuarioTO.find(
						(a: any) =>  a.tokenProctoring === session.token
					);
					if(session.screenshot && screenshot.created && agendamentoUsuario && (agendamentoUsuario.dataInicio || agendamentoUsuario.dataReinicio) && !agendamentoUsuario.dataFim){
						const lastScreenshot = moment(screenshot.created);
						if(moment().diff(lastScreenshot, 'minutes') >= 5){
							alert = `
								<i title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_129")}' class="fa fa-exclamation-triangle" style="color: red" aria-hidden="true"></i>
							`;
						}
					}

					if(screenshot.eyes_open === false || screenshot.face_is_clear === false || screenshot.face_is_not_occluded === false || screenshot.has_face === false || screenshot.has_one_face === false){
						let htmlTooltip = this.buildTooltipDeteccoes(screenshot);
						alert += `
							<i title='${htmlTooltip}' data-html="true" data-placement="bottom" data-toggle="tooltip" class="fa fa-exclamation-circle" style="color: red" aria-hidden="true"></i>
						`;
					}

					const extraInfos = `
						<div class="extra-infos">
							<div class="descricao-screenshot">
								<span class="descricao-span">
									${alert} <span title="${session.description}">${session.description}</span>
								</span>
							</div>
							<a title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_128")}' onclick="agendamentoProvaVH.irParaScreenshotsDoUsuario('${salaAvaliacaoTO.tokenJwtProctoring}', '${session.token}')">
								<i class="fa fa-desktop" aria-hidden="true"></i>
							</a>
						</div>
					`
					this.append(await this.createThumbScreenshot(screenshot, extraInfos));
				}
			}
		} else {
			this.append(`
				<div id='alertNoScreenshots' class='alert alert-success col-md-12'>
					<strong>${this.getMsg("FP_FRONT_AgendamentoProvaVH_141")}</strong>
				</div>
			`);
		}

		this.setIdTarget(null);
		this.exibir();

		$(".images-screenshot").on('load', (event: any) => {
			$(event.target).css('opacity', 1);
			$(event.target).prev('.spinner-screenshot').hide();
		});
	}

	buildTooltipDeteccoes(screenshot: any){
		const atendido = `
			<span class="screenshot-deteccoes-atendido">
				<i class="fa fa-check"></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_134")}
			</span>
		`;

		const naoAtendido = `
			<span class="screenshot-deteccoes-naoatendido">
				<i class="fa fa-exclamation-triangle"></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_135")}
			</span>
		`;

		return `
			<div class="screenshot-deteccoes">
				<ul>
					<li>${this.getMsg("FP_FRONT_AgendamentoProvaVH_136")}: ${screenshot.face_is_clear === true ? atendido : naoAtendido}</li>
					<li>${this.getMsg("FP_FRONT_AgendamentoProvaVH_137")}: ${screenshot.has_face === true ? atendido : naoAtendido}</li>
					<li>${this.getMsg("FP_FRONT_AgendamentoProvaVH_138")}: ${screenshot.eyes_open === true ? atendido : naoAtendido}</li>
					<li>${this.getMsg("FP_FRONT_AgendamentoProvaVH_139")}: ${screenshot.face_is_not_occluded === true ? atendido : naoAtendido}</li>
					<li>${this.getMsg("FP_FRONT_AgendamentoProvaVH_140")}: ${screenshot.has_one_face === true ? atendido : naoAtendido}</li>
				</ul>
			</div>
		`;
	}

	async exibirListagemScreenshots(response: any){
		$("#body_screenshots").html("");
		this.setIdTarget("body_screenshots");
		if(response.screenshots && response.screenshots.length > 0){
			for(let screenshot of response.screenshots){
				let alert = '';
				if(screenshot.eyes_open === false || screenshot.face_is_clear === false || screenshot.face_is_not_occluded === false || screenshot.has_face === false || screenshot.has_one_face === false){
					let htmlTooltip = this.buildTooltipDeteccoes(screenshot);
					alert += `
						<div class="extra-infos">
							<span>
								<i title='${htmlTooltip}' data-html="true" data-placement="bottom" data-toggle="tooltip" class="fa fa-exclamation-circle" style="color: red" aria-hidden="true"></i>
							</span>
						</div>
					`;
				}

				this.append(await this.createThumbScreenshot(screenshot, alert));
			}
		} else{
			this.append(`
				<div id='alertNoScreenshots' class='alert alert-success col-md-12'>
					<strong>${this.getMsg("FP_FRONT_AgendamentoProvaVH_126")}</strong>
				</div>
			`);
		}

		this.setIdTarget(null);
		this.exibir();

		$(".images-screenshot").on('load', (event: any) => {
			$(event.target).css('opacity', 1);
			$(event.target).prev('.spinner-screenshot').hide();
		});
	}

	atualizarAutomaticamenteScreenshots(tsInicioAtualizacao: number) {

		if (tsInicioAtualizacao !== this.tsInicioAtualizacaoScreenshots) return;

		const $botao = $("#botaoAtualizarScreenshots");

		if ($botao.length == 0) return;

		const agora = Date.now();
		let tsUltima = $botao.data("ultima-atualizacao");
		let atualizacaoHabilitada = $botao.data("atualizacao-automatica");

		if(atualizacaoHabilitada == false) {
			$botao.css('background', '');
			return;
		}

		if (tsUltima == null) {
			$botao.data("ultima-atualizacao", agora);
			tsUltima = agora;
		}

		const porcentagemPercorrida = Math.round(100 * ((agora - tsUltima) / 1000) / 60);

		$botao.css("background", `linear-gradient(90deg, #ddd ${porcentagemPercorrida}%, white 0%)`);

		if (porcentagemPercorrida >= 100 && this.atualizandoScreeshots === false) {
			this.atualizandoScreeshots = true;
			$botao.click();
		}

		setTimeout(() => this.atualizarAutomaticamenteScreenshots(tsInicioAtualizacao), 1000);
	}

	async getRequestOptionsProctoring(jwtProctoring: any){
		const myHeaders = new Headers();
		myHeaders.append("Authorization", `JWT ${jwtProctoring}`);

		return {
			method: 'GET',
			headers: myHeaders
		};
	}

	async getScreenshotsSalaVirtualProctoring(jwtProctoring: any, applicationId: any, page: any, pageSize: any){
		let divMsgCarregando = AmaisVH.criarDivMsgAjax(
			this.getMsg("FP_FRONT_AmaisVH_036"),
			true
		);

		const requestOptions = await this.getRequestOptionsProctoring(jwtProctoring);

		let response = await fetch(
			`${this.proctoringBackendUrl}/screenshot/grouped-application/?application_id=${applicationId}&page=${page}&page_size=${pageSize}`,
			requestOptions
		);

		await divMsgCarregando?.fadeOut(() => {
			divMsgCarregando?.remove();
			divMsgCarregando = null;
		});

		const sessions = await response.json();

		return {
			sessions: sessions,
			page: +response.headers.get("X-Page"),
			pageSize: +response.headers.get("X-Per-Page"),
			totalObjects: +response.headers.get("X-Total-Objects"),
			totalPages: +response.headers.get("X-Total-Pages")
		};
	}

	async getScreenshotsProctoring(jwtProctoring: any, tokenProctoring: any, page: any, pageSize: any){
		let divMsgCarregando = AmaisVH.criarDivMsgAjax(
			this.getMsg("FP_FRONT_AmaisVH_036"),
			true
		);

		const requestOptions = await this.getRequestOptionsProctoring(jwtProctoring);

		let response = await fetch(
			`${this.proctoringBackendUrl}/screenshot/?session_id=${tokenProctoring}&page=${page}&page_size=${pageSize}`,
			requestOptions
		);

		await divMsgCarregando?.fadeOut(() => {
			divMsgCarregando?.remove();
			divMsgCarregando = null;
		});

		const screenshots = await response.json();

		return {
			screenshots: screenshots,
			page: +response.headers.get("X-Page"),
			pageSize: +response.headers.get("X-Per-Page"),
			totalObjects: +response.headers.get("X-Total-Objects"),
			totalPages: +response.headers.get("X-Total-Pages")
		};
	}

	modalDocumentoIdentificacao(codUsuario: number, pathDocumento: string, cpfUsuario?: string){
		this.addPopup({
			id: "modal_documento_identificacao",
			titulo: this.getMsg("FP_FRONT_AgendamentoSalaVH_096"),
			width: "60%",
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoSalaVH_097"),
				classe: "btn-warning",
				onClick: async () => {
					await this.exibirAlerta({
						msg: this.getMsg("FP_FRONT_AgendamentoSalaVH_100"),
						botoes: [{
							label: this.getMsg("FP_FRONT_AgendamentoSalaVH_099"),
							onClick: async () => {
								await this.call("CadastroUsuarioFCD/excluirDocumentosIdentificacao", [codUsuario]);
								if (this.salaAvaliacaoTO?.codAgendamento) {
									await this.exibirSalaDeAvaliacao(this.salaAvaliacaoTO.codAgendamento, true);
								}
							}
						}]
					})
				}
			}, {
				label: this.getMsg("FP_FRONT_AgendamentoSalaVH_098"),
				classe: "btn-primary",
				onClick: () => this.fecharPopup("modal_documento_identificacao")
			}]
		});

		this.append(`
			<div class="col-md-12" style="display: flex;justify-content: center;">
				<img src="${UtilArmazenamento.getPathArmazenamento(pathDocumento)}" alt="Documento Identificação"/>
			</div>
		`);

		this.addEspacamentoHorizontal("10px");

		if (cpfUsuario) {
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_165"),
				valor: cpfUsuario.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
			})
		}

		this.exibirPopups();
	}

	preGerarCadernosDeQuestoes(codAgendamento: number) {
		
		this.addPopup({
			titulo: "Pré-geração de cadernos",
			botoes: [{
				label: "Iniciar pré-geração", 
				classe: "btn-primary", 
				onClick: async () => {
					await this.call("AgendamentoProvaFCD/preGerarCadernosDeQuestoes", codAgendamento);
					await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AgendamentoProvaVH_153") });
				}
			}]
		});

		this.addTexto(`
			É possível que todos os cadernos de questões dos avaliados sejam gerados previamente. 
			Para isso, é preciso que a prova esteja com todas suas informações (seções, questões, gabaritos, etc)
			já em situação definitiva, pois não será possível desfazer esse processo.
			<br><br>
			Este processo só será executado previamente ao período que os avaliados têm para iniciar
			sua prova. Caso este processo esteja em execução e este período se inicie, ele será suspenso neste momento.
			<br><br>
			Deseja continuar?
		`);

		this.exibirPopups();
	}

	filtrarSalaAvaliacao(link) {
		if (link) {
			const texto = $(link).text().trim();
			const $linhas = $("#listagemAvaliados tbody td:nth-child(3)").closest("tr");
			if (texto == this.getMsg('FP_FRONT_AgendamentoProvaVH_028')) {
				const emProva = this.getMsg('FP_FRONT_AgendamentoProvaVH_030');
				$linhas.each(function() {
					const conteudo = $(this).find("td:nth-child(3)").text().trim().replace(/\s+/g, ' ');
					if (!conteudo.includes(emProva)) {
						$(this).show();
					} else {
						$(this).hide();
					}
				});
			} else {
				$linhas.each(function() {
					const conteudo = $(this).find("td:nth-child(3)").text().trim().replace(/\s+/g, ' ');
					if (conteudo.includes(texto)) {
						$(this).show();
					} else {
						$(this).hide();
					}
				});
			}
		} else {
			$("#listagemAvaliados tbody tr").show();
		}
	}

	async modalConfirmacaoManualFaceMatch(codProvaFeita: number, codAgendamento?: number, isRevalidacao?) {

		const to = await this.call("FaceMatchFCD/recuperarFotosAnaliseManual", codProvaFeita, isRevalidacao);
		const titulo = isRevalidacao ? this.getMsg("FP_FRONT_AgendamentoSalaVH_103") : this.getMsg("FP_FRONT_AgendamentoProvaVH_058");
		
		this.addPopup({
			id: "modal_validacao_manual",
			width: "80%",
			titulo: titulo,
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_059"),
				classe: "btn-danger",
				onClick: async () => {

					await this.call("FaceMatchFCD/reprovarAnaliseManual", codProvaFeita, isRevalidacao);

					if (codAgendamento) {
						await this.exibirSalaDeAvaliacao(codAgendamento, true);
					} else {
						document.location.reload();
					}
				}
			}, {
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_060"),
				classe: "btn-primary",
				onClick: async () => {

					await this.call("FaceMatchFCD/aprovarAnaliseManual", codProvaFeita, isRevalidacao);

					if (codAgendamento) {
						await this.exibirSalaDeAvaliacao(codAgendamento, true);
					} else {
						document.location.reload();
					}
				}
			}]
		});

		let selfies = to.todasSelfies.map(function (selfie, index) {
			let classe = index === 0 ? "item active" : "item";
			return `
				<div class='${classe}'>
					<img src=${selfie} alt="Selfie"/>
				</div>
			`
		});

		this.append(`
			<div class="col-md-12">
				<div class="col-md-6 centered-flexbox">
					<h4>${this.getMsg("FP_FRONT_AgendamentoProvaVH_061")}</h4>
					<img src=${to.fotoDocumento} alt="Foto Documento"/>
				</div>
				<div class="col-md-6 centered-flexbox">
					<h4>${this.getMsg("FP_FRONT_AgendamentoProvaVH_062")}</h4>
					<div id="carouselSelfies" class="carousel slide" data-ride="carousel">
						${selfies && selfies.length ? 
							`
								<div class="carousel-inner" role="listbox">
								${selfies.join("")}
								</div>
								<a class="left carousel-control centered-flexbox" href="#carouselSelfies" role="button" data-slide="prev">
									<span class="fa fa-chevron-left" aria-hidden="true"></span>
									<span class="sr-only">${this.getMsg("FP_FRONT_AgendamentoProvaVH_063")}</span>
								</a>
								<a class="right carousel-control centered-flexbox" href="#carouselSelfies" role="button" data-slide="next">
									<span class="fa fa-chevron-right" aria-hidden="true"></span>
									<span class="sr-only">${this.getMsg("FP_FRONT_AgendamentoProvaVH_064")}</span>
								</a>
							`
							: `<div style="margin-top: 30px">${this.getMsg('FP_FRONT_AgendamentoSalaVH_107')}</div>`
						}
						
					</div>
				</div>
			</div>
		`);

		this.exibirPopups();
	}

	async modalEdicaoManualFaceMatch(codProvaFeita, codAgendamento, isRevalidacao) {

		const to = await this.call("FaceMatchFCD/recuperarFotosAnaliseManual", codProvaFeita, isRevalidacao);
		const titulo = isRevalidacao ? this.getMsg("FP_FRONT_AgendamentoSalaVH_103") : this.getMsg("FP_FRONT_AgendamentoProvaVH_058");

		this.addPopup({
			id: "modal_validacao_manual",
			width: "80%",
			titulo: titulo,
			// botoes: [{
			// 	label: this.getMsg("FP_FRONT_AgendamentoProvaVH_159"),
			// 	classe: "btn-primary",
			// 	visivel: ![TipoFaceMatchLog.VALIDACAO_SUCESSO, TipoFaceMatchLog.REVALIDACAO_SUCESSO].includes(to.tipoLogValidacao),
			// 	onClick: async () => {

			// 		await this.call("FaceMatchFCD/desfazerAnaliseManual", codProvaFeita, isRevalidacao);

			// 		if (codAgendamento) {
			// 			await this.exibirSalaDeAvaliacao(codAgendamento, true);
			// 		} else {
			// 			document.location.reload();
			// 		}
			// 	}
			// }]
		});

		let selfies = to.todasSelfies.map(function (selfie, index) {
			let classe = index === 0 ? "item active" : "item";
			return `
				<div class='${classe}'>
					<img src=${selfie} alt="Selfie"/>
				</div>
			`
		});

		this.append(`
			<div class="col-md-12">
				<div class="col-md-6 centered-flexbox">
					<h4>${this.getMsg("FP_FRONT_AgendamentoProvaVH_061")}</h4>
					<img src=${to.fotoDocumento} alt="Foto Documento"/>
				</div>
				<div class="col-md-6 centered-flexbox">
					<h4>${this.getMsg("FP_FRONT_AgendamentoProvaVH_062")}</h4>
					<div id="carouselSelfies" class="carousel slide" data-ride="carousel">
						${selfies && selfies.length ?
						`
								<div class="carousel-inner" role="listbox">
								${selfies.join("")}
								</div>
								<a class="left carousel-control centered-flexbox" href="#carouselSelfies" role="button" data-slide="prev">
									<span class="fa fa-chevron-left" aria-hidden="true"></span>
									<span class="sr-only">${this.getMsg("FP_FRONT_AgendamentoProvaVH_063")}</span>
								</a>
								<a class="right carousel-control centered-flexbox" href="#carouselSelfies" role="button" data-slide="next">
									<span class="fa fa-chevron-right" aria-hidden="true"></span>
									<span class="sr-only">${this.getMsg("FP_FRONT_AgendamentoProvaVH_064")}</span>
								</a>
							`
							: `<div style="margin-top: 30px">${this.getMsg('FP_FRONT_AgendamentoSalaVH_107')}</div>`
							}
					</div>
				</div>
			</div>
		`);

		if (to.tipoLogValidacao && to.msgValidacao) {
			const dataValidacao = to.dataValidacao ? ` em ${UtilData.toDDMMYYYYHHMM(to.dataValidacao)}` : '';
			const classeAlerta = to.tipoLogValidacao == TipoFaceMatchLog.VALIDACAO_MANUAL_ACEITA ? 'alert-success' : 'alert-danger';
			this.append(`
					<div class="col-md-12" style="padding: 30px">
						<div class="alert ${classeAlerta} col-md-12">
							<i class="fa fa-info-circle"></i>
							<strong>${to.msgValidacao}${dataValidacao}</strong>
						</div>
					</div>
			`);
		}

		this.exibirPopups();
	}

	getLabelToggleAtualizacaoAutomatica() {
		if (this.isAtualizacaoAutomatica) {
			return this.getMsg("FP_FRONT_AgendamentoProvaVH_065");
		} else {
			return this.getMsg("FP_FRONT_AgendamentoProvaVH_066");
		}
	}

	private getLabelExibirIP() {
		if (!this.isExibirDispositivosNaSalaDeAvaliacao()) {
			return this.getMsg("FP_FRONT_AgendamentoProvaVH_143");
		} else {
			return this.getMsg("FP_FRONT_AgendamentoProvaVH_144");
		}
	}

	toggleAtualizacaoAutomaticaSalaDeAvaliacao(botao) {
		this.isAtualizacaoAutomatica = !this.isAtualizacaoAutomatica;
		$(botao).text(this.getLabelToggleAtualizacaoAutomatica());
		if(this.isAtualizacaoAutomatica) {
			this.atualizarAutomaticamente();
			return;
		}
		clearTimeout(this.idIntervalAtualizacaoSalaDeAvaliacao);
		const $botao = $("#botaoAtualizarSalaAvaliacao");
		$botao.css("background", 'initial');
		$botao.data("ultima-atualizacao", null);
	}

	montarLinkNomeUsuario(cfgs: MontarLinkNomeUsuarioCfgs) {

		let html = [];

		if (this.isAnalista()) {
			const hashEdicaoAluno = UtilHash.getHash(cadastroUsuarioVH.editarUsuario, cfgs.codUsuario);
			let icone = `fa-user`;
			let title = ``;

			if (cfgs.isCpfVerificadoOCR) {
				icone = `fa-user-shield text-success`;
				title = `title="${this.getMsg("FP_FRONT_AgendamentoProvaVH_166")}"`;
			} else if (cfgs.isCpfVerificadoOCR === false) {
				icone = `fa-user-shield text-danger`;
				title = `title="${this.getMsg("FP_FRONT_AgendamentoProvaVH_167")}"`;
			}

			html.push(`
				<a href="${hashEdicaoAluno}" ${title} target="_blank"><i class="fa ${icone}"></i></a>
			`);
		}

		let abriu = false;

		if (this.isAnalista() || this.isFiscal()) {

			if (cfgs.codProvaFeita != null && (!this.isFiscal(false) || this.salaAvaliacaoTO.listagemAgendamentoTO.isResultadoProvaLiberadoFiscal)) {
				html.push(`
					<a href="${UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, cfgs.codProvaFeita)}" target="_blank">
				`);
				abriu = true;

			}
		}

		html.push(cfgs.nomeUsuario);

		if(cfgs.tipoAcessibilidade) {
			let tipoAcessibilidade = cfgs.tipoAcessibilidade.toLowerCase();
			tipoAcessibilidade = tipoAcessibilidade.charAt(0).toUpperCase() + tipoAcessibilidade.slice(1);
			html.push(`
				<i class="fa fa-universal-access text-success" style="font-size: 1.2em;" title='${tipoAcessibilidade}'></i>
			`);
		}

		if (abriu) html.push("</a>");

		return html.join("");
	}

	exibirSituacaoProva(salaAvaliacaoTO: SalaAvaliacaoTO, agendamentoEmAndamentoUsuarioTO) {
		return this.getLabelSituacaoAvaliadoNaAO({
			ultimoKA: agendamentoEmAndamentoUsuarioTO.dataUltimoKeepAlive,
			agora: UtilData.toMillis(salaAvaliacaoTO.agora),
			codProvaFeita: agendamentoEmAndamentoUsuarioTO.codProvaFeita,
			isCadernoPreGerado: agendamentoEmAndamentoUsuarioTO.isCadernoPreGerado,
			dataEncerramento: salaAvaliacaoTO.dataEncerramento,
			codUsuarioFiscal: salaAvaliacaoTO.codUsuarioFiscal,
			isLiberado: agendamentoEmAndamentoUsuarioTO.isLiberado,
			isAusente: agendamentoEmAndamentoUsuarioTO.isAusente,
			validacaoFaceMatch: !salaAvaliacaoTO.isAvaliadoPodeProsseguirSemFacematch && agendamentoEmAndamentoUsuarioTO.isAguardandoValidacaoFacematch,
			revalidacaoFaceMatch: !salaAvaliacaoTO.isAvaliadoPodeProsseguirSemFacematch && agendamentoEmAndamentoUsuarioTO.isAguardandoRevalidacaoFacematch,
			codAgendamento: salaAvaliacaoTO.codAgendamento,
			isAnulada: agendamentoEmAndamentoUsuarioTO.isAnulada,
			possuiNota: agendamentoEmAndamentoUsuarioTO.possuiNota,
			isProvaIniciada: agendamentoEmAndamentoUsuarioTO.dataInicio != null,
			isProvaFinalizada: agendamentoEmAndamentoUsuarioTO.dataFim != null,
			isOnline: agendamentoEmAndamentoUsuarioTO.isOnline,
			isProvaReaberta: agendamentoEmAndamentoUsuarioTO.isProvaReaberta,
			statusInscricao: agendamentoEmAndamentoUsuarioTO.statusInscricao,
			possuiConfirmacaoInscricao: salaAvaliacaoTO.possuiConfirmacaoInscricao,
		});
	}

	async autorizarAgendamento(codAgendamento) {
		await this.call("AgendamentoProvaFCD/autorizarAgendamento", codAgendamento);
		await this.exibirSalaDeAvaliacao(codAgendamento);
	}

	async exibirEdicaoEmail(codAgendamento) {

		UtilHash.registrarHistorico(this.exibirEdicaoEmail, codAgendamento);

		const resumoAgendamentoTO = await this.call("AgendamentoProvaFCD/recuperarResumoAgendamento", codAgendamento);

		this.limpar(true);

		this.addSubtitulo(this.getMsg("FP_FRONT_AgendamentoProvaVH_067", codAgendamento));

		this.addFormulario();

		this.append("<div class='row'>");

		if (resumoAgendamentoTO.numAlunosAgendados == null || resumoAgendamentoTO.numAlunosAgendados < 1) {
			this.addTextoErro({ texto: this.getMsg("FP_FRONT_AgendamentoProvaVH_068") });

			this.addEspacamentoHorizontal("5px");

			this.append("<div class='btn-group col-md-12'>");

		} else {

			let destinatarios = [];
			if (resumoAgendamentoTO.tipoAplicacao == TipoAplicacao.F) {
				if (resumoAgendamentoTO.numAlunosAgendados > 0) destinatarios.push({ id: "inscritos", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_069", resumoAgendamentoTO.numAlunosAgendados) });
				if (resumoAgendamentoTO.numAlunosSemPF > 0) destinatarios.push({ id: "aguardando", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_070", resumoAgendamentoTO.numAlunosSemPF) });
				if (resumoAgendamentoTO.numAlunosComPFSemNota > 0) destinatarios.push({ id: "emprova", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_071", resumoAgendamentoTO.numAlunosComPFSemNota) });
				if (resumoAgendamentoTO.numAlunosComNota > 0) destinatarios.push({ id: "encerraram", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_072", resumoAgendamentoTO.numAlunosComNota) });
			} else {
				if (resumoAgendamentoTO.numAlunosAgendados > 0) destinatarios.push({ id: "inscritos", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_073", resumoAgendamentoTO.numAlunosAgendados) });
				if (resumoAgendamentoTO.numAlunosSemPF > 0) destinatarios.push({ id: "aguardando", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_074", resumoAgendamentoTO.numAlunosSemPF) });
				if (resumoAgendamentoTO.numAlunosComPFSemNota > 0) destinatarios.push({ id: "emprova", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_075", resumoAgendamentoTO.numAlunosComPFSemNota) });
				if (resumoAgendamentoTO.numAlunosComNota > 0) destinatarios.push({ id: "encerraram", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_076", resumoAgendamentoTO.numAlunosComNota) });
			}
			destinatarios.push({ id: "individuais", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_077") });

			this.append("</div>");

			this.append("<div class='row'>");
			this.append("<div class='row'>");

			this.append("<div class='col-md-9'>");

			this.addRadioGroup({
				collection: destinatarios,
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_239"),
				id: "destinatarios",
				classe: "col-md-12",
				classeRadio: "radio-inline",
				ajuda: this.getMsg("FP_FRONT_AgendamentoProvaVH_078"),
				onChange: async () => {
					const dest = this.getValor("destinatarios");
					if (dest == "individuais") {
						await this.show("codsUsuariosDestinatarios");
					} else {
						await this.hide("codsUsuariosDestinatarios");
					}
				}
			});

			this.addCampoTexto({ id: "destinatariosIndividuais", classe: "col-md-12", visivel: false });

			this.addEspacamentoHorizontal("5px");

			await this.addSelect({
				id: "codsUsuariosDestinatarios",
				multiplo: true,
				classe: "col-md-12",
				loadOpcoesBack: {
					endpoint: "ListagemSelecaoFCD/listarUsuariosInscritos",
					payload: {
						codAgendamento: codAgendamento
					},
					numMinimoCaracteres: 3,
				},
				visivel: false,
			});

			this.addCampoTexto({ id: "enderecoCC", label: this.getMsg("FP_FRONT_AgendamentoProvaVH_079"), classe: "col-md-4", valor: resumoAgendamentoTO.enderecoCC });
			this.addCampoTexto({ id: "enderecoCCO", label: this.getMsg("FP_FRONT_AgendamentoProvaVH_080"), classe: "col-md-4", valor: resumoAgendamentoTO.enderecoCCO });
			this.addCampoTexto({ id: "responderPara", label: this.getMsg("FP_FRONT_AgendamentoProvaVH_081"), classe: "col-md-4", valor: resumoAgendamentoTO.responderPara });

			this.addCampoTexto({ id: "tituloDoEmail", label: this.getMsg("FP_FRONT_AgendamentoProvaVH_082"), classe: "col-md-12" });

			this.addEditorHTML({
				id: "textoDoEmail", 
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_083"), 
				classe: "col-md-12", 
				onChange: () => this.atualizarTextoPrevia(resumoAgendamentoTO.templateEmail)
			});

			// this.append(`
			// 	<div class='row'>
			// 		<div class='col-md-12'>
			// 			<label class='col-md-12'>
			// 				${this.getMsg("FP_FRONT_AgendamentoProvaVH_084")}
			// 				<small class='pull-right'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_085")}</small>
			// 			</label>
			// 			<div email-previa class='col-md-12'></div>
			// 		</div>
			// 	</div>
			// `);

			this.append(UtilEmailPrevia.criar({
				titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_084"),
				info: this.getMsg("FP_FRONT_AgendamentoProvaVH_085")
			}));

			this.addRadioGroup({
				collection: [
					{id: "NAO_MUDAR_SENHA", descricao: this.getMsg("FP_FRONT_AgendamentoProvaVH_240")},
					{id: "GERAR_NOVA_SENHA", descricao: `
						${this.getMsg("FP_FRONT_AgendamentoProvaVH_241")}
						<small>	
							${this.getMsg("FP_FRONT_AgendamentoProvaVH_243")}
							${this.getMsg("FP_FRONT_AgendamentoProvaVH_242")}
						</small>
					`},
				],
				label: "Senha",
				id: "geracaoNovaSenha",
				classe: "col-md-12",
				valor: "NAO_MUDAR_SENHA",
				onChange: () => {
					this.atualizarVariavelSenha();
					this.atualizarTextoPrevia(resumoAgendamentoTO.templateEmail)
				}
			});

			this.append("</div>");

			this.append("<dl class='col-md-3'>");

			this.addTexto(`<h4>${this.getMsg("FP_FRONT_AgendamentoProvaVH_086")}</h4><br>`);

			this.append("<div class='row'>");

			let valorPeriodo = this.getMsg("FP_FRONT_AgendamentoProvaVH_087");

			if (resumoAgendamentoTO.inicioAgendamento) {
				if (resumoAgendamentoTO.tipoAplicacao == TipoAplicacao.F || resumoAgendamentoTO.fimAgendamento == null) {
					valorPeriodo = UtilData.toDDMMYYYYHHMM(resumoAgendamentoTO.inicioAgendamento);
				} else {
					valorPeriodo = this.getMsg("FP_FRONT_AgendamentoProvaVH_088", UtilData.toDDMMYYYYHHMM(resumoAgendamentoTO.inicioAgendamento), UtilData.toDDMMYYYYHHMM(resumoAgendamentoTO.fimAgendamento));
				}
			}

			this.addCampoExibicao({ label: this.getMsg("FP_FRONT_AgendamentoProvaVH_089"), classe: "col-md-12", valor: valorPeriodo });

			if (this.hasValue(resumoAgendamentoTO.nomesTurmas)) {
				this.addCampoExibicao({ label: this.getCfg("LABEL_ALUNO", this.getMsg("FP_FRONT_AgendamentoProvaVH_090")), classe: "col-md-12", valor: resumoAgendamentoTO.nomesTurmas });
			}
			this.append("</div>");

			this.append(`<h4>${this.getMsg("FP_FRONT_AgendamentoProvaVH_097")}</h4><br>`);
			this.append(`<dt>usuario_codigo</dt><dl class='text-primary'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_091")}</dl>`)
			this.append(`<dt>usuario_nome</dt><dl class='text-primary'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_092")}</dl>`)
			this.append(`<dt>usuario_login</dt><dl class='text-primary'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_093")}</dl>`)
			this.append(`<dt usuario_senha>usuario_senha</dt><dl usuario_senha class='text-primary'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_094")}</dl>`)
			this.append(`<dt>usuario_link_definir_nova_senha</dt><dl class='text-primary'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_111")}</dl>`);
			this.append(`<dt>usuario_aproveitamento</dt><dl class='text-primary'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_095")}</dl>`)
			this.append(`<dl><Br><br>${this.getMsg("FP_FRONT_AgendamentoProvaVH_096")}<br><br><em>${this.getMsg("FP_FRONT_AgendamentoProvaVH_098")}</em></dl>`)
			this.append(`</dl>`);

			this.append("</div>");
			this.append("</div>");
			this.append("</div>");

			this.addEspacamentoHorizontal("5px");

			this.append("<div class='col-md-9'>");

			this.addBotao({ label: this.getMsg("MSG_VH_AP_71"), onClick: () => UtilHash.voltar() });

			this.append("<div class='btn-group pull-right'>");

			this.addBotao({
				label: `<i class='fa fa-clock'></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_099")}`, onClick: () => {
					this.addPopup({
						titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_244"),
						botoes: [{
							label: `<i class='fa fa-clock'></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_099")}`,
							onClick: async () => {
								if (!this.validarCamposObrigatorios("dataParaEnvio")) {
									return false;
								}
								const dataParaEnvio = this.getValor("dataParaEnvio");
								setTimeout(() => {
									this.salvarEnvioEmail(codAgendamento, dataParaEnvio);
								}, 500);
							}
						}]
					});
					this.addCampoTexto({ id: "dataParaEnvio", obrigatorio: true, label: this.getMsg("FP_FRONT_AgendamentoProvaVH_100"), classe: "col-md-6", tipo: "DATAHORA" });
					this.exibirPopups();
				}
			});

			this.addBotao({ 
				label: `<i class='fa fa-envelope'></i> ${this.getMsg("FP_FRONT_AgendamentoProvaVH_101")}`, 
				classe: "btn-primary", 
				onClick: () => this.salvarEnvioEmail(codAgendamento) 
			});

			this.append("</div>");
		}

		this.fecharFormulario();

		this.addEspacamentoHorizontal("5px");

		this.exibir();

		$("#textoDoEmail").trigger("blur");

		this.atualizarVariavelSenha();
	}

	atualizarTextoPrevia(templateEmail: any) {
		const variaveis = [];

		const isGerarNovaSenha = this.getValor("geracaoNovaSenha") == "GERAR_NOVA_SENHA";

		if (!isGerarNovaSenha) {
			variaveis.push({
				variavel: UtilEmailPreviaVariaveisEnum.usuario_senha,
				valor: 'usuario_senha',
				mensagem: `<strong class="text-danger">ATENÇÃO: esta varíavel não está disponível</strong>`
			});
		}

		UtilEmailPrevia.atualizarTextoPrevia(
			templateEmail,
			'textoDoEmail', variaveis
		);
	}
	
	atualizarVariavelSenha() {
		const isGerarNovaSenha = this.getValor("geracaoNovaSenha") == "GERAR_NOVA_SENHA";
		
		if (isGerarNovaSenha) {
			$("[usuario_senha]").show();
		} else {
			$("[usuario_senha]").hide();
		}
	}

	async salvarEnvioEmail(codAgendamento: number, dataParaEnvio?) {

		$("#textoDoEmail").trigger("blur");

		const envioEmailParaAgendamentoTO = {
			codAgendamento: codAgendamento,
			textoDoEmail: this.getValor("textoDoEmail"),
			tituloDoEmail: this.getValor("tituloDoEmail"),
			enderecoCC: this.getValor("enderecoCC"),
			enderecoCCO: this.getValor("enderecoCCO"),
			responderPara: this.getValor("responderPara"),
			isGerarNovaSenha: this.getValor("geracaoNovaSenha") == "GERAR_NOVA_SENHA",
			enviarParaTodosInscritos: ("inscritos" == this.getValor("destinatarios")),
			enviarParaQuemNaoIniciou: ("aguardando" == this.getValor("destinatarios")),
			enviarParaQuemEstaEmProva: ("emprova" == this.getValor("destinatarios")),
			enviarParaQuemFinalizou: ("encerraram" == this.getValor("destinatarios")),
			codsUsuariosDestinatarios: ("individuais" == this.getValor("destinatarios") ? this.getValor("codsUsuariosDestinatarios") : []),
			dataParaEnvio: dataParaEnvio
		};

		if (!this.validarCamposObrigatorios("destinatarios", "textoDoEmail", "tituloDoEmail")) return;

		const numEmailsEnviados = await this.call("AgendamentoProvaFCD/enviarEmailsParaAgendamento", envioEmailParaAgendamentoTO);

		let msg = this.getMsg("FP_FRONT_AgendamentoProvaVH_102");
		if (dataParaEnvio) {
			msg = this.getMsg("FP_FRONT_AgendamentoProvaVH_103", UtilData.toDDMMYYYYHHMM(dataParaEnvio));
		}
		this.addMsgSucesso(msg);
		this.exibir();
	}

	async exibirDocumentosParaDownload(codAgendamento) {

		const listOpcaoListaTODocumentos = await this.call("AgendamentoProvaFCD/recuperarDocs");

		this.addPopup({
			titulo: this.getMsg("MSG_VH_AP_72"), 
			id: "popup_doc", 
			botoes: [{
				label: this.getMsg("MSG_VH_AP_73"), 
				onClick: async () => {

					if (!this.validarCamposObrigatorios("pathArquivo")) return false;

					const urlDoc = await this.call("AgendamentoProvaFCD/baixarDoc", this.getValor("pathArquivo"), codAgendamento);

					this.setIdTarget("popup_doc");
					this.addLink({ label: UtilArquivo.getNomeArquivo(urlDoc), href: urlDoc, classe: "fp-link-aguardar-geracao-arquivo" });
					this.exibir();

					return false;
				}
			}]
		});

		this.addRadioGroup({ id: "pathArquivo", collection: listOpcaoListaTODocumentos, label: this.getMsg("MSG_VH_AP_74"), classe: "col-md-12" });

		this.exibirPopups();
	}

	async carregarAplicacaoImpressa(codAgendamento) {

		const listagemAplicacoesImpressasTO = await this.call("AplicacaoImpressaFCD/recuperarAplicacaoImpressa", codAgendamento);
		const salaAvaliacaoSelector = $("#sala_de_avaliacao");
		salaAvaliacaoSelector.html("");
		salaAvaliacaoSelector.data("fp-cod-agendamento", null);
		this.setIdTarget("sala_de_avaliacao");
		aplicacaoImpressaVH.exibirAplicacaoImpressa(listagemAplicacoesImpressasTO);
		this.exibir();
		UtilWindow.scrollTo("#sala_de_avaliacao");
	}

	exibirExportarCorrecoes(codAgendamento) {

		this.addPopup({
			width: "600px",
			// height: "320px",
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_104"),
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_105"), 
				classe: "btn-primary", 
				onClick: async () => {
					const isPDF = this.getValor("formatoArquivoCorrecao") == "PDF";
					await this.call("AgendamentoProvaFCD/enviarCorrecoesPorEmail", codAgendamento, isPDF);
					await this.exibirAlerta({ msg: this.getMsg("FP_FRONT_AgendamentoProvaVH_106") });
				}
			}]
		});

		this.addRadioGroup({
			id: "formatoArquivoCorrecao",
			label: this.getMsg("FP_FRONT_AgendamentoProvaVH_107"),
			collection: [{ id: "PDF", descricao: "PDF" }, { id: "DOCX", descricao: "Word (DOCX)" }],
			valor: "DOCX",
		});

		this.addTexto(this.getMsg("FP_FRONT_AgendamentoProvaVH_108", this.getEmailUsuarioLogado()));

		this.exibirPopups();
	}

	async encerrarAgendamento(codAgendamento, handlePosEncerramento?: Function, deveArquivarAplicacaoPorEstarSemRespostas?: boolean) {

		let idMsgConfirmacao = "FP_FRONT_AgendamentoProvaVH_109";

		if (deveArquivarAplicacaoPorEstarSemRespostas === true) {
			idMsgConfirmacao = "FP_FRONT_AgendamentoProvaVH_133";
		}

		await this.exibirAlerta({
			msg: this.getMsg(idMsgConfirmacao),
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_245"),
				classe: "btn-primary",
				onClick: async () => {
					await this.call("AgendamentoProvaFCD/encerrarAgendamento", codAgendamento);

					if (deveArquivarAplicacaoPorEstarSemRespostas === true) {
						await this.call("AgendamentoProvaFCD/cancelar", codAgendamento);
					}

					if (handlePosEncerramento) {
						await handlePosEncerramento(codAgendamento);
					} else {
						document.location.reload();
					}
				}
			}]
		});
	}

	async encerrarAgendamentoSemPopupConfirmacao(codAgendamento, callback) {

		await this.call("AgendamentoProvaFCD/encerrarAgendamento", codAgendamento);

		if (callback) {
			callback(codAgendamento);
			return true;
		} else {
			document.location.reload();
			return false;
		}
	}

	async finalizarAgendamento(codAgendamento: number, callback?: Function) {

		await this.exibirAlerta({
			msg: this.getMsg("FP_FRONT_AgendamentoProvaVH_110"),
			botoes: [
				{
					label: this.getMsg("MSG_VH_004"),
					classe: "btn-primary",
					onClick: async () => {
						await this.call("AgendamentoProvaFCD/finalizarAgendamento", codAgendamento);

						if (callback) {
							callback(codAgendamento);
						} else {
							document.location.reload();
						}
					}
				},
				{label: this.getMsg("MSG_VH_005"),}
			],
		});
	}

	addFiltrosAOs(exibicaoAplicacoesProvaTO) {

		this.append("<div id='filtrosAOs' style='display: none'>")
		
		this.abrirAbaAccordion({ titulo: "Filtros", aberta: true });

		this.addFormulario({ classe: "col-md-12" });

		this.append("<div class='row'>");

		this.addCheckbox({
			id: "isExibirSomenteAguardandoLiberacao",
			label: "Somente com avaliados aguardando liberação",
			valor: this.isExibirSomenteAguardandoLiberacao,
			classe: "col-md-6",
		});

		if (exibicaoAplicacoesProvaTO.possuiAplicacaoArquivada) {
			this.addCheckbox({
				id: "isExibirExcluidas",
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_004"),
				classe: "col-md-6",
				valor: this.isExibirExcluidas,
			});
		}
		this.append("<div class='col-md-12'>");

		this.addBotao({
			label: "Filtrar",
			classe: "btn-primary pull-right",
			onClick: async () => {
				this.isExibirSomenteAguardandoLiberacao = this.getValor("isExibirSomenteAguardandoLiberacao");
				this.isExibirExcluidas = this.getValor("isExibirExcluidas");
				if (this.isFiscal(false)) {
					await this.listarAOsPorAplicacaoParaFiscal();
				} else {
					await this.listarAOsProva(exibicaoAplicacoesProvaTO.codProva, null, null);
				}
			}
		});

		this.append("</div>");

		this.append("</div>");

		this.fecharFormulario();
		this.fecharAbaAccordion();
		this.fecharGrupoAccordion();
		this.append("</div>");

	}

	getLabelSituacaoAvaliadoNaAO(infosSituacaoAvaliado: InfosSituacaoAvaliado) {
		
		let isEmProvaAusente = false;

		if (infosSituacaoAvaliado.ultimoKA != null) {
			const millisUltimoSinal = UtilData.getDiferencaMillis(infosSituacaoAvaliado.agora, infosSituacaoAvaliado.ultimoKA);
			isEmProvaAusente = millisUltimoSinal > (90 * 1000)
		}

		if (infosSituacaoAvaliado.codProvaFeita == null) {

			if (infosSituacaoAvaliado.dataEncerramento) {
				this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_035"));
				return `<div style='color: gray'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_035")}</div>`;
			} else if((infosSituacaoAvaliado.possuiConfirmacaoInscricao && infosSituacaoAvaliado.codUsuarioFiscal == null) || (infosSituacaoAvaliado.possuiConfirmacaoInscricao && (!infosSituacaoAvaliado.statusInscricao || infosSituacaoAvaliado.statusInscricao == StatusInscricao.PENDENTE) && infosSituacaoAvaliado.codUsuarioFiscal != null) || (infosSituacaoAvaliado.possuiConfirmacaoInscricao && infosSituacaoAvaliado.statusInscricao == StatusInscricao.CONFIRMADO && infosSituacaoAvaliado.codUsuarioFiscal == null)) {
				if(!infosSituacaoAvaliado.statusInscricao || infosSituacaoAvaliado.statusInscricao == StatusInscricao.PENDENTE) {
					this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_194"));
					return `<div style='color: #d2a706'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_194")}</div>`;
				} else if(infosSituacaoAvaliado.statusInscricao == StatusInscricao.CONFIRMADO) {
					this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_195"));
					return `<div style='color: purple'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_195")}</div>`;
				}
			} else if (infosSituacaoAvaliado.codUsuarioFiscal == null) {
				if (this.salaAvaliacaoTO && !this.salaAvaliacaoTO.isAplicacaoVigente && this.salaAvaliacaoTO.listagemAgendamentoTO?.inicioFaseAplicacao && UtilData.isPassada(this.salaAvaliacaoTO?.listagemAgendamentoTO?.inicioFaseAplicacao)) {
					this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_035"));
					return `<div style='color: gray'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_035")}</div>`;
				}
				this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_036"));
				return `<div style='color: #d2a706'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_036")}</div>`;

			} else if (infosSituacaoAvaliado.isLiberado) {
				this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_037"));
				return `<div style='color: purple'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_037")}</div>`;

			} else if (infosSituacaoAvaliado.isAusente) {
				this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_038"));
				return `<div style='color: gray'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_038")}</div>`;

			} else {
				const statusColor = infosSituacaoAvaliado.isOnline ? 'green' : 'gray';
				const statusText = infosSituacaoAvaliado.isOnline ? 'Online' : 'Offline';
				this.situacoesAlunos.push(`${this.getMsg("FP_FRONT_AgendamentoProvaVH_039")} (${statusText})`);
				return `
					<div style="color: #d2a706">
						${this.getMsg("FP_FRONT_AgendamentoProvaVH_039")}
						<span style="color: ${statusColor}">
							(${statusText})
						</span>
					</div>
				`;
			}
		} else if (infosSituacaoAvaliado.validacaoFaceMatch) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_049"));
			return `
				<a title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_048")}' onclick='agendamentoProvaVH.modalConfirmacaoManualFaceMatch(${infosSituacaoAvaliado.codProvaFeita}, ${infosSituacaoAvaliado.codAgendamento})'>
					<div style='color: orange'>
						<i class='fa fa-exclamation-circle'></i> 
						${this.getMsg("FP_FRONT_AgendamentoProvaVH_049")}
					</div>
				</a>
			`;
		} else if (infosSituacaoAvaliado.revalidacaoFaceMatch) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoSalaVH_105"));
			return `
				<a title='${this.getMsg("FP_FRONT_AgendamentoSalaVH_104")}' onclick='agendamentoProvaVH.modalConfirmacaoManualFaceMatch(${infosSituacaoAvaliado.codProvaFeita}, ${infosSituacaoAvaliado.codAgendamento}, true)'>
					<div style='color: orange'>
						<i class='fa fa-exclamation-circle'></i> 
						${this.getMsg("FP_FRONT_AgendamentoSalaVH_105")}
					</div>
				</a>
			`;

		} else if (infosSituacaoAvaliado.possuiNota && infosSituacaoAvaliado.isProvaReaberta) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_154"));
			return `<div style='color: #d2a706'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_154")}</div>`;

		} else if (infosSituacaoAvaliado.isAnulada) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_047"));
			return `<span class='label label-danger' style='text-transform: uppercase'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_047")}</span>`;
			
		} else if (infosSituacaoAvaliado.possuiNota && !infosSituacaoAvaliado.isProvaIniciada && !infosSituacaoAvaliado.isProvaFinalizada) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_155"));
			return `<div style='color: gray'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_155")}</div>`;

		} else if (infosSituacaoAvaliado.possuiNota && !infosSituacaoAvaliado.isProvaFinalizada) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_041"));
			return `<div style='color: green'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_041")}</div>`;

		} else if (infosSituacaoAvaliado.possuiNota) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_042"));
			return `<div style='color: green'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_042")}</div>`;

		} else if (isEmProvaAusente) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_044"));
			return `<div style='color: #d2a706' title='${this.getMsg("FP_FRONT_AgendamentoProvaVH_043")}'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_044")}</div>`;

		} else if (infosSituacaoAvaliado.isProvaIniciada) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_045"));
			return `<div style='color: red'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_045")}</div>`;

		} else if (infosSituacaoAvaliado.codUsuarioFiscal && infosSituacaoAvaliado.isLiberado) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_037"));
			return `<div style='color: purple'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_037")}</div>`;

		} else if (infosSituacaoAvaliado.codUsuarioFiscal && infosSituacaoAvaliado.isAusente) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_038"));
			return `<div style='color: gray'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_038")}</div>`;

		} else if (infosSituacaoAvaliado.codUsuarioFiscal) {
			const statusColor = infosSituacaoAvaliado.isOnline ? 'green' : 'gray';
			const statusText = infosSituacaoAvaliado.isOnline ? 'Online' : 'Offline';
			this.situacoesAlunos.push(`${this.getMsg(this.getMsg("FP_FRONT_AgendamentoProvaVH_196"))} (${statusText})`);
			return `
				<div style="color: #d2a706">
					${this.getMsg(this.getMsg("FP_FRONT_AgendamentoProvaVH_196"))}
					<span style="color: ${statusColor}">
						(${statusText})
					</span>
				</div>
			`;

		} else if (infosSituacaoAvaliado.isCadernoPreGerado === true) {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_122"));
			return `<div style='color: #d2a706'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_122")}</div>`;

		} else {
			this.situacoesAlunos.push(this.getMsg("FP_FRONT_AgendamentoProvaVH_046"));
			return `<div style='color: #d2a706'>${this.getMsg("FP_FRONT_AgendamentoProvaVH_046")}</div>`;
		}
	}

	addFiltrosPorSituacao() {
		if (this.situacoesAlunos && this.situacoesAlunos.length) {
			const countObj = {};
			this.situacoesAlunos.forEach((item) => {
				if (countObj[item]) {
					countObj[item]++;
				} else {
					countObj[item] = 1;
				}
			});
			this.situacoesAlunos = [];
			const dlElement = $('dl#dl-filtro');

			for (let chave in countObj) {
				if (countObj.hasOwnProperty(chave)) {
					dlElement.append(`
					<div>
						<dt>
							<a onclick='agendamentoProvaVH.filtrarSalaAvaliacao(this)'>
								${chave}
							</a>
						</dt>
						<dd>
							${countObj[chave]}
						</dd>
					</div>
				`);
				}
			}
		}

	}

	atualizarAutomaticamente() {
		const $botao = $("#botaoAtualizarSalaAvaliacao");

		if (!this.isAtualizacaoAutomatica) {
			$botao.css("background", 'initial');
			clearTimeout(this.idIntervalAtualizacaoSalaDeAvaliacao);
		}

		if ($botao.length == 0) return;

		const agora = Date.now();
		let tsUltima = $botao.data("ultima-atualizacao");

		if (tsUltima == null) {
			$botao.data("ultima-atualizacao", agora);
			tsUltima = agora;
		}

		const segundosAtualizacao = 30;
		const porcentagemPercorrida = Math.round(100 * ((agora - tsUltima) / 1000) / segundosAtualizacao);

		$botao.css("background", `linear-gradient(90deg, #ddd ${porcentagemPercorrida}%, white 0%)`);

		if (porcentagemPercorrida >= 100) {
			this.atualizarSalaAvaliacao();
		}

		this.idIntervalAtualizacaoSalaDeAvaliacao = setTimeout(() => this.atualizarAutomaticamente(), 1000);
	}

	async encerrarEnvioDeRespostasEmTodasAplicacoes(collectionListagemAgendamentoTO: any) {


		for (const aplicacao of collectionListagemAgendamentoTO) {
			await this.encerrarAgendamentoSemPopupConfirmacao(aplicacao.codAgendamento,
				() => {});
		}

		document.location.reload();
	}

	private setExibirSalaDeAvaliacaoComIPs(ativo: boolean) {
		const itemId = "fp-sala-avaliacao-exibir-dispositivo-" + this.getCodUsuarioLogado();
		if (ativo) {
			localStorage.setItem(itemId, "true");
		} else {
			localStorage.removeItem(itemId);
		}
	}
	
	private isExibirDispositivosNaSalaDeAvaliacao() {
		return this.hasValue(localStorage.getItem("fp-sala-avaliacao-exibir-dispositivo-" + this.getCodUsuarioLogado()));
	}

	private copiarCodigoAcesso() {
		const e = document.querySelector(`#codigo-acesso`);
		if (!e) return;
		let texto = e.textContent;
		if (!texto) return;
		texto = texto.trim();
		if (this.isEmpty(texto)) return;
		navigator.clipboard.writeText(texto.trim());
		this.mostrarMsgAjax(this.getMsg("FP_FRONT_AgendamentoProvaVH_164"))
	}

	exibirCodAcesso(codAgendamento) {
		const milisegundosRestantesCodigoAcesso = this.salaAvaliacaoTO.codigoAcessoRotativoTO.milissegundosParaExpirar;
		const timer = this.addTemporizador({id: 'timer_cod_acesso_prova', retornarHtml: true, milisegundosRestantes: milisegundosRestantesCodigoAcesso});

		// this.append("<div class='col-md-12' style='display: flex; gap: 10px; justify-content: end; align-items: center'>");
		// this.addTextoAlerta({ texto: this.getMsg("FP_FRONT_AgendamentoProvaVH_163")});
		// this.append("</div>");
		this.append(`
			<div class="col-md-12">
				<div style="display: flex; gap: 10px; justify-content: end; align-items: center"> 
					<strong title="${this.getMsg("FP_FRONT_AgendamentoProvaVH_163")}">${this.getMsg("FP_FRONT_AgendamentoProvaVH_160")}</strong>
					
					<span id="codigo-acesso" style="font-size: 24px; font-weight: bold; cursor: pointer" title="${this.getMsg("FP_FRONT_AgendamentoProvaVH_161")}" onclick="agendamentoProvaVH.copiarCodigoAcesso()">
						${this.salaAvaliacaoTO.codigoAcessoRotativoTO.codigoAcesso}
					</span> 
				
					<span id="codigo-acesso-timer" title="${this.getMsg("FP_FRONT_AgendamentoProvaVH_162")}" style="cursor: pointer">
						${timer}
					</span>
						
					</div>
				</div>
			`);

		setTimeout(() => {
			const crono: CronoTemporizadorVH = new CronoTemporizadorVH();
			crono.iniciarCronometro(milisegundosRestantesCodigoAcesso, $(`#timer_cod_acesso_prova`), async () => {
				await this.atualizarCodAcesso(codAgendamento);
			});

		}, 300);

		this.addEspacamentoHorizontal("1px");
	}


	async atualizarCodAcesso(codAgendamento) {

		const codigoAcessoRotativoTO = await this.call("CodigoAcessoFCD/gerarCodigoAcessoRotativo", codAgendamento);

		$('#codigo-acesso').text(codigoAcessoRotativoTO.codigoAcesso);

		const milisegundosRestantesCodigoAcesso = codigoAcessoRotativoTO.milissegundosParaExpirar;

		const timer = this.addTemporizador({id: 'timer_cod_acesso_prova', retornarHtml: true, milisegundosRestantes: milisegundosRestantesCodigoAcesso});

		$('#codigo-acesso-timer').html("");
		$('#codigo-acesso-timer').html(timer);

		setTimeout(() => {
			const crono: CronoTemporizadorVH = new CronoTemporizadorVH();
			crono.iniciarCronometro(milisegundosRestantesCodigoAcesso, $(`#timer_cod_acesso_prova`), async () => {
				await this.atualizarCodAcesso(codAgendamento);
			});
		}, 300);
		this.exibir();
	}

	async modalConfirmacaoInscricao(codAgendamentoUsuario: number, codAgendamento: number) {
		await this.exibirAlerta({
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_201"),
			msg: this.getMsg("FP_FRONT_AgendamentoProvaVH_202"),
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_200"),
			}, {
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_203"),
				classe: "btn-primary",
				onClick: async () => {
					await this.call("AgendamentoProvaFCD/atualizarStatusInscricao", codAgendamentoUsuario, StatusInscricao.CONFIRMADO);
					await this.exibirSalaDeAvaliacao(codAgendamento, true);
				}
			}]
		})

	}

	async modalReversaoConfirmacaoInscricao(codAgendamentoUsuario: number, codAgendamento: number) {
		await this.exibirAlerta({
			titulo: this.getMsg("FP_FRONT_AgendamentoProvaVH_197"),
			msg: this.getMsg("FP_FRONT_AgendamentoProvaVH_198"),
			botoes: [{
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_200"),
			}, {
				label: this.getMsg("FP_FRONT_AgendamentoProvaVH_199"),
				classe: "btn-primary",
				onClick: async () => {
					await this.call("AgendamentoProvaFCD/atualizarStatusInscricao", codAgendamentoUsuario, StatusInscricao.PENDENTE);
					await this.exibirSalaDeAvaliacao(codAgendamento, true);
				}
			}]
		})

		this.exibirPopups();
	}
}

const agendamentoProvaVH = new AgendamentoProvaVH();

class FiltrosFiscalizacaoPorAvaliadoTO {
	buscaTextual: String;
	dataInicioFaseAplicacao: Date;
	isExibirSomenteAguardandoLiberacao: boolean = true;
}

class ExibicaoFiscalizacaoPorAvaliadoTO {
	filtrosFiscalizacaoPorAvaliadoTO: FiltrosFiscalizacaoPorAvaliadoTO;
	listaDetalhesFiscalizacaoPorAvaliadoTO: DetalhesFiscalizacaoPorAvaliadoTO[];
}

class DetalhesFiscalizacaoPorAvaliadoTO {
	codAgendamento: number;
	codAgendamentoUsuario: number;
	codProvaFeita: number;
	inicioFaseAplicacao: string;
	tipoAplicacao: TipoAplicacao;
	isAplicacaoArquivada: boolean; 
	nomeProva: string;
	nomeTurma: string;
	nomeAluno: string;
	isPermiteLiberacao: boolean;
	isPermiteCancelamentoLiberacao: boolean;
	isAusenciaRegistrada: boolean;
	isAguardandoAvaliado: boolean;
	isAguardandoAvaliadoIniciarAProva: boolean;
	isFazendoProva: boolean;
	isFinalizouProva: boolean;
	isAnulada: boolean;
	isOnline: boolean;
}

class TipoAplicacao extends EnumFP { 

	static E = new TipoAplicacao("E", "Lista de exercícios");
	static P = new TipoAplicacao("P", "Online");
	static I = new TipoAplicacao("I", "Impressa pelo avaliado");
	static F = new TipoAplicacao("F", "Impressa");
	
	constructor(id: string, idMsg: string) {
		super(TipoAplicacao.name, id, idMsg);
	}

	static from(id: string): TipoAplicacao {
		return <TipoAplicacao> EnumFP.from(TipoAplicacao.name, id);
	}
}
class TipoAplicacaoFase extends EnumFP { 

	static INSCRICOES = new TipoAplicacaoFase("INSCRICOES", "FP_FRONT_AgendamentoProvaVH_115");
	static APLICACAO = new TipoAplicacaoFase("APLICACAO", "FP_FRONT_AgendamentoProvaVH_116");
	static RECURSOS = new TipoAplicacaoFase("RECURSOS", "FP_FRONT_AgendamentoProvaVH_117");
	static LIBERADO = new TipoAplicacaoFase("LIBERADO", "FP_FRONT_AgendamentoProvaVH_118");
	static INTERMEDIARIA = new TipoAplicacaoFase("INTERMEDIARIA", "FP_FRONT_AgendamentoProvaVH_119");
	static GERAL = new TipoAplicacaoFase("GERAL", "FP_FRONT_AgendamentoProvaVH_120");
	static REAPLICACAO = new TipoAplicacaoFase("REAPLICACAO", "FP_FRONT_AgendamentoProvaVH_121");

	constructor(id: string, idMsg: string) {
		super(TipoAplicacaoFase.name, id, idMsg);
	}

	static from(id: string): TipoAplicacaoFase {
		return <TipoAplicacaoFase> EnumFP.from(TipoAplicacaoFase.name, id);
	}
}
class InfosSituacaoAvaliado {
	ultimoKA: number;
	agora: number;
	codProvaFeita: Number;
	dataEncerramento: String;
	codUsuarioFiscal: Number;
	isLiberado: Boolean;
	isAusente: Boolean;
	validacaoFaceMatch: Boolean;
	revalidacaoFaceMatch: Boolean;
	codAgendamento: Number;
	isAnulada: Boolean;
	possuiNota: Boolean;
	isProvaIniciada: Boolean;
	isProvaFinalizada: Boolean;
	isCadernoPreGerado: Boolean;
	isOnline: Boolean;
	isProvaReaberta?: Boolean;
	statusInscricao?: string;
	possuiConfirmacaoInscricao?: Boolean;
	// dataUltimaReabertura?: string;
	// minutosToleranciaReabertura?: string;
}

enum TipoFaceMatchLog {
	/* 0 */ VALIDACAO_SUCESSO,
	/* 1 */ VALIDACAO_ERRO,
	/* 2 */ VALIDACAO_MANUAL_SOLICITADA,
	/* 3 */ VALIDACAO_MANUAL_ACEITA,
	/* 4 */ VALIDACAO_MANUAL_RECUSADA,
	/* 5 */ REVALIDACAO_SUCESSO,
	/* 6 */ REVALIDACAO_ERRO,
	/* 7 */ REVALIDACAO_MANUAL_SOLICITADA,
	/* 8 */ REVALIDACAO_MANUAL_ACEITA,
	/* 9 */ REVALIDACAO_MANUAL_RECUSADA
}
enum StatusInscricao {
	PENDENTE = 'PENDENTE',
	CONFIRMADO = 'CONFIRMADO'
}
type ListagemAgendamentoTO = {
	codAgendamento: number;
	nomeSegmento: string;
	codUsuarioCriador: number;
	inicioFaseAplicacao: string;
	fimFaseAplicacao: string;
	dataInicializacaoDoAgendamento: string;
	dataEncerramentoDoAgendamento: string;
	dataFinalizacaoDoAgendamento: string;
	isArquivada: boolean;
	isResultadoProvaLiberadoFiscal: boolean;
	tipoAplicacaoEnum: string;
	codProva: number;
	nomeProva: string;
	codUsuarioFiscal: number;
	nomeFiscal: string;
	nomeTurma: string;
	numTurmas: number;
	isAgendamentoSemFiscal: boolean;
	isAguardandoAutorizacaoAdministrador: boolean;
	tokenDeIdentificacao: string;
	pathZip: string;
	detalhesAgendamentoUsuarios: DetalhesAgendamentoUsuariosTO;
	situacao: string;
	tipoAplicacao: string;
	isUsuarioLogadoPodeEncerrar: boolean;
	isUsuarioLogadoPodeFinalizar: boolean;
	isAplicacaoTemplate: boolean;
	possuiAvaliacaoPessoal: boolean;
}
type DetalhesAgendamentoUsuariosTO = {
	isSalaDeAvaliacaoAberta: boolean;
	numRecursos: number;
	numFeedbacks: number;
	numAlunosAgendados: number;
	numAlunosAguardandoAvaliado: number;
	numAlunosAguardandoAvaliadoIniciarAProva: number;
	numAlunosFazendoProva: number;
	numAlunosFinalizaramProva: number;
	numAlunosAnuladas: number;
	numDiscursivasACorrigir: number;
	numAlunosAguardandoLiberacao: number;
}
type SalaAvaliacaoTO = {
	codAgendamento: number;
	codProva: number;
	agora: string;
	dataEncerramento: string;
	dataFinalizacao: string;
	codUsuarioFiscal: number;
	isInterrupcoesParamCronometro: boolean;
	listagemAgendamentoTO: ListagemAgendamentoTO;
	isAguardandoAutorizacaoAdministrador: boolean;
	collectionAgendamentoEmAndamentoUsuarioTO: any[];
	collectionSalaAvaliacaoFaseTO: any[];
	isFacematchHabilitado: boolean;
	isAvaliadoPodeProsseguirSemFacematch: boolean;
	isScreenshotHabilitado: boolean;
	isAplicacaoVigente: boolean;
	tokenJwtProctoring: string;
	codigoAcessoRotativoTO: any;
	possuiConfirmacaoInscricao: boolean;
}
type MontarLinkNomeUsuarioCfgs = {
	codUsuario: number;
	isCpfVerificadoOCR: boolean;
	codProvaFeita: number;
	nomeUsuario: string;
	tipoAcessibilidade?: string;
}