class CronoTemporizadorVH {
    worker: Worker;
    $temporizador: any;
    callback: Function;
    iniciouTemporizador: boolean;
    milisegundosRestantes: number;

    cronoListener: Function = (msDecorridos) => {
        this.handleCronoTick(msDecorridos);
    }

    iniciarCronometro(milisegundosRestantes, $temporizador, callback: Function) {
        this.pararCronometro();

        this.milisegundosRestantes = milisegundosRestantes;
        this.$temporizador = $temporizador;
        this.iniciouTemporizador = false;
        this.callback = callback;

        UtilCrono.adicionarListener(this.cronoListener);
    }

    async handleCronoTick(msDecorridos: number) {
        if (!this.$temporizador.is(':visible')) {
            this.pararCronometro();
        }

        if (msDecorridos == null || msDecorridos < 1) return;

        const tempoFormatado = Math.round(this.milisegundosRestantes / 1000);

        const $timerProgress = this.$temporizador.find('.progress-value');
        $timerProgress.text(tempoFormatado);

        if (this.isTempoEsgotado()) {
            this.pararCronometro();
            this.callback();
        }

        if(!this.iniciouTemporizador) {
            this.setarPorcentagemInicialTemporizador(100);
            this.iniciarTemporizadorBarra();
            this.iniciouTemporizador = true;
        }

        this.milisegundosRestantes -= msDecorridos;
    }

    isTempoEsgotado(): boolean {
        return this.milisegundosRestantes <= 0;
    }

    pararCronometro() {
        UtilCrono.removerListener(this.cronoListener);
    }

    iniciarTemporizadorBarra() {
        const tempoEmSegundos = Math.round(this.milisegundosRestantes / 1000);

        const esquerda = this.$temporizador.find('.progress-left .progress-bar');
        const direita = this.$temporizador.find('.progress-right .progress-bar');

        const tempoCadaBarra = tempoEmSegundos / 2;

        direita.css('transition', `transform ${tempoCadaBarra}s linear`);
        direita.css('transform', 'rotate(0deg)');

        setTimeout(() => {
            esquerda.css('transition', `transform ${tempoCadaBarra}s linear`);
            esquerda.css('transform', 'rotate(360deg)');
        }, this.milisegundosRestantes / 2);
    }

    setarPorcentagemInicialTemporizador(percentual): void {
        var esquerda = $('.progress-left .progress-bar');
        var direita = $('.progress-right .progress-bar');

        var rotacaoDireita, rotacaoEsquerda;

        if (percentual <= 50) {
            rotacaoDireita = 0;
            rotacaoEsquerda = 180 + (50 - percentual) * 3.6;
        } else {
            rotacaoDireita = -180 + ((100 - percentual) * 3.6);
            rotacaoEsquerda = 180;
        }

        direita.css('transform', `rotate(${rotacaoDireita}deg)`);
        esquerda.css('transform', `rotate(${rotacaoEsquerda}deg)`);
    }
}
