class CadastroTelaVH extends AmaisVH {

	isChecandoAjuda: any;
	idAjudaSelecionada: any;
	textoBusca: any;

	constructor() {
		super(CadastroTelaVH.name);
		this.addOperacaoParaHash("ctla", this.listar);
		this.addOperacaoParaHash("ctea", this.editarAjuda);	
	}

	exibicaoTelaTOPorHash = {};
	identificadoresJaCadastradosMasSemAjuda = {};

	verificarCamposQuePodemPossuirAjuda() {

		if (this.isChecandoAjuda) {
			return;
		}

		this.isChecandoAjuda = true;

		try {
			var ajudas = [];

			var hash = cadastroTelaVH.getHashTelaAtual();
			var titulo = $("#titulo").text();
			var subtitulo = $("#subtitulo").text();
			var $divLinkTitulo = $("#titulo").parent();

			if ($divLinkTitulo.find(".fa-question-circle-o").length == 0) {

				if (!cadastroTelaVH.exibicaoTelaTOPorHash[hash] && !cadastroTelaVH.identificadoresJaCadastradosMasSemAjuda[hash]) {
					ajudas.push({ identificador: hash, nome: cadastroTelaVH.getNomeElemento(titulo, subtitulo, hash) });
				}

				$("#tituloSuperiorEsquerdo h1").attr("identificador-ajuda", hash);
			}

			$("[vh][id]").each(function () {
				var id = this.id;

				var $elementoLink = null;
				var nome = null;

				if ($(this).is("h3")) {
					$elementoLink = $(this);
					nome = $elementoLink.text();

				} else {
					var $formGroup = $(this).closest(".form-group");

					if ($formGroup.length) {
						var idComponente = $formGroup.attr("id");

						if (id == null || (idComponente != null && !idComponente.startsWith("componente_"))) {
							id = idComponente;
						}

						var $label = $formGroup.find("> label");
						var $inputGroup = $formGroup.find(".input-group");

						if ($label.length) {
							nome = $label.text();
							$elementoLink = $label;

						} else if ($inputGroup.length) {
							$elementoLink = $inputGroup;

						} else {
							$elementoLink = $formGroup;
						}
					}
				}

				if (id.startsWith("componente_")) {
					return;
				}

				var identificador = $(this).attr("vh") + "." + id;

				if ($elementoLink && $elementoLink.attr("identificador-ajuda") != null) return;

				if (nome == null) {
					nome = $(this).attr("placeholder");
				}
				if (nome == null) {
					nome = this.id;
				}

				if ($elementoLink) {
					$elementoLink.attr("identificador-ajuda", identificador);
				}

				if (!cadastroTelaVH.exibicaoTelaTOPorHash[identificador] && !cadastroTelaVH.identificadoresJaCadastradosMasSemAjuda[identificador]) {
					ajudas.push({ identificador: identificador, nome: cadastroTelaVH.getNomeElemento(titulo, subtitulo, nome) });
				}
			});

			cadastroTelaVH.exibirLinksDeAjudaDisponiveis();

		} catch (e) {
		} finally {
			this.isChecandoAjuda = false;
		}
	}

	getHashTelaAtual() {
		var hash = $.address.value();

		if (hash == null || hash == "" || hash == "/") {
			return "/";

		} else {
			return hash.split("/")[1];
		}
	}

	getNomeElemento(titulo, subtitulo, nomeElemento) {
		if (this.hasValue(titulo)) {
			if (this.hasValue(subtitulo)) {
				return titulo + " - " + subtitulo + ": " + nomeElemento;
			} else {
				return titulo + ": " + nomeElemento;
			}
		} else if (this.hasValue(subtitulo)) {
			return subtitulo + ": " + nomeElemento;
		} else {
			return "(sem título): " + nomeElemento;
		}
	}

	exibirLinksDeAjudaDisponiveis() {

		if (!AmaisVH.identificadoresDeTelaComAjuda) return;

		$("[identificador-ajuda]:not([com-link-ajuda])").each((i, elemento) => {
			var $this = $(elemento);
			var identificador = $this.attr("identificador-ajuda");

			if (AmaisVH.identificadoresDeTelaComAjuda.includes(identificador) || this.isAdministradorSistema()) {
				var htmlLink = ` <i class='fa fa-question-circle-o' title='${this.getMsg("FP_FRONT_CadastroTelaVH_001")}'></i> `;
				var $inputGroupButton = $this.find("> .input-group-btn");
				var $clicavel;

				if ($inputGroupButton.length == 1) {
					$clicavel = $inputGroupButton.append("<button class='btn btn-default'>" + htmlLink + "</button>").find("button:last-child");

				} else {
					if ($this.is(".input-group")) {
						htmlLink = " <span class='input-group-addon'> " + htmlLink + " </span> ";
					}

					$clicavel = $this.append(htmlLink).find("i.fa-question-circle-o");
				}

				$this.attr("com-link-ajuda", "true");

				$clicavel.off("click").on("click", async ({ target }) => {
					const identificador = $(target).closest("[identificador-ajuda]").attr("identificador-ajuda");

					if (this.isAdministradorSistema()) {
						this.editarAjuda(identificador);
					} else {
						try {
							const setExibicaoAjudaTO = await this.call({
								endpoint: "CadastroTelaFCD/recuperarAjuda",
								params: [[identificador], AmaisVH.localeDoUsuarioLogado],
								desativarMsgCarregando: true,
								onRequestError: async () => BackendRequestError.ERRO_TRATADO
							});

							const exibicaoAjudaTO = setExibicaoAjudaTO[0];
							this.addPopup({
								id: "popup_ajuda",
								titulo: "<i class='fa fa-question-circle-o' style='opacity:1'></i> " + exibicaoAjudaTO.nome,
								botoes: [{
									label: "Fechar",
									onClick: async () => {
										this.fecharPopup("popup_ajuda");
									}
								}]
							});
							this.append(exibicaoAjudaTO.textoAjuda);
							this.exibirPopups();
						} catch (ignored) {}
					}
					return false;
				});
			}
		});
	}

	async editarAjuda(identificador) {

		UtilHash.registrarHistorico(this.editarAjuda, identificador);

		const edicaoAjudaTO = await this.call("CadastroTelaFCD/recuperarEdicao", identificador);

		this.limpar();
		this.setTitulo("Edição de texto de ajuda");
		this.setSubtitulo(edicaoAjudaTO.nome);

		this.addCampoExibicao({ label: "Identificador", classe: "col-sm-12", valor: edicaoAjudaTO.identificador });
		this.addCampoTexto({ id: "tela_nome", label: "Título da ajuda", classe: "col-sm-12", valor: edicaoAjudaTO.nome });
		this.addEditorHTML({ id: "tela_texto_ajuda", label: "Português - Brasil", classe: "col-sm-6", valor: edicaoAjudaTO.textoAjuda });
		this.addEditorHTML({ id: "tela_texto_ajuda_es_ES", label: "Espanhol - Espanha", classe: "col-sm-6", valor: edicaoAjudaTO.textoAjuda_es_ES });

		this.append("<div class='btn-group' style='float: right;'>");

		this.addBotao({
			label: "Remover", 
			classe: "btn-danger", 
			onClick: async () => {

				edicaoAjudaTO.textoAjuda = null;

				const id = await this.call("CadastroTelaFCD/salvar", edicaoAjudaTO);
				this.idAjudaSelecionada = id;
				UtilHash.voltar();
			}
		});

		this.addBotao({
			label: "Salvar", 
			onClick: async () => {

				if (!this.validarCamposObrigatorios("tela_nome", "tela_texto_ajuda")) return false;

				edicaoAjudaTO.nome = this.getValor("tela_nome");
				edicaoAjudaTO.textoAjuda = this.getValor("tela_texto_ajuda");
				edicaoAjudaTO.textoAjuda_es_ES = this.getValor("tela_texto_ajuda_es_ES");

				edicaoAjudaTO.textoAjuda = await UtilHtml.enviarImagensParaArmazenamento(edicaoAjudaTO.textoAjuda);
				edicaoAjudaTO.textoAjuda_es_ES = await UtilHtml.enviarImagensParaArmazenamento(edicaoAjudaTO.textoAjuda_es_ES);

				this.idAjudaSelecionada = await this.call("CadastroTelaFCD/salvar", edicaoAjudaTO);

				UtilHash.voltar();
			}
		});

		this.append("</div>");

		this.exibir();
	}

	async exibirAjuda(hash) {
		const exibicaoTelaTO = await this.call("CadastroTelaFCD/recuperarExibicaoTela", hash);

		this.addPopup({ titulo: "<i class='fa fa-question-circle-o text-primary'></i> " + exibicaoTelaTO.nome, minWidth: "200px", botoes: [{ label: "Fechar" }] });
		this.append(exibicaoTelaTO.textoAjuda);
		this.exibirPopups();
	}

	atualizarLinkAjuda(isComTextoAjuda) {
		var linkAjuda = $("#titulo i");
		if (isComTextoAjuda) {
			$(linkAjuda).removeClass("text-danger").addClass("text-primary");
		} else {
			$(linkAjuda).removeClass("text-primary").addClass("text-danger");
		}
	}

	async listar(textoBusca) {

		UtilHash.registrarHistorico(this.listar, textoBusca);

		this.textoBusca = textoBusca;

		const collectionExibicaoTelaTO = await this.call("CadastroTelaFCD/listarTelas", textoBusca);

		this.limpar();
		this.setTitulo("Textos de ajuda");

		this.addCampoTexto({ id: "textoBusca", dica: "Busca textual", prefixo: "<i class='fa fa-search'></i> ", valor: this.textoBusca, onEnter: () => {
			this.textoBusca = this.getValor("textoBusca");
			this.listar(this.textoBusca);
		}});

		for (const exibicaoTelaTO of collectionExibicaoTelaTO) {

		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: "Título", prop: "${nome}<br><br>{${identificador}}"});
		colunas.push({titulo: "Cadastro", prop: "dataCadastro", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: "Atualização", prop: "dataAtualizacao", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: "Português - Brasil", prop: "textoAjuda", classe: "descricao"});
		colunas.push({titulo: "Espanhol - Espanha", prop: "textoAjuda_es_ES", classe: "descricao"});

		await this.addTabela({
			collection: collectionExibicaoTelaTO,
			propId: "identificador",
			colunas: colunas,
			onEdicao: this.editarAjuda,
			idSelecionado: this.idAjudaSelecionada
		});

		this.exibir();
		this.idAjudaSelecionada = null;
	}
}

const cadastroTelaVH = new CadastroTelaVH();

$(document).ready(() => {
	$(document).on("container-alterado", () => {
		cadastroTelaVH.verificarCamposQuePodemPossuirAjuda();
	})
});