class CadastroCargoVH extends AmaisVH {
	
	buscaTextual: any;
	buscaNvel: any;
	collectionListagemCargoTO: any;
	nomeDocOriginal: any;
	nomeArqGabaritoOriginal: any;
	codCargoRepresentante: any;
	codsCargosRepetidos: any;

	constructor() {
		super(CadastroCargoVH.name);
	}

	async listar() {

		const exibicaoListagemCargoTO = await this.call("CadastroCargoFCD/listarCargos", this.buscaTextual, this.buscaNvel);

		this.addCampoTexto({
			dica: this.getMsg("FP_FRONT_CadastroCargoVH_001"),
			// widthComponente: "40%",
			id: "buscaTextual",
			onEnter: () => {
				this.buscaTextual = this.getValor("buscaTextual");
				this.listar();
			},
			valor: this.buscaTextual
		})

		this.addRadioGroup({
			id: "nivel",
			label: this.getMsg("FP_FRONT_CadastroCargoVH_002"),
			valor: this.buscaNvel,
			collection: exibicaoListagemCargoTO.collectionOpcaoListaTONiveis,
			onChange: () => {
				this.buscaNvel = this.getValor("nivel");
				this.listar();
			},
			width: "45%"
		})

		this.addBotao({
			texto: `<i class='fa fa-plus-circle'></i> ${this.getMsg("FP_FRONT_CadastroCargoVH_008")}`,
			onClick: () => this.editar(),
			css: "float: right",
			classe: "btn-success btn-sm"
		});

		this.addBotao({
			texto: this.getMsg("FP_FRONT_CadastroCargoVH_003"),
			onClick: () => this.exibirSelecaoEquivalentes(),
			css: "float: right",
			classe: "btn-sm"
		});

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroCargoVH_004"), prop: "nome"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroCargoVH_005"), prop: "area"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroCargoVH_006"), prop: "nivel"});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroCargoVH_007"), prop: "quantidade"});

		await this.addTabela({
			collection: exibicaoListagemCargoTO.collectionListagemCargoTO,
			propId: "codCargo",
			colunas: colunas,
			onExclusao: async (botao, codCargo) => {
				await this.call("CadastroCargoFCD/excluir", codCargo);
				await this.listar();
			},
			onEdicao: async (codCargo) => await this.editar(codCargo),
			ordenacao: [["nome", false], ["area", false]]
		})

		this.limpar(true)
		this.exibir();
		this.collectionListagemCargoTO = exibicaoListagemCargoTO.collectionListagemCargoTO;
	}

	async editar(codCargo?: number) {

		const recuperacaoCargoTO = await this.call("CadastroCargoFCD/recuperar", codCargo);
		
		var nomePopUp;
		if (recuperacaoCargoTO.codCargo == null) {
			nomePopUp = this.getMsg("FP_FRONT_CadastroCargoVH_009");
		} else {
			nomePopUp = this.getMsg("FP_FRONT_CadastroCargoVH_010");
		}

		this.addPopup({
			id: "exibicao_cargo",
			titulo: nomePopUp,
			botoes: [{ label: this.getMsg("FP_FRONT_CadastroCargoVH_011"), onClick: () => this.salvarCargo() }],
		});

		this.addInputHidden("codCargo", recuperacaoCargoTO.codCargo);

		this.addCampoTexto({
			id: "nome_usuario",
			label: this.getMsg("FP_FRONT_CadastroCargoVH_012"),
			valor: recuperacaoCargoTO.nome,
		})

		this.addCampoTexto({
			id: "area_usuario",
			label: this.getMsg("FP_FRONT_CadastroCargoVH_013"),
			valor: recuperacaoCargoTO.area,
		})

		this.addRadioGroup({
			id: "nivel",
			label: this.getMsg("FP_FRONT_CadastroCargoVH_014"),
			valor: recuperacaoCargoTO.nivel,
			collection: recuperacaoCargoTO.collectionOpcaoListaTONiveis,
		})

		if (recuperacaoCargoTO.codCargo != null) {
			this.addCheckbox({
				id: "isExcluido",
				label: this.getMsg("FP_FRONT_CadastroCargoVH_015"),
				valor: recuperacaoCargoTO.isExcluido,
			});
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroCargoVH_016"), prop: this.abrirProvaPdf});
		colunas.push({titulo: this.getMsg("FP_FRONT_CadastroCargoVH_017"), prop: this.abrirGabaritoPdf});

		if (nomePopUp == "Edição de cargo") {
			await this.addTabela({
				collection: recuperacaoCargoTO.collectionListagemProvasDoCargoTO,
				propId: "codCargo",
				colunas: colunas
			})
		}

		this.exibirPopups();
	}

	abrirProvaPdf(listagemProvasDoCargoTO) {
		this.nomeDocOriginal = listagemProvasDoCargoTO.nomeDocOriginal;
		if (this.nomeDocOriginal == null) {
			return listagemProvasDoCargoTO.nomeProva;
		} else {
			return " <a onclick='cadastroCargoVH.abrirMidia(\"" + listagemProvasDoCargoTO.nomeDocOriginal + "\")'>" + listagemProvasDoCargoTO.nomeProva + "</a>";
		}
	}

	abrirGabaritoPdf(listagemProvasDoCargoTO) {
		this.nomeArqGabaritoOriginal = listagemProvasDoCargoTO.nomeArqGabaritoOriginal;
		if (this.nomeArqGabaritoOriginal == null) {

		} else {
			return " <a onclick='cadastroCargoVH.abrirMidia(\"" + listagemProvasDoCargoTO.nomeArqGabaritoOriginal + "\")'>" + this.getMsg("FP_FRONT_CadastroCargoVH_018") + "</a>";
		}
	}

	async exibirSelecaoEquivalentes() {
		var colecao = [];

		for (var i = 0; i < this.collectionListagemCargoTO.length; i++) {
			colecao.push({
				id: this.collectionListagemCargoTO[i].codCargo,
				nome: this.collectionListagemCargoTO[i].nomeComArea,
				descricao: this.collectionListagemCargoTO[i].nivel + " - " + this.collectionListagemCargoTO[i].quantidade + this.getMsg("FP_FRONT_CadastroCargoVH_019")
			})
		}
		this.addPopup({
			id: "associar_equivalencia",
			titulo: this.getMsg("FP_FRONT_CadastroCargoVH_020"),
			width: "770px",
			// height: "440px",
			botoes: [{ label: this.getMsg("FP_FRONT_CadastroCargoVH_022"), onClick: async () => this.exibirEquivalentesEscolhidos() }],
		});
		await this.addSelect({
			collection: colecao,
			id: "cargo_representante",
			label: this.getMsg("FP_FRONT_CadastroCargoVH_021"),
			classe: "col-md-12"
		})

		await this.addSelect({
			collection: colecao,
			id: "cargos_repetidos",
			multiplo: true,
			label: this.getMsg("FP_FRONT_CadastroCargoVH_023"),
			classe: "col-md-12"
		})

		this.exibirPopups();
	}

	exibirEquivalentesEscolhidos() {
		if (!this.validarCamposObrigatorios("cargo_representante", "cargos_repetidos")) {
			return false
		}

		this.codCargoRepresentante = this.getValor("cargo_representante");
		this.codsCargosRepetidos = this.getValor("cargos_repetidos");

		this.addPopup({
			id: "associacao_de_equivalencia",
			titulo: this.getMsg("FP_FRONT_CadastroCargoVH_024"),
			width: "600px",
			// height: "250px",
			botoes: [{ label: this.getMsg("FP_FRONT_CadastroCargoVH_025"), onClick: async () => this.associarEquivalentes() }],
		});

		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroCargoVH_026"), valor: this.getNomeCargo(this.codCargoRepresentante, this.collectionListagemCargoTO)})

		var nomesCargosRepetidos = []

		for (var i = 0; i < this.codsCargosRepetidos.length; i++) {
			nomesCargosRepetidos.push(this.getNomeCargo(this.codsCargosRepetidos[i], this.collectionListagemCargoTO))
		}

		this.addCampoExibicao({ label: this.getMsg("FP_FRONT_CadastroCargoVH_027"), valor: nomesCargosRepetidos.join(", ")})

		this.exibirPopups();
	}

	getNomeCargo(codCargo, collectionListagemCargoTO) {

		for (var i = 0; i < collectionListagemCargoTO.length; i++) {
			var listagemCargoTO = collectionListagemCargoTO[i]

			if (listagemCargoTO.codCargo == codCargo) {
				return listagemCargoTO.nomeComArea
			} else {
				var nome = this.getNomeCargo(codCargo, listagemCargoTO)

				if (nome != null) {
					return nome
				}
			}
		}

		return null
	}

	async associarEquivalentes() {
		var associacaoCargosEquivalentesTO = {
			codCargoRepresentante: this.codCargoRepresentante,
			codsCargosRepetidos: this.codsCargosRepetidos
		}

		await this.call("CadastroCargoFCD/associarEquivalentes", associacaoCargosEquivalentesTO);
		this.listar();
	}

	async salvarCargo() {
		var salvamentoCargoTO = {
			codCargo: this.getValor("codCargo"),
			nome: this.getValor("nome_usuario"),
			area: this.getValor("area_usuario"),
			nivel: this.getValor("nivel"),
			isExcluido: this.getValor("isExcluido")
		}

		if (this.isEmpty(salvamentoCargoTO.nome)) {
			this.validarCamposObrigatorios("nome_usuario");
			return false;
		}

		await this.call("CadastroCargoFCD/salvar", salvamentoCargoTO);

		this.listar();
	}

	
}

const cadastroCargoVH = new CadastroCargoVH();

