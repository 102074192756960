class CalculadoraFinanceiraVH extends AmaisVH {
	
	temImagemFaltando: any;

	constructor() {
		super("CalculadoraFinanceiraVH");
	}

	exibirCalculadora(botao) {

		var $botao = $(botao);

		if (this.temImagemFaltando) {
			$('#calculadora-financeira-panel').toggle();
			$('#calculadora-financeira-panel').remove();
			$botao.data("fp-ja-inicializou", false);
			this.temImagemFaltando = false;
		}

		if (!$botao.data("fp-ja-inicializou")) {

			$botao.closest("[questao]").on("fp-questao-hidden", () => {
				if (!$botao.is(":visible")) {
					this.fecharCalculadora();
				}
			});

			$botao.data("fp-ja-inicializou", true);

			var h = [];

			h.push('<div id="calculadora-financeira-panel" class="calculadora-financeira-panel" style="display:none; z-index:99999;" ondragstart="return false" ondrop="return false"> ');
			h.push('    <div class="panel-heading">');
			h.push(`        <i class="hidden-xs fa fa-calculator"></i> ${this.getMsg("FP_FRONT_CalculadoraFinanceiraVH_001")}`);
			h.push('        <div class="btn-group pull-right">');
			h.push('            <button type="button" class="btn btn-default btn-xs" onclick="calculadoraFinanceiraVH.fecharCalculadora()">');
			h.push('                <span>x</span>');
			h.push('            </button>');
			h.push('        </div>');
			h.push('    </div>');
			h.push('    <div class="panel-body-calculadora">');

			//
			// Início - HTML Calculadora - sem iframe - sem jquery
			//

			h.push('<link href="widgets/calculadora-hp/arquivos/hp1xc.css" rel="STYLESHEET" type="text/css">');
			h.push('<script src="widgets/calculadora-hp/arquivos/bundled.js" type="text/javascript"></script>');

			h.push(' <div id="pointer_div" style="position: absolute; height: 375px; width: 600px" tabindex="1">');
			h.push('<img alt="" id="cross" src="widgets/calculadora-hp/arquivos/hp12c.png" style="position: absolute; left: 0px; top:0px;">');
			h.push('<div style="z-index:1; position:absolute; left: 87px; top:24px; font-weight: bold; font-size: 16px; font-family: sans-serif;">');
			h.push('<input id="display" type="text">');
			h.push('</div>');
			h.push('<div style="z-index:1; position:absolute; left: 120px; top:58px; font-size: 12px; font-family: sans-serif;">');
			h.push('<span id="modifier"></span>');
			h.push('</div>');
			h.push('<div style="z-index:1; position:absolute; left: 257px; top:58px; font-size: 12px; font-family: sans-serif;">');
			h.push(' <span id="begin"></span>');
			h.push('</div>');
			h.push('<div style="z-index:1; position:absolute; left: 307px; top:58px; font-size: 12px; font-family: sans-serif;">');
			h.push('<span id="dmyc"></span>');
			h.push('</div>');
			h.push('<div style="z-index:1; position:absolute; left: 188px; top:58px; font-size: 12px; font-family: sans-serif;">');
			h.push('<span id="pgrm"></span>');
			h.push('</div>');
			h.push('<img alt="" id="lcd0a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd0b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd0c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd0d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd0e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd0f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd0g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd0p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd0t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 120px;">');
			h.push('<img alt="" id="lcd1a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 144px;">');
			h.push('<img alt="" id="lcd1b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 144px;">');
			h.push('<img alt="" id="lcd1c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 144px;">');
			h.push('<img alt="" id="lcd1d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 144px;">');
			h.push('<img alt="" id="lcd1e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 144px;">');
			h.push('<img alt="" id="lcd1f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 144px;">');
			h.push('<img alt="" id="lcd1g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 144px;">');
			h.push('<img alt="" id="lcd1p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 144px;">');
			h.push('<img alt="" id="lcd1t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 144px;">');
			h.push('<img alt="" id="lcd2a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 168px;">');
			h.push('<img alt="" id="lcd2b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 168px;">');
			h.push('<img alt="" id="lcd2c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 168px;">');
			h.push('<img alt="" id="lcd2d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 168px;">');
			h.push('<img alt="" id="lcd2e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 168px;">');
			h.push('<img alt="" id="lcd2f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 168px;">');
			h.push('<img alt="" id="lcd2g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 168px;">');
			h.push('<img alt="" id="lcd2p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 168px;">');
			h.push('<img alt="" id="lcd2t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 168px;">');
			h.push('<img alt="" id="lcd3a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 192px;">');
			h.push('<img alt="" id="lcd3b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 192px;">');
			h.push('<img alt="" id="lcd3c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 192px;">');
			h.push('<img alt="" id="lcd3d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 192px;">');
			h.push('<img alt="" id="lcd3e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 192px;">');
			h.push('<img alt="" id="lcd3f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 192px;">');
			h.push('<img alt="" id="lcd3g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index: 1; position: absolute; top: 35px; visibility: visible; left: 192px;">');
			h.push('<img alt="" id="lcd3p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 192px;">');
			h.push('<img alt="" id="lcd3t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 192px;">');
			h.push('<img alt="" id="lcd4a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd4b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd4c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd4d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd4e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd4f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd4g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd4p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd4t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 216px;">');
			h.push('<img alt="" id="lcd5a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd5b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd5c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd5d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd5e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd5f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd5g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd5p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd5t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 240px;">');
			h.push('<img alt="" id="lcd6a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd6b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd6c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd6d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd6e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd6f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd6g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd6p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd6t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 264px;">');
			h.push('<img alt="" id="lcd7a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd7b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd7c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd7d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd7e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd7f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd7g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd7p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd7t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 288px;">');
			h.push('<img alt="" id="lcd8a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd8b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd8c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd8d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd8e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd8f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd8g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd8p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd8t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 312px;">');
			h.push('<img alt="" id="lcd9a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd9b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd9c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd9d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd9e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd9f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd9g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd9p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd9t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 337px;">');
			h.push('<img alt="" id="lcd10a" src="widgets/calculadora-hp/arquivos/11_lcda.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('<img alt="" id="lcd10b" src="widgets/calculadora-hp/arquivos/11_lcdb.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('<img alt="" id="lcd10c" src="widgets/calculadora-hp/arquivos/11_lcdc.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('<img alt="" id="lcd10d" src="widgets/calculadora-hp/arquivos/11_lcdd.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('<img alt="" id="lcd10e" src="widgets/calculadora-hp/arquivos/11_lcde.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('<img alt="" id="lcd10f" src="widgets/calculadora-hp/arquivos/11_lcdf.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('<img alt="" id="lcd10g" src="widgets/calculadora-hp/arquivos/11_lcdg.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('<img alt="" id="lcd10p" src="widgets/calculadora-hp/arquivos/11_lcdp.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('<img alt="" id="lcd10t" src="widgets/calculadora-hp/arquivos/11_lcdt.png" style="z-index:1; position:absolute; top: 35px; visibility: hidden; left: 362px;">');
			h.push('</div>');

			// Fim - HTML Calculadora

			h.push('    </div>');
			h.push('</div>');

			let html = h.join("");
			html = html.replaceAll("<img ", "<img onerror=\"calculadoraFinanceiraVH.onErroCargaImagem(this);\"");
			this.addFerramenta({
				id: "container-calculadora-financeira",
				html: html
			});

			$(botao).on("remove", () => {
				this.fecharCalculadora();
			});
		}

		$('#calculadora-financeira-panel').toggle();
	}

	onErroCargaImagem(img) {
		this.temImagemFaltando = true;
	}

	fecharCalculadora() {
		$('#calculadora-financeira-panel').hide();
	}
}

const calculadoraFinanceiraVH = new CalculadoraFinanceiraVH();
