class MontagemProvaVH extends AmaisVH {

	filtrosSelecaoQuestoesTO: { filtrosAtivos: any[]; textoBusca: any; tituloExibicaoFiltro: string; codsDisciplinasRestricao: any; };
	numQuestoesEncontradas: number;

	constructor() {
		super(MontagemProvaVH.name);
		this.addOperacaoParaHash("busca", this.fazerNovaProva);
		this.addOperacaoParaHash("mpbt", this.onBuscaTextual);	
	}

	async fazerNovaProva(...args: any[]) {

		UtilHash.registrarHistorico(this.fazerNovaProva);

		this.limpar();

		this.setTitulo(this.getMsg("MSG_VH_MP_01"))
		this.setSubtitulo(this.getMsg("MSG_VH_MP_02"))

		await selecaoQuestoesVH.montarHtmlFiltros({
			subtitulo: this.getMsg("MSG_VH_MP_03"),
			onFiltroAlterado: async () => await montagemProvaVH.buscarQuestoes()
		});
		this.fecharFormulario();

		this.append("<div id='div_resultado_busca'>");
		this.addSubtitulo(this.getMsg("MSG_VH_MP_04"));

		this.append(`
			<div class='espacamentoBusca'></div>
			<div id='resultadoBusca' class='resultadoBusca col-md-12'></div>
			<div class='espacamentoBusca'></div>
			<div class='col-md-4'>
				<div class='panel panel-default'>
					<div class='panel-heading'>
						${this.getMsg("MSG_VH_MP_05")}
					</div>
					<div class='panel-body' id='box_aleatoriamente'>
						<div class='campo_texto' id='textoQtdQuestoes' style='margin-bottom: 0;'>
							<label>${this.getMsg("MSG_VH_MP_06")}</label>
						</div>
						<div id='sliderContainer' class='col-md-12'></div>
		`);

		this.addBotao({
			id: 'btn_montagemprova_resolver',
			label: `<i class='fa fa-pencil'></i> ${this.getMsg("MSG_VH_MP_07")}`,
			onClick: async (event: any) => await montagemProvaVH.montarProva(null, event.target),
			css: "float: right", 
			classe: "btn-primary"
		});
		
		this.append(`
			</div></div></div>
			<div class='col-md-8'>
				<div class='panel panel-default'>
					<div class='panel-heading'>
						${this.getMsg("MSG_VH_MP_08")}
					</div>
					<div class='panel-body' id='box_por_concurso'></div>
				</div>
			</div></div>
		`);

		this.exibir();
		this.focar("textoBusca")

		await this.hide("div_resultado_busca");
		
		if (args != null && args[0] != null && args[1] != null) {

			const collectionFiltroAtivoTO = [];

			for (let i = 0; i < args.length; i = i + 2) {
				collectionFiltroAtivoTO.push({
					tipoFiltro: this.converterSiglaTipoFiltro(args[i]),
					codigo: args[i + 1],
				})
			}
			
			await selecaoQuestoesVH.adicionarFiltrosSemUtilizarListagem(collectionFiltroAtivoTO);
		}
	}

	converterSiglaTipoFiltro(sigla: any) {
		if ("DP" == sigla) {
			return "DISCIPLINA";
		} else if ("B" == sigla) {
			return "BANCA";
		} else if ("A" == sigla) {
			return "ANO";
		} else if ("O" == sigla) {
			return "ORGAO";
		}
	}
	
	async buscarQuestoes() {
		this.filtrosSelecaoQuestoesTO = selecaoQuestoesVH.getFiltrosSelecaoQuestoesTO();
		
		if (this.filtrosSelecaoQuestoesTO == null) {
			await this.hide("box_aleatoriamente", "box_por_concurso");
			await this.show("div_resultado_busca");
			this.setHtml("resultadoBusca", this.getMsg("MSG_VH_MP_09"));
			this.exibir();
			return;
		}
		
		$("#div_resultado_busca .subtitulo:first").html(this.filtrosSelecaoQuestoesTO.tituloExibicaoFiltro);
		
		if (this.filtrosSelecaoQuestoesTO != null) {

			const collectionExibicaoNumQuestoesPorProvaTO = await this.call("MontagemProvaFCD/buscarQuestoes", this.filtrosSelecaoQuestoesTO);
		
			this.numQuestoesEncontradas = 0

			this.setHtml("box_por_concurso", "")
			this.setIdTarget("box_por_concurso")

			for (let i = 0; i < collectionExibicaoNumQuestoesPorProvaTO.length; i++) {
				const exibicaoNumQuestoesPorProvaTO = collectionExibicaoNumQuestoesPorProvaTO[i];

				this.numQuestoesEncontradas += exibicaoNumQuestoesPorProvaTO.numQuestoes;

				if (i < 50) {
					this.append(`
						<div class='buscaQuestaoGrupamentoConcurso col-sm-12'>
							<div>${exibicaoNumQuestoesPorProvaTO.tituloProva}</div>
							<div class="links">
					`);

					this.addLink({
						label: exibicaoNumQuestoesPorProvaTO.numQuestoes + " " + this.getMsg("MSG_VH_MP_10"),
						onClick: async (event: any) => await montagemProvaVH.montarProva(exibicaoNumQuestoesPorProvaTO.codProva, event.target),
					})
					
					this.addLink({
						label: this.getMsg("MSG_VH_MP_11"),
						onClick: async () => await inicioPFVH.verificarPrecondicoesParaInicioDeProva(exibicaoNumQuestoesPorProvaTO.codProva),
					})

					this.append("</div></div>");
				}
			}

			this.setIdTarget(null)

			await this.show("div_resultado_busca", "box_aleatoriamente", "box_por_concurso");

			let numeroMinimoQuestoes = 1;
			let numeroMaximoQuestoes = 200;

			if (this.numQuestoesEncontradas == 0) {
				numeroMinimoQuestoes = 0
			}

			if (this.numQuestoesEncontradas < 200) {
				numeroMaximoQuestoes = this.numQuestoesEncontradas
			}

			this.addSlider("sliderContainer", "numQuestoesSorteadas", numeroMinimoQuestoes, numeroMaximoQuestoes)

			if (this.numQuestoesEncontradas == 0) {
				this.setHtml("resultadoBusca", "<b>" + this.getMsg("MSG_VH_MP_12") + "</b>")

			} else if (this.numQuestoesEncontradas == 1) {
				this.setHtml("resultadoBusca", "<b> " + this.getMsg("MSG_VH_MP_13") + "</b>")

			} else {
				this.setHtml(
					"resultadoBusca",
					`<b>${this.numQuestoesEncontradas} ${this.getMsg("MSG_VH_MP_14")}</b>`
				)
			}

			this.exibir();
		}
	}

	async montarProva(codProvaConcursoSelecionada: any, botaoQueDisparou: any) {
		
		if (!botaoQueDisparou) botaoQueDisparou = null;
		
		if (botaoQueDisparou != null) {
			$(botaoQueDisparou).data("html-original", $(botaoQueDisparou).html()).text(this.getMsg("MSG_VH_MP_15") + "...").attr("disabled", "disabled");
		}
		
		try {

			let tempoParaResposta = null;
			let numQuestoesProva = null;

			if (codProvaConcursoSelecionada == null) {

				if (this.numQuestoesEncontradas == null || this.numQuestoesEncontradas == 0) {
					throw this.getMsg("MSG_VH_MP_16");
				}

				numQuestoesProva = this.getValor("numQuestoesSorteadas")

				if (numQuestoesProva == null || numQuestoesProva == "" || isNaN(numQuestoesProva)) {
					await this.exibirAlerta({
						msg: this.getMsg("MSG_VH_MP_17"),
						botoes: [{ label: this.getMsg("MSG_VH_003") }]
					});
					document.getElementById("numQuestoesSorteadas").focus()
					return
				}

				if (numQuestoesProva > this.numQuestoesEncontradas) {
					await this.exibirAlerta({ msg: this.getMsg("MSG_VH_MP_18") });
					return;
				}

				tempoParaResposta = this.getValor("tempoParaResposta")

				if (isNaN(tempoParaResposta) || tempoParaResposta < 0) {
					await this.exibirAlerta({
						msg: this.getMsg("MSG_VH_MP_17"),
						botoes: [{ label: this.getMsg("MSG_VH_MP_19") }],
					});
					document.getElementById("tempoParaResposta").focus();
					return
				}
			}

			let geracaoProvaAlunoTO = {
				nomeProva: this.getValor("nomeProva"),
				numQuestoesProva: numQuestoesProva,
				tempoParaResposta: tempoParaResposta,
				isQuestaoPorQuestao: this.getValor("isQuestaoPorQuestao"),
				codProvaConcursoSelecionada: codProvaConcursoSelecionada,
				filtrosSelecaoQuestoesTO: this.filtrosSelecaoQuestoesTO
			}
			
			if (geracaoProvaAlunoTO.tempoParaResposta != null) {
				geracaoProvaAlunoTO.tempoParaResposta = geracaoProvaAlunoTO.tempoParaResposta * 60;
			}

			const codProvaFeita = await this.call("MontagemProvaFCD/gerarNovaProva", geracaoProvaAlunoTO);
			
			setTimeout(function() {
				inicioPFVH.iniciarProvaAposVerificarPrecondicoes({ codProvaFeita: codProvaFeita });
			}, 500);
			
		} catch (e) {
			alert(e);
			this.logger.error(e);

			if (botaoQueDisparou != null) {
				$(botaoQueDisparou).html($(botaoQueDisparou).data("html-original")).removeAttr("disabled", "disabled");
			}
			
			return;
		}
	}
	
	exibirCampoBuscaTextual() {

		this.append(`
			<div class='input-group'>
				<input type='text' id='buscaTextual' class='form-control' placeholder='${this.getMsg("MSG_VH_MP_20")}' />
				<div class='input-group-btn'>
					<button class='btn btn-primary' onclick='montagemProvaVH.onBuscaTextual(0, "")'>
						<i class='fa fa-search'></i>
					</button>
					<button class='btn btn-primary' onclick='montagemProvaVH.fazerNovaProva()'>
						${this.getMsg("MSG_VH_MP_21")}
					</button>
				</div>
			</div>	
		`);

		this.appendJs(
			() => this.listenToEnter("#buscaTextual", async (event: any) => {
				await montagemProvaVH.onBuscaTextual(0, event.target.value);
			})
		);
	}
	
	async onBuscaTextual(pagina: any, busca: any) {

		UtilHash.registrarHistorico(this.onBuscaTextual, pagina, busca);
		
		pagina = pagina || 0;
		
		busca = busca || $("#buscaTextual").val();
		
		if (busca) busca = decodeURI(busca);
		
		if ($("#resultadoBuscaTextual").length == 0) {
			this.limpar();
			this.setTitulo(this.getMsg("MSG_VH_MP_22"));
			this.append(`
				<div class="col-sm-12">
			`);
			this.exibirCampoBuscaTextual();
			this.append(`
				</div>
				<div id="resultadoBuscaTextual" class="col-sm-12">
				</div>
			`);
			this.addBotao({label: this.getMsg("MSG_VH_MP_23"), onClick: () => UtilHash.voltar()})
			this.exibir();
			if (this.hasValue(busca)) this.setValor("buscaTextual", busca);
		} else {
			$("#resultadoBuscaTextual").attr("cod-prova-feita", null).html("");
		}

		if (this.hasValue(busca)) {
			
			const exibicaoBuscaTextualTO = await this.call("MontagemProvaFCD/recuperarQuestoesParaResolverPorBuscaTextual", busca, pagina);
		
			this.setIdTarget("resultadoBuscaTextual");

			this.addEspacamentoHorizontal();

			this.append(UtilPaginacao.criarPaginador({
				onCarregarPagina: async (paginacaoTO: any) => {
					await this.onBuscaTextual(paginacaoTO.numPaginaAtual, busca);
				},
				paginacaoTO: {
					numPaginaAtual: pagina,
					numTotalItens: exibicaoBuscaTextualTO.totalQuestoesEncontradas,
					numItensPorPagina: exibicaoBuscaTextualTO.itensPorPagina,
				},
				// itensPorPagina: exibicaoBuscaTextualTO.itensPorPagina,  
				// paginaAtiva: pagina,
				// totalItens: exibicaoBuscaTextualTO.totalQuestoesEncontradas,
				displayOpcao: null,
				displayOpcaoInicioFim: null,
			}));

			exibicaoQuestaoVH.limparControleTextos();

			const exibicaoQuestaoTOPorCodQuestao = {};

			for (let exibicaoQuestaoTO of exibicaoBuscaTextualTO.listExibicaoQuestaoTO) {

				if (this.hasValue(exibicaoQuestaoTO.collectionExibicaoItemTO)) {
					for (let exibicaoItemTO of exibicaoQuestaoTO.collectionExibicaoItemTO) {
						exibicaoItemTO._gabaritoDoItem = exibicaoItemTO.gabaritoDoItem;
						exibicaoItemTO.gabaritoDoItem = null;
					}
				}

				const gabaritoDaQuestao = exibicaoQuestaoTO.gabaritoDaQuestao;

				exibicaoQuestaoTO.gabaritoDaQuestao = null;
				exibicaoQuestaoVH.exibirQuestao(exibicaoQuestaoTO);
				exibicaoQuestaoTO.gabaritoDaQuestao = gabaritoDaQuestao;

				exibicaoQuestaoTOPorCodQuestao[exibicaoQuestaoTO.codQuestao] = exibicaoQuestaoTO;

				if (this.hasValue(exibicaoQuestaoTO.collectionExibicaoItemTO)) {
					for (let exibicaoItemTO of exibicaoQuestaoTO.collectionExibicaoItemTO) {
						exibicaoItemTO.gabaritoDoItem = exibicaoItemTO._gabaritoDoItem;
						exibicaoItemTO._gabaritoDoItem = null;
					}
				}
			}

			this.append(UtilPaginacao.criarPaginador({
				onCarregarPagina: async (paginacaoTO: any) => {
					await this.onBuscaTextual(paginacaoTO.numPaginaAtual, busca)
				},
				paginacaoTO: {
					numPaginaAtual: pagina,
					numTotalItens: exibicaoBuscaTextualTO.totalQuestoesEncontradas,
					numItensPorPagina: exibicaoBuscaTextualTO.itensPorPagina,
				},
				// itensPorPagina: exibicaoBuscaTextualTO.itensPorPagina,
				// paginaAtiva: pagina,
				// totalItens: exibicaoBuscaTextualTO.totalQuestoesEncontradas,
				displayOpcao: null,
				displayOpcaoInicioFim: null,
			}));

			this.exibir();

			$("a.botao_resolver").on("click", ({ target }) => {

				const questao = $(target).closest("[questao]")[0];
				const codQuestao = Number($(questao).attr("cod-questao"));
				const opcaoMarcada = $(questao).find("[opcao].active");
				const codItem = Number($(opcaoMarcada).closest("[item]").attr("cod-item"));
				const exibicaoQuestaoTO = exibicaoQuestaoTOPorCodQuestao[codQuestao];
				const exibicaoItemTO = exibicaoQuestaoTO.collectionExibicaoItemTO.find((to: any) => to.codItem === codItem);
				const numeradorItem = $(opcaoMarcada).closest("[item]").find("[numerador]").attr("numerador");

				exibicaoQuestaoTO.exibicaoRQTO = {
					isGabaritoVisualizado: true,
					codQuestao: codQuestao
				}
				exibicaoQuestaoTO.exibicaoCorrecaoRQTO = {};
				exibicaoItemTO.exibicaoRespostaItemTO = {};

				if (opcaoMarcada.length) {
					if ($(questao).is("[ME]")) {
						exibicaoQuestaoTO.exibicaoRQTO.resposta = $(opcaoMarcada).attr("letra");
						exibicaoQuestaoTO.exibicaoCorrecaoRQTO.acertouQuestao = (exibicaoQuestaoTO.gabaritoDaQuestao === $(opcaoMarcada).attr("letra"));
						exibicaoItemTO.exibicaoRespostaItemTO = {
							codItem,
							numerador: numeradorItem,
							resposta: true
						};
					} else if ($(questao).is("[CE]")) {
						const resposta = $(opcaoMarcada).val();
						exibicaoItemTO.exibicaoRespostaItemTO = {
							codItem,
							numerador: numeradorItem,
							resposta: this.hasValue(resposta) && resposta === "C"
						};
						exibicaoItemTO.correcaoItemCE = exibicaoItemTO.gabaritoDoItem === exibicaoItemTO.exibicaoRespostaItemTO.resposta;
					}
				}
				
				exibicaoQuestaoVH.atualizarExibicaoQuestao(questao, exibicaoQuestaoTO);
				$(questao).find("a.botao_resolver").hide();
			})
		}
	}	
}

const montagemProvaVH = new MontagemProvaVH();