class RelAcertosErrosVH extends DataWarehouseVH {

	propsBarras: string[];
	colunas: ColunaAddTabela[];

	constructor() {
		super(RelAcertosErrosVH.name);
	}

	async exibirRelatorioAcertosErrosPorQuestao() {
		return this.exibirRelatorioAcertosErros({
			campoAgrupamento: CampoRelRespostas.CONTEUDO_QUESTAO.id
		});
	}

	async exibirRelatorioAcertosErrosPorSegmento() {
		return this.exibirRelatorioAcertosErros({
			campoAgrupamento: CampoRelRespostas.NOME_EMPRESA_USUARIO.id
		});
	}

	async exibirRelatorioAcertosErrosPorProva() {
		return this.exibirRelatorioAcertosErros({
			campoAgrupamento: CampoRelRespostas.NOME_PROVA.id
		});
	}

	async exibirRelatorioAcertosErrosPorDisciplina() {
		return this.exibirRelatorioAcertosErros({
			campoAgrupamento: CampoRelRespostas.NOMES_DISCIPLINAS.id
		});
	}

	async exibirRelatorioAcertosErrosPorTurma() {
		return this.exibirRelatorioAcertosErros({
			campoAgrupamento: CampoRelRespostas.NOME_TURMA_NA_APLICACAO.id
		});
	}

	async exibirRelatorioAcertosErrosPorUsuario() {
		return this.exibirRelatorioAcertosErros({
			campoAgrupamento: CampoRelRespostas.NOME_USUARIO.id
		});
	}

	async exibirRelatorioAcertosErros(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO) {

		cfgsRelIndiceRespostasTO = this.verificar(cfgsRelIndiceRespostasTO);

		cfgsRelIndiceRespostasTO.paginacaoTO  = {
			numItensPorPagina: 10,
			numPaginaAtual: 0,
			numTotalItens: null
		};

		let resultadoAcertosErrosTO = await this.call({
			endpoint: "RelatorioAcertosErrosFCD/gerar",
			params: [cfgsRelIndiceRespostasTO],
			blockUiOnCarregando: true,
		});

		let listaGrupoAcertosErrosTO = resultadoAcertosErrosTO.listaGrupoAcertosErrosTO;
		const mostrarColunaEmBranco = listaGrupoAcertosErrosTO.some(to => to.emBranco > 0);
		const mostrarColunaAnuladas = listaGrupoAcertosErrosTO.some(to => to.anuladas > 0);
		const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);

		 this.colunas = [
			 {titulo: campoAgrupamento.getNome(), prop: "nome", classe: "descricao"},
			 {titulo: this.getMsg("MSG_VH_RAE_04"), prop: "acertos", formato: "numero"},
			 {titulo: this.getMsg("MSG_VH_RAE_05"), prop: "erros", formato: "numero"}
		];

		 this.propsBarras = ["acertos", "erros"];

		if (mostrarColunaEmBranco) {
			this.colunas.push( {titulo: this.getMsg("MSG_VH_RAE_06"), prop: "emBranco", formato: "numero"});
			this.propsBarras.push("emBranco");
		}

		if (mostrarColunaAnuladas) {
			this.colunas.push( {titulo: this.getMsg("MSG_VH_RAE_07"), prop: "anuladas", formato: "numero"});
			this.propsBarras.push("anuladas");
		}

		this.append(`
				<div id='grafico_relatorio_acertos_erros_wrapper' style='width: 100%; overflow: auto; margin: 10px 0'>
				</div>
		`);

		if(this.isAluno()){
			this.abrirAbaAccordion({
				id: 'accordion_tabela_acertos_e_erros',
				titulo: this.getMsg("FP_FRONT_RelAcertosErrosVH_002"),
				aberta: false
			});

			this.append(`
				<div id="tabela_relatorio_acertos_erros" class="tabela-estatisticas"></div>
			`);

			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();
		} else {
			this.append(`
				<div class="row">
					<div id="tabela_relatorio_acertos_erros" class="tabela-estatisticas col-md-12"></div>
				</div>
			`);
		}

		this.exibir();

		await this.exibirTabelaEGrafico(cfgsRelIndiceRespostasTO, resultadoAcertosErrosTO);

		if(!this.isAluno()) this.collapseAccordion("accordion_acertos_e_erros");
	}

	async exibirGrafico(listaGrupoAcertosErrosTO: any) {
		const tamanho = listaGrupoAcertosErrosTO?.length || 0
		const h = tamanho * 75;

		const el = $("#grafico_relatorio_acertos_erros_wrapper");

		el.css('height',tamanho > 0 ? '320px' : '')

		if (!listaGrupoAcertosErrosTO?.length) {
			el.html(`
				<div>${this.getMsg('MSG_VH_RAE_46')}</div>
			`);
			return;
		}

		el.html(` 
			<div id='grafico_relatorio_acertos_erros' class='ct-chart' style='width: 100%; height: ${h > 300 ? h+'px' : tamanho > 0 ? '300px' : ''}; margin: 0 auto;'>
			</div>
		`);

		const labels = {
			"acertos": this.getMsg('MSG_VH_RAE_42'),
			"erros": this.getMsg('MSG_VH_RAE_43'),
			"emBranco": this.getMsg('MSG_VH_RAE_44'),
			"anuladas": this.getMsg('MSG_VH_RAE_45'),
		}

		const cores = {
			"acertos": UtilGrafico.COR_VERDE_CLARO,
			"erros": UtilGrafico.COR_VERMELHO,
			"emBranco": UtilGrafico.COR_CINZA,
			"anuladas": UtilGrafico.COR_AMARELO,
		}

		await UtilGrafico.criarEChart("#grafico_relatorio_acertos_erros", {
			tos: listaGrupoAcertosErrosTO.reverse(),
			labels: labels,
			cores: cores,
			labelBarra: "nome",
			exibirPorcentagem: true,
			tipo: "BARRAS_HORIZONTAIS_EMPILHADAS",
		});
	}

	async exibirTabelaEGrafico(cfgsRelIndiceRespostasTO: CfgsRelIndiceRespostasTO, resultadoAcertosErrosTO: any) {

		$("#tabela_relatorio_acertos_erros").html("");
		this.setIdTarget("tabela_relatorio_acertos_erros");

		const exportTodosResultados = async() => {
			this.disable("botao_export_all");
			const divMsgAjax = AmaisVH.criarDivMsgAjax(this.getMsg("MSG_VH_RAE_41"));
			let cfgsRelIndiceRespostasCopyTO: any = {...cfgsRelIndiceRespostasTO};
			let colunasXlsx = this.colunas.map(item => {
				return {
					nome: item.titulo, identificador: item.prop, tipo: item.formato
				}
			});

			cfgsRelIndiceRespostasCopyTO['paginacaoTO'] = {
				numPaginaAtual: 0, numItensPorPagina: 9999
			}

			let resultados = await this.call("RelatorioAcertosErrosFCD/gerar", cfgsRelIndiceRespostasCopyTO);
			const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);

			await UtilXlsx.criarXlsx(
				colunasXlsx,
				resultados.listaGrupoAcertosErrosTO,
				"export_acertoseerros_" + campoAgrupamento.getNome().toString().toLowerCase()
			);

			divMsgAjax.remove();
			this.enable("botao_export_all");
		}

		const campoAgrupamento = CampoRelRespostas.from(cfgsRelIndiceRespostasTO.campoAgrupamento);
		this.append('<div id="tabela_acertos_erros_container" style="display: none">');

		await this.addTabela({
			id: "tabela_acertos_erros_por_prova",
			collection: resultadoAcertosErrosTO.listaGrupoAcertosErrosTO,
			propId: "cod",
			colunas: this.colunas,
			paginacao: {
				paginacaoTO: resultadoAcertosErrosTO.paginacaoTO,
				exibirPaginacaoRodape: true,
				onCarregarPagina: async (paginacaoTO) => {

					cfgsRelIndiceRespostasTO.paginacaoTO = paginacaoTO;

					resultadoAcertosErrosTO = await this.call("RelatorioAcertosErrosFCD/gerar", cfgsRelIndiceRespostasTO);

					await this.exibirGrafico(resultadoAcertosErrosTO.listaGrupoAcertosErrosTO);

					return resultadoAcertosErrosTO.listaGrupoAcertosErrosTO.reverse();
				}
			},
			onEdicao: !this.isAdministrador() ? null : campoAgrupamento.onEdicao,
			onEdicaoParam1: campoAgrupamento.onEdicaoParam1,
		});
		this.append('</div>')

		this.exibir();

		await this.exibirGrafico(resultadoAcertosErrosTO.listaGrupoAcertosErrosTO);

		$("#botao_export_all").click(exportTodosResultados);
	}
}

const relAcertosErrosVH = new RelAcertosErrosVH();
