class RankingVH extends AmaisVH {
	
	provas: any;

	constructor() {
		super(RankingVH.name);
		this.addOperacaoParaHash("ranksp", this.exibirProvasRealizadas);
		this.addOperacaoParaHash("rankp", this.exibirRankingPorProva);
		this.addOperacaoParaHash("rankd", this.exibirDisciplinasRealizadas);	
	}

	iniciar() {
		this.exibirProvasRealizadas()
	}

	async exibirDisciplinasRealizadas() {

		UtilHash.registrarHistorico(this.exibirDisciplinasRealizadas);

		const rankingPorDisciplinaTO = await this.call("RankingFCD/exibirRankingPorDisciplina", null, null);

		this.limpar()

		this.addLinksRanking(false, true)
		
		this.setTitulo(this.getMsg("MSG_VH_R_01"))

		if (rankingPorDisciplinaTO == null) {
			var msg = this.getMsg("MSG_VH_R_02")
				
			if (!this.isAluno()) {
				msg = this.getMsg("MSG_VH_R_03") + " " + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase() + " " + this.getMsg("MSG_VH_R_04")
			}
			
			this.addTextoObs({texto: msg})
			this.exibir();
			return
		}
		
		await this.addSelect({
			collection: rankingPorDisciplinaTO.disciplinasPrincipais,
			id: "codDisciplinaPrincipal",
			label: this.getMsg("MSG_VH_R_30"),
			propFilhas: "filhos",
			onChange: async () => await this.onDisciplinaPrincipalAlterada(),
			valor: rankingPorDisciplinaTO.codDisciplinaPrincipalMaisEstudada,
			obrigatorio: true
		})

		if (rankingPorDisciplinaTO.disciplinasSecundarias != null) {
			rankingPorDisciplinaTO.disciplinasSecundarias.unshift({id: "", descricao: "(" + this.getMsg("MSG_VH_R_15") + ")"})
		}

		await this.addSelect({
			collection: rankingPorDisciplinaTO.disciplinasSecundarias,
			id: "codDisciplinaSecundaria",
			label: this.getMsg("MSG_VH_R_05"),
			propFilhas: "filhos",
			onChange: async () => await this.onDisciplinaSecundariaAlterada(),
			valor: rankingPorDisciplinaTO.codDisciplinaSecundariaMaisEstudada,
			obrigatorio: true
		})

		this.append("<div id='listagem_ranking' style='float: left'>")

		if (this.isAluno()) {
			this.addTexto(this.getMsg("MSG_VH_R_06"))
		} else {
			this.addTexto(this.getMsg("MSG_VH_R_07") + " " + this.getCfg("LABEL_MENU_CADASTRO_ALUNO").toLowerCase() + ". " + this.getMsg("MSG_VH_R_08"))
		}

		await this.addListagemRankingDisciplina(rankingPorDisciplinaTO)

		this.append("</div>")

		this.addTextoObs({texto: this.getMsg("MSG_VH_R_09"), css: "float: right"})

		this.exibir();
	}

	async addListagemRankingDisciplina(rankingPorDisciplinaTO) {
		this.addSubtitulo(rankingPorDisciplinaTO.nomeDisciplina)

		var montarColunaAproveitamento = function(listagemAlunosRankingDisciplinaTO) {
			return '<div class="barra-acertos-erros"><small class="acertos">' + listagemAlunosRankingDisciplinaTO.pontuacaoObtida + ' ' + this.getMsg("MSG_VH_R_10") + (listagemAlunosRankingDisciplinaTO.pontuacaoObtida == 1 ? "" : "s") + ' ' + this.getMsg("MSG_VH_R_11") + ' ' + listagemAlunosRankingDisciplinaTO.pontuacaoMaxima + ' (' + Math.round(100*listagemAlunosRankingDisciplinaTO.aproveitamento) + '%)</small> <div class="progress">'
			+ '<div class="progress-bar progress-bar-success" style="width:' + listagemAlunosRankingDisciplinaTO.aproveitamento * 100 + '%"></div>'
			+ '<div class="progress-bar progress-bar-danger" style="width:' + (1 - listagemAlunosRankingDisciplinaTO.aproveitamento) * 100 + '%"></div>'
			+ '<small class="acertos"></small></div></div>';
		}

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_R_12"), prop: "identificacao", classe: "descricao" });
		colunas.push({titulo: this.getMsg("MSG_VH_R_13"), prop: montarColunaAproveitamento });
		colunas.push({titulo: "", prop: "aproveitamento" });

		await this.addTabela({
			collection: rankingPorDisciplinaTO.collectionListagemAlunosRankingDisciplinaTO,
			colunas: colunas,
			exibirNumeracao: true,
			sufixoNumeracao: "&#176;",
			labelNumeracao: this.getMsg("MSG_VH_R_28"),
			ordenar: false
		})
	}

	async onDisciplinaPrincipalAlterada() {
		this.updateSelect({
			id: "codDisciplinaSecundaria",
			collection: [{id:"", descricao:"(" + this.getMsg("MSG_VH_R_14") + "...)"}]
		})
		const rankingPorDisciplinaTO = await this.call("RankingFCD/exibirRankingPorDisciplina", this.getValor("codDisciplinaPrincipal"), null);
		await this.atualizarRanking(rankingPorDisciplinaTO);
	}

	async atualizarRanking(rankingPorDisciplinaTO) {
		this.setTexto("listagem_ranking", "")
		this.setIdTarget("listagem_ranking")
		await this.addListagemRankingDisciplina(rankingPorDisciplinaTO)
		this.exibir();
		this.setIdTarget(null)

		if (rankingPorDisciplinaTO.disciplinasSecundarias != null) {
			if (this.hasValue(rankingPorDisciplinaTO.disciplinasSecundarias)) {
				rankingPorDisciplinaTO.disciplinasSecundarias.unshift({id: "", descricao: "(" + this.getMsg("MSG_VH_R_15") + ")"})
				this.updateSelect({
					id: "codDisciplinaSecundaria",
					collection: rankingPorDisciplinaTO.disciplinasSecundarias
				})
			} else {
				var msg = "(" + this.getMsg("MSG_VH_R_16") + ")"
					
				if (!this.isAluno) {
					msg = "(" + this.getMsg("MSG_VH_R_17") + " " + this.getCfg("LABEL_ALUNO").toLowerCase() + " " + this.getMsg("MSG_VH_R_18") + ")"
				}
				
				this.updateSelect({
					id: "codDisciplinaSecundaria",
					collection: [{id: "", descricao: msg}]
				})
			}
		}
	}

	async onDisciplinaSecundariaAlterada() {
		const rankingPorDisciplinaTO = await this.call("RankingFCD/exibirRankingPorDisciplina", this.getValor("codDisciplinaPrincipal"), this.getValor("codDisciplinaSecundaria"));
		await this.atualizarRanking(rankingPorDisciplinaTO);
	}

	async exibirProvasRealizadas() {

		UtilHash.registrarHistorico(this.exibirProvasRealizadas);

		const provas = await this.call("RankingFCD/listarProvasProntas");
		await this.exibirProvasRealizadasCB(provas);
	}

	addLinksRanking(isPorProva, isPorDisciplina) {
		if (isPorProva) {
			this.addTexto({texto: "<b>" + this.getMsg("MSG_VH_R_19") + "</b>", idAlvo: "tituloSuperiorDireito", css: "margin-right: 5px"})
		} else {
			this.addLink({ 
				label: this.getMsg("MSG_VH_R_20"), 
				onClick: () => this.exibirProvasRealizadas(), 
				idAlvo: "tituloSuperiorDireito", 
				// width: "70px", 
				css: "margin-right: 5px" 
			})
		}
		if (isPorDisciplina) {
			this.addTexto({texto: "<b>" + this.getMsg("MSG_VH_R_21") + "</b>", idAlvo: "tituloSuperiorDireito", css: "margin-right: 5px"})
		} else {
			this.addLink({
				label: this.getMsg("MSG_VH_R_22"), 
				onClick: () => this.exibirDisciplinasRealizadas(), 
				idAlvo: "tituloSuperiorDireito", 
				// width: "90px", 
				css: "margin-right: 5px"
			})
		}
	}

	async exibirProvasRealizadasCB(provas) {

		this.limpar()

		this.setTitulo(this.getMsg("MSG_VH_R_24"))


		if (provas.length == 0) {
			this.addTextoObs({texto: this.getMsg("MSG_VH_R_23")})

		} else {
			this.addLinksRanking(true, false)

			const colunas: ColunaAddTabela[] = [];
			colunas.push({titulo: this.getMsg("MSG_VH_R_31"), prop: "nomeProva", classe: "descricao" });
			colunas.push({titulo: "# " + this.getMsg("MSG_VH_R_32"), prop: "numeroQuestoes" });
			colunas.push({titulo: this.getMsg("MSG_VH_R_33"), prop: "banca" });
			
			await this.addTabela({
				collection: provas,
				propId: "codProva",
				colunas: colunas,
				onEdicao: this.exibirRankingPorProva
			})
		}

		this.exibir();
		this.provas = provas
	}

	async voltarParaProvas() {
		await this.exibirProvasRealizadasCB(this.provas)
	}

	async exibirRankingPorProva(codProva) {

		UtilHash.registrarHistorico(this.exibirRankingPorProva, codProva);

		const rankingProvaTO = await this.call("RankingFCD/exibirRankingPorProva", codProva);

		this.limpar()

		this.setTitulo(this.getMsg("MSG_VH_R_24"))
		this.setSubtitulo(rankingProvaTO.nomeProva)

		var props = ["identificacao", "dataAplicacao", "nota", "tempoProva"]
		var labels = [this.getCfg("LABEL_ALUNO"), this.getMsg("MSG_VH_R_25"), this.getMsg("MSG_VH_R_26"), this.getMsg("MSG_VH_R_27")]

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getCfg("LABEL_ALUNO"), prop: "identificacao"});
		colunas.push({titulo: this.getMsg("MSG_VH_R_25"), prop: "dataAplicacao", formato: "DD/MM/YY HH:mm"});
		colunas.push({titulo: this.getMsg("MSG_VH_R_26"), prop: "nota"});
		colunas.push({titulo: this.getMsg("MSG_VH_R_27"), prop: "tempoProva"});

		if (rankingProvaTO.possuiNotaMinina) {
			colunas.push({titulo: "Classificado", prop: "isClassificado"});
		}

		await this.addTabela({
			collection: rankingProvaTO.collectionListagemAlunosRankingTO,
			colunas: colunas,
			exibirNumeracao: true,
			sufixoNumeracao: "&#176;",
			labelNumeracao: this.getMsg("MSG_VH_R_28"),
			ordenar: false
		})

		this.addBotaoEsquerdo(this.getMsg("MSG_VH_R_29"), async () => await this.voltarParaProvas())

		this.exibir();
	}
}

const rankingVH = new RankingVH();